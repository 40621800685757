import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
  Collapse,
  Badge,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import StoreView from "views/examples/StoreView";

import APIV2 from "lib/APIV2";
import Spinner from "reactstrap/lib/Spinner";
import { withRouter } from "react-router";
import moment from "moment";
import { ProgressBar } from "@tremor/react";
import _ from "underscore";
import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";
import LinkUtils from "lib/LinkUtils";
import DataAPI from "lib/DataAPI";
import cubejs from "@cubejs-client/core";
import StringUtils from "lib/StringUtils";
import { Drawer } from "rsuite";
import StoreNSOWidget from "./StoreNSOWidget";
import StorePurchaseListWidget from "./StorePurchaseListWidget";
import StoreOpeningChecklistWidget from "./StoreOpeningChecklistWidget";

class StoreOpeningWidget extends React.Component {
  state = {
    store: null,
    expanded: [],
  };

  async loadMemberCount(cubejsApi) {
    this.setState({
      loadingMembers: true,
    });

    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["CustomerSubscriptions.count"],
          order: {
            "CustomerSubscriptions.createdat": "asc",
          },

          timeDimensions: [],
          filters: [
            {
              member: "CustomerSubscriptions.storeid",
              operator: "equals",
              values: [this.props.store?._id],
            },
            {
              member: "CustomerSubscriptions.status",
              operator: "equals",
              values: ["ACTIVE"],
            },
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          if (!data?.length) {
            return resolve(null);
          }

          data = data[0];

          let count = data["CustomerSubscriptions.count"];

          this.setState({
            memberCount: count,
          });
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          this.setState({
            loadingMembers: false,
          });
        });
    });
  }

  async loadLeadCount(cubejsApi) {
    this.setState({
      loadingLeads: true,
    });

    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["CustomerLeads.total"],
          order: {
            "CustomerLeads.createdat": "asc",
          },

          timeDimensions: [],
          filters: [
            {
              member: "CustomerLeads.storeid",
              operator: "equals",
              values: [this.props.store?._id],
            },
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          // console.log("LEads", data);

          if (!data?.length) {
            return resolve(null);
          }

          data = data[0];

          let count = data["CustomerLeads.total"];

          this.setState({
            leadCount: count,
          });
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          this.setState({
            loadingLeads: false,
          });
        });
    });
  }

  loadCounts() {
    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    this.loadMemberCount(cubejsApi);
    this.loadLeadCount(cubejsApi);
  }

  loadSections(template) {
    let sections = _.filter(_.keys(template), (key) => {
      return (
        key != "_id" &&
        key != "recordType" &&
        key != "createdAt" &&
        key != "modifiedAt" &&
        !key?.includes("items_") &&
        !key?.includes("opening_")
      );
    });

    let purchaseSections = _.filter(_.keys(template), (key) => {
      return key?.includes("items_");
    });

    let openingSections = _.filter(_.keys(template), (key) => {
      return key?.includes("opening_");
    });

    sections = _.map(sections, (section) => {
      let s = template[section];

      s.key = section;

      return s;
    });

    purchaseSections = _.map(purchaseSections, (section) => {
      let s = template[section];

      s.key = section;

      return s;
    });

    openingSections = _.map(openingSections, (section) => {
      let s = template[section];

      s.key = section;

      return s;
    });

    sections = _.sortBy(sections, "order");
    purchaseSections = _.sortBy(purchaseSections, "order");
    openingSections = _.sortBy(openingSections, "order");

    if (!this.props.globalMode) {
      for (let i = 0; i < sections.length; i++) {
        let actions = sections[i].actions;

        actions = _.filter(actions, (action) => {
          return action.visibleTo == "All Parties";
        });

        sections[i].actions = actions;
      }
    }

    this.setState({
      template,
      sections,
      purchaseSections,
      openingSections,
    });
  }

  loadNSOTemplate() {
    this.setState({
      templateLoading: true,
    });

    this.loadCounts();

    APIV2.getNSOTemplate()
      .then(
        (data) => {
          let template = data?.data?.template;

          this.loadSections(template);
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          templateLoading: false,
        });
      });
  }

  isComplete(item) {
    let nso = this.state.store?.nso;

    if (nso?.[item?._id]?.completed) {
      return true;
    }

    return false;
  }

  complete(item, status) {
    let user = LocalStorage.get(Constant.CACHE.USER);

    let payload = {
      completed: false,
    };

    if (status == true) {
      payload = {
        completed: true,
        completedBy: {
          _id: user?._id,
          name: user?.name,
          email: user?.email,
        },
        completedAt: new Date(),
      };
    }

    this.setState({
      submitting: item?._id,
    });

    APIV2.modifyStoreNSO(this.props.store?.nsoID, item._id, payload)
      .then(
        (data) => {
          //  console.log(data?.data?.nso);

          this.props.onUpdate(data?.data?.nso);
        },
        (e) => {}
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  loadStore() {
    const {
      match: { params },
    } = this.props;

    APIV2.getStoreByID(params.storeID)
      .then(
        (data) => {
          if (data && data.data && data.data.store) {
            this.setState({
              store: data.data.store,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    this.loadNSOTemplate();
    this.loadStore();
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.store != this.props?.store) {
      if (this.props.store) {
        this.loadCounts();
        this.loadStore();
      } else {
        this.setState({
          memberCount: null,
          leadCount: null,
        });
      }
    }
  }

  calculateSectionCompletion(section) {
    let actions = section?.actions;

    if (!actions?.length) {
      return 0;
    }

    let completed = _.filter(actions, (action) => {
      return this.isComplete(action);
    });

    return (completed.length / actions.length) * 100;
  }

  calculateTotalCompletion() {
    let sections = this.state.sections;

    if (!sections?.length) {
      return 0;
    }

    let actions = [];

    for (let i = 0; i < sections.length; i++) {
      actions = actions.concat(sections[i].actions);
    }

    if (!actions?.length) {
      return 0;
    }

    let completed = _.filter(actions, (action) => {
      return this.isComplete(action);
    });

    //console.log(completed?.length, actions?.length);

    return (completed?.length / actions?.length) * 100;
  }

  calculateTotalOpeningCompletion() {
    let sections = this.state.openingSections;

    if (!sections?.length) {
      return 0;
    }

    let actions = [];

    for (let i = 0; i < sections.length; i++) {
      actions = actions.concat(sections[i].items);
    }

    if (!actions?.length) {
      return 0;
    }

    let completed = _.filter(actions, (action) => {
      //console.log(action);

      return this.isComplete(action);
    });

    //console.log(completed?.length, actions?.length);

    return (completed?.length / actions?.length) * 100;
  }

  calculateTotalPurchaseCompletion() {
    let sections = this.state.purchaseSections;

    if (!sections?.length) {
      return 0;
    }

    let actions = [];

    for (let i = 0; i < sections.length; i++) {
      actions = actions.concat(sections[i].items);
    }

    if (!actions?.length) {
      return 0;
    }

    let completed = _.filter(actions, (action) => {
      return this.isComplete(action);
    });

    // console.log(completed?.length, actions?.length);

    return (completed?.length / actions?.length) * 100;
  }

  getCompletionColor(completion) {
    if (completion == 100) {
      return "emerald";
    }

    if (completion >= 75) {
      return "green";
    }

    if (completion >= 50) {
      return "yellow";
    }

    if (completion >= 49) {
      return "orange";
    }

    return "red";
  }

  render() {
    return (
      <>
        <Card className="border shadow mb-4">
          <CardHeader className="p-3">
            <Row className="align-items-center">
              <Col xs="">
                <h3 className="mb-0">
                  {this.props.store?.name
                    ? `${this.props.store?.name
                        ?.replace("Project LeanNation", "PLN")
                        ?.trim()} `
                    : ""}
                  Store Opening
                </h3>
              </Col>
              <Col className="text-right" xs="auto"></Col>
            </Row>
          </CardHeader>
          <CardBody className="p-3">
            <Row className="align-items-center">
              <Col xs="">
                <p className="m-0">
                  <span className="text-dark font-weight-500">
                    NSO Progress:{" "}
                  </span>
                  {this.calculateTotalCompletion()?.toFixed(1)}% Complete
                </p>
              </Col>
              <Col xs="auto">
                <Button
                  color="primary"
                  outline
                  onClick={(e) => {
                    this.setState({
                      open: true,
                    });
                  }}
                  size="sm"
                >
                  View Timeline
                </Button>
              </Col>
              <Col xs="12">
                <ProgressBar
                  percentageValue={this.calculateTotalCompletion()}
                  color={this.getCompletionColor(
                    this.calculateTotalCompletion()
                  )}
                  marginTop="mt-3"
                />
              </Col>
            </Row>
          </CardBody>
          <CardBody className="p-3 border-top">
            <Row className="align-items-center">
              <Col xs="">
                <p className="m-0">
                  <span className="text-dark font-weight-500">
                    Purchase Lists:{" "}
                  </span>
                  {this.calculateTotalPurchaseCompletion()?.toFixed(1)}%
                  Purchased
                </p>
              </Col>
              <Col xs="auto">
                <Button
                  color="primary"
                  outline
                  onClick={(e) => {
                    this.setState({
                      openLists: true,
                    });
                  }}
                  size="sm"
                >
                  View Lists
                </Button>
              </Col>
              <Col xs="12">
                <ProgressBar
                  percentageValue={this.calculateTotalPurchaseCompletion()}
                  color={this.getCompletionColor(
                    this.calculateTotalPurchaseCompletion()
                  )}
                  marginTop="mt-3"
                />
              </Col>
            </Row>
          </CardBody>
          {this.props.store?.operations?.storeOpening?.openingDate &&
          moment().isSameOrAfter(
            moment(
              this.props.store?.operations?.storeOpening?.openingDate
            ).subtract(30, "days")
          ) &&
          moment().isSameOrBefore(
            moment(this.props.store?.operations?.storeOpening?.openingDate).add(
              30,
              "days"
            )
          ) ? (
            <>
              <CardBody className="p-3">
                <Row className="align-items-center">
                  <Col xs="">
                    <p className="m-0">
                      <span className="text-dark font-weight-500">
                        Opening Checklist:{" "}
                      </span>
                      {this.calculateTotalOpeningCompletion()?.toFixed(1)}%
                      Complete
                    </p>
                  </Col>
                  <Col xs="auto">
                    <Button
                      color="primary"
                      outline
                      onClick={(e) => {
                        this.setState({
                          openOpeningChecklist: true,
                        });
                      }}
                      size="sm"
                    >
                      View List
                    </Button>
                  </Col>
                  <Col xs="12">
                    <ProgressBar
                      percentageValue={this.calculateTotalOpeningCompletion()}
                      color={this.getCompletionColor(
                        this.calculateTotalOpeningCompletion()
                      )}
                      marginTop="mt-3"
                    />
                  </Col>
                </Row>
              </CardBody>
            </>
          ) : null}
          {this.props.store?.operations?.storeOpening?.prelaunchStartDate &&
          moment().toDate() >=
            moment(
              this.props.store?.operations?.storeOpening?.prelaunchStartDate
            ).toDate() ? (
            <>
              <CardBody className="p-3 border-top">
                <Row className="align-items-center">
                  <Col xs="">
                    <p className="m-0 text-dark font-weight-500">
                      Founding Members:
                    </p>
                  </Col>
                  <Col xs="12" sm="auto">
                    {this.state.loadingMembers ? (
                      <Spinner size="sm"></Spinner>
                    ) : (
                      <p className="m-0">
                        {this.state.memberCount ? this.state.memberCount : 0} /
                        150 Members (
                        {(
                          ((this.state.memberCount
                            ? this.state.memberCount
                            : 0) /
                            150) *
                          100
                        ).toFixed(1)}
                        % Of Goal)
                      </p>
                    )}
                  </Col>
                  <Col xs="12">
                    <ProgressBar
                      percentageValue={
                        ((this.state.memberCount ? this.state.memberCount : 0) /
                          150) *
                        100
                      }
                      color={this.getCompletionColor(
                        ((this.state.memberCount ? this.state.memberCount : 0) /
                          150) *
                          100
                      )}
                      marginTop="mt-2"
                    />
                  </Col>
                </Row>
              </CardBody>
            </>
          ) : null}
          <CardBody className="p-3 border-top">
            <Row className="align-items-center">
              <Col xs="">
                <p className="m-0 text-dark font-weight-500">Leads:</p>
              </Col>
              <Col xs="12" sm="auto">
                {this.state.loadingLeads ? (
                  <Spinner size="sm"></Spinner>
                ) : (
                  <p className="m-0">
                    {StringUtils.numberFormat(
                      this.state.leadCount ? this.state.leadCount : 0
                    )}{" "}
                    / 1,500 Leads (
                    {(
                      ((this.state.leadCount ? this.state.leadCount : 0) /
                        1500) *
                      100
                    ).toFixed(1)}
                    % Of Goal)
                  </p>
                )}
              </Col>
              <Col xs="12">
                <ProgressBar
                  percentageValue={
                    ((this.state.leadCount ? this.state.leadCount : 0) / 1500) *
                    100
                  }
                  color={this.getCompletionColor(
                    ((this.state.leadCount ? this.state.leadCount : 0) / 1500) *
                      100
                  )}
                  marginTop="mt-2"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Drawer
          size="lg"
          style={{ maxWidth: "100%" }}
          open={this.state.open}
          onClose={() => {
            this.setState({
              open: false,
            });
          }}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3
              className="m-0"
              style={{ position: "relative", top: "7px", paddingBottom: 10 }}
            >
              NSO Timeline
            </h3>
            <Drawer.Actions></Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            <StoreNSOWidget store={this.state.store} globalMode={false} />
          </Drawer.Body>
        </Drawer>
        <Drawer
          size="lg"
          style={{ maxWidth: "100%" }}
          open={this.state.openLists}
          onClose={() => {
            this.setState({
              openLists: false,
            });
          }}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3
              className="m-0"
              style={{ position: "relative", top: "7px", paddingBottom: 10 }}
            >
              Purchase Lists
            </h3>
            <Drawer.Actions></Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-0">
            <StorePurchaseListWidget
              onUpdate={(nso) => {
                let store = this.state.store;

                if (store) {
                  store.nso = nso;
                }

                PubSub.publish(Event.STORE.MODIFIED, store);
              }}
              store={this.state.store}
              globalMode={false}
            />
          </Drawer.Body>
        </Drawer>
        <Drawer
          size="lg"
          style={{ maxWidth: "100%" }}
          open={this.state.openOpeningChecklist}
          onClose={() => {
            this.setState({
              openOpeningChecklist: false,
            });
          }}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3
              className="m-0"
              style={{ position: "relative", top: "7px", paddingBottom: 10 }}
            >
              Opening Checklist
            </h3>
            <Drawer.Actions></Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            <StoreOpeningChecklistWidget
              store={this.state.store}
              globalMode={false}
            />
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(StoreOpeningWidget);
