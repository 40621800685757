import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Spinner,
  Collapse,
  Table,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import moment, { isMoment } from "moment";
import { CheckboxGroup, DatePicker, SelectPicker, Checkbox } from "rsuite";
import StringUtils from "lib/StringUtils";
import { Cell, Column, HeaderCell } from "rsuite-table";
import _ from "underscore";
import MoneyCell from "components/Table/MoneyCell";
import NumericCell from "components/Table/NumericCell";
import StoreCell from "components/Table/StoreCell";
import BootstrapTable from "react-bootstrap-table-next";
import MealsOrderedWidget from "components/Widgets/Fulfillment/MealsOrderedWidget";
import PlansFulfilledWidget from "components/Widgets/Fulfillment/PlansFulfilledWidget";
import AverageBoxSizeWidget from "components/Widgets/Fulfillment/AverageBoxSizeWidget";
import PlanFrequencyChartWidget from "components/Widgets/Fulfillment/PlanFrequencyChartWidget";
import PlanProcurementChartWidget from "components/Widgets/Fulfillment/PlanProcurementChartWidget";
import PlanTypeChartWidget from "components/Widgets/Fulfillment/PlanTypeChartWidget";
import { Link, withRouter } from "react-router-dom";
import Input from "reactstrap/lib/Input";
import swal from "sweetalert";
import CustomerOrderRow from "components/CustomerOrderRow";

class ProductOrderPackingSlipView extends React.Component {
  state = {};

  loadOrder() {
    this.setState({
      loadingOrder: true,
    });

    APIV2.getProductionOrderByID(this.props?.match?.params?.orderID)
      .then(
        (data) => {
          this.setState({
            order: data?.data?.order,
          });

          console.log(data.data.order);

          this._loadOrderTable(data.data.order);
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the order. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingOrder: false,
          loading: false,
        });
      });
  }

  _loadOrderTable(order) {
    if (!order) {
      return;
    }

    if (order?.fulfillmentReportID) {
      let planKeys = _.keys(order?.summary?.planAnalysis);
      let plans = [];

      let totalQuantity = 0;

      for (let i = 0; i < planKeys?.length; i++) {
        const plan = planKeys[i];
        const qty = order?.summary?.planAnalysis[plan];

        plans.push({
          name: plan,
          quantity: qty,
        });

        totalQuantity += qty;
      }

      plans = _.sortBy(plans, "name");

      this.setState({
        plans,
        totalQuantity,
      });

      return;
    }

    let vals = _.values(order?.menus?.meals);

    console.log(vals);

    let allMeals = [];

    // Parse all options off the meal menus
    for (let i = 0; i < vals?.length; i++) {
      if (vals[i].hasOwnProperty("active") && vals[i].active?.meals?.length) {
        const menu = vals[i].active;

        let activeMenuMeals = vals[i].active?.meals;

        // Check for an upcoming transition for new meals to be orderable
        if (menu?.menuTransition) {
          let transitionDate = moment(
            menu?.menuTransition?.transitionDate
          ).toDate();

          // Make new meals available or order one week out
          if (moment().add(1, "week").toDate() >= transitionDate) {
            let swaps = menu?.menuTransition?.newMenu?.meals?.map((item) => {
              item.upcoming = true;

              return item;
            });

            let retiring = menu?.menuTransition?.retiringMealSwaps.map(
              (item) => {
                return item?.fromMeal;
              }
            );

            for (let j = 0; j < retiring?.length; j++) {
              const idx = _.findIndex(activeMenuMeals, {
                _id: retiring[j]?._id,
              });

              if (idx >= 0) {
                activeMenuMeals[idx].retiring = true;
              }
            }

            swaps = _.filter(swaps, (m) => {
              return !_.findWhere(activeMenuMeals, { _id: m?._id });
            });

            activeMenuMeals = activeMenuMeals.concat(swaps);
          }
        }

        for (let j = 0; j < activeMenuMeals?.length; j++) {
          const meal = activeMenuMeals[j];

          meal.name = meal?.name?.trim();

          // Set as default until it can be calculated
          if (
            order?.meals?.hasOwnProperty(meal?.sku) &&
            order?.meals[meal?.sku].caseQuantity
          ) {
            meal.caseQuantity = order?.meals[meal?.sku]?.caseQuantity;
          } else {
            meal.caseQuantity = 0;
          }

          if (
            order?.meals?.hasOwnProperty(meal?.sku) &&
            order?.meals[meal?.sku]?.mealQuantity
          ) {
            meal.mealQuantity = order?.meals[meal?.sku]?.mealQuantity;
          } else {
            meal.mealQuantity = 0;
          }

          if (!_.findWhere(allMeals, { _id: meal?._id })) {
            allMeals.push(meal);
          }
        }
      }
    }

    console.log(allMeals);

    let lifestyle = [];
    let athlete = [];
    let leancheats = [];

    let totalMeals = 0;
    let totalCases = 0;
    let lifestyleTotalCases = 0;
    let lifestyleTotalMeals = 0;
    let athleteTotalCases = 0;
    let athleteTotalMeals = 0;
    let leanCheatTotalPacks = 0;
    let leanCheatTotalMeals = 0;

    // Group by type
    for (let i = 0; i < allMeals?.length; i++) {
      const meal = allMeals[i];

      totalMeals += meal?.mealQuantity;

      if (meal?.planType?.name?.includes("Lifestyle")) {
        // Lifestyle meal
        // Lean cheat or lifestyle check
        // TODO: convert to sku check
        if (
          _.findWhere(meal?.categories, { name: "Lean Cheats" }) &&
          !_.findWhere(leancheats, { name: meal?.name })
        ) {
          leanCheatTotalPacks += meal?.caseQuantity;
          leanCheatTotalMeals += meal?.mealQuantity;

          leancheats.push(meal);
        } else if (
          !_.findWhere(lifestyle, { _id: meal?._id }) &&
          !_.findWhere(meal?.categories, { name: "Lean Cheats" })
        ) {
          lifestyleTotalCases += meal?.caseQuantity;
          lifestyleTotalMeals += meal?.mealQuantity;
          totalCases += meal?.caseQuantity;

          lifestyle.push(meal);
        }
      } else {
        // Athlete meal
        // Lean cheat or athlete check
        // TODO: convert to sku check
        if (
          _.findWhere(meal?.categories, { name: "Lean Cheats" }) &&
          !_.findWhere(leancheats, { name: meal?.name })
        ) {
          leancheats.push(meal);

          leanCheatTotalPacks += meal?.caseQuantity;
          leanCheatTotalMeals += meal?.mealQuantity;
        } else if (
          !_.findWhere(athlete, { _id: meal?._id }) &&
          !_.findWhere(meal?.categories, { name: "Lean Cheats" })
        ) {
          athlete.push(meal);

          athleteTotalCases += meal?.caseQuantity;
          athleteTotalMeals += meal?.mealQuantity;
          totalCases += meal?.caseQuantity;
        }
      }
    }

    // Sort alphabetically

    lifestyle = _.sortBy(lifestyle, "name");
    athlete = _.sortBy(athlete, "name");
    leancheats = _.sortBy(leancheats, "name");

    for (let i = 0; i < leancheats?.length; i++) {
      leancheats[i].freightCaseQuantity = (
        leancheats[i].mealQuantity / order?.pricing?.leanCheatCaseSize
      ).toFixed(2);
    }

    let leanCheatTotalCases =
      leanCheatTotalMeals / order?.pricing?.leanCheatCaseSize;

    totalCases = totalCases + leanCheatTotalCases;

    let totalBoxes = Math.ceil(totalCases);

    let casesPerPallet = order?.pricing?.logisticsPalletCaseQuantity;

    if (
      this.state.store?._id &&
      this.state.pricing?.logisticsCustomPalletCaseQuantity?.hasOwnProperty(
        this.state.store?._id
      )
    ) {
      casesPerPallet =
        this.state.pricing?.logisticsCustomPalletCaseQuantity[
          this.state.store?._id
        ];
    }

    let palletsShipped = Math.ceil(totalCases / casesPerPallet);

    this.setState({
      lifestyleMeals: lifestyle,
      athleteMeals: athlete,
      leanCheats: leancheats,
      lifestyleTotalCases,
      lifestyleTotalMeals,
      athleteTotalCases,
      athleteTotalMeals,
      leanCheatTotalPacks,
      leanCheatTotalMeals,
      leanCheatTotalCases,
      totalMeals,
      totalCases,
      palletsShipped,
      totalBoxes,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match?.params?.orderID != prevProps?.match?.params?.orderID &&
      this.props.match?.params?.orderID
    ) {
      this.loadOrder();
    }
  }

  componentDidMount() {
    if (this.props.match?.params?.orderID) {
      this.loadOrder();
    }
  }

  render() {
    return (
      <>
        <div>
          {this.state.loading || this.state.loadingOrder ? (
            <div className="p-3">
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">
                    <Spinner color="dark" size="sm"></Spinner>
                    <span className="pl-3">Generating Packing Slip</span>
                  </h3>
                </CardHeader>
                <CardBody>
                  Hang tight while we fetch your order information.
                </CardBody>
              </Card>
            </div>
          ) : null}

          {!this.state.order &&
          !this.state.loadingOrder &&
          !this.state.loading ? (
            <div className="p-3">
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">
                    <span>No Packing Slip Available</span>
                  </h3>
                </CardHeader>
                <CardBody>
                  We were unable to generate a packing slip for this order.
                  Please reload and try again.
                </CardBody>
              </Card>
            </div>
          ) : null}
          {!this.state.loading &&
          !this.state.loadingOrder &&
          this.state.order ? (
            <div
              className="label-page-container px-2 bg-white"
              style={{ overflowX: "hidden", minHeight: "100vh" }}
            >
              {/**
               * Standard Header
               */}
              <div
                style={{ borderTop: "2px solid var(--dark)" }}
                className="pb-4"
              />

              <div
                className="text-uppercase font-weight-bold"
                style={{
                  position: "absolute",
                  top: "0.5rem",
                  right: "0.5rem",
                  color: "black",
                }}
              >
                Packing Slip
              </div>
              <img
                style={{ maxWidth: 300 }}
                src={require("../../assets/img/brand/llm_logo.svg")}
                alt="LLM Logo"
              ></img>
              <Row>
                <Col xs="12" sm="6">
                  <div className="py-4">
                    <h4 style={{ color: "black" }} className="m-0">
                      Lean Life Manufacturing
                    </h4>
                    <p
                      className="m-0"
                      style={{ color: "black", fontSize: 12, lineHeight: 1.2 }}
                    >
                      900 Jefferson Rd. Suite P4
                    </p>
                    <p
                      className="mt-0 mb-1"
                      style={{ color: "black", fontSize: 12, lineHeight: 1.2 }}
                    >
                      Rochester, NY 14623
                    </p>
                    <p
                      className="m-0"
                      style={{ color: "black", fontSize: 12, lineHeight: 1.2 }}
                    >
                      Phone: (585) 622-9937
                    </p>
                    <p
                      className="m-0"
                      style={{ color: "black", fontSize: 12, lineHeight: 1.2 }}
                    >
                      Email: victoriapalmeroni@leanlifemfg.com
                    </p>
                  </div>
                </Col>
                <Col xs="12" sm="6">
                  <div
                    className="text-left text-sm-right py-4 font-weight-500"
                    style={{ color: "black", fontSize: 12 }}
                  >
                    Order #{this.state.order?._id}
                    {this.state.order?.inTransitOn ? (
                      <div className="mt-0">
                        Ship Date:{" "}
                        {moment(this.state.order?.inTransitOn).format(
                          "MMMM DD, YYYY"
                        )}
                      </div>
                    ) : null}
                  </div>
                </Col>
              </Row>

              <div
                className="rounded p-3"
                style={{ border: "1px solid black" }}
              >
                <Row>
                  <Col xs="12" sm="6">
                    <h5 className="text-uppercase" style={{ color: "black" }}>
                      Ship To
                    </h5>
                    <h5 className="mb-1" style={{ color: "black" }}>
                      {this.state.order?.store?.name}
                    </h5>
                    <p
                      className="m-0"
                      style={{ color: "black", fontSize: 12, lineHeight: 1.2 }}
                    >
                      {this.state.order?.store?.location?.address?.address_1}
                      {this.state.order?.store?.location?.address?.address_2
                        ? ` ${this.state.order?.store?.location?.address?.address_2}`
                        : ""}
                    </p>
                    <p
                      className="mt-0 mb-1"
                      style={{ color: "black", fontSize: 12, lineHeight: 1.2 }}
                    >
                      {this.state.order?.store?.location?.address?.city},{" "}
                      {this.state.order?.store?.location?.address?.state}{" "}
                      {this.state.order?.store?.location?.address?.zip}
                    </p>
                    <p
                      className="m-0"
                      style={{ color: "black", fontSize: 12, lineHeight: 1.2 }}
                    >
                      Phone:{" "}
                      {StringUtils.formatPhoneNumber(
                        this.state.order?.store?.twilioNumber
                          ? this.state.order?.store?.twilioNumber
                          : this.state.order?.store?.contact?.phone
                      )}
                    </p>
                  </Col>
                  <Col xs="12" sm="6" className="mt-3 mt-sm-0">
                    <h5 className="text-uppercase" style={{ color: "black" }}>
                      Bill To
                    </h5>
                    <h5 className="mb-1" style={{ color: "black" }}>
                      {this.state.order?.store?.name}
                    </h5>
                    <p
                      className="m-0"
                      style={{ color: "black", fontSize: 12, lineHeight: 1.2 }}
                    >
                      {this.state.order?.store?.location?.address?.address_1}
                      {this.state.order?.store?.location?.address?.address_2
                        ? ` ${this.state.order?.store?.location?.address?.address_2}`
                        : ""}
                    </p>
                    <p
                      className="mt-0 mb-1"
                      style={{ color: "black", fontSize: 12, lineHeight: 1.2 }}
                    >
                      {this.state.order?.store?.location?.address?.city},{" "}
                      {this.state.order?.store?.location?.address?.state}{" "}
                      {this.state.order?.store?.location?.address?.zip}
                    </p>
                    <p
                      className="m-0"
                      style={{ color: "black", fontSize: 12, lineHeight: 1.2 }}
                    >
                      Phone:{" "}
                      {StringUtils.formatPhoneNumber(
                        this.state.order?.store?.twilioNumber
                          ? this.state.order?.store?.twilioNumber
                          : this.state.order?.store?.contact?.phone
                      )}
                    </p>
                  </Col>
                </Row>
              </div>
              <div className="py-3">
                <div
                  className="rounded"
                  style={{ border: "1px solid black", overflow: "hidden" }}
                >
                  <Table
                    className="table-bordered rounded m-0"
                    style={{ overflow: "hidden" }}
                  >
                    <thead>
                      <tr>
                        <td
                          className="align-middle text-center py-1 bg-light text-uppercase font-weight-bold"
                          style={{
                            borderColor: "black",
                            fontSize: 14,
                            width: "33%",
                            color: "black",
                            borderBottomWidth: 1,
                          }}
                        >
                          Order Date
                        </td>
                        <td
                          className="align-middle text-center py-1 bg-light text-uppercase font-weight-bold"
                          style={{
                            borderColor: "black",
                            fontSize: 14,
                            width: "33%",
                            color: "black",
                            borderBottomWidth: 1,
                          }}
                        ></td>
                        <td
                          className="align-middle text-center py-1 bg-light text-uppercase font-weight-bold"
                          style={{
                            borderColor: "black",
                            fontSize: 14,
                            width: "33%",
                            color: "black",
                            borderBottomWidth: 1,
                          }}
                        >
                          Scheduled Delivery
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          className="align-middle text-center py-1"
                          style={{ color: "black", borderColor: "black" }}
                        >
                          {moment(this.state.order?.submittedOn)?.format(
                            "MM/DD/YYYY"
                          )}
                        </td>
                        <td
                          style={{ color: "black", borderColor: "black" }}
                        ></td>
                        <td
                          className="align-middle text-center py-1"
                          style={{ color: "black", borderColor: "black" }}
                        >
                          {this.state?.order?.deliveryDate
                            ? moment(this.state.order?.deliveryDate)?.format(
                                "MM/DD/YYYY"
                              )
                            : "--"}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>

              {/**
               * Slip Body
               */}
              {this.state.order?.fulfillmentReportID ? (
                <>
                  <div
                    className="rounded"
                    style={{ border: "1px solid black", overflow: "hidden" }}
                  >
                    <Table
                      className="table-bordered rounded m-0"
                      style={{ overflow: "hidden" }}
                    >
                      <thead>
                        <tr>
                          <td
                            className="align-middle px-2 text-left py-1 bg-light text-uppercase font-weight-bold"
                            style={{
                              borderColor: "black",
                              fontSize: 14,

                              color: "black",
                              borderBottomWidth: 1,
                            }}
                          >
                            Plan
                          </td>
                          <td
                            className="align-middle px-2 text-center py-1 bg-light text-uppercase font-weight-bold"
                            style={{
                              borderColor: "black",
                              fontSize: 14,
                              width: 250,
                              color: "black",
                              borderBottomWidth: 1,
                            }}
                          >
                            Member Boxes
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.plans?.map((plan, i) => (
                          <tr key={i}>
                            <td
                              className="align-middle px-2 py-1"
                              style={{ color: "black", borderColor: "black" }}
                            >
                              {plan?.name}
                            </td>

                            <td
                              className="align-middle text-center px-2 py-1"
                              style={{ color: "black", borderColor: "black" }}
                            >
                              {StringUtils.numberFormat(plan?.quantity)}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td
                            className="align-middle px-2 py-3"
                            style={{ color: "black", borderColor: "black" }}
                            colspan={2}
                          ></td>
                        </tr>
                        <tr>
                          <td
                            className="align-middle text-uppercase px-2 py-1 text-right font-weight-bold"
                            style={{ color: "black", borderColor: "black" }}
                          >
                            Total Member Boxes
                          </td>

                          <td
                            className="align-middle text-center px-2 py-1 font-weight-bold"
                            style={{ color: "black", borderColor: "black" }}
                          >
                            {StringUtils.numberFormat(this.state.totalQuantity)}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="rounded"
                    style={{ border: "1px solid black", overflow: "hidden" }}
                  >
                    <Table
                      className="table-bordered rounded m-0"
                      style={{ overflow: "hidden" }}
                    >
                      <thead>
                        <tr>
                          <td
                            className="align-middle text-left px-2 py-1 bg-light text-uppercase font-weight-bold"
                            style={{
                              borderColor: "black",
                              fontSize: 14,
                              width: 200,
                              color: "black",
                              borderBottomWidth: 1,
                            }}
                          >
                            Item #
                          </td>
                          <td
                            className="align-middle text-left px-2 py-1 bg-light text-uppercase font-weight-bold"
                            style={{
                              borderColor: "black",
                              fontSize: 14,

                              color: "black",
                              borderBottomWidth: 1,
                            }}
                          >
                            Description
                          </td>
                          <td
                            className="align-middle text-center px-2 py-1 bg-light text-uppercase font-weight-bold"
                            style={{
                              borderColor: "black",
                              fontSize: 14,
                              width: 150,
                              color: "black",
                              borderBottomWidth: 1,
                            }}
                          >
                            Case Quantity
                          </td>
                          <td
                            className="align-middle text-center px-2 py-1 bg-light text-uppercase font-weight-bold"
                            style={{
                              borderColor: "black",
                              fontSize: 14,
                              width: 150,
                              color: "black",
                              borderBottomWidth: 1,
                            }}
                          >
                            Meal Quantity
                          </td>
                        </tr>
                      </thead>

                      <tbody>
                        {this.state.lifestyleMeals?.map((meal, i) => (
                          <tr key={i}>
                            <td
                              className="align-middle px-2 py-1 text-uppercase"
                              style={{
                                color: "black",
                                borderColor: "black",
                                fontSize: 18,
                              }}
                            >
                              {i == 0 && "Lifestyle"}
                            </td>
                            <td
                              className="align-middle px-2 py-1"
                              style={{
                                color: "black",
                                borderColor: "black",
                                fontSize: 18,
                              }}
                            >
                              {meal?.sku}
                            </td>
                            <td
                              className="align-middle text-center py-1 px-2"
                              style={{
                                color: "black",
                                borderColor: "black",
                                fontSize: 18,
                              }}
                            >
                              {`${StringUtils.numberFormat(
                                meal?.caseQuantity
                              )}`}
                            </td>
                            <td
                              className="align-middle text-center py-1 px-2"
                              style={{
                                color: "black",
                                borderColor: "black",
                                fontSize: 18,
                              }}
                            >
                              {`${StringUtils.numberFormat(
                                meal?.mealQuantity
                              )}`}
                            </td>
                          </tr>
                        ))}

                        <tr>
                          <td
                            className="align-middle bg-superlight text-uppercase px-2 py-1 text-right font-weight-bold"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                            colspan={2}
                          >
                            Total Lifestyle
                          </td>

                          <td
                            className="align-middle bg-superlight text-center py-1 px-2 font-weight-bold"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          >
                            {StringUtils.numberFormat(
                              this.state.lifestyleTotalCases
                            )}
                          </td>
                          <td
                            className="align-middle bg-superlight text-center py-1 px-2 font-weight-bold"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          >
                            {StringUtils.numberFormat(
                              this.state.lifestyleTotalMeals
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="align-middle px-3 py-3"
                            style={{ color: "black", borderColor: "black" }}
                            colspan={4}
                          ></td>
                        </tr>

                        {this.state.athleteMeals?.map((meal, i) => (
                          <tr key={i}>
                            <td
                              className="align-middle px-2 py-1 text-uppercase"
                              style={{
                                color: "black",
                                borderColor: "black",
                                fontSize: 18,
                              }}
                            >
                              {i == 0 && "Athlete"}
                            </td>
                            <td
                              className="align-middle px-2 py-1"
                              style={{
                                color: "black",
                                borderColor: "black",
                                fontSize: 18,
                              }}
                            >
                              {meal?.sku}
                            </td>
                            <td
                              className="align-middle text-center py-1 px-2"
                              style={{
                                color: "black",
                                borderColor: "black",
                                fontSize: 18,
                              }}
                            >
                              {`${StringUtils.numberFormat(
                                meal?.caseQuantity
                              )}`}
                            </td>
                            <td
                              className="align-middle text-center py-1 px-2"
                              style={{
                                color: "black",
                                borderColor: "black",
                                fontSize: 18,
                              }}
                            >
                              {`${StringUtils.numberFormat(
                                meal?.mealQuantity
                              )}`}
                            </td>
                          </tr>
                        ))}

                        <tr>
                          <td
                            className="align-middle bg-superlight text-uppercase px-2 py-1 text-right font-weight-bold"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                            colspan={2}
                          >
                            Total Athlete
                          </td>

                          <td
                            className="align-middle bg-superlight text-center py-1 px-2 font-weight-bold"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          >
                            {StringUtils.numberFormat(
                              this.state.athleteTotalCases
                            )}
                          </td>
                          <td
                            className="align-middle bg-superlight text-center py-1 px-2 font-weight-bold"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          >
                            {StringUtils.numberFormat(
                              this.state.athleteTotalMeals
                            )}
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="align-middle px-3 py-3"
                            style={{ color: "black", borderColor: "black" }}
                            colspan={4}
                          ></td>
                        </tr>

                        {this.state.leanCheats?.map((meal, i) => (
                          <tr key={i}>
                            <td
                              className="align-middle px-2 py-1 text-uppercase"
                              style={{
                                color: "black",
                                borderColor: "black",
                                fontSize: 18,
                              }}
                            >
                              {i == 0 && "Lean Cheats"}
                            </td>
                            <td
                              className="align-middle px-2 py-1"
                              style={{
                                color: "black",
                                borderColor: "black",
                                fontSize: 18,
                              }}
                            >
                              {meal?.sku}
                            </td>
                            <td
                              className="align-middle text-center py-1 px-2"
                              style={{
                                color: "black",
                                borderColor: "black",
                                fontSize: 18,
                              }}
                            >
                              {`${StringUtils.numberFormat(
                                meal?.freightCaseQuantity
                              )}`}
                            </td>
                            <td
                              className="align-middle text-center py-1 px-2"
                              style={{
                                color: "black",
                                borderColor: "black",
                                fontSize: 18,
                              }}
                            >
                              {`${StringUtils.numberFormat(
                                meal?.mealQuantity
                              )}`}
                            </td>
                          </tr>
                        ))}

                        <tr>
                          <td
                            className="align-middle bg-superlight text-uppercase px-2 py-1 text-right font-weight-bold"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                            colspan={2}
                          >
                            Total Lean Cheats
                          </td>

                          <td
                            className="align-middle font-weight-bold bg-superlight text-center py-1 px-2"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          >
                            {StringUtils.numberFormat(
                              this.state.leanCheatTotalCases
                            )}
                          </td>
                          <td
                            className="align-middle font-weight-bold bg-superlight text-center py-1 px-2"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          >
                            {StringUtils.numberFormat(
                              this.state.leanCheatTotalMeals
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="align-middle px-3 py-3"
                            style={{ color: "black", borderColor: "black" }}
                            colspan={4}
                          ></td>
                        </tr>
                        <tr>
                          <td
                            className="align-middle text-left px-2 py-1 bg-light text-uppercase font-weight-bold"
                            style={{
                              borderColor: "black",
                              fontSize: 14,
                              width: 200,
                              color: "black",
                              borderBottomWidth: 1,
                            }}
                          >
                            Item #
                          </td>
                          <td
                            className="align-middle text-left px-2 py-1 bg-light text-uppercase font-weight-bold"
                            style={{
                              borderColor: "black",
                              fontSize: 14,

                              color: "black",
                              borderBottomWidth: 1,
                            }}
                          >
                            Description
                          </td>
                          <td
                            className="align-middle text-center px-2 py-1 bg-light text-uppercase font-weight-bold"
                            style={{
                              borderColor: "black",
                              fontSize: 14,
                              width: 150,
                              color: "black",
                              borderBottomWidth: 1,
                            }}
                          >
                            Bundle Quantity
                          </td>
                          <td
                            className="align-middle text-center px-2 py-1 bg-light text-uppercase font-weight-bold"
                            style={{
                              borderColor: "black",
                              fontSize: 14,
                              width: 150,
                              color: "black",
                              borderBottomWidth: 1,
                            }}
                          >
                            Box Quantity
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="align-middle px-2 py-1 text-uppercase"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          >
                            Extra Boxes
                          </td>
                          <td
                            className="align-middle px-2 py-1"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          >
                            Small Box (Size 12)
                          </td>
                          <td
                            className="align-middle text-center py-1 px-2"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          >
                            {`${StringUtils.numberFormat(
                              this.state.order?.boxes?.hasOwnProperty(
                                "Small Box"
                              ) &&
                                this.state.order?.boxes["Small Box"]
                                  ?.caseQuantity
                                ? this.state.order?.boxes["Small Box"]
                                    ?.caseQuantity
                                : 0
                            )}`}
                          </td>
                          <td
                            className="align-middle text-center py-1 px-2"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          >
                            {`${StringUtils.numberFormat(
                              this.state.order?.boxes?.hasOwnProperty(
                                "Small Box"
                              ) &&
                                this.state.order?.boxes["Small Box"]
                                  ?.boxQuantity
                                ? this.state.order?.boxes["Small Box"]
                                    ?.boxQuantity
                                : 0
                            )}`}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="align-middle px-2 py-1 text-uppercase"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          ></td>
                          <td
                            className="align-middle px-2 py-1"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          >
                            Medium Box (Size 18)
                          </td>
                          <td
                            className="align-middle text-center py-1 px-2"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          >
                            {`${StringUtils.numberFormat(
                              this.state.order?.boxes?.hasOwnProperty(
                                "Medium Box"
                              ) &&
                                this.state.order?.boxes["Medium Box"]
                                  ?.caseQuantity
                                ? this.state.order?.boxes["Medium Box"]
                                    ?.caseQuantity
                                : 0
                            )}`}
                          </td>
                          <td
                            className="align-middle text-center py-1 px-2"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          >
                            {`${StringUtils.numberFormat(
                              this.state.order?.boxes?.hasOwnProperty(
                                "Medium Box"
                              ) &&
                                this.state.order?.boxes["Medium Box"]
                                  ?.boxQuantity
                                ? this.state.order?.boxes["Medium Box"]
                                    ?.boxQuantity
                                : 0
                            )}`}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="align-middle px-2 py-1 text-uppercase"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          ></td>
                          <td
                            className="align-middle px-2 py-1"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          >
                            Large Box (Size 24)
                          </td>
                          <td
                            className="align-middle text-center py-1 px-2"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          >
                            {`${StringUtils.numberFormat(
                              this.state.order?.boxes?.hasOwnProperty(
                                "Large Box"
                              ) &&
                                this.state.order?.boxes["Large Box"]
                                  ?.caseQuantity
                                ? this.state.order?.boxes["Large Box"]
                                    ?.caseQuantity
                                : 0
                            )}`}
                          </td>
                          <td
                            className="align-middle text-center py-1 px-2"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          >
                            {`${StringUtils.numberFormat(
                              this.state.order?.boxes?.hasOwnProperty(
                                "Large Box"
                              ) &&
                                this.state.order?.boxes["Large Box"]
                                  ?.boxQuantity
                                ? this.state.order?.boxes["Large Box"]
                                    ?.boxQuantity
                                : 0
                            )}`}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="align-middle px-2 py-1 text-uppercase"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          ></td>
                          <td
                            className="align-middle px-2 py-1"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          >
                            XL Box (Size 30)
                          </td>
                          <td
                            className="align-middle text-center py-1 px-2"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          >
                            {`${StringUtils.numberFormat(
                              this.state.order?.boxes?.hasOwnProperty(
                                "XL Box"
                              ) &&
                                this.state.order?.boxes["XL Box"]?.caseQuantity
                                ? this.state.order?.boxes["XL Box"]
                                    ?.caseQuantity
                                : 0
                            )}`}
                          </td>
                          <td
                            className="align-middle text-center py-1 px-2"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          >
                            {`${StringUtils.numberFormat(
                              this.state.order?.boxes?.hasOwnProperty(
                                "XL Box"
                              ) &&
                                this.state.order?.boxes["XL Box"]?.boxQuantity
                                ? this.state.order?.boxes["XL Box"]?.boxQuantity
                                : 0
                            )}`}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="align-middle bg-superlight text-uppercase px-2 py-1 text-right font-weight-bold"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                            colspan={2}
                          >
                            Total Extra Boxes
                          </td>

                          <td
                            className="align-middle font-weight-bold bg-superlight text-center py-1 px-2"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          >
                            {StringUtils.numberFormat(
                              (this.state.order?.boxes["Small Box"]
                                ?.caseQuantity ?? 0) +
                                (this.state.order?.boxes["Medium Box"]
                                  ?.caseQuantity ?? 0) +
                                (this.state.order?.boxes["Large Box"]
                                  ?.caseQuantity ?? 0)
                            )}
                          </td>
                          <td
                            className="align-middle font-weight-bold bg-superlight text-center py-1 px-2"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          >
                            {StringUtils.numberFormat(
                              (this.state.order?.boxes["Small Box"]
                                ?.boxQuantity ?? 0) +
                                (this.state.order?.boxes["Medium Box"]
                                  ?.boxQuantity ?? 0) +
                                (this.state.order?.boxes["Large Box"]
                                  ?.boxQuantity ?? 0)
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="align-middle px-3 py-3"
                            style={{ color: "black", borderColor: "black" }}
                            colspan={4}
                          ></td>
                        </tr>
                        <tr>
                          <td
                            className="align-middle bg-superlight text-uppercase px-2 py-1 text-right font-weight-bold"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                            colspan={2}
                          >
                            Total Lifestyle, Athlete, Lean Cheats
                          </td>

                          <td
                            className="align-middle font-weight-bold bg-superlight text-center py-1 px-2"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          >
                            {StringUtils.numberFormat(this.state.totalCases)}
                          </td>
                          <td
                            className="align-middle font-weight-bold bg-superlight text-center py-1 px-2"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          >
                            {StringUtils.numberFormat(this.state.totalMeals)}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="align-middle bg-superlight text-uppercase px-2 py-1 text-right font-weight-bold"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                            colspan={2}
                          >
                            Total Boxes (For Freight)
                          </td>

                          <td
                            className="align-middle font-weight-bold bg-superlight text-center py-1 px-2"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          >
                            {StringUtils.numberFormat(this.state.totalBoxes)}
                          </td>
                          <td
                            className="align-middle font-weight-bold bg-superlight text-center py-1 px-2"
                            style={{ color: "black", borderColor: "black" }}
                          ></td>
                        </tr>
                        <tr>
                          <td
                            className="align-middle bg-superlight text-uppercase px-2 py-1 text-right font-weight-bold"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                            colspan={2}
                          >
                            Total Pallets
                          </td>

                          <td
                            className="align-middle font-weight-bold bg-superlight text-center py-1 px-2"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          >
                            {StringUtils.numberFormat(
                              this.state.palletsShipped
                            )}
                          </td>
                          <td
                            className="align-middle font-weight-bold bg-superlight text-center py-1 px-2"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                            }}
                          ></td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </>
              )}

              {/**
               * Standard Footer
               */}
              <div
                className="rounded p-3 my-4"
                style={{ border: "1px solid black" }}
              >
                <Row>
                  <Col xs="12" sm="4">
                    <h5 className="text-uppercase" style={{ color: "black" }}>
                      Shipper Signature
                    </h5>
                    <div className="py-2 border-bottom border-dark">
                      <i
                        className="mdi mdi-close pl-1"
                        style={{
                          fontSize: 20,
                          position: "relative",
                          top: 5,
                          color: "black",
                        }}
                      ></i>
                    </div>
                  </Col>
                  <Col xs="12" sm="4" className="mt-3 mt-sm-0">
                    <h5 className="text-uppercase" style={{ color: "black" }}>
                      Driver Signature
                    </h5>
                    <div className="py-2 border-bottom border-dark">
                      <i
                        className="mdi mdi-close pl-1"
                        style={{
                          fontSize: 20,
                          position: "relative",
                          top: 5,
                          color: "black",
                        }}
                      ></i>
                    </div>
                  </Col>
                  <Col xs="12" sm="4" className="mt-3 mt-sm-0">
                    <h5 className="text-uppercase" style={{ color: "black" }}>
                      Receiver Signature
                    </h5>
                    <div className="py-2 border-bottom border-dark">
                      <i
                        className="mdi mdi-close pl-1"
                        style={{
                          fontSize: 20,
                          position: "relative",
                          top: 5,
                          color: "black",
                        }}
                      ></i>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="text-center pt-2 pb-4">
                <p
                  className="mt-0 mb-1"
                  style={{ fontSize: 14, color: "black" }}
                >
                  Please contact{" "}
                  <span className="font-weight-bold">
                    Customer Service at (585) 622-9937
                  </span>{" "}
                  with any questions or comments.
                </p>
                <p className="m-0" style={{ fontSize: 14, color: "black" }}>
                  Thank You For Your Business!
                </p>
              </div>
              {!this.state.order?.fulfillmentReportID ? (
                <>
                  <div className="mt-2 mb-5 mx-5 p-3 text-center bg-light rounded border border-dark">
                    <h2 className="m-0 display-3" style={{ color: "black" }}>
                      {this.state.order?.store?.name?.replace(
                        "Project LeanNation",
                        "PLN"
                      )}
                      {" - "}
                      {this.state.order?.store?.location?.address?.city},{" "}
                      {this.state.order?.store?.location?.address?.state}
                    </h2>
                  </div>
                </>
              ) : null}
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

export default withRouter(ProductOrderPackingSlipView);
