import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import Alert from "reactstrap/lib/Alert";
import CustomerOpenActivitiesWidget from "./CustomerOpenActivitiesWidget";
import CustomerUnclaimedBoxWidget from "./CustomerUnclaimedBoxWidget";

class CustomerDeliveryInformationWidget extends React.Component {
  state = {
    expand: false,
    route: "",
    zone: "",
    notes: "",
  };

  updateInformation() {
    this.setState({ submitting: true });

    let delivery = {
      route: this.state.route,
      zone: this.state.zone,
      notes: this.state.notes,
    };

    APIV2.updateCustomerDeliveryInformation(
      this.props?.match?.params?.storeID,
      this.props?.customer?._id,
      delivery
    )
      .then(
        (data) => {
          PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);

          this.setState({ openModify: false });
        },
        (e) => {
          this.setError(
            "error",
            "Could not update customer, please try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 4000);
  }

  render() {
    return (
      <>
        <Card
          className={`mb-4 ${
            this.props.flat ? "shadow-none" : "shadow"
          } border`}
        >
          <CardHeader>
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Delivery</h3>
              </div>
              {this.props.modifiable ? (
                <Col xs="auto">
                  {!this.state.openModify ? (
                    <Button
                      size="sm"
                      color="secondary"
                      outline
                      onClick={() => {
                        this.setState({
                          openModify: true,
                          zone: this?.props?.customer?.delivery?.zone,
                          route: this?.props?.customer?.delivery?.route,
                          notes: this?.props?.customer?.delivery?.notes,
                        });
                      }}
                    >
                      Edit
                    </Button>
                  ) : (
                    <>
                      <Button
                        size="sm"
                        color="secondary"
                        onClick={() => {
                          this.setState({
                            openModify: false,
                          });
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="sm"
                        color="primary"
                        disabled={this.state.submitting}
                        onClick={() => {
                          this.updateInformation();
                        }}
                      >
                        {this.state.submitting ? (
                          <Spinner color="white" size="sm" />
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  )}
                </Col>
              ) : null}
            </Row>
          </CardHeader>
          {this.props.loading ? (
            <CardBody>
              <Spinner size="sm" color="dark" className="mr-2" /> Loading...
            </CardBody>
          ) : (
            <CardBody className="p-0">
              {this.state.openModify ? (
                <>
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h5 className="text-uppercase">Route</h5>
                        <Input
                          type="text"
                          value={this.state.route}
                          name="route"
                          placeholder="Delivery Route"
                          onChange={this.handleInputChange.bind(this)}
                        ></Input>
                      </Col>
                    </Row>
                  </div>
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h5 className="text-uppercase">Zone</h5>
                        <Input
                          type="text"
                          value={this.state.zone}
                          name="zone"
                          placeholder="Delivery Zone"
                          onChange={this.handleInputChange.bind(this)}
                        ></Input>
                      </Col>
                    </Row>
                  </div>
                  <div className="px-4 py-3">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h5 className="text-uppercase">Notes</h5>

                        <Input
                          type="textarea"
                          value={this.state.notes}
                          name="notes"
                          placeholder="Delivery Notes"
                          onChange={this.handleInputChange.bind(this)}
                        ></Input>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                <>
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="auto">Route:</Col>
                      <Col xs="" className="pl-0">
                        {this.props.customer?.delivery?.route ?? "--"}
                      </Col>
                    </Row>
                  </div>
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="auto">Zone:</Col>
                      <Col xs="" className="pl-0">
                        {this.props.customer?.delivery?.zone ?? "--"}
                      </Col>
                    </Row>
                  </div>
                  <div className="px-4 py-3">
                    <Row className="align-items-center">
                      <Col xs="12" sm="auto">
                        Notes (Prints On Label):
                      </Col>
                      <Col xs="12" sm="" className="mt-3 mt-sm-0 pl-sm-0">
                        {this.props.customer?.delivery?.notes ?? "--"}
                      </Col>
                    </Row>
                  </div>
                </>
              )}
              {this.state.error ? (
                <Alert className="mt-3 mb-0" color="danger">
                  {this.state.error}
                </Alert>
              ) : null}
            </CardBody>
          )}
        </Card>
      </>
    );
  }
}

export default withRouter(CustomerDeliveryInformationWidget);
