import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  CheckPicker,
  Tooltip,
  Checkbox,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import Constant from "lib/Constant";
import LinkUtils from "../../lib/LinkUtils";
import EmojiPicker from "emoji-picker-react";
import { count } from "sms-length";
import { SegmentedMessage } from "sms-segments-calculator";
import LocalStorage from "lib/LocalStorage";
import FileUploadDrawer from "components/FileUploadDrawer";
import EditorV2 from "components/NovelEditor/ui/editor";

class MarketingAutomationDrawer extends React.Component {
  state = {
    message: "",
    name: "",
    list: "",
    scheduledSend: null,
    phone: {
      value: "",
    },
    channel: "conversations",

    results: {},
    pagination: {},
    templates: [],
    cohortOptions: [],
    excludeOptions: [],
  };

  testMessageRef = React.createRef();

  componentDidUpdate(prevProps) {
    if (
      this.props.automation != prevProps?.automation &&
      this.props.automation
    ) {
      this.setState(
        {
          name: this.props.automation?.name,
          description: this.props.automation?.description
            ? this.props.automation?.description
            : "",
          collectionName: this.props.automation?.collectionName
            ? this.props.automation?.collectionName
            : "",
          cohort: this.props.automation?.cohortID
            ? this.props.automation?.cohortID
            : "",
          excludeCohorts: this.props.automation?.excludeCohortIDs?.length
            ? this.props.automation?.excludeCohortIDs
            : [],
        },
        () => {
          let opt = _.findWhere(this.state.cohortOptions, {
            value: this.state.cohort,
          });

          if (opt) {
            let exludeOptions = _.filter(this.state.cohortOptions, (o) => {
              return (
                o.collectionName == opt?.collectionName &&
                o.value != this.state.cohort
              );
            });

            this.setState({
              excludeOptions: exludeOptions,
              collectionName: opt?.collectionName,
            });
          }
        }
      );
    }
  }

  componentDidMount() {
    if (this.props.automation) {
      this.setState({
        name: this.props.automation?.name,
        description: this.props.automation?.description
          ? this.props.automation?.description
          : "",
        collectionName: this.props.automation?.collectionName
          ? this.props.automation?.collectionName
          : "",
        cohort: this.props.automation?.cohortID
          ? this.props.automation?.cohortID
          : "",
        excludeCohorts: this.props.automation?.excludeCohortIDs?.length
          ? this.props.automation?.excludeCohortIDs
          : [],
      });
    }

    this.getCohorts();
  }

  getCohorts() {
    APIV2.getMarketingCohorts(1, 1000, {})
      .then(
        (data) => {
          let cohorts = [];

          if (data?.data?.cohorts?.length) {
            cohorts = data?.data?.cohorts;
          }

          let cohortOptions = cohorts?.map((c) => {
            let group = c?.collectionName;

            if (group == "Customers") {
              group = "Members / HFNs";
            } else if (group == "CustomerLeads") {
              group = "Leads";
            } else if (group == "RetailCustomers") {
              group = "Retail Customers";
            } else if (group == "FranchiseLeads") {
              group = "Franchise Leads";
            }

            return {
              value: c._id,
              label: (
                <>
                  <p className="text-dark m-0">{c?.name}</p>
                  <p
                    className="small text-dark m-0"
                    style={{ lineHeight: 1.2 }}
                  >
                    {c?.description}
                  </p>
                </>
              ),
              collectionName: c?.collectionName,
              group,
            };
          });

          this.setState({
            cohorts: cohorts,
            cohortOptions,
            excludeOptions: [],
          });
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the cohorts. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingCohorts: false,
        });
      });
  }

  toggleModal() {
    this.setState({
      name: "",
      description: "",
      collectionName: "",
      cohort: "",
      excludeCohorts: [],
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });

    if (name == "message") {
      if (value?.length) {
        this.setState({
          messageLength: count(value),
        });
      } else {
        this.setState({
          messageLength: null,
        });
      }
    }
  }

  submit() {
    if (this.props.automation && !this.props.duplicate) {
      this.modify();
    } else {
      this.create();
    }
  }

  create() {
    this.setState({ submitting: true });

    let payload = {
      name: this.state.name,
      description: this.state.description,
      cohortID: this.state.cohort,
      excludeCohortIDs: this.state.excludeCohorts,
      collectionName: this.state.collectionName,
      active: false,
    };

    APIV2.createMarketingAutomation(payload)
      .then(
        (data) => {
          const broadcast = data.data.automation;

          this.toggleModal();

          PubSub.publish(Event.COHORT.CREATED, broadcast);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create automation - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  modify() {
    this.setState({ submitting: true });

    let payload = {
      name: this.state.name,
      description: this.state.description,
      cohortID: this.state.cohort,
      excludeCohortIDs: this.state.excludeCohorts,
      collectionName: this.state.collectionName,
    };

    console.log(payload);

    APIV2.modifyMarketingAutomation(this.props?.automation?._id, payload)
      .then(
        (data) => {
          this.toggleModal();

          PubSub.publish(Event.COHORT.MODIFIED, data.data.automation);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to modify automation - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.automation && !this.props.duplicate
                ? "Modify Automation"
                : null}

              {!this.props.automation ? "New Automation" : null}
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={
                  this.state.submitting ||
                  !this.state.name ||
                  !this.state.cohort ||
                  !this.state.collectionName
                }
                color="primary"
                onClick={this.submit.bind(this)}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  <>
                    {this.props.automation && !this.props.duplicate
                      ? "Save"
                      : "Add"}
                  </>
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            {this.state.testSuccess ? (
              <>
                <Alert color="success">{this.state.testSuccess}</Alert>
              </>
            ) : null}
            <FormGroup>
              <h5>
                Automation Name{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <Input
                bsSize="sm"
                type="text"
                name="name"
                placeholder="Name"
                value={this.state.name}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Automation Description</h5>
              <Input
                bsSize="sm"
                type="textarea"
                rows={2}
                name="description"
                placeholder="Description"
                value={this.state.description}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>
                Target Marketing Cohort
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <p className="small" style={{ lineHeight: 1.2 }}>
                When contacts are added to this cohort, the automation will be
                started. If they are removed, the automation will be stopped.
              </p>
              <SelectPicker
                style={{ maxWidth: "90vw" }}
                searchable
                data={this.state.cohortOptions}
                value={this.state.cohort}
                cleanable={false}
                block
                groupBy="group"
                preventOverflow={true}
                placement="autoVertical"
                onChange={(v) => {
                  this.setState({ cohort: v });

                  let opt = _.findWhere(this.state.cohortOptions, {
                    value: v,
                  });

                  let exludeOptions = _.filter(
                    this.state.cohortOptions,
                    (o) => {
                      return (
                        o.collectionName == opt?.collectionName && o.value != v
                      );
                    }
                  );

                  this.setState({
                    excludeOptions: exludeOptions,
                    excludeCohorts: [],
                    collectionName: opt?.collectionName,
                  });
                }}
              ></SelectPicker>
            </FormGroup>
            <FormGroup>
              <h5>Exclude Marketing Cohorts</h5>
              <p className="small" style={{ lineHeight: 1.2 }}>
                Contacts in these cohorts will be excluded from the automation
                even if they are in the target cohort.
              </p>
              <CheckPicker
                style={{ maxWidth: "90vw" }}
                searchable
                groupBy="group"
                data={this.state.excludeOptions}
                value={this.state.excludeCohorts}
                cleanable
                block
                placement="autoVertical"
                preventOverflow={true}
                onChange={(v) => {
                  this.setState({ excludeCohorts: v });
                }}
                disabled={
                  !this.state.excludeOptions?.length || !this.state.cohort
                }
              ></CheckPicker>
            </FormGroup>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(MarketingAutomationDrawer);
