import {
  Axis,
  Chart,
  Coordinate,
  Interval,
  Tooltip,
  getTheme,
  Interaction,
} from "bizcharts";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { nodeName } from "dom-lib";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { withRouter } from "react-router";

// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Button,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap";
import CardHeader from "reactstrap/lib/CardHeader";
import Spinner from "reactstrap/lib/Spinner";
import _ from "underscore";

class PlanFrequencyChartWidget extends React.Component {
  state = {
    active: "",
    planTypesLoading: true,
    filteredResults: [],
    data: [],
    cols: {
      percent: {
        formatter: (val) => {
          val = val * 100 + "%";
          return val;
        },
      },
    },
  };

  loadSummary(reports) {
    let payload = {};

    if (!reports) {
      return;
    }

    let overallCount = 0;

    let freq = [];

    for (let i = 0; i < reports.length; i++) {
      let report = reports[i];

      for (
        let j = 0;
        j < report?.statistics?.totalOrderQtyByFrequency?.length;
        j++
      ) {
        let idx = _.findIndex(freq, {
          frequency: report?.statistics?.totalOrderQtyByFrequency[j].frequency,
        });

        if (idx < 0) {
          freq.push(_.clone(report?.statistics?.totalOrderQtyByFrequency[j]));
        } else {
          freq[idx].quantity +=
            report?.statistics?.totalOrderQtyByFrequency[j].quantity;
        }

        overallCount +=
          report?.statistics?.totalOrderQtyByFrequency[j].quantity;
      }
    }

    let data = [];

    for (let i = 0; i < freq.length; i++) {
      data.push({
        item: StringUtils.capitalizeFirstLetter(freq[i].frequency),
        count: freq[i].quantity,
        percent: freq[i].quantity / overallCount,
      });
    }

    this.setState({
      data,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.reports &&
      prevProps.reports?.length != this.props.reports?.length
    ) {
      this.loadSummary(this.props.reports);
    }
  }

  componentDidMount() {
    if (this.props.reports) {
      this.loadSummary(this.props.reports);
    }
  }

  handleFilterChange(val) {
    this.setState(
      {
        selected: val,
      },
      () => {
        this.loadSummary(this.props.reports);
      }
    );
  }

  render() {
    return (
      <>
        <Card className="border shadow">
          <CardHeader className="p-2">
            <h4 className="m-0">Frequency</h4>
          </CardHeader>
          <CardBody className="p-1">
            <Chart
              height={200}
              data={this.state.data}
              scale={this.state.cols}
              autoFit
              onIntervalClick={(e) => {
                const states = e.target.cfg.element.getStates();
              }}
            >
              <Coordinate type="theta" radius={0.75} />
              <Tooltip showTitle={false} />
              <Axis visible={false} />
              <Interval
                position="percent"
                adjust="stack"
                color="item"
                style={{
                  lineWidth: 1,
                  stroke: "#fff",
                }}
                label={[
                  "count",
                  {
                    layout: {
                      type: "limit-in-plot",
                      cfg: { action: "ellipsis" },
                    },
                    content: (data) => {
                      return `${data.item}: ${
                        Math.round(data.percent * 100 * 10) / 10
                      }%`;
                    },
                  },
                ]}
                state={{
                  selected: {
                    style: (t) => {
                      const res =
                        getTheme().geometries.interval.rect.selected.style(t);
                      return { ...res, fill: "#fb6340" };
                    },
                  },
                }}
              />
              <Interaction type="element-single-selected" />
            </Chart>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default withRouter(PlanFrequencyChartWidget);
