import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  BarChart,
  BarList,
  Card,
  DonutChart,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import APIV2 from "../../../lib/APIV2";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import {
  Axis,
  Chart,
  Coordinate,
  Interaction,
  Interval,
  Legend,
  Tooltip,
} from "bizcharts";
import ChartMetricHeader from "./ChartMetricHeader";

class SubscriptionFulfillmentBySKUPieChartCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,

    active: "all",
  };

  /**
   * Fetches the order data by week for the given stores & date range
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchOrderDataByWeek(cubejsApi, stores, dateRange) {
    return new Promise(async (resolve, reject) => {
      let endDate6 = moment(dateRange[1]);

      endDate6.startOf("day").day(6);

      let now = moment();

      if (endDate6.isAfter(now)) {
        endDate6.subtract(1, "week");
      }

      let allMeals = await APIV2.getMeals(true);

      allMeals = allMeals?.data?.meals;

      // Load
      cubejsApi
        .load({
          measures: [
            "SubscriptionFulfillmentStatisticsTotalQtyByMeal.statisticsTotalbomqtybymealQuantity",
          ],
          order: {
            "SubscriptionFulfillmentStatisticsTotalQtyByMeal.statisticsTotalbomqtybymealQuantity":
              "asc",
          },

          timeDimensions: [
            {
              dimension: "SubscriptionFulfillmentReports.fulfillmentdate",
              dateRange: [moment(dateRange[0]).toDate(), endDate6?.toDate()],
            },
          ],
          filters: [
            {
              member: "SubscriptionFulfillmentReports.storeid",
              operator: "equals",
              values: stores,
            },
            {
              member: "SubscriptionFulfillmentReports.orderCount",
              operator: "notEquals",
              values: ["0"],
            },
          ],
          dimensions: [
            "SubscriptionFulfillmentStatisticsTotalQtyByMeal.mealSku",
            "SubscriptionFulfillmentStatisticsTotalQtyByMeal.mealName",
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          if (!data?.length) {
            return resolve(null);
          }

          let total = 0;

          data = data.map((item) => {
            total +=
              item[
                "SubscriptionFulfillmentStatisticsTotalQtyByMeal.statisticsTotalbomqtybymealQuantity"
              ];

            return {
              mealSKU:
                item[
                  "SubscriptionFulfillmentStatisticsTotalQtyByMeal.mealSku"
                ]?.trim(),
              mealName:
                item[
                  "SubscriptionFulfillmentStatisticsTotalQtyByMeal.mealName"
                ]?.trim(),

              count:
                item[
                  "SubscriptionFulfillmentStatisticsTotalQtyByMeal.statisticsTotalbomqtybymealQuantity"
                ],
            };
          });

          let outByStore2 = data.map((item) => {
            return {
              mealSKU: item?.mealSKU,
              mealName: item?.mealName,
              Meals: item?.count,
            };
          });

          outByStore2 = _.sortBy(outByStore2, "Meals");

          outByStore2.reverse();

          console.log(outByStore2);

          return resolve({
            bySKU: outByStore2,
            total,
            allMeals,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async loadDetailedReport(stores, dateRange) {
    this.setState({
      loading: true,
      dataAvailable: false,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!stores?.length || !dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchOrderDataByWeek(
        cubejsApi,
        stores,
        dateRange
      );
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load meal quantities.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });

      return;
    }

    let colors = StringUtils.randomChartColorSet(currentCount.bySKU?.length);

    console.log(currentCount?.bySKU);

    for (let i = 0; i < colors?.length; i++) {
      currentCount.bySKU[i].color = colors[i];
    }

    this.setState({
      currentCount: currentCount,
      loading: false,
      bySKU: currentCount?.bySKU,
      total: currentCount?.total,
      allMeals: currentCount?.allMeals,
    });

    if (typeof this.props.onLoaded == "function") {
      this.props.onLoaded();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stores != prevProps?.stores ||
      this.props.dateRange != prevProps?.dateRange
    ) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload != prevProps.reload && this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  filter(data, key) {
    if (key == "all") {
      return data;
    }

    if (!this.state.allMeals?.length) {
      return [];
    }

    let lifestyle = _.filter(this.state.allMeals, (item) => {
      return (
        item?.planType?.name?.includes("Lifestyle") &&
        !_.findWhere(item?.categories, { name: "Lean Cheats" })
      );
    });

    let athlete = _.filter(this.state.allMeals, (item) => {
      return (
        item?.planType?.name?.includes("Athlete") &&
        !_.findWhere(item?.categories, { name: "Lean Cheats" })
      );
    });

    let leanCheats = _.filter(this.state.allMeals, (item) => {
      return (
        item?.planType?.name?.includes("Lifestyle") &&
        _.findWhere(item?.categories, { name: "Lean Cheats" })
      );
    });

    if (key == "lifestyle") {
      return _.filter(data, (item) => {
        return _.findWhere(lifestyle, { sku: item?.mealSKU });
      });
    }

    if (key == "athlete") {
      return _.filter(data, (item) => {
        return _.findWhere(athlete, { sku: item?.mealSKU });
      });
    }

    if (key == "leanCheat") {
      return _.filter(data, (item) => {
        return _.findWhere(leanCheats, { sku: item?.mealSKU });
      });
    }

    return [];
  }

  componentDidMount() {
    if (this.props.store && this.props.dateRange) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  render() {
    return (
      <>
        <Card marginTop="mt-0">
          <Row className="align-items-center">
            <Col xs="">
              <h3 className="m-0 text-dark">Meal Fulfillment By SKU</h3>
            </Col>
            <Col xs="auto">
              <Button
                size="sm"
                outline
                color="dark"
                className="border-0 btn-icon-only"
                disabled={this.state.loading}
                onClick={() => {
                  this.loadDetailedReport(
                    this.props.stores,
                    this.props.dateRange
                  );
                }}
              >
                {this.state.loading ? (
                  <Spinner size="sm"></Spinner>
                ) : (
                  <i className="mdi mdi-refresh"></i>
                )}
              </Button>
            </Col>
          </Row>
          <TabList
            color="orange"
            defaultValue="all"
            handleSelect={(value) => {
              this.setState({
                active: value,
              });
            }}
            marginTop="mt-3"
            disabled={this.state.loading || !this.state.dataAvailable}
          >
            <Tab value="all" text="All SKUs" />
            <Tab value="lifestyle" text="Lifestyle SKUs" />
            <Tab value="athlete" text="Athlete SKUs" />
            <Tab value="leanCheat" text="Lean Cheat SKUs" />
          </TabList>

          {this.state.loading ? (
            <div
              className="skeleton mt-4"
              style={{ height: "calc(320px + 1rem)", width: "100%" }}
            >
              &nbsp;
            </div>
          ) : (
            <>
              {this.state.dataAvailable ? (
                <>
                  <div className="mt-4 mx--4 px-4 border-top">
                    <Row className="align-items-center">
                      <Col xs="12" sm="12" md="6">
                        <div className="pt-4">
                          <DonutChart
                            data={this.filter(
                              this.state.bySKU,
                              this.state.active
                            )}
                            category={"Meals"}
                            dataKey="mealSKU"
                            colors={this.filter(
                              this.state.bySKU,
                              this.state.active
                            ).map((item) => {
                              return item?.color;
                            })}
                            valueFormatter={(number) => {
                              return (
                                StringUtils.numberFormat(number) + " Meals"
                              );
                            }}
                            height="h-80"
                          />
                        </div>
                      </Col>
                      <Col md="6" className="mt-3 mt-md-0">
                        <div
                          className="pt-4 mb--4 pb-4"
                          style={{
                            maxHeight: 368,
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          <BarList
                            data={this.filter(
                              this.state.bySKU,
                              this.state.active
                            )?.map((item) => {
                              return {
                                name: item?.mealSKU,
                                value: item?.Meals,
                              };
                            })}
                            valueFormatter={(number) => {
                              return (
                                StringUtils.numberFormat(number) +
                                " Meals " +
                                `[${
                                  this.state.total
                                    ? (
                                        (number / this.state.total) *
                                        100
                                      ).toFixed(1)
                                    : 0
                                }%]`
                              );
                            }}
                            color="orange"
                            showAnimation={true}
                            marginTop="mt-0"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "calc(320px + 1rem)", width: "100%" }}
                >
                  <p className="m-0">
                    No data available.
                    {this.state.error ? ` ${this.state.error}` : null}
                  </p>
                </div>
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default SubscriptionFulfillmentBySKUPieChartCard;
