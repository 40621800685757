import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Input,
  FormGroup,
  Spinner,
  Alert,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import CreateStoreDrawer from "components/Drawers/CreateStoreDrawer";
import LocalStorage from "../../lib/LocalStorage";
import Constant from "../../lib/Constant";
import StringUtils from "../../lib/StringUtils";
import NumberFormat from "react-number-format";
import swal from "sweetalert";
import User from "../../lib/Model/User";

class AccountInformationView extends React.Component {
  state = {
    stores: [],
    loading: true,
    editPhone: {
      value: "",
    },
  };

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  saveInformation() {
    this.setState({
      submitting: true,
    });

    let phone = this.state.editPhone?.value;

    APIV2.modifyAccountInformation(
      this.state.editName,
      this.state.editEmail,
      phone
    )
      .then(
        (data) => {
          this.setState({
            user: data.data.user,
            editInformation: false,
          });

          LocalStorage.set(Constant.CACHE.USER, data.data.user);
          window[Constant.CACHE.USER] = User._fromData(data.data.user);

          PubSub.publish(Event.USER_LOADED, data.data.user);
        },
        (e) => {
          this.setError(
            "informationError",
            "Unable to update information, please try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  updatePassword() {
    this.setState({
      passwordSubmitting: true,
    });

    APIV2.modifyAccountPassword(this.state.password, this.state.newPassword)
      .then(
        (data) => {
          swal({
            title: "Password Updated",
            text: "Your password has been successfully updated",
            icon: "success",
          });

          this.setState({
            password: "",
            rePassword: "",
            newPassword: "",
          });
        },
        (e) => {
          this.setError(
            "passwordError",
            "Unable to update password, please try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  componentDidMount() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    console.log(user);

    this.setState({
      user,
    });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  handlePhoneChange(v) {
    this.setState({
      editPhone: v,
    });
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeader title="Account Information" showBackButton={false} />
          {/* Table */}
          <Row>
            <Col xs="12">
              <Card className="shadow border">
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="">
                      <h3 className="m-0 text-dark">Contact Information</h3>
                      <p className="small mb-0">
                        Manage the contact information for your Storefront
                        account.
                      </p>
                    </Col>
                    <Col xs="auto">
                      {this.state.editInformation ? (
                        <>
                          <Button
                            onClick={() => {
                              this.setState({
                                editInformation: false,
                              });
                            }}
                            size="sm"
                            color="dark"
                            outline
                          >
                            Cancel
                          </Button>
                          <Button
                            disabled={
                              !this.state.editName ||
                              !this.state.editEmail ||
                              !this.state.editPhone?.value ||
                              this.state.submitting
                            }
                            size="sm"
                            color="primary"
                            onClick={() => {
                              this.saveInformation();
                            }}
                          >
                            {this.state.submitting ? (
                              <>
                                <Spinner size="sm"></Spinner>
                              </>
                            ) : (
                              <>Save</>
                            )}
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            size="sm"
                            color="dark"
                            onClick={() => {
                              this.setState({
                                editInformation: true,
                                editName: this.state.user?.name,
                                editPhone: {
                                  value: this.state.user?.phone
                                    ? this.state.user.phone?.replace("+1", "")
                                    : "",
                                },
                                editEmail: this.state.user?.email,
                              });
                            }}
                            outline
                          >
                            Modify
                          </Button>
                        </>
                      )}
                    </Col>
                    {this.state.informationError ? (
                      <>
                        <Col xs="12">
                          <Alert className="m-0 mt-3" color="warning">
                            {this.state.informationError}
                          </Alert>
                        </Col>
                      </>
                    ) : null}
                  </Row>
                </CardHeader>
                <CardBody>
                  {this.state.editInformation ? (
                    <>
                      <FormGroup>
                        <h4 className="mb-2">Name</h4>
                        <Input
                          type="text"
                          name="editName"
                          onChange={this.handleInputChange.bind(this)}
                          placeholder="Name"
                          value={this.state.editName}
                        ></Input>
                      </FormGroup>
                      <FormGroup>
                        <h4 className="mb-2">Email</h4>
                        <Input
                          type="email"
                          name="editEmail"
                          onChange={this.handleInputChange.bind(this)}
                          placeholder="Email"
                          value={this.state.editEmail}
                        ></Input>
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <h4 className="mb-2">Phone</h4>
                        <NumberFormat
                          className="form-control mb-3"
                          format="(###) ###-####"
                          name="phoneNumberInput"
                          placeholder="Phone Number (Optional)"
                          onValueChange={this.handlePhoneChange.bind(this)}
                          value={this.state.editPhone.value}
                          type="tel"
                        />
                      </FormGroup>
                    </>
                  ) : (
                    <>
                      <h4 className="mb-1">Name</h4>
                      <p>{this.state.user?.name}</p>
                      <h4 className="mb-1">Email</h4>
                      <p>{this.state.user?.email}</p>
                      <h4 className="mb-1">Phone</h4>
                      <p className="mb-0">
                        {this.state.user?.phone
                          ? StringUtils.formatPhoneNumber(
                              this.state.user?.phone
                            )
                          : "--"}
                      </p>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" className="mt-4">
              <Card className="shadow border">
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="">
                      <h3 className="m-0 text-dark">Change Password</h3>
                      <p className="small mb-0">
                        Change the password for your Storefront account.
                      </p>
                    </Col>
                    <Col xs="auto">
                      <Button
                        disabled={
                          !this.state.password ||
                          !this.state.newPassword ||
                          this.state.rePassword != this.state.newPassword ||
                          this.state.newPassword?.length < 8 ||
                          this.state.passwordSubmitting
                        }
                        size="sm"
                        color="primary"
                        onClick={() => {
                          this.updatePassword();
                        }}
                      >
                        {this.state.passwordSubmitting ? (
                          <>
                            <Spinner size="sm"></Spinner>
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </Col>
                    {this.state.passwordError ? (
                      <>
                        <Col xs="12">
                          <Alert className="m-0 mt-3" color="warning">
                            {this.state.passwordError}
                          </Alert>
                        </Col>
                      </>
                    ) : null}
                  </Row>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <h4 className="mb-2">Current Password</h4>
                    <Input
                      type="password"
                      name="password"
                      onChange={this.handleInputChange.bind(this)}
                      placeholder="Current Password"
                      value={this.state.password}
                    ></Input>
                  </FormGroup>
                  <hr className="mt-2 mb-4"></hr>
                  <FormGroup>
                    <h4 className="mb-0">New Password</h4>
                    <p className="mb-2 small">Must be 8+ characters long.</p>
                    <Input
                      type="password"
                      name="newPassword"
                      onChange={this.handleInputChange.bind(this)}
                      placeholder="New Password"
                      value={this.state.newPassword}
                    ></Input>
                  </FormGroup>
                  <FormGroup className="mb-0">
                    <h4 className="mb-2">Re-Enter New Password</h4>

                    <Input
                      type="password"
                      name="rePassword"
                      onChange={this.handleInputChange.bind(this)}
                      placeholder="Re-Enter New Password"
                      value={this.state.rePassword}
                    ></Input>
                  </FormGroup>
                  {this.state.newPassword &&
                    this.state.newPassword?.length < 8 && (
                      <>
                        <p className="text-danger small mb-0 mt-1">
                          Your new password must be 8+ characters long.
                        </p>
                      </>
                    )}
                  {this.state.newPassword &&
                    this.state.rePassword &&
                    this.state.rePassword != this.state.newPassword && (
                      <>
                        <p className="text-danger small mt-1 mb-0">
                          The passwords you entered do not match.
                        </p>
                      </>
                    )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default AccountInformationView;
