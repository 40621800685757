import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
  Badge,
  Spinner,
  Collapse,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import StoreView from "views/examples/StoreView";
import NumberFormat from "react-number-format";
import ModifyStoreModal from "./ModifyStoreModal";
import APIV2 from "lib/APIV2";
import { withRouter } from "react-router";
import {
  CheckPicker,
  DatePicker,
  Dropdown,
  Popover,
  SelectPicker,
  Toggle,
  Whisper,
} from "rsuite";

import StringUtils from "../lib/StringUtils";
import LinkUtils from "../lib/LinkUtils";
import swal from "sweetalert";
import moment from "moment";
import DateUtils from "../lib/DateUtils";
import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";
import NSOUtils from "lib/NSOUtils";
import _ from "underscore";

class StoreViewAboutTab extends React.Component {
  state = {
    open: false,
    twilioNumber: {
      value: "",
    },
    phone: {
      value: "",
    },
    retailGoal: {
      value: "",
    },
    level: "",
  };

  constructor(props) {
    super(props);

    this.menuRef = React.createRef();
  }

  loadStore() {
    const {
      match: { params },
    } = this.props;

    APIV2.getStoreByID(params.storeID)
      .then(
        (data) => {
          if (data && data.data && data.data.store) {
            this.setState({
              store: data.data.store,
              modifySquareLocation: data.data.store?.squareLocation?.id
                ? data.data.store?.squareLocation?.id
                : "",
            });
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  getSquareLocations() {
    const {
      match: { params },
    } = this.props;

    APIV2.getSquareLocations().then(
      (data) => {
        if (data && data.data && data.data.locations) {
          let locations = data.data.locations.map((l) => {
            return {
              value: l.id,
              label: l.name + " (#" + l.id + ")",
              location: l,
            };
          });

          this.setState({
            squareLocations: locations,
          });
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  getBirdChannels() {
    this.setState({
      birdLoading: true,
    });

    APIV2.getBirdSMSChannels()
      .then(
        (data) => {
          if (data?.data?.channels) {
            let locations = data.data.channels.map((l) => {
              return {
                value: l.id,
                label: `${l.name} (${StringUtils.formatPhoneNumber(
                  l.identifier
                )})`,
                data: l,
              };
            });

            this.setState({
              birdOptions: locations,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          birdLoading: false,
        });
      });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  setNSOStage() {
    this.setState({
      nsoSubmitting: true,
    });

    // TODO: implement NSO update here

    APIV2.modifyStoreParameter(
      this.state?.store?._id,
      "nsoStage",
      this.state.nsoStage
    )
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          this.setState({
            modifyStage: false,
          });
        },
        (e) => {
          window.alert(
            "Unable to modify NSO Stage: " + e?.message ?? "error occurred."
          );
        }
      )
      .finally(() => {
        this.setState({
          nsoSubmitting: false,
        });
      });
  }

  setPhone() {
    this.setState({
      phoneSubmitting: true,
    });

    let twilioNumber = "+1" + this.state.twilioNumber?.value;
    let phone = "+1" + this.state.phone?.value;

    APIV2.modifyStoreParameter(
      this.state?.store?._id,
      "twilioNumber",
      twilioNumber
    ).then(
      (data) => {
        APIV2.modifyStorePhone(this.state?.store?._id, phone)
          .then(
            (data) => {
              APIV2.modifyStoreParameter(
                this.state?.store?._id,
                "birdPhone",
                this.state.birdPhone
                  ? _.findWhere(this.state.birdOptions, {
                      value: this.state.birdPhone,
                    })?.data
                  : null
              ).then(
                (data) => {
                  APIV2.modifyStoreParameter(
                    this.state?.store?._id,
                    "birdEnabled",
                    this.state.birdEnabled ? true : false
                  ).then(
                    (data) => {
                      PubSub.publish(Event.STORE.MODIFIED, data.data.store);

                      this.setState({
                        modifyPhone: false,
                      });
                    },
                    (e) => {
                      window.alert(
                        "Unable to modify Phone: " + e?.message ??
                          "error occurred."
                      );
                    }
                  );
                },
                (e) => {
                  window.alert(
                    "Unable to modify Phone: " + e?.message ?? "error occurred."
                  );
                }
              );
            },
            (e) => {
              window.alert(
                "Unable to modify Phone: " + e?.message ?? "error occurred."
              );
            }
          )
          .finally(() => {
            this.setState({
              phoneSubmitting: false,
            });
          });
      },
      (e) => {
        window.alert(
          "Unable to modify Phone: " + e?.message ?? "error occurred."
        );
      }
    );
  }

  setReferralSource() {
    this.setState({
      referralSubmitting: true,
    });

    // TODO: implement NSO update here

    APIV2.modifyStoreParameter(
      this.state?.store?._id,
      "referralSource",
      this.state.referralSource ? this.state?.referralSource : ""
    )
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          console.log(
            JSON.stringify({ source: data.data.store?.referralSource })
          );

          this.setState({
            modifyReferral: false,
          });
        },
        (e) => {
          window.alert(
            "Unable to modify Referral Source: " + e?.message ??
              "error occurred."
          );
        }
      )
      .finally(() => {
        this.setState({
          referralSubmitting: false,
        });
      });
  }

  setDisplayCityState() {
    this.setState({
      displaySubmitting: true,
    });

    // TODO: implement NSO update here

    APIV2.modifyStoreParameter(
      this.state?.store?._id,
      "displayCity",
      this.state.displayCity
    ).then(
      (data1) => {
        APIV2.modifyStoreParameter(
          this.state.store?._id,
          "displayState",
          this.state.displayState
        )
          .then(
            (data) => {
              PubSub.publish(Event.STORE.MODIFIED, data.data.store);

              this.setState({
                modifyDisplay: false,
                store: data.data.store,
                displayCity: "",
                displayState: "",
              });
            },
            (e) => {
              window.alert(
                "Unable to modify marketing city & state: " + e?.message ??
                  "error occurred."
              );
            }
          )
          .finally(() => {
            this.setState({
              displaySubmitting: false,
            });
          });
      },
      (e) => {
        this.setState({
          displaySubmitting: false,
        });

        window.alert(
          "Unable to modify marketing city & state: " + e?.message ??
            "error occurred."
        );
      }
    );
  }

  updateSystemCheckParam(name, value) {
    swal({
      title: "Mark As Completed?",
      text: `Are you sure you want to mark this as completed?`,
      buttons: ["Nevermind", "Yes"],
      dangerMode: false,
    }).then((confirm) => {
      if (!confirm) {
        return;
      }

      APIV2.modifyStoreParameter(this.state?.store?._id, name, value).then(
        (data1) => {
          PubSub.publish(Event.STORE.MODIFIED, data1.data.store);
        },
        (e) => {
          this.setState({
            displaySubmitting: false,
          });

          window.alert(
            "Unable to update NSO system check item: " + e?.message ??
              "error occurred."
          );
        }
      );
    });
  }

  componentDidMount() {
    this.loadStore();
    this.getBirdChannels();
    this.getSquareLocations();

    PubSub.subscribe(Event.STORE.DELETED, (data) => {
      this.props.history.push("/frandev/stores");
    });

    PubSub.subscribe(Event.STORE.MODIFIED, (s) => {
      this.setState({
        store: s,
      });

      /**if (s?.franchisePartners?.length) {
        this.getPartnerImages(s?.franchisePartners);
      }*/
    });
  }

  storeHasMenus(store) {
    return NSOUtils.storeHasMenus(store);
  }

  render() {
    return (
      <>
        <>
          <Card className=" shadow">
            <CardHeader className="bg-transparent">
              <Row>
                <div className="col">
                  <h3 className=" mb-0">About</h3>
                </div>
                <div className="col-auto text-right">
                  <Button
                    onClick={() => {
                      PubSub.publish(Event.STORE.MODIFY_OPEN, this.state.store);
                    }}
                    className="btn-sm btn"
                    color="secondary"
                    outline
                  >
                    Modify
                  </Button>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <div>
                <div className="row">
                  {this.state.store && this.state.store.type == "retail" ? (
                    <>
                      <div className="col-12 col-md-6 mb-3 mb-md-0">
                        <h3 className="mb-3">Address</h3>

                        <p className="mb-0">
                          {this.state.store
                            ? this.state.store.location.address.address_1
                            : ""}
                          &nbsp;
                          {this.state.store
                            ? this.state.store.location.address.address_2
                            : ""}
                          <br></br>
                          {this.state.store
                            ? this.state.store.location.address.city
                            : ""}
                          ,&nbsp;
                          {this.state.store
                            ? this.state.store.location.address.state
                            : ""}
                          &nbsp;
                          {this.state.store
                            ? this.state.store.location.address.zip
                            : ""}
                        </p>
                      </div>
                    </>
                  ) : null}
                  <div className="col-12 col-md-6">
                    <h3 className="mb-3">Contact</h3>
                    <p className="mb-0">
                      Store Phone:{" "}
                      {this.state.store && this.state.store.twilioNumber ? (
                        <NumberFormat
                          format={"+# (###) ###-####"}
                          value={this.state.store.twilioNumber}
                          displayType={"text"}
                        ></NumberFormat>
                      ) : (
                        "(None)"
                      )}
                    </p>
                    <p className="m-0">
                      Email:{" "}
                      {this.state.store && this.state.store.contact.email
                        ? this.state.store.contact.email
                        : "(None)"}
                    </p>
                    <p className="mt-2 mb-0">
                      {this.state.store &&
                      this.state.store.contact.instagram_url ? (
                        <a
                          href={this.state.store.contact.instagram_url}
                          target="_blank"
                          className="mr-3"
                        >
                          Instagram<i className="mdi mdi-chevron-right"></i>
                        </a>
                      ) : null}
                      {this.state.store &&
                      this.state.store.contact.facebook_url ? (
                        <a
                          href={this.state.store.contact.facebook_url}
                          target="_blank"
                          className="mr-3"
                        >
                          Facebook<i className="mdi mdi-chevron-right"></i>
                        </a>
                      ) : null}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <hr></hr>
                  </div>
                </div>
                <Row>
                  <Col xs="12" md="6" className="mb-3 mb-md-0">
                    <h3 className="mb-3">Key Dates</h3>
                    <h4>
                      Website Launch Date:{" "}
                      <span className="font-weight-normal pl-2">
                        {this.state.store?.operations?.storeOpening
                          ?.websiteLaunchDate
                          ? this.state.store.operations.storeOpening
                              .websiteLaunchDate
                          : "(none set)"}
                      </span>
                    </h4>
                    <h4>
                      Prelaunch Start Date:{" "}
                      <span className="font-weight-normal pl-2">
                        {this.state.store?.operations?.storeOpening
                          ?.prelaunchStartDate
                          ? this.state.store.operations.storeOpening
                              .prelaunchStartDate
                          : "(none set)"}
                      </span>
                    </h4>
                    <h4>
                      On-Site Training Date:{" "}
                      <span className="font-weight-normal pl-2">
                        {this.state.store?.onSiteTrainingDate
                          ? moment(this.state.store.onSiteTrainingDate).format(
                              "YYYY-MM-DD"
                            )
                          : "(none set)"}
                      </span>
                    </h4>
                    <h4>
                      Soft Opening Date:{" "}
                      <span className="font-weight-normal pl-2">
                        {this.state.store?.operations?.storeOpening?.openingDate
                          ? this.state.store.operations.storeOpening.openingDate
                          : "(none set)"}
                      </span>
                    </h4>
                    <h4>
                      First Fulfillment Date:{" "}
                      <span className="font-weight-normal pl-2">
                        {this.state.store?.operations?.storeOpening
                          ?.firstBillDate
                          ? this.state.store.operations.storeOpening
                              .firstBillDate
                          : "(none set)"}
                      </span>
                    </h4>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
          <Card className="mt-3">
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="">
                  <h3 className="mb-0">NSO Stage</h3>
                  <p className="small mb-0">New Store Opening process stage.</p>
                </Col>
                <Col xs="auto">
                  {this.state.modifyStage ? (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            modifyStage: false,
                          });
                        }}
                        className="btn-sm btn"
                        color="secondary"
                        outline
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.setNSOStage();
                        }}
                        disabled={
                          this.state.nsoSubmitting || !this.state.nsoStage
                        }
                        className="btn-sm btn"
                        color="primary"
                      >
                        {this.state.nsoSubmitting ? (
                          <>
                            <Spinner size="sm"></Spinner>
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyStage: true,
                          nsoStage: this.state.store?.nsoStage ?? "",
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Modify
                    </Button>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {this.state.modifyStage ? (
                <>
                  <Input
                    type="select"
                    name="nsoStage"
                    onChange={this.handleInputChange.bind(this)}
                    value={this.state.nsoStage}
                  >
                    <option disabled value="">
                      Select A Stage
                    </option>
                    <option value="Site Selection">Site Selection</option>
                    <option value="Buildout">Buildout</option>
                    <option value="Prelaunch">Prelaunch</option>
                    <option value="Live">Live</option>
                  </Input>
                </>
              ) : (
                <>
                  <p className="m-0">
                    {this.state.store?.nsoStage ?? "(none set)"}
                  </p>
                </>
              )}
            </CardBody>
          </Card>

          <Card className="mt-3">
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="">
                  <h3 className="mb-0">Phone Numbers</h3>
                  <p className="small mb-0">Store call/SMS phone numbers</p>
                </Col>
                <Col xs="auto">
                  {this.state.modifyPhone ? (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            modifyPhone: false,
                          });
                        }}
                        className="btn-sm btn"
                        color="secondary"
                        outline
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.setPhone();
                        }}
                        disabled={
                          this.state.phoneSubmitting ||
                          !this.state.phone?.value ||
                          !this.state.twilioNumber?.value
                        }
                        className="btn-sm btn"
                        color="primary"
                      >
                        {this.state.phoneSubmitting ? (
                          <>
                            <Spinner size="sm"></Spinner>
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyPhone: true,
                          twilioNumber: {
                            value: this.state.store?.twilioNumber
                              ? this.state.store?.twilioNumber?.replace(
                                  "+1",
                                  ""
                                )
                              : "",
                          },
                          phone: {
                            value: this.state.store?.contact?.phone
                              ? this.state.store?.contact?.phone?.replace(
                                  "+1",
                                  ""
                                )
                              : "",
                          },
                          birdPhone: this.state.store?.birdPhone
                            ? this.state.store?.birdPhone?.id
                            : null,
                          birdEnabled: this.state.store?.birdEnabled
                            ? true
                            : false,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Modify
                    </Button>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <h4 className="mb-0">Store Phone Number</h4>
              <p className="text-muted" style={{ fontSize: 14 }}>
                The Twilio phone number assigned to this store that clients and
                leads will see and use to communicate with the store.
              </p>
              {this.state.modifyPhone ? (
                <>
                  <NumberFormat
                    className="form-control mb-3"
                    format="(###) ###-####"
                    name="phoneNumberInput"
                    placeholder="Phone Number (Optional)"
                    onValueChange={(v) => {
                      this.setState({
                        twilioNumber: v,
                      });
                    }}
                    value={this.state.twilioNumber.value}
                    type="tel"
                    mask="_"
                  />
                </>
              ) : (
                <>
                  <p className="m-0">
                    {this.state.store?.twilioNumber
                      ? StringUtils.formatPhoneNumber(
                          this.state.store?.twilioNumber
                        )
                      : "(none set)"}
                  </p>
                </>
              )}
            </CardBody>
            <CardBody className="border-top">
              <h4 className="mb-0">Call Redirect Phone Number</h4>
              <p className="text-muted" style={{ fontSize: 14 }}>
                When the store receives a call to the Store Phone Number, it
                will be redirected to this number.
              </p>
              {this.state.modifyPhone ? (
                <>
                  <NumberFormat
                    className="form-control mb-3"
                    format="(###) ###-####"
                    name="phoneNumberInput"
                    placeholder="Phone Number"
                    onValueChange={(v) => {
                      this.setState({
                        phone: v,
                      });
                    }}
                    value={this.state.phone.value}
                    type="tel"
                    mask="_"
                  />
                </>
              ) : (
                <>
                  <p className="m-0">
                    {this.state.store?.contact?.phone
                      ? StringUtils.formatPhoneNumber(
                          this.state.store?.contact?.phone
                        )
                      : "(none set)"}
                  </p>
                </>
              )}
            </CardBody>
            <CardBody className="border-top">
              <h4 className="mb-0">Bird.com Phone Number</h4>
              <p className="text-muted" style={{ fontSize: 14 }}>
                The Bird.com phone number assigned to this store that clients
                and leads will see and use to communicate with the store.
              </p>
              {this.state.modifyPhone ? (
                <>
                  <SelectPicker
                    block
                    data={this.state.birdOptions}
                    value={this.state.birdPhone}
                    onChange={(v) => {
                      if (!v && this.state.birdPhone) {
                        this.setState({
                          birdEnabled: false,
                        });
                      }

                      this.setState({
                        birdPhone: v,
                      });
                    }}
                    loading={this.state.birdLoading}
                    preventOverflow={true}
                    placement="auto"
                    searchable={true}
                    cleanable={true}
                  ></SelectPicker>
                </>
              ) : (
                <>
                  <p className="m-0">
                    {this.state.store?.birdPhone?.identifier
                      ? `${StringUtils.formatPhoneNumber(
                          this.state.store?.birdPhone?.identifier
                        )}`
                      : "(none set)"}
                  </p>
                  {this.state.store?.birdPhone ? (
                    <p
                      className="small text-dark m-0"
                      style={{ lineHeight: 1.2 }}
                    >
                      Bird.com SMS Channel: {this.state.store?.birdPhone?.name}
                    </p>
                  ) : null}
                </>
              )}
            </CardBody>
            <CardBody className="border-top">
              <h4 className="mb-0">Bird.com Enabled</h4>
              <p className="text-muted" style={{ fontSize: 14 }}>
                Enable Bird.com communications instead of Twilio communications.
              </p>
              {this.state.modifyPhone ? (
                <>
                  <Toggle
                    checked={this.state.birdEnabled}
                    onChange={(v) => {
                      this.setState({ birdEnabled: v });
                    }}
                  >
                    Enabled
                  </Toggle>
                </>
              ) : (
                <>
                  <p className="m-0">
                    {this.state.store?.birdEnabled ? (
                      <Badge color="success">Enabled</Badge>
                    ) : (
                      <Badge color="medium">Disabled</Badge>
                    )}
                  </p>
                </>
              )}
            </CardBody>
          </Card>

          <Card className="mt-3">
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="">
                  <h3 className="mb-0">Referral Source</h3>
                  <p className="small mb-0">
                    How did we connect with the franchise partners?
                  </p>
                </Col>
                <Col xs="auto">
                  {this.state.modifyReferral ? (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            modifyReferral: false,
                          });
                        }}
                        className="btn-sm btn"
                        color="secondary"
                        outline
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.setReferralSource();
                        }}
                        disabled={this.state.referralSubmitting}
                        className="btn-sm btn"
                        color="primary"
                      >
                        {this.state.referralSubmitting ? (
                          <>
                            <Spinner size="sm"></Spinner>
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyReferral: true,
                          referralSource:
                            this.state.store?.referralSource ?? "",
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Modify
                    </Button>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {this.state.modifyReferral ? (
                <>
                  <Input
                    type="text"
                    name="referralSource"
                    onChange={this.handleInputChange.bind(this)}
                    value={this.state.referralSource}
                    placeholder="Referral Source"
                  ></Input>
                </>
              ) : (
                <>
                  <p className="m-0">
                    {this.state.store?.referralSource
                      ? this.state.store?.referralSource
                      : "(none set)"}
                  </p>
                </>
              )}
            </CardBody>
          </Card>

          <Card className="mt-3">
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="">
                  <h3 className="mb-0">Marketing Location</h3>
                  <p className="small mb-0">
                    Override the city &amp; state used for marketing purposes.
                    By default, the retail address is used.
                  </p>
                </Col>
                <Col xs="auto">
                  {this.state.modifyDisplay ? (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            modifyDisplay: false,
                          });
                        }}
                        className="btn-sm btn"
                        color="secondary"
                        outline
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.setDisplayCityState();
                        }}
                        disabled={
                          this.state.displaySubmitting ||
                          !this.state.displayCity ||
                          !this.state.displayState
                        }
                        className="btn-sm btn"
                        color="primary"
                      >
                        {this.state.displaySubmitting ? (
                          <>
                            <Spinner size="sm"></Spinner>
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            modifyDisplay: true,
                            displayCity: this.state.store?.displayCity ?? "",
                            displayState: this.state.store?.displayState ?? "",
                          });
                        }}
                        className="btn-sm btn"
                        color="secondary"
                        outline
                      >
                        Modify
                      </Button>
                      {this.state.store?.displayCity ? (
                        <>
                          <Button
                            onClick={() => {
                              this.setState(
                                {
                                  displayCity: "",
                                  displayState: "",
                                },
                                () => {
                                  this.setDisplayCityState();
                                }
                              );
                            }}
                            className="btn-sm btn"
                            color="danger"
                            outline
                          >
                            Reset
                          </Button>
                        </>
                      ) : null}
                    </>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {this.state.modifyDisplay ? (
                <>
                  <FormGroup>
                    <h5>City</h5>
                    <Input
                      type="text"
                      placeholder="City"
                      name="displayCity"
                      value={this.state.displayCity}
                      onChange={this.handleInputChange.bind(this)}
                    ></Input>
                  </FormGroup>
                  <FormGroup className="mb-0">
                    <h5>State</h5>
                    <Input
                      type="select"
                      name="displayState"
                      value={this.state.displayState}
                      onChange={this.handleInputChange.bind(this)}
                      id="createStoreState"
                    >
                      <option value="" disabled>
                        Select A State
                      </option>
                      <option value="AL">AL - Alabama</option>
                      <option value="AK">AK - Alaska</option>
                      <option value="AZ">AZ - Arizona</option>
                      <option value="AR">AR - Arkansas</option>
                      <option value="CA">CA - California</option>
                      <option value="CO">CO - Colorado</option>
                      <option value="CT">CT - Connecticut</option>
                      <option value="DE">DE - Delaware</option>
                      <option value="DC">DC - District Of Columbia</option>
                      <option value="FL">FL - Florida</option>
                      <option value="GA">GA - Georgia</option>
                      <option value="HI">HI - Hawaii</option>
                      <option value="ID">ID - Idaho</option>
                      <option value="IL">IL - Illinois</option>
                      <option value="IN">IN - Indiana</option>
                      <option value="IA">IA - Iowa</option>
                      <option value="KS">KS - Kansas</option>
                      <option value="KY">KY - Kentucky</option>
                      <option value="LA">LA - Louisiana</option>
                      <option value="ME">ME - Maine</option>
                      <option value="MD">MD - Maryland</option>
                      <option value="MA">MA - Massachusetts</option>
                      <option value="MI">MI - Michigan</option>
                      <option value="MN">MN - Minnesota</option>
                      <option value="MS">MS - Mississippi</option>
                      <option value="MO">MO - Missouri</option>
                      <option value="MT">MT - Montana</option>
                      <option value="NE">NE - Nebraska</option>
                      <option value="NV">NV - Nevada</option>
                      <option value="NH">NH - New Hampshire</option>
                      <option value="NJ">NJ - New Jersey</option>
                      <option value="NM">NM - New Mexico</option>
                      <option value="NY">NY - New York</option>
                      <option value="NC">NC - North Carolina</option>
                      <option value="ND">ND - North Dakota</option>
                      <option value="OH">OH - Ohio</option>
                      <option value="OK">OK - Oklahoma</option>
                      <option value="OR">OR - Oregon</option>
                      <option value="PA">PA - Pennsylvania</option>
                      <option value="RI">RI - Rhode Island</option>
                      <option value="SC">CS - South Carolina</option>
                      <option value="SD">SD - South Dakota</option>
                      <option value="TN">TN - Tennessee</option>
                      <option value="TX">TX - Texas</option>
                      <option value="UT">UT - Utah</option>
                      <option value="VT">VT - Vermont</option>
                      <option value="VA">VA - Virginia</option>
                      <option value="WA">WA - Washington</option>
                      <option value="WV">WV - West Virginia</option>
                      <option value="WI">WI - Wisconsin</option>
                      <option value="WY">WY - Wyoming</option>
                    </Input>
                  </FormGroup>
                </>
              ) : (
                <>
                  <p className="m-0">
                    {this.state.store?.displayCity &&
                    this.state.store?.displayState ? (
                      <>
                        <p className="m-0">
                          {this.state.store?.displayCity},{" "}
                          {this.state.store?.displayState}
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="m-0">
                          {this.state.store?.location?.address?.city},{" "}
                          {this.state.store?.location?.address?.state}{" "}
                          {this.state.store?.location?.address?.zip}
                        </p>
                      </>
                    )}
                  </p>
                </>
              )}
            </CardBody>
          </Card>

          <ModifyStoreModal></ModifyStoreModal>
        </>
      </>
    );
  }
}

export default withRouter(StoreViewAboutTab);
