import PubSub from "./PubSub";
import Event from "./Event";
import axios from "axios";

export default class API {
  static ENV_STAGING = "https://api.projectleannation.com";
  static ENV_PRODUCTION = "https://api.projectleannation.com";
  static ENV_DEV = "http://localhost:9090";

  /**
   * Sets the environment
   *
   * @param {*} env
   */
  static setEnvironment(env) {
    this.ENV_CURRENT = env;

    PubSub.publish(Event.ENV_CHANGED);
  }

  /**
   * Gets the current auth token.
   */
  static getAuthToken() {
    return this.ENV_USER_TOKEN;
  }

  /**
   * Sets the user's auth token.
   *
   * @param {*} val
   */
  static setAuthToken(val) {
    this.ENV_USER_TOKEN = val;
  }

  /**
   * Sends a generic request.
   *
   * @param {*} options
   */
  static _sendRequest(options) {
    if (this.ENV_CURRENT) {
      return axios(options);
    } else {
      return new Promise((resolve, reject) => {
        PubSub.subscribe(Event.ENV_CHANGED, () => {
          options.uri = this.ENV_CURRENT + options.uri;

          axios(options).then(
            (data) => {
              if (data) {
                resolve(data);
              }
            },
            (error) => {
              reject(error);
            }
          );
        });
      });
    }
  }

  /**
   * Sends an unauthenticed HTTP request.
   *
   * @param method
   * @param path
   * @param data
   */
  static _unauthenticatedRequest(method, path, data = null) {
    let options = {
      method: method,
      url: this.ENV_CURRENT + path,
      data: null,
      responseType: "json",
    };

    if (data) {
      options.data = data;
    }

    return this._sendRequest(options);
  }

  /**
   * Sends a basic authenticated HTTP request.
   *
   * @param method
   * @param path
   * @param username
   * @param password
   * @param data
   */
  static _basicAuthRequest(method, path, username, password, data = null) {
    let options = {
      method: method,
      url: this.ENV_CURRENT + path,
      responseType: "json",
      data: null,
      auth: {
        user: username,
        pass: password,
      },
    };

    if (data) {
      options.data = data;
    }

    return this._sendRequest(options);
  }

  /**
   * Sends a bearer authenticated HTTP request.
   *
   * @param method
   * @param path
   * @param token
   * @param data
   */
  static _bearerAuthRequest(method, path, token, data = null) {
    let options = {
      method: method,
      url: this.ENV_CURRENT + path,
      data: null,
      responseType: "json",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (data) {
      options.data = data;
    }

    return this._sendRequest(options);
  }

  /**
   * Authenticates the user.
   *
   * @param email
   * @param password
   */
  static authenticate(email = "", password = "") {
    return this._basicAuthRequest(
      "GET",
      "/account/authenticate",
      email,
      password
    );
  }

  /**
   * Gets the user's account
   */
  static getAccount() {
    return this._bearerAuthRequest("GET", "/account", this.ENV_USER_TOKEN);
  }

  static getBillingAccounts() {
    return this._bearerAuthRequest(
      "GET",
      "/billing-accounts",
      this.ENV_USER_TOKEN
    );
  }

  static deleteBillingAccount(id) {
    return this._bearerAuthRequest(
      "DELETE",
      "/billing-accounts/" + id,
      this.ENV_USER_TOKEN
    );
  }

  static createBillingAccount(name, desc, key, secret, publicKey) {
    let params = {
      name: name,
      description: desc ? desc : "",
      api_key: key,
      api_secret: secret,
      public_key: publicKey,
    };

    return this._bearerAuthRequest(
      "POST",
      "/billing-accounts",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static modifyBillingAccount(id, name, desc, key, secret, publicKey) {
    let params = {
      name: name,
      description: desc ? desc : "",
      api_key: key,
      api_secret: secret,
      public_key: publicKey,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/billing-accounts/" + id,
      this.ENV_USER_TOKEN,
      params
    );
  }

  static getProductGroups() {
    return this._bearerAuthRequest(
      "GET",
      "/product-groups",
      this.ENV_USER_TOKEN
    );
  }

  static deleteProductGroup(id) {
    return this._bearerAuthRequest(
      "DELETE",
      "/product-groups/" + id,
      this.ENV_USER_TOKEN
    );
  }

  static createProductGroup(name, desc, min, max) {
    let params = {
      name: name,
      description: desc ? desc : "",
      calorie_min: min,
      calorie_max: max,
    };

    return this._bearerAuthRequest(
      "POST",
      "/product-groups",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static modifyProductGroup(id, name, desc, min, max) {
    let params = {
      name: name,
      description: desc ? desc : "",
      calorie_min: min,
      calorie_max: max,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/product-groups/" + id,
      this.ENV_USER_TOKEN,
      params
    );
  }

  static getStores() {
    return this._bearerAuthRequest("GET", "/stores", this.ENV_USER_TOKEN);
  }

  static getStoreByID(id) {
    return this._bearerAuthRequest("GET", "/stores/" + id, this.ENV_USER_TOKEN);
  }

  static createStore(
    code,
    zohoAccount,
    firstBillDate,
    firstBillCoupon,
    name,
    type,
    add1,
    add2,
    city,
    state,
    zip,
    phone,
    email,
    ig,
    fb,
    hours
  ) {
    let params = {
      name: name,
      code: code,
      type: type,
      zoho_account: zohoAccount,
      first_bill_date: firstBillDate ? firstBillDate : "",
      first_bill_coupon: firstBillCoupon ? firstBillCoupon : "",
    };

    if (type == 0) {
      params.address_1 = add1;

      if (add2) {
        params.address_2 = add2;
      }

      params.city = city;
      params.state = state;
      params.zip_code = zip;
    }

    if (phone) {
      params.phone = phone;
    }

    if (email) {
      params.email = email;
    }

    if (ig) {
      params.instagram_url = ig;
    }

    if (fb) {
      params.facebook_url = fb;
    }

    if (hours) {
      params.hours = hours;
    }

    return this._bearerAuthRequest(
      "POST",
      "/stores",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static modifyStore(
    id,
    code,
    zohoAccount,
    firstBillDate,
    firstBillCoupon,
    name,
    type,
    add1,
    add2,
    city,
    state,
    zip,
    phone,
    email,
    ig,
    fb,
    hours
  ) {
    let params = {
      name: name,
      code: code,
      type: type,
      zoho_account: zohoAccount,
      first_bill_date: firstBillDate ? firstBillDate : "",
      first_bill_coupon: firstBillCoupon ? firstBillCoupon : "",
    };

    if (type == 0) {
      params.address_1 = add1;

      if (add2) {
        params.address_2 = add2;
      }

      params.city = city;
      params.state = state;
      params.zip_code = zip;
    }

    if (phone) {
      params.phone = phone;
    }

    if (email) {
      params.email = email;
    }

    if (ig) {
      params.instagram_url = ig;
    }

    if (fb) {
      params.facebook_url = fb;
    }

    if (hours) {
      params.hours = hours;
    }

    return this._bearerAuthRequest(
      "PUT",
      "/stores/" + id,
      this.ENV_USER_TOKEN,
      params
    );
  }

  static deleteStore(id) {
    return this._bearerAuthRequest(
      "DELETE",
      "/stores/" + id,
      this.ENV_USER_TOKEN
    );
  }

  static setStoreBillingAccount(storeID, id) {
    let params = {
      account_id: id,
    };

    return this._bearerAuthRequest(
      "POST",
      "/stores/" + storeID + "/billing-account",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static setStoreGeneralTerms(id, terms) {
    let params = {
      terms: terms,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/stores/" + id + "/terms/general",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static setStorePrelaunchTerms(id, terms) {
    let params = {
      terms: terms,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/stores/" + id + "/terms/prelaunch",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static setStoreDeliveryTerms(id, terms) {
    let params = {
      terms: terms,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/stores/" + id + "/terms/delivery",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static setStorePickupTerms(id, terms) {
    let params = {
      terms: terms,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/stores/" + id + "/terms/pickup",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static addStoreTerritory(id, zip, reassign) {
    let params = {
      zip_code: zip,
    };

    return this._bearerAuthRequest(
      "POST",
      "/stores/" + id + "/territories" + (reassign ? "?reassign=true" : ""),
      this.ENV_USER_TOKEN,
      params
    );
  }

  static deleteStoreTerritory(id, zip) {
    return this._bearerAuthRequest(
      "DELETE",
      "/stores/" + id + "/territories/" + zip,
      this.ENV_USER_TOKEN
    );
  }

  static getStorePlans(id) {
    return this._bearerAuthRequest(
      "GET",
      "/stores/" + id + "/plans",
      this.ENV_USER_TOKEN
    );
  }

  static createStorePlan(
    storeID,
    code,
    name,
    groupID,
    price,
    quantity,
    procurement,
    frequency,
    visible
  ) {
    let params = {
      code: code,
      name: name,
      product_group_id: groupID,
      unit_price: price,
      quantity: quantity,
      procurement: procurement,
      frequency: frequency,
      visible: visible,
    };

    return this._bearerAuthRequest(
      "POST",
      "/stores/" + storeID + "/plans",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static modifyStorePlan(
    storeID,
    planID,
    code,
    name,
    groupID,
    price,
    quantity,
    procurement,
    frequency,
    visible
  ) {
    let params = {
      code: code,
      name: name,
      product_group_id: groupID,
      unit_price: price,
      quantity: quantity,
      procurement: procurement,
      frequency: frequency,
      visible: visible,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/stores/" + storeID + "/plans/" + planID,
      this.ENV_USER_TOKEN,
      params
    );
  }

  static deleteStorePlan(storeID, planID) {
    return this._bearerAuthRequest(
      "DELETE",
      "/stores/" + storeID + "/plans/" + planID,
      this.ENV_USER_TOKEN
    );
  }

  static getMealsByProductGroup(groupID) {
    return this._bearerAuthRequest(
      "GET",
      "/meals?product_group_id=" + groupID,
      this.ENV_USER_TOKEN
    );
  }

  static deleteMeal(mealID) {
    return this._bearerAuthRequest(
      "DELETE",
      "/meals/" + mealID,
      this.ENV_USER_TOKEN
    );
  }

  static createMeal(
    name,
    zohoAPIName,
    description,
    groupID,
    calories,
    carbs,
    protein,
    fat,
    sugar,
    fiber,
    sodium,
    foodWeight,
    finishedWeight,
    visible
  ) {
    let params = {
      name: name,
      description: description,
      zoho_api_name: zohoAPIName,
      product_group_id: groupID,
      calories: calories,
      carbs: carbs,
      protein: protein,
      fat: fat,
      sugar: sugar,
      fiber: fiber,
      sodium: sodium,
      food_weight: foodWeight,
      finished_weight: finishedWeight,
      visible: visible,
    };

    return this._bearerAuthRequest(
      "POST",
      "/meals",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static modifyMeal(
    id,
    name,
    zohoAPIName,
    description,
    groupID,
    calories,
    carbs,
    protein,
    fat,
    sugar,
    fiber,
    sodium,
    foodWeight,
    finishedWeight,
    visible
  ) {
    let params = {
      name: name,
      description: description,
      product_group_id: groupID,
      calories: calories,
      carbs: carbs,
      protein: protein,
      fat: fat,
      sugar: sugar,
      fiber: fiber,
      sodium: sodium,
      food_weight: foodWeight,
      zoho_api_name: zohoAPIName,
      finished_weight: finishedWeight,
      visible: visible,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/meals/" + id,
      this.ENV_USER_TOKEN,
      params
    );
  }

  static setMealAllergens(mealID, allergens) {
    let params = {
      allergens: allergens,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/meals/" + mealID + "/allergens",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static setMealCategories(mealID, categories) {
    let params = {
      categories: categories,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/meals/" + mealID + "/categories",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static setMealImage(mealID, file) {
    return axios.post(this.ENV_CURRENT + "/meals/" + mealID + "/image", file, {
      headers: {
        Authorization: "Bearer " + this.ENV_USER_TOKEN,
      },
    });
  }

  static getAllergens() {
    return this._bearerAuthRequest(
      "GET",
      "/meals/allergens",
      this.ENV_USER_TOKEN
    );
  }

  static getCategories() {
    return this._bearerAuthRequest(
      "GET",
      "/meals/categories",
      this.ENV_USER_TOKEN
    );
  }
}
