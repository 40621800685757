import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import APIV2 from "lib/APIV2";

class CreateBillingAccountModal extends React.Component {
  state = {
    open: false,
    name: "",
    description: "",
    key: "",
    secret: "",
    gatewayMerchantID: "",
    submitting: false,
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });

    if (this.state[state] == true) {
      this.setState({
        name: "",
        description: "",
        key: "",
        secret: "",
        publicKey: "",
        username: "",
        password: "",
        email: "",
        gatewayMerchantID: "",
      });
    }
  };

  submit(e) {
    e.preventDefault();

    this.setState({
      submitting: true,
    });

    APIV2.createBillingAccount(
      this.state.name,
      this.state.description,
      this.state.key,
      this.state.secret,
      this.state.publicKey,
      this.state.username,
      this.state.password,
      this.state.email,
      this.state.gatewayMerchantID
    )
      .then(
        (data) => {
          if (data && data.data && data.data.billingAccount) {
            PubSub.publish(
              Event.BILLING_ACCOUNT.CREATED,
              data.data.billingAccount
            );

            this.toggleModal("open");

            this.setState({
              name: "",
              description: "",
              key: "",
              secret: "",
              publicKey: "",
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "We were unable to create the account. Please try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  componentDidMount() {
    PubSub.subscribe(Event.BILLING_ACCOUNT.CREATE_OPEN, () => {
      this.setState({
        open: true,
      });
    });
  }

  render() {
    return (
      <>
        <Modal isOpen={this.state.open} toggle={() => this.toggleModal("open")}>
          <div className="modal-header">
            <h5 className="modal-title" id="createBAModalLabel">
              Add Billing Account
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("open")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Form onSubmit={this.submit.bind(this)}>
            <div className="modal-body">
              <h3>General Information</h3>
              <FormGroup>
                <h5>Name</h5>
                <Input
                  name="name"
                  placeholder="Name"
                  type="text"
                  className="mb-3"
                  required={true}
                  value={this.state.name}
                  onChange={this.handleInputChange.bind(this)}
                />
              </FormGroup>
              <hr className="mt-2 mb-3"></hr>
              <h3>Login Information</h3>
              <FormGroup>
                <h5>Authorize.net Username</h5>
                <Input
                  name="username"
                  placeholder="Username"
                  type="text"
                  required={false}
                  value={this.state.username}
                  onChange={this.handleInputChange.bind(this)}
                />
              </FormGroup>
              <FormGroup>
                <h5>Authorize.net Password</h5>
                <Input
                  name="password"
                  placeholder="Password"
                  type="text"
                  required={false}
                  value={this.state.password}
                  onChange={this.handleInputChange.bind(this)}
                />
              </FormGroup>
              <FormGroup>
                <h5>Authorize.net Email</h5>
                <Input
                  name="email"
                  placeholder="Email"
                  type="text"
                  required={false}
                  value={this.state.email}
                  onChange={this.handleInputChange.bind(this)}
                />
              </FormGroup>
              <hr className="mt-2 mb-3"></hr>
              <h3>Developer Settings</h3>
              <FormGroup>
                <h5>Authorize.net Payment Gateway ID</h5>
                <Input
                  name="gatewayMerchantID"
                  placeholder="Payment Gateway ID"
                  type="text"
                  className="mb-3"
                  value={this.state.gatewayMerchantID}
                  onChange={this.handleInputChange.bind(this)}
                />
              </FormGroup>
              <FormGroup>
                <h5>Authorize.net Login ID</h5>
                <Input
                  name="key"
                  placeholder="Login ID"
                  type="text"
                  className="mb-3"
                  required={true}
                  value={this.state.key}
                  onChange={this.handleInputChange.bind(this)}
                />
              </FormGroup>
              <FormGroup>
                <h5>Authorize.net Transaction Key</h5>
                <Input
                  name="secret"
                  placeholder="Transaction Key"
                  type="text"
                  className="mb-3"
                  required={true}
                  value={this.state.secret}
                  onChange={this.handleInputChange.bind(this)}
                />
              </FormGroup>
              <FormGroup>
                <h5>Authorize.net Public Key</h5>
                <Input
                  name="publicKey"
                  placeholder="Public Key"
                  type="text"
                  required={false}
                  value={this.state.publicKey}
                  onChange={this.handleInputChange.bind(this)}
                />
              </FormGroup>
            </div>
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="reset"
                onClick={() => this.toggleModal("open")}
              >
                Close
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={this.state.submitting}
              >
                Add
              </Button>
            </div>
          </Form>
        </Modal>
      </>
    );
  }
}

export default CreateBillingAccountModal;
