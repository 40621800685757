import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import API from "lib/API";
import BillingAccountRow from "components/BillingAccountRow";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex, findWhere } from "underscore";
import CreateBillingAccountModal from "components/CreateBillingAccountModal";
import StoreRow from "components/StoreRow";
import ModifyStoreModal from "components/ModifyStoreModal";
import { withRouter } from "react-router-dom";
import DeleteStoreModal from "components/DeleteStoreModal";
import StoreViewAboutTab from "components/StoreViewAboutTab";
import StoreViewIntegrationsTab from "components/StoreViewIntegrationsTab";
import StoreViewLogisticsTab from "components/StoreViewLogisticsTab";
import StoreViewManagementTab from "components/StoreViewManagementTab";
import StoreViewTermsTab from "components/StoreViewTermsTab";
import StoreViewProductsTab from "components/StoreViewProductsTab";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Capacitor } from "@capacitor/core";
import StoreViewNotesTab from "components/StoreViewNotesTab";
import StoreViewTasksTab from "components/StoreViewTasksTab";
import StoreViewSupportTicketsTab from "components/StoreViewSupportTicketsTab";
import StoreViewNSOTab from "components/StoreViewNSOTab";
import StoreViewComplianceTab from "components/StoreViewComplianceTab";
import StoreViewSupportTab from "components/StoreViewSupportTab";
import StoreViewPurchaseListTab from "components/StoreViewPurchaseListTab";
import StoreViewOpeningChecklistTab from "components/StoreViewOpeningChecklistTab";
import moment from "moment";

class StoreView extends React.Component {
  state = {
    store: null,
    loading: true,
    activeTab: null,
    openTabs: [
      {
        name: "About",
        id: "about",
      },
      {
        name: "Notes",
        id: "notes",
      },
      {
        name: "Tasks",
        id: "tasks",
      },
      {
        name: "Support Tickets",
        id: "tickets",
      },
      {
        name: "Unit Support",
        id: "support",
      },
      {
        name: "Compliance",
        id: "compliance",
      },
      {
        name: "Products",
        id: "products",
      },
      {
        name: "Logistics",
        id: "logistics",
      },
      {
        name: "Terms",
        id: "terms",
      },
      {
        name: "Integrations",
        id: "integrations",
      },
      {
        name: "NSO Timeline",
        id: "nso",
      },
      {
        name: "Purchase Lists",
        id: "purchase-lists",
      },
      {
        name: "Opening Checklist",
        id: "opening-checklist",
      },
      {
        name: "Manage",
        id: "manage",
      },
    ],
    nsoTabs: [
      {
        name: "About",
        id: "about",
      },
      {
        name: "NSO Timeline",
        id: "nso",
      },
      {
        name: "Purchase Lists",
        id: "purchase-lists",
      },
      {
        name: "Opening Checklist",
        id: "opening-checklist",
      },
      {
        name: "Notes",
        id: "notes",
      },
      {
        name: "Tasks",
        id: "tasks",
      },
      {
        name: "Support Tickets",
        id: "tickets",
      },

      {
        name: "Unit Support",
        id: "support",
      },
      {
        name: "Compliance",
        id: "compliance",
      },
      {
        name: "Products",
        id: "products",
      },
      {
        name: "Logistics",
        id: "logistics",
      },
      {
        name: "Terms",
        id: "terms",
      },
      {
        name: "Integrations",
        id: "integrations",
      },
      {
        name: "Manage",
        id: "manage",
      },
    ],
    tabs: [
      {
        name: "About",
        id: "about",
      },
      {
        name: "Notes",
        id: "notes",
      },
      {
        name: "Tasks",
        id: "tasks",
      },
      {
        name: "Support Tickets",
        id: "tickets",
      },
      {
        name: "Unit Support",
        id: "support",
      },
      {
        name: "Compliance",
        id: "compliance",
      },
      {
        name: "Products",
        id: "products",
      },
      {
        name: "Logistics",
        id: "logistics",
      },
      {
        name: "Terms",
        id: "terms",
      },
      {
        name: "Integrations",
        id: "integrations",
      },
      {
        name: "NSO Timeline",
        id: "nso",
      },
      {
        name: "Purchase Lists",
        id: "purchase-lists",
      },
      {
        name: "Opening Checklist",
        id: "opening-checklist",
      },
      {
        name: "Manage",
        id: "manage",
      },
    ],
  };

  constructor(props) {
    super(props);

    this.headerRef = React.createRef();
  }

  checkStickyHeader(e) {
    if (this.headerRef.current) {
      const stickyElm = this.headerRef.current;

      var rect = stickyElm.getBoundingClientRect();

      if (rect.top <= (Capacitor.getPlatform() == "ios" ? 110 : 80)) {
        this.setState({ sticky: true });
      } else {
        this.setState({ sticky: false });
      }

      if (rect.top == 0) {
        this.setState({ fixed: true });
      } else {
        this.setState({ fixed: false });
      }
    }
  }

  loadStore() {
    const {
      match: { params },
    } = this.props;

    APIV2.getStoreByID(params.storeID)
      .then(
        (data) => {
          if (data.data.store) {
            let store = data.data.store;
            this.setState({
              store,
            });

            if (
              store?.operations?.storeOpening?.firstBillDate &&
              moment().isSameOrAfter(
                moment(store?.operations?.storeOpening?.firstBillDate)
                  .add(60, "days")
                  .startOf("day")
              )
            ) {
              this.setState({
                tabs: this.state.openTabs,
              });
            } else {
              this.setState({
                tabs: this.state.nsoTabs,
              });
            }
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.activeTab != prevProps.match.params.activeTab) {
      this.setState({
        activeTab: findWhere(this.state.tabs, {
          id: this.props.match.params.activeTab,
        }),
      });
    }
  }

  componentDidMount() {
    if (this.props.match.params.activeTab) {
      this.setState({
        activeTab: findWhere(this.state.tabs, {
          id: this.props.match.params.activeTab,
        }),
      });
    }

    this.loadStore();

    PubSub.subscribe(Event.STORE.DELETED, (data) => {
      this.props.history.push("/frandev/stores");
    });

    PubSub.subscribe(Event.STORE.MODIFIED, (s) => {
      this.setState({
        store: s,
      });
    });

    document.addEventListener("scroll", this.checkStickyHeader.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.checkStickyHeader.bind(this));
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeader
            backOverride={`/frandev/stores`}
            title={
              this.state.store ? (
                <>
                  {this.state.store.name}
                  <p className="small mb-0" style={{ lineHeight: 1.2 }}>
                    {this.state.store?.nsoStage}
                  </p>
                </>
              ) : (
                "Loading..."
              )
            }
            showBackButton={true}
            forceSecondaryDropdown={true}
            secondaryActions={
              [
                /*{
                name: <>Duplicate</>,
                onClick: () => {
                  PubSub.publish(Event.MEAL.CREATE_OPEN);
                },
              },*/
              ]
            }
            secondaryAction={
              this.state.modified
                ? {
                    name: <>Cancel</>,
                    onClick: () => {
                      this.loadMeal();
                      this.setState({ modified: false, modifyImage: false });
                    },
                    disabled: this.state.submitting,
                  }
                : null
            }
            tabs={
              <CardSelectorHeader
                id="stickyHeader"
                loading={this.state.loading}
                value={this.state.activeTab}
                options={this.state.tabs}
                compact={true}
                onChange={(option) => {
                  this.props.history.push(
                    `/frandev/stores/details/${this.props.match.params.storeID}/${option.id}`,
                    { noScroll: true }
                  );
                }}
              ></CardSelectorHeader>
            }
            sticky={true}
            primaryAction={
              this.state.modified
                ? {
                    name: <>Save</>,
                    onClick: () => {
                      this.modify();
                    },
                    submitting: this.state.submitting,
                    disabled: this.state.submitting,
                  }
                : null
            }
          />
          {/* Table */}

          <Switch>
            <Route
              path="/frandev/stores/details/:storeID/about"
              component={StoreViewAboutTab}
              exact
            />
            <Route
              path="/frandev/stores/details/:storeID/nso"
              component={StoreViewNSOTab}
              exact
            />
            <Route
              path="/frandev/stores/details/:storeID/purchase-lists"
              component={StoreViewPurchaseListTab}
              exact
            />
            <Route
              path="/frandev/stores/details/:storeID/opening-checklist"
              component={StoreViewOpeningChecklistTab}
              exact
            />
            <Route
              path="/frandev/stores/details/:storeID/products"
              component={StoreViewProductsTab}
              exact
            />
            <Route
              path="/frandev/stores/details/:storeID/logistics"
              component={StoreViewLogisticsTab}
              exact
            />
            <Route
              path="/frandev/stores/details/:storeID/terms"
              component={StoreViewTermsTab}
              exact
            />
            <Route
              path="/frandev/stores/details/:storeID/compliance"
              component={StoreViewComplianceTab}
              exact
            />
            <Route
              path="/frandev/stores/details/:storeID/support"
              component={StoreViewSupportTab}
              exact
            />
            <Route
              path="/frandev/stores/details/:storeID/notes"
              component={StoreViewNotesTab}
              exact
            />
            <Route
              path="/frandev/stores/details/:storeID/integrations"
              component={StoreViewIntegrationsTab}
              exact
            />
            <Route
              path="/frandev/stores/details/:storeID/tasks"
              component={StoreViewTasksTab}
              exact
            />
            <Route
              path="/frandev/stores/details/:storeID/tickets"
              component={StoreViewSupportTicketsTab}
              exact
            />
            <Route
              path="/frandev/stores/details/:storeID/manage"
              component={StoreViewManagementTab}
              exact
            />

            <Redirect from="*" to="/frandev/stores/details/:storeID/about" />
          </Switch>
        </Container>
      </>
    );
  }
}

export default withRouter(StoreView);
