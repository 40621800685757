import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import {
  Checkbox,
  Dropdown,
  Popover,
  SelectPicker,
  Tooltip,
  Whisper,
} from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import MarketingCohortDrawer from "../../components/Drawers/MarketingCohortDrawer";
import swal from "sweetalert";
import Constant from "../../lib/Constant";

class GlobalMarketingKitsView extends React.Component {
  state = {
    active: {
      id: "CA / Prelaunch Store",
      name: "CA / Prelaunch Store",
    },
  };

  componentDidUpdate(prevProps) {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab && tab != this.state.active?.id) {
      this.handleTabChange({
        id: decodeURIComponent(tab),
        name: decodeURIComponent(tab),
      });
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab) {
      this.handleTabChange(
        {
          id: decodeURIComponent(tab),
          name: decodeURIComponent(tab),
        },
        true
      );
    } else {
      this.handleTabChange(this.state.active, true);
    }
  }

  handleTabChange(option, force = false) {
    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    this.setState({
      active: option,
    });
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader title="Marketing Kits" showBackButton={false} />

          <Card className=" shadow" style={{ minHeight: "500px" }}>
            <CardSelectorHeader
              compact={true}
              value={this.state.active}
              options={[
                {
                  name: "CA / Prelaunch Store",
                  id: "CA / Prelaunch Store",
                },
                {
                  name: "Live Store",
                  id: "Live Store",
                },
              ]}
              onChange={this.handleTabChange.bind(this)}
            ></CardSelectorHeader>

            <CardBody className="p-0">
              {this.state.active?.id == "CA / Prelaunch Store" ? (
                <>
                  <iframe
                    id="form_qLQ3Z8w527od6D7Kzk"
                    style={{
                      width: 1,
                      border: "none",
                      minWidth: "100%",
                      display: "block",
                      minHeight: "calc(100vh - 300px)",
                    }}
                    src="https://app.bannerbear.com/forms/qLQ3Z8w527od6D7Kzk"
                  ></iframe>
                  <script src="https://cdn.bannerbear.com/iframeResizer.min.js"></script>
                  <script>iFrameResize({}, "#form_qLQ3Z8w527od6D7Kzk")</script>
                </>
              ) : null}
              {this.state.active?.id == "Live Store" ? <></> : null}
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}

export default GlobalMarketingKitsView;
