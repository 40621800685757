import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";
import Constant from "./Constant";
import APIV2 from "./APIV2";

export default class LinkUtils {
  static async openInNewTab(link, appendPLN = false) {
    if (Capacitor.isNativePlatform()) {
      console.log("here");

      if (appendPLN) {
        let linkParts = link?.split("?");

        if (linkParts?.length == 1) {
          link = `https://${
            Constant.ENV_HOST.PRODUCTION
          }${link}?authToken=${window.localStorage.getItem(
            Constant.CACHE.TOKEN
          )}`;
        } else {
          link = `https://${
            Constant.ENV_HOST.PRODUCTION
          }${link}&authToken=${window.localStorage.getItem(
            Constant.CACHE.TOKEN
          )}`;
        }
      }

      console.log(link);

      Browser.open({ url: link });
    } else {
      console.log("here2 ");

      window.open(link, "_blank");
    }
  }

  static openPrivateURL(url) {
    APIV2.getPrivateFileURL(url).then((data) => {
      let url = data?.data?.url;

      this.openInNewTab(url);
    });
  }
}
