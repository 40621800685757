import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import Constant from "lib/Constant";

class SuppressedContactBulkCreateDrawer extends React.Component {
  state = {
    phone: {
      value: "",
    },
    type: "UNSUBSCRIBED",

    phones: [],
  };

  componentDidUpdate(prevProps) {}

  componentDidMount() {}

  handlePhoneChange(value) {
    this.setState({
      phone: value,
    });
  }

  addPhone(phone) {
    let phones = this.state.phones?.length ? this.state.phones : [];

    if (!phones?.includes(phone)) {
      phones.push(phone?.length == 10 ? `+1${phone}` : phone);
    }

    this.setState({
      phones,
      phone: {
        value: "",
      },
    });
  }

  removePhone(phone) {
    let phones = this.state.phones?.length ? this.state.phones : [];

    phones = _.filter(phones, (p) => {
      return p != phone;
    });

    this.setState({
      phones,
    });
  }

  toggleModal() {
    this.setState({
      type: "UNSUBSCRIBE",
      phones: [],
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  create() {
    this.setState({ submitting: true });

    APIV2.bulkCreateContactSuppressions(
      this.props?.store?._id,
      this.state.type,
      this.state.phones
    )

      .then(
        (data) => {
          const contacts = data.data.contacts;

          this.toggleModal();

          PubSub.publish(Event.SUPPRESSED_CONTACT.CREATED, contacts);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to bulk add suppressed contacts - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Bulk Add Suppressed Contacts
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={
                  this.state.submitting ||
                  !this.state.type ||
                  !this.state.phones?.length
                }
                color="primary"
                onClick={this.create.bind(this)}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  <>Add</>
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}

            <FormGroup>
              <h5>
                Suppression Type{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <SelectPicker
                size="sm"
                block
                oneTap
                data={[
                  {
                    value: "UNSUBSCRIBED",
                    label: "Unsubscribed",
                  },
                  {
                    value: "BLOCKED",
                    label: "Blocked",
                  },
                ]}
                value={this.state.type}
                onChange={(e) => {
                  this.setState({
                    type: e,
                  });
                }}
                cleanable={false}
                searchable={false}
              ></SelectPicker>
            </FormGroup>
            <FormGroup>
              <h5>
                Add Contacts{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <Row>
                <Col xs="" className="pr-0">
                  <NumberFormat
                    className="form-control mb-3 form-control-sm"
                    format="(###) ###-####"
                    name="phoneNumberInput"
                    placeholder="Phone Number"
                    onValueChange={this.handlePhoneChange.bind(this)}
                    value={this.state.phone.value}
                    type="tel"
                    mask="_"
                  />
                </Col>
                <Col xs="auto">
                  <Button
                    size="sm"
                    color="primary"
                    className="btn-icon-only"
                    disabled={!this.state?.phone?.value}
                    onClick={() => {
                      this.addPhone(this.state.phone?.value);
                    }}
                  >
                    <i className="mdi mdi-plus"></i>
                  </Button>
                </Col>
              </Row>
            </FormGroup>
            <div>
              {this.state.phones?.map((phone, i) => (
                <div
                  key={phone}
                  className={`py-2 ${
                    i != this.state.phones?.length - 1 && "border-bottom"
                  }`}
                >
                  <Row className="align-items-center">
                    <Col xs="">
                      <h4 className="font-weight-500 m-0">
                        {StringUtils.formatPhoneNumber(phone) ?? phone}
                      </h4>
                    </Col>
                    <Col xs="auto">
                      <Button
                        size="sm"
                        outline
                        color="danger"
                        className="btn-icon-only"
                        onClick={() => {
                          this.removePhone(phone);
                        }}
                      >
                        <i className="mdi mdi-close"></i>
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(SuppressedContactBulkCreateDrawer);
