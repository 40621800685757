import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  Uploader,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";

class CreateMenuTransitionDrawer extends React.Component {
  state = {
    retiringMenu: "",
    newMenu: "",
    menuLaunchDate:
      moment().day() == 0
        ? moment().day(0).toDate()
        : moment().day(0).add(1, "week").toDate(),
    mealMapping: {},
    deleteAfter: "yes",
  };

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  createTransition() {
    this.setState({
      submitting: true,
    });

    let launchDate = moment(this.state.menuLaunchDate.toISOString()).format(
      "YYYY-MM-DD"
    );

    let retiringMealSwaps = [];

    let swapKeys = _.keys(this.state.mealMapping);

    for (let i = 0; i < swapKeys?.length; i++) {
      retiringMealSwaps.push({
        from: swapKeys[i],
        to: this.state.mealMapping[swapKeys[i]],
      });
    }

    APIV2.createMenuTransition(
      this.state.retiringMenu,
      this.state.newMenu,
      launchDate,
      retiringMealSwaps,
      this.state.deleteAfter == "yes"
    )
      .then(
        (data) => {
          if (typeof this.props.onCreate == "function") {
            this.props.onCreate(data.data.menuTransition);
          }

          if (typeof this.props.onClose == "function") {
            this.props.onClose();
          }

          this.setState({
            retiringMenu: "",
            newMenu: "",
            menuLaunchDate:
              moment().day() == 0
                ? moment().day(0).toDate()
                : moment().day(0).add(1, "week").toDate(),
            mealMapping: {},
            deleteAfter: "yes",
          });
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create transition - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  modifyTransition() {
    this.setState({
      submitting: true,
    });

    let launchDate = moment(this.state.menuLaunchDate.toISOString()).format(
      "YYYY-MM-DD"
    );

    let retiringMealSwaps = [];

    let swapKeys = _.keys(this.state.mealMapping);

    for (let i = 0; i < swapKeys?.length; i++) {
      retiringMealSwaps.push({
        from: swapKeys[i],
        to: this.state.mealMapping[swapKeys[i]],
      });
    }

    APIV2.modifyMenuTransition(
      this.props.transition?._id,
      this.state.retiringMenu,
      this.state.newMenu,
      launchDate,
      retiringMealSwaps,
      this.state.deleteAfter == "yes"
    )
      .then(
        (data) => {
          if (typeof this.props.onModify == "function") {
            this.props.onModify(data.data.menuTransition);
          }

          if (typeof this.props.onClose == "function") {
            this.props.onClose();
          }

          this.setState({
            retiringMenu: "",
            newMenu: "",
            menuLaunchDate:
              moment().day() == 0
                ? moment().day(0).toDate()
                : moment().day(0).add(1, "week").toDate(),
            mealMapping: {},
            deleteAfter: "yes",
          });
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create transition - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  loadMenuOptions(menus) {
    let menuOptions = _.map(menus, (menu) => {
      return {
        value: menu?._id,
        label: menu?.name,
        type: menu?.planType?.name,
      };
    });

    this.setState({
      menuOptions,
      menus,
    });
  }

  getMenuOptions(mainMenu) {
    if (!mainMenu) {
      return [];
    }

    let selected = _.findWhere(this.state.menuOptions, { value: mainMenu });

    return _.filter(this.state.menuOptions, (opt) => {
      return opt?.type == selected?.type && opt?.value != mainMenu;
    });
  }

  loadTransition(transition) {
    let mealMapping = {};

    for (let i = 0; i < transition.retiringMealSwaps?.length; i++) {
      const swap = transition.retiringMealSwaps[i];

      mealMapping[swap.from] = swap.to;
    }

    this.setState(
      {
        newMenu: transition.newMenuID,
        retiringMenu: transition.oldMenuID,
        deleteAfter: transition.deleteAfter ? "yes" : "no",
        menuLaunchDate: moment(transition.transitionDate).toDate(),
        mealMapping: mealMapping,
      },
      () => {
        this.getRetiringMeals(true);
      }
    );
  }

  componentDidMount() {
    if (this.props.menus?.length) {
      this.loadMenuOptions(this.props.menus);
    }

    if (this.props.transition) {
      this.loadTransition(this.props.transition);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.menus != prevProps.menus && this.props.menus?.length) {
      this.loadMenuOptions(this.props.menus);
    }

    if (
      this.props.transition != prevProps.transition &&
      this.props.transition
    ) {
      this.loadTransition(this.props.transition);
    }
  }

  getRetiringMeals(keepMapping = false) {
    let retiringMenu = _.findWhere(this.state.menus, {
      _id: this.state.retiringMenu,
    });
    let newMenu = _.findWhere(this.state.menus, { _id: this.state.newMenu });

    console.log(retiringMenu, newMenu);

    if (!retiringMenu || !newMenu) {
      return;
    }

    let retiringMeals = [];

    for (let i = 0; i < retiringMenu?.meals?.length; i++) {
      if (!_.findWhere(newMenu?.meals, { _id: retiringMenu.meals[i]._id })) {
        retiringMeals.push(retiringMenu.meals[i]);
      }
    }

    let newMealOptions = newMenu?.meals?.map((meal) => {
      return {
        value: meal?._id,
        label: meal?.name,
      };
    });

    let mealMapping = {};

    if (keepMapping) {
      mealMapping = this.state.mealMapping;
    }

    this.setState({
      retiringMeals,
      newMeals: newMenu?.meals,
      mealMapping,
      newMealOptions,
    });
  }

  setMapping(retiringMeal, newMeal) {
    let map = this.state.mealMapping ?? {};

    map[retiringMeal] = newMeal;

    this.setState({
      mealMapping: map,
    });
  }

  allMealsMapped() {
    if (!this.state.retiringMeals?.length) {
      return true;
    }

    let retiringMealKeys = this.state.retiringMeals.map((r) => r._id);

    for (let i = 0; i < retiringMealKeys.length; i++) {
      if (
        !this.state.mealMapping?.hasOwnProperty(retiringMealKeys[i]) ||
        !this.state.mealMapping[retiringMealKeys[i]]
      ) {
        return false;
      }
    }

    return true;
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => {
            this.setState({
              retiringMenu: "",
              newMenu: "",
              menuLaunchDate:
                moment().day() == 0
                  ? moment().day(0).toDate()
                  : moment().day(0).add(1, "week").toDate(),
              mealMapping: {},
              deleteAfter: "yes",
            });

            if (typeof this.props.onClose == "function") {
              this.props.onClose();
            }
          }}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.transition ? "Modify" : "New"} Menu Transition
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={
                  this.state.submitting ||
                  !this.state.newMenu ||
                  !this.state.retiringMenu ||
                  !this.state.menuLaunchDate ||
                  !this.allMealsMapped()
                }
                color="primary"
                onClick={() => {
                  if (this.props.transition) {
                    return this.modifyTransition();
                  }

                  this.createTransition();
                }}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  <>{this.props.transition ? "Save" : "Add"}</>
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            <h3>Transition Information</h3>
            <FormGroup>
              <h5>Retiring Menu</h5>
              <SelectPicker
                placement="auto"
                placeholder="Select A Menu"
                data={this.state.menuOptions}
                value={this.state.retiringMenu}
                block
                groupBy="type"
                cleanable
                onChange={(value, item) => {
                  this.setState({ retiringMenu: value });
                }}
              ></SelectPicker>
            </FormGroup>
            <FormGroup>
              <h5>New Menu</h5>
              <SelectPicker
                placement="auto"
                placeholder="Select A Menu"
                data={this.getMenuOptions(this.state.retiringMenu)}
                disabled={!this.state.retiringMenu}
                value={this.state.newMenu}
                block
                groupBy="type"
                cleanable
                onChange={(value, item) => {
                  this.setState({ newMenu: value }, () => {
                    this.getRetiringMeals();
                  });
                }}
              ></SelectPicker>
            </FormGroup>
            <FormGroup>
              <h5>New Menu Launch Date</h5>
              <DatePicker
                oneTap
                required
                block
                placeholder="Select A Date"
                value={this.state.menuLaunchDate}
                onChange={(e) => {
                  this.setState({ menuLaunchDate: e });
                }}
                disabledDate={(date) => date < new Date() || date.getDay() != 0}
                ranges={[
                  {
                    label: "Next Week",
                    value:
                      moment().day() == 0
                        ? moment().add(1, "week").toDate()
                        : moment().day(0).add(1, "week").toDate(),
                    closeOverlay: true,
                  },
                  {
                    label: "2 Weeks",
                    value:
                      moment().day() == 0
                        ? moment().add(2, "week").toDate()
                        : moment().day(0).add(2, "week").toDate(),
                    closeOverlay: true,
                  },
                ]}
              ></DatePicker>
            </FormGroup>
            <FormGroup>
              <h5>Delete Retired Menu After Transition</h5>
              <SelectPicker
                block
                value={this.state.deleteAfter}
                data={[
                  {
                    value: "yes",
                    label: "Yes",
                  },
                  {
                    value: "no",
                    label: "No",
                  },
                ]}
                cleanable={false}
                searchable={false}
                onChange={(v) => {
                  this.setState({ deleteAfter: v });
                }}
              ></SelectPicker>
            </FormGroup>
            {/**
             * <Card>
              <CardBody>
                <div className="text-dark mb-2">
                  <span className="font-weight-bold text-dark">
                    Announcement Email:
                  </span>
                  &nbsp;
                  {this.state.menuLaunchDate
                    ? moment(this.state.menuLaunchDate)
                        .subtract(1, "week")
                        .format("dddd MMM DD, YYYY")
                    : "--"}
                </div>

                <div className="text-dark mb-2">
                  <span className="font-weight-bold text-dark">
                    Retiring Meals Swapped:
                  </span>
                  &nbsp;
                  {this.state.menuLaunchDate
                    ? moment(this.state.menuLaunchDate).format(
                        "dddd MMM DD, YYYY"
                      ) + " at 12:01 AM"
                    : "--"}
                </div>
                <div className="text-dark mb-2">
                  <span className="font-weight-bold text-dark">
                    Menu Available &amp; Email:
                  </span>
                  &nbsp;
                  {this.state.menuLaunchDate
                    ? moment(this.state.menuLaunchDate).format(
                        "dddd MMM DD, YYYY"
                      )
                    : "--"}
                </div>
                <div className="text-dark mb-2">
                  <span className="font-weight-bold text-dark">
                    Swap Reminder Email #1:
                  </span>
                  &nbsp;
                  {this.state.menuLaunchDate
                    ? "Morning of " +
                      moment(this.state.menuLaunchDate)
                        .add(2, "days")
                        .format("dddd MMM DD, YYYY")
                    : "--"}
                </div>
                <div className="text-dark">
                  <span className="font-weight-bold text-dark">
                    New Meal Fulfillment Starts:
                  </span>
                  &nbsp;
                  {this.state.menuLaunchDate
                    ? moment(this.state.menuLaunchDate)
                        .day(6)
                        .format("dddd MMM DD, YYYY")
                    : "--"}
                </div>
              </CardBody>
            </Card>
             */}
            <hr className="my-3"></hr>
            <h3 className="m-0">Retiring Meal Replacement</h3>
            <p>Choose a new meal to replace each retiring meal.</p>
            {!this.state.retiringMenu || !this.state.newMenu ? (
              <>
                <Card className="border">
                  <CardBody>
                    <p className="m-0">
                      Select two menus to being matching meal replacements.
                    </p>
                  </CardBody>
                </Card>
              </>
            ) : (
              <>
                <div className="py-2 border-bottom border-top bg-superlight mx--4 px-4">
                  <Row className="align-items-center">
                    <Col xs="12" sm="6">
                      <h4 className="text-dark m-0">Retiring Meal</h4>
                    </Col>
                    <Col xs="12" sm="6">
                      <h4 className="text-dark m-0">Replace With</h4>
                    </Col>
                  </Row>
                </div>

                {this.state.retiringMeals?.map((retiringMeal, i) => (
                  <div
                    key={i}
                    className={`py-3 ${
                      i != this.state.retiringMeals?.length - 1 &&
                      "border-bottom"
                    }`}
                  >
                    <Row className="align-items-center">
                      <Col xs="12" sm="6">
                        <h3 className="m-0">{retiringMeal?.name}</h3>
                      </Col>
                      <Col xs="12" sm="6">
                        <SelectPicker
                          placement="auto"
                          cleanable={true}
                          data={this.state.newMealOptions}
                          oneTap
                          block
                          value={this.state.mealMapping[retiringMeal._id]}
                          onChange={(v) => {
                            this.setMapping(retiringMeal._id, v);
                          }}
                        ></SelectPicker>
                      </Col>
                    </Row>
                  </div>
                ))}
              </>
            )}
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(CreateMenuTransitionDrawer);
