import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
  Alert,
  FormGroup,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import CreateMenuModal from "components/CreateMenuModal";
import MenuRow from "components/MenuRow";
import PageHeader from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import ArrowText from "components/ArrowText";
import { DatePicker, Drawer, SelectPicker } from "rsuite";
import moment from "moment";
import _ from "underscore";
import CreateMenuTransitionDrawer from "components/Drawers/CreateMenuTransitionDrawer";
import swal from "sweetalert";

class MenuList extends React.Component {
  state = {
    active: 0,
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    menus: [],
    menuLaunchDate:
      moment().day() == 0
        ? moment().day(0).toDate()
        : moment().day(0).add(1, "week").toDate(),
    menuOptions: [],
  };

  componentWillMount() {
    APIV2.getProductGroups().then(
      (data) => {
        if (data.data && data.data.planTypes) {
          let active = data.data.planTypes.length ? data.data.planTypes[0] : {};

          this.setState({
            productGroups: data.data.planTypes,
            loadingGroups: false,
          });

          this.loadMenus(active);
        }
      },
      () => {
        window.alert("Failed to get product groups. Please try again.");
      }
    );

    this.loadAllMenus();

    this.loadMenuTransitions();
  }

  componentDidMount() {
    PubSub.subscribe(Event.MENU.DELETED, (data) => {
      let g = filter(this.state.inactiveMenus, (a) => {
        return a.id != data._id;
      });

      let g2 = filter(this.state.menus, (a) => {
        return a.id != data._id;
      });

      let menuOptions = _.map(g2, (menu) => {
        return {
          value: menu?._id,
          label: menu?.name,
          type: menu?.planType?.name,
        };
      });

      this.setState({
        inactiveMenus: g,
        menus: g2,
        menuOptions,
      });
    });

    PubSub.subscribe(Event.MENU.CREATED, (m) => {
      let g = this.state.inactiveMenus;
      let g2 = this.state.menus;

      g.push(m);
      g2.push(m);

      let menuOptions = _.map(g2, (menu) => {
        return {
          value: menu?._id,
          label: menu?.name,
          type: menu?.planType?.name,
        };
      });

      this.setState({
        inactiveMenus: g,
        menus: g2,
        menuOptions,
      });
    });

    PubSub.subscribe(Event.MENU.MODIFIED, (a) => {
      let g = this.state.inactiveMenus;
      let a2 = this.state.activeMenus;
      let a3 = this.state.menus;
      let idxG = findIndex(g, { _id: a._id });
      let idxA = findIndex(a2, { _id: a._id });
      let idxB = findIndex(a3, { _id: a._id });

      if (idxG > -1) {
        g[idxG] = a;
      }

      if (idxA > -1) {
        a2[idxA] = a;
      }

      if (idxB > -1) {
        a3[idxB] = a;
      }

      let menuOptions = _.map(a3, (menu) => {
        return {
          value: menu?._id,
          label: menu?.name,
          type: menu?.planType?.name,
        };
      });

      this.setState({
        inactiveMenus: g,
        activeMenus: a2,
        menus: a3,
        menuOptions,
      });
    });
  }

  getMenuOptions(mainMenu) {
    if (!mainMenu) {
      return [];
    }

    let selected = _.findWhere(this.state.menuOptions, { value: mainMenu });

    return _.filter(this.state.menuOptions, (opt) => {
      return opt?.type == selected?.type && opt?.value != mainMenu;
    });
  }

  loadAllMenus() {
    this.setState({
      menus: [],
      loadingMenus: true,
    });

    APIV2.getMealMenusByProductGroup()
      .then(
        (data) => {
          if (data && data.data) {
            let menus = [];

            if (data.data.menus.length) {
              menus = data.data.menus;
            }

            let menuOptions = _.map(menus, (menu) => {
              return {
                value: menu?._id,
                label: menu?.name,
                type: menu?.planType?.name,
              };
            });

            this.setState({
              menus: menus,
              menuOptions,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading " +
              "the" +
              " menus. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingMenus: false,
        });
      });
  }

  loadMenus(group) {
    if (!group) {
      return;
    }

    let active = { name: group.name, id: group._id, _id: group._id };

    this.setState({
      activeMenus: [],
      inactiveMenus: [],
      active: active,
      loadingActiveMenus: true,
      loadingInativeMenus: true,
    });

    APIV2.getActiveMealMenusByProductGroup(group._id)
      .then(
        (data) => {
          if (data && data.data) {
            let menus = [];

            if (data.data.menus.length) {
              menus = data.data.menus;
            }

            this.setState({
              activeMenus: menus,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading " +
              (group ? group.name : "the") +
              " menus. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingActiveMenus: false,
        });
      });

    APIV2.getInactiveMealMenusByProductGroup(group._id)
      .then(
        (data) => {
          if (data && data.data) {
            let menus = [];

            if (data.data.menus.length) {
              menus = data.data.menus;
            }

            this.setState({
              inactiveMenus: menus,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading " +
              (group ? group.name : "the") +
              " menus. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingInactiveMenus: false,
        });
      });
  }

  loadMenuTransitions() {
    this.setState({
      transitions: [],
      loadingTransitions: true,
    });

    APIV2.getMealMenuTransitions()
      .then(
        (data) => {
          if (data && data.data) {
            let menuTransitions = [];

            if (data.data.menuTransitions.length) {
              menuTransitions = data.data.menuTransitions;
            }

            this.setState({
              transitions: menuTransitions,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading menu transitions. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingTransitions: false,
        });
      });
  }

  onTransitionCreate(transition) {
    let transitions = this.state.transitions;

    transitions.push(transition);

    console.log(transition);

    this.setState({ transitions });
  }

  onTransitionModify(transition) {
    let transitions = this.state.transitions;

    let idx = _.findIndex(transitions, { _id: transition?._id });

    if (idx < 0) {
      return;
    }

    transitions[idx] = transition;

    this.setState({ transitions });
  }

  deleteTransition(transition) {
    swal({
      title: "Delete Transition",
      text: `Are you sure you want to delete this transition?`,
      icon: "warning",
      buttons: ["Nevermind", "Delete"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        deletingTransition: transition?._id,
      });

      APIV2.deleteMenuTransition(transition?._id)
        .then(
          () => {
            swal({
              title: "Transition Deleted",
              text: "The menu transition was successfully deleted.",
              icon: "success",
            });

            let transitions = _.filter(this.state.transitions, (t) => {
              return t._id != transition._id;
            });

            this.setState({
              transitions,
            });
          },
          (e) => {}
        )
        .finally(() => {
          this.setState({
            deletingTransition: "",
          });
        });
    });
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader title="Menus" showBackButton={false} sticky={true} />

          <Row>
            <Col xs="12" className="mb-4">
              <Card className="border shadow">
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="">
                      <h3 className="m-0">Menu Transitions</h3>
                      <p className="m-0" style={{ lineHeight: 1.2 }}>
                        <small>
                          Scheduled transitions between retiring and new menus.
                        </small>
                      </p>
                    </Col>
                    <Col xs="auto">
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() => {
                          this.setState({
                            transitionOpen: true,
                          });
                        }}
                      >
                        Add
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                {this.state.transitions?.length ? (
                  <>
                    {this?.state?.transitions?.map((transition, i) => (
                      <div
                        key={i}
                        className={`p-3 ${
                          i != this?.state?.transitions?.length &&
                          "border-bottom"
                        }`}
                      >
                        <Row className="align-items-center">
                          <Col xs="12" sm="">
                            <div className="mb-2">
                              <Badge color="medium">
                                {transition?.oldMenu?.planType?.name} Menu
                              </Badge>
                            </div>
                            <h4 className="mb-0">
                              {transition?.oldMenu?.name}
                              <span className="px-2">
                                <ArrowText></ArrowText>
                              </span>
                              {transition?.newMenu?.name}
                            </h4>
                            <p className="m-0 small">
                              {transition.retiringMealSwaps?.length} Meals
                              Retiring On{" "}
                              {moment(transition.transitionDate).format(
                                "MMM DD, YYYY"
                              )}
                            </p>
                          </Col>
                          <Col xs="12" sm="auto" className="mt-3 mt-sm-0">
                            <Button
                              size="sm"
                              className="btn-icon-only"
                              outline
                              color="dark"
                              onClick={() => {
                                this.setState({
                                  editTransition: transition,
                                  transitionOpen: true,
                                });
                              }}
                            >
                              <i className="mdi mdi-pencil"></i>
                            </Button>
                            <Button
                              size="sm"
                              className="btn-icon-only"
                              outline
                              color="danger"
                              onClick={() => {
                                this.deleteTransition(transition);
                              }}
                            >
                              <i className="mdi mdi-trash-can"></i>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="p-3">
                    <p className="m-0">
                      No upcoming menu transitions scheduled.
                    </p>
                  </div>
                )}
              </Card>
            </Col>
            <Col xs="12" className="mb-4">
              <Card className="border shadow" style={{ minHeight: 400 }}>
                <CardHeader>
                  <h3 className="m-0">Active Menus</h3>
                  <p className="m-0" style={{ lineHeight: 1.2 }}>
                    <small>
                      Menus that are currently available at one or more stores.
                    </small>
                  </p>
                </CardHeader>
                <CardSelectorHeader
                  loading={this.state.loadingGroups}
                  value={this.state.active}
                  options={
                    this.state.productGroups
                      ? this.state.productGroups.map((pg, i) => {
                          return {
                            name: pg.name + " Menus",
                            id: pg._id,
                            _id: pg._id,
                          };
                        })
                      : []
                  }
                  onChange={(option) => {
                    this.loadMenus(option);
                  }}
                ></CardSelectorHeader>

                {this.state.active ? (
                  <Table responsive={true} className="table-hover">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col" className="d-none d-md-table-cell">
                          Meals
                        </th>

                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.loadingActiveMenus ? (
                        <tr>
                          <td colSpan="5">Loading...</td>
                        </tr>
                      ) : null}
                      {!this.state.loadingActiveMenus &&
                      !this.state.activeMenus.length ? (
                        <tr>
                          <td colSpan="5">
                            No active
                            {this.state.active ? this.state.active.name : ""}
                            &nbsp;menus. Get started by adding the first one.
                          </td>
                        </tr>
                      ) : null}
                      {this.state.activeMenus.map((menu, i) => (
                        <MenuRow
                          key={i}
                          menu={menu}
                          planType={this.state.active}
                          allowDelete={false}
                        ></MenuRow>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light"></thead>
                    <tbody>
                      <tr>
                        <td>Loading...</td>
                      </tr>
                    </tbody>
                  </Table>
                )}
              </Card>
            </Col>
            <Col xs="12">
              <Card className="border shadow" style={{ minHeight: 400 }}>
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="">
                      <h3 className="m-0">Inactive Menus</h3>
                      <p className="m-0" style={{ lineHeight: 1.2 }}>
                        <small>
                          Menus that are not currently available at any stores.
                        </small>
                      </p>
                    </Col>
                    <Col xs="auto">
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() => {
                          PubSub.publish(Event.MENU.CREATE_OPEN);
                        }}
                      >
                        Add
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardSelectorHeader
                  loading={this.state.loadingGroups}
                  value={this.state.active}
                  options={
                    this.state.productGroups
                      ? this.state.productGroups.map((pg, i) => {
                          return {
                            name: pg.name + " Menus",
                            id: pg._id,
                            _id: pg._id,
                          };
                        })
                      : []
                  }
                  onChange={(option) => {
                    this.loadMenus(option);
                  }}
                ></CardSelectorHeader>

                {this.state.active ? (
                  <Table responsive={true} className="table-hover">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col" className="d-none d-md-table-cell">
                          Meals
                        </th>

                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.loadingInactiveMenus ? (
                        <tr>
                          <td colSpan="5">Loading...</td>
                        </tr>
                      ) : null}
                      {!this.state.loadingInactiveMenus &&
                      !this.state.inactiveMenus.length ? (
                        <tr>
                          <td colSpan="5">
                            No inactive{" "}
                            {this.state.active ? this.state.active.name : ""}
                            &nbsp;menus.
                          </td>
                        </tr>
                      ) : null}
                      {this.state.inactiveMenus.map((menu, i) => (
                        <MenuRow
                          key={i}
                          menu={menu}
                          planType={this.state.active}
                          allowDelete={true}
                        ></MenuRow>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light"></thead>
                    <tbody>
                      <tr>
                        <td>Loading...</td>
                      </tr>
                    </tbody>
                  </Table>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
        <CreateMenuModal planType={this.state.active}></CreateMenuModal>
        <CreateMenuTransitionDrawer
          menus={this.state.menus}
          open={this.state.transitionOpen}
          onClose={() => {
            this.setState({ transitionOpen: false, editTransition: null });
          }}
          transition={this.state.editTransition}
          onCreate={(v) => {
            this.onTransitionCreate(v);
          }}
          onModify={(v) => {
            this.onTransitionModify(v);
          }}
        ></CreateMenuTransitionDrawer>
      </>
    );
  }
}

export default MenuList;
