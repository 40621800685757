import ArrowText from "components/ArrowText";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  Badge,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import Card from "reactstrap/lib/Card";
import moment from "moment";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import _ from "underscore";
import TaskDetailDrawer from "components/Drawers/TaskDetailDrawer";
import TaskCreateDrawer from "components/Drawers/TaskCreateDrawer";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import CalendarWeekView from "../CalendarWeekView";
import AppointmentCard from "../AppointmentCard";
import OutreachCard from "../OutreachCard";
import ConsultationDetailDrawer from "../Drawers/ConsultationDetailDrawer";
import OutreachDetailDrawer from "../Drawers/OutreachDetailDrawer";
import ContentCalendarCard from "components/ContentCalendarCard";
import ContentCalendarDetailDrawer from "components/Drawers/ContentCalendarDetailDrawer";

class StoreUpcomingAppointmentsWidget extends React.Component {
  state = {
    expand: false,
    taskDetail: null,
    appointments: [],
    events: [],
    loadingAppointments: false,
    loadingSocial: false,
    groups: [
      {
        _id: "1",
        name: "Marketing & Social",
        order: 1,
        emptyString: "Nothing scheduled",
        expanded: false,
      },
      {
        _id: "2",
        name: "Community Events",
        order: 2,
        emptyString: "No events scheduled",
        expanded: false,
      },
      {
        _id: "3",
        name: "Appointments",
        order: 3,
        emptyString: "No appointments scheduled",
        expanded: true,
      },
    ],
  };

  componentDidMount() {
    //this.handleTabChange(this.state.active, true);

    PubSub.subscribe(Event.APPOINTMENT.CREATED, () => {
      this.loadCalendar(this.state.dateRange);
    });

    PubSub.subscribe(Event.APPOINTMENT.MODIFIED, (appointment) => {
      this.loadCalendar(this.state.dateRange);

      if (this.state?.consultation?._id == appointment?._id) {
        this.setState({
          consultation: appointment,
        });
      }

      if (this.state?.event?._id == appointment?._id) {
        this.setState({
          event: appointment,
        });
      }

      if (this.state.detailContent?._id == appointment?._id) {
        this.setState({
          detailContent: appointment,
        });
      }
    });

    PubSub.subscribe(Event.APPOINTMENT.DELETED, () => {
      this.loadCalendar(this.state.dateRange);
    });

    // Check for new appointment updates every 15 seconds
    let interval = setInterval(() => {
      if (this.state.dateRange) {
        this.getAppointments(
          this.state?.dateRange?.startDate,
          this.state.dateRange?.endDate,
          true
        );
      }
    }, 60000);

    this.setState({
      backgroundRefresh: interval,
    });
  }

  componentWillUnmount() {
    if (this.state.backgroundRefresh) {
      clearInterval(this.state.backgroundRefresh);
    }
  }

  filterGroups(groups, filter) {
    if (filter === null || filter === undefined) {
      return groups;
    }

    return groups.filter((group) => {
      return filter.includes(group?.name);
    });
  }

  getAppointments(startDate, endDate, background = false) {
    return new Promise((resolve, reject) => {
      if (!background) {
        this.setState({
          loadingAppointments: true,
        });
      }

      let start = moment(startDate?.toISOString())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .toDate();
      let end = moment(endDate?.toISOString())
        .hour(23)
        .minutes(59)
        .second(59)
        .millisecond(999)
        .toDate();

      let filters = {
        storeID: this.props.match?.params?.storeID,
        status: { $ne: "CANCELLED" },
        startsAt: {
          $gte: { $date: start },
          $lte: { $date: end },
        },
        walkIn: { $ne: true },
      };

      let orderBy = {
        startsAt: -1,
      };

      if (this.props.match?.params?.storeID == "select") {
        return;
      }

      APIV2.getAppointmentsForStore(
        this.props.match?.params?.storeID,
        1,
        500,
        filters,
        orderBy
      )
        .then(
          (data) => {
            if (data && data.data) {
              let appointments = [];

              if (data.data.appointments.length) {
                appointments = data.data.appointments;

                appointments = appointments.map((appointment) => {
                  if (
                    appointment?.appointmentType?.includes("ec-") ||
                    appointment?.appointmentType?.includes("nc-") ||
                    appointment?.appointmentType?.includes("challenge")
                  ) {
                    appointment.group = "Appointments";
                  } else {
                    appointment.group = "Community Events";
                  }

                  return appointment;
                });
              }

              let existing = JSON.stringify(this.state.appointments);

              if (JSON.stringify(appointments) != existing) {
                console.log("UPDATE");

                this.setState(
                  {
                    appointments,
                    pagination: data.data.pagination,
                    result: data.data.result,
                  },
                  () => {
                    resolve(appointments);
                  }
                );
              } else {
                resolve(appointments);
              }
            }
          },
          (e) => {
            console.error(e);

            if (!background) {
              window.alert(
                "There was an error loading the upcoming appointments. Please reload and try again."
              );
            }

            resolve([]);
          }
        )
        .finally(() => {
          this.setState({
            loadingAppointments: false,
          });
        });
    });
  }

  getSocialContent(startDate, endDate, background = false) {
    return new Promise((resolve, reject) => {
      if (!background) {
        this.setState({
          loadingSocial: true,
        });
      }

      let start = moment(startDate?.toISOString())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .toDate();
      let end = moment(endDate?.toISOString())
        .hour(23)
        .minutes(59)
        .second(59)
        .millisecond(999)
        .toDate();

      let filters = {
        scheduledFor: {
          $gte: { $date: start },
          $lte: { $date: end },
        },
      };

      let orderBy = {
        scheduledFor: -1,
      };

      APIV2.getContentCalendar(1, 500, filters, orderBy)
        .then(
          (data) => {
            if (data?.data) {
              let content = [];

              if (data?.data?.content?.length) {
                content = data.data.content;

                content = content.map((item) => {
                  item.group = "Marketing & Social";

                  item.startsAt = item.scheduledFor;

                  return item;
                });
              }

              let existing = JSON.stringify(this.state.content);

              if (JSON.stringify(content) != existing) {
                this.setState(
                  {
                    content,
                    pagination: data.data.pagination,
                    result: data.data.result,
                  },
                  () => {
                    resolve(content);
                  }
                );
              } else {
                resolve(content);
              }
            }
          },
          (e) => {
            console.error(e);

            resolve([]);

            if (!background) {
              window.alert(
                "There was an error loading the content calendar. Please reload and try again."
              );
            }
          }
        )
        .finally(() => {
          this.setState({
            loadingSocial: false,
          });
        });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.store != this.props?.store) {
      this.loadCalendar(this.state.dateRange);
    }
  }

  taskDetailDrawer(task) {
    this.setState({
      taskDetail: task,
      detailOpen: true,
    });
  }

  async loadCalendar(dateRange) {
    if (!dateRange) {
      return;
    }

    try {
      let appointments = await this.getAppointments(
        dateRange?.startDate,
        dateRange?.endDate
      );

      let social = await this.getSocialContent(
        dateRange?.startDate,
        dateRange?.endDate
      );

      let all = appointments.concat(social);

      this.setState({
        events: all,
      });
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    return (
      <>
        {this.props.loading ? (
          <>
            <Card className="mb-4 shadow-sm border">
              <CardBody className="bg-transparent py-5 text-center">
                <Spinner size="lg" color="medium"></Spinner>
              </CardBody>
            </Card>
          </>
        ) : (
          <>
            <Card className="mb-4 border shadow-sm">
              <CardBody className="p-0">
                <CalendarWeekView
                  loading={
                    this.state.loadingAppointments || this.state.loadingSocial
                  }
                  timeslotComponent={(data) => {
                    return (
                      <>
                        {data?.group == "Appointments" ? (
                          <AppointmentCard
                            onClick={() => {
                              this.setState({
                                consultation: data,
                                detailOpen: true,
                              });
                            }}
                            appointment={data}
                            nameField={"SHORT_NAME"}
                          ></AppointmentCard>
                        ) : null}
                        {data?.group == "Community Events" ? (
                          <OutreachCard
                            onClick={() => {
                              this.setState({
                                event: data,
                                detailOutreachOpen: true,
                              });
                            }}
                            appointment={data}
                            nameField={"SHORT_NAME"}
                          ></OutreachCard>
                        ) : null}
                        {data?.group == "Marketing & Social" ? (
                          <ContentCalendarCard
                            onClick={() => {
                              this.setState({
                                detailContent: data,
                                detailContentOpen: true,
                              });
                            }}
                            content={data}
                          ></ContentCalendarCard>
                        ) : null}
                      </>
                    );
                  }}
                  data={this.state.events}
                  groups={this.filterGroups(
                    this.state.groups,
                    this.props.filter
                  )}
                  grouped={true}
                  onChange={(v) => {
                    this.loadCalendar(v);
                    this.setState({
                      dateRange: v,
                    });
                  }}
                  onRefresh={async (v) => {
                    try {
                      let appointments = await this.getAppointments(
                        v?.startDate,
                        v?.endDate
                      );
                      let social = await this.getSocialContent(
                        v?.startDate,
                        v?.endDate
                      );

                      let all = appointments.concat(social);

                      this.setState(
                        {
                          events: all,
                        },
                        () => {
                          console.log("ALL", this.state.events);
                        }
                      );
                    } catch (e) {
                      console.error(e);
                    }
                  }}
                  compareKey="startsAt"
                  emptyString={"Nothing scheduled."}
                ></CalendarWeekView>
              </CardBody>
            </Card>
          </>
        )}
        <ConsultationDetailDrawer
          consultation={this.state.consultation}
          open={this.state.detailOpen}
          onClose={() => {
            this.setState({
              consultation: null,
              detailOpen: false,
            });
          }}
          store={this.props.store}
        ></ConsultationDetailDrawer>
        <ContentCalendarDetailDrawer
          content={this.state.detailContent}
          open={this.state.detailContentOpen}
          onClose={() => {
            this.setState({
              detailContent: null,
              detailContentOpen: false,
            });
          }}
        ></ContentCalendarDetailDrawer>
        <OutreachDetailDrawer
          event={this.state.event}
          open={this.state.detailOutreachOpen}
          onClose={() => {
            this.setState({
              event: null,
              detailOutreachOpen: false,
            });
          }}
        ></OutreachDetailDrawer>
      </>
    );
  }
}

export default withRouter(StoreUpcomingAppointmentsWidget);
