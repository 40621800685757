import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
  Collapse,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import _, { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import { Dropdown, Popover, Tooltip, Whisper } from "rsuite";
import swal from "sweetalert";
import LibraryModuleEditDrawer from "components/Drawers/LibraryModuleEditDrawer";
import LibraryAllModulesDrawer from "components/Drawers/LibraryAllModulesDrawer";
import { convertTypeAcquisitionFromJson } from "typescript";
import LibrarySectionEditDrawer from "components/Drawers/LibrarySectionEditDrawer";
import LibraryTopicEditDrawer from "components/Drawers/LibraryTopicEditDrawer";
import { Link } from "react-router-dom";
import { Capacitor } from "@capacitor/core";

class LibraryModule extends React.Component {
  state = {
    loadingModule: true,
    openSection: false,
    module: null,
  };

  constructor() {
    super();

    this.dropdownRef = React.createRef();
  }

  /**
   * Fetches all categories.
   */
  getModule() {
    this.setState({
      loadingModule: true,
    });

    let loadInternal = this.isGlobal() || this.isProduction() ? true : false;

    APIV2.getLibraryModuleByID(this.props.match?.params?.moduleID)
      .then(
        (data) => {
          let mod = data.data.module;

          for (let i = 0; i < mod?.sections?.length; i++) {
            mod.sections[i].open = true;
          }

          if (!loadInternal && mod?.internal) {
            this.props.history.push("/university/library");
          }

          this.setState({
            module: mod,
          });
        },
        () => {
          window.alert("Failed to get module. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          loadingModule: false,
        });
      });
  }

  /**
   * Event handler for after asection is created
   * @param {} module
   */
  onCreateSection(module) {
    this.setState({ module, openSection: false });
  }

  /**
   * Event handler for after a section is modified.
   *
   * @param {*} module
   */
  onModifySection(module) {
    for (let i = 0; i < module?.sections?.length; i++) {
      module.sections[i].open = true;
    }

    this.setState({ module, openSection: false });
  }

  /**
   * Event handler for after a section is modified.
   *
   * @param {*} module
   */
  onCreateTopic(module) {
    for (let i = 0; i < module?.sections?.length; i++) {
      module.sections[i].open = true;
    }

    this.setState({ module, openTopic: false });
  }

  /**
   * Event handler for after a section is modified.
   *
   * @param {*} module
   */
  onModifyTopic(topic) {
    let module = this.state.module;

    for (let i = 0; i < module?.sections?.length; i++) {
      module.sections[i].open = true;
    }

    for (let i = 0; i < module?.sections?.length; i++) {
      for (let j = 0; j < module.sections[i].topics?.length; j++) {
        if (module.sections[i].topics[j].topicID == topic?._id) {
          module.sections[i].topics[j].topic = topic;
        }
      }
    }

    this.setState({ module, openTopic: false });
  }

  /**
   * Event handler for when module is modified
   *
   * @param {*} module
   */
  onModifyModule(module) {
    for (let i = 0; i < module?.sections?.length; i++) {
      module.sections[i].open = true;
    }

    this.setState({ module, editModule: false });
  }

  /**
   * Handles deleting a category
   *
   * @param {*} category
   */
  deleteSection(section) {
    swal({
      title: "Delete Section",
      text: "Are you sure you want delete this section? This will also delete all the topics within this section.",
      icon: "warning",
      buttons: ["Nevermind", "Delete"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        deletingSection: section?._id,
      });

      APIV2.deleteLibraryModuleSection(this.state.module?._id, section?._id)
        .then(
          (data) => {
            let module = data.data.module;

            for (let i = 0; i < module?.sections?.length; i++) {
              module.sections[i].open = true;
            }

            this.setState({ module });
          },
          (e) => {
            alert("Unable to delete section. Please try again.");
          }
        )
        .finally(() => {
          this.setState({ deletingSection: "" });
        });
    });
  }

  componentDidMount() {
    this.getModule();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match?.params?.moduleID != prevProps?.match?.params?.moduleID
    ) {
      this.getModule();
    }
  }

  /**
   * Checks if the current user is a global user via permissions
   *
   * @returns
   */
  isGlobal() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  /**
   * Checks if the current user is a global user via permissions
   *
   * @returns
   */
  isProduction() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.productionPermissions?.length ? true : false;
  }

  /**
   * Helper function to handle reorder events from react-beautiful-dnd library
   *
   * @param {*} list
   * @param {*} startIndex
   * @param {*} endIndex
   * @returns
   */
  reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  /**
   * Event handler for when a category drag has ended.
   *
   * @param {*} result
   * @returns
   */
  onSectionDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.modifySections,
      result.source.index,
      result.destination.index
    );

    this.setState({
      modifySections: items,
    });
  }

  onTopicDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.topicOrder,
      result.source.index,
      result.destination.index
    );

    this.setState({
      topicOrder: items,
    });
  }

  saveSectionOrder() {
    if (!this.state.modifySections?.length) {
      this.setState({
        reorderSections: false,
      });

      return;
    }

    this.setState({
      reorderSectionsSubmitting: true,
    });

    let order = this.state.modifySections;

    APIV2.reorderLibraryModuleSections(this.state.module?._id, order)
      .then(
        (data) => {
          this.setState({
            module: data.data?.module,
            reorderSections: false,
          });
        },
        (e) => {
          alert("Unable to save order of sections. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          reorderSectionsSubmitting: false,
        });
      });
  }

  saveTopicOrder(section, topics) {
    if (!topics?.length) {
      this.setState({
        reorderTopics: false,
      });

      return;
    }

    this.setState({
      reorderTopicsSubmitting: true,
    });

    let order = topics.map((topic, i) => {
      return { topicID: topic?.topicID, order: i + 1 };
    });

    APIV2.reorderLibraryModuleSectionTopics(
      this.state.module?._id,
      section?._id,
      order
    )
      .then(
        (data) => {
          let module = data.data.module;

          for (let i = 0; i < module?.sections?.length; i++) {
            module.sections[i].open = true;
          }

          this.setState({
            module,
            reorderTopics: false,
            reorderTopicsSubmitting: false,
          });
        },
        (e) => {
          alert("Unable to save order of topics. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          reorderSectionsSubmitting: false,
        });
      });
  }

  removeTopicFromSection(section, topic) {
    swal({
      title: "Remove Topic From Section",
      text: "Are you sure you want remove this topic from the section?",
      icon: "warning",
      buttons: ["Nevermind", "Remove"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        removingTopic: topic?._id,
      });

      APIV2.removeLibraryTopicFromModuleSection(
        this.state.module?._id,
        section?._id,
        topic?._id
      )
        .then(
          (data) => {
            let module = data.data.module;

            for (let i = 0; i < module?.sections?.length; i++) {
              module.sections[i].open = true;
            }

            this.setState({ module });
          },
          (e) => {
            alert("Unable to remove topic from section. Please try again.");
          }
        )
        .finally(() => {
          this.setState({ removingModule: "" });
        });
    });
  }

  render() {
    return (
      <>
        {/* Page content */}
        <div
          className="bg-white border-bottom px-4 py-4 py-md-6 mb-4"
          style={{ marginTop: "5rem" }}
        >
          {Capacitor.isNativePlatform() && (
            <div className="pt-5 d-md-none"></div>
          )}
          <Row className="align-items-center">
            <Col xs="">
              {this.state.loadingModule ? (
                <>
                  <div
                    style={{
                      height: 20,
                      width: "100%",
                      maxWidth: 300,
                      marginBottom: "1.5rem",
                      borderRadius: 6,
                    }}
                    className="skeleton"
                  ></div>
                  <div
                    style={{
                      height: 35,
                      width: "100%",
                      maxWidth: 500,
                      borderRadius: 6,
                      marginBottom: "1rem",
                    }}
                    className="skeleton"
                  ></div>
                  <div
                    style={{
                      height: 20,
                      width: "100%",
                      marginBottom: "0.25rem",
                      borderRadius: 6,
                    }}
                    className="skeleton"
                  ></div>
                </>
              ) : (
                <>
                  <p className="text-muted mb-2">
                    <div className="d-inline-block pr-2">
                      <Link to="/university/library">Library</Link>
                    </div>
                    <span>-</span>
                    <div className="d-inline-block pl-2">
                      {this.state.module?.name}
                    </div>
                  </p>
                  {this.state.module?.internal ? (
                    <h2 className="mb-0">
                      <Badge color="light" className="border">
                        INTERNAL
                      </Badge>
                    </h2>
                  ) : null}
                  <h1 className="m-0 display-3 text-dark">
                    {this.state.module?.name}
                  </h1>
                  {this.state.module?.description && (
                    <p className="m-0 text-dark font-weight-500">
                      {this.state.module.description}
                    </p>
                  )}
                </>
              )}
            </Col>
            {this.isGlobal() && this.state.editMode && (
              <Col xs="auto">
                <Button
                  color="secondary"
                  outline
                  className="btn-icon-only"
                  onClick={() => {
                    this.setState({ editModule: true });
                  }}
                >
                  <i className="mdi mdi-pencil"></i>
                </Button>
              </Col>
            )}
          </Row>
        </div>

        <Container fluid>
          {/* Table */}
          {this.isGlobal() && (
            <>
              <div className="text-right mb-4">
                <Button
                  onClick={() => {
                    this.setState({ editMode: !this.state.editMode });
                  }}
                  color="dark"
                  outline
                  className=""
                >
                  {this.state.editMode ? "Done Editing" : "Edit"}
                </Button>
              </div>
            </>
          )}
          {this.isGlobal() && this.state.editMode && (
            <>
              <Row className="align-items-center mb-4">
                <Col xs=""></Col>
                <Col xs="auto">
                  {this.state.reorderSections ? (
                    <>
                      <Button
                        color="secondary"
                        outline
                        onClick={() => {
                          this.setState({
                            reorderSections: false,
                            modifySections: [],
                          });
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        disabled={this.state.reorderSectionsSubmitting}
                        onClick={() => {
                          this.saveSectionOrder();
                        }}
                      >
                        {this.state.reorderSectionsSubmitting ? (
                          <>
                            <Spinner size="sm"></Spinner>
                          </>
                        ) : (
                          <>Save Order</>
                        )}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Whisper
                        trigger="hover"
                        placement="autoHorizontal"
                        delayOpen={400}
                        speaker={
                          <Tooltip>
                            <p className="m-0">Reorder sections</p>
                          </Tooltip>
                        }
                      >
                        <Button
                          color="secondary"
                          outline
                          className="btn-icon-only"
                          onClick={() => {
                            this.setState({
                              reorderSections: true,
                              modifySections: JSON.parse(
                                JSON.stringify(
                                  this.state.module?.sections ?? []
                                )
                              ),
                            });
                          }}
                        >
                          <i className="mdi mdi-swap-vertical-bold"></i>
                        </Button>
                      </Whisper>

                      <Whisper
                        trigger="hover"
                        placement="autoHorizontal"
                        delayOpen={400}
                        speaker={
                          <Tooltip>
                            <p className="m-0">Add section</p>
                          </Tooltip>
                        }
                      >
                        <Button
                          color="primary"
                          className="btn-icon-only"
                          onClick={() => {
                            this.setState({ openSection: true });
                          }}
                        >
                          <i className="mdi mdi-plus"></i>
                        </Button>
                      </Whisper>
                    </>
                  )}
                </Col>
              </Row>
            </>
          )}
          {this.state.loadingModule ? (
            <>
              <Card className="border">
                <CardBody>
                  <Row className="align-items-center">
                    <Col xs="auto">
                      <Spinner color="dark"></Spinner>
                    </Col>
                    <Col xs="">
                      <h2 className="m-0">Hang Tight...</h2>
                      <p className="m-0">Fetching your library now.</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </>
          ) : (
            <>
              {this.state.module?.sections?.length ? (
                <>
                  {this.state.reorderSections ? (
                    <>
                      <DragDropContext
                        onDragEnd={this.onSectionDragEnd.bind(this)}
                      >
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                background: snapshot.isDraggingOver
                                  ? "var(--primary-superlight)"
                                  : "",
                                borderRadius: 6,
                              }}
                            >
                              {this.state.modifySections.map(
                                (section, index) => (
                                  <Draggable
                                    key={section._id}
                                    draggableId={section._id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className="my-2"
                                      >
                                        <Card
                                          className={`cursor-pointer ${
                                            snapshot.isDragging ? "shadow" : ""
                                          } border`}
                                        >
                                          <CardBody>
                                            <Row className="align-items-center">
                                              <Col
                                                xs="auto"
                                                className="pr-0 pl-2"
                                              >
                                                <i
                                                  className="mdi mdi-drag text-dark"
                                                  style={{ fontSize: 25 }}
                                                ></i>
                                              </Col>
                                              <Col xs="">
                                                <h3 className="m-0 text-dark">
                                                  {section?.name}
                                                </h3>
                                                <p className="m-0 small text-muted">
                                                  {section?.description}
                                                </p>
                                              </Col>
                                            </Row>
                                          </CardBody>
                                        </Card>
                                      </div>
                                    )}
                                  </Draggable>
                                )
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </>
                  ) : (
                    <>
                      {this.state.module?.sections?.map((section, i) => (
                        <Card
                          className="border shadow bg-white mb-3"
                          key={section?._id}
                        >
                          <CardHeader className="cursor-pointer">
                            <Row className="align-items-center">
                              <Col
                                xs="auto"
                                className="pr-0 pl-2"
                                onClick={() => {
                                  let module = this.state.module;
                                  let sections = module?.sections;

                                  const idx = _.findIndex(sections, {
                                    _id: section?._id,
                                  });

                                  if (idx >= 0) {
                                    sections[idx].open = !sections[idx].open;
                                  }

                                  this.setState({
                                    module,
                                  });
                                }}
                              >
                                <Button
                                  color="dark"
                                  outline
                                  className="border-0 btn-icon-only"
                                >
                                  <i
                                    className={`mdi mdi-${
                                      section?.open
                                        ? "chevron-down"
                                        : "chevron-right"
                                    }`}
                                    style={{ fontSize: 28 }}
                                  ></i>
                                </Button>
                              </Col>
                              <Col
                                xs=""
                                onClick={() => {
                                  let module = this.state.module;
                                  let sections = module?.sections;

                                  const idx = _.findIndex(sections, {
                                    _id: section?._id,
                                  });

                                  if (idx >= 0) {
                                    sections[idx].open = !sections[idx].open;
                                  }

                                  this.setState({
                                    module,
                                  });
                                }}
                              >
                                <h2 className="m-0 text-dark">
                                  {section?.name}
                                </h2>
                                {section?.description ? (
                                  <>
                                    <p className="m-0 text-muted">
                                      {section?.description}
                                    </p>
                                  </>
                                ) : null}
                              </Col>
                              {this.isGlobal() &&
                                this.state.editMode &&
                                this.state.reorderTopics != section?._id && (
                                  <Col xs="auto">
                                    <Whisper
                                      trigger="hover"
                                      placement="autoHorizontal"
                                      delayOpen={400}
                                      speaker={
                                        <Tooltip>
                                          <p className="m-0">
                                            Modify section name &amp;
                                            description
                                          </p>
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        size="sm"
                                        color="dark"
                                        outline
                                        className="btn-icon-only"
                                        onClick={() => {
                                          this.setState({
                                            modifySection: section,
                                            openSection: true,
                                          });
                                        }}
                                      >
                                        <i className="mdi mdi-pencil"></i>
                                      </Button>
                                    </Whisper>

                                    <Whisper
                                      trigger="hover"
                                      placement="autoHorizontal"
                                      delayOpen={400}
                                      speaker={
                                        <Tooltip>
                                          <p className="m-0">Add topic</p>
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        size="sm"
                                        color="primary"
                                        className="btn-icon-only"
                                        onClick={() => {
                                          this.setState({
                                            openTopic: true,
                                            modifySection: section,
                                            modifyTopic: null,
                                          });
                                        }}
                                      >
                                        <i className="mdi mdi-plus"></i>
                                      </Button>
                                    </Whisper>
                                  </Col>
                                )}
                            </Row>
                          </CardHeader>
                          <Collapse isOpen={section?.open}>
                            <CardBody
                              className={`${
                                this.state.reorderTopics == section?._id
                                  ? "px-2 py-2"
                                  : "p-0"
                              }`}
                            >
                              {this.state.reorderTopics == section?._id ? (
                                <>
                                  <DragDropContext
                                    onDragEnd={this.onTopicDragEnd.bind(this)}
                                  >
                                    <Droppable droppableId="droppable">
                                      {(provided, snapshot) => (
                                        <div
                                          {...provided.droppableProps}
                                          ref={provided.innerRef}
                                          style={{
                                            background: snapshot.isDraggingOver
                                              ? "var(--primary-superlight)"
                                              : "",
                                            borderRadius: 0,
                                          }}
                                        >
                                          {this.state.topicOrder.map(
                                            (topic, index) => (
                                              <Draggable
                                                key={topic?.topicID}
                                                draggableId={topic?.topicID}
                                                index={index}
                                              >
                                                {(provided, snapshot) => (
                                                  <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={`py-2 mx-2`}
                                                  >
                                                    <Card
                                                      className={`cursor-pointer ${
                                                        snapshot.isDragging
                                                          ? "shadow"
                                                          : ""
                                                      } border`}
                                                    >
                                                      <CardBody className="py-2 px-2">
                                                        <Row className="align-items-center">
                                                          <Col
                                                            xs="auto"
                                                            className="pr-0 pl-3"
                                                          >
                                                            <i
                                                              className="mdi mdi-drag text-dark"
                                                              style={{
                                                                fontSize: 25,
                                                              }}
                                                            ></i>
                                                          </Col>
                                                          <Col xs="">
                                                            <h3 className="m-0 text-dark">
                                                              {
                                                                topic?.topic
                                                                  ?.name
                                                              }
                                                            </h3>
                                                            <p className="m-0 small text-muted">
                                                              {
                                                                topic?.topic
                                                                  ?.description
                                                              }
                                                            </p>
                                                          </Col>
                                                        </Row>
                                                      </CardBody>
                                                    </Card>
                                                  </div>
                                                )}
                                              </Draggable>
                                            )
                                          )}
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Droppable>
                                  </DragDropContext>
                                </>
                              ) : (
                                <>
                                  {section?.topics?.length ? (
                                    <>
                                      {section?.topics?.map((topic, k) => (
                                        <div
                                          className={`px-4 py-3 ${
                                            !this.isGlobal() &&
                                            !this.isProduction() &&
                                            topic?.topic?.internal &&
                                            "d-none"
                                          } ${
                                            k != section?.topics?.length - 1 &&
                                            "border-bottom"
                                          } bg-superlight--hover cursor-pointer`}
                                          key={topic?.topic?._id}
                                        >
                                          <Row className="align-items-center">
                                            <Col
                                              xs=""
                                              onClick={() => {
                                                this.props.history.push(
                                                  `/university/library/topics/${topic?.topic?._id}?moduleID=${this.state.module?._id}&sectionID=${section?._id}`
                                                );
                                              }}
                                            >
                                              <h3 className="m-0">
                                                {topic?.topic?.internal ? (
                                                  <Badge
                                                    color="light"
                                                    className="mr-2 border"
                                                    style={{
                                                      position: "relative",
                                                      top: -1,
                                                    }}
                                                  >
                                                    INTERNAL
                                                  </Badge>
                                                ) : null}
                                                {topic?.topic?.name}
                                              </h3>
                                              {topic?.topic?.description ? (
                                                <>
                                                  <p className="small m-0">
                                                    {topic?.topic?.description}
                                                  </p>
                                                </>
                                              ) : null}
                                            </Col>
                                            <Col xs="auto">
                                              {this.isGlobal() &&
                                                this.state.editMode && (
                                                  <Whisper
                                                    ref={this.dropdownRef}
                                                    trigger="click"
                                                    placement={
                                                      "autoVerticalEnd"
                                                    }
                                                    speaker={
                                                      <Popover full>
                                                        <Dropdown.Menu>
                                                          <Dropdown.Item
                                                            onClick={() => {
                                                              this.setState({
                                                                modifySection:
                                                                  section,
                                                                modifyTopic:
                                                                  topic?.topic,
                                                                openTopic: true,
                                                              });

                                                              this.dropdownRef.current.close();
                                                            }}
                                                          >
                                                            Modify
                                                          </Dropdown.Item>
                                                          <Dropdown.Item
                                                            divider
                                                          ></Dropdown.Item>
                                                          <Dropdown.Item
                                                            className="text-danger"
                                                            onClick={() => {
                                                              this.dropdownRef.current.close();

                                                              this.removeTopicFromSection(
                                                                section,
                                                                topic?.topic
                                                              );
                                                            }}
                                                          >
                                                            Remove From Section
                                                          </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                      </Popover>
                                                    }
                                                  >
                                                    <Button
                                                      size="sm"
                                                      color="dark"
                                                      outline
                                                      className="btn-icon-only"
                                                    >
                                                      <i className="mdi mdi-dots-horizontal"></i>
                                                    </Button>
                                                  </Whisper>
                                                )}
                                              <Button
                                                size="sm"
                                                color="dark"
                                                outline
                                                className="btn-icon-only border-0"
                                                onClick={() => {
                                                  this.props.history.push(
                                                    `/university/library/topics/${topic?.topic?._id}?moduleID=${this.state.module?._id}&sectionID=${section?._id}`
                                                  );
                                                }}
                                              >
                                                <i className="mdi mdi-chevron-right"></i>
                                              </Button>
                                            </Col>
                                          </Row>
                                        </div>
                                      ))}
                                    </>
                                  ) : (
                                    <>
                                      <p className="m-0 p-4">
                                        Section is empty.
                                        {this.isGlobal() &&
                                          this.state.editMode &&
                                          " Add a module to get started."}
                                      </p>
                                    </>
                                  )}
                                </>
                              )}
                            </CardBody>
                            {this.isGlobal() && this.state.editMode && (
                              <>
                                <CardFooter>
                                  <Row className="align-items-center">
                                    <Col xs="">
                                      {this.state.reorderTopics !=
                                        section?._id && (
                                        <Whisper
                                          trigger="hover"
                                          placement="autoHorizontal"
                                          delayOpen={400}
                                          speaker={
                                            <Tooltip>
                                              <p className="m-0">
                                                Delete section
                                              </p>
                                            </Tooltip>
                                          }
                                        >
                                          <Button
                                            size="sm"
                                            color="danger"
                                            outline
                                            className="btn-icon-only"
                                            onClick={() =>
                                              this.deleteSection(section)
                                            }
                                          >
                                            <i className="mdi mdi-trash-can"></i>
                                          </Button>
                                        </Whisper>
                                      )}
                                    </Col>
                                    <Col xs="auto">
                                      {this.state.reorderTopics ==
                                      section?._id ? (
                                        <>
                                          <Button
                                            color="secondary"
                                            outline
                                            size="sm"
                                            onClick={() => {
                                              this.setState({
                                                reorderTopics: false,
                                                moduleTopics: [],
                                              });
                                            }}
                                          >
                                            Cancel
                                          </Button>
                                          <Button
                                            color="primary"
                                            size="sm"
                                            disabled={
                                              this.state.reorderTopicsSubmitting
                                            }
                                            onClick={() => {
                                              this.saveTopicOrder(
                                                section,
                                                this.state.topicOrder
                                              );
                                            }}
                                          >
                                            {this.state
                                              .reorderTopicsSubmitting ? (
                                              <>
                                                <Spinner size="sm"></Spinner>
                                              </>
                                            ) : (
                                              <>Save Order</>
                                            )}
                                          </Button>
                                        </>
                                      ) : (
                                        <Whisper
                                          trigger="hover"
                                          placement="autoHorizontal"
                                          delayOpen={400}
                                          speaker={
                                            <Tooltip>
                                              <p className="m-0">
                                                Reorder modules
                                              </p>
                                            </Tooltip>
                                          }
                                        >
                                          <Button
                                            className="btn-icon-only"
                                            size="sm"
                                            outline
                                            color="dark"
                                            onClick={() => {
                                              this.setState({
                                                reorderTopics: section?._id,
                                                topicOrder: JSON.parse(
                                                  JSON.stringify(
                                                    section?.topics ?? []
                                                  )
                                                ),
                                              });
                                            }}
                                          >
                                            <i className="mdi mdi-swap-vertical-bold"></i>
                                          </Button>
                                        </Whisper>
                                      )}
                                    </Col>
                                  </Row>
                                </CardFooter>
                              </>
                            )}
                          </Collapse>
                        </Card>
                      ))}
                    </>
                  )}
                </>
              ) : (
                <>
                  <Card className="border">
                    <CardBody>
                      <Row className="align-items-center">
                        <Col xs="">
                          <h2 className="m-0">No sections in this module.</h2>
                          <p className="m-0">
                            Check back later or reload to try again.
                          </p>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </>
              )}
            </>
          )}
        </Container>
        <LibrarySectionEditDrawer
          open={this.state.openSection}
          section={this.state.modifySection}
          onCreate={this.onCreateSection.bind(this)}
          onModify={this.onModifySection.bind(this)}
          module={this.state.module}
          onClose={() => {
            this.setState({ openSection: false });
          }}
        ></LibrarySectionEditDrawer>
        <LibraryTopicEditDrawer
          open={this.state.openTopic}
          module={this.state.module}
          section={this.state.modifySection}
          topic={this.state.modifyTopic}
          onCreate={(module) => {
            this.onCreateTopic(module);
          }}
          onModify={(module) => {
            this.onModifyTopic(module);
          }}
          onClose={() => {
            this.setState({ openTopic: false });
          }}
        ></LibraryTopicEditDrawer>
        <LibraryModuleEditDrawer
          open={this.state.editModule}
          module={this.state.module}
          onModify={(module) => {
            this.onModifyModule(module);
          }}
          onClose={() => {
            this.setState({ editModule: false });
          }}
        ></LibraryModuleEditDrawer>
      </>
    );
  }
}

export default LibraryModule;
