import ArrowText from "components/ArrowText";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  Badge,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import Card from "reactstrap/lib/Card";
import moment from "moment";
import CustomerOrderDrawer from "components/Drawers/CustomerOrderDrawer";
import CustomerCreateOrderDrawer from "components/Drawers/CustomerCreateOrderDrawer";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import CustomerAllOrdersDrawer from "components/Drawers/CustomerAllOrdersDrawer";
import _ from "underscore";
import CustomerInvoiceDrawer from "components/Drawers/CustomerInvoiceDrawer";
import CustomerAllInvoicesDrawer from "components/Drawers/CustomerAllInvoicesDrawer";
import CustomerCreateInvoiceDrawer from "components/Drawers/CustomerCreateInvoiceDrawer";
import CustomerCreateConsultationDrawer from "components/Drawers/CustomerCreateConsultationDrawer";
import swal from "sweetalert";
import CustomerModifyConsultationDrawer from "components/Drawers/CustomerModifyConsultationDrawer";
import NewConsultationDrawer from "../Drawers/NewConsultationDrawer";
import ConsultationDetailDrawer from "components/Drawers/ConsultationDetailDrawer";

class CustomerConsultationsWidget extends React.Component {
  state = {
    expand: false,
    consultation: null,
  };

  componentDidMount() {
    if (this.props.customer?._id) {
      this.loadAppointments();
    }

    PubSub.subscribe(Event.APPOINTMENT.CREATED, () => {
      this.loadAppointments(false);
      PubSub.publish(Event.CUSTOMER.RELOAD);
    });

    PubSub.subscribe(Event.APPOINTMENT.MODIFIED, (appointment) => {
      this.loadAppointments(false);

      if (this.state?.consultation?._id == appointment?._id) {
        this.setState({
          consultation: appointment,
        });

        PubSub.publish(Event.CUSTOMER.MODIFIED, this.props.customer);
        PubSub.publish(Event.CUSTOMER.RELOAD);
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.customer?._id !== this.props.customer?._id &&
      this.props.customer?._id
    ) {
      this.loadAppointments();
    }
  }

  openModifyDrawer(consultation) {
    this.setState({
      consultation,
      modifyDrawer: true,
    });
  }

  loadAppointments(showLoader = true) {
    if (showLoader) {
      this.setState({
        loading: true,
      });
    }

    APIV2.getAppointmentsForStore(
      this.props.customer?.storeID,
      1,
      500,
      {
        customerID: this.props.customer?._id,
        storeID: this.props.customer?.storeID,
        status: { $ne: "CANCELLED" },
      },
      {
        startsAt: -1,
      }
    )
      .then(
        (data) => {
          let appointments = data?.data?.appointments?.length
            ? data.data.appointments
            : [];

          let consult = _.findWhere(appointments, {
            appointmentType: "nc-consult",
            status: "COMPLETED",
          });

          let checkin = _.findWhere(appointments, {
            appointmentType: "ec-consult",
            status: "COMPLETED",
          });

          this.setState({
            appointments,
            receivedConsult: consult ? true : false,
            receivedCheckin: checkin ? true : false,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    return (
      <>
        {this.props.loading ? (
          <>
            <Card className="mb-4 shadow border">
              <CardBody className="bg-transparent py-5 text-center">
                <Spinner size="lg" color="medium"></Spinner>
              </CardBody>
            </Card>
          </>
        ) : (
          <>
            <Card className="mb-4 border shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <Col>
                    <h3 className="text-dark mb-0">Appointments</h3>
                  </Col>
                  <Col xs="auto">
                    {/*<Button
                      size="sm"
                      color="secondary"
                      outline
                      onClick={() => {
                        this.setState({
                          modified: true,
                          modifyImage: true,
                          imageURL: "",
                        });
                      }}
                    >
                      Mark All <i className="mdi mdi-chevron-down"></i>
                    </Button>*/}

                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => {
                        this.setState({ openSchedule: true });
                      }}
                    >
                      New
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                {this.state?.loading && (
                  <div className="px-3 py-5 text-center">
                    <Spinner size="sm" color="dark"></Spinner>
                  </div>
                )}
                {!this.state.appointments?.length && !this.state.loading && (
                  <div className="px-3 py-5">
                    <Row>
                      <Col xs="12">
                        <h3 className="m-0">No appointments recorded.</h3>
                        <p>
                          {this.props?.customer?.name
                            ? StringUtils.getFirstName(
                                this.props?.customer?.name
                              )
                            : "This member"}{" "}
                          does not have any appointments recorded.
                        </p>
                      </Col>
                    </Row>
                  </div>
                )}

                {this.state?.appointments?.map((consultation, i) => (
                  <div key={consultation?._id}>
                    <div className="px-4 py-3 cursor-pointer bg-superlight--hover">
                      <Row
                        onClick={() => {
                          this.openModifyDrawer(consultation);
                        }}
                        className={`align-items-center`}
                      >
                        <Col xs="">
                          <p
                            className="mb-1 mt-0 small"
                            style={{ lineHeight: 1.2 }}
                          >
                            {consultation?.status == "COMPLETED"
                              ? "Completed"
                              : null}
                            {consultation.status == "CONFIRMED"
                              ? "Confirmed"
                              : null}
                            {consultation?.status == "CANCELLED"
                              ? "Cancelled"
                              : null}
                            {consultation?.status == "NO SHOW"
                              ? "No Show"
                              : null}
                          </p>
                          <h3 className="mb-0">{consultation?.name}</h3>
                          <p
                            className="mt-1 mb-0 small"
                            style={{ lineHeight: 1.2 }}
                          >
                            {consultation?.status == "COMPLETED"
                              ? "Completed "
                              : "Scheduled for "}
                            {moment(consultation?.startsAt).format(
                              "MM/DD/YYYY hh:mm a"
                            )}
                            {consultation?.status == "COMPLETED" &&
                            consultation?.staff?.name
                              ? " by " + consultation?.staff?.name
                              : null}
                          </p>
                        </Col>
                        <Col xs="auto">
                          {consultation?.storeID ==
                          this.props?.match?.params?.storeID ? (
                            <>
                              <Button
                                className="btn-icon-only mb-0"
                                size="sm"
                                color="dark"
                                outline
                              >
                                <i className="mdi mdi-chevron-right"></i>
                              </Button>
                            </>
                          ) : (
                            <>
                              <Badge color="medium">
                                From{" "}
                                {consultation?.store?.name
                                  ? consultation?.store?.name
                                      ?.toLowerCase()
                                      ?.replace("project leannation", "PLN")
                                  : "Another Store"}
                              </Badge>
                            </>
                          )}
                        </Col>
                      </Row>
                    </div>
                    {i != this.state?.appointments?.length - 1 && (
                      <hr className="m-0"></hr>
                    )}
                  </div>
                ))}
              </CardBody>
            </Card>
          </>
        )}
        <NewConsultationDrawer
          customer={this.props.customer}
          store={this.props.store}
          open={this.state.openSchedule}
          receivedConsult={this.state.receivedConsult}
          receivedCheckin={this.state.receivedCheckin}
          onClose={() => {
            this.setState({ openSchedule: false });
          }}
        ></NewConsultationDrawer>
        <ConsultationDetailDrawer
          consultation={this.state.consultation}
          open={this.state.modifyDrawer}
          onClose={() => {
            this.setState({
              consultation: null,
              modifyDrawer: false,
            });
          }}
          store={this.props.store}
        ></ConsultationDetailDrawer>
      </>
    );
  }
}

export default withRouter(CustomerConsultationsWidget);
