import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  BarChart,
  BarList,
  Card,
  DonutChart,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import {
  Axis,
  Chart,
  Coordinate,
  Interaction,
  Interval,
  Legend,
  Tooltip,
} from "bizcharts";
import ChartMetricHeader from "./ChartMetricHeader";

class ActiveMemberDistributionPieChartCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    tab: "overall",
    metricNames: ["member_new", "member_reactivate"],
  };

  /**
   * Fetches the order data by week for the given stores & date range
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchData(cubejsApi, stores, dateRange, metricNames = []) {
    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["SubscriptionFulfillmentReports.totalActiveCount"],
          order: {
            "SubscriptionFulfillmentReports.createdat": "asc",
          },

          timeDimensions: [
            {
              dimension: "SubscriptionFulfillmentReports.fulfillmentdate",
              dateRange: [dateRange[0], dateRange[1]],
            },
          ],
          filters: [
            {
              member: "SubscriptionFulfillmentReports.storeid",
              operator: "equals",
              values: stores,
            },
            {
              member: "SubscriptionFulfillmentReports.fulfillmentdate",
              operator: "beforeDate",
              values: [moment().toISOString()],
            },
          ],
          dimensions: ["Stores.name", "Stores.nsoStage"],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          if (!data?.length) {
            return resolve(null);
          }

          let total = 0;
          let liveTotal = 0;
          let prelaunchTotal = 0;

          data = data.map((item) => {
            total += item["SubscriptionFulfillmentReports.totalActiveCount"];

            if (item["Stores.nsoStage"] == "Prelaunch") {
              prelaunchTotal +=
                item["SubscriptionFulfillmentReports.totalActiveCount"];
            }

            if (item["Stores.nsoStage"] == "Live") {
              liveTotal +=
                item["SubscriptionFulfillmentReports.totalActiveCount"];
            }

            console.log("HER HERE");

            return {
              storeName: item["Stores.name"]
                ?.replace("Project LeanNation", "PLN")
                ?.trim(),

              count: item["SubscriptionFulfillmentReports.totalActiveCount"],
              nsoStage: item["Stores.nsoStage"],
              color: StringUtils.randomChartColor(),
            };
          });

          let outByStore2 = data.map((item) => {
            console.log(item?.nsoStage);

            return {
              storeName: item?.storeName,
              Members: Math.round(item?.count),
              color: item?.color,
              nsoStage: item?.nsoStage,
            };
          });

          outByStore2 = _.sortBy(outByStore2, "Members");
          outByStore2.reverse();

          let prelaunch = _.filter(outByStore2, (item) => {
            return item?.nsoStage == "Prelaunch";
          });

          let live = _.filter(outByStore2, (item) => {
            return item?.nsoStage == "Live";
          });

          console.log(live); //debug

          let liveColors = live.map((item) => {
            return item.color;
          });

          let prelaunchColors = prelaunch.map((item) => {
            return item.color;
          });

          outByStore2 = _.sortBy(outByStore2, "Members");
          outByStore2.reverse();

          prelaunch = _.sortBy(prelaunch, "Members");
          prelaunch.reverse();

          live = _.sortBy(live, "Members");
          live.reverse();

          return resolve({
            byStore: outByStore2,
            prelaunch,
            live,
            total,
            liveTotal,
            prelaunchTotal,
            liveColors,
            prelaunchColors,
            allColors: outByStore2.map((item) => {
              return item.color;
            }),
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async loadDetailedReport(stores, dateRange) {
    this.setState({
      loading: true,
      dataAvailable: false,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!stores?.length || !dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchData(
        cubejsApi,
        stores,
        dateRange,
        this.state.metricNames
      );
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load total new members by store.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });

      return;
    }

    this.setState({
      currentCount: currentCount,
      loading: false,
      byStore: currentCount?.byStore,
      prelaunch: currentCount?.prelaunch,
      live: currentCount?.live,
      byStoreColors: currentCount?.allColors,
      total: currentCount?.total,
      liveTotal: currentCount?.liveTotal,
      prelaunchTotal: currentCount?.prelaunchTotal,
      liveColors: currentCount?.liveColors,
      prelaunchColors: currentCount?.prelaunchColors,
    });

    if (typeof this.props.onLoaded == "function") {
      this.props.onLoaded();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stores != prevProps?.stores ||
      this.props.dateRange != prevProps?.dateRange
    ) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload != prevProps.reload && this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  componentDidMount() {
    if (this.props.store && this.props.dateRange) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  render() {
    return (
      <>
        <Card marginTop="mt-0">
          <Row className="align-items-center">
            <Col xs="">
              <h3 className="m-0 text-dark">Active Member Distribution</h3>
            </Col>
            <Col xs="auto">
              <Button
                size="sm"
                outline
                color="dark"
                className="border-0 btn-icon-only"
                disabled={this.state.loading}
                onClick={() => {
                  this.loadDetailedReport(
                    this.props.stores,
                    this.props.dateRange
                  );
                }}
              >
                {this.state.loading ? (
                  <Spinner size="sm"></Spinner>
                ) : (
                  <i className="mdi mdi-refresh"></i>
                )}
              </Button>
            </Col>
          </Row>
          <TabList
            color="orange"
            defaultValue="overall"
            handleSelect={(value) => {
              this.setState({
                tab: value,
              });
            }}
            marginTop="mt-3"
            disabled={this.state.loading || !this.state.dataAvailable}
          >
            <Tab value="overall" text="All Stores" />
            <Tab value="live" text="Live Stores" />
            <Tab value="prelaunch" text="Prelaunch Stores" />
          </TabList>

          {this.state.loading ? (
            <div
              className="skeleton mt-3"
              style={{ height: "calc(327px + 1rem)", width: "100%" }}
            >
              &nbsp;
            </div>
          ) : (
            <>
              {this.state.dataAvailable ? (
                <>
                  {this.state?.tab == "overall" ? (
                    <>
                      <div className="mt-3 mx--4 px-4 ">
                        <Row className="align-items-center">
                          <Col xs="12" sm="12" md="6">
                            <div className="pt-4">
                              <DonutChart
                                data={this.state.byStore}
                                category={"Members"}
                                dataKey="storeName"
                                colors={this.state.byStoreColors}
                                valueFormatter={(number) => {
                                  return (
                                    "~" +
                                    StringUtils.numberFormat(number) +
                                    " Members"
                                  );
                                }}
                                height="h-80"
                              />
                            </div>
                          </Col>
                          <Col md="6" className="mt-3 mt-md-0">
                            <div
                              className="pt-4 mb--4 pb-4"
                              style={{
                                maxHeight: 368,
                                overflowY: "auto",
                                overflowX: "hidden",
                              }}
                            >
                              <BarList
                                data={this.state.byStore?.map((item) => {
                                  return {
                                    name: item?.storeName,
                                    value: item?.Members,
                                  };
                                })}
                                valueFormatter={(number) => {
                                  return (
                                    "~" +
                                    StringUtils.numberFormat(number) +
                                    " Members " +
                                    `[${
                                      this.state.total
                                        ? (
                                            (number / this.state.total) *
                                            100
                                          ).toFixed(1)
                                        : 0
                                    }%]`
                                  );
                                }}
                                color="orange"
                                showAnimation={true}
                                marginTop="mt-0"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </>
                  ) : null}
                  {this.state?.tab == "prelaunch" ? (
                    <>
                      {this.state.prelaunch?.length ? (
                        <>
                          <div className="mt-3 mx--4 px-4 ">
                            <Row className="align-items-center">
                              <Col xs="12" sm="12" md="6">
                                <div className="pt-4">
                                  <DonutChart
                                    data={this.state.prelaunch}
                                    category={"Members"}
                                    dataKey="storeName"
                                    colors={this.state.prelaunchColors}
                                    valueFormatter={(number) => {
                                      return (
                                        "~" +
                                        StringUtils.numberFormat(number) +
                                        " Members"
                                      );
                                    }}
                                    height="h-80"
                                  />
                                </div>
                              </Col>
                              <Col md="6" className="mt-3 mt-md-0">
                                <div
                                  className="pt-4 mb--4 pb-4"
                                  style={{
                                    maxHeight: 368,
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                  }}
                                >
                                  <BarList
                                    data={this.state.prelaunch?.map((item) => {
                                      return {
                                        name: item?.storeName,
                                        value: item?.Members,
                                      };
                                    })}
                                    valueFormatter={(number) => {
                                      return (
                                        "~" +
                                        StringUtils.numberFormat(number) +
                                        " Members " +
                                        `[${
                                          this.state.prelaunchTotal
                                            ? (
                                                (number /
                                                  this.state.prelaunchTotal) *
                                                100
                                              ).toFixed(1)
                                            : 0
                                        }%]`
                                      );
                                    }}
                                    color="orange"
                                    showAnimation={true}
                                    marginTop="mt-0"
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="d-flex align-items-center justify-content-center"
                            style={{
                              height: "calc(327px + 1rem)",
                              width: "100%",
                            }}
                          >
                            <p className="m-0">
                              No prelaunch store available.
                              {this.state.error ? ` ${this.state.error}` : null}
                            </p>
                          </div>
                        </>
                      )}
                    </>
                  ) : null}
                  {this.state?.tab == "live" ? (
                    <>
                      {this.state.live?.length ? (
                        <>
                          <div className="mt-3 mx--4 px-4 ">
                            <Row className="align-items-center">
                              <Col xs="12" sm="12" md="6">
                                <div className="pt-4">
                                  <DonutChart
                                    data={this.state.live}
                                    category={"Members"}
                                    dataKey="storeName"
                                    colors={this.state.liveColors}
                                    valueFormatter={(number) => {
                                      return (
                                        "~" +
                                        StringUtils.numberFormat(number) +
                                        " Members"
                                      );
                                    }}
                                    height="h-80"
                                  />
                                </div>
                              </Col>
                              <Col md="6" className="mt-3 mt-md-0">
                                <div
                                  className="pt-4 mb--4 pb-4"
                                  style={{
                                    maxHeight: 368,
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                  }}
                                >
                                  <BarList
                                    data={this.state.live?.map((item) => {
                                      return {
                                        name: item?.storeName,
                                        value: item?.Members,
                                      };
                                    })}
                                    valueFormatter={(number) => {
                                      return (
                                        "~" +
                                        StringUtils.numberFormat(number) +
                                        " Members " +
                                        `[${
                                          this.state.liveTotal
                                            ? (
                                                (number /
                                                  this.state.liveTotal) *
                                                100
                                              ).toFixed(1)
                                            : 0
                                        }%]`
                                      );
                                    }}
                                    color="orange"
                                    showAnimation={true}
                                    marginTop="mt-0"
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="d-flex align-items-center justify-content-center"
                            style={{
                              height: "calc(327px + 1rem)",
                              width: "100%",
                            }}
                          >
                            <p className="m-0">
                              No live store available.
                              {this.state.error ? ` ${this.state.error}` : null}
                            </p>
                          </div>
                        </>
                      )}
                    </>
                  ) : null}
                </>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "calc(327px + 1rem)", width: "100%" }}
                >
                  <p className="m-0">
                    No data available.
                    {this.state.error ? ` ${this.state.error}` : null}
                  </p>
                </div>
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default ActiveMemberDistributionPieChartCard;
