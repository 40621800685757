import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
  Collapse,
  Badge,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import StoreView from "views/examples/StoreView";

import APIV2 from "lib/APIV2";
import Spinner from "reactstrap/lib/Spinner";
import { withRouter } from "react-router";
import moment from "moment";
import { ProgressBar } from "@tremor/react";
import _ from "underscore";
import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";
import LinkUtils from "lib/LinkUtils";
import { Tooltip, Whisper } from "rsuite";
import TaskCreateDrawer from "components/Drawers/TaskCreateDrawer";
import swal from "sweetalert";

class StoreNSOWidget extends React.Component {
  state = {
    store: null,
    expanded: [],
  };

  loadNSOTemplate() {
    this.setState({
      templateLoading: true,
    });

    APIV2.getNSOTemplate()
      .then(
        (data) => {
          let template = data?.data?.template;

          let sections = _.filter(_.keys(template), (key) => {
            return (
              key != "_id" &&
              key != "recordType" &&
              key != "createdAt" &&
              key != "modifiedAt" &&
              !key?.includes("items_") &&
              !key?.includes("retail_") &&
              !key?.includes("opening_")
            );
          });

          sections = _.map(sections, (section) => {
            let s = template[section];

            s.key = section;

            return s;
          });

          sections = _.sortBy(sections, "order");

          if (!this.props.globalMode) {
            for (let i = 0; i < sections.length; i++) {
              let actions = sections[i].actions;

              actions = _.filter(actions, (action) => {
                return action.visibleTo == "All Parties";
              });

              sections[i].actions = actions;
            }
          }

          this.setState({
            template,
            sections,
          });
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          templateLoading: false,
        });
      });
  }

  isComplete(item) {
    let nso = this.props.store?.nso;

    if (nso?.[item?._id]?.completed) {
      return true;
    }

    return false;
  }

  taskCreated(item) {
    let nso = this.props.store?.nso;

    if (nso?.[item?._id]?.taskCreated) {
      return nso?.[item?._id];
    }

    return false;
  }

  complete(item, status) {
    let user = LocalStorage.get(Constant.CACHE.USER);

    let payload = {
      completed: false,
    };

    if (status == true) {
      payload = {
        completed: true,
        completedBy: {
          _id: user?._id,
          name: user?.name,
          email: user?.email,
        },
        completedAt: new Date(),
      };
    }

    this.setState({
      submitting: item?._id,
    });

    APIV2.modifyStoreNSO(this.props.store?.nsoID, item._id, payload)
      .then(
        (data) => {
          console.log(data?.data?.nso);

          this.props.onUpdate(data?.data?.nso);
        },
        (e) => {}
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  onTaskCreated(item, task) {
    let user = LocalStorage.get(Constant.CACHE.USER);

    let payload = {
      completed: false,
    };

    payload = {
      taskCreated: true,
      taskCreatedBy: {
        _id: user?._id,
        name: user?.name,
        email: user?.email,
      },
      taskCreatedAt: new Date(),
      taskID: task?._id,
    };

    this.setState({
      submitting: item?._id,
    });

    APIV2.modifyStoreNSO(this.props.store?.nsoID, item._id, payload)
      .then(
        (data) => {
          console.log(data?.data?.nso);

          this.props.onUpdate(data?.data?.nso);
        },
        (e) => {}
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  componentDidMount() {
    this.loadNSOTemplate();

    PubSub.subscribe(Event.TASK.CREATED, (task) => {
      let itemName = task?.subject?.replace("NSO: ", "");

      for (let i = 0; i < this.state.sections.length; i++) {
        let section = this.state.sections[i];

        for (let j = 0; j < section.actions.length; j++) {
          let action = section.actions[j];

          if (action?.name == itemName) {
            this.onTaskCreated(action, task);

            break;
          }
        }
      }
    });
  }

  calculateSectionCompletion(section) {
    let actions = section?.actions;

    if (!actions?.length) {
      return 0;
    }

    let completed = _.filter(actions, (action) => {
      return this.isComplete(action);
    });

    return (completed.length / actions.length) * 100;
  }

  calculateTotalCompletion() {
    let sections = this.state.sections;

    if (!sections?.length) {
      return 0;
    }

    let actions = [];

    for (let i = 0; i < sections.length; i++) {
      actions = actions.concat(sections[i].actions);
    }

    if (!actions?.length) {
      return 0;
    }

    let completed = _.filter(actions, (action) => {
      return this.isComplete(action);
    });

    return parseFloat(
      ((completed?.length / actions?.length) * 100)?.toFixed(1)
    );
  }

  getCompletionColor(completion) {
    if (completion == 100) {
      return "emerald";
    }

    if (completion >= 75) {
      return "green";
    }

    if (completion >= 50) {
      return "yellow";
    }

    if (completion >= 49) {
      return "orange";
    }

    return "red";
  }

  getTaskType(item) {
    switch (item?.assignedTo) {
      case "Production":
        return "Production";
      case "Franchise Partner":
        return "Franchise Partner";
      case "Unit Operations":
        return "Unit Operations";
      case "Growth Technology":
        return "Technology";
      case "Fran-Dev":
        return "Fran-Dev";
      case "Fran-Support":
        return "Fran-Support";
      case "Fran-Finance":
        return "Fran-Finance";
      case "Marketing":
        return "Marketing";
      default:
        return "";
    }
  }

  render() {
    return (
      <>
        <Card className="border shadow mb-4">
          <CardBody>
            <Row className="align-items-end">
              <Col xs="12" sm="">
                <h2 className="m-0">New Store Open Progress</h2>

                <p className="m-0">
                  First Bill Date:{" "}
                  {this.props.store?.operations?.storeOpening?.firstBillDate
                    ? moment(
                        this.props.store?.operations?.storeOpening
                          ?.firstBillDate
                      ).format("MM/DD/YYYY")
                    : "(none set)"}
                </p>
              </Col>
              <Col className="mt-2 mt-sm-0" xs="12" sm="auto">
                <p className="m-0 font-weight-normal">
                  {parseInt(this.calculateTotalCompletion())}% Complete
                </p>
              </Col>
              <Col xs="12">
                <ProgressBar
                  percentageValue={this.calculateTotalCompletion()}
                  color={this.getCompletionColor(
                    this.calculateTotalCompletion()
                  )}
                  marginTop="mt-2"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <div className="text-right pb-4">
          <Button
            outline
            size="sm"
            color="dark"
            onClick={() => {
              this.setState({
                relativeDates: !this.state.relativeDates,
              });
            }}
          >
            View {this.state.relativeDates ? "Absolute" : "Relative"} Dates
          </Button>
        </div>
        {this.state.sections?.map((section, i) => (
          <div key={i}>
            <Card
              className={`shadow border ${
                i != this.state.sections?.length - 1 ? "mb-4" : ""
              }`}
            >
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="">
                    <h3 className="m-0">{section.name}</h3>
                  </Col>
                  <Col xs="auto">
                    <p className="m-0">
                      {parseInt(this.calculateSectionCompletion(section))}%
                      Complete
                    </p>
                  </Col>
                  <Col xs="12">
                    <ProgressBar
                      percentageValue={this.calculateSectionCompletion(section)}
                      color={this.getCompletionColor(
                        this.calculateSectionCompletion(section)
                      )}
                      marginTop="mt-2"
                    />
                  </Col>
                </Row>
              </CardHeader>
              {section?.actions?.length ? (
                <>
                  {section?.actions?.map((item, i2) => (
                    <CardBody
                      className={`py-2 ${
                        i2 != section?.actions?.length - 1
                          ? "border-bottom"
                          : ""
                      }`}
                      key={i2}
                    >
                      <Row className="align-items-center">
                        <Col xs="">
                          <Row className="align-items-center">
                            <Col xs="">
                              <p
                                className="mb-1"
                                style={{ lineHeight: 1.2, fontSize: 15 }}
                              >
                                {item?.assignedTo}
                                {this.props.globalMode ? (
                                  <>
                                    &nbsp;(
                                    <i
                                      className="mdi mdi-eye"
                                      style={{ position: "relative", top: 1 }}
                                    ></i>{" "}
                                    {item?.visibleTo})
                                  </>
                                ) : null}
                              </p>
                              <h3 className="m-0" style={{ fontSize: 20 }}>
                                {item?.name}
                              </h3>
                              {this.isComplete(item) ? (
                                <p
                                  className="mt-1 mb-0"
                                  style={{ lineHeight: 1.2, fontSize: 12 }}
                                >
                                  Marked as completed by{" "}
                                  {
                                    this.props.store?.nso?.[item?._id]
                                      ?.completedBy?.name
                                  }{" "}
                                  at{" "}
                                  {moment(
                                    this.props.store?.nso?.[item?._id]
                                      ?.completedAt
                                  ).format("MM/DD/YYYY hh:mm A")}
                                </p>
                              ) : null}
                            </Col>
                            <Col xs="12" sm="auto" className="pr-0">
                              <div>
                                <h2 className="m-0">
                                  <Badge
                                    color={`${
                                      !this.isComplete(item) &&
                                      (!this.props.store?.operations
                                        ?.storeOpening?.firstBillDate ||
                                        moment().isBefore(
                                          moment(
                                            this.props.store?.operations
                                              ?.storeOpening?.firstBillDate
                                          )
                                            .add(item.timeToFFD, "days")
                                            .startOf("day")
                                        ))
                                        ? "light"
                                        : ""
                                    }${this.isComplete(item) ? "success" : ""}`}
                                    className={`border ${
                                      moment().isAfter(
                                        moment(
                                          this.props.store?.operations
                                            ?.storeOpening?.firstBillDate
                                        )
                                          .add(item.timeToFFD, "days")
                                          .startOf("day")
                                      ) && !this.isComplete(item)
                                        ? "bg-yellow text-dark border-dark"
                                        : ""
                                    }${
                                      this.isComplete(item)
                                        ? "border-success"
                                        : ""
                                    }`}
                                  >
                                    {this.isComplete(item) ? (
                                      <>Complete</>
                                    ) : (
                                      <>
                                        {this.state.relativeDates ? (
                                          <>
                                            {this.props.store?.operations
                                              ?.storeOpening?.firstBillDate
                                              ? moment(
                                                  this.props.store?.operations
                                                    ?.storeOpening
                                                    ?.firstBillDate
                                                )
                                                  .add(item.timeToFFD, "days")
                                                  .startOf("day")
                                                  .fromNow()
                                              : "No Date Set"}
                                          </>
                                        ) : (
                                          <>
                                            {this.props.store?.operations
                                              ?.storeOpening?.firstBillDate
                                              ? moment(
                                                  this.props.store?.operations
                                                    ?.storeOpening
                                                    ?.firstBillDate
                                                )
                                                  .add(item.timeToFFD, "days")
                                                  .startOf("day")
                                                  .format("MM/DD/YYYY")
                                              : "No Date Set"}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </Badge>
                                </h2>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs="auto" style={{ minWidth: 62 }}>
                          {this.props.globalMode ? (
                            <Button
                              size="sm"
                              outline={!this.isComplete(item)}
                              color={this.isComplete(item) ? "success" : "dark"}
                              className="btn-icon-only"
                              onClick={() => {
                                this.complete(item, !this.isComplete(item));
                              }}
                            >
                              <i className="mdi mdi-check"></i>
                            </Button>
                          ) : null}
                          {this.props.globalMode && !this.isComplete(item) ? (
                            <>
                              <Whisper
                                placement="auto"
                                preventOverflow={true}
                                delayOpen={400}
                                trigger="hover"
                                speaker={
                                  <Tooltip>
                                    {this.taskCreated(item)
                                      ? `Task has been created by ${
                                          this.taskCreated(item)?.taskCreatedBy
                                            ?.name
                                        } on ${moment(
                                          this.taskCreated(item)?.taskCreatedAt
                                        ).format("MM/DD/YYYY hh:mm A")}`
                                      : "Create a task for this action"}
                                  </Tooltip>
                                }
                              >
                                <Button
                                  size="sm"
                                  outline={!this.taskCreated(item)}
                                  color={
                                    this.taskCreated(item) ? "success" : "dark"
                                  }
                                  className="btn-icon-only"
                                  onClick={() => {
                                    if (this.taskCreated(item)) {
                                      swal({
                                        title: "Recreate Task?",
                                        text: "A task has already been created for this action. Are you sure you want to recreate it?",
                                        icon: "warning",
                                        buttons: true,
                                      }).then((confirm) => {
                                        if (!confirm) {
                                          return;
                                        }

                                        this.setState({
                                          taskDrawer: true,
                                          taskPrefill: {
                                            subject: `NSO: ${item?.name}`,
                                            description: `Please complete the NSO Timeline Action: ${item?.name}`,
                                            storeID: this.props.store?._id,
                                            dueDate: this.props.store
                                              ?.operations?.storeOpening
                                              ?.firstBillDate
                                              ? moment(
                                                  this.props.store?.operations
                                                    ?.storeOpening
                                                    ?.firstBillDate
                                                )
                                                  .add(item.timeToFFD, "days")
                                                  .startOf("day")
                                                  .toDate()
                                              : null,
                                            type: this.getTaskType(item),
                                          },
                                        });
                                      });
                                    } else {
                                      this.setState({
                                        taskDrawer: true,
                                        taskPrefill: {
                                          subject: `NSO: ${item?.name}`,
                                          description: `Please complete the NSO Timeline Action: ${item?.name}`,
                                          storeID: this.props.store?._id,
                                          dueDate: this.props.store?.operations
                                            ?.storeOpening?.firstBillDate
                                            ? moment(
                                                this.props.store?.operations
                                                  ?.storeOpening?.firstBillDate
                                              )
                                                .add(item.timeToFFD, "days")
                                                .startOf("day")
                                                .toDate()
                                            : null,
                                          type: this.getTaskType(item),
                                        },
                                      });
                                    }
                                  }}
                                >
                                  {this.taskCreated(item) ? (
                                    <i className="mdi mdi-playlist-check"></i>
                                  ) : (
                                    <i className="mdi mdi-playlist-plus"></i>
                                  )}
                                </Button>
                              </Whisper>
                            </>
                          ) : null}
                          {item?.description?.text || item?.files?.length ? (
                            <Button
                              size="sm"
                              outline
                              color="dark"
                              className="btn-icon-only"
                              onClick={() => {
                                if (this.state.expanded?.includes(item?._id)) {
                                  this.setState({
                                    expanded: _.filter(
                                      this.state?.expanded,
                                      (e) => {
                                        return e != item?._id;
                                      }
                                    ),
                                  });

                                  return;
                                }

                                this.setState({
                                  expanded: [
                                    ...this.state?.expanded,
                                    item?._id,
                                  ],
                                });
                              }}
                            >
                              <i
                                className={`${
                                  this.state?.expanded?.includes(item?._id)
                                    ? "mdi mdi-chevron-down"
                                    : "mdi mdi-chevron-right"
                                }`}
                              ></i>
                            </Button>
                          ) : null}
                        </Col>
                      </Row>
                      <Collapse
                        isOpen={this.state?.expanded?.includes(item?._id)}
                      >
                        <Row className="align-items-center">
                          <Col xs="">
                            <div
                              className="mt-2 p-3 border rounded"
                              dangerouslySetInnerHTML={{
                                __html: item?.description?.html,
                              }}
                            ></div>
                            {item?.description?.text &&
                            item?.files &&
                            item?.files?.length ? (
                              <hr className="my-3"></hr>
                            ) : null}
                            {item?.files && item?.files?.length ? (
                              <>
                                <h4 className="my-3">Files:</h4>
                                {item?.files?.map((at, i) => (
                                  <div
                                    className={`border p-2 rounded mb-2`}
                                    key={i}
                                  >
                                    <Row className="align-items-center">
                                      <Col xs="12" sm="">
                                        <p className="small m-0 text-dark text-truncate">
                                          {at?.fileName}
                                        </p>
                                      </Col>
                                      <Col
                                        xs="12"
                                        sm="auto"
                                        className="mt-3 mt-sm-0 text-right"
                                      >
                                        <Button
                                          className="btn-icon-only"
                                          size="sm"
                                          outline
                                          color="secondary"
                                          disabled={!at?.url}
                                          onClick={() => {
                                            if (!at?.url) {
                                              return;
                                            }

                                            LinkUtils.openPrivateURL(at?.url);
                                          }}
                                        >
                                          <i className="mdi mdi-eye"></i>
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                ))}
                              </>
                            ) : null}
                          </Col>
                        </Row>
                      </Collapse>
                    </CardBody>
                  ))}
                </>
              ) : (
                <CardBody>
                  <p className="m-0">
                    No actions required in the {section.name} stage.
                  </p>
                </CardBody>
              )}
            </Card>
          </div>
        ))}
        <TaskCreateDrawer
          storeTask={true}
          prefill={this.state.taskPrefill}
          store={this.props.store}
          open={this.state.taskDrawer}
          onClose={() => {
            this.setState({ taskDrawer: false });
          }}
          requireAssignedTo={true}
        ></TaskCreateDrawer>
      </>
    );
  }
}

export default withRouter(StoreNSOWidget);
