import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker, Whisper, Tooltip } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import StorefrontCreateUserDrawer from "components/Drawers/StorefrontCreateUserDrawer";
import swal from "sweetalert";

class StorefrontUserListView extends React.Component {
  state = {
    active: {
      id: "All",
      name: "All",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    users: [],
    page: 1,
    count: 25,
    filters: null,
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps?.match?.params?.storeID != this.props?.match?.params?.storeID
    ) {
      this.loadUsers();
    }
  }

  componentDidMount() {
    this.loadUsers();

    PubSub.subscribe(Event.USER.CREATED, () => {
      this.loadUsers();
    });

    PubSub.subscribe(Event.USER.MODIFIED, () => {
      this.loadUsers();
    });
  }

  loadUsers() {
    this.setState({
      loadingUsers: true,
    });

    APIV2.getStoreUsers(this?.props?.match?.params?.storeID)
      .then(
        (data) => {
          if (data?.data?.users) {
            let users = data.data.users;

            this.setState({
              users,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the users. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingUsers: false,
        });
      });
  }

  performSearch() {
    console.log(this.state.search);

    APIV2.simpleSearchUserForStore(
      this.props?.match?.params?.storeID,
      this.state.search
    )
      .then(
        (data) => {
          this.setState({
            searchResultUser: data.data.user,
            searchResults: true,
          });
        },
        (e) => {
          console.error(e);

          this.setState({ searchResults: true, searchResultUser: [] });
        }
      )
      .finally(() => {
        this.setState({
          searchLoading: false,
        });
      });
  }

  delete(user) {
    swal({
      title: "Remove User?",
      text: `Are you sure you want remove ${user?.name} as a Storefront user?`,
      icon: "warning",
      buttons: ["Nevermind", "Remove"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        deleteSubmitting: user?._id,
      });

      APIV2.deleteStoreUser(this?.props?.match?.params?.storeID, user?._id)
        .then(
          (data) => {
            this.loadUsers();
          },
          (e) => {
            console.error(e);
          }
        )
        .finally(() => {
          this.setState({
            deleteSubmitting: false,
          });
        });
    });
  }

  resendInvite(user) {
    this.setState({
      resendSubmitting: user?._id,
    });

    APIV2.resendStoreUserInvite(this?.props?.match?.params?.storeID, user?._id)
      .then(
        (data) => {
          swal("Invite Resent!", {
            icon: "success",
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          resendSubmitting: false,
        });
      });
  }

  getRole(user) {
    let perms = _.findWhere(user?.storePermissions, {
      storeID: this.props?.match?.params?.storeID,
    });

    if (perms?.permissions) {
      return <span className="text-capitalize">{perms.permissions}</span>;
    }

    return "--";
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader
            title="Storefront Users"
            showBackButton={false}
            primaryAction={{
              name: <>Add</>,
              onClick: () => {
                this.setState({
                  createOpen: true,
                });
              },
            }}
          />

          <Row>
            <div className="col">
              <Card className=" shadow" style={{ minHeight: "500px" }}>
                <CardSelectorHeader
                  compact={true}
                  value={this.state.active}
                  options={[
                    {
                      name: "All",
                      id: "All",
                    },
                  ]}
                  onChange={() => {}}
                ></CardSelectorHeader>

                <CardBody className="p-0">
                  <div className="py-2 px-3 bg-superlight border-bottom border-top text-dark">
                    <Row className="align-items-center">
                      <Col xs="6" sm="6" md="4">
                        <h4 className="mb-0">User</h4>
                      </Col>

                      <Col
                        xs="6"
                        sm="6"
                        md="4"
                        className="text-right text-md-left"
                      >
                        <h4 className="mb-0">Role</h4>
                      </Col>
                      <Col xs="12" sm="12" md="4" className="text-right">
                        <h4 className="mb-0">Actions</h4>
                      </Col>
                    </Row>
                  </div>

                  <div>
                    {this.state.loadingUsers || this.state.searchLoading ? (
                      <>
                        <div
                          className="text-center p-3"
                          style={{
                            zIndex: 1,
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            background: "rgba(255, 255, 255, 0.6)",
                          }}
                        >
                          <Spinner
                            style={{ position: "relative", top: "15vh" }}
                            color="medium"
                            size=""
                          ></Spinner>
                        </div>
                      </>
                    ) : null}
                    <div>
                      {!this.state.users?.length && !this.state.loadingUsers ? (
                        <div className="px-3 py-2">
                          <Row>
                            <Col>Get started by adding your first user.</Col>
                          </Row>
                        </div>
                      ) : null}

                      {this.state.users?.map((c, i) => (
                        <div
                          className={`px-3 py-2 bg-superlight--hover cursor-pointer ${
                            i != this.state.users.length - 1
                              ? "border-bottom"
                              : ""
                          }`}
                          key={i}
                        >
                          <Row className="align-items-center">
                            <Col xs="6" sm="6" md="4">
                              <h4
                                className={`mb-0 ${
                                  c.name ? "text-capitalize" : "text-lowercase"
                                }`}
                              >
                                {c.name}
                              </h4>
                              <p className="mb-0" style={{ fontSize: "13px" }}>
                                {c.invited ? (
                                  <span className="mr-1">(Invited)</span>
                                ) : null}
                                {c.email}
                              </p>
                            </Col>
                            <Col
                              xs="6"
                              sm="6"
                              md="4"
                              className="text-right text-md-left"
                            >
                              {this.getRole(c)}
                            </Col>
                            <Col xs="12" sm="12" md="4" className="text-right">
                              {c?.invited ? (
                                <Whisper
                                  placement="autoHorizontal"
                                  trigger="hover"
                                  speaker={
                                    <Tooltip>Resend Invite Email</Tooltip>
                                  }
                                >
                                  <Button
                                    color="secondary"
                                    outline
                                    className="btn-icon-only"
                                    disabled={
                                      this.state.resendSubmitting == c?._id
                                    }
                                    onClick={() => {
                                      this.resendInvite(c);
                                    }}
                                    size="sm"
                                  >
                                    {this.state.resendSubmitting == c._id ? (
                                      <Spinner
                                        color="secondary"
                                        size="sm"
                                      ></Spinner>
                                    ) : (
                                      <i className="mdi mdi-email-send"></i>
                                    )}
                                  </Button>
                                </Whisper>
                              ) : null}
                              <Button
                                color="danger"
                                outline
                                className="btn-icon-only"
                                disabled={this.state.deleteSubmitting == c._id}
                                onClick={() => {
                                  this.delete(c);
                                }}
                                size="sm"
                              >
                                {this.state.deleteSubmitting == c._id ? (
                                  <Spinner color="danger" size="sm"></Spinner>
                                ) : (
                                  <i className="mdi mdi-close"></i>
                                )}
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        <StorefrontCreateUserDrawer
          open={this.state.createOpen}
          onClose={() => {
            this.setState({
              createOpen: false,
            });
          }}
          storeID={this?.props?.match?.params?.storeID}
        ></StorefrontCreateUserDrawer>
      </>
    );
  }
}

export default StorefrontUserListView;
