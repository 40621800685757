import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Spinner,
  Collapse,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import moment from "moment";
import { CheckboxGroup, DatePicker, SelectPicker, Checkbox } from "rsuite";
import StringUtils from "lib/StringUtils";
import { Table, Cell, Column, HeaderCell } from "rsuite-table";
import _ from "underscore";
import MoneyCell from "components/Table/MoneyCell";
import NumericCell from "components/Table/NumericCell";
import StoreCell from "components/Table/StoreCell";
import BootstrapTable from "react-bootstrap-table-next";
import MealsOrderedWidget from "components/Widgets/Fulfillment/MealsOrderedWidget";
import PlansFulfilledWidget from "components/Widgets/Fulfillment/PlansFulfilledWidget";
import AverageBoxSizeWidget from "components/Widgets/Fulfillment/AverageBoxSizeWidget";
import PlanFrequencyChartWidget from "components/Widgets/Fulfillment/PlanFrequencyChartWidget";
import PlanProcurementChartWidget from "components/Widgets/Fulfillment/PlanProcurementChartWidget";
import PlanTypeChartWidget from "components/Widgets/Fulfillment/PlanTypeChartWidget";
import { Link } from "react-router-dom";
import Input from "reactstrap/lib/Input";
import swal from "sweetalert";
import CustomerOrderRow from "components/CustomerOrderRow";
import { CSVLink } from "react-csv";

class StoreOrderDeliveryListDownloadView extends React.Component {
  state = {
    stores: [],
    loading: true,
    fulfillmentDate: new Date(),
    availableDates: [],
    storeOpen: true,
    mealOpen: true,
    planOpen: true,
    storePerformance: [],
    dates: [],
    orders: [],
    compareToOptions: [],
    count: 25,
    page: 1,
    selectedOrders: [],
    searchResultOrders: [],
    active: {
      id: "All",
    },
  };

  getAvailableDates() {
    return new Promise((resolve) => {
      APIV2.getStoreSubscriptionFulfillmentDates(
        this.props.match?.params?.storeID
      )
        .then(
          (data) => {
            let dates = data.data.dates;

            dates.push(this.state.fulfillmentMoment.format("YYYY-MM-DD"));

            this.setState({
              availableDates: dates,
            });
          },
          (e) => {
            this.setState({
              availableDates: [],
            });

            console.error(e);
          }
        )
        .finally(() => {
          resolve();
        });
    });
  }

  _rerunStatus(reportID, jobID, callback) {
    APIV2.checkRerunSubscriptionFulfillmentReportStatus(reportID, jobID).then(
      (data) => {
        console.log(data);

        callback(data);
      },
      (e) => {
        console.log(e);

        callback(null, e);
      }
    );
  }

  checkRerunStatus(reportID, jobID) {
    let self = this;

    let load = (dateString) => {
      this.loadSummary(dateString);
    };

    return new Promise((resolve, reject) => {
      this._rerunStatus(reportID, jobID, function callback(data, error) {
        if (error) {
          resolve();

          return; // Don't continue after error
        }

        if (data?.data?.job?.progress == 100) {
          resolve();

          load(self.state.fulfillmentDateString);

          return;
        }

        setTimeout(() => {
          self._rerunStatus(reportID, jobID, callback);
        }, 5000);
      });
    });
  }

  rerunReport(reportID) {
    APIV2.rerunSubscriptionFulfillmentReport(reportID).then(
      (data) => {
        this.setState({
          rerunning: true,
          rerunJob: data.data.job,
        });

        this.checkRerunStatus(reportID, data.data.job.id).then(() => {
          console.log("DONE");
        });
      },
      (e) => {
        window.alert("Unable to rerun report. Try again.");
      }
    );
  }

  sortBom(bom) {
    return _.sortBy(bom, "name");
  }

  loadOrders() {
    this.setState({
      loadingOrders: true,
    });

    APIV2.getStoreByID(this.props?.match?.params?.storeID).then((data) => {
      this.setState({ store: data?.data?.store });
    });

    APIV2.getOrdersForStore(
      this.props?.match?.params?.storeID,
      1,
      2000000,
      {
        status: { $ne: "CANCELLED" },
        "subscription.plan.procurement": { $in: ["delivery", "shipping"] },
        fulfillmentDateString: {
          $regex: this.state.fulfillmentDateString,
          $options: "i",
        },
      },
      { "customer.delivery.zone": -1, "customer.delivery.route": -1 }
    )
      .then(
        (data) => {
          if (data && data.data) {
            let orders = [];

            if (data.data.orders.length) {
              orders = data.data.orders;
            }

            if (this.state.ordersArr?.length) {
              orders = _.filter(orders, (o) => {
                return this.state.ordersArr.indexOf(o._id) >= 0;
              });
            }

            orders.sort((a, b) => {
              return a?.customer?.name > b?.customer?.name ? 1 : -1;
            });

            let ordersCsv = orders?.map((order) => {
              const address = `${order?.customer?.shippingAddress?.address1}${
                order?.customer?.shippingAddress?.address2
                  ? " " + order?.customer?.shippingAddress?.address2
                  : ""
              }, ${order?.customer?.shippingAddress?.city}, ${
                order?.customer?.shippingAddress?.state
              } ${order?.customer?.shippingAddress?.zip}`;

              return {
                "Member Name": order?.customer?.name,
                Email: order?.customer?.email,
                Phone: StringUtils.formatPhoneNumber(
                  order?.customer?.phone.replace("+1", "")
                ),
                "Delivery Address": address,
                "Delivery Zone": order?.customer?.delivery?.zone,
                "Delivery Route": order?.customer?.delivery?.route,
                "Delivery Notes": order?.customer?.delivery?.notes,
                Invoice: order?.invoice?.status,
              };
            });

            this.setState({
              orders,
              ordersCsv,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the orders. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingOrders: false,
          loading: false,
        });
      });
  }

  loadSummary(dateString) {
    if (!dateString) {
      return;
    }

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("date", dateString);
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.setState(
      {
        loading: true,
        rerunning: false,
        noReport: false,
        dateString,
      },
      () => {
        this.loadOrders();
      }
    );
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const date = urlParams.get("date");

    const orders = urlParams.get("orders");

    let ordersArr = [];

    let fulfillmentDate = moment();

    if (date) {
      fulfillmentDate = moment(date);
    }

    if (orders) {
      ordersArr = orders.split(",");
    }

    fulfillmentDate
      .set("day", 6)
      .set("hours", 0)
      .set("minutes", 0)
      .set("seconds", 0);

    this.setState(
      {
        fulfillmentDate: fulfillmentDate.toDate(),
        fulfillmentMoment: fulfillmentDate,
        fulfillmentDateString: fulfillmentDate.format("YYYY-MM-DD"),
        ordersArr,
      },
      () => {
        this.loadSummary(this.state.fulfillmentDateString);
      }
    );
  }

  render() {
    return (
      <>
        <div>
          {this.state.loading || this.state.loadingOrders ? (
            <div className="p-3">
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">
                    <Spinner color="dark" size="sm"></Spinner>
                    <span className="pl-3">Creating Delivery List</span>
                  </h3>
                </CardHeader>
                <CardBody>
                  Hang tight while we fetch your delivery orders for{" "}
                  {this.state.fulfillmentMoment?.format("dddd, MMM Do YYYY")}.
                </CardBody>
              </Card>
            </div>
          ) : null}

          {this.state.noReport ||
          (!this.state.orders.length &&
            !this.state.loadingOrders &&
            !this.state.loading) ? (
            <div className="p-3">
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">
                    <span className="pl-3">No Orders Available</span>
                  </h3>
                </CardHeader>
                <CardBody>
                  There are no orders available for delivery on{" "}
                  {this.state.fulfillmentMoment?.format("dddd, MMM Do YYYY")}.
                </CardBody>
              </Card>
            </div>
          ) : null}
          {!this.state.loading ? (
            <>
              <div className="p-3">
                <Card className="border">
                  <CardHeader>
                    <h3 className="m-0">
                      Delivery List for{" "}
                      {this.state.fulfillmentMoment?.format(
                        "dddd, MMMM Do YYYY"
                      )}
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <CSVLink
                      filename={`${this.state.store?.name
                        ?.replace("Project LeanNation", "PLN")
                        .split(" ")
                        .join(
                          "_"
                        )}_Delivery_List_${this.state.fulfillmentMoment?.format(
                        "MM-DD-YYYY"
                      )}.csv`}
                      data={this.state.ordersCsv}
                      className="btn btn-primary"
                    >
                      Download Delivery List
                    </CSVLink>
                  </CardBody>
                </Card>
              </div>
            </>
          ) : null}
        </div>
      </>
    );
  }
}

export default StoreOrderDeliveryListDownloadView;
