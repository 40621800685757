import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import Alert from "reactstrap/lib/Alert";
import CustomerBillingAddressWidget from "./CustomerBillingAddressWidget";
import CustomerContactInformationWidget from "./CustomerContactInformationWidget";
import CustomerNotesWidget from "./CustomerNotesWidget";
import CustomerOpenActivitiesWidget from "./CustomerOpenActivitiesWidget";
import CustomerPreviewWidget from "./CustomerPreviewWidget";
import CustomerShippingAddressWidget from "./CustomerShippingAddressWidget";
import CustomerStoreAssignmentWidget from "./CustomerStoreAssignmentWidget";
import CustomerUnclaimedBoxWidget from "./CustomerUnclaimedBoxWidget";
import LeadConsultationsWidget from "./LeadConsultationsWidget";
import LeadNotesWidget from "./LeadNotesWidget";
import LeadSourceStatusWidget from "./LeadSourceStatusWidget";
import LeadGoalNotesWidget from "./LeadGoalNotesWidget";

class LeadSummaryWidget extends React.Component {
  state = {
    expand: false,
    phone: {
      value: "",
    },
    name: "",
    email: "",
  };

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 4000);
  }

  render() {
    return (
      <>
        {this.props.loading ? (
          <>
            <Card className="border shadow mb-4">
              <CardBody className="bg-transparent py-5 text-center">
                <Spinner size="lg" color="medium"></Spinner>
              </CardBody>
            </Card>
          </>
        ) : (
          <>
            {/*<CustomerOpenActivitiesWidget customer={this.props.customer} />*/}
            <LeadSourceStatusWidget
              lead={this.props.lead}
              loading={this.props.loading}
            ></LeadSourceStatusWidget>
            <LeadGoalNotesWidget
              lead={this.props.lead}
              loading={this.props.loading}
            ></LeadGoalNotesWidget>
            {this.props.lead?.customer ? (
              <CustomerPreviewWidget
                customer={this.props.lead?.customer}
                loading={this.props.loading}
              ></CustomerPreviewWidget>
            ) : null}

            <LeadNotesWidget
              lead={this.props.lead}
              loading={this.props.loading}
            ></LeadNotesWidget>
          </>
        )}
      </>
    );
  }
}

export default withRouter(LeadSummaryWidget);
