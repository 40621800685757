import APIV2 from "lib/APIV2";
import Constant from "lib/Constant";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
  Badge,
} from "reactstrap";
import Alert from "reactstrap/lib/Alert";
import { SelectPicker } from "rsuite";
import _ from "underscore";
import CustomerOpenActivitiesWidget from "./CustomerOpenActivitiesWidget";
import CustomerUnclaimedBoxWidget from "./CustomerUnclaimedBoxWidget";
import LocalStorage from "lib/LocalStorage";

class CustomerStoreAssignmentWidget extends React.Component {
  state = {
    expand: false,
    phone: {
      value: "",
    },
    name: "",
    email: "",
  };

  updateLocation() {
    this.setState({ locationSubmitting: true });

    console.log(this.state.selectedStore);

    APIV2.assignCustomerToStore(
      this.state.selectedStore,
      this.props?.customer?._id
    )
      .then(
        (data) => {
          this.props.history.push(
            "/storefront/" +
              data?.data?.customer?.storeID +
              "/members/details/" +
              data.data.customer._id +
              "/summary"
          );

          PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);

          this.setState({ modifyLocation: false });
        },
        (e) => {
          this.setError(
            "error",
            "Could not update customer, please try again."
          );
        }
      )
      .finally(() => {
        this.setState({ locationSubmitting: false });
      });
  }

  updateCoach() {
    this.setState({ coachSubmitting: true });

    APIV2.setCustomerVariable(
      this.props.customer?.storeID,
      this.props?.customer?._id,
      "coachID",
      this.state.selectedCoach ? this.state.selectedCoach : null
    )
      .then(
        (data) => {
          PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);

          this.setState({ modifyCoach: false });
        },
        (e) => {
          this.setError(
            "error",
            "Could not update customer, please try again."
          );
        }
      )
      .finally(() => {
        this.setState({ coachSubmitting: false });
      });
  }

  updateInitialSource() {
    this.setState({ initialSourceSubmitting: true });

    APIV2.setCustomerVariable(
      this.props.customer?.storeID,
      this.props?.customer?._id,
      "initialSource",
      this.state.selectedInitialSource ? this.state.selectedInitialSource : null
    )
      .then(
        (data) => {
          PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);

          this.setState({ modifyInitialSource: false });
        },
        (e) => {
          this.setError(
            "error",
            "Could not update customer, please try again."
          );
        }
      )
      .finally(() => {
        this.setState({ initialSourceSubmitting: false });
      });
  }

  updateConversionSource() {
    this.setState({ conversionSourceSubmitting: true });

    APIV2.setCustomerVariable(
      this.props.customer?.storeID,
      this.props?.customer?._id,
      "conversionSource",
      this.state.selectedConversionSource
        ? this.state.selectedConversionSource
        : null
    )
      .then(
        (data) => {
          PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);

          this.setState({ modifyConversionSource: false });
        },
        (e) => {
          this.setError(
            "error",
            "Could not update customer, please try again."
          );
        }
      )
      .finally(() => {
        this.setState({ conversionSourceSubmitting: false });
      });
  }

  updateInformation() {
    this.setState({ submitting: true });

    let phone = "";

    if (this.state.phone?.value.length == 10) {
      phone = `+1${this.state.phone?.value}`;
    }

    APIV2.updateCustomerInformation(
      this.props?.match?.params?.storeID,
      this.props?.customer?._id,
      this.state.name,
      this.state.email,
      phone
    )
      .then(
        (data) => {
          PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);

          this.setState({ openModify: false });
        },
        (e) => {
          this.setError(
            "error",
            "Could not update customer, please try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  getStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStoresForUser()
      .then(
        (data) => {
          let storeOptions = data.data.stores?.map((s) => {
            return {
              value: s._id,
              label: s.name.replace("Project LeanNation", "PLN"),
            };
          });

          this.setState({
            stores: data.data.stores,
            storeOptions: storeOptions,
          });
        },
        (e) => {
          this.setError("error", "Could not fetch stores.");
        }
      )
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  getStoreStaff() {
    this.setState({
      loadingCoaches: true,
    });

    APIV2.getStaffForStore(this?.props?.customer?.storeID, 1, 10000, {
      position: { $nin: ["Terminated", "Applicant"] },
    })
      .then(
        (data) => {
          let coachOptions = data.data.staff?.map((s) => {
            return {
              value: s._id,
              label: s.name,
            };
          });

          this.setState({
            coaches: data.data.staff,
            coachOptions: coachOptions,
          });
        },
        (e) => {
          this.setError("error", "Could not fetch coaches.");
        }
      )
      .finally(() => {
        this.setState({
          loadingCoaches: false,
        });
      });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 4000);
  }

  componentDidMount() {
    this.getStores();
    this.getStoreStaff();
  }

  componentDidUpdate(prevProps) {
    if (this.props.customer != prevProps.customer) {
      this.getStoreStaff();
    }
  }

  isGlobalUser() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  render() {
    return (
      <>
        <Card className="mb-4 shadow border">
          <CardHeader>
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Store Information</h3>
              </div>
            </Row>
          </CardHeader>
          {this.props.loading ? (
            <CardBody>
              <Spinner size="sm" color="dark" className="mr-2" /> Loading...
            </CardBody>
          ) : (
            <>
              <CardBody className="bg-transparent p-0">
                {this.isGlobalUser() && (
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="auto">Location:</Col>
                      <Col xs="" className="pl-0">
                        {!this.state.modifyLocation ? (
                          <>
                            {this.props.customer?.store?.name
                              ? this.props.customer?.store?.name
                              : "--"}
                          </>
                        ) : null}
                      </Col>
                      <Col xs="auto">
                        {this.state.modifyLocation ? (
                          <>
                            <Button
                              onClick={() => {
                                this.setState({ modifyLocation: false });
                              }}
                              size="sm"
                              color="secondary"
                              outline
                              disabled={this.state.locationSubmitting}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={() => {
                                this.updateLocation();
                              }}
                              disabled={
                                this.state.locationSubmitting ||
                                !this.state.selectedStore
                              }
                              size="sm"
                              color="primary"
                            >
                              {this.state.locationSubmitting ? (
                                <>
                                  <Spinner size="sm" color="white" />
                                </>
                              ) : (
                                <>Save</>
                              )}
                            </Button>
                          </>
                        ) : (
                          <Button
                            onClick={() => {
                              this.setState({
                                modifyLocation: true,
                                selectedStore: this.props.customer?.storeID,
                              });
                            }}
                            size="sm"
                            color="secondary"
                            outline
                          >
                            Edit
                          </Button>
                        )}
                      </Col>
                      {this.state.modifyLocation ? (
                        <Col xs="12" className="mt-3">
                          <SelectPicker
                            loading={this.state.loadingStores}
                            cleanable={false}
                            size="md"
                            placeholder="Select A Store"
                            data={this.state.storeOptions}
                            style={{}}
                            value={this.state.selectedStore}
                            block
                            onChange={(val) => {
                              this.setState({ selectedStore: val });
                            }}
                            placement="auto"
                          />
                        </Col>
                      ) : null}
                    </Row>
                  </div>
                )}
                <div className="px-4 py-3 border-bottom">
                  <Row className="align-items-center">
                    <Col xs="auto">Coach:</Col>
                    <Col xs="" className="pl-0">
                      {this?.props?.customer?.coach?.name ? (
                        this?.props?.customer?.coach?.name
                      ) : (
                        <Badge color="" className="bg-yellow text-dark">
                          None Assigned
                        </Badge>
                      )}
                    </Col>
                    <Col xs="auto">
                      {this.state.modifyCoach ? (
                        <>
                          <Button
                            onClick={() => {
                              this.setState({ modifyCoach: false });
                            }}
                            size="sm"
                            outline
                            color="secondary"
                            disabled={this.state.coachSubmitting}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              this.updateCoach();
                            }}
                            disabled={this.state.coachSubmitting}
                            size="sm"
                            color="primary"
                          >
                            {this.state.coachSubmitting ? (
                              <>
                                <Spinner size="sm" color="white" />
                              </>
                            ) : (
                              <>Save</>
                            )}
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => {
                            this.setState({
                              modifyCoach: true,
                              selectedCoach: this.props.customer?.coachID,
                            });
                          }}
                          size="sm"
                          color="secondary"
                          outline
                        >
                          Edit
                        </Button>
                      )}
                    </Col>
                    {this.state.modifyCoach ? (
                      <Col xs="12" className="mt-3">
                        <SelectPicker
                          loading={this.state.loadingCoaches}
                          cleanable
                          size="md"
                          preventOverflow={true}
                          placeholder="Select A Coach"
                          data={this.state.coachOptions}
                          style={{}}
                          value={this.state.selectedCoach}
                          block
                          onChange={(val) => {
                            this.setState({ selectedCoach: val });
                          }}
                          placement="auto"
                        />
                      </Col>
                    ) : null}
                  </Row>
                </div>
              </CardBody>
              <CardHeader>
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Member Source</h3>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="bg-transparent p-0">
                <div className="px-4 py-3 border-bottom">
                  <Row className="align-items-center">
                    <Col xs="auto">First Contact:</Col>
                    <Col xs="" className="pl-0">
                      {this.props?.customer?.initialSourceType
                        ? `(${this.props?.customer?.initialSourceType}) `
                        : ""}
                      {this?.props?.customer?.initialSource
                        ? this.props.customer?.initialSource
                        : "--"}
                    </Col>
                    <Col xs="auto">
                      {this.state.modifyInitialSource ? (
                        <>
                          <Button
                            onClick={() => {
                              this.setState({ modifyInitialSource: false });
                            }}
                            size="sm"
                            outline
                            color="secondary"
                            disabled={this.state.initialSourceSubmitting}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              this.updateInitialSource();
                            }}
                            disabled={this.state.initialSourceSubmitting}
                            size="sm"
                            color="primary"
                          >
                            {this.state.initialSourceSubmitting ? (
                              <>
                                <Spinner size="sm" color="white" />
                              </>
                            ) : (
                              <>Save</>
                            )}
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => {
                            this.setState({
                              modifyInitialSource: true,
                              selectedInitialSource:
                                this.props.customer?.initialSource,
                            });
                          }}
                          size="sm"
                          color="secondary"
                          outline
                        >
                          Edit
                        </Button>
                      )}
                    </Col>
                    {this.state.modifyInitialSource ? (
                      <Col xs="12" className="mt-3">
                        <SelectPicker
                          placeholder="Select A Source"
                          data={Constant.SELECT_PICKER.LEAD_SOURCES}
                          value={this.state.selectedInitialSource}
                          cleanable={false}
                          groupBy="type"
                          placement="auto"
                          block
                          onChange={(value, item) => {
                            let i = _.findWhere(
                              Constant.SELECT_PICKER.LEAD_SOURCES,
                              { value }
                            );

                            this.setState({
                              selectedInitialSource: value,
                              selectedInitialSourceType: i?.type,
                            });
                          }}
                        ></SelectPicker>
                      </Col>
                    ) : null}
                  </Row>
                </div>
                <div className="px-4 py-3 border-bottom">
                  <Row className="align-items-center">
                    <Col xs="auto">Conversion:</Col>
                    <Col xs="" className="pl-0">
                      {this.props?.customer?.conversionSourceType
                        ? `(${this.props?.customer?.conversionSourceType}) `
                        : ""}
                      {this?.props?.customer?.conversionSource
                        ? this.props.customer?.conversionSource
                        : "--"}
                    </Col>
                    <Col xs="auto">
                      {this.state.modifyConversionSource ? (
                        <>
                          <Button
                            onClick={() => {
                              this.setState({ modifyConversionSource: false });
                            }}
                            size="sm"
                            outline
                            color="secondary"
                            disabled={this.state.conversionSourceSubmitting}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              this.updateConversionSource();
                            }}
                            disabled={this.state.conversionSourceSubmitting}
                            size="sm"
                            color="primary"
                          >
                            {this.state.conversionSourceSubmitting ? (
                              <>
                                <Spinner size="sm" color="white" />
                              </>
                            ) : (
                              <>Save</>
                            )}
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => {
                            this.setState({
                              modifyConversionSource: true,
                              selectedConversionSource:
                                this.props.customer?.conversionSource,
                            });
                          }}
                          size="sm"
                          color="secondary"
                          outline
                        >
                          Edit
                        </Button>
                      )}
                    </Col>
                    {this.state.modifyConversionSource ? (
                      <Col xs="12" className="mt-3">
                        <SelectPicker
                          placeholder="Select A Source"
                          data={Constant.SELECT_PICKER.LEAD_SOURCES}
                          value={this.state.selectedConversionSource}
                          cleanable={false}
                          groupBy="type"
                          placement="auto"
                          block
                          onChange={(value, item) => {
                            let i = _.findWhere(
                              Constant.SELECT_PICKER.LEAD_SOURCES,
                              { value }
                            );

                            this.setState({
                              selectedConversionSource: value,
                              selectedConversionSourceType: i?.type,
                            });
                          }}
                        ></SelectPicker>
                      </Col>
                    ) : null}
                  </Row>
                </div>
              </CardBody>
            </>
          )}
        </Card>
      </>
    );
  }
}

export default withRouter(CustomerStoreAssignmentWidget);
