import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
  Button,
  Modal,
  Row,
  Col,
} from "reactstrap";
import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";
import API from "lib/API";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import User from "lib/Model/User";
import Badge from "reactstrap/lib/Badge";
import APIV2 from "lib/APIV2";

class BillingAccountRow extends React.Component {
  state = {
    showSecret: false,
    name: "",
    description: "",
    key: "",
    gatewayMerchantID: "",
    secret: "",
    modify: false,
  };

  updateShowSecret(show) {
    this.setState({
      showSecret: show,
    });
  }

  delete() {
    let c = window.confirm(
      "Are you sure you want to delete this billing account?"
    );

    if (c) {
      APIV2.deleteBillingAccount(this.props.account._id).then(
        (data) => {
          PubSub.publish(Event.BILLING_ACCOUNT.DELETED, {
            id: this.props.account._id,
          });
        },
        (e) => {
          console.error(e);
          window.alert(
            "We're having trouble removing that account. Please try again."
          );
        }
      );
    }
  }

  modify(e) {
    e.preventDefault();

    this.setState({
      submitting: true,
    });

    APIV2.modifyBillingAccount(
      this.props.account._id,
      this.state.name,
      this.state.description,
      this.state.key,
      this.state.secret,
      this.state.publicKey,
      this.state.username,
      this.state.password,
      this.state.email,
      this.state.gatewayMerchantID
    )
      .then(
        (data) => {
          console.log("here");

          if (data && data.data && data.data.billingAccount) {
            PubSub.publish(
              Event.BILLING_ACCOUNT.MODIFIED,
              data.data.billingAccount
            );

            this.closeModifyModal();
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "We were unable to modify that account. Please try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  closeModifyModal() {
    this.setState({
      name: "",
      description: "",
      key: "",
      secret: "",
      publicKey: "",
      gatewayMerchantID: "",
      modify: false,
    });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  openModifyModal() {
    this.setState({
      name: this.props.account.name,
      description: this.props.account.description,
      key: this.props.account.loginID,
      publicKey: this.props.account.publicKey,
      secret: this.props.account.secret,
      username: this.props.account?.username,
      password: this.props.account?.password,
      email: this.props.account?.email,
      gatewayMerchantID: this.props.account?.gatewayMerchantID,
      modify: true,
    });
  }

  render() {
    return (
      <>
        <tr className={this.state.showSecret ? "border-bottom-0" : ""}>
          <td className="pr-0">
            <Button
              size="sm"
              color=""
              className="btn-link text-dark btn-icon-only"
              style={{ fontSize: "20px" }}
              onClick={() => this.updateShowSecret(!this.state.showSecret)}
            >
              {this.state.showSecret ? (
                <i className="mdi mdi-chevron-down"></i>
              ) : (
                <i className="mdi mdi-chevron-right"></i>
              )}
            </Button>
          </td>
          <td>
            <h4 className="mb-0" style={{ whiteSpace: "pre-wrap" }}>
              {this.props.account.name}
            </h4>
          </td>

          <td className="text-right">
            <Button
              size="sm"
              outline
              color="secondary"
              className="btn-icon-only"
              onClick={() => this.openModifyModal()}
            >
              <i className="mdi mdi-pencil"></i>
            </Button>
            <Button
              size="sm"
              outline
              color="danger"
              className="btn-icon-only"
              onClick={() => this.delete()}
            >
              <i className="mdi mdi-delete"></i>
            </Button>
          </td>
        </tr>
        {this.state.showSecret ? (
          <tr>
            <td colspan={3} className="">
              <div className="border-bottom">
                <h3>General Information</h3>
                <Row>
                  <Col xs="12" sm="6" className=" mb-3">
                    <div className="text-uppercase text-muted font-weight-bold">
                      Username:
                    </div>
                    <pre
                      style={{ minHeight: 35 }}
                      className="p-2 border bg-superlight text-dark mt-2"
                    >
                      {this.props.account.username}
                    </pre>
                  </Col>
                  <Col xs="12" sm="6" className="mb-3">
                    <div className="text-uppercase text-muted font-weight-bold">
                      Password:
                    </div>
                    <pre
                      style={{ minHeight: 35 }}
                      className="p-2 border bg-superlight text-dark mt-2"
                    >
                      {this.props.account.password}
                    </pre>
                  </Col>
                  <Col xs="12">
                    <div className="text-uppercase text-muted font-weight-bold">
                      Email:
                    </div>
                    <pre
                      className="p-2 border bg-superlight text-dark mt-2"
                      style={{
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                        minHeight: 35,
                      }}
                    >
                      {this.props.account.email}
                    </pre>
                  </Col>
                </Row>
              </div>
              <div className="pt-3">
                <h3>Developer Settings</h3>
                <Row>
                  <Col xs="12" sm="6" className=" mb-3">
                    <div className="text-uppercase text-muted font-weight-bold">
                      Login ID:
                    </div>
                    <pre className="p-2 border bg-superlight text-dark mt-2">
                      {this.props.account.loginID}
                    </pre>
                  </Col>
                  <Col xs="12" sm="6" className="mb-3">
                    <div className="text-uppercase text-muted font-weight-bold">
                      Transaction Key:
                    </div>
                    <pre className="p-2 border bg-superlight text-dark mt-2">
                      {this.props.account.secret}
                    </pre>
                  </Col>
                  <Col xs="12">
                    <div className="text-uppercase text-muted font-weight-bold">
                      Public Key:
                    </div>
                    <pre
                      className="p-2 border bg-superlight text-dark mt-2"
                      style={{
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                      }}
                    >
                      {this.props.account.publicKey}
                    </pre>
                  </Col>
                </Row>
              </div>
            </td>
          </tr>
        ) : null}

        <Modal
          isOpen={this.state.modify}
          toggle={() => this.closeModifyModal()}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="createBAModalLabel">
              Modify Billing Account
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.closeModifyModal()}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Form onSubmit={this.modify.bind(this)}>
            <div className="modal-body">
              <h3>General Information</h3>
              <FormGroup>
                <h5>Name</h5>
                <Input
                  name="name"
                  placeholder="Name"
                  type="text"
                  className="mb-3"
                  required={true}
                  value={this.state.name}
                  onChange={this.handleInputChange.bind(this)}
                />
              </FormGroup>
              <hr className="mt-2 mb-3"></hr>
              <h3>Login Information</h3>
              <FormGroup>
                <h5>Authorize.net Username</h5>
                <Input
                  name="username"
                  placeholder="Username"
                  type="text"
                  required={false}
                  value={this.state.username}
                  onChange={this.handleInputChange.bind(this)}
                />
              </FormGroup>
              <FormGroup>
                <h5>Authorize.net Password</h5>
                <Input
                  name="password"
                  placeholder="Password"
                  type="text"
                  required={false}
                  value={this.state.password}
                  onChange={this.handleInputChange.bind(this)}
                />
              </FormGroup>
              <FormGroup>
                <h5>Authorize.net Email</h5>
                <Input
                  name="email"
                  placeholder="Email"
                  type="email"
                  required={false}
                  value={this.state.email}
                  onChange={this.handleInputChange.bind(this)}
                />
              </FormGroup>
              <hr className="mt-2 mb-3"></hr>
              <h3>Developer Settings</h3>
              <FormGroup>
                <h5>Authorize.net Payment Gateway ID</h5>
                <Input
                  name="gatewayMerchantID"
                  placeholder="Payment Gateway ID"
                  type="text"
                  className="mb-3"
                  value={this.state.gatewayMerchantID}
                  onChange={this.handleInputChange.bind(this)}
                />
              </FormGroup>
              <FormGroup>
                <h5>Authorize.net Login ID</h5>
                <Input
                  name="key"
                  placeholder="Login ID"
                  type="text"
                  className="mb-3"
                  required={true}
                  value={this.state.key}
                  onChange={this.handleInputChange.bind(this)}
                />
              </FormGroup>
              <FormGroup>
                <h5>Authorize.net Transaction Key</h5>
                <Input
                  name="secret"
                  placeholder="Transaction Key"
                  type="text"
                  className="mb-3"
                  required={true}
                  value={this.state.secret}
                  onChange={this.handleInputChange.bind(this)}
                />
              </FormGroup>
              <FormGroup>
                <h5>Authorize.net Public Key</h5>
                <Input
                  name="publicKey"
                  placeholder="Public Key"
                  type="text"
                  required={false}
                  value={this.state.publicKey}
                  onChange={this.handleInputChange.bind(this)}
                />
              </FormGroup>
            </div>
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="reset"
                onClick={() => this.closeModifyModal()}
              >
                Close
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={this.state.submitting}
              >
                Save
              </Button>
            </div>
          </Form>
        </Modal>
      </>
    );
  }
}

export default BillingAccountRow;
