import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import { isThisTypeNode } from "typescript";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";

class CustomerOrderDrawer extends React.Component {
  state = {};

  constructor() {
    super();

    this.dropdownRef = React.createRef();
  }

  componentDidUpdate(prevProps) {}

  componentDidMount() {}

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 7000);
  }

  toggleModal() {
    this.props.onClose();
  }

  userIsGlobal() {}

  setInvoiceStatus(status) {
    this.setState({
      invoiceStatusSubmitting: true,
    });

    APIV2.updateInvoiceStatus(
      this.props.invoice?.storeID,
      this.props.invoice?._id,
      status
    )
      .then(
        (data) => {
          const invoice = data.data.invoice;

          PubSub.publish(Event.INVOICE.MODIFIED, invoice);
        },
        (e) => {
          console.error(e); //debug

          window.alert("Unable to update invoice status. Try again.");
        }
      )
      .finally(() => {
        this.setState({ invoiceStatusSubmitting: false });
      });
  }

  refund(type = "full", amount = null, note = "") {
    this.setState({ refunding: true });

    let cents =
      this.state.type == "full"
        ? this.props.invoice?.totalPrice
        : parseFloat(this.state.refundAmount?.value) * 100;

    APIV2.refundInvoice(
      this.props?.invoice?.storeID,
      this.props.invoice?._id,
      type,
      cents,
      note
    )
      .then(
        (data) => {
          const invoice = data.data.invoice;

          this.setState({
            openRefund: false,
            refundType: "",
            refundAmount: {
              value: null,
            },
            refundNote: "",
          });

          swal({
            title: "Refund Issued",
            text: `A refund for ${StringUtils.centsToCurrency(
              cents
            )} has been successfully issued. You will see a withdrawal from your business bank account from "PROJECT LEAN NATION" within 2-4 business days corresponding to this refund.`,
            icon: "success",
          });

          PubSub.publish(Event.INVOICE.MODIFIED, invoice);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to refund invoice - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ refunding: false });
      });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  process() {
    this.setState({ processing: true });

    APIV2.processInvoice(this.props?.invoice?.storeID, this.props.invoice?._id)
      .then(
        (data) => {
          const invoice = data.data.invoice;

          PubSub.publish(Event.INVOICE.MODIFIED, invoice);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to collect payment for invoice - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ processing: false });
      });
  }

  userIsGlobal = () => {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  };

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.invoice?.customer?.name}
              <br></br>
            </h3>
            <Drawer.Actions>
              <Whisper
                ref={this.dropdownRef}
                trigger="click"
                placement={"autoVerticalEnd"}
                speaker={
                  <Popover full>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        panel
                        className="py-2 font-weight-800 text-uppercase"
                        style={{ paddingLeft: 12, paddingRight: 12 }}
                      >
                        <h5 className="m-0">Payment</h5>
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={
                          this.props.invoice?.status == "PAID" ||
                          this.props.invoice?.status == "REFUNDED" ||
                          this.props.invoice?.status == "CANCELLED" ||
                          this.state.processing
                        }
                        onClick={() => {
                          this.process();

                          this.dropdownRef.current.close();
                        }}
                      >
                        {this.state.processing
                          ? "Collecting..."
                          : "Collect Payment"}
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={
                          this.props.invoice?.status != "PAID" ||
                          this.state.refunding
                        }
                        onClick={() => {
                          this.setState({
                            openRefund: true,
                          });

                          this.dropdownRef.current.close();
                        }}
                      >
                        Issue Refund
                      </Dropdown.Item>
                      <Dropdown.Item divider></Dropdown.Item>
                      <Dropdown.Item
                        panel
                        className="py-2 font-weight-800 text-uppercase"
                        style={{ paddingLeft: 12, paddingRight: 12 }}
                      >
                        <h5 className="m-0">Status</h5>
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={this.props.invoice?.status == "PAID"}
                        onClick={() => {
                          this.setInvoiceStatus("PAID");

                          this.dropdownRef.current.close();
                        }}
                      >
                        Mark as PAID
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={this.props.invoice?.status == "UNPAID"}
                        onClick={() => {
                          this.setInvoiceStatus("UNPAID");

                          this.dropdownRef.current.close();
                        }}
                      >
                        Mark as UNPAID
                      </Dropdown.Item>

                      {this.userIsGlobal() ? (
                        <Dropdown.Item
                          disabled={this.props.invoice?.status == "REFUNDED"}
                          onClick={() => {
                            this.setInvoiceStatus("REFUNDED");

                            this.dropdownRef.current.close();
                          }}
                        >
                          Mark as REFUNDED
                        </Dropdown.Item>
                      ) : null}

                      <Dropdown.Item
                        disabled={this.props.invoice?.status == "CANCELLED"}
                        onClick={() => {
                          this.setInvoiceStatus("CANCELLED");

                          this.dropdownRef.current.close();
                        }}
                      >
                        Mark as CANCELLED
                      </Dropdown.Item>
                      {this.userIsGlobal() ? (
                        <>
                          <Dropdown.Item
                            panel
                            className="py-2 font-weight-800 text-uppercase"
                            style={{ paddingLeft: 12, paddingRight: 12 }}
                          >
                            <h5 className="m-0">Developer</h5>
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              this.setState({ viewObjectDrawer: true });

                              this.dropdownRef.current.close();
                            }}
                          >
                            View Invoice Object
                          </Dropdown.Item>
                        </>
                      ) : null}
                    </Dropdown.Menu>
                  </Popover>
                }
              >
                <Button size="sm" outline color="secondary">
                  Actions <i className="mdi mdi-chevron-down pl-2"></i>
                </Button>
              </Whisper>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error && (
              <>
                <Alert
                  color=""
                  className="m-0 mb-4 bg-primary-superlight text-dark font-weight-500"
                >
                  {this.state.error}
                </Alert>
              </>
            )}

            <Invoice invoice={this.props?.invoice}></Invoice>
            <hr className="my-4"></hr>
            <div>
              <div
                onClick={() => {
                  this.setState({
                    customerExpanded: !this.state.customerExpanded,
                  });
                }}
                className="cursor-pointer mb-3"
              >
                <Row className="align-items-center">
                  <Col xs="">
                    <h3 className="mb-0">
                      <i
                        className={`${
                          this.state.customerExpanded
                            ? "mdi mdi-chevron-down"
                            : "mdi mdi-chevron-right"
                        } pr-2`}
                      ></i>
                      Member Information
                    </h3>
                  </Col>
                  <Col xs="auto">
                    <Button
                      size="sm"
                      onClick={() => {
                        this.props.history.push(
                          "/storefront/" +
                            this.props.invoice?.storeID +
                            "/members/details/" +
                            this.props.invoice?.customer?._id +
                            "/summary"
                        );
                      }}
                      color="secondary"
                      outline
                    >
                      View
                    </Button>
                  </Col>
                </Row>
              </div>
              <Collapse isOpen={this.state.customerExpanded}>
                <div>
                  <CustomerContactInformationWidget
                    modifiable={false}
                    customer={this.props.invoice?.customer}
                  ></CustomerContactInformationWidget>
                </div>
                <div className="">
                  <CustomerBillingAddressWidget
                    customer={this.props.invoice?.customer}
                  ></CustomerBillingAddressWidget>
                </div>
                <div className="">
                  <CustomerShippingAddressWidget
                    customer={this.props.invoice?.customer}
                  ></CustomerShippingAddressWidget>
                </div>
              </Collapse>
            </div>
          </Drawer.Body>
        </Drawer>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.state.viewObjectDrawer}
          onClose={() => this.setState({ viewObjectDrawer: false })}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Invoice Object
            </h3>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            <div
              className="mt-3 p-2 border bg-white"
              dangerouslySetInnerHTML={{
                __html: StringUtils.jsonToHTML(
                  this.props.invoice ? this.props.invoice : {}
                ),
              }}
            ></div>
          </Drawer.Body>
        </Drawer>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.state.openRefund}
          onClose={() => this.setState({ openRefund: false })}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Issue Invoice Refund
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                color="primary"
                disabled={
                  !this.state.refundType ||
                  this.state.refunding ||
                  (this.state.refundType != "full" &&
                    !this.state.refundAmount?.value) ||
                  parseFloat(this.state.refundAmount?.value) < 0 ||
                  Math.round(parseFloat(this.state.refundAmount?.value) * 100) >
                    this.props.invoice?.totalPrice
                }
                onClick={() => {
                  this.refund(
                    this.state.refundType,
                    this.state.refundAmount?.value,
                    this.state.refundNote
                  );
                }}
              >
                {this.state.refunding ? (
                  <Spinner size="sm"></Spinner>
                ) : (
                  "Issue Refund"
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error && (
              <>
                <Alert
                  color=""
                  className="m-0 mb-4 bg-primary-superlight text-dark font-weight-500"
                >
                  {this.state.error}
                </Alert>
              </>
            )}
            <FormGroup>
              <h5>Refund Type</h5>
              <SelectPicker
                block
                preventOverflow={true}
                placement="auto"
                data={[
                  {
                    value: "full",
                    label: (
                      <>
                        <p className="m-0">Full Refund</p>
                        <small style={{ lineHeight: 1.2 }}>
                          Issue a refund for the full invoice amount.
                        </small>
                      </>
                    ),
                  },
                  {
                    value: "partial",
                    label: (
                      <>
                        <p className="m-0">Partial Refund</p>
                        <small style={{ lineHeight: 1.2 }}>
                          Issue a partial refund for a custom amount.
                        </small>
                      </>
                    ),
                  },
                ]}
                value={this.state.refundType}
                onChange={(v) => {
                  this.setState({
                    refundType: v,
                  });

                  if (v == "full") {
                    this.setState({
                      refundAmount: {
                        value: this.props.invoice?.totalPrice / 100,
                      },
                    });
                  }
                }}
                placeholder="Select Refund Type"
              ></SelectPicker>
            </FormGroup>
            <FormGroup>
              <h5>Refund Amount</h5>
              <NumberFormat
                className="form-control form-control-sm"
                fixedDecimalScale={true}
                decimalScale={2}
                required={true}
                disabled={
                  !this.state.refundType || this.state.refundType == "full"
                }
                value={this.state.refundAmount?.value}
                placeholder="Price"
                onValueChange={(v) => {
                  this.setState({
                    refundAmount: v,
                  });
                }}
                thousandSeparator={true}
                prefix={"$"}
              />
            </FormGroup>
            <FormGroup>
              <h5 className="mb-0">Refund Note (Optional)</h5>
              <p className="small" style={{ lineHeight: 1.2 }}>
                Add a custom note to the refund line item of the invoice.
              </p>
              <Input
                type="text"
                value={this.state.refundNote}
                disabled={!this.state.refundType}
                name="refundNote"
                placeholder="Refund Note"
                onChange={this.handleInputChange.bind(this)}
                bsSize="sm"
              ></Input>
            </FormGroup>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(CustomerOrderDrawer);
