import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  BarChart,
  Card,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import {
  Axis,
  Chart,
  Coordinate,
  Interaction,
  Interval,
  Legend,
  Tooltip,
} from "bizcharts";
import ChartMetricHeader from "./ChartMetricHeader";

class CancelledOrderCountSummaryChartCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    tab: "performanceTrend",
  };

  async _fetchRateData(cubejsApi, stores, dateRange) {
    let metricType = "overall";

    if (this.props.metricType) {
      metricType = this.props.metricType;
    }

    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: [
            "MetricsLTV.avgMetadataTotalcancelledorders",
            "MetricsLTV.avgMetadataTotalcustomers",
          ],
          order: {
            "MetricsLTV.createdat": "asc",
          },

          timeDimensions: [
            {
              dimension: "MetricsLTV.createdat",
              dateRange: [dateRange[0], dateRange[1]],
            },
          ],

          filters: [
            {
              member: "MetricsLTV.name",
              operator: "contains",
              values: [`ltv_store_${metricType}`],
            },
            {
              member: "MetricsLTV.metadataStoreid",
              operator: "equals",
              values: stores,
            },
            {
              member: "MetricsLTV.createdat",
              operator: "beforeDate",
              values: [this.props.dateRange[1].toISOString()],
            },
            {
              member: "MetricsLTV.createdat",
              operator: "beforeDate",
              values: [moment().toISOString()],
            },
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          if (
            data?.length &&
            data[0].hasOwnProperty(
              "MetricsLTV.avgMetadataTotalcancelledorders"
            ) &&
            data[0].hasOwnProperty("MetricsLTV.avgMetadataTotalcustomers")
          ) {
            return resolve(
              data[0]["MetricsLTV.avgMetadataTotalcustomers"] > 0
                ? parseFloat(
                    (
                      data[0]["MetricsLTV.avgMetadataTotalcancelledorders"] /
                      data[0]["MetricsLTV.avgMetadataTotalcustomers"]
                    ).toFixed(1)
                  )
                : 0
            );
          }

          return resolve(null);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  /**
   * Fetches the order data by week for the given stores & date range
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchRateDataByWeek(cubejsApi, stores, dateRange) {
    let metricType = "overall";

    if (this.props.metricType) {
      metricType = this.props.metricType;
    }

    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: [
            "MetricsLTV.avgMetadataTotalcancelledorders",
            "MetricsLTV.avgMetadataTotalcustomers",
          ],
          order: {
            "MetricsLTV.createdat": "asc",
          },

          timeDimensions: [
            {
              dimension: "MetricsLTV.createdat",
              dateRange: [dateRange[0], dateRange[1]],
              granularity: "week",
            },
          ],
          dimensions: ["Stores.name"],
          filters: [
            {
              member: "MetricsLTV.name",
              operator: "contains",
              values: [`ltv_store_${metricType}`],
            },
            {
              member: "MetricsLTV.metadataStoreid",
              operator: "equals",
              values: stores,
            },
            {
              member: "MetricsLTV.createdat",
              operator: "beforeDate",
              values: [this.props.dateRange[1].toISOString()],
            },
            {
              member: "MetricsLTV.createdat",
              operator: "beforeDate",
              values: [moment().toISOString()],
            },
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          if (!data?.length) {
            return resolve(null);
          }

          console.log(data);

          data = data.map((item) => {
            return {
              storeName: item["Stores.name"]
                ?.replace("Project LeanNation", "PLN")
                ?.trim(),
              fulfillmentDate: moment(item["MetricsLTV.createdat.week"])
                .day(6)
                .hours(0)
                .minute(0)
                .second(0)
                .millisecond(0)
                .toDate(),
              fulfillmentDateString: moment(item["MetricsLTV.createdat.week"])
                .day(6)
                .hours(0)
                .minute(0)
                .second(0)
                .millisecond(0)
                .format("MM/DD/YY"),
              count:
                item["MetricsLTV.avgMetadataTotalcustomers"] > 0
                  ? parseFloat(
                      (
                        item["MetricsLTV.avgMetadataTotalcancelledorders"] /
                        item["MetricsLTV.avgMetadataTotalcustomers"]
                      ).toFixed(1)
                    )
                  : 0,
            };
          });

          let outByStore = data.map((item) => {
            return {
              storeName: item?.storeName,
              fulfillmentDate: item?.fulfillmentDate,
              fulfillmentDateString: item?.fulfillmentDateString,
              count: parseFloat((item?.count).toFixed(1)),
            };
          });

          let outOverall = [];

          let totalCount = 0;
          let totalValues = 0;

          for (let i = 0; i < data?.length; i++) {
            const item = data[i];

            const overallIDX = _.findIndex(outOverall, {
              fulfillmentDateString: item.fulfillmentDateString,
            });

            if (overallIDX == -1) {
              outOverall.push({
                fulfillmentDateString: item?.fulfillmentDateString,
                count: parseFloat((item?.count).toFixed(1)),
                fulfillmentDate: item?.fulfillmentDate,
                totalStores: 1,
              });
            } else {
              outOverall[overallIDX].count += parseFloat(item.count.toFixed(1));
              outOverall[overallIDX].totalStores++;
            }

            totalCount += item?.count;
            totalValues++;
          }

          for (let i = 0; i < outOverall?.length; i++) {
            outOverall[i].count = parseFloat(
              (outOverall[i].count / outOverall[i].totalStores).toFixed(1)
            );
          }

          console.log(totalCount / totalValues, data?.length);

          totalCount = parseFloat((totalCount / data?.length).toFixed(1));

          outByStore = _.sortBy(outByStore, "fulfillmentDate");

          outOverall = _.sortBy(outOverall, "fulfillmentDate");

          return resolve({
            byStore: outByStore,
            overall: outOverall,
            totalCount,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async loadDetailedReport(stores, dateRange) {
    this.setState({
      loading: true,
      dataAvailable: false,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!stores?.length || !dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    let currentCount = null;
    let overallCurrent = null;
    let overallPrevious = null;

    try {
      currentCount = await this._fetchRateDataByWeek(
        cubejsApi,
        stores,
        dateRange
      );
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load client order quantities by week.",
        loading: false,
      });

      return;
    }

    try {
      overallCurrent = await this._fetchRateData(cubejsApi, stores, dateRange);
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load client order quantities by week.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });

      return;
    }

    let storeNames = [];

    for (let i = 0; i < currentCount?.byStore?.length; i++) {
      let keys = _.keys(currentCount.byStore[i]);

      let names = _.filter(keys, (name) => {
        return name.includes("PLN");
      });

      storeNames = storeNames.concat(names);
    }

    storeNames = _.uniq(storeNames);

    let previousCount = null;

    let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

    secondDifference = secondDifference / 1000;

    let startMoment = moment(dateRange[0].toISOString());
    let endMoment = moment(dateRange[1].toISOString());

    startMoment.subtract(secondDifference, "seconds");
    endMoment.subtract(secondDifference + 1, "seconds");

    console.log(startMoment.toDate(), endMoment.toDate());

    try {
      previousCount = await this._fetchRateDataByWeek(cubejsApi, stores, [
        startMoment.toDate(),
        endMoment.toDate(),
      ]);
    } catch (e) {}

    try {
      overallPrevious = await this._fetchRateData(cubejsApi, stores, [
        startMoment.toDate(),
        endMoment.toDate(),
      ]);
    } catch (e) {}

    if (overallPrevious) {
      let percentChange = parseFloat(
        (((overallCurrent - overallPrevious) / overallPrevious) * 100).toFixed(
          1
        )
      );

      let declineMode = "";
      let isNegative = false;

      if (percentChange > 0) {
        if (Math.abs(percentChange) < 1) {
          declineMode = "unchanged";
        } else if (Math.abs(percentChange) < 4) {
          declineMode = "moderateIncrease";
        } else if (Math.abs(percentChange) >= 4) {
          declineMode = "increase";
        }
      } else {
        isNegative = true;

        if (Math.abs(percentChange) < 1) {
          declineMode = "unchanged";
        } else if (Math.abs(percentChange) < 4) {
          declineMode = "moderateDecrease";
        } else if (Math.abs(percentChange) >= 4) {
          declineMode = "decrease";
        }
      }

      this.setState({
        changeIsNegative: isNegative,
        deltaType: declineMode,
        percentChange: percentChange,
        percentChangeString: Math.abs(percentChange).toFixed(1) + "%",
      });
    }

    if (previousCount !== null) {
      this.setState({
        previousCount: previousCount,
      });
    }

    let performanceTrend = [];

    for (let i = 0; i < currentCount?.overall?.length; i++) {
      const item = currentCount?.overall[i];

      performanceTrend.push({
        fulfillmentDate: item?.fulfillmentDate,
        fulfillmentDateString: "EOW " + item?.fulfillmentDateString,
        "Average Orders": item?.count,
        Previous: null,
      });
    }

    for (let i = 0; i < previousCount?.overall?.length; i++) {
      const item = previousCount?.overall[i];

      //console.log(item?.fulfillmentDate);

      const fulfillmentDate = moment(item?.fulfillmentDate?.toISOString())
        .add(secondDifference, "seconds")
        .day(6)
        .hours(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      const realDate = moment(item?.fulfillmentDate?.toISOString())
        .day(6)
        .hours(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      //console.log(fulfillmentDate?.toISOString());

      const idx = _.findIndex(performanceTrend, {
        fulfillmentDateString: "EOW " + fulfillmentDate?.format("MM/DD/YY"),
      });

      if (idx >= 0) {
        performanceTrend[idx].Previous = item?.count;
        performanceTrend[idx].fulfillmentDateString += ` vs. ${realDate?.format(
          "MM/DD/YY"
        )}`;
      } else if (
        fulfillmentDate.toDate() >= dateRange[0] &&
        fulfillmentDate.toDate() <= dateRange[1]
      ) {
        performanceTrend.push({
          Previous: item?.count,
          "Average Orders": null,
          fulfillmentDate: fulfillmentDate?.toDate(),
          fulfillmentDateString:
            "EOW " +
            fulfillmentDate?.format("MM/DD/YY") +
            ` vs. ${realDate?.format("MM/DD/YY")}`,
        });
      }
    }

    performanceTrend = _.sortBy(performanceTrend, "fulfillmentDate");

    storeNames.sort();

    let colors = StringUtils.randomChartColorSet(storeNames?.length);

    this.setState({
      currentCount: currentCount,
      loading: false,
      performanceTrend,
      byStore: currentCount?.byStore,
      storeNames: storeNames,
      byStoreColors: colors,
      overallCurrent,
      overallPrevious,
    });

    if (typeof this.props.onLoaded == "function") {
      this.props.onLoaded();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stores != prevProps?.stores ||
      this.props.dateRange != prevProps?.dateRange ||
      this.props.metricType != prevProps?.metricType
    ) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload != prevProps.reload && this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  componentDidMount() {
    if (this.props.store && this.props.dateRange) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  render() {
    return (
      <>
        <Card marginTop="mt-0">
          <ChartMetricHeader
            title="Cancelled Orders / Member Summary"
            description={`Avg. ${
              this.props.metricType == "overall" ? "lifetime " : ""
            }order cancellations / member ${
              this.props.metricType == "12mo"
                ? " who signed up with 12 months of"
                : " through"
            } ${moment(this.props.dateRange[1].toISOString()).format(
              "MM/DD/YYYY"
            )}.`}
            isIncreasePositive={false}
            actions={
              <>
                <Button
                  size="sm"
                  outline
                  color="dark"
                  className="border-0 btn-icon-only"
                  disabled={this.state.loading}
                  onClick={() => {
                    this.loadDetailedReport(
                      this.props.stores,
                      this.props.dateRange
                    );
                  }}
                >
                  {this.state.loading ? (
                    <Spinner size="sm"></Spinner>
                  ) : (
                    <i className="mdi mdi-refresh"></i>
                  )}
                </Button>
                {/*<Button
                  size="sm"
                  outline
                  color="dark"
                  className="border-0 btn-icon-only"
                  disabled={this.state.loading || !this.state.dataAvailable}
                >
                  <i className="mdi mdi-download"></i>
                  </Button>*/}
              </>
            }
            loading={this.state.loading}
            dataAvailable={this.state.dataAvailable}
            metric={
              StringUtils.numberFormat(this.state.overallCurrent) + " Orders"
            }
            comparisonMetric={
              this.state.overallPrevious !== null
                ? StringUtils.numberFormat(this.state.overallPrevious)
                : null
            }
            dateRange={this.props.dateRange}
            deltaType={this.state.deltaType}
            percentChange={this.state.percentChangeString}
            showPercentChange={true}
          ></ChartMetricHeader>

          <TabList
            color="orange"
            defaultValue="performanceTrend"
            handleSelect={(value) => {
              this.setState({
                tab: value,
              });

              if (value == "byStore") {
                this.setState({
                  chartAdjust: [{ type: "stack" }],
                });
              } else {
                this.setState({
                  chartAdjust: [{ type: "dodge", marginRatio: 1 / 32 }],
                });
              }
            }}
            marginTop="mt-3"
            disabled={this.state.loading || !this.state.dataAvailable}
          >
            <Tab value="performanceTrend" text="Rate Trend" />
            <Tab value="byStoreBar" text="Rate By Store" />
          </TabList>
          {this.state.loading ? (
            <div
              className="skeleton"
              style={{ height: "calc(320px + 1rem)", width: "100%" }}
            >
              &nbsp;
            </div>
          ) : (
            <>
              {this.state.dataAvailable ? (
                <>
                  {this.state.tab == "performanceTrend" && (
                    <div className="mt-3">
                      <AreaChart
                        data={this.state.performanceTrend}
                        categories={["Average Orders", "Previous"]}
                        dataKey="fulfillmentDateString"
                        colors={["orange", "gray"]}
                        valueFormatter={(number) => {
                          return StringUtils.numberFormat(number) + " Orders";
                        }}
                        height="h-80"
                      />
                    </div>
                  )}
                  {this.state.tab == "byStoreBar" && (
                    <>
                      <div className="mt-3">
                        <Chart
                          height={320}
                          appendPadding={[20, 0, 10, 30]}
                          data={this.state.byStore}
                          autoFit
                        >
                          <Coordinate transposed></Coordinate>
                          <Tooltip
                            itemTpl={`<li style="padding-bottom: 8px; color: var(--dark);" data-index={index}><span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>{name}:<span style="padding-left: 5px;">\{value} Orders / Member</span></li>`}
                            shared
                          ></Tooltip>
                          <Axis
                            name="fulfillmentDateString"
                            label={{ offset: 12 }}
                          ></Axis>
                          <Axis
                            name="count"
                            grid={{
                              line: {
                                style: {
                                  stroke: "#CCC",
                                  lineDash: [3, 3],
                                },
                              },
                            }}
                          ></Axis>
                          <Legend
                            marker={{ symbol: "circle" }}
                            label={{}}
                            position="top-right"
                            layout={"horizontal"}
                            offsetY={0}
                          ></Legend>
                          <Interval
                            adjust={this.state.chartAdjust}
                            color="storeName"
                            position="fulfillmentDateString*count"
                          ></Interval>
                          <Interaction type="active-region"></Interaction>
                        </Chart>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "calc(320px + 1rem)", width: "100%" }}
                >
                  <p className="m-0">
                    No data available.
                    {this.state.error ? ` ${this.state.error}` : null}
                  </p>
                </div>
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default CancelledOrderCountSummaryChartCard;
