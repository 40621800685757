import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import Alert from "reactstrap/lib/Alert";
import { SelectPicker } from "rsuite";
import CustomerOpenActivitiesWidget from "./CustomerOpenActivitiesWidget";
import CustomerUnclaimedBoxWidget from "./CustomerUnclaimedBoxWidget";
import _ from "underscore";
import Constant from "lib/Constant";
import moment from "moment";

class MemberExperienceScoreCard extends React.Component {
  state = {
    expand: false,
    phone: {
      value: "",
    },
    zip: {
      value: "",
    },
    name: "",
    email: "",
    checkinLoading: true,
    consultationLoading: true,
  };

  countCallTasks(customer) {
    let count = 0;

    if (customer?.cohort_customerCallTaskWeek1Complete) {
      count++;
    }

    if (customer?.cohort_customerCallTaskWeek3Complete) {
      count++;
    }

    return count;
  }

  countOrders(customer) {
    let count = 0;

    if (customer?.cohort_customerOnePlusOrderFirst30Days) {
      count++;
    }

    if (customer?.cohort_customerOrdersFirst30Days) {
      count++;
    }

    return count;
  }

  loadCheckIn(showLoader = true) {
    if (showLoader) {
      this.setState({
        checkinLoading: true,
      });
    }

    APIV2.getAppointmentsForStore(
      this.props.customer?.storeID,
      1,
      1,
      {
        customerID: this.props.customer?._id,
        storeID: this.props.customer?.storeID,
        appointmentType: "ec-consult",
        $or: [
          {
            status: "COMPLETED",
          },
          {
            status: "CONFIRMED",
            startsAt: { $gte: { $date: moment().toISOString() } },
          },
        ],
      },
      {
        startsAt: -1,
      }
    )
      .then(
        (data) => {
          let appointments = data?.data?.appointments?.length
            ? data.data.appointments[0]
            : null;

          this.setState({
            checkIn: appointments,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          checkinLoading: false,
        });
      });
  }

  componentDidMount() {
    if (this.props.customer) {
      this.loadCheckIn(true);
      this.loadConsultation(true);
    }

    PubSub.subscribe(Event.CUSTOMER.RELOAD, () => {
      if (this.props.customer) {
        this.loadCheckIn(true);
        this.loadConsultation(true);
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.customer?._id != prevProps?.customer?._id &&
      this.props.customer
    ) {
      this.loadCheckIn(true);
      this.loadConsultation(true);
    }
  }

  loadConsultation(showLoader = true) {
    if (showLoader) {
      this.setState({
        consultationLoading: true,
      });
    }

    APIV2.getAppointmentsForStore(
      this.props.customer?.storeID,
      1,
      1,
      {
        customerID: this.props.customer?._id,
        storeID: this.props.customer?.storeID,
        appointmentType: "nc-consult",
        $or: [
          {
            status: "COMPLETED",
          },
          {
            status: "CONFIRMED",
            startsAt: { $gte: { $date: moment().toISOString() } },
          },
        ],
      },
      {
        startsAt: -1,
      }
    )
      .then(
        (data) => {
          let appointments = data?.data?.appointments?.length
            ? data.data.appointments[0]
            : null;

          this.setState({
            consultation: appointments,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          consultationLoading: false,
        });
      });
  }

  render() {
    return (
      <>
        <Card className="border">
          <CardHeader className="p-2">
            <Row className="align-items-center">
              <Col xs="">
                <h4 className="text-dark m-0">Member Experience Score</h4>
                <p className="small m-0 text-dark">
                  Based on activity and order history during the first 30 days
                  after signup
                </p>
              </Col>
              <Col xs="auto">
                <div
                  className={`${
                    this.props.customer?.memberHealthScore <= 50
                      ? "bg-danger text-white"
                      : ""
                  } ${
                    this.props.customer?.memberHealthScore > 50 &&
                    this.props.customer?.memberHealthScore <= 70
                      ? "bg-yellow text-dark"
                      : ""
                  } ${
                    this.props.customer?.memberHealthScore > 70
                      ? "bg-success text-white"
                      : ""
                  } rounded d-flex align-items-center  justify-content-center`}
                  style={{ height: 30, width: 30 }}
                >
                  <div className="text-center">
                    <div
                      className="font-weight-bold"
                      style={{
                        fontSize: 16,
                        lineHeight: 1.1,
                      }}
                    >
                      {this.props.customer?.memberHealthScore}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="p-2 border-bottom">
            <Row className="align-items-center">
              <Col xs="12" sm="6">
                <div>
                  <Row className="align-items-center">
                    <Col xs="auto" className="pr-2">
                      <h3
                        style={{
                          fontSize: 22,
                          lineHeight: 1,
                        }}
                        className="m-0"
                      >
                        {this.props.customer?.cohort_received_consult ? (
                          <i className="mdi mdi-check-circle text-success"></i>
                        ) : (
                          <i className="mdi mdi-close-circle text-danger"></i>
                        )}
                      </h3>
                    </Col>
                    <Col xs="" className="pl-0">
                      <p
                        style={{
                          fontSize: 14,
                          lineHeight: 1.2,
                        }}
                        className="m-0 text-dark"
                      >
                        Completed LEAN Consultation
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs="12" sm="6" className="mt-1 mt-sm-0">
                <div>
                  <Row className="align-items-center">
                    <Col xs="auto" className="pr-2">
                      <h3
                        style={{
                          fontSize: 22,
                          lineHeight: 1,
                        }}
                        className="m-0"
                      >
                        {this.props.customer?.cohort_received_checkin ? (
                          <i className="mdi mdi-check-circle text-success"></i>
                        ) : (
                          <i className="mdi mdi-close-circle text-danger"></i>
                        )}
                      </h3>
                    </Col>
                    <Col xs="" className="pl-0">
                      <p
                        style={{
                          fontSize: 14,
                          lineHeight: 1.2,
                        }}
                        className="m-0 text-dark"
                      >
                        Completed A Member Check-In
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs="12" sm="6" className="mt-1">
                <div>
                  <Row className="align-items-center">
                    <Col xs="auto" className="pr-2">
                      <h3
                        style={{
                          fontSize: 22,
                          lineHeight: 1,
                        }}
                        className="m-0"
                      >
                        {this.countCallTasks(this.props.customer) == 2 ? (
                          <i className="mdi mdi-check-circle text-success"></i>
                        ) : null}
                        {this.countCallTasks(this.props.customer) == 0 ? (
                          <i className="mdi mdi-close-circle text-danger"></i>
                        ) : null}
                        {this.countCallTasks(this.props.customer) == 1 ? (
                          <i className="mdi mdi-circle-half-full text-success"></i>
                        ) : null}
                      </h3>
                    </Col>
                    <Col xs="" className="pl-0">
                      <p
                        style={{
                          fontSize: 14,
                          lineHeight: 1.2,
                        }}
                        className="m-0 text-dark"
                      >
                        Completed{" "}
                        {this.countCallTasks(this.props.customer) > 0
                          ? `${this.countCallTasks(
                              this.props.customer
                            )}/2 Follow-Up Calls In First 30 Days`
                          : "2 Follow-Up Calls In First 30 Days"}
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs="12" sm="6" className="mt-1">
                <div>
                  <Row className="align-items-center">
                    <Col xs="auto" className="pr-2">
                      <h3
                        style={{
                          fontSize: 22,
                          lineHeight: 1,
                        }}
                        className="m-0"
                      >
                        {this.countOrders(this.props.customer) == 2 ? (
                          <i className="mdi mdi-check-circle text-success"></i>
                        ) : null}
                        {this.countOrders(this.props.customer) == 0 ? (
                          <i className="mdi mdi-close-circle text-danger"></i>
                        ) : null}
                        {this.countOrders(this.props.customer) == 1 ? (
                          <i className="mdi mdi-circle-half-full text-success"></i>
                        ) : null}
                      </h3>
                    </Col>
                    <Col xs="" className="pl-0">
                      <p
                        style={{
                          fontSize: 14,
                          lineHeight: 1.2,
                        }}
                        className="m-0 text-dark"
                      >
                        Received 2+ Orders In The First 30 Days
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </CardBody>
          <CardBody className="p-2">
            <Row className="align-items-center">
              <Col xs="12" sm="auto">
                <p
                  style={{
                    fontSize: 14,
                    lineHeight: 1.2,
                  }}
                  className="m-0 text-dark font-weight-bold"
                >
                  Recent LEAN Consultation:
                </p>
              </Col>
              <Col xs="12" sm="" className="mt-1 pl-sm-0 mt-sm-0">
                <p
                  style={{
                    fontSize: 14,
                    lineHeight: 1.2,
                  }}
                  className="m-0 text-dark"
                >
                  {this.state.consultationLoading ? (
                    <p
                      style={{
                        fontSize: 14,
                        lineHeight: 1.2,
                        width: 140,
                        height: 16,
                      }}
                      className={`m-0 skeleton rounded`}
                    ></p>
                  ) : (
                    <p
                      style={{
                        fontSize: 14,
                        lineHeight: 1.2,
                      }}
                      className={`m-0 ${
                        !this.state.consultation
                          ? "text-danger font-weight-bold"
                          : "text-dark"
                      }`}
                    >
                      {this.state.consultation &&
                      moment(this.state.consultation?.startsAt).isAfter(
                        moment()
                      )
                        ? "Scheduled "
                        : null}
                      {this.state.consultation &&
                      moment(this.state.consultation?.startsAt).isSameOrBefore(
                        moment()
                      ) &&
                      this.state.consultation?.status == "COMPLETED"
                        ? "Completed "
                        : null}
                      {this.state.consultation ? (
                        <>
                          <span className="pr-1">
                            {moment(
                              this.state.consultation?.startsAt
                            ).fromNow()}
                          </span>
                          (
                          {moment(this.state.consultation?.startsAt).format(
                            "MM/DD/YYYY"
                          )}
                          )
                        </>
                      ) : (
                        "Not Completed"
                      )}
                    </p>
                  )}
                </p>
              </Col>
            </Row>
            <Row className="align-items-center mt-2">
              <Col xs="12" sm="auto">
                <p
                  style={{
                    fontSize: 14,
                    lineHeight: 1.2,
                  }}
                  className="m-0 text-dark font-weight-bold"
                >
                  Recent Member Check-In:
                </p>
              </Col>
              <Col xs="12" sm="" className="mt-1 pl-sm-0 mt-sm-0">
                {this.state.checkinLoading ? (
                  <p
                    style={{
                      fontSize: 14,
                      lineHeight: 1.2,
                      width: 140,
                      height: 16,
                    }}
                    className={`m-0 skeleton rounded`}
                  ></p>
                ) : (
                  <p
                    style={{
                      fontSize: 14,
                      lineHeight: 1.2,
                    }}
                    className={`m-0 ${
                      !this.state.checkIn ||
                      (this.state.checkIn?.startsAt &&
                        moment(this.state.checkIn.startsAt).isBefore(
                          moment().subtract(21, "days")
                        ))
                        ? "text-danger font-weight-bold"
                        : "text-dark"
                    }`}
                  >
                    {this.state.checkIn &&
                    moment(this.state.checkIn?.startsAt).isAfter(moment())
                      ? "Scheduled "
                      : null}
                    {this.state.checkIn &&
                    moment(this.state.checkIn?.startsAt).isSameOrBefore(
                      moment()
                    ) &&
                    this.state.checkIn?.status == "COMPLETED"
                      ? "Completed "
                      : null}
                    {this.state.checkIn ? (
                      <>
                        <span className="pr-1">
                          {moment(this.state.checkIn?.startsAt).fromNow()}
                        </span>
                        (
                        {moment(this.state.checkIn?.startsAt).format(
                          "MM/DD/YYYY"
                        )}
                        )
                      </>
                    ) : (
                      "Not Completed"
                    )}
                  </p>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default withRouter(MemberExperienceScoreCard);
