import _ from "underscore";

export default class NSOUtils {
  static TOTAL_RETAIL_CHECKS = 7;
  static TOTAL_PRELAUNCH_CHECKS = 12;
  static TOTAL_FBD_CHECKS = 2;

  static storeHasMenus(store) {
    if (!store?.menus?.meals) {
      return false;
    }

    const keys = _.keys(store?.menus?.meals);

    if (!keys?.length) {
      return false;
    }

    for (let i = 0; i < keys.length; i++) {
      if (keys[i] == "active" || keys[i] == "upcoming") {
        continue;
      }

      if (!store?.menus?.meals[keys[i]]?.active) {
        return false;
      }
    }

    return true;
  }

  static getPrelaunchCompletion(store, scoreOnly = false) {
    let completed = 0;

    if (!store) {
      return 0;
    }

    if (store?.operations?.territories?.length) {
      completed++;
    }

    if (store?.products?.mealPlans?.length) {
      completed++;
    }

    if (
      store?.operations?.billingAccount &&
      store?.operations?.billingAccount?._id != "62e3ef0891b803110b1a114b"
    ) {
      completed++;
    }

    if (store?.twilioNumber) {
      completed++;
    }

    if (store?.facebookPageID) {
      completed++;
    }

    if (store?.instagramAccountID) {
      completed++;
    }

    if (store?.googleMapsPlaceID && store?.googleMapsReviewURL) {
      completed++;
    }

    if (this.storeHasMenus(store)) {
      completed++;
    }

    if (store?.prelaunchMigrationJobID) {
      completed++;
    }

    if (store?.nsoCheckFoundingMemberCouponAdded) {
      completed++;
    }

    if (store?.nsoCheckBroadcastsScheduled) {
      completed++;
    }

    if (store?.nsoCheckCheckoutTested) {
      completed++;
    }

    if (scoreOnly) {
      return completed;
    }

    return completed / this.TOTAL_PRELAUNCH_CHECKS;
  }

  static getRetailCompletion(store, scoreOnly = false) {
    let completed = 0;

    if (!store) {
      return 0;
    }

    if (store?.squareLocation) {
      completed++;
    }

    if (store?.nsoCheckTaxRequirementsReviewed) {
      completed++;
    }

    if (store?.weeklyRetailGoal && store?.weeklyOrderGoal) {
      completed++;
    }
    if (store?.nsoCheckSquareCatalog) {
      completed++;
    }

    if (store?.nsoCheckAppleMaps) {
      completed++;
    }

    if (store?.nsoCheckHoursUpdated) {
      completed++;
    }

    if (store?.nsoCheckHoursUpdatedMaps) {
      completed++;
    }

    if (scoreOnly) {
      return completed;
    }

    return completed / this.TOTAL_RETAIL_CHECKS;
  }

  static getFirstBillDateCompletion(store, scoreOnly = false) {
    let completed = 0;

    if (!store) {
      return 0;
    }

    if (
      store?.operations?.terms?.general &&
      store?.operations?.terms?.pickup &&
      store?.operations?.terms?.delivery
    ) {
      completed++;
    }

    if (store?.nsoCheckAuthNetReview) {
      completed++;
    }

    if (scoreOnly) {
      return completed;
    }

    return completed / this.TOTAL_FBD_CHECKS;
  }

  static getOverallCompletion(store, scoreOnly = false) {
    const completed =
      this.getPrelaunchCompletion(store, true) +
      this.getRetailCompletion(store, true) +
      this.getFirstBillDateCompletion(store, true);

    if (scoreOnly) {
      return completed;
    }

    return (
      completed /
      (this.TOTAL_PRELAUNCH_CHECKS +
        this.TOTAL_RETAIL_CHECKS +
        this.TOTAL_FBD_CHECKS)
    );
  }

  static getTotalCheckCount() {
    return (
      this.TOTAL_PRELAUNCH_CHECKS +
      this.TOTAL_RETAIL_CHECKS +
      this.TOTAL_FBD_CHECKS
    );
  }
}
