/**
 * Generic settings key/value store.
 * 
 * Author: Brandon Hudson
 * Created: 12-16-2019
 */

class Settings {

    constructor(data = {}) {
        if (!data) {
            this.data = {};

            return;
        }

        this.data = data;
    }

    has(key) {
        return this.data.hasOwnProperty(key);
    }

    get(key) {
        if (!this.has(key)) {
            return null;
        }

        return this.data[key];
    }

    set(key, value) {
        this.data[key] = value;
    }

    delete(key) {
        if (this.has(key)) {
            delete this.data[key];
        }
    }

    clear() {
        this.data = {};
    }

    _toData() {
        return this.data;
    }
}

export default Settings;