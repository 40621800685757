import ArrowText from "components/ArrowText";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  Badge,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import Card from "reactstrap/lib/Card";
import moment from "moment";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import _ from "underscore";
import SupportTicketDetailDrawer from "components/Drawers/SupportTicketDetailDrawer";
import TaskCreateDrawer from "components/Drawers/TaskCreateDrawer";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";

class StoreAllTicketsWidget extends React.Component {
  state = {
    expand: false,
    taskDetail: null,
    active: {
      id: "All Incomplete",
      name: "All Incomplete",
    },
    tasks: [],
    count: 25,
    page: 1,
  };

  componentDidMount() {
    this.handleTabChange(this.state.active, true);

    PubSub.subscribe(Event.TASK.CREATED, () => {
      this.handleTabChange(this.state.active, true);
    });

    PubSub.subscribe(Event.TASK.MODIFIED, (order) => {
      this.handleTabChange(this.state.active, true);

      if (this?.state?.taskDetail?._id == order?._id) {
        this.setState({
          taskDetail: order,
        });
      }
    });
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadTasks(
      page,
      this.state.count,
      this.state.filters,
      this.state.orderBy
    );
  }

  loadTasks(page = 1, count = 25, filters = null, orderBy) {
    this.setState({
      loadingTasks: true,
    });

    console.log("LOAD", page, count, filters, orderBy);

    APIV2.getTechSupportTickets(page, count, filters, orderBy)
      .then(
        (data) => {
          if (data && data.data) {
            let tasks = [];

            if (data.data.tickets?.length) {
              tasks = data.data.tickets;
            }

            this.setState({
              tasks,
              pagination: data.data.pagination,
              result: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the tasks. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingTasks: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.match?.params?.storeID != this.props?.match?.params?.storeID
    ) {
      this.handleTabChange(this.state.active, true);
    }
  }

  taskDetailDrawer(task) {
    this.setState({
      taskDetail: task,
      detailOpen: true,
    });
  }

  handleTabChange(option, force = false) {
    let filters = null;
    let orderBy = {
      dueDate: 1,
    };

    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    console.log(option);

    if (option.id == "All Incomplete") {
      filters = {
        status: { $in: ["Open", "Investigating", "In Progress", "On Hold"] },
        storeTask: true,
        supportTicket: true,
        storeID: this.props?.match?.params?.storeID,
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "All Resolved") {
      filters = {
        status: "Resolved",
        storeTask: true,
        supportTicket: true,
        storeID: this.props?.match?.params?.storeID,
      };
      orderBy = { dueDate: -1 };
    }

    this.setState({
      filters,
      orderBy,
      active: option,
    });

    console.log(filters);

    this.loadTasks(1, this.state.count, filters, orderBy);
  }

  formatDueDate(dateString, status) {
    if (!dateString) {
      return "--";
    }

    let now = moment();
    let due = moment(dateString);

    if (now.toDate() > due.toDate() && status != "Resolved") {
      return (
        <Badge color="" className="bg-yellow text-dark">
          {due.format("MMM DD, YYYY")}
        </Badge>
      );
    }

    return due.format("MMM DD, YYYY");
  }

  formatStatus(status) {
    if (!status) {
      return "--";
    }

    return status;
  }

  taskRow(c) {
    return (
      <Row className="align-items-center">
        <Col xs="6" sm="6" md="6">
          <h4 className="mb-0">{c.subject}</h4>
          <p className="mb-0" style={{ fontSize: "13px" }}>
            {c.supportType}
          </p>
        </Col>

        <Col xs="6" sm="6" md="3">
          {this.formatStatus(c.status)}
        </Col>
        <Col xs="6" sm="6" md="3">
          {this.formatDueDate(c.dueDate, c.status)}
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <>
        {this.props.loading ? (
          <>
            <Card className="mb-4 shadow border">
              <CardBody className="bg-transparent py-5 text-center">
                <Spinner size="lg" color="medium"></Spinner>
              </CardBody>
            </Card>
          </>
        ) : (
          <>
            <Card className="mb-4 border shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <Col>
                    <h3 className="text-dark mb-0">Support Tickets</h3>
                    <p
                      className="small m-0 text-dark"
                      style={{ lineHeight: 1.2 }}
                    >
                      {this.state.loadingTasks ? (
                        "Loading"
                      ) : (
                        <>
                          {this.state.tasks?.length ? (
                            <>
                              {this.state.result?.start} -{" "}
                              {this.state.result?.end} of{" "}
                              {this.state.result?.total} tickets
                            </>
                          ) : (
                            <>No tickets</>
                          )}
                        </>
                      )}
                    </p>
                  </Col>
                  <Col xs="auto">
                    <Button
                      size="sm"
                      color={
                        this.state.loadingTasks ||
                        !this.state.pagination?.hasPrevious
                          ? "light"
                          : "secondary"
                      }
                      className="btn-icon-only"
                      disabled={
                        this.state.loadingTasks ||
                        !this.state.pagination?.hasPrevious
                      }
                      outline
                      onClick={() => {
                        this.incrementPage(-1);
                      }}
                    >
                      <i className="mdi mdi-chevron-left"></i>
                    </Button>
                    <Button
                      size="sm"
                      color={
                        this.state.loadingTasks ||
                        !this.state.pagination?.hasNext
                          ? "light"
                          : "secondary"
                      }
                      className="btn-icon-only"
                      disabled={
                        this.state.loadingTasks ||
                        !this.state.pagination?.hasNext
                      }
                      outline
                      onClick={() => {
                        this.incrementPage(1);
                      }}
                    >
                      <i className="mdi mdi-chevron-right"></i>
                    </Button>
                    {/**
                     * <Button
                      size="sm"
                      color="primary"
                      onClick={() => {
                        this.setState({ openCreate: true });
                      }}
                    >
                      New Task
                    </Button>
                     */}
                  </Col>
                </Row>
              </CardHeader>
              <CardSelectorHeader
                compact={true}
                value={this.state.active}
                options={[
                  {
                    name: "All Incomplete",
                    id: "All Incomplete",
                  },
                  {
                    name: "All Resolved",
                    id: "All Resolved",
                  },
                ]}
                onChange={this.handleTabChange.bind(this)}
              ></CardSelectorHeader>
              <CardBody className="p-0">
                {this.state?.loadingTasks && (
                  <div className="px-3 py-5 text-center">
                    <Spinner size="sm" color="dark"></Spinner>
                  </div>
                )}
                {!this.state?.tasks?.length && !this.state.loadingTasks && (
                  <div className="px-3 py-5">
                    <Row>
                      <Col xs="12">
                        <h3 className="m-0">No support tickets available.</h3>
                        <p>The store does not have any support tickets.</p>
                      </Col>
                    </Row>
                  </div>
                )}

                {this.state?.tasks?.length > 0 && !this.state?.loadingTasks && (
                  <div className="px-4 py-2 bg-superlight border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12" sm="12" md="6">
                        <h4 className="mb-0">Subject</h4>
                      </Col>
                      <Col xs="6" sm="6" md="3">
                        <h4 className="mb-0">Status</h4>
                      </Col>
                      <Col xs="6" sm="6" md="3">
                        <h4 className="mb-0">Due Date</h4>
                      </Col>
                    </Row>
                  </div>
                )}
                {!this.state?.loadingTasks
                  ? this.state?.tasks?.map((task, i) => (
                      <div key={task?._id}>
                        <div
                          className="px-4 py-3 cursor-pointer bg-superlight--hover"
                          onClick={() => {
                            this.taskDetailDrawer(task);
                          }}
                        >
                          {this.taskRow(task)}
                        </div>
                        {i != this.state?.tasks?.length - 1 && (
                          <hr className="m-0"></hr>
                        )}
                      </div>
                    ))
                  : null}
              </CardBody>
            </Card>
          </>
        )}
        <SupportTicketDetailDrawer
          task={this.state.taskDetail}
          open={this.state.detailOpen}
          onClose={() => {
            this.setState({
              taskDetail: null,
              detailOpen: false,
            });
          }}
        ></SupportTicketDetailDrawer>
        <TaskCreateDrawer
          storeTask={true}
          store={this.props.store}
          open={this.state.openCreate}
          onClose={() => {
            this.setState({ openCreate: false });
          }}
        ></TaskCreateDrawer>
      </>
    );
  }
}

export default withRouter(StoreAllTicketsWidget);
