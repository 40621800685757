import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
  Button,
  Modal,
  Badge,
  Row,
  Col,
} from "reactstrap";
import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";
import API from "lib/API";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import User from "lib/Model/User";
import NumberFormat from "react-number-format";
import APIV2 from "lib/APIV2";
import _ from "underscore";
import { Checkbox } from "rsuite";
import StringUtils from "../lib/StringUtils";
import CustomerOrderDrawer from "./Drawers/CustomerOrderDrawer";
import moment from "moment";

class CustomerOrderRow extends React.Component {
  state = {};

  componentDidMount() {
    console.log(this?.props?.order?._id, this?.props?.order?.createdAt);
  }

  render() {
    return (
      <>
        <div
          className={`px-3 py-2 ${
            this.props.selected
              ? "bg-primary-ultralight"
              : "bg-superlight--hover"
          } cursor-pointer`}
        >
          <Row className="align-items-center">
            {this.props.showCheckbox ? (
              <Col xs="auto">
                <Checkbox
                  checked={this.props.selected}
                  onChange={(v, c) => {
                    this.props.onSelect(c);
                  }}
                ></Checkbox>
              </Col>
            ) : null}
            <Col
              xs=""
              className={this.props.showCheckbox ? "pl-0" : ""}
              onClick={() => {
                PubSub.publish(Event.ORDER.OPEN, this.props.order);
              }}
            >
              <Row className="align-items-center">
                <Col xs="6" sm="6" md="4">
                  <h4 className="mb-0 text-capitalize">
                    {this.props.order?.customer?.name}
                  </h4>
                  <p className="mb-0" style={{ fontSize: "13px" }}>
                    {this.props.order?.customer?.phone
                      ? StringUtils.formatPhoneNumber(
                          this.props.order?.customer?.phone.replace("+1", "")
                        )
                      : "--"}
                  </p>
                </Col>
                <Col xs="6" sm="6" md="3">
                  <p className="text-dark m-0">
                    {this.props.order?.subscription?.plan?.planType?.name
                      ? `${this.props.order?.subscription?.plan?.planType?.name} ${this.props.order?.subscription?.plan?.quantity}`
                      : "--"}
                  </p>
                  <p
                    className="m-0 text-dark text-capitalize"
                    style={{ lineHeight: 1.2 }}
                  >
                    <small>
                      {this.props.order?.subscription?.plan?.frequency?.replace(
                        "biweekly",
                        "Bi-Weekly"
                      )}{" "}
                      {this.props.order?.subscription?.plan?.procurement}
                    </small>
                  </p>
                </Col>
                <Col xs="6" sm="6" md="2">
                  {/*<>{this.props.order?.status == "CLAIMED" ||
                  this.props.order?.status == "PENDING" ||
                  this.props.order?.status == "CANCELLED" ? (
                    <>
                      <Badge color="medium">{this.props.order?.status}</Badge>
                    </>
                  ) : null}</> */}
                  {this.props.order?.invoice?.status != "UNPAID" ? (
                    <>
                      <Badge color="medium" className="ml-2">
                        {this.props.order?.invoice?.status}
                      </Badge>
                    </>
                  ) : (
                    <>
                      <Badge color="" className="ml-2 bg-yellow">
                        {this.props.order?.invoice?.status}
                      </Badge>
                    </>
                  )}
                </Col>
                <Col xs="6" sm="6" md="3">
                  <p className="text-dark m-0">
                    {moment(this.props.order?.createdAt).format(
                      "MM/DD/YYYY hh:mm A"
                    )}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withRouter(CustomerOrderRow);
