import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
  Button,
  Modal,
  Row,
  Col,
  Badge,
} from "reactstrap";
import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";
import API from "lib/API";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import User from "lib/Model/User";
import { isNumber } from "underscore";
import _ from "underscore";
import { Editor } from "react-draft-wysiwyg";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import APIV2 from "lib/APIV2";
import ArrowText from "./ArrowText";
import { withRouter } from "react-router";
import StringUtils from "lib/StringUtils";
import { Popover, Whisper } from "rsuite";

class MealMenuRow extends React.Component {
  state = {
    showSecret: false,
    name: "",
    description: "",
    key: "",
    secret: "",
    modify: false,
  };

  getRating(meal, ratings) {
    if (!meal || !ratings?.length) {
      return "no reviews submitted";
    }

    let out = _.findWhere(ratings, { mealID: meal._id });

    if (!out) {
      return "no reviews submitted";
    }

    return `${out.rating} /5 from ${out.count} reviews`;
  }

  render() {
    return (
      <>
        <tr
          onClick={() => {
            if (typeof this.props.onClick == "function") {
              this.props.onClick(this.props.meal);
            }
          }}
        >
          <td className="cursor-pointer align-middle">
            <div
              className="border p-1 bg-dark"
              style={{ borderRadius: "6px", width: "60px", height: 60 }}
            >
              <img
                src={this.props.meal ? this.props.meal.imageURL : "##"}
                style={{ width: "100%" }}
                alt={this.props?.meal?.name ? this.props.meal.name : "meal"}
              ></img>
            </div>
          </td>
          <td className="cursor-pointer align-middle pl-0">
            <h3 className="mb-0 d-none d-md-block">
              {this.props.meal ? this.props.meal.name : null}
            </h3>
            <h4 className="mb-0 d-block d-md-none">
              {this.props.meal ? this.props.meal.name : null}
            </h4>
            <div
              className="small mb-0 text-dark"
              style={{ whiteSpace: "pre-wrap", fontSize: 12 }}
              dangerouslySetInnerHTML={{
                __html: StringUtils.stripHTML(this.props.meal?.description),
              }}
            ></div>
            <div className="mt-1">
              <p
                className="m-0 text-dark"
                style={{ fontSize: 14, lineHeight: 1.2 }}
              >
                Rating: {this.getRating(this.props.meal, this.props.ratings)}
              </p>
            </div>
          </td>
          <td
            className="cursor-pointer align-middle"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {this.props.meal.categories
              ? this.props.meal.categories.map((c, i) => (
                  <>
                    {c.name}
                    {i != this.props.meal.categories.length - 1 ? (
                      <br></br>
                    ) : (
                      ""
                    )}
                  </>
                ))
              : "--"}
          </td>
          <td
            className="cursor-pointer align-middle"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {this.props.meal.allergens
              ? this.props.meal.allergens.map((c, i) => (
                  <>
                    {c.name}
                    {i != this.props.meal.allergens.length - 1 ? <br></br> : ""}
                  </>
                ))
              : "--"}
          </td>
          <td
            className="cursor-pointer align-middle"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {this.props.meal.primaryProtein
              ? this.props.meal.primaryProtein
              : "--"}
          </td>
          <td
            className="cursor-pointer align-middle"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {this.props.meal.secondaryProtein
              ? this.props.meal.secondaryProtein
              : "--"}
          </td>
          <td className="cursor-pointer align-middle">
            {this.props.meal
              ? this.props.meal.nutrition.calories + " CAL"
              : "--"}
          </td>
          <td className="cursor-pointer align-middle">
            {this.props.meal ? this.props.meal.nutrition.carbs + " G" : "--"}
          </td>
          <td className="cursor-pointer align-middle">
            {this.props.meal ? this.props.meal.nutrition.protein + " G" : "--"}
          </td>
          <td className="align-middle cursor-pointer">
            {this.props.meal ? this.props.meal.nutrition.fat + " G" : "--"}
          </td>

          <td className="align-middle cursor-pointer">
            {this.props.meal?.saturatedFat !== null &&
            this.props.meal?.saturatedFat !== undefined
              ? this.props.meal.saturatedFat + " G"
              : "--"}
          </td>
          <td className="align-middle cursor-pointer">
            {this.props.meal?.transFat !== null &&
            this.props.meal?.transFat !== undefined
              ? this.props.meal.transFat + " G"
              : "--"}
          </td>
          <td className="align-middle cursor-pointer">
            {this.props.meal?.nutrition?.sugar !== null &&
            this.props.meal?.nutrition?.sugar !== undefined
              ? this.props.meal.nutrition?.sugar + " G"
              : "--"}
          </td>
          <td className="align-middle cursor-pointer">
            {this.props.meal?.nutrition?.fiber &&
            this.props.meal?.nutrition?.fiber !== undefined
              ? this.props.meal.nutrition?.fiber + " G"
              : "--"}
          </td>
          <td className="align-middle cursor-pointer">
            {this.props.meal?.nutrition?.sodium !== null &&
            this.props.meal?.nutrition?.sodium !== undefined
              ? this.props.meal.nutrition?.sodium + " mg"
              : "--"}
          </td>
          <td className="align-middle cursor-pointer">
            {this.props.meal?.cholesterol !== null &&
            this.props.meal?.cholesterol !== undefined
              ? this.props.meal.cholesterol + " mg"
              : "--"}
          </td>
          <td className="align-middle cursor-pointer">
            {this.props.meal?.vitaminD !== null &&
            this.props.meal?.vitaminD !== undefined
              ? this.props.meal.vitaminD + " IU"
              : "--"}
          </td>
          <td className="align-middle cursor-pointer">
            {this.props.meal?.potassium !== null &&
            this.props.meal?.potassium !== undefined
              ? this.props.meal.potassium + " mg"
              : "--"}
          </td>
          <td className="align-middle cursor-pointer">
            {this.props.meal?.calcium !== null &&
            this.props.meal?.calcium !== undefined
              ? this.props.meal.calcium + " mg"
              : "--"}
          </td>
          <td className="align-middle cursor-pointer">
            {this.props.meal?.iron !== null &&
            this.props.meal?.iron !== undefined
              ? this.props.meal.iron + " mg"
              : "--"}
          </td>
          <td className="align-middle cursor-pointer">
            {this.props.meal?.foodWeight !== null &&
            this.props.meal?.foodWeight !== undefined
              ? this.props.meal.foodWeight + " oz"
              : "--"}
          </td>

          <td className="align-middle cursor-pointer text-left">
            {this.props.meal?.ingredients ? (
              <div
                style={{
                  fontSize: 12,
                  width: 600,

                  whiteSpace: "pre-wrap",
                }}
              >
                <p className="text-dark m-0" style={{ fontSize: 12 }}>
                  {this.props.meal.ingredients}
                </p>
              </div>
            ) : (
              "--"
            )}
          </td>
        </tr>
      </>
    );
  }
}

export default withRouter(MealMenuRow);
