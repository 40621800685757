import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
  Button,
  Modal,
  Row,
  Col,
  Badge,
} from "reactstrap";
import _ from "underscore";

import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import Compressor from "@uppy/compressor";
import XHR from "@uppy/xhr-upload";
import GoldenRetriever from "@uppy/golden-retriever";
import RemoteSources from "@uppy/remote-sources";
import GoogleDrive from "@uppy/google-drive";
import Instagram from "@uppy/instagram";
import Url from "@uppy/url";
import Facebook from "@uppy/facebook";

import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import APIV2 from "../lib/APIV2";
import { Capacitor } from "@capacitor/core";

class FileUploader extends React.Component {
  state = {
    uppy: null,
    files: [],
    done: false,
  };

  dashboard = React.createRef();

  done() {
    if (typeof this.props.onUpload == "function") {
      this.props.onUpload(this.state.files);
    }

    this.setState(
      {
        files: [],
        done: true,
      },
      () => {
        setTimeout(() => {
          this.setState(
            {
              done: false,
              files: [],
            },
            () => {
              this._loadUppy();
            }
          );
        }, 0);
      }
    );
  }

  componentDidUpdate() {
    if (this.dashboard?.current && !this.state.uppy) {
      this._loadUppy();
    }
  }

  _loadUppy() {
    if (!this.dashboard?.current) {
      return;
    }

    const self = this;

    let headers = {
      Authorization: "Bearer " + APIV2.ENV_USER_TOKEN,
    };

    let opts = {
      restrictions: {},
    };

    if (this.props.singleFile) {
      opts.restrictions.maxNumberOfFiles = 1;
    }

    if (this.props.allowedFileTypes) {
      opts.restrictions.allowedFileTypes = this.props.allowedFileTypes;
    }

    const uppyDashboard = new Uppy(opts)
      .use(Dashboard, {
        target: this.dashboard?.current,
        width: "100%",
        height: this.props.height
          ? this.props.height
          : this.props.size == "sm"
          ? 250
          : 450,
        inline: true,
        doneButtonHandler: () => {
          const dashboard = self.state.uppy?.getPlugin("Dashboard");

          if (dashboard.isModalOpen()) {
            dashboard.closeModal();
          }

          self.done();
        },
      })

      .use(Url, {
        target: Dashboard,
        companionUrl: APIV2.ENV_UPLOADER_CURRENT,
      })
      .use(XHR, {
        endpoint:
          this.props.cdn == "public"
            ? APIV2.ENV_CURRENT + "/global/cdn/public"
            : APIV2.ENV_CURRENT + "/global/cdn/private",
        method: "post",
        formData: true,
        headers: {
          Authorization: `Bearer ${APIV2.ENV_USER_TOKEN}`,
        },
      });

    if (!Capacitor.isNativePlatform()) {
      uppyDashboard.use(GoogleDrive, {
        target: Dashboard,
        companionUrl: APIV2.ENV_UPLOADER_CURRENT,
        companionAllowedHosts: APIV2.ENV_UPLOADER_CURRENT + "/*",
      });
    }

    if (this.props.compressUploads) {
      uppyDashboard.use(Compressor);
    }

    uppyDashboard.on("complete", (result) => {
      let files = result?.successful?.map((fileUpload) => {
        let out = fileUpload?.response?.body?.data;

        out.fileName = fileUpload?.data?.name;

        return out;
      });

      files = _.filter(files, (f) => {
        return f;
      });

      if (!files?.length) {
        return;
      }

      if (this.props.singleFile) {
        this.setState({
          files: [files[0]],
        });

        if (typeof this.props.onChange == "function") {
          this.props.onChange([files[0]]);
        }

        return;
      }

      let original = this.state.files?.length ? this.state.files : [];

      original = original.concat(files);

      if (typeof this.props.onChange == "function") {
        this.props.onChange(original);
      }

      this.setState({
        files: original,
      });
    });

    this.setState({
      uppy: uppyDashboard,
    });
  }

  componentDidMount() {
    this._loadUppy();
  }

  render() {
    return (
      <>
        {!this.state.done ? (
          <div
            className={this.props.embedded ? "uploader-embedded" : ""}
            ref={this.dashboard}
          ></div>
        ) : null}
      </>
    );
  }
}

export default FileUploader;
