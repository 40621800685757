import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import CustomerInvoiceDrawer from "components/Drawers/CustomerInvoiceDrawer";

class StorefrontInvoiceListView extends React.Component {
  state = {
    active: {
      id: "All",
      name: "All",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingInvoices: true,
    invoices: [],
    page: 1,
    count: 25,
    filters: null,
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab) {
      this.handleTabChange(
        {
          id: decodeURIComponent(tab),
          name: decodeURIComponent(tab),
        },
        true
      );
    } else {
      this.handleTabChange(this.state.active, true);
    }

    //this.loadStores();

    PubSub.subscribe(Event.INVOICE.CREATED, (m) => {
      let g = this.state.invoices;

      g.push(m);

      this.setState({
        invoices: g,
      });
    });

    PubSub.subscribe(Event.INVOICE.MODIFIED, (a) => {
      let g = this.state.invoices;
      let idx = findIndex(g, { _id: a._id });

      g[idx] = a;

      this.setState({
        invoices: g,
      });

      if (this.state.openInvoice?._id == a?._id) {
        this.setState({
          openInvoice: a,
        });
      }
    });
  }

  loadInvoices(page = 1, count = 25, filters = null) {
    this.setState({
      loadingInvoices: true,
    });

    APIV2.getInvoicesForStore(
      this.props?.match?.params?.storeID,
      page,
      count,
      filters,
      {
        dueOn: -1,
        status: -1,
      }
    )
      .then(
        (data) => {
          if (data && data.data) {
            let invoices = [];

            if (data.data.invoices.length) {
              invoices = data.data.invoices;
            }

            this.setState({
              invoices,
              pagination: data.data.pagination,
              result: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the invoices. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingInvoices: false,
        });
      });
  }

  performSearch() {
    console.log(this.state.search);

    APIV2.simpleSearchInvoicesForStore(
      this.props?.match?.params?.storeID,
      this.state.search
    )
      .then(
        (data) => {
          this.setState({
            searchResultInvoices: data.data.invoices,
            searchResults: true,
          });
        },
        (e) => {
          console.error(e);

          this.setState({ searchResults: true, searchResultInvoices: [] });
        }
      )
      .finally(() => {
        this.setState({
          searchLoading: false,
        });
      });
  }

  clearSearch() {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);

      this.setState({
        searchTimeout: null,
      });
    }

    this.setState({
      search: "",
    });
  }

  handleSearchChange(e) {
    const { name, value } = e.target;

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    if (value) {
      this.setState({
        searchLoading: true,
      });
    } else {
      this.setState({
        searchLoading: false,
        searchResults: false,
      });
    }

    if (value && value.length >= 3) {
      let timeout = setTimeout(() => {
        this.performSearch();
      }, 1200);

      this.setState({
        searchTimeout: timeout,
      });
    }

    this.setState({
      [name]: value,
    });
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadInvoices(page, this.state.count, this.state.filters);
  }

  invoiceRow(c) {
    return (
      <Row className="align-items-center">
        <Col xs="8" sm="6" md="4">
          <h4 className="mb-0">{c.name}</h4>
          <p className="mb-0" style={{ fontSize: "13px" }}>
            {c.customer?.name ? c.customer?.name : "--"}
          </p>
        </Col>
        <Col xs="4" sm="6" md="2" className="text-right text-sm-left">
          {c.status == "PAID" ? (
            <>
              <Badge color="success">Paid</Badge>
            </>
          ) : null}
          {c.status == "UNPAID" ? (
            <>
              <Badge color="" className="bg-yellow text-dark">
                UNPAID
              </Badge>
            </>
          ) : null}
          {c.status != "PAID" && c.status != "UNPAID" && (
            <>
              <Badge color="medium">{c.status}</Badge>
            </>
          )}
        </Col>
        <Col xs="6" sm="6" md="2">
          <div style={{ fontSize: "15px" }}>
            {(c.dueOnString && c.status == "PENDING") ||
            c.status == "UNPAID" ||
            c.status == "PAID" ? (
              <>
                <span className="d-inline-block d-md-none">Due:&nbsp;</span>
                {moment(c.dueOn ?? c.dueOnString).format("MM/DD/YYYY")}
              </>
            ) : (
              <>--</>
            )}
          </div>
        </Col>
        <Col xs="6" sm="6" md="3" className="d-none d-sm-flex">
          <div style={{ fontSize: "15px" }}>
            {c.createdAt ? (
              <>{moment(c.createdAt).format("MM/DD/YYYY hh:mm A")}</>
            ) : (
              <>--</>
            )}
          </div>
        </Col>
      </Row>
    );
  }

  handleTabChange(option, force = false) {
    let filters = null;

    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    if (option.id == "All") {
      filters = null;
    } else if (option.id == "This Week") {
      let start = moment();
      let end = moment();

      end.day(6);
      start.day(0);

      start.minutes(0).hours(0).seconds(0).milliseconds(0);

      end.minutes(59).hours(23).seconds(59).milliseconds(59);

      filters = {
        dueOn: {
          $gte: {
            $date: start.toDate().toISOString(),
          },
          $lte: { $date: end.toDate().toISOString() },
        },
      };

      console.log(filters);
    } else if (option.id == "Last Week") {
      let start = moment();
      let end = moment();

      start
        .set(0, "minutes")
        .set(0, "hours")
        .set(0, "seconds")
        .set(0, "milliseconds")
        .day(0)
        .subtract("1", "week");
      end
        .set(59, "minutes")
        .set(23, "hours")
        .set(59, "seconds")
        .set(59, "milliseconds")
        .day(6)
        .subtract("1", "week");

      filters = {
        dueOn: {
          $gte: {
            $date: start.toDate().toISOString(),
          },
          $lt: {
            $date: end.toDate().toISOString(),
          },
        },
      };
    } else if (option.id == "Unpaid") {
      filters = {
        status: { $in: ["UNPAID", "PROCESSING"] },
      };
    }

    this.setState({
      filters,
      active: option,
    });

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.loadInvoices(1, this.state.count, filters);
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader
            tabs={
              <CardSelectorHeader
                compact={true}
                value={this.state.active}
                options={[
                  {
                    name: "All",
                    id: "All",
                  },
                  {
                    name: "This Week",
                    id: "This Week",
                  },
                  {
                    name: "Last Week",
                    id: "Last Week",
                  },
                  {
                    name: "Unpaid",
                    id: "Unpaid",
                  },
                ]}
                onChange={this.handleTabChange.bind(this)}
              ></CardSelectorHeader>
            }
            title="Invoices"
            showBackButton={false}
          />

          <Row>
            <div className="col">
              <Card
                className="border-0 mt--3"
                style={{
                  minHeight: "500px",
                  marginLeft: -15,
                  marginRight: -15,
                }}
              >
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    <Col xs="12" sm="">
                      <Input
                        type="text"
                        placeholder="Search invoices..."
                        size="sm"
                        name="search"
                        value={this.state.search}
                        onChange={this.handleSearchChange.bind(this)}
                      ></Input>
                    </Col>
                  </Row>
                  <Row className="align-items-center mt-3">
                    {/*<Col xs="auto" sm="auto" className="pr-0">
                      <Checkbox
                        indeterminate={true}
                        checked={true}
                        onChange={() => {}}
                      ></Checkbox>
                </Col>*/}
                    <Col xs="" sm="" className="">
                      {this.state.searchResults ? (
                        <>
                          Found{" "}
                          {this.state.searchResultInvoices?.length
                            ? StringUtils.numberFormat(
                                this.state.searchResultInvoices?.length
                              )
                            : "0"}{" "}
                          invoice
                          {this.state.searchResultInvoices?.length == 1
                            ? ""
                            : "s"}{" "}
                          matching your search.
                        </>
                      ) : (
                        <div>
                          {this.state.result?.start &&
                          this.state.result?.end ? (
                            <>
                              Showing{" "}
                              {StringUtils.numberFormat(
                                this.state.result?.start
                              )}
                              -
                              {StringUtils.numberFormat(this.state.result?.end)}
                              {this.state.result?.total ? (
                                <>
                                  &nbsp;of{" "}
                                  {StringUtils.numberFormat(
                                    this.state.result?.total
                                  )}{" "}
                                  invoices
                                </>
                              ) : null}
                            </>
                          ) : (
                            <>---</>
                          )}
                        </div>
                      )}
                    </Col>
                    <Col
                      xs="auto"
                      sm="auto"
                      className="pl-2 pl-sm-3 text-right"
                    >
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        className="btn-icon-only"
                        outline
                        disabled={
                          this.state.page == 1 ||
                          !this.state.pagination?.hasPrevious ||
                          this.state.searchResults
                        }
                        onClick={() => {
                          this.incrementPage(-1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-left"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        disabled={
                          !this.state.pagination?.hasNext ||
                          this.state.searchResults
                        }
                        className="btn-icon-only"
                        outline
                        onClick={() => {
                          this.incrementPage(1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-right"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="py-2 px-3 bg-superlight border-bottom border-top text-dark">
                    <Row className="align-items-center">
                      <Col xs="6" sm="6" md="4">
                        <h4 className="mb-0">Invoice</h4>
                      </Col>

                      <Col
                        xs="6"
                        sm="6"
                        md="2"
                        className="text-right text-sm-left"
                      >
                        <h4 className="mb-0">Status</h4>
                      </Col>
                      <Col xs="6" sm="6" md="2">
                        <h4 className="mb-0">Due On</h4>
                      </Col>
                      <Col xs="6" sm="6" md="3" className="d-none d-sm-flex">
                        <h4 className="mb-0">Created At</h4>
                      </Col>
                    </Row>
                  </div>

                  <div>
                    {this.state.loadingInvoices || this.state.searchLoading ? (
                      <>
                        <div
                          className="text-center p-3"
                          style={{
                            zIndex: 1,
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            background: "rgba(255, 255, 255, 0.6)",
                          }}
                        >
                          <Spinner
                            style={{ position: "relative", top: "15vh" }}
                            color="medium"
                            size=""
                          ></Spinner>
                        </div>
                      </>
                    ) : null}
                    {this.state.searchResults ? (
                      <div>
                        {!this.state.searchResultInvoices?.length &&
                        !this.state.searchLoading ? (
                          <div className="px-3 py-2">
                            <Row>
                              <Col>
                                No invoices found matching your search "
                                {this.state.search}"
                              </Col>
                            </Row>
                          </div>
                        ) : null}

                        {this.state.searchResultInvoices?.map((c, i) => (
                          <div
                            className={`px-3 py-2 bg-superlight--hover cursor-pointer ${
                              i != this.state.searchResultInvoices.length - 1
                                ? "border-bottom"
                                : ""
                            }`}
                            key={i}
                            onClick={() => {
                              this.setState({
                                drawer: true,
                                openInvoice: c,
                              });
                            }}
                          >
                            {this.invoiceRow(c)}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>
                        {!this.state.invoices?.length &&
                        !this.state.loadingInvoices ? (
                          <div className="px-3 py-2">
                            <Row>
                              <Col>
                                Get started by creating your first invoice.
                              </Col>
                            </Row>
                          </div>
                        ) : null}

                        {this.state.invoices?.map((c, i) => (
                          <div
                            className={`px-3 py-2 bg-superlight--hover cursor-pointer ${
                              i != this.state.invoices.length - 1
                                ? "border-bottom"
                                : ""
                            }`}
                            key={i}
                            onClick={() => {
                              this.setState({
                                drawer: true,
                                openInvoice: c,
                              });
                            }}
                          >
                            {this.invoiceRow(c)}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        <CustomerInvoiceDrawer
          invoice={this.state.openInvoice}
          onClose={() => {
            this.setState({ drawer: false });
          }}
          open={this.state.drawer}
        ></CustomerInvoiceDrawer>
      </>
    );
  }
}

export default StorefrontInvoiceListView;
