import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import { isThisTypeNode } from "typescript";
import EditorV2 from "components/NovelEditor/ui/editor";

class LearningCertificationDrawer extends React.Component {
  state = {
    name: "",
    description: "",
    type: "",
    criteria: {},
    score: null,
  };

  constructor() {
    super();

    this.dropdownRef = React.createRef();
  }

  updateForm() {
    this.setState(
      {
        name: this.props.certification?.name
          ? this.props.certification?.name
          : "",
        description: this.props.certification?.description
          ? this.props.certification?.description
          : "",
        type: this.props.certification?.type
          ? this.props.certification?.type
          : "",
        score: this.props.certification?.score
          ? this.props.certification?.score
          : null,
        criteria: this.props.certification?.criteria
          ? this.props.certification?.criteria
          : {},
      },
      () => {
        setTimeout(() => {
          //console.log("HYDRATE", message);

          PubSub.publish("editor:contentUpdated", {
            event: "contentUpdated",
            forceHydrate: true,
            id: "learningCertificationCriteriaEditor",
            data: this.props.certification?.criteria
              ? this.props.certification?.criteria
              : {},
          });
        }, 0);
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props?.certification != prevProps?.certification) {
      this.updateForm();
    }
  }

  componentDidMount() {
    if (this.props.certification) {
      this.updateForm();
    }
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  toggleModal() {
    this.setState({
      name: "",
      nextRoleID: "",
      criteria: {},
    });

    PubSub.publish("editor:contentUpdated", {
      event: "contentUpdated",
      forceHydrate: true,
      id: "learningCertificationCriteriaEditor",
      data: {},
    });

    this.props.onClose();
  }

  create() {
    this.setState({ submitting: true });

    APIV2.createLearningCertification(
      this.state.name,
      this.state.description,
      this.state.type,
      this.state.criteria,
      this.state.type == "automated" && !isNaN(parseInt(this.state.score))
        ? parseInt(this.state.score)
        : null
    )
      .then(
        (data) => {
          const staff = data.data.certification;

          PubSub.publish(Event.STAFF.CREATED, staff);

          this.setState({
            name: "",
            nextRoleID: "",
          });

          this.toggleModal();
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create certification - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  modify() {
    this.setState({ submitting: true });

    APIV2.modifyLearningCertification(
      this.props?.certification?._id,
      this.state.name,
      this.state.description,
      this.state.type,
      this.state.criteria,
      this.state.type == "automated" && !isNaN(parseInt(this.state.score))
        ? parseInt(this.state.score)
        : null
    )
      .then(
        (data) => {
          const staff = data.data.certification;

          PubSub.publish(Event.STAFF.MODIFIED, staff);

          this.setState({
            name: "",
            nextRoleID: "",
          });

          this.toggleModal();
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to modify certification - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.certification ? "Modify" : "Create"}
              &nbsp;Certification
            </h3>
            <Drawer.Actions>
              <Button
                onClick={() => {
                  if (this.props.certification) {
                    this.modify();
                  } else {
                    this.create();
                  }
                }}
                size="sm"
                color="primary"
                disabled={
                  !this.state.name ||
                  !this.state.type ||
                  (this.state.type == "automated" && !this.state.score) ||
                  this.state.submitting
                }
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : this.props.certification ? (
                  "Save"
                ) : (
                  "Create"
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error && (
              <>
                <Alert
                  color=""
                  className="m-0 mb-4 bg-primary-superlight text-dark font-weight-500"
                >
                  {this.state.error}
                </Alert>
              </>
            )}
            <FormGroup>
              <h5>
                Name<span className="text-danger">*</span>
              </h5>
              <Input
                type="text"
                name="name"
                placeholder="Name"
                required
                value={this.state.name}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Description</h5>
              <Input
                type="textarea"
                numRows={3}
                name="description"
                placeholder="Description"
                required
                value={this.state.description}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5 className="m-0">
                Certification Type<span className="text-danger">*</span>
              </h5>
              <p className="small text-dark mb-2">
                Select if this is a manual or automated certification.
              </p>
              <Input
                type="select"
                name="type"
                required
                value={this.state.type}
                onChange={this.handleInputChange.bind(this)}
                placeholder="Select Certification Type"
              >
                <option value="" disabled>
                  Select An Option
                </option>
                <option value="manual">Manual</option>
                <option value="automated">Automated</option>
              </Input>
            </FormGroup>
            {this.state.type == "automated" ? (
              <FormGroup>
                <h5 className="m-0">
                  Automated Certification Score
                  <span className="text-danger">*</span>
                </h5>
                <p className="small text-dark mb-2">
                  Enter the minimum score required to receive this certification
                  on a 0-100 scale. This represents the percentage of learning
                  track questions that must be met to pass.
                </p>
                <InputGroup>
                  <NumberFormat
                    value={this.state.score}
                    onValueChange={(values) => {
                      let { value } = values;

                      if (parseFloat(value) > 100) {
                        value = 100;
                      }

                      this.setState({
                        score: value,
                      });
                    }}
                    thousandSeparator={true}
                    allowNegative={false}
                    decimalScale={0}
                    fixedDecimalScale={true}
                    placeholder="Score"
                    className="form-control"
                    type="text"
                  ></NumberFormat>
                  <div
                    className="input-group-text text-dark"
                    style={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                  >
                    %
                  </div>
                </InputGroup>
              </FormGroup>
            ) : null}
            <FormGroup>
              <h5 className="mb-0">Certification Criteria</h5>
              <p className="small text-dark mb-2">
                Define the process or requirements for this certification.
              </p>
              <div
                className="rounded p-3 border mb-3"
                style={{ minHeight: 300 }}
              >
                <EditorV2
                  editable={true}
                  allowForceHydration={
                    this.state.criteria && this.state.templateLoad
                  }
                  id={"learningCertificationCriteriaEditor"}
                  padding="p-0"
                  autoSave={false}
                  debounceRate={750}
                  cdnMode="private"
                  value={this.state?.criteria ? this.state?.criteria : {}}
                  onChange={(value) => {
                    console.log("CHANGE", value);

                    if (value?.clear) {
                      this.setState({
                        templateLoad: false,
                      });

                      return;
                    }

                    //console.log("CHANGE", value);

                    this.setState({
                      criteria: value,
                    });
                  }}
                  allowTaskLists={false}
                ></EditorV2>
              </div>
            </FormGroup>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(LearningCertificationDrawer);
