import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  BarChart,
  BarList,
  Card,
  DonutChart,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import {
  Axis,
  Chart,
  Coordinate,
  Interaction,
  Interval,
  Legend,
  Tooltip,
} from "bizcharts";
import ChartMetricHeader from "./ChartMetricHeader";

class MemberTypePieChartCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    tab: "overall",
  };

  /**
   * Fetches the order data by week for the given stores & date range
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchData(cubejsApi, stores, dateRange) {
    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["Metrics.count"],
          order: {
            "Metrics.createdat": "desc",
          },

          timeDimensions: [
            {
              dimension: "Metrics.createdat",
              dateRange: [dateRange[0], dateRange[1]],
            },
          ],
          filters: [
            {
              member: "Metrics.metadatastoreid",
              operator: "equals",
              values: stores,
            },
            {
              member: "Metrics.name",
              operator: "equals",
              values: ["member_new", "member_reactivate"],
            },
          ],
          dimensions: ["Stores.name", "Metrics.name"],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          if (!data?.length) {
            return resolve(null);
          }

          let total = 0;
          let totalFirstTime = 0;

          let out = [];

          let outOverall = [];

          for (let i = 0; i < data?.length; i++) {
            const item = data[i];
            const storeName = item["Stores.name"]
              ?.replace("Project LeanNation", "PLN")
              ?.trim();

            total += item["Metrics.count"];

            const idx = _.findIndex(out, { storeName });

            let metricName = item["Metrics.name"];

            if (metricName == "member_new") {
              metricName = "First-Time Members";

              totalFirstTime += item["Metrics.count"];
            } else if (metricName == "member_reactivate") {
              metricName = "Reactivated Members";
            }

            if (idx >= 0) {
              out[idx][metricName] = item["Metrics.count"];
            } else {
              out.push({
                storeName,
                [metricName]: item["Metrics.count"],
              });
            }

            const idx2 = _.findIndex(outOverall, { metricName });

            if (idx2 >= 0) {
              outOverall[idx2].Members += item["Metrics.count"];
            } else {
              outOverall.push({
                Members: item["Metrics.count"],
                metricName,
              });
            }
          }

          out = out.map((item) => {
            const total =
              (item["First-Time Members"] ? item["First-Time Members"] : 0) +
              (item["Reactivated Members"] ? item["Reactivated Members"] : 0);

            return {
              ...item,
              total,
              percent: total
                ? ((item["First-Time Members"]
                    ? item["First-Time Members"]
                    : 0) /
                    total) *
                  100
                : 0,
            };
          });

          outOverall = _.sortBy(outOverall, "metricName");

          out = _.sortBy(out, "total");

          out.reverse();

          return resolve({
            byStore: out,
            total: ((totalFirstTime / total) * 100).toFixed(1),
            overall: outOverall,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async loadDetailedReport(stores, dateRange) {
    this.setState({
      loading: true,
      dataAvailable: false,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!stores?.length || !dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchData(cubejsApi, stores, dateRange);
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load total new members by store.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });

      return;
    }

    let colors = StringUtils.randomChartColorSet(currentCount.byStore?.length);

    console.log(currentCount?.byStore);

    this.setState({
      currentCount: currentCount,
      loading: false,
      byStore: currentCount?.byStore,
      overall: currentCount?.overall,
      byStoreColors: colors,
      total: currentCount?.total,
    });

    if (typeof this.props.onLoaded == "function") {
      this.props.onLoaded();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stores != prevProps?.stores ||
      this.props.dateRange != prevProps?.dateRange
    ) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload != prevProps.reload && this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  componentDidMount() {
    if (this.props.store && this.props.dateRange) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  render() {
    return (
      <>
        <Card marginTop="mt-0">
          <ChartMetricHeader
            title="New Members By Type"
            actions={
              <>
                <Button
                  size="sm"
                  outline
                  color="dark"
                  className="border-0 btn-icon-only"
                  disabled={this.state.loading}
                  onClick={() => {
                    this.loadDetailedReport(
                      this.props.stores,
                      this.props.dateRange
                    );
                  }}
                >
                  {this.state.loading ? (
                    <Spinner size="sm"></Spinner>
                  ) : (
                    <i className="mdi mdi-refresh"></i>
                  )}
                </Button>
              </>
            }
            loading={this.state.loading}
            dataAvailable={this.state.dataAvailable}
            metric={`${StringUtils.numberFormat(
              this.state.currentCount?.total
            )}% First-Time`}
            comparisonMetric={null}
            dateRange={this.props.dateRange}
            deltaType={null}
            percentChange={null}
            showPercentChange={false}
          ></ChartMetricHeader>
          <div className="mt-3 border-bottom"></div>
          {this.state.loading ? (
            <div
              className="skeleton mt-3"
              style={{ height: "calc(327px + 1rem)", width: "100%" }}
            >
              &nbsp;
            </div>
          ) : (
            <>
              {this.state.dataAvailable ? (
                <>
                  <div className="mt-3 mx--4 px-4">
                    <Row className="align-items-center">
                      <Col xs="12" sm="12" md="6">
                        <div className="pt-4">
                          <DonutChart
                            data={this.state.overall}
                            category={"Members"}
                            dataKey="metricName"
                            colors={["purple", "teal"]}
                            valueFormatter={(number) => {
                              return (
                                StringUtils.numberFormat(number) + " Members"
                              );
                            }}
                            height="h-80"
                          />
                        </div>
                      </Col>
                      <Col md="6" className="mt-3 mt-md-0">
                        <div
                          className="pt-4 mb--4 pb-4"
                          style={{
                            maxHeight: 368,
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          {this.state.byStore?.map((store, i) => (
                            <div key={i} className={`${i != 0 && "mt-3"}`}>
                              <p
                                className="m-0 text-dark font-weight-500"
                                style={{ fontSize: 16, lineHeight: 1.3 }}
                              >
                                {store?.storeName}
                              </p>
                              <Flex marginTop="mt-0">
                                <Text>
                                  {StringUtils.numberFormat(
                                    store["First-Time Members"]
                                      ? store["First-Time Members"]
                                      : 0
                                  )}
                                  &nbsp;First-Time &bull;{" "}
                                  {store.percent.toFixed(1)}%
                                </Text>
                                <Text>
                                  {StringUtils.numberFormat(
                                    store["Reactivated Members"]
                                      ? store["Reactivated Members"]
                                      : 0
                                  )}
                                  &nbsp;Reactivated
                                </Text>
                              </Flex>
                              <ProgressBar
                                marginTop="mt-1"
                                percentageValue={store.percent}
                                color={"purple"}
                              />
                            </div>
                          ))}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "calc(327px + 1rem)", width: "100%" }}
                >
                  <p className="m-0">
                    No data available.
                    {this.state.error ? ` ${this.state.error}` : null}
                  </p>
                </div>
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default MemberTypePieChartCard;
