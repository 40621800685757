/**
 * Event constants for the app.
 */

class Event {
  static ENV_CHANGED = "apiEnvChanged";
  static USER_LOADED = "userLoaded";

  static LOOSE_SEAL_SCANNER = {
    OPEN: "openLooseSealScanner",
    CLOSE: "closeLooseSealScanner",
    COMPLETE: "looseSealScanComplete",
    LOADED: "looseSealScannerLoaded",
  };

  static BILLING_ACCOUNT = {
    DELETED: "billingAccountDeleted",
    CREATED: "billingAccountCreated",
    MODIFIED: "billingAccountModified",
    CREATE_OPEN: "openCreateBillingAccountModal",
  };

  static MENU = {
    DELETED: "menuDeleted",
    CREATED: "menuCreated",
    MODIFIED: "menuModified",
    CREATE_OPEN: "openCreateMenuModal",
  };

  static MEAL = {
    DELETED: "mealDeleted",
    CREATED: "mealCreated",
    MODIFIED: "mealModified",
    CREATE_OPEN: "openMealCreateModal",
    OPEN_INFO: "openMealInfo",
  };

  static NSO_TEMPLATE = {
    MODIFIED: "nsoTemplateModified",
  };

  static APPOINTMENT = {
    CREATED: "appointmentCreated",
    MODIFIED: "appointmentModified",
    DELETED: "appointmentDeleted",
  };

  static CONTENT = {
    CREATED: "contentCreated",
    MODIFIED: "contentModified",
    DELETED: "contentDeleted",
  };

  static CONVERSATION_TEMPLATE = {
    CREATED: "conversationTemplateCreated",
    MODIFIED: "conversationTemplateModified",
    DELETED: "conversationTemplateDeleted",
  };

  static IN_APP_CONTENT = {
    CREATED: "inAppContentCreated",
    MODIFIED: "inAppContentModified",
    DELETED: "inAppContentDeleted",
  };

  static NOTE_TEMPLATE = {
    CREATED: "noteTemplateCreated",
    MODIFIED: "noteTemplateModified",
    DELETED: "noteTemplateDeleted",
  };

  static CONVERSATION_BROADCAST = {
    CREATED: "conversationBroadcastCreated",
    MODIFIED: "conversationBroadcastModified",
    DELETED: "conversationBroadcastDeleted",
  };

  static COHORT = {
    CREATED: "cohortCreated",
    MODIFIED: "cohortModified",
    DELETED: "cohortDeleted",
  };

  static SUPPRESSED_CONTACT = {
    CREATED: "suppressedContactCreated",
    MODIFIED: "suppressedContactModified",
    DELETED: "suppressedContactDeleted",
  };

  static STORE = {
    DELETED: "storeDeleted",
    CREATED: "storeCreated",
    MODIFIED: "storeModified",
    CREATE_OPEN: "openCreateStoreModal",
    MODIFY_OPEN: "openModifyStoreModal",
    DELETE_OPEN: "openDeleteStoreModal",
    MODIFY_BILLING_ACCOUNT_OPEN: "openModifyStoreBillingAccountModal",
    MODIFY_DEFAULT_COUPON_OPEN: "openModifyStoreDefCouponModal",
    MODIFY_MENU_OPEN: "openModifyStoreMenuModal",
    MODIFY_BLACKOUT_OPEN: "openModifyBlackoutDatesModal",
    MODIFY_TAX_OPEN: "openModifyTaxModal",
  };

  static CONVERSATION = {
    MODIFIED: "conversationModified",
    MESSAGE_MODIFIED: "conversationMessageModified",
    VIEW_DETAILS: "conversationViewDetails",
    REASSIGNED: "conversationReassigned",
  };

  static ORDER = {
    MODIFIED: "storeOrderModified",
    OPEN: "storeOrderOpen",
    CREATED: "orderCreated",
  };

  static NOTE = {
    MODIFIED: "storeNoteModified",
    CREATED: "storeNoteCreated",
  };

  static TASK = {
    MODIFIED: "storeTaskModified",
    CREATED: "storeTaskCreated",
  };

  static INVOICE = {
    MODIFIED: "storeInvoiceModified",
    CREATED: "storeInvoiceCreated",
  };

  static PRODUCT_ORDER = {
    MODIFIED: "storeProductOrderModified",
    CREATED: "storeProductOrderCreated",
    DELETED: "storeProductOrderDeleted",
  };

  static QUALITY_CLAIM = {
    MODIFIED: "storeQualityClaimModified",
    CREATED: "storeQualityClaimCreated",
    DELETED: "storeQualityClaimDeleted",
  };

  static STAFF = {
    MODIFIED: "storeStaffModified",
    CREATED: "storeStaffCreated",
  };

  static COURSE = {
    MODIFIED: "learningCourseModified",
    CREATED: "learningCourseCreated",
  };

  static COUPON = {
    MODIFIED: "storeCouponModified",
    CREATED: "storeCouponCreated",
  };

  static USER = {
    MODIFIED: "storeUserModified",
    CREATED: "storeUserCreated",
    DELETED: "storeUserDeleted",
  };

  static FILTERS = {
    CUSTOMERS_OPEN: "openCustomerListViewFilter",
  };

  static PLAN = {
    CREATE_OPEN: "openCreatePlanModal",
    MODIFIED: "planModified",
  };

  static PRODUCT_GROUP = {
    DELETED: "productGroupDeleted",
    CREATED: "productGroupCreated",
    MODIFIED: "productGroupModified",
    CREATE_OPEN: "openProductGroupModal",
  };

  static CUSTOMER = {
    DELETED: "customerDeleted",
    CREATED: "customerCreated",
    MODIFIED: "customerModified",
  };

  static LEAD = {
    DELETED: "leadDeleted",
    CREATED: "leadCreated",
    MODIFIED: "leadModified",
  };

  static AFFILIATE = {
    DELETED: "affiliateDeleted",
    CREATED: "affiliateCreated",
    MODIFIED: "affiliateModified",
  };
}

export default Event;
