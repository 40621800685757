import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  BarChart,
  BarList,
  Card,
  DonutChart,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Drawer, Popover, Whisper } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import {
  Axis,
  Chart,
  Coordinate,
  Interaction,
  Interval,
  Legend,
  Tooltip,
} from "bizcharts";
import { Tooltip as RTooltip } from "rsuite";
import ChartMetricHeader from "./ChartMetricHeader";

class OrderFulfillmentByQuantityPieChartCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    tab: "performanceTrend",
    colors: {
      "Lifestyle 12": "emerald",
      "Lifestyle 18": "lime",
      "Lifestyle 24": "teal",
      "Lifestyle 30": "cyan",
      "Athlete 12": "orange",
      "Athlete 18": "amber",
      "Athlete 24": "yellow",
      "Athlete 30": "rose",
    },
  };

  /**
   * Fetches the order data by week for the given stores & date range
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchOrderDataByWeek(cubejsApi, stores, dateRange) {
    return new Promise((resolve, reject) => {
      let endDate6 = moment(dateRange[1]);

      endDate6.startOf("day").day(6);

      let now = moment();

      if (endDate6.isAfter(now)) {
        endDate6.subtract(1, "week");
      }

      // Load
      cubejsApi
        .load({
          measures: [
            "SubscriptionFulfillmentStatisticsTotalOrderQTYByPlan.statisticsTotalorderqtybyplanQuantity",
          ],
          order: {
            "SubscriptionFulfillmentStatisticsTotalOrderQTYByPlan.statisticsTotalorderqtybyplanQuantity":
              "desc",
          },

          timeDimensions: [
            {
              dimension: "SubscriptionFulfillmentReports.fulfillmentdate",
              dateRange: [moment(dateRange[0]).toDate(), endDate6?.toDate()],
            },
          ],
          filters: [
            {
              member: "SubscriptionFulfillmentReports.storeid",
              operator: "equals",
              values: stores,
            },
            {
              member: "SubscriptionFulfillmentReports.orderCount",
              operator: "notEquals",
              values: ["0"],
            },
          ],
          dimensions: [
            "Stores.name",
            "SubscriptionFulfillmentStatisticsTotalOrderQTYByPlan.planQuantity",
            "SubscriptionFulfillmentStatisticsTotalOrderQTYByPlan.planTypeName",
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          console.log("DATA", data);

          if (!data?.length) {
            return resolve(null);
          }

          let total = 0;

          data = data.map((item) => {
            total +=
              item[
                "SubscriptionFulfillmentStatisticsTotalOrderQTYByPlan.statisticsTotalorderqtybyplanQuantity"
              ];

            return {
              storeName: item["Stores.name"]
                ?.replace("Project LeanNation", "PLN")
                ?.trim(),
              planQuantity:
                item[
                  "SubscriptionFulfillmentStatisticsTotalOrderQTYByPlan.planQuantity"
                ],
              planTypeName:
                item[
                  "SubscriptionFulfillmentStatisticsTotalOrderQTYByPlan.planTypeName"
                ]?.trim(),
              planReadableName: `${item[
                "SubscriptionFulfillmentStatisticsTotalOrderQTYByPlan.planTypeName"
              ]?.trim()} ${
                item[
                  "SubscriptionFulfillmentStatisticsTotalOrderQTYByPlan.planQuantity"
                ]
              }`,
              count:
                item[
                  "SubscriptionFulfillmentStatisticsTotalOrderQTYByPlan.statisticsTotalorderqtybyplanQuantity"
                ],
            };
          });

          let out = [];
          let overall = [];

          for (let i = 0; i < data?.length; i++) {
            const item = data[i];

            const idx = _.findIndex(out, { storeName: item?.storeName });

            const overallIdx = _.findIndex(overall, {
              planReadableName: item?.planReadableName,
            });

            if (overallIdx >= 0) {
              overall[overallIdx].Quantity += item?.count;
            } else {
              overall.push({
                planReadableName: item?.planReadableName,
                Quantity: item?.count,
              });
            }

            if (idx >= 0) {
              out[idx][item.planReadableName] = item.count;
            } else {
              out.push({
                storeName: item.storeName,
                [item.planReadableName]: item?.count,
              });
            }
          }

          let outByStore2 = out.map((item) => {
            let total =
              this.getStoreTotal(item, "Lifestyle") +
              this.getStoreTotal(item, "Athlete");

            item.Quantity = total;
            item.lsTotal = this.getStoreTotal(item, "Lifestyle");
            item.atTotal = this.getStoreTotal(item, "Athlete");

            return item;
          });

          outByStore2 = _.sortBy(outByStore2, "Quantity");

          outByStore2.reverse();

          overall = _.sortBy(overall, "Quantity");

          overall.reverse();

          overall = overall.map((item, i) => {
            item.color = this.state.colors[item.planReadableName];

            return item;
          });

          console.log(outByStore2);

          return resolve({
            byStore: outByStore2,
            total,
            overall,
          });
        })
        .catch((e) => {
          console.error(e);

          reject(e);
        });
    });
  }

  async loadDetailedReport(stores, dateRange) {
    this.setState({
      loading: true,
      dataAvailable: false,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!stores?.length || !dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchOrderDataByWeek(
        cubejsApi,
        stores,
        dateRange
      );
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load meal quantities.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });

      return;
    }

    let total = 0;

    for (let i = 0; i < currentCount?.overall?.length; i++) {
      total += currentCount.overall[i].Quantity;
    }

    this.setState({
      currentCount: currentCount,
      loading: false,
      byStore: currentCount?.byStore,
      overall: currentCount?.overall,
      total: total,
      colors: currentCount?.overall?.map((item) => {
        return item.color;
      }),
    });

    if (typeof this.props.onLoaded == "function") {
      this.props.onLoaded();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stores != prevProps?.stores ||
      this.props.dateRange != prevProps?.dateRange
    ) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload != prevProps.reload && this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  getColorForPlan(key) {
    let item = _.findWhere(this.state.overall, { planReadableName: key });

    return StringUtils.CHART_COLOR_CODES[item.color];
  }

  getStoreTotal(item, type) {
    let total = 0;

    let keys = _.keys(item);

    for (let i = 0; i < keys?.length; i++) {
      if (
        keys[i] == "storeName" ||
        keys[i] == "color" ||
        keys[i] == "Quantity" ||
        isNaN(item[keys[i]]) ||
        !keys[i].includes(type)
      ) {
        continue;
      }

      console.log(keys[i]);

      total += item[keys[i]];
    }

    console.log(total);

    return total;
  }

  componentDidMount() {
    if (this.props.store && this.props.dateRange) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  render() {
    return (
      <>
        <Card marginTop="mt-0">
          <Row className="align-items-center">
            <Col xs="">
              <h3 className="m-0 text-dark">
                Order Fulfillment By Order Quantity
              </h3>
            </Col>
            <Col xs="auto">
              <Button
                size="sm"
                outline
                color="dark"
                className="border-0 btn-icon-only"
                disabled={this.state.loading}
                onClick={() => {
                  this.loadDetailedReport(
                    this.props.stores,
                    this.props.dateRange
                  );
                }}
              >
                {this.state.loading ? (
                  <Spinner size="sm"></Spinner>
                ) : (
                  <i className="mdi mdi-refresh"></i>
                )}
              </Button>
            </Col>
          </Row>

          {this.state.loading ? (
            <div
              className="skeleton mt-4"
              style={{ height: "calc(320px + 1rem)", width: "100%" }}
            >
              &nbsp;
            </div>
          ) : (
            <>
              {this.state.dataAvailable ? (
                <>
                  <div className="mt-4 mx--4 px-4 border-top">
                    <Row className="align-items-center">
                      <Col xs="12" sm="12" md="6">
                        <div className="pt-4">
                          <DonutChart
                            data={this.state.overall}
                            category={"Quantity"}
                            dataKey="planReadableName"
                            colors={this.state.overall?.map(
                              (item) => item.color
                            )}
                            valueFormatter={(number) => {
                              return `${StringUtils.numberFormat(
                                number
                              )} Orders [${StringUtils.numberFormat(
                                parseFloat(
                                  ((number / this.state.total) * 100).toFixed(1)
                                )
                              )}%]`;
                            }}
                            height="h-80"
                          />
                        </div>
                      </Col>
                      <Col md="6" className="mt-3 mt-md-0">
                        <div
                          className="pt-4 mb--4 pb-4"
                          style={{
                            maxHeight: 368,
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          {this.state.byStore?.map((item, i) => (
                            <>
                              <div className="mb-3" key={i}>
                                <h3 className="text-dark mb-1">
                                  {item?.storeName}
                                </h3>

                                <div className="text-dark small font-weight-medium">
                                  Lifestyle Plans:{" "}
                                  {StringUtils.numberFormat(item?.lsTotal)}{" "}
                                  Orders
                                </div>
                                <div
                                  style={{
                                    borderRadius: 6,
                                    width: "100%",
                                    height: 20,
                                    overflow: "hidden",
                                  }}
                                  className="mb-2 border border-superlight"
                                >
                                  {_.filter(_.keys(item), (item) => {
                                    return item?.includes("Lifestyle");
                                  }).map((quantity, j) => (
                                    <div
                                      style={{
                                        height: 20,
                                        width: `${
                                          (item[quantity] /
                                            this.getStoreTotal(
                                              item,
                                              "Lifestyle"
                                            )) *
                                          100
                                        }%`,
                                        background:
                                          this.getColorForPlan(quantity),
                                      }}
                                      className="d-inline-block cursor-pointer"
                                      key={j}
                                    >
                                      <Whisper
                                        trigger="hover"
                                        placement="auto"
                                        preventOverflow={true}
                                        speaker={
                                          <Popover full className="">
                                            <div className="p-2">
                                              <h4 className="mb-1 text-dark">
                                                {item.storeName}
                                              </h4>
                                              <p className="m-0 text-dark">
                                                <span className="font-weight-bold">
                                                  {quantity}:
                                                </span>
                                                <span>
                                                  &nbsp;
                                                  {StringUtils.numberFormat(
                                                    item[quantity]
                                                  )}{" "}
                                                  Orders [
                                                  {(
                                                    (item[quantity] /
                                                      this.getStoreTotal(
                                                        item,
                                                        "Lifestyle"
                                                      )) *
                                                    100
                                                  ).toFixed(1)}
                                                  %]
                                                </span>
                                              </p>
                                            </div>
                                          </Popover>
                                        }
                                      >
                                        <div
                                          style={{
                                            height: 20,
                                            width: "100%",
                                          }}
                                        ></div>
                                      </Whisper>
                                    </div>
                                  ))}
                                </div>
                                <div className="text-dark small font-weight-medium">
                                  Athlete Plans:&nbsp;
                                  {StringUtils.numberFormat(item?.atTotal)}{" "}
                                  Orders
                                </div>
                                <div
                                  style={{
                                    borderRadius: 6,
                                    width: "100%",
                                    height: 20,
                                    overflow: "hidden",
                                  }}
                                  className="mb-3 border border-superlight"
                                >
                                  {_.filter(_.keys(item), (item) => {
                                    return item?.includes("Athlete");
                                  }).map((quantity, j) => (
                                    <div
                                      style={{
                                        height: 20,
                                        width: `${
                                          (item[quantity] /
                                            this.getStoreTotal(
                                              item,
                                              "Athlete"
                                            )) *
                                          100
                                        }%`,
                                        background:
                                          this.getColorForPlan(quantity),
                                      }}
                                      className="d-inline-block cursor-pointer"
                                      key={j}
                                    >
                                      <Whisper
                                        trigger="hover"
                                        placement="auto"
                                        preventOverflow={true}
                                        speaker={
                                          <Popover className="" full>
                                            <div className="p-2">
                                              <h4 className="mb-1 text-dark">
                                                {item.storeName}
                                              </h4>
                                              <p className="m-0 text-dark">
                                                <span className="font-weight-bold">
                                                  {quantity}:
                                                </span>
                                                <span>
                                                  &nbsp;
                                                  {StringUtils.numberFormat(
                                                    item[quantity]
                                                  )}{" "}
                                                  Orders [
                                                  {(
                                                    (item[quantity] /
                                                      this.getStoreTotal(
                                                        item,
                                                        "Athlete"
                                                      )) *
                                                    100
                                                  ).toFixed(1)}
                                                  %]
                                                </span>
                                              </p>
                                            </div>
                                          </Popover>
                                        }
                                      >
                                        <div
                                          style={{
                                            height: 20,
                                            width: "100%",
                                          }}
                                        ></div>
                                      </Whisper>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div className="my-2 border border-midlighter"></div>
                            </>
                          ))}

                          {/**
                           * <BarList
                            data={this.state.byStore?.map((item) => {
                              return {
                                name: item?.storeName,
                                value: parseFloat(
                                  (
                                    (item?.weekly / item?.Quantity) *
                                    100
                                  ).toFixed(1)
                                ),
                              };
                            })}
                            valueFormatter={(number) => {
                              return `${StringUtils.numberFormat(
                                number
                              )}% weekly, ${StringUtils.numberFormat(
                                100 - number
                              )}% biweekly`;
                            }}
                            color="emerald"
                            showAnimation={true}
                            marginTop="mt-0"
                          />
                           */}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "calc(320px + 1rem)", width: "100%" }}
                >
                  <p className="m-0">
                    No data available.
                    {this.state.error ? ` ${this.state.error}` : null}
                  </p>
                </div>
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default OrderFulfillmentByQuantityPieChartCard;
