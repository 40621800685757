import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  Card,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import ClientOrderKPIDetailDrawer from "./ClientOrderKPIDetailDrawer";
import ChartMetricHeader from "./ChartMetricHeader";
import RetailSalesKPIDetailDrawer from "./RetailSalesKPIDetailDrawer";
import pluralize from "pluralize";

class RetailSalesKPICard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
  };

  /**
   * Fetches a summary of the order data for the given stores and date range
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchSalesData(cubejsApi, stores, dateRange) {
    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["SquareOrders.plnNetAmount"],
          order: {
            "SquareOrders.createdat": "asc",
          },

          timeDimensions: [
            {
              dimension: "SquareOrders.createdat",
              dateRange: [dateRange[0], dateRange[1]],
            },
          ],
          filters: [
            {
              member: "SquareOrders.storeid",
              operator: "equals",
              values: stores,
            },
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          if (
            data?.length &&
            data[0].hasOwnProperty("SquareOrders.plnNetAmount")
          ) {
            return resolve(
              parseFloat(data[0]["SquareOrders.plnNetAmount"]) * 100
            );
          }

          return resolve(null);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async _fetchGoalData(cubejsApi, stores, dateRange) {
    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: [
            "MetricsWeeklyRetailSales.metadataSalesTotalSum",
            "MetricsWeeklyRetailSales.metadataSalesTotalAverage",
            "MetricsWeeklyRetailSales.metadataSalesGoalSum",
            "MetricsWeeklyRetailSales.metadataSalesGoalAverage",
            "MetricsWeeklyRetailSales.metadataPercentGoal",
          ],
          order: {
            "MetricsWeeklyRetailSales.createdat": "asc",
          },
          dimensions: ["MetricsWeeklyRetailSales.metadatastoreid"],
          timeDimensions: [
            {
              dimension: "MetricsWeeklyRetailSales.createdat",
              dateRange: [dateRange[0], dateRange[1]],
            },
          ],
          filters: [
            {
              member: "MetricsWeeklyRetailSales.metadatastoreid",
              operator: "equals",
              values: stores,
            },
            {
              member: "MetricsWeeklyRetailSales.name",
              operator: "equals",
              values: ["weekly_retail_sales"],
            },
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          let goalData = {
            totalGoalCount: null,
            goalPercent: null,
            goalCount: null,
          };

          data = data.map((item) => {
            return {
              totalGoalCount:
                item["MetricsWeeklyRetailSales.metadataSalesGoalAverage"],
              goalCount:
                item["MetricsWeeklyRetailSales.metadataSalesTotalAverage"],
            };
          });

          for (let i = 0; i < data?.length; i++) {
            const item = data[i];

            if (item?.goalCount !== null) {
              goalData.totalGoalCount += item.totalGoalCount;
              goalData.goalCount += item.goalCount;
            }
          }

          if (goalData.goalCount !== null && goalData.totalGoalCount) {
            goalData.goalPercent =
              (goalData.goalCount / goalData.totalGoalCount) * 100;

            if (goalData.goalPercent < 50) {
              goalData.goalColor = "rose";
            } else if (goalData.goalPercent < 60) {
              goalData.goalColor = "orange";
            } else if (goalData.goalPercent < 70) {
              goalData.goalColor = "amber";
            } else if (goalData.goalPercent < 85) {
              goalData.goalColor = "yellow";
            } else if (goalData.goalPercent < 93) {
              goalData.goalColor = "lime";
            } else if (goalData.goalPercent >= 93) {
              goalData.goalColor = "emerald";
            }
          }

          return resolve(goalData);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async loadReport(stores, dateRange, compare) {
    this.setState({
      loading: true,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!stores?.length || !dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchSalesData(cubejsApi, stores, dateRange);
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load client order quantity.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });
    }

    if (this.props.comparePrevious) {
      let previousCount = null;

      let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

      secondDifference = secondDifference / 1000;

      let startMoment = moment(dateRange[0].toISOString());
      let endMoment = moment(dateRange[1].toISOString());

      startMoment.subtract(secondDifference, "seconds");
      endMoment.subtract(secondDifference + 1, "seconds");

      try {
        previousCount = await this._fetchSalesData(cubejsApi, stores, [
          startMoment.toDate(),
          endMoment.toDate(),
        ]);
      } catch (e) {}

      if (previousCount !== null) {
        let percentChange = (currentCount - previousCount) / previousCount;

        let declineMode = "";
        let isNegative = false;

        if (percentChange > 0) {
          if (Math.abs(percentChange) < 0.015) {
            declineMode = "unchanged";
          } else if (Math.abs(percentChange) < 0.1) {
            declineMode = "moderateIncrease";
          } else if (Math.abs(percentChange) >= 0.1) {
            declineMode = "increase";
          }
        } else {
          isNegative = true;

          if (Math.abs(percentChange) < 0.015) {
            declineMode = "moderateDecrease";
          } else if (Math.abs(percentChange) < 0.1) {
            declineMode = "moderateDecrease";
          } else if (Math.abs(percentChange) >= 0.1) {
            declineMode = "decrease";
          }
        }

        this.setState({
          previousCount,
          changeIsNegative: isNegative,
          deltaType: declineMode,
          percentChange,
          percentChangeString: Math.abs(percentChange * 100).toFixed(1) + "%",
        });
      }
    }

    let goals = null;

    try {
      goals = await this._fetchGoalData(cubejsApi, stores, dateRange);
    } catch (e) {}

    if (goals) {
      goals.goalDataAvailable = true;
    }

    this.setState({
      currentCount,
      loading: false,
      ...goals,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stores != prevProps?.stores ||
      this.props.dateRange != prevProps?.dateRange ||
      this.props.comparePrevious != prevProps.comparePrevious
    ) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  componentDidMount() {
    if (this.props.store && this.props.dateRange) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  render() {
    return (
      <>
        <Card>
          <ChartMetricHeader
            title="Retail Sales"
            badge={
              this.props.isGlobal || !this.props.stores?.length
                ? null
                : `Your ${this.props.stores?.length} ${pluralize(
                    "Store",
                    this.props.stores?.length
                  )}`
            }
            actions={
              <>
                <Button
                  size="sm"
                  outline
                  color="dark"
                  className="border-0 btn-icon-only"
                  disabled={this.state.loading || !this.state.dataAvailable}
                  onClick={() => {
                    this.setState({
                      open: true,
                    });
                  }}
                >
                  <i className="mdi mdi-fullscreen"></i>
                </Button>
              </>
            }
            loading={this.state.loading}
            dataAvailable={this.state.dataAvailable}
            metric={StringUtils.centsToCurrency(this.state.currentCount)}
            forceWrapComparison={true}
            comparisonMetric={
              this.state.previousCount !== null
                ? StringUtils.centsToCurrency(this.state.previousCount)
                : null
            }
            dateRange={this.props.dateRange}
            deltaType={this.state.deltaType}
            percentChange={this.state.percentChangeString}
            showPercentChange={true}
          ></ChartMetricHeader>

          {this.state.loading ? (
            <div
              className="tr-mt-4 skeleton"
              style={{ height: 20, width: 60 }}
            ></div>
          ) : (
            <>
              {this.state.goalDataAvailable ? (
                <>
                  <Row className="align-items-center mt-3">
                    <Col xs="12" sm="" md="12" lg="">
                      <Text>
                        ~{StringUtils.centsToCurrency(this.state.goalCount)}
                        /Week&nbsp; &bull; {this.state.goalPercent?.toFixed(1)}%
                      </Text>
                    </Col>
                    <Col
                      xs="12"
                      sm="auto"
                      md="12"
                      lg="auto"
                      className="mt-1 mt-sm-0 mt-md-1 mt-lg-0"
                    >
                      <Text>
                        ~
                        {StringUtils.centsToCurrency(this.state.totalGoalCount)}
                        /Week Goal
                      </Text>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Flex marginTop="mt-4">
                    <Text>--</Text>
                  </Flex>
                </>
              )}
            </>
          )}
          {this.state.loading ? (
            <div
              className="tr-mt-2 skeleton"
              style={{ height: 8, width: "100%" }}
            ></div>
          ) : (
            <>
              {this.state.goalDataAvailable ? (
                <ProgressBar
                  percentageValue={this.state.goalPercent}
                  color={this.state.goalColor}
                  marginTop="mt-2"
                />
              ) : (
                <ProgressBar
                  percentageValue={0}
                  color="gray"
                  marginTop="mt-2"
                />
              )}
            </>
          )}
        </Card>
        <RetailSalesKPIDetailDrawer
          open={this.state.open}
          onClose={() => {
            this.setState({
              open: false,
            });
          }}
          stores={this.props.stores}
          dateRange={this.props.dateRange}
        />
      </>
    );
  }
}

export default RetailSalesKPICard;
