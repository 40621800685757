import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import CustomerInvoiceDrawer from "components/Drawers/CustomerInvoiceDrawer";
import ProductionOrderDrawer from "../../components/Drawers/ProductionOrderDrawer";
import NewQualityClaimDrawer from "components/Drawers/NewQualityClaimDrawer";
import QualityClaimDetailDrawer from "components/Drawers/QualityClaimDetailDrawer";

class ProductionQualityListView extends React.Component {
  state = {
    active: {
      id: "Pending Claims",
      name: "Pending Claims",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingOrders: true,
    claims: [],
    page: 1,
    count: 25,
    filters: null,
  };

  componentDidUpdate(prevProps) {
    if (
      this.props?.match?.params?.storeID != prevProps?.match?.params?.storeID &&
      this.props.match?.params?.storeID
    ) {
      this.loadStore();
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab) {
      this.handleTabChange(
        {
          id: decodeURIComponent(tab),
          name: decodeURIComponent(tab),
        },
        true
      );
    } else {
      this.handleTabChange(this.state.active, true);
    }

    PubSub.subscribe(Event.QUALITY_CLAIM.CREATED, (m) => {
      this.handleTabChange(this.state.active, true);
    });

    PubSub.subscribe(Event.QUALITY_CLAIM.DELETED, (m) => {
      this.handleTabChange(this.state.active, true);
    });

    PubSub.subscribe(Event.QUALITY_CLAIM.MODIFIED, (a) => {
      this.handleTabChange(this.state.active, true);

      if (this.state.claim?._id == a?._id) {
        this.setState({
          claim: _.clone(a),
        });
      }
    });

    if (this.props.match?.params?.storeID) {
      this.loadStore();
    }
  }

  loadStore() {
    APIV2.getStoreByID(this.props.match.params.storeID).then(
      (data) => {
        this.setState({
          store: data.data.store,
        });
      },
      (e) => {
        alert("Unable to load store. Reload and try again.");
      }
    );
  }

  loadClaims(page = 1, count = 25, filters = null, orderBy = null) {
    this.setState({
      loadingOrders: true,
    });

    const f = filters ? JSON.parse(JSON.stringify(filters)) : {};

    APIV2.getProductionQualityClaims(
      page,
      count,
      f,
      orderBy
        ? orderBy
        : {
            claimDate: -1,
          }
    )
      .then(
        (data) => {
          if (data && data.data) {
            let claims = [];

            if (data.data.claims.length) {
              claims = data.data.claims;
            }

            this.setState({
              claims,
              pagination: data.data.pagination,
              result: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the claims. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingOrders: false,
        });
      });
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadClaims(page, this.state.count, this.state.filters);
  }

  formatStatus(claim) {
    let color = "light";

    let icon = "mdi-circle-outline";

    if (claim?.status == "SUBMITTED") {
      color = "medium";
    }

    if (claim?.status == "PROCESSING") {
      color = "medium";
      icon = "mdi-circle";
    }

    if (claim?.status == "CREDIT PENDING") {
      color = "success";
    }

    if (claim?.status == "COMPLETE") {
      color = "success";

      icon = "mdi-circle";
    }

    return (
      <Badge color={color}>
        <i className={`mr-1 mdi ${icon}`}></i>
        {claim?.status}
      </Badge>
    );

    return <>--</>;
  }

  getMealCount(claim, status) {
    if (status == "pending") {
      return _.filter(claim?.claims, (c) => {
        return c?.approved === null;
      })?.length;
    }

    if (status == "approved") {
      return _.filter(claim?.claims, (c) => {
        return c?.approved;
      })?.length;
    }

    if (status == "unapproved") {
      return _.filter(claim?.claims, (c) => {
        return c?.approved === false;
      })?.length;
    }
  }

  getTotalValue(c) {
    let claims = _.filter(c?.claims, (cl) => {
      return cl?.approved;
    });

    let total = 0;

    for (let i = 0; i < claims?.length; i++) {
      total += claims[i]?.value;
    }

    return total;
  }

  claimRow(c, i) {
    return (
      <>
        <tr
          key={i}
          className="cursor-pointer"
          onClick={() => {
            this.setState({
              viewDrawer: true,
              claim: c,
            });
          }}
        >
          <td className="py-2 px-3">
            <p className="mb-0 text-dark" style={{ fontSize: "12px" }}>
              {c?.store?.name?.replace("Project LeanNation", "PLN")}
            </p>
            <h4 className="mb-0 text-dark">
              Product Quality Claim -{" "}
              {moment(c?.submittedOn ? c?.submittedOn : c?.claimDate)
                .startOf("day")
                .format("MM/DD/YYYY")}
            </h4>
            <p className="mb-0 text-dark" style={{ fontSize: "12px" }}>
              {c?.claims?.length} Meal{c?.claims?.length != 1 ? "s" : ""}{" "}
              Submitted
            </p>
          </td>
          <td className="py-2 px-3">
            <p className="mb-0">{this.formatStatus(c)}</p>
          </td>

          <td className="py-2 px-3">
            <p className="mb-0 text-dark">
              {this.getMealCount(c, "pending")} Meal
              {this.getMealCount(c, "pending") != 1 ? "s" : ""}
            </p>
          </td>
          <td className="py-2 px-3">
            <p className="mb-0 text-dark">
              {this.getMealCount(c, "approved")} Meal
              {this.getMealCount(c, "approved") != 1 ? "s" : ""}
            </p>
          </td>
          <td className="py-2 px-3">
            <p className="mb-0 text-dark">
              {this.getMealCount(c, "unapproved")} Meal
              {this.getMealCount(c, "unapproved") != 1 ? "s" : ""}
            </p>
          </td>
          <td className="py-2 px-3">
            <p className="mb-0 text-dark">
              {StringUtils.centsToCurrency(this.getTotalValue(c) ?? 0)}
            </p>
          </td>
        </tr>
      </>
    );
  }

  handleTabChange(option, force = false) {
    let filters = null;

    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    if (option.id == "Pending Claims") {
      filters = {
        status: { $in: ["SUBMITTED", "PROCESSING", "CREDIT PENDING"] },
      };
    } else if (option.id == "Completed Claims") {
      filters = {
        status: "COMPLETE",
      };
    }

    this.setState({
      filters,
      active: option,
    });

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    const orderBy = {
      claimDate: -1,
    };

    this.loadClaims(1, this.state.count, filters, orderBy);
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader title="Meal Claims & Credits" showBackButton={false} />

          <Row>
            <div className="col">
              <Card className=" shadow" style={{ minHeight: "500px" }}>
                <CardSelectorHeader
                  compact={true}
                  value={this.state.active}
                  options={[
                    {
                      name: "Pending Claims",
                      id: "Pending Claims",
                    },
                    {
                      name: "Completed Claims",
                      id: "Completed Claims",
                    },
                  ]}
                  onChange={this.handleTabChange.bind(this)}
                ></CardSelectorHeader>
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    {/*<Col xs="auto" sm="auto" className="pr-0">
                      <Checkbox
                        indeterminate={true}
                        checked={true}
                        onChange={() => {}}
                      ></Checkbox>
                </Col>*/}
                    <Col xs="" sm="" className="">
                      <div>
                        {this.state.result?.start && this.state.result?.end ? (
                          <>
                            Showing{" "}
                            {StringUtils.numberFormat(this.state.result?.start)}
                            -{StringUtils.numberFormat(this.state.result?.end)}
                            {this.state.result?.total ? (
                              <>
                                &nbsp;of{" "}
                                {StringUtils.numberFormat(
                                  this.state.result?.total
                                )}{" "}
                                claims
                              </>
                            ) : null}
                          </>
                        ) : (
                          <>---</>
                        )}
                      </div>
                    </Col>
                    <Col
                      xs="auto"
                      sm="auto"
                      className="pl-2 pl-sm-3 text-right"
                    >
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        className="btn-icon-only"
                        outline
                        disabled={
                          this.state.page == 1 ||
                          !this.state.pagination?.hasPrevious
                        }
                        onClick={() => {
                          this.incrementPage(-1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-left"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        disabled={!this.state.pagination?.hasNext}
                        className="btn-icon-only"
                        outline
                        onClick={() => {
                          this.incrementPage(1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-right"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <Table
                    className="align-items-center table-flush table-hover"
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        <th className="text-dark px-3">Order</th>

                        <th className="text-dark px-3">Claim Status</th>
                        <th className="text-dark px-3">In Review</th>
                        <th className="text-dark px-3">Approved</th>
                        <th className="text-dark px-3">Rejected</th>
                        <th className="text-dark px-3">Credit Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.loadingOrders || this.state.searchLoading ? (
                        <>
                          <tr>
                            <td
                              colspan={5}
                              className="text-center p-3"
                              style={{
                                zIndex: 1,
                                position: "absolute",
                                height: "100%",
                                width: "100%",
                                background: "rgba(255, 255, 255, 0.6)",
                              }}
                            >
                              <Spinner
                                style={{ position: "relative", top: "15vh" }}
                                color="medium"
                                size=""
                              ></Spinner>
                            </td>
                          </tr>
                        </>
                      ) : null}
                      {!this.state.claims?.length &&
                      !this.state.loadingOrders ? (
                        <tr>
                          <td colspan={7}>
                            <div className="py-2">
                              <Row>
                                <Col>
                                  Get started by creating your first claim.
                                </Col>
                              </Row>
                            </div>
                          </td>
                        </tr>
                      ) : null}
                      {this.state.claims?.map((c, i) => {
                        return this.claimRow(c, i);
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>

        <QualityClaimDetailDrawer
          open={this.state.viewDrawer}
          claim={this.state.claim}
          onClose={() => {
            this.setState({
              viewDrawer: false,
              claim: null,
            });
          }}
        ></QualityClaimDetailDrawer>
      </>
    );
  }
}

export default ProductionQualityListView;
