import StringUtils from "lib/StringUtils";
import React from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import { Avatar } from "rsuite";

class CustomerOpenActivitiesWidget extends React.Component {
  state = {
    expand: false,
  };

  render() {
    return (
      <>
        {this.props.loading ? (
          <>
            <CardHeader className="bg-transparent py-5 text-center">
              <Spinner size="lg" color="medium"></Spinner>
            </CardHeader>
          </>
        ) : (
          <>
            <Card className="shadow mb-4">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <Col>
                    <h5 className="text-uppercase text-muted mb-0">
                      Open Activities
                    </h5>
                  </Col>
                  <Col xs="auto">
                    <Button
                      size="sm"
                      color="secondary"
                      outline
                      onClick={() => {
                        this.setState({
                          modified: true,
                          modifyImage: true,
                          imageURL: "",
                        });
                      }}
                    >
                      Mark All <i className="mdi mdi-chevron-down"></i>
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                <div className="px-4 py-3 bg-superlight--hover cursor-pointer border-bottom">
                  <Row>
                    <Col xs="12">
                      <Row className="align-items-center">
                        <Col xs="" sm="">
                          <h4 className="mb-0" style={{ lineHeight: 1.2 }}>
                            New Online Call
                          </h4>
                        </Col>
                        <Col xs="auto" sm="auto">
                          <h3
                            className="mb-0"
                            style={{ lineHeight: 1.2, fontSize: "25px" }}
                          >
                            <i className="mdi mdi-chevron-right"></i>
                          </h3>
                        </Col>
                      </Row>
                      <Row className="align-items-center mt-1">
                        <Col xs="" sm="">
                          <Badge
                            size="sm"
                            color="info"
                            className=""
                            style={{ position: "relative", top: "-2px" }}
                          >
                            Call
                          </Badge>
                          <div className="ml-2 d-inline-block">
                            <Avatar size="xs">B</Avatar>{" "}
                            <small>Brandon Hudson</small>
                          </div>
                        </Col>
                        <Col xs="auto" sm="auto">
                          <p className="mb-0">
                            <small>Due: Nov. 16, 2021</small>
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <div className="px-4 py-3 bg-superlight--hover cursor-pointer border-bottom">
                  <Row>
                    <Col xs="12">
                      <Row className="align-items-center">
                        <Col xs="" sm="">
                          <h4 className="mb-0" style={{ lineHeight: 1.2 }}>
                            New Online Call
                          </h4>
                        </Col>
                        <Col xs="auto" sm="auto">
                          <h3
                            className="mb-0"
                            style={{ lineHeight: 1.2, fontSize: "25px" }}
                          >
                            <i className="mdi mdi-chevron-right"></i>
                          </h3>
                        </Col>
                      </Row>
                      <Row className="align-items-center mt-1">
                        <Col xs="" sm="">
                          <Badge
                            size="sm"
                            color="info"
                            className=""
                            style={{ position: "relative", top: "-2px" }}
                          >
                            Call
                          </Badge>
                          <div className="ml-2 d-inline-block">
                            <Avatar size="xs">B</Avatar>{" "}
                            <small>Brandon Hudson</small>
                          </div>
                        </Col>
                        <Col xs="auto" sm="auto">
                          <p className="mb-0">
                            <small>Due: Nov. 16, 2021</small>
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </>
        )}
      </>
    );
  }
}

export default CustomerOpenActivitiesWidget;
