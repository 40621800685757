import React from "react";
// react component that copies the given text inside your clipboard

import { DateRangePicker } from "react-date-range";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Spinner,
  Collapse,
} from "reactstrap";

import {
  Card as TCard,
  Title,
  Text,
  ColGrid,
  Col as TCol,
  Block,
  Flex,
  MarkerBar,
  CategoryBar,
  ProgressBar,
  Metric,
  BadgeDelta,
  ButtonInline,
  Footer,
  Tab,
  Divider,
  TabList,
  DeltaBar,
  AreaChart,
  BarChart,
  DonutChart,
  Bold,
  List,
  ListItem,
} from "@tremor/react";

// core components
import Header from "components/Headers/Header.js";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import moment from "moment";
import {
  Checkbox,
  CheckPicker,
  CheckTreePicker,
  DatePicker,
  Popover,
  SelectPicker,
  Tooltip,
  Whisper,
} from "rsuite";
import StringUtils from "lib/StringUtils";
import { Table, Cell, Column, HeaderCell } from "rsuite-table";
import _ from "underscore";
import MoneyCell from "components/Table/MoneyCell";
import NumericCell from "components/Table/NumericCell";
import StoreCell from "components/Table/StoreCell";
import BootstrapTable from "react-bootstrap-table-next";
import MealsOrderedWidget from "components/Widgets/Fulfillment/MealsOrderedWidget";
import PlansFulfilledWidget from "components/Widgets/Fulfillment/PlansFulfilledWidget";
import AverageBoxSizeWidget from "components/Widgets/Fulfillment/AverageBoxSizeWidget";
import PlanFrequencyChartWidget from "components/Widgets/Fulfillment/PlanFrequencyChartWidget";
import PlanProcurementChartWidget from "components/Widgets/Fulfillment/PlanProcurementChartWidget";
import PlanTypeChartWidget from "components/Widgets/Fulfillment/PlanTypeChartWidget";
import { Link } from "react-router-dom";
import PLNDateRangePicker from "../../components/PLNDateRangePicker";
import cubejs from "@cubejs-client/core";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";

import DataAPI from "../../lib/DataAPI";
import SalesSummaryTrendlineChart from "../../components/Charts/SalesSummaryTrendlineChart";
import SalesSummaryPieChart from "../../components/Charts/SalesSummaryPieChart";
import DashboardSectionHeader from "../../components/DashboardSectionHeader";
import ClientOrderKPICard from "../../components/Charts/Scorecard/ClientOrderKPICard";
import RetailSalesKPICard from "../../components/Charts/Scorecard/RetailSalesKPICard";
import StoreLeaderboardKPICard from "../../components/Charts/Scorecard/StoreLeaderboardKPICard";
import ScorecardNewLeadChartCard from "../../components/Charts/Scorecard/ScorecardNewLeadChartCard";
import ScorecardNewHFNChartCard from "../../components/Charts/Scorecard/ScorecardNewHFNChartCard";
import ScorecardNewContactsChartCard from "../../components/Charts/Scorecard/ScorecardNewContactsChartCard";
import LeadConversionRateKPICard from "../../components/Charts/Scorecard/LeadConversionRateKPICard";
import FulfillmentRateKPICard from "../../components/Charts/Scorecard/FulfillmentRateKPICard";
import OrderCountKPICard from "../../components/Charts/Scorecard/OrderCountKPICard";
import CancelledOrderCountKPICard from "../../components/Charts/Scorecard/CancelledOrderCountKPICard";
import LeadConversionTimeKPICard from "../../components/Charts/Scorecard/LeadConversionTimeKPICard";
import LeadSourcesCard from "../../components/Charts/Scorecard/LeadSourcesCard";
import NewMemberKPICard from "../../components/Charts/Scorecard/NewMemberKPICard";
import ConsultationKPICard from "../../components/Charts/Scorecard/ConsultationKPICard";
import NetMemberKPICard from "../../components/Charts/Scorecard/NetMemberKPICard";
import NewMemberSourcesCard from "../../components/Charts/Scorecard/NewMemberSourcesCard";
import ActiveMemberSummaryChartCard from "../../components/Charts/Scorecard/ActiveMemberSummaryChartCard";
import SubscriptionLTVKPICard from "../../components/Charts/Scorecard/SubscriptionLTVKPICard";
import FranLeadActivityCard from "../../components/Charts/FranLeadActivityCard";

class FranLeadDashboard extends React.Component {
  state = {
    dateRange: [
      moment()
        .day(0)
        .subtract(1, "week")
        .hours(0)
        .minutes(0)
        .seconds(0)
        .millisecond(0)
        .toDate(),
      moment()
        .day(6)
        .subtract(1, "week")
        .hours(23)
        .minutes(59)
        .seconds(59)
        .millisecond(999)
        .toDate(),
    ],
    storeOptions: [],
    selectedStores: [],
    pendingSelectedStores: [],
    groupByOptions: [
      {
        label: "Summary",
        value: "summary",
      },
      {
        label: "Daily",
        value: "daily",
      },
      {
        label: "Weekly",
        value: "weekly",
      },
      {
        label: "Monthly",
        value: "monthly",
      },
      {
        label: "Quarterly",
        value: "quarterly",
      },
      {
        label: "Yearly",
        value: "yearly",
      },
    ],
    groupBy: "summary",
    revenueSourceOptions: [
      {
        label: "All Sales",
        value: "all",
      },
      {
        label: "Subscription Sales",
        value: "subscription",
      },
      {
        label: "Net Retail Sales",
        value: "retail",
      },
    ],
    revenueSource: "all",
  };

  constructor(props) {
    super(props);

    this.storePickerRef = React.createRef();
  }

  getUser() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user;
  }

  /**
   * Checks if the current user is a global user via permissions
   *
   * @returns
   */
  isGlobal() {
    let user = this.getUser();

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  componentDidMount() {
    this.readURLParameters();

    let user = LocalStorage.get(Constant.CACHE.USER);

    console.log(user);

    if (!user?.globalPermissions?.length) {
      this.props.history.push("/reports");

      return;
    }
  }

  hasValidDateRange() {
    if (
      this.state?.dateRange?.length != 2 ||
      !this.state?.dateRange[0] ||
      !this.state?.dateRange[1]
    ) {
      return false;
    }

    return true;
  }

  renderDateRange() {
    if (!this.hasValidDateRange()) {
      return "Select Date Range";
    }

    let startMoment = moment(this.state.dateRange[0]?.toISOString());
    let endMoment = moment(this.state.dateRange[1]?.toISOString());

    if (startMoment?.format("YYYY-MM-DD") == endMoment.format("YYYY-MM-DD")) {
      return `${startMoment?.format("MMM D, YYYY")}`;
    }

    return `${startMoment?.format("MMM D, YYYY")} - ${endMoment?.format(
      "MMM D, YYYY"
    )}`;
  }

  renderPreviousDateRange() {
    if (!this.hasValidDateRange()) {
      return "--";
    }

    let secondDifference =
      this.state.dateRange[1].getTime() - this.state.dateRange[0].getTime();

    secondDifference = secondDifference / 1000;

    let startMoment = moment(this.state.dateRange[0].toISOString());
    let endMoment = moment(this.state.dateRange[1].toISOString());

    startMoment.subtract(secondDifference, "seconds");
    endMoment.subtract(secondDifference + 1, "seconds");

    if (startMoment?.format("YYYY-MM-DD") == endMoment.format("YYYY-MM-DD")) {
      return `${startMoment?.format("MMM D, YYYY")}`;
    }

    return `${startMoment?.format("MMM D, YYYY")} - ${endMoment?.format(
      "MMM D, YYYY"
    )}`;
  }

  setURLParameters() {
    // Construct URLSearchParams object instance from current URL querystring.
    let queryParams = new URLSearchParams(window.location.search);

    if (this.state.dateRange?.length == 2) {
      let startDate = this.state.dateRange[0]?.toISOString();
      let endDate = this.state.dateRange[1]?.toISOString();

      // Set new or modify existing parameter value.
      queryParams.set("startDate", startDate);
      // Set new or modify existing parameter value.
      queryParams.set("endDate", endDate);
    }

    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );
  }

  readURLParameters() {
    let queryParams = new URLSearchParams(window.location.search);

    let state = {};

    if (queryParams.get("startDate") && queryParams.get("endDate")) {
      let startDate = moment(queryParams.get("startDate")).toDate();
      let endDate = moment(queryParams.get("endDate")).toDate();
      state.dateRange = [startDate, endDate];
    }

    this.setState(state);
  }

  async loadData() {
    this.setState({
      loadingData: true,
    });

    this.setURLParameters();
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeader title="FranLead Dashboard" showBackButton={false} />
          {/* Table */}
          <div className="border-bottom pb-3 mt--2 mb-3">
            <Row className="align-items-center">
              <Col xs="12" sm="6" lg="auto" className="pr-sm-1">
                <div className="reports-date-picker">
                  <PLNDateRangePicker
                    value={this.state.dateRange}
                    onChange={(v) => {
                      console.log(v);

                      this.setState({ dateRange: v }, () => {
                        this.loadData();
                      });

                      if (v?.length == 2 && v[0] && v[1]) {
                        localStorage.setItem(
                          "salesSummary_dateRange",
                          JSON.stringify({
                            startDate: v[0]?.toISOString(),
                            endDate: v[1]?.toISOString(),
                          })
                        );
                      }
                    }}
                  ></PLNDateRangePicker>
                </div>
              </Col>
            </Row>
          </div>
          <div className="mb-3">
            <h1 className="m-0">{this.renderDateRange()}</h1>
            <p className="m-0 text-muted">
              Compared to {this.renderPreviousDateRange()}
            </p>
          </div>
          <div>
            {!this.hasValidDateRange() && (
              <>
                <Card className="border">
                  <CardBody className="text-center">
                    <h3>Select Date Range</h3>
                    <p className="m-0">
                      Select a date range to view the FranLead dashboard.
                    </p>
                  </CardBody>
                </Card>
              </>
            )}
            {this.hasValidDateRange() && (
              <>
                <div className="mb-5">
                  <DashboardSectionHeader
                    title="Lead Outreach"
                    description="Measure outreach activity to FranDev Leads"
                  ></DashboardSectionHeader>
                  <ColGrid
                    numColsMd={12}
                    numColsLg={12}
                    gapX="gap-x-6"
                    gapY="gap-y-6"
                    marginTop="mt-3"
                  >
                    <TCol numColSpanMd={12} numColSpanLg={12}>
                      <FranLeadActivityCard
                        dateRange={this.state.dateRange}
                        comparePrevious={true}
                      ></FranLeadActivityCard>
                    </TCol>
                    {/*<TCol numColSpanMd={6} numColSpanLg={6}>
                     <ScorecardNewContactsChartCard
                        dateRange={this.state.dateRange}
                        comparePrevious={true}
                        stores={this.state.selectedStores}
            ></ScorecardNewContactsChartCard>
                    </TCol>*/}
                  </ColGrid>
                </div>
              </>
            )}
          </div>
        </Container>
      </>
    );
  }
}

export default FranLeadDashboard;
