import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
  Badge,
} from "reactstrap";
import Alert from "reactstrap/lib/Alert";
import { SelectPicker } from "rsuite";
import CustomerOpenActivitiesWidget from "./CustomerOpenActivitiesWidget";
import CustomerUnclaimedBoxWidget from "./CustomerUnclaimedBoxWidget";
import Constant from "../../lib/Constant";
import _ from "underscore";

class FranDevLeadNameWidget extends React.Component {
  state = {
    expand: false,
    phone: {
      value: "",
    },
    name: "",
    email: "",
  };

  updateName() {
    this.setState({ nameSubmitting: true });

    APIV2.modifyFranDevLead(this.props.lead?._id, {
      name: this.state.name,
    })
      .then(
        (data) => {
          PubSub.publish(Event.LEAD.MODIFIED, data.data?.lead);

          this.setState({ modifyName: false });
        },
        () => {
          this.setError("error", "Unable to modify lead. Please try again.");
        }
      )
      .finally(() => {
        this.setState({ nameSubmitting: false });
      });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 4000);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  render() {
    return (
      <>
        <Card className="mb-4 shadow border">
          <CardHeader>
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Name</h3>
              </div>
            </Row>
          </CardHeader>
          {this.props.loading ? (
            <CardBody>
              <Spinner size="sm" color="dark" className="mr-2" /> Loading...
            </CardBody>
          ) : (
            <>
              <CardBody className="bg-transparent p-0">
                <div className="px-4 py-3 border-bottom">
                  <Row className="align-items-center">
                    <Col xs="auto">Name:</Col>
                    <Col xs="" className="pl-0">
                      {this.props.lead?.name ? this.props.lead?.name : "--"}
                    </Col>
                    <Col xs="auto">
                      {this.state.modifyName ? (
                        <>
                          <Button
                            onClick={() => {
                              this.setState({ modifyName: false });
                            }}
                            size="sm"
                            outline
                            color="secondary"
                            disabled={this.state.nameSubmitting}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              this.updateName();
                            }}
                            disabled={this.state.nameSubmitting}
                            size="sm"
                            color="primary"
                          >
                            {this.state.nameSubmitting ? (
                              <>
                                <Spinner size="sm" color="white" />
                              </>
                            ) : (
                              <>Save</>
                            )}
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => {
                            this.setState({
                              modifyName: true,
                              name: this.props.lead?.name,
                            });
                          }}
                          size="sm"
                          color="secondary"
                          outline
                        >
                          Edit
                        </Button>
                      )}
                    </Col>
                    {this.state.modifyName ? (
                      <Col xs="12" className="mt-3">
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Name"
                          value={this.state.name}
                          onChange={(e) => {
                            this.handleInputChange(e);
                          }}
                          className="form-control"
                        />
                      </Col>
                    ) : null}
                  </Row>
                </div>
              </CardBody>
            </>
          )}
        </Card>
      </>
    );
  }
}

export default withRouter(FranDevLeadNameWidget);
