import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { nodeName } from "dom-lib";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { withRouter } from "react-router";

// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Button,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap";
import CardHeader from "reactstrap/lib/CardHeader";
import Spinner from "reactstrap/lib/Spinner";
import _ from "underscore";

class MealsOrderedWidget extends React.Component {
  state = {
    active: "",
    planTypesLoading: true,
    filteredResults: [],
    mealPerformanceColumns: [
      {
        dataField: "mealID",
        text: "Meal ID",
        hidden: true,
      },
      {
        dataField: "planType",
        text: "Type",
        sort: true,
        headerClasses: "bg-superlight",
        headerStyle: { position: "relative", paddingLeft: "2.3rem" },
        formatter: (cell, row, rowIndex) => {
          return row.planType;
        },
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === "asc") return a.localeCompare(b);
          else return b.localeCompare(a);
        },
        sortCaret: (order, column) => {
          if (order) {
            if (order == "asc") {
              return (
                <i
                  className="mdi mdi-sort-ascending text-primary mr--1"
                  style={{ position: "absolute", left: "1rem" }}
                ></i>
              );
            }

            return (
              <i
                className="mdi mdi-sort-descending text-primary"
                style={{ position: "absolute", left: "1rem" }}
              ></i>
            );
          }

          return (
            <i
              className="mdi mdi-sort"
              style={{ position: "absolute", left: "1rem" }}
            ></i>
          );
        },
      },
      {
        dataField: "mealName",
        text: "Meal",
        sort: true,
        headerClasses: "bg-superlight",
        headerStyle: { position: "relative", paddingLeft: "2.3rem" },
        formatter: (cell, row, rowIndex) => {
          return row.mealName;
        },
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === "asc") return a.localeCompare(b);
          else return b.localeCompare(a);
        },
        sortCaret: (order, column) => {
          if (order) {
            if (order == "asc") {
              return (
                <i
                  className="mdi mdi-sort-ascending text-primary mr--1"
                  style={{ position: "absolute", left: "1rem" }}
                ></i>
              );
            }

            return (
              <i
                className="mdi mdi-sort-descending text-primary"
                style={{ position: "absolute", left: "1rem" }}
              ></i>
            );
          }

          return (
            <i
              className="mdi mdi-sort"
              style={{ position: "absolute", left: "1rem" }}
            ></i>
          );
        },
      },
      {
        dataField: "quantity",
        text: "Quantity",
        sort: true,
        headerClasses: "bg-superlight",
        headerStyle: { position: "relative", paddingLeft: "2.3rem" },
        formatter: (cell, row, rowIndex) => {
          return StringUtils.numberFormat(row.quantity);
        },
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === "asc") return a - b;
          else return b - a;
        },
        sortCaret: (order, column) => {
          if (order) {
            if (order == "asc") {
              return (
                <i
                  className="mdi mdi-sort-ascending text-primary mr--1"
                  style={{ position: "absolute", left: "1rem" }}
                ></i>
              );
            }

            return (
              <i
                className="mdi mdi-sort-descending text-primary"
                style={{ position: "absolute", left: "1rem" }}
              ></i>
            );
          }

          return (
            <i
              className="mdi mdi-sort"
              style={{ position: "absolute", left: "1rem" }}
            ></i>
          );
        },
      },
    ],
  };

  loadSummary(reports) {
    let filteredMeals = [];
    let filteredResults = [];

    this.setState({
      loadingResults: true,
      filteredResults: [],
    });

    for (let i = 0; i < reports.length; i++) {
      if (!reports[i]?.statistics?.totalBOMQtyByMeal) {
        continue;
      }

      for (
        let j = 0;
        j < reports[i]?.statistics?.totalBOMQtyByMeal.length;
        j++
      ) {
        let meal = _.findWhere(this.state.meals, {
          _id: reports[i]?.statistics?.totalBOMQtyByMeal[j].mealID,
        });

        let idx = _.findIndex(filteredMeals, {
          mealSKU: meal?.sku,
        });

        if (idx < 0) {
          let clone = _.clone(reports[i]?.statistics?.totalBOMQtyByMeal[j]);

          clone.mealSKU = meal?.sku;

          filteredMeals.push(clone);
        } else {
          filteredMeals[idx].quantity =
            filteredMeals[idx].quantity +
            reports[i]?.statistics?.totalBOMQtyByMeal[j].quantity;
        }
      }
    }

    for (let i = 0; i < filteredMeals.length; i++) {
      let meal = _.findWhere(this.state.meals, {
        sku: filteredMeals[i].mealSKU,
      });

      if (!meal) {
        continue;
      }

      if (
        this.state.selected?.id == "all" ||
        (this.state.selected?.id == meal.planType?.name &&
          !_.findWhere(meal?.categories, { name: "Lean Cheats" })) ||
        (this.state.selected?.id == "Lean Cheats" &&
          _.findWhere(meal?.categories, { name: "Lean Cheats" }))
      ) {
        let planType = "";

        if (_.findWhere(meal?.categories, { name: "Lean Cheats" })) {
          planType = "Lean Cheats";
        } else {
          planType = meal.planType?.name;
        }

        filteredResults.push({
          planType,
          mealID: meal._id,
          mealSKU: meal.sku,
          mealName: meal.sku,
          quantity: filteredMeals[i].quantity,
          meal: _.clone(meal),
        });
      }
    }

    this.setState({ filteredResults });
  }

  loadPlanTypes() {
    this.setState({
      planTypesLoading: true,
    });

    APIV2.getProductGroups()
      .then((data) => {
        this.setState({
          planTypes: data.data.planTypes,
        });

        let selectorOptions = [{ name: "All", id: "all", _id: "all" }];

        for (let i = 0; i < data.data.planTypes.length; i++) {
          selectorOptions.push({
            name: data.data.planTypes[i].name,
            id: data.data.planTypes[i]?.name,
            _id: data.data.planTypes[i]?.name,
          });
        }

        selectorOptions.push({
          name: "Lean Cheats",
          id: "Lean Cheats",
          _id: "Lean Cheats",
        });

        this.setState({
          selectorOptions,
          selected: { name: "All", id: "all", _id: "all" },
        });
      })
      .finally(() => {
        this.setState({
          planTypesLoading: false,
        });
      });
  }

  loadMeals() {
    return new Promise((resolve) => {
      this.setState({
        mealsLoading: true,
      });

      APIV2.getMeals()
        .then((data) => {
          this.setState({
            meals: data.data.meals,
          });
        })
        .finally(() => {
          this.setState({
            mealsLoading: false,
          });

          resolve();
        });
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.reports &&
      prevProps.reports?.length != this.props.reports?.length
    ) {
      this.loadMeals().then(() => {
        this.loadSummary(this.props.reports);
      });
    }
  }

  componentDidMount() {
    if (this.props.reports) {
      this.loadMeals().then(() => {
        this.loadSummary(this.props.reports);
      });
    }

    this.loadPlanTypes();
    this.loadMeals();
  }

  handleFilterChange(val) {
    this.setState(
      {
        selected: val,
      },
      () => {
        this.loadSummary(this.props.reports);
      }
    );
  }

  render() {
    return (
      <>
        <Card className="border shadow">
          <CardHeader className="p-2">
            <h4 className="m-0">Meals Quantities In Live Orders</h4>
          </CardHeader>
          <CardSelectorHeader
            sticky={false}
            compact
            value={this.state.selected}
            loading={this.state.planTypesLoading}
            onChange={this.handleFilterChange.bind(this)}
            options={this.state.selectorOptions}
          ></CardSelectorHeader>
          <CardBody className="p-0">
            <div className="table-responsive">
              <BootstrapTable
                condensed
                pagination={paginationFactory({
                  withFirstAndLast: false,
                  sizePerPage: 100,
                  showTotal: true,
                  alwaysShowAllBtns: true,
                  paginationTotalRenderer: (from, to, size) => (
                    <div className="pt-2 pb-2 pl-sm-2 text-center text-sm-left react-bootstrap-table-pagination-total">
                      <span className="">
                        <small>
                          {from}-{to} of {size} meals
                        </small>
                      </span>
                    </div>
                  ),
                  sizePerPageRenderer: ({
                    options,
                    currSizePerPage,
                    onSizePerPageChange,
                  }) => <div></div>,
                  pageListRenderer: ({ pages, onPageChange }) => {
                    // just exclude <, <<, >>, >
                    const pageWithoutIndication = pages.filter(
                      (p) => typeof p.page === "string"
                    );
                    return (
                      <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                        <div className="text-center text-sm-right pt-2 pb-2 pr-sm-2">
                          {pageWithoutIndication.map((p) => (
                            <Button
                              className="btn btn-sm btn-round"
                              color="secondary"
                              outline
                              onClick={() => onPageChange(p.page)}
                            >
                              {p.page}
                            </Button>
                          ))}
                        </div>
                      </div>
                    );
                  },
                })}
                remote={{
                  filter: true,
                  pagination: false,
                  sort: false,
                  cellEdit: false,
                }}
                columns={this.state.mealPerformanceColumns}
                data={this.state.filteredResults}
                bootstrap4
                keyField="mealID"
                defaultSorted={[
                  {
                    dataField: "quantity", // if dataField is not match to any column you defined, it will be ignored.
                    order: "desc", // desc or asc
                  },
                ]}
              />
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default withRouter(MealsOrderedWidget);
