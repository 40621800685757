import ArrowText from "components/ArrowText";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  Badge,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import Card from "reactstrap/lib/Card";
import moment from "moment";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import _ from "underscore";
import TaskDetailDrawer from "components/Drawers/TaskDetailDrawer";
import TaskCreateDrawer from "components/Drawers/TaskCreateDrawer";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import AutomatedEmailWidget from "components/Widgets/AutomatedEmailWidget";
import ConversationWidget from "components/Widgets/ConversationWidget";

class LeadCommunicationTabView extends React.Component {
  state = {
    expand: false,
    taskDetail: null,
  };

  loadStore() {
    const {
      match: { params },
    } = this.props;

    APIV2.getStoreByID(params.storeID)
      .then(
        (data) => {
          if (data?.data?.store) {
            console.log(data.data.store);

            this.setState({
              store: data.data.store,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    if (this.props.match?.params?.storeID) {
      this.loadStore();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match?.params?.storeID != prevProps?.match?.params?.storeID &&
      this.props.match?.params?.storeID
    ) {
      this.loadStore();
    }
  }

  render() {
    return (
      <>
        {this.props.loading ? (
          <>
            <Card className="mb-4 shadow border">
              <CardBody className="bg-transparent py-5 text-center">
                <Spinner size="lg" color="medium"></Spinner>
              </CardBody>
            </Card>
          </>
        ) : (
          <>
            <ConversationWidget
              loading={this.props.loading}
              lead={this.props.lead}
              store={this.state.store}
            ></ConversationWidget>
            <AutomatedEmailWidget
              loading={this.props.loading}
              lead={this.props.lead}
            ></AutomatedEmailWidget>
          </>
        )}
      </>
    );
  }
}

export default withRouter(LeadCommunicationTabView);
