import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import API from "lib/API";
import BillingAccountRow from "components/BillingAccountRow";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import CreateBillingAccountModal from "components/CreateBillingAccountModal";
import APIV2 from "lib/APIV2";
import PageHeaderV2 from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";

class BillingAccountList extends React.Component {
  state = {
    accounts: [],
    loading: true,
  };

  loadBillingAccounts() {
    APIV2.getBillingAccounts()
      .then(
        (data) => {
          if (data && data.data && data.data.billingAccounts) {
            this.setState({
              accounts: data.data.billingAccounts,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    this.loadBillingAccounts();

    PubSub.subscribe(Event.BILLING_ACCOUNT.DELETED, (data) => {
      let accounts = filter(this.state.accounts, (a) => {
        return a._id != data.id;
      });

      this.setState({
        accounts: accounts,
      });
    });

    PubSub.subscribe(Event.BILLING_ACCOUNT.CREATED, (account) => {
      let accounts = this.state.accounts;

      accounts.push(account);

      this.setState({
        accounts: accounts,
      });
    });

    PubSub.subscribe(Event.BILLING_ACCOUNT.MODIFIED, (account) => {
      let accounts = this.state.accounts;
      let idx = findIndex(accounts, { _id: account._id });

      accounts[idx] = account;

      this.setState({
        accounts: accounts,
      });
    });
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeaderV2
            title="Billing Accounts"
            showBackButton={false}
            sticky={true}
            primaryAction={{
              name: <>Add</>,
              onClick: () => {
                PubSub.publish(Event.BILLING_ACCOUNT.CREATE_OPEN);
              },
            }}
          />
          {/* Table */}
          <div
            style={{ marginLeft: -15, marginRight: -15 }}
            className="bg-white border-bottom  mt--4"
          >
            <Table className="align-items-center table-flush mb-0" responsive>
              <thead className="thead-light">
                <tr>
                  <th
                    scope="col"
                    className="pr-0"
                    style={{ width: "30px" }}
                  ></th>
                  <th scope="col">Name</th>

                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {this.state.loading ? (
                  <tr>
                    <td colSpan="4">Loading...</td>
                  </tr>
                ) : null}
                {!this.state.loading && !this.state.accounts.length ? (
                  <tr>
                    <td colSpan="4">No billing accounts.</td>
                  </tr>
                ) : null}
                {this.state.accounts.map((account, i) => (
                  <BillingAccountRow
                    key={i}
                    account={account}
                  ></BillingAccountRow>
                ))}
              </tbody>
            </Table>
          </div>
        </Container>
        <CreateBillingAccountModal></CreateBillingAccountModal>
      </>
    );
  }
}

export default BillingAccountList;
