import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
  Badge,
  Spinner,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import StoreView from "views/examples/StoreView";
import NumberFormat from "react-number-format";
import ModifyStoreModal from "./ModifyStoreModal";
import APIV2 from "lib/APIV2";
import { withRouter } from "react-router";
import {
  CheckPicker,
  DatePicker,
  Dropdown,
  Popover,
  SelectPicker,
  Whisper,
} from "rsuite";
import FranchisePartnerDrawer from "./Drawers/FranchisePartnerDrawer";
import StringUtils from "../lib/StringUtils";
import LinkUtils from "../lib/LinkUtils";
import swal from "sweetalert";
import moment from "moment";

class StoreViewAboutTab extends React.Component {
  state = {
    open: false,
    twilioNumber: {
      value: "",
    },
    phone: {
      value: "",
    },
    retailGoal: {
      value: "",
    },
    level: "",
  };

  constructor(props) {
    super(props);

    this.menuRef = React.createRef();
  }

  loadStore() {
    const {
      match: { params },
    } = this.props;

    APIV2.getStoreByID(params.storeID)
      .then(
        (data) => {
          if (data && data.data && data.data.store) {
            this.setState({
              store: data.data.store,
              modifySquareLocation: data.data.store?.squareLocation?.id
                ? data.data.store?.squareLocation?.id
                : "",
            });
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  getSquareLocations() {
    const {
      match: { params },
    } = this.props;

    APIV2.getSquareLocations().then(
      (data) => {
        if (data && data.data && data.data.locations) {
          let locations = data.data.locations.map((l) => {
            return {
              value: l.id,
              label: l.name + " (#" + l.id + ")",
              location: l,
            };
          });

          this.setState({
            squareLocations: locations,
          });
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  setValue(key, value, modifyKey = "") {
    return new Promise((resolve, reject) => {
      this.setState({
        valSubmitting: true,
      });

      // TODO: implement NSO update here

      APIV2.modifyStoreParameter(this.state?.store?._id, key, value)
        .then(
          (data) => {
            PubSub.publish(Event.STORE.MODIFIED, data.data.store);

            this.setState({
              [modifyKey]: false,
            });

            resolve(true);
          },
          (e) => {
            window.alert(
              "Unable to modify: " + e?.message ?? "error occurred."
            );

            reject(false);
          }
        )
        .finally(() => {
          this.setState({
            valSubmitting: false,
          });
        });
    });
  }

  setDisplayCityState() {
    this.setState({
      displaySubmitting: true,
    });

    // TODO: implement NSO update here

    APIV2.modifyStoreParameter(
      this.state?.store?._id,
      "displayCity",
      this.state.displayCity
    ).then(
      (data1) => {
        APIV2.modifyStoreParameter(
          this.state.store?._id,
          "displayState",
          this.state.displayState
        )
          .then(
            (data) => {
              PubSub.publish(Event.STORE.MODIFIED, data.data.store);

              this.setState({
                modifyDisplay: false,
                store: data.data.store,
                displayCity: "",
                displayState: "",
              });
            },
            (e) => {
              window.alert(
                "Unable to modify marketing city & state: " + e?.message ??
                  "error occurred."
              );
            }
          )
          .finally(() => {
            this.setState({
              displaySubmitting: false,
            });
          });
      },
      (e) => {
        this.setState({
          displaySubmitting: false,
        });

        window.alert(
          "Unable to modify marketing city & state: " + e?.message ??
            "error occurred."
        );
      }
    );
  }

  componentDidMount() {
    this.loadStore();
    this.getSquareLocations();

    PubSub.subscribe(Event.STORE.DELETED, (data) => {
      this.props.history.push("/frandev/stores");
    });

    PubSub.subscribe(Event.STORE.MODIFIED, (s) => {
      this.setState({
        store: s,
      });
    });
  }

  render() {
    return (
      <>
        <>
          {this.state.store?.type == "retail" ? (
            <Card className="mt-3">
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="">
                    <h3 className="mb-0">Link To Square POS</h3>
                    <p className="m-0" style={{ lineHeight: 1.3 }}>
                      <small>Link this store to a Square POS Location</small>
                    </p>
                  </Col>
                  <Col xs="auto">
                    {this.state.modifySquare ? (
                      <>
                        <Button
                          onClick={() => {
                            this.setState({
                              modifySquare: false,
                            });
                          }}
                          className="btn-sm btn mr-2"
                          color="secondary"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            this.setState({ squareSubmitting: true });

                            APIV2.setSquareLocationForStore(
                              this.state.store._id,
                              this.state.modifySquareLocation
                            )
                              .then(
                                (data) => {
                                  let store = data.data.store;

                                  PubSub.publish(Event.STORE.MODIFIED, store);

                                  this.setState({
                                    modifySquare: false,
                                  });
                                },
                                (e) => {
                                  console.error(e);
                                }
                              )
                              .finally(() => {
                                this.setState({
                                  squareSubmitting: false,
                                });
                              });
                          }}
                          className="btn-sm btn"
                          color="primary"
                          disabled={this.state.squareSubmitting}
                        >
                          {this.state.squareSubmitting ? (
                            <>
                              <Spinner color="white" size="sm" />
                            </>
                          ) : (
                            <>Save</>
                          )}
                        </Button>
                      </>
                    ) : (
                      <>
                        {this.state.store?.squareLocation ? (
                          <>
                            <a
                              href={`https://squareup.com/dashboard/locations/${this.state.store?.squareLocation?.id}`}
                              target="_blank"
                            >
                              <Button
                                className="btn-sm btn mr-2"
                                color="secondary"
                                outline
                              >
                                View{" "}
                                <i className="mdi mdi-open-in-new pl-1"></i>
                              </Button>
                            </a>
                            <Button
                              onClick={() => {
                                this.setState({
                                  modifySquare: true,
                                });
                              }}
                              className="btn-sm btn"
                              color="secondary"
                              outline
                            >
                              Modify
                            </Button>
                          </>
                        ) : (
                          <Button
                            onClick={() => {
                              this.setState({
                                modifySquare: true,
                              });
                            }}
                            className="btn-sm btn"
                            color="primary"
                          >
                            Add
                          </Button>
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              {this.state.modifySquare ? (
                <CardBody>
                  <SelectPicker
                    data={this.state.squareLocations}
                    value={this.state.modifySquareLocation}
                    block
                    placeholder="Select Location..."
                    onChange={(v) => {
                      this.setState({ modifySquareLocation: v });
                    }}
                    placement="autoVerticalStart"
                  ></SelectPicker>
                </CardBody>
              ) : (
                <CardBody>
                  {this.state.store?.squareLocation ? (
                    <>
                      Linked to the{" "}
                      <span className="font-weight-bold">
                        {this.state.store?.squareLocation?.name} (#
                        {this.state.store?.squareLocation?.id})
                      </span>
                      &nbsp;Square location.
                    </>
                  ) : (
                    <>Click Add to link this store to a Square POS Location.</>
                  )}
                </CardBody>
              )}
            </Card>
          ) : null}
          <Card className="mt-3">
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="">
                  <h3 className="mb-0">Facebook Page API</h3>
                  <p className="m-0" style={{ lineHeight: 1.3 }}>
                    <small>Link this store to its Facebook Page</small>
                  </p>
                </Col>
                <Col xs="auto">
                  {this.state.modifyFacebook ? (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            modifyFacebook: false,
                          });
                        }}
                        className="btn-sm btn mr-2"
                        color="secondary"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.setValue(
                            "facebookPageID",
                            this.state.modifyFacebookPage,
                            "modifyFacebookPage"
                          )
                            .then(() => {
                              this.setState({
                                modifyFacebook: false,
                              });
                            })
                            .catch(() => {});
                        }}
                        className="btn-sm btn"
                        color="primary"
                        disabled={this.state.valSubmitting}
                      >
                        {this.state.valSubmitting ? (
                          <>
                            <Spinner color="white" size="sm" />
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  ) : (
                    <>
                      {this.state.store?.facebookPageID ? (
                        <>
                          <Button
                            size="sm"
                            outline
                            color="dark"
                            className="border-0 btn-icon-only"
                            onClick={() => {
                              LinkUtils.openInNewTab(
                                "https://business.facebook.com/latest/settings/business_assets?business_id=991177727666131"
                              );
                            }}
                          >
                            <i className="mdi mdi-information-outline"></i>
                          </Button>
                          <Button
                            onClick={() => {
                              this.setState({
                                modifyFacebook: true,
                                modifyFacebookPage:
                                  this.state.store?.facebookPageID,
                              });
                            }}
                            className="btn-sm btn"
                            color="secondary"
                            outline
                          >
                            Modify
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            size="sm"
                            outline
                            color="dark"
                            className="border-0 btn-icon-only"
                            onClick={() => {
                              LinkUtils.openInNewTab(
                                "https://business.facebook.com/latest/settings/business_assets?business_id=991177727666131"
                              );
                            }}
                          >
                            <i className="mdi mdi-information-outline"></i>
                          </Button>
                          <Button
                            onClick={() => {
                              this.setState({
                                modifyFacebook: true,
                              });
                            }}
                            className="btn-sm btn"
                            color="primary"
                          >
                            Add
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </CardHeader>
            {this.state.modifyFacebook ? (
              <CardBody>
                <h4 className="m-0">Facebook Page ID</h4>
                <p className="small text-muted" style={{ lineHeight: 1.2 }}>
                  Page ID from inside Facebook Business Manager
                </p>
                <Input
                  bsSize="small"
                  name="modifyFacebookPage"
                  value={this.state.modifyFacebookPage}
                  onChange={this.handleInputChange.bind(this)}
                  placeholder="Facebook Page ID"
                ></Input>
              </CardBody>
            ) : (
              <CardBody>
                {this.state.store?.facebookPageID ? (
                  <>Linked to Page ID: {this.state.store?.facebookPageID}</>
                ) : (
                  <>Click Add to link this store to a Facebook page.</>
                )}
              </CardBody>
            )}
          </Card>
          <Card className="mt-3">
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="">
                  <h3 className="mb-0">Instagram API</h3>
                  <p className="m-0" style={{ lineHeight: 1.3 }}>
                    <small>Link this store to its Instagram account</small>
                  </p>
                </Col>
                <Col xs="auto">
                  {this.state.modifyInstagram ? (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            modifyInstagram: false,
                          });
                        }}
                        className="btn-sm btn mr-2"
                        color="secondary"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.setValue(
                            "instagramAccountID",
                            this.state.instagramAccount,
                            "instagramAccount"
                          )
                            .then(() => {
                              this.setState({
                                modifyInstagram: false,
                              });
                            })
                            .catch(() => {});
                        }}
                        className="btn-sm btn"
                        color="primary"
                        disabled={this.state.valSubmitting}
                      >
                        {this.state.valSubmitting ? (
                          <>
                            <Spinner color="white" size="sm" />
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  ) : (
                    <>
                      {this.state.store?.instagramAccountID ? (
                        <>
                          <Button
                            size="sm"
                            outline
                            color="dark"
                            className="border-0 btn-icon-only"
                            onClick={() => {
                              LinkUtils.openInNewTab(
                                "https://business.facebook.com/latest/settings/business_assets?business_id=991177727666131"
                              );
                            }}
                          >
                            <i className="mdi mdi-information-outline"></i>
                          </Button>
                          <Button
                            onClick={() => {
                              this.setState({
                                modifyInstagram: true,
                                instagramAccount:
                                  this.state.store?.instagramAccountID,
                              });
                            }}
                            className="btn-sm btn"
                            color="secondary"
                            outline
                          >
                            Modify
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            size="sm"
                            outline
                            color="dark"
                            className="border-0 btn-icon-only"
                            onClick={() => {
                              LinkUtils.openInNewTab(
                                "https://business.facebook.com/latest/settings/business_assets?business_id=991177727666131"
                              );
                            }}
                          >
                            <i className="mdi mdi-information-outline"></i>
                          </Button>
                          <Button
                            onClick={() => {
                              this.setState({
                                modifyInstagram: true,
                              });
                            }}
                            className="btn-sm btn"
                            color="primary"
                          >
                            Add
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </CardHeader>
            {this.state.modifyInstagram ? (
              <CardBody>
                <h4 className="m-0">Instagram Account ID</h4>
                <p className="small text-muted" style={{ lineHeight: 1.2 }}>
                  Account ID from inside Facebook Business Manager
                </p>
                <Input
                  bsSize="small"
                  name="instagramAccount"
                  value={this.state.instagramAccount}
                  onChange={this.handleInputChange.bind(this)}
                  placeholder="Instagram Account ID"
                ></Input>
              </CardBody>
            ) : (
              <CardBody>
                {this.state.store?.instagramAccountID ? (
                  <>
                    Linked to Account ID: {this.state.store?.instagramAccountID}
                  </>
                ) : (
                  <>Click Add to link this store to an Instagram account.</>
                )}
              </CardBody>
            )}
          </Card>
          <Card className="mt-3">
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="">
                  <h3 className="mb-0">Google Maps Places API</h3>
                  <p className="m-0" style={{ lineHeight: 1.3 }}>
                    <small>Link this store to its Google Maps listing</small>
                  </p>
                </Col>
                <Col xs="auto">
                  {this.state.modifyPlaceID ? (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            modifyPlaceID: false,
                          });
                        }}
                        className="btn-sm btn mr-2"
                        color="secondary"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.setValue(
                            "googleMapsPlaceID",
                            this.state.placeID,
                            "placeID"
                          )
                            .then(() => {
                              this.setState({
                                modifyPlaceID: false,
                              });
                            })
                            .catch(() => {});
                        }}
                        className="btn-sm btn"
                        color="primary"
                        disabled={this.state.valSubmitting}
                      >
                        {this.state.valSubmitting ? (
                          <>
                            <Spinner color="white" size="sm" />
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  ) : (
                    <>
                      {this.state.store?.googleMapsPlaceID ? (
                        <>
                          <Button
                            size="sm"
                            outline
                            color="dark"
                            className="border-0 btn-icon-only"
                            onClick={() => {
                              LinkUtils.openInNewTab(
                                "https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder"
                              );
                            }}
                          >
                            <i className="mdi mdi-information-outline"></i>
                          </Button>
                          <Button
                            onClick={() => {
                              this.setState({
                                modifyPlaceID: true,
                                placeID: this.state.store?.googleMapsPlaceID,
                              });
                            }}
                            className="btn-sm btn"
                            color="secondary"
                            outline
                          >
                            Modify
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            size="sm"
                            outline
                            color="dark"
                            className="border-0 btn-icon-only"
                            onClick={() => {
                              LinkUtils.openInNewTab(
                                "https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder"
                              );
                            }}
                          >
                            <i className="mdi mdi-information-outline"></i>
                          </Button>
                          <Button
                            onClick={() => {
                              this.setState({
                                modifyPlaceID: true,
                              });
                            }}
                            className="btn-sm btn"
                            color="primary"
                          >
                            Add
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </CardHeader>
            {this.state.modifyPlaceID ? (
              <CardBody>
                <h4 className="m-0">Google Maps Place ID</h4>
                <p className="small text-muted" style={{ lineHeight: 1.2 }}>
                  Place ID from Google Maps
                </p>
                <Input
                  bsSize="small"
                  name="placeID"
                  value={this.state.placeID}
                  onChange={this.handleInputChange.bind(this)}
                  placeholder="Place ID"
                ></Input>
              </CardBody>
            ) : (
              <CardBody>
                {this.state.store?.googleMapsPlaceID ? (
                  <>
                    Linked to Google Maps Place ID:{" "}
                    {this.state.store?.googleMapsPlaceID}
                  </>
                ) : (
                  <>Click Add to link this store to a Google Maps Place.</>
                )}
              </CardBody>
            )}
          </Card>
          <Card className="mt-3">
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="">
                  <h3 className="mb-0">Google Maps Reviews</h3>
                  <p className="m-0" style={{ lineHeight: 1.3 }}>
                    <small>
                      Link this store to its Google Maps listing for embedding
                      reviews
                    </small>
                  </p>
                </Col>
                <Col xs="auto">
                  {this.state.modifyReviewURL ? (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            modifyReviewURL: false,
                          });
                        }}
                        className="btn-sm btn mr-2"
                        color="secondary"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.setValue(
                            "googleMapsReviewURL",
                            this.state.reviewURL,
                            "reviewURL"
                          )
                            .then(() => {
                              this.setState({
                                modifyReviewURL: false,
                              });
                            })
                            .catch(() => {});
                        }}
                        className="btn-sm btn"
                        color="primary"
                        disabled={this.state.valSubmitting}
                      >
                        {this.state.valSubmitting ? (
                          <>
                            <Spinner color="white" size="sm" />
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  ) : (
                    <>
                      {this.state.store?.googleMapsReviewURL ? (
                        <>
                          <Button
                            size="sm"
                            outline
                            color="dark"
                            className="border-0 btn-icon-only"
                            onClick={() => {
                              LinkUtils.openInNewTab(
                                "https://support.google.com/business/answer/3474122?hl=en#create_and_share_a_link"
                              );
                            }}
                          >
                            <i className="mdi mdi-information-outline"></i>
                          </Button>
                          <Button
                            onClick={() => {
                              this.setState({
                                modifyPlaceID: true,
                                placeID: this.state.store?.googleMapsReviewURL,
                              });
                            }}
                            className="btn-sm btn"
                            color="secondary"
                            outline
                          >
                            Modify
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            size="sm"
                            outline
                            color="dark"
                            className="border-0 btn-icon-only"
                            onClick={() => {
                              LinkUtils.openInNewTab(
                                "https://support.google.com/business/answer/3474122?hl=en#create_and_share_a_link"
                              );
                            }}
                          >
                            <i className="mdi mdi-information-outline"></i>
                          </Button>
                          <Button
                            onClick={() => {
                              this.setState({
                                modifyReviewURL: true,
                              });
                            }}
                            className="btn-sm btn"
                            color="primary"
                          >
                            Add
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </CardHeader>
            {this.state.modifyReviewURL ? (
              <CardBody>
                <h4 className="m-0">Google Maps Review URL</h4>
                <p className="small text-muted" style={{ lineHeight: 1.2 }}>
                  Review URL From Google Business Profile Manager
                </p>
                <Input
                  bsSize="small"
                  name="reviewURL"
                  value={this.state.reviewURL}
                  onChange={this.handleInputChange.bind(this)}
                  placeholder="Review URL"
                ></Input>
              </CardBody>
            ) : (
              <CardBody>
                {this.state.store?.googleMapsReviewURL ? (
                  <>
                    Google Maps Review URL:{" "}
                    {this.state.store?.googleMapsReviewURL}
                  </>
                ) : (
                  <>Click Add to link this store to Google Maps Reviews.</>
                )}
              </CardBody>
            )}
          </Card>
        </>
      </>
    );
  }
}

export default withRouter(StoreViewAboutTab);
