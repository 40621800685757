import ArrowText from "components/ArrowText";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  Badge,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import Card from "reactstrap/lib/Card";
import moment from "moment";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import _ from "underscore";
import TaskDetailDrawer from "components/Drawers/TaskDetailDrawer";
import TaskCreateDrawer from "components/Drawers/TaskCreateDrawer";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";

class AffiliateAllTasksWidget extends React.Component {
  state = {
    expand: false,
    taskDetail: null,
    active: {
      id: "All Incomplete",
      name: "All Incomplete",
    },
  };

  componentDidMount() {
    this.handleTabChange(this.state.active, true);

    PubSub.subscribe(Event.TASK.CREATED, () => {
      this.handleTabChange(this.state.active, true);
    });

    PubSub.subscribe(Event.TASK.MODIFIED, (order) => {
      this.handleTabChange(this.state.active, true);

      if (this?.state?.taskDetail?._id == order?._id) {
        this.setState({
          taskDetail: order,
        });
      }
    });
  }

  loadTasks(page = 1, count = 2000, filters = null, orderBy) {
    this.setState({
      loadingTasks: true,
    });

    APIV2.getTasksForStore(
      this.props.match?.params?.storeID,
      page,
      count,
      filters,
      orderBy
    )
      .then(
        (data) => {
          if (data && data.data) {
            let tasks = [];

            if (data.data.tasks.length) {
              tasks = data.data.tasks;
            }

            this.setState({
              tasks,
              pagination: data.data.pagination,
              result: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the tasks. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingTasks: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.affiliate != this.props?.affiliate ||
      prevProps?.storeID != this.props?.storeID
    ) {
      this.handleTabChange(this.state.active, true);
    }
  }

  taskDetailDrawer(task) {
    this.setState({
      taskDetail: task,
      detailOpen: true,
    });
  }

  handleTabChange(option, force = false) {
    let filters = null;
    let orderBy = {
      dueDate: 1,
    };

    if (!this.props.affiliate) {
      return;
    }

    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    if (option.id == "All Upcoming") {
      let mom = moment();

      mom.add(7, "days");

      filters = {
        status: { $in: ["Not Started", "In Progress"] },
        dueDate: {
          $lte: { $date: mom.toDate().toISOString() },
        },
        affiliateID: this.props?.affiliate?._id,
      };
      orderBy = { dueDate: 1, priority: -1, status: -1 };
    } else if (option.id == "Lead Engagement") {
      filters = {
        type: "Lead Engagement",
        status: { $in: ["Not Started", "In Progress"] },
        affiliateID: this.props?.affiliate?._id,
      };
      orderBy = { dueDate: 1, priority: -1, status: -1 };
    } else if (option.id == "Member Engagement") {
      filters = {
        type: "Member Engagement",
        status: { $in: ["Not Started", "In Progress"] },
        affiliateID: this.props?.affiliate?._id,
      };
      orderBy = { dueDate: 1, priority: -1, status: -1 };
    } else if (option.id == "Billing") {
      filters = {
        type: "Billing",
        status: { $in: ["Not Started", "In Progress"] },
        affiliateID: this.props?.affiliate?._id,
      };
      orderBy = { dueDate: 1, priority: -1, status: -1 };
    } else if (option.id == "Fulfillment") {
      filters = {
        type: "Fulfillment",
        status: { $in: ["Not Started", "In Progress"] },
        affiliateID: this.props?.affiliate?._id,
      };
      orderBy = { dueDate: 1, priority: -1, status: -1 };
    } else if (option.id == "All Incomplete") {
      filters = {
        status: { $in: ["Not Started", "In Progress"] },
        affiliateID: this.props?.affiliate?._id,
      };
      orderBy = { dueDate: 1, priority: -1, status: -1 };
    } else if (option.id == "All Completed") {
      filters = {
        status: "Completed",
        affiliateID: this.props?.affiliate?._id,
      };
      orderBy = { completedAt: -1 };
    }

    this.setState({
      filters,
      orderBy,
      active: option,
    });

    this.loadTasks(1, this.state.count, filters);
  }

  formatDueDate(dateString, status) {
    if (!dateString) {
      return "--";
    }

    console.log(dateString);

    let now = moment();
    let due = moment(dateString);

    if (now.toDate() > due.toDate() && status != "Completed") {
      return (
        <Badge color="" className="bg-yellow text-dark">
          {due.format("MMM DD, YYYY")}
        </Badge>
      );
    }

    return due.format("MMM DD, YYYY");
  }

  formatStatus(status) {
    if (!status) {
      return "--";
    }

    if (status == "Not Started") {
      return (
        <Badge color="" className="bg-yellow text-dark">
          {status}
        </Badge>
      );
    }

    if (status == "In Progress") {
      return <Badge color="info">{status}</Badge>;
    }

    if (status == "Completed") {
      return <Badge color="light">{status}</Badge>;
    }
  }

  taskRow(c) {
    return (
      <Row className="align-items-center">
        <Col xs="6" sm="6" md="4">
          <h4 className="mb-0">{c.subject}</h4>
          <p className="mb-0" style={{ fontSize: "13px" }}>
            {c.type}
          </p>
        </Col>
        <Col xs="6" sm="6" md="2">
          {this.formatDueDate(c.dueDate, c.status)}
        </Col>
        <Col xs="6" sm="6" md="3">
          {c.priority == "High" ? (
            <i className="mdi mdi-exclamation-thick text-danger pr-2"></i>
          ) : null}
          {c.priority}
        </Col>
        <Col xs="6" sm="6" md="3">
          {this.formatStatus(c.status)}
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <>
        {this.props.loading ? (
          <>
            <Card className="mb-4 shadow border">
              <CardBody className="bg-transparent py-5 text-center">
                <Spinner size="lg" color="medium"></Spinner>
              </CardBody>
            </Card>
          </>
        ) : (
          <>
            <Card className="mb-4 border shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <Col>
                    <h3 className="text-dark mb-0">Tasks</h3>
                  </Col>
                  <Col xs="auto">
                    {/*<Button
                      size="sm"
                      color="secondary"
                      outline
                      onClick={() => {
                        this.setState({
                          modified: true,
                          modifyImage: true,
                          imageURL: "",
                        });
                      }}
                    >
                      Mark All <i className="mdi mdi-chevron-down"></i>
                    </Button>*/}
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => {
                        this.setState({ openCreate: true });
                      }}
                    >
                      New Task
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardSelectorHeader
                compact={true}
                value={this.state.active}
                options={[
                  {
                    name: "All Incomplete",
                    id: "All Incomplete",
                  },
                  {
                    name: "All Completed",
                    id: "All Completed",
                  },
                ]}
                onChange={this.handleTabChange.bind(this)}
              ></CardSelectorHeader>
              <CardBody className="p-0">
                {this.state?.loading && (
                  <div className="px-3 py-5 text-center">
                    <Spinner size="sm" color="dark"></Spinner>
                  </div>
                )}
                {!this.state?.tasks?.length && !this.state.loading && (
                  <div className="px-3 py-5">
                    <Row>
                      <Col xs="12">
                        <h3 className="m-0">No tasks available.</h3>
                        <p>
                          {this.props?.affiliate?.name
                            ? StringUtils.getFirstName(
                                this.props?.affiliate?.name
                              )
                            : "This affiliate"}{" "}
                          does not have any tasks.
                        </p>
                      </Col>
                    </Row>
                  </div>
                )}

                {this.state?.tasks?.length > 0 && (
                  <div className="px-4 py-2 bg-superlight border-bottom">
                    <Row className="align-items-center">
                      <Col xs="6" sm="6" md="4">
                        <h4 className="mb-0">Subject</h4>
                      </Col>

                      <Col xs="6" sm="6" md="2">
                        <h4 className="mb-0">Due Date</h4>
                      </Col>
                      <Col xs="6" sm="6" md="3">
                        <h4 className="mb-0">Priority</h4>
                      </Col>
                      <Col xs="6" sm="6" md="3">
                        <h4 className="mb-0">Status</h4>
                      </Col>
                    </Row>
                  </div>
                )}
                {this.state?.tasks?.map((task, i) => (
                  <div key={task?._id}>
                    <div
                      className="px-4 py-3 cursor-pointer bg-superlight--hover"
                      onClick={() => {
                        this.taskDetailDrawer(task);
                      }}
                    >
                      {this.taskRow(task)}
                    </div>
                    {i != this.state?.tasks?.length - 1 && (
                      <hr className="m-0"></hr>
                    )}
                  </div>
                ))}
              </CardBody>
            </Card>
          </>
        )}
        <TaskDetailDrawer
          task={this.state.taskDetail}
          open={this.state.detailOpen}
          onClose={() => {
            this.setState({
              taskDetail: null,
              detailOpen: false,
            });
          }}
        ></TaskDetailDrawer>
        <TaskCreateDrawer
          affiliate={this.props.affiliate}
          store={this.props.store}
          open={this.state.openCreate}
          onClose={() => {
            this.setState({ openCreate: false });
          }}
        ></TaskCreateDrawer>
      </>
    );
  }
}

export default withRouter(AffiliateAllTasksWidget);
