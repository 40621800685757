import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";

class ReferralCreateDrawer extends React.Component {
  state = {
    dueDate: moment().toDate(),
    subject: "",
    description: "",
    relatedToType: "Member",
    type: "",
    priority: "Normal",
    status: "Not Started",
    assignedTo: null,
    timeToContact: "",
    customers: [],
  };

  componentDidUpdate(prevProps) {}

  componentDidMount() {}

  toggleModal() {
    this.setState({
      relatedCustomer: null,
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  create() {
    this.setState({ submitting: true });

    APIV2.createCustomerReferralReceived(
      this?.props?.customer?.storeID,
      this?.props?.customer?._id,
      this.state.relatedCustomer?._id
    )
      .then(
        (data) => {
          this.toggleModal();

          PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create referral - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  performSearch() {
    console.log(this.state.search);

    let promise = null;

    APIV2.simpleSearchCustomers(this.state.search)
      .then(
        (data) => {
          this.setState({
            customers: data.data.customers,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          searchLoading: false,
        });
      });
  }

  clearSearch() {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);

      this.setState({
        searchTimeout: null,
      });
    }

    this.setState({
      search: "",
    });
  }

  handleSearchChange(e) {
    const { name, value } = e.target;

    this.setState({ modified: true });

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    if (value) {
      this.setState({
        searchLoading: true,
      });
    }

    if (value && value.length >= 3) {
      let timeout = setTimeout(() => {
        this.performSearch();
      }, 1200);

      this.setState({
        searchTimeout: timeout,
      });
    }

    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              New Referral
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={this.state.submitting || !this.state.relatedCustomer}
                color="primary"
                onClick={this.create.bind(this)}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  "Add"
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}

            <FormGroup>
              <h5>Referrer</h5>
              {this.state.relatedCustomer ? (
                <>
                  <div className="form-control form-control-sm text-dark">
                    {this.state.relatedCustomer?.name
                      ? this.state.relatedCustomer?.name
                      : this.state.relatedCustomer?.email}
                  </div>
                  <div className="text-right mt-2">
                    <Button
                      onClick={() => {
                        this.setState({
                          relatedCustomer: null,
                          search: "",
                        });
                      }}
                      size="sm"
                      color="danger"
                      outline
                    >
                      Remove
                    </Button>
                  </div>
                </>
              ) : (
                <Whisper
                  placement="autoVerticalStart"
                  trigger="focus"
                  enterable
                  speaker={
                    <Popover
                      style={{ width: "calc(100% - 20px)", maxWidth: 450 }}
                      className="p-0"
                    >
                      <div className="border-bottom p-2">
                        <h5 className="mb-1">{this.state.relatedToType}s</h5>
                        <div className="mb-1">
                          {!this.state.search ? (
                            <>
                              Search for a{" "}
                              {this.state.relatedToType.toLowerCase()}.
                            </>
                          ) : null}
                          {this.state.search && this.state.searchLoading ? (
                            <>Loading results...</>
                          ) : null}
                          {this.state.search &&
                          !this.state.searchLoading &&
                          !this.state.customers?.length ? (
                            <>
                              No {this.state.relatedToType.toLowerCase()}s
                              matching your search.
                            </>
                          ) : null}
                          {this.state.search &&
                          this.state.customers?.length &&
                          !this.state.searchLoading ? (
                            <>{this.state.customers?.length} results</>
                          ) : null}
                        </div>
                      </div>
                      <div
                        className=""
                        style={{ maxHeight: "400px", overflowY: "scroll" }}
                      >
                        {!this.state.searchLoading &&
                          this.state.customers?.map((c, i) => (
                            <>
                              <div
                                className="cursor-pointer bg-superlight--hover px-2 py-1"
                                key={c?._id}
                                onClick={() => {
                                  this.setState({
                                    relatedCustomer: c,
                                  });
                                }}
                              >
                                <h4 className="m-0">
                                  {c?.name ? c.name : c.email}
                                </h4>
                                <p className="m-0" style={{ lineHeight: 1.2 }}>
                                  {this.state.relatedToType == "Member" ? (
                                    <small>{c.email}</small>
                                  ) : null}
                                  {this.state.relatedToType == "Lead" ? (
                                    <small>
                                      {c.name
                                        ? c.email
                                        : `Source: ${c.initialSource}`}
                                    </small>
                                  ) : null}
                                  {this.state.relatedToType == "Affiliate" ? (
                                    <small>{c.name}</small>
                                  ) : null}
                                </p>
                              </div>
                              {i != this.state.customers?.length - 1 ? (
                                <hr className="m-0"></hr>
                              ) : null}
                            </>
                          ))}
                        {this.state.searchLoading ? (
                          <>
                            <div className="text-center py-4">
                              <Spinner size="sm" color="dark"></Spinner>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </Popover>
                  }
                >
                  <Input
                    bsSize="sm"
                    type="text"
                    name="search"
                    placeholder={`Search ${this.state.relatedToType?.toLowerCase()}s...`}
                    value={this.state.search}
                    onChange={this.handleSearchChange.bind(this)}
                  ></Input>
                </Whisper>
              )}
            </FormGroup>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(ReferralCreateDrawer);
