import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Alert,
} from "reactstrap";
import API from "lib/API";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import User from "lib/Model/User";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import APIV2 from "lib/APIV2";
import { Link } from "react-router-dom/cjs/react-router-dom";

let logo = require("assets/img/brand/storefront_wordmark.svg");

class ForgotPassword extends React.Component {
  state = {
    submitting: false,
    email: "",
    password: "",
    error: false,
  };

  handleEmailChange(event) {
    const val = event.target.value;

    this.setState({
      email: val,
    });
  }

  handlePasswordChange(event) {
    const val = event.target.value;

    this.setState({
      password: val,
    });
  }

  forgotPassword(e) {
    let self = this;

    e.preventDefault();

    let email = this.state.email;

    if (!email) {
      return;
    }

    this.setState({
      submitting: true,
      error: false,
    });

    APIV2.sendForgotPasswordEmail(email)
      .then(
        (data) => {
          console.log(data);

          this.setState({
            success: true,
          });
        },
        (error) => {
          console.error(error);
          this.setState({
            error: true,
          });
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  render() {
    // Redirect if already authenticated
    if (LocalStorage.get(Constant.CACHE.TOKEN)) {
      this.props.history.push("/admin");
    }

    return (
      <>
        <Col lg="6" md="7">
          <Card className="bg-white shadow border">
            <CardHeader className="bg-white py-4 px-5">
              <img
                src={logo}
                style={{
                  width: "90%",
                  maxWidth: "240px",
                  position: "relative",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
                className="my-2"
              ></img>
            </CardHeader>
            <CardHeader className="py-3">
              <div className="text-left">
                <Link to="/auth/login">
                  <i className="mdi mdi-chevron-left ml-2"></i>Back
                </Link>
              </div>
            </CardHeader>
            {this.state.success ? (
              <>
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-dark mb-5">
                    <h3>Password Reset Email Sent</h3>
                    <p>
                      We've sent you an email to verify your identity. Click the
                      button inside the email to continue the password reset
                      process.
                    </p>
                    <Link to="/auth/login">
                      <Button color="primary" outline>
                        Return to Login
                      </Button>
                    </Link>
                  </div>
                </CardBody>
              </>
            ) : (
              <>
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-dark mb-5 font-weight-500">
                    Enter your email below and receive a link to reset your
                    password.
                  </div>
                  <Form role="form" onSubmit={this.forgotPassword.bind(this)}>
                    <FormGroup className="mb-3">
                      <h5>Email</h5>
                      <Input
                        required={true}
                        onChange={this.handleEmailChange.bind(this)}
                        value={this.state.email}
                        placeholder="Email"
                        type="email"
                        autoComplete="new-email"
                      />
                    </FormGroup>

                    <div className="text-center">
                      <Button
                        disabled={this.state.submitting}
                        className="my-4 px-6"
                        color="primary"
                        type="submit"
                        size="lg"
                        style={{ fontSize: 15 }}
                      >
                        Reset Password
                      </Button>
                    </div>
                    {this.state.error ? (
                      <div className="text-center">
                        <Alert color="danger">
                          Unable to send password reset email, please try again.
                        </Alert>
                      </div>
                    ) : null}
                  </Form>
                </CardBody>
              </>
            )}
          </Card>
          <Row className="mt-3">
            {/*<Col xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a>
            </Col>*/}
          </Row>
        </Col>
      </>
    );
  }
}

export default ForgotPassword;
