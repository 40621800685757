import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  Card,
  DeltaBar,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import ClientOrderKPIDetailDrawer from "./ClientOrderKPIDetailDrawer";
import ChartMetricHeader from "./ChartMetricHeader";
import MemberExperienceScoreDetailDrawer from "./MemberExperienceScoreDetailDrawer";

class MemberExperienceKPICard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
  };

  /**
   * Fetches a summary of the order data for the given stores and date range
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchRateData(cubejsApi, stores, dateRange) {
    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["Metrics.totalEventsAverage"],
          order: {
            "Metrics.createdat": "asc",
          },

          timeDimensions: [
            {
              dimension: "Metrics.createdat",
              dateRange: [dateRange[0], dateRange[1]],
            },
          ],
          filters: [
            {
              member: "Metrics.name",
              operator: "equals",
              values: ["member_experience_score_avg"],
            },
            {
              member: "Metrics.metadatastoreid",
              operator: "equals",
              values: stores,
            },
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          if (
            data?.length &&
            data[0].hasOwnProperty("Metrics.totalEventsAverage")
          ) {
            return resolve(
              parseFloat(data[0]["Metrics.totalEventsAverage"].toFixed(1))
            );
          }

          return resolve(null);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async loadReport(stores, dateRange, compare) {
    this.setState({
      loading: true,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!stores?.length || !dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchRateData(cubejsApi, stores, dateRange);

      console.log(currentCount);
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load client order quantity.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });
    }

    if (this.props.comparePrevious) {
      let previousCount = null;

      let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

      secondDifference = secondDifference / 1000;

      let startMoment = moment(dateRange[0].toISOString());
      let endMoment = moment(dateRange[1].toISOString());

      startMoment.subtract(secondDifference, "seconds");
      endMoment.subtract(secondDifference + 1, "seconds");

      try {
        previousCount = await this._fetchRateData(cubejsApi, stores, [
          startMoment.toDate(),
          endMoment.toDate(),
        ]);
      } catch (e) {}

      if (previousCount !== null) {
        let percentChange = currentCount - previousCount;

        let declineMode = "";
        let isNegative = false;

        if (percentChange > 0) {
          if (Math.abs(percentChange) < 1) {
            declineMode = "unchanged";
          } else if (Math.abs(percentChange) < 4) {
            declineMode = "moderateIncrease";
          } else if (Math.abs(percentChange) >= 4) {
            declineMode = "increase";
          }
        } else {
          isNegative = true;

          if (Math.abs(percentChange) < 1) {
            declineMode = "moderateDecrease";
          } else if (Math.abs(percentChange) < 4) {
            declineMode = "moderateDecrease";
          } else if (Math.abs(percentChange) >= 4) {
            declineMode = "decrease";
          }
        }

        this.setState({
          previousCount,
          changeIsNegative: isNegative,
          deltaType: declineMode,
          percentChange,
          percentChangeString: Math.abs(percentChange)?.toFixed(1),
        });
      }
    }

    let allStoreAvg = null;
    let allStoreAvgAvailable = false;
    let allStoreAvgDifference = 0;

    if (this.props.allStores?.length) {
      try {
        allStoreAvg = await this._fetchRateData(
          cubejsApi,
          this.props.allStores,
          dateRange
        );
      } catch (e) {}

      if (allStoreAvg !== null) {
        allStoreAvgAvailable = true;
        allStoreAvgDifference = parseFloat(
          (currentCount - allStoreAvg).toFixed(1)
        );
      }
    }

    this.setState({
      currentCount,
      loading: false,
      allStoreAvg,
      allStoreAvgAvailable,
      allStoreAvgDifference,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stores != prevProps?.stores ||
      this.props.allStores != prevProps?.allStores ||
      this.props.dateRange != prevProps?.dateRange ||
      this.props.comparePrevious != prevProps.comparePrevious
    ) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  componentDidMount() {
    if (this.props.stores && this.props.dateRange) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  render() {
    return (
      <>
        <Card hFull={true}>
          <ChartMetricHeader
            forceWrapComparison={true}
            title="Member Experience Score"
            description="Rolling average for members 30+ days after signup."
            actions={
              <>
                <Button
                  size="sm"
                  outline
                  color="dark"
                  className="border-0 btn-icon-only"
                  disabled={this.state.loading || !this.state.dataAvailable}
                  onClick={() => {
                    this.setState({
                      open: true,
                    });
                  }}
                >
                  <i className="mdi mdi-fullscreen"></i>
                </Button>
              </>
            }
            loading={this.state.loading}
            dataAvailable={this.state.dataAvailable}
            metric={`${StringUtils.numberFormat(this.state.currentCount)}/100`}
            comparisonMetric={
              this.state.previousCount !== null
                ? `${StringUtils.numberFormat(this.state.previousCount)}/100`
                : null
            }
            dateRange={this.props.dateRange}
            deltaType={this.state.deltaType}
            percentChange={this.state.percentChangeString}
            showPercentChange={true}
          ></ChartMetricHeader>

          {this.state.loading ? (
            <div
              className="tr-mt-4 skeleton"
              style={{ height: 20, width: 60 }}
            ></div>
          ) : (
            <>
              {this.state.allStoreAvgAvailable ? (
                <>
                  <Flex marginTop="mt-4">
                    <Text>
                      {`${this.state.allStoreAvgDifference > 0 ? "+" : ""}${
                        this.state.allStoreAvgDifference
                      }`}
                      &nbsp;vs. all-store avg.
                    </Text>
                    <Text>{this.state.allStoreAvg} all-store avg.</Text>
                  </Flex>
                </>
              ) : (
                <>
                  <Flex marginTop="mt-4">
                    <Text>--</Text>
                  </Flex>
                </>
              )}
            </>
          )}
          {this.state.loading ? (
            <div
              className="tr-mt-2 skeleton"
              style={{ height: 8, width: "100%" }}
            ></div>
          ) : (
            <>
              {this.state.allStoreAvgAvailable ? (
                <DeltaBar
                  percentageValue={this.state.allStoreAvgDifference * 4}
                  isIncreasePositive={true}
                  tooltip=""
                  showAnimation={true}
                  marginTop="mt-2"
                />
              ) : (
                <DeltaBar
                  percentageValue={0}
                  isIncreasePositive={false}
                  tooltip=""
                  showAnimation={true}
                  marginTop="mt-2"
                />
              )}
            </>
          )}
        </Card>
        <MemberExperienceScoreDetailDrawer
          open={this.state.open}
          onClose={() => {
            this.setState({
              open: false,
            });
          }}
          stores={this.props.stores}
          allStores={this.props.allStores}
          dateRange={this.props.dateRange}
        />
      </>
    );
  }
}

export default MemberExperienceKPICard;
