import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
  Button,
  Modal,
  Badge,
  Spinner,
} from "reactstrap";
import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";
import API from "lib/API";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import User from "lib/Model/User";
import NumberFormat from "react-number-format";
import APIV2 from "lib/APIV2";
import _ from "underscore";
import { Drawer } from "rsuite";

class PlanRow extends React.Component {
  state = {
    showSecret: false,
    code: "",
    name: "",
    price: {
      value: null,
    },
    deliveryFee: {
      value: null,
    },
    groupID: 0,
    frequency: 0,
    procurement: 0,
    quantity: 0,
    modify: false,
    productGroups: [],
  };

  delete() {
    let c = window.confirm("Are you sure you want to delete this plan?");

    if (c) {
      APIV2.deleteStorePlan(this.props.plan._id).then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);
        },
        (e) => {
          console.error(e);
          window.alert(
            "We're having trouble removing that plan. Please try again."
          );
        }
      );
    }
  }

  modify(e) {
    e.preventDefault();

    this.setState({
      submitting: true,
    });

    let frequency = this.state.frequency == "0" ? "weekly" : "biweekly";

    let procurement = "pickup";

    if (this.state.procurement == "1") {
      procurement = "delivery";
    }

    if (this.state.procurement == "2") {
      procurement = "shipping";
    }

    APIV2.modifyStorePlan(
      this.props.plan._id,
      this.state.code,
      this.state.name,
      this.state.groupID,
      parseFloat(this.state.price.value) * 100,
      this.state.deliveryFee?.value
        ? parseFloat(this.state.deliveryFee.value) * 100
        : 0,
      parseInt(this.state.quantity),
      procurement,
      frequency,
      this.props.plan.availableToNewClients
    )
      .then(
        (data) => {
          console.log(data.data);

          if (data && data.data && data.data.store) {
            PubSub.publish(Event.STORE.MODIFIED, data.data.store);
            PubSub.publish(Event.PLAN.MODIFIED, data.data.plan);

            this.closeModifyModal();
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "We were unable to modify that group. Please try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  toggleVisible() {
    let visible = !this.props.plan.availableToNewClients;

    this.setState({
      submitting: true,
    });

    APIV2.modifyStorePlan(
      this.props.plan._id,
      this.props.plan.code,
      this.props.plan.name,
      this.props.plan.planTypeID,
      this.props.plan.unit_price,
      this.props.plan.deliveryFee,
      this.props.plan.quantity,
      this.props.plan.procurement,
      this.props.plan.frequency,
      visible
    )
      .then(
        (data) => {
          if (data && data.data && data.data.store) {
            PubSub.publish(Event.STORE.MODIFIED, data.data.store);
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "We were unable to toggle availability for that plan. Please try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  closeModifyModal() {
    this.setState({
      code: "",
      name: "",
      price: {
        value: null,
      },
      groupID: 0,
      frequency: 0,
      procurement: 0,
      quantity: 0,
      modify: false,
    });
  }

  handleCodeChange(event) {
    const val = event.target.value;

    this.setState(
      {
        code: val,
      },
      () => {
        this.generatePlanCode();
      }
    );
  }

  handleNameChange(event) {
    const val = event.target.value;

    this.setState(
      {
        name: val,
      },
      () => {
        this.generatePlanCode();
      }
    );
  }

  handleQuantityChange(event) {
    const val = event.target.value;

    this.setState(
      {
        quantity: val,
      },
      () => {
        this.generatePlanCode();
      }
    );
  }

  handlePriceChange(val) {
    this.setState({
      price: val,
    });
  }

  handleDeliveryFeeChange(val) {
    this.setState({
      deliveryFee: val,
    });
  }

  handleFrequencyChange(event) {
    const val = event.target.value;

    this.setState(
      {
        frequency: val,
      },
      () => {
        this.generatePlanCode();
      }
    );
  }

  handleProcurementChange(event) {
    const val = event.target.value;

    this.setState(
      {
        procurement: val,
      },
      () => {
        this.generatePlanCode();
      }
    );
  }

  handleGroupChange(event) {
    const val = event.target.value;

    this.setState(
      {
        groupID: val,
      },
      () => {
        this.generatePlanCode();
      }
    );
  }

  generatePlanCode() {
    let productGroup = _.findWhere(this.state.productGroups, {
      _id: this.state.groupID,
    });

    let frequencyString = this.state.frequency == "0" ? "W" : "B";
    let frequencyStringLong =
      this.state.frequency == "0" ? "Weekly" : "Bi-Weekly";

    let procurementString = "P";
    let procurementStringLong = "Pickup";

    if (this.state.procurement == "1") {
      procurementString = "D";
      procurementStringLong = "Delivery";
    }

    if (this.state.procurement == "2") {
      procurementString = "S";
      procurementStringLong = "Shipping";
    }

    let code = `${this.props.store.productCode}${
      productGroup && productGroup.groupCode ? productGroup.groupCode : "X"
    }${
      this.state.quantity !== null ? this.state.quantity : ""
    }-${frequencyString}${procurementString}`;

    let name = `${productGroup && productGroup.name ? productGroup.name : ""} ${
      this.state.quantity !== null ? this.state.quantity : ""
    } - ${frequencyStringLong} ${procurementStringLong}`;

    this.setState({
      code,
      name,
    });
  }

  openModifyModal() {
    APIV2.getProductGroups().then(
      (data) => {
        if (data.data && data.data.planTypes) {
          this.setState({
            productGroups: data.data.planTypes,
            loadingBAs: false,
          });
        }
      },
      () => {
        //window.alert("Failed to get product groups. Please try again.");
      }
    );

    let procurement = "0";

    if (this.props.plan.procurement == "delivery") {
      procurement = "1";
    }

    if (this.props.plan.procurement == "shipping") {
      procurement = "2";
    }

    let frequency = this.props.plan.frequency == "weekly" ? "0" : "1";

    this.setState({
      name: this.props.plan.name,
      code: this.props.plan.code,
      price: {
        value: this.props.plan.unitPrice / 100,
      },
      deliveryFee: {
        value: this.props.plan.deliveryFee
          ? this.props.plan.deliveryFee / 100
          : 0,
      },
      quantity: this.props.plan.quantity,
      procurement: procurement,
      frequency: frequency,
      groupID: this.props.plan.planTypeID,
      modify: true,
    });
  }

  convertToCurrency(val) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });

    return formatter.format(val / 100); // "$1,000.00"
  }

  componentDidMount() {
    APIV2.getProductGroups().then(
      (data) => {
        if (data.data && data.data.planTypes) {
          this.setState({
            productGroups: data.data.planTypes,
            loadingBAs: false,
          });
        }
      },
      () => {
        //window.alert("Failed to get product groups. Please try again.");
      }
    );
  }

  render() {
    const { productGroups } = this.state;

    let pgList =
      productGroups.length > 0 &&
      productGroups.map((item, i) => {
        return (
          <option key={i} value={item._id}>
            {item.name}
          </option>
        );
      }, this);

    return (
      <>
        <tr>
          <td style={{ whiteSpace: "initial" }}>
            <h4 className="mb-1">{this.props.plan.name}</h4>
            <small style={{ fontSize: "12px" }}>{this.props.plan.code}</small>
          </td>
          <td style={{ whiteSpace: "initial" }}>
            {this.convertToCurrency(
              this.props.plan.unitPrice + (this.props.plan.deliveryFee ?? 0)
            )}
          </td>
          <td style={{ whiteSpace: "initial" }}>{this.props.plan.quantity}</td>

          <td style={{ whiteSpace: "initial" }}>
            {this.props.plan.availableToNewClients ? (
              <Badge color="success">Yes</Badge>
            ) : (
              <Badge color="warning">No</Badge>
            )}
          </td>
          <td className="text-right">
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Button outline color="dark" className="btn-icon-only">
                  <i className="mdi mdi-dots-vertical"></i>
                </Button>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Actions</h6>
                </DropdownItem>
                <DropdownItem
                  to="/admin/user-profile"
                  onClick={() => this.openModifyModal()}
                >
                  <i className="mdi mdi-pencil" />
                  <span>Modify</span>
                </DropdownItem>
                <DropdownItem
                  to="/admin/user-profile"
                  onClick={() => this.toggleVisible()}
                >
                  {this.props.plan.availableToNewClients ? (
                    <i className="mdi mdi-eye-off" />
                  ) : (
                    <i className="mdi mdi-eye" />
                  )}
                  {this.props.plan.availableToNewClients ? (
                    <span>Make Unavailable To New Members</span>
                  ) : (
                    <span>Make Available To New Members</span>
                  )}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  className="text-danger"
                  onClick={() => this.delete()}
                >
                  <i className="mdi mdi-trash-can" />
                  <span>Delete</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>

        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.state.modify}
          onClose={() => this.closeModifyModal()}
        >
          <Form onSubmit={this.modify.bind(this)}>
            <Drawer.Header className="pr-4">
              <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
                Modify Plan
              </h3>
              <Drawer.Actions>
                <Button
                  size="sm"
                  disabled={this.state.submitting}
                  color="primary"
                  type="submit"
                >
                  {this.state.submitting ? (
                    <Spinner size="sm" color="white"></Spinner>
                  ) : (
                    "Save"
                  )}
                </Button>
              </Drawer.Actions>
            </Drawer.Header>
            <Drawer.Body className="p-4">
              <h5 className="mb-2">Product Group</h5>
              {this.state.loadingBAs ? (
                <select value="0" disabled={true} className="form-control mb-3">
                  <option value="0">Loading...</option>
                </select>
              ) : null}
              {!this.state.loadingBAs ? (
                <select
                  value={this.state.groupID}
                  required={true}
                  onChange={this.handleGroupChange.bind(this)}
                  id="createPlanGroup"
                  className="form-control mb-3"
                >
                  {pgList}
                </select>
              ) : null}
              <h5 className="mb-2">Meal Quantity</h5>
              <Input
                id="createPGQuantity"
                placeholder="Quantity"
                rows="3"
                type="text"
                className="mb-3"
                required={true}
                value={this.state.quantity}
                onChange={this.handleQuantityChange.bind(this)}
              />
              <h5 className="mb-2">Unit Price</h5>
              <NumberFormat
                className="form-control mb-3"
                fixedDecimalScale={true}
                decimalScale={2}
                required={true}
                placeholder="$0.00"
                value={this.state.price.value}
                onValueChange={this.handlePriceChange.bind(this)}
                thousandSeparator={true}
                prefix={"$"}
              />
              <h5 className="mb-2">Delivery/Shipping Fee</h5>
              <NumberFormat
                className="form-control mb-3"
                fixedDecimalScale={true}
                decimalScale={2}
                required={false}
                placeholder="$0.00"
                value={this.state.deliveryFee.value}
                onValueChange={this.handleDeliveryFeeChange.bind(this)}
                thousandSeparator={true}
                prefix={"$"}
              />
              <h5 className="mb-2">Fulfillment Frequency</h5>
              <select
                required={true}
                value={this.state.frequency}
                onChange={this.handleFrequencyChange.bind(this)}
                id="createPlanFreq"
                className="form-control mb-3"
              >
                <option value="0">Weekly</option>
                <option value="1">Biweekly</option>
              </select>
              <h5 className="mb-2">Procurement Method</h5>
              <select
                value={this.state.procurement}
                required={true}
                onChange={this.handleProcurementChange.bind(this)}
                id="createPlanProc"
                className="form-control mb-3"
              >
                <option value="1">Delivery</option>
                <option value="0">Pickup</option>
                <option value="2">Shipping</option>
              </select>

              <h5 className="mb-2">Plan Name</h5>
              <Input
                id="createPGName"
                placeholder="Name"
                type="text"
                className="mb-3"
                required={true}
                value={this.state.name}
                onChange={this.handleNameChange.bind(this)}
              />
              <h5 className="mb-2">Plan Code</h5>
              <Input
                id="createPGCode"
                placeholder="Code"
                type="text"
                className="mb-3"
                required={true}
                value={this.state.code}
                onChange={this.handleCodeChange.bind(this)}
              />
            </Drawer.Body>
          </Form>
        </Drawer>
      </>
    );
  }
}

export default PlanRow;
