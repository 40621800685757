import CustomerCreateInvoiceDrawer from "components/Drawers/CustomerCreateInvoiceDrawer";
import StringUtils from "lib/StringUtils";
import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Spinner,
  Table,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import Badge from "reactstrap/lib/Badge";
import CustomerAccountBalanceWidget from "./CustomerAccountBalanceWidget";
import CustomerInvoicesWidget from "./CustomerInvoicesWidget";
import NumberFormat from "react-number-format";
import APIV2 from "lib/APIV2";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import Alert from "reactstrap/lib/Alert";
import { Capacitor } from "@capacitor/core";
import { Tooltip, Whisper } from "rsuite";
import swal from "sweetalert";
import {
  Elements,
  ElementsConsumer,
  ExpressCheckoutElement,
  PaymentElement,
} from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";
import Constant from "lib/Constant";
import _ from "underscore";

class CustomerPaymentMethodWidget extends React.Component {
  state = {
    expand: false,
    number: {
      value: null,
    },
    cvc: {
      value: null,
    },
    expiry: {
      value: null,
    },
    stripeOptions: null,
    stripe: null,
  };

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  handleNumberChange(value) {
    this.setState({
      number: value,
    });
  }

  handleCVCChange(value) {
    this.setState({
      cvc: value,
    });
  }

  handleExpiryChange(value) {
    this.setState({
      expiry: value,
    });
  }

  getStore() {
    APIV2.getStoreByID(this?.props?.customer?.storeID).then((data) => {
      this.setState({
        store: data?.data?.store,
      });

      this._loadStripe();
    });
  }

  componentDidMount() {
    if (this.props.customer) {
      this.getStore();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.customer?.email != prevProps.customer?.email) {
      this.getStore();
    }
  }

  removePayment() {
    swal({
      title: "Remove Payment Method",
      text: `Are you sure you want to remove the member's payment method?`,
      icon: "warning",
      buttons: ["Nevermind", "Remove"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        removing: true,
      });

      APIV2.removeCustomerPaymentMethod(
        this.props?.customer?.storeID,
        this.props?.customer?._id
      )
        .then(
          (data) => {
            PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);
          },
          (e) => {
            this.setState({
              errorMsg:
                "Oops! We're having trouble removing the payment method. Please try again.",
            });
          }
        )
        .finally(() => {
          this.setState({
            removing: false,
          });
        });
    });
  }

  async submit(stripeElements) {
    console.log("Submit");

    this.setState({
      errorMsg: "",
      submitting: true,
    });

    //console.log("STRIPE");

    if (!this.state.stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    let params = {
      //`Elements` instance that was used to create the Payment Element
      elements: stripeElements,
      confirmParams: {},
      redirect: "if_required",
    };

    params.confirmParams.payment_method_data = {
      billing_details: {
        name: this.props.customer?.name,
      },
    };

    let result = await this.state.stripe.confirmSetup(params);

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error);

      this.setState({
        errorMsg:
          "We received the following payment error: " + result.error.message,
      });

      setTimeout(() => {
        this.setState({
          errorMsg: "",
        });
      }, 4000);

      this.setState({
        submitting: false,
      });

      return;
    }

    result.stripeCustomerID = this.state.stripeCustomerID;

    let opaqueData = {
      provider: "stripe",
      stripeCustomerID: result?.stripeCustomerID,
      stripePaymentMethod: result?.setupIntent?.payment_method,
    };

    APIV2.updateCustomerPaymentMethod(
      this.props?.customer?.storeID,
      this.props?.customer?._id,
      opaqueData
    )
      .then(
        (data) => {
          PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);

          this.setState({
            editPayment: false,
            number: {
              value: null,
            },
            cvc: {
              value: null,
            },
            expiry: {
              value: null,
            },
            cardName: "",
          });

          this._loadStripe();
        },
        (err) => {
          this.setState({
            errorMsg:
              "Oops! We're having trouble updating the payment method. Please try again.",
          });

          setTimeout(() => {
            this.setState({
              errorMsg: "",
            });
          }, 4000);
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  async _loadStripe() {
    const st = await loadStripe(Constant.STRIPE_KEY);

    console.log("STRIPE", this.props.customer?.email);

    this.setState({
      stripe: st,
    });

    if (this.props.customer?.email) {
      APIV2.getStripeCheckoutIntent(this.props.customer?.email)
        .then(
          (data) => {
            //console.log(data.data);

            let opts = {
              cssSrc: "https://fonts.googleapis.com/css?family=Karla",
              loader: "always",
            };

            console.log(data?.data);

            opts.clientSecret = data.data?.clientSecret;

            this.setState({
              stripeOptions: opts,
              stripeCustomerID: data.data?.stripeCustomerID,
            });
          },
          (e) => {
            console.error(e);
          }
        )
        .finally(() => {});
    }
  }

  render() {
    return (
      <>
        <Card className="mb-4 border shadow">
          {this.state.stripeOptions?.clientSecret ? (
            <Elements
              stripe={this.state.stripe}
              options={this.state.stripeOptions}
            >
              <ElementsConsumer>
                {({ stripe, elements }) => {
                  return (
                    <>
                      <CardHeader>
                        <Row className="align-items-center">
                          <div className="col">
                            <h3 className="mb-0">Payment Method</h3>
                          </div>
                          <Col xs="auto">
                            {!this.state.editPayment ? (
                              <>
                                {this.props?.customer?.paymentMethod
                                  ?.stripePaymentMethod ? (
                                  <Button
                                    size="sm"
                                    color="danger"
                                    outline
                                    disabled={this.state.removing}
                                    onClick={() => {
                                      this.removePayment();
                                    }}
                                  >
                                    {this.state.removing ? (
                                      <>
                                        <Spinner size="sm"></Spinner>
                                      </>
                                    ) : (
                                      "Remove"
                                    )}
                                  </Button>
                                ) : null}
                                <Button
                                  size="sm"
                                  color="secondary"
                                  disabled={
                                    !StringUtils.isValidEmail(
                                      this.props.customer?.email
                                    )
                                  }
                                  outline
                                  onClick={() => {
                                    this.setState({
                                      editPayment: true,
                                    });
                                  }}
                                >
                                  Edit
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  size="sm"
                                  color="secondary"
                                  outline
                                  onClick={() => {
                                    this.setState({
                                      editPayment: false,
                                      number: {
                                        value: null,
                                      },
                                      cvc: {
                                        value: null,
                                      },
                                      expiry: {
                                        value: null,
                                      },
                                      cardName: "",
                                    });

                                    this._loadStripe();
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  size="sm"
                                  color="primary"
                                  disabled={
                                    this.state.submitting || !this.state.stripe
                                  }
                                  onClick={() => {
                                    this.submit(elements);
                                  }}
                                >
                                  {this.state.submitting ? (
                                    <>
                                      <Spinner
                                        size="sm"
                                        color="white"
                                      ></Spinner>
                                    </>
                                  ) : (
                                    "Save"
                                  )}
                                </Button>
                              </>
                            )}
                          </Col>
                          {!StringUtils.isValidEmail(
                            this.props.customer?.email
                          ) ? (
                            <Col xs="12">
                              <Alert color="danger" className="mt-3">
                                Please add a valid email to this member's
                                profile to add & modify their payment method.
                              </Alert>
                            </Col>
                          ) : null}
                        </Row>
                      </CardHeader>
                      {this?.state?.errorMsg ? (
                        <>
                          <CardHeader>
                            <Alert color="danger">{this.state.errorMsg}</Alert>
                          </CardHeader>
                        </>
                      ) : null}
                      {this.state.editPayment ? (
                        <>
                          <CardBody>
                            {!this.state?.paymentElementReady && (
                              <>
                                <div
                                  className="mt-2 skeleton"
                                  animated={true}
                                  style={{
                                    width: "100%",
                                    height: 250,
                                    marginTop: -4,
                                    marginBottom: -4,
                                    borderRadius: 6,
                                  }}
                                ></div>
                              </>
                            )}
                            <PaymentElement
                              className="mt-2"
                              onReady={() => {}}
                              onLoaderStart={() => {
                                this.setState({
                                  paymentElementReady: true,
                                });
                              }}
                              options={{
                                layout: {
                                  type: "accordion",
                                },

                                wallets: {
                                  applePay: "never",
                                  googlePay: "never",
                                },
                              }}
                            />
                          </CardBody>
                        </>
                      ) : (
                        <>
                          <CardBody className="">
                            <Row className="align-items-center">
                              {this.props.customer?.paymentMethod
                                ?.stripePaymentMethod ? (
                                <>
                                  <Col xs="12">
                                    <h3 className="mb-0 text-dark">
                                      {this.props.customer?.paymentMethod
                                        ?.type ? (
                                        <span className="text-uppercase">
                                          {
                                            this.props.customer?.paymentMethod
                                              ?.type
                                          }
                                          &nbsp;
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {this.props.customer?.paymentMethod?.last4
                                        ? `x${this.props.customer?.paymentMethod?.last4}`
                                        : "Credit Card"}
                                    </h3>
                                    <div className="table-responsive">
                                      <Table className="mt-4 border">
                                        <thead>
                                          <th className="bg-superlight text-dark">
                                            Provider
                                          </th>
                                          <th className="bg-superlight text-dark">
                                            Customer ID
                                          </th>
                                          <th className="bg-superlight text-dark">
                                            Payment Method ID
                                          </th>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>Stripe</td>
                                            <td>
                                              {
                                                this.props?.customer
                                                  ?.paymentMethod
                                                  ?.stripeCustomerID
                                              }
                                            </td>
                                            <td>
                                              {
                                                this.props?.customer
                                                  ?.paymentMethod
                                                  ?.stripePaymentMethod
                                              }
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </div>
                                  </Col>
                                </>
                              ) : null}
                              {!this.props.customer?.paymentMethod
                                ?.stripePaymentMethod &&
                              !this.props.customer?.paymentMethod
                                ?.customerProfileID ? (
                                <Col xs="12">
                                  <h3
                                    className="text-dark"
                                    style={{ lineHeight: 1.2 }}
                                  >
                                    <Badge
                                      color=""
                                      className="bg-yellow text-dark"
                                    >
                                      No Payment Method
                                    </Badge>
                                  </h3>
                                </Col>
                              ) : null}
                              {this.props?.customer?.paymentMethod
                                ?.customerProfileID &&
                              this.props.customer?.paymentMethod
                                ?.paymentProfileID &&
                              !this.props.customer?.paymentMethod
                                ?.stripePaymentMethod ? (
                                <>
                                  <Col xs="12">
                                    <h3 className="mb-0 text-dark">
                                      {this.props.customer?.paymentMethod
                                        ?.type ? (
                                        <span className="text-uppercase">
                                          {
                                            this.props.customer?.paymentMethod
                                              ?.type
                                          }
                                          &nbsp;
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {this.props.customer?.paymentMethod?.last4
                                        ? `x${this.props.customer?.paymentMethod?.last4}`
                                        : "Credit Card"}
                                    </h3>
                                    <div className="table-responsive">
                                      <Table className="mt-4 border">
                                        <thead>
                                          <th className="bg-superlight text-dark">
                                            Provider
                                          </th>
                                          <th className="bg-superlight text-dark">
                                            Customer ID
                                          </th>
                                          <th className="bg-superlight text-dark">
                                            Payment Profile ID
                                          </th>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>Authorize.net</td>
                                            <td>
                                              {
                                                this.props?.customer
                                                  ?.paymentMethod
                                                  ?.customerProfileID
                                              }
                                            </td>
                                            <td>
                                              {
                                                this.props?.customer
                                                  ?.paymentMethod
                                                  ?.paymentProfileID
                                              }
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </div>
                                  </Col>
                                </>
                              ) : null}
                            </Row>
                          </CardBody>
                        </>
                      )}
                    </>
                  );
                }}
              </ElementsConsumer>
            </Elements>
          ) : (
            <>
              <CardHeader>
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Payment Method</h3>
                  </div>
                  <Col xs="auto">
                    {this.props?.customer?.paymentMethod
                      ?.stripePaymentMethod ? (
                      <Button
                        size="sm"
                        color="danger"
                        outline
                        disabled={this.state.removing}
                        onClick={() => {
                          this.removePayment();
                        }}
                      >
                        {this.state.removing ? (
                          <>
                            <Spinner size="sm"></Spinner>
                          </>
                        ) : (
                          "Remove"
                        )}
                      </Button>
                    ) : null}
                    <Button
                      size="sm"
                      color="secondary"
                      outline
                      disabled={true}
                      onClick={() => {
                        this.setState({
                          editPayment: true,
                        });

                        this._loadStripe();
                      }}
                    >
                      <Spinner size="sm"></Spinner>
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="">
                <Row className="align-items-center">
                  {this.props.customer?.paymentMethod?.stripePaymentMethod ? (
                    <>
                      <Col xs="12">
                        <h3 className="mb-0 text-dark">
                          {this.props.customer?.paymentMethod?.type ? (
                            <span className="text-uppercase">
                              {this.props.customer?.paymentMethod?.type}
                              &nbsp;
                            </span>
                          ) : (
                            ""
                          )}
                          {this.props.customer?.paymentMethod?.last4
                            ? `x${this.props.customer?.paymentMethod?.last4}`
                            : "Credit Card"}
                        </h3>
                        <div className="table-responsive">
                          <Table className="mt-4 border">
                            <thead>
                              <th className="bg-superlight text-dark">
                                Provider
                              </th>
                              <th className="bg-superlight text-dark">
                                Customer ID
                              </th>
                              <th className="bg-superlight text-dark">
                                Payment Method ID
                              </th>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Stripe</td>
                                <td>
                                  {
                                    this.props?.customer?.paymentMethod
                                      ?.stripeCustomerID
                                  }
                                </td>
                                <td>
                                  {
                                    this.props?.customer?.paymentMethod
                                      ?.stripePaymentMethod
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </>
                  ) : null}
                  {!this.props.customer?.paymentMethod?.stripePaymentMethod &&
                  !this.props.customer?.paymentMethod?.customerProfileID ? (
                    <Col xs="12">
                      <h3 className="text-dark" style={{ lineHeight: 1.2 }}>
                        <Badge color="" className="bg-yellow text-dark">
                          No Payment Method
                        </Badge>
                      </h3>
                    </Col>
                  ) : null}
                  {this.props?.customer?.paymentMethod?.customerProfileID &&
                  this.props.customer?.paymentMethod?.paymentProfileID &&
                  !this.props.customer?.paymentMethod?.stripePaymentMethod ? (
                    <>
                      <Col xs="12">
                        <h3 className="mb-0 text-dark">
                          {this.props.customer?.paymentMethod?.type ? (
                            <span className="text-uppercase">
                              {this.props.customer?.paymentMethod?.type}
                              &nbsp;
                            </span>
                          ) : (
                            ""
                          )}
                          {this.props.customer?.paymentMethod?.last4
                            ? `x${this.props.customer?.paymentMethod?.last4}`
                            : "Credit Card"}
                        </h3>
                        <div className="table-responsive">
                          <Table className="mt-4 border">
                            <thead>
                              <th className="bg-superlight text-dark">
                                Provider
                              </th>
                              <th className="bg-superlight text-dark">
                                Customer ID
                              </th>
                              <th className="bg-superlight text-dark">
                                Payment Profile ID
                              </th>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Authorize.net</td>
                                <td>
                                  {
                                    this.props?.customer?.paymentMethod
                                      ?.customerProfileID
                                  }
                                </td>
                                <td>
                                  {
                                    this.props?.customer?.paymentMethod
                                      ?.paymentProfileID
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </>
                  ) : null}
                </Row>
              </CardBody>
            </>
          )}
        </Card>
      </>
    );
  }
}

export default CustomerPaymentMethodWidget;
