import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
  Spinner,
  Alert,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import NumberFormat from "react-number-format";
import APIV2 from "lib/APIV2";
import { Drawer } from "rsuite";

class CreateStoreDrawer extends React.Component {
  state = {
    name: "",
    submitting: false,
    city: "",
    state: "",

    code: "",
    productCode: "",
  };

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });

    if (this.state[state] == true) {
      this.setState({
        name: "",
        city: "",
        state: "",
        code: "",
        productCode: "",
      });
    }

    if (typeof this.props.onClose == "function") {
      this.props.onClose();
    }
  };

  submit() {
    this.setState({
      submitting: true,
    });

    APIV2.createStore(
      this.state.code,
      this.state.productCode,
      null,
      null,
      null,
      null,
      null,
      this.state.name,
      0,
      "",
      "",
      this.state.city,
      this.state.state,
      "",
      "",
      "",
      "",
      "",
      ""
    )
      .then(
        (data) => {
          if (data && data.data && data.data.store) {
            console.log(data.data.store);

            PubSub.publish(Event.STORE.CREATED, data.data.store);

            if (typeof this.props.onClose == "function") {
              this.props.onClose();
            }

            this.setState({
              name: "",
              city: "",
              state: "",
              type: 0,
              code: "",
              productCode: "",
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert("We were unable to create the store. Please try again.");
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              New Store
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={
                  this.state.submitting ||
                  !this.state.name ||
                  !this.state.code ||
                  !this.state.productCode ||
                  !this.state.city ||
                  !this.state.state
                }
                color="primary"
                onClick={this.submit.bind(this)}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  "Add"
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}

            <h3>Information</h3>
            <FormGroup>
              <h5>Store Name</h5>
              <Input
                bsSize="sm"
                type="text"
                name="name"
                placeholder="Name"
                value={this.state.name}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Store Code</h5>
              <Input
                bsSize="sm"
                type="text"
                name="code"
                placeholder="Code"
                value={this.state.code}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Store Product Code</h5>
              <Input
                bsSize="sm"
                type="text"
                name="productCode"
                placeholder="Name"
                value={this.state.productCode}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <hr className="mt-0 mb-3"></hr>
            <h3>Location</h3>
            <FormGroup>
              <h5>City</h5>
              <Input
                bsSize="sm"
                type="text"
                name="city"
                placeholder="City"
                value={this.state.city}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>State</h5>
              <Input
                type="select"
                value={this.state.state}
                onChange={this.handleInputChange.bind(this)}
                name="state"
                className="form-control mb-3"
                bsSize="sm"
              >
                <option value="" disabled>
                  Select A State
                </option>
                <option value="AL">AL - Alabama</option>
                <option value="AK">AK - Alaska</option>
                <option value="AZ">AZ - Arizona</option>
                <option value="AR">AR - Arkansas</option>
                <option value="CA">CA - California</option>
                <option value="CO">CO - Colorado</option>
                <option value="CT">CT - Connecticut</option>
                <option value="DE">DE - Delaware</option>
                <option value="DC">DC - District Of Columbia</option>
                <option value="FL">FL - Florida</option>
                <option value="GA">GA - Georgia</option>
                <option value="HI">HI - Hawaii</option>
                <option value="ID">ID - Idaho</option>
                <option value="IL">IL - Illinois</option>
                <option value="IN">IN - Indiana</option>
                <option value="IA">IA - Iowa</option>
                <option value="KS">KS - Kansas</option>
                <option value="KY">KY - Kentucky</option>
                <option value="LA">LA - Louisiana</option>
                <option value="ME">ME - Maine</option>
                <option value="MD">MD - Maryland</option>
                <option value="MA">MA - Massachusetts</option>
                <option value="MI">MI - Michigan</option>
                <option value="MN">MN - Minnesota</option>
                <option value="MS">MS - Mississippi</option>
                <option value="MO">MO - Missouri</option>
                <option value="MT">MT - Montana</option>
                <option value="NE">NE - Nebraska</option>
                <option value="NV">NV - Nevada</option>
                <option value="NH">NH - New Hampshire</option>
                <option value="NJ">NJ - New Jersey</option>
                <option value="NM">NM - New Mexico</option>
                <option value="NY">NY - New York</option>
                <option value="NC">NC - North Carolina</option>
                <option value="ND">ND - North Dakota</option>
                <option value="OH">OH - Ohio</option>
                <option value="OK">OK - Oklahoma</option>
                <option value="OR">OR - Oregon</option>
                <option value="PA">PA - Pennsylvania</option>
                <option value="RI">RI - Rhode Island</option>
                <option value="SC">CS - South Carolina</option>
                <option value="SD">SD - South Dakota</option>
                <option value="TN">TN - Tennessee</option>
                <option value="TX">TX - Texas</option>
                <option value="UT">UT - Utah</option>
                <option value="VT">VT - Vermont</option>
                <option value="VA">VA - Virginia</option>
                <option value="WA">WA - Washington</option>
                <option value="WV">WV - West Virginia</option>
                <option value="WI">WI - Wisconsin</option>
                <option value="WY">WY - Wyoming</option>
              </Input>
            </FormGroup>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default CreateStoreDrawer;
