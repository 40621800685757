import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  Tooltip,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import Toolbar from "rsuite/esm/DatePicker/Toolbar";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import { TooltipController } from "bizcharts";
import LinkUtils from "lib/LinkUtils";

class LibraryAllTopicsDrawer extends React.Component {
  state = {
    name: "",
    description: "",
  };

  getTopics() {
    this.setState({
      loadingTopics: true,
    });

    APIV2.getLibraryTopics()
      .then(
        (data) => {
          this.setState({
            topics: data?.data?.topics,
          });
        },
        () => {
          window.alert("Failed to get topics. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          loadingTopics: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.open != prevProps.open && this.props.open) {
      this.getTopics();
    }
  }

  componentDidMount() {
    this.getTopics();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  /**
   * Checks if the current user is a global user via permissions
   *
   * @returns
   */
  isGlobal() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  deleteTopic(topic) {
    swal({
      title: "Delete Topic",
      text: "Are you sure you want delete this topic? This will remove it from all topics.",
      icon: "warning",
      buttons: ["Nevermind", "Delete"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        deletingTopic: topic?._id,
      });

      APIV2.deleteLibraryTopic(topic?._id)
        .then(
          () => {
            let topics = this.state.topics;

            topics = _.filter(topics, (cat) => {
              return cat?._id != topic?._id;
            });

            this.setState({ topics: _.sortBy(topics, "name") });
          },
          (e) => {
            alert("Unable to delete topic. Please try again.");
          }
        )
        .finally(() => {
          this.setState({ deletingTopic: "" });
        });
    });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => {
            if (typeof this.props.onClose == "function") {
              this.props.onClose();
            }
          }}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              All Topics
            </h3>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            {this.state.topics?.map((topic, i) => (
              <>
                <div
                  className={`pb-3 ${i != 0 && "pt-3"} ${
                    i != this.state.topics?.length - 1 && "border-bottom"
                  }`}
                  key={topic?._id}
                >
                  <Row className="align-items-center">
                    <Col xs="">
                      <h4 className="m-0">
                        {topic?.internal ? (
                          <Badge color="light" className="border mr-2">
                            INTERNAL
                          </Badge>
                        ) : null}
                        {topic?.name}
                      </h4>
                      {topic.description ? (
                        <>
                          <p className="small m-0" style={{ lineHeight: 1.2 }}>
                            {topic?.description}
                          </p>
                        </>
                      ) : null}
                      <p
                        className="small mt-1 mb-0"
                        style={{ lineHeight: 1.2 }}
                      >
                        Created: {moment(topic?.createdAt).format("MM/DD/YYYY")}
                      </p>
                    </Col>
                    <Col xs="auto">
                      {this.isGlobal() && (
                        <>
                          <Whisper
                            placement="autoHorizontal"
                            trigger="hover"
                            delayOpen={400}
                            speaker={
                              <Tooltip>
                                <p className="m-0 text-white">
                                  Delete topic &amp; remove from modules.
                                </p>
                              </Tooltip>
                            }
                          >
                            <Button
                              size="sm"
                              color="danger"
                              outline
                              className="btn-icon-only"
                              disabled={this.state.deletingTopic == topic?._id}
                              onClick={() => {
                                this.deleteTopic(topic);
                              }}
                            >
                              {this.state.deletingTopic == topic?._id ? (
                                <Spinner size="sm"></Spinner>
                              ) : (
                                <i className="mdi mdi-trash-can"></i>
                              )}
                            </Button>
                          </Whisper>
                        </>
                      )}
                      <Button
                        size="sm"
                        color="dark"
                        outline
                        disabled={this.state.deletingTopic == topic?._id}
                        onClick={() => {
                          LinkUtils.openInNewTab(
                            `/university/library/topics/${topic?._id}`,
                            true
                          );
                        }}
                      >
                        View
                      </Button>
                    </Col>
                  </Row>
                </div>
              </>
            ))}
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(LibraryAllTopicsDrawer);
