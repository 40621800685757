import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
  Badge,
  FormGroup,
} from "reactstrap";
import Alert from "reactstrap/lib/Alert";
import { SelectPicker } from "rsuite";
import CustomerOpenActivitiesWidget from "./CustomerOpenActivitiesWidget";
import CustomerUnclaimedBoxWidget from "./CustomerUnclaimedBoxWidget";
import Constant from "../../lib/Constant";
import _ from "underscore";

class FranDevLeadSourceWidget extends React.Component {
  state = {
    expand: false,
    phone: {
      value: "",
    },
    name: "",
    email: "",
  };

  updateSource() {
    this.setState({ sourceSubmitting: true });

    APIV2.modifyFranDevLead(this.props.lead?._id, {
      source: this.state.selectedSource,
      sourceDetails: this.state.sourceDetails ? this.state.sourceDetails : "",
    })
      .then(
        (data) => {
          PubSub.publish(Event.LEAD.MODIFIED, data.data?.lead);

          this.setState({ modifySource: false });
        },
        () => {
          this.setError("error", "Unable to modify lead. Please try again.");
        }
      )
      .finally(() => {
        this.setState({ sourceSubmitting: false });
      });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 4000);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  render() {
    return (
      <>
        <Card className="mb-4 shadow border">
          <CardHeader>
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Source</h3>
              </div>
            </Row>
          </CardHeader>
          {this.props.loading ? (
            <CardBody>
              <Spinner size="sm" color="dark" className="mr-2" /> Loading...
            </CardBody>
          ) : (
            <>
              <CardBody className="bg-transparent p-0">
                <div className="px-4 py-3 border-bottom">
                  {this.state.error ? (
                    <>
                      <Alert color="danger" className="mb-3 mt-0">
                        {this.state.error}
                      </Alert>
                    </>
                  ) : null}

                  <Row className="align-items-center">
                    <Col xs="auto">Source:</Col>
                    <Col xs="" className="pl-0">
                      {!this.state.modifySource && (
                        <div>
                          {this.props.lead?.source
                            ? this.props.lead?.source
                            : "--"}
                        </div>
                      )}
                    </Col>
                    <Col xs="auto">
                      {this.state.modifySource ? (
                        <>
                          <Button
                            onClick={() => {
                              this.setState({ modifySource: false });
                            }}
                            size="sm"
                            outline
                            color="secondary"
                            disabled={this.state.sourceSubmitting}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              this.updateSource();
                            }}
                            disabled={this.state.sourceSubmitting}
                            size="sm"
                            color="primary"
                          >
                            {this.state.sourceSubmitting ? (
                              <>
                                <Spinner size="sm" color="white" />
                              </>
                            ) : (
                              <>Save</>
                            )}
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => {
                            this.setState({
                              modifySource: true,
                              selectedSource: this.props.lead?.source,
                              sourceDetails: this.props.lead?.sourceDetails
                                ? this.props.lead?.sourceDetails
                                : "",
                            });
                          }}
                          size="sm"
                          color="secondary"
                          outline
                        >
                          Edit
                        </Button>
                      )}
                    </Col>
                    {this.props.lead?.sourceDetails &&
                    !this.state.modifySource ? (
                      <>
                        <Col xs="12"></Col>
                        <Col xs="auto">Details:</Col>
                        <Col xs="" className="pl-0">
                          {this.props.lead?.sourceDetails}
                        </Col>
                      </>
                    ) : null}
                    {this.state.modifySource ? (
                      <Col xs="12" className="mt-3">
                        <FormGroup>
                          <h5>Source:</h5>
                          <SelectPicker
                            placement="autoVertical"
                            preventOverflow={true}
                            placeholder="Select A Source"
                            data={Constant.SELECT_PICKER.FRANDEV_LEAD_SOURCES}
                            value={this.state.selectedSource}
                            cleanable={false}
                            valueKey="label"
                            block
                            onChange={(value, item) => {
                              this.setState({
                                selectedSource: value,
                              });
                            }}
                          ></SelectPicker>
                        </FormGroup>
                        <FormGroup>
                          <h5>Details:</h5>
                          <Input
                            type="textarea"
                            name="sourceDetails"
                            placeholder="Details & Additional Information"
                            value={this.state.sourceDetails}
                            onChange={(e) => {
                              this.handleInputChange(e);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    ) : null}
                  </Row>
                </div>
              </CardBody>
            </>
          )}
        </Card>
      </>
    );
  }
}

export default withRouter(FranDevLeadSourceWidget);
