import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  Uploader,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import ReactGoogleAutocomplete from "react-google-autocomplete";

class FranchisePartnerDrawer extends React.Component {
  state = {
    dueDate: moment().toDate(),
    subject: "",
    description: "",
    relatedToType: "",
    type: "",
    priority: "Normal",
    status: "Not Started",
    assignedTo: null,
    timeToContact: "",
    customers: [],
    stores: [],
    store: "",
    phone: {
      value: "",
    },
    decisionMaker: "yes",
  };

  componentDidUpdate(prevProps) {
    if (this.props.store != prevProps.store) {
      this.setState({
        store: this.props.store?._id,
      });
    }

    if (this.props.partner != prevProps.partner) {
      this.loadPartner();
    }
  }

  loadPartner() {
    this.setState({
      name: this.props.partner?.name ?? "",
      image: this.props.partner?.imageURL ?? "",
      email: this.props.partner?.email ?? "",
      phone: { value: this.props.partner?.phone?.replace("+1", "") ?? "" },
      occupation: this.props.partner?.occupation ?? "",
      notes: this.props.partner?.notes ?? "",
      location: this.props.partner?.location ?? "",
      mailingAddress: this.props.partner?.mailingAddress ?? "",
      decisionMaker: this.props.partner?.decisionMaker ? "yes" : "no",
    });

    if (this.props.partner?.imageURL) {
      this.getImageFromURL(this.props.partner.imageURL);
    }
  }

  componentDidMount() {
    if (this.props.store) {
      this.setState({
        store: this.props.store?._id,
      });
    }

    if (this.props.partner) {
      this.loadPartner();
    }
  }

  toggleModal() {
    this.setState({
      name: "",
      email: "",
      phone: {
        value: "",
      },
      occupation: "",
      notes: "",
      image: "",
      imageFile: null,
      decisionMaker: "yes",
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  async create() {
    this.setState({ submitting: true });

    let imageURL = "";

    if (this.state.imageFile?.length) {
      try {
        // Create an object of formData
        let file = new FormData();

        file.append("file", this.state.imageFile[0].blobFile);

        let result = await APIV2.uploadPrivateFile(file);

        imageURL = result.data.data.url;
      } catch (e) {}
    }

    console.log(imageURL);

    APIV2.addFranchisePartnerToStore(
      this.props?.store?._id,
      this.state.name,
      this.state.email,
      this.state.phone?.value ?? "",
      imageURL,
      this.state.mailingAddress,
      this.state.location,
      this.state.occupation,
      this.state.notes,
      this.state.decisionMaker == "yes"
    )
      .then(
        (data) => {
          const store = data.data.store;

          this.toggleModal();

          PubSub.publish(Event.STORE.MODIFIED, store);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to add partner to store - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  handleGoogleAutocomplete(place, ref) {
    let streetNumber = "";
    let address1 = "";
    let city = "";
    let state = "";
    let zip = "";

    if (
      !place ||
      !place.address_components ||
      !place.address_components.length
    ) {
      return;
    }

    for (let i = 0; i < place.address_components.length; i++) {
      let comp = place.address_components[i];

      if (comp.types && comp.types.indexOf("street_number") >= 0) {
        streetNumber = comp.long_name;
      }

      if (comp.types && comp.types.indexOf("route") >= 0) {
        address1 = comp.long_name;
      }

      if (comp.types && comp.types.indexOf("locality") >= 0) {
        city = comp.long_name;
      }

      if (
        comp.types &&
        comp.types.indexOf("administrative_area_level_1") >= 0
      ) {
        state = comp.long_name;
      }

      if (comp.types && comp.types.indexOf("postal_code") >= 0) {
        zip = comp.long_name;
      }
    }

    this.setState({
      mailingAddress: `${streetNumber} ${address1}, ${city}, ${state} ${zip}`,
    });

    if (!this.state.location) {
      this.setState({
        location: `${city}, ${state}`,
      });
    }

    ref.value = `${streetNumber} ${address1}, ${city}, ${state} ${zip}`;
  }

  getImageFromURL(url) {
    APIV2.getPrivateFileURL(url).then((data) => {
      this.setState({ imageFullURL: data.data.url });
    });
  }

  async modify() {
    this.setState({ submitting: true });

    let imageURL = this.state.image ?? "";

    if (this.state.imageFile?.length) {
      try {
        // Create an object of formData
        let file = new FormData();

        file.append("file", this.state.imageFile[0].blobFile);

        let result = await APIV2.uploadPrivateFile(file);

        imageURL = result.data.data.url;
      } catch (e) {}
    }

    APIV2.modifyFranchisePartnerForStore(
      this.props?.store?._id,
      this.props.partner?.id,
      this.state.name,
      this.state.email,
      this.state.phone?.value ?? "",
      imageURL,
      this.state.mailingAddress,
      this.state.location,
      this.state.occupation,
      this.state.notes,
      this.state.decisionMaker == "yes"
    )
      .then(
        (data) => {
          const store = data.data.store;

          this.toggleModal();

          PubSub.publish(Event.STORE.MODIFIED, store);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to modify partner - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  onFileChange(e) {
    let files = [];

    files = e.length ? [e[e.length - 1]] : [];

    this.setState({ imageFile: files });
  }

  handlePhoneChange(values) {
    this.setState({
      phone: values,
    });
  }

  render() {
    return (
      <>
        <Drawer
          size="md"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.partner ? "Modify Partner" : "Add Partner"}
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={
                  this.state.submitting || !this.state.name || !this.state.email
                }
                color="primary"
                onClick={() => {
                  if (this.props.partner) {
                    return this.modify();
                  }

                  this.create();
                }}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  <>{this.props.partner ? "Save" : "Add"}</>
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            <Row>
              <Col xs="12" md="4" className="mb-3 mb-md-0">
                <h4 className="mb-3">Image</h4>
                {this.state.image ? (
                  <>
                    <div className="border text-center">
                      <img
                        src={this.state.imageFullURL}
                        style={{ width: "100%", maxWidth: 200 }}
                        alt="Partner"
                      ></img>
                    </div>
                    <Button
                      onClick={() => {
                        this.setState({ image: "" });
                      }}
                      size="sm"
                      color="danger"
                      outline
                      className="mt-3"
                    >
                      Remove
                    </Button>
                  </>
                ) : (
                  <>
                    <Uploader
                      fileList={this.state.imageFile}
                      autoUpload={false}
                      action="#"
                      onChange={this.onFileChange.bind(this)}
                      draggable={true}
                      listType="picture-text"
                      multiple={false}
                      renderFileInfo={(file, fileElement) => {
                        return (
                          <div className="text-truncate">
                            <span>File Name: {file.name}</span>
                          </div>
                        );
                      }}
                    >
                      <div style={{ height: 200 }}>
                        <div
                          style={{
                            position: "relative",
                            top: "50%",
                            transform: "translateY(-50%)",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Click or Drag an image to this area to upload
                        </div>
                      </div>
                    </Uploader>
                  </>
                )}
              </Col>
              <Col xs="12" md="8">
                <h4 className="mb-3">Partner Information</h4>
                <FormGroup>
                  <h5>
                    Decision Maker
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="decisionMaker"
                    placeholder="Decision Maker"
                    value={this.state.decisionMaker}
                    onChange={this.handleInputChange.bind(this)}
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <h5>
                    Name
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={this.state.name}
                    onChange={this.handleInputChange.bind(this)}
                  ></Input>
                </FormGroup>
                <FormGroup>
                  <h5>
                    Email
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  <Input
                    bsSize="sm"
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={this.handleInputChange.bind(this)}
                  ></Input>
                </FormGroup>
                <FormGroup>
                  <h5>Phone</h5>
                  <NumberFormat
                    className="form-control form-control-sm mb-3"
                    format="(###) ###-####"
                    mask=""
                    name="phoneNumberInput"
                    placeholder="Phone Number (Optional)"
                    onValueChange={this.handlePhoneChange.bind(this)}
                    value={this.state.phone.value}
                    type="tel"
                  />
                </FormGroup>
                <FormGroup>
                  <h5>Mailing Address</h5>
                  <ReactGoogleAutocomplete
                    required
                    placeholder="Mailing Address"
                    apiKey={"AIzaSyA5-fbnedb5uXDBwbjnz0J_2eDe_9Gi2Cs"}
                    onPlaceSelected={this.handleGoogleAutocomplete.bind(this)}
                    onChange={this.handleInputChange.bind(this).bind(this)}
                    name="mailingAddress"
                    options={{ types: ["address"] }}
                    value={this.state.mailingAddress}
                    className="form-control form-control-sm"
                    //value={this.state.address1}
                  ></ReactGoogleAutocomplete>
                </FormGroup>
                <FormGroup>
                  <h5>Location</h5>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="location"
                    placeholder="City, State"
                    value={this.state.location}
                    onChange={this.handleInputChange.bind(this)}
                  ></Input>
                </FormGroup>
                <FormGroup>
                  <h5>Occupation</h5>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="occupation"
                    placeholder="Occupation"
                    value={this.state.occupation}
                    onChange={this.handleInputChange.bind(this)}
                  ></Input>
                </FormGroup>
                <FormGroup>
                  <h5>Notes</h5>
                  <Input
                    bsSize="sm"
                    type="textarea"
                    name="notes"
                    placeholder="Notes"
                    value={this.state.notes}
                    onChange={this.handleInputChange.bind(this)}
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(FranchisePartnerDrawer);
