import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  Checkbox,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";

class LibraryCategoryEditDrawer extends React.Component {
  state = {
    name: "",
    description: "",
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.category != prevProps.category ||
      this.props.open != prevProps.open
    ) {
      if (this.props.category) {
        this.setState({
          name: this.props.category?.name,
          description: this.props.category?.description,
          category: this.props.category,
          internal: this.props.category?.internal ? true : false,
        });
      } else {
        this.setState({
          name: "",
          description: "",
          category: null,
          internal: false,
        });
      }
    }
  }

  componentDidMount() {
    if (this.props.category) {
      this.setState({
        name: this.props.category?.name,
        description: this.props.category?.description,
        category: this.props.category,
        internal: this.props.category?.internal ? true : false,
      });
    } else {
      this.setState({
        name: "",
        description: "",
        category: null,
        internal: false,
      });
    }
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  create() {
    this.setState({ submitting: true });

    APIV2.createLibraryCategory(
      this.state.name,
      this.state.description,
      this.state.internal
    )
      .then(
        (data) => {
          const category = data.data.category;

          this.setState({
            name: "",
            description: "",
            internal: false,
          });

          if (typeof this.props.onCreate == "function") {
            this.props.onCreate(category);
          }
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create category - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  modify() {
    this.setState({ submitting: true });

    APIV2.modifyLibraryCategory(
      this.state.category?._id,
      this.state.name,
      this.state.description,
      this.state.internal
    )
      .then(
        (data) => {
          const category = data.data.category;

          this.setState({
            name: "",
            description: "",
            internal: false,
          });

          if (typeof this.props.onModify == "function") {
            this.props.onModify(category);
          }
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to modify category - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => {
            if (typeof this.props.onClose == "function") {
              this.props.onClose();
            }
          }}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.category ? "Modify" : "New"} Category
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={this.state.submitting || !this.state.name}
                color="primary"
                onClick={() => {
                  if (this.props.category) {
                    this.modify();
                  } else {
                    this.create();
                  }
                }}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  <>{this.props.category ? "Save" : "Add"}</>
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            <h4 className="mb-3">Category Information</h4>
            <FormGroup>
              <h5>Name</h5>
              <Input
                bsSize="sm"
                type="text"
                name="name"
                placeholder="Name"
                value={this.state.name}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Description</h5>
              <Input
                bsSize="sm"
                type="textarea"
                name="description"
                placeholder="Description"
                value={this.state.description}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Internal Category</h5>
              <p className="small" style={{ lineHeight: 1.2 }}>
                This category will only be visible to the Franchise Development
                & Production teams
              </p>
              <Checkbox
                checked={this.state.internal}
                onChange={(e, v) => {
                  this.setState({ internal: v });
                }}
              >
                Internal
              </Checkbox>
            </FormGroup>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(LibraryCategoryEditDrawer);
