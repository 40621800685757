import StarterKit from "@tiptap/starter-kit";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import TiptapLink from "@tiptap/extension-link";
import TiptapImage from "@tiptap/extension-image";
import Placeholder from "@tiptap/extension-placeholder";
import TiptapUnderline from "@tiptap/extension-underline";
import TextStyle from "@tiptap/extension-text-style";
import { Color } from "@tiptap/extension-color";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import { Markdown } from "tiptap-markdown";
import Highlight from "@tiptap/extension-highlight";

import SlashCommand from "./slash-command";
import { InputRule } from "@tiptap/core";
import UploadImagesPlugin from "../plugins/upload-images";
import PubSub from "lib/PubSub";

const CustomImage = TiptapImage.extend({
  addProseMirrorPlugins() {
    return [UploadImagesPlugin()];
  },
});

export const TiptapExtensions = (allowTaskLists) => {
  let out = [
    StarterKit.configure({
      bulletList: {
        HTMLAttributes: {
          class: "mt-3 note-bulletlist",
        },
      },
      orderedList: {
        HTMLAttributes: {
          class: "mt-3 note-orderedlist",
        },
      },
      listItem: {
        HTMLAttributes: {
          class: "leading-normal -mb-2",
        },
      },
      blockquote: {
        HTMLAttributes: {
          class: "notes-blockquote",
        },
      },
      codeBlock: {
        HTMLAttributes: {
          class: "rounded bg-ultralight border p-4 text-dark",
        },
      },
      code: {
        HTMLAttributes: {
          class:
            "rounded-md bg-stone-200 px-1.5 py-1 font-mono font-medium text-stone-900",
          spellcheck: "false",
        },
      },
      horizontalRule: false,
      dropcursor: {
        color: "#DBEAFE",
        width: 4,
      },
      gapcursor: false,
    }),
    // patch to fix horizontal rule bug: https://github.com/ueberdosis/tiptap/pull/3859#issuecomment-1536799740
    HorizontalRule.extend({
      addInputRules() {
        return [
          new InputRule({
            find: /^(?:---|—-|___\s|\*\*\*\s)$/,
            handler: ({ state, range }) => {
              const attributes = {};

              const { tr } = state;
              const start = range.from;
              let end = range.to;

              tr.insert(start - 1, this.type.create(attributes)).delete(
                tr.mapping.map(start),
                tr.mapping.map(end)
              );
            },
          }),
        ];
      },
    }).configure({
      HTMLAttributes: {
        class: "mt-4 mb-6 border-t border-stone-300",
      },
    }),
    TiptapLink.configure({
      HTMLAttributes: {
        class:
          "text-stone-400 underline underline-offset-[3px] hover:text-stone-600 transition-colors cursor-pointer",
      },
    }),
    CustomImage.configure({
      allowBase64: true,
      inline: true,
      HTMLAttributes: {
        class: "notes-image rounded border",
      },
    }),
    Placeholder.configure({
      placeholder: ({ node }) => {
        //console.log(node);

        if (node.type.name === "heading") {
          return `Heading ${node.attrs.level}`;
        }
        return "Press '/' for commands, or type to continue...";
      },
      includeChildren: true,
    }),
    SlashCommand,
    TiptapUnderline,
    TextStyle,
    Color,
    Highlight.configure({
      multicolor: true,
    }),
    TaskList.configure({
      HTMLAttributes: {
        class: "pl-2",
      },
    }),
    TaskItem.configure({
      HTMLAttributes: {
        class: "d-flex align-items-start my-3 note-taskitem",
      },
      nested: true,
      onReadOnlyChecked: (node, checked) => {
        console.log("Test", node, checked);

        node.attrs.checked = checked;

        PubSub.publish("editor:contentChangedReadOnly", {
          node,
          checked,
        });

        return node;
      },
    }),
    Markdown.configure({
      html: false,
      transformCopiedText: true,
    }),
  ];

  let noLists = [
    StarterKit.configure({
      bulletList: {
        HTMLAttributes: {
          class: "mt-3 note-bulletlist",
        },
      },
      orderedList: {
        HTMLAttributes: {
          class: "mt-3 note-orderedlist",
        },
      },
      listItem: {
        HTMLAttributes: {
          class: "leading-normal -mb-2",
        },
      },
      blockquote: {
        HTMLAttributes: {
          class: "notes-blockquote",
        },
      },
      codeBlock: {
        HTMLAttributes: {
          class: "rounded bg-ultralight border p-4 text-dark",
        },
      },
      code: {
        HTMLAttributes: {
          class:
            "rounded-md bg-stone-200 px-1.5 py-1 font-mono font-medium text-stone-900",
          spellcheck: "false",
        },
      },
      horizontalRule: false,
      dropcursor: {
        color: "#DBEAFE",
        width: 4,
      },
      gapcursor: false,
    }),
    // patch to fix horizontal rule bug: https://github.com/ueberdosis/tiptap/pull/3859#issuecomment-1536799740
    HorizontalRule.extend({
      addInputRules() {
        return [
          new InputRule({
            find: /^(?:---|—-|___\s|\*\*\*\s)$/,
            handler: ({ state, range }) => {
              const attributes = {};

              const { tr } = state;
              const start = range.from;
              let end = range.to;

              tr.insert(start - 1, this.type.create(attributes)).delete(
                tr.mapping.map(start),
                tr.mapping.map(end)
              );
            },
          }),
        ];
      },
    }).configure({
      HTMLAttributes: {
        class: "mt-4 mb-6 border-t border-stone-300",
      },
    }),
    TiptapLink.configure({
      HTMLAttributes: {
        class:
          "text-stone-400 underline underline-offset-[3px] hover:text-stone-600 transition-colors cursor-pointer",
      },
    }),
    CustomImage.configure({
      allowBase64: true,
      HTMLAttributes: {
        class: "notes-image rounded border",
      },
    }),
    Placeholder.configure({
      emptyNodeClass: "my-custom-is-empty-class",
      placeholder: ({ node }) => {
        if (node.type.name === "heading") {
          return `Heading ${node.attrs.level}`;
        }
        return "Press '/' for commands, or type to continue...";
      },
      includeChildren: true,
    }),
    SlashCommand,
    TiptapUnderline,
    TextStyle,
    Color,
    Highlight.configure({
      multicolor: true,
    }),

    Markdown.configure({
      html: false,
      transformCopiedText: true,
    }),
  ];

  if (allowTaskLists) {
    return out;
  }

  return noLists;
};
