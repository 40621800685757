import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import Spinner from "reactstrap/lib/Spinner";
import swal from "sweetalert";

class MealCategoryList extends React.Component {
  state = {
    loading: true,
    categories: [],
  };

  componentDidMount() {
    this.loadCategories();
  }

  modify(id, name) {
    this.setState({
      submitting: id,
    });

    APIV2.modifyCategory(id, name)
      .then(
        (data) => {
          let c = data.data.category;

          let i = findIndex(this.state.categories, { _id: c._id });

          let cats = this.state.categories;

          cats[i] = c;

          this.setState({
            categories: cats,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  delete(id, name) {
    swal({
      title: "Delete '" + name + "' Category?",
      text:
        "Are you sure you want to delete the " +
        name +
        " category? This cannot be undone.",
      icon: "warning",
      buttons: ["Nevermind", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      this.setState({
        submitting: id,
      });

      APIV2.deleteCategory(id)
        .then(
          (data) => {
            let cats = filter(this.state.categories, (c) => {
              return c._id != id;
            });

            this.setState({
              categories: cats,
            });
          },
          (e) => {
            console.error(e);
          }
        )
        .finally(() => {
          this.setState({
            submitting: false,
          });
        });
    });
  }

  create(name) {
    this.setState({
      submitting: true,
    });

    APIV2.createCategory(name)
      .then(
        (data) => {
          let c = data.data.category;

          let cats = this.state.categories;

          cats.push(c);

          this.setState({
            categories: cats,
            name: "",
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  loadCategories() {
    this.setState({
      loading: true,
    });

    APIV2.getCategories()
      .then(
        (data) => {
          if (data.data && data.data.categories) {
            this.setState({
              categories: data.data.categories,
            });
          }
        },
        () => {
          window.alert("Failed to get categories. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader
            sticky={true}
            title="Meal Categories"
            showBackButton={false}
            primaryAction={{
              name: <>Add</>,
              onClick: () => {
                this.setState({
                  showCreate: true,
                });
              },
            }}
          />
          <div
            style={{ marginLeft: -15, marginRight: -15 }}
            className="mt--4 bg-white border-bottom"
          >
            {!this.state.loading ? (
              <Table
                style={{ minHeight: "300px" }}
                className="mb-0"
                responsive={true}
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scole="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.showCreate ? (
                    <tr>
                      <td className="align-middle">
                        <Input
                          type="text"
                          bsSize="sm"
                          value={this.state.name}
                          placeholder="Name"
                          onChange={(e) => {
                            let name = e.target.value;

                            this.setState({ name });
                          }}
                        />
                      </td>
                      <td className="text-right align-middle">
                        <Button
                          size="sm"
                          outline
                          color="secondary"
                          onClick={() => {
                            this.setState({ showCreate: false, name: "" });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="sm"
                          color="primary"
                          disabled={
                            !this.state.name || this.state.submitting === true
                          }
                          onClick={() => this.create(this.state.name)}
                        >
                          {this.state.submitting === true ? (
                            <Spinner size="sm" color="white" />
                          ) : (
                            <>Create</>
                          )}
                        </Button>
                      </td>
                    </tr>
                  ) : null}
                  {!this.state.categories?.length ? (
                    <tr>
                      <td colSpan="3">
                        Get started by adding the first meal category.
                      </td>
                    </tr>
                  ) : null}
                  {this.state.categories.map((c, i) => (
                    <tr>
                      <td className="align-middle">
                        {c.edit ? (
                          <Input
                            placeholder="Name"
                            type="text"
                            bsSize="sm"
                            value={c.name}
                            onChange={(e) => {
                              c.name = e.target.value;
                              let cats = this.state.categories;

                              let i = findIndex(cats, { _id: c._id });

                              cats[i] = c;

                              this.setState({ categories: cats });
                            }}
                          />
                        ) : (
                          <h4 className="m-0">{c.name}</h4>
                        )}
                      </td>
                      <td className="text-right align-middle">
                        {c.edit ? (
                          <>
                            <Button
                              size="sm"
                              outline
                              color="secondary"
                              onClick={() => {
                                c.edit = false;
                                c.name = c.oldName;
                                let cats = this.state.categories;

                                let i = findIndex(cats, { _id: c._id });

                                cats[i] = c;

                                this.setState({ categories: cats });
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              size="sm"
                              color="primary"
                              disabled={
                                !c.name || this.state.submitting == c._id
                              }
                              onClick={() => this.modify(c._id, c.name)}
                            >
                              {this.state.submitting == c._id ? (
                                <Spinner size="sm" color="white" />
                              ) : (
                                <>Save</>
                              )}
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              size="sm"
                              outline
                              color="secondary"
                              className="btn-icon-only"
                              disabled={this.state.submitting == c._id}
                              onClick={() => {
                                c.edit = true;
                                c.oldName = c.name;
                                let cats = this.state.categories;

                                let i = findIndex(cats, { _id: c._id });

                                cats[i] = c;

                                this.setState({ categories: cats });
                              }}
                            >
                              <i className="mdi mdi-pencil"></i>
                            </Button>
                            <Button
                              size="sm"
                              outline
                              color="danger"
                              className="btn-icon-only"
                              disabled={this.state.submitting == c._id}
                              onClick={() => this.delete(c._id, c.name)}
                            >
                              {this.state.submitting == c._id ? (
                                <Spinner size="sm" color="danger" />
                              ) : (
                                <i className="mdi mdi-delete"></i>
                              )}
                            </Button>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light"></thead>
                <tbody>
                  <tr>
                    <td>Loading...</td>
                  </tr>
                </tbody>
              </Table>
            )}
          </div>
        </Container>
      </>
    );
  }
}

export default MealCategoryList;
