import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import Constant from "lib/Constant";

class NewOutreachDrawer extends React.Component {
  state = {
    startsAt: moment().hours(0).minutes(0).seconds(0).millisecond(0).toDate(),
    endsAt: null,
    affiliateID: null,
    notes: "",
    type: "or-popup",
    types: [
      {
        label: Constant.APPOINTMENT_TYPES["or-popup"].SHORT_NAME,
        value: "or-popup",
      },
      {
        label: Constant.APPOINTMENT_TYPES["or-groupsweat"].SHORT_NAME,
        value: "or-groupsweat",
      },
      {
        label: Constant.APPOINTMENT_TYPES["or-dropin"].SHORT_NAME,
        value: "or-dropin",
      },
      {
        label: Constant.APPOINTMENT_TYPES["or-inreach"].SHORT_NAME,
        value: "or-inreach",
      },
    ],
    loadingAffiliates: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.event != prevProps.event && this.props.event) {
      this.setState({
        affiliateID: this.props.event?.affiliateID,
        notes: this.props.event?.notes,
        type: this.props.event?.appointmentType,
        startsAt: moment(this.props.event?.startsAt)
          .second(0)
          .millisecond(0)
          .toDate(),
        endsAt: this.props.event?.endsAt
          ? moment(this.props.event?.endsAt).second(0).millisecond(0).toDate()
          : null,
      });
    }

    if (
      this.props.open != prevProps.open &&
      this.props.open &&
      !this.props.event
    ) {
      this.setState({
        startsAt: new Date(),
      });
    }

    if (this.props.affiliate != prevProps.affiliate && this.props.affiliate) {
      this.setState({
        affiliateID: this.props.affiliate?._id,
        affiliate: this.props.affiliate,
      });
    }
  }

  componentDidMount() {
    if (this.props.event) {
      this.setState({
        affiliateID: this.props.event?.affiliateID,
        affiliate: this.props.event?.affiliate,
        notes: this.props.event?.notes,
        type: this.props.event?.appointmentType,
        startsAt: moment(this.props.event?.startsAt)
          .second(0)
          .millisecond(0)
          .toDate(),
        endsAt: this.props.event?.endsAt
          ? moment(this.props.event?.endsAt).second(0).millisecond(0).toDate()
          : null,
      });
    }

    if (this.props.affiliate) {
      this.setState({
        affiliateID: this.props.affiliate?._id,
        affiliate: this.props.affiliate,
      });
    }
  }

  toggleModal() {
    this.setState({
      affiliateID: null,
      notes: "",
      affiliate: null,
      type: "or-popup",
      startDateTime: null,
      endDateTime: null,
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  create() {
    this.setState({ submitting: true });

    let payload = {
      affiliateID: this.state.affiliateID,
      notes: this.state.notes,
      appointmentType: this.state.type,
      dateTime: this.state.startsAt,
      endsAt: this.state.endsAt ? this.state.endsAt : null,
      forceAppointmentType: true,
    };

    APIV2.scheduleAppointment(this.props?.store?._id, payload)
      .then(
        (data) => {
          const appointment = data.data.appointment;

          this.toggleModal();

          PubSub.publish(Event.APPOINTMENT.CREATED, appointment);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              `Unable to schedule event - unknown error occurred. Try again.`
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  modify() {
    this.setState({
      submitting: true,
    });

    let payload = {
      affiliateID: this.state.affiliateID,
      notes: this.state.notes,
      appointmentType: this.state.type,
      dateTime: this.state.startsAt,
      endsAt: this.state.endsAt ? this.state.endsAt : null,
    };

    APIV2.modifyAppointmentInformation(
      this.props.event?.storeID,
      this.props.event?._id,
      payload
    )
      .then(
        (data) => {
          const appointment = data.data.appointment;

          this.toggleModal();

          PubSub.publish(Event.APPOINTMENT.MODIFIED, appointment);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              `Unable to modify event - unknown error occurred. Try again.`
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  handlePhoneChange(values) {
    this.setState({
      phone: values,
    });
  }

  async getExistingAppointments(store, appointmentType, date) {
    return new Promise((resolve, reject) => {
      APIV2.getExistingAppointments(
        store?._id,
        appointmentType?.APPOINTMENT_TYPE,
        date.toISOString()
      ).then(
        (data) => {
          const appointments = data?.data?.appointments
            ? data.data.appointments
            : [];

          resolve(appointments);
        },
        (e) => {
          reject(e);
        }
      );
    });
  }

  formatAddress(address) {
    if (!address?.address1) {
      return "--";
    }

    return `${address?.address1}${
      address?.address2 ? ` ${address.address2}` : ""
    } ${address?.city ? `, ${address?.city}` : ""} ${
      address?.state ? `, ${address.state}` : ""
    } ${address?.zip ? address.zip : ""}`;
  }

  onSearchChange(query) {
    if (!query) {
      this.setState({
        loading: false,
        partners: [],
        query,
      });

      return;
    }

    this.setState({
      query,
      loading: true,
    });

    APIV2.getAffiliatesForStore(
      this.props.store?._id,
      1,
      100,
      {
        name: { $regex: query, $options: "i" },
      },
      {
        name: -1,
      }
    )
      .then(
        (data) => {
          let partners = data?.data?.affiliates?.map((affiliate) => {
            return {
              label: (
                <>
                  <p className="small text-muted m-0" style={{ lineHeight: 1 }}>
                    {affiliate?.type ? affiliate.type : "Partner"}
                  </p>
                  <p className="m-0 text-dark">{affiliate?.name}</p>
                  <p
                    className="small text-muted m-0"
                    style={{ lineHeight: 1.2 }}
                  >
                    {affiliate?.address?.address1
                      ? this.formatAddress(affiliate.address)
                      : affiliate?.phone
                      ? StringUtils.formatPhoneNumber(affiliate.phone)
                      : affiliate?.email}
                  </p>
                </>
              ),
              value: affiliate._id,
              name: affiliate?.name,
              affiliate,
            };
          });

          partners = _.sortBy(partners, "name");

          this.setState({
            partners,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  renderOptions(menu) {
    if (this.state.loading) {
      return <p className="text-muted m-0 py-2 px-3">Searching...</p>;
    }

    if (this.state.query && !this.state.partners?.length) {
      return (
        <p className="text-muted m-0 py-2 px-3">
          No partners found matching your search "{this.state.query}"
        </p>
      );
    }

    if (!this.state.partners?.length && !this.state.query) {
      return (
        <p className="text-muted m-0 py-2 px-3">
          Search & select a community partner
        </p>
      );
    }

    return menu;
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.event ? (
                <>{this.props.mode == "reschedule" ? "Reschedule" : "Modify"}</>
              ) : (
                "Schedule"
              )}{" "}
              Community Event
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={
                  this.state.submitting ||
                  !this.state.type ||
                  !this.state.startsAt
                }
                color="primary"
                onClick={() => {
                  this.props.event ? this.modify() : this.create();
                }}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  <>{this.props.consultation ? "Save" : "Schedule"}</>
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            <FormGroup>
              <h5>
                Event Type
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <SelectPicker
                searchable={false}
                oneTap
                data={this.state.types}
                value={this.state.type}
                block
                placeholder="Select Type"
                onChange={(v) => {
                  this.setState({ type: v });
                }}
                placement="autoVertical"
                preventOverflow={true}
              ></SelectPicker>
            </FormGroup>
            <FormGroup>
              <h5>Community Partner</h5>
              {this.state.affiliate ? (
                <>
                  <div className="p-2 border rounded">
                    <p
                      className="small text-muted m-0"
                      style={{ lineHeight: 1 }}
                    >
                      {this.state.affiliate?.type
                        ? this.state.affiliate.type
                        : "Partner"}
                    </p>
                    <p className="m-0 text-dark">
                      {this.state.affiliate?.name}
                    </p>
                    <p
                      className="small text-muted m-0"
                      style={{ lineHeight: 1.2 }}
                    >
                      {this.state.affiliate?.address?.address1
                        ? this.formatAddress(this.state.affiliate.address)
                        : this.state.affiliate?.phone
                        ? StringUtils.formatPhoneNumber(
                            this.state.affiliate.phone
                          )
                        : this.state.affiliate?.email}
                    </p>
                  </div>
                  <div className="text-right mt-2">
                    <Button
                      onClick={() => {
                        this.setState({
                          affiliate: null,
                          affiliateID: null,
                        });
                      }}
                      size="sm"
                      outline
                      color="danger"
                    >
                      Remove
                    </Button>
                  </div>
                </>
              ) : (
                <SelectPicker
                  placeholder="Select A Partner"
                  data={this.state.partners}
                  onChange={(v) => {
                    let opt = _.findWhere(this.state.partners, { value: v });

                    this.setState({
                      affiliateID: v,
                      affiliate: opt?.affiliate,
                    });
                  }}
                  block
                  loading={this.state.loading}
                  value={this.state.affiliateID}
                  placement="autoVertical"
                  preventOverflow={true}
                  onSearch={this.onSearchChange.bind(this)}
                  renderMenu={this.renderOptions.bind(this)}
                />
              )}
            </FormGroup>
            <FormGroup>
              <h5>
                Event Starts At
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <DatePicker
                required
                block
                placeholder="Select A Date & Time"
                value={this.state.startsAt}
                format="MM/dd/yy hh:mm a"
                placement="autoVertical"
                preventOverflow={true}
                showMeridian={true}
                onChange={(e) => {
                  this.setState({
                    startsAt: e,
                  });
                }}
                ranges={[
                  {
                    label: "Now",
                    value: moment().toDate(),
                    closeOverlay: true,
                  },
                ]}
              ></DatePicker>
            </FormGroup>
            <FormGroup>
              <h5>Event Ends At</h5>
              <DatePicker
                required
                block
                placeholder="Select A Date & Time"
                showMeridian={true}
                value={this.state.endsAt}
                format="MM/dd/yy hh:mm a"
                placement="autoVertical"
                preventOverflow={true}
                onChange={(e) => {
                  this.setState({
                    endsAt: e,
                  });
                }}
                ranges={[
                  {
                    label: "Now",
                    value: moment().toDate(),
                    closeOverlay: true,
                  },
                  {
                    label: "1 Hr. Later",
                    value: moment(this.state.startsAt).add(1, "hour").toDate(),
                    closeOverlay: true,
                  },
                  {
                    label: "2 Hrs. Later",
                    value: moment(this.state.startsAt).add(2, "hour").toDate(),
                    closeOverlay: true,
                  },
                ]}
              ></DatePicker>
            </FormGroup>
            {/** TODO: put affiliates here */}
            <FormGroup>
              <h5>Event Notes</h5>
              <Input
                type="textarea"
                name="notes"
                onChange={this.handleInputChange.bind(this)}
                value={this.state.notes}
                placeholder="Notes"
                bsSize="sm"
              ></Input>
            </FormGroup>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(NewOutreachDrawer);
