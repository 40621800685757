import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import React, { useEffect, useRef } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Collapse,
  Row,
  Spinner,
} from "reactstrap";
import { Dropdown, Popover, Tooltip, Whisper } from "rsuite";
import _ from "underscore";
import LearningCourseSectionDrawer from "../Drawers/LearningCourseSectionDrawer";
import swal from "sweetalert";
import LearningCourseTopicSelectorDrawer from "../Drawers/LearningCourseTopicSelectorDrawer";
import LinkUtils from "lib/LinkUtils";

class LearningCourseTopicEditor extends React.Component {
  state = {
    name: "",
    description: "",
    draft: true,
    overview: {},
    score: null,
    videoURL: "",
  };

  constructor() {
    super();

    this.dropdownRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props?.course != prevProps?.course) {
      this.setState({
        sections: this.props?.course?.sections ?? [],
      });
    }
  }

  componentDidMount() {
    if (this.props.course) {
      this.setState({
        sections: this.props.course.sections ?? [],
      });
    }
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  modify() {
    this.setState({ submitting: true });

    APIV2.modifyLearningCourse(
      this.props?.course?._id,
      this.state.name,
      this.state.description,
      this.state.overview,
      this.state.cover,
      this.state.video,
      this.state.draft
    )
      .then(
        (data) => {
          const staff = data.data.course;

          PubSub.publish(Event.COURSE.MODIFIED, staff);

          this.setState({
            name: "",
            nextRoleID: "",
          });

          this.toggleModal();
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to modify course - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  /**
   * Event handler for after asection is created
   * @param {} course
   */
  onCreateSection(course) {
    this.setState({ sections: course.sections, openSection: false });
  }

  /**
   * Event handler for after a section is modified.
   *
   * @param {*} course
   */
  onModifySection(course) {
    for (let i = 0; i < course?.sections?.length; i++) {
      course.sections[i].open = true;
    }

    this.setState({ sections: course.sections, openSection: false });
  }

  /**
   * Handles deleting a category
   *
   * @param {*} category
   */
  deleteSection(section) {
    swal({
      title: "Delete Section",
      text: "Are you sure you want delete this section? This will also remove all the topics within this section from the course.",
      icon: "warning",
      buttons: ["Nevermind", "Delete"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        deletingSection: section?._id,
      });

      APIV2.deleteLearningCourseSection(this.props.course?._id, section?._id)
        .then(
          (data) => {
            let course = data.data.course;

            for (let i = 0; i < course?.sections?.length; i++) {
              course.sections[i].open = true;
            }

            this.setState({ sections: course.sections });
          },
          (e) => {
            alert("Unable to delete section. Please try again.");
          }
        )
        .finally(() => {
          this.setState({ deletingSection: "" });
        });
    });
  }

  /**
   * Helper function to handle reorder events from react-beautiful-dnd library
   *
   * @param {*} list
   * @param {*} startIndex
   * @param {*} endIndex
   * @returns
   */
  reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  /**
   * Event handler for when a category drag has ended.
   *
   * @param {*} result
   * @returns
   */
  onSectionDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.modifySections,
      result.source.index,
      result.destination.index
    );

    this.setState({
      modifySections: items,
    });
  }

  onTopicDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.topicOrder,
      result.source.index,
      result.destination.index
    );

    this.setState({
      topicOrder: items,
    });
  }

  saveSectionOrder() {
    if (!this.state.modifySections?.length) {
      this.setState({
        reorderSections: false,
      });

      return;
    }

    this.setState({
      reorderSectionsSubmitting: true,
    });

    let order = this.state.modifySections;

    APIV2.reorderLearningCourseSections(this.props.course?._id, order)
      .then(
        (data) => {
          this.setState({
            sections: data?.data?.course?.sections,
            reorderSections: false,
          });
        },
        (e) => {
          alert("Unable to save order of sections. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          reorderSectionsSubmitting: false,
        });
      });
  }

  saveTopicOrder(section, topics) {
    if (!topics?.length) {
      this.setState({
        reorderTopics: false,
      });

      return;
    }

    this.setState({
      reorderTopicsSubmitting: true,
    });

    let order = topics.map((topic, i) => {
      return { topicID: topic?.topicID, order: i + 1 };
    });

    APIV2.reorderLearningCourseSectionTopics(
      this.props.course?._id,
      section?._id,
      order
    )
      .then(
        (data) => {
          let course = data?.data?.course;

          for (let i = 0; i < course?.sections?.length; i++) {
            course.sections[i].open = true;
          }

          this.setState({
            sections: course.sections,
            reorderTopics: false,
            reorderTopicsSubmitting: false,
          });
        },
        (e) => {
          alert("Unable to save order of topics. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          reorderSectionsSubmitting: false,
        });
      });
  }

  addTopicToSection(section, topic) {
    APIV2.addLearningCourseTopicToSection(
      this.props.course?._id,
      section?._id,
      topic?._id
    )
      .then(
        (data) => {
          let cat = data.data.course;

          for (let i = 0; i < cat?.sections?.length; i++) {
            cat.sections[i].open = true;
          }

          let modifySection = cat?.sections.find(
            (s) => s._id == this.state.modifySection?._id
          );

          this.setState({ sections: cat?.sections, modifySection });
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to add topic top section - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  removeTopicFromSection(section, topic) {
    swal({
      title: "Remove Topic From Section",
      text: "Are you sure you want remove this topic from the section?",
      icon: "warning",
      buttons: ["Nevermind", "Remove"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        removingTopic: topic?._id,
      });

      APIV2.removeLearningCourseTopicFromSection(
        this.props.course?._id,
        section?._id,
        topic?._id
      )
        .then(
          (data) => {
            let course = data.data.course;

            for (let i = 0; i < course?.sections?.length; i++) {
              course.sections[i].open = true;
            }

            this.setState({ sections: course.sections });
          },
          (e) => {
            alert("Unable to remove topic from section. Please try again.");
          }
        )
        .finally(() => {
          this.setState({ removingModule: "" });
        });
    });
  }

  getTotalQuestions(section) {
    let total = 0;

    for (let i = 0; i < section?.topics?.length; i++) {
      total += section?.topics[i]?.topic?.questions?.length
        ? section?.topics[i]?.topic?.questions?.length
        : 0;
    }

    return total;
  }

  render() {
    return (
      <>
        <Row className="align-items-center mb-4">
          <Col xs="">
            <h2 className="m-0">Content</h2>
          </Col>
          <Col xs="auto">
            {this.state.reorderSections ? (
              <>
                <Button
                  color="secondary"
                  outline
                  onClick={() => {
                    this.setState({
                      reorderSections: false,
                      modifySections: [],
                    });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  disabled={this.state.reorderSectionsSubmitting}
                  onClick={() => {
                    this.saveSectionOrder();
                  }}
                >
                  {this.state.reorderSectionsSubmitting ? (
                    <>
                      <Spinner size="sm"></Spinner>
                    </>
                  ) : (
                    <>Save Order</>
                  )}
                </Button>
              </>
            ) : (
              <>
                <Whisper
                  trigger="hover"
                  placement="autoHorizontal"
                  delayOpen={400}
                  speaker={
                    <Tooltip>
                      <p className="m-0 text-white">Reorder sections</p>
                    </Tooltip>
                  }
                >
                  <Button
                    color="secondary"
                    outline
                    className="btn-icon-only"
                    onClick={() => {
                      this.setState({
                        reorderSections: true,
                        modifySections: JSON.parse(
                          JSON.stringify(this.state.sections ?? [])
                        ),
                      });
                    }}
                  >
                    <i className="mdi mdi-swap-vertical-bold"></i>
                  </Button>
                </Whisper>

                <Whisper
                  trigger="hover"
                  placement="autoHorizontal"
                  delayOpen={400}
                  speaker={
                    <Tooltip>
                      <p className="m-0 text-white">Add section</p>
                    </Tooltip>
                  }
                >
                  <Button
                    color="primary"
                    className="btn-icon-only"
                    onClick={() => {
                      this.setState({ openSection: true });
                    }}
                  >
                    <i className="mdi mdi-plus"></i>
                  </Button>
                </Whisper>
              </>
            )}
          </Col>
        </Row>
        {this.state.sections?.length ? (
          <>
            {this.state.reorderSections ? (
              <>
                <DragDropContext onDragEnd={this.onSectionDragEnd.bind(this)}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDraggingOver
                            ? "var(--primary-superlight)"
                            : "",
                          borderRadius: 6,
                        }}
                      >
                        {this.state.modifySections.map((section, index) => (
                          <Draggable
                            key={section._id}
                            draggableId={section._id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="my-2"
                              >
                                <Card
                                  className={`cursor-pointer ${
                                    snapshot.isDragging ? "shadow" : ""
                                  } border`}
                                >
                                  <CardBody>
                                    <Row className="align-items-center">
                                      <Col xs="auto" className="pr-0 pl-2">
                                        <i
                                          className="mdi mdi-drag text-dark"
                                          style={{ fontSize: 25 }}
                                        ></i>
                                      </Col>
                                      <Col xs="">
                                        <h3 className="m-0 text-dark">
                                          {section?.name}
                                        </h3>
                                        <p className="m-0 small text-dark">
                                          {section?.description}
                                        </p>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </>
            ) : (
              <>
                {this.state.sections?.map((section, i) => (
                  <Card
                    className="border shadow bg-white mb-3"
                    key={section?._id}
                  >
                    <CardHeader className="cursor-pointer">
                      <Row className="align-items-center">
                        <Col
                          xs="auto"
                          className="pr-0 pl-2"
                          onClick={() => {
                            let sections = this.state?.sections;

                            const idx = _.findIndex(sections, {
                              _id: section?._id,
                            });

                            if (idx >= 0) {
                              sections[idx].open = !sections[idx].open;
                            }

                            this.setState({
                              module,
                            });
                          }}
                        >
                          <Button
                            color="dark"
                            outline
                            className="border-0 btn-icon-only"
                          >
                            <i
                              className={`mdi mdi-${
                                section?.open ? "chevron-down" : "chevron-right"
                              }`}
                              style={{ fontSize: 28 }}
                            ></i>
                          </Button>
                        </Col>
                        <Col
                          xs=""
                          onClick={() => {
                            let sections = this.state?.sections;

                            const idx = _.findIndex(sections, {
                              _id: section?._id,
                            });

                            if (idx >= 0) {
                              sections[idx].open = !sections[idx].open;
                            }

                            this.setState({
                              sections,
                            });
                          }}
                        >
                          <h2 className="m-0 text-dark">{section?.name}</h2>
                          {section?.description ? (
                            <>
                              <p className="m-0 text-dark">
                                {section?.description}
                              </p>
                            </>
                          ) : null}
                          <div className="m-1">
                            <Badge color="medium">
                              {this.getTotalQuestions(section)}&nbsp;Question
                              {this.getTotalQuestions(section) == 1 ? "" : "s"}
                            </Badge>
                          </div>
                        </Col>
                        {this.state.reorderTopics != section?._id && (
                          <Col xs="auto">
                            <Whisper
                              trigger="hover"
                              placement="autoHorizontal"
                              delayOpen={400}
                              speaker={
                                <Tooltip>
                                  <p className="m-0 text-white">
                                    Modify section name &amp; description
                                  </p>
                                </Tooltip>
                              }
                            >
                              <Button
                                size="sm"
                                color="dark"
                                outline
                                className="btn-icon-only"
                                onClick={() => {
                                  this.setState({
                                    modifySection: section,
                                    openSection: true,
                                  });
                                }}
                              >
                                <i className="mdi mdi-pencil"></i>
                              </Button>
                            </Whisper>

                            <Whisper
                              trigger="hover"
                              placement="autoHorizontal"
                              delayOpen={400}
                              speaker={
                                <Tooltip>
                                  <p className="m-0 text-white">Add topic</p>
                                </Tooltip>
                              }
                            >
                              <Button
                                size="sm"
                                color="primary"
                                className="btn-icon-only"
                                onClick={() => {
                                  this.setState({
                                    openTopic: true,
                                    modifySection: JSON.parse(
                                      JSON.stringify(section)
                                    ),
                                    modifyTopic: null,
                                  });
                                }}
                              >
                                <i className="mdi mdi-plus"></i>
                              </Button>
                            </Whisper>
                          </Col>
                        )}
                      </Row>
                    </CardHeader>
                    <Collapse isOpen={section?.open}>
                      <CardBody
                        className={`${
                          this.state.reorderTopics == section?._id
                            ? "px-2 py-2"
                            : "p-0"
                        }`}
                      >
                        {this.state.reorderTopics == section?._id ? (
                          <>
                            <DragDropContext
                              onDragEnd={this.onTopicDragEnd.bind(this)}
                            >
                              <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                  <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{
                                      background: snapshot.isDraggingOver
                                        ? "var(--primary-superlight)"
                                        : "",
                                      borderRadius: 0,
                                    }}
                                  >
                                    {this.state.topicOrder.map(
                                      (topic, index) => (
                                        <Draggable
                                          key={topic?.topicID}
                                          draggableId={topic?.topicID}
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              className={`py-2 mx-2`}
                                            >
                                              <Card
                                                className={`cursor-pointer ${
                                                  snapshot.isDragging
                                                    ? "shadow"
                                                    : ""
                                                } border`}
                                              >
                                                <CardBody className="py-2 px-2">
                                                  <Row className="align-items-center">
                                                    <Col
                                                      xs="auto"
                                                      className="pr-0 pl-3"
                                                    >
                                                      <i
                                                        className="mdi mdi-drag text-dark"
                                                        style={{
                                                          fontSize: 25,
                                                        }}
                                                      ></i>
                                                    </Col>
                                                    <Col xs="">
                                                      <h3 className="m-0 text-dark">
                                                        {topic?.topic?.name}
                                                      </h3>
                                                      <p className="m-0 small text-dark">
                                                        {
                                                          topic?.topic
                                                            ?.description
                                                        }
                                                      </p>
                                                      <p className="m-0 small text-dark">
                                                        <Badge color="medium">
                                                          {topic?.topic
                                                            ?.questions?.length
                                                            ? topic?.topic
                                                                ?.questions
                                                                ?.length
                                                            : 0}
                                                          &nbsp;Question
                                                          {topic?.topic
                                                            ?.questions
                                                            ?.length == 1
                                                            ? ""
                                                            : "s"}
                                                        </Badge>
                                                      </p>
                                                    </Col>
                                                  </Row>
                                                </CardBody>
                                              </Card>
                                            </div>
                                          )}
                                        </Draggable>
                                      )
                                    )}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                          </>
                        ) : (
                          <>
                            {section?.topics?.length ? (
                              <>
                                {section?.topics?.map((topic, k) => (
                                  <div
                                    className={`px-4 py-3 ${
                                      k != section?.topics?.length - 1 &&
                                      "border-bottom"
                                    } bg-superlight--hover cursor-pointer`}
                                    key={topic?.topic?._id}
                                  >
                                    <Row className="align-items-center">
                                      <Col
                                        xs=""
                                        onClick={() => {
                                          /*this.props.history.push(
                                            `/university/library/topics/${topic?.topic?._id}?moduleID=${this.state.module?._id}&sectionID=${section?._id}`
                                          );*/
                                        }}
                                      >
                                        <h3 className="m-0">
                                          {topic?.topic?.internal ? (
                                            <Badge
                                              color="light"
                                              className="mr-2 border"
                                              style={{
                                                position: "relative",
                                                top: -1,
                                              }}
                                            >
                                              INTERNAL
                                            </Badge>
                                          ) : null}
                                          {topic?.topic?.name}
                                        </h3>
                                        {topic?.topic?.description ? (
                                          <>
                                            <p className="small m-0 text-dark">
                                              {topic?.topic?.description}
                                            </p>
                                          </>
                                        ) : null}
                                        <p className="m-0 text-dark mt-1">
                                          <Badge color="medium">
                                            {topic?.topic?.questions?.length
                                              ? topic?.topic?.questions?.length
                                              : 0}
                                            &nbsp;Question
                                            {topic?.topic?.questions?.length ==
                                            1
                                              ? ""
                                              : "s"}
                                          </Badge>
                                        </p>
                                      </Col>
                                      <Col xs="auto">
                                        <Whisper
                                          ref={this.dropdownRef}
                                          trigger="click"
                                          placement={"autoVerticalEnd"}
                                          speaker={
                                            <Popover full>
                                              <Dropdown.Menu>
                                                <Dropdown.Item
                                                  onClick={() => {
                                                    LinkUtils.openInNewTab(
                                                      "/university/library/topics/" +
                                                        topic?.topic?._id,
                                                      true
                                                    );
                                                  }}
                                                >
                                                  View Topic
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  divider
                                                ></Dropdown.Item>
                                                <Dropdown.Item
                                                  className="text-danger"
                                                  onClick={() => {
                                                    this.dropdownRef.current.close();

                                                    this.removeTopicFromSection(
                                                      section,
                                                      topic?.topic
                                                    );
                                                  }}
                                                >
                                                  Remove From Section
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Popover>
                                          }
                                        >
                                          <Button
                                            size="sm"
                                            color="dark"
                                            outline
                                            className="btn-icon-only"
                                          >
                                            <i className="mdi mdi-dots-horizontal"></i>
                                          </Button>
                                        </Whisper>
                                        {/**
                                         * <Button
                                          size="sm"
                                          color="dark"
                                          outline
                                          className="btn-icon-only border-0"
                                          onClick={() => {
                                            this.props.history.push(
                                              `/university/library/topics/${topic?.topic?._id}?moduleID=${this.state.module?._id}&sectionID=${section?._id}`
                                            );
                                          }}
                                        >
                                          <i className="mdi mdi-chevron-right"></i>
                                        </Button>
                                         */}
                                      </Col>
                                    </Row>
                                  </div>
                                ))}
                              </>
                            ) : (
                              <>
                                <p className="m-0 p-4">
                                  Section is empty. Add a topic to get started.
                                </p>
                              </>
                            )}
                          </>
                        )}
                      </CardBody>
                      <CardFooter>
                        <Row className="align-items-center">
                          <Col xs="">
                            {this.state.reorderTopics != section?._id && (
                              <Whisper
                                trigger="hover"
                                placement="autoHorizontal"
                                delayOpen={400}
                                speaker={
                                  <Tooltip>
                                    <p className="m-0 text-white">
                                      Delete section
                                    </p>
                                  </Tooltip>
                                }
                              >
                                <Button
                                  size="sm"
                                  color="danger"
                                  outline
                                  className="btn-icon-only"
                                  onClick={() => this.deleteSection(section)}
                                >
                                  <i className="mdi mdi-trash-can"></i>
                                </Button>
                              </Whisper>
                            )}
                          </Col>
                          <Col xs="auto">
                            {this.state.reorderTopics == section?._id ? (
                              <>
                                <Button
                                  color="secondary"
                                  outline
                                  size="sm"
                                  onClick={() => {
                                    this.setState({
                                      reorderTopics: false,
                                      moduleTopics: [],
                                    });
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  color="primary"
                                  size="sm"
                                  disabled={this.state.reorderTopicsSubmitting}
                                  onClick={() => {
                                    this.saveTopicOrder(
                                      section,
                                      this.state.topicOrder
                                    );
                                  }}
                                >
                                  {this.state.reorderTopicsSubmitting ? (
                                    <>
                                      <Spinner size="sm"></Spinner>
                                    </>
                                  ) : (
                                    <>Save Order</>
                                  )}
                                </Button>
                              </>
                            ) : (
                              <Whisper
                                trigger="hover"
                                placement="autoHorizontal"
                                delayOpen={400}
                                speaker={
                                  <Tooltip>
                                    <p className="m-0 text-white">Reorder</p>
                                  </Tooltip>
                                }
                              >
                                <Button
                                  className="btn-icon-only"
                                  size="sm"
                                  outline
                                  color="dark"
                                  onClick={() => {
                                    this.setState({
                                      reorderTopics: section?._id,
                                      topicOrder: JSON.parse(
                                        JSON.stringify(section?.topics ?? [])
                                      ),
                                    });
                                  }}
                                >
                                  <i className="mdi mdi-swap-vertical-bold"></i>
                                </Button>
                              </Whisper>
                            )}
                          </Col>
                        </Row>
                      </CardFooter>
                    </Collapse>
                  </Card>
                ))}
              </>
            )}
          </>
        ) : (
          <>
            <Card className="border mb-4">
              <CardBody>
                <Row className="align-items-center">
                  <Col xs="">
                    <h2 className="m-0">No sections</h2>
                    <p className="m-0">
                      Get started by adding your first section.
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </>
        )}
        <LearningCourseSectionDrawer
          open={this.state.openSection}
          section={this.state.modifySection}
          onCreate={this.onCreateSection.bind(this)}
          onModify={this.onModifySection.bind(this)}
          course={this.props.course}
          onClose={() => {
            this.setState({ openSection: false, modifySection: null });
          }}
        ></LearningCourseSectionDrawer>
        <LearningCourseTopicSelectorDrawer
          open={this.state.openTopic}
          section={this.state.modifySection}
          selectedTopics={this.state.modifySection?.topics ?? []}
          onSelect={(topic) => {
            this.addTopicToSection(this.state.modifySection, topic);
          }}
          onClose={() => {
            this.setState({ openTopic: false, modifySection: null });
          }}
        ></LearningCourseTopicSelectorDrawer>
      </>
    );
  }
}

export default withRouter(LearningCourseTopicEditor);
