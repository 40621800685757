import ReactDOM from "react-dom";
import cubejs from "@cubejs-client/core";
import { QueryRenderer } from "@cubejs-client/react";
import React from "react";
import APIV2 from "../../lib/APIV2";
import DataAPI from "lib/DataAPI";
import { Button, Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import StringUtils from "lib/StringUtils";
import _ from "underscore";

import moment from "moment";

import {
  Chart,
  Area,
  Line,
  Tooltip,
  Axis,
  Coordinate,
  Point,
  Interaction,
  Interval,
  getTheme,
} from "bizcharts";

class SalesSummaryPieChart extends React.Component {
  state = {
    activeMode: "source",
    chartData: [],
    colorKey: "",
    scale: {
      percent: {
        formatter: (v) => {
          return `${(v * 100).toFixed(2)}%`;
        },
      },
    },
  };

  constructor(props) {
    super(props);

    this.chartRef = React.createRef();
  }

  getPeriodString(startMoment) {
    if (!startMoment) {
      return "--";
    }

    if (this.props.groupBy == "daily" || this.props.groupBy == "summary") {
      return startMoment.format("MM/DD/YYYY");
    }

    if (this.props.groupBy == "weekly") {
      return `${startMoment?.format("MM/DD/YYYY")} - ${moment(
        startMoment?.format("YYYY-MM-DD")
      )
        .endOf("week")
        .format("MM/DD/YYYY")}`;
    }

    if (this.props.groupBy == "monthly") {
      return `${startMoment?.format("MMMM YYYY")}`;
    }

    if (this.props.groupBy == "quarterly") {
      return `Q${startMoment?.format("Q YYYY")}`;
    }

    if (this.props.groupBy == "yearly") {
      return `FY ${startMoment?.format("YYYY")}`;
    }
  }

  setActiveMode(mode) {
    this.setState(
      {
        activeMode: mode,
      },
      () => {
        this.loadChartData(this.state.data);
      }
    );
  }

  componentDidMount() {
    if (this.props.data) {
      if (this.props.data?.revenueSource != "all") {
        this.setState(
          {
            activeMode: "store",
          },
          () => {
            this.loadChartData(this.props.data);
          }
        );
      } else {
        this.setState(
          {
            activeMode: "source",
          },
          () => {
            this.loadChartData(this.props.data);
          }
        );
      }
    }
  }

  loadChartData(data) {
    this.setState({
      data: data,
    });

    let preparedData = [];

    if (this.state.activeMode == "source") {
      if (data?.revenueSource == "all") {
        let totalAmount =
          data?.subscriptionData?.summedData + data?.retailData?.summedData;

        let subscriptionTotal = data?.subscriptionData?.summedData;
        let retailTotal = data?.retailData?.summedData;

        preparedData.push({
          revenueSource: "Subscription Sales",
          percent: subscriptionTotal / totalAmount,
          count: ((subscriptionTotal / totalAmount) * 100).toFixed(2),
          currency: StringUtils.centsToCurrency(subscriptionTotal * 100),
        });

        preparedData.push({
          revenueSource: "Retail Sales",
          percent: retailTotal / totalAmount,
          count: ((retailTotal / totalAmount) * 100).toFixed(2),
          currency: StringUtils.centsToCurrency(retailTotal * 100),
        });

        return this.setState({
          chartData: preparedData,
          colorKey: ["revenueSource", ["#E17853", "#81D96D"]],
        });
      }

      if (data?.revenueSource == "subscription") {
        let totalAmount = data?.subscriptionData?.summedData;

        let subscriptionTotal = data?.subscriptionData?.summedData;

        preparedData.push({
          revenueSource: "Subscription Sales",
          percent: subscriptionTotal / totalAmount,
          count: ((subscriptionTotal / totalAmount) * 100).toFixed(2),
          currency: StringUtils.centsToCurrency(subscriptionTotal * 100),
        });

        return this.setState({
          chartData: preparedData,
          colorKey: ["revenueSource", ["#E17853"]],
        });
      }

      if (data?.revenueSource == "retail") {
        let totalAmount = data?.retailData?.summedData;

        let retailTotal = data?.retailData?.summedData;

        preparedData.push({
          revenueSource: "Retail Sales",
          percent: retailTotal / totalAmount,
          count: ((retailTotal / totalAmount) * 100).toFixed(2),
          currency: StringUtils.centsToCurrency(retailTotal * 100),
        });

        return this.setState({
          chartData: preparedData,

          colorKey: ["revenueSource", ["#81D96D"]],
        });
      }
    } else if (this.state.activeMode == "store") {
      // TODO: fix combined data here

      let source = {};

      if (data.revenueSource == "all") {
        source = data.combinedData;
      } else if (data.revenueSource == "subscription") {
        source = data.subscriptionData;
      } else if (data.revenueSource == "retail") {
        source = data.retailData;
      }

      let totalAmount = source?.summedData;

      for (let i = 0; i < this.props?.stores?.length; i++) {
        const store = this.props.stores[i];

        if (this.props.groupBy == "summary") {
          console.log(`Source Chart Data`, source);

          let storeData = [];
          let storeTotal = 0;

          if (source?.storeData?.interval?.hasOwnProperty(store?._id)) {
            storeData = source?.storeData.interval[store?._id];
          }

          if (storeData?.length) {
            console.log("Store Data", storeData);

            for (let k = 0; k < storeData?.length; k++) {
              storeTotal += storeData[k].revenue;
            }
          }

          preparedData.push({
            storeName: store?.name?.replace("Project LeanNation", ""),
            percent: storeTotal / totalAmount,
            count: ((storeTotal / totalAmount) * 100).toFixed(2),
            currency: StringUtils.centsToCurrency(storeTotal * 100),
          });
        } else {
          if (
            source?.storeData?.hasOwnProperty(store?._id) &&
            source?.storeData[store?._id]?.length
          ) {
            //console.log(data?.combinedData?.storeData[store?._id]);

            let storeData = source?.storeData[store?._id];
            let storeTotal = 0;

            if (storeData?.length) {
              console.log("Store Data", storeData);

              for (let k = 0; k < storeData?.length; k++) {
                storeTotal += storeData[k].revenue;
              }
            }

            preparedData.push({
              storeName: store?.name?.replace("Project LeanNation", ""),
              percent: storeTotal / totalAmount,
              count: ((storeTotal / totalAmount) * 100).toFixed(2),
              currency: StringUtils.centsToCurrency(storeTotal * 100),
            });
          }
        }
      }

      return this.setState({
        chartData: preparedData,

        colorKey: "storeName",
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.data) != JSON.stringify(prevProps?.data)) {
      if (this.props.data?.revenueSource != "all") {
        this.setState(
          {
            activeMode: "store",
          },
          () => {
            this.loadChartData(this.props.data);
          }
        );
      } else {
        this.setState(
          {
            activeMode: "source",
          },
          () => {
            this.loadChartData(this.props.data);
          }
        );
      }

      // Reload here
    }
  }

  toggleExpanded(setTo) {
    this.setState({
      expanded: setTo,
    });

    if (typeof this.props.onExpandChange == "function") {
      this.props.onExpandChange(setTo);
    }
  }

  generateFileName() {
    let source = "";

    if (this.props.data?.revenueSource == "all") {
      source = "All Sales";
    } else if (this.props.data?.revenueSource == "subscription") {
      source = "Subscription Sales";
    } else if (this.props.data?.revenueSource == "retail") {
      source = "Retail Sales";
    }

    let mode = "";

    if (this.state.activeMode == "source") {
      mode = "By Source";
    } else {
      mode = "By Store";
    }

    let range = "";

    if (this.props.dateRange?.length == 2) {
      range = `${moment(this.props?.dateRange[0]?.toISOString()).format(
        "MM-DD-YYYY"
      )}-${moment(this.props?.dateRange[1]?.toISOString()).format(
        "MM-DD-YYYY"
      )}`;
    }

    return `${source} Revenue Distribution ${mode} ${range}.jpeg`;
  }

  exportToImage() {
    let canvas =
      this?.chartRef?.current?.props?.container?.querySelector("canvas");

    if (canvas) {
      var ctx = canvas.getContext("2d");
      // Add behind elements.
      ctx.globalCompositeOperation = "destination-over";
      // Now draw!
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      const img = canvas.toDataURL("image/jpeg", 1.0);

      console.log(img);

      let link = document.createElement("a");
      link.download = this.generateFileName();
      link.href = img;
      link.click();

      //window.location.href = img;
    }

    return;
  }

  render() {
    return (
      <>
        <Card className="border shadow border-lighter" style={this.props.style}>
          <CardHeader className="p-2 border-lighter">
            <Row className="align-items-center">
              <Col xs="">
                {this.state.expanded ? (
                  <>
                    <Button
                      outline={this.state.activeMode != "source"}
                      size="sm"
                      color={
                        this.state.activeMode == "source" ? "primary" : "dark"
                      }
                      onClick={() => {
                        if (this.state.activeMode != "source") {
                          this.setActiveMode("source");
                        }
                      }}
                      className={`mr-1 ${
                        !this.state.expanded &&
                        this.state.activeMode != "source"
                          ? "d-none"
                          : ""
                      } ${
                        this.props.data?.revenueSource != "all" ? "d-none" : ""
                      }`}
                    >
                      By Source
                    </Button>
                    <Button
                      outline={this.state.activeMode != "store"}
                      size="sm"
                      className={`mr-1 ${
                        !this.state.expanded && this.state.activeMode != "store"
                          ? "d-none"
                          : ""
                      }`}
                      color={
                        this.state.activeMode == "store" ? "primary" : "dark"
                      }
                      onClick={() => {
                        if (this.state.activeMode != "store") {
                          this.setActiveMode("store");
                        }
                      }}
                    >
                      By Store
                    </Button>
                  </>
                ) : (
                  <>
                    <h4 className="m-0">
                      By {this.state.activeMode == "store" ? "Store" : "Source"}
                    </h4>
                  </>
                )}
              </Col>
              <Col xs="auto">
                {this.state.expanded && (
                  <Button
                    onClick={() => {
                      this.exportToImage();
                    }}
                    size="sm"
                    className="btn-icon-only border-0 mr-0"
                    color="dark"
                    outline
                  >
                    <i className="mdi mdi-download"></i>
                  </Button>
                )}
                <Button
                  onClick={() => {
                    this.toggleExpanded(!this.state.expanded);
                  }}
                  size="sm"
                  outline
                  className="btn-icon-only border-0"
                >
                  <i
                    className={`mdi mdi-${
                      this.state.expanded
                        ? "arrow-collapse-all"
                        : "arrow-expand-all"
                    }`}
                  ></i>
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="p-2" style={{ overflow: "hidden" }}>
            {this.props.loading ? (
              <>
                <div
                  className="skeleton"
                  style={{ height: this.state.expanded ? 275 : 133 }}
                ></div>
              </>
            ) : (
              <>
                <Chart
                  ref={this.chartRef}
                  height={this.state.expanded ? 275 : 133}
                  autoFit
                  scale={this.state.scale}
                  data={this.state.chartData}
                >
                  <Coordinate type="theta" radius={0.75}></Coordinate>
                  <Tooltip showTitle={false} />
                  <Axis visible={false}></Axis>
                  <Interval
                    position="percent"
                    adjust="stack"
                    color={this.state.colorKey}
                    style={{
                      lineWidth: 1,
                      stroke: "#FFFFFF",
                    }}
                    label={[
                      "count",
                      {
                        layout: {
                          type: "limit-in-log",
                          cfg: { action: "ellipsis" },
                        },
                        content: (data) => {
                          return `${
                            data[
                              this.state.activeMode == "source"
                                ? "revenueSource"
                                : "storeName"
                            ]
                          }: ${(data.percent * 100).toFixed(2)}% (${
                            data.currency
                          })`;
                        },
                      },
                    ]}
                  ></Interval>

                  <Interaction type="element-single-selected" />
                </Chart>
              </>
            )}
          </CardBody>
        </Card>
      </>
    );
  }
}

export default SalesSummaryPieChart;
