import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { nodeName } from "dom-lib";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { withRouter } from "react-router";

// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Button,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap";
import CardHeader from "reactstrap/lib/CardHeader";
import Spinner from "reactstrap/lib/Spinner";
import _ from "underscore";

class AverageBoxSizeWidget extends React.Component {
  state = {
    active: "",
    planTypesLoading: true,
    filteredResults: [],
    mealPerformanceColumns: [
      {
        dataField: "typeID",
        text: "Type ID",
        hidden: true,
      },
      {
        dataField: "planType",
        text: "Type",
        sort: true,
        headerClasses: "bg-superlight",
        headerStyle: { position: "relative", paddingLeft: "2.3rem" },
        formatter: (cell, row, rowIndex) => {
          return row.planType;
        },
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === "asc") return a.localeCompare(b);
          else return b.localeCompare(a);
        },
        sortCaret: (order, column) => {
          if (order) {
            if (order == "asc") {
              return (
                <i
                  className="mdi mdi-sort-ascending text-primary mr--1"
                  style={{ position: "absolute", left: "1rem" }}
                ></i>
              );
            }

            return (
              <i
                className="mdi mdi-sort-descending text-primary"
                style={{ position: "absolute", left: "1rem" }}
              ></i>
            );
          }

          return (
            <i
              className="mdi mdi-sort"
              style={{ position: "absolute", left: "1rem" }}
            ></i>
          );
        },
      },
      {
        dataField: "size",
        text: "Box Size",
        sort: true,
        headerClasses: "bg-superlight",
        headerStyle: { position: "relative", paddingLeft: "2.3rem" },
        formatter: (cell, row, rowIndex) => {
          return <span className="text-capitalize">{row.size}</span>;
        },
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === "asc") return a.localeCompare(b);
          else return b.localeCompare(a);
        },
        sortCaret: (order, column) => {
          if (order) {
            if (order == "asc") {
              return (
                <i
                  className="mdi mdi-sort-ascending text-primary mr--1"
                  style={{ position: "absolute", left: "1rem" }}
                ></i>
              );
            }

            return (
              <i
                className="mdi mdi-sort-descending text-primary"
                style={{ position: "absolute", left: "1rem" }}
              ></i>
            );
          }

          return (
            <i
              className="mdi mdi-sort"
              style={{ position: "absolute", left: "1rem" }}
            ></i>
          );
        },
      },
      {
        dataField: "procurement",
        text: "Procurement",
        sort: true,
        headerClasses: "bg-superlight",
        headerStyle: { position: "relative", paddingLeft: "2.3rem" },
        formatter: (cell, row, rowIndex) => {
          return <span className="text-capitalize">{row.procurement}</span>;
        },
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === "asc") return a.localeCompare(b);
          else return b.localeCompare(a);
        },
        sortCaret: (order, column) => {
          if (order) {
            if (order == "asc") {
              return (
                <i
                  className="mdi mdi-sort-ascending text-primary mr--1"
                  style={{ position: "absolute", left: "1rem" }}
                ></i>
              );
            }

            return (
              <i
                className="mdi mdi-sort-descending text-primary"
                style={{ position: "absolute", left: "1rem" }}
              ></i>
            );
          }

          return (
            <i
              className="mdi mdi-sort"
              style={{ position: "absolute", left: "1rem" }}
            ></i>
          );
        },
      },
      {
        dataField: "frequency",
        text: "Frequency",
        sort: true,
        headerClasses: "bg-superlight",
        headerStyle: { position: "relative", paddingLeft: "2.3rem" },
        formatter: (cell, row, rowIndex) => {
          return <span className="text-capitalize">{row.frequency}</span>;
        },
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === "asc") return a.localeCompare(b);
          else return b.localeCompare(a);
        },
        sortCaret: (order, column) => {
          if (order) {
            if (order == "asc") {
              return (
                <i
                  className="mdi mdi-sort-ascending text-primary mr--1"
                  style={{ position: "absolute", left: "1rem" }}
                ></i>
              );
            }

            return (
              <i
                className="mdi mdi-sort-descending text-primary"
                style={{ position: "absolute", left: "1rem" }}
              ></i>
            );
          }

          return (
            <i
              className="mdi mdi-sort"
              style={{ position: "absolute", left: "1rem" }}
            ></i>
          );
        },
      },
      {
        dataField: "quantity",
        text: "Quantity",
        sort: true,
        headerClasses: "bg-superlight",
        headerStyle: { position: "relative", paddingLeft: "2.3rem" },
        formatter: (cell, row, rowIndex) => {
          return StringUtils.numberFormat(row.quantity);
        },
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === "asc") return a - b;
          else return b - a;
        },
        sortCaret: (order, column) => {
          if (order) {
            if (order == "asc") {
              return (
                <i
                  className="mdi mdi-sort-ascending text-primary mr--1"
                  style={{ position: "absolute", left: "1rem" }}
                ></i>
              );
            }

            return (
              <i
                className="mdi mdi-sort-descending text-primary"
                style={{ position: "absolute", left: "1rem" }}
              ></i>
            );
          }

          return (
            <i
              className="mdi mdi-sort"
              style={{ position: "absolute", left: "1rem" }}
            ></i>
          );
        },
      },
    ],
  };

  loadSummary(reports) {
    let payload = {};

    if (!reports) {
      return;
    }

    let overall = 0;
    let overallCount = 0;

    let planTypes = [];

    for (let i = 0; i < reports.length; i++) {
      let report = reports[i];

      if (!report) {
        continue;
      }

      if (report?.statistics?.averageBoxSize) {
        overall += report.statistics?.averageBoxSize;
        overallCount++;

        console.log(report.statistics?.averageBoxSize);
      }

      for (
        let j = 0;
        j < report.statistics?.averageBoxSizeByPlanType?.length;
        j++
      ) {
        let idx = _.findIndex(planTypes, {
          planTypeID: report.statistics?.averageBoxSizeByPlanType[j].planTypeID,
        });

        if (idx < 0) {
          planTypes.push({
            planTypeID:
              report.statistics?.averageBoxSizeByPlanType[j].planTypeID,
            sum: report.statistics?.averageBoxSizeByPlanType[j].quantity,
            count: 1,
          });
        } else {
          planTypes[idx].sum +=
            report.statistics?.averageBoxSizeByPlanType[j].quantity;
          planTypes[idx].count += 1;
        }
      }

      //for ()
    }

    for (let i = 0; i < planTypes.length; i++) {
      payload[`avgBox${planTypes[i].planTypeID}`] = planTypes[i].sum
        ? "~" + Math.round((planTypes[i].sum / planTypes[i].count) * 10) / 10
        : 0;
    }

    payload.avgBoxOverall = overallCount
      ? "~" + Math.round((overall / overallCount) * 10) / 10
      : 0;

    this.setState(payload);
  }

  loadPlanTypes() {
    return new Promise((resolve) => {
      this.setState({
        planTypesLoading: true,
      });

      APIV2.getProductGroups()
        .then((data) => {
          this.setState({
            planTypes: data.data.planTypes,
          });

          let selectorOptions = [{ name: "All", id: "all", _id: "all" }];

          for (let i = 0; i < data.data.planTypes.length; i++) {
            selectorOptions.push({
              name: data.data.planTypes[i].name,
              id: data.data.planTypes[i]._id,
              _id: data.data.planTypes[i]._id,
            });
          }

          this.setState({
            selectorOptions,
            selected: { name: "All", id: "all", _id: "all" },
          });
        })
        .finally(() => {
          this.setState({
            planTypesLoading: false,
          });

          resolve();
        });
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.reports &&
      prevProps.reports?.length != this.props.reports?.length
    ) {
      this.loadPlanTypes().then(() => {
        this.loadSummary(this.props.reports);
      });
    }
  }

  componentDidMount() {
    if (this.props.reports) {
      this.loadPlanTypes().then(() => {
        this.loadSummary(this.props.reports);
      });
    }
  }

  handleFilterChange(val) {
    this.setState(
      {
        selected: val,
      },
      () => {
        this.loadSummary(this.props.reports);
      }
    );
  }

  render() {
    return (
      <>
        <Card className="border shadow">
          <CardHeader className="p-2">
            <h4 className="m-0">Average Box Size</h4>
          </CardHeader>
          <CardBody className="p-0">
            <Row className="align-items-top">
              <Col xs="12" md="4" className="border-md-right">
                <div className="p-3 border-bottom border-md-bottom-0">
                  <h5>Overall</h5>
                  <h2 className="m-0 d-inline-block">
                    {this.state.avgBoxOverall ? this.state.avgBoxOverall : "--"}
                  </h2>
                  {this.state.avgBoxOverall ? (
                    <small className="pl-2 font-weight-bold text-uppercase text-medium">
                      Meals/Box
                    </small>
                  ) : null}
                </div>
              </Col>
              {this.state.planTypes?.map((pt, i) => (
                <Col xs="12" md="4" className="border-md-right">
                  <div
                    className={`p-3 ${
                      i != this.state.planTypes.length - 1
                        ? "border-bottom border-md-bottom-0"
                        : ""
                    }`}
                  >
                    <h5>{pt.name} Plans</h5>
                    <h2 className="m-0 d-inline-block">
                      {this.state[`avgBox${pt._id}`]
                        ? this.state[`avgBox${pt._id}`]
                        : "--"}
                    </h2>
                    {this.state[`avgBox${pt._id}`] ? (
                      <small className="pl-2 font-weight-bold text-uppercase text-medium">
                        Meals/Box
                      </small>
                    ) : null}
                  </div>
                </Col>
              ))}
            </Row>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default withRouter(AverageBoxSizeWidget);
