import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import { isThisTypeNode } from "typescript";

class StorefrontCreateStaffMemberDrawer extends React.Component {
  state = {
    position: "",
    name: "",
    email: "",
    phone: {
      value: "",
    },
    hireDate: null,
  };

  constructor() {
    super();

    this.dropdownRef = React.createRef();
  }

  componentDidUpdate(prevProps) {}

  componentDidMount() {}

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  handlePhoneChange(values) {
    this.setState({
      phone: values,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  toggleModal() {
    this.props.onClose();
  }

  submit() {
    let hireDate = null;

    if (this.state.hireDate) {
      const hireMoment = moment(this.state.hireDate.toISOString());

      hireDate = hireMoment.format("YYYY-MM-DD");
    }

    this.setState({ submitting: true });

    APIV2.createStoreStaff(
      this.props?.storeID,
      this.state.name,
      this.state.email,
      this.state.phone?.value,
      this.state.position,
      hireDate
    )
      .then(
        (data) => {
          const staff = data.data.staff;

          PubSub.publish(Event.STAFF.CREATED, staff);

          this.setState({
            name: "",
            email: "",
            phone: {
              value: "",
            },
            position: "",
            hireDate: null,
          });

          this.toggleModal();
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create staff member - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Add Staff Member
            </h3>
            <Drawer.Actions>
              <Button
                onClick={this.submit.bind(this)}
                size="sm"
                color="primary"
                disabled={
                  !this.state.name ||
                  !this.state.email ||
                  !this.state.phone ||
                  !this.state.position ||
                  this.state.submitting
                }
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  "Create"
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error && (
              <>
                <Alert
                  color=""
                  className="m-0 mb-4 bg-primary-superlight text-dark font-weight-500"
                >
                  {this.state.error}
                </Alert>
              </>
            )}
            <FormGroup>
              <h5>Name</h5>
              <Input
                type="text"
                name="name"
                placeholder="Name"
                required
                value={this.state.name}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Email</h5>
              <Input
                type="email"
                name="email"
                placeholder="Email"
                required
                value={this.state.email}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Phone</h5>
              <NumberFormat
                className="form-control mb-3"
                format="(###) ###-####"
                mask=""
                name="phoneNumberInput"
                placeholder="Phone Number"
                onValueChange={this.handlePhoneChange.bind(this)}
                value={this.state.phone?.value}
                type="tel"
                mask="_"
                required
              />
            </FormGroup>
            <FormGroup>
              <h5>Position</h5>
              <Input
                type="select"
                name="position"
                required
                value={this.state.position}
                onChange={this.handleInputChange.bind(this)}
                placeholder="Position"
              >
                <option value="" disabled>
                  Choose A Position
                </option>
                <option value="Educator">Educator</option>
                <option value="Key Lead">Key Lead</option>
                <option value="Assistant Manager">Assistant Manager</option>
                <option value="Manager">Manager</option>
                <option value="Social Strategist">Social Strategist</option>
                <option value="Applicant">Applicant</option>
                <option value="Terminated">Past Staff Member</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <h5>Hire Date (Optional)</h5>
              <DatePicker
                block
                onChange={(v) => {
                  this.setState({ hireDate: v });
                }}
                value={this.state.hireDate}
                cleanable
                oneTap
                placement="auto"
              ></DatePicker>
            </FormGroup>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(StorefrontCreateStaffMemberDrawer);
