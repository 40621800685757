import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import APIV2 from "lib/APIV2";

class ModifyStoreMealMenuModal extends React.Component {
  state = {
    open: false,
    id: null,
    name: "",
    submitting: false,
    type: 0,
    address_1: "",
    address_2: "",
    city: "",
    state: "AL",
    zip_code: "",
    billingAccounts: [],
    loadingBAs: true,
    billingAccountID: null,
    menus: [],
    menuID: "0",
    planType: {},
    store: {},
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });

    if (this.state[state] == true) {
      this.setState({
        name: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "AL",
        zip_code: "",
        type: 0,
      });
    }
  };

  handleBillingAccountChange(event) {
    const val = event.target.value;

    this.setState({
      menuID: val,
    });
  }

  submit(e) {
    e.preventDefault();

    if (!this.state.menuID) {
      window.alert("Please select a menu to continue.");

      return;
    }

    this.setState({
      submitting: true,
    });

    APIV2.setStoreMealMenu(
      this.state.store._id,
      this.state.planType._id,
      this.state.menuID
    )
      .then(
        (data) => {
          if (data && data.data && data.data.store) {
            console.log(data.data.store);

            PubSub.publish(Event.STORE.MODIFIED, data.data.store);

            this.setState({
              open: false,
            });

            this.setState({
              menuID: "",
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "We were unable to set the menu for the store. Please try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  componentDidMount() {
    PubSub.subscribe(Event.STORE.MODIFY_MENU_OPEN, (data) => {
      this.setState({
        open: true,
        loadingBAs: true,
        menuID: "",
        planType: data.planType,
        store: data.store,
      });

      APIV2.getMealMenusByProductGroup(data.planType._id).then(
        (data) => {
          console.log(data);

          this.setState({
            menuID: "0",
            menus: data.data.menus ? data.data.menus : [],
            loadingBAs: false,
          });
        },
        () => {
          window.alert("Failed to get meal menus. Please try again.");
        }
      );
    });
  }

  render() {
    const { menus } = this.state;

    let billingAccountList =
      menus.length > 0 &&
      menus.map((item, i) => {
        return (
          <option key={i} value={item._id}>
            {item.name}
          </option>
        );
      }, this);

    return (
      <>
        <Modal isOpen={this.state.open} toggle={() => this.toggleModal("open")}>
          <div className="modal-header">
            <h5 className="modal-title" id="createBAModalLabel">
              Modify Meal Menu
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("open")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Form onSubmit={this.submit.bind(this)}>
            <div className="modal-body">
              <h5 className="mb-2">Menus</h5>
              {this.state.loadingBAs ? (
                <select value="1" disabled={true} className="form-control mb-3">
                  <option value="1">Loading...</option>
                </select>
              ) : null}
              {!this.state.loadingBAs ? (
                <select
                  value={this.state.menuID}
                  onChange={this.handleBillingAccountChange.bind(this)}
                  id="createStoreBillingAccount"
                  className="form-control mb-3"
                >
                  <option value="0" disabled={true}>
                    Select A Menu
                  </option>
                  {billingAccountList}
                </select>
              ) : null}
            </div>
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="reset"
                onClick={() => this.toggleModal("open")}
              >
                Close
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={this.state.submitting || this.state.menuID == "0"}
              >
                Save
              </Button>
            </div>
          </Form>
        </Modal>
      </>
    );
  }
}

export default ModifyStoreMealMenuModal;
