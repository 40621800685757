import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  BarChart,
  BarList,
  Card,
  CategoryBar,
  DeltaBar,
  DonutChart,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import {
  Axis,
  Chart,
  Coordinate,
  Interaction,
  Interval,
  Legend,
  Tooltip,
} from "bizcharts";
import ChartMetricHeader from "./ChartMetricHeader";

class PercentLeanConsultByStoreCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    tab: "absolute",
  };

  /**
   * Fetches a summary of all the member data
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchData(cubejsApi, stores, dateRange) {
    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["Metrics.count"],
          order: {
            "Metrics.createdat": "desc",
          },

          timeDimensions: [
            {
              dimension: "Metrics.createdat",
              dateRange: [dateRange[0], dateRange[1]],
            },
          ],
          filters: [
            {
              member: "Metrics.metadatastoreid",
              operator: "equals",
              values: stores,
            },
            {
              member: "Metrics.name",
              operator: "equals",
              values: [
                "member_consultation",
                "member_new",
                "member_reactivate",
              ],
            },
          ],
          dimensions: ["Stores.name", "Metrics.name"],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          if (!data?.length) {
            return resolve(null);
          }

          let total = 0;
          let totalConsults = 0;

          let out = [];

          let outOverall = [];

          for (let i = 0; i < data?.length; i++) {
            const item = data[i];
            const storeName = item["Stores.name"]
              ?.replace("Project LeanNation", "PLN")
              ?.trim();

            const idx = _.findIndex(out, { storeName });

            let metricName = item["Metrics.name"];

            if (metricName == "member_consultation") {
              metricName = "LEAN Consultations";

              totalConsults += item["Metrics.count"];
            } else if (
              metricName == "member_new" ||
              metricName == "member_reactivate"
            ) {
              metricName = "New & Reactivated Members";

              total += item["Metrics.count"];
            }

            if (idx >= 0) {
              out[idx][metricName] = item["Metrics.count"];
            } else {
              out.push({
                storeName,
                [metricName]: item["Metrics.count"],
              });
            }

            const idx2 = _.findIndex(outOverall, { metricName });

            if (idx2 >= 0) {
              outOverall[idx2].Consultations += item["Metrics.count"];
            } else {
              outOverall.push({
                Consultations: item["Metrics.count"],
                metricName,
              });
            }
          }

          out = out.map((item) => {
            const total =
              (item["LEAN Consultations"] ? item["LEAN Consultations"] : 0) +
              (item["New & Reactivated Members"]
                ? item["New & Reactivated Members"]
                : 0);

            return {
              ...item,
              total,
              percent: item["New & Reactivated Members"]
                ? ((item["LEAN Consultations"]
                    ? item["LEAN Consultations"]
                    : 0) /
                    item["New & Reactivated Members"]) *
                  100
                : 0,
            };
          });

          outOverall = _.sortBy(outOverall, "metricName");

          out = _.sortBy(out, "total");

          out.reverse();

          return resolve({
            byStore: out,
            total: ((totalConsults / total) * 100).toFixed(1),
            overall: outOverall,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async _fetchReport(stores, dateRange) {
    this.setState({
      loading: true,
      dataAvailable: false,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!stores?.length || !dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchData(cubejsApi, stores, dateRange);
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load total new members by store.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });

      return;
    }

    let colors = StringUtils.randomChartColorSet(currentCount.byStore?.length);

    console.log("Consult %", currentCount?.byStore);

    this.setState({
      currentCount: currentCount,
      loading: false,
      byStore: currentCount?.byStore,
      overall: currentCount?.overall,
      byStoreColors: colors,
      total: currentCount?.total,
    });

    if (typeof this.props.onLoaded == "function") {
      this.props.onLoaded();
    }
  }

  /**
   * Load all report information
   *
   * @param {*} stores
   * @param {*} dateRange
   * @param {*} compare
   */
  async loadReport(stores, dateRange, compare) {
    this.setState({
      loading: true,
    });

    this._fetchReport(stores, dateRange);
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.stores != prevProps?.stores && this.props.stores?.length) ||
      this.props.dateRange != prevProps?.dateRange ||
      this.props.metricType != prevProps?.metricType ||
      this.props.comparePrevious != prevProps.comparePrevious
    ) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }

    if (this.props.reload != prevProps.reload && this.props.reload) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  componentDidMount() {
    if (this.props.stores && this.props.dateRange) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }

    if (this.props.reload) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  render() {
    return (
      <>
        <Card marginTop="mt-0">
          <ChartMetricHeader
            title="LEAN Consultation Rate"
            description="The percentage of new clients who received a LEAN consulation in the selected time range."
            actions={
              <>
                <Button
                  size="sm"
                  outline
                  color="dark"
                  className="border-0 btn-icon-only"
                  disabled={this.state.loading}
                  onClick={() => {
                    this.loadReport(
                      this.props.stores,
                      this.props.dateRange,
                      this.props.comparePrevious
                    );
                  }}
                >
                  {this.state.loading ? (
                    <Spinner size="sm"></Spinner>
                  ) : (
                    <i className="mdi mdi-refresh"></i>
                  )}
                </Button>
              </>
            }
            loading={this.state.loading}
            dataAvailable={this.state.dataAvailable}
            metric={`~${StringUtils.numberFormat(
              parseFloat(this.state.average?.toFixed(1))
            )}% Consultation Rate`}
            comparisonMetric={null}
            dateRange={this.props.dateRange}
            deltaType={null}
            percentChange={null}
            showPercentChange={false}
          ></ChartMetricHeader>
          <TabList
            color="orange"
            defaultValue="absolute"
            handleSelect={(value) => {
              this.setState({
                tab: value,
              });
            }}
            marginTop="mt-3"
            disabled={this.state.loading || !this.state.dataAvailable}
          >
            <Tab value="absolute" text="Absolute" />
            <Tab value="relative" text="Relative" />
          </TabList>

          {this.state.loading ? (
            <div
              className="skeleton mt-3"
              style={{ height: "calc(327px + 1rem)", width: "100%" }}
            >
              &nbsp;
            </div>
          ) : (
            <>
              {this.state.dataAvailable ? (
                <>
                  {this.state.tab == "absolute" ? (
                    <div className="mt-3 mx--4 px-4">
                      <div className="mb--4 pb-4">
                        {this.state.currentCount?.history?.map((store, i) => (
                          <div
                            key={i}
                            className={`${i != 0 && "mt-4 border-top"} pt-3`}
                          >
                            <Row className="align-items-center mb-2">
                              <Col xs="12" sm="">
                                <p
                                  className="mt-0 mb-0 text-dark font-weight-500"
                                  style={{ fontSize: 16, lineHeight: 1.3 }}
                                >
                                  {store?.storeName}
                                </p>
                              </Col>
                              <Col xs="12" sm="auto" className="mt-1 mt-sm-0">
                                <Text>
                                  ~
                                  {StringUtils.numberFormat(
                                    store?.count.toFixed(1)
                                  )}
                                  &nbsp;Orders / Member
                                </Text>
                              </Col>
                            </Row>
                            <ProgressBar
                              marginTop="mt-1"
                              percentageValue={(store?.count / 20) * 100}
                              color={store.goalColor}
                              showAnimation={true}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="mt-3 mx--4 px-4">
                      <div className="mb--4 pb-4">
                        {this.state.currentCount?.history?.map((store, i) => (
                          <div
                            key={i}
                            className={`${i != 0 && "mt-4 border-top"} pt-3`}
                          >
                            <p
                              className="mt-0 mb-1 text-dark font-weight-500"
                              style={{ fontSize: 16, lineHeight: 1.3 }}
                            >
                              {store?.storeName}
                            </p>

                            <Row className="align-items-center mb-2">
                              <Col xs="12" sm="">
                                <Text>
                                  ~
                                  {StringUtils.numberFormat(
                                    store?.count.toFixed(1)
                                  )}
                                  &nbsp;Orders / Member
                                </Text>
                              </Col>
                              <Col xs="12" sm="auto" className="mt-1 mt-sm-0">
                                <Text>
                                  {(this.state.average
                                    ? (store?.count - this.state.average) /
                                      this.state.average
                                    : 0) > 0
                                    ? "+"
                                    : ""}
                                  {StringUtils.numberFormat(
                                    (this.state.average
                                      ? ((store?.count - this.state.average) /
                                          this.state.average) *
                                        100
                                      : 0
                                    ).toFixed(1)
                                  )}
                                  %&nbsp;vs. all-store avg.
                                </Text>
                              </Col>
                            </Row>

                            <DeltaBar
                              marginTop="mt-1"
                              percentageValue={
                                this.state.average
                                  ? ((store?.count - this.state.average) /
                                      this.state.average) *
                                    100
                                  : 0
                              }
                              isIncreasePositive={true}
                              showAnimation={true}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "calc(327px + 1rem)", width: "100%" }}
                >
                  <p className="m-0">
                    No data available.
                    {this.state.error ? ` ${this.state.error}` : null}
                  </p>
                </div>
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default PercentLeanConsultByStoreCard;
