import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { Checkbox } from "rsuite";
import Alert from "reactstrap/lib/Alert";
import CustomerOpenActivitiesWidget from "./CustomerOpenActivitiesWidget";
import CustomerUnclaimedBoxWidget from "./CustomerUnclaimedBoxWidget";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import Badge from "reactstrap/lib/Badge";

class CustomerShippingAddressWidget extends React.Component {
  state = {
    expand: false,
    phone: {
      value: "",
    },
    name: "",
    email: "",
  };

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 4000);
  }

  updateShippingAddress() {
    this.setState({ submitting: true });

    let payload = {};

    if (this.state.sameAsBilling) {
      payload = {
        name: this.props.customer?.name,
        address1: this.props.customer?.billingAddress?.address1,
        address2: this.props.customer?.billingAddress?.address2,
        city: this.props.customer?.billingAddress?.city,
        state: this.props.customer?.billingAddress?.state,
        zip: this.props.customer?.billingAddress?.zip,
      };
    } else {
      payload = {
        name: this.props.customer?.name,
        address1: this.state.address1,
        address2: this.state.address2,
        city: this.state.city,
        state: this.state.state,
        zip: this.state.zip,
      };
    }

    APIV2.setCustomerVariable(
      this.props?.match?.params?.storeID,
      this.props?.customer?._id,
      "shippingAddress",
      payload
    )
      .then(
        (data) => {
          PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);

          this.setState({ openModify: false });
        },
        (e) => {
          this.setError(
            "error",
            "Failed to update billing address. try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  checkBillingAddress() {
    if (this.props.customer) {
      if (
        this.props.customer?.billingAddress?.address1 ==
          this.props.customer?.shippingAddress?.address1 &&
        this.props.customer?.billingAddress?.address2 ==
          this.props.customer?.shippingAddress?.address2 &&
        this.props.customer?.billingAddress?.city ==
          this.props.customer?.shippingAddress?.city &&
        this.props.customer?.billingAddress?.state ==
          this.props.customer?.shippingAddress?.state &&
        this.props.customer?.billingAddress?.zip ==
          this.props.customer?.shippingAddress?.zip
      ) {
        this.setState({ sameAsBilling: true });

        return;
      }

      this.setState({ sameAsBilling: false });

      return;
    }

    this.setState({ sameAsBilling: false });
  }

  componentDidMount() {
    this.checkBillingAddress();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.customer != this.props.customer ||
      prevProps?.customer?.billingAddress?.address1 !=
        this.props?.customer?.billingAddress?.address1 ||
      prevProps?.customer?.billingAddress?.address2 !=
        this.props?.customer?.billingAddress?.address2 ||
      prevProps?.customer?.billingAddress?.city !=
        this.props?.customer?.billingAddress?.city ||
      prevProps?.customer?.billingAddress?.state !=
        this.props?.customer?.billingAddress?.state ||
      prevProps?.customer?.billingAddress?.zip !=
        this.props?.customer?.billingAddress?.zip
    ) {
      this.checkBillingAddress();
    }
  }

  handleGoogleAutocomplete(place, ref) {
    let streetNumber = "";
    let address1 = "";
    let city = "";
    let state = "";
    let zip = "";

    if (
      !place ||
      !place.address_components ||
      !place.address_components.length
    ) {
      return;
    }

    for (let i = 0; i < place.address_components.length; i++) {
      let comp = place.address_components[i];

      if (comp.types && comp.types.indexOf("street_number") >= 0) {
        streetNumber = comp.long_name;
      }

      if (comp.types && comp.types.indexOf("route") >= 0) {
        address1 = comp.long_name;
      }

      if (comp.types && comp.types.indexOf("locality") >= 0) {
        city = comp.long_name;
      }

      if (
        comp.types &&
        comp.types.indexOf("administrative_area_level_1") >= 0
      ) {
        state = comp.long_name;
      }

      if (comp.types && comp.types.indexOf("postal_code") >= 0) {
        zip = comp.long_name;
      }
    }

    this.setState({
      address1: `${streetNumber} ${address1}`,
      city,
      state,
      zip,
    });

    ref.value = `${streetNumber} ${address1}`;
  }

  render() {
    return (
      <>
        <Card className="mb-4 shadow border">
          <CardHeader>
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Shipping Address</h3>
              </div>
              {this.props.modifiable ? (
                <Col xs="auto">
                  {!this.state.openModify ? (
                    <Button
                      size="sm"
                      color="secondary"
                      outline
                      onClick={() => {
                        this.setState({
                          openModify: true,
                          address1:
                            this.props.customer?.shippingAddress?.address1,
                          address2:
                            this.props.customer?.shippingAddress?.address2,
                          city: this.props.customer?.shippingAddress?.city,
                          state: this.props.customer?.shippingAddress?.state,
                          zip: this.props.customer?.shippingAddress?.zip,
                        });
                      }}
                    >
                      Edit
                    </Button>
                  ) : (
                    <>
                      <Button
                        size="sm"
                        color="secondary"
                        onClick={() => {
                          this.setState({
                            openModify: false,
                          });
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="sm"
                        color="primary"
                        disabled={
                          this.state.submitting ||
                          (!this.state.sameAsBilling &&
                            (!this.state.address1 ||
                              !this.state.city ||
                              !this.state.state ||
                              !this.state.zip))
                        }
                        onClick={() => {
                          this.updateShippingAddress();
                        }}
                      >
                        {this.state.submitting ? (
                          <Spinner color="white" size="sm" />
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  )}
                </Col>
              ) : null}
            </Row>
          </CardHeader>
          {this.props.loading ? (
            <CardBody>
              <Spinner size="sm" color="dark" className="mr-2" /> Loading...
            </CardBody>
          ) : (
            <CardBody>
              {this.state.openModify ? (
                <>
                  <Row>
                    <Col xs="12" className="mb-3">
                      <Checkbox
                        checked={this.state.sameAsBilling}
                        onChange={(t, e) => this.setState({ sameAsBilling: e })}
                      >
                        {" "}
                        Same as billing address
                      </Checkbox>
                    </Col>
                  </Row>
                  {!this.state.sameAsBilling ? (
                    <>
                      <Row>
                        <Col xs="12" lg="12" className="mb-3">
                          <h5>
                            Address <span className="text-danger">*</span>
                          </h5>
                          <ReactGoogleAutocomplete
                            required
                            placeholder="Address"
                            apiKey={"AIzaSyA5-fbnedb5uXDBwbjnz0J_2eDe_9Gi2Cs"}
                            onPlaceSelected={this.handleGoogleAutocomplete.bind(
                              this
                            )}
                            onChange={this.handleInputChange
                              .bind(this)
                              .bind(this)}
                            name="address1"
                            options={{ types: ["address"] }}
                            value={this.state.address1}
                            className="form-control"
                            //value={this.state.address1}
                          ></ReactGoogleAutocomplete>
                          {/*<Input
                      autoComplete="billing street-address"
                      name="address1"
                      value={this.state.address1}
                      onChange={this.handleInputChange.bind(this)}
                      id="name"
                      placeholder="Address"
                    ></Input>*/}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" lg="6" className="mb-3">
                          <h5>Apartment, Suite, etc.</h5>
                          <Input
                            name="address2"
                            value={this.state.address2}
                            onChange={this.handleInputChange.bind(this)}
                            id="name"
                            placeholder="Apartment, suite, etc. (optional)"
                          ></Input>
                        </Col>
                        <Col xs="12" lg="6" className="mb-3">
                          <h5>
                            City <span className="text-danger">*</span>
                          </h5>
                          <Input
                            required
                            name="city"
                            value={this.state.city}
                            onChange={this.handleInputChange.bind(this)}
                            id="name"
                            placeholder="City"
                          ></Input>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" md="6" className="mb-3">
                          <h5>
                            State <span className="text-danger">*</span>
                          </h5>
                          <Input
                            required
                            name="state"
                            value={this.state.state}
                            onChange={this.handleInputChange.bind(this)}
                            id="name"
                            placeholder="State"
                          ></Input>
                        </Col>
                        <Col xs="12" md="6" className="mb-3">
                          <h5>
                            Zip Code <span className="text-danger">*</span>
                          </h5>
                          <Input
                            required
                            name="zip"
                            value={this.state.zip}
                            onChange={this.handleInputChange.bind(this)}
                            id="name"
                            placeholder="Zip Code"
                          ></Input>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  <Row className="align-items-center">
                    <Col xs="12">
                      <p className="mb-0 text-dark" style={{ lineHeight: 1.2 }}>
                        {this.props.customer?.name}
                      </p>
                      {this.props.customer?.shippingAddress ? (
                        <>
                          <p
                            className="mb-0 text-dark"
                            style={{ lineHeight: 1.25, fontSize: "13px" }}
                          >
                            {this.props.customer?.shippingAddress?.address1}
                          </p>
                          {this.props.customer?.shippingAddress?.address2 ? (
                            <p
                              className="mb-0 text-dark"
                              style={{ lineHeight: 1.25, fontSize: "13px" }}
                            >
                              {this.props.customer?.shippingAddress?.address2}
                            </p>
                          ) : null}
                          <p
                            className="mb-0 text-dark"
                            style={{ lineHeight: 1.25, fontSize: "13px" }}
                          >
                            {this.props.customer?.shippingAddress?.city},{" "}
                            {this.props.customer?.shippingAddress?.state
                              ? this.props.customer?.shippingAddress?.state.toUpperCase()
                              : ""}{" "}
                            {this.props.customer?.shippingAddress?.zip}
                          </p>
                        </>
                      ) : (
                        <Badge color="" className="mb-0 bg-yellow text-dark">
                          No address
                        </Badge>
                      )}
                    </Col>
                  </Row>
                </>
              )}
              {this.state.error ? (
                <Alert className="mt-3 mb-0" color="danger">
                  {this.state.error}
                </Alert>
              ) : null}
            </CardBody>
          )}
        </Card>
      </>
    );
  }
}

export default withRouter(CustomerShippingAddressWidget);
