import { Check, ChevronDown } from "lucide-react";
import React from "react";

/** 
export interface BubbleColorMenuItem {
  name: string;
  color: string | null;
}
*/

/**
interface ColorSelectorProps {
  editor: Editor;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}
 */

const TEXT_COLORS = [
  {
    name: "Default",
    color: "var(--dark)",
  },
  {
    name: "Medium Gray",
    color: "var(--medium)",
  },
  {
    name: "Light Gray",
    color: "var(--light)",
  },
  {
    name: "Orange",
    color: "var(--primary)",
  },
  {
    name: "Green",
    color: "var(--success)",
  },
  {
    name: "Red",
    color: "var(--danger)",
  },
  {
    name: "Black",
    color: "#000000",
  },
  {
    name: "White",
    color: "#FFFFFF",
  },
];

const HIGHLIGHT_COLORS = [
  {
    name: "Default",
    color: "var(--dark)",
  },
  {
    name: "Medium Gray",
    color: "var(--medium)",
  },
  {
    name: "Light Gray",
    color: "var(--light)",
  },
  {
    name: "Orange",
    color: "var(--primary)",
  },
  {
    name: "Green",
    color: "var(--success)",
  },
  {
    name: "Red",
    color: "var(--danger)",
  },
  {
    name: "Black",
    color: "#000000",
  },
  {
    name: "White",
    color: "#FFFFFF",
  },
];

export const ColorSelector = ({ editor, isOpen, setIsOpen }) => {
  const activeColorItem = TEXT_COLORS.find(({ color }) =>
    editor.isActive("textStyle", { color })
  );

  const activeHighlightItem = HIGHLIGHT_COLORS.find(({ color }) =>
    editor.isActive("highlight", { color })
  );

  return (
    <div className="relative h-full">
      <button
        className="d-flex h-full align-items-center gap-1 p-2 text-sm font-medium text-dark text-primary-superlight--hover"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span
          className="rounded-sm px-1"
          style={{
            color: activeColorItem?.color,
            backgroundColor: activeHighlightItem?.color,
          }}
        >
          A
        </span>

        <ChevronDown className="h-4 w-4" />
      </button>

      {isOpen && (
        <section
          style={{
            position: "fixed",
            maxHeight: 300,
            height: "auto",
            overflowY: "auto",
            width: 150,
          }}
          className="mt-1 rounded border bg-white p-1 shadow animate-in fade-in slide-in-from-top-1"
        >
          <div className="py-1 font-weight-500 px-2 text-sm text-dark mb-2 border-bottom">
            Color
          </div>
          {TEXT_COLORS.map(({ name, color }, index) => (
            <button
              key={index}
              onClick={() => {
                editor.commands.unsetColor();
                name !== "Default" &&
                  editor.chain().focus().setColor(color).run();
                setIsOpen(false);
              }}
              className="d-flex align-items-center justify-between rounded-sm px-2 py-1 text-sm text-dark text-primary-superlight--hover"
            >
              <div className="d-flex align-items-center space-x-2">
                <div
                  className="rounded-sm border px-1 py-px font-medium"
                  style={{ color }}
                >
                  A
                </div>
                <span className="pl-2">{name}</span>
              </div>
              {editor.isActive("textStyle", { color }) && (
                <Check style={{ width: 15, height: 15 }} />
              )}
            </button>
          ))}

          <div className="py-1 mt-2 font-weight-500 px-2 text-sm text-dark mb-2 border-bottom">
            Background
          </div>

          {HIGHLIGHT_COLORS.map(({ name, color }, index) => (
            <button
              key={index}
              onClick={() => {
                editor.commands.unsetHighlight();
                name !== "Default" && editor.commands.setHighlight({ color });
                setIsOpen(false);
              }}
              className="d-flex align-items-center justify-between rounded-sm px-2 py-1 text-sm text-dark text-primary-superlight--hover"
            >
              <div className="d-flex align-items-center space-x-2">
                <div
                  className="rounded-sm border border-stone-200 px-1 py-px font-medium"
                  style={{ backgroundColor: color }}
                >
                  A
                </div>
                <span className="pl-2">{name}</span>
              </div>
              {editor.isActive("highlight", { color }) && (
                <Check className="h-4 w-4" />
              )}
            </button>
          ))}
        </section>
      )}
    </div>
  );
};
