import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Spinner,
  Collapse,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import moment from "moment";
import { CheckboxGroup, DatePicker, SelectPicker, Checkbox } from "rsuite";
import StringUtils from "lib/StringUtils";
import { Table, Cell, Column, HeaderCell } from "rsuite-table";
import _ from "underscore";
import MoneyCell from "components/Table/MoneyCell";
import NumericCell from "components/Table/NumericCell";
import StoreCell from "components/Table/StoreCell";
import BootstrapTable from "react-bootstrap-table-next";
import MealsOrderedWidget from "components/Widgets/Fulfillment/MealsOrderedWidget";
import PlansFulfilledWidget from "components/Widgets/Fulfillment/PlansFulfilledWidget";
import AverageBoxSizeWidget from "components/Widgets/Fulfillment/AverageBoxSizeWidget";
import PlanFrequencyChartWidget from "components/Widgets/Fulfillment/PlanFrequencyChartWidget";
import PlanProcurementChartWidget from "components/Widgets/Fulfillment/PlanProcurementChartWidget";
import PlanTypeChartWidget from "components/Widgets/Fulfillment/PlanTypeChartWidget";
import { Link } from "react-router-dom";
import Input from "reactstrap/lib/Input";
import swal from "sweetalert";
import CustomerPickupListRow from "components/CustomerPickupListRow";
import CustomerOrderDrawer from "components/Drawers/CustomerOrderDrawer";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import LinkUtils from "lib/LinkUtils";

class StoreFulfillmentPickupListView extends React.Component {
  state = {
    stores: [],
    loading: true,
    fulfillmentDate: new Date(),
    availableDates: [],
    storeOpen: true,
    mealOpen: true,
    planOpen: true,
    storePerformance: [],
    dates: [],
    orders: [],
    compareToOptions: [],
    count: 50,
    page: 1,
    selectedOrders: [],
    searchResultOrders: [],
    active: {
      id: "All",
    },
    filters: {
      status: { $ne: "CANCELLED" },
      "subscription.plan.procurement": { $eq: "pickup" },
    },
  };

  getAvailableDates() {
    return new Promise((resolve) => {
      APIV2.getStoreSubscriptionFulfillmentDates(
        this.props.match?.params?.storeID
      )
        .then(
          (data) => {
            let dates = data.data.dates;

            dates.push(this.state.fulfillmentMoment.format("YYYY-MM-DD"));

            this.setState({
              availableDates: dates,
            });
          },
          (e) => {
            this.setState({
              availableDates: [],
            });

            console.error(e);
          }
        )
        .finally(() => {
          resolve();
        });
    });
  }

  _rerunStatus(reportID, jobID, callback) {
    APIV2.checkRerunSubscriptionFulfillmentReportStatus(reportID, jobID).then(
      (data) => {
        console.log(data);

        callback(data);
      },
      (e) => {
        console.log(e);

        callback(null, e);
      }
    );
  }

  checkRerunStatus(reportID, jobID) {
    let self = this;

    let load = (dateString) => {
      this.loadSummary(dateString);
    };

    return new Promise((resolve, reject) => {
      this._rerunStatus(reportID, jobID, function callback(data, error) {
        if (error) {
          resolve();

          return; // Don't continue after error
        }

        if (data?.data?.job?.progress == 100) {
          resolve();

          load(self.state.fulfillmentDateString);

          return;
        }

        setTimeout(() => {
          self._rerunStatus(reportID, jobID, callback);
        }, 5000);
      });
    });
  }

  rerunReport(reportID) {
    APIV2.rerunSubscriptionFulfillmentReport(reportID).then(
      (data) => {
        this.setState({
          rerunning: true,
          rerunJob: data.data.job,
        });

        this.checkRerunStatus(reportID, data.data.job.id).then(() => {
          console.log("DONE");
        });
      },
      (e) => {
        window.alert("Unable to rerun report. Try again.");
      }
    );
  }

  loadOrders(page = 1, count = 50, filters = null, force = false) {
    return new Promise((resolve) => {
      this.setState({
        loadingOrders: true,
        selectedOrders: [],
      });

      let orderBy = {
        "customer.name": 1,
      };

      if (force) {
        this.setState({
          orders: [],
        });
      }

      APIV2.getOrdersForStore(
        this.props.match?.params?.storeID,
        page,
        count,
        filters,
        orderBy
      ).then(
        (data) => {
          if (data && data.data) {
            let orders = [];

            if (this.state.orders?.length && !force) {
              orders = this.state.orders.concat(orders);
            }

            console.log(data.data.result);

            if (data.data.orders.length) {
              orders = orders.concat(data.data.orders);
            }

            let response = {
              orders,
              pagination: data.data.pagination,
              result: data.data.result,
              totalCount: data.data.result?.total,
              loadedCount: data?.data?.result?.end,
            };

            this.setState(response);

            resolve(response);
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the orders. Please reload and try again."
          );

          resolve({});
        }
      );
    });
  }

  searchOrders(page = 1, count = 25, filters = null) {
    this.setState({
      loadingOrders: true,
      searchResults: true,
      searchPage: page,
      selectedOrders: [],
    });

    if (filters) {
      this.setState({
        searchFilters: filters,
      });
    }

    APIV2.getOrdersForStore(
      this.props?.match?.params?.storeID,
      page,
      count,
      filters
    )
      .then(
        (data) => {
          if (data && data.data) {
            let orders = [];

            if (data.data.orders.length) {
              orders = data.data.orders;
            }

            console.log(data.data.pagination);

            this.setState({
              searchResultOrders: orders,
              searchPagination: data.data.pagination,
              searchResultOut: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the orders. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingOrders: false,
          searchLoading: false,
        });
      });
  }

  performSearch() {
    let filters = {
      $or: [
        { "customer.name": { $regex: this.state.search, $options: "i" } },
        { "customer.email": { $regex: this.state.search, $options: "i" } },
        { "customer.phone": { $regex: this.state.search, $options: "i" } },
        { status: { $regex: this.state.search, $options: "i" } },
        {
          "subscription.plan.name": {
            $regex: this.state.search,
            $options: "i",
          },
        },
      ],
      "subscription.plan.procurement": { $eq: "pickup" },
      fulfillmentDateString: {
        $regex: this.state.dateString,
        $options: "i",
      },
      status: { $ne: "CANCELLED" },
    };

    console.log(JSON.stringify(filters));

    this.setState({
      searchFilters: filters,
    });

    this.searchOrders(1, 25, filters);
  }

  clearSearch() {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);

      this.setState({
        searchTimeout: null,
      });
    }

    this.setState({
      search: "",
      searchResults: false,
    });
  }

  handleSearchChange(e) {
    const { name, value } = e.target;

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    if (value) {
      this.setState({
        searchLoading: true,
      });
    } else {
      this.setState({
        searchLoading: false,
        searchResults: false,
      });
    }

    if (value && value.length >= 3) {
      let timeout = setTimeout(() => {
        this.performSearch();
      }, 1200);

      this.setState({
        searchTimeout: timeout,
      });
    }

    this.setState({
      [name]: value,
    });
  }

  loadSummary(dateString) {
    if (!dateString) {
      return;
    }

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("date", dateString);
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.setState({
      loading: true,
      rerunning: false,
      noReport: false,
    });

    let filter = this.state.filters;

    filter.fulfillmentDateString = {
      $regex: this.state.fulfillmentDateString,
      $options: "i",
    };

    this.setState(
      {
        filters: filter,
        dateString,
      },
      () => {
        this.loadAllOrders();
      }
    );
  }

  async loadAllOrders() {
    this.setState({
      loading: true,
    });

    let res = await this.loadOrders(
      1,
      this.state.count,
      this.state.filters,
      true
    );

    while (res?.pagination?.hasNext) {
      res = await this.loadOrders(
        res.pagination.next,
        this.state.count,
        this.state.filters
      );
    }

    this.setState({
      loading: false,
      loadingOrders: false,
    });
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (this.state.searchResults) {
      page = this.state.searchPage ? this.state.searchPage + count : 1 + count;
    }

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    if (this.state.searchResults) {
      this.searchOrders(page, this.state.count, this.state.searchFilters);
    } else {
      this.loadOrders(page, this.state.count, this.state.filters);
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const date = urlParams.get("date");

    let fulfillmentDate = moment();

    if (date) {
      fulfillmentDate = moment(date);
    }

    fulfillmentDate
      .set("day", 6)
      .set("hours", 0)
      .set("minutes", 0)
      .set("seconds", 0);

    this.setState(
      {
        fulfillmentDate: fulfillmentDate.toDate(),
        fulfillmentMoment: fulfillmentDate,
        fulfillmentDateString: fulfillmentDate.format("YYYY-MM-DD"),
      },
      () => {
        this.getAvailableDates().then(() => {
          this.loadSummary(this.state.fulfillmentDateString);
        });
      }
    );

    PubSub.subscribe(Event.ORDER.MODIFIED, (order) => {
      let orderIdx = _.findIndex(this.state.orders, { _id: order._id });
      let searchIdx = _.findIndex(this.state.searchResultOrders, {
        _id: order._id,
      });

      if (orderIdx >= 0) {
        let orders = this.state.orders;

        orders[orderIdx] = order;

        this.setState({
          orders,
        });
      }

      if (searchIdx >= 0) {
        let searchResultOrders = this.state.searchResultOrders;

        searchResultOrders[searchIdx] = order;

        this.setState({
          searchResultOrders,
        });
      }

      if (this.state.viewOrder?._id == order._id) {
        this.setState({
          viewOrder: order,
        });
      }
    });

    PubSub.subscribe(Event.ORDER.OPEN, (order) => {
      this.setState({
        drawer: true,
        viewOrder: order,
      });
    });
  }

  toggleSelected(order, val) {
    let selected = this.state.selectedOrders;

    if (!val) {
      selected = _.filter(selected, (o) => {
        return o._id != order._id;
      });
    } else {
      if (!this.isSelected(order)) {
        selected.push(_.clone(order));
      }
    }

    this.setState({ selectedOrders: selected }, () => {});
  }

  toggleAllSelected(val) {
    this.setState(
      {
        selectedOrders: [],
      },
      () => {
        if (this.state.searchResults) {
          for (let i = 0; i < this.state.searchResultOrders.length; i++) {
            this.toggleSelected(this.state.searchResultOrders[i], val);
          }
        } else {
          for (let i = 0; i < this.state.orders.length; i++) {
            this.toggleSelected(this.state.orders[i], val);
          }
        }
      }
    );
  }

  isSelected(order) {
    return _.findWhere(this.state.selectedOrders, { _id: order?._id })
      ? true
      : false;
  }

  allSelected() {
    let all = true;

    if (this.state.searchResults) {
      for (let i = 0; i < this.state.searchResultOrders.length; i++) {
        if (!this.isSelected(this.state.searchResultOrders[i])) {
          all = false;
        }
      }
    } else {
      for (let i = 0; i < this.state.orders.length; i++) {
        if (!this.isSelected(this.state.orders[i])) {
          all = false;
        }
      }
    }

    return all;
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding pb-4 bg-white" fluid>
          <PageHeader
            forceSecondaryDropdown={true}
            title="Pickup List"
            showBackButton={false}
            secondaryActions={[
              {
                id: "printPickupList",
                name: <>Print Pickup List</>,
                onClick: () => {
                  LinkUtils.openInNewTab(
                    "/storefront-print/" +
                      this.props.match?.params?.storeID +
                      "/fulfillment/orders/pickup-list?date=" +
                      this.state.fulfillmentDateString,
                    true
                  );
                },
              },
              {
                id: "printPickupList",
                name: <>Download Pickup List</>,
                onClick: () => {
                  LinkUtils.openInNewTab(
                    "/storefront-print/" +
                      this.props.match?.params?.storeID +
                      "/fulfillment/orders/pickup-list/csv?date=" +
                      this.state.fulfillmentDateString,
                    true
                  );
                },
              },
            ]}
          />
          {/* Table */}
          <div className="mt--2 mb-3">
            <Row className="align-items-center">
              <Col xs="12" sm="6" md="">
                <h3 className="m-0">
                  {this.state.fulfillmentMoment?.format("ddd, MMM Do YYYY")}
                </h3>
                {!this.state.loading && !this.state.rerunning ? (
                  <small style={{ lineHeight: 1 }}>
                    {this.state.result?.total
                      ? StringUtils.numberFormat(this.state.result?.total)
                      : "0"}
                    &nbsp;orders
                  </small>
                ) : null}
              </Col>
              <Col xs="12" sm="6" md="auto">
                <DatePicker
                  oneTap
                  value={this.state.fulfillmentDate}
                  onChange={(v) => {
                    let mom = moment(v.toISOString());

                    mom.set("hours", 0).set("minutes", 0).set("seconds", 0);

                    this.setState(
                      {
                        fulfillmentDate: mom.toDate(),
                        fulfillmentMoment: mom,
                        fulfillmentDateString: mom.format("YYYY-MM-DD"),
                      },
                      () => {
                        this.loadSummary(
                          this.state.fulfillmentDateString,
                          this.state.comparisonValue
                        );
                      }
                    );
                  }}
                  className="mt-3 mt-md-0"
                  placeholder="Select Date"
                  style={{
                    minWidth: "200px",
                    width: "100%",
                    maxWidth: "800px",
                  }}
                  placement="autoVerticalEnd"
                  block
                  cleanable={false}
                  disabledDate={(date) => {
                    let mom = moment(date.toISOString());

                    if (!this.state.availableDates) {
                      return false;
                    }

                    return this.state.availableDates.indexOf(
                      mom.format("YYYY-MM-DD")
                    ) >= 0
                      ? false
                      : true;
                  }}
                />
              </Col>
            </Row>
          </div>

          {this.state.loading ? (
            <>
              <Card className="border mb-5">
                <CardHeader>
                  <h3 className="m-0">
                    <Spinner color="dark" size="sm"></Spinner>
                    <span className="pl-3">
                      Loading Pickup List{" "}
                      {this.state.loadedCount ? (
                        <>
                          ({this.state.loadedCount} of {this.state.totalCount})
                        </>
                      ) : null}
                    </span>
                  </h3>
                </CardHeader>
                <CardBody>
                  Hang tight while we fetch your pickup orders for{" "}
                  {this.state.fulfillmentMoment?.format("dddd, MMM Do YYYY")}.
                </CardBody>
              </Card>
            </>
          ) : null}
          {!this.state.loading && !this.state.orders?.length ? (
            <>
              <Card className="border shadow-none mb-5">
                <CardHeader>
                  <h3 className="m-0">
                    {moment().isBefore(this.state.fulfillmentMoment)
                      ? "Pickup List Not Ready"
                      : "No Orders Available For Pickup"}
                  </h3>
                </CardHeader>
                <CardBody>
                  {moment().isBefore(this.state.fulfillmentMoment) ? (
                    <>
                      <p className="mb-0">
                        Check back on Thursday to view the pickup list for this
                        week's orders.
                      </p>
                    </>
                  ) : (
                    <>
                      There are no orders available for pickup on{" "}
                      {this.state.fulfillmentMoment?.format(
                        "dddd, MMM Do YYYY"
                      )}
                      . Try choosing another date.
                    </>
                  )}
                </CardBody>
              </Card>
            </>
          ) : null}
          {!this.state.loading && this.state.orders?.length ? (
            <>
              <Card className="mb-5 border-0" style={{ minHeight: "500px" }}>
                <CardHeader className="px-0 pt-0 pb-3">
                  <Row className="align-items-center">
                    <Col xs="12" sm="">
                      <Input
                        type="text"
                        placeholder="Search by member name"
                        name="search"
                        value={this.state.search}
                        onChange={this.handleSearchChange.bind(this)}
                      ></Input>
                    </Col>
                    {/*<Col xs="6" sm="auto" className="mt-3 pr-0 mt-sm-0">
                      <SelectPicker
                        searchable={false}
                        size="sm"
                        placeholder="Sort By"
                        disabled={true}
                        data={[]}
                        block
                        placement="autoVerticalEnd"
                      />
                    </Col>
                    <Col xs="6" sm="auto" className="mt-3 mt-sm-0">
                      <Button
                        color="secondary"
                        size="sm"
                        block
                        outline
                        onClick={() => {
                          PubSub.publish(Event.FILTERS.CUSTOMERS_OPEN);
                        }}
                        disabled
                      >
                        Filter
                      </Button>
                    </Col>*/}
                  </Row>
                  <Row className="align-items-center mt-2">
                    {/*<Col xs="auto" sm="auto" className="pr-0">
                      <Checkbox
                        indeterminate={true}
                        checked={true}
                        onChange={() => {}}
                      ></Checkbox>
                </Col>*/}
                    <Col xs="" sm="" className="">
                      <div>
                        {this.state.searchResults ? (
                          <>
                            {this.state.searchResultOut?.start &&
                            this.state.searchResultOut?.end ? (
                              <>
                                Showing{" "}
                                {StringUtils.numberFormat(
                                  this.state.searchResultOut?.start
                                )}
                                -
                                {StringUtils.numberFormat(
                                  this.state.searchResultOut?.end
                                )}
                                {this.state.searchResultOut?.total ? (
                                  <>
                                    &nbsp;of{" "}
                                    {StringUtils.numberFormat(
                                      this.state.searchResultOut?.total
                                    )}{" "}
                                    orders
                                  </>
                                ) : null}
                              </>
                            ) : (
                              <>---</>
                            )}
                          </>
                        ) : (
                          <>
                            {this.state.result?.start &&
                            this.state.result?.end ? (
                              <>
                                Showing all&nbsp;
                                {StringUtils.numberFormat(
                                  this.state.result?.total
                                )}{" "}
                                orders
                              </>
                            ) : (
                              <>---</>
                            )}
                          </>
                        )}
                      </div>
                    </Col>
                    {/**
                     * <Col
                      xs="auto"
                      sm="auto"
                      className="pl-2 pl-sm-3 text-right"
                    >
                      {this.state.searchResults ? (
                        <>
                          <Button
                            style={{ height: "28px" }}
                            size="sm"
                            color="secondary"
                            className="btn-icon-only"
                            outline
                            disabled={
                              this.state.searchPage == 1 ||
                              !this.state.searchPagination?.hasPrevious
                            }
                            onClick={() => {
                              this.incrementPage(-1);
                            }}
                          >
                            <i
                              className="mdi mdi-chevron-left"
                              style={{ fontSize: "18px" }}
                            ></i>
                          </Button>
                          <Button
                            style={{ height: "28px" }}
                            size="sm"
                            color="secondary"
                            disabled={!this.state.searchPagination?.hasNext}
                            className="btn-icon-only"
                            outline
                            onClick={() => {
                              this.incrementPage(1);
                            }}
                          >
                            <i
                              className="mdi mdi-chevron-right"
                              style={{ fontSize: "18px" }}
                            ></i>
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            style={{ height: "28px" }}
                            size="sm"
                            color="secondary"
                            className="btn-icon-only"
                            outline
                            disabled={
                              this.state.page == 1 ||
                              !this.state.pagination?.hasPrevious
                            }
                            onClick={() => {
                              this.incrementPage(-1);
                            }}
                          >
                            <i
                              className="mdi mdi-chevron-left"
                              style={{ fontSize: "18px" }}
                            ></i>
                          </Button>
                          <Button
                            style={{ height: "28px" }}
                            size="sm"
                            color="secondary"
                            disabled={!this.state.pagination?.hasNext}
                            className="btn-icon-only"
                            outline
                            onClick={() => {
                              this.incrementPage(1);
                            }}
                          >
                            <i
                              className="mdi mdi-chevron-right"
                              style={{ fontSize: "18px" }}
                            ></i>
                          </Button>
                        </>
                      )}
                    </Col>
                     */}
                  </Row>
                </CardHeader>
                <CardBody className="p-0 border-left border-right border-bottom">
                  <div className="py-2 px-3 bg-superlight border-bottom border-top text-dark">
                    <Row className="align-items-center">
                      <Col xs="" sm="" md="">
                        <h4 className="mb-0 d-none d-md-block">Member</h4>
                        <h4 className="mb-0 d-md-none">Order</h4>
                      </Col>
                      <Col xs="6" sm="6" md="" className="d-none d-md-block">
                        <h4 className="mb-0">Plan</h4>
                      </Col>
                      <Col xs="6" sm="6" md="2" className="d-none d-md-block">
                        <h4 className="mb-0">Invoice</h4>
                      </Col>
                      <Col xs="6" sm="6" md="2" className="d-none d-md-block">
                        <h4 className="mb-0">Order</h4>
                      </Col>
                      <Col xs="auto" className="d-none d-md-block">
                        <div className="" style={{ fontSize: 24, width: 24 }}>
                          &nbsp;
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div>
                    {this.state.loadingOrders || this.state.searchLoading ? (
                      <>
                        <div
                          className="text-center p-3"
                          style={{
                            zIndex: 1,
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            background: "rgba(255, 255, 255, 0.6)",
                          }}
                        >
                          <Spinner
                            style={{ position: "relative", top: "15vh" }}
                            color="medium"
                            size=""
                          ></Spinner>
                        </div>
                      </>
                    ) : null}
                    {this.state.searchResults ? (
                      <div>
                        {!this.state.searchResultOrders?.length &&
                        !this.state.searchLoading ? (
                          <div className="px-3 py-2">
                            <Row>
                              <Col>
                                No orders found matching your search "
                                {this.state.search}"
                              </Col>
                            </Row>
                          </div>
                        ) : null}

                        {this.state.searchResultOrders?.map((c, i) => (
                          <div
                            className={`${
                              i != this.state.orders.length - 1
                                ? "border-bottom"
                                : ""
                            }`}
                            key={i}
                          >
                            <CustomerPickupListRow
                              onSelect={(v) => {
                                this.toggleSelected(v);
                              }}
                              selected={this.isSelected(c)}
                              order={c}
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>
                        {!this.state.orders?.length && !this.state.orders ? (
                          <div className="px-3 py-2">
                            <Row>
                              <Col>No orders found.</Col>
                            </Row>
                          </div>
                        ) : null}

                        {this.state.orders?.map((c, i) => (
                          <div
                            className={`${
                              i != this.state.orders.length - 1
                                ? "border-bottom"
                                : ""
                            }`}
                            key={i}
                          >
                            <CustomerPickupListRow
                              order={c}
                              onSelect={(v) => {
                                this.toggleSelected(c, v);
                              }}
                              selected={this.isSelected(c)}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </>
          ) : null}
        </Container>
        <CustomerOrderDrawer
          order={this.state.viewOrder}
          onClose={() => {
            this.setState({ drawer: false });
          }}
          open={this.state.drawer}
          showMemberNotes={true}
        ></CustomerOrderDrawer>
      </>
    );
  }
}

export default StoreFulfillmentPickupListView;
