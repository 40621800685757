import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import { withRouter } from "react-router-dom";
import APIV2 from "lib/APIV2";

class DeleteStoreModal extends React.Component {
  state = {
    open: false,
    id: null,
    name: "",
    submitting: false,
    store: null,
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });

    if (this.state[state] == true) {
      this.setState({
        name: "",
      });
    }
  };

  handleNameChange(event) {
    const val = event.target.value;

    this.setState({
      name: val,
    });
  }

  submit(e) {
    e.preventDefault();

    if (this.state.name != this.state.store.name) {
      window.alert("Please type the store name to continue.");

      return;
    }

    this.setState({
      submitting: true,
    });

    APIV2.deleteStore(this.state.store._id)
      .then(
        (data) => {
          this.props.history.push("/admin/stores");

          window.alert("The store was successfully closed.");
        },
        (e) => {
          console.error(e);
          window.alert("We were unable to closed the store. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  componentDidMount() {
    PubSub.subscribe(Event.STORE.DELETE_OPEN, (s) => {
      this.setState({
        open: true,
        store: s,
        name: "",
      });
    });
  }

  render() {
    return (
      <>
        <Modal isOpen={this.state.open} toggle={() => this.toggleModal("open")}>
          <div className="modal-header">
            <h5 className="modal-title" id="createBAModalLabel">
              Close Store
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("open")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Form onSubmit={this.submit.bind(this)}>
            <div className="modal-body">
              <p>
                Are you sure you want to close this store? This will make it
                unavailable to new customers.
              </p>

              <h5 className="mb-2">
                Type{" "}
                <span
                  className="badge badge-danger"
                  style={{ textTransform: "unset" }}
                >
                  {this.state.store ? this.state.store.name : null}
                </span>{" "}
                to continue:
              </h5>
              <Input
                id="createBAName"
                placeholder="Name"
                type="text"
                className="mb-3"
                required={true}
                value={this.state.name}
                onChange={this.handleNameChange.bind(this)}
              />
            </div>
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="reset"
                onClick={() => this.toggleModal("open")}
              >
                Nevermind
              </Button>
              <Button
                color="danger"
                type="submit"
                disabled={
                  this.state.submitting ||
                  (this.state.store && this.state.name != this.state.store.name)
                }
              >
                Close Store
              </Button>
            </div>
          </Form>
        </Modal>
      </>
    );
  }
}

export default withRouter(DeleteStoreModal);
