import { Cell } from "rsuite-table";
import React, { useState, useEffect } from "react";
import StringUtils from "lib/StringUtils";
import { Link } from "react-router-dom";

function StoreCell({ rowData, dataKey, ...props }) {
  const [store, setstore] = useState({});

  useEffect(() => {
    if (typeof rowData[dataKey] == "function") {
      setstore(rowData[dataKey]());
    } else if (typeof rowData[dataKey] == "object") {
      setstore(rowData[dataKey]);
    }
  }, [rowData]);

  return (
    <>
      <Cell {...props}>
        {store?.name
          ? store?.name.replace("Project LeanNation", "PLN")
          : "Loading.."}
      </Cell>
    </>
  );
}

export default StoreCell;
