import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  BarChart,
  BarList,
  Card,
  CategoryBar,
  DeltaBar,
  DonutChart,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import {
  Axis,
  Chart,
  Coordinate,
  Interaction,
  Interval,
  Legend,
  Tooltip,
} from "bizcharts";
import ChartMetricHeader from "./ChartMetricHeader";

class SubLTVByStoreCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    tab: "absolute",
  };

  /**
   * Fetches a summary of all the member data
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchData(cubejsApi, stores, dateRange, metricNames = []) {
    let metricType = "overall";

    if (this.props.metricType) {
      metricType = this.props.metricType;
    }

    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: [
            "MetricsLTV.avgMetadataFulfilledorderltv",
            "MetricsLTV.avgMetadataTotalcustomers",
          ],
          order: {
            "MetricsLTV.createdat": "asc",
          },

          timeDimensions: [
            {
              dimension: "MetricsLTV.createdat",
              dateRange: [dateRange[0], dateRange[1]],
            },
          ],
          dimensions: ["Stores.name"],
          filters: [
            {
              member: "MetricsLTV.name",
              operator: "contains",
              values: [`ltv_store_${metricType}`],
            },
            {
              member: "MetricsLTV.metadataStoreid",
              operator: "equals",
              values: stores,
            },
            {
              member: "MetricsLTV.createdat",
              operator: "beforeDate",
              values: [this.props.dateRange[1].toISOString()],
            },
            {
              member: "MetricsLTV.createdat",
              operator: "beforeDate",
              values: [moment().toISOString()],
            },
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          let total = 0;
          let totalCustomers = 0;

          if (!data?.length) {
            return resolve(null);
          }

          let stores = [];

          data = data.map((item) => {
            total += item["MetricsLTV.avgMetadataFulfilledorderltv"];
            totalCustomers += item["MetricsLTV.avgMetadataTotalcustomers"];

            if (!stores?.includes(item["Stores.name"])) {
              stores.push(item["Stores.name"]);
            }

            const count = parseFloat(
              (item["MetricsLTV.avgMetadataTotalcustomers"] > 0
                ? item["MetricsLTV.avgMetadataFulfilledorderltv"] /
                  item["MetricsLTV.avgMetadataTotalcustomers"]
                : 0
              ).toFixed(1)
            );

            let goalColor = "red";

            if (this.props.metricType == "overall") {
              if (count / 100 >= 1150) {
                goalColor = "emerald";
              } else if (count / 100 >= 1000) {
                goalColor = "lime";
              } else if (count / 100 >= 800) {
                goalColor = "yellow";
              } else if (count / 100 >= 600) {
                goalColor = "orange";
              }
            } else if (this.props.metricType == "12mo") {
              if (count / 100 >= 1000) {
                goalColor = "emerald";
              } else if (count / 100 >= 800) {
                goalColor = "lime";
              } else if (count / 100 >= 700) {
                goalColor = "yellow";
              } else if (count / 100 >= 500) {
                goalColor = "orange";
              }
            }

            return {
              count: count,
              storeName: item["Stores.name"]
                ?.replace("Project LeanNation", "PLN")
                ?.trim(),
              goalColor,
            };
          });

          return resolve({
            total,
            history: data,
            average: totalCustomers > 0 ? total / totalCustomers : 0,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async _fetchReport(stores, dateRange) {
    this.setState({
      loading: true,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    if (!stores?.length) {
      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchData(cubejsApi, stores, dateRange);

      currentCount.history = _.sortBy(currentCount.history, "count");
      currentCount.history.reverse();
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load new member quantity.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });
    }

    this.setState({
      currentCount,
      average: currentCount?.average,
      loading: false,
    });

    if (typeof this.props.onLoaded == "function") {
      this.props.onLoaded();
    }
  }

  /**
   * Load all report information
   *
   * @param {*} stores
   * @param {*} dateRange
   * @param {*} compare
   */
  async loadReport(stores, dateRange, compare) {
    this.setState({
      loading: true,
    });

    this._fetchReport(stores, dateRange);
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.stores != prevProps?.stores && this.props.stores?.length) ||
      this.props.dateRange != prevProps?.dateRange ||
      this.props.metricType != prevProps?.metricType ||
      this.props.comparePrevious != prevProps.comparePrevious
    ) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }

    if (this.props.reload != prevProps.reload && this.props.reload) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  componentDidMount() {
    if (this.props.stores && this.props.dateRange) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }

    if (this.props.reload) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  render() {
    return (
      <>
        <Card marginTop="mt-0">
          <ChartMetricHeader
            title="Subscription LTV By Store"
            actions={
              <>
                <Button
                  size="sm"
                  outline
                  color="dark"
                  className="border-0 btn-icon-only"
                  disabled={this.state.loading}
                  onClick={() => {
                    this.loadReport(
                      this.props.stores,
                      this.props.dateRange,
                      this.props.comparePrevious
                    );
                  }}
                >
                  {this.state.loading ? (
                    <Spinner size="sm"></Spinner>
                  ) : (
                    <i className="mdi mdi-refresh"></i>
                  )}
                </Button>
              </>
            }
            loading={this.state.loading}
            dataAvailable={this.state.dataAvailable}
            metric={`~${StringUtils.centsToCurrency(this.state.average)}`}
            comparisonMetric={null}
            dateRange={this.props.dateRange}
            deltaType={null}
            percentChange={null}
            showPercentChange={false}
          ></ChartMetricHeader>
          <TabList
            color="orange"
            defaultValue="absolute"
            handleSelect={(value) => {
              this.setState({
                tab: value,
              });
            }}
            marginTop="mt-3"
            disabled={this.state.loading || !this.state.dataAvailable}
          >
            <Tab value="absolute" text="Absolute" />
            <Tab value="relative" text="Relative" />
          </TabList>

          {this.state.loading ? (
            <div
              className="skeleton mt-3"
              style={{ height: "calc(327px + 1rem)", width: "100%" }}
            >
              &nbsp;
            </div>
          ) : (
            <>
              {this.state.dataAvailable ? (
                <>
                  {this.state.tab == "absolute" ? (
                    <div className="mt-3 mx--4 px-4">
                      <div className="mb--4 pb-4">
                        {this.state.currentCount?.history?.map((store, i) => (
                          <div
                            key={i}
                            className={`${i != 0 && "mt-4 border-top"} pt-3`}
                          >
                            <Row className="align-items-center mb-2">
                              <Col xs="12" sm="">
                                <p
                                  className="mt-0 mb-0 text-dark font-weight-500"
                                  style={{ fontSize: 16, lineHeight: 1.3 }}
                                >
                                  {store?.storeName}
                                </p>
                              </Col>
                              <Col xs="12" sm="auto" className="mt-1 mt-sm-0">
                                <Text>
                                  ~{StringUtils.centsToCurrency(store?.count)} /
                                  Member
                                </Text>
                              </Col>
                            </Row>
                            <ProgressBar
                              marginTop="mt-1"
                              percentageValue={(store?.count / 180000) * 100}
                              color={store.goalColor}
                              showAnimation={true}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="mt-3 mx--4 px-4">
                      <div className="mb--4 pb-4">
                        {this.state.currentCount?.history?.map((store, i) => (
                          <div
                            key={i}
                            className={`${i != 0 && "mt-4 border-top"} pt-3`}
                          >
                            <p
                              className="mt-0 mb-1 text-dark font-weight-500"
                              style={{ fontSize: 16, lineHeight: 1.3 }}
                            >
                              {store?.storeName}
                            </p>

                            <Row className="align-items-center mb-2">
                              <Col xs="12" sm="">
                                <Text>
                                  ~{StringUtils.centsToCurrency(store?.count)} /
                                  Member
                                </Text>
                              </Col>
                              <Col xs="12" sm="auto" className="mt-1 mt-sm-0">
                                <Text>
                                  {(this.state.average
                                    ? (store?.count - this.state.average) /
                                      this.state.average
                                    : 0) > 0
                                    ? "+"
                                    : ""}
                                  {StringUtils.numberFormat(
                                    (this.state.average
                                      ? ((store?.count - this.state.average) /
                                          this.state.average) *
                                        100
                                      : 0
                                    ).toFixed(1)
                                  )}
                                  %&nbsp;vs. all-store avg.
                                </Text>
                              </Col>
                            </Row>

                            <DeltaBar
                              marginTop="mt-1"
                              percentageValue={
                                this.state.average
                                  ? ((store?.count - this.state.average) /
                                      this.state.average) *
                                    100
                                  : 0
                              }
                              isIncreasePositive={true}
                              showAnimation={true}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "calc(327px + 1rem)", width: "100%" }}
                >
                  <p className="m-0">
                    No data available.
                    {this.state.error ? ` ${this.state.error}` : null}
                  </p>
                </div>
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default SubLTVByStoreCard;
