import { toast } from "sonner";
import { EditorState, Plugin, PluginKey } from "@tiptap/pm/state";
import { Decoration, DecorationSet, EditorView } from "@tiptap/pm/view";
import APIV2 from "lib/APIV2";

const uploadKey = new PluginKey("upload-image");

const UploadImagesPlugin = () =>
  new Plugin({
    key: uploadKey,
    state: {
      init() {
        return DecorationSet.empty;
      },
      apply(tr, set) {
        set = set.map(tr.mapping, tr.doc);
        // See if the transaction adds or removes any placeholders
        const action = tr.getMeta(this);
        if (action && action.add) {
          const { id, pos, src } = action.add;

          //console.log("SRC", src, id, pos);

          const placeholder = document.createElement("div");
          placeholder.setAttribute("class", "img-placeholder");
          const image = document.createElement("img");
          image.setAttribute("class", "notes-image rounded border my-3");
          image.src = src;
          placeholder.appendChild(image);
          const deco = Decoration.widget(pos + 1, placeholder, {
            id,
          });
          set = set.add(tr.doc, [deco]);

          //console.log("SET", set);
        } else if (action && action.remove) {
          set = set.remove(
            set.find(null, null, (spec) => spec.id == action.remove.id)
          );
        }
        return set;
      },
    },
    props: {
      decorations(state) {
        return this.getState(state);
      },
    },
  });

export default UploadImagesPlugin;

function findPlaceholder(state, id) {
  const decos = uploadKey.getState(state);
  const found = decos.find(null, null, (spec) => spec.id == id);
  return found.length ? found[0].from : null;
}

export function startImageUpload(file, view, pos, cdnMode = "private") {
  // check if the file is an image
  if (!file.type.includes("image/")) {
    toast.error("File type not supported.");
    return;

    // check if the file size is less than 20MB
  } else if (file.size / 1024 / 1024 > 20) {
    toast.error("File size too big (max 20MB).");
    return;
  }

  //console.log(file);

  // A fresh object to act as the ID for this upload
  const id = {};

  // Replace the selection with a placeholder
  const tr = view.state.tr;
  if (!tr.selection.empty) tr.deleteSelection();

  const reader = new FileReader();
  reader.readAsDataURL(file);

  reader.onload = () => {
    tr.setMeta(uploadKey, {
      add: {
        id,
        pos,
        src: reader.result,
      },
    });
    view.dispatch(tr);
  };

  //console.log("IMAGE UPLOAD");

  handleImageUpload(file, cdnMode).then(
    (src) => {
      const { schema } = view.state;

      //console.log("INSIDE 1");

      let pos = findPlaceholder(view.state, id);
      // If the content around the placeholder has been deleted, drop
      // the image
      if (pos == null) return;

      //console.log("INSIDE 2");

      // Otherwise, insert it at the placeholder's position, and remove
      // the placeholder

      // When BLOB_READ_WRITE_TOKEN is not valid or unavailable, read
      // the image locally
      const imageSrc = typeof src === "object" ? reader.result : src;

      const node = schema.nodes.image.create({ src: imageSrc });

      //console.log(node);

      const transaction = view.state.tr
        .replaceWith(pos, pos, node)
        .setMeta(uploadKey, { remove: { id } });

      //console.log("TRANSACTION", transaction);

      view.dispatch(transaction);
    },
    (e) => {
      console.error(e);
    }
  );
}

export const handleImageUpload = (file, cdnMode) => {
  // upload to Vercel Blob
  //console.log("FILE 1");

  return new Promise(async (resolve2) => {
    //console.log("FILE 2", file);

    // Create an object of formData
    let f2 = new FormData();

    f2.append("file", file);

    //console.log("FormData", f2);

    toast.promise(
      new Promise((resolve) => {
        //console.log("FILE 3");

        let promise = null;

        if (cdnMode == "private") {
          promise = APIV2.uploadPrivateFile(f2);
        } else {
          promise = APIV2.uploadCDNImage(f2);
        }

        promise.then(
          async (res) => {
            //console.log("FILE 4");

            let imageURL = res.data.data.url;

            let url = imageURL;
            // preload the image
            let image = new Image();

            //resolve2(url);

            let presignedURL = null;

            if (cdnMode == "private") {
              presignedURL = await APIV2.getPrivateFileURL(imageURL);

              presignedURL = presignedURL?.data?.url;
            } else {
              presignedURL = imageURL;
            }

            //console.log(presignedURL?.data?.url);

            resolve2(presignedURL);

            image.src = presignedURL;

            image.onload = () => {
              //console.log("FILE 5", presignedURL);

              resolve(presignedURL);
            };
            // No blob store configured
          },
          (e) => {
            resolve2();
            throw new Error(`Error uploading image. Please try again.`);
          }
        );
      }),
      {
        loading: "Uploading image...",
        success: "Image uploaded successfully.",
        error: (e) => e.message,
      }
    );
  });
};
