import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  BarChart,
  Card,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import {
  Axis,
  Chart,
  Coordinate,
  Interaction,
  Interval,
  Legend,
  Tooltip,
} from "bizcharts";
import ChartMetricHeader from "./ChartMetricHeader";

class NewMemberDetailChartCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    tab: "performanceTrend",
  };

  /**
   * Fetches a summary of all the member data
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchData(cubejsApi, stores, dateRange, metricNames = []) {
    let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

    secondDifference = secondDifference / 1000;

    let granularity = secondDifference > 60 * 60 * 24 * 90 ? "week" : "day";

    if (secondDifference >= 60 * 60 * 24 * 364) {
      granularity = "month";
    }

    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["Metrics.count"],
          order: {
            "Metrics.createdat": "asc",
          },

          timeDimensions: [
            {
              dimension: "Metrics.createdat",
              dateRange: [dateRange[0], dateRange[1]],
              granularity,
            },
          ],
          filters: [
            {
              member: "Metrics.metadatastoreid",
              operator: "equals",
              values: stores,
            },
            {
              member: "Metrics.name",
              operator: "equals",
              values: metricNames,
            },
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          let total = 0;

          if (!data?.length) {
            return resolve(null);
          }

          data = data.map((item) => {
            total += item["Metrics.count"];

            let mom = moment(item[`Metrics.createdat.${granularity}`]);

            let granularityString = mom.format("MM/DD/YYYY");

            if (granularity == "week") {
              mom.day(0);
              granularityString = "Week " + mom.format("MM/DD/YYYY");
            }

            if (granularity == "month") {
              mom.startOf("month");

              granularityString = mom.format("MMMM YY");
            }

            return {
              count: item["Metrics.count"],
              dateObj: mom.toDate(),
              date: granularityString,
            };
          });

          return resolve({
            total,
            history: data,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async _fetchReport(stores, dateRange) {
    this.setState({
      loading: true,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    if (!stores?.length) {
      return;
    }

    let currentCountAdd = null;
    let currentCountMinus = null;
    let currentCount = null;

    try {
      currentCountAdd = await this._fetchData(cubejsApi, stores, dateRange, [
        "member_new",
        "member_reactivate",
      ]);

      currentCountMinus = await this._fetchData(cubejsApi, stores, dateRange, [
        "hfn",
      ]);

      currentCount = {
        total:
          (currentCountAdd?.total ? currentCountAdd?.total : 0) -
          (currentCountMinus?.total ? currentCountMinus?.total : 0),
        history: [],
      };

      for (let i = 0; i < currentCountAdd?.history?.length; i++) {
        const point = currentCountAdd?.history[i];

        let item = {
          count: point?.count ? point?.count : 0,
          dateObj: point?.dateObj,
          date: point?.date,
        };

        const idx = _.findIndex(currentCountMinus?.history, {
          date: point?.date,
        });

        if (idx >= 0 && currentCountMinus.history[idx].count) {
          item.count = item.count - currentCountMinus.history[idx].count;
        }

        currentCount.history.push(item);
      }

      for (let i = 0; i < currentCountMinus?.history?.length; i++) {
        const point = currentCountMinus?.history[i];

        let item = {
          count: point?.count ? point?.count * -1 : 0,
          dateObj: point?.dateObj,
          date: point?.date,
        };

        const idx = _.findIndex(currentCount?.history, {
          date: point?.date,
        });

        if (idx < 0) {
          currentCount.history.push(item);
        }
      }

      currentCount.history = _.sortBy(currentCount.history, "date");
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load new member quantity.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });
    }

    if (this.props.comparePrevious) {
      let previousCount = null;
      let previousCountAdd = null;
      let previousCountMinus = null;

      let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

      secondDifference = secondDifference / 1000;

      let granularity = secondDifference > 60 * 60 * 24 * 90 ? "week" : "day";

      if (secondDifference >= 60 * 60 * 24 * 364) {
        granularity = "month";
      }

      let startMoment = moment(dateRange[0].toISOString());
      let endMoment = moment(dateRange[1].toISOString());

      startMoment.subtract(secondDifference, "seconds");
      endMoment.subtract(secondDifference + 1, "seconds");

      try {
        previousCountAdd = await this._fetchData(
          cubejsApi,
          stores,
          [startMoment.toDate(), endMoment.toDate()],
          ["member_new", "member_reactivate"]
        );
        previousCountMinus = await this._fetchData(
          cubejsApi,
          stores,
          [startMoment.toDate(), endMoment.toDate()],
          ["hfn"]
        );
      } catch (e) {}

      previousCount = {
        total:
          (previousCountAdd?.total ? previousCountAdd?.total : 0) -
          (previousCountMinus?.total ? previousCountMinus?.total : 0),
        history: [],
      };

      for (let i = 0; i < previousCountAdd?.history?.length; i++) {
        const point = previousCountAdd?.history[i];

        let item = {
          count: point?.count ? point?.count : 0,
          dateObj: point?.dateObj,
          date: point?.date,
        };

        const idx = _.findIndex(previousCountMinus?.history, {
          date: point?.date,
        });

        if (idx >= 0 && previousCountMinus.history[idx].count) {
          item.count = item.count - previousCountMinus.history[idx].count;
        }

        previousCount.history.push(item);
      }

      for (let i = 0; i < previousCountMinus?.history?.length; i++) {
        const point = previousCountMinus?.history[i];

        let item = {
          count: point?.count ? point?.count * -1 : 0,
          dateObj: point?.dateObj,
          date: point?.date,
        };

        const idx = _.findIndex(previousCount?.history, {
          date: point?.date,
        });

        if (idx < 0) {
          previousCount.history.push(item);
        }
      }

      previousCount.history = _.sortBy(previousCount.history, "date");

      let netHistory = currentCount?.history?.map((item) => {
        return {
          dateObj: item?.dateObj,
          date: item?.date,
          "Net New Members": item?.count,
        };
      });

      startMoment = moment(dateRange[0]);
      endMoment = moment(dateRange[1]);

      let tempDate = moment(dateRange[0])
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      if (granularity == "week") {
        tempDate.day(0);
      }

      if (granularity == "month") {
        tempDate.startOf("month");
      }

      while (tempDate.toDate() <= endMoment.toDate()) {
        let granularityString = tempDate.format("MM/DD/YYYY");

        if (granularity == "week") {
          tempDate.day(0);
          granularityString = "Week " + granularityString;
        }

        if (granularity == "month") {
          tempDate.startOf("month");
          granularityString = tempDate.format("MMMM YY");
        }

        if (!_.findWhere(netHistory, { date: granularityString })) {
          netHistory.push({
            dateObj: tempDate.toDate(),
            date: granularityString,
            "Net New Members": 0,
          });
        }

        if (granularity == "day") {
          tempDate.add(1, "day");
        } else if (granularity == "week") {
          tempDate.add(1, "week");
        } else if (granularity == "month") {
          tempDate.add(1, "month");
        }
      }

      if (previousCount !== null) {
        let percentChange =
          previousCount?.total > 0
            ? (currentCount?.total - previousCount?.total) /
              previousCount?.total
            : null;

        let declineMode = "";
        let isNegative = false;

        if (percentChange > 0) {
          if (Math.abs(percentChange) < 0.015) {
            declineMode = "unchanged";
          } else if (Math.abs(percentChange) < 0.1) {
            declineMode = "moderateIncrease";
          } else if (Math.abs(percentChange) >= 0.1) {
            declineMode = "increase";
          }
        } else {
          isNegative = true;

          if (Math.abs(percentChange) < 0.015) {
            declineMode = "moderateDecrease";
          } else if (Math.abs(percentChange) < 0.1) {
            declineMode = "moderateDecrease";
          } else if (Math.abs(percentChange) >= 0.1) {
            declineMode = "decrease";
          }
        }

        netHistory = _.sortBy(netHistory, "dateObj");

        let performanceTrend = [];

        for (let i = 0; i < currentCount?.history?.length; i++) {
          const item = currentCount?.history[i];

          performanceTrend.push({
            dateObj: item?.dateObj,
            date: item?.date,
            "Net Members": item?.count,
            metric: "Net New Members",
          });
        }

        for (let i = 0; i < previousCount?.history?.length; i++) {
          const item = previousCount?.history[i];

          const dateObj = moment(item?.dateObj?.toISOString())
            .add(secondDifference, "seconds")
            .hours(0)
            .minute(0)
            .second(0)
            .millisecond(0);

          const realDate = moment(item?.dateObj?.toISOString())
            .hours(0)
            .minute(0)
            .second(0)
            .millisecond(0);

          let granularityString = dateObj.format("MM/DD/YYYY");
          let realDateString = realDate.format("MM/DD/YYYY");

          if (granularity == "week") {
            dateObj.day(0);
            realDate.day(0);
            granularityString = "Week " + dateObj.format("MM/DD/YYYY");

            realDateString = "Week " + realDate.format("MM/DD/YYYY");
          }

          if (granularity == "month") {
            dateObj.startOf("month");
            realDate.startOf("month");

            granularityString = dateObj.format("MMMM YY");
            realDateString = realDate.format("MMMM YY");
          }

          const idx = _.findIndex(performanceTrend, {
            date: granularityString,
          });

          performanceTrend.push({
            "Net Members": item?.count,
            metric: "Previous",
            dateObj: dateObj?.toDate(),
            date: granularityString,
          });
        }

        performanceTrend = _.sortBy(performanceTrend, "dateObj");

        this.setState({
          previousCount,
          changeIsNegative: isNegative,
          deltaType: declineMode,
          percentChange,
          percentChangeString: Math.abs(percentChange * 100).toFixed(1) + "%",
          performanceTrend,
          netHistory,
        });
      }
    }

    this.setState({
      currentCount,
      loading: false,
    });

    if (typeof this.props.onLoaded == "function") {
      this.props.onLoaded();
    }
  }

  /**
   * Load all report information
   *
   * @param {*} stores
   * @param {*} dateRange
   * @param {*} compare
   */
  async loadReport(stores, dateRange, compare) {
    this.setState({
      loading: true,
    });

    this._fetchReport(stores, dateRange);
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.stores != prevProps?.stores && this.props.stores?.length) ||
      this.props.dateRange != prevProps?.dateRange ||
      this.props.comparePrevious != prevProps.comparePrevious
    ) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }

    if (this.props.reload != prevProps.reload && this.props.reload) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  componentDidMount() {
    if (this.props.stores && this.props.dateRange) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }

    if (this.props.reload) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  render() {
    return (
      <>
        <Card marginTop="mt-4">
          <ChartMetricHeader
            forceWrapComparison={true}
            title="Net Members"
            actions={
              <>
                <Button
                  size="sm"
                  outline
                  color="dark"
                  className="border-0 btn-icon-only"
                  disabled={this.state.loading}
                  onClick={() => {
                    this.loadReport(
                      this.props.stores,
                      this.props.dateRange,
                      this.props.comparePrevious
                    );
                  }}
                >
                  {this.state.loading ? (
                    <Spinner size="sm"></Spinner>
                  ) : (
                    <i className="mdi mdi-refresh"></i>
                  )}
                </Button>
              </>
            }
            loading={this.state.loading}
            dataAvailable={this.state.dataAvailable}
            metric={`${
              this.state.currentCount?.total > 0 ? "+" : ""
            }${StringUtils.numberFormat(
              this.state.currentCount?.total
            )} Members`}
            comparisonMetric={
              this.state.previousCount !== null
                ? `${
                    this.state.previousCount?.total > 0 ? "+" : ""
                  }${StringUtils.numberFormat(
                    this.state.previousCount?.total
                  )} Members`
                : null
            }
            dateRange={this.props.dateRange}
            deltaType={this.state.deltaType}
            percentChange={this.state.percentChangeString}
            showPercentChange={true}
          ></ChartMetricHeader>

          {this.state.loading ? (
            <>
              <div
                className="skeleton rounded"
                style={{ width: "100%", height: 320, marginTop: "1.5rem" }}
              >
                &nbsp;
              </div>
            </>
          ) : (
            <Chart
              height={320}
              appendPadding={[20, 0, 10, 30]}
              data={this.state.performanceTrend}
              autoFit
            >
              <Coordinate transposed></Coordinate>
              <Tooltip
                itemTpl={`<li style="padding-bottom: 8px; color: var(--dark);" data-index={index}><span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>{name}:<span style="padding-left: 5px;">\{value} Members</span></li>`}
                shared
              ></Tooltip>
              <Axis name="date" label={{ offset: 12 }}></Axis>
              <Axis
                name="count"
                grid={{
                  line: {
                    style: {
                      stroke: "#CCC",
                      lineDash: [3, 3],
                    },
                  },
                }}
              ></Axis>
              <Legend
                marker={{ symbol: "circle" }}
                label={{}}
                visible={true}
                position="top-right"
                layout={"horizontal"}
                offsetY={0}
              ></Legend>
              <Interval
                adjust={[{ type: "dodge", marginRatio: 1 / 32 }]}
                color={[
                  "metric",
                  (metric) => {
                    //console.log(metric); //debug

                    return metric == "Previous" ? "#ced4da" : "#fb6340";
                  },
                ]}
                position="date*Net Members"
              ></Interval>
              <Interaction type="active-region"></Interaction>
            </Chart>
          )}
        </Card>
      </>
    );
  }
}

export default NewMemberDetailChartCard;
