import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import { Badge, Button, Card, CardBody, Col, Row } from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  Tooltip,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import swal from "sweetalert";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import TaskModifyDrawer from "./TaskModifyDrawer";
import CustomerInformationWidget from "components/Widgets/CustomerInformationWidget";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import NoteBody from "components/NoteBody";
import Spinner from "reactstrap/lib/Spinner";
import LeadContactInformationWidget from "components/Widgets/LeadContactInformationWidget";
import AffiliateAboutWidget from "components/Widgets/AffiliateAboutWidget";
import TechSupportTicketDrawer from "./TechSupportTicketDrawer";
import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";
import User from "lib/Model/User";
import LinkUtils from "lib/LinkUtils";
import Linkify from "components/Linkify";
import ReactPlayer from "react-player";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";

class NotificationDrawer extends React.Component {
  state = {
    notificationsLoaded: false,
    tab: {
      id: "All",
      name: "All",
    },
    tabs: [
      {
        id: "All",
        name: "All",
      },
      {
        id: "Announcements",
        name: "Announcements",
      },
      {
        id: "Notifications",
        name: "Notifications",
      },
    ],
  };

  dropdownRef = React.createRef();

  componentDidUpdate(prevProps) {
    if (
      this.props.open != prevProps.open &&
      !this.state.notifications?.length
    ) {
      this.fetchNotifications(this.state.tab, 1, true);
    }

    if (
      this.props.open &&
      !prevProps.open &&
      this.state.notifications?.length
    ) {
      this.markNotificationRead(this.state.notifications);
    }
  }

  componentDidMount() {
    this.fetchNotifications(this.state.tab, 1, true);

    let user = LocalStorage.get(Constant.CACHE.USER);

    PubSub.subscribe(`ws_${user?._id}:notification:created`, (data) => {
      console.log("HERE");

      if (this.props.open) {
        if (this.state.tab?.id == "All") {
          let notifications = this.state.notifications?.length
            ? this.state.notifications
            : [];

          notifications.unshift(data);

          this.setState({
            notifications,
          });

          PubSub.publish(
            "notificationCount",
            notifications?.filter((n) => !n?.read)?.length
          );
        } else if (
          this.state.tab?.id == "Announcements" &&
          data?.announcement?.text
        ) {
          let notifications = this.state.notifications?.length
            ? this.state.notifications
            : [];

          notifications.unshift(data);

          this.setState({
            notifications,
          });

          PubSub.publish(
            "notificationCount",
            notifications?.filter((n) => !n?.read)?.length
          );
        } else if (
          this.state.tab?.id == "Notifications" &&
          !data?.announcement?.text
        ) {
          let notifications = this.state.notifications?.length
            ? this.state.notifications
            : [];

          notifications.unshift(data);

          this.setState({
            notifications,
          });

          PubSub.publish(
            "notificationCount",
            notifications?.filter((n) => !n?.read)?.length
          );
        }
      } else {
        this.setState(
          {
            tab: {
              id: "All",
              name: "All",
            },
          },
          () => {
            this.fetchNotifications(this.state.tab, 1, true);
          }
        );
      }
    });
  }

  toggleModal() {
    this.setState(
      {
        tab: {
          id: "All",
          name: "All",
        },
      },
      () => {
        this.fetchNotifications(this.state.tab, 1, true);
      }
    );

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  async markNotificationRead(notifications) {
    APIV2.setNotificationsAsRead(notifications?.map((n) => n?._id)).then(
      (data) => {
        // console.log(data?.data);
      },
      (e) => {
        console.error(e);
      }
    );
  }

  async fetchNotifications(tab = null, page = 1, force = false) {
    if (force) {
      this.setState({
        loading: true,
      });
    }

    let filters = null;

    if (tab?.id == "Announcements") {
      filters = {
        "announcement.text": { $ne: null },
      };
    } else if (tab?.id == "Notifications") {
      filters = {
        "announcement.text": null,
      };
    }

    this.setState({
      loadingNotifications: true,
    });

    APIV2.getNotifications(filters, page)
      .then(
        async (data) => {
          let existing = this.state.notifications?.length
            ? this.state.notifications
            : [];

          let newNotifications = data?.data?.notifications;

          let unread = newNotifications?.filter((n) => !n?.read);

          PubSub.publish("notificationCount", unread?.length);

          this.setState({
            notifications: force
              ? data?.data?.notifications
              : existing.concat(data?.data?.notifications),
            notificationsLoaded: true,
            pagination: data?.data?.pagination,
          });

          if (this.props.open) {
            await this.markNotificationRead(newNotifications);
          }
        },
        (e) => {
          this.setError(
            "error",
            "Unable to load notififications, please try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingNotifications: false,
          loading: false,
        });
      });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <div style={{ width: "100%" }}>
              <div className="d-flex">
                <h3
                  className="m-0 flex-grow"
                  style={{ position: "relative", top: "7px" }}
                >
                  Notifications
                </h3>
                <Drawer.Actions>
                  <Button
                    className="btn-icon-only"
                    disabled={this.state.loadingNotifications}
                    onClick={() => {
                      this.fetchNotifications(this.state.tab, 1, true);
                    }}
                    size="sm"
                    outline
                    color="dark"
                  >
                    {this.state.loadingNotifications ? (
                      <Spinner size="sm"></Spinner>
                    ) : (
                      <i className="mdi mdi-refresh"></i>
                    )}
                  </Button>
                </Drawer.Actions>
              </div>
              <div style={{ marginBottom: -21, marginLeft: -10 }}>
                <CardSelectorHeader
                  disabled={this.state.loading}
                  sticky={false}
                  value={this.state.tab}
                  options={this.state.tabs}
                  showCount={false}
                  compact={true}
                  onChange={(v) => {
                    this.setState({
                      tab: v,
                    });

                    this.fetchNotifications(v, 1, true);
                  }}
                ></CardSelectorHeader>
              </div>
            </div>
          </Drawer.Header>
          <Drawer.Body className="p-0">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            {this.state.loading ? (
              <>
                <div className="p-4 border-bottom cursor-pointer bg-ultralight--hover">
                  <div
                    className="skeleton rounded"
                    style={{ height: 140 }}
                  ></div>
                </div>
                <div className="p-4 border-bottom cursor-pointer bg-ultralight--hover">
                  <div
                    className="skeleton rounded"
                    style={{ height: 140 }}
                  ></div>
                </div>
                <div className="p-4 border-bottom cursor-pointer bg-ultralight--hover">
                  <div
                    className="skeleton rounded"
                    style={{ height: 140 }}
                  ></div>
                </div>
                <div className="p-4 border-bottom cursor-pointer bg-ultralight--hover">
                  <div
                    className="skeleton rounded"
                    style={{ height: 140 }}
                  ></div>
                </div>
                <div className="p-4 border-bottom cursor-pointer bg-ultralight--hover">
                  <div
                    className="skeleton rounded"
                    style={{ height: 140 }}
                  ></div>
                </div>
                <div className="p-4 border-bottom cursor-pointer bg-ultralight--hover">
                  <div
                    className="skeleton rounded"
                    style={{ height: 140 }}
                  ></div>
                </div>
              </>
            ) : (
              <>
                {this.state.notifications &&
                this.state.notifications.length > 0 ? (
                  <>
                    {this.state.notifications?.map((notification, i) => (
                      <div
                        key={i}
                        className={`p-4 border-bottom ${
                          notification?.action?.eventType &&
                          "cursor-pointer bg-ultralight--hover"
                        }`}
                        onClick={async () => {
                          let notifications = this.state.notifications;

                          notifications[i].read = true;

                          this.setState({
                            notifications,
                          });

                          PubSub.publish(
                            "notificationCount",
                            notifications?.filter((n) => !n?.read)?.length
                          );

                          if (!notification?.action?.eventType) {
                            return;
                          }

                          const action = notification?.action;

                          if (action?.eventType == "website-link") {
                            LinkUtils.openInNewTab(action?.eventData);
                          } else if (action?.eventType == "in-app-page") {
                            this.props.history.push(action?.eventData);
                          } else if (action?.eventType == "in-app-event") {
                            PubSub.publish(
                              action?.eventData,
                              action?.eventMetadata ? action?.eventMetadata : {}
                            );
                          } else if (action?.eventType == "announcement") {
                            // TODO: open announcement drawer
                            let mod = notification?.announcement;

                            let content = mod ? mod : {};

                            //console.log(content);

                            //console.log(content?.json);

                            if (content?.json?.content?.length) {
                              for (
                                let i = 0;
                                i < content?.json?.content?.length;
                                i++
                              ) {
                                let block = content?.json?.content[i];

                                //console.log(block);

                                if (block?.type == "image") {
                                  let url = block?.attrs?.src;

                                  let withoutParams =
                                    StringUtils.removeURLParameters(url);

                                  withoutParams = withoutParams?.split(
                                    `s3.us-east-1.amazonaws.com/files.projectleannation.com/`
                                  )[1];

                                  if (url) {
                                    let presignedURL =
                                      await APIV2.getPrivateFileURL(
                                        withoutParams
                                      );

                                    if (presignedURL?.data?.url) {
                                      block.attrs.src = presignedURL?.data?.url;

                                      //console.log(presignedURL?.data?.url);

                                      if (content?.html?.length) {
                                        //console.log(content?.html);

                                        let knownPartOfSrc = withoutParams; // Part of the src you know
                                        let newSrc = presignedURL?.data?.url; // New src to replace with

                                        // Regex explanation:
                                        // <img\s+ : Matches the start of the img tag followed by one or more whitespace characters
                                        // src=" : Matches the src attribute opening quote
                                        // [^"]* : Matches any character except the closing quote, as many times as possible (greedy)
                                        // example.com/images : The known part of the src attribute you're targeting
                                        // [^"]* : Again, matches any character except the closing quote, as many times as possible
                                        // " : Matches the closing quote of the src attribute
                                        let regex = new RegExp(
                                          `(src="[^"]*${knownPartOfSrc}[^"]*")`,
                                          "g"
                                        );

                                        // Replace the src attribute in the matched img tag
                                        content.html = content.html.replace(
                                          regex,
                                          `src="${newSrc}"`
                                        );
                                      }
                                    }
                                  }

                                  content.json.content[i] = block;
                                }
                              }

                              mod.content = content;

                              notification.announcement = mod;
                            }

                            this.setState({
                              announcementOpen: true,
                              selectedNotification: notification,
                            });
                          }
                        }}
                      >
                        <div className="m-0 text-dark">
                          <Row className="align-items-center">
                            <Col xs="">
                              {" "}
                              {moment().diff(
                                moment(notification?.timestamp),
                                "days"
                              ) > 2
                                ? moment(notification?.timestamp).format(
                                    "MM/DD/YY hh:mm A"
                                  )
                                : moment(notification?.timestamp).fromNow()}
                            </Col>
                            {!notification?.read ? (
                              <Col xs="auto">
                                <div
                                  className="bg-primary d-inline-block"
                                  style={{
                                    borderRadius: "100%",
                                    height: "12px",
                                    width: "12px",
                                  }}
                                ></div>
                              </Col>
                            ) : null}
                          </Row>
                        </div>
                        {notification?.title ? (
                          <h3 className="mb-1 text-dark">
                            {notification?.title}
                          </h3>
                        ) : null}
                        <p
                          className="m-0 text-dark"
                          style={{ lineHeight: 1.3 }}
                        >
                          {notification?.body}
                        </p>
                        {notification?.action?.eventType ? (
                          <Button
                            size="sm"
                            outline
                            color="dark"
                            className="mt-2"
                          >
                            {notification?.action?.buttonText
                              ? notification?.action?.buttonText
                              : "View"}
                            <i className="mdi mdi-arrow-right ml-2"></i>
                          </Button>
                        ) : null}
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <div className="p-4">
                      <Card className="border">
                        <CardBody className="text-center text-dark">
                          There are no notifications.
                        </CardBody>
                      </Card>
                    </div>
                  </>
                )}
                {this.state.pagination?.hasNext ? (
                  <div className="d-flex p-4 justify-content-center">
                    <Button
                      color="dark"
                      onClick={() => {
                        this.fetchNotifications(
                          this.state.tab,
                          this.state.pagination?.next
                        );
                      }}
                      disabled={this.state.loadingNotifications}
                      outline
                      size="sm"
                    >
                      {this.state.loadingNotifications ? (
                        <Spinner size="sm"></Spinner>
                      ) : (
                        "Load More Notifications"
                      )}
                    </Button>
                  </div>
                ) : (
                  <div className="d-flex p-4 justify-content-center">
                    <Button
                      color="dark"
                      onClick={() => {}}
                      disabled={true}
                      outline
                      size="sm"
                    >
                      End Of Notifications
                    </Button>
                  </div>
                )}
              </>
            )}
          </Drawer.Body>
        </Drawer>
        <Drawer
          size="md"
          style={{ maxWidth: "100%" }}
          open={this.state.announcementOpen}
          onClose={() => {
            this.setState({
              announcementOpen: false,
              selectedNotification: null,
            });
          }}
        >
          <Drawer.Header className="pr-4">
            <h3 className="mb-3" style={{ position: "relative", top: "7px" }}>
              {this.state.selectedNotification?.title}
            </h3>
            <Drawer.Actions></Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-0 ">
            {this.state.selectedNotification?.announcementVideoURL ? (
              <ReactPlayer
                className="p-4 bg-dark border border-midlighter"
                width={"100%"}
                url={this.state.selectedNotification?.announcementVideoURL}
              />
            ) : null}

            {this.state.selectedNotification?.announcementEmbed ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.selectedNotification?.announcementEmbed,
                }}
                className="p-4 border border-midlighter bg-dark "
                style={{ width: "100%" }}
              ></div>
            ) : null}
            <div
              style={{ minHeight: 400 }}
              className="p-4 plnu-topic"
              dangerouslySetInnerHTML={{
                __html: this.state.selectedNotification?.announcement?.html
                  ? this.state.selectedNotification?.announcement?.html
                  : "(empty)",
              }}
            ></div>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(NotificationDrawer);
