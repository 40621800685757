import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import NumberFormat from "react-number-format";
import APIV2 from "lib/APIV2";
import { Drawer } from "rsuite";

class ModifyStoreModal extends React.Component {
  state = {
    open: false,
    id: null,
    name: "",
    submitting: false,
    type: 0,
    address_1: "",
    address_2: "",
    city: "",
    state: "AL",
    zip_code: "",
    billingAccounts: [],
    loadingBAs: true,
    billingAccountID: null,
    email: "",
    phone: "",
    fbURL: "",
    igURL: "",
    code: "",
    firstBillDate: "",
    firstBillCoupon: "",
    productCode: "",
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });

    if (this.state[state] == true) {
      this.setState({
        name: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "AL",
        zip_code: "",
        type: 0,
        email: "",
        phone: "",
        fbURL: "",
        igURL: "",
        code: "",
        zohoAccount: "",
        firstBillCoupon: "",
        firstBillDate: "",
        prelaunchStartDate: "",
        productCode: "",
        websiteLaunchDate: "",
        retailOpeningDate: "",
      });
    }
  };

  handleNameChange(event) {
    const val = event.target.value;

    this.setState({
      name: val,
    });
  }

  handleCodeChange(event) {
    const val = event.target.value;

    this.setState({
      code: val,
    });
  }

  handleCouponChange(event) {
    const val = event.target.value;

    this.setState({
      firstBillCoupon: val,
    });
  }

  handleZohoChange(event) {
    const val = event.target.value;

    this.setState({
      zohoAccount: val,
    });
  }

  handleAddress1Change(event) {
    const val = event.target.value;

    this.setState({
      address_1: val,
    });
  }

  handleAddress2Change(event) {
    const val = event.target.value;

    this.setState({
      address_2: val,
    });
  }

  handleCityChange(event) {
    const val = event.target.value;

    this.setState({
      city: val,
    });
  }

  handleProductCodeChange(event) {
    const val = event.target.value;

    this.setState({
      productCode: val,
    });
  }

  handleStateChange(event) {
    const val = event.target.value;

    this.setState({
      state: val,
    });
  }

  handleBillingAccountChange(event) {
    const val = event.target.value;

    this.setState({
      billingAccountID: val,
    });
  }

  handleZipChange(event) {
    const val = event.target.value;

    this.setState({
      zip_code: val,
    });
  }

  handleEmailChange(event) {
    const val = event.target.value;

    this.setState({
      email: val,
    });
  }

  handleFirstBillChange(event) {
    const val = event.target.value;

    this.setState({
      firstBillDate: val,
    });
  }

  handlePrelaunchStartChange(event) {
    const val = event.target.value;

    this.setState({
      prelaunchStartDate: val,
    });
  }

  handleRetailOpeningChange(event) {
    const val = event.target.value;

    this.setState({
      retailOpeningDate: val,
    });
  }

  handleWebsiteLaunchChange(event) {
    const val = event.target.value;

    this.setState({
      websiteLaunchDate: val,
    });
  }

  handleFacebookChange(event) {
    const val = event.target.value;

    this.setState({
      fbURL: val,
    });
  }

  handleInstagramChange(event) {
    const val = event.target.value;

    this.setState({
      igURL: val,
    });
  }

  submit(e) {
    e.preventDefault();

    if (this.state.type == 0) {
      if (!this.state.city || !this.state.state) {
        window.alert("Please provide a valid address.");

        return;
      }
    }

    this.setState({
      submitting: true,
    });

    if (
      this.state.email &&
      !this.state.email?.includes("projectleannation.com")
    ) {
      window.alert(
        "Store email must be a projectleannation.com email address."
      );

      return;
    }

    APIV2.modifyStore(
      this.state.id,
      this.state.code,
      this.state.productCode,
      this.state.websiteLaunchDate,
      this.state.prelaunchStartDate,
      this.state.firstBillDate,
      this.state.retailOpeningDate,
      this.state.firstBillCoupon,
      this.state.name,
      parseInt(this.state.type),
      this.state.address_1,
      this.state.address_2,
      this.state.city,
      this.state.state,
      this.state.zip_code,
      this.state.phone && this.state.phone.value
        ? "+1" + this.state.phone.value
        : "",
      this.state.email,
      this.state.igURL,
      this.state.fbURL,
      null
    ).then(
      (data) => {
        if (data && data.data && data.data.store) {
          console.log(data.data.store);

          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          this.setState({
            open: false,
            name: "",
            address_1: "",
            address_2: "",
            city: "",
            state: "AL",
            zip_code: "",
            type: 0,
            submitting: false,
            email: "",
            phone: "",
            fbURL: "",
            igURL: "",
            code: "",
            zohoAccount: "",
            firstBillCoupon: "",
            firstBillDate: "",
            prelaunchStartDate: "",
            productCode: "",
            retailOpeningDate: "",
            websiteLaunchDate: "",
          });

          return;
        }

        window.alert("We were unable to modify the store. Please try again.");
        this.setState({
          submitting: false,
        });
      },
      (e) => {
        console.error(e);
        window.alert("We were unable to modify the store. Please try again.");
        this.setState({
          submitting: false,
        });
      }
    );
  }

  componentDidMount() {
    PubSub.subscribe(Event.STORE.MODIFY_OPEN, (s) => {
      this.setState({
        open: true,
        loadingBAs: true,
        name: s.name,
        id: s._id,
        type: 0,
        billingAccountID:
          s.operations && s.operations.billingAccount
            ? s.operations.billingAccount.id.toString()
            : "0",
        address_1: s.type == "retail" ? s.location.address.address_1 : "",
        address_2: s.type == "retail" ? s.location.address.address_2 : "",
        city: s.type == "retail" ? s.location.address.city : "",
        state: s.type == "retail" ? s.location.address.state : "",
        zip_code: s.type == "retail" ? s.location.address.zip : "",
        phone: s.contact.phone
          ? { value: s.contact.phone.replace("+1", "") }
          : { value: "" },
        email: s.contact.email,
        igURL: s.contact.instagram_url,
        fbURL: s.contact.facebook_url,
        code: s.code,
        productCode: s.productCode,
        firstBillCoupon: s.operations.storeOpening.preorderIncentive
          ? s.operations.storeOpening.preorderIncentive
          : "",
        firstBillDate: s.operations.storeOpening.firstBillDate
          ? s.operations.storeOpening.firstBillDate
          : "",
        prelaunchStartDate: s.operations.storeOpening.prelaunchStartDate
          ? s.operations.storeOpening.prelaunchStartDate
          : "",
        websiteLaunchDate: s.operations.storeOpening.websiteLaunchDate
          ? s.operations.storeOpening.websiteLaunchDate
          : "",
        retailOpeningDate: s.operations.storeOpening.openingDate
          ? s.operations.storeOpening.openingDate
          : "",
      });
    });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.state.open}
          onClose={() => {
            this.toggleModal("open");
          }}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Modify Store
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={this.state.submitting}
                color="primary"
                onClick={this.submit.bind(this)}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  <>Save</>
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            <div>
              <FormGroup>
                <h4 className="mb-2">
                  Name <span className="text-danger">*</span>
                </h4>
                <Input
                  id="createBAName"
                  placeholder="Name"
                  type="text"
                  className="mb-3"
                  required={true}
                  value={this.state.name}
                  onChange={this.handleNameChange.bind(this)}
                />
              </FormGroup>
              <FormGroup>
                <h4 className="mb-2">
                  Store Code <span className="text-danger">*</span>
                </h4>
                <Input
                  id="createBASC"
                  placeholder="Store Code"
                  type="text"
                  className="mb-3"
                  required={true}
                  value={this.state.code}
                  onChange={this.handleCodeChange.bind(this)}
                />
              </FormGroup>
              <FormGroup>
                <h4 className="mb-2">
                  Store Product Code <span className="text-danger">*</span>
                </h4>
                <Input
                  id="createBASC"
                  placeholder="Store Product Code"
                  type="text"
                  className="mb-3"
                  required={true}
                  value={this.state.productCode}
                  onChange={this.handleProductCodeChange.bind(this)}
                />
              </FormGroup>

              {this.state.type == 0 ? (
                <div>
                  <hr></hr>
                  <FormGroup>
                    <h4>
                      Street Address <span className="text-danger">*</span>
                    </h4>
                    <Input
                      id="createStoreAddress1"
                      placeholder="Address 1"
                      type="text"
                      className="mb-3"
                      required={false}
                      value={this.state.address_1}
                      onChange={this.handleAddress1Change.bind(this)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <h4>Unit / Suite #</h4>
                    <Input
                      id="createStoreAddress2"
                      placeholder="Address 2"
                      type="text"
                      className="mb-3"
                      required={false}
                      value={this.state.address_2}
                      onChange={this.handleAddress2Change.bind(this)}
                    />
                  </FormGroup>

                  <FormGroup>
                    <h4>
                      City <span className="text-danger">*</span>
                    </h4>
                    <Input
                      id="createStoreCity"
                      placeholder="City"
                      type="text"
                      className="mb-3"
                      required={this.state.type == 0}
                      value={this.state.city}
                      onChange={this.handleCityChange.bind(this)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <h4>
                      State <span className="text-danger">*</span>
                    </h4>
                    <select
                      value={this.state.state}
                      onChange={this.handleStateChange.bind(this)}
                      id="createStoreState"
                      className="form-control mb-3"
                    >
                      <option value="AL">AL - Alabama</option>
                      <option value="AK">AK - Alaska</option>
                      <option value="AZ">AZ - Arizona</option>
                      <option value="AR">AR - Arkansas</option>
                      <option value="CA">CA - California</option>
                      <option value="CO">CO - Colorado</option>
                      <option value="CT">CT - Connecticut</option>
                      <option value="DE">DE - Delaware</option>
                      <option value="DC">DC - District Of Columbia</option>
                      <option value="FL">FL - Florida</option>
                      <option value="GA">GA - Georgia</option>
                      <option value="HI">HI - Hawaii</option>
                      <option value="ID">ID - Idaho</option>
                      <option value="IL">IL - Illinois</option>
                      <option value="IN">IN - Indiana</option>
                      <option value="IA">IA - Iowa</option>
                      <option value="KS">KS - Kansas</option>
                      <option value="KY">KY - Kentucky</option>
                      <option value="LA">LA - Louisiana</option>
                      <option value="ME">ME - Maine</option>
                      <option value="MD">MD - Maryland</option>
                      <option value="MA">MA - Massachusetts</option>
                      <option value="MI">MI - Michigan</option>
                      <option value="MN">MN - Minnesota</option>
                      <option value="MS">MS - Mississippi</option>
                      <option value="MO">MO - Missouri</option>
                      <option value="MT">MT - Montana</option>
                      <option value="NE">NE - Nebraska</option>
                      <option value="NV">NV - Nevada</option>
                      <option value="NH">NH - New Hampshire</option>
                      <option value="NJ">NJ - New Jersey</option>
                      <option value="NM">NM - New Mexico</option>
                      <option value="NY">NY - New York</option>
                      <option value="NC">NC - North Carolina</option>
                      <option value="ND">ND - North Dakota</option>
                      <option value="OH">OH - Ohio</option>
                      <option value="OK">OK - Oklahoma</option>
                      <option value="OR">OR - Oregon</option>
                      <option value="PA">PA - Pennsylvania</option>
                      <option value="RI">RI - Rhode Island</option>
                      <option value="SC">CS - South Carolina</option>
                      <option value="SD">SD - South Dakota</option>
                      <option value="TN">TN - Tennessee</option>
                      <option value="TX">TX - Texas</option>
                      <option value="UT">UT - Utah</option>
                      <option value="VT">VT - Vermont</option>
                      <option value="VA">VA - Virginia</option>
                      <option value="WA">WA - Washington</option>
                      <option value="WV">WV - West Virginia</option>
                      <option value="WI">WI - Wisconsin</option>
                      <option value="WY">WY - Wyoming</option>
                    </select>
                  </FormGroup>
                  <FormGroup>
                    <h4>
                      Zip Code <span className="text-danger">*</span>
                    </h4>
                    <Input
                      id="createStoreZipCode"
                      placeholder="Zip Code"
                      type="text"
                      className="mb-3"
                      value={this.state.zip_code}
                      onChange={this.handleZipChange.bind(this)}
                    />
                  </FormGroup>
                </div>
              ) : null}

              <hr></hr>
              <FormGroup>
                <h4>
                  Email <span className="text-danger">*</span>
                </h4>
                <Input
                  id="createStoreEmail"
                  placeholder="Email Address (Optional)"
                  type="text"
                  className="mb-3"
                  required={false}
                  value={this.state.email}
                  onChange={this.handleEmailChange.bind(this)}
                />
              </FormGroup>
              <FormGroup>
                <h4>Instagram URL</h4>
                <Input
                  id="createStoreIG"
                  placeholder="Instagram Page URL (Optional)"
                  type="text"
                  className="mb-3"
                  required={false}
                  value={this.state.igURL}
                  onChange={this.handleInstagramChange.bind(this)}
                />
              </FormGroup>
              <FormGroup>
                <h4>Facebook URL</h4>
                <Input
                  id="createStoreFB"
                  placeholder="Facebook Page URL (Optional)"
                  type="text"
                  className="mb-3"
                  required={false}
                  value={this.state.fbURL}
                  onChange={this.handleFacebookChange.bind(this)}
                />
              </FormGroup>
            </div>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default ModifyStoreModal;
