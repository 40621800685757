import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import APIV2 from "lib/APIV2";

class ModifyStoreBillingAccountModal extends React.Component {
  state = {
    open: false,
    id: null,
    name: "",
    submitting: false,
    type: 0,
    address_1: "",
    address_2: "",
    city: "",
    state: "AL",
    zip_code: "",
    billingAccounts: [],
    loadingBAs: true,
    billingAccountID: null,
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });

    if (this.state[state] == true) {
      this.setState({
        name: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "AL",
        zip_code: "",
        type: 0,
      });
    }
  };

  handleBillingAccountChange(event) {
    const val = event.target.value;

    this.setState({
      billingAccountID: val,
    });
  }

  submit(e) {
    e.preventDefault();

    if (!this.state.billingAccountID) {
      window.alert("Please select a billing account to continue.");

      return;
    }

    this.setState({
      submitting: true,
    });

    APIV2.setStoreBillingAccount(
      this.props.store._id,
      this.state.billingAccountID
    )
      .then(
        (data) => {
          if (data && data.data && data.data.store) {
            console.log(data.data.store);

            PubSub.publish(Event.STORE.MODIFIED, data.data.store);

            this.setState({
              open: false,
            });

            this.setState({
              name: "",
              address_1: "",
              address_2: "",
              city: "",
              state: "AL",
              zip_code: "",
              type: 0,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "We were unable to set the billing account for the store. Please try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  componentDidMount() {
    PubSub.subscribe(Event.STORE.MODIFY_BILLING_ACCOUNT_OPEN, (s) => {
      this.setState({
        open: true,
        loadingBAs: true,
        name: s.name,
        id: s.id,
        type: s.type,
        billingAccountID: s.billing_account
          ? s.billing_account.id.toString()
          : "0",
        address_1: s.type == 0 ? s.address_1 : "",
        address_2: s.type == 0 ? s.address_2 : "",
        city: s.type == 0 ? s.city : "",
        state: s.type == 0 ? s.state : "",
        zip_code: s.type == 0 ? s.zip_code : "",
      });

      APIV2.getBillingAccounts().then(
        (data) => {
          console.log(data);

          if (data.data && data.data.billingAccounts) {
            this.setState({
              billingAccounts: data.data.billingAccounts,
              loadingBAs: false,
            });
          }
        },
        () => {
          window.alert("Failed to get billing accounts. Please try again.");
        }
      );
    });

    APIV2.getBillingAccounts().then(
      (data) => {
        if (data.data && data.data.billingAccounts) {
          this.setState({
            billingAccounts: data.data.billingAccounts,
            loadingBAs: false,
          });
        }
      },
      () => {
        window.alert("Failed to get billing accounts. Please try again.");
      }
    );
  }

  render() {
    const { billingAccounts } = this.state;

    let billingAccountList =
      billingAccounts.length > 0 &&
      billingAccounts.map((item, i) => {
        return (
          <option key={i} value={item._id}>
            {item.name}
          </option>
        );
      }, this);

    return (
      <>
        <Modal isOpen={this.state.open} toggle={() => this.toggleModal("open")}>
          <div className="modal-header">
            <h5 className="modal-title" id="createBAModalLabel">
              Modify Billing Account
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("open")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Form onSubmit={this.submit.bind(this)}>
            <div className="modal-body">
              <h5 className="mb-2">Billing Account</h5>
              {this.state.loadingBAs ? (
                <select value="1" disabled={true} className="form-control mb-3">
                  <option value="1">Loading...</option>
                </select>
              ) : null}
              {!this.state.loadingBAs ? (
                <select
                  value={this.state.billingAccountID}
                  onChange={this.handleBillingAccountChange.bind(this)}
                  id="createStoreBillingAccount"
                  className="form-control mb-3"
                >
                  <option value="0" disabled={true}>
                    Select An Account
                  </option>
                  {billingAccountList}
                </select>
              ) : null}
            </div>
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="reset"
                onClick={() => this.toggleModal("open")}
              >
                Close
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={this.state.submitting}
              >
                Save
              </Button>
            </div>
          </Form>
        </Modal>
      </>
    );
  }
}

export default ModifyStoreBillingAccountModal;
