import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
  Button,
  Modal,
  Row,
  Col,
  Badge,
} from "reactstrap";
import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";
import API from "lib/API";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import User from "lib/Model/User";
import { isNumber } from "underscore";
import _ from "underscore";
import { Editor } from "react-draft-wysiwyg";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import APIV2 from "lib/APIV2";
import Spinner from "reactstrap/lib/Spinner";

class MenuRow extends React.Component {
  state = {
    showSecret: false,
    name: "",
    description: "",
    key: "",
    secret: "",
    modify: false,
    meals: [],
  };

  updateShowSecret(show) {
    this.setState({
      showSecret: show,
    });
  }

  delete() {
    let c = window.confirm("Are you sure you want to delete this menu?");

    if (c) {
      APIV2.deleteMealMenu(this.props.menu._id).then(
        (data) => {
          PubSub.publish(Event.MENU.DELETED, { id: this.props.menu._id });
        },
        (e) => {
          console.error(e);
          window.alert(
            "We're having trouble removing that menu. Please try again."
          );
        }
      );
    }
  }

  closeModifyModal() {
    this.setState({
      name: "",
      description: "",
      key: "",
      secret: "",
      modify: false,
      meals: [],
    });
  }

  closeDescriptionModal() {
    this.setState({
      showDescription: false,
    });
  }

  handleNameChange(event) {
    const val = event.target.value;

    this.setState({
      name: val,
    });
  }

  handleCalorieChange(event) {
    const val = event.target.value;

    this.setState({
      calories: val,
    });
  }

  handleCarbChange(event) {
    const val = event.target.value;

    this.setState({
      carbs: val,
    });
  }

  handleProteinChange(event) {
    const val = event.target.value;

    this.setState({
      protein: val,
    });
  }

  handleFatChange(event) {
    const val = event.target.value;

    this.setState({
      fat: val,
    });
  }

  handleFiberChange(event) {
    const val = event.target.value;

    this.setState({
      fiber: val,
    });
  }

  handleSugarChange(event) {
    const val = event.target.value;

    this.setState({
      sugar: val,
    });
  }

  handleZohoChange(event) {
    const val = event.target.value;

    this.setState({
      zohoApi: val,
    });
  }

  handleSodiumChange(event) {
    const val = event.target.value;

    this.setState({
      sodium: val,
    });
  }

  handleFoodWeightChange(event) {
    const val = event.target.value;

    this.setState({
      foodWeight: val,
    });
  }

  handleFinishedWeightChange(event) {
    const val = event.target.value;

    this.setState({
      finishedWeight: val,
    });
  }

  async submit(e) {
    e.preventDefault();

    this.setState({
      submitting: true,
    });

    let meals = [];

    for (let i = 0; i < this.state.meals.length; i++) {
      if (this.state.meals[i].checked) {
        meals.push(this.state.meals[i]._id);
      }
    }

    APIV2.modifyMealMenu(
      this.props.menu._id,
      this.props.menu.planType._id,
      this.state.name,
      meals
    )
      .then(
        (data) => {
          console.log(data);

          if (data && data.data && data.data.menu) {
            PubSub.publish(Event.MENU.MODIFIED, data.data.menu);

            this.setState({
              name: "",
              description: "",
              key: "",
              secret: "",
              publicKey: "",
              meals: [],
              open: false,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert("We were unable to modify this menu. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  openModifyModal() {
    let meals = this.state.meals;

    this.setState({
      name: this.props.menu.name,
      meals: meals,
    });

    APIV2.getMealsByProductGroup(
      this.props.planType ? this.props.planType._id : ""
    ).then(
      (data) => {
        let meals = data.data.meals;

        if (this.props.menu.meals) {
          for (let i = 0; i < this.props.menu.meals.length; i++) {
            let idx = _.findIndex(meals, {
              _id: this.props.menu.meals[i]._id,
            });

            meals[idx].checked = true;
          }
        }

        this.setState({ meals });
      },
      (e) => {
        window.alert("Failed to load meals by product group.");
      }
    );

    this.setState({
      open: true,
    });
  }

  toggleMeal(c) {
    let meals = this.state.meals;

    let idx = _.findIndex(meals, { _id: c._id });

    if (idx >= 0) {
      meals[idx].checked = !meals[idx].checked;
    }

    this.setState({
      meals,
    });
  }

  render() {
    return (
      <>
        <tr className="align-items-middle">
          <td
            className="cursor-pointer align-middle"
            onClick={() => {
              this.setState({
                showDescription: !this.state.showDescription,
              });
            }}
            style={{ whiteSpace: "pre-wrap" }}
          >
            <h3 className="mb-0 d-none d-md-block">
              {this.props.menu ? this.props.menu.name : null}
            </h3>
            <h4 className="mb-0 d-block d-md-none">
              {this.props.menu ? this.props.menu.name : null}
            </h4>
          </td>
          <td
            onClick={() => {
              this.setState({
                showDescription: !this.state.showDescription,
              });
            }}
            className="cursor-pointer align-middle d-none d-md-table-cell"
          >
            <p className="mb-0">
              {this.props.menu.meals ? (
                <>{this.props.menu.meals.length} Meals</>
              ) : (
                "No meals"
              )}
            </p>
          </td>

          <td className="text-right align-middle">
            <Button
              size="sm"
              outline
              color="secondary"
              className="btn-icon-only"
              onClick={() => this.openModifyModal()}
            >
              <i className="mdi mdi-pencil"></i>
            </Button>
            {this.props?.allowDelete && (
              <Button
                size="sm"
                outline
                color="danger"
                className="btn-icon-only"
                onClick={() => this.delete()}
              >
                <i className="mdi mdi-delete"></i>
              </Button>
            )}
          </td>
        </tr>

        <Modal
          isOpen={this.state.open}
          toggle={() => this.setState({ open: false })}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="createBAModalLabel">
              Modify Menu
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({ open: false })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Form onSubmit={this.submit.bind(this)}>
            <div className="modal-body">
              <h5>Menu Name</h5>
              <Input
                id="createBAName"
                placeholder="Name"
                type="text"
                className="mb-3"
                required={true}
                value={this.state.name}
                onChange={this.handleNameChange.bind(this)}
              />
              <h5 className="mb-2">
                Meals
                {this.state.loadingMeals ? (
                  <span className="badge badge-info">Loading...</span>
                ) : null}
              </h5>
              <small>Select all meals you'd like to add to this menu.</small>
              <Row className="mt-3">
                {this.state.meals.map((c, i) => (
                  <Col xs="12" sm="6">
                    <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                      <input
                        className="custom-control-input"
                        id={"catCheck" + i}
                        type="checkbox"
                        onChange={() => {
                          this.toggleMeal(c);
                        }}
                        checked={c.checked}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={"catCheck" + i}
                      >
                        {c.name}
                      </label>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="reset"
                onClick={() => this.setState({ open: false })}
              >
                Close
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={this.state.submitting}
              >
                Save
              </Button>
            </div>
          </Form>
        </Modal>

        <Modal
          isOpen={this.state.showDescription}
          toggle={() => this.closeDescriptionModal()}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="createBAModalLabel">
              {this.props.menu.name}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.closeDescriptionModal()}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <h5>Menu Name</h5>
            <p>{this.props.menu.name}</p>
            <h5>Product Category</h5>
            <p>{this.props.menu.planType.name}</p>
            <h5>Meals</h5>
            <ul>
              {this.props.menu.meals
                ? this.props.menu.meals.map((c, i) => <li>{c.name}</li>)
                : ""}
              {!this.props.menu.meals || !this.props.menu.meals.length
                ? "(none)"
                : ""}
            </ul>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              className="mr-auto"
              type="button"
              onClick={() => this.closeDescriptionModal()}
            >
              Close
            </Button>
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                this.closeDescriptionModal();
                this.openModifyModal();
              }}
            >
              Modify
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default MenuRow;
