import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  BarChart,
  BarList,
  Card,
  DonutChart,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import APIV2 from "../../../lib/APIV2";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import {
  Axis,
  Chart,
  Coordinate,
  Interaction,
  Interval,
  Legend,
  Tooltip,
} from "bizcharts";
import ChartMetricHeader from "./ChartMetricHeader";

class SubscriptionFulfillmentByProteinPieChartCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,

    active: "category",
  };

  /**
   * Fetches the order data by week for the given stores & date range
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchOrderDataByWeek(cubejsApi, stores, dateRange) {
    return new Promise(async (resolve, reject) => {
      let endDate6 = moment(dateRange[1]);

      endDate6.startOf("day").day(6);

      let now = moment();

      if (endDate6.isAfter(now)) {
        endDate6.subtract(1, "week");
      }

      let allMeals = await APIV2.getMeals(true);

      allMeals = allMeals?.data?.meals;

      // Load
      cubejsApi
        .load({
          measures: [
            "SubscriptionFulfillmentStatisticsTotalQtyByMeal.statisticsTotalbomqtybymealQuantity",
          ],
          order: {
            "SubscriptionFulfillmentStatisticsTotalQtyByMeal.statisticsTotalbomqtybymealQuantity":
              "asc",
          },

          timeDimensions: [
            {
              dimension: "SubscriptionFulfillmentReports.fulfillmentdate",
              dateRange: [moment(dateRange[0]).toDate(), endDate6?.toDate()],
            },
          ],
          filters: [
            {
              member: "SubscriptionFulfillmentReports.storeid",
              operator: "equals",
              values: stores,
            },
            {
              member: "SubscriptionFulfillmentReports.orderCount",
              operator: "notEquals",
              values: ["0"],
            },
          ],
          dimensions: [
            "SubscriptionFulfillmentStatisticsTotalQtyByMeal.mealSku",
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          if (!data?.length) {
            return resolve(null);
          }

          let total = 0;

          data = data.map((item) => {
            total +=
              item[
                "SubscriptionFulfillmentStatisticsTotalQtyByMeal.statisticsTotalbomqtybymealQuantity"
              ];

            return {
              mealSKU:
                item[
                  "SubscriptionFulfillmentStatisticsTotalQtyByMeal.mealSku"
                ]?.trim(),
              mealName:
                item[
                  "SubscriptionFulfillmentStatisticsTotalQtyByMeal.mealName"
                ]?.trim(),

              protein: _.findWhere(allMeals, {
                sku: item[
                  "SubscriptionFulfillmentStatisticsTotalQtyByMeal.mealSku"
                ],
              }).primaryProteinCategory,
              proteinSource: _.findWhere(allMeals, {
                sku: item[
                  "SubscriptionFulfillmentStatisticsTotalQtyByMeal.mealSku"
                ],
              }).primaryProtein,

              count:
                item[
                  "SubscriptionFulfillmentStatisticsTotalQtyByMeal.statisticsTotalbomqtybymealQuantity"
                ],
            };
          });

          let outByStore2 = data.map((item) => {
            return {
              mealSKU: item?.mealSKU,
              mealName: item?.mealName,
              Meals: item?.count,
              proteinCategory: item?.protein,
              proteinSource: item?.proteinSource,
            };
          });

          let out = [];
          let out2 = [];

          for (let i = 0; i < outByStore2?.length; i++) {
            const idx = _.findIndex(out, {
              proteinCategory: outByStore2[i].proteinCategory,
            });
            const idx2 = _.findIndex(out2, {
              proteinSource: outByStore2[i].proteinSource,
            });

            if (idx >= 0) {
              out[idx].Meals += outByStore2[i].Meals;
            } else {
              out.push({
                proteinCategory: outByStore2[i].proteinCategory,
                Meals: outByStore2[i].Meals,
              });
            }

            if (idx2 >= 0) {
              out2[idx2].Meals += outByStore2[i].Meals;
            } else {
              out2.push({
                proteinSource: outByStore2[i].proteinSource,
                Meals: outByStore2[i].Meals,
              });
            }
          }

          console.log(out, out2);

          out = _.sortBy(out, "Meals");

          out.reverse();

          out2 = _.sortBy(out2, "Meals");

          out2.reverse();

          return resolve({
            byCategory: out,
            bySource: out2,
            total,
            allMeals,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async loadDetailedReport(stores, dateRange) {
    this.setState({
      loading: true,
      dataAvailable: false,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!stores?.length || !dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchOrderDataByWeek(
        cubejsApi,
        stores,
        dateRange
      );
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load meal quantities.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });

      return;
    }

    let colors = StringUtils.randomChartColorSet(
      currentCount.byCategory?.length
    );
    let colors2 = StringUtils.randomChartColorSet(
      currentCount.bySource?.length
    );

    for (let i = 0; i < colors?.length; i++) {
      currentCount.byCategory[i].color = colors[i];
    }

    for (let i = 0; i < colors2?.length; i++) {
      currentCount.bySource[i].color = colors2[i];
    }

    this.setState({
      currentCount: currentCount,
      loading: false,
      byCategory: currentCount?.byCategory,
      bySource: currentCount?.bySource,
      total: currentCount?.total,
      allMeals: currentCount?.allMeals,
    });

    if (typeof this.props.onLoaded == "function") {
      this.props.onLoaded();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stores != prevProps?.stores ||
      this.props.dateRange != prevProps?.dateRange
    ) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload != prevProps.reload && this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  componentDidMount() {
    if (this.props.store && this.props.dateRange) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  render() {
    return (
      <>
        <Card marginTop="mt-0">
          <Row className="align-items-center">
            <Col xs="">
              <h3 className="m-0 text-dark">Meal Fulfillment By Protein</h3>
            </Col>
            <Col xs="auto">
              <Button
                size="sm"
                outline
                color="dark"
                className="border-0 btn-icon-only"
                disabled={this.state.loading}
                onClick={() => {
                  this.loadDetailedReport(
                    this.props.stores,
                    this.props.dateRange
                  );
                }}
              >
                {this.state.loading ? (
                  <Spinner size="sm"></Spinner>
                ) : (
                  <i className="mdi mdi-refresh"></i>
                )}
              </Button>
            </Col>
          </Row>
          <TabList
            color="orange"
            defaultValue="category"
            handleSelect={(value) => {
              this.setState({
                active: value,
              });
            }}
            marginTop="mt-3"
            disabled={this.state.loading || !this.state.dataAvailable}
          >
            <Tab value="category" text="Protein Category" />
            <Tab value="source" text="Protein Source" />
          </TabList>

          {this.state.loading ? (
            <div
              className="skeleton mt-4"
              style={{ height: "calc(320px + 1rem)", width: "100%" }}
            >
              &nbsp;
            </div>
          ) : (
            <>
              {this.state.dataAvailable ? (
                <>
                  <div className="mt-4 mx--4 px-4 border-top">
                    <Row className="align-items-center">
                      <Col xs="12" sm="12" md="6">
                        <div className="pt-4">
                          <DonutChart
                            data={
                              this.state.active == "category"
                                ? this.state.byCategory
                                : this.state.bySource
                            }
                            category={"Meals"}
                            dataKey={
                              this.state.active == "category"
                                ? "proteinCategory"
                                : "proteinSource"
                            }
                            colors={(this.state.active == "category"
                              ? this.state.byCategory
                              : this.state.bySource
                            ).map((item) => {
                              return item?.color;
                            })}
                            valueFormatter={(number) => {
                              return (
                                StringUtils.numberFormat(number) + " Meals"
                              );
                            }}
                            height="h-80"
                          />
                        </div>
                      </Col>
                      <Col md="6" className="mt-3 mt-md-0">
                        <div
                          className="pt-4 mb--4 pb-4"
                          style={{
                            maxHeight: 368,
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          <BarList
                            data={(this.state.active == "category"
                              ? this.state.byCategory
                              : this.state.bySource
                            )?.map((item) => {
                              return {
                                name:
                                  this.state.active == "category"
                                    ? item.proteinCategory
                                    : item.proteinSource,
                                value: item?.Meals,
                              };
                            })}
                            valueFormatter={(number) => {
                              return (
                                StringUtils.numberFormat(number) +
                                " Meals " +
                                `[${
                                  this.state.total
                                    ? (
                                        (number / this.state.total) *
                                        100
                                      ).toFixed(1)
                                    : 0
                                }%]`
                              );
                            }}
                            color="orange"
                            showAnimation={true}
                            marginTop="mt-0"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "calc(320px + 1rem)", width: "100%" }}
                >
                  <p className="m-0">
                    No data available.
                    {this.state.error ? ` ${this.state.error}` : null}
                  </p>
                </div>
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default SubscriptionFulfillmentByProteinPieChartCard;
