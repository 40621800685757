import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import StoreView from "views/examples/StoreView";
import ModifyStoreBillingAccountModal from "./ModifyStoreBillingAccountModal";
import DeleteStoreModal from "./DeleteStoreModal";
import { withRouter } from "react-router";
import APIV2 from "lib/APIV2";
import Badge from "reactstrap/lib/Badge";
import CardFooter from "reactstrap/lib/CardFooter";

class StoreViewManagementTab extends React.Component {
  state = {
    open: false,
  };

  loadStore() {
    const {
      match: { params },
    } = this.props;

    APIV2.getStoreByID(params.storeID)
      .then(
        (data) => {
          if (data && data.data && data.data.store) {
            this.setState({
              store: data.data.store,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  toggleBilling() {
    this.setState({
      billingSubmitting: true,
    });

    APIV2.toggleStoreBilling(
      this.props.match?.params?.storeID,
      !this.state.store?.billingEnabled
    )
      .then(
        (data) => {
          if (data && data.data && data.data.store) {
            this.setState({
              store: data.data.store,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          billingSubmitting: false,
        });
      });
  }

  componentDidMount() {
    this.loadStore();

    PubSub.subscribe(Event.STORE.MODIFIED, (s) => {
      this.setState({
        store: s,
      });
    });
  }

  render() {
    return (
      <>
        <>
          <div className="row">
            <div className="col">
              <Card className=" shadow">
                <CardHeader className="bg-transparent">
                  <Row>
                    <div className="col">
                      <h3 className="mb-0">
                        {this.state.store?.nsoStage == "Lead" ||
                        this.state.store?.nsoStage == "Discovery" ||
                        this.state.store?.nsoStage == "Funding"
                          ? "Delete Store"
                          : "Close Store"}
                      </h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <p className="mb-0">
                    {this.state.store?.nsoStage == "Lead" ||
                    this.state.store?.nsoStage == "Discovery" ||
                    this.state.store?.nsoStage == "Funding"
                      ? "Delete this store and remove it from the NSO process."
                      : " Mark a store as closed. This will make it unavailable to new customers"}
                  </p>
                </CardBody>
                <CardFooter>
                  <Button
                    onClick={() => {
                      PubSub.publish(Event.STORE.DELETE_OPEN, this.state.store);
                    }}
                    color="danger"
                  >
                    <i className="mdi mdi-close"></i>{" "}
                    {this.state.store?.nsoStage == "Lead" ||
                    this.state.store?.nsoStage == "Discovery" ||
                    this.state.store?.nsoStage == "Funding"
                      ? "Delete Store"
                      : "Close Store"}
                  </Button>
                </CardFooter>
              </Card>
            </div>
          </div>
        </>
        <DeleteStoreModal store={this.state.store}></DeleteStoreModal>
      </>
    );
  }
}

export default withRouter(StoreViewManagementTab);
