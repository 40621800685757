import ArrowText from "components/ArrowText";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  Badge,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import Card from "reactstrap/lib/Card";
import moment from "moment";
import CustomerOrderDrawer from "components/Drawers/CustomerOrderDrawer";
import CustomerCreateOrderDrawer from "components/Drawers/CustomerCreateOrderDrawer";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import CustomerAllOrdersDrawer from "components/Drawers/CustomerAllOrdersDrawer";
import _ from "underscore";
import { SelectPicker } from "rsuite";
import NoteBody from "components/NoteBody";
import swal from "sweetalert";
import LinkUtils from "lib/LinkUtils";
import Linkify from "components/Linkify";

class CustomerNotesWidget extends React.Component {
  state = {
    expand: false,
    page: 1,
    recentFirst: "recentFirst",
    notes: [],
  };

  fetchNotes(forceLoad = false) {
    if (forceLoad) {
      this.setState({
        loading: true,
      });
    }

    let query = {
      customerID: this?.props?.match?.params?.customerID,
    };

    let sortBy = {
      createdAt: this.state.recentFirst == "recentFirst" ? -1 : 1,
    };

    APIV2.getNotesForStore(
      this.props?.match?.params?.storeID,
      this.state.page,
      20,
      query,
      sortBy,
      true
    )
      .then(
        (data) => {
          let notes = forceLoad ? [] : this.state.notes;

          notes = data?.data?.notes?.length
            ? notes.concat(data?.data?.notes)
            : notes;

          let notesOut = [];

          for (let i = 0; i < notes?.length; i++) {
            if (!_.findWhere(notesOut, { _id: notes[i]?._id })) {
              notesOut.push(notes[i]);
            }
          }

          this.setState({
            notes: notesOut,
            pagination: data?.data?.pagination,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  handleRecentFirstChange(v) {
    this.setState(
      {
        recentFirst: v,
        page: 1,
      },
      () => {
        this.fetchNotes(true);
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match?.params?.customerID !=
        prevProps?.match?.params?.customerID ||
      this.props.match?.params?.storeID != prevProps?.match?.params?.storeID
    ) {
      this.fetchNotes(true);
    }
  }

  componentDidMount() {
    this.fetchNotes(true);

    PubSub.subscribe(Event.NOTE.CREATED, (note) => {
      let notes = this.state.notes?.length ? this.state.notes : [];

      notes = [note].concat(notes);

      this.setState({
        notes,
      });
    });

    PubSub.subscribe(Event.NOTE.MODIFIED, (note) => {
      if (this.state?.notes?.length) {
        let idx = _.findIndex(this.state.notes, { _id: note?.id });

        if (idx >= 0) {
          let od = this.state.notes;
          od[idx] = note;

          this.setState({
            notes: od,
          });
        }
      }
    });
  }

  openOrderDrawer(note) {
    this.setState({
      openOrder: note,
      noteDrawer: true,
    });
  }

  render() {
    return (
      <>
        {this.props.loading ? (
          <>
            <Card className="mb-4 shadow bnote">
              <CardBody className="bg-transparent py-5 text-center">
                <Spinner size="lg" color="medium"></Spinner>
              </CardBody>
            </Card>
          </>
        ) : (
          <>
            <Card className="mb-4 bnote shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <Col>
                    <h3 className="text-dark mb-0">Notes</h3>
                  </Col>
                  <Col xs="auto">
                    <SelectPicker
                      searchable={false}
                      data={[
                        { label: "Newest First", value: "recentFirst" },
                        { label: "Oldest First", value: "oldestFirst" },
                      ]}
                      value={this.state.recentFirst}
                      onChange={(v) => {
                        this.handleRecentFirstChange(v);
                      }}
                      cleanable={false}
                      placement="auto"
                    ></SelectPicker>
                  </Col>
                </Row>
              </CardHeader>
              <CardHeader className="bg-transparent">
                <NoteBody
                  customer={this.props.customer}
                  onSave={(note) => {
                    console.log(note);

                    this.setState(
                      {
                        page: 1,
                      },
                      () => {
                        this.fetchNotes(true);
                      }
                    );
                  }}
                ></NoteBody>
              </CardHeader>
              <CardBody className="p-0">
                {this.state?.loading && (
                  <div className="px-3 py-5 text-center">
                    <Spinner size="sm" color="dark"></Spinner>
                  </div>
                )}
                {!this.state?.notes?.length && !this.state.loading && (
                  <div className="px-3 py-5">
                    <Row>
                      <Col xs="12" className="text-center">
                        <p className="m-0">No notes found.</p>
                      </Col>
                    </Row>
                  </div>
                )}

                {this.state?.notes?.map((note, i) => (
                  <div key={note?._id}>
                    {this.state.editNote == note?._id ? (
                      <>
                        <div className="p-4">
                          <NoteBody
                            note={note}
                            customer={this.props.customer}
                            forceEdit={true}
                            onSave={(n) => {
                              let notes = this.state.notes;

                              let idx = _.findIndex(notes, { _id: n._id });

                              if (idx >= 0) {
                                notes[idx] = n;
                              }

                              this.setState({
                                editNote: "",
                                notes,
                              });
                            }}
                            onCancel={() => {
                              this.setState({ editNote: "" });
                            }}
                          ></NoteBody>
                        </div>
                      </>
                    ) : (
                      <div className="px-4 cursor-pointer py-3 bg-superlight--hover">
                        <Row>
                          <Col xs="">
                            {note?.title ? (
                              <>
                                <h3 className="text-dark mb-1">
                                  {note?.title}
                                </h3>
                                <Linkify
                                  body={note?.body}
                                  wrap={true}
                                ></Linkify>
                              </>
                            ) : (
                              <>
                                <Linkify
                                  body={note?.body}
                                  wrap={true}
                                ></Linkify>
                              </>
                            )}
                          </Col>
                          <Col xs="auto">
                            <Button
                              size="sm"
                              color="secondary"
                              outline
                              className="p-1 mr-0"
                              style={{ width: 22, height: 22 }}
                              onClick={() => {
                                this.setState({
                                  editNote: note?._id,
                                });
                              }}
                            >
                              <i
                                className="mdi mdi-pencil"
                                style={{
                                  fontSize: 16,
                                  lineHeight: 1,
                                  position: "relative",
                                  left: -2,
                                  top: -2,
                                }}
                              ></i>
                            </Button>
                            <Button
                              size="sm"
                              color="danger"
                              outline
                              className="p-1"
                              style={{ width: 22, height: 22 }}
                              disabled={this.state.deletingNote == note?._id}
                              onClick={() => {
                                swal({
                                  title: "Delete Note?",
                                  text: "Are you sure you want to delete this note?",
                                  icon: "warning",
                                  buttons: ["Nevermind", "Delete"],
                                  dangerMode: true,
                                }).then((conf) => {
                                  if (!conf) {
                                    return;
                                  }

                                  APIV2.deleteStoreNote(
                                    note?.storeID,
                                    note?._id
                                  ).then(() => {
                                    let notes = this.state.notes;

                                    let idx = _.findIndex(notes, {
                                      _id: note._id,
                                    });

                                    notes.splice(idx, 1);

                                    this.setState({
                                      editNote: "",
                                      notes,
                                    });

                                    this.setState({
                                      deletingNote: "",
                                    });
                                  });
                                });
                              }}
                            >
                              <i
                                className="mdi mdi-close"
                                style={{
                                  fontSize: 18,
                                  lineHeight: 1,
                                  position: "relative",
                                  left: -3,
                                  top: -3,
                                }}
                              ></i>
                            </Button>
                          </Col>
                          {note?.attachments?.length > 0 && (
                            <>
                              <Col className="mt-2" xs="12">
                                <h5>Attachments: </h5>
                                {note?.attachments?.map((at, i) => (
                                  <div
                                    className={`p-2 border rounded ${
                                      i !=
                                        this.state?.attachments?.length - 1 &&
                                      "mb-2"
                                    }`}
                                    key={i}
                                  >
                                    <Row className="align-items-center">
                                      <Col xs="">
                                        <p className="small m-0 text-dark">
                                          {at?.fileName}
                                        </p>
                                      </Col>
                                      <Col xs="auto">
                                        <Button
                                          className="btn-icon-only"
                                          size="sm"
                                          outline
                                          color="secondary"
                                          onClick={() => {
                                            LinkUtils.openPrivateURL(at?.url);
                                          }}
                                        >
                                          <i className="mdi mdi-eye"></i>
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                ))}
                              </Col>
                            </>
                          )}
                        </Row>
                        <div className="mt-2">
                          <small>
                            {moment(note?.createdAt).format("MM/DD/YY h:mmA")}{" "}
                            by {note?.createdBy?.name}
                          </small>
                        </div>
                      </div>
                    )}
                    {i != this.state?.notes?.length - 1 && (
                      <hr className="m-0"></hr>
                    )}
                  </div>
                ))}
              </CardBody>
              <CardFooter className="text-center">
                <Button
                  size="sm"
                  color="secondary"
                  outline
                  onClick={() => {
                    console.log(this.state.pagination);

                    this.setState(
                      {
                        page: this.state.pagination?.next,
                      },
                      () => {
                        this.fetchNotes();
                      }
                    );
                  }}
                  disabled={!this.state.pagination?.hasNext}
                >
                  {this.state.pagination?.hasNext
                    ? "View More Notes"
                    : "Viewing All Notes"}
                </Button>
              </CardFooter>
            </Card>
          </>
        )}
      </>
    );
  }
}

export default withRouter(CustomerNotesWidget);
