import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
  Badge,
} from "reactstrap";
import Alert from "reactstrap/lib/Alert";
import { SelectPicker, CheckPicker } from "rsuite";
import CustomerOpenActivitiesWidget from "./CustomerOpenActivitiesWidget";
import CustomerUnclaimedBoxWidget from "./CustomerUnclaimedBoxWidget";
import Constant from "../../lib/Constant";
import _ from "underscore";

class CustomerGoalNotesWidget extends React.Component {
  state = {
    expand: false,
    phone: {
      value: "",
    },
    name: "",
    email: "",
  };

  updateGoal() {
    this.setState({ goalSubmitting: true });

    console.log(this.state.selectedGoal);

    APIV2.setCustomerVariable(
      this.props.customer?.storeID,
      this.props?.customer?._id,
      "goals",
      this.state.selectedGoal ? this.state.selectedGoal : []
    )
      .then(
        (data) => {
          PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);

          this.setState({ modifyGoal: false });
        },
        (e) => {
          this.setError(
            "error",
            "Could not update customer, please try again."
          );
        }
      )
      .finally(() => {
        this.setState({ goalSubmitting: false });
      });
  }

  updateUserNotes() {
    this.setState({ notesSubmitting: true });

    let promises = [];

    APIV2.setCustomerVariable(
      this.props.customer?.storeID,
      this.props?.customer?._id,
      "userNotes",
      this.state.userNotes ? this.state.userNotes : ""
    )
      .then(
        (data) => {
          PubSub.publish(Event.CUSTOMER.MODIFIED, data.data?.customer);

          this.setState({ modifyNotes: false });
        },
        () => {
          this.setError(
            "error",
            "Unable to modify customer. Please try again."
          );

          this.setState({ notesSubmitting: false });
        }
      )
      .finally(() => {
        this.setState({ notesSubmitting: false });
      });
  }

  getStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStoresForUser()
      .then(
        (data) => {
          let storeOptions = data.data.stores?.map((s) => {
            return {
              value: s._id,
              label: s.name.replace("Project LeanNation", "PLN"),
            };
          });

          this.setState({
            stores: data.data.stores,
            storeOptions: storeOptions,
          });
        },
        (e) => {
          this.setError("error", "Could not fetch stores.");
        }
      )
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  getStoreStaff() {
    this.setState({
      loadingCoaches: true,
    });

    APIV2.getStaffForStore(this?.props?.customer?.storeID, 1, 10000, {
      position: { $nin: ["Terminated", "Applicant"] },
    })
      .then(
        (data) => {
          let coachOptions = data.data.staff?.map((s) => {
            return {
              value: s._id,
              label: s.name,
            };
          });

          this.setState({
            coaches: data.data.staff,
            coachOptions: coachOptions,
          });
        },
        (e) => {
          this.setError("error", "Could not fetch coaches.");
        }
      )
      .finally(() => {
        this.setState({
          loadingCoaches: false,
        });
      });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  formatStatus(c) {
    if (c?.status == "Not Contacted") {
      return (
        <Badge color="" className="bg-yellow text-dark">
          {c?.status}
        </Badge>
      );
    }

    if (c?.status == "Attempted") {
      return <Badge color="info">{c?.status}</Badge>;
    }

    if (c?.status == "closedwon") {
      return <Badge color="success">Converted</Badge>;
    }

    if (c?.status == "Inactive") {
      return <Badge color="light">{c?.status}</Badge>;
    }

    return "--";
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 4000);
  }

  componentDidMount() {
    this.getStores();
    this.getStoreStaff();
  }

  componentDidUpdate(prevProps) {
    if (this.props.customer != prevProps.customer) {
      this.getStoreStaff();
    }
  }

  render() {
    return (
      <>
        <Card className="mb-4 shadow border">
          <CardHeader>
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Goal &amp; Member Notes</h3>
                <p className="small m-0">
                  Goal &amp; additional notes shared by the member.
                </p>
              </div>
            </Row>
          </CardHeader>
          {this.props.loading ? (
            <CardBody>
              <Spinner size="sm" color="dark" className="mr-2" /> Loading...
            </CardBody>
          ) : (
            <>
              <CardBody className="bg-transparent p-0">
                <div className="px-4 py-3 border-bottom">
                  <Row className="align-items-center">
                    <Col xs="auto" className="text-dark font-weight-500">
                      Goals:
                    </Col>
                    <Col xs="" className="pl-0">
                      {!this.state.modifyGoal ? (
                        <>
                          {_.filter(this?.props?.customer?.goals, (g) => {
                            return g != "None Of These Apply";
                          })?.length
                            ? this?.props?.customer?.goals?.join(", ")
                            : "--"}
                        </>
                      ) : null}
                    </Col>
                    <Col xs="auto">
                      {this.state.modifyGoal ? (
                        <>
                          <Button
                            onClick={() => {
                              this.setState({ modifyGoal: false });
                            }}
                            size="sm"
                            color="secondary"
                            outline
                            disabled={this.state.goalSubmitting}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              this.updateGoal();
                            }}
                            disabled={this.state.goalSubmitting}
                            size="sm"
                            color="primary"
                          >
                            {this.state.goalSubmitting ? (
                              <>
                                <Spinner size="sm" color="white" />
                              </>
                            ) : (
                              <>Save</>
                            )}
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => {
                            this.setState({
                              modifyGoal: true,
                              selectedGoal: this.props.customer?.goals?.length
                                ? this.props.customer?.goals
                                : [],
                            });
                          }}
                          size="sm"
                          color="secondary"
                          outline
                        >
                          Edit
                        </Button>
                      )}
                    </Col>
                    {this.state.modifyGoal ? (
                      <Col xs="12" className="mt-3">
                        <CheckPicker
                          placement="auto"
                          placeholder="Select A Goal"
                          data={Constant.SELECT_PICKER.CLIENT_GOALS}
                          value={this.state.selectedGoal}
                          cleanable={true}
                          block
                          onChange={(value, item) => {
                            this.setState({ selectedGoal: value });
                          }}
                        ></CheckPicker>
                      </Col>
                    ) : null}
                  </Row>
                </div>
                <div className="px-4 py-3 border-bottom">
                  <Row className="align-items-center">
                    <Col xs="">
                      <div className="text-dark font-weight-500">
                        Member Notes:
                      </div>
                    </Col>

                    <Col xs="auto">
                      {this.state.modifyNotes ? (
                        <>
                          <Button
                            onClick={() => {
                              this.setState({ modifyNotes: false });
                            }}
                            size="sm"
                            outline
                            color="secondary"
                            disabled={this.state.notesSubmitting}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              this.updateUserNotes();
                            }}
                            disabled={this.state.notesSubmitting}
                            size="sm"
                            color="primary"
                          >
                            {this.state.notesSubmitting ? (
                              <>
                                <Spinner size="sm" color="white" />
                              </>
                            ) : (
                              <>Save</>
                            )}
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => {
                            this.setState({
                              modifyNotes: true,
                              userNotes: this.props.customer?.userNotes
                                ? this.props.customer?.userNotes
                                : "",
                            });
                          }}
                          size="sm"
                          color="secondary"
                          outline
                        >
                          Edit
                        </Button>
                      )}
                    </Col>
                    {this.state.modifyNotes ? (
                      <Col xs="12" className="mt-3">
                        <Input
                          type="textarea"
                          name="userNotes"
                          placeholder="Member Notes"
                          value={this.state.userNotes}
                          onChange={this.handleInputChange.bind(this)}
                        ></Input>
                      </Col>
                    ) : (
                      <Col xs="12" className="mt-3">
                        <p className="m-0">
                          {this.props.customer?.userNotes
                            ? this.props.customer?.userNotes
                            : "--"}
                        </p>
                      </Col>
                    )}
                  </Row>
                </div>
              </CardBody>
            </>
          )}
        </Card>
      </>
    );
  }
}

export default withRouter(CustomerGoalNotesWidget);
