import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import APIV2 from "lib/APIV2";
import { InputPicker, DateRangePicker } from "rsuite";
import StringUtils from "lib/StringUtils";
import moment from "moment";

const { beforeToday } = DateRangePicker;

class ModifyStoreDefaultCouponModal extends React.Component {
  state = {
    open: false,
    id: null,
    name: "",
    submitting: false,
    type: 0,
    address_1: "",
    address_2: "",
    city: "",
    state: "AL",
    zip_code: "",
    billingAccounts: [],
    loadingBAs: true,
    billingAccountID: null,
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });

    if (this.state[state] == true) {
      this.setState({
        name: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "AL",
        zip_code: "",
        type: 0,
      });
    }
  };

  handleBillingAccountChange(event) {
    const val = event.target.value;

    this.setState({
      billingAccountID: val,
    });
  }

  submit(e) {
    e.preventDefault();

    let startDate = null;
    let endDate = null;

    if (this.state.couponCode) {
      if (this.state.between.length != 2) {
        alert("Please select a start and end date range.");

        return;
      }

      startDate = moment(this.state.between[0]).startOf("day").toDate();
      endDate = moment(this.state.between[1]).endOf("day").toDate();
    }

    this.setState({
      submitting: true,
    });

    APIV2.setStoreDefaultCoupon(
      this.props.store._id,
      this.state.couponCode,
      startDate,
      endDate
    )
      .then(
        (data) => {
          if (data && data.data && data.data.store) {
            console.log(data.data.store);

            PubSub.publish(Event.STORE.MODIFIED, data.data.store);

            this.setState({
              open: false,
              couponCode: "",
              between: [],
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "We were unable to set the default coupon for the store. Please try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  componentDidMount() {
    PubSub.subscribe(Event.STORE.MODIFY_DEFAULT_COUPON_OPEN, (s) => {
      let startDate = null;
      let endDate = null;
      let between = [];

      if (s?.operations?.defaultCoupon?.startDate) {
        startDate = new Date(s?.operations?.defaultCoupon?.startDate);
      }

      if (s?.operations?.defaultCoupon?.endDate) {
        endDate = new Date(s?.operations?.defaultCoupon?.endDate);
      }

      if (startDate && endDate) {
        between = [startDate, endDate];
      }

      this.setState({
        open: true,

        couponCode: s?.operations?.defaultCoupon?.couponCode,
        id: s?.id,
        between: between,
      });

      this.loadCoupons();
    });

    this.loadCoupons();
  }

  getDiscountValue(c) {
    return StringUtils.couponValueString(c);
  }

  getUsage(c) {
    if (c?.couponUsage == "tiered") {
      return "Tiered Usage";
    }

    if (c?.couponUsage == "limited") {
      return "Limited Usage";
    }

    if (c?.couponUsage == "onetime") {
      return "One-Time Usage";
    }

    return "Recurring Usage";
  }

  loadCoupons() {
    APIV2.getCoupons().then(
      (data) => {
        let coupData = data.data?.coupons?.map((c) => {
          let discountValue = StringUtils.couponValueString(c);

          let label = (
            <>
              <p className="m-0 font-weight-bold">{c.name}</p>
              <p className="m-0" style={{ lineHeight: 1.3 }}>
                <small>{discountValue}</small>
              </p>
            </>
          );

          return {
            label: label,
            value: c.code,
            usage: this.getUsage(c),
          };
        });

        this.setState({ coupons: coupData });
      },
      (e) => {}
    );
  }

  render() {
    return (
      <>
        <Modal isOpen={this.state.open} toggle={() => this.toggleModal("open")}>
          <div className="modal-header">
            <h5 className="modal-title" id="createBAModalLabel">
              Modify Default Coupon
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("open")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Form onSubmit={this.submit.bind(this)}>
            <div className="modal-body">
              <FormGroup>
                <h5 className="mb-2">Coupon Code</h5>
                <InputPicker
                  placeholder="Enter Coupon Code"
                  data={this.state.coupons}
                  value={this.state.couponCode}
                  groupBy="usage"
                  block
                  creatable
                  onChange={(value, item) => {
                    this.setState({ couponCode: value });
                  }}
                ></InputPicker>
              </FormGroup>
              <FormGroup>
                <h5 className="mb-2">Available Between</h5>
                <DateRangePicker
                  value={this.state.between}
                  onChange={(val) => {
                    this.setState({ between: val });
                  }}
                  block
                  showOneCalendar={true}
                  disabledDate={beforeToday()}
                  ranges={[]}
                ></DateRangePicker>
              </FormGroup>
            </div>
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="reset"
                onClick={() => this.toggleModal("open")}
              >
                Close
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={this.state.submitting}
              >
                Save
              </Button>
            </div>
          </Form>
        </Modal>
      </>
    );
  }
}

export default ModifyStoreDefaultCouponModal;
