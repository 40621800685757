import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
  Table,
  Spinner,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import StoreView from "views/examples/StoreView";
import NumberFormat from "react-number-format";
import ModifyStoreModal from "./ModifyStoreModal";
import CreatePlanModal from "./CreatePlanModal";
import PlanRow from "./PlanRow";
import APIV2 from "lib/APIV2";
import { withRouter } from "react-router";
import CardSelectorHeader from "./Headers/CardSelectorHeader";
import { filter, sortBy } from "underscore";
import CsvDownload from "react-json-to-csv";
import moment from "moment";
import StoreApprovedRetailListWidget from "./Widgets/StoreApprovedRetailListWidget";

class StoreViewProductsTab extends React.Component {
  state = {
    open: false,
    tabs: [],
    activeTab: {
      id: "All",
      name: "All",
    },
    filteredPlans: [],
    planExport: [],
  };

  loadStore() {
    const {
      match: { params },
    } = this.props;

    APIV2.getStoreByID(params.storeID)
      .then(
        (data) => {
          if (data && data.data && data.data.store) {
            this.setState({
              store: data.data.store,
            });

            let planExport = data.data.store?.products?.mealPlans?.map(
              (plan, i) => {
                return {
                  _id: plan._id,
                  "Plan Code": plan.code,
                  Name: plan.name,
                  Type: plan.planType?.name,
                  Quantity: plan.quantity,
                  Frequency: plan.frequency,
                  Procurement: plan.procurement,
                  "Unit Price": "$" + plan.unitPrice / 100,
                  "Available To New Members": plan.availableToNewClients
                    ? "Yes"
                    : "No",
                };
              }
            );

            planExport = sortBy(planExport, "Plan Code");

            this.setState({ planExport: planExport });

            this.filterPlans(this.state.activeTab);
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  getPlanTypes() {
    this.setState({
      planTypesLoading: true,
    });

    APIV2.getProductGroups()
      .then(
        (data) => {
          if (data.data && data.data.planTypes) {
            let tabs = [
              {
                _id: "All",
                id: "All",
                name: "All",
              },
            ];

            for (let i = 0; i < data.data.planTypes.length; i++) {
              tabs.push({
                name: data.data.planTypes[i].name,
                id: data.data.planTypes[i]._id,
                _id: data.data.planTypes[i]._id,
              });
            }

            this.setState({
              productGroups: data.data.planTypes,
              tabs,
            });
          }
        },
        () => {}
      )
      .finally(() => {
        this.setState({
          planTypesLoading: false,
        });
      });
  }

  componentDidMount() {
    this.loadStore();
    this.getPlanTypes();

    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab && tab != this.state.activeTab?.id) {
      console.log(tab);

      this.filterPlans({
        id: decodeURIComponent(tab),
      });
    }

    PubSub.subscribe(Event.STORE.MODIFIED, (s) => {
      this.setState({
        store: s,
      });

      let planExport = s?.products?.mealPlans?.map((plan, i) => {
        return {
          _id: plan._id,
          "Plan Code": plan.code,
          Name: plan.name,
          Type: plan.planType?.name,
          Quantity: plan.quantity,
          Frequency: plan.frequency,
          Procurement: plan.procurement,
          "Unit Price": "$" + plan.unitPrice / 100,
          "Available To New Members": plan.availableToNewClients ? "Yes" : "No",
        };
      });

      planExport = sortBy(planExport, "Plan Code");

      this.setState({ planExport: planExport });

      this.filterPlans(this.state.activeTab);
    });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  filterPlans(option) {
    this.setState({ activeTab: option });

    console.log(option);

    if (!this.state.store?.products?.mealPlans) {
      return;
    }

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    if (option.id == "All") {
      this.setState({
        filteredPlans: this.state.store?.products?.mealPlans,
      });

      return;
    }

    let filtered = filter(this.state.store?.products?.mealPlans, (p) => {
      return p.planTypeID == option.id;
    });

    console.log(filtered);

    this.setState({
      filteredPlans: filtered,
    });
  }

  setPricingTier() {
    this.setState({
      tierSubmitting: true,
    });

    // TODO: implement NSO update here

    APIV2.modifyStoreParameter(
      this.state?.store?._id,
      "pricingTier",
      this.state.pricingTier
    )
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          this.setState({
            modifyTier: false,
          });
        },
        (e) => {
          window.alert(
            "Unable to modify Pricing Tier: " + e?.message ?? "error occurred."
          );
        }
      )
      .finally(() => {
        this.setState({
          tierSubmitting: false,
        });
      });
  }

  setOrderAhead() {
    this.setState({
      orderAheadSubmitting: true,
    });

    // TODO: implement NSO update here

    APIV2.modifyStoreParameter(
      this.state?.store?._id,
      "orderAhead",
      this.state.orderAhead == "true"
    )
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          this.setState({
            modifyOrderAhead: false,
          });
        },
        (e) => {
          window.alert(
            "Unable to modify Retail Order Ahead: " + e?.message ??
              "error occurred."
          );
        }
      )
      .finally(() => {
        this.setState({
          orderAheadSubmitting: false,
        });
      });
  }

  render() {
    return (
      <>
        <Card className="mb-3 shadow">
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="">
                <h3 className="mb-0">Pricing Tier</h3>
                <p className="small mb-0">
                  Set the subscription plan pricing tier.
                </p>
              </Col>
              <Col xs="auto">
                {this.state.modifyTier ? (
                  <>
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyTier: false,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        this.setPricingTier();
                      }}
                      disabled={
                        this.state.tierSubmitting || !this.state.pricingTier
                      }
                      className="btn-sm btn"
                      color="primary"
                    >
                      {this.state.tierSubmitting ? (
                        <>
                          <Spinner size="sm"></Spinner>
                        </>
                      ) : (
                        <>Save</>
                      )}
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      this.setState({
                        modifyTier: true,
                        pricingTier: this.state.store?.pricingTier ?? "",
                      });
                    }}
                    className="btn-sm btn"
                    color="secondary"
                    outline
                  >
                    Modify
                  </Button>
                )}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {this.state.modifyTier ? (
              <>
                <Input
                  type="select"
                  name="pricingTier"
                  onChange={this.handleInputChange.bind(this)}
                  value={this.state.pricingTier}
                >
                  <option disabled value="">
                    Select A Tier
                  </option>
                  <option value="Tier 1">Tier 1</option>
                  <option value="Tier 2">Tier 2</option>
                  <option value="Tier 3">Tier 3</option>
                </Input>
              </>
            ) : (
              <>
                <p className="m-0">
                  {this.state.store?.pricingTier ?? "(none set)"}
                </p>
              </>
            )}
          </CardBody>
        </Card>
        <Card className="mb-3 shadow">
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="">
                <h3 className="mb-0">Retail In-App Order Ahead</h3>
                <p className="small mb-0">
                  Enable in-app retail order ahead support for this location.
                </p>
              </Col>
              <Col xs="auto">
                {this.state.modifyOrderAhead ? (
                  <>
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyOrderAhead: false,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        this.setOrderAhead();
                      }}
                      disabled={this.state.orderAheadSubmitting}
                      className="btn-sm btn"
                      color="primary"
                    >
                      {this.state.tierSubmitting ? (
                        <>
                          <Spinner size="sm"></Spinner>
                        </>
                      ) : (
                        <>Save</>
                      )}
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      this.setState({
                        modifyOrderAhead: true,
                        orderAhead: this.state.store?.orderAhead ?? false,
                      });
                    }}
                    className="btn-sm btn"
                    color="secondary"
                    outline
                  >
                    Modify
                  </Button>
                )}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {this.state.modifyOrderAhead ? (
              <>
                <Input
                  type="select"
                  name="orderAhead"
                  onChange={this.handleInputChange.bind(this)}
                  value={this.state.orderAhead}
                >
                  <option disabled value="">
                    Select An Option
                  </option>
                  <option value={"false"}>Disabled</option>
                  <option value={"true"}>Enabled</option>
                </Input>
              </>
            ) : (
              <>
                <p className="m-0">
                  {this.state.store?.orderAhead ? "Enabled" : "Disabled"}
                </p>
              </>
            )}
          </CardBody>
        </Card>
        <Card className=" shadow">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
              <div className="col">
                <h3 className=" mb-0">Subscription Plans</h3>
                <p
                  className="text-muted mb-0 mt-1 small"
                  style={{ lineHeight: 1.2 }}
                >
                  A list of all subscription plans available for sale at this
                  location.
                </p>
              </div>
              <div className="col-auto text-right">
                <CsvDownload
                  className="btn-outline-secondary btn-sm btn"
                  data={this.state.planExport}
                  filename={
                    this.state.store?.name +
                    " Plan Export " +
                    moment().format() +
                    ".csv"
                  }
                >
                  Export
                </CsvDownload>
                <Button
                  onClick={() => {
                    PubSub.publish(Event.PLAN.CREATE_OPEN, this.state.store);
                  }}
                  className="btn-sm btn"
                  color="primary"
                >
                  Add
                </Button>
              </div>
            </Row>
          </CardHeader>
          <CardSelectorHeader
            loading={this.state.planTypesLoading}
            options={this.state.tabs}
            value={this.state.activeTab}
            onChange={(e) => this.filterPlans(e)}
          ></CardSelectorHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col" style={{ minWidth: 300 }}>
                  Name
                </th>
                <th scope="col">Total Price</th>
                <th scope="col">Quantity</th>
                <th scope="col">Available</th>
                <th scope="col">Manage</th>
              </tr>
            </thead>
            <tbody>
              {!this.state.store ||
              !this.state.store.products ||
              !this.state.store.products.mealPlans ||
              !this.state.store.products.mealPlans.length ? (
                <tr>
                  <td colSpan="7">No plans.</td>
                </tr>
              ) : null}
              {this.state.store &&
              this.state.store.products &&
              this.state.store.products.mealPlans
                ? this.state.filteredPlans.map((s, i) => (
                    <PlanRow
                      key={i}
                      plan={s}
                      store={this.state.store ? this.state.store : null}
                    ></PlanRow>
                  ))
                : null}
            </tbody>
          </Table>
        </Card>
        <div className="mt-4">
          <StoreApprovedRetailListWidget
            store={this.state.store}
            loading={this.state.loading}
            onUpdate={(nso) => {
              let store = this.state.store;

              if (store) {
                store.nso = nso;
              }

              this.setState({
                store: store,
              });
            }}
          ></StoreApprovedRetailListWidget>
        </div>
        <CreatePlanModal
          store={this.state.store ? this.state.store : null}
        ></CreatePlanModal>
      </>
    );
  }
}

export default withRouter(StoreViewProductsTab);
