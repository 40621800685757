import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import StorefrontCreateStaffMemberDrawer from "components/Drawers/StorefrontCreateStaffMemberDrawer";
import StorefrontModifyStaffMemberDrawer from "components/Drawers/StorefrontModifyStaffMemberDrawer";
import swal from "sweetalert";
import LearningCourseDrawer from "../../components/Drawers/LearningCourseDrawer";

class LearningManagerCoursesListView extends React.Component {
  state = {
    active: {
      id: "All",
      name: "All",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 25,
    filters: null,
  };

  componentDidUpdate() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    this.loadCourses(1, this.state.count, null);

    PubSub.subscribe(Event.STAFF.CREATED, () => {
      this.loadCourses(this.state.page, this.state.count, this.state.filters);
    });

    PubSub.subscribe(Event.STAFF.MODIFIED, () => {
      this.loadCourses(this.state.page, this.state.count, this.state.filters);
    });
  }

  loadCourses(page = 1, count = 25, filters = null) {
    this.setState({
      loadingCourses: true,
    });

    APIV2.getLearningCourses(page, count, filters)
      .then(
        (data) => {
          if (data?.data?.courses) {
            let courses = data.data.courses;

            this.setState({
              courses,
              pagination: data.data.pagination,
              result: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the courses. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingCourses: false,
        });
      });
  }

  delete(course, replacementID) {
    swal({
      title: "Remove Course?",
      text: `Are you sure you want remove ${course?.name} as a course?`,
      icon: "warning",
      buttons: ["Nevermind", "Remove"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        deleteSubmitting: course?._id,
      });

      APIV2.deleteLearningCourse(course?._id, this.state.replacementID)
        .then(
          (data) => {
            this.loadCourses(
              this.state.page,
              this.state.count,
              this.state.filters
            );
          },
          (e) => {
            console.error(e);
          }
        )
        .finally(() => {
          this.setState({
            deleteSubmitting: false,
          });
        });
    });
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadCourses(page, this.state.count, this.state.filters);
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader
            title="Courses"
            showBackButton={false}
            primaryAction={{
              name: <>Add</>,
              onClick: () => {
                this.setState({
                  openModify: true,
                  modifyCourse: null,
                });
              },
            }}
          />

          <Row>
            <div className="col">
              <Card className=" shadow" style={{ minHeight: "500px" }}>
                <CardSelectorHeader
                  compact={true}
                  value={this.state.active}
                  options={[
                    {
                      name: "All",
                      id: "All",
                    },
                  ]}
                  onChange={() => {}}
                ></CardSelectorHeader>
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    {/*<Col xs="auto" sm="auto" className="pr-0">
                      <Checkbox
                        indeterminate={true}
                        checked={true}
                        onChange={() => {}}
                      ></Checkbox>
                </Col>*/}
                    <Col xs="" sm="" className="">
                      {this.state.result?.start && this.state.result?.end ? (
                        <>
                          Showing{" "}
                          {StringUtils.numberFormat(this.state.result?.start)}-
                          {StringUtils.numberFormat(this.state.result?.end)}
                          {this.state.result?.total ? (
                            <>
                              &nbsp;of{" "}
                              {StringUtils.numberFormat(
                                this.state.result?.total
                              )}{" "}
                              courses
                            </>
                          ) : null}
                        </>
                      ) : (
                        <>---</>
                      )}
                    </Col>
                    <Col
                      xs="auto"
                      sm="auto"
                      className="pl-2 pl-sm-3 text-right"
                    >
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        className="btn-icon-only"
                        outline
                        disabled={
                          this.state.page == 1 ||
                          !this.state.pagination?.hasPrevious ||
                          this.state.searchResults
                        }
                        onClick={() => {
                          this.incrementPage(-1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-left"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        disabled={
                          !this.state.pagination?.hasNext ||
                          this.state.searchResults
                        }
                        className="btn-icon-only"
                        outline
                        onClick={() => {
                          this.incrementPage(1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-right"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="py-2 px-3 bg-superlight border-bottom border-top text-dark">
                    <Row className="align-items-center">
                      <Col xs="">
                        <h4 className="mb-0">Course</h4>
                      </Col>
                    </Row>
                  </div>

                  <div>
                    {this.state.loadingCourses ? (
                      <>
                        <div
                          className="text-center p-3"
                          style={{
                            zIndex: 1,
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            background: "rgba(255, 255, 255, 0.6)",
                          }}
                        >
                          <Spinner
                            style={{ position: "relative", top: "15vh" }}
                            color="medium"
                            size=""
                          ></Spinner>
                        </div>
                      </>
                    ) : null}
                    <div>
                      {!this.state.courses?.length &&
                      !this.state.loadingCourses ? (
                        <div className="px-3 py-2">
                          <Row>
                            <Col>
                              Get started by creating your first course.
                            </Col>
                          </Row>
                        </div>
                      ) : null}

                      {this.state.courses?.map((c, i) => (
                        <div
                          className={`px-3 py-2 bg-superlight--hover cursor-pointer ${
                            i != this.state.courses.length - 1
                              ? "border-bottom"
                              : ""
                          }`}
                          key={i}
                          onClick={() => {
                            this.props.history.push(
                              "/university/lmsmanager/courses/" +
                                c._id +
                                "/overview"
                            );
                          }}
                        >
                          <Row className="align-items-center">
                            <Col xs="">
                              <p className="text-dark text-capitalize small mb-0">
                                {c.type}
                              </p>
                              <h4 className={`mb-0`}>
                                {c?.draft ? (
                                  <Badge
                                    style={{ position: "relative", top: -1 }}
                                    color="medium"
                                    className="mr-2"
                                  >
                                    Draft
                                  </Badge>
                                ) : null}
                                {c.name}
                              </h4>
                              {c?.description ? (
                                <p className="text-dark small mb-0">
                                  {c.description}
                                </p>
                              ) : null}
                            </Col>

                            <Col xs="auto" className="text-right">
                              <Button
                                color="secondary"
                                outline
                                className="btn-icon-only"
                                onClick={() => {
                                  this.props.history.push(
                                    "/university/lmsmanager/courses/" +
                                      c._id +
                                      "/overview"
                                  );
                                }}
                                size="sm"
                              >
                                <i className="mdi mdi-chevron-right"></i>
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        <LearningCourseDrawer
          open={this.state.openModify}
          course={this.state.modifyCourse}
          onClose={() => {
            this.setState({
              openModify: false,
              modifyCourse: null,
            });
          }}
        ></LearningCourseDrawer>
      </>
    );
  }
}

export default LearningManagerCoursesListView;
