export default class LocalStorage {
    static get(key) {
        if (!this.has(key)) {
            return null;
        }

        try {
            let v = JSON.parse(window.localStorage[key]);

            return v;
        } catch (e) {
            return window.localStorage[key];
        } 
    }

    static has(key) {
        if (!key) {
            return false;
        }

        return window.localStorage && window.localStorage.hasOwnProperty(key);
    }

    static set(key, value = null) {
        if (typeof value == 'object') {
            value = JSON.stringify(value);
        }

        if (!window.localStorage || !key) {
            return false;
        }

        window.localStorage[key] = value;
    }
}