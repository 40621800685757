import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  Card,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Badge, Button, Col, Row } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import ClientOrderKPIDetailDrawer from "./ClientOrderKPIDetailDrawer";
import ChartMetricHeader from "./ChartMetricHeader";
import pluralize from "pluralize";

class BusinessReviewOrderGoalCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
  };

  async _fetchGoalData(cubejsApi, stores, dateRange) {
    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: [
            "SubscriptionFulfillmentReports.goalOrderCount",
            "SubscriptionFulfillmentReports.percentGoalOrders",
            "SubscriptionFulfillmentReports.avgFulfilledCount",
          ],
          order: {
            "SubscriptionFulfillmentReports.createdat": "asc",
          },

          timeDimensions: [
            {
              dimension: "SubscriptionFulfillmentReports.fulfillmentdate",
              dateRange: [dateRange[0], dateRange[1]],
            },
          ],
          dimensions: ["SubscriptionFulfillmentReports.storeid"],
          filters: [
            {
              member: "SubscriptionFulfillmentReports.storeid",
              operator: "equals",
              values: stores,
            },
            {
              member: "SubscriptionFulfillmentReports.fulfillmentdate",
              operator: "beforeDate",
              values: [moment().toISOString()],
            },
            {
              member: "SubscriptionFulfillmentReports.goalOrderCount",
              operator: "set",
            },
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          let goalData = {
            totalGoalCount: null,
            goalPercent: null,
            goalCount: null,
          };

          data = data.map((item) => {
            return {
              totalGoalCount:
                item["SubscriptionFulfillmentReports.goalOrderCount"],
              goalCount:
                item["SubscriptionFulfillmentReports.avgFulfilledCount"],
            };
          });

          for (let i = 0; i < data?.length; i++) {
            const item = data[i];

            if (item?.goalCount !== null) {
              goalData.totalGoalCount += item.totalGoalCount;
              goalData.goalCount += item.goalCount;
            }
          }

          if (goalData.goalCount !== null && goalData.totalGoalCount) {
            goalData.goalPercent =
              (goalData.goalCount / goalData.totalGoalCount) * 100;

            if (goalData.goalPercent < 50) {
              goalData.goalColor = "rose";
            } else if (goalData.goalPercent < 60) {
              goalData.goalColor = "orange";
            } else if (goalData.goalPercent < 70) {
              goalData.goalColor = "amber";
            } else if (goalData.goalPercent < 85) {
              goalData.goalColor = "yellow";
            } else if (goalData.goalPercent < 93) {
              goalData.goalColor = "lime";
            } else if (goalData.goalPercent >= 93) {
              goalData.goalColor = "emerald";
            }
          }

          return resolve(goalData);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async loadReport(stores, dateRange, compare) {
    this.setState({
      loading: true,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!stores?.length || !dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    let goals = null;

    try {
      goals = await this._fetchGoalData(cubejsApi, stores, dateRange);
    } catch (e) {}

    if (goals !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });

      goals.goalDataAvailable = true;
    } else {
      this.setState({
        dataAvailable: false,
      });
    }

    this.setState({
      loading: false,
      ...goals,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.store != prevProps?.store ||
      this.props.dateRange != prevProps?.dateRange ||
      this.props.comparePrevious != prevProps.comparePrevious
    ) {
      this.loadReport(
        [this.props.store],
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  componentDidMount() {
    if (this.props.store && this.props.dateRange) {
      this.loadReport(
        [this.props.store],
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  render() {
    return (
      <>
        <Card hFull={true}>
          <ChartMetricHeader
            title="Month To Date"
            description="Average member orders fulfilled weekly this month."
            loading={this.state.loading}
            dataAvailable={this.state.dataAvailable}
            metric={``}
            dateRange={this.props.dateRange}
            showPercentChange={false}
          ></ChartMetricHeader>

          {this.state.loading ? (
            <div
              className="tr-mt-4 skeleton"
              style={{ height: 20, width: 60 }}
            ></div>
          ) : (
            <>
              {this.state.goalDataAvailable ? (
                <>
                  <div className="mt-3">
                    <h1
                      className="m-0 text-dark  text-truncate"
                      style={{ fontSize: 36 }}
                    >
                      {StringUtils.numberFormat(
                        this.state.goalCount?.toFixed(1)
                      )}
                    </h1>
                    <p className="m-0 text-dark">
                      avg. orders/week{" "}
                      {this.props.dateRange?.length
                        ? `during ${moment(this.props.dateRange[0]).format(
                            "MMMM YY"
                          )}`
                        : "this month"}
                    </p>
                  </div>
                  <Row className="align-items-center mt-4">
                    <Col xs="12" sm="6">
                      <p className="text-dark m-0" style={{ fontSize: 18 }}>
                        ~{StringUtils.numberFormat(this.state.goalCount)}
                        /Week &bull; {this.state.goalPercent?.toFixed(1)}%
                      </p>
                    </Col>
                    <Col xs="12" sm="6">
                      <p
                        className="text-dark m-0 text-sm-right"
                        style={{ fontSize: 18 }}
                      >
                        ~
                        {StringUtils.numberFormat(
                          this.state.totalGoalCount?.toFixed(1)
                        )}
                        /Week Goal
                      </p>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Flex marginTop="mt-4">
                    <Text>--</Text>
                  </Flex>
                </>
              )}
            </>
          )}
          {this.state.loading ? (
            <div
              className="tr-mt-2 skeleton"
              style={{ height: 8, width: "100%" }}
            ></div>
          ) : (
            <>
              {this.state.goalDataAvailable ? (
                <ProgressBar
                  percentageValue={this.state.goalPercent}
                  color={this.state.goalColor}
                  marginTop="mt-3"
                />
              ) : (
                <ProgressBar
                  percentageValue={0}
                  color="gray"
                  marginTop="mt-3"
                />
              )}
            </>
          )}
        </Card>
        <ClientOrderKPIDetailDrawer
          open={this.state.open}
          onClose={() => {
            this.setState({
              open: false,
            });
          }}
          stores={this.props.stores}
          dateRange={this.props.dateRange}
        />
      </>
    );
  }
}

export default BusinessReviewOrderGoalCard;
