import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import Alert from "reactstrap/lib/Alert";
import { SelectPicker } from "rsuite";
import CustomerOpenActivitiesWidget from "./CustomerOpenActivitiesWidget";
import CustomerUnclaimedBoxWidget from "./CustomerUnclaimedBoxWidget";
import _ from "underscore";
import Constant from "lib/Constant";

class AffiliateAboutWidget extends React.Component {
  state = {
    expand: false,
    phone: {
      value: "",
    },
    zip: {
      value: "",
    },
    name: "",
    email: "",
  };

  updateInformation() {
    this.setState({ submitting: true });

    let phone = "";

    if (this.state.phone?.value.length == 10) {
      phone = `+1${this.state.phone?.value}`;
    }

    let payload = {
      name: this.state.name,

      phone: phone,
      address: {
        address1: this.state.address1,
        address2: this.state.address2,
        city: this.state.city,
        state: this.state.state,
        zip: this.state.zip?.value,
      },
      type: this.state.type,
      whyPLN: this.state.whyPLN,
      numberMembers: this.state.numberMembers,
    };

    APIV2.modifyAffiliate(
      this.props?.affiliate?.storeID,
      this.props?.affiliate?._id,
      payload
    )
      .then(
        (data) => {
          PubSub.publish(Event.AFFILIATE.MODIFIED, data.data.affiliate);

          this.setState({ openModify: false });
        },
        (e) => {
          this.setError(
            "error",
            "Could not update affiliate, please try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 4000);
  }

  formatAddress(address) {
    if (!address?.address1) {
      return "--";
    }

    return `${address?.address1}${
      address?.address2 ? ` ${address.address2}` : ""
    }, ${address?.city}, ${address?.state} ${address?.zip}`;
  }

  render() {
    return (
      <>
        <Card
          className={`mb-4 ${
            this.props.flat ? "shadow-none" : "shadow"
          } border`}
        >
          <CardHeader>
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Information</h3>
              </div>
              {this.props.modifiable ? (
                <Col xs="auto">
                  {!this.state.openModify ? (
                    <Button
                      size="sm"
                      color="secondary"
                      outline
                      onClick={() => {
                        this.setState({
                          openModify: true,
                          name: this.props?.affiliate?.name,

                          phone: {
                            value: this.props?.affiliate?.phone
                              ? this.props?.affiliate?.phone?.replace("+1", "")
                              : "",
                          },
                          address1: this.props.affiliate?.address?.address1,
                          address2: this.props.affiliate?.address?.address2,
                          city: this.props.affiliate?.address?.city,
                          state: this.props.affiliate?.address?.state,
                          type: this.props.affiliate?.type,
                          zip: {
                            value: this.props.affiliate?.address?.zip,
                          },
                          whyPLN: this.props.affiliate?.whyPLN,
                          numberMembers: this.props.affiliate?.numberMembers,
                        });
                      }}
                    >
                      Edit
                    </Button>
                  ) : (
                    <>
                      <Button
                        size="sm"
                        color="secondary"
                        onClick={() => {
                          this.setState({
                            openModify: false,
                          });
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="sm"
                        color="primary"
                        disabled={
                          this.state.submitting ||
                          !this.state.name ||
                          (this.state.phone?.value &&
                            this.state.phone?.value?.length != 10)
                        }
                        onClick={() => {
                          this.updateInformation();
                        }}
                      >
                        {this.state.submitting ? (
                          <Spinner color="white" size="sm" />
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  )}
                </Col>
              ) : null}
            </Row>
          </CardHeader>
          {this.props.loading ? (
            <CardBody>
              <Spinner size="sm" color="dark" className="mr-2" /> Loading...
            </CardBody>
          ) : (
            <CardBody className="p-0">
              {this.state.openModify ? (
                <>
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h5 className="text-uppercase">Name</h5>
                        <Input
                          type="text"
                          value={this.state.name}
                          name="name"
                          placeholder="Name"
                          onChange={this.handleInputChange.bind(this)}
                        ></Input>
                      </Col>
                    </Row>
                  </div>
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h5 className="text-uppercase">Type</h5>
                        <SelectPicker
                          placement="auto"
                          placeholder="Select A Type"
                          data={Constant.AFFILIATE.TYPES}
                          value={this.state.type}
                          cleanable={false}
                          block
                          onChange={(value, item) => {
                            this.setState({ type: value });
                          }}
                        ></SelectPicker>
                      </Col>
                    </Row>
                  </div>
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h5 className="text-uppercase"># Of Members</h5>
                        <Input
                          type="text"
                          value={this.state.numberMembers}
                          name="numberMembers"
                          placeholder="# Of Members"
                          onChange={this.handleInputChange.bind(this)}
                        ></Input>
                      </Col>
                    </Row>
                  </div>

                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h5 className="text-uppercase">Phone</h5>

                        <NumberFormat
                          className="form-control"
                          format="+1 (###) ###-####"
                          mask=""
                          name="phoneNumberInput"
                          placeholder="Phone Number"
                          onValueChange={(e) => {
                            console.log(e);

                            this.setState({ phone: e });
                          }}
                          value={this.state.phone.value}
                          type="tel"
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="px-4 py-3 border-bottom">
                    <h4>Street Address</h4>
                    <hr className="mb-3 mt-1"></hr>
                    <Row className="align-items-center">
                      <Col xs="12" className="mb-3">
                        <h5 className="text-uppercase">Address</h5>

                        <Input
                          type="text"
                          value={this.state.address1}
                          name="address1"
                          placeholder="Address"
                          onChange={this.handleInputChange.bind(this)}
                        ></Input>
                      </Col>
                      <Col xs="12" className="mb-3">
                        <h5 className="text-uppercase">APT / Suite #</h5>

                        <Input
                          type="text"
                          value={this.state.address2}
                          name="address2"
                          placeholder="APT / Suite #"
                          onChange={this.handleInputChange.bind(this)}
                        ></Input>
                      </Col>
                      <Col xs="12" className="mb-3">
                        <h5 className="text-uppercase">City</h5>

                        <Input
                          type="text"
                          value={this.state.city}
                          name="city"
                          placeholder="City"
                          onChange={this.handleInputChange.bind(this)}
                        ></Input>
                      </Col>
                      <Col xs="12" className="mb-3">
                        <h5 className="text-uppercase">State</h5>

                        <Input
                          type="text"
                          value={this.state.state}
                          name="state"
                          placeholder="State"
                          onChange={this.handleInputChange.bind(this)}
                        ></Input>
                      </Col>
                      <Col xs="12">
                        <h5 className="text-uppercase">Zip Code</h5>

                        <NumberFormat
                          className="form-control"
                          format="#####"
                          mask=""
                          name="zip"
                          placeholder="Zip Code"
                          onValueChange={(e) => {
                            console.log(e);

                            this.setState({ zip: e });
                          }}
                          value={this.state.zip.value}
                          type="tel"
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h5 className="text-uppercase">Why PLN?</h5>
                        <Input
                          type="textarea"
                          value={this.state.whyPLN}
                          name="whyPLN"
                          placeholder="Why PLN?"
                          onChange={this.handleInputChange.bind(this)}
                        ></Input>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                <>
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="auto">Name:</Col>
                      <Col xs="" className="pl-0">
                        {this.props.affiliate?.name
                          ? this.props.affiliate?.name
                          : "--"}
                      </Col>
                    </Row>
                  </div>
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="auto">Type:</Col>
                      <Col xs="" className="pl-0">
                        {this.props.affiliate?.type
                          ? this.props.affiliate?.type
                          : "--"}
                      </Col>
                    </Row>
                  </div>
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="auto"># Of Members:</Col>
                      <Col xs="" className="pl-0">
                        {this.props.affiliate?.numberMembers
                          ? this.props.affiliate?.numberMembers
                          : "--"}
                      </Col>
                    </Row>
                  </div>

                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="auto">Phone:</Col>
                      <Col xs="" className="pl-0">
                        <div>
                          {this.props.affiliate?.phone ? (
                            <>
                              <a href={`tel:${this.props.affiliate?.phone}`}>
                                {StringUtils.formatPhoneNumber(
                                  this.props.affiliate?.phone
                                )}
                              </a>
                            </>
                          ) : (
                            "--"
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="auto">Address:</Col>
                      <Col xs="" className="pl-0">
                        <div>
                          {this.props.affiliate?.address
                            ? this.formatAddress(this.props.affiliate?.address)
                            : "--"}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="px-4 py-3">
                    <Row className="align-items-center">
                      <Col xs="auto">Why PLN:</Col>
                      <Col xs="" className="pl-0">
                        <div>
                          {this.props.affiliate?.whyPLN
                            ? this.props.affiliate?.whyPLN
                            : "--"}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
              {this.state.error ? (
                <Alert className="mt-3 mb-0" color="danger">
                  {this.state.error}
                </Alert>
              ) : null}
            </CardBody>
          )}
        </Card>
      </>
    );
  }
}

export default withRouter(AffiliateAboutWidget);
