import ArrowText from "components/ArrowText";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  Badge,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import Card from "reactstrap/lib/Card";
import moment from "moment";
import CustomerOrderDrawer from "components/Drawers/CustomerOrderDrawer";
import CustomerCreateOrderDrawer from "components/Drawers/CustomerCreateOrderDrawer";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import CustomerAllOrdersDrawer from "components/Drawers/CustomerAllOrdersDrawer";
import _ from "underscore";
import CustomerInvoiceDrawer from "components/Drawers/CustomerInvoiceDrawer";
import CustomerAllInvoicesDrawer from "components/Drawers/CustomerAllInvoicesDrawer";
import CustomerCreateInvoiceDrawer from "components/Drawers/CustomerCreateInvoiceDrawer";
import CustomerCreateConsultationDrawer from "components/Drawers/CustomerCreateConsultationDrawer";
import swal from "sweetalert";
import CustomerModifyConsultationDrawer from "components/Drawers/CustomerModifyConsultationDrawer";
import NewConsultationDrawer from "../Drawers/NewConsultationDrawer";

class LeadConsultationsWidget extends React.Component {
  state = {
    expand: false,
    consultation: null,
  };

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  openModifyDrawer(consultation) {
    this.setState({
      consultation,
      modifyDrawer: true,
    });
  }

  deleteConsultation(consultation) {
    swal({
      title: "Delete Consultation?",
      text: "Are you sure you want to delete this consultation?",
      icon: "warning",
      buttons: ["Nevermind", "Delete"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      APIV2.deleteLeadConsultation(
        this.props?.lead?.storeID,
        this.props?.lead?._id,
        consultation?.id
      ).then((data) => {
        const lead = data?.data?.lead;

        console.log(lead);

        PubSub.publish(Event.LEAD.MODIFIED, lead);
      });
    });
  }

  render() {
    return (
      <>
        {this.props.loading ? (
          <>
            <Card className="mb-4 shadow border">
              <CardBody className="bg-transparent py-5 text-center">
                <Spinner size="lg" color="medium"></Spinner>
              </CardBody>
            </Card>
          </>
        ) : (
          <>
            <Card className="mb-4 border shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <Col>
                    <h3 className="text-dark mb-0">Consultations</h3>
                  </Col>
                  <Col xs="auto">
                    {/*<Button
                      size="sm"
                      color="secondary"
                      outline
                      onClick={() => {
                        this.setState({
                          modified: true,
                          modifyImage: true,
                          imageURL: "",
                        });
                      }}
                    >
                      Mark All <i className="mdi mdi-chevron-down"></i>
                    </Button>*/}
                    <Button
                      size="sm"
                      color="primary"
                      outline
                      onClick={() => {
                        this.setState({ openCreate: true });
                      }}
                    >
                      Add Previous
                    </Button>
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => {
                        this.setState({ openSchedule: true });
                      }}
                    >
                      Schedule
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                {this.state?.loading && (
                  <div className="px-3 py-5 text-center">
                    <Spinner size="sm" color="dark"></Spinner>
                  </div>
                )}
                {!this.props?.lead?.consultations?.length &&
                  !this.state.loading && (
                    <div className="px-3 py-5">
                      <Row>
                        <Col xs="12">
                          <h3 className="m-0">No consultations recorded.</h3>
                          <p>
                            {this.props?.lead?.name
                              ? StringUtils.getFirstName(this.props?.lead?.name)
                              : "This lead"}{" "}
                            does not have any consultations recorded.
                          </p>
                        </Col>
                      </Row>
                    </div>
                  )}

                {this.props?.lead?.consultations?.map((consultation, i) => (
                  <div key={consultation?.id}>
                    <div className="px-4 py-3 cursor-pointer bg-superlight--hover">
                      <Row
                        className={`align-items-center ${
                          consultation?.notes ? "mb-2" : "mb-0"
                        }`}
                      >
                        <Col xs="">
                          <h3 className="mb-0">
                            {moment(consultation?.eventTimestamp).format(
                              "ddd, MMM Do, YYYY"
                            )}
                            {consultation?.coach?.name
                              ? ` by ${consultation?.coach?.name}`
                              : ""}
                          </h3>
                        </Col>
                        <Col xs="auto">
                          {consultation?.storeID ==
                          this.props?.match?.params?.storeID ? (
                            <>
                              <Button
                                className="btn-icon-only mb-0"
                                size="sm"
                                color="dark"
                                onClick={() => {
                                  this.openModifyDrawer(consultation);
                                }}
                                outline
                              >
                                <i className="mdi mdi-pencil"></i>
                              </Button>
                              <Button
                                className="btn-icon-only mb-0"
                                size="sm"
                                color="danger"
                                onClick={() => {
                                  this.deleteConsultation(consultation);
                                }}
                                outline
                              >
                                <i className="mdi mdi-close"></i>
                              </Button>
                            </>
                          ) : (
                            <>
                              <Badge color="medium">
                                From{" "}
                                {consultation?.store?.name
                                  ? consultation?.store?.name
                                      ?.toLowerCase()
                                      ?.replace("project leannation", "PLN")
                                  : "Another Store"}
                              </Badge>
                            </>
                          )}
                        </Col>
                      </Row>
                      {consultation?.notes ? (
                        <>
                          <Row>
                            <Col xs="12">
                              <p
                                style={{ lineHeight: 1.25 }}
                                className="mb-0 text-dark"
                                dangerouslySetInnerHTML={{
                                  __html: consultation?.notes?.replace(
                                    /\n/g,
                                    "<br />"
                                  ),
                                }}
                              ></p>
                            </Col>
                          </Row>
                        </>
                      ) : null}
                    </div>
                    {i != this.props?.lead?.consultations?.length - 1 && (
                      <hr className="m-0"></hr>
                    )}
                  </div>
                ))}
              </CardBody>
            </Card>
          </>
        )}
        <NewConsultationDrawer
          lead={this.props.lead}
          open={this.state.openSchedule}
          onClose={() => {
            this.setState({ openSchedule: false });
          }}
          store={this.props.store}
        ></NewConsultationDrawer>
        <CustomerCreateConsultationDrawer
          lead={this.props.lead}
          open={this.state.openCreate}
          onClose={() => {
            this.setState({ openCreate: false });
          }}
        ></CustomerCreateConsultationDrawer>
        <CustomerModifyConsultationDrawer
          lead={this.props.lead}
          open={this.state.modifyDrawer}
          consultation={this.state.consultation}
          onClose={() => {
            this.setState({ modifyDrawer: false });
          }}
        ></CustomerModifyConsultationDrawer>
      </>
    );
  }
}

export default withRouter(LeadConsultationsWidget);
