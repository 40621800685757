import ArrowText from "components/ArrowText";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  Badge,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import Card from "reactstrap/lib/Card";
import moment from "moment";
import CustomerOrderDrawer from "components/Drawers/CustomerOrderDrawer";
import CustomerCreateOrderDrawer from "components/Drawers/CustomerCreateOrderDrawer";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import CustomerAllOrdersDrawer from "components/Drawers/CustomerAllOrdersDrawer";
import _ from "underscore";
import CustomerInvoiceDrawer from "components/Drawers/CustomerInvoiceDrawer";
import CustomerAllInvoicesDrawer from "components/Drawers/CustomerAllInvoicesDrawer";
import CustomerCreateInvoiceDrawer from "components/Drawers/CustomerCreateInvoiceDrawer";
import CustomerAllEventsDrawer from "components/Drawers/CustomerAllEventsDrawer";
import { SelectPicker } from "rsuite";

class CustomerTimelineWidget extends React.Component {
  state = {
    expand: false,
    openInvoice: null,
    recentFirst: "recentFirst",
  };

  fetchRecentEvents() {
    this.setState({
      loading: true,
    });

    let query = {
      customerID: this?.props?.customer?._id,
    };

    let sortBy = {
      eventTimestamp: this.state.recentFirst == "recentFirst" ? -1 : 1,
    };

    APIV2.getCustomerEventsForStore(
      this.props?.customer?.storeID,
      1,
      20,
      query,
      sortBy
    )
      .then(
        (data) => {
          console.log(data?.data?.events);

          this.setState({ recentEvents: data?.data?.events ?? [] });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    if (this.props.customer) {
      this.fetchRecentEvents();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.customer != prevProps?.customer) {
      this.fetchRecentEvents();
    }
  }

  typeFromString(type) {
    if (type == "automation") {
      return (
        <Badge color="medium" className="text-dark">
          PLN Automation
        </Badge>
      );
    }

    if (type == "user-engagement") {
      return (
        <Badge color="success" className="text-dark">
          User Engagement
        </Badge>
      );
    }

    if (type == "email") {
      return (
        <Badge color="medium" className="text-dark">
          Email Communication
        </Badge>
      );
    }

    if (type == "record-modification") {
      return (
        <Badge color="info" className="text-dark">
          Customer Modified
        </Badge>
      );
    }
  }

  syntaxHighlight(json) {
    return StringUtils.jsonToHTML(json);
  }

  invoiceRow(c) {
    return (
      <Row className="align-items-center">
        <Col className="mb-2 mb-md-0" xs="12" sm="12" md="3">
          <h3 className="mb-0">{this.typeFromString(c.type)}</h3>
          <p className="mb-0" style={{ fontSize: "13px" }}>
            {moment(c.eventTimestamp).format("MM/DD/YY hh:mm A")}
          </p>
        </Col>
        <Col className="mb-3 mb-md-0 text-left" xs="12" sm="12" md="7">
          {c.title}
        </Col>
        <Col xs="12" sm="12" md="2" className="text-left text-md-right">
          <Button
            size="sm"
            outline
            color="dark"
            onClick={() => {
              this.setState({
                viewData: this.state.viewData == c?._id ? "" : c._id,
              });
            }}
          >
            {this.state.viewData == c?._id ? "Hide" : "View"} Data
          </Button>
        </Col>
        {this.state.viewData == c?._id ? (
          <Col xs="12">
            <div
              className="mt-3 p-2 border bg-white"
              dangerouslySetInnerHTML={{
                __html: this.syntaxHighlight(c),
              }}
            ></div>
          </Col>
        ) : null}
      </Row>
    );
  }

  handleRecentFirstChange(v) {
    this.setState(
      {
        recentFirst: v,
        page: 1,
      },
      () => {
        this.fetchRecentEvents();
      }
    );
  }

  render() {
    return (
      <>
        {this.props.loading ? (
          <>
            <Card className="mb-4 shadow border">
              <CardBody className="bg-transparent py-5 text-center">
                <Spinner size="lg" color="medium"></Spinner>
              </CardBody>
            </Card>
          </>
        ) : (
          <>
            <Card className="mb-4 border shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <Col>
                    <h3 className="text-dark mb-0">Recent Events</h3>
                  </Col>
                  <Col xs="auto">
                    <SelectPicker
                      searchable={false}
                      placement="auto"
                      data={[
                        { label: "Newest First", value: "recentFirst" },
                        { label: "Oldest First", value: "oldestFirst" },
                      ]}
                      value={this.state.recentFirst}
                      onChange={(v) => {
                        this.handleRecentFirstChange(v);
                      }}
                      cleanable={false}
                    ></SelectPicker>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                {this.state?.loading && (
                  <div className="px-3 py-5 text-center">
                    <Spinner size="sm" color="dark"></Spinner>
                  </div>
                )}
                {!this.state?.recentEvents?.length && !this.state.loading && (
                  <div className="px-3 py-5">
                    <Row>
                      <Col xs="12">
                        <h3 className="m-0">No customer events available.</h3>
                        <p>
                          {this.props?.customer?.name
                            ? StringUtils.getFirstName(
                                this.props?.customer?.name
                              )
                            : "This customer"}{" "}
                          does not have any recent events.
                        </p>
                      </Col>
                    </Row>
                  </div>
                )}

                {this.state?.recentEvents?.length > 0 && (
                  <div className="px-4 py-2 bg-superlight border-bottom">
                    <Row>
                      <Col xs="12" sm="6" md="4">
                        <h4 className="m-0">Type</h4>
                      </Col>
                      <Col xs="12" sm="6" md="6" className="d-none d-sm-flex">
                        <h4 className="m-0">Subject</h4>
                      </Col>

                      <Col
                        xs="12"
                        sm="6"
                        md="2"
                        className="d-none d-sm-flex"
                      ></Col>
                    </Row>
                  </div>
                )}
                {this.state?.recentEvents?.map((invoice, i) => (
                  <div key={invoice?._id}>
                    <div className="px-4 py-3 cursor-pointer bg-superlight--hover">
                      {this.invoiceRow(invoice)}
                    </div>
                    {i != this.state?.recentEvents?.length - 1 && (
                      <hr className="m-0"></hr>
                    )}
                  </div>
                ))}
              </CardBody>
              <CardFooter className="text-right">
                <Button
                  size="sm"
                  color="secondary"
                  outline
                  onClick={() => {
                    this.setState({ openAll: true });
                  }}
                >
                  View All <ArrowText></ArrowText>
                </Button>
              </CardFooter>
            </Card>
          </>
        )}
        <CustomerAllEventsDrawer
          open={this.state.openAll}
          onClose={() => {
            this.setState({ openAll: false });
          }}
          customer={this.props.customer}
        ></CustomerAllEventsDrawer>
      </>
    );
  }
}

export default withRouter(CustomerTimelineWidget);
