import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  Block,
  ButtonInline,
  Card,
  Divider,
  Flex,
  Footer,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import ClientOrderKPIDetailDrawer from "./ClientOrderKPIDetailDrawer";
import ChartMetricHeader from "./ChartMetricHeader";
import StoreLeaderboardKPIDetailDrawer from "./StoreLeaderboardKPIDetailDrawer";

class StoreLeaderboardKPICard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    activeTab: "A",
  };

  /**
   * Fetches the order goal data
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchOrderGoalData(cubejsApi, stores, dateRange) {
    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: [
            "SubscriptionFulfillmentReports.goalOrderCount",
            "SubscriptionFulfillmentReports.percentGoalOrders",
            "SubscriptionFulfillmentReports.avgFulfilledCount",
          ],
          order: {
            "SubscriptionFulfillmentReports.percentGoalOrders": "desc",
          },

          timeDimensions: [
            {
              dimension: "SubscriptionFulfillmentReports.fulfillmentdate",
              dateRange: [dateRange[0], dateRange[1]],
            },
          ],
          dimensions: ["Stores.name"],
          filters: [
            {
              member: "SubscriptionFulfillmentReports.storeid",
              operator: "equals",
              values: stores,
            },
            {
              member: "SubscriptionFulfillmentReports.fulfillmentdate",
              operator: "beforeDate",
              values: [moment().toISOString()],
            },
            {
              member: "SubscriptionFulfillmentReports.goalOrderCount",
              operator: "set",
            },
          ],
          limit: 4,
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          data = data.map((item) => {
            return {
              totalGoalCount:
                item["SubscriptionFulfillmentReports.goalOrderCount"],
              avgFulfilledCount:
                item["SubscriptionFulfillmentReports.avgFulfilledCount"],
              storeName: item["Stores.name"]?.replace(
                "Project LeanNation",
                "PLN"
              ),
              goalPercent: parseFloat(
                item[
                  "SubscriptionFulfillmentReports.percentGoalOrders"
                ].toFixed(1)
              ),
            };
          });

          for (let i = 0; i < data?.length; i++) {
            let item = data[i];

            if (item.goalPercent < 50) {
              item.goalColor = "rose";
            } else if (item.goalPercent < 60) {
              item.goalColor = "orange";
            } else if (item.goalPercent < 70) {
              item.goalColor = "amber";
            } else if (item.goalPercent < 85) {
              item.goalColor = "yellow";
            } else if (item.goalPercent < 93) {
              item.goalColor = "lime";
            } else if (item.goalPercent >= 93) {
              item.goalColor = "emerald";
            }
          }

          return resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  /**
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchSalesGoalData(cubejsApi, stores, dateRange) {
    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: [
            "MetricsWeeklyRetailSales.metadataSalesTotalAverage",
            "MetricsWeeklyRetailSales.metadataSalesGoalAverage",
            "MetricsWeeklyRetailSales.metadataPercentGoal",
          ],
          order: {
            "MetricsWeeklyRetailSales.metadataPercentGoal": "desc",
          },
          dimensions: ["Stores.name"],
          timeDimensions: [
            {
              dimension: "MetricsWeeklyRetailSales.createdat",
              dateRange: [dateRange[0], dateRange[1]],
            },
          ],
          filters: [
            {
              member: "MetricsWeeklyRetailSales.metadatastoreid",
              operator: "equals",
              values: stores,
            },
            {
              member: "MetricsWeeklyRetailSales.name",
              operator: "equals",
              values: ["weekly_retail_sales"],
            },
          ],

          limit: 4,
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          data = data.map((item) => {
            return {
              totalGoalCount:
                item["MetricsWeeklyRetailSales.metadataSalesGoalAverage"],
              avgFulfilledCount:
                item["MetricsWeeklyRetailSales.metadataSalesTotalAverage"],
              storeName: item["Stores.name"]?.replace(
                "Project LeanNation",
                "PLN"
              ),
              goalPercent: parseFloat(
                (
                  item["MetricsWeeklyRetailSales.metadataPercentGoal"] * 100
                ).toFixed(1)
              ),
            };
          });

          for (let i = 0; i < data?.length; i++) {
            let item = data[i];

            if (item.goalPercent < 50) {
              item.goalColor = "rose";
            } else if (item.goalPercent < 60) {
              item.goalColor = "orange";
            } else if (item.goalPercent < 70) {
              item.goalColor = "amber";
            } else if (item.goalPercent < 85) {
              item.goalColor = "yellow";
            } else if (item.goalPercent < 93) {
              item.goalColor = "lime";
            } else if (item.goalPercent >= 93) {
              item.goalColor = "emerald";
            }
          }

          return resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  /**
   * Fetches a summary of the order data for the given stores and date range
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchOrderData(cubejsApi, stores, dateRange) {
    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["SubscriptionFulfillmentReports.totalFulfilledCount"],
          order: {
            "SubscriptionFulfillmentReports.createdat": "asc",
          },

          timeDimensions: [
            {
              dimension: "SubscriptionFulfillmentReports.fulfillmentdate",
              dateRange: [dateRange[0], dateRange[1]],
            },
          ],
          filters: [
            {
              member: "SubscriptionFulfillmentReports.storeid",
              operator: "equals",
              values: stores,
            },
            {
              member: "SubscriptionFulfillmentReports.fulfillmentdate",
              operator: "beforeDate",
              values: [moment().toISOString()],
            },
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          if (
            data?.length &&
            data[0].hasOwnProperty(
              "SubscriptionFulfillmentReports.totalFulfilledCount"
            )
          ) {
            return resolve(
              data[0]["SubscriptionFulfillmentReports.totalFulfilledCount"]
            );
          }

          return resolve(null);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  /**
   * Fetches a summary of the order data for the given stores and date range
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchSalesData(cubejsApi, stores, dateRange) {
    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["SquareOrders.plnNetAmount"],
          order: {
            "SquareOrders.createdat": "asc",
          },

          timeDimensions: [
            {
              dimension: "SquareOrders.createdat",
              dateRange: [dateRange[0], dateRange[1]],
            },
          ],
          filters: [
            {
              member: "SquareOrders.storeid",
              operator: "equals",
              values: stores,
            },
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          if (
            data?.length &&
            data[0].hasOwnProperty("SquareOrders.plnNetAmount")
          ) {
            return resolve(
              parseFloat(data[0]["SquareOrders.plnNetAmount"]) * 100
            );
          }

          return resolve(null);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async _fetchOrderReport(stores, dateRange) {
    this.setState({
      loading: true,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    if (!stores?.length) {
      return;
    }

    let currentCount = null;

    let goalData = await this._fetchOrderGoalData(cubejsApi, stores, dateRange);

    this.setState({
      orderLeaderboard: goalData,
    });

    try {
      currentCount = await this._fetchOrderData(cubejsApi, stores, dateRange);
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load client order quantity.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });
    }

    if (this.props.comparePrevious) {
      let previousCount = null;

      let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

      secondDifference = secondDifference / 1000;

      let startMoment = moment(dateRange[0].toISOString());
      let endMoment = moment(dateRange[1].toISOString());

      startMoment.subtract(secondDifference, "seconds");
      endMoment.subtract(secondDifference + 1, "seconds");

      try {
        previousCount = await this._fetchOrderData(cubejsApi, stores, [
          startMoment.toDate(),
          endMoment.toDate(),
        ]);
      } catch (e) {}

      if (previousCount !== null) {
        let percentChange = (currentCount - previousCount) / previousCount;

        let declineMode = "";
        let isNegative = false;

        if (percentChange > 0) {
          if (Math.abs(percentChange) < 0.015) {
            declineMode = "unchanged";
          } else if (Math.abs(percentChange) < 0.1) {
            declineMode = "moderateIncrease";
          } else if (Math.abs(percentChange) >= 0.1) {
            declineMode = "increase";
          }
        } else {
          isNegative = true;

          if (Math.abs(percentChange) < 0.015) {
            declineMode = "moderateDecrease";
          } else if (Math.abs(percentChange) < 0.1) {
            declineMode = "moderateDecrease";
          } else if (Math.abs(percentChange) >= 0.1) {
            declineMode = "decrease";
          }
        }

        this.setState({
          previousCount,
          changeIsNegative: isNegative,
          deltaType: declineMode,
          percentChange,
          percentChangeString: Math.abs(percentChange * 100).toFixed(1) + "%",
        });
      }
    }

    this.setState({
      currentCount,
      loading: false,
    });
  }

  /**
   * Fetches the sales report information.
   *
   * @param {*} stores
   * @param {*} dateRange
   * @param {*} compare
   * @returns
   */
  async _fetchSalesReport(stores, dateRange) {
    this.setState({
      salesLoading: true,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!stores?.length || !dateRange?.length) {
      this.setState({
        salesLoading: false,
        salesDataAvailable: false,
      });

      return;
    }

    let currentCount = null;

    let goalData = await this._fetchSalesGoalData(cubejsApi, stores, dateRange);

    this.setState({
      salesLeaderboard: goalData,
    });

    try {
      currentCount = await this._fetchSalesData(cubejsApi, stores, dateRange);
    } catch (e) {
      this.setState({
        salesDataAvailable: false,
        error: "Unable to load client order quantity.",
        salesLoading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        salesDataAvailable: true,
        salesPreviousCount: null,
      });
    } else {
      this.setState({
        salesDataAvailable: false,
      });
    }

    if (this.props.comparePrevious) {
      let previousCount = null;

      let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

      secondDifference = secondDifference / 1000;

      let startMoment = moment(dateRange[0].toISOString());
      let endMoment = moment(dateRange[1].toISOString());

      startMoment.subtract(secondDifference, "seconds");
      endMoment.subtract(secondDifference + 1, "seconds");

      try {
        previousCount = await this._fetchSalesData(cubejsApi, stores, [
          startMoment.toDate(),
          endMoment.toDate(),
        ]);
      } catch (e) {}

      if (previousCount !== null) {
        let percentChange = (currentCount - previousCount) / previousCount;

        let declineMode = "";
        let isNegative = false;

        if (percentChange > 0) {
          if (Math.abs(percentChange) < 0.015) {
            declineMode = "unchanged";
          } else if (Math.abs(percentChange) < 0.1) {
            declineMode = "moderateIncrease";
          } else if (Math.abs(percentChange) >= 0.1) {
            declineMode = "increase";
          }
        } else {
          isNegative = true;

          if (Math.abs(percentChange) < 0.015) {
            declineMode = "moderateDecrease";
          } else if (Math.abs(percentChange) < 0.1) {
            declineMode = "moderateDecrease";
          } else if (Math.abs(percentChange) >= 0.1) {
            declineMode = "decrease";
          }
        }

        this.setState({
          salesPreviousCount: previousCount,
          salesChangeIsNegative: isNegative,
          salesDeltaType: declineMode,
          salesPercentChange: percentChange,
          salesPercentChangeString:
            Math.abs(percentChange * 100).toFixed(1) + "%",
        });
      }
    }

    this.setState({
      salesCurrentCount: currentCount,
      salesLoading: false,
    });
  }

  /**
   * Load all report information
   *
   * @param {*} stores
   * @param {*} dateRange
   * @param {*} compare
   */
  async loadReport(stores, dateRange, compare) {
    this.setState({
      loading: true,
      salesLoading: true,
    });

    this._fetchOrderReport(stores, dateRange);
    this._fetchSalesReport(stores, dateRange);
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.stores != prevProps?.stores && this.props.stores?.length) ||
      this.props.dateRange != prevProps?.dateRange ||
      this.props.comparePrevious != prevProps.comparePrevious
    ) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  componentDidMount() {
    if (this.props.stores && this.props.dateRange) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  render() {
    return (
      <>
        <Card hFull={true}>
          <Row className="align-items-center">
            <Col xs="">
              <h3 className="m-0 text-dark">Store Leaderboard</h3>
            </Col>
            <Col xs="auto">
              <Button
                size="sm"
                outline
                color="dark"
                className="border-0 btn-icon-only"
                disabled={this.state.loading || !this.state.dataAvailable}
                onClick={() => {
                  this.setState({
                    open: true,
                  });
                }}
              >
                <i className="mdi mdi-fullscreen"></i>
              </Button>
            </Col>
          </Row>
          <TabList
            color="orange"
            defaultValue="A"
            handleSelect={(value) => {
              this.setState({
                activeTab: value,
              });
            }}
            marginTop="mt-0"
          >
            <Tab value="A" text="Member Orders" />
            <Tab value="B" text="Retail Sales" />
          </TabList>

          {this.state.activeTab == "A" ? (
            <Block key={"tab1"} spaceY="space-y-2" marginTop="mt-3">
              <ChartMetricHeader
                loading={this.state.loading}
                dataAvailable={this.state.dataAvailable}
                metric={`${StringUtils.numberFormat(
                  this.state.currentCount
                )} Orders`}
                comparisonMetric={
                  this.state.previousCount !== null
                    ? StringUtils.numberFormat(this.state.previousCount)
                    : null
                }
                dateRange={this.props.dateRange}
                deltaType={this.state.deltaType}
                percentChange={this.state.percentChangeString}
                showPercentChange={true}
                forceWrapComparison={true}
              ></ChartMetricHeader>
              <div className="mt-3"></div>
              <Divider className="my-3"></Divider>

              <div>
                {this.state.loading ? (
                  <>
                    <div className="mt-3">
                      <div
                        className="skeleton"
                        style={{ width: 100, height: 15 }}
                      ></div>
                      <div
                        className="skeleton mt-2"
                        style={{ width: "100%", height: 8 }}
                      ></div>
                    </div>
                    <div className="mt-3">
                      <div
                        className="skeleton"
                        style={{ width: 100, height: 15 }}
                      ></div>
                      <div
                        className="skeleton mt-2"
                        style={{ width: "100%", height: 8 }}
                      ></div>
                    </div>
                    <div className="mt-3">
                      <div
                        className="skeleton"
                        style={{ width: 100, height: 15 }}
                      ></div>
                      <div
                        className="skeleton mt-2"
                        style={{ width: "100%", height: 8 }}
                      ></div>
                    </div>
                  </>
                ) : (
                  <>
                    {this.state.orderLeaderboard?.length ? (
                      <>
                        {this.state.orderLeaderboard?.map((store) => (
                          <div>
                            <Flex marginTop="mt-3">
                              <Text>{store.storeName}</Text>
                              <Text>{`~${store.goalPercent}% of Goal`}</Text>
                            </Flex>
                            <ProgressBar
                              marginTop="mt-1"
                              percentageValue={store.goalPercent}
                              color={store.goalColor}
                            />
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        <div className="mt-3">No data available.</div>
                      </>
                    )}
                  </>
                )}
              </div>
            </Block>
          ) : (
            <Block key={"tab2"} spaceY="space-y-2" marginTop="mt-3">
              <ChartMetricHeader
                loading={this.state.salesLoading}
                dataAvailable={this.state.salesDataAvailable}
                metric={StringUtils.centsToCurrency(
                  this.state.salesCurrentCount
                )}
                comparisonMetric={
                  this.state.salesPreviousCount !== null
                    ? StringUtils.centsToCurrency(this.state.salesPreviousCount)
                    : null
                }
                dateRange={this.props.dateRange}
                deltaType={this.state.salesDeltaType}
                percentChange={this.state.salesPercentChangeString}
                showPercentChange={true}
                forceWrapComparison={true}
              ></ChartMetricHeader>
              <div className="mt-3"></div>
              <Divider className="my-3"></Divider>

              <div>
                {this.state.salesLoading ? (
                  <>
                    <div className="mt-3">
                      <div
                        className="skeleton"
                        style={{ width: 100, height: 15 }}
                      ></div>
                      <div
                        className="skeleton mt-2"
                        style={{ width: "100%", height: 8 }}
                      ></div>
                    </div>
                    <div className="mt-3">
                      <div
                        className="skeleton"
                        style={{ width: 100, height: 15 }}
                      ></div>
                      <div
                        className="skeleton mt-2"
                        style={{ width: "100%", height: 8 }}
                      ></div>
                    </div>
                    <div className="mt-3">
                      <div
                        className="skeleton"
                        style={{ width: 100, height: 15 }}
                      ></div>
                      <div
                        className="skeleton mt-2"
                        style={{ width: "100%", height: 8 }}
                      ></div>
                    </div>
                  </>
                ) : (
                  <>
                    {this.state.salesLeaderboard?.length ? (
                      <>
                        {this.state.salesLeaderboard?.map((store) => (
                          <div>
                            <Flex marginTop="mt-3">
                              <Text>{store.storeName}</Text>
                              <Text>{`~${store.goalPercent}% of Goal`}</Text>
                            </Flex>
                            <ProgressBar
                              marginTop="mt-1"
                              percentageValue={store.goalPercent}
                              color={store.goalColor}
                            />
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        <div className="mt-3">No data available.</div>
                      </>
                    )}
                  </>
                )}
              </div>
            </Block>
          )}
        </Card>

        <StoreLeaderboardKPIDetailDrawer
          open={this.state.open}
          onClose={() => {
            this.setState({
              open: false,
            });
          }}
          stores={this.props.stores}
          dateRange={this.props.dateRange}
        />
      </>
    );
  }
}

export default StoreLeaderboardKPICard;
