import PubSub from "lib/PubSub";
import React from "react";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Input } from "reactstrap";

export default class LocationSearchInput extends React.Component {
  state = {
    address: "",
  };

  componentDidMount() {
    PubSub.subscribe("locationSearchClear", () => {
      this.setState({ address: "" });
    });
  }

  handleChange = (address) => {
    this.setState({ address });

    if (typeof this.props.onChange == "function") {
      this.props.onChange({ address });
    }
  };

  handleSelect = (address) => {
    let result = null;

    geocodeByAddress(address)
      .then((results) => {
        result = results[0];

        return getLatLng(result);
      })
      .then((latLng) => {
        console.log("Success", latLng);

        if (typeof this.props.onSelect == "function") {
          this.props.onSelect({ result, geographic: latLng });
        }
      })
      .catch((error) => console.error("Error", error));
  };

  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={this.props.searchOptions ? this.props.searchOptions : {}}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="relative">
            {this.props.label ? (
              <label htmlFor="name" className={`small`}>
                {this.props.label}
              </label>
            ) : (
              <></>
            )}
            <Input
              type="text"
              name="location"
              id="location"
              bsSize="sm"
              className={`form-control`}
              placeholder="New York, NY"
              {...getInputProps({
                placeholder: this.props.placeholder
                  ? this.props.placeholder
                  : "Search Locations",
              })}
            />
            <div
              style={{
                position: "absolute",
                top: 50,
                zIndex: 99,
                maxHeight: "15rem",
              }}
              className={`absolute w-full ${
                loading || suggestions?.length
                  ? `rounded border border-gray-100 bg-white shadow overflow-y-auto`
                  : ""
              }`}
            >
              {loading && (
                <div className="p-2 text-dark font-normal">Searching...</div>
              )}
              {!loading &&
                suggestions.map((suggestion, i) => {
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      key={i}
                      {...getSuggestionItemProps(suggestion, {
                        className: "p-2 text-dark",
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}
