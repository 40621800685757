import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  BarChart,
  BarList,
  Card,
  DonutChart,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Drawer, Popover, Whisper } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import {
  Axis,
  Chart,
  Coordinate,
  Interaction,
  Interval,
  Legend,
  Tooltip,
} from "bizcharts";
import { Tooltip as RTooltip } from "rsuite";
import ChartMetricHeader from "./ChartMetricHeader";

class StoreDailyInventoryMealAveragePieChartCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    tab: "performanceTrend",
    colorKeys: {
      Lifestyle: "emerald",
      Athlete: "orange",
      "Lean Cheat": "yellow",
    },
    tab: "Quantity",
    categories: [
      "Lifestyle",
      "Athlete",
      "Lean Cheat",
    ],
    colors: ["emerald", "orange", "yellow"],
  };

  /**
   * Fetches the order data by week for the given stores & date range
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchOrderDataByWeek(cubejsApi, stores, dateRange) {
    return new Promise((resolve, reject) => {
      let endDate6 = moment(dateRange[1]);

      endDate6.endOf("day");

      let now = moment();

      if (endDate6.isAfter(now)) {
        endDate6 = now;
      }

      // Load
      cubejsApi
        .load({
          measures: [
            "StoreClosingInventoryReports.totalquantityaverage",
            "StoreClosingInventoryReports.lifestylequantityaverage",
            "StoreClosingInventoryReports.athletequantityaverage",
            "StoreClosingInventoryReports.leancheatquantityaverage",
            "StoreClosingInventoryReports.totalmealvalueaverage",
            "StoreClosingInventoryReports.lifestylemealvalueaverage",
            "StoreClosingInventoryReports.athletemealvalueaverage",
            "StoreClosingInventoryReports.leancheatvalueaverage",
            "StoreClosingInventoryReports.totalunitcostaverage",
            "StoreClosingInventoryReports.lifestylemealtotalunitcostaverage",
            "StoreClosingInventoryReports.athletemealtotalunitcostaverage",
            "StoreClosingInventoryReports.leancheattotalunitcostaverage",
          ],
          order: {
            "StoreClosingInventoryReports.date": "desc",
          },

          timeDimensions: [
            {
              dimension: "StoreClosingInventoryReports.date",
              dateRange: [moment(dateRange[0]).toDate(), endDate6?.toDate()],
            },
          ],
          filters: [
            {
              member: "StoreClosingInventoryReports.storeid",
              operator: "equals",
              values: stores,
            },
          ],
          dimensions: ["Stores.name"],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          console.log("DATA", data);

          if (!data?.length) {
            return resolve(null);
          }

          let totalCount = 0;
          let totalCost = 0;
          let totalValue = 0;

          let lifestyleValue = 0;
          let lifestyleCount = 0;
          let lifestyleCost = 0;

          let athleteValue = 0;
          let athleteCount = 0;
          let athleteCost = 0;

          let leancheatValue = 0;
          let leancheatCount = 0;
          let leancheatCost = 0;

          data = data.map((item) => {
            totalCount +=
              item["StoreClosingInventoryReports.totalquantityaverage"];

            totalCost +=
              item["StoreClosingInventoryReports.totalunitcostaverage"];

            totalValue +=
              item["StoreClosingInventoryReports.totalmealvalueaverage"];

            lifestyleValue +=
              item["StoreClosingInventoryReports.lifestylemealvalueaverage"];

            lifestyleCount +=
              item["StoreClosingInventoryReports.lifestylequantityaverage"];

            lifestyleCost +=
              item[
                "StoreClosingInventoryReports.lifestylemealtotalunitcostaverage"
              ];

            athleteValue +=
              item["StoreClosingInventoryReports.athletemealvalueaverage"];

            athleteCount +=
              item["StoreClosingInventoryReports.athletequantityaverage"];

            athleteCost +=
              item[
                "StoreClosingInventoryReports.athletemealtotalunitcostaverage"
              ];

            leancheatValue +=
              item["StoreClosingInventoryReports.leancheatvalueaverage"];

            leancheatCount +=
              item["StoreClosingInventoryReports.leancheatquantityaverage"];

            leancheatCost +=
              item[
                "StoreClosingInventoryReports.leancheattotalunitcostaverage"
              ];

            return {
              storeName: item["Stores.name"]
                ?.replace("Project LeanNation", "PLN")
                ?.trim(),
              "Lifestyle Quantity":
                item["StoreClosingInventoryReports.lifestylequantityaverage"],
              "Athlete Quantity":
                item["StoreClosingInventoryReports.athletequantityaverage"],
              "Lean Cheat Quantity":
                item["StoreClosingInventoryReports.leancheatquantityaverage"],
              "Total Quantity":
                item["StoreClosingInventoryReports.totalquantityaverage"],
              "Lifestyle Value":
                item["StoreClosingInventoryReports.lifestylemealvalueaverage"],
              "Athlete Value":
                item["StoreClosingInventoryReports.athletemealvalueaverage"],
              "Lean Cheat Value":
                item["StoreClosingInventoryReports.leancheatvalueaverage"],
              "Total Value":
                item["StoreClosingInventoryReports.totalmealvalueaverage"],
              "Lifestyle Unit Cost":
                item[
                  "StoreClosingInventoryReports.lifestylemealtotalunitcostaverage"
                ],
              "Athlete Unit Cost":
                item[
                  "StoreClosingInventoryReports.athletemealtotalunitcostaverage"
                ],
              "Lean Cheat Unit Cost":
                item[
                  "StoreClosingInventoryReports.leancheattotalunitcostaverage"
                ],
              "Total Cost":
                item["StoreClosingInventoryReports.totalunitcostaverage"],
            };
          });

          lifestyleCost = Math.round(lifestyleCost / data?.length);
            athleteCost = Math.round(athleteCost / data?.length);
            leancheatCost = Math.round(leancheatCost / data?.length);
            
            lifestyleValue = Math.round(lifestyleValue / data?.length);
            athleteValue = Math.round(athleteValue / data?.length);
            leancheatValue = Math.round(leancheatValue / data?.length);

            lifestyleCount = Math.round(lifestyleCount / data?.length);
            athleteCount = Math.round(athleteCount / data?.length);
            leancheatCount = Math.round(leancheatCount / data?.length);

          let out = [];
          let overall = [{key: 'Lifestyle', Quantity: lifestyleCount, Value: lifestyleValue, Cost: lifestyleCost, color: 'emerald'}, {key: 'Athlete', Quantity: athleteCount, Value: athleteValue, Cost: athleteCost, color: 'orange'}, {key: 'Lean Cheats', Quantity: leancheatCount, Value: leancheatValue, Cost: leancheatCost, color: 'yellow'}];

          let outByStore2 = data;
          

          totalCost = Math.round(totalCost / data?.length);
            totalValue = Math.round(totalValue / data?.length);
            totalCount = Math.round(totalCount / data?.length);

          console.log(overall);

          outByStore2 = _.sortBy(outByStore2, "Total Quantity");

          outByStore2.reverse();

          return resolve({
            byStore: outByStore2,
            total: totalCount,
            totalCost,
            totalValue,
            overall,
          });
        })
        .catch((e) => {
          console.error(e);

          reject(e);
        });
    });
  }

  async loadDetailedReport(stores, dateRange) {
    this.setState({
      loading: true,
      dataAvailable: false,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!stores?.length || !dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchOrderDataByWeek(
        cubejsApi,
        stores,
        dateRange
      );
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load meal quantities.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });

      return;
    }

    

    this.setState({
      currentCount: currentCount,
      loading: false,
      byStore: currentCount?.byStore,
      overall: currentCount?.overall,
      total: currentCount?.total,
      totalCost: currentCount?.totalCost,
      totalValue: currentCount?.totalValue,
      colors: currentCount?.overall?.map((item) => {
        return item.color;
      }),
    });

    if (typeof this.props.onLoaded == "function") {
      this.props.onLoaded();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stores != prevProps?.stores ||
      this.props.dateRange != prevProps?.dateRange
    ) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload != prevProps.reload && this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  getColorForPlan(key) {
    let item = _.findWhere(this.state.overall, { planReadableName: key });

    return StringUtils.CHART_COLOR_CODES[item.color];
  }

  getStoreTotal(item, type) {
    let total = 0;

    let keys = _.keys(item);

    for (let i = 0; i < keys?.length; i++) {
      if (
        keys[i] == "storeName" ||
        keys[i] == "color" ||
        keys[i] == "Quantity" ||
        isNaN(item[keys[i]]) ||
        !keys[i].includes(type)
      ) {
        continue;
      }

      console.log(keys[i]);

      total += item[keys[i]];
    }

    console.log(total);

    return total;
  }

  componentDidMount() {
    if (this.props.store && this.props.dateRange) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  render() {
    return (
      <>
        <Card marginTop="mt-0">
          <Row className="align-items-center">
            <Col xs="">
              <h3 className="m-0 text-dark">Average Daily Inventory By Type</h3>
            </Col>
            <Col xs="auto">
              <Button
                size="sm"
                outline
                color="dark"
                className="border-0 btn-icon-only"
                disabled={this.state.loading}
                onClick={() => {
                  this.loadDetailedReport(
                    this.props.stores,
                    this.props.dateRange
                  );
                }}
              >
                {this.state.loading ? (
                  <Spinner size="sm"></Spinner>
                ) : (
                  <i className="mdi mdi-refresh"></i>
                )}
              </Button>
            </Col>
          </Row>

          {this.state.loading ? (
            <div
              className="skeleton mt-4"
              style={{ height: "calc(320px + 1rem)", width: "100%" }}
            >
              &nbsp;
            </div>
          ) : (
            <>
              {this.state.dataAvailable ? (
                <>
                <TabList
            color="orange"
            defaultValue="Quantity"
            handleSelect={(value) => {
              this.setState({
                tab: value,
              });
            }}
            marginTop="mt-3"
            disabled={this.state.loading || !this.state.dataAvailable}
          >
            <Tab value="Quantity" text="Unit Quantity" />
            {/*<Tab value="type" text="Units By Type" />*/}
            <Tab value="Value" text="Total Sale Value" />
            <Tab value="Cost" text="Total Unit Cost" />
          </TabList>
                  <div className="mt-4 mx--4 px-4">
                    <Row className="align-items-center">
                      <Col xs="12" sm="12" md="6">
                        <div className="pt-4">
                          <DonutChart
                            data={this.state.overall}
                            category={this.state.tab}
                            dataKey={"key"}
                            colors={this.state.overall?.map(
                              (item) => item.color
                            )}
                            valueFormatter={(number) => {
                              return `${this.state.tab == 'Quantity' ? `${StringUtils.numberFormat(
                                number
                              )} Units` : StringUtils.centsToCurrency(
                                number
                              )} [${StringUtils.numberFormat(
                                parseFloat(
                                  ((number / (this.state.tab == 'Quantity' ? this.state.total : (this.state.tab == 'Cost' ? this.state.totalCost : this.state.totalValue))) * 100).toFixed(1)
                                )
                              )}%]`;
                            }}
                            height="h-80"
                          />
                        </div>
                      </Col>
                      <Col md="6" className="mt-3 mt-md-0">
                        <div
                          className="pt-4 mb--4 pb-4"
                          style={{
                            maxHeight: 368,
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          {this.state.byStore?.map((item, i) => (
                            <>
                              <div className="mb-3" key={i}>
                                <h3 className="text-dark mb-1">
                                  {item?.storeName}
                                </h3>

                                <div className="text-dark small font-weight-medium">
                                  Units: {StringUtils.numberFormat(Math.round(item['Total Quantity']))}{" "}
                                  ({StringUtils.centsToCurrency(item['Total Value'])} Value)
                                </div>
                                <div
                                  style={{
                                    borderRadius: 6,
                                    width: "100%",
                                    height: 20,
                                    overflow: "hidden",
                                  }}
                                  className="mb-2 border border-superlight"
                                >
                                  {this.state.categories.map((category, j) => (
                                    <div
                                      style={{
                                        height: 20,
                                        width: `${
                                          (item[`${category} ${this.state.tab}`] / item[`Total ${this.state.tab}`]) * 100
                                        }%`,
                                        background: StringUtils.CHART_COLOR_CODES[this.state.colorKeys[category]],
                                      }}
                                      className="d-inline-block cursor-pointer"
                                      key={j}
                                    >
                                      <Whisper
                                        trigger="hover"
                                        placement="auto"
                                        preventOverflow={true}
                                        speaker={
                                          <Popover full className="">
                                            <div className="p-2">
                                              <h4 className="mb-1 text-dark">
                                                {item.storeName}
                                              </h4>
                                              <p className="m-0 text-dark">
                                                <span className="font-weight-bold">
                                                  {category} {this.state.tab}:
                                                </span>
                                                <span>
                                                  &nbsp;
                                                  {
                                                    this.state.tab == 'Quantity' ? `${StringUtils.numberFormat(
                                                        Math.round(item[`${category} ${this.state.tab}`]))} Units` : StringUtils.centsToCurrency(
                                                        item[`${category} ${this.state.tab}`]
                                                        )
                                                  } [
                                                    {(
                                                      (item[`${category} ${this.state.tab}`] /
                                                        item[`Total ${this.state.tab}`]) *
                                                      100
                                                    ).toFixed(1)}
                                                    %]
                                                </span>
                                              </p>
                                            </div>
                                          </Popover>
                                        }
                                      >
                                        <div
                                          style={{
                                            height: 20,
                                            width: "100%",
                                          }}
                                        ></div>
                                      </Whisper>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div className="my-2 border border-midlighter"></div>
                            </>
                          ))}

                          {/**
                           * <BarList
                            data={this.state.byStore?.map((item) => {
                              return {
                                name: item?.storeName,
                                value: parseFloat(
                                  (
                                    (item?.weekly / item?.Quantity) *
                                    100
                                  ).toFixed(1)
                                ),
                              };
                            })}
                            valueFormatter={(number) => {
                              return `${StringUtils.numberFormat(
                                number
                              )}% weekly, ${StringUtils.numberFormat(
                                100 - number
                              )}% biweekly`;
                            }}
                            color="emerald"
                            showAnimation={true}
                            marginTop="mt-0"
                          />
                           */}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "calc(320px + 1rem)", width: "100%" }}
                >
                  <p className="m-0">
                    No data available.
                    {this.state.error ? ` ${this.state.error}` : null}
                  </p>
                </div>
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default StoreDailyInventoryMealAveragePieChartCard;
