import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  Block,
  Card,
  ColGrid,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
  Col as TCol,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import ClientOrderSummaryChartCard from "./ClientOrderSummaryChartCard";
import ClientOrderGoalSummaryChartCard from "./ClientOrderGoalSummaryChartCard";
import StoreLeaderboardOrderSummaryChartCard from "./StoreLeaderboardOrderSummaryChartCard";
import StoreLeaderboardGoalTrendChartCard from "./StoreLeaderboardGoalTrendChartCard";
import StoreLeaderboardOrderCard from "./StoreLeaderboardOrderCard";
import StoreLeaderboardRetailSummaryChartCard from "./StoreLeaderboardRetailSummaryChartCard";
import StoreLeaderboardRetailGoalTrendChartCard from "./StoreLeaderboardRetailGoalTrendChartCard";
import StoreLeaderboardRetailCard from "./StoreLeaderboardRetailCard";

class StoreLeaderboardKPIDetailDrawer extends React.Component {
  state = {
    activeTab: "A",
  };

  componentDidUpdate(prevProps) {
    if (this.props.open != prevProps.open && this.props.open) {
      this.setState({
        reloadCards: true,
      });
    }
  }

  componentDidMount() {
    if (this.props.open) {
      this.setState({
        reloadCards: true,
      });
    }
  }

  hasValidDateRange() {
    if (
      this.props?.dateRange?.length != 2 ||
      !this.props?.dateRange[0] ||
      !this.props?.dateRange[1]
    ) {
      return false;
    }

    return true;
  }

  renderDateRange() {
    if (!this.hasValidDateRange()) {
      return "Select Date Range";
    }

    let startMoment = moment(this.props.dateRange[0]?.toISOString());
    let endMoment = moment(this.props.dateRange[1]?.toISOString());

    if (startMoment?.format("YYYY-MM-DD") == endMoment.format("YYYY-MM-DD")) {
      return `${startMoment?.format("MMM D, YYYY")}`;
    }

    return `${startMoment?.format("MMM D, YYYY")} - ${endMoment?.format(
      "MMM D, YYYY"
    )}`;
  }

  renderPreviousDateRange() {
    if (!this.hasValidDateRange()) {
      return "--";
    }

    let secondDifference =
      this.props.dateRange[1].getTime() - this.props.dateRange[0].getTime();

    secondDifference = secondDifference / 1000;

    let startMoment = moment(this.props.dateRange[0].toISOString());
    let endMoment = moment(this.props.dateRange[1].toISOString());

    startMoment.subtract(secondDifference, "seconds");
    endMoment.subtract(secondDifference + 1, "seconds");

    if (startMoment?.format("YYYY-MM-DD") == endMoment.format("YYYY-MM-DD")) {
      return `${startMoment?.format("MMM D, YYYY")}`;
    }

    return `${startMoment?.format("MMM D, YYYY")} - ${endMoment?.format(
      "MMM D, YYYY"
    )}`;
  }

  render() {
    return (
      <>
        <Drawer
          size="lg"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => {
            if (typeof this.props.onClose == "function") {
              this.props.onClose();
            }
          }}
        >
          <Drawer.Header className="pr-4">
            <h3 className="mb-2" style={{ position: "relative", top: "7px" }}>
              Store Leaderboard
            </h3>
            <Drawer.Actions>
              <p
                className="small m-0 text-dark"
                style={{ position: "relative", top: "8px", lineHeight: 1.2 }}
              >
                {this.props.stores?.length} Store
                {this.props.stores?.length != 1 && "s"}
              </p>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            <div className="mb-2">
              <h1 className="m-0">{this.renderDateRange()}</h1>
              <p className="m-0 text-muted">
                Compared to {this.renderPreviousDateRange()}
              </p>
            </div>
            <TabList
              color="orange"
              defaultValue="A"
              handleSelect={(value) => {
                this.setState({
                  activeTab: value,
                  reloadCards: true,
                });
              }}
              marginTop="mt-0"
            >
              <Tab value="A" text="Member Orders" />
              <Tab value="B" text="Retail Sales" />
            </TabList>
            <div className="mb-4"></div>
            {this.state.activeTab == "A" ? (
              <>
                <ColGrid
                  numColsLg={12}
                  numColsMd={12}
                  gapX="gap-x-6"
                  gapY="gap-y-6"
                  marginTop="mt-6"
                >
                  {/* Main section */}
                  <TCol numColSpanMd={6}>
                    <StoreLeaderboardOrderSummaryChartCard
                      stores={this.props.stores}
                      reload={this.state.reloadCards}
                      dateRange={this.props.dateRange}
                      onLoaded={() => {
                        this.setState({
                          reloadCards: false,
                        });
                      }}
                    ></StoreLeaderboardOrderSummaryChartCard>
                  </TCol>

                  {/* KPI sidebar */}
                  <TCol numColSpanMd={6}>
                    <StoreLeaderboardGoalTrendChartCard
                      stores={this.props.stores}
                      reload={this.state.reloadCards}
                      dateRange={this.props.dateRange}
                      onLoaded={() => {
                        this.setState({
                          reloadCards: false,
                        });
                      }}
                    ></StoreLeaderboardGoalTrendChartCard>
                  </TCol>
                </ColGrid>

                <div className="my-4"></div>
                <StoreLeaderboardOrderCard
                  stores={this.props.stores}
                  reload={this.state.reloadCards}
                  dateRange={this.props.dateRange}
                  onLoaded={() => {
                    this.setState({
                      reloadCards: false,
                    });
                  }}
                ></StoreLeaderboardOrderCard>
              </>
            ) : (
              <>
                <ColGrid
                  numColsLg={12}
                  numColsMd={12}
                  gapX="gap-x-6"
                  gapY="gap-y-6"
                  marginTop="mt-6"
                >
                  {/* Main section */}
                  <TCol numColSpanMd={6}>
                    <StoreLeaderboardRetailSummaryChartCard
                      stores={this.props.stores}
                      reload={this.state.reloadCards}
                      dateRange={this.props.dateRange}
                      onLoaded={() => {
                        this.setState({
                          reloadCards: false,
                        });
                      }}
                    ></StoreLeaderboardRetailSummaryChartCard>
                  </TCol>

                  {/* KPI sidebar */}
                  <TCol numColSpanMd={6}>
                    <StoreLeaderboardRetailGoalTrendChartCard
                      stores={this.props.stores}
                      reload={this.state.reloadCards}
                      dateRange={this.props.dateRange}
                      onLoaded={() => {
                        this.setState({
                          reloadCards: false,
                        });
                      }}
                    ></StoreLeaderboardRetailGoalTrendChartCard>
                  </TCol>
                </ColGrid>

                <div className="my-4"></div>
                <StoreLeaderboardRetailCard
                  stores={this.props.stores}
                  reload={this.state.reloadCards}
                  dateRange={this.props.dateRange}
                  onLoaded={() => {
                    this.setState({
                      reloadCards: false,
                    });
                  }}
                ></StoreLeaderboardRetailCard>
              </>
            )}
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default StoreLeaderboardKPIDetailDrawer;
