import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";

class CustomerCreateDrawer extends React.Component {
  state = {
    fulfillmentDate: moment().day(6).toDate(),
    phone: {
      value: "",
    },
    name: "",
    email: "",
    plan: null,
    plans: [],
  };

  componentDidUpdate(prevProps) {
    if (this.props.store?._id != prevProps.store?._id) {
      this.calculateFirstBillDate();
      this.getAllPlans();
    }

    if (this.props.lead != prevProps.lead) {
      this.setState({
        name: this.props.lead?.name ? this.props?.lead?.name : "",
        email: this.props.lead?.email ? this.props?.lead?.email : "",
        phone: {
          value:
            this.props.lead?.phone && typeof this.props.lead?.phone === "string"
              ? this.props?.lead?.phone?.replace("+1", "")
              : "",
        },
      });
    }
  }

  componentDidMount() {
    if (this.props.store?._id) {
      this.calculateFirstBillDate();
      this.getAllPlans();
    }

    if (this.props.lead) {
      this.setState({
        name: this.props.lead?.name ? this.props?.lead?.name : "",
        email: this.props.lead?.email ? this.props?.lead?.email : "",
        phone: {
          value: this.props.lead?.phone
            ? this.props?.lead?.phone?.replace("+1", "")
            : "",
        },
      });
    }
  }

  calculateFirstBillDate() {
    let first = moment().day(6).toDate();

    if (moment().day() == 6) {
      first = moment().day(6).add(1, "weeks").toDate();
    }

    if (this.props.store?.operations?.storeOpening?.firstBillDate) {
      let firstFromStore = moment(
        this.props.store?.operations?.storeOpening?.firstBillDate
      ).toDate();

      if (firstFromStore > first) {
        first = firstFromStore;
      }
    }

    this.setState({
      fulfillmentDate: first,
    });
  }

  toggleModal() {
    this.setState({
      name: "",
      email: "",
      phone: {
        value: "",
      },
      plan: null,
    });

    this.calculateFirstBillDate();

    this.props.onClose();
  }

  handlePhoneChange(values) {
    console.log(values);

    this.setState({
      phone: values,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  create() {
    this.setState({ submitting: true });

    APIV2.createCustomer(
      this.props?.store?._id,
      this.state.name,
      this.state.email,
      this.state.phone?.value,
      this.state.plan,
      this.state.fulfillmentDate,
      new Date(),
      "Store Visit"
    )
      .then(
        (data) => {
          const customer = data.data.customer;

          this.toggleModal();

          PubSub.publish(Event.CUSTOMER.CREATED, customer);

          if (this.props.redirect !== false) {
            this.props.history.push(
              `/storefront/${this?.props?.store?._id}/members/details/${customer?._id}/summary`
            );
          }
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create customer - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  generatePlanDescription(plan) {
    return StringUtils.generatePlanDescription(plan);
  }

  getAllPlans() {
    this.setState({
      loadingPlans: true,
    });

    if (!this.props.store?._id) {
      return;
    }

    APIV2.getStoreByID(this.props.store?._id)
      .then(
        (data) => {
          let plans = data.data?.store?.products?.mealPlans?.map((c) => {
            return {
              label: (
                <>
                  <p className="m-0 font-weight-bold">{c.name}</p>
                  <p className="m-0" style={{ lineHeight: 1.3 }}>
                    <small>{this.generatePlanDescription(c)}</small>
                  </p>
                </>
              ),
              value: c._id,
              type: c.planType.name,
            };
          });

          this.setState({ plans: plans });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          loadingPlans: false,
        });
      });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              New Member
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={
                  this.state.submitting ||
                  !this.state.fulfillmentDate ||
                  !this.state.name ||
                  !this.state.email ||
                  !this.state.phone?.value ||
                  !this.state.plan
                }
                color="primary"
                onClick={this.create.bind(this)}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  "Add"
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            <h4 className="mb-3">Contact Information</h4>
            <FormGroup>
              <h5>Name</h5>
              <Input
                bsSize="sm"
                type="text"
                name="name"
                placeholder="Name"
                value={this.state.name}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Email</h5>
              <Input
                bsSize="sm"
                type="email"
                name="email"
                placeholder="Email"
                value={this.state.email}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Phone</h5>
              <NumberFormat
                className="form-control mb-3 form-control-sm"
                format="(###) ###-####"
                name="phoneNumberInput"
                placeholder="Phone"
                onValueChange={this.handlePhoneChange.bind(this)}
                value={this.state.phone.value}
                type="tel"
                mask="_"
              />
            </FormGroup>
            <hr className="my-3"></hr>
            <h4 className="mb-3">Subscription</h4>
            <FormGroup>
              <h5>Plan</h5>
              <SelectPicker
                placement="auto"
                placeholder="Select A Plan"
                data={this.state.plans}
                value={this.state.plan}
                block
                groupBy="type"
                onChange={(value, item) => {
                  this.setState({ plan: value });
                }}
              ></SelectPicker>
            </FormGroup>
            <FormGroup>
              <h5>First Fulfillment Date</h5>
              <DatePicker
                oneTap
                required
                block
                placeholder="Select A Date"
                defaultValue={moment().day(6).toDate()}
                value={this.state.fulfillmentDate}
                onChange={(e) => {
                  this.setState({ fulfillmentDate: e });
                }}
                disabledDate={(date) => date < new Date() || date.getDay() != 6}
                ranges={[
                  {
                    label: "Next Week",
                    value:
                      moment().day() == 6
                        ? moment().add(1, "week").toDate()
                        : moment().day(6).add(1, "week").toDate(),
                    closeOverlay: true,
                  },
                  {
                    label: "2 Weeks",
                    value:
                      moment().day() == 6
                        ? moment().add(2, "week").toDate()
                        : moment().day(6).add(2, "week").toDate(),
                    closeOverlay: true,
                  },
                ]}
              ></DatePicker>
            </FormGroup>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(CustomerCreateDrawer);
