import React from "react";

const DashboardSectionHeader = ({ title, description }) => {
  return (
    <>
      <h2 className="text-dark mb-0">{title}</h2>
      {description ? <p className="text-muted m-0">{description}</p> : null}
    </>
  );
};

export default DashboardSectionHeader;
