"use client";

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import { TiptapEditorProps } from "./props";
import { TiptapExtensions } from "./extensions";
import useLocalStorage from "../../lib/hooks/use-local-storage";
import { useDebouncedCallback } from "use-debounce";
import DEFAULT_EDITOR_CONTENT from "./default-content";
import { EditorBubbleMenu } from "./components";
import { getPrevText } from "../../lib/editor";
import PubSub from "lib/PubSub";
import { Badge } from "reactstrap";
import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import StringUtils from "lib/StringUtils";

export default function Editor({
  id = "",
  onChange = null,
  value = null,
  editable = true,
  saving = false,
  autoSave = false,
  debounceRate = 100,
  onSave = null,
  allowTaskLists = false,
  padding = null,
  autofocus = false,
  allowForceHydration = false,
  cdnMode = "private",
}) {
  const [content, setContent] = useState({
    type: "doc",
    content: [{ type: "paragraph" }],
  });

  let subscriber = id ? id : StringUtils.uuid();
  const [hydrated, setHydrated] = useState(false);

  const editorRef = useRef();

  let editor = useEditor({
    extensions: TiptapExtensions(allowTaskLists),
    editorProps: TiptapEditorProps,
    onDestroy: (e) => {
      console.log(e);
    },
    onFocus: async ({ editor, event }) => {
      // The editor is focused.

      if (Capacitor.isNativePlatform()) {
        await Keyboard.setAccessoryBarVisible({ isVisible: true });

        Keyboard.show();
      }
    },
    onBlur: async ({ editor, event }) => {
      // The editor isn’t focused anymore.
      if (Capacitor.isNativePlatform()) {
        Keyboard.hide();
      }
    },
    onUpdate: (e) => {
      try {
        if (!editor) {
          return;
        }

        const json = editor.getJSON();
        const html = editor.getHTML();
        const raw = editor.getText();

        //console.log("ON UPDATE");

        setContent(json);

        if (typeof onChange === "function") {
          onChange({ json, html, text: raw });
        }
      } catch (e) {
        console.error(e);
      }

      debouncedUpdates(e);
    },
    autofocus: autofocus ? true : false,
  });

  useEffect(() => {
    //console.log("TOGGLE EDITABLE", editable);

    if (!editor) {
      return;
    }

    if (cdnMode) {
      editor.cdnMode = cdnMode;
    } else {
      editor.cdnMode = "private";
    }

    //console.log(editor);

    try {
      editor.setEditable(editable);
    } catch (e) {
      console.error(e);
    }
  }, [editable, editor]);

  const debouncedUpdates = useDebouncedCallback(async ({ editor }) => {
    const json = editor.getJSON();
    const html = editor.getHTML();
    const raw = editor.getText();

    if (typeof onSave === "function") {
      onSave({ json, html, text: raw });
    }
  }, debounceRate);

  useMemo(() => {
    if (subscriber) {
      PubSub.removeSubscriber("editor:contentUpdated", subscriber);
    }

    const sub = PubSub.subscribe(
      "editor:contentUpdated",
      (data) => {
        //console.log(data, hydrated);

        if (
          data?.event == "contentUpdated" &&
          data?.id == id &&
          data?.forceHydrate
        ) {
          setTimeout(() => {
            try {
              const valString = JSON.stringify(data?.data);
              const contentString = JSON.stringify(content);

              //console.log("FORCE HYDRATE", data?.data, content);

              if (valString != contentString) {
                setContent(data?.data?.json);

                if (editor && hydrated) {
                  if (!autoSave) {
                    //console.log("HERE HERE");

                    editor.commands.setContent(data?.data?.json);

                    if (typeof onChange == "function") {
                      onChange(data?.data);
                    }
                  }
                }
              }
            } catch (e) {
              console.error(e);
            }
          }, 10);
        }

        if (data?.event == "saveCompleted" && data?.id == id) {
          try {
            editor.commands.blur();
          } catch (e) {}
        }
      },
      subscriber
    );
  }, [typeof editor, hydrated]);

  useEffect(() => {
    let json = value?.json ? value?.json : null;

    if (!json) {
      json = { type: "doc", content: [{ type: "paragraph" }] };
    }

    const valString = JSON.stringify(json);
    const contentString = JSON.stringify(content);

    if (valString != contentString) {
      setContent(json);

      if (hydrated && allowForceHydration) {
        //console.log("FORCE");

        if (typeof onChange == "function") {
          onChange({ clear: true });
        }

        editor.commands.setContent(json);
      }
    }
  }, [value, content, allowForceHydration]);

  const prev = useRef("");

  // Hydrate the editor with the content from localStorage.
  useEffect(() => {
    if (editor && content && !hydrated) {
      queueMicrotask(() => {
        // console.log("MICRO");

        editor.commands.setContent(content);
      });

      setHydrated(true);
    }
  }, [editor, content, hydrated]);

  return (
    <div
      onClick={() => {
        editor.chain().focus().run();
      }}
      style={{ mineHeight: 400, position: "relative" }}
      className={`w-100 ${padding ? padding : "p-3"} bg-white`}
    >
      {editor && (
        <EditorBubbleMenu allowTaskLists={allowTaskLists} editor={editor} />
      )}
      <EditorContent
        cdnMode={cdnMode}
        editorContentRef={editorRef}
        editor={editor}
      />
    </div>
  );
}
