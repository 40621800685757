import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  BarChart,
  BarList,
  Card,
  CategoryBar,
  DeltaBar,
  DonutChart,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import {
  Axis,
  Chart,
  Coordinate,
  Interaction,
  Interval,
  Legend,
  Tooltip,
} from "bizcharts";
import ChartMetricHeader from "./ChartMetricHeader";

class MemberExperienceScoreByStepCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    tab: "absolute",
    steps: [
      {
        name: "LEAN Consultation (25 pts.)",
        key: "member_experience_score_avg_consult",
        points: 25,
      },
      {
        name: "Member Check-In (25 pts.)",
        key: "member_experience_score_avg_checkin",
        points: 25,
      },
      {
        name: "Week 1 Call Task (12.5 pts.)",
        key: "member_experience_score_avg_task_one",
        points: 12.5,
      },
      {
        name: "Week 3 Call Task (12.5 pts.)",
        key: "member_experience_score_avg_task_three",
        points: 12.5,
      },
      {
        name: "Subscription Order #1 (12.5 pts.)",
        key: "member_experience_score_avg_order_one",
        points: 12.5,
      },
      {
        name: "Subscription Order #2 (12.5 pts.)",
        key: "member_experience_score_avg_order_two",
        points: 12.5,
      },
    ],
  };

  /**
   * Fetches a summary of all the member data
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchData(cubejsApi, stores, dateRange, metricNames = []) {
    let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

    secondDifference = secondDifference / 1000;

    let granularity = secondDifference > 60 * 60 * 24 * 90 ? "week" : "day";

    if (secondDifference >= 60 * 60 * 24 * 364) {
      granularity = "month";
    }

    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["Metrics.totalEventsAverage"],
          order: {
            "Metrics.createdat": "asc",
          },
          timeDimensions: [
            {
              dimension: "Metrics.createdat",
              dateRange: [dateRange[0], dateRange[1]],
            },
          ],
          filters: [
            {
              member: "Metrics.metadatastoreid",
              operator: "equals",
              values: stores,
            },
            {
              member: "Metrics.name",
              operator: "equals",
              values: this.state.steps?.map((s) => s.key),
            },
          ],
          dimensions: ["Metrics.name"],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          if (!data?.length) {
            return resolve(null);
          }

          let out = {};

          for (let i = 0; i < data.length; i++) {
            const item = data[i];

            console.log(item);

            out[item["Metrics.name"]] = item["Metrics.totalEventsAverage"];
          }

          return resolve({
            metrics: out,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async _fetchReport(stores, dateRange) {
    this.setState({
      loading: true,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    if (!stores?.length) {
      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchData(cubejsApi, stores, dateRange);
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load new member quantity.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });
    }

    this.setState({
      currentCount,

      loading: false,
    });

    if (typeof this.props.onLoaded == "function") {
      this.props.onLoaded();
    }
  }

  /**
   * Load all report information
   *
   * @param {*} stores
   * @param {*} dateRange
   * @param {*} compare
   */
  async loadReport(stores, dateRange, compare) {
    this.setState({
      loading: true,
    });

    this._fetchReport(stores, dateRange);
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.stores != prevProps?.stores && this.props.stores?.length) ||
      this.props.dateRange != prevProps?.dateRange ||
      this.props.comparePrevious != prevProps.comparePrevious
    ) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }

    if (this.props.reload != prevProps.reload && this.props.reload) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  componentDidMount() {
    if (this.props.stores && this.props.dateRange) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }

    if (this.props.reload) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  getColor(value) {
    /*if (goalData.goalPercent < 50) {
              goalData.goalColor = "rose";
            } else if (goalData.goalPercent < 60) {
              goalData.goalColor = "orange";
            } else if (goalData.goalPercent < 70) {
              goalData.goalColor = "amber";
            } else if (goalData.goalPercent < 85) {
              goalData.goalColor = "yellow";
            } else if (goalData.goalPercent < 93) {
              goalData.goalColor = "lime";
            } else if (goalData.goalPercent >= 93) {
              goalData.goalColor = "emerald";
            }

     */

    if (value < 40) {
      return "rose";
    } else if (value < 45) {
      return "orange";
    } else if (value < 50) {
      return "amber";
    } else if (value < 55) {
      return "yellow";
    } else if (value < 60) {
      return "lime";
    } else if (value >= 60) {
      return "emerald";
    }
  }

  render() {
    return (
      <>
        <Card marginTop="mt-0">
          <ChartMetricHeader
            title="Member Experience Behaviors"
            description="Analyzing the behaviors and execution driving the member experience score."
            actions={
              <>
                <Button
                  size="sm"
                  outline
                  color="dark"
                  className="border-0 btn-icon-only"
                  disabled={this.state.loading}
                  onClick={() => {
                    this.loadReport(
                      this.props.stores,
                      this.props.dateRange,
                      this.props.comparePrevious
                    );
                  }}
                >
                  {this.state.loading ? (
                    <Spinner size="sm"></Spinner>
                  ) : (
                    <i className="mdi mdi-refresh"></i>
                  )}
                </Button>
              </>
            }
            loading={this.state.loading}
            dataAvailable={this.state.dataAvailable}
            comparisonMetric={null}
            dateRange={this.props.dateRange}
            deltaType={null}
            percentChange={null}
            showPercentChange={false}
          ></ChartMetricHeader>

          {this.state.loading ? (
            <div
              className="skeleton mt-3"
              style={{ height: "calc(327px + 1rem)", width: "100%" }}
            >
              &nbsp;
            </div>
          ) : (
            <>
              {this.state.dataAvailable ? (
                <>
                  <div className="mt-3 mx--4 px-4">
                    <div className="mb--4 pb-4">
                      {this.state.steps?.map((step, i) => (
                        <div
                          key={i}
                          className={`${i != 0 && "mt-4 border-top"} pt-3`}
                        >
                          <Row className="align-items-center mb-2">
                            <Col xs="12" sm="">
                              <p
                                className="mt-0 mb-0 text-dark font-weight-500"
                                style={{ fontSize: 16, lineHeight: 1.3 }}
                              >
                                {step?.name}
                              </p>
                            </Col>
                            <Col xs="12" sm="auto" className="mt-1 mt-sm-0">
                              <p className="text-dark font-weight-bold m-0">
                                Executed For{" "}
                                {StringUtils.numberFormat(
                                  parseFloat(
                                    (
                                      ((this.state.currentCount?.metrics[
                                        step?.key
                                      ]
                                        ? this.state.currentCount?.metrics[
                                            step?.key
                                          ]
                                        : 0) /
                                        step?.points) *
                                      100
                                    ).toFixed(1)
                                  )
                                )}
                                % Of Members
                              </p>
                            </Col>
                          </Row>
                          <ProgressBar
                            percentageValue={parseFloat(
                              ((this.state.currentCount?.metrics[step?.key]
                                ? this.state.currentCount?.metrics[step?.key]
                                : 0) /
                                step?.points) *
                                100
                            )}
                            color={this.getColor(
                              parseFloat(
                                ((this.state.currentCount?.metrics[step?.key]
                                  ? this.state.currentCount?.metrics[step?.key]
                                  : 0) /
                                  step?.points) *
                                  100
                              )
                            )}
                            showLabels={true}
                            showAnimation={true}
                            marginTop="mt-1"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "calc(327px + 1rem)", width: "100%" }}
                >
                  <p className="m-0">
                    No data available.
                    {this.state.error ? ` ${this.state.error}` : null}
                  </p>
                </div>
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default MemberExperienceScoreByStepCard;
