import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
  Collapse,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import _, { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import { Dropdown, Popover, Tooltip, Whisper } from "rsuite";
import swal from "sweetalert";
import LibraryCategoryEditDrawer from "components/Drawers/LibraryCategoryEditDrawer";
import LibraryModuleEditDrawer from "components/Drawers/LibraryModuleEditDrawer";
import LibraryAllModulesDrawer from "components/Drawers/LibraryAllModulesDrawer";
import { convertTypeAcquisitionFromJson } from "typescript";
import LibraryAllTopicsDrawer from "components/Drawers/LibraryAllTopicsDrawer";
import { Capacitor } from "@capacitor/core";

class LearningCenterHome extends React.Component {
  state = {
    active: 0,
    loadingCategories: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    openCategory: false,
  };

  constructor() {
    super();

    this.dropdownRef = React.createRef();
  }

  /**
   * Fetches all categories.
   */
  getCategories() {
    this.setState({
      loadingCategories: true,
    });

    if (LocalStorage.get("libraryCategories")) {
      let categories = LocalStorage.get("libraryCategories");

      categories = categories?.map((cat) => {
        return { ...cat, open: false };
      });

      this.setState({
        categories,
        loadingCategories: false,
      });
    }

    let loadInternal = this.isGlobal() || this.isProduction() ? true : false;

    APIV2.getLibraryCategories(loadInternal)

      .then(
        (data) => {
          this.setState(
            {
              categories: _.sortBy(
                data?.data?.categories?.map((cat) => {
                  return {
                    ...cat,
                    open: this.state.categories?.length
                      ? _.findWhere(this.state.categories, { _id: cat?._id })
                          ?.open
                        ? true
                        : false
                      : false,
                  };
                }),
                "order"
              ),
            },
            () => {
              LocalStorage.set("libraryCategories", this.state.categories);
            }
          );
        },
        () => {
          window.alert("Failed to get categories. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          loadingCategories: false,
        });
      });
  }

  /**
   * Event handler for after a category is created
   * @param {} category
   */
  onCreateCategory(category) {
    let categories = this.state.categories;

    categories.push(category);

    this.setState({ categories, openCategory: false });
  }

  /**
   * Event handler for when a module is added to a category
   *
   * @param {*} category
   * @param {*} module
   */
  onCreateCategoryModule(category, module) {
    let categories = this.state.categories;

    const idx = _.findIndex(categories, { _id: category?._id });

    if (idx >= 0) {
      categories[idx] = category;
    }

    this.setState({
      categories,
      openModule: false,
      modifyModule: null,
      modifyCategory: null,
    });
  }

  /**
   * Event handler for after a category is modified.
   *
   * @param {*} category
   */
  onModifyCategory(category) {
    let categories = this.state.categories;

    const idx = _.findIndex(categories, { _id: category?._id });

    if (idx >= 0) {
      categories[idx] = category;
    }

    this.setState({ categories, openCategory: false });
  }

  /**
   * Event handler for when module is modified
   *
   * @param {*} category
   */
  onModifyModule(module) {
    let categories = this.state.categories;

    for (let i = 0; i < categories?.length; i++) {
      const idx = _.findIndex(categories[i]?.modules, {
        moduleID: module?._id,
      });

      if (idx < 0) {
        continue;
      }

      categories[i].modules[idx].module = module;
    }

    this.setState({ categories, openModule: false });
  }

  /**
   * Handles deleting a category
   *
   * @param {*} category
   */
  deleteCategory(category) {
    swal({
      title: "Delete Category",
      text: "Are you sure you want delete this category?",
      icon: "warning",
      buttons: ["Nevermind", "Delete"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        deletingCategory: category?._id,
      });

      APIV2.deleteLibraryCategory(category?._id)
        .then(
          () => {
            let categories = this.state.categories;

            categories = _.filter(categories, (cat) => {
              return cat?._id != category?._id;
            });

            this.setState({ categories: _.sortBy(categories, "order") });
          },
          (e) => {
            alert("Unable to delete category. Please try again.");
          }
        )
        .finally(() => {
          this.setState({ deletingCategory: "" });
        });
    });
  }

  componentDidMount() {
    this.getCategories();
  }

  /**
   * Checks if the current user is a global user via permissions
   *
   * @returns
   */
  isGlobal() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  /**
   * Checks if the current user is a global user via permissions
   *
   * @returns
   */
  isProduction() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.productionPermissions?.length ? true : false;
  }

  /**
   * Helper function to handle reorder events from react-beautiful-dnd library
   *
   * @param {*} list
   * @param {*} startIndex
   * @param {*} endIndex
   * @returns
   */
  reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  /**
   * Event handler for when a category drag has ended.
   *
   * @param {*} result
   * @returns
   */
  onCategoryDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.categories,
      result.source.index,
      result.destination.index
    );

    this.setState({
      categories: items,
    });
  }

  onModuleDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.moduleOrder,
      result.source.index,
      result.destination.index
    );

    this.setState({
      moduleOrder: items,
    });
  }

  saveCategoryOrder() {
    if (!this.state.categories?.length) {
      this.setState({
        reorderCategories: false,
      });

      return;
    }

    this.setState({
      reorderCategoriesSubmitting: true,
    });

    let order = this.state.categories.map((category, i) => {
      return { categoryID: category?._id, order: i + 1 };
    });

    APIV2.reorderLibraryCategories(order)
      .then(
        (data) => {
          this.setState({
            categories: data.data?.categories,
            reorderCategories: false,
          });
        },
        (e) => {
          alert("Unable to save order of categories. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          reorderCategoriesSubmitting: false,
        });
      });
  }

  saveModuleOrder(category, modules) {
    if (!modules?.length) {
      this.setState({
        reorderModules: false,
      });

      return;
    }

    this.setState({
      reorderModulesSubmitting: true,
    });

    let order = modules.map((module, i) => {
      return { moduleID: module?.moduleID, order: i + 1 };
    });

    APIV2.reorderLibraryCategoryModules(category?._id, order)
      .then(
        (data) => {
          let categories = this.state.categories;

          const idx = _.findIndex(categories, (cat) => {
            return cat?._id == data.data.category?._id;
          });

          console.log(data.data.categories);

          if (idx >= 0) {
            categories[idx] = data.data.category;
          }

          this.setState({
            categories: _.sortBy(categories, "order"),
            reorderModules: false,
            reorderModulesSubmitting: false,
          });
        },
        (e) => {
          alert("Unable to save order of modules. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          reorderCategoriesSubmitting: false,
        });
      });
  }

  removeModuleFromCategory(category, module) {
    swal({
      title: "Remove Module From Category",
      text: "Are you sure you want remove this module from the category?",
      icon: "warning",
      buttons: ["Nevermind", "Remove"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        removingModule: module?._id,
      });

      APIV2.removeLibraryModuleFromCategory(category?._id, module?._id)
        .then(
          (data) => {
            let categories = this.state.categories;

            const idx = _.findIndex(categories, (cat) => {
              return cat?._id == data.data.category?._id;
            });

            if (idx >= 0) {
              categories[idx] = data.data.category;
            }

            this.setState({ categories: _.sortBy(categories, "order") });
          },
          (e) => {
            alert("Unable to remove module from category. Please try again.");
          }
        )
        .finally(() => {
          this.setState({ removingModule: "" });
        });
    });
  }

  submitPrompt(prompt) {
    this.setState({
      searching: true,
    });

    APIV2.ragSearchLibraryTopics(this.state.prompt)
      .then(
        (data) => {
          let response = data?.data?.response;

          this.setState({
            searchResponse: response,
          });
        },
        (e) => {
          this.setState({
            searchResponse: null,
            searchError: true,
          });
        }
      )
      .finally(() => {
        this.setState({
          searching: false,
        });
      });
  }

  render() {
    return (
      <>
        <div
          className="bg-white border-bottom px-4 py-4 mb-4"
          style={{ marginTop: "5rem" }}
        >
          {/* Page content */}
          {Capacitor.isNativePlatform() && (
            <div className="pt-5 d-md-none"></div>
          )}
          <Row className="align-items-center">
            <Col xs="12">
              <h2 className="m-0 text-dark">Learning Center</h2>
            </Col>
          </Row>
        </div>

        <Container fluid>
          {/* Table */}

          <Row className="items-center">
            <Col xs="6" sm="6" md="4" className="mb-4">
              <div
                className="bg-superlight p-3 rounded"
                style={{ height: 180 }}
              >
                <h2>Progress</h2>
              </div>
            </Col>
            <Col xs="6" sm="6" md="4" className="mb-4">
              <div
                className="bg-superlight p-3 rounded"
                style={{ height: 180 }}
              >
                <h2>Learning Level</h2>
              </div>
            </Col>
            <Col xs="12" sm="12" md="4" className="mb-4">
              <div
                className="bg-superlight p-3 rounded"
                style={{ height: 180 }}
              >
                <h2>Certificates</h2>
              </div>
            </Col>
          </Row>
          <div
            className="bg-superlight rounded mb-4 p-3"
            style={{ height: 400 }}
          >
            <h2>Learning Tracks</h2>
          </div>
          <div
            className="bg-superlight rounded mb-4 p-3"
            style={{ height: 400 }}
          >
            <h2>Continued Education</h2>
          </div>
        </Container>
        <LibraryCategoryEditDrawer
          open={this.state.openCategory}
          category={this.state.modifyCategory}
          onCreate={this.onCreateCategory.bind(this)}
          onModify={this.onModifyCategory.bind(this)}
          onClose={() => {
            this.setState({ openCategory: false });
          }}
        ></LibraryCategoryEditDrawer>
        <LibraryModuleEditDrawer
          open={this.state.openModule}
          category={this.state.modifyCategory}
          module={this.state.modifyModule}
          onCreate={(category, module) => {
            this.onCreateCategoryModule(category, module);
          }}
          onModify={(module) => {
            this.onModifyModule(module);
          }}
          onClose={() => {
            this.setState({ openModule: false });
          }}
        ></LibraryModuleEditDrawer>
        <LibraryAllModulesDrawer
          open={this.state.openAllModules}
          onClose={() => {
            this.setState({ openAllModules: false });
          }}
        ></LibraryAllModulesDrawer>
        <LibraryAllTopicsDrawer
          open={this.state.openAllTopics}
          onClose={() => {
            this.setState({ openAllTopics: false });
          }}
        ></LibraryAllTopicsDrawer>
      </>
    );
  }
}

export default LearningCenterHome;
