import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  BarChart,
  Card,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import {
  Axis,
  Chart,
  Coordinate,
  Interaction,
  Interval,
  Legend,
  Tooltip,
} from "bizcharts";
import ChartMetricHeader from "./ChartMetricHeader";

class StoreLeaderboardOrderSummaryChartCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    tab: "performanceTrend",
  };

  /**
   * Fetches the order goal data
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchOrderGoalData(cubejsApi, stores, dateRange) {
    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: [
            "SubscriptionFulfillmentReports.goalOrderCount",
            "SubscriptionFulfillmentReports.percentGoalOrders",
            "SubscriptionFulfillmentReports.avgFulfilledCount",
          ],
          order: {
            "SubscriptionFulfillmentReports.percentGoalOrders": "desc",
          },

          timeDimensions: [
            {
              dimension: "SubscriptionFulfillmentReports.fulfillmentdate",
              dateRange: [dateRange[0], dateRange[1]],
            },
          ],
          dimensions: [
            "Stores.name",
            "Stores.locationAddressCity",
            "Stores.locationAddressState",
          ],
          filters: [
            {
              member: "SubscriptionFulfillmentReports.storeid",
              operator: "equals",
              values: stores,
            },
            {
              member: "SubscriptionFulfillmentReports.fulfillmentdate",
              operator: "beforeDate",
              values: [moment().toISOString()],
            },
            {
              member: "SubscriptionFulfillmentReports.goalOrderCount",
              operator: "set",
            },
            {
              member: "Stores.type",
              operator: "equals",
              values: ["retail"],
            },
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          data = data.map((item) => {
            return {
              totalGoalCount:
                item["SubscriptionFulfillmentReports.goalOrderCount"],
              avgFulfilledCount:
                item["SubscriptionFulfillmentReports.avgFulfilledCount"],
              storeName: item["Stores.name"]
                ?.replace("Project LeanNation", "PLN")
                ?.trim(),
              goalPercent: parseFloat(
                item[
                  "SubscriptionFulfillmentReports.percentGoalOrders"
                ].toFixed(1)
              ),
              location: item["Stores.locationAddressCity"]
                ? `${item["Stores.locationAddressCity"]?.trim()}, ${item[
                    "Stores.locationAddressState"
                  ]?.trim()}`
                : "",
            };
          });

          for (let i = 0; i < data?.length; i++) {
            let item = data[i];

            if (item.goalPercent < 50) {
              item.goalColor = "rose";
            } else if (item.goalPercent < 60) {
              item.goalColor = "orange";
            } else if (item.goalPercent < 70) {
              item.goalColor = "amber";
            } else if (item.goalPercent < 85) {
              item.goalColor = "yellow";
            } else if (item.goalPercent < 93) {
              item.goalColor = "lime";
            } else if (item.goalPercent >= 93) {
              item.goalColor = "emerald";
            }
          }

          return resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async loadDetailedReport(stores, dateRange) {
    this.setState({
      loading: true,
      dataAvailable: false,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!stores?.length || !dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    let currentCount = null;

    let topPerformer = null;
    let biggestMover = null;
    let mostOpportunity = null;

    try {
      currentCount = await this._fetchOrderGoalData(
        cubejsApi,
        stores,
        dateRange
      );

      if (currentCount?.length) {
        topPerformer = currentCount[0];
        mostOpportunity = currentCount[currentCount?.length - 1];
      }
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load store order leaderboard summary.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });

      return;
    }

    let previousCount = null;

    let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

    secondDifference = secondDifference / 1000;

    let startMoment = moment(dateRange[0].toISOString());
    let endMoment = moment(dateRange[1].toISOString());

    startMoment.subtract(secondDifference, "seconds");
    endMoment.subtract(secondDifference + 1, "seconds");

    try {
      previousCount = await this._fetchOrderGoalData(cubejsApi, stores, [
        startMoment.toDate(),
        endMoment.toDate(),
      ]);
    } catch (e) {}

    if (previousCount !== null) {
      for (let i = 0; i < currentCount?.length; i++) {
        let item = currentCount[i];

        item.delta = null;

        console.log(item); //debug

        if (_.findWhere(previousCount, { storeName: item?.storeName })) {
          const prev = _.findWhere(previousCount, {
            storeName: item?.storeName,
          });

          if (prev.goalPercent !== null) {
            item.delta =
              Math.abs(item.goalPercent - prev.goalPercent) *
              (item?.goalPercent >= prev.goalPercent ? 1 : -1);

            item.delta = parseFloat(item.delta.toFixed(1));
          }
        }
      }

      currentCount = _.filter(currentCount, (item) => {
        return item.delta !== null;
      });

      currentCount = _.sortBy(currentCount, "delta");

      if (currentCount?.length) {
        biggestMover = currentCount[currentCount?.length - 1];
      }
    }

    this.setState({
      topPerformer,
      biggestMover,
      mostOpportunity,
      loading: false,
    });

    if (typeof this.props.onLoaded == "function") {
      this.props.onLoaded();
    }
  }

  getColorOfGoal(percent) {
    if (!percent || percent < 60) {
      return "text-danger";
    }

    if (percent < 85) {
      return "text-warning";
    }

    return "text-success";
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stores != prevProps?.stores ||
      this.props.dateRange != prevProps?.dateRange
    ) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload != prevProps.reload && this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  componentDidMount() {
    if (this.props.store && this.props.dateRange) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  render() {
    return (
      <>
        <Card className="mt-0 h-full">
          <Row className="align-items-center">
            <Col xs="">
              <h3 className="m-0 text-dark">Member Order Summary</h3>
            </Col>
            <Col xs="auto">
              <Button
                size="sm"
                outline
                color="dark"
                className="border-0 btn-icon-only"
                disabled={this.state.loading}
                onClick={() => {
                  this.loadDetailedReport(
                    this.props.stores,
                    this.props.dateRange
                  );
                }}
              >
                {this.state.loading ? (
                  <Spinner size="sm"></Spinner>
                ) : (
                  <i className="mdi mdi-refresh"></i>
                )}
              </Button>
            </Col>
          </Row>

          {this.state.loading ? (
            <div
              className="skeleton mt-3"
              style={{ height: "298px", width: "100%" }}
            >
              &nbsp;
            </div>
          ) : (
            <>
              {this.state.dataAvailable ? (
                <>
                  <div className="mx--4 mt-3">
                    <div className="px-4 py-3 border-top border-bottom">
                      <div className="mb-2">
                        <h4 className="m-0 text-muted font-weight-500">
                          Top Performer
                        </h4>
                      </div>
                      <Row className="align-items-center">
                        <Col xs="auto" className="pr-0">
                          <img
                            src={require("../../../assets/img/icons/common/top_performer.svg")}
                            style={{ height: 40, width: 40 }}
                            alt="top performer"
                          ></img>
                        </Col>
                        <Col
                          xs=""
                          style={{ maxWidth: "calc(100% - 147px)" }}
                          className="pl-2"
                        >
                          <h2
                            className="m-0 text-truncate"
                            style={{ lineHeight: 1.3 }}
                          >
                            {this.state.topPerformer
                              ? this.state.topPerformer?.storeName
                              : "--"}
                          </h2>
                          <p
                            className="m-0 text-muted text-truncate small"
                            style={{ lineHeight: 1.2 }}
                          >
                            {this.state.topPerformer
                              ? this.state.topPerformer?.location
                              : "Not available."}
                          </p>
                        </Col>
                        <Col
                          className="pl-0 text-right"
                          xs="auto"
                          style={{ width: 91 }}
                        >
                          <h2
                            className="m-0 text-success"
                            style={{ lineHeight: 1.3 }}
                          >
                            {this.state.topPerformer
                              ? `~${this.state.topPerformer?.goalPercent}%`
                              : "--"}
                          </h2>
                          <p
                            className="m-0 text-muted small"
                            style={{ lineHeight: 1.2 }}
                          >
                            avg. of goal
                          </p>
                        </Col>
                      </Row>
                    </div>
                    <div className="px-4 py-3 border-bottom">
                      <div className="mb-2">
                        <h4 className="m-0 text-muted font-weight-500">
                          Biggest Mover
                        </h4>
                      </div>
                      <Row className="align-items-center">
                        <Col xs="auto" className="pr-0">
                          <img
                            src={require("../../../assets/img/icons/common/biggest_mover.svg")}
                            style={{ height: 40, width: 40 }}
                            alt="top performer"
                          ></img>
                        </Col>
                        <Col
                          xs=""
                          style={{ maxWidth: "calc(100% - 147px)" }}
                          className="pl-2"
                        >
                          <h2
                            className="m-0 text-truncate"
                            style={{ lineHeight: 1.3 }}
                          >
                            {this.state.biggestMover
                              ? this.state.biggestMover?.storeName
                              : "--"}
                          </h2>
                          <p
                            className="m-0 text-muted text-truncate small"
                            style={{ lineHeight: 1.2 }}
                          >
                            {this.state.biggestMover
                              ? this.state.biggestMover?.location
                              : "Not available."}
                          </p>
                        </Col>
                        <Col
                          className="pl-0 text-right"
                          xs="auto"
                          style={{ width: 91 }}
                        >
                          <h2
                            className={`m-0 text-success ${
                              this.state.biggestMover?.delta >= 0
                                ? "text-success"
                                : "text-danger"
                            }`}
                            style={{ lineHeight: 1.3 }}
                          >
                            {this.state.biggestMover
                              ? `${
                                  this.state.biggestMover?.delta >= 0 ? "+" : ""
                                }${this.state.biggestMover?.delta}%`
                              : "--"}
                          </h2>
                          <p
                            className="m-0 text-muted text-truncate small"
                            style={{ lineHeight: 1.2 }}
                          >
                            vs. previous
                          </p>
                        </Col>
                      </Row>
                    </div>
                    <div className="px-4 pt-3">
                      <div className="mb-2">
                        <h4 className="m-0 text-muted font-weight-500">
                          Most Opportunity
                        </h4>
                      </div>
                      <Row className="align-items-center">
                        <Col xs="auto" className="pr-0">
                          <img
                            src={require("../../../assets/img/icons/common/most_opportunity.svg")}
                            style={{ height: 40, width: 40 }}
                            alt="top performer"
                          ></img>
                        </Col>
                        <Col
                          xs=""
                          style={{ maxWidth: "calc(100% - 147px)" }}
                          className="pl-2"
                        >
                          <h2
                            className="m-0 text-truncate"
                            style={{ lineHeight: 1.3 }}
                          >
                            {this.state.mostOpportunity
                              ? this.state.mostOpportunity?.storeName
                              : "--"}
                          </h2>
                          <p
                            className="m-0 text-muted small"
                            style={{ lineHeight: 1.2 }}
                          >
                            {this.state.mostOpportunity
                              ? this.state.mostOpportunity?.location
                              : "Not available."}
                          </p>
                        </Col>
                        <Col
                          className="pl-0 text-right"
                          xs="auto"
                          style={{ width: 91 }}
                        >
                          <h2
                            className={`m-0 ${this.getColorOfGoal(
                              this.state.mostOpportunity?.goalPercent
                            )}`}
                            style={{ lineHeight: 1.3 }}
                          >
                            {this.state.mostOpportunity
                              ? `~${this.state.mostOpportunity?.goalPercent}%`
                              : "--"}
                          </h2>
                          <p
                            className="m-0 text-muted small"
                            style={{ lineHeight: 1.2 }}
                          >
                            avg. of goal
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "calc(14rem)", width: "100%" }}
                >
                  <p className="m-0">
                    No data available.
                    {this.state.error ? ` ${this.state.error}` : null}
                  </p>
                </div>
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default StoreLeaderboardOrderSummaryChartCard;
