import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { DatePicker } from "rsuite";
import moment from "moment";

class CustomerNextBillDateWidget extends React.Component {
  state = {
    nextBillDate: null,
  };

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 4000);
  }

  setNextBillDate(nbd) {
    this.setState({
      submitting: true,
    });

    APIV2.updateCustomerNextBillDate(
      this.props.customer.storeID,
      this.props.customer._id,
      nbd
    )
      .then(
        (data) => {
          PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);

          this.setState({
            modify: false,
            nextBillDate: null,
          });
        },
        (err) => {
          this.setError(
            "error",
            "Unable to update customer's next bill date. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  renderNextBillDate(d) {
    let mom = moment(d);

    return mom.format("MMM Do YYYY");
  }

  render() {
    return (
      <>
        <Card
          style={{ height: "100%" }}
          className={`${this.props.flat ? "border-0" : "border shadow"}`}
        >
          <CardHeader>
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Next Bill Date</h3>
              </div>
              <Col xs="auto">
                {this.state.modify ? (
                  <>
                    <Button
                      size="sm"
                      color="secondary"
                      outline
                      onClick={() => {
                        this.setState({
                          modify: false,
                          nextBillDate: null,
                        });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="sm"
                      color="primary"
                      disabled={
                        !this.state.nextBillDate || this.state.submitting
                      }
                      onClick={() => {
                        this.setNextBillDate(
                          moment(this.state.nextBillDate.toString()).format(
                            "YYYY-MM-DD"
                          )
                        );
                      }}
                    >
                      {this.state.submitting ? (
                        <Spinner color="white" size="sm" />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </>
                ) : (
                  <Button
                    size="sm"
                    color="secondary"
                    outline
                    onClick={() => {
                      this.setState({
                        modify: true,
                        nextBillDate: this.props.customer?.subscription
                          ?.nextBillDate
                          ? moment(
                              this.props.customer?.subscription?.nextBillDate
                            ).toDate()
                          : null,
                      });
                    }}
                  >
                    Edit
                  </Button>
                )}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row className="align-items-center">
              {!this.state.modify ? (
                <Col xs="" className="">
                  <h3 className="mb-0">
                    {this.renderNextBillDate(
                      this.props.customer?.subscription?.nextBillDate
                    )}
                  </h3>
                </Col>
              ) : (
                <Col xs="" className="">
                  <DatePicker
                    oneTap
                    required
                    block
                    placeholder="Select A Date"
                    defaultValue={moment().day(6).toDate()}
                    value={this.state.nextBillDate}
                    onChange={(e) => {
                      this.setState({ nextBillDate: e });
                    }}
                    disabledDate={(date) =>
                      date < new Date() || date.getDay() != 6
                    }
                    ranges={[
                      {
                        label: "Next Week",
                        value:
                          moment().day() == 6
                            ? moment().add(1, "week").toDate()
                            : moment().day(6).add(1, "week").toDate(),
                        closeOverlay: true,
                      },
                      {
                        label: "2 Weeks",
                        value:
                          moment().day() == 6
                            ? moment().add(2, "week").toDate()
                            : moment().day(6).add(2, "week").toDate(),
                        closeOverlay: true,
                      },
                    ]}
                  ></DatePicker>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default withRouter(CustomerNextBillDateWidget);
