import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  BarChart,
  BarList,
  Card,
  DonutChart,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Drawer, Popover, SelectPicker, Whisper } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import {
  Axis,
  Chart,
  Coordinate,
  Interaction,
  Interval,
  Legend,
  Tooltip,
} from "bizcharts";
import { Tooltip as RTooltip } from "rsuite";
import ChartMetricHeader from "./ChartMetricHeader";
import APIV2 from "lib/APIV2";

class StoreDailyInventorySKUAveragePieChartCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    tab: "performanceTrend",
    colorKeys: {
      Lifestyle: "emerald",
      Athlete: "orange",
      "Lean Cheat": "yellow",
    },
    skuSelector: "active",
    tab: "All",
    categories: ["Lifestyle", "Athlete", "Lean Cheat"],
    colors: ["emerald", "orange", "yellow"],
  };

  async getAllMeals() {
    APIV2.getMeals()
      .then((data) => {
        this.setState(
          {
            meals: data?.data?.meals,
          },
          () => {
            this.loadDetailedReport(
              this.props.stores,
              this.props.dateRange,
              this.state.tab
            );
          }
        );
      })
      .catch((e) => {
        console.error(e);
      });
  }

  /**
   * Fetches the order data by week for the given stores & date range
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchOrderDataByWeek(cubejsApi, stores, dateRange) {
    return new Promise((resolve, reject) => {
      let endDate6 = moment(dateRange[1]);

      endDate6.endOf("day");

      let now = moment();

      if (endDate6.isAfter(now)) {
        endDate6 = now;
      }

      let filters = [
        {
          member: "StoreClosingInventoryReportItems.storeid",
          operator: "equals",
          values: stores,
        },
      ];

      if (this.state.skuSelector == "active" && this.state.meals?.length) {
        const skus = this.state.meals.map((meal) => {
          return meal?.sku;
        });

        filters.push({
          member: "StoreClosingInventoryReportItems.items_sku",
          operator: "equals",
          values: skus,
        });
      }

      if (this.state.skuSelector == "retired" && this.state.meals?.length) {
        const skus = this.state.meals.map((meal) => {
          return meal?.sku;
        });

        filters.push({
          member: "StoreClosingInventoryReportItems.items_sku",
          operator: "notEquals",
          values: skus,
        });

        filters.push({
          member: "StoreClosingInventoryReportItems.items_quantity",
          operator: "gt",
          values: [0],
        });
      }

      // Load
      cubejsApi
        .load({
          measures: ["StoreClosingInventoryReportItems.items_quantityaverage"],
          order: {
            "StoreClosingInventoryReportItems.items_quantityaverage": "desc",
          },

          timeDimensions: [
            {
              dimension: "StoreClosingInventoryReportItems.date",
              dateRange: [moment(dateRange[0]).toDate(), endDate6?.toDate()],
            },
          ],
          filters: filters,
          dimensions: [
            "Stores.name",
            "StoreClosingInventoryReportItems.items_sku",
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          console.log("DATA", data);

          if (!data?.length) {
            return resolve(null);
          }

          let totalCount = 0;

          data = data.map((item) => {
            let meal = _.findWhere(this.state.meals, {
              sku: item["StoreClosingInventoryReportItems.items_sku"],
            });

            let planType = "";

            if (meal) {
              if (_.findWhere(meal?.categories, { name: "Lean Cheats" })) {
                planType = "Lean Cheats";
              } else if (meal?.planType?.name == "Lifestyle") {
                planType = "Lifestyle";
              } else if (meal?.planType?.name == "Athlete") {
                planType = "Athlete";
              }
            }

            totalCount += Math.round(
              item["StoreClosingInventoryReportItems.items_quantityaverage"]
            );

            return {
              storeName: item["Stores.name"]
                ?.replace("Project LeanNation", "PLN")
                ?.trim(),
              sku: item["StoreClosingInventoryReportItems.items_sku"],
              quantity: Math.round(
                item["StoreClosingInventoryReportItems.items_quantityaverage"]
              ),
              planType,
            };
          });

          if (this.state.tab == "Lean Cheats") {
            data = data.filter((item) => {
              return item.planType == "Lean Cheats";
            });
          } else if (this.state.tab == "Lifestyle") {
            data = data.filter((item) => {
              return item.planType == "Lifestyle";
            });
          } else if (this.state.tab == "Athlete") {
            data = data.filter((item) => {
              return item.planType == "Athlete";
            });
          }

          let overall = [];

          for (let i = 0; i < data.length; i++) {
            const idx = overall.findIndex((item) => {
              return item.key == data[i].sku;
            });

            if (idx == -1) {
              overall.push({
                key: data[i].sku,
                Quantity: data[i].quantity,
              });
            } else {
              overall[idx].Quantity += data[i].quantity;
            }
          }

          let colors = StringUtils.randomChartColorSet(overall?.length);

          for (let i = 0; i < overall?.length; i++) {
            overall[i].color = colors[i];
          }

          let outByStore2 = [];

          for (let i = 0; i < data.length; i++) {
            const idx = outByStore2.findIndex((item) => {
              return item.storeName == data[i].storeName;
            });

            const ov = overall.findIndex((item) => {
              return item.key == data[i].sku;
            });

            const color = overall[ov]?.color;

            if (idx == -1) {
              outByStore2.push({
                storeName: data[i].storeName,
                totalQuantity: data[i].quantity,
                skus: [
                  {
                    key: data[i].sku,
                    quantity: data[i].quantity,
                    color: color,
                  },
                ],
              });
            } else {
              outByStore2[idx].skus.push({
                key: data[i].sku,
                quantity: data[i].quantity,
                color: color,
              });

              outByStore2[idx].totalQuantity += data[i].quantity;
            }
          }

          for (let i = 0; i < outByStore2.length; i++) {
            outByStore2[i].skus = _.sortBy(outByStore2[i].skus, "key");
          }

          let out = [];
          //let overall = [{key: 'Lifestyle', Quantity: lifestyleCount, Value: lifestyleValue, Cost: lifestyleCost, color: 'emerald'}, {key: 'Athlete', Quantity: athleteCount, Value: athleteValue, Cost: athleteCost, color: 'orange'}, {key: 'Lean Cheats', Quantity: leancheatCount, Value: leancheatValue, Cost: leancheatCost, color: 'yellow'}];

          

          outByStore2 = _.sortBy(outByStore2, "totalQuantity");

          outByStore2.reverse();

          overall = _.sortBy(overall, "Quantity");

          overall.reverse();

          console.log(overall);

          return resolve({
            byStore: outByStore2,
            total: totalCount,
            overall,
          });
        })
        .catch((e) => {
          console.error(e);

          reject(e);
        });
    });
  }

  async loadDetailedReport(stores, dateRange) {
    this.setState({
      loading: true,
      dataAvailable: false,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!stores?.length || !dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchOrderDataByWeek(
        cubejsApi,
        stores,
        dateRange
      );
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load meal quantities.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });

      return;
    }

    this.setState({
      currentCount: currentCount,
      loading: false,
      byStore: currentCount?.byStore,
      overall: currentCount?.overall,
      total: currentCount?.total,
      totalCost: currentCount?.totalCost,
      totalValue: currentCount?.totalValue,
      colors: currentCount?.overall?.map((item) => {
        return item.color;
      }),
    });

    if (typeof this.props.onLoaded == "function") {
      this.props.onLoaded();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stores != prevProps?.stores ||
      this.props.dateRange != prevProps?.dateRange
    ) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload != prevProps.reload && this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  getColorForPlan(key) {
    let item = _.findWhere(this.state.overall, { planReadableName: key });

    return StringUtils.CHART_COLOR_CODES[item.color];
  }

  getStoreTotal(item, type) {
    let total = 0;

    let keys = _.keys(item);

    for (let i = 0; i < keys?.length; i++) {
      if (
        keys[i] == "storeName" ||
        keys[i] == "color" ||
        keys[i] == "Quantity" ||
        isNaN(item[keys[i]]) ||
        !keys[i].includes(type)
      ) {
        continue;
      }

      console.log(keys[i]);

      total += item[keys[i]];
    }

    console.log(total);

    return total;
  }

  componentDidMount() {
    this.getAllMeals();

    if (this.props.store && this.props.dateRange) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  render() {
    return (
      <>
        <Card marginTop="mt-0">
          <Row className="align-items-center">
            <Col xs="">
              <h3 className="m-0 text-dark">Average Daily Inventory By SKU</h3>
            </Col>
            <Col xs="auto">
              <SelectPicker
                className="mr-2"
                size="sm"
                style={{ width: 200 }}
                data={[
                  { label: "On Menu SKUs", value: "active" },
                  { label: "Retired SKUs", value: "retired" },
                  { label: "All SKUs", value: "all" },
                ]}
                loading={!this.state.meals?.length}
                value={this.state.skuSelector}
                onChange={(val) => {
                    let tab = this.state.tab;

                    if (val == 'retired') {
                        tab = 'All';
                    }

                  this.setState(
                    {
                      skuSelector: val,
                        tab
                    },
                    () => {
                      this.loadDetailedReport(
                        this.props.stores,
                        this.props.dateRange,
                        this.state.tab
                      );
                    }
                  );
                }}
                cleanable={false}
                searchable={false}
              ></SelectPicker>
              <Button
                size="sm"
                outline
                color="dark"
                className="border-0 btn-icon-only"
                disabled={this.state.loading}
                onClick={() => {
                  this.loadDetailedReport(
                    this.props.stores,
                    this.props.dateRange
                  );
                }}
              >
                {this.state.loading ? (
                  <Spinner size="sm"></Spinner>
                ) : (
                  <i className="mdi mdi-refresh"></i>
                )}
              </Button>
            </Col>
          </Row>
          <TabList
                    color="orange"
                    defaultValue="All"
                    handleSelect={(value) => {
                      this.setState({
                        tab: value,
                      }, () => {
                        this.loadDetailedReport(
                          this.props.stores,
                          this.props.dateRange,
                          this.state.tab
                        );
                      });
                    }}
                    marginTop="mt-3"
                    disabled={this.state.loading || !this.state.dataAvailable}
                  >
                    <Tab value="All" text="All Meals" />
                    {
                        this.state.skuSelector != 'retired' && (
                            <>
                            <Tab value="Lifestyle" text="Lifestyle Meals" />
                    <Tab value="Athlete" text="Athlete Meals" />
                    <Tab value="Lean Cheats" text="Lean Cheats" />
                            </>
                        )
                    }
                  </TabList>
          {this.state.loading ? (
            <div
              className="skeleton mt-4"
              style={{ height: "calc(320px + 1rem)", width: "100%" }}
            >
              &nbsp;
            </div>
          ) : (
            <>
              {this.state.dataAvailable ? (
                <>
                  
                  <div className="mt-4 mx--4 px-4">
                    <Row className="align-items-center">
                      <Col xs="12" sm="12" md="6">
                        <div className="pt-4">
                          <DonutChart
                            data={this.state.overall}
                            category={"Quantity"}
                            dataKey={"key"}
                            colors={this.state.overall?.map(
                              (item) => item.color
                            )}
                            valueFormatter={(number) => {
                                

                              return `${StringUtils.numberFormat(number)} Units [${StringUtils.numberFormat(
                                parseFloat(
                                  (
                                    (number /
                                    this.state.total) *
                                    100
                                  ).toFixed(1)
                                )
                              )}%]`;
                            }}
                            height="h-80"
                          />
                        </div>
                      </Col>
                      <Col md="6" className="mt-3 mt-md-0">
                        <div
                          className="pt-4 mb--4 pb-4"
                          style={{
                            maxHeight: 368,
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          {this.state.byStore?.map((item, i) => (
                            <>
                              <div className="mb-3" key={i}>
                                <h3 className="text-dark mb-1">
                                  {item?.storeName}
                                </h3>

                                <div className="text-dark small font-weight-medium">
                                  {StringUtils.numberFormat(
                                    Math.round(item["totalQuantity"])
                                  )}{" "}
                                  Units Average
                                </div>
                                <div
                                  style={{
                                    borderRadius: 6,
                                    width: "100%",
                                    height: 20,
                                    overflow: "hidden",
                                  }}
                                  className="mb-2 border border-superlight"
                                >
                                  {item?.skus.map((sku, j) => (
                                    <div
                                      style={{
                                        height: 20,
                                        width: `${
                                          (sku.quantity /
                                            item[`totalQuantity`]) *
                                          100
                                        }%`,
                                        background:
                                          StringUtils.CHART_COLOR_CODES[
                                            sku.color
                                          ],
                                      }}
                                      className="d-inline-block cursor-pointer"
                                      key={j}
                                    >
                                      <Whisper
                                        trigger="hover"
                                        placement="auto"
                                        preventOverflow={true}
                                        speaker={
                                          <Popover full className="">
                                            <div className="p-2">
                                              <h4 className="mb-1 text-dark">
                                                {item.storeName}
                                              </h4>
                                              <p className="m-0 text-dark">
                                                <span className="font-weight-bold">
                                                  {sku.key}:
                                                </span>
                                                <span>
                                                  &nbsp;
                                                  {StringUtils.numberFormat(
                                                    Math.round(sku.quantity)
                                                  )}{" "}
                                                  Units [
                                                  {(
                                                    (sku.quantity /
                                                      item.totalQuantity) *
                                                    100
                                                  ).toFixed(1)}
                                                  %]
                                                </span>
                                              </p>
                                            </div>
                                          </Popover>
                                        }
                                      >
                                        <div
                                          style={{
                                            height: 20,
                                            width: "100%",
                                          }}
                                        ></div>
                                      </Whisper>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div className="my-2 border border-midlighter"></div>
                            </>
                          ))}

                          {/**
                           * <BarList
                            data={this.state.byStore?.map((item) => {
                              return {
                                name: item?.storeName,
                                value: parseFloat(
                                  (
                                    (item?.weekly / item?.Quantity) *
                                    100
                                  ).toFixed(1)
                                ),
                              };
                            })}
                            valueFormatter={(number) => {
                              return `${StringUtils.numberFormat(
                                number
                              )}% weekly, ${StringUtils.numberFormat(
                                100 - number
                              )}% biweekly`;
                            }}
                            color="emerald"
                            showAnimation={true}
                            marginTop="mt-0"
                          />
                           */}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "calc(320px + 1rem)", width: "100%" }}
                >
                  <p className="m-0">
                    No data available.
                    {this.state.error ? ` ${this.state.error}` : null}
                  </p>
                </div>
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default StoreDailyInventorySKUAveragePieChartCard;
