import { P } from "@antv/g2plot";
import { Capacitor } from "@capacitor/core";
import moment from "moment";
import { Filesystem } from "@capacitor/filesystem";
import { isValidPhoneNumber } from "libphonenumber-js";

export default class StringUtils {
  static CHART_COLORS = [
    "slate",
    "gray",
    "zinc",
    "neutral",
    "stone",
    "red",
    "orange",
    "amber",
    "yellow",
    "lime",
    "green",
    "emerald",
    "teal",
    "cyan",
    "sky",
    "blue",
    "indigo",
    "violet",
    "purple",
    "fuschia",
    "pink",
    "rose",
  ];

  static CHART_COLOR_CODES = {
    slate: "#64748b",
    gray: "#6b7280",
    zinc: "#71717a",
    neutral: "#737373",
    stone: "#78716c",
    red: "#ef4444",
    orange: "#f97316",
    amber: "#f59e0b",
    yellow: "#eab308",
    lime: "#84cc16",
    green: "#22c55e",
    emerald: "#10b981",
    teal: "#14b8a6",
    cyan: "#06b6d4",
    sky: "#0ea5e9",
    blue: "#3b82f6",
    indigo: "#6366f1",
    violet: "#8b5cf6",
    purple: "#a855f7",
    fuchsia: "#d946ef",
    pink: "#ec4899",
    rose: "#f43f5e",
  };

  static centsToCurrency(cents) {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(cents / 100); /* $2,500.00 */
  }

  static removeURLParameters(url) {
    // Regular expression to match the query string part of the URL
    const regex = /\?.*$/;
    // Replace the matched query string with an empty string
    return url.replace(regex, "");
  }

  static numberFormat(num) {
    var formatter = new Intl.NumberFormat("en-US");

    return formatter.format(num);
  }

  static parseURLsFromText(text = "") {
    let regex = /(http|https|ftp):\/\/(\S*)/gi;

    let urls = text.match(regex);

    return urls;
  }

  static couponValueString(c) {
    if (c.couponUsage == "tiered") {
      if (!c?.couponTiers?.length) {
        return "";
      }

      let values = [];

      for (let i = 0; i < c?.couponTiers?.length; i++) {
        const tier = c?.couponTiers[i];

        let discount = 0;

        if (tier.discountType == "Flat") {
          discount = tier.flatDiscount ? tier.flatDiscount : 0;

          values.push(
            `${StringUtils.centsToCurrency(discount)} (${
              tier.maxNumberUses
            } Uses)`
          );
        } else {
          discount = tier.percentDiscount ? tier.percentDiscount * 100 : 0;

          values.push(`${discount}% (${tier.maxNumberUses} Orders)`);
        }
      }

      return values.join(", ") + " OFF";
    } else {
      let discountValue = "";

      if (c.discountType == "Flat") {
        discountValue = StringUtils.centsToCurrency(c.flatDiscount);
      }

      if (c.discountType == "Percent") {
        discountValue = c.percentDiscount * 100 + "%";
      }

      return (
        discountValue +
        " OFF" +
        (c?.removeFromSubscriptionAfterUses > 1
          ? ` (${c?.removeFromSubscriptionAfterUses} Orders)`
          : "") +
        (c.removeFromSubscriptionAfterUses == -1 ? " (Recurring)" : "")
      );
    }
  }

  static stripHTML(html) {
    if (!html) {
      return "";
    }

    return html.replace(/<[^>]+>/g, "");
  }

  static uuid() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }

  static removeLinks(text) {
    // This regex matches URLs starting with http, https, www, and without any protocol
    const urlPattern =
      /(https?:\/\/\S+|www\.\S+|\b\S+\.(com|org|net|edu|gov|mil|int)\b)/g;
    return text.replace(urlPattern, "");
  }

  static getAttachmentFileName(url) {
    var filename = url.substring(url.lastIndexOf("/") + 1);

    return filename ?? "1 Attachment";
  }

  static getInitials(name) {
    if (!name) {
      return "";
    }

    console.log(name);

    let initials = name
      ?.split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase()
      ?.trim();

    return initials;
  }

  static capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  static formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  static isValidPhoneNumber(phone) {
    console.log(phone);

    if (!phone?.includes("+1")) {
      phone = `+1${phone}`;
    }

    return isValidPhoneNumber(phone, "US");
  }

  static isValidEmail(email) {
    // Regular expression for validating email format
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;

    // Test the email against the regular expression
    return emailRegex.test(email);
  }

  static generatePlanDescription(plan) {
    if (!plan) {
      return "";
    }

    let frequency = plan.frequency == "weekly" ? "Weekly" : "Bi-Weekly";
    let procurement = plan.procurement;

    let costFreq = plan.frequency == "weekly" ? "Week" : "2 Weeks";

    if (procurement == "pickup") {
      procurement = "Pickup";
    }

    if (procurement == "delivery") {
      procurement = "Delivery";
    }

    if (procurement == "shipping") {
      procurement = "Shipping";
    }

    return `${frequency} ${procurement} @ ${this.centsToCurrency(
      plan.unitPrice + (plan?.deliveryFee ? plan?.deliveryFee : 0)
    )} / ${costFreq}`;
  }

  static getFirstName = function (str) {
    var arr = str.split(" ");
    if (arr.length === 1) {
      return arr[0];
    }
    return arr.slice(0, -1).join(" "); // returns "Paul Steve"
  };

  static getLastName = function (str) {
    var arr = str.split(" ");
    if (arr.length === 1) {
      return "";
    }
    return arr.slice(-1).join(" "); // returns "Paul Steve"
  };

  static getStoreSlug = (store) => {
    if (!store) {
      return "";
    }

    const slug = `${store?.name
      ?.replace("Project LeanNation", "")
      ?.replace("PLN", "")
      ?.trim()
      ?.split(" ")
      .join("-")
      ?.split(".")
      .join("")
      ?.toLowerCase()}${
      store?.location?.address?.state
        ? `-${store?.location?.address?.state?.toLowerCase()}`
        : ""
    }`;

    return slug;
  };

  /**
   * Converts JSON to syntax highlighted html
   *
   * @param {*} json
   * @returns
   */
  static jsonToHTML(json) {
    if (!json) {
      return "(empty)";
    }

    if (typeof json != "string") {
      json = JSON.stringify(json, null, "\t");
    }

    json = json
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/\n/g, "<br/>")
      .replace(/\t/g, `&emsp;`);
    json = json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
      function (match) {
        var cls = "number";
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = "text-dark font-weight-bold";
          } else {
            cls = "text-muted";
          }
        } else if (/true|false/.test(match)) {
          cls = "text-muted";
        } else if (/null/.test(match)) {
          cls = "text-muted";
        }
        return '<span class="' + cls + '">' + match + "</span>";
      }
    );

    return json;
  }

  static dateRangeDescription(startDate, endDate) {
    if (!startDate || !endDate) {
      return "period";
    }

    let start = moment(startDate?.toISOString())
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0);
    let end = moment(endDate?.toISOString())
      .hour(0)
      .minutes(0)
      .seconds(0)
      .millisecond(0);

    let diff = moment.duration(start.diff(end)).asDays();

    diff = Math.ceil(Math.abs(diff)) + 1;

    if (diff < 1) {
      return "period";
    }

    if (diff == 1) {
      return "day";
    }

    if (diff > 1 && diff < 7) {
      return `${diff} days`;
    }

    if (diff == 7) {
      return "week";
    }

    if (diff > 7 && diff < 14) {
      return `${diff} days`;
    }

    if (diff == 14) {
      return "2 weeks";
    }

    if (diff > 14 && diff < 21) {
      return `${diff} days`;
    }

    if (diff == 21) {
      return "3 weeks";
    }

    if (diff > 21 && diff < 28) {
      return `${diff} days`;
    }

    if (diff == 28) {
      return `4 weeks`;
    }

    if (diff == 90) {
      return "quarter";
    }

    if (diff % 90 == 0) {
      return `${parseInt(diff / 90)} quarters`;
    }

    if (diff == 365) {
      return "year";
    }

    if (diff % 365 == 0) {
      return `${parseInt(diff / 365)} years`;
    }

    if (diff == 30 || diff == 31) {
      return "month";
    }

    if (diff % 30 == 0) {
      return `${parseInt(diff / 30)} months`;
    }

    if (diff % 31 == 0) {
      return `${parseInt(diff / 31)} months`;
    }

    return `${diff} days`;
  }

  static randomChartColor() {
    let randomIDX = Math.floor(
      Math.random() * (this.CHART_COLORS?.length - 1 - 0 + 1) + 0
    );

    return this.CHART_COLORS[randomIDX];
  }

  static randomChartColorSet(count = 0) {
    if (!count) {
      return [];
    }

    let colors = [];
    let noDuplicates = true;

    if (count > this.CHART_COLORS?.length) {
      noDuplicates = false;
    }

    while (true) {
      if (colors?.length == count) {
        break;
      }

      const color = this.randomChartColor();

      if (noDuplicates && colors?.includes(color)) {
        continue;
      }

      colors.push(color);
    }

    return colors;
  }

  static async base64ImageToBlob(base64, type) {
    //console.log(base64, type);

    return new Promise((resolve) => {
      fetch(`data:${type};base64,${base64}`).then(
        async (res) => {
          let b = await res.blob();

          console.log("BLOB IN RES", b);

          resolve(b);
        },
        (e) => {
          console.error(e);

          resolve(null);
        }
      );
    });
  }

  static async fetchFile(file) {
    return new Promise(async (resolve) => {
      try {
        if (!Capacitor.isNativePlatform()) {
          resolve(null);

          return;
        }

        let res = await Filesystem.readFile({
          path: file,
        });

        res.name = "";

        resolve(res);
      } catch (e) {
        console.error(e);
        resolve(null);
      }
    });
  }
}
