import CustomerCreateInvoiceDrawer from "components/Drawers/CustomerCreateInvoiceDrawer";
import StringUtils from "lib/StringUtils";
import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Spinner,
  Table,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import Badge from "reactstrap/lib/Badge";
import CustomerAccountBalanceWidget from "./CustomerAccountBalanceWidget";
import CustomerInvoicesWidget from "./CustomerInvoicesWidget";
import NumberFormat from "react-number-format";
import CustomerPaymentMethodWidget from "./CustomerPaymentMethodWidget";
import CustomerPriceOverrideWidget from "./CustomerPriceOverrideWidget";

class CustomerBillingWidget extends React.Component {
  state = {};

  render() {
    return (
      <>
        {this.props.loading ? (
          <>
            <Card className={`border ${this.props.flat ? "" : "shadow"} mb-4`}>
              <CardHeader className="bg-transparent py-5 text-center">
                <Spinner size="lg" color="medium"></Spinner>
              </CardHeader>
            </Card>
          </>
        ) : (
          <>
            <CustomerPaymentMethodWidget
              customer={this.props.customer}
              loading={this.props.loading}
            ></CustomerPaymentMethodWidget>
            <Row>
              <Col xs="12" sm="6" className="mb-4">
                <CustomerAccountBalanceWidget
                  customer={this.props.customer}
                  loading={this.props.loading}
                ></CustomerAccountBalanceWidget>
              </Col>
              <Col xs="12" sm="6" className="mb-4">
                <CustomerPriceOverrideWidget
                  customer={this.props.customer}
                  loading={this.props.loading}
                ></CustomerPriceOverrideWidget>
              </Col>
            </Row>
            <CustomerInvoicesWidget
              customer={this.props.customer}
              loading={this.props.loading}
            ></CustomerInvoicesWidget>
          </>
        )}
      </>
    );
  }
}

export default CustomerBillingWidget;
