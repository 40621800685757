import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import Constant from "lib/Constant";

class ConversationTemplateDrawer extends React.Component {
  state = {
    message: "",
    name: "",
    type: "",
  };

  componentDidUpdate(prevProps) {
    if (this.props.template != prevProps?.template && this.props.template) {
      this.setState({
        name: this.props.template?.name + (this.props.duplicate ? " Copy" : ""),
        message: this.props.template?.message,
        type: this.props.template?.type ? this.props.template?.type : "",
      });
    }
  }

  componentDidMount() {
    if (this.props.template) {
      this.setState({
        name: this.props.template?.name + (this.props.duplicate ? " Copy" : ""),
        message: this.props.template?.message,
        type: this.props.template?.type ? this.props.template?.type : "",
      });
    }
  }

  toggleModal() {
    this.setState({
      name: "",
      message: "",
      type: "",
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  submit() {
    if (this.props.template && !this.props.duplicate) {
      this.modify();
    } else {
      this.create();
    }
  }

  create() {
    this.setState({ submitting: true });

    let promise = null;

    if (this.props.global) {
      promise = APIV2.createGlobalConversationTemplate(
        this.state.name,
        this.state.message,
        this.state.type
      );
    } else {
      promise = APIV2.createConversationTemplate(
        this.props?.store?._id,
        this.state.name,
        this.state.message,
        this.state.type
      );
    }

    promise
      .then(
        (data) => {
          const template = data.data.template;

          this.toggleModal();

          PubSub.publish(Event.CONVERSATION_TEMPLATE.CREATED, template);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create template - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  modify() {
    this.setState({ submitting: true });

    let promise = null;

    if (this.props.global) {
      promise = APIV2.modifyGlobalConversationTemplate(
        this.props.template?._id,
        this.state.name,
        this.state.message,
        this.state.type
      );
    } else {
      promise = APIV2.modifyConversationTemplate(
        this.props?.store?._id,
        this.props.template?._id,
        this.state.name,
        this.state.message,
        this.state.type
      );
    }

    promise
      .then(
        (data) => {
          const template = data.data.template;

          this.toggleModal();

          PubSub.publish(Event.CONVERSATION_TEMPLATE.MODIFIED, template);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to modify template - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.template && !this.props.duplicate
                ? "Modify Template"
                : null}
              {this.props.template && this.props.duplicate
                ? "Duplicate Template"
                : null}
              {!this.props.template ? "New Template" : null}
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={
                  this.state.submitting ||
                  !this.state.name ||
                  !this.state.message ||
                  !this.state.type
                }
                color="primary"
                onClick={this.submit.bind(this)}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  <>
                    {this.props.template && !this.props.duplicate
                      ? "Save"
                      : "Add"}
                  </>
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}

            <FormGroup>
              <h5>
                Name{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <Input
                bsSize="sm"
                type="text"
                name="name"
                placeholder="Name"
                value={this.state.name}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>
                Template Type{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <Input
                bsSize="sm"
                type="select"
                name="type"
                placeholder="Type"
                value={this.state.type}
                onChange={this.handleInputChange.bind(this)}
              >
                <option value="" disabled>
                  Select An Option
                </option>
                <option value="Retail">Retail</option>
                <option value="Member Engagement">Member Engagement</option>
                <option value="HFN Engagement">HFN Engagement</option>
                <option value="Lead Engagement">Lead Engagement</option>
                <option value="Orders & Logistics">Orders & Logistics</option>
                <option value="NSO: Community Activation">
                  NSO: Community Activation
                </option>
                <option value="NSO: Prelaunch">NSO: Prelaunch</option>
                <option value="Other">Other</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <h5>
                Message{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <Input
                bsSize="sm"
                type="textarea"
                rows={4}
                name="message"
                placeholder="Message"
                value={this.state.message}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(ConversationTemplateDrawer);
