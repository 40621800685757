import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  CheckPicker,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import { isThisTypeNode } from "typescript";
import EditorV2 from "components/NovelEditor/ui/editor";
import LearningCourseSelectorDrawer from "./LearningCourseSelectorDrawer";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

class LearningTrackDrawer extends React.Component {
  state = {
    name: "",
    description: {},
    courseIDs: [],
    courses: [],
    certificationIDs: [],
  };

  constructor() {
    super();

    this.dropdownRef = React.createRef();
  }

  updateForm() {
    this.setState(
      {
        name: this.props.track?.name ? this.props.track?.name : "",
        description: this.props.track?.description
          ? this.props.track?.description
          : {},
        courseIDs: this.props.track?.courseIDs?.length
          ? this.props.track?.courseIDs
          : [],
        courses: this.props.track?.courses?.length
          ? this.props.track?.courses
          : [],
        certificationIDs: this.props.track?.certificationIDs?.length
          ? this.props.track?.certificationIDs
          : [],
        staffRoleID: this.props.track?.staffRoleID
          ? this.props.track?.staffRoleID
          : null,
      },
      () => {
        setTimeout(() => {
          //console.log("HYDRATE", message);

          PubSub.publish("editor:contentUpdated", {
            event: "contentUpdated",
            forceHydrate: true,
            id: "learningTrackDescriptionEditor",
            data: this.props.track?.description
              ? this.props.track?.description
              : {},
          });
        }, 0);
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props?.track != prevProps?.track) {
      this.updateForm();
    }
  }

  componentDidMount() {
    if (this.props.track) {
      this.updateForm();
    }

    this.loadCertifications();
    this.loadStaffRoles();
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  toggleModal() {
    this.setState({
      name: "",
      courseIDs: [],
      courses: [],
      openCourseDrawer: false,
      certificationIDs: [],
      description: {},
    });

    PubSub.publish("editor:contentUpdated", {
      event: "contentUpdated",
      forceHydrate: true,
      id: "learningTrackDescriptionEditor",
      data: {},
    });

    this.props.onClose();
  }

  create() {
    this.setState({ submitting: true });

    APIV2.createLearningTrack(
      this.state.name,
      this.state.description,
      this.state.staffRoleID ? this.state.staffRoleID : null,
      this.state.courseIDs,
      this.state.certificationIDs
    )
      .then(
        (data) => {
          const staff = data.data.track;

          PubSub.publish(Event.STAFF.CREATED, staff);

          this.toggleModal();
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create track - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  modify() {
    this.setState({ submitting: true });

    console.log(this.state.courseIDs);

    APIV2.modifyLearningTrack(
      this.props?.track?._id,
      this.state.name,
      this.state.description,
      this.state.staffRoleID ? this.state.staffRoleID : null,
      this.state.courseIDs,
      this.state.certificationIDs
    )
      .then(
        (data) => {
          const staff = data.data.track;

          PubSub.publish(Event.STAFF.MODIFIED, staff);

          this.toggleModal();
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to modify track - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  /**
   * Helper function to handle reorder events from react-beautiful-dnd library
   *
   * @param {*} list
   * @param {*} startIndex
   * @param {*} endIndex
   * @returns
   */
  reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  onDragStart(result, e) {
    console.log(result, e);

    let elements = document.querySelectorAll(
      `[data-rbd-draggable-id="${result?.draggableId}"]`
    );

    if (elements?.length) {
      let element = elements[0];

      if (element) {
        element.style.left = "24px";
      }
    }
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.courses,
      result.source.index,
      result.destination.index
    );

    console.log(items);

    this.setState({
      courses: items,
      courseIDs: items.map((item) => item._id),
    });
  }

  loadCertifications() {
    this.setState({
      loadingCertifications: true,
    });

    APIV2.getLearningCertifications(1, 500, {
      type: "manual",
    })
      .then(
        (data) => {
          this.setState({
            certifications: data.data.certifications,
            certificationOptions: data.data.certifications.map((cert) => {
              return {
                label: cert.name,
                value: cert._id,
              };
            }),
          });
        },
        () => {
          window.alert("Failed to get certifications. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          loadingCertifications: false,
        });
      });
  }

  loadStaffRoles() {
    this.setState({
      loadingStaffRoles: true,
    });

    APIV2.getStaffRoles(1, 500, {}, { name: 1 })
      .then(
        (data) => {
          this.setState({
            roles: data.data.roles,
            roleOptions: data.data.roles.map((cert) => {
              return {
                label: cert.name,
                value: cert._id,
              };
            }),
          });
        },
        () => {
          window.alert("Failed to get staff roles. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          loadingStaffRoles: false,
        });
      });
  }

  addCourse(course) {
    let courseIDs = this.state.courseIDs?.length ? this.state.courseIDs : [];
    let courses = this.state.courses?.length ? this.state.courses : [];

    if (courseIDs.indexOf(course._id) == -1) {
      courseIDs.push(course._id);
      courses.push(course);

      this.setState({
        courseIDs,
        courses,
      });
    }
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.track ? "Modify" : "Create"}
              &nbsp;Track
            </h3>
            <Drawer.Actions>
              <Button
                onClick={() => {
                  if (this.props.track) {
                    this.modify();
                  } else {
                    this.create();
                  }
                }}
                size="sm"
                color="primary"
                disabled={
                  !this.state.name ||
                  !this.state?.courses?.length ||
                  this.state.submitting
                }
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : this.props.track ? (
                  "Save"
                ) : (
                  "Create"
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error && (
              <>
                <Alert
                  color=""
                  className="m-0 mb-4 bg-primary-superlight text-dark font-weight-500"
                >
                  {this.state.error}
                </Alert>
              </>
            )}
            <FormGroup>
              <h5>
                Name<span className="text-danger">*</span>
              </h5>
              <Input
                type="text"
                name="name"
                placeholder="Name"
                required
                value={this.state.name}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>

            <FormGroup>
              <h5 className="mb-2">Description</h5>
              <div
                className="rounded p-3 border mb-3"
                style={{ minHeight: 300 }}
              >
                <EditorV2
                  editable={true}
                  allowForceHydration={
                    this.state.description && this.state.templateLoad
                  }
                  id={"learningTrackDescriptionEditor"}
                  padding="p-0"
                  autoSave={false}
                  debounceRate={750}
                  cdnMode="private"
                  value={this.state?.description ? this.state?.description : {}}
                  onChange={(value) => {
                    if (value?.clear) {
                      this.setState({
                        templateLoad: false,
                      });

                      return;
                    }

                    this.setState({
                      description: value,
                    });
                  }}
                  allowTaskLists={false}
                ></EditorV2>
              </div>
            </FormGroup>
            <FormGroup>
              <h5 className="m-0">Assign To Staff Role</h5>
              <p className="small text-dark">
                Select a staff role to assign this learning track to.
              </p>
              <SelectPicker
                data={this.state.roleOptions}
                value={this.state.staffRoleID}
                onChange={(value) => {
                  this.setState({
                    staffRoleID: value,
                  });
                }}
                style={{ width: "100%" }}
                placeholder="Select role"
                preventOverflow={true}
                placement="autoVertical"
                loading={this.state.loadingStaffRoles}
              ></SelectPicker>
            </FormGroup>
            <FormGroup>
              <h5 className="m-0">Manual Certifications</h5>
              <p className="small text-dark">
                Select one or more manual certifications required for this
                learning track.
              </p>
              <CheckPicker
                data={this.state.certificationOptions}
                value={this.state.certificationIDs}
                onChange={(value) => {
                  this.setState({
                    certificationIDs: value,
                  });
                }}
                style={{ width: "100%" }}
                placeholder="Select certifications"
                preventOverflow={true}
                placement="autoVertical"
                loading={this.state.loadingCertifications}
              ></CheckPicker>
            </FormGroup>
            <FormGroup>
              <Row className="align-items-center">
                <Col xs="">
                  <h5 className="m-0">
                    Courses<span className="text-danger">*</span>
                  </h5>
                  <p className="small text-dark">
                    Add and organize one or more courses for this learning
                    track.
                  </p>
                </Col>
                <Col xs="auto">
                  <Button
                    size="sm"
                    color="primary"
                    outline
                    onClick={() => {
                      this.setState({
                        openCourseDrawer: true,
                      });
                    }}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
              {/**
               * TODO: drag to reorder course
               */}

              <div style={{ position: "relative" }}>
                <DragDropContext
                  onDragStart={this.onDragStart.bind(this)}
                  onDragEnd={this.onDragEnd.bind(this)}
                >
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDraggingOver
                            ? "var(--primary-superlight)"
                            : "",
                          borderRadius: 6,
                        }}
                      >
                        {this.state.courses.map((section, index) => (
                          <Draggable
                            key={section._id}
                            draggableId={section._id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="my-2"
                              >
                                <Card
                                  className={`cursor-pointer ${
                                    snapshot.isDragging ? "shadow" : ""
                                  } border`}
                                >
                                  <CardBody>
                                    <Row className="align-items-center">
                                      <Col xs="auto" className="pr-0 pl-2">
                                        <i
                                          className="mdi mdi-drag text-dark"
                                          style={{ fontSize: 25 }}
                                        ></i>
                                      </Col>
                                      <Col xs="">
                                        <h3 className="m-0 text-dark">
                                          {section?.draft ? (
                                            <Badge
                                              color="medium"
                                              className="mr-2"
                                            >
                                              Draft
                                            </Badge>
                                          ) : null}

                                          {section?.name}
                                        </h3>
                                        {section?.description ? (
                                          <p className="m-0 small text-dark">
                                            {section?.description}
                                          </p>
                                        ) : null}
                                      </Col>
                                      <Col xs="auto" className="pl-0">
                                        <Button
                                          size="sm"
                                          outline
                                          color="danger"
                                          className="btn-icon-only"
                                          onClick={() => {
                                            swal({
                                              title: "Remove Course?",
                                              text: "Once deleted this course will be removed from the track.",
                                              icon: "warning",
                                              buttons: true,
                                              dangerMode: true,
                                            }).then((willDelete) => {
                                              if (willDelete) {
                                                let courses =
                                                  this.state.courses;
                                                let courseIDs =
                                                  this.state.courseIDs;

                                                courses.splice(index, 1);
                                                courseIDs.splice(index, 1);

                                                this.setState({
                                                  courses,
                                                  courseIDs,
                                                });
                                              }
                                            });
                                          }}
                                        >
                                          <i className="mdi mdi-trash-can"></i>
                                        </Button>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </FormGroup>
          </Drawer.Body>
        </Drawer>
        <LearningCourseSelectorDrawer
          open={this.state.openCourseDrawer}
          onClose={() => {
            this.setState({
              openCourseDrawer: false,
            });
          }}
          selectedCourses={this.state.courseIDs ?? []}
          onSelect={(course) => {
            this.addCourse(course);
          }}
        ></LearningCourseSelectorDrawer>
      </>
    );
  }
}

export default withRouter(LearningTrackDrawer);
