import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Spinner,
  Collapse,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import moment from "moment";
import {
  CheckboxGroup,
  DatePicker,
  SelectPicker,
  Checkbox,
  CheckPicker,
} from "rsuite";
import StringUtils from "lib/StringUtils";
import { Table, Cell, Column, HeaderCell } from "rsuite-table";
import _ from "underscore";
import MoneyCell from "components/Table/MoneyCell";
import NumericCell from "components/Table/NumericCell";
import StoreCell from "components/Table/StoreCell";
import BootstrapTable from "react-bootstrap-table-next";
import MealsOrderedWidget from "components/Widgets/Fulfillment/MealsOrderedWidget";
import PlansFulfilledWidget from "components/Widgets/Fulfillment/PlansFulfilledWidget";
import AverageBoxSizeWidget from "components/Widgets/Fulfillment/AverageBoxSizeWidget";
import PlanFrequencyChartWidget from "components/Widgets/Fulfillment/PlanFrequencyChartWidget";
import PlanProcurementChartWidget from "components/Widgets/Fulfillment/PlanProcurementChartWidget";
import PlanTypeChartWidget from "components/Widgets/Fulfillment/PlanTypeChartWidget";
import { Link } from "react-router-dom";
import Input from "reactstrap/lib/Input";
import swal from "sweetalert";
import CustomerPickupListRow from "components/CustomerPickupListRow";
import CustomerOrderDrawer from "components/Drawers/CustomerOrderDrawer";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import LinkUtils from "lib/LinkUtils";

class GlobalFulfillmentProjectionView extends React.Component {
  state = {
    stores: [],
    loading: true,
    fulfillmentDate: moment().day(6).toDate(),
    availableDates: [],
    storeOpen: true,
    mealOpen: true,
    planOpen: true,
    storePerformance: [],
    dates: [],
    orders: [],
    compareToOptions: [],
    count: 25,
    page: 1,
    selectedOrders: [],
    searchResultOrders: [],

    reports: [],
    active: {
      id: "All",
    },
    filters: {
      status: { $ne: "CANCELLED" },
      "subscription.plan.procurement": { $eq: "pickup" },
    },
    storeOptions: [],
    selectedStores: [],
    pendingSelectedStores: [],
    loadingStores: false,
  };

  constructor(props) {
    super(props);

    this.storePickerRef = React.createRef();
  }

  onSelectAllStores() {
    let selected = this.state.storeOptions?.map((opt) => {
      return opt.value;
    });

    if (
      this.state.pendingSelectedStores?.length ==
      this.state.storeOptions?.length
    ) {
      return this.setState({
        pendingSelectedStores: [],
      });
    }

    this.setState({
      pendingSelectedStores: selected,
    });
  }

  onChangeStores(v) {
    this.setState({
      pendingSelectedStores: v,
    });
  }

  /**
   * Checks if the current user is a global user via permissions
   *
   * @returns
   */
  isGlobal() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ||
      user?.productionPermissions?.length
      ? true
      : false;
  }

  onApplyChangeStores() {
    let userSelectedStores = this.state.pendingSelectedStores;
    let userSelectedLiveStores = this.state.pendingSelectedStores;
    let userSelectedLiveAndPrelaunchStores = this.state.pendingSelectedStores;
    let selectedLiveAndPrelaunchStores = this.state.pendingSelectedStores;

    if (!this.isGlobal()) {
      userSelectedStores = _.filter(this.state.pendingSelectedStores, (st) => {
        return _.findWhere(this.state.allUserStores, { _id: st });
      });
      userSelectedLiveStores = _.filter(
        this.state.pendingSelectedStores,
        (st) => {
          return _.findWhere(this.state.allUserLiveStores, { _id: st });
        }
      );
      userSelectedLiveAndPrelaunchStores = _.filter(
        this.state.pendingSelectedStores,
        (st) => {
          return _.findWhere(this.state.allUserLiveAndPrelaunchStores, {
            _id: st,
          });
        }
      );
    } else {
      userSelectedLiveStores = _.filter(
        this.state.pendingSelectedStores,
        (st) => {
          return _.findWhere(this.state.allLiveStores, { _id: st });
        }
      );
      userSelectedLiveAndPrelaunchStores = _.filter(
        this.state.pendingSelectedStores,
        (st) => {
          return _.findWhere(this.state.allLiveAndPrelaunchStores, {
            _id: st,
          });
        }
      );
    }

    selectedLiveAndPrelaunchStores = _.filter(
      this.state.pendingSelectedStores,
      (st) => {
        return _.findWhere(this.state.allLiveAndPrelaunchStores, {
          _id: st,
        });
      }
    );

    let selectedLiveStores = _.filter(
      this.state.pendingSelectedStores,
      (st) => {
        return _.findWhere(this.state.allLiveStores, {
          _id: st,
        });
      }
    );

    this.setState(
      {
        selectedStores: this.state.pendingSelectedStores,
        userSelectedStores,
        userSelectedLiveStores,
        userSelectedLiveAndPrelaunchStores,
        selectedLiveAndPrelaunchStores,
        selectedLiveStores,
      },
      () => {
        this.loadSummary(this.state.fulfillmentDateString);
      }
    );
  }

  getAllStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStores()
      .then((data) => {
        const now = new Date();

        console.log("HERE");

        let stores = _.filter(data.data.stores, (st) => {
          return st?.nsoStage == "Live" || st?.nsoStage == "Prelaunch";
        });

        let allUserStores = [];
        let userSelectedStores = [];
        let allUserLiveStores = [];
        let allUserLiveAndPrelaunchStores = [];
        let userSelectedLiveStores = [];
        let userSelectedLiveAndPrelaunchStores = [];
        let selectedLiveAndPrelaunchStores = [];
        let selectedLiveStores = [];
        // test

        let allStores = stores?.map((st) => {
          return _.clone(st);
        });

        let allLiveStores = _.filter(allStores, (st) => {
          return st.nsoStage == "Live";
        });

        let allLiveStoreIDs = allLiveStores.map((st) => {
          return st._id;
        });

        let allLiveAndPrelaunchStores = _.filter(allStores, (st) => {
          return st.nsoStage == "Live" || st.nsoStage == "Prelaunch";
        });

        let allLiveAndPrelaunchStoreIDs = allLiveAndPrelaunchStores.map(
          (st) => {
            return st._id;
          }
        );

        let allStoreIDs = allStores.map((st) => {
          return st._id;
        });

        if (!this.isGlobal()) {
          let user = this.getUser();

          allUserStores = _.filter(data.data.stores, (st) => {
            return _.findWhere(user?.storePermissions, { storeID: st._id });
          });
          allUserLiveStores = _.filter(allLiveStores, (st) => {
            return _.findWhere(user?.storePermissions, { storeID: st._id });
          });

          allUserLiveAndPrelaunchStores = _.filter(
            allLiveAndPrelaunchStores,
            (st) => {
              return _.findWhere(user?.storePermissions, { storeID: st._id });
            }
          );
        } else {
          allUserStores = data.data.stores;
          allUserLiveStores = allLiveStores;
          allUserLiveAndPrelaunchStores = allLiveAndPrelaunchStores;
        }

        let storeOptions = stores?.map((st) => {
          return {
            label: st?.name,
            value: st?._id,
            groupBy: st?.nsoStage,
          };
        });

        let selectedStores = [];

        if (this.state.selectedStores?.length) {
          selectedStores = _.filter(this.state.selectedStores, (v) => {
            return _.findWhere(stores, { _id: v });
          });

          userSelectedStores = _.filter(this.state.selectedStores, (st) => {
            return _.findWhere(allUserStores, { _id: st });
          });
          userSelectedLiveStores = _.filter(this.state.selectedStores, (st) => {
            return _.findWhere(allUserLiveStores, { _id: st });
          });
          userSelectedLiveAndPrelaunchStores = _.filter(
            this.state.selectedStores,
            (st) => {
              return _.findWhere(allUserLiveAndPrelaunchStores, { _id: st });
            }
          );
          selectedLiveAndPrelaunchStores = _.filter(
            this.state.selectedStores,
            (st) => {
              return _.findWhere(allLiveAndPrelaunchStores, { _id: st });
            }
          );

          selectedLiveStores = _.filter(this.state.selectedStores, (st) => {
            return _.findWhere(allLiveStores, { _id: st });
          });
        } else {
          selectedStores = storeOptions?.map((st) => {
            return st.value;
          });

          userSelectedStores = _.filter(
            storeOptions?.map((st) => {
              return st.value;
            }),
            (st) => {
              return _.findWhere(allUserStores, { _id: st });
            }
          );
          userSelectedLiveStores = _.filter(
            storeOptions?.map((st) => {
              return st.value;
            }),
            (st) => {
              return _.findWhere(allUserLiveStores, { _id: st });
            }
          );

          userSelectedLiveAndPrelaunchStores = _.filter(
            storeOptions?.map((st) => {
              return st.value;
            }),
            (st) => {
              return _.findWhere(allUserLiveAndPrelaunchStores, { _id: st });
            }
          );

          selectedLiveAndPrelaunchStores = _.filter(
            storeOptions?.map((st) => {
              return st.value;
            }),
            (st) => {
              return _.findWhere(allLiveAndPrelaunchStores, { _id: st });
            }
          );

          selectedLiveStores = _.filter(
            storeOptions?.map((st) => {
              return st.value;
            }),
            (st) => {
              return _.findWhere(allLiveStores, { _id: st });
            }
          );
        }

        this.setState(
          {
            stores: stores,
            allStores,
            allUserStores,
            allStoreIDs,
            allLiveStoreIDs,
            allLiveStores,
            allLiveAndPrelaunchStoreIDs,
            allLiveAndPrelaunchStores,
            storeOptions,
            selectedStores,
            pendingSelectedStores: selectedStores,
            userSelectedStores,
            allUserLiveStores,
            userSelectedLiveStores,
            selectedLiveStores,
            userSelectedLiveAndPrelaunchStores,
            allUserLiveAndPrelaunchStores,
            selectedLiveAndPrelaunchStores,
          },
          () => {
            console.log("HERE");

            const urlParams = new URLSearchParams(window.location.search);
            const date = urlParams.get("date");

            let fulfillmentDate = moment();

            if (date) {
              fulfillmentDate = moment(date);
            }

            console.log(fulfillmentDate);

            fulfillmentDate
              .set("day", 6)
              .set("hours", 12)
              .set("minutes", 0)
              .set("seconds", 0);

            this.setState(
              {
                fulfillmentDate: fulfillmentDate.toDate(),
                fulfillmentMoment: fulfillmentDate,
                fulfillmentDateString: fulfillmentDate.format("YYYY-MM-DD"),
              },
              () => {
                this.loadSummary(this.state.fulfillmentDateString);
              }
            );
          }
        );
      })
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  onCancelChangeStores() {
    this.setState({
      pendingSelectedStores: this.state.selectedStores,
    });
  }

  loadOrders() {
    this.setState({
      loadingOrders: true,
      selectedOrders: [],
    });

    console.log(this.state.selectedStores);

    APIV2.getProjections(this.state.dateString, this.state.selectedStores)
      .then(
        (data) => {
          if (data && data.data) {
            let projections = data?.data?.projection;

            let reports = [
              {
                statistics: {
                  totalBOMQtyByMeal: projections,
                },
              },
            ];

            this.setState({
              projections,
              reports,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the projections. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingOrders: false,
          loading: false,
        });
      });
  }

  loadSummary(dateString) {
    if (!dateString) {
      return;
    }

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("date", dateString);

    if (this.state.selectedStores) {
      queryParams.set(
        "stores",
        this.state.selectedStores
          ?.map((st) => {
            return st.value;
          })
          ?.join(",")
      );
    }

    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.setState({
      loading: true,
      rerunning: false,
      noReport: false,
    });

    let filter = this.state.filters;

    filter.fulfillmentDateString = dateString;

    this.setState(
      {
        filters: filter,
        dateString,
      },
      () => {
        this.loadOrders();
      }
    );
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (this.state.searchResults) {
      page = this.state.searchPage ? this.state.searchPage + count : 1 + count;
    }

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadOrders();
  }

  componentDidMount() {
    this.getAllStores();

    PubSub.subscribe(Event.ORDER.MODIFIED, (order) => {
      let orderIdx = _.findIndex(this.state.orders, { _id: order._id });
      let searchIdx = _.findIndex(this.state.searchResultOrders, {
        _id: order._id,
      });

      if (orderIdx >= 0) {
        let orders = this.state.orders;

        orders[orderIdx] = order;

        this.setState({
          orders,
        });
      }

      if (searchIdx >= 0) {
        let searchResultOrders = this.state.searchResultOrders;

        searchResultOrders[searchIdx] = order;

        this.setState({
          searchResultOrders,
        });
      }
    });

    PubSub.subscribe(Event.ORDER.OPEN, (order) => {
      this.setState({
        drawer: true,
        viewOrder: order,
      });
    });
  }

  toggleSelected(order, val) {
    let selected = this.state.selectedOrders;

    if (!val) {
      selected = _.filter(selected, (o) => {
        return o._id != order._id;
      });
    } else {
      if (!this.isSelected(order)) {
        selected.push(_.clone(order));
      }
    }

    this.setState({ selectedOrders: selected }, () => {});
  }

  toggleAllSelected(val) {
    this.setState(
      {
        selectedOrders: [],
      },
      () => {
        if (this.state.searchResults) {
          for (let i = 0; i < this.state.searchResultOrders.length; i++) {
            this.toggleSelected(this.state.searchResultOrders[i], val);
          }
        } else {
          for (let i = 0; i < this.state.orders.length; i++) {
            this.toggleSelected(this.state.orders[i], val);
          }
        }
      }
    );
  }

  isSelected(order) {
    return _.findWhere(this.state.selectedOrders, { _id: order?._id })
      ? true
      : false;
  }

  allSelected() {
    let all = true;

    if (this.state.searchResults) {
      for (let i = 0; i < this.state.searchResultOrders.length; i++) {
        if (!this.isSelected(this.state.searchResultOrders[i])) {
          all = false;
        }
      }
    } else {
      for (let i = 0; i < this.state.orders.length; i++) {
        if (!this.isSelected(this.state.orders[i])) {
          all = false;
        }
      }
    }

    return all;
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeader
            forceSecondaryDropdown={true}
            title="Projections"
            showBackButton={false}
            secondaryActions={[
              {
                id: "printPickupList",
                name: <>Download Projections</>,
                onClick: () => {
                  LinkUtils.openInNewTab(
                    "/storefront-print/fulfillment/projections/csv?date=" +
                      this.state.fulfillmentDateString,
                    true
                  );
                },
              },
            ]}
          />
          {/* Table */}
          <div className="border-bottom pb-3 mt--2 mb-4">
            <Row className="align-items-center">
              <Col xs="12" sm="12" md="">
                <h3 className="m-0">
                  {this.state.fulfillmentMoment?.format("ddd, MMM Do YYYY")}
                </h3>
              </Col>
              <Col xs="12" sm="12" md="auto">
                <div
                  className="reports-store-picker  mt-3 mt-md-0  d-inline-block mr-md-2"
                  style={{ maxWidth: "100%" }}
                >
                  <CheckPicker
                    groupBy="groupBy"
                    block
                    ref={this.storePickerRef}
                    cleanable={true}
                    label="Stores"
                    data={this.state.storeOptions}
                    placement="autoVertical"
                    preventOverflow={true}
                    placeholder="Select Stores"
                    value={this.state.pendingSelectedStores}
                    onChange={this.onChangeStores.bind(this)}
                    onClose={() => {
                      if (
                        JSON.stringify(this.state.pendingSelectedStores) !=
                        JSON.stringify(this.state.selectedStores)
                      ) {
                        this.onApplyChangeStores();
                      }
                    }}
                    sort={(isGroup) => {
                      if (isGroup) {
                        return (a, b) => {
                          if (b.groupTitle == "Live") {
                            return 1;
                          }

                          if (b.groupTitle == "Prelaunch") {
                            if (a.groupTitle == "Live") {
                              return -1;
                            }

                            return 1;
                          }

                          if (b.groupTitle == "Buildout") {
                            if (
                              a.groupTitle == "Live" ||
                              a.groupTitle == "Prelaunch"
                            ) {
                              return -1;
                            }

                            return 1;
                          }

                          if (b.groupTitle == "Site Selection") {
                            if (
                              a.groupTitle == "Live" ||
                              a.groupTitle == "Prelaunch" ||
                              a.groupTitle == "Buildout"
                            ) {
                              return -1;
                            }

                            return 1;
                          }

                          return a.groupTitle < b.groupTitle ? -1 : 1;
                        };
                      }

                      return (a, b) => {
                        return a.label < b.label ? -1 : 1;
                      };
                    }}
                    renderExtraFooter={() => (
                      <div className="pr-2 py-2 border-top">
                        <Row className="align-items-center">
                          <Col xs="">
                            <Checkbox
                              className="ml-1"
                              inline
                              indeterminate={
                                this.state.pendingSelectedStores?.length > 0 &&
                                this.state.pendingSelectedStores?.length <
                                  this.state.storeOptions?.length
                              }
                              checked={
                                this.state.pendingSelectedStores?.length ===
                                this.state.storeOptions?.length
                              }
                              onChange={this.onSelectAllStores.bind(this)}
                            >
                              All Stores
                            </Checkbox>
                          </Col>
                          <Col xs="auto">
                            <Button
                              color="dark"
                              outline
                              size="sm"
                              onClick={() => {
                                this.onCancelChangeStores();

                                this.storePickerRef.current.close();
                              }}
                            >
                              Cancel
                            </Button>

                            <Button
                              color="primary"
                              size="sm"
                              onClick={() => {
                                this.storePickerRef.current.close();
                              }}
                            >
                              Apply
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    )}
                  ></CheckPicker>
                </div>
                <div className="d-inline-block">
                  <DatePicker
                    oneTap
                    value={this.state.fulfillmentDate}
                    onChange={(v) => {
                      let mom = moment(v.toISOString());

                      mom
                        .day(6)
                        .set("hours", 12)
                        .set("minutes", 0)
                        .set("seconds", 0);

                      this.setState(
                        {
                          fulfillmentDate: mom.toDate(),
                          fulfillmentMoment: mom,
                          fulfillmentDateString: mom.format("YYYY-MM-DD"),
                        },
                        () => {
                          this.loadSummary(this.state.fulfillmentDateString);
                        }
                      );
                    }}
                    className="mt-3 mt-md-0"
                    placeholder="Select Date"
                    style={{
                      minWidth: "200px",
                      width: "100%",
                      maxWidth: "800px",
                    }}
                    placement="autoVerticalEnd"
                    block
                    cleanable={false}
                    disabledDate={(date) => {
                      let mom = moment(date.toISOString());

                      return (
                        mom.toDate() < moment().toDate() || mom.day() !== 6
                      );
                    }}
                  />
                </div>
              </Col>
            </Row>
          </div>

          {this.state.loading ? (
            <>
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">
                    <Spinner color="dark" size="sm"></Spinner>
                    <span className="pl-3">Loading Projections</span>
                  </h3>
                </CardHeader>
                <CardBody>
                  Hang tight while we fetch your projections for{" "}
                  {this.state.fulfillmentMoment?.format("dddd, MMM Do YYYY")}.
                </CardBody>
              </Card>
            </>
          ) : (
            <MealsOrderedWidget
              reports={this.state.reports}
            ></MealsOrderedWidget>
          )}
        </Container>
      </>
    );
  }
}

export default GlobalFulfillmentProjectionView;
