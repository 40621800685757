import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  BarChart,
  BarList,
  Card,
  DeltaBar,
  DonutChart,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import {
  Axis,
  Chart,
  Coordinate,
  Interaction,
  Interval,
  Legend,
  Tooltip,
} from "bizcharts";
import ChartMetricHeader from "./ChartMetricHeader";

class NewMembersByStoreCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    tab: "total",
  };

  /**
   * Fetches a summary of all the member data
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchData(cubejsApi, stores, dateRange, metricNames = []) {
    let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

    secondDifference = secondDifference / 1000;

    let granularity = secondDifference > 60 * 60 * 24 * 90 ? "week" : "day";

    if (secondDifference >= 60 * 60 * 24 * 364) {
      granularity = "month";
    }

    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["Metrics.count"],
          order: {
            "Metrics.createdat": "desc",
          },

          timeDimensions: [
            {
              dimension: "Metrics.createdat",
              dateRange: [dateRange[0], dateRange[1]],
              granularity: "week",
            },
          ],
          filters: [
            {
              member: "Metrics.metadatastoreid",
              operator: "equals",
              values: stores,
            },
            {
              member: "Metrics.name",
              operator: "equals",
              values: ["member_new", "member_reactivate"],
            },
          ],
          dimensions: ["Stores.name"],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          let total = 0;

          if (!data?.length) {
            return resolve(null);
          }

          let stores = [];

          data = data.map((item) => {
            total += item["Metrics.count"];

            if (!stores?.includes(item["Stores.name"])) {
              stores.push(item["Stores.name"]);
            }

            return {
              count: item["Metrics.count"],
              storeName: item["Stores.name"],
            };
          });

          return resolve({
            total,
            history: data,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async _fetchReport(stores, dateRange) {
    this.setState({
      loading: true,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    if (!stores?.length) {
      return;
    }

    let currentCount = null;
    let currentCountOut = {
      history: [],
    };

    try {
      currentCount = await this._fetchData(cubejsApi, stores, dateRange, []);

      console.log(currentCount);

      for (let i = 0; i < currentCount?.history?.length; i++) {
        const point = currentCount?.history[i];

        let item = {
          count: point?.count ? point?.count : 0,
          storeName: point?.storeName,
          reports: 1,
        };

        const idx = _.findIndex(currentCountOut?.history, {
          storeName: point?.storeName,
        });

        if (idx >= 0) {
          currentCountOut.history[idx].count += item.count;
          currentCountOut.history[idx].reports += 1;
        } else {
          currentCountOut.history.push(item);
        }
      }

      currentCountOut.history = _.sortBy(currentCountOut.history, "count");
      currentCountOut.history.reverse();
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load new member quantity.",
        loading: false,
      });

      return;
    }

    if (currentCountOut !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });
    }

    let total = 0;
    let reports = 0;
    let count = 0;

    for (let i = 0; i < currentCountOut?.history?.length; i++) {
      reports += currentCountOut?.history[i]?.reports;
      total += currentCountOut?.history[i]?.count;

      count++;

      if (currentCountOut.history[i].reports > 0) {
        currentCountOut.history[i].weeklyCount =
          currentCountOut.history[i].count / currentCountOut.history[i].reports;
      } else {
        currentCountOut.history[i].weeklyCount = 0;
      }
    }

    console.log(currentCountOut);

    this.setState({
      currentCount: currentCountOut,
      weeklyAverage: total / reports,
      average: total / count,
      loading: false,
    });

    if (typeof this.props.onLoaded == "function") {
      this.props.onLoaded();
    }
  }

  /**
   * Load all report information
   *
   * @param {*} stores
   * @param {*} dateRange
   * @param {*} compare
   */
  async loadReport(stores, dateRange, compare) {
    this.setState({
      loading: true,
    });

    this._fetchReport(stores, dateRange);
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.stores != prevProps?.stores && this.props.stores?.length) ||
      this.props.dateRange != prevProps?.dateRange ||
      this.props.comparePrevious != prevProps.comparePrevious
    ) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }

    if (this.props.reload != prevProps.reload && this.props.reload) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  componentDidMount() {
    if (this.props.stores && this.props.dateRange) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }

    if (this.props.reload) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  render() {
    return (
      <>
        <Card marginTop="mt-0">
          <ChartMetricHeader
            title="New Members By Store"
            actions={
              <>
                <Button
                  size="sm"
                  outline
                  color="dark"
                  className="border-0 btn-icon-only"
                  disabled={this.state.loading}
                  onClick={() => {
                    this.loadReport(
                      this.props.stores,
                      this.props.dateRange,
                      this.props.comparePrevious
                    );
                  }}
                >
                  {this.state.loading ? (
                    <Spinner size="sm"></Spinner>
                  ) : (
                    <i className="mdi mdi-refresh"></i>
                  )}
                </Button>
              </>
            }
            loading={this.state.loading}
            dataAvailable={this.state.dataAvailable}
            metric={`Avg. +${StringUtils.numberFormat(
              parseFloat(
                (this.state.tab == "weekly"
                  ? this.state.weeklyAverage
                  : this.state.average
                )?.toFixed(1)
              )
            )} Members / Store ${
              this.state.tab == "weekly" ? "Weekly" : "Total"
            }`}
            comparisonMetric={null}
            dateRange={this.props.dateRange}
            deltaType={null}
            percentChange={null}
            showPercentChange={false}
          ></ChartMetricHeader>
          <TabList
            color="orange"
            defaultValue="total"
            handleSelect={(value) => {
              this.setState({
                tab: value,
              });
            }}
            marginTop="mt-3"
            disabled={this.state.loading || !this.state.dataAvailable}
          >
            
            <Tab value="total" text="Total New Members" />
          </TabList>

          {this.state.loading ? (
            <div
              className="skeleton mt-3"
              style={{ height: "calc(327px + 1rem)", width: "100%" }}
            >
              &nbsp;
            </div>
          ) : (
            <>
              {this.state.dataAvailable ? (
                <>
                  <div className="mt-3 mx--4 px-4">
                    <div className="mb--4 pb-4">
                      {this.state.currentCount?.history?.map((store, i) => (
                        <div
                          key={i}
                          className={`${i != 0 && "mt-4 border-top"} pt-3`}
                        >
                          <p
                            className="mt-0 mb-1 text-dark font-weight-500"
                            style={{ fontSize: 16, lineHeight: 1.3 }}
                          >
                            {store?.storeName
                              ?.replace("Project LeanNation", "PLN")
                              ?.trim()}
                          </p>

                          <Row className="align-items-center mb-2">
                            <Col xs="12" sm="">
                              <Text>
                                +
                                {StringUtils.numberFormat(
                                  (this.state.tab == "weekly"
                                    ? store?.weeklyCount
                                    : store?.count
                                  )?.toFixed(1)
                                )}
                                &nbsp;Member
                                {(this.state.tab == "weekly"
                                  ? store?.weeklyCount
                                  : store?.count) != 1
                                  ? "s"
                                  : ""}
                                {this.state.tab == "weekly"
                                  ? " / Week Avg."
                                  : " Total"}
                              </Text>
                            </Col>
                            <Col xs="12" sm="auto" className="mt-1 mt-sm-0">
                              <Text>
                                {(this.state.tab == "weekly"
                                  ? store?.weeklyCount
                                  : store?.count) -
                                  (this.state.tab == "weekly"
                                    ? this.state.weeklyAverage
                                    : this.state.average) >
                                0
                                  ? "+"
                                  : ""}
                                {StringUtils.numberFormat(
                                  (
                                    (this.state.tab == "weekly"
                                      ? store?.weeklyCount
                                      : store?.count) -
                                    (this.state.tab == "weekly"
                                      ? this.state.weeklyAverage
                                      : this.state.average)
                                  ).toFixed(1)
                                )}
                                &nbsp;Members vs. all-store avg.
                              </Text>
                            </Col>
                          </Row>

                          <DeltaBar
                            marginTop="mt-1"
                            percentageValue={
                              ((this.state.tab == "weekly"
                                ? store?.weeklyCount
                                : store?.count) -
                                (this.state.tab == "weekly"
                                  ? this.state.weeklyAverage
                                  : this.state.average)) *
                              (this.state.tab == "weekly" ? 10 : 1)
                            }
                            isIncreasePositive={true}
                            showAnimation={true}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "calc(327px + 1rem)", width: "100%" }}
                >
                  <p className="m-0">
                    No data available.
                    {this.state.error ? ` ${this.state.error}` : null}
                  </p>
                </div>
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default NewMembersByStoreCard;
