import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Alert,
} from "reactstrap";
import API from "lib/API";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import User from "lib/Model/User";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import APIV2 from "lib/APIV2";
import { Link } from "react-router-dom/cjs/react-router-dom";
import swal from "sweetalert";

let logo = require("assets/img/brand/storefront_wordmark.svg");

class ResetPassword extends React.Component {
  state = {
    submitting: false,
    email: "",
    password: "",
    error: false,
  };

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  resetPassword(e) {
    let self = this;

    e.preventDefault();

    this.setState({
      submitting: true,
      error: false,
    });

    APIV2.resetPassword(this.state.token, this.state.password)
      .then(
        (data) => {
          console.log(data);

          swal({
            title: "Successfully Reset Password",
            text: "Your password has been successfully updated",
            icon: "success",
          });

          this.props.history.push("/auth/login");
        },
        (error) => {
          console.error(error);
          this.setState({
            error: true,
          });
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (!token) {
      return this.setState({
        noToken: true,
      });
    }

    this.setState({
      token: token,
    });
  }

  render() {
    // Redirect if already authenticated
    if (LocalStorage.get(Constant.CACHE.TOKEN)) {
      this.props.history.push("/admin");
    }

    return (
      <>
        <Col lg="6" md="7">
          <Card className="bg-white shadow border">
            <CardHeader className="bg-white py-4 px-5">
              <img
                src={logo}
                style={{
                  width: "90%",
                  maxWidth: "240px",
                  position: "relative",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
                className="my-2"
              ></img>
            </CardHeader>
            <CardHeader className="py-3">
              <div className="text-left">
                <Link to="/auth/login">
                  <i className="mdi mdi-chevron-left ml-2"></i>Back
                </Link>
              </div>
            </CardHeader>
            {this.state.noToken ? (
              <>
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-dark mb-5">
                    <h3>Your Password Reset Link Is Invalid</h3>
                    <p>
                      Your password reset link is invalid. Please return to the
                      login and request a new one.
                    </p>
                    <Link to="/auth/login">
                      <Button color="primary" outline>
                        Return to Login
                      </Button>
                    </Link>
                  </div>
                </CardBody>
              </>
            ) : (
              <>
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-dark mb-5 font-weight-500">
                    Enter your new password that is 8+ characters long below
                  </div>
                  <Form role="form" onSubmit={this.resetPassword.bind(this)}>
                    <FormGroup className="mb-3">
                      <h5>New Password</h5>
                      <Input
                        required={true}
                        onChange={this.handleInputChange.bind(this)}
                        value={this.state.password}
                        placeholder="Password"
                        type="password"
                        name="password"
                        autoComplete="new-password"
                      />
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <h5>Re-Enter New Password</h5>
                      <Input
                        required={true}
                        onChange={this.handleInputChange.bind(this)}
                        value={this.state.rePassword}
                        placeholder="Password"
                        name="rePassword"
                        type="password"
                        autoComplete="new-password"
                      />
                      {this.state.password &&
                        this.state.password?.length < 8 && (
                          <>
                            <p className="text-danger small mb-0 mt-1">
                              Your new password must be 8+ characters long.
                            </p>
                          </>
                        )}
                      {this.state.password &&
                        this.state.rePassword &&
                        this.state.rePassword != this.state.password && (
                          <>
                            <p className="text-danger small mt-1 mb-0">
                              The passwords you entered do not match.
                            </p>
                          </>
                        )}
                    </FormGroup>

                    <div className="text-center">
                      <Button
                        disabled={
                          this.state.submitting ||
                          !this.state.password ||
                          this.state.password != this.state.rePassword ||
                          this.state.password?.length < 8
                        }
                        className="my-4 px-6"
                        color="primary"
                        type="submit"
                        size="lg"
                        style={{ fontSize: 15 }}
                      >
                        Update Password
                      </Button>
                    </div>

                    {this.state.error ? (
                      <div className="text-center">
                        <Alert color="danger">
                          Unable to send password reset email, please try again.
                        </Alert>
                      </div>
                    ) : null}
                  </Form>
                </CardBody>
              </>
            )}
          </Card>
          <Row className="mt-3">
            {/*<Col xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a>
            </Col>*/}
          </Row>
        </Col>
      </>
    );
  }
}

export default ResetPassword;
