import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { Checkbox } from "rsuite";
import Alert from "reactstrap/lib/Alert";
import CustomerOpenActivitiesWidget from "./CustomerOpenActivitiesWidget";
import CustomerUnclaimedBoxWidget from "./CustomerUnclaimedBoxWidget";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import swal from "sweetalert";
import CustomerHoldDrawer from "../Drawers/CustomerHoldDrawer";

class CustomerStatusWidget extends React.Component {
  state = {};

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 4000);
  }

  setStatus(status) {
    this.setState({
      submitting: true,
    });

    APIV2.updateCustomerStatus(
      this.props.customer.storeID,
      this.props.customer._id,
      status
    )
      .then(
        (data) => {
          PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);
        },
        (err) => {
          this.setError(
            "error",
            "Unable to update customer status. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  setActive() {
    this.setStatus("ACTIVE");
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (
      prevProps.customer != this.props.customer ||
      prevProps.customer?.status != this.props.customer?.status
    ) {
    }
  }

  render() {
    return (
      <>
        <Card
          style={{ height: "100%" }}
          className={`${this.props.flat ? "border-0" : "border shadow"}`}
        >
          <CardHeader>
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Status</h3>
              </div>
              <Col xs="auto">
                {this.props.customer?.accountStatus == "ACTIVE" ? (
                  <Button
                    disabled={this.state.submitting}
                    size="sm"
                    color="secondary"
                    outline
                    onClick={() => {
                      this.setState({
                        holdDrawer: true,
                      });
                    }}
                  >
                    {this.state.submitting ? (
                      <Spinner size="sm" color="secondary" />
                    ) : (
                      "Hold"
                    )}
                  </Button>
                ) : (
                  <Button
                    disabled={this.state.submitting}
                    size="sm"
                    color="primary"
                    onClick={() => {
                      this.setActive();
                    }}
                  >
                    {this.state.submitting ? (
                      <Spinner size="sm" color="white" />
                    ) : (
                      "Activate"
                    )}
                  </Button>
                )}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row className="align-items-center">
              <Col xs="12" className="">
                {this.props.customer?.accountStatus == "ACTIVE" ? (
                  <h3 className="mb-0">Active</h3>
                ) : (
                  <h3 className="mb-0">On Hold</h3>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
        <CustomerHoldDrawer
          open={this.state.holdDrawer}
          customer={this.props.customer}
          onClose={() => {
            this.setState({ holdDrawer: false });
          }}
        ></CustomerHoldDrawer>
      </>
    );
  }
}

export default withRouter(CustomerStatusWidget);
