import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import Alert from "reactstrap/lib/Alert";
import { SelectPicker, TagPicker } from "rsuite";
import CustomerOpenActivitiesWidget from "./CustomerOpenActivitiesWidget";
import CustomerUnclaimedBoxWidget from "./CustomerUnclaimedBoxWidget";
import _ from "underscore";
import Constant from "lib/Constant";

class AffiliateDeliverablesWidget extends React.Component {
  state = {
    expand: false,
    phone: {
      value: "",
    },
    zip: {
      value: "",
    },
    name: "",
    email: "",
    deliverableOptions: [
      {
        value: "10% off First week to all members",
        label: "10% off First week to all members",
      },
      {
        value: "15% off Retail for staff and members",
        label: "15% off Retail for staff and members",
      },
      {
        value: "PLNHERO Discount 10% off Owners/Coaches",
        label: "PLNHERO Discount 10% off Owners/Coaches",
      },
      {
        value: "Announce PLN Affiliate",
        label: "Announce PLN Affiliate",
      },
      {
        value: "Cross Promotion Vlog/Blog/Social Media",
        label: "Cross Promotion Vlog/Blog/Social Media",
      },
      {
        value: "5 Star Review from Staff",
        label: "5 Star Review from Staff",
      },
      {
        value: "Flyers in our Boxes to members",
        label: "Flyers in our Boxes to members",
      },
      {
        value: "6 Week Challenge Involvement",
        label: "6 Week Challenge Involvement",
      },
      {
        value: "Member of the Month Gift Card $10.00",
        label: "Member of the Month Gift Card $10.00",
      },
    ],
    expectationOptions: [
      {
        value: "Group Sweats",
        label: "Group Sweats",
      },
      {
        value: "Announce Affiliation on Social Media",
        label: "Announce Affiliation on Social Media",
      },
      {
        value: "Mass Email to Network",
        label: "Mass Email to Network",
      },
      {
        value: "Display Materials",
        label: "Display Materials",
      },
    ],
  };

  updateInformation() {
    this.setState({ submitting: true });

    let payload = {
      groupSweat: this.state.groupSweat,
      expectations: this.state.expectations,
      deliverables: this.state.deliverables,
    };

    APIV2.modifyAffiliate(
      this.props?.affiliate?.storeID,
      this.props?.affiliate?._id,
      payload
    )
      .then(
        (data) => {
          PubSub.publish(Event.AFFILIATE.MODIFIED, data.data.affiliate);

          this.setState({ openModify: false });
        },
        (e) => {
          this.setError(
            "error",
            "Could not update affiliate, please try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 4000);
  }

  renderList(list) {
    return (
      <ul className="m-0">
        {list.map((opt, i) => (
          <li key={i}>{opt}</li>
        ))}
      </ul>
    );
  }

  render() {
    return (
      <>
        <Card
          className={`mb-4 ${
            this.props.flat ? "shadow-none" : "shadow"
          } border`}
        >
          <CardHeader>
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Deliverables &amp; Expectations</h3>
              </div>
              {this.props.modifiable ? (
                <Col xs="auto">
                  {!this.state.openModify ? (
                    <Button
                      size="sm"
                      color="secondary"
                      outline
                      onClick={() => {
                        this.setState({
                          openModify: true,
                          groupSweat: this.props.affiliate?.groupSweat,
                          expectations: this.props.affiliate?.expectations,
                          deliverables: this.props.affiliate?.deliverables,
                        });
                      }}
                    >
                      Edit
                    </Button>
                  ) : (
                    <>
                      <Button
                        size="sm"
                        color="secondary"
                        onClick={() => {
                          this.setState({
                            openModify: false,
                          });
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="sm"
                        color="primary"
                        disabled={this.state.submitting}
                        onClick={() => {
                          this.updateInformation();
                        }}
                      >
                        {this.state.submitting ? (
                          <Spinner color="white" size="sm" />
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  )}
                </Col>
              ) : null}
            </Row>
          </CardHeader>
          {this.props.loading ? (
            <CardBody>
              <Spinner size="sm" color="dark" className="mr-2" /> Loading...
            </CardBody>
          ) : (
            <CardBody className="p-0">
              {this.state.openModify ? (
                <>
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h5 className="text-uppercase">
                          Group Sweat Opportunity
                        </h5>
                        <SelectPicker
                          placement="auto"
                          placeholder="Select An Option"
                          data={[
                            { value: "Yes", label: "Yes" },
                            { value: "No", label: "No" },
                          ]}
                          value={this.state.type}
                          cleanable={false}
                          block
                          onChange={(value, item) => {
                            this.setState({ groupSweat: value });
                          }}
                        ></SelectPicker>
                      </Col>
                    </Row>
                  </div>
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h5 className="text-uppercase">PLN Deliverables</h5>
                        <TagPicker
                          placement="auto"
                          placeholder="Select Options"
                          data={this.state.deliverableOptions}
                          value={this.state.deliverables}
                          cleanable={false}
                          block
                          onChange={(value, item) => {
                            this.setState({ deliverables: value });
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h5 className="text-uppercase">
                          Affiliate Expectations
                        </h5>
                        <TagPicker
                          placement="auto"
                          placeholder="Select Options"
                          data={this.state.expectationOptions}
                          value={this.state.expectations}
                          cleanable={false}
                          block
                          onChange={(value, item) => {
                            this.setState({ expectations: value });
                          }}
                        ></TagPicker>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                <>
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="auto">Group Sweat Opportunity:</Col>
                      <Col xs="" className="pl-0">
                        {this.props.affiliate?.groupSweat
                          ? this.props.affiliate?.groupSweat
                          : "--"}
                      </Col>
                    </Row>
                  </div>
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="auto">Deliverables:</Col>
                      <Col xs="" className="pl-0">
                        {this.props.affiliate?.deliverables?.length
                          ? this.renderList(this.props.affiliate?.deliverables)
                          : "--"}
                      </Col>
                    </Row>
                  </div>
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="auto">Affiliate Expectations:</Col>
                      <Col xs="" className="pl-0">
                        {this.props.affiliate?.expectations?.length
                          ? this.renderList(this.props.affiliate.expectations)
                          : "--"}
                      </Col>
                    </Row>
                  </div>
                </>
              )}
              {this.state.error ? (
                <Alert className="mt-3 mb-0" color="danger">
                  {this.state.error}
                </Alert>
              ) : null}
            </CardBody>
          )}
        </Card>
      </>
    );
  }
}

export default withRouter(AffiliateDeliverablesWidget);
