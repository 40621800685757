import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
  Badge,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import StoreView from "views/examples/StoreView";
import ModifyStoreBillingAccountModal from "./ModifyStoreBillingAccountModal";
import NumberFormat from "react-number-format";
import _ from "underscore";
import APIV2 from "lib/APIV2";
import ModifyStoreMealMenuModal from "./ModifyStoreMealMenuModal";
import { withRouter } from "react-router";
import Spinner from "reactstrap/lib/Spinner";
import moment from "moment";
import ModifyStoreDefaultCouponModal from "./ModifyStoreDefaultCouponModal";
import StringUtils from "lib/StringUtils";
import ModifyStoreBlackoutDatesModal from "./ModifyStoreBlackoutDatesModal";
import ModifyStoreTaxModal from "./ModifyStoreTaxModal";

class StoreViewLogisticsTab extends React.Component {
  state = {
    open: false,
    editTerritories: false,
    territories: [],
    editDedicatedTerritories: false,
    dedicatedterritories: [],
    code: {},
    firstBillDate: {},
    descMenu: {},
    submittingAdd: false,
    todayMoment: moment(),
    filteredBlackoutDates: [],
    dedicatedCode: {},
  };

  loadStore() {
    const {
      match: { params },
    } = this.props;

    APIV2.getStoreByID(params.storeID)
      .then(
        (data) => {
          if (data && data.data && data.data.store) {
            let filteredBlackoutDates = [];

            if (data.data.store?.operations?.blackoutDates?.length) {
              filteredBlackoutDates = _.filter(
                data.data.store.operations.blackoutDates,
                (date) => {
                  return (
                    date.indexOf(this.state.todayMoment.format("YYYY")) >= 0
                  );
                }
              );
            }

            this.setState({
              store: data.data.store,
              filteredBlackoutDates,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    this.loadStore();

    PubSub.subscribe(Event.STORE.MODIFIED, (s) => {
      let filteredBlackoutDates = _.filter(
        s?.operations?.blackoutDates,
        (date) => {
          return date.indexOf(this.state.todayMoment.format("YYYY")) >= 0;
        }
      );

      this.setState({
        store: s,
        couponLabel: "",
        filteredBlackoutDates,
      });

      let c = _.findWhere(this.state.coupons, {
        code: s?.operations?.defaultCoupon?.couponCode,
      });

      if (c) {
        let label = `${c.name} - ${StringUtils.couponValueString(c)}`;

        this.setState({ couponLabel: label });
      }
    });

    this.setState({
      planTypesLoading: true,
    });

    APIV2.getProductGroups()
      .then((data) => {
        let planTypes = data.data.planTypes;

        this.setState({ planTypes });
      })
      .finally(() => {
        this.setState({
          planTypesLoading: false,
        });
      });

    APIV2.getCoupons().then(
      (data) => {
        let c = _.findWhere(data.data.coupons, {
          code: this.state.store?.operations?.defaultCoupon?.couponCode,
        });

        if (c) {
          let label = `${c.name} - ${StringUtils.couponValueString(c)}`;

          this.setState({ coupons: data.data.coupons, couponLabel: label });
        }
      },
      (e) => {}
    );
  }

  deleteTerritory(zip) {
    APIV2.deleteStoreTerritory(this.state.store._id, zip).then(
      (data) => {
        PubSub.publish(Event.STORE.MODIFIED, data.data.store);
        let territories =
          data.data.store.operations && data.data.store.operations.territories
            ? data.data.store.operations.territories
            : [];

        territories = _.sortBy(territories, (t) => {});

        this.setState({
          territories: territories,
        });
      },
      (e) => {
        window.alert(
          "We were unable to remove that territory. Please try again."
        );
        console.error(e);
      }
    );
  }

  deleteDedicatedTerritory(zip) {
    let territories = this.state.dedicatedTerritories
      ? this.state.dedicatedTerritories
      : [];

    territories = _.without(territories, zip);

    APIV2.modifyStoreParameter(
      this.state.store._id,
      "dedicatedTerritories",
      territories
    ).then(
      (data) => {
        PubSub.publish(Event.STORE.MODIFIED, data.data.store);
        let territories2 = data.data.store.dedicatedTerritories
          ? data.data.store.dedicatedTerritories
          : [];

        territories2 = _.sortBy(territories2, (t) => {});

        this.setState({
          dedicatedTerritories: territories2,
        });
      },
      (e) => {
        window.alert(
          "We were unable to remove that territory. Please try again."
        );
        console.error(e);
      }
    );
  }

  addDedicatedTerritory(e) {
    e.preventDefault();

    let territories = this.state.dedicatedTerritories
      ? this.state.dedicatedTerritories
      : [];

    if (
      !territories.includes(this.state.dedicatedCode?.value) &&
      this.state.dedicatedCode?.value
    ) {
      territories.push(this.state.dedicatedCode?.value);
    }

    APIV2.modifyStoreParameter(
      this.state.store._id,
      "dedicatedTerritories",
      territories
    ).then(
      (data) => {
        PubSub.publish(Event.STORE.MODIFIED, data.data.store);
        let territories2 = data.data.store.dedicatedTerritories
          ? data.data.store.dedicatedTerritories
          : [];

        territories2 = _.sortBy(territories2, (t) => {});

        this.setState({
          dedicatedTerritories: territories2,
          dedicatedCode: {
            value: "",
          },
        });
      },
      (e) => {
        window.alert("We were unable to add that territory. Please try again.");
        console.error(e);
      }
    );
  }

  isBetween(start, end) {
    if (!start || !end) {
      return false;
    }

    start = new Date(start);
    end = new Date(end);

    let now = new Date();

    return now >= start && now <= end;
  }

  openMenuDescriptionModal(menu) {
    this.setState({
      showDescription: true,
      descMenu: menu,
    });
  }

  addTerritory(e) {
    e.preventDefault();

    if (!this.state.code.value || this.state.code.value.length != 5) {
      window.alert("Please enter a valid zip code to continue.");

      return;
    }

    this.setState({
      submittingAdd: true,
    });

    APIV2.addStoreTerritory(this.state.store._id, this.state.code.value)
      .then(
        (data) => {
          console.log(data);

          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          let territories =
            data.data.store.operations && data.data.store.operations.territories
              ? data.data.store.operations.territories
              : [];

          territories = _.sortBy(territories, (t) => {});

          this.setState({
            territories: territories,
            code: {
              value: "",
            },
          });
        },
        (e) => {
          if (e.statusCode != 409) {
            console.error(e);
            window.alert(
              "We were unable to add the territory. Please try again."
            );

            return;
          }

          window.alert("This territory is already assigned to this store.");
        }
      )
      .finally(() => {
        this.setState({
          submittingAdd: false,
        });
      });
  }

  handleCodeChange(val) {
    this.setState({
      code: val,
    });
  }

  handleDedicatedCodeChange(val) {
    this.setState({
      dedicatedCode: val,
    });
  }

  handleFirstCouponChange(e) {
    const val = e.target.value;

    this.setState({
      firstBillCoupon: val,
    });
  }

  handleFirstBillDateChange(val) {
    this.setState({
      firstBillDate: val,
    });
  }

  closeDescriptionModal() {
    this.setState({
      showDescription: false,
    });
  }

  getMenuForPlanType(id) {
    return null;
  }

  renderTax(tax) {
    if (!tax) {
      return "0.00%";
    }

    return `${(tax * 100).toFixed(3)}%`;
  }

  setFulfillmentStrategy() {
    this.setState({
      strategySubmitting: true,
    });

    // TODO: implement NSO update here

    APIV2.modifyStoreParameter(
      this.state?.store?._id,
      "fulfillmentStrategy",
      this.state.fulfillmentStrategy
    )
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          this.setState({
            modifyStrategy: false,
          });
        },
        (e) => {
          window.alert(
            "Unable to modify Fulfillment Strategy: " + e?.message ??
              "error occurred."
          );
        }
      )
      .finally(() => {
        this.setState({
          strategySubmitting: false,
        });
      });
  }

  setProductOrderStrategy() {
    this.setState({
      orderStrategySubmitting: true,
    });

    // TODO: implement NSO update here

    APIV2.modifyStoreParameter(
      this.state?.store?._id,
      "productOrderStrategy",
      this.state.orderStrategy
    )
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          this.setState({
            modifyOrderStrategy: false,
          });
        },
        (e) => {
          window.alert(
            "Unable to modify product order strategy: " + e?.message ??
              "error occurred."
          );
        }
      )
      .finally(() => {
        this.setState({
          orderStrategySubmitting: false,
        });
      });
  }

  render() {
    return (
      <>
        <>
          <div className="row">
            <div className="col-12 mb-3">
              <Card className=" shadow">
                <CardHeader className="bg-transparent">
                  <Row>
                    <div className="col">
                      <h3 className="mb-0">
                        All Territories
                        {!this.state.editTerritories &&
                        this.state.store &&
                        this.state.store.operations &&
                        this.state.store.operations.territories &&
                        this.state.store.operations.territories.length ? (
                          <span className="badge ml-2 badge-medium">
                            {this.state.store.operations.territories.length}{" "}
                            assigned
                          </span>
                        ) : null}
                      </h3>
                      <p className="small text-dark mb-0">
                        All territories that are dedicated, allocated, or
                        pivoted for this store.
                      </p>
                    </div>
                    <div className="col-auto text-right">
                      {this.state.editTerritories ? (
                        <>
                          <Button
                            onClick={() => {
                              this.setState({
                                editTerritories: false,
                              });
                            }}
                            className="btn-sm btn"
                            color="light"
                          >
                            Done
                          </Button>
                        </>
                      ) : (
                        <>
                          {this.state.store?.operations?.territories?.length ? (
                            <Button
                              onClick={() => {
                                this.setState({
                                  editTerritories: true,
                                  territories: this.state.store.operations
                                    .territories
                                    ? this.state.store.operations.territories
                                    : [],
                                });
                              }}
                              className="btn-sm btn"
                              color="secondary"
                              outline
                            >
                              Modify
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                this.setState({
                                  editTerritories: true,
                                  territories: this.state.store.operations
                                    .territories
                                    ? this.state.store.operations.territories
                                    : [],
                                });
                              }}
                              className="btn-sm btn"
                              color="primary"
                            >
                              Add
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {this.state.editTerritories ? (
                    <>
                      <Form onSubmit={this.addTerritory.bind(this)}>
                        <Row>
                          <div className="col">
                            <NumberFormat
                              allowLeadingZeros={true}
                              className="form-control mb-3"
                              format="#####"
                              mask=""
                              name="zipInput"
                              placeholder="Zip Code"
                              onValueChange={this.handleCodeChange.bind(this)}
                              value={this.state.code.value}
                              type="tel"
                            />
                          </div>
                          <div className="col-auto">
                            <Button
                              color="primary"
                              className="btn"
                              type="submit"
                              disabled={this.state.submittingAdd}
                            >
                              Add
                            </Button>
                          </div>
                          <div className="col-12">
                            <hr className="mt-1 mb-3"></hr>
                          </div>
                          {this.state.territories.map((t, index) => (
                            <div className="col-3 mb-2" key={index}>
                              {t}{" "}
                              <i
                                onClick={() => this.deleteTerritory(t)}
                                className="mdi mdi-close text-danger cursor-pointer"
                              ></i>
                            </div>
                          ))}
                        </Row>
                      </Form>
                    </>
                  ) : (
                    <>
                      {this.state.store &&
                      this.state.store.operations &&
                      this.state.store.operations.territories &&
                      this.state.store.operations.territories.length ? (
                        <Row>
                          {this.state.store.operations.territories.map(
                            (t, index) => (
                              <div className="col-3 mb-2" key={index}>
                                {t}
                              </div>
                            )
                          )}
                        </Row>
                      ) : (
                        "(None)"
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
            </div>
            <div className="col-12 mb-3">
              <Card className=" shadow">
                <CardHeader className="bg-transparent">
                  <Row>
                    <div className="col">
                      <h3 className="mb-0">
                        Dedicated Territories
                        {!this.state.editDedicatedTerritories &&
                        this.state.store?.dedicatedTerritories?.length ? (
                          <span className="badge ml-2 badge-medium">
                            {this.state.store.dedicatedTerritories.length}{" "}
                            assigned
                          </span>
                        ) : null}
                      </h3>
                      <p className="small text-dark mb-0">
                        Territories that are solely dedicated to this store.
                      </p>
                    </div>
                    <div className="col-auto text-right">
                      {this.state.editDedicatedTerritories ? (
                        <>
                          <Button
                            onClick={() => {
                              this.setState({
                                editDedicatedTerritories: false,
                              });
                            }}
                            className="btn-sm btn"
                            color="primary"
                          >
                            Done
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            onClick={() => {
                              this.setState({
                                editDedicatedTerritories: true,
                                dedicatedTerritories: this.state.store
                                  ?.dedicatedTerritories
                                  ? this.state.store.dedicatedTerritories
                                  : [],
                              });
                            }}
                            className="btn-sm btn"
                            color="secondary"
                            outline
                          >
                            Modify
                          </Button>
                        </>
                      )}
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {this.state.editDedicatedTerritories ? (
                    <>
                      <Form onSubmit={this.addDedicatedTerritory.bind(this)}>
                        <Row>
                          <div className="col">
                            <NumberFormat
                              allowLeadingZeros={true}
                              className="form-control mb-3"
                              format="#####"
                              mask=""
                              name="zipInput"
                              placeholder="Zip Code"
                              onValueChange={this.handleDedicatedCodeChange.bind(
                                this
                              )}
                              value={this.state.dedicatedCode.value}
                              type="tel"
                            />
                          </div>
                          <div className="col-auto">
                            <Button
                              color="primary"
                              className="btn"
                              type="submit"
                              disabled={this.state.submittingAdd}
                            >
                              Add
                            </Button>
                          </div>
                          <div className="col-12">
                            <hr className="mt-1 mb-3"></hr>
                          </div>
                          {this.state.dedicatedTerritories.map((t, index) => (
                            <div className="col-3 mb-2" key={index}>
                              {t}{" "}
                              <i
                                onClick={() => this.deleteDedicatedTerritory(t)}
                                className="mdi mdi-close text-danger cursor-pointer"
                              ></i>
                            </div>
                          ))}
                        </Row>
                      </Form>
                    </>
                  ) : (
                    <>
                      {this.state.store?.dedicatedTerritories?.length ? (
                        <Row>
                          {this.state.store.dedicatedTerritories.map(
                            (t, index) => (
                              <div className="col-3 mb-2" key={index}>
                                {t}
                              </div>
                            )
                          )}
                        </Row>
                      ) : (
                        "(None)"
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
            </div>

            <div className="col-12 mb-3">
              <Card className=" shadow">
                <CardHeader className="bg-transparent">
                  <Row>
                    <div className="col">
                      <h3 className="mb-0">Billing Account</h3>
                    </div>
                    <div className="col-auto text-right">
                      {this.state.store &&
                      this.state.store.operations &&
                      this.state.store.operations.billingAccount ? (
                        <Button
                          onClick={() => {
                            PubSub.publish(
                              Event.STORE.MODIFY_BILLING_ACCOUNT_OPEN,
                              this.state.store
                            );
                          }}
                          className="btn-sm btn"
                          color="secondary"
                          outline
                        >
                          Modify
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            PubSub.publish(
                              Event.STORE.MODIFY_BILLING_ACCOUNT_OPEN,
                              this.state.store
                            );
                          }}
                          className="btn-sm btn"
                          color="primary"
                        >
                          <i className="mdi mdi-plus"></i> Add
                        </Button>
                      )}
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <p className="mb-0">
                    {this.state.store &&
                    this.state.store.operations &&
                    this.state.store.operations.billingAccount ? (
                      <a href="/admin/billing-accounts">
                        {this.state.store.operations.billingAccount.name}
                        <i className="mdi mdi-chevron-right"></i>
                      </a>
                    ) : (
                      "(None)"
                    )}
                  </p>
                </CardBody>
              </Card>
            </div>

            <div className="col-12 mb-3">
              <Card className=" shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Default Coupon</h3>
                      <p className="m-0">
                        <small>
                          Adds a coupon to every new online order automatically.
                        </small>
                      </p>
                    </div>
                    <div className="col-auto text-right">
                      {this.state.store?.operations?.defaultCoupon
                        ?.couponCode ? (
                        <>
                          <Button
                            onClick={() => {
                              APIV2.setStoreDefaultCoupon(
                                this.state.store?._id,
                                "",
                                null,
                                null
                              ).then((data) => {
                                PubSub.publish(
                                  Event.STORE.MODIFIED,
                                  data.data.store
                                );
                              });
                            }}
                            className="btn-sm btn"
                            color="danger"
                            outline
                          >
                            Remove
                          </Button>
                          <Button
                            onClick={() => {
                              PubSub.publish(
                                Event.STORE.MODIFY_DEFAULT_COUPON_OPEN,
                                this.state.store
                              );
                            }}
                            className="btn-sm btn"
                            color="secondary"
                            outline
                          >
                            Modify
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => {
                            PubSub.publish(
                              Event.STORE.MODIFY_DEFAULT_COUPON_OPEN,
                              this.state.store
                            );
                          }}
                          className="btn-sm btn"
                          color="primary"
                        >
                          Add
                        </Button>
                      )}
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row className="align-items-center">
                    <Col xs="3" sm="4" md="3" className="font-weight-bold">
                      Coupon
                    </Col>
                    <Col>
                      <p className="m-0">
                        {this.state.store?.operations?.defaultCoupon?.couponCode
                          ? this.state.couponLabel
                            ? this.state.couponLabel
                            : this.state.store?.operations?.defaultCoupon
                                ?.couponCode
                          : "--"}
                      </p>
                    </Col>
                  </Row>
                  <hr className="my-3" />
                  <Row className="align-items-center">
                    <Col xs="3" sm="4" md="3" className="font-weight-bold">
                      Available Between
                    </Col>
                    <Col>
                      <p className="m-0">
                        {this.state.store?.operations?.defaultCoupon
                          ?.startDate ? (
                          <>
                            {this.isBetween(
                              this.state.store?.operations?.defaultCoupon
                                ?.startDate,
                              this.state.store?.operations?.defaultCoupon
                                ?.endDate
                            ) ? (
                              <>
                                <Badge color="success" className="mr-2">
                                  ACTIVE
                                </Badge>
                              </>
                            ) : (
                              <>
                                <Badge color="light" className="mr-2">
                                  INACTIVE
                                </Badge>
                              </>
                            )}
                            {this.state.store?.operations?.defaultCoupon
                              ?.startDate
                              ? moment(
                                  this.state.store?.operations?.defaultCoupon
                                    ?.startDate
                                ).format("MMM D, YYYY")
                              : "--"}{" "}
                            -{" "}
                            {this.state.store?.operations?.defaultCoupon
                              ?.endDate
                              ? moment(
                                  this.state.store?.operations?.defaultCoupon
                                    ?.endDate
                                ).format("MMM D, YYYY")
                              : "--"}
                          </>
                        ) : (
                          "--"
                        )}
                      </p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>

            <div className="col-12 mb-3">
              <Card className=" shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Tax Collection</h3>
                      <p className="m-0">
                        <small>Apply tax to subscription invoices.</small>
                      </p>
                    </div>
                    <div className="col-auto text-right">
                      <Button
                        onClick={() => {
                          PubSub.publish(
                            Event.STORE.MODIFY_TAX_OPEN,
                            this.state.store
                          );
                        }}
                        className="btn-sm btn"
                        color="secondary"
                        outline
                      >
                        Modify
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row className="align-items-center">
                    <Col xs="3" sm="4" md="3" className="font-weight-bold">
                      Tax Applied
                    </Col>
                    <Col>
                      <p className="m-0">
                        {this.state.store?.taxApplied ? "Yes" : "No"}
                      </p>
                    </Col>
                  </Row>
                  <hr className="my-3" />
                  <Row className="align-items-center">
                    <Col xs="3" sm="4" md="3" className="font-weight-bold">
                      Description
                    </Col>
                    <Col>
                      <p className="m-0">
                        {this.state.store?.taxApplied &&
                        this.state.store?.taxDescription
                          ? this.state.store?.taxDescription
                          : "--"}
                      </p>
                    </Col>
                  </Row>
                  <hr className="my-3" />
                  <Row className="align-items-center">
                    <Col xs="3" sm="4" md="3" className="font-weight-bold">
                      Tax Rate
                    </Col>
                    <Col>
                      <p className="m-0">
                        {this.state.store?.taxApplied
                          ? this.renderTax(this.state.store.taxRate)
                          : "--"}
                      </p>
                    </Col>
                  </Row>
                  <hr className="my-3" />
                  <Row className="align-items-center">
                    <Col xs="3" sm="4" md="3" className="font-weight-bold">
                      Applied To
                    </Col>
                    <Col>
                      <p className="m-0">
                        {!this.state.store?.taxApplied && "--"}
                        {this.state.store?.taxApplied &&
                          this.state.store?.taxMethod == "total" &&
                          "Invoice Subtotal (Plan Unit Price + Delivery/Shipping Fee)"}
                        {this.state.store?.taxApplied &&
                          this.state.store?.taxMethod == "unitPrice" &&
                          "Plan Unit Price"}
                      </p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
            <div className="col-12 mb-3">
              <Card className="shadow">
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="">
                      <h3 className="mb-0">Fulfillment Strategy</h3>
                      <p className="small mb-0">
                        How does this store fulfill it's subscriptions
                      </p>
                    </Col>
                    <Col xs="auto">
                      {this.state.modifyStrategy ? (
                        <>
                          <Button
                            onClick={() => {
                              this.setState({
                                modifyStrategy: false,
                              });
                            }}
                            className="btn-sm btn"
                            color="secondary"
                            outline
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              this.setFulfillmentStrategy();
                            }}
                            disabled={
                              this.state.strategySubmitting ||
                              !this.state.fulfillmentStrategy
                            }
                            className="btn-sm btn"
                            color="primary"
                          >
                            {this.state.strategySubmitting ? (
                              <>
                                <Spinner size="sm"></Spinner>
                              </>
                            ) : (
                              <>Save</>
                            )}
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => {
                            this.setState({
                              modifyStrategy: true,
                              fulfillmentStrategy:
                                this.state.store?.fulfillmentStrategy ?? "",
                            });
                          }}
                          className="btn-sm btn"
                          color="secondary"
                          outline
                        >
                          Modify
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {this.state.modifyStrategy ? (
                    <>
                      <Input
                        type="select"
                        name="fulfillmentStrategy"
                        onChange={(e) => {
                          this.setState({
                            fulfillmentStrategy: e?.target?.value,
                          });
                        }}
                        value={this.state.fulfillmentStrategy}
                      >
                        <option disabled value="">
                          Select A Strategy
                        </option>
                        <option value="In-Store Fulfillment">
                          In-Store Fulfillment
                        </option>
                        <option value="LeanLife Fulfillment">
                          LeanLife Fulfillment
                        </option>
                      </Input>
                    </>
                  ) : (
                    <>
                      <p className="m-0">
                        {this.state.store?.fulfillmentStrategy ?? "(none set)"}
                      </p>
                      <p className="m-0">
                        {this.state.store?.fulfillmentStrategy ==
                          "LeanLife Fulfillment" && (
                          <>
                            Label Color Code: {this.state.store?.labelColorCode}
                          </>
                        )}
                      </p>
                    </>
                  )}
                </CardBody>
              </Card>
            </div>
            <div className="col-12 mb-3">
              <Card className="shadow">
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="">
                      <h3 className="mb-0">Product Order Strategy</h3>
                      <p className="small mb-0">
                        What strategy does this store use to purchase product?
                      </p>
                      {this.state.store?.productOrderStrategy ==
                      "Bi-Weekly Ordering" ? (
                        <p>
                          Bi-Weekly Ordering Start Date:{" "}
                          {moment(
                            this.state.store?.biWeeklyOrderStartDate
                          ).format("MMMM Do, YYYY")}
                        </p>
                      ) : null}
                    </Col>
                    <Col xs="auto">
                      {this.state.modifyOrderStrategy ? (
                        <>
                          <Button
                            onClick={() => {
                              this.setState({
                                modifyOrderStrategy: false,
                              });
                            }}
                            className="btn-sm btn"
                            color="secondary"
                            outline
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              this.setProductOrderStrategy();
                            }}
                            disabled={
                              this.state.orderStrategySubmitting ||
                              !this.state.orderStrategy
                            }
                            className="btn-sm btn"
                            color="primary"
                          >
                            {this.state.orderStrategySubmitting ? (
                              <>
                                <Spinner size="sm"></Spinner>
                              </>
                            ) : (
                              <>Save</>
                            )}
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => {
                            this.setState({
                              modifyOrderStrategy: true,
                              orderStrategy:
                                this.state.store?.productOrderStrategy ?? "",
                            });
                          }}
                          className="btn-sm btn"
                          color="secondary"
                          outline
                        >
                          Modify
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {this.state.modifyOrderStrategy ? (
                    <>
                      <Input
                        type="select"
                        name="orderStrategy"
                        onChange={(e) => {
                          this.setState({
                            orderStrategy: e?.target?.value,
                          });
                        }}
                        value={this.state.orderStrategy}
                      >
                        <option disabled value="">
                          Select A Strategy
                        </option>
                        <option value="Weekly Ordering">Weekly Ordering</option>
                        <option value="Bi-Weekly Ordering">
                          Bi-Weekly Ordering
                        </option>
                      </Input>
                    </>
                  ) : (
                    <>
                      <p className="m-0">
                        {this.state.store?.productOrderStrategy ?? "(none set)"}
                      </p>
                    </>
                  )}
                </CardBody>
              </Card>
            </div>
            <div className="col-12 mb-3">
              <Card className=" shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">
                        {this.state.todayMoment.format("YYYY")} Annual Blackout
                        Dates
                      </h3>
                      <p className="m-0">
                        <small>
                          Dates where fulfillment will not occur this calendar
                          year.
                        </small>
                      </p>
                    </div>
                    <div className="col-auto text-right">
                      <Button
                        onClick={(e) => {
                          e.preventDefault();

                          console.log("here");

                          PubSub.publish(
                            Event.STORE.MODIFY_BLACKOUT_OPEN,
                            this.state.store
                          );
                        }}
                        size="sm"
                        color="secondary"
                        outline
                      >
                        Modify
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row className="align-items-center">
                    <Col>
                      {this.state.filteredBlackoutDates?.length ? (
                        <>
                          {this.state.filteredBlackoutDates.map((date, i) => (
                            <p>{moment(date).format("MMMM Do YYYY")}</p>
                          ))}
                        </>
                      ) : (
                        "--"
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>

            <div className="col">
              <Card className=" shadow">
                <CardHeader className="bg-transparent">
                  <Row>
                    <div className="col">
                      <h3 className="mb-0">Menus</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {this.state.planTypesLoading ? (
                    <Spinner size="sm" color="dark" />
                  ) : null}
                  {this.state.planTypes && this.state.planTypes?.length
                    ? this.state.planTypes.map((type, i) => (
                        <div key={i}>
                          <Row className="align-items-center">
                            <Col
                              xs="3"
                              sm="4"
                              md="3"
                              className="font-weight-bold"
                            >
                              {type.name}
                            </Col>
                            <Col>
                              {this.state.store &&
                              this.state.store.menus &&
                              this.state.store.menus.meals &&
                              this.state.store.menus.meals.hasOwnProperty(
                                type._id
                              ) &&
                              this.state.store.menus.meals[type._id] &&
                              this.state.store.menus.meals[type._id].active ? (
                                <p className="m-0">
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.openMenuDescriptionModal(
                                        this.state.store.menus.meals[type._id]
                                          .active
                                      );
                                    }}
                                  >
                                    {
                                      this.state.store.menus.meals[type._id]
                                        .active.name
                                    }
                                  </a>
                                </p>
                              ) : (
                                <p className="m-0">No menu.</p>
                              )}
                            </Col>
                            <Col xs="auto">
                              <div className="col-auto text-right">
                                {this.state.store &&
                                this.state.store.menus &&
                                this.state.store.menus.meals &&
                                this.state.store.menus.meals.hasOwnProperty(
                                  type._id
                                ) &&
                                this.state.store.menus.meals[type._id] &&
                                this.state.store.menus.meals[type._id]
                                  .active ? (
                                  <Button
                                    onClick={() => {
                                      PubSub.publish(
                                        Event.STORE.MODIFY_MENU_OPEN,
                                        {
                                          planType: type,
                                          store: this.state.store,
                                        }
                                      );
                                    }}
                                    className="btn-sm btn"
                                    color="secondary"
                                    outline
                                  >
                                    Modify
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      PubSub.publish(
                                        Event.STORE.MODIFY_MENU_OPEN,
                                        {
                                          planType: type,
                                          store: this.state.store,
                                        }
                                      );
                                    }}
                                    className="btn-sm btn"
                                    color="primary"
                                  >
                                    <i className="mdi mdi-plus"></i> Add
                                  </Button>
                                )}
                              </div>
                            </Col>
                          </Row>
                          {i != this.state.planTypes?.length - 1 ? (
                            <hr></hr>
                          ) : null}
                        </div>
                      ))
                    : null}
                </CardBody>
              </Card>
            </div>
          </div>
        </>
        <ModifyStoreBlackoutDatesModal
          store={this.state.store}
        ></ModifyStoreBlackoutDatesModal>
        <ModifyStoreBillingAccountModal
          store={this.state.store}
        ></ModifyStoreBillingAccountModal>
        <ModifyStoreDefaultCouponModal
          store={this.state.store}
        ></ModifyStoreDefaultCouponModal>
        <ModifyStoreMealMenuModal
          store={this.state.store}
        ></ModifyStoreMealMenuModal>
        <ModifyStoreTaxModal store={this.state.store}></ModifyStoreTaxModal>

        <Modal
          isOpen={this.state.showDescription}
          toggle={() => this.closeDescriptionModal()}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="createBAModalLabel">
              {this.state.descMenu.name}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.closeDescriptionModal()}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <h5>Menu Name</h5>
            <p>{this.state.descMenu.name}</p>
            <h5>Product Category</h5>
            <p>
              {this.state.descMenu.planType
                ? this.state.descMenu.planType.name
                : ""}
            </p>
            <h5>Meals</h5>
            <ul>
              {this.state.descMenu.meals
                ? this.state.descMenu.meals.map((c, i) => <li>{c.name}</li>)
                : ""}
              {!this.state.descMenu.meals || !this.state.descMenu.meals?.length
                ? "(none)"
                : ""}
            </ul>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              className="mr-auto"
              type="button"
              onClick={() => this.closeDescriptionModal()}
            >
              Close
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default withRouter(StoreViewLogisticsTab);
