import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import Alert from "reactstrap/lib/Alert";
import CustomerBillingAddressWidget from "./CustomerBillingAddressWidget";
import CustomerContactInformationWidget from "./CustomerContactInformationWidget";
import CustomerNotesWidget from "./CustomerNotesWidget";
import CustomerOpenActivitiesWidget from "./CustomerOpenActivitiesWidget";
import CustomerPreviewWidget from "./CustomerPreviewWidget";
import CustomerShippingAddressWidget from "./CustomerShippingAddressWidget";
import CustomerStoreAssignmentWidget from "./CustomerStoreAssignmentWidget";
import CustomerUnclaimedBoxWidget from "./CustomerUnclaimedBoxWidget";
import LeadConsultationsWidget from "./LeadConsultationsWidget";
import LeadNotesWidget from "./LeadNotesWidget";
import LeadSourceStatusWidget from "./LeadSourceStatusWidget";
import LeadGoalNotesWidget from "./LeadGoalNotesWidget";
import FranDevLeadStageWidget from "./FranDevLeadStageWidget";
import FranDevLeadSourceWidget from "./FranDevLeadSourceWidget";
import FranDevLeadNotesWidget from "./FranDevLeadNotesWidget";
import FranDevLeadDatesWidget from "./FranDevLeadDatesWidget";
import FranDevContactsWidget from "./FranDevContactsWidget";
import FranDevMarketsWidget from "./FranDevMarketsWidget";
import FranDevBusinessesWidget from "./FranDevBusinessesWidget";
import FranDevLeadNameWidget from "./FranDevLeadNameWidget";

class FranDevLeadSummaryWidget extends React.Component {
  state = {
    expand: false,
    phone: {
      value: "",
    },
    name: "",
    email: "",
  };

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 4000);
  }

  render() {
    return (
      <>
        {this.props.loading ? (
          <>
            <Card className="border shadow mb-4">
              <CardBody className="bg-transparent py-5 text-center">
                <Spinner size="lg" color="medium"></Spinner>
              </CardBody>
            </Card>
          </>
        ) : (
          <>
            {/*<CustomerOpenActivitiesWidget customer={this.props.customer} />*/}
            <FranDevLeadNameWidget
              lead={this.props.lead}
              loading={this.props.loading}
            ></FranDevLeadNameWidget>
            <FranDevLeadStageWidget
              lead={this.props.lead}
              loading={this.props.loading}
            ></FranDevLeadStageWidget>
            <FranDevLeadSourceWidget
              lead={this.props.lead}
              loading={this.props.loading}
            ></FranDevLeadSourceWidget>
            <FranDevLeadDatesWidget
              lead={this.props.lead}
              loading={this.props.loading}
            ></FranDevLeadDatesWidget>
            <FranDevContactsWidget
              lead={this.props.lead}
              loading={this.props.loading}
            ></FranDevContactsWidget>
            <FranDevMarketsWidget
              lead={this.props.lead}
              loading={this.props.loading}
            ></FranDevMarketsWidget>
            <FranDevBusinessesWidget
              lead={this.props.lead}
              loading={this.props.loading}
            ></FranDevBusinessesWidget>
          </>
        )}
      </>
    );
  }
}

export default withRouter(FranDevLeadSummaryWidget);
