import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  Card,
  DeltaBar,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
  Col as TCol,
  ColGrid,
  DonutChart,
  Bold,
  List,
  ListItem,
  BarList,
  BarChart,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import ChartMetricHeader from "./ChartMetricHeader";
import ScorecardOutreachDetailDrawer from "./ScorecardOutreachDetailDrawer";

class OutreachKPICard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    active: "A",
    typeList: [],
    nameList: [],
    utmSourceList: [],
    utmCampaignList: [],
  };

  /**
   * Fetches a summary of all the member data
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchData(cubejsApi, stores, dateRange, metricNames = []) {
    let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

    secondDifference = secondDifference / 1000;

    let granularity = secondDifference > 60 * 60 * 24 * 90 ? "week" : "day";

    if (secondDifference >= 60 * 60 * 24 * 364) {
      granularity = "month";
    }

    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["Appointments.count"],
          timeDimensions: [
            {
              dimension: "Appointments.startsat",
              dateRange: [dateRange[0], dateRange[1]],
              granularity,
            },
          ],
          order: {
            "Appointments.startsat": "asc",
          },
          filters: [
            {
              member: "Appointments.storeid",
              operator: "equals",
              values: stores,
            },
            {
              member: "Appointments.appointmenttype",
              operator: "equals",
              values: metricNames,
            },
            {
              member: "Appointments.status",
              operator: "equals",
              values: ["COMPLETED"],
            },
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          let total = 0;

          if (!data?.length) {
            return resolve(null);
          }

          data = data.map((item) => {
            total += item["Appointments.count"];

            let mom = moment(item[`Appointments.startsat.${granularity}`]);

            let granularityString = mom.format("MM/DD/YYYY");

            if (granularity == "week") {
              mom.day(0);
              granularityString = "Week " + mom.format("MM/DD/YYYY");
            }

            if (granularity == "month") {
              mom.startOf("month");

              granularityString = mom.format("MMMM YY");
            }

            return {
              count: item["Appointments.count"],
              dateObj: mom.toDate(),
              date: granularityString,
            };
          });

          return resolve({
            total,
            history: data,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async _fetchReport(stores, dateRange) {
    this.setState({
      loading: true,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    if (!stores?.length) {
      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchData(cubejsApi, stores, dateRange, [
        "or-popup",
        "or-groupsweat",
        "or-dropin",
        "or-inreach",
      ]);
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load new member quantity.",
        loading: false,
      });

      return;
    }

    console.log("Outreach", currentCount);

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });
    }

    if (this.props.comparePrevious) {
      let previousCount = null;

      let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

      secondDifference = secondDifference / 1000;

      let granularity = secondDifference > 60 * 60 * 24 * 90 ? "week" : "day";

      if (secondDifference >= 60 * 60 * 24 * 364) {
        granularity = "month";
      }

      let startMoment = moment(dateRange[0].toISOString());
      let endMoment = moment(dateRange[1].toISOString());

      startMoment.subtract(secondDifference, "seconds");
      endMoment.subtract(secondDifference + 1, "seconds");

      try {
        previousCount = await this._fetchData(
          cubejsApi,
          stores,
          [startMoment.toDate(), endMoment.toDate()],
          ["or-popup", "or-groupsweat", "or-dropin", "or-inreach"]
        );
      } catch (e) {}

      let popupHistory = [];
      let groupSweatHistory = [];
      let dropinHistory = [];
      let inreachHistory = [];

      try {
        let res = await this._fetchData(cubejsApi, stores, dateRange, [
          "or-popup",
        ]);

        popupHistory = res?.history;
      } catch (e) {}

      try {
        let res = await this._fetchData(cubejsApi, stores, dateRange, [
          "or-groupsweat",
        ]);

        groupSweatHistory = res?.history;
      } catch (e) {}

      try {
        let res = await this._fetchData(cubejsApi, stores, dateRange, [
          "or-dropin",
        ]);

        dropinHistory = res?.history;
      } catch (e) {}

      try {
        let res = await this._fetchData(cubejsApi, stores, dateRange, [
          "or-inreach",
        ]);

        inreachHistory = res?.history;
      } catch (e) {}

      let dataHistory = [];

      for (let i = 0; i < popupHistory?.length; i++) {
        const cur = popupHistory[i];

        let out = {
          dateObj: cur?.dateObj,
          date: cur?.date,
          "Pop-Up Events": cur?.count,
          "Group Sweats": 0,
          "Drop-Ins": 0,
          "InReach Events": 0,
        };

        dataHistory.push(out);
      }

      for (let i = 0; i < groupSweatHistory?.length; i++) {
        const cur = groupSweatHistory[i];

        const idx = _.findIndex(dataHistory, { date: cur?.date });

        if (idx >= 0) {
          dataHistory[idx]["Group Sweats"] = cur?.count;
        } else {
          let out = {
            dateObj: cur?.dateObj,
            date: cur?.date,
            "Pop-Up Events": 0,
            "Group Sweats": cur?.count,
            "Drop-Ins": 0,
            "InReach Events": 0,
          };

          dataHistory.push(out);
        }
      }

      for (let i = 0; i < dropinHistory?.length; i++) {
        const cur = dropinHistory[i];

        const idx = _.findIndex(dataHistory, { date: cur?.date });

        if (idx >= 0) {
          dataHistory[idx]["Drop-Ins"] = cur?.count;
        } else {
          let out = {
            dateObj: cur?.dateObj,
            date: cur?.date,
            "Pop-Up Events": 0,
            "Group Sweats": 0,
            "Drop-Ins": cur?.count,
            "InReach Events": 0,
          };

          dataHistory.push(out);
        }
      }

      for (let i = 0; i < inreachHistory?.length; i++) {
        const cur = inreachHistory[i];

        const idx = _.findIndex(dataHistory, { date: cur?.date });

        if (idx >= 0) {
          dataHistory[idx]["InReach Events"] = cur?.count;
        } else {
          let out = {
            dateObj: cur?.dateObj,
            date: cur?.date,
            "Pop-Up Events": 0,
            "Group Sweats": 0,
            "Drop-Ins": 0,
            "InReach Events": cur?.count,
          };

          dataHistory.push(out);
        }
      }

      startMoment = moment(dateRange[0]);
      endMoment = moment(dateRange[1]);

      let tempDate = moment(dateRange[0])
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      if (granularity == "week") {
        tempDate.day(0);
      }

      if (granularity == "month") {
        tempDate.startOf("month");
      }

      while (tempDate.toDate() <= endMoment.toDate()) {
        let granularityString = tempDate.format("MM/DD/YYYY");

        if (granularity == "week") {
          tempDate.day(0);
          granularityString = "Week " + granularityString;
        }

        if (granularity == "month") {
          tempDate.startOf("month");
          granularityString = tempDate.format("MMMM YY");
        }

        if (!_.findWhere(dataHistory, { date: granularityString })) {
          dataHistory.push({
            dateObj: tempDate.toDate(),
            date: granularityString,
            "Pop-Up Events": 0,
            "Group Sweats": 0,
            "Drop-Ins": 0,
            "InReach Events": 0,
          });
        }

        if (granularity == "day") {
          tempDate.add(1, "day");
        } else if (granularity == "week") {
          tempDate.add(1, "week");
        } else if (granularity == "month") {
          tempDate.add(1, "month");
        }
      }

      if (previousCount !== null) {
        let percentChange =
          previousCount?.total > 0
            ? (currentCount?.total - previousCount?.total) /
              previousCount?.total
            : null;

        let declineMode = "";
        let isNegative = false;

        if (percentChange > 0) {
          if (Math.abs(percentChange) < 0.015) {
            declineMode = "unchanged";
          } else if (Math.abs(percentChange) < 0.1) {
            declineMode = "moderateIncrease";
          } else if (Math.abs(percentChange) >= 0.1) {
            declineMode = "increase";
          }
        } else {
          isNegative = true;

          if (Math.abs(percentChange) < 0.015) {
            declineMode = "moderateDecrease";
          } else if (Math.abs(percentChange) < 0.1) {
            declineMode = "moderateDecrease";
          } else if (Math.abs(percentChange) >= 0.1) {
            declineMode = "decrease";
          }
        }

        this.setState({
          previousCount,
          changeIsNegative: isNegative,
          deltaType: declineMode,
          percentChange,
          percentChangeString: Math.abs(percentChange * 100).toFixed(1) + "%",
        });
      }

      dataHistory = _.sortBy(dataHistory, "dateObj");

      this.setState({
        dataHistory,
      });
    }

    this.setState({
      currentCount,
      loading: false,
    });
  }

  /**
   * Load all report information
   *
   * @param {*} stores
   * @param {*} dateRange
   * @param {*} compare
   */
  async loadReport(stores, dateRange, compare) {
    this.setState({
      loading: true,
    });

    this._fetchReport(stores, dateRange);
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.stores != prevProps?.stores && this.props.stores?.length) ||
      this.props.dateRange != prevProps?.dateRange ||
      this.props.comparePrevious != prevProps.comparePrevious
    ) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  componentDidMount() {
    if (this.props.stores && this.props.dateRange) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  render() {
    return (
      <>
        <Card marginTop="mt-0" hFull={true}>
          <ChartMetricHeader
            forceWrapComparison={true}
            title="Community Events"
            actions={
              <>
                <Button
                  size="sm"
                  outline
                  color="dark"
                  className="border-0 btn-icon-only"
                  disabled={this.state.loading || !this.state.dataAvailable}
                  onClick={() => {
                    this.setState({
                      open: true,
                    });
                  }}
                >
                  <i className="mdi mdi-fullscreen"></i>
                </Button>
              </>
            }
            loading={this.state.loading}
            dataAvailable={this.state.dataAvailable}
            metric={`${StringUtils.numberFormat(
              this.state.currentCount?.total
            )} Events`}
            comparisonMetric={
              this.state.previousCount !== null
                ? `${StringUtils.numberFormat(
                    this.state.previousCount?.total
                  )} Events`
                : null
            }
            dateRange={this.props.dateRange}
            deltaType={this.state.deltaType}
            percentChange={this.state.percentChangeString}
            showPercentChange={true}
          ></ChartMetricHeader>

          {this.state.loading ? (
            <>
              <div
                className="skeleton rounded"
                style={{ width: "100%", height: 320, marginTop: "1.5rem" }}
              >
                &nbsp;
              </div>
            </>
          ) : (
            <BarChart
              data={this.state.dataHistory}
              dataKey="date"
              categories={[
                "Pop-Up Events",
                "Group Sweats",
                "Drop-Ins",
                "InReach Events",
              ]}
              colors={["orange", "green", "purple", "blue"]}
              stack={true}
              valueFormatter={(number) => {
                return StringUtils.numberFormat(number) + " Events";
              }}
              marginTop="mt-6"
              yAxisWidth="w-14"
            />
          )}
        </Card>
        <ScorecardOutreachDetailDrawer
          open={this.state.open}
          onClose={() => {
            this.setState({
              open: false,
            });
          }}
          stores={this.props.stores}
          dateRange={this.props.dateRange}
        />
      </>
    );
  }
}

export default OutreachKPICard;
