import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import StorefrontCreateStaffMemberDrawer from "components/Drawers/StorefrontCreateStaffMemberDrawer";
import StorefrontModifyStaffMemberDrawer from "components/Drawers/StorefrontModifyStaffMemberDrawer";
import swal from "sweetalert";
import StaffRoleDrawer from "../../components/Drawers/StaffRoleDrawer";

class StaffRolesListView extends React.Component {
  state = {
    active: {
      id: "All",
      name: "All",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 25,
    filters: null,
  };

  componentDidUpdate() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    this.loadRoles(1, this.state.count, null);

    PubSub.subscribe(Event.STAFF.CREATED, () => {
      this.loadRoles(this.state.page, this.state.count, this.state.filters);
    });

    PubSub.subscribe(Event.STAFF.MODIFIED, () => {
      this.loadRoles(this.state.page, this.state.count, this.state.filters);
    });
  }

  loadRoles(page = 1, count = 25, filters = null) {
    this.setState({
      loadingRoles: true,
    });

    APIV2.getStaffRoles(page, count, filters)
      .then(
        (data) => {
          if (data?.data?.roles) {
            let roles = data.data.roles;

            this.setState({
              roles,
              pagination: data.data.pagination,
              result: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the roles. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingRoles: false,
        });
      });
  }

  delete(role, replacementID) {
    swal({
      title: "Remove Role?",
      text: `Are you sure you want remove ${role?.name} as a role?`,
      icon: "warning",
      buttons: ["Nevermind", "Remove"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        deleteSubmitting: role?._id,
      });

      APIV2.deleteStaffRole(role?._id, this.state.replacementID)
        .then(
          (data) => {
            this.loadRoles(
              this.state.page,
              this.state.count,
              this.state.filters
            );
          },
          (e) => {
            console.error(e);
          }
        )
        .finally(() => {
          this.setState({
            deleteSubmitting: false,
          });
        });
    });
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadRoles(page, this.state.count, this.state.filters);
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader
            title="Roles"
            showBackButton={false}
            primaryAction={{
              name: <>Add</>,
              onClick: () => {
                this.setState({
                  openModify: true,
                  modifyRole: null,
                });
              },
            }}
          />

          <Row>
            <div className="col">
              <Card className=" shadow" style={{ minHeight: "500px" }}>
                <CardSelectorHeader
                  compact={true}
                  value={this.state.active}
                  options={[
                    {
                      name: "All",
                      id: "All",
                    },
                  ]}
                  onChange={() => {}}
                ></CardSelectorHeader>
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    {/*<Col xs="auto" sm="auto" className="pr-0">
                      <Checkbox
                        indeterminate={true}
                        checked={true}
                        onChange={() => {}}
                      ></Checkbox>
                </Col>*/}
                    <Col xs="" sm="" className="">
                      {this.state.searchResults ? (
                        <>
                          Found{" "}
                          {this.state.searchResultStaff?.length
                            ? StringUtils.numberFormat(
                                this.state.searchResultStaff?.length
                              )
                            : "0"}{" "}
                          roles
                          {this.state.searchResultStaff?.length == 1
                            ? ""
                            : "s"}{" "}
                          matching your search.
                        </>
                      ) : (
                        <div>
                          {this.state.result?.start &&
                          this.state.result?.end ? (
                            <>
                              Showing{" "}
                              {StringUtils.numberFormat(
                                this.state.result?.start
                              )}
                              -
                              {StringUtils.numberFormat(this.state.result?.end)}
                              {this.state.result?.total ? (
                                <>
                                  &nbsp;of{" "}
                                  {StringUtils.numberFormat(
                                    this.state.result?.total
                                  )}{" "}
                                  roles
                                </>
                              ) : null}
                            </>
                          ) : (
                            <>---</>
                          )}
                        </div>
                      )}
                    </Col>
                    <Col
                      xs="auto"
                      sm="auto"
                      className="pl-2 pl-sm-3 text-right"
                    >
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        className="btn-icon-only"
                        outline
                        disabled={
                          this.state.page == 1 ||
                          !this.state.pagination?.hasPrevious ||
                          this.state.searchResults
                        }
                        onClick={() => {
                          this.incrementPage(-1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-left"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        disabled={
                          !this.state.pagination?.hasNext ||
                          this.state.searchResults
                        }
                        className="btn-icon-only"
                        outline
                        onClick={() => {
                          this.incrementPage(1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-right"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="py-2 px-3 bg-superlight border-bottom border-top text-dark">
                    <Row className="align-items-center">
                      <Col xs="">
                        <h4 className="mb-0">Role</h4>
                      </Col>
                    </Row>
                  </div>

                  <div>
                    {this.state.loadingRoles ? (
                      <>
                        <div
                          className="text-center p-3"
                          style={{
                            zIndex: 1,
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            background: "rgba(255, 255, 255, 0.6)",
                          }}
                        >
                          <Spinner
                            style={{ position: "relative", top: "15vh" }}
                            color="medium"
                            size=""
                          ></Spinner>
                        </div>
                      </>
                    ) : null}
                    <div>
                      {!this.state.roles?.length && !this.state.loadingRoles ? (
                        <div className="px-3 py-2">
                          <Row>
                            <Col>Get started by creating your first role.</Col>
                          </Row>
                        </div>
                      ) : null}

                      {this.state.roles?.map((c, i) => (
                        <div
                          className={`px-3 py-2 bg-superlight--hover cursor-pointer ${
                            i != this.state.roles.length - 1
                              ? "border-bottom"
                              : ""
                          }`}
                          key={i}
                        >
                          <Row className="align-items-center">
                            <Col xs="">
                              <h4 className={`mb-0`}>{c.name}</h4>
                            </Col>

                            <Col xs="auto" className="text-right">
                              <Button
                                color="danger"
                                outline
                                className="btn-icon-only"
                                disabled={this.state.deleteSubmitting == c._id}
                                onClick={() => {
                                  this.delete(c);
                                }}
                                size="sm"
                              >
                                {this.state.deleteSubmitting == c._id ? (
                                  <Spinner color="danger" size="sm"></Spinner>
                                ) : (
                                  <i className="mdi mdi-close"></i>
                                )}
                              </Button>
                              <Button
                                color="secondary"
                                outline
                                className="btn-icon-only"
                                onClick={() => {
                                  this.setState({
                                    openModify: true,
                                    modifyRole: c,
                                  });
                                }}
                                size="sm"
                              >
                                <i className="mdi mdi-pencil"></i>
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        <StaffRoleDrawer
          open={this.state.openModify}
          role={this.state.modifyRole}
          onClose={() => {
            this.setState({
              openModify: false,
              modifyRole: null,
            });
          }}
          roles={this.state.roles}
        ></StaffRoleDrawer>
      </>
    );
  }
}

export default StaffRolesListView;
