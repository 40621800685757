import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  Uploader,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";

class ConversationStoreAssignmentDrawer extends React.Component {
  state = {
    dueDate: moment().add(1, "days").toDate(),
    subject: "",
    description: "",
    relatedToType: "",
    supportType: "",
    priority: "Normal",
    status: "Not Started",
    assignedTo: null,
    timeToContact: "",
    customers: [],
    store: "",
  };

  componentDidUpdate(prevProps) {
    if (this.props.conversation != prevProps.conversation) {
      this.setState({
        store: this.props.conversation?.storeID
          ? this.props.conversation?.storeID
          : "",
      });
    }
  }

  componentDidMount() {
    this.getAllStores();
  }

  getAllStores() {
    this.setState({
      storesLoading: true,
    });

    APIV2.getStores()
      .then((data) => {
        this.setState({
          stores: data.data.stores,
          storeOptions: data?.data?.stores?.map((st) => {
            return {
              value: st?._id,
              label: st?.name?.replace("Project LeanNation", "PLN")?.trim(),
              category: st?.nsoStage,
            };
          }),
        });
      })
      .finally(() => {
        this.setState({
          storesLoading: false,
        });
      });
  }

  toggleModal() {
    this.setState({
      store: "",
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  async modify() {
    this.setState({ submitting: true });

    //let attachments = await this.uploadAttachments();

    APIV2.modifyConversationAssignment(
      this.props.conversation?._id,
      this.state.store
    )
      .then(
        (data) => {
          this.toggleModal();

          if (typeof this.props.onModify == "function") {
            let storeObj = _.findWhere(this.state.stores, {
              _id: this.state.store,
            });

            console.log(storeObj);

            return this.props.onModify({
              store: storeObj,
              conversation: data.data.conversation,
            });
          }
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to reassign conversation - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Change Store Assignment
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={this.state.submitting || !this.state.store}
                color="primary"
                onClick={() => {
                  this.modify();
                }}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  <>Save</>
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}

            <FormGroup>
              <h5>Store</h5>
              <SelectPicker
                loading={this.state.storesLoading}
                cleanable={false}
                size="md"
                placeholder="Select A Store"
                placement="auto"
                groupBy={"category"}
                data={this.state.storeOptions}
                menuStyle={{
                  zIndex: 9999,
                }}
                preventOverflow={true}
                value={this.state.store}
                block
                onChange={(val) => {
                  this.setState({
                    store: val,
                  });
                }}
              />
            </FormGroup>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(ConversationStoreAssignmentDrawer);
