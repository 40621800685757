import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import { isThisTypeNode } from "typescript";
import EditorV2 from "components/NovelEditor/ui/editor";

class StaffRoleDrawer extends React.Component {
  state = {
    position: "",
    name: "",
    email: "",
    phone: {
      value: "",
    },
    hireDate: null,
  };

  constructor() {
    super();

    this.dropdownRef = React.createRef();
  }

  updateForm() {
    this.setState(
      {
        name: this.props.role?.name ? this.props.role?.name : "",
        nextRoleID: this.props.role?.nextRoleID
          ? this.props.role?.nextRoleID
          : "",
        responsibilities: this.props.role?.responsibilities
          ? this.props.role?.responsibilities
          : {},
      },
      () => {
        setTimeout(() => {
          //console.log("HYDRATE", message);

          PubSub.publish("editor:contentUpdated", {
            event: "contentUpdated",
            forceHydrate: true,
            id: "staffRoleResponsibilityEditor",
            data: this.props.role?.responsibilities
              ? this.props.role?.responsibilities
              : {},
          });
        }, 0);
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props?.role != prevProps?.role) {
      this.updateForm();
    }
  }

  componentDidMount() {
    if (this.props.role) {
      this.updateForm();
    }
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  toggleModal() {
    this.setState({
      name: "",
      nextRoleID: "",
      responsibilities: {},
    });

    PubSub.publish("editor:contentUpdated", {
      event: "contentUpdated",
      forceHydrate: true,
      id: "staffRoleResponsibilityEditor",
      data: {},
    });

    this.props.onClose();
  }

  create() {
    this.setState({ submitting: true });

    APIV2.createStaffRole(
      this.state.name,
      this.state.responsibilities,
      this.state.nextRoleID
    )
      .then(
        (data) => {
          const staff = data.data.role;

          PubSub.publish(Event.STAFF.CREATED, staff);

          this.setState({
            name: "",
            nextRoleID: "",
          });

          this.toggleModal();
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create role - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  modify() {
    this.setState({ submitting: true });

    APIV2.modifyStaffRole(
      this.props?.role?._id,
      this.state.name,
      this.state.responsibilities,
      this.state.nextRoleID
    )
      .then(
        (data) => {
          const staff = data.data.role;

          PubSub.publish(Event.STAFF.MODIFIED, staff);

          this.setState({
            name: "",
            nextRoleID: "",
          });

          this.toggleModal();
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to modify role - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.role ? "Modify" : "Create"}&nbsp;Role
            </h3>
            <Drawer.Actions>
              <Button
                onClick={() => {
                  if (this.props.role) {
                    this.modify();
                  } else {
                    this.create();
                  }
                }}
                size="sm"
                color="primary"
                disabled={
                  !this.state.name ||
                  !this.state.responsibilities?.text ||
                  this.state.submitting
                }
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : this.props.role ? (
                  "Save"
                ) : (
                  "Create"
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error && (
              <>
                <Alert
                  color=""
                  className="m-0 mb-4 bg-primary-superlight text-dark font-weight-500"
                >
                  {this.state.error}
                </Alert>
              </>
            )}
            <FormGroup>
              <h5>Name</h5>
              <Input
                type="text"
                name="name"
                placeholder="Name"
                required
                value={this.state.name}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5 className="m-0">Next Possible Role (Optional)</h5>
              <p className="small text-dark mb-2">
                Select the next possible role a staff member could be promoted
                into after this role.
              </p>
              <Input
                type="select"
                name="nextRoleID"
                required
                value={this.state.nextRoleID}
                onChange={this.handleInputChange.bind(this)}
                placeholder="Next Role"
              >
                <option value="">(None)</option>
                {this.props.roles?.map((r) => {
                  return (
                    <option
                      value={r?._id}
                      key={r?._id}
                      disabled={r?._id == this.props.role?._id}
                    >
                      {r?.name}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
            <FormGroup>
              <h5 className="mb-2">Role Responsibilities</h5>
              <div
                className="rounded p-3 border mb-3"
                style={{ minHeight: 300 }}
              >
                <EditorV2
                  editable={true}
                  allowForceHydration={
                    this.state.responsibilities && this.state.templateLoad
                  }
                  id={"staffRoleResponsibilityEditor"}
                  padding="p-0"
                  autoSave={false}
                  debounceRate={750}
                  cdnMode="private"
                  value={
                    this.state?.responsibilities
                      ? this.state?.responsibilities
                      : {}
                  }
                  onChange={(value) => {
                    console.log("CHANGE", value);

                    if (value?.clear) {
                      this.setState({
                        templateLoad: false,
                      });

                      return;
                    }

                    //console.log("CHANGE", value);

                    this.setState({
                      responsibilities: value,
                    });
                  }}
                  allowTaskLists={false}
                ></EditorV2>
              </div>
            </FormGroup>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(StaffRoleDrawer);
