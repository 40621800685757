import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  Card,
  DeltaBar,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
  Col as TCol,
  ColGrid,
  DonutChart,
  Bold,
  List,
  ListItem,
  BarList,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import ClientOrderKPIDetailDrawer from "./ClientOrderKPIDetailDrawer";
import ChartMetricHeader from "./ChartMetricHeader";

class OrderCountSourcesCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    active: "cs",
    tabName: "Conversion Location",
    list: [],
  };

  /**
   * Fetches a summary of the order data for the given stores and date range
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchData(cubejsApi, stores, dateRange, dimension = "") {
    return new Promise((resolve, reject) => {
      let dimensionName = "";

      if (dimension == "cs") {
        dimensionName = "MetricsLTV.metadataConversionsource";
        dimension = "conversionsource";
      } else if (dimension == "is") {
        dimensionName = "MetricsLTV.metadataInitialsource";
        dimension = "initialsource";
      } else if (dimension == "us") {
        dimensionName = "MetricsLTV.metadataUtmsource";
        dimension = "utmsource";
      } else if (dimension == "um") {
        dimensionName = "MetricsLTV.metadataUtmmedium";
        dimension = "utmmedium";
      } else if (dimension == "uc") {
        dimensionName = "MetricsLTV.metadataUtmcampaign";
        dimension = "utmcampaign";
      }

      let metricType = "overall";

      if (this.props.metricType) {
        metricType = this.props.metricType;
      }

      // Load
      cubejsApi
        .load({
          measures: [
            "MetricsLTV.avgMetadataTotalfulfilledorders",
            "MetricsLTV.avgMetadataTotalcustomers",
          ],
          order: {
            "MetricsLTV.createdat": "asc",
          },

          timeDimensions: [
            {
              dimension: "MetricsLTV.createdat",
              dateRange: [dateRange[0], dateRange[1]],
            },
          ],
          dimensions: [dimensionName, "Stores.name"],
          filters: [
            {
              member: "MetricsLTV.name",
              operator: "contains",
              values: [`ltv_${dimension}_${metricType}`],
            },
            {
              member: "MetricsLTV.metadataStoreid",
              operator: "equals",
              values: stores,
            },
            {
              member: "MetricsLTV.createdat",
              operator: "beforeDate",
              values: [this.props.dateRange[1].toISOString()],
            },
            {
              member: "MetricsLTV.createdat",
              operator: "beforeDate",
              values: [moment().toISOString()],
            },
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          let total = 0;

          let out = [];

          for (let i = 0; i < data?.length; i++) {
            const item = data[i];

            let payload = {
              name: item[dimensionName]
                ? item[dimensionName]
                : `${
                    dimensionName == "MetricsLTV.metadataConversionsource"
                      ? "Online"
                      : "(none set)"
                  }`,
              totalCount:
                item["MetricsLTV.avgMetadataTotalcustomers"] > 0
                  ? item["MetricsLTV.avgMetadataTotalfulfilledorders"]
                  : 0,
              totalCustomers: Math.round(
                item["MetricsLTV.avgMetadataTotalcustomers"]
              ),
              totalStores: 1,
            };

            const idx = _.findIndex(out, { name: payload.name });

            if (idx >= 0) {
              out[idx].totalCount += payload.totalCount;
              out[idx].totalCustomers += payload.totalCustomers;
              out[idx].totalStores++;
            } else {
              out.push(payload);
            }
          }

          out = out.map((item) => {
            item.count = parseFloat(
              (item.totalCount / item.totalCustomers).toFixed(1)
            );

            return item;
          });

          /*data = data?.map((item) => {
            return {
              name: item[dimensionName]
                ? item[dimensionName]
                : `${
                    dimensionName == "MetricsLTV.metadataConversionsource"
                      ? "Online"
                      : "(none set)"
                  }`,
              count:
                item["MetricsLTV.avgMetadataTotalcustomers"] > 0
                  ? parseFloat(
                      (
                        item["MetricsLTV.avgMetadataTotalfulfilledorders"] /
                        item["MetricsLTV.avgMetadataTotalcustomers"]
                      ).toFixed(1)
                    )
                  : 0,
              totalCustomers: Math.round(
                item["MetricsLTV.avgMetadataTotalcustomers"]
              ),
            };
          });*/

          data = out;

          data = _.sortBy(data, "count");
          data.reverse();

          return resolve({
            total,
            history: data,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async loadReport(stores, dateRange, compare) {
    this.setState({
      loading: true,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!stores?.length || !dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    let list = [];

    try {
      list = await this._fetchData(
        cubejsApi,
        stores,
        dateRange,
        this.state.active
      );

      this.setState({
        total: list.total,
      });

      list = list.history;
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load average orders by member source.",
      });
    }

    if (list !== null) {
      this.setState({
        dataAvailable: true,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });
    }

    this.setState({
      list,
      loading: false,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stores != prevProps?.stores ||
      this.props.allStores != prevProps?.allStores ||
      this.props.dateRange != prevProps?.dateRange ||
      this.props.comparePrevious != prevProps.comparePrevious ||
      this.props.metricType != prevProps?.metricType
    ) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  componentDidMount() {
    if (this.props.stores && this.props.dateRange && this.props.metricType) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  render() {
    return (
      <>
        <Card>
          <div>
            <Row>
              <Col xs="">
                <h3 className="m-0 text-dark">
                  Avg. Orders / Member By Source
                </h3>
                <p
                  className="small text-muted mb-1"
                  style={{ lineHeight: 1.2 }}
                >
                  The average number of orders / member based on how they found
                  & signed up for a subscription.
                </p>
              </Col>
              <Col xs="auto">
                <Button
                  size="sm"
                  outline
                  color="dark"
                  className="border-0 btn-icon-only"
                  disabled={this.state.loading}
                  onClick={() => {
                    this.loadReport(
                      this.props.stores,
                      this.props.dateRange,
                      this.props.comparePrevious
                    );
                  }}
                >
                  {this.state.loading ? (
                    <Spinner size="sm"></Spinner>
                  ) : (
                    <i className="mdi mdi-refresh"></i>
                  )}
                </Button>
              </Col>
            </Row>
          </div>
          <TabList
            color="orange"
            defaultValue="cs"
            handleSelect={(value) => {
              let tabName = "";

              switch (value) {
                case "cs":
                  tabName = "Conversion Location";
                  break;
                case "is":
                  tabName = "Lead Source";
                  break;
                case "us":
                  tabName = "UTM Source";
                  break;
                case "um":
                  tabName = "UTM Medium";
                  break;
                case "uc":
                  tabName = "UTM Campaign";
                  break;
                default:
                  tabName = "";
              }

              this.setState(
                {
                  active: value,
                  tabName,
                },
                () => {
                  this.loadReport(
                    this.props.stores,
                    this.props.dateRange,
                    this.props.comparePrevious
                  );
                }
              );
            }}
            marginTop="mt-0"
          >
            <Tab value="cs" text="By Conversion Location" />
            <Tab value="is" text="By Lead Source" />
            <Tab value="us" text="By UTM Source" />
            <Tab value="um" text="By UTM Medium" />
            <Tab value="uc" text="By UTM Campaign" />
          </TabList>
          {this.state.loading ? (
            <>
              <div
                className="skeleton rounded mt-4"
                style={{ width: "100%", height: 208 }}
              >
                &nbsp;
              </div>
            </>
          ) : (
            <>
              <div className="mt-4">
                <Flex>
                  <Text truncate={true}>
                    <Bold>{this.state.tabName}</Bold>
                  </Text>
                  <Text>
                    <Bold># Members</Bold>
                  </Text>
                </Flex>

                <div>
                  <div className="pt-3 mb--4 pb-4">
                    <BarList
                      data={this.state.list?.map((item) => {
                        return {
                          name:
                            item?.name +
                            ` [~${StringUtils.numberFormat(
                              item?.totalCustomers
                            )} members]`,
                          value: item?.count,
                        };
                      })}
                      valueFormatter={(number) => {
                        return (
                          `~` +
                          StringUtils.numberFormat(number) +
                          " Orders / Member"
                        );
                      }}
                      color="orange"
                      showAnimation={true}
                      marginTop="mt-0"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </Card>
      </>
    );
  }
}

export default OrderCountSourcesCard;
