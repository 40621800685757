import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
  Badge,
  FormGroup,
  Collapse,
} from "reactstrap";
import Alert from "reactstrap/lib/Alert";
import { DatePicker, SelectPicker } from "rsuite";
import CustomerOpenActivitiesWidget from "./CustomerOpenActivitiesWidget";
import CustomerUnclaimedBoxWidget from "./CustomerUnclaimedBoxWidget";
import Constant from "../../lib/Constant";
import _ from "underscore";
import moment from "moment";

class FranDevLeadDatesWidget extends React.Component {
  state = {
    expand: false,
    phone: {
      value: "",
    },
    name: "",
    email: "",
    dateOptions: [
      {
        label: "Added To CRM",
        description: "Date the lead was created in the CRM.",
        key: "createdAt",
        editable: false,
      },
      {
        label: "First Contact Date",
        description:
          "Date the lead was first contacted via call, sms, or email.",
        key: "firstContactDate",
        editable: true,
      },
      {
        label: "Last Contact Date",
        description:
          "Date the lead was last contacted via call, sms, or email.",
        key: "lastContactDate",
        editable: false,
      },
      {
        label: "Moved To Contacted Stage",
        description: 'Date the lead was moved to the "Contacted" stage.',
        key: "stageChangeContactedDate",
        editable: false,
      },
      {
        label: "Moved To Focus Call Stage",
        description: 'Date the lead was moved to the "Focus Call" stage.',
        key: "stageChangeFocusCallDate",
        editable: false,
      },
      {
        label: "Focus Call Date",
        description: "Date the lead attended a Focus Call meeting.",
        key: "focusCallDate",
        editable: true,
      },
      {
        label: "Moved To Discovery Call Stage",
        description: 'Date the lead was moved to the "Discovery Call" stage.',
        key: "stageChangeDiscoveryCallDate",
        editable: false,
      },
      {
        label: "Discovery Call Date",
        description: "Date the lead attended a Discovery Call meeting.",
        key: "discoveryCallDate",
        editable: true,
      },
      {
        label: "Moved To Discovery Date Stage",
        description: 'Date the lead was moved to the "Discovery Date" stage.',
        key: "stageChangeDiscoveryDateDate",
        editable: false,
      },
      {
        label: "Discovery Day Date",
        description: "Date the lead attended a Discovery Day in-person event.",
        key: "discoveryDayDate",
        editable: true,
      },

      {
        label: "Moved To Funding Stage",
        description: 'Date the lead was moved to the "Funding" stage.',
        key: "stageChangeFundingDate",
        editable: false,
      },
      {
        label: "FDD Send Date",
        description: "Date the FDD was provided to the lead.",
        key: "fddSendDate",
        editable: true,
      },
      {
        label: "Funded On Date",
        description: "Date the lead was funded and the FDD was executed.",
        key: "fundedDate",
        editable: true,
      },
      {
        label: "Moved To Converted Stage",
        description: 'Date the lead was moved to the "Converted" stage.',
        key: "stageChangeConvertedDate",
        editable: false,
      },
      {
        label: "Moved To Inactive Stage",
        description: 'Date the lead was moved to the "Inactive" stage.',
        key: "stageChangeInactiveDate",
        editable: false,
      },
    ],
  };

  setValue(key, value, modifyKey = "") {
    this.setState({
      valSubmitting: true,
    });

    console.log(key, value);

    // TODO: implement NSO update here

    APIV2.modifyFranDevLead(this.props?.lead?._id, {
      [key]: value,
    })
      .then(
        (data) => {
          PubSub.publish(Event.LEAD.MODIFIED, data.data.lead);

          this.setState({
            [modifyKey]: false,
          });
        },
        (e) => {
          window.alert("Unable to modify: " + e?.message ?? "error occurred.");
        }
      )
      .finally(() => {
        this.setState({
          valSubmitting: false,
        });
      });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 4000);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  render() {
    return (
      <>
        <Card className="mb-4 shadow border">
          <CardHeader
            className="cursor-pointer"
            onClick={() => {
              this.setState({
                open: !this.state.open,
              });
            }}
          >
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Key Dates</h3>
              </div>
              <Col xs="auto">
                <h1 className="m-0">
                  <i
                    className={`mdi mdi-chevron-${
                      this.state.open ? "down" : "right"
                    }`}
                  ></i>
                </h1>
              </Col>
            </Row>
          </CardHeader>
          <Collapse isOpen={this.state.open}>
            {this.props.loading ? (
              <CardBody>
                <Spinner size="sm" color="dark" className="mr-2" /> Loading...
              </CardBody>
            ) : (
              <>
                {this.state.error ? (
                  <>
                    <CardBody>
                      <Alert color="danger" className="mb-0 mt-0">
                        {this.state.error}
                      </Alert>
                    </CardBody>
                  </>
                ) : null}
                {this.state.dateOptions?.map((opt, i) => (
                  <CardBody
                    key={i}
                    className={`py-3 ${
                      i != this.state.dateOptions?.length - 1
                        ? "border-bottom"
                        : ""
                    }`}
                  >
                    <h4 className="mb-1 text-dark">{opt?.label}:</h4>
                    <p
                      className="small text-muted mb-2"
                      style={{ lineHeight: 1.2 }}
                    >
                      {opt?.description}
                    </p>
                    <Row className="align-items-center">
                      <Col xs="">
                        {this.state[`modify${opt?.key}`] ? (
                          <>
                            <DatePicker
                              value={this.state[opt?.key]}
                              onChange={(v) => {
                                this.setState({ [opt?.key]: v });
                              }}
                              placement="auto"
                              oneTap={true}
                              cleanable={true}
                              block
                              format="MM/dd/yyyy hh:mm a"
                              showMeridian={true}
                            ></DatePicker>
                          </>
                        ) : (
                          <>
                            <p className="m-0">
                              {this.props.lead[opt?.key] ? (
                                <>
                                  {moment(this.props.lead[opt?.key]).format(
                                    "MM/DD/YYYY hh:mm A"
                                  )}{" "}
                                  (
                                  {moment(
                                    moment(this.props.lead[opt?.key])
                                  ).fromNow()}
                                  )
                                </>
                              ) : (
                                "--"
                              )}
                            </p>
                          </>
                        )}
                      </Col>
                      <Col xs="auto">
                        {this.state[`modify${opt?.key}`] ? (
                          <>
                            <Button
                              onClick={() => {
                                this.setState({
                                  [`modify${opt?.key}`]: false,
                                });
                              }}
                              className="btn-sm btn"
                              color="secondary"
                              outline
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={() => {
                                this.setValue(
                                  opt?.key,
                                  this.state[opt?.key]
                                    ? this.state[opt?.key]?.toISOString()
                                    : null,
                                  `modify${opt?.key}`
                                );
                              }}
                              disabled={this.state.valSubmitting}
                              className="btn-sm btn"
                              color="primary"
                            >
                              {this.state.valSubmitting ? (
                                <>
                                  <Spinner size="sm"></Spinner>
                                </>
                              ) : (
                                <>Save</>
                              )}
                            </Button>
                          </>
                        ) : (
                          <>
                            {opt?.editable ? (
                              <>
                                <Button
                                  onClick={() => {
                                    this.setState({
                                      [`modify${opt?.key}`]: true,
                                      [opt?.key]: this.props.lead[opt?.key]
                                        ? moment(
                                            this.props.lead[opt?.key]
                                          )?.toDate()
                                        : null,
                                    });
                                  }}
                                  className="btn-sm btn"
                                  color="secondary"
                                  outline
                                >
                                  Modify
                                </Button>
                              </>
                            ) : null}
                          </>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                ))}
              </>
            )}
          </Collapse>
        </Card>
      </>
    );
  }
}

export default withRouter(FranDevLeadDatesWidget);
