import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import {
  Checkbox,
  Dropdown,
  Popover,
  SelectPicker,
  Tooltip,
  Whisper,
} from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import MarketingAutomationDrawer from "../../components/Drawers/MarketingAutomationDrawer";
import swal from "sweetalert";
import Constant from "../../lib/Constant";

class MarketingAutomationStepView extends React.Component {
  state = {
    active: {
      id: "All",
      name: "All",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 25,
    filters: null,
    orderBy: {
      scheduledFor: -1,
    },
    sortByOption: "dueDate",
    sortByDirection: "ascending",
    truncate: true,
    truncateStyles: {
      maxHeight: "30px",
    },
    channels: [
      {
        value: "conversations",
        label: "Conversations Automation",
      },
      {
        value: "email",
        label: "Email",
      },
      {
        value: "push",
        label: "Push Notification",
      },
    ],
  };

  deletingAutomation(automation) {
    swal({
      title: "Delete Automation",
      text: `Are you sure you want to delete the "${automation?.name}" marketing automation?`,
      icon: "warning",
      buttons: ["Nevermind", "Delete"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        deletingAutomation: automation?._id,
      });

      APIV2.deleteMarketingAutomation(automation?._id)
        .then(
          () => {
            PubSub.publish(Event.COHORT.DELETED, automation);

            swal({
              title: "Automation Deleted",
              text: "Your automation has been successfully deleted",
              icon: "success",
            });
          },
          (e) => {
            window.alert(
              e?.replace?.message ??
                "Unable to delete automation. Please try again."
            );
          }
        )
        .finally(() => {
          this.setState({
            deletingAutomation: null,
          });
        });
    });
  }

  activateAutomation(automation, active) {
    swal({
      title: `${active ? "Activate" : "Deactivate"} Automation?`,
      text: `Are you sure you want to ${
        active ? "activate" : "deactivate"
      } the "${automation?.name}" marketing automation?`,
      icon: "warning",
      buttons: ["Nevermind", `${active ? "Activate" : "Deactivate"}`],
      dangerMode: !active,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        activatingAutomation: automation?._id,
      });

      APIV2.modifyMarketingAutomation(automation?._id, { active })
        .then(
          () => {
            PubSub.publish(Event.COHORT.MODIFIED, automation);

            swal({
              title: `Automation ${active ? "Activated" : "Deactivated"}`,
              text: `Your automation has been successfully ${
                active ? "activated" : "deactivated"
              }`,
              icon: "success",
            });
          },
          (e) => {
            window.alert(
              e?.replace?.message ??
                "Unable to modify automation status. Please try again."
            );
          }
        )
        .finally(() => {
          this.setState({
            activatingAutomation: null,
          });
        });
    });
  }

  componentDidUpdate(prevProps) {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (
      this.props?.match?.params?.automationID !=
      prevProps?.match?.params?.automationID
    ) {
      this.loadAutomation(this.props?.match?.params?.automationID);
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (this.props?.match?.params?.automationID) {
      this.loadAutomation(this.props?.match?.params?.automationID);
    }

    PubSub.subscribe(Event.COHORT.MODIFIED, (a) => {
      this.loadAutomation(this.props?.match?.params?.automationID);

      if (this.state.modifyAutomation?._id == a?._id) {
        this.setState({
          modifyAutomation: a,
        });
      }
    });
  }

  loadAutomation(automationID) {
    this.setState({
      loadingAutomation: true,
    });

    APIV2.getMarketingAutomationByID(automationID)
      .then(
        (data) => {
          let automation = null;

          if (data?.data?.automation) {
            automation = data?.data?.automation;
          }

          this.setState({
            automation: automation,
          });
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the automation. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingAutomation: false,
        });
      });
  }

  getAudience(automation) {
    if (automation?.collectionName == "Customers") {
      return "Member/HFN";
    } else if (automation?.collectionName == "CustomerLeads") {
      return "Lead";
    } else if (automation?.collectionName == "RetailCustomers") {
      return "Retail Customer";
    } else if (automation?.collectionName == "FranchiseLeads") {
      return "Franchise Lead";
    }
  }

  formatDelay(delay) {
    if (delay == 0) {
      return "Send Immediately";
    }

    let duration = moment.duration(delay, "seconds");

    let hours = duration.hours();
    let minutes = duration.minutes();
    let days = duration.days();

    let formatted = "";

    if (days > 0) {
      formatted += `${days} day${days > 1 ? "s" : ""}`;
    }

    if (hours > 0) {
      formatted += `${hours} hour${hours > 1 ? "s" : ""}`;
    }

    if (minutes > 0) {
      formatted += `${hours > 0 ? " " : ""}${minutes} minute${
        minutes > 1 ? "s" : ""
      }`;
    }

    return formatted;
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader
            title={
              this.state.loadingAutomation
                ? "Loading..."
                : this.state.automation?.name ?? "Marketing Automation"
            }
            showBackButton={true}
            secondaryAction={{
              name: (
                <>
                  <i className="mdi mdi-cog"></i>
                </>
              ),
              iconButton: true,
              onClick: () => {
                this.setState({
                  createOpen: true,
                  modifyAutomation: this.state.automation,
                });
              },
              disabled: this.state.submitting,
            }}
            primaryAction={{
              name: <>Save</>,
              onClick: () => {},
              disabled: this.state.submitting || !this.state.modified,
            }}
          />
          <div>
            <Row>
              <Col xs="12" md="6">
                <div className="rounded border p-3" style={{ height: "100%" }}>
                  <h3 className="mb-1">Entry Trigger</h3>
                  <p
                    className="text-dark m-0 text-sm"
                    style={{ lineHeight: 1.3 }}
                  >
                    Automations will start the first time that the{" "}
                    {this.getAudience(this.state.automation)} is added to the
                    following cohort:
                  </p>
                  <p className="font-weight-bold text-dark mb-0 mt-2">
                    {this.state.automation?.cohort?.name}
                  </p>
                  {this.state.automation?.excludeCohorts?.length > 0 ? (
                    <>
                      <p
                        className="text-dark mt-3 mb-0 text-sm"
                        style={{ lineHeight: 1.3 }}
                      >
                        And the {this.getAudience(this.state.automation)} is not
                        part of the following cohorts:
                      </p>
                      {this.state.automation?.excludeCohorts?.map((c, i) => (
                        <p
                          key={i}
                          className={`font-weight-bold text-dark ${
                            i !=
                              this.state.automation?.excludeCohorts?.length -
                                1 && "mb-2"
                          }`}
                        >
                          {c?.name}
                        </p>
                      ))}
                    </>
                  ) : null}
                </div>
              </Col>
              <Col xs="12" md="6" className="mt-3 mt-md-0">
                <div className="rounded border p-3" style={{ height: "100%" }}>
                  <h3 className="mb-1">Exit Trigger</h3>
                  <p
                    className="text-dark mt-0 mb-3 text-sm"
                    style={{ lineHeight: 1.3 }}
                  >
                    Automations will stop when the{" "}
                    {this.getAudience(this.state.automation)} is no longer part
                    of the following cohort:
                  </p>
                  <p className="font-weight-bold text-dark m-0">
                    {this.state.automation?.cohort?.name}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <Card className="border mt-4 mb-0">
            <CardHeader className="p-3">
              <Row className="align-items-center">
                <Col xs="">
                  <h3 className="m-0 text-dark">Automation Steps</h3>
                </Col>
                <Col xs="auto">
                  {/**
                   * <Button color="primary" className="btn-icon-only" size="sm">
                    <i className="mdi mdi-plus"></i>
                  </Button>
                   */}
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="p-3">
              <div className="d-flex justify-content-center">
                <div
                  className="border d-inline-block border-lighter bg-superlight text-center text-dark font-weight-bold rounded p-2"
                  style={{ fontSize: 14 }}
                >
                  <i className="mdi mdi-lightning-bolt pr-2"></i>
                  {this.getAudience(this.state.automation)} Added To Cohort
                </div>
              </div>
              {this.state.steps?.map((s, i) => (
                <div key={i} className="mt-3">
                  <div className="d-flex justify-content-center">
                    <div
                      style={{ height: 50, width: 2 }}
                      className="bg-light mb-3"
                    ></div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div
                      className="border d-inline-block border-lighter bg-superlight text-center text-dark font-weight-bold rounded p-2"
                      style={{ fontSize: 14 }}
                    >
                      Wait: {this.formatDelay(s?.delay)}
                    </div>
                  </div>
                  <Card className="border border-lighter mt-2">
                    <CardHeader className="p-3">
                      <Row className="align-items-center">
                        <Col xs="">
                          <h3 className="m-0 text-dark">Step {i + 1}</h3>
                          <p className="m-0 text-sm text-dark">
                            {s?.content?.channel
                              ? s?.content?.channel
                              : "Select A Communication Channel"}
                          </p>
                        </Col>
                        <Col xs="auto">
                          <Button
                            color="danger"
                            size="sm"
                            outline
                            className="btn-icon-only"
                            onClick={() => {}}
                          >
                            <i className="mdi mdi-trash-can"></i>
                          </Button>
                          <Button
                            color="dark"
                            size="sm"
                            outline
                            className="btn-icon-only"
                            onClick={() => {}}
                          >
                            <i className="mdi mdi-pencil"></i>
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardHeader className="p-3">
                      <Row className="align-items-center">
                        <Col xs="">
                          <h3 className="mb-0 text-dark">Performance</h3>
                        </Col>
                        <Col xs="auto">
                          <Button
                            size="sm"
                            outline
                            color="dark"
                            className="btn-icon-only"
                          >
                            <i className="mdi mdi-percent"></i>
                          </Button>
                        </Col>
                      </Row>
                      <Row className="align-items-center justify-content-between mt-3">
                        <Col xs="6" sm="4" md="" className="text-center">
                          <h2 className="mb-1">--</h2>
                          <p
                            className="text-dark text-sm m-0"
                            style={{ lineHeight: 1.2 }}
                          >
                            Triggered
                          </p>
                        </Col>
                        <Col xs="6" sm="4" md="" className="text-center">
                          <h2 className="mb-1">--</h2>
                          <p
                            className="text-dark text-sm m-0"
                            style={{ lineHeight: 1.2 }}
                          >
                            Delivered
                          </p>
                        </Col>
                        <Col xs="6" sm="4" md="" className="text-center">
                          <h2 className="mb-1">--</h2>
                          <p
                            className="text-dark text-sm m-0"
                            style={{ lineHeight: 1.2 }}
                          >
                            Opened
                          </p>
                        </Col>
                        <Col xs="6" sm="4" md="" className="text-center">
                          <h2 className="mb-1">--</h2>
                          <p
                            className="text-dark text-sm m-0"
                            style={{ lineHeight: 1.2 }}
                          >
                            Clicked
                          </p>
                        </Col>
                        <Col xs="6" sm="4" md="" className="text-center">
                          <h2 className="mb-1">--</h2>
                          <p
                            className="text-dark text-sm m-0"
                            style={{ lineHeight: 1.2 }}
                          >
                            Unsubscribe
                          </p>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className="p-3">
                      <h3 className="mb-0 text-dark">Content</h3>
                    </CardBody>
                  </Card>
                </div>
              ))}
              <div className="mt-3">
                <div className="d-flex justify-content-center">
                  <div
                    style={{ height: 50, width: 2 }}
                    className="bg-light mb-3"
                  ></div>
                </div>
                <div className="d-flex justify-content-center">
                  <Button
                    color="primary"
                    onClick={() => {
                      let steps = this.state.steps?.length
                        ? this.state.steps
                        : [];

                      steps.push({
                        content: {},
                        _id: StringUtils.uuid(),
                        delay: 3600,
                      });

                      this.setState({
                        steps: steps,
                      });
                    }}
                  >
                    Add Step
                  </Button>
                </div>
              </div>
            </CardBody>
          </Card>
        </Container>
        <MarketingAutomationDrawer
          open={this.state.createOpen}
          onClose={() => {
            this.setState({
              createOpen: false,
              duplicate: false,
              modifyAutomation: null,
            });
          }}
          automation={this.state.modifyAutomation}
        ></MarketingAutomationDrawer>
      </>
    );
  }
}

export default MarketingAutomationStepView;
