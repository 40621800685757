import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
  Row,
  Col,
  Button,
  Spinner,
  Badge,
} from "reactstrap";
import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";
import API from "lib/API";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import User from "lib/Model/User";
import { Avatar, Drawer, Dropdown, Whisper, Popover } from "rsuite";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import LinkUtils from "lib/LinkUtils";
import NotificationDrawer from "components/Drawers/NotificationDrawer";
import InstantAnswerDrawer from "components/Drawers/InstantAnswerDrawer";

class AdminNavbarV2 extends React.Component {
  state = {
    user: null,
    notificationCount: 0,
  };

  constructor(props) {
    super(props);

    this.menuRef = React.createRef();
  }

  loadUser() {
    console.log("LOADING USER");

    if (window[Constant.CACHE.USER]) {
      this.setState({
        user: window[Constant.CACHE.USER],
      });
    } else if (LocalStorage.get(Constant.CACHE.USER)) {
      this.setState({
        user: User._fromData(LocalStorage.get(Constant.CACHE.USER)),
      });
    }
  }

  logout() {
    LocalStorage.set(Constant.CACHE.TOKEN, null);
    window[Constant.CACHE.USER] = null;
    LocalStorage.set(Constant.CACHE.USER, null);

    this.props.history.push("/auth");
  }

  formatPhoneNumber(phoneNumberString) {
    return StringUtils.formatPhoneNumber(phoneNumberString);
  }

  _handleEscKey(event) {
    if (event.keyCode == 27) {
      this.clearSearch();
    }
  }

  performStorefrontSearch() {
    console.log(this.state.search);

    APIV2.simpleSearchCustomersForStore(
      this.props.match?.params?.storeID,
      this.state.search
    )
      .then(
        (data) => {
          this.setState({
            storeCustomers: data.data.customers,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          searchLoading: false,
        });
      });
  }

  performGlobalSearch() {
    APIV2.simpleSearchCustomers(this.state.search)
      .then(
        (data) => {
          this.setState({
            storeCustomers: data.data.customers,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          searchLoading: false,
        });
      });
  }

  performFranDevSearch() {
    APIV2.simpleSearchStores(this.state.search)
      .then(
        (data) => {
          this.setState({
            stores: data.data.stores,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          searchLoading: false,
        });
      });
  }

  performUniversitySearch() {
    console.log(this.state.search);

    APIV2.simpleSearchLibraryTopics(this.state.search)
      .then(
        (data) => {
          this.setState({
            topics: data.data?.topics?.length ? data.data?.topics : [],
            modules: data.data?.modules?.length ? data.data?.modules : [],
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          searchLoading: false,
        });
      });
  }

  clearSearch() {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);

      this.setState({
        searchTimeout: null,
      });
    }

    this.setState({
      search: "",
    });
  }

  handleSearchChange(e) {
    if (this.props.disableSearch) {
      return;
    }

    const { name, value } = e.target;

    this.setState({ modified: true });

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    if (value) {
      this.setState({
        searchLoading: true,
      });
    }

    if (value && value.length >= 3) {
      let timeout = setTimeout(() => {
        if (this.props.searchMode == "storefront") {
          this.performStorefrontSearch();
        } else if (this.props.searchMode == "global") {
          this.performGlobalSearch();
        } else if (this.props.searchMode == "frandev") {
          this.performFranDevSearch();
        } else if (this.props.searchMode == "university") {
          this.performUniversitySearch();
        }
      }, 1200);

      this.setState({
        searchTimeout: timeout,
      });
    }

    this.setState({
      [name]: value,
    });
  }

  componentDidMount() {
    this.loadUser();

    PubSub.subscribe(Event.USER_LOADED, () => {
      console.log("EVENT FOR USER");

      this.loadUser();
    });

    PubSub.subscribe("notificationCount", (v) => {
      //console.log("Count", v);

      this.setState({
        notificationCount: v,
      });
    });

    document.addEventListener("keydown", this._handleEscKey.bind(this), false);
  }

  componentWillUnmount() {
    document.removeEventListener(
      "keydown",
      this._handleEscKey.bind(this),
      false
    );
  }

  isGlobal() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  isStorefront() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length || user?.storePermissions?.length
      ? true
      : false;
  }

  isProduction() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.productionPermissions?.length ||
      user?.globalPermissions?.length
      ? true
      : false;
  }

  renderSearchResults() {
    if (
      this.props.searchMode == "storefront" ||
      this.props.searchMode == "global"
    ) {
      return (
        <>
          {this.state.storeCustomers && this.state.storeCustomers.length ? (
            <>
              <div className="px-3 pt-3 pb-2">
                <h5 className="text-uppercase text-muted">
                  {this.state.storeCustomers.length} Result
                  {this.state.storeCustomers.length != 1 ? "s" : ""}
                </h5>
              </div>
              {this.state.storeCustomers.map((cust, i) => (
                <div
                  key={i}
                  className={`py-2 px-3 cursor-pointer search-result-row ${
                    i != this.state.storeCustomers.length - 1
                      ? "border-bottom"
                      : ""
                  }`}
                  onClick={(e) => {
                    this.props.history.push(
                      "/storefront/" +
                        cust.storeID +
                        "/members/details/" +
                        cust._id +
                        "/summary"
                    );
                  }}
                >
                  <Row className="align-items-center">
                    <Col xs="auto" className="pr-0">
                      <i
                        className="mdi mdi-account"
                        style={{ fontSize: "30px" }}
                      ></i>
                    </Col>
                    <Col
                      xs=""
                      style={{
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                      }}
                    >
                      <h3 className="mb-0">{cust.name}</h3>
                      <p className="mb-0" style={{ lineHeight: 1.25 }}>
                        <small>
                          {isNaN(this.state.search) ? (
                            cust.email
                          ) : (
                            <>
                              {this.formatPhoneNumber(cust.phone)}
                              <br />
                              {cust.email}
                            </>
                          )}
                        </small>
                      </p>
                    </Col>
                    <Col xs="auto" className="pl-0 text-right">
                      {cust.accountStatus == "ACTIVE" ? (
                        <Badge size="lg" color="success">
                          ACTIVE
                        </Badge>
                      ) : (
                        <Badge size="lg" color="medium">
                          HOLD
                        </Badge>
                      )}
                    </Col>
                    <Col
                      xs="auto"
                      className="pl-0 text-right"
                      style={{ height: "28px" }}
                    >
                      <i
                        className="mdi text-medium mdi-open-in-new"
                        style={{ fontSize: "22px" }}
                      ></i>
                    </Col>
                  </Row>
                </div>
              ))}
            </>
          ) : (
            <>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  display: "inline-block",
                }}
                className="text-dark"
              >
                No results found.
              </div>
            </>
          )}
        </>
      );
    }

    if (this.props.searchMode == "frandev") {
      return (
        <>
          {this.state.stores && this.state.stores.length ? (
            <>
              <div className="px-3 pt-3 pb-2">
                <h5 className="text-uppercase text-muted">
                  {this.state.stores.length} Result
                  {this.state.stores.length != 1 ? "s" : ""}
                </h5>
              </div>
              {this.state.stores.map((cust, i) => (
                <div
                  key={i}
                  className={`py-2 px-3 cursor-pointer search-result-row ${
                    i != this.state.stores.length - 1 ? "border-bottom" : ""
                  }`}
                  onClick={(e) => {
                    this.props.history.push(
                      "/frandev/stores/details/" + cust._id + "/about"
                    );
                  }}
                >
                  <Row className="align-items-center">
                    <Col xs="auto" className="pr-0">
                      <i
                        className="mdi mdi-store"
                        style={{ fontSize: "30px" }}
                      ></i>
                    </Col>
                    <Col
                      xs=""
                      style={{
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                        maxWidth: "calc(100% - 82px)",
                      }}
                    >
                      <h3 className="mb-0 text-truncate">{cust.name}</h3>
                      <p className="mb-0" style={{ lineHeight: 1.25 }}>
                        <small>{cust.nsoStage}</small>
                      </p>
                    </Col>
                    <Col
                      xs="auto"
                      className="pl-0 text-right"
                      style={{ height: "28px" }}
                    >
                      <i
                        className="mdi text-medium mdi-open-in-new"
                        style={{ fontSize: "22px" }}
                      ></i>
                    </Col>
                  </Row>
                </div>
              ))}
            </>
          ) : (
            <>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  display: "inline-block",
                }}
                className="text-dark"
              >
                No results found.
              </div>
            </>
          )}
        </>
      );
    }

    return (
      <>
        {this.state.modules?.length ? (
          <>
            <div className="px-3 pt-3 pb-3 border-bottom border-lighter">
              <Row className="align-items-center">
                <Col xs="">
                  <h3 className="text-dark m-0">Modules</h3>
                </Col>
                <Col xs="auto">
                  <h5 className="text-uppercase text-dark m-0">
                    {this.state.modules.length} Result
                    {this.state.modules.length != 1 ? "s" : ""}
                  </h5>
                </Col>
              </Row>
            </div>
            {this.state.modules.map((topic, i) => (
              <div
                key={i}
                className={`py-2 px-3 cursor-pointer search-result-row ${
                  i != this.state.topics.length - 1 ? "border-bottom" : ""
                }`}
                onClick={(e) => {
                  this.props.history.push(
                    "/university/library/modules/" + topic._id
                  );
                }}
              >
                <Row className="align-items-center">
                  <Col
                    xs=""
                    style={{
                      whiteSpace: "pre-wrap",
                      wordBreak: "break-word",
                      maxWidth: "calc(100% - 37px)",
                    }}
                  >
                    <h3 className="mb-0">{topic.name}</h3>
                    <p
                      className="mb-0 text-truncate"
                      style={{ lineHeight: 1.25, whiteSpace: "nowrap" }}
                    >
                      <small>
                        {topic?.description ? (
                          topic.description
                        ) : (
                          <>
                            {StringUtils.stripHTML(topic?.content)
                              .split("\n")
                              .join(" ")}
                          </>
                        )}
                      </small>
                    </p>
                  </Col>

                  <Col
                    xs="auto"
                    className="pl-0 text-right"
                    style={{ height: "28px" }}
                  >
                    <i
                      className="mdi text-medium mdi-open-in-new"
                      style={{ fontSize: "22px" }}
                    ></i>
                  </Col>
                </Row>
              </div>
            ))}
          </>
        ) : null}
        {this.state.modules?.length && this.state.topics?.length ? (
          <div className="py-4"></div>
        ) : null}
        {this.state.topics && this.state.topics.length ? (
          <>
            <div className="px-3 pt-3 pb-3 border-bottom border-lighter">
              <Row className="align-items-center">
                <Col xs="">
                  <h3 className="text-dark m-0">Topics</h3>
                </Col>
                <Col xs="auto">
                  <h5 className="text-uppercase text-dark m-0">
                    {this.state.topics.length} Result
                    {this.state.topics.length != 1 ? "s" : ""}
                  </h5>
                </Col>
              </Row>
            </div>
            {this.state.topics.map((topic, i) => (
              <div
                key={i}
                className={`py-2 px-3 cursor-pointer search-result-row ${
                  i != this.state.topics.length - 1 ? "border-bottom" : ""
                } ${!topic?.modules?.length ? "d-none" : ""}`}
                onClick={(e) => {
                  this.props.history.push(
                    "/university/library/topics/" +
                      topic._id +
                      "?moduleID=" +
                      (topic?.modules?.length ? topic?.modules[0]?._id : "")
                  );
                }}
              >
                <Row className="align-items-center">
                  <Col
                    xs=""
                    style={{
                      whiteSpace: "pre-wrap",
                      wordBreak: "break-word",
                      maxWidth: "calc(100% - 37px)",
                    }}
                  >
                    <p
                      className="mb-0 text-truncate"
                      style={{ lineHeight: 1.25, whiteSpace: "nowrap" }}
                    >
                      <small>
                        {topic?.modules?.map((mod) => mod?.name).join(", ")}
                      </small>
                    </p>
                    <h3 className="mb-0">{topic.name}</h3>
                    <p
                      className="mb-0 text-truncate"
                      style={{ lineHeight: 1.25, whiteSpace: "nowrap" }}
                    >
                      <small>
                        {topic?.description ? (
                          topic.description
                        ) : (
                          <>
                            {StringUtils.stripHTML(topic?.content)
                              .split("\n")
                              .join(" ")}
                          </>
                        )}
                      </small>
                    </p>
                  </Col>

                  <Col
                    xs="auto"
                    className="pl-0 text-right"
                    style={{ height: "28px" }}
                  >
                    <i
                      className="mdi text-medium mdi-open-in-new"
                      style={{ fontSize: "22px" }}
                    ></i>
                  </Col>
                </Row>
              </div>
            ))}
          </>
        ) : null}
        {!this.state.modules?.length && !this.state.topics?.length ? (
          <>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "inline-block",
              }}
              className="text-dark"
            >
              No results found.
            </div>
          </>
        ) : null}
      </>
    );
  }

  render() {
    return (
      <>
        <Navbar
          className="shadow-sm fixed-top navbar-light bg-white border-bottom"
          id="navbar-main"
        >
          <Container
            fluid
            className="d-block"
            style={{
              paddingLeft: "0px",
              paddingRight: "0px ",
            }}
          >
            {/*<Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Search" type="text" />
                </InputGroup>
              </FormGroup>
    </Form>*/}
            <Row className="align-items-center">
              <Col xs="auto" className="d-none d-sm-none d-lg-flex pr-0">
                <img
                  src={require("../../assets/img/brand/pln_primary_no_dot.svg")}
                  style={{ width: "150px" }}
                  className="mr-6 mr-lg-7"
                ></img>
              </Col>
              <Col xs="auto" className="d-flex d-lg-none">
                <Button
                  onClick={(e) => PubSub.publish("SidenavDrawer", true)}
                  className="btn-icon-only btn-link text-dark p-0 border-0 mr-0 mr-md-5"
                  color=""
                  style={{ fontSize: "30px" }}
                >
                  <i
                    className="mdi mdi-menu"
                    style={{ position: "relative", top: "-2px" }}
                  ></i>
                </Button>
              </Col>
              <Col>
                <InputGroup
                  style={{
                    width: "100%",
                    maxWidth: "600px",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                  className={`${this.props.disableSearch ? "d-none" : ""}`}
                >
                  <InputGroupAddon
                    addonType="prepend"
                    style={{ height: "46px" }}
                  >
                    <InputGroupText className="px-2 border-right-0">
                      <i
                        className={`mdi mdi-magnify ${
                          this.state.search ? "text-dark" : ""
                        }`}
                        style={{ fontSize: 22, lineHeight: 1 }}
                      ></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    autocomplete="off"
                    type="text"
                    placeholder={this.props.searchPlaceholder ?? "Search..."}
                    name="search"
                    value={this.state.search}
                    onChange={this.handleSearchChange.bind(this)}
                    className={`text-dark ${
                      this.state.search ? "border-lighter" : ""
                    }`}
                  ></Input>
                  <InputGroupAddon
                    addonType="append"
                    style={{
                      height: "46px",
                      width: this.state.search ? "44px" : "0px",
                    }}
                  >
                    <InputGroupText
                      style={{ width: "44px" }}
                      className="px-2 border-left-0"
                    >
                      {this.state.search ? (
                        <Button
                          onClick={(e) => this.clearSearch()}
                          color=""
                          className="btn-link text-dark border-0 p-0"
                        >
                          <i
                            className="mdi mdi-close"
                            style={{ fontSize: "24px" }}
                          ></i>
                        </Button>
                      ) : null}
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col
                xs="auto"
                className={`${
                  this.state.search ? "d-none d-md-block" : ""
                } text-right pr-1 pr-sm-1`}
              >
                <Button
                  color=""
                  className="p-0 btn-link text-dark"
                  style={{ fontSize: "24px", lineHeight: 1 }}
                  onClick={() => {
                    this.setState({ instantAnswerOpen: true });
                  }}
                >
                  <i
                    className="mdi mdi-comment-search"
                    style={{ position: "relative", top: -1 }}
                  ></i>
                </Button>
              </Col>
              <Col
                xs="auto"
                className={`${
                  this.state.search ? "d-none d-md-block" : ""
                } text-right pr-2 pr-sm-3 pl-2 pl-sm-3`}
              >
                <Button
                  color=""
                  className="p-0 btn-link text-dark"
                  style={{ fontSize: "24px", lineHeight: 1 }}
                  onClick={() => {
                    this.setState({ notificationsOpen: true });
                  }}
                >
                  {this.state.notificationCount > 0 ? (
                    <>
                      <div
                        style={{
                          position: "absolute",
                          top: 1,
                          right: -2,
                          height: 13,
                          width: 13,
                          border: "2px solid #FFFFFF",
                          borderRadius: "100%",
                          zIndex: 1,
                        }}
                        className="bg-primary"
                      ></div>
                    </>
                  ) : null}
                  <i
                    className="mdi mdi-bell"
                    style={{ position: "relative", top: -1 }}
                  ></i>
                </Button>
              </Col>
              <Col xs="auto" className="text-right pl-0 pl-0">
                <Button
                  color=""
                  className="p-0 btn-link text-dark"
                  style={{ fontSize: "30px" }}
                  onClick={() => {
                    this.setState({ appsOpen: true });
                  }}
                >
                  <i className="mdi mdi-apps"></i>
                </Button>
              </Col>
            </Row>
          </Container>
        </Navbar>
        {this.state.search ? (
          <div
            className="navbar-search-container"
            onClick={(e) => {
              console.log(e.target?.classList);

              if (e.target?.classList && e.target?.classList.length) {
                for (let i = 0; i < e.target.classList.length; i++) {
                  if (e.target?.classList[i].indexOf("card") >= 0) {
                    return;
                  }

                  if (e.target?.classList[i].indexOf("spinner") >= 0) {
                    return;
                  }
                }
              }

              this.clearSearch();
            }}
          >
            <Card
              style={{
                width: "95%",
                maxWidth: "700px",
                position: "relative",
                left: "50%",
                transform: "translateX(-50%)",
              }}
              className="mt-2"
            >
              <CardBody
                style={{
                  minHeight: "300px",
                  maxHeight: "calc(100vh - 150px)",
                  overflowY: "scroll",
                }}
                className="px-0 py-1"
              >
                {this.state.searchLoading ? (
                  <>
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        display: "inline-block",
                      }}
                    >
                      <Spinner size="lg" color="medium" />
                    </div>
                  </>
                ) : (
                  <>{this.renderSearchResults()}</>
                )}
              </CardBody>
            </Card>
          </div>
        ) : null}
        <Drawer
          size="xs"
          placement={"right"}
          open={this.state.appsOpen}
          onClose={() => this.setState({ appsOpen: false })}
          style={{ maxWidth: "100%" }}
        >
          <Drawer.Header>
            <Drawer.Title className="font-weight-bold">
              Switch Apps
            </Drawer.Title>
            <Drawer.Actions></Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-0">
            {this.isGlobal() ? (
              <>
                <div
                  className="px-4 py-3 bg-superlight--hover cursor-pointer"
                  onClick={() => {
                    this.props.history.push("/global");
                  }}
                >
                  <Row className="align-items-center">
                    <Col xs="auto" className="pr-0">
                      <h2 className="mb-0">
                        <i
                          className={`mdi mdi-cog ${
                            window.location.pathname.indexOf("/global") == 0
                              ? "text-primary"
                              : ""
                          }`}
                        ></i>
                      </h2>
                    </Col>
                    <Col xs="">
                      <h3 className="mb-0">System &amp; Platform</h3>
                    </Col>
                    <Col xs="auto">
                      <h3 className="m-0">
                        <i className="mdi mdi-chevron-right"></i>
                      </h3>
                    </Col>
                  </Row>
                </div>

                <hr className="my-0"></hr>
                <div
                  className="px-4 py-3 bg-superlight--hover cursor-pointer"
                  onClick={() => {
                    this.props.history.push("/frandev");
                  }}
                >
                  <Row className="align-items-center">
                    <Col xs="auto" className="pr-0">
                      <h2 className="mb-0">
                        <i
                          className={`mdi mdi-store-marker ${
                            window.location.pathname.indexOf("/frandev") == 0
                              ? "text-primary"
                              : ""
                          }`}
                        ></i>
                      </h2>
                    </Col>
                    <Col xs="">
                      <h3 className="mb-0">Franchise Development</h3>
                    </Col>
                    <Col xs="auto">
                      <h3 className="m-0">
                        <i className="mdi mdi-chevron-right"></i>
                      </h3>
                    </Col>
                  </Row>
                </div>
                <hr className="my-0"></hr>
              </>
            ) : null}
            {this.isProduction() ? (
              <>
                <div
                  className="px-4 py-3 bg-superlight--hover cursor-pointer"
                  onClick={() => {
                    this.props.history.push("/production");
                  }}
                >
                  <Row className="align-items-center">
                    <Col xs="auto" className="pr-0">
                      <h2 className="mb-0">
                        <i
                          className={`mdi mdi-shipping-pallet ${
                            window.location.pathname.indexOf("/production") == 0
                              ? "text-primary"
                              : ""
                          }`}
                        ></i>
                      </h2>
                    </Col>
                    <Col xs="">
                      <h3 className="mb-0">Production</h3>
                    </Col>
                    <Col xs="auto">
                      <h3 className="m-0">
                        <i className="mdi mdi-chevron-right"></i>
                      </h3>
                    </Col>
                  </Row>
                </div>
                <hr className="my-0"></hr>
              </>
            ) : null}

            <div
              className="px-4 py-3 bg-superlight--hover cursor-pointer"
              onClick={() => {
                this.props.history.push("/university");
              }}
            >
              <Row className="align-items-center">
                <Col xs="auto" className="pr-0">
                  <h2 className="mb-0">
                    <i
                      className={`mdi mdi-library ${
                        window.location.pathname.indexOf("/university") == 0
                          ? "text-primary"
                          : ""
                      }`}
                    ></i>
                  </h2>
                </Col>
                <Col xs="">
                  <h3 className="mb-0">Resource Center</h3>
                </Col>
                <Col xs="auto">
                  <h3 className="m-0">
                    <i className="mdi mdi-chevron-right"></i>
                  </h3>
                </Col>
              </Row>
            </div>
            <hr className="my-0"></hr>
            <div
              className="px-4 py-3 bg-superlight--hover cursor-pointer"
              onClick={() => {
                LinkUtils.openInNewTab(
                  "https://projectleannation.talentlms.com/index"
                );
              }}
            >
              <Row className="align-items-center">
                <Col xs="auto" className="pr-0">
                  <h2 className="mb-0">
                    <i className={`mdi mdi-school`}></i>
                  </h2>
                </Col>
                <Col xs="">
                  <h3 className="mb-0">Online Learning (LMS)</h3>
                </Col>
                <Col xs="auto">
                  <h3 className="m-0">
                    <i className="mdi mdi-open-in-new"></i>
                  </h3>
                </Col>
              </Row>
            </div>
            <hr className="my-0"></hr>

            {this.isStorefront() ? (
              <>
                <div
                  className="px-4 py-3 bg-superlight--hover cursor-pointer"
                  onClick={() => {
                    this.props.history.push(
                      `/storefront/${
                        this.props.match?.params?.storeID
                          ? this.props.match?.params?.storeID
                          : "select"
                      }`
                    );
                  }}
                >
                  <Row className="align-items-center">
                    <Col xs="auto" className="pr-0">
                      <h2 className="mb-0">
                        <i
                          className={`mdi mdi-storefront ${
                            window.location.pathname.indexOf("/storefront") == 0
                              ? "text-primary"
                              : ""
                          }`}
                        ></i>
                      </h2>
                    </Col>
                    <Col xs="">
                      <h3 className="mb-0">Storefront</h3>
                    </Col>
                    <Col xs="auto">
                      <h3 className="m-0">
                        <i className="mdi mdi-chevron-right"></i>
                      </h3>
                    </Col>
                  </Row>
                </div>
                <hr className="my-0"></hr>
                <div
                  className="px-4 py-3 bg-superlight--hover cursor-pointer"
                  onClick={() => {
                    this.props.history.push("/reports");
                  }}
                >
                  <Row className="align-items-center">
                    <Col xs="auto" className="pr-0">
                      <h2 className="mb-0">
                        <i
                          className={`mdi mdi-chart-box ${
                            window.location.pathname.indexOf("/reports") == 0
                              ? "text-primary"
                              : ""
                          }`}
                        ></i>
                      </h2>
                    </Col>
                    <Col xs="">
                      <h3 className="mb-0">Reports</h3>
                    </Col>
                    <Col xs="auto">
                      <h3 className="m-0">
                        <i className="mdi mdi-chevron-right"></i>
                      </h3>
                    </Col>
                  </Row>
                </div>
                <hr className="my-0"></hr>
              </>
            ) : null}
            <div
              className="px-4 py-3 bg-superlight--hover cursor-pointer"
              onClick={() => {
                this.props.history.push("/account");
              }}
            >
              <Row className="align-items-center">
                <Col xs="auto" className="pr-0">
                  <h2 className="mb-0">
                    <i
                      className={`mdi mdi-account-cog ${
                        window.location.pathname.indexOf("/account") == 0
                          ? "text-primary"
                          : ""
                      }`}
                    ></i>
                  </h2>
                </Col>
                <Col xs="">
                  <h3 className="mb-0">Account Settings</h3>
                </Col>
                <Col xs="auto">
                  <h3 className="m-0">
                    <i className="mdi mdi-chevron-right"></i>
                  </h3>
                </Col>
              </Row>
            </div>
            <hr className="my-0"></hr>
          </Drawer.Body>
        </Drawer>
        <NotificationDrawer
          open={this.state.notificationsOpen}
          onClose={() => {
            this.setState({ notificationsOpen: false });
          }}
        ></NotificationDrawer>
        <InstantAnswerDrawer
          open={this.state.instantAnswerOpen}
          onClose={() => {
            this.setState({ instantAnswerOpen: false });
          }}
        ></InstantAnswerDrawer>
      </>
    );
  }
}

export default AdminNavbarV2;
