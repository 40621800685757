import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import APIV2 from "lib/APIV2";
import { InputPicker, DateRangePicker } from "rsuite";
import StringUtils from "lib/StringUtils";
import NumberFormat from "react-number-format";

const { beforeToday } = DateRangePicker;

class ModifyStoreTaxModal extends React.Component {
  state = {
    open: false,
    id: null,
    name: "",
    submitting: false,
    type: 0,
    address_1: "",
    address_2: "",
    city: "",
    state: "AL",
    zip_code: "",
    billingAccounts: [],
    loadingBAs: true,
    billingAccountID: null,
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });

    if (this.state[state] == true) {
      this.setState({
        name: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "AL",
        zip_code: "",
        type: 0,
      });
    }
  };

  handleRateChange(val) {
    this.setState({
      taxRate: val,
    });
  }

  submit(e) {
    e.preventDefault();

    this.setState({
      submitting: true,
    });

    let taxApplied = this.state.taxApplied == "Yes";
    let rate = taxApplied ? parseFloat(this.state.taxRate?.value) / 100 : null;

    APIV2.setStoreTax(
      this.props.store._id,
      taxApplied,
      taxApplied ? this.state.description : "",
      rate,
      taxApplied ? this.state.taxMethod : null
    )
      .then(
        (data) => {
          if (data && data.data && data.data.store) {
            console.log(data.data.store);

            PubSub.publish(Event.STORE.MODIFIED, data.data.store);

            this.setState({
              open: false,
              couponCode: "",
              between: [],
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "We were unable to set the default coupon for the store. Please try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  componentDidMount() {
    PubSub.subscribe(Event.STORE.MODIFY_TAX_OPEN, (s) => {
      this.setState({
        open: true,
        taxApplied: s?.taxApplied ? "Yes" : "No",
        description: s?.taxApplied ? s?.taxDescription : "",
        taxRate: {
          value: s?.taxApplied ? s.taxRate * 100 : null,
        },
        taxMethod: s?.taxApplied && s?.taxMethod ? s.taxMethod : "",
      });
    });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <>
        <Modal isOpen={this.state.open} toggle={() => this.toggleModal("open")}>
          <div className="modal-header">
            <h5 className="modal-title" id="createBAModalLabel">
              Modify Store Tax Collection
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("open")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Form onSubmit={this.submit.bind(this)}>
            <div className="modal-body">
              <FormGroup>
                <h5 className="mb-2">Tax Applied</h5>
                <Input
                  type="select"
                  name="taxApplied"
                  value={this.state.taxApplied}
                  onChange={this.handleInputChange.bind(this)}
                >
                  <option value="" disabled>
                    Choose An Option
                  </option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Input>
              </FormGroup>
              {this.state.taxApplied == "Yes" && (
                <>
                  <FormGroup>
                    <h5 className="mb-2">Description</h5>
                    <Input
                      type="text"
                      name="description"
                      placeholder="Description"
                      value={this.state.description}
                      onChange={this.handleInputChange.bind(this)}
                    ></Input>
                  </FormGroup>
                  <FormGroup>
                    <h5 className="mb-2">Tax Rate</h5>
                    <NumberFormat
                      className="form-control mb-3"
                      fixedDecimalScale={false}
                      decimalScale={3}
                      required={false}
                      allowNegative={false}
                      placeholder="0.000%"
                      suffix="%"
                      value={this.state.taxRate.value}
                      onValueChange={this.handleRateChange.bind(this)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <h5 className="mb-2">Applied To</h5>
                    <Input
                      type="select"
                      name="taxMethod"
                      value={this.state.taxMethod}
                      onChange={this.handleInputChange.bind(this)}
                    >
                      <option value="" disabled>
                        Choose An Option
                      </option>
                      <option value="total">
                        Invoice Subtotal (Plan Unit Price + Delivery/Shipping
                        Fee)
                      </option>
                      <option value="unitPrice">Plan Unit Price</option>
                    </Input>
                  </FormGroup>
                </>
              )}
            </div>
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="reset"
                onClick={() => this.toggleModal("open")}
              >
                Close
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={
                  this.state.submitting ||
                  (this.state.taxApplied == "Yes" &&
                    (!this.state.taxRate?.value || !this.state.taxMethod))
                }
              >
                Save
              </Button>
            </div>
          </Form>
        </Modal>
      </>
    );
  }
}

export default ModifyStoreTaxModal;
