import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  BarChart,
  Card,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Badge, Button, Col, Row } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import ClientOrderKPIDetailDrawer from "./ClientOrderKPIDetailDrawer";
import ChartMetricHeader from "./ChartMetricHeader";
import pluralize from "pluralize";

class BusinessReviewOrderHistoryCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    categories: [],
  };

  async _fetchData(cubejsApi, stores, dateRange) {
    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["SubscriptionFulfillmentReports.avgFulfilledCount"],
          order: {
            "SubscriptionFulfillmentReports.createdat": "asc",
          },

          timeDimensions: [
            {
              dimension: "SubscriptionFulfillmentReports.fulfillmentdate",
              dateRange: [dateRange[0], dateRange[1]],
              granularity: "month",
            },
          ],

          filters: [
            {
              member: "SubscriptionFulfillmentReports.storeid",
              operator: "equals",
              values: stores,
            },
            {
              member: "SubscriptionFulfillmentReports.fulfillmentdate",
              operator: "beforeDate",
              values: [moment().toISOString()],
            },
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          data = data.map((item) => {
            let mom = moment(
              item[`SubscriptionFulfillmentReports.fulfillmentdate.month`]
            );

            mom = mom.startOf("month");

            return {
              date: mom.toDate(),
              dateString: mom.format("YYYY-MM-DD"),
              granularityString: "month",
              count: item["SubscriptionFulfillmentReports.avgFulfilledCount"],
            };
          });

          return resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async loadReport(store, stores, dateRange) {
    this.setState({
      loading: true,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!stores?.length || !store || !dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    let storeData = null;
    let compData = null;

    try {
      storeData = await this._fetchData(cubejsApi, [store], dateRange);
    } catch (e) {
      console.error(e);
    }

    try {
      compData = await this._fetchData(cubejsApi, stores, dateRange);
    } catch (e) {
      console.error(e);
    }

    if (storeData !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });

      storeData.dataAvailable = true;
    } else {
      this.setState({
        dataAvailable: false,
      });
    }

    let out = [];

    for (let i = 0; i < storeData?.length; i++) {
      let record = {
        date: storeData[i].date,
        [`${this.props.storeName?.replace("Project LeanNation", "PLN")}`]:
          storeData[i].count,
        dateString: moment(storeData[i].date).format("MMMM YY"),
      };

      out.push(record);
    }

    for (let i = 0; i < compData?.length; i++) {
      compData[i].dateString = moment(compData[i].date).format("MMMM YY");

      const idx = _.findIndex(out, { dateString: compData[i].dateString });

      if (idx >= 0) {
        out[idx]["System Average"] = compData[i].count;
      } else {
        let record = {
          date: compData[i].date,
          [`${this.props.storeName?.replace("Project LeanNation", "PLN")}`]:
            null,
          "System Average": compData[i].count,
          dateString: moment(compData[i].date).format("MMMM YY"),
        };

        out.push(record);
      }
    }

    out = _.sortBy(out, "date");

    //console.log(out);

    let summary = {
      store: 0,
      system: 0,
    };

    for (let i = 0; i < out.length; i++) {
      summary.store +=
        out[i][
          `${this.props.storeName?.replace("Project LeanNation", "PLN")}`
        ] || 0;
      summary.system += out[i]["System Average"] || 0;
    }

    summary.store =
      summary.store /
      _.filter(
        out,
        (o) =>
          o[`${this.props.storeName?.replace("Project LeanNation", "PLN")}`]
      ).length;
    summary.system =
      summary.system / _.filter(out, (o) => o["System Average"]).length;

    summary.percentDifference =
      ((summary.store - summary.system) / summary.system) * 100;

    if (summary.percentDifference > 0) {
      summary.isIncreasePositive = true;
      summary.deltaType = "moderateIncrease";
    } else if (summary.percentDifference == 0) {
      summary.isIncreasePositive = false;
      summary.deltaType = "unchanged";
    } else {
      summary.isIncreasePositive = false;
      summary.deltaType = "moderateDecrease";
    }

    //console.log(summary);

    this.setState({
      loading: false,
      storeData,
      compData,
      combinedData: out,
      summary: summary,
      categories: [
        this.props.storeName?.replace("Project LeanNation", "PLN"),
        "System Average",
      ],
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.store != prevProps?.store ||
      this.props.compareStores != prevProps?.compareStores ||
      this.props.dateRange != prevProps?.dateRange
    ) {
      this.loadReport(
        this.props.store,
        this.props.compareStores,
        this.props.dateRange
      );
    }
  }

  componentDidMount() {
    if (this.props.store && this.props.dateRange && this.props.compareStores) {
      this.loadReport(
        this.props.store,
        this.props.compareStores,
        this.props.dateRange
      );
    }
  }

  render() {
    return (
      <>
        <Card hFull={true}>
          <ChartMetricHeader
            title="Last 3 Months"
            description="Average weekly member orders by month compared to system average."
            loading={this.state.loading}
            dataAvailable={this.state.dataAvailable}
            showPercentChange={false}
          ></ChartMetricHeader>

          {this.state.loading ? (
            <div
              className="tr-mt-2 skeleton"
              style={{ height: "calc(144px + 27px)", width: "100%" }}
            ></div>
          ) : (
            <>
              <Row className="align-items-center mt-2">
                <Col xs="12" sm="12" md="auto">
                  <BadgeDelta
                    isIncreasePositive={true}
                    deltaType={this.state.summary?.deltaType}
                    text={`${this.state.summary?.percentDifference?.toFixed(
                      1
                    )}% vs. system`}
                  />{" "}
                </Col>
                <Col xs="12" sm="" md="" className="text-md-right mt-2 mt-sm-0">
                  <p
                    className="text-dark m-0"
                    style={{ fontSize: "0.875rem", lineHeight: "1.25rem" }}
                  >
                    {this.state.summary?.store.toFixed(1)}/wk. vs. system Avg.{" "}
                    {this.state.summary?.system?.toFixed(1)}/wk.
                  </p>
                </Col>
              </Row>
              <BarChart
                data={this.state.combinedData}
                dataKey="dateString"
                categories={this.state.categories}
                colors={["orange", "gray"]}
                valueFormatter={(number) => {
                  return (
                    StringUtils.numberFormat(number?.toFixed(1)) + " Orders"
                  );
                }}
                height="h-36"
                marginTop="mt-2"
                yAxisWidth="w-14"
              />
            </>
          )}
        </Card>
      </>
    );
  }
}

export default BusinessReviewOrderHistoryCard;
