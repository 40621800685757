import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  Block,
  ButtonInline,
  Card,
  Divider,
  Flex,
  Footer,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import ClientOrderKPIDetailDrawer from "./ClientOrderKPIDetailDrawer";
import ChartMetricHeader from "./ChartMetricHeader";
import StoreLeaderboardKPIDetailDrawer from "./StoreLeaderboardKPIDetailDrawer";
import ScorecardNewHFNDetailDrawer from "./ScorecardNewHFNDetailDrawer";

class ScorecardNewHFNChartCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: true,
    activeTab: "A",
    dataSet: [],
  };

  /**
   * Fetches a summary of all the HFN data
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchLeadData(cubejsApi, stores, dateRange) {
    let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

    secondDifference = secondDifference / 1000;

    let granularity = secondDifference > 60 * 60 * 24 * 90 ? "week" : "day";

    if (secondDifference >= 60 * 60 * 24 * 364) {
      granularity = "month";
    }

    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["Metrics.count"],
          order: {
            "Metrics.createdat": "asc",
          },

          timeDimensions: [
            {
              dimension: "Metrics.createdat",
              dateRange: [dateRange[0], dateRange[1]],
              granularity,
            },
          ],
          filters: [
            {
              member: "Metrics.metadatastoreid",
              operator: "equals",
              values: stores,
            },
            {
              member: "Metrics.name",
              operator: "equals",
              values: ["hfn"],
            },
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          let total = 0;

          if (!data?.length) {
            return resolve(null);
          }

          data = data.map((item) => {
            total += item["Metrics.count"];

            let mom = moment(item[`Metrics.createdat.${granularity}`]);

            let granularityString = mom.format("MM/DD/YYYY");

            if (granularity == "week") {
              mom.day(0);
              granularityString = "Week " + mom.format("MM/DD/YYYY");
            }

            if (granularity == "month") {
              mom.startOf("month");

              granularityString = mom.format("MMMM YY");
            }

            return {
              count: item["Metrics.count"],
              dateObj: mom.toDate(),
              date: granularityString,
            };
          });

          return resolve({
            total,
            history: data,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async _fetchLeadReport(stores, dateRange) {
    this.setState({
      loading: true,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    if (!stores?.length) {
      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchLeadData(cubejsApi, stores, dateRange);
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load new HFN quantity.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });
    }

    if (this.props.comparePrevious) {
      let previousCount = null;

      let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

      secondDifference = secondDifference / 1000;

      let granularity = secondDifference > 60 * 60 * 24 * 90 ? "week" : "day";

      if (secondDifference >= 60 * 60 * 24 * 364) {
        granularity = "month";
      }

      let startMoment = moment(dateRange[0].toISOString());
      let endMoment = moment(dateRange[1].toISOString());

      startMoment.subtract(secondDifference, "seconds");
      endMoment.subtract(secondDifference + 1, "seconds");

      try {
        previousCount = await this._fetchLeadData(cubejsApi, stores, [
          startMoment.toDate(),
          endMoment.toDate(),
        ]);
      } catch (e) {}

      if (previousCount !== null) {
        let percentChange =
          previousCount?.total > 0
            ? (currentCount?.total - previousCount?.total) /
              previousCount?.total
            : null;

        let declineMode = "";
        let isNegative = false;

        if (percentChange > 0) {
          if (Math.abs(percentChange) < 0.015) {
            declineMode = "unchanged";
          } else if (Math.abs(percentChange) < 0.1) {
            declineMode = "moderateIncrease";
          } else if (Math.abs(percentChange) >= 0.1) {
            declineMode = "increase";
          }
        } else {
          isNegative = true;

          if (Math.abs(percentChange) < 0.015) {
            declineMode = "moderateDecrease";
          } else if (Math.abs(percentChange) < 0.1) {
            declineMode = "moderateDecrease";
          } else if (Math.abs(percentChange) >= 0.1) {
            declineMode = "decrease";
          }
        }

        let dataSet = [];

        for (let i = 0; i < currentCount?.history?.length; i++) {
          const cur = currentCount.history[i];

          const offsetDate = moment(cur?.dateObj?.toISOString()).subtract(
            secondDifference,
            "seconds"
          );

          let granularityString = offsetDate.format("MM/DD/YYYY");

          if (granularity == "week") {
            offsetDate.day(0);
            granularityString = "Week " + offsetDate.format("MM/DD/YYYY");
          }

          if (granularity == "month") {
            offsetDate.startOf("month");

            granularityString = offsetDate.format("MMMM YY");
          }

          const prev = _.findWhere(previousCount?.history, {
            date: granularityString,
          });

          let out = {
            dateObj: cur?.dateObj,
            date: cur?.date,
            HFNs: cur?.count,
            Previous:
              prev?.count !== null && prev?.count !== undefined
                ? prev.count
                : 0,
          };

          dataSet.push(out);
        }

        for (let i = 0; i < previousCount?.history?.length; i++) {
          const item = previousCount?.history[i];

          const dateObj = moment(item?.dateObj?.toISOString())
            .add(secondDifference, "seconds")
            .hours(0)
            .minute(0)
            .second(0)
            .millisecond(0);

          const realDate = moment(item?.dateObj?.toISOString())
            .hours(0)
            .minute(0)
            .second(0)
            .millisecond(0);

          //console.log(dateObj?.toISOString());

          let granularityString = dateObj.format("MM/DD/YYYY");
          let realDateString = realDate.format("MM/DD/YYYY");

          if (granularity == "week") {
            dateObj.day(0);
            realDate.day(0);
            granularityString = "Week " + dateObj.format("MM/DD/YYYY");

            realDateString = "Week " + realDate.format("MM/DD/YYYY");
          }

          if (granularity == "month") {
            dateObj.startOf("month");
            realDate.startOf("month");

            granularityString = dateObj.format("MMMM YY");
            realDateString = realDate.format("MMMM YY");
          }

          const idx = _.findIndex(dataSet, {
            date: granularityString,
          });

          if (idx >= 0) {
            dataSet[idx].Previous = item?.count;
            dataSet[idx].date += ` vs. ${realDateString}`;
          } else if (
            dateObj?.toDate() >= dateRange[0] &&
            dateObj.toDate() <= dateRange[1]
          ) {
            dataSet.push({
              Previous: item?.count,
              HFNs: 0,
              dateObj: dateObj?.toDate(),
              date: granularityString + ` vs. ${realDateString}`,
            });
          }
        }

        dataSet = _.sortBy(dataSet, "dateObj");

        isNegative = !isNegative;

        this.setState({
          previousCount,
          changeIsNegative: isNegative,
          deltaType: declineMode,
          percentChange,
          percentChangeString: Math.abs(percentChange * 100).toFixed(1) + "%",
          dataSet,
        });
      }
    }

    this.setState({
      currentCount,
      loading: false,
    });
  }

  /**
   * Load all report information
   *
   * @param {*} stores
   * @param {*} dateRange
   * @param {*} compare
   */
  async loadReport(stores, dateRange, compare) {
    this.setState({
      loading: true,
    });

    this._fetchLeadReport(stores, dateRange);
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.stores != prevProps?.stores && this.props.stores?.length) ||
      this.props.dateRange != prevProps?.dateRange ||
      this.props.comparePrevious != prevProps.comparePrevious
    ) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  componentDidMount() {
    if (this.props.stores && this.props.dateRange) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  render() {
    return (
      <>
        <Card hFull={true}>
          <ChartMetricHeader
            title="New HFNs"
            description="All members who went on hold during the selected date range."
            forceWrapComparison={true}
            isIncreasePositive={false}
            actions={
              <>
                <Button
                  size="sm"
                  outline
                  color="dark"
                  className="border-0 btn-icon-only"
                  disabled={this.state.loading || !this.state.dataAvailable}
                  onClick={() => {
                    this.setState({
                      open: true,
                    });
                  }}
                >
                  <i className="mdi mdi-fullscreen"></i>
                </Button>
              </>
            }
            loading={this.state.loading}
            dataAvailable={this.state.dataAvailable}
            metric={
              <>
                {StringUtils.numberFormat(this.state.currentCount?.total)} HFNs
              </>
            }
            comparisonMetric={
              this.state.previousCount !== null &&
              !isNaN(this.state.previousCount?.total) && (
                <>{StringUtils.numberFormat(this.state.previousCount?.total)}</>
              )
            }
            dateRange={this.props.dateRange}
            deltaType={this.state.deltaType}
            percentChange={this.state.percentChangeString}
            showPercentChange={true}
          ></ChartMetricHeader>

          {this.state.loading ? (
            <div
              className="mt-4 skeleton"
              style={{ height: 320, width: "100%" }}
            ></div>
          ) : (
            <>
              {this.state.dataAvailable ? (
                <AreaChart
                  marginTop="mt-4"
                  data={this.state.dataSet}
                  categories={["HFNs", "Previous"]}
                  dataKey="date"
                  colors={["orange", "gray"]}
                  valueFormatter={(number) => {
                    return StringUtils.numberFormat(number);
                  }}
                  height="h-80"
                />
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "calc(320px + 1rem)", width: "100%" }}
                >
                  <p className="m-0">
                    No data available.
                    {this.state.error ? ` ${this.state.error}` : null}
                  </p>
                </div>
              )}
            </>
          )}
        </Card>

        <ScorecardNewHFNDetailDrawer
          open={this.state.open}
          onClose={() => {
            this.setState({
              open: false,
            });
          }}
          stores={this.props.stores}
          dateRange={this.props.dateRange}
        />
      </>
    );
  }
}

export default ScorecardNewHFNChartCard;
