import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import { Badge, Button, Card, CardBody, Col, Input, Row } from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  Tooltip,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import swal from "sweetalert";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import TaskModifyDrawer from "./TaskModifyDrawer";
import CustomerInformationWidget from "components/Widgets/CustomerInformationWidget";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import NoteBody from "components/NoteBody";
import Spinner from "reactstrap/lib/Spinner";
import LeadContactInformationWidget from "components/Widgets/LeadContactInformationWidget";
import AffiliateAboutWidget from "components/Widgets/AffiliateAboutWidget";
import TechSupportTicketDrawer from "./TechSupportTicketDrawer";
import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";
import User from "lib/Model/User";
import LinkUtils from "lib/LinkUtils";
import Linkify from "components/Linkify";
import ReactPlayer from "react-player";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";

class InstantAnswerDrawer extends React.Component {
  state = {
    searching: false,
    searchResponse: null,
    searchError: null,
    prompt: "",
  };

  dropdownRef = React.createRef();

  componentDidMount() {}

  submitPrompt(prompt) {
    this.setState({
      searching: true,
    });

    APIV2.ragSearchLibraryTopics(prompt)
      .then(
        (data) => {
          let response = data?.data?.response;

          this.setState({
            searchResponse: response,
          });
        },
        (e) => {
          this.setState({
            searchResponse: null,
            searchError: true,
          });
        }
      )
      .finally(() => {
        this.setState({
          searching: false,
        });
      });
  }

  toggleModal() {
    this.props.onClose();
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          backdrop="static"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h2 className="mb-2" style={{ position: "relative", top: "5px" }}>
              <Badge
                color="success"
                className="py-1 mr-2"
                style={{ position: "relative", top: -1 }}
              >
                BETA
              </Badge>
              Instant Answers
            </h2>
          </Drawer.Header>
          <Drawer.Body className="p-0">
            <div className="p-3 border-bottom border-lighter">
              <p className="text-dark mt-0 mb-3" style={{ lineHeight: 1.3 }}>
                Receive instant answers to your questions directly from the
                Resource Center using GPT-powered search.
              </p>

              <div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();

                    this.submitPrompt(this.state.prompt);
                  }}
                >
                  <Row className="align-items-center">
                    <Col xs="">
                      <Input
                        style={{
                          padding: 7,
                          paddingLeft: 10,
                          paddingRight: 10,
                          height: 38,
                        }}
                        size=""
                        value={this.state.prompt}
                        onChange={(e) => {
                          this.setState({ prompt: e.target.value });
                        }}
                        placeholder="Ask a question..."
                      />
                    </Col>
                    <Col xs="auto" className="pl-0">
                      <Button
                        className="btn-icon-only"
                        color="primary"
                        type="submit"
                        disabled={!this.state.prompt || this.state.searching}
                      >
                        {this.state.searching ? (
                          <>
                            <Spinner size="sm"></Spinner>
                          </>
                        ) : (
                          <>
                            <i className="mdi mdi-chevron-right"></i>
                          </>
                        )}
                      </Button>
                    </Col>
                    <Col xs="12">
                      <div className="mt-1 text-dark small">
                        Instant Answers is in BETA and can make mistakes. It
                        uses the Resource Center as it's knowledge base. Check
                        important info.
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
            <div className="p-3">
              {this.state.searching ? (
                <div className="rounded skeleton" style={{ height: 400 }}></div>
              ) : null}
              {this.state.searchResponse && !this.state.searching ? (
                <>
                  <div style={{ position: "relative" }}>
                    <div
                      className=""
                      style={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <Button
                        size="sm"
                        color="dark"
                        outline
                        className="btn-icon-only"
                        onClick={() => {
                          this.setState({
                            searchResponse: null,
                            searchError: false,
                            prompt: "",
                          });
                        }}
                      >
                        <i className="mdi mdi-close"></i>
                      </Button>
                    </div>
                    <div
                      className="university-gpt-response-container pt-5"
                      dangerouslySetInnerHTML={{
                        __html: this.state.searchResponse,
                      }}
                    ></div>
                  </div>
                </>
              ) : null}
              {this.state.searchError && !this.state.searching ? (
                <div style={{ position: "relative" }}>
                  <div
                    className="p-3"
                    style={{ position: "absolute", top: 0, right: 0 }}
                  >
                    <Button
                      size="sm"
                      color="dark"
                      outline
                      className="btn-icon-only"
                      onClick={() => {
                        this.setState({
                          searchResponse: null,
                          searchError: false,
                          prompt: "",
                        });
                      }}
                    >
                      <i className="mdi mdi-close"></i>
                    </Button>
                  </div>
                  <div className="text-center p-3">
                    <p className="m-0 text-danger">
                      Unable to fetch search results. An error occurred.
                    </p>
                  </div>
                </div>
              ) : null}
            </div>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(InstantAnswerDrawer);
