import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import APIV2 from "lib/APIV2";

class CreateProductGroupModal extends React.Component {
  state = {
    open: false,
    name: "",
    description: "",
    key: "",
    secret: "",
    submitting: false,
    groupCode: "",
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });

    if (this.state[state] == true) {
      this.setState({
        name: "",
        description: "",
        key: "",
        secret: "",
        groupCode: "",
      });
    }
  };

  handleNameChange(event) {
    const val = event.target.value;

    this.setState({
      name: val,
    });
  }

  handleDescriptionChange(event) {
    const val = event.target.value;

    this.setState({
      description: val,
    });
  }

  handleMinChange(event) {
    const val = event.target.value;

    this.setState({
      calorieMin: val,
    });
  }

  handleCodeChange(event) {
    const val = event.target.value;

    this.setState({
      groupCode: val,
    });
  }

  handleMaxChange(event) {
    const val = event.target.value;

    this.setState({
      calorieMax: val,
    });
  }

  submit(e) {
    e.preventDefault();

    this.setState({
      submitting: true,
    });

    APIV2.createProductGroup(
      this.state.name,
      this.state.groupCode,
      this.state.description,
      parseInt(this.state.calorieMin),
      parseInt(this.state.calorieMax)
    )
      .then(
        (data) => {
          if (data && data.data && data.data.planType) {
            PubSub.publish(Event.PRODUCT_GROUP.CREATED, data.data.planType);

            this.setState({
              name: "",
              description: "",
              calorieMin: "",
              calorieMax: "",
              groupCode: "",
              open: false,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert("We were unable to create the group. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  componentDidMount() {
    PubSub.subscribe(Event.PRODUCT_GROUP.CREATE_OPEN, () => {
      this.setState({
        open: true,
      });
    });
  }

  render() {
    return (
      <>
        <Modal isOpen={this.state.open} toggle={() => this.toggleModal("open")}>
          <div className="modal-header">
            <h5 className="modal-title" id="createBAModalLabel">
              Add Product Category
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("open")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Form onSubmit={this.submit.bind(this)}>
            <div className="modal-body">
              <Input
                id="createBAName"
                placeholder="Name"
                type="text"
                className="mb-3"
                required={true}
                value={this.state.name}
                onChange={this.handleNameChange.bind(this)}
              />
              <Input
                id="createBAName"
                placeholder="Group Code"
                type="text"
                className="mb-3"
                required={true}
                value={this.state.groupCode}
                onChange={this.handleCodeChange.bind(this)}
              />
              <Input
                id="createBADescription"
                placeholder="Description"
                rows="3"
                type="textarea"
                className="mb-3"
                required={false}
                value={this.state.description}
                onChange={this.handleDescriptionChange.bind(this)}
              />
              <Input
                id="createBAKey"
                placeholder="Calorie Min"
                type="text"
                className="mb-3"
                required={true}
                value={this.state.calorieMin}
                onChange={this.handleMinChange.bind(this)}
              />
              <Input
                id="createBASecret"
                placeholder="Calorie Max"
                type="text"
                required={true}
                value={this.state.calorieMax}
                onChange={this.handleMaxChange.bind(this)}
              />
            </div>
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="reset"
                onClick={() => this.toggleModal("open")}
              >
                Close
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={this.state.submitting}
              >
                Add
              </Button>
            </div>
          </Form>
        </Modal>
      </>
    );
  }
}

export default CreateProductGroupModal;
