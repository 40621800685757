import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Spinner,
  Collapse,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import moment from "moment";
import { DatePicker, SelectPicker } from "rsuite";
import StringUtils from "lib/StringUtils";
import { Table, Cell, Column, HeaderCell } from "rsuite-table";
import _ from "underscore";
import MoneyCell from "components/Table/MoneyCell";
import NumericCell from "components/Table/NumericCell";
import StoreCell from "components/Table/StoreCell";
import BootstrapTable from "react-bootstrap-table-next";
import MealsOrderedWidget from "components/Widgets/Fulfillment/MealsOrderedWidget";
import PlansFulfilledWidget from "components/Widgets/Fulfillment/PlansFulfilledWidget";
import AverageBoxSizeWidget from "components/Widgets/Fulfillment/AverageBoxSizeWidget";
import PlanFrequencyChartWidget from "components/Widgets/Fulfillment/PlanFrequencyChartWidget";
import PlanProcurementChartWidget from "components/Widgets/Fulfillment/PlanProcurementChartWidget";
import PlanTypeChartWidget from "components/Widgets/Fulfillment/PlanTypeChartWidget";
import { Link } from "react-router-dom";
import DataAPI from "lib/DataAPI";
import cubejs from "@cubejs-client/core";

class StoreScorecard extends React.Component {
  state = {
    stores: [],
    loading: true,
    fulfillmentDate: new Date(),
    availableDates: [],
    storeOpen: true,
    mealOpen: true,
    planOpen: true,
    storePerformance: [],
    dates: [],
    compareToOptions: [],
    fulfillmentOpen: true,
    revenueOpen: true,
    growthOpen: true,
    netLoading: false,
    newMembersLoading: true,
    hfnsLoading: true,
    reMembersLoading: true,
    newOnlineLoading: true,
    newStoreLoading: true,
  };

  loadCompareToOptions(first) {
    let current = moment(this.state.fulfillmentMoment.format("YYYY-MM-DD"));

    let weekPrior = moment(current.format());
    let monthPrior = moment(current.format());
    let quarterPrior = moment(current.format());
    let yearPrior = moment(current.format());

    weekPrior = weekPrior.subtract(1, "week");
    monthPrior = monthPrior.subtract(4, "weeks");
    quarterPrior = quarterPrior.subtract(12, "weeks");
    yearPrior = yearPrior.subtract(1, "year").day(6);

    //let monthPrior = current.subtract(4 * 7, "days");
    //let quarterPrior = current.subtract(12 * 7, "days");
    //let yearPrior = current.subtract(1, "year");

    let compareTo = [];

    if (
      this.state.availableDates.indexOf(weekPrior.format("YYYY-MM-DD")) >= 0
    ) {
      compareTo.push({
        value: "lastweek",
        label: "vs. Previous Week",
      });
    }

    if (
      this.state.availableDates.indexOf(monthPrior.format("YYYY-MM-DD")) >= 0
    ) {
      compareTo.push({
        value: "lastmonth",
        label: "vs. Previous Month",
      });
    }

    if (
      this.state.availableDates.indexOf(quarterPrior.format("YYYY-MM-DD")) >= 0
    ) {
      compareTo.push({
        value: "lastquarter",
        label: "vs. Previous Quarter",
      });
    }

    if (
      this.state.availableDates.indexOf(yearPrior.format("YYYY-MM-DD")) >= 0
    ) {
      compareTo.push({
        value: "lastyear",
        label: "vs. Previous Year",
      });
    }

    this.setState({ compareToOptions: compareTo });
  }

  getAvailableDates() {
    return new Promise((resolve) => {
      APIV2.getStoreSubscriptionFulfillmentDates(
        this.props.match?.params?.storeID
      )
        .then(
          (data) => {
            let dates = data.data.dates;

            dates.push(this.state.fulfillmentMoment.format("YYYY-MM-DD"));

            this.setState({
              availableDates: dates,
            });

            this.loadCompareToOptions(true);
          },
          (e) => {
            this.setState({
              availableDates: [],
            });

            console.error(e);
          }
        )
        .finally(() => {
          resolve();
        });
    });
  }

  loadGrowthSummary() {
    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    this.setState({
      newMembersLoading: true,
      newStoreLoading: true,
      newOnlineLoading: true,
      reMembersLoading: true,
      referralsLoading: true,
      hfnsLoading: true,
      consultationsLoading: true,
      clientConsultationsLoading: true,
      netMembers: 0,
    });

    // Load New Leads
    cubejsApi
      .load({
        measures: ["Metrics.totalEvents"],
        timeDimensions: [
          {
            dimension: "Metrics.createdat",
            dateRange: [
              moment(this.state.fulfillmentMoment?.toDate().toISOString())
                ?.day(0)
                .hour(0)
                .minute(0)
                .second(0)
                ?.format("YYYY-MM-DD"),
              moment(this.state.fulfillmentMoment?.toDate().toISOString())
                ?.hour(23)
                .minute(59)
                .second(59)
                .format("YYYY-MM-DD"),
            ],
          },
        ],
        order: {
          "Metrics.createdat": "asc",
        },
        filters: [
          {
            member: "Metrics.metadatastoreid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
          {
            member: "Metrics.name",
            operator: "equals",
            values: ["member_new"],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        let total = data?.length ? data[0]["Metrics.totalEvents"] : 0;
        let netTotal = this.state.netMembers ? this.state.netMembers : 0;

        this.setState({
          newMembers: StringUtils.numberFormat(total),
          netMembers: netTotal + total,
        });
      })
      .catch(() => {
        this.setState({ newMembers: "--" });
      })
      .finally(() => {
        this.setState({
          newMembersLoading: false,
        });
      });

    // Load New Leads
    cubejsApi
      .load({
        measures: ["Metrics.totalEvents"],
        timeDimensions: [
          {
            dimension: "Metrics.createdat",
            dateRange: [
              moment(this.state.fulfillmentMoment?.toDate().toISOString())
                ?.day(0)
                .hour(0)
                .minute(0)
                .second(0)
                ?.format("YYYY-MM-DD"),
              moment(this.state.fulfillmentMoment?.toDate().toISOString())
                ?.hour(23)
                .minute(59)
                .second(59)
                .format("YYYY-MM-DD"),
            ],
          },
        ],
        order: {
          "Metrics.createdat": "asc",
        },
        filters: [
          {
            member: "Metrics.metadatastoreid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
          {
            member: "Metrics.metadataSourcetype",
            operator: "notContains",
            values: ["In-Store"],
          },
          {
            member: "Metrics.name",
            operator: "equals",
            values: ["member_new"],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        let total = data?.length ? data[0]["Metrics.totalEvents"] : 0;

        this.setState({
          newOnline: StringUtils.numberFormat(total),
        });
      })
      .catch(() => {
        this.setState({ newOnline: "--" });
      })
      .finally(() => {
        this.setState({
          newOnlineLoading: false,
        });
      });

    // Load New Leads
    cubejsApi
      .load({
        measures: ["Metrics.totalEvents"],
        timeDimensions: [
          {
            dimension: "Metrics.createdat",
            dateRange: [
              moment(this.state.fulfillmentMoment?.toDate().toISOString())
                ?.day(0)
                .hour(0)
                .minute(0)
                .second(0)
                ?.format("YYYY-MM-DD"),
              moment(this.state.fulfillmentMoment?.toDate().toISOString())
                ?.hour(23)
                .minute(59)
                .second(59)
                .format("YYYY-MM-DD"),
            ],
          },
        ],
        order: {
          "Metrics.createdat": "asc",
        },
        filters: [
          {
            member: "Metrics.metadatastoreid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
          {
            member: "Metrics.metadataSourcetype",
            operator: "equals",
            values: ["In-Store"],
          },
          {
            member: "Metrics.name",
            operator: "equals",
            values: ["member_new"],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        let total = data?.length ? data[0]["Metrics.totalEvents"] : 0;

        this.setState({
          newStore: StringUtils.numberFormat(total),
        });
      })
      .catch(() => {
        this.setState({ newStore: "--" });
      })
      .finally(() => {
        this.setState({
          newStoreLoading: false,
        });
      });

    // Load New Leads
    cubejsApi
      .load({
        measures: ["Metrics.totalEvents"],
        timeDimensions: [
          {
            dimension: "Metrics.createdat",
            dateRange: [
              moment(this.state.fulfillmentMoment?.toDate().toISOString())
                ?.day(0)
                .hour(0)
                .minute(0)
                .second(0)
                ?.format("YYYY-MM-DD"),
              moment(this.state.fulfillmentMoment?.toDate().toISOString())
                ?.hour(23)
                .minute(59)
                .second(59)
                .format("YYYY-MM-DD"),
            ],
          },
        ],
        order: {
          "Metrics.createdat": "asc",
        },
        filters: [
          {
            member: "Metrics.metadatastoreid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
          {
            member: "Metrics.name",
            operator: "equals",
            values: ["member_reactivate"],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        let total = data?.length ? data[0]["Metrics.totalEvents"] : 0;

        let netTotal = this.state.netMembers ? this.state.netMembers : 0;

        this.setState({
          reMembers: StringUtils.numberFormat(total),
          netMembers: netTotal + total,
        });
      })
      .catch(() => {
        this.setState({ reMembers: "--" });
      })
      .finally(() => {
        this.setState({
          reMembersLoading: false,
        });
      });

    // Load New Leads
    cubejsApi
      .load({
        measures: ["Metrics.totalEvents"],
        timeDimensions: [
          {
            dimension: "Metrics.createdat",
            dateRange: [
              moment(this.state.fulfillmentMoment?.toDate().toISOString())
                ?.day(0)
                .hour(0)
                .minute(0)
                .second(0)
                ?.format("YYYY-MM-DD"),
              moment(this.state.fulfillmentMoment?.toDate().toISOString())
                ?.hour(23)
                .minute(59)
                .second(59)
                .format("YYYY-MM-DD"),
            ],
          },
        ],
        order: {
          "Metrics.createdat": "asc",
        },
        filters: [
          {
            member: "Metrics.metadatastoreid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
          {
            member: "Metrics.name",
            operator: "equals",
            values: ["hfn"],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        let total = data?.length ? data[0]["Metrics.totalEvents"] : 0;

        let netTotal = this.state.netMembers ? this.state.netMembers : 0;

        this.setState({
          hfns: StringUtils.numberFormat(total),
          netMembers: netTotal - total,
        });
      })
      .catch(() => {
        this.setState({ hfns: "--" });
      })
      .finally(() => {
        this.setState({
          hfnsLoading: false,
        });
      });

    cubejsApi
      .load({
        measures: ["Metrics.totalEvents"],
        timeDimensions: [
          {
            dimension: "Metrics.createdat",
            dateRange: [
              moment(this.state.fulfillmentMoment?.toDate().toISOString())
                ?.day(0)
                .hour(0)
                .minute(0)
                .second(0)
                ?.format("YYYY-MM-DD"),
              moment(this.state.fulfillmentMoment?.toDate().toISOString())
                ?.hour(23)
                .minute(59)
                .second(59)
                .format("YYYY-MM-DD"),
            ],
          },
        ],
        order: {
          "Metrics.createdat": "asc",
        },
        filters: [
          {
            member: "Metrics.metadatastoreid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
          {
            member: "Metrics.name",
            operator: "equals",
            values: ["member_consultation"],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        let total = data?.length ? data[0]["Metrics.totalEvents"] : 0;

        this.setState({
          consultations: StringUtils.numberFormat(total),
        });
      })
      .catch(() => {
        this.setState({ consultations: "--" });
      })
      .finally(() => {
        this.setState({
          consultationsLoading: false,
        });
      });

    cubejsApi
      .load({
        measures: ["Metrics.totalEvents"],
        timeDimensions: [
          {
            dimension: "Metrics.createdat",
            dateRange: [
              moment(this.state.fulfillmentMoment?.toDate().toISOString())
                ?.day(0)
                .hour(0)
                .minute(0)
                .second(0)
                ?.format("YYYY-MM-DD"),
              moment(this.state.fulfillmentMoment?.toDate().toISOString())
                ?.hour(23)
                .minute(59)
                .second(59)
                .format("YYYY-MM-DD"),
            ],
          },
        ],
        order: {
          "Metrics.createdat": "asc",
        },
        filters: [
          {
            member: "Metrics.metadatastoreid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
          {
            member: "Metrics.name",
            operator: "equals",
            values: ["member_consultation_client"],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        let total = data?.length ? data[0]["Metrics.totalEvents"] : 0;

        this.setState({
          clientConsultations: StringUtils.numberFormat(total),
        });
      })
      .catch(() => {
        this.setState({ clientConsultations: "--" });
      })
      .finally(() => {
        this.setState({
          clientConsultationsLoading: false,
        });
      });

    // Referrals
    cubejsApi
      .load({
        measures: ["Metrics.totalEvents"],
        timeDimensions: [
          {
            dimension: "Metrics.createdat",
            dateRange: [
              moment(this.state.fulfillmentMoment?.toDate().toISOString())
                ?.day(0)
                .hour(0)
                .minute(0)
                .second(0)
                ?.format("YYYY-MM-DD"),
              moment(this.state.fulfillmentMoment?.toDate().toISOString())
                ?.hour(23)
                .minute(59)
                .second(59)
                .format("YYYY-MM-DD"),
            ],
          },
        ],
        order: {
          "Metrics.createdat": "asc",
        },
        filters: [
          {
            member: "Metrics.metadatastoreid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
          {
            member: "Metrics.name",
            operator: "equals",
            values: ["member_referral"],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        let total = data?.length ? data[0]["Metrics.totalEvents"] : 0;

        this.setState({
          referrals: StringUtils.numberFormat(total),
        });
      })
      .catch(() => {
        this.setState({ referrals: "--" });
      })
      .finally(() => {
        this.setState({
          referralsLoading: false,
        });
      });
  }

  renderSummary(reports, comparison = []) {
    if (!reports || !reports.length) {
      this.setState({ emptyReporting: true });
    } else {
      this.setState({ emptyReporting: false });
    }

    this.loadGrowthSummary();

    let totalOrders = 0;
    let totalComparisonOrders = 0;
    let totalQuantity = 0;
    let totalComparisonQuantity = 0;
    let totalGrossRevenue = 0;
    let totalComparisonGrossRevenue = 0;
    let totalActiveSubscriptions = 0;
    let totalCompareActiveSubscriptions = 0;
    let comparePercentFulfilled = 0;
    let percentFulfilled = 0;
    let avgRevenuePerOrder = 0;

    let storeTotals = [];
    let storeTotalsComparison = [];

    for (let i = 0; i < reports.length; i++) {
      if (!reports[i]?.statistics) {
        continue;
      }

      totalOrders += reports[i].statistics?.totalFulfilledCount
        ? reports[i].statistics?.totalFulfilledCount
        : reports[i].totalOrderCount;
      totalQuantity += reports[i].statistics?.totalBOMQuantity;
      totalGrossRevenue += reports[i].statistics?.grossRevenue;
      totalActiveSubscriptions += reports[i].totalActiveSubscriptions
        ? reports[i].totalActiveSubscriptions
        : 0;

      let storePerformance = {
        storeID: "",
        storeName: "",
        orders: reports[i].statistics?.totalFulfilledCount
          ? reports[i].statistics?.totalFulfilledCount
          : reports[i].totalOrderCount,
        meals: reports[i].statistics?.totalBOMQuantity,
        grossRevenue: reports[i].statistics?.grossRevenue,
        totalActives: reports[i].totalActiveSubscriptions
          ? reports[i].totalActiveSubscriptions
          : 0,
        percentFulfilled:
          (reports[i].statistics?.totalFulfilledCount
            ? reports[i].statistics?.totalFulfilledCount
            : reports[i].totalOrderCount) && reports[i].totalActiveSubscriptions
            ? (reports[i].statistics?.totalFulfilledCount
                ? reports[i].statistics?.totalFulfilledCount
                : reports[i].totalOrderCount) /
              reports[i].totalActiveSubscriptions
            : 0,
      };

      let st = _.findWhere(this.state.stores, {
        _id: reports[i].storeID,
      });

      if (st) {
        storePerformance.storeID = st._id;
        storePerformance.storeName = st.name;
      }

      storeTotals.push(storePerformance);
    }

    percentFulfilled = totalOrders / totalActiveSubscriptions;

    avgRevenuePerOrder = totalGrossRevenue / totalOrders;

    this.setState({
      reports,
      totalOrders,
      totalQuantity,
      totalGrossRevenue,
      storePerformance: storeTotals,
      totalActiveSubscriptions,
      percentFulfilled,
      avgRevenuePerOrder,
    });

    this.setState({
      totalOrdersPercentChange: null,
      totalQuantityPercentChange: null,
      totalGrossRevenuePercentChange: null,
      totalPercentFulfilledPercentChange: null,
      totalActiveSubscriptionsPercentChange: null,
    });

    if (comparison?.length) {
      for (let i = 0; i < comparison.length; i++) {
        if (!comparison[i].statistics) {
          continue;
        }

        totalComparisonOrders += comparison[i].statistics?.totalFulfilledCount
          ? comparison[i].statistics?.totalFulfilledCount
          : comparison[i].totalOrderCount;
        totalCompareActiveSubscriptions +=
          comparison[i].totalActiveSubscriptions;
        totalComparisonQuantity += comparison[i].statistics?.totalBOMQuantity;
        totalComparisonGrossRevenue += comparison[i].statistics?.grossRevenue;

        let storePerformance = {
          storeID: "",
          storeName: "",
          orders: comparison[i].statistics?.totalFulfilledCount
            ? comparison[i].statistics?.totalFulfilledCount
            : comparison[i].totalOrderCount,
          meals: comparison[i].statistics?.totalBOMQuantity,
          grossRevenue: comparison[i].statistics?.grossRevenue,
          totalActives: comparison[i].totalActiveSubscriptions
            ? comparison[i].totalActiveSubscriptions
            : 0,
          percentFulfilled:
            (comparison[i].statistics?.totalFulfilledCount
              ? comparison[i].statistics?.totalFulfilledCount
              : comparison[i].totalOrderCount) &&
            comparison[i].totalActiveSubscriptions
              ? (comparison[i].statistics?.totalFulfilledCount
                  ? comparison[i].statistics?.totalFulfilledCount
                  : comparison[i].totalOrderCount) /
                comparison[i].totalActiveSubscriptions
              : 0,
        };

        let st = _.findWhere(this.state.stores, {
          _id: comparison[i].storeID,
        });

        if (st) {
          storePerformance.storeID = st._id;
          storePerformance.storeName = st.name;
        }

        storeTotalsComparison.push(storePerformance);
      }

      let totalOrdersPercentChange = totalOrders - totalComparisonOrders;

      totalOrdersPercentChange = (
        (totalOrdersPercentChange / totalOrders) *
        100
      ).toFixed(1);

      let totalQuantityPercentChange = totalQuantity - totalComparisonQuantity;

      totalQuantityPercentChange = (
        (totalQuantityPercentChange / totalQuantity) *
        100
      ).toFixed(1);

      let totalGrossRevenuePercentChange =
        totalGrossRevenue - totalComparisonGrossRevenue;

      totalGrossRevenuePercentChange = (
        (totalGrossRevenuePercentChange / totalGrossRevenue) *
        100
      ).toFixed(1);

      let totalActiveSubscriptionsPercentChange = null;
      let totalPercentFulfilledPercentChange = null;

      if (totalCompareActiveSubscriptions) {
        totalActiveSubscriptionsPercentChange =
          totalActiveSubscriptions - totalCompareActiveSubscriptions;

        totalActiveSubscriptionsPercentChange = (
          (totalActiveSubscriptionsPercentChange / totalActiveSubscriptions) *
          100
        ).toFixed(1);
      }

      if (comparePercentFulfilled) {
        totalPercentFulfilledPercentChange =
          percentFulfilled - comparePercentFulfilled;

        totalPercentFulfilledPercentChange = (
          (totalPercentFulfilledPercentChange / totalActiveSubscriptions) *
          100
        ).toFixed(1);
      }

      this.setState({
        comparisonReports: comparison,
        totalOrdersPercentChange,
        totalQuantityPercentChange,
        totalGrossRevenuePercentChange,
        totalActiveSubscriptionsPercentChange,
        totalPercentFulfilledPercentChange,
      });

      let bestPerformer = null;
      let bestPerformerRev = null;
      let weakestPerformer = null;
      let weakestPerformerRev = null;

      // TODO: add plus minus for stores here.
      for (let j = 0; j < storeTotalsComparison.length; j++) {
        let comp = storeTotalsComparison[j];

        let idx = _.findIndex(storeTotals, { storeID: comp.storeID });

        if (idx >= 0) {
          let report = storeTotals[idx];

          let ordersPercentChange = report.orders - comp.orders;

          ordersPercentChange = (
            (ordersPercentChange / report.orders) *
            100
          ).toFixed(1);

          let quantityPercentChange = report.meals - comp.meals;

          quantityPercentChange = (
            (quantityPercentChange / report.meals) *
            100
          ).toFixed(1);

          let grossRevenuePercentChange =
            report.grossRevenue - comp.grossRevenue;

          grossRevenuePercentChange = (
            (grossRevenuePercentChange / report.grossRevenue) *
            100
          ).toFixed(1);

          let activeSubscriptionsPercentChange = null;
          let percentFulfilledPercentChange = null;

          if (comp.totalActives) {
            activeSubscriptionsPercentChange =
              report.totalActives - comp.totalActives;

            activeSubscriptionsPercentChange = (
              (activeSubscriptionsPercentChange / report.totalActives) *
              100
            ).toFixed(1);
          }

          if (comp.percentFulfilled > 0 && comp.percentFulfilled < 2) {
            percentFulfilledPercentChange =
              report.percentFulfilled - comp.percentFulfilled;

            percentFulfilledPercentChange = (
              (percentFulfilledPercentChange / report.percentFulfilled) *
              100
            ).toFixed(1);
          }

          storeTotals[idx].ordersPercentChange = ordersPercentChange;
          storeTotals[idx].quantityPercentChange = quantityPercentChange;
          storeTotals[idx].grossRevenuePercentChange =
            grossRevenuePercentChange;
          storeTotals[idx].totalActivesPercentChange =
            activeSubscriptionsPercentChange;
          storeTotals[idx].percentFulfilledPercentChange =
            percentFulfilledPercentChange;

          if (
            (!isNaN(Number(storeTotals[idx].grossRevenuePercentChange)) &&
              bestPerformerRev === null) ||
            Number(storeTotals[idx].grossRevenuePercentChange) >
              bestPerformerRev
          ) {
            bestPerformer = storeTotals[idx];
            bestPerformerRev = Number(
              storeTotals[idx].grossRevenuePercentChange
            );
          }

          if (
            !isNaN(Number(storeTotals[idx].grossRevenuePercentChange)) &&
            (weakestPerformerRev === null ||
              Number(storeTotals[idx].grossRevenuePercentChange) <
                weakestPerformerRev)
          ) {
            weakestPerformer = storeTotals[idx];
            weakestPerformerRev = Number(
              storeTotals[idx].grossRevenuePercentChange
            );
          }

          this.setState({
            storePerformance: storeTotals,
          });
        }
      }

      console.log(bestPerformer, bestPerformerRev);

      this.setState({
        bestGrowth: bestPerformer,
        worstGrowth: weakestPerformer,
      });
    }

    if (!this.state.comparisonValue) {
      this.setState({
        comparisonReports: [],
        totalOrdersPercentChange: null,
        totalQuantityPercentChange: null,
        totalGrossRevenuePercentChange: null,
        totalPercentFulfilledPercentChange: null,
        totalActiveSubscriptionsPercentChange: null,
      });
    }
  }

  loadSummary(dateString, compareTo = false) {
    if (!dateString) {
      return;
    }

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("date", dateString);
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.setState({
      loading: true,
    });

    let comp = "";

    if (compareTo) {
      let current = moment(this.state.fulfillmentMoment.format("YYYY-MM-DD"));
      let weekPrior = moment(current.format());
      let monthPrior = moment(current.format());
      let quarterPrior = moment(current.format());
      let yearPrior = moment(current.format());

      weekPrior = weekPrior.subtract(1, "week");
      monthPrior = monthPrior.subtract(4, "weeks");
      quarterPrior = quarterPrior.subtract(12, "weeks");
      yearPrior = yearPrior.subtract(1, "year").day(6);

      if (compareTo == "lastweek") {
        comp = weekPrior.format("YYYY-MM-DD");
      } else if (compareTo == "lastmonth") {
        comp = monthPrior.format("YYYY-MM-DD");
      } else if (compareTo == "lastquarter") {
        comp = quarterPrior.format("YYYY-MM-DD");
      } else if (compareTo == "lastyear") {
        comp = yearPrior.format("YYYY-MM-DD");
      }
    }

    if (this.state.availableDates.indexOf(comp) >= 0) {
      this.setState({ comparisonDateString: comp });
    } else {
      comp = null;
    }

    if (comp) {
      APIV2.getStoreSubscriptionFulfillmentSummaryForDate(
        this.props.match?.params?.storeID,
        dateString
      ).then(
        (data) => {
          let payload = {};

          payload.reports = [];

          if (data.data.report) {
            payload.reports.push(data.data.report);
          }

          this.setState({
            report: data.data.report,
          });

          this.setState(payload, () => {
            APIV2.getStoreSubscriptionFulfillmentSummaryForDate(
              this.props.match?.params?.storeID,
              comp
            )
              .then(
                (data) => {
                  let comp = [];

                  if (data.data.report) {
                    comp = [data.data.report];
                  }

                  this.setState(
                    {
                      comparisonReports: comp,
                    },
                    () => {
                      this.renderSummary(
                        this.state.reports,
                        this.state.comparisonReports
                      );

                      this.loadCompareToOptions();
                    }
                  );
                },
                (e) => {}
              )
              .finally(() => {
                this.setState({ loading: false });
              });
          });
        },
        (e) => {
          this.setState({ loading: false });
        }
      );
    } else {
      APIV2.getStoreSubscriptionFulfillmentSummaryForDate(
        this.props.match?.params?.storeID,
        dateString
      )
        .then(
          (data) => {
            let payload = {};

            payload.reports = [data.data.report];

            this.setState(payload, () => {
              this.renderSummary(this.state.reports);

              this.loadCompareToOptions();
            });
          },
          (e) => {}
        )
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const date = urlParams.get("date");

    let fulfillmentDate = moment();

    if (date) {
      fulfillmentDate = moment(date);
    }

    fulfillmentDate
      .set("day", 6)
      .set("hours", 0)
      .set("minutes", 0)
      .set("seconds", 0);

    if (!date) {
      fulfillmentDate.subtract(1, "week");
    }

    this.setState(
      {
        fulfillmentDate: fulfillmentDate.toDate(),
        fulfillmentMoment: fulfillmentDate,
        fulfillmentDateString: fulfillmentDate.format("YYYY-MM-DD"),
        comparisonValue: "lastweek",
      },
      () => {
        this.getAvailableDates().then(() => {
          this.loadSummary(this.state.fulfillmentDateString, "lastweek");
        });
      }
    );
  }

  refreshReportStatistics(reportID) {
    APIV2.refreshSubscriptionFulfillmentReportStatistics(reportID).then(
      (data) => {
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      },
      (e) => {
        window.alert("Unable to refresh report. Try again.");
      }
    );
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeader
            title="Weekly Scorecard"
            showBackButton={false}
            forceSecondaryDropdown={true}
            secondaryActions={[]}
          />
          {/* Table */}
          <div className="border-bottom pb-3 mt--2 mb-4">
            <Row className="align-items-center">
              <Col xs="12" sm="12" md="">
                <h3 className="m-0">
                  Week Ending{" "}
                  {this.state.fulfillmentMoment?.format("ddd, MMM Do YYYY")}
                </h3>
                {/*this.state.comparisonDateString ? (
                  <small>
                    compared to{" "}
                    {moment(this.state.comparisonDateString).format(
                      "ddd, MMM Do YYYY"
                    )}
                  </small>
                    ) : null*/}
              </Col>
              <Col xs="12" sm="6" md="auto">
                <DatePicker
                  oneTap
                  value={this.state.fulfillmentDate}
                  onChange={(v) => {
                    let mom = moment(v.toISOString());

                    mom.set("hours", 0).set("minutes", 0).set("seconds", 0);

                    this.setState(
                      {
                        fulfillmentDate: mom.toDate(),
                        fulfillmentMoment: mom,
                        fulfillmentDateString: mom.format("YYYY-MM-DD"),
                      },
                      () => {
                        this.loadSummary(
                          this.state.fulfillmentDateString,
                          this.state.comparisonValue
                        );
                      }
                    );
                  }}
                  className="mt-3 mt-md-0"
                  placeholder="Select Date"
                  style={{
                    minWidth: "200px",
                    width: "100%",
                    maxWidth: "800px",
                  }}
                  placement="autoVerticalEnd"
                  block
                  cleanable={false}
                  disabledDate={(date) => {
                    let mom = moment(date.toISOString());

                    if (!this.state.availableDates) {
                      return false;
                    }

                    return this.state.availableDates.indexOf(
                      mom.format("YYYY-MM-DD")
                    ) >= 0
                      ? false
                      : true;
                  }}
                />
              </Col>
              {/*<Col xs="12" sm="6" md="auto" className="pl-md-0">
                <SelectPicker
                  className="mt-3 mt-md-0"
                  searchable={false}
                  oneTap
                  value={this.state.comparisonValue}
                  onChange={(v) => {
                    this.setState({
                      comparisonValue: v,
                    });

                    if (v) {
                      this.loadSummary(this.state.fulfillmentDateString, v);
                    } else {
                      this.loadSummary(this.state.fulfillmentDateString, false);
                    }
                  }}
                  cleanable={true}
                  block
                  placement="autoVerticalEnd"
                  placeholder="Compare To"
                  data={this.state.compareToOptions}
                ></SelectPicker>
                </Col>*/}
            </Row>
          </div>

          {this.state.loading ? (
            <>
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">
                    <Spinner color="dark" size="sm"></Spinner>
                    <span className="pl-3">Loading Summary</span>
                  </h3>
                </CardHeader>
                <CardBody>
                  Hang tight while we fetch your fulfillment summary for{" "}
                  {this.state.fulfillmentMoment?.format("dddd, MMM Do YYYY")}.
                </CardBody>
              </Card>
            </>
          ) : null}
          {!this.state.loading && this.state.emptyReporting ? (
            <>
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">
                    {moment().isBefore(this.state.fulfillmentMoment)
                      ? "Summary Not Ready"
                      : "Summary Not Available"}
                  </h3>
                </CardHeader>
                <CardBody>
                  {moment().isBefore(this.state.fulfillmentMoment) ? (
                    <>
                      <p className="mb-0">
                        Check back on Thursday to see a summary of this week's
                        store fulfillment.
                      </p>
                    </>
                  ) : (
                    <>
                      We couldn't find any Store Fulfillment reports for{" "}
                      {this.state.fulfillmentMoment?.format(
                        "dddd, MMM Do YYYY"
                      )}
                      . Try choosing another date.
                    </>
                  )}
                </CardBody>
              </Card>
            </>
          ) : null}
          {!this.state.loading && !this.state.emptyReporting ? (
            <>
              <div>
                <div
                  className="mt-3 pb-2 mb-3 border-bottom cursor-pointer"
                  onClick={() => {
                    this.setState({
                      revenueOpen: !this.state.revenueOpen,
                    });
                  }}
                >
                  <h3 className="mb-0">
                    <i
                      className={`pr-3 mdi mdi-chevron-${
                        this.state.mealOpen ? "down" : "right"
                      }`}
                    />
                    Revenue
                  </h3>
                </div>
                <Collapse isOpen={this.state.revenueOpen}>
                  <Row>
                    <Col xs="12" sm="12" lg="6" className="mb-3">
                      <Card className="border shadow">
                        <CardHeader className="p-2">
                          <Row className="align-items-center">
                            <Col xs="">
                              <h4 className="m-0">
                                Est. Gross Subscription Revenue
                              </h4>
                            </Col>
                            {/*<Col xs="auto">
                              {this.state.totalGrossRevenuePercentChange !==
                                null &&
                              this.state.totalGrossRevenuePercentChange !==
                                0 ? (
                                <small
                                  className={`pl-2 font-weight-bold ${
                                    this.state.totalGrossRevenuePercentChange >
                                    0
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                  style={{ fontSize: "14px" }}
                                >
                                  {this.state.totalGrossRevenuePercentChange >
                                  0 ? (
                                    <i className="text-success mdi mdi-chevron-up"></i>
                                  ) : (
                                    <i className="text-danger mdi mdi-chevron-down"></i>
                                  )}
                                  {Math.abs(
                                    this.state.totalGrossRevenuePercentChange
                                  )}
                                  %
                                </small>
                              ) : null}
                                  </Col>*/}
                          </Row>
                        </CardHeader>
                        <CardBody className="p-3">
                          <h1 className="m-0">
                            {StringUtils.centsToCurrency(
                              this.state.totalGrossRevenue
                            )}
                          </h1>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xs="12" sm="12" lg="6" className="mb-3">
                      <Card className="border shadow">
                        <CardHeader className="p-2">
                          <Row className="align-items-center">
                            <Col xs="">
                              <h4 className="m-0">Average Order</h4>
                            </Col>
                            {/*<Col xs="auto">
                              {this.state.totalGrossRevenuePercentChange !==
                                null &&
                              this.state.totalGrossRevenuePercentChange !==
                                0 ? (
                                <small
                                  className={`pl-2 font-weight-bold ${
                                    this.state.totalGrossRevenuePercentChange >
                                    0
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                  style={{ fontSize: "14px" }}
                                >
                                  {this.state.totalGrossRevenuePercentChange >
                                  0 ? (
                                    <i className="text-success mdi mdi-chevron-up"></i>
                                  ) : (
                                    <i className="text-danger mdi mdi-chevron-down"></i>
                                  )}
                                  {Math.abs(
                                    this.state.totalGrossRevenuePercentChange
                                  )}
                                  %
                                </small>
                              ) : null}
                                  </Col>*/}
                          </Row>
                        </CardHeader>
                        <CardBody className="p-3">
                          <h1 className="m-0">
                            {StringUtils.centsToCurrency(
                              this.state.avgRevenuePerOrder
                            )}
                          </h1>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Collapse>
              </div>

              <div>
                <div
                  className="mt-3 pb-2 mb-3 border-bottom cursor-pointer"
                  onClick={() => {
                    this.setState({
                      fulfillmentOpen: !this.state.fulfillmentOpen,
                    });
                  }}
                >
                  <h3 className="mb-0">
                    <i
                      className={`pr-3 mdi mdi-chevron-${
                        this.state.mealOpen ? "down" : "right"
                      }`}
                    />
                    Fulfillment
                  </h3>
                </div>
                <Collapse isOpen={this.state.fulfillmentOpen}>
                  <Row>
                    <Col xs="12" sm="6" lg="4" className="mb-3">
                      <Card className="border shadow">
                        <CardHeader className="p-2">
                          <Row className="align-items-center">
                            <Col xs="">
                              <h4 className="m-0">Active Members</h4>
                            </Col>
                            {/*<Col xs="auto">
                              {this.state
                                .totalActiveSubscriptionsPercentChange !==
                                null &&
                              this.state
                                .totalActiveSubscriptionsPercentChange !== 0 ? (
                                <small
                                  className={`pl-2 font-weight-bold ${
                                    this.state
                                      .totalActiveSubscriptionsPercentChange > 0
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                  style={{ fontSize: "14px" }}
                                >
                                  {this.state
                                    .totalActiveSubscriptionsPercentChange >
                                  0 ? (
                                    <i className="text-success mdi mdi-chevron-up"></i>
                                  ) : (
                                    <i className="text-danger mdi mdi-chevron-down"></i>
                                  )}
                                  {Math.abs(
                                    this.state
                                      .totalActiveSubscriptionsPercentChange
                                  )}
                                  %
                                </small>
                              ) : null}
                                  </Col>*/}
                          </Row>
                        </CardHeader>
                        <CardBody className="p-3">
                          <h1 className="m-0">
                            {this.state.totalActiveSubscriptions > 0
                              ? StringUtils.numberFormat(
                                  this.state.totalActiveSubscriptions
                                )
                              : "--"}
                          </h1>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xs="12" sm="6" lg="4" className="mb-3">
                      <Card className="border shadow">
                        <CardHeader className="p-2">
                          <Row className="align-items-center">
                            <Col xs="">
                              <h4 className="m-0">Orders</h4>
                            </Col>
                            {/*<Col xs="auto">
                              {this.state.totalOrdersPercentChange !== null &&
                              this.state.totalOrdersPercentChange !== 0 ? (
                                <small
                                  className={`pl-2 font-weight-bold ${
                                    this.state.totalOrdersPercentChange > 0
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                  style={{ fontSize: "14px" }}
                                >
                                  {this.state.totalOrdersPercentChange > 0 ? (
                                    <i className="text-success mdi mdi-chevron-up"></i>
                                  ) : (
                                    <i className="text-danger mdi mdi-chevron-down"></i>
                                  )}
                                  {Math.abs(
                                    this.state.totalOrdersPercentChange
                                  )}
                                  %
                                </small>
                              ) : null}
                                  </Col>*/}
                          </Row>
                        </CardHeader>
                        <CardBody className="p-3">
                          <h1 className="m-0">
                            {StringUtils.numberFormat(this.state.totalOrders)}
                          </h1>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xs="12" sm="6" lg="4" className="mb-3">
                      <Card className="border shadow">
                        <CardHeader className="p-2">
                          <Row className="align-items-center">
                            <Col xs="">
                              <h4 className="m-0">Percent Fulfilled</h4>
                            </Col>
                            {/*<Col xs="auto">
                              {this.state.totalPercentFulfilledPercentChange !==
                                null &&
                              this.state.totalPercentFulfilledPercentChange !==
                                0 ? (
                                <small
                                  className={`pl-2 font-weight-bold ${
                                    this.state
                                      .totalPercentFulfilledPercentChange > 0
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                  style={{ fontSize: "14px" }}
                                >
                                  {this.state
                                    .totalPercentFulfilledPercentChange > 0 ? (
                                    <i className="text-success mdi mdi-chevron-up"></i>
                                  ) : (
                                    <i className="text-danger mdi mdi-chevron-down"></i>
                                  )}
                                  {Math.abs(
                                    this.state
                                      .totalPercentFulfilledPercentChange
                                  )}
                                  %
                                </small>
                              ) : null}
                                  </Col>*/}
                          </Row>
                        </CardHeader>
                        <CardBody className="p-3">
                          <h1 className="m-0">
                            {this.state.percentFulfilled > 0 &&
                            this.state.percentFulfilled < 2 ? (
                              <>
                                {StringUtils.numberFormat(
                                  (this.state.percentFulfilled * 100).toFixed(2)
                                )}
                                %
                              </>
                            ) : (
                              "--"
                            )}
                          </h1>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Collapse>
              </div>

              <div>
                <div
                  className="mt-3 pb-2 mb-3 border-bottom cursor-pointer"
                  onClick={() => {
                    this.setState({
                      growthOpen: !this.state.growthOpen,
                    });
                  }}
                >
                  <h3 className="mb-0">
                    <i
                      className={`pr-3 mdi mdi-chevron-${
                        this.state.mealOpen ? "down" : "right"
                      }`}
                    />
                    Growth
                  </h3>
                </div>
                <Collapse isOpen={this.state.growthOpen}>
                  <Row>
                    <Col xs="12" sm="6" lg="4" className="mb-3">
                      <Card className="border shadow">
                        <CardHeader className="p-2">
                          <Row className="align-items-center">
                            <Col xs="">
                              <h4 className="m-0">New Members</h4>
                            </Col>
                            {/*<Col xs="auto">
                              {this.state
                                .totalActiveSubscriptionsPercentChange !==
                                null &&
                              this.state
                                .totalActiveSubscriptionsPercentChange !== 0 ? (
                                <small
                                  className={`pl-2 font-weight-bold ${
                                    this.state
                                      .totalActiveSubscriptionsPercentChange > 0
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                  style={{ fontSize: "14px" }}
                                >
                                  {this.state
                                    .totalActiveSubscriptionsPercentChange >
                                  0 ? (
                                    <i className="text-success mdi mdi-chevron-up"></i>
                                  ) : (
                                    <i className="text-danger mdi mdi-chevron-down"></i>
                                  )}
                                  {Math.abs(
                                    this.state
                                      .totalActiveSubscriptionsPercentChange
                                  )}
                                  %
                                </small>
                              ) : null}
                                  </Col>*/}
                          </Row>
                        </CardHeader>
                        <CardBody className="p-3">
                          {this.state.newMembersLoading ? (
                            <>
                              <Spinner size="sm" color="dark"></Spinner>
                            </>
                          ) : (
                            <>
                              <h1 className="m-0">{this.state.newMembers}</h1>
                            </>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xs="12" sm="6" lg="4" className="mb-3">
                      <Card className="border shadow">
                        <CardHeader className="p-2">
                          <Row className="align-items-center">
                            <Col xs="">
                              <h4 className="m-0">New In-Store</h4>
                            </Col>
                            {/*<Col xs="auto">
                              {this.state
                                .totalActiveSubscriptionsPercentChange !==
                                null &&
                              this.state
                                .totalActiveSubscriptionsPercentChange !== 0 ? (
                                <small
                                  className={`pl-2 font-weight-bold ${
                                    this.state
                                      .totalActiveSubscriptionsPercentChange > 0
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                  style={{ fontSize: "14px" }}
                                >
                                  {this.state
                                    .totalActiveSubscriptionsPercentChange >
                                  0 ? (
                                    <i className="text-success mdi mdi-chevron-up"></i>
                                  ) : (
                                    <i className="text-danger mdi mdi-chevron-down"></i>
                                  )}
                                  {Math.abs(
                                    this.state
                                      .totalActiveSubscriptionsPercentChange
                                  )}
                                  %
                                </small>
                              ) : null}
                                  </Col>*/}
                          </Row>
                        </CardHeader>
                        <CardBody className="p-3">
                          {this.state.newStoreLoading ? (
                            <>
                              <Spinner size="sm" color="dark"></Spinner>
                            </>
                          ) : (
                            <>
                              <h1 className="m-0">{this.state.newStore}</h1>
                            </>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xs="12" sm="6" lg="4" className="mb-3">
                      <Card className="border shadow">
                        <CardHeader className="p-2">
                          <Row className="align-items-center">
                            <Col xs="">
                              <h4 className="m-0">
                                New Online &amp; Other Sources
                              </h4>
                            </Col>
                            {/*<Col xs="auto">
                              {this.state
                                .totalActiveSubscriptionsPercentChange !==
                                null &&
                              this.state
                                .totalActiveSubscriptionsPercentChange !== 0 ? (
                                <small
                                  className={`pl-2 font-weight-bold ${
                                    this.state
                                      .totalActiveSubscriptionsPercentChange > 0
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                  style={{ fontSize: "14px" }}
                                >
                                  {this.state
                                    .totalActiveSubscriptionsPercentChange >
                                  0 ? (
                                    <i className="text-success mdi mdi-chevron-up"></i>
                                  ) : (
                                    <i className="text-danger mdi mdi-chevron-down"></i>
                                  )}
                                  {Math.abs(
                                    this.state
                                      .totalActiveSubscriptionsPercentChange
                                  )}
                                  %
                                </small>
                              ) : null}
                                  </Col>*/}
                          </Row>
                        </CardHeader>
                        <CardBody className="p-3">
                          {this.state.newOnlineLoading ? (
                            <>
                              <Spinner size="sm" color="dark"></Spinner>
                            </>
                          ) : (
                            <>
                              <h1 className="m-0">{this.state.newOnline}</h1>
                            </>
                          )}
                        </CardBody>
                      </Card>
                    </Col>

                    <Col xs="12" sm="6" lg="4" className="mb-3">
                      <Card className="border shadow">
                        <CardHeader className="p-2">
                          <Row className="align-items-center">
                            <Col xs="">
                              <h4 className="m-0">Reactivations</h4>
                            </Col>
                            {/*<Col xs="auto">
                              {this.state.totalOrdersPercentChange !== null &&
                              this.state.totalOrdersPercentChange !== 0 ? (
                                <small
                                  className={`pl-2 font-weight-bold ${
                                    this.state.totalOrdersPercentChange > 0
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                  style={{ fontSize: "14px" }}
                                >
                                  {this.state.totalOrdersPercentChange > 0 ? (
                                    <i className="text-success mdi mdi-chevron-up"></i>
                                  ) : (
                                    <i className="text-danger mdi mdi-chevron-down"></i>
                                  )}
                                  {Math.abs(
                                    this.state.totalOrdersPercentChange
                                  )}
                                  %
                                </small>
                              ) : null}
                                  </Col>*/}
                          </Row>
                        </CardHeader>
                        <CardBody className="p-3">
                          {this.state.reMembersLoading ? (
                            <>
                              <Spinner size="sm" color="dark"></Spinner>
                            </>
                          ) : (
                            <>
                              <h1 className="m-0">{this.state.reMembers}</h1>
                            </>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xs="12" sm="6" lg="4" className="mb-3">
                      <Card className="border shadow">
                        <CardHeader className="p-2">
                          <Row className="align-items-center">
                            <Col xs="">
                              <h4 className="m-0">HFNs</h4>
                            </Col>
                            {/*<Col xs="auto">
                              {this.state.totalPercentFulfilledPercentChange !==
                                null &&
                              this.state.totalPercentFulfilledPercentChange !==
                                0 ? (
                                <small
                                  className={`pl-2 font-weight-bold ${
                                    this.state
                                      .totalPercentFulfilledPercentChange > 0
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                  style={{ fontSize: "14px" }}
                                >
                                  {this.state
                                    .totalPercentFulfilledPercentChange > 0 ? (
                                    <i className="text-success mdi mdi-chevron-up"></i>
                                  ) : (
                                    <i className="text-danger mdi mdi-chevron-down"></i>
                                  )}
                                  {Math.abs(
                                    this.state
                                      .totalPercentFulfilledPercentChange
                                  )}
                                  %
                                </small>
                              ) : null}
                                  </Col>*/}
                          </Row>
                        </CardHeader>
                        <CardBody className="p-3">
                          {this.state.hfnsLoading ? (
                            <>
                              <Spinner size="sm" color="dark"></Spinner>
                            </>
                          ) : (
                            <>
                              <h1 className="m-0">{this.state.hfns}</h1>
                            </>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xs="12" sm="6" lg="4" className="mb-3">
                      <Card className="border shadow">
                        <CardHeader className="p-2">
                          <Row className="align-items-center">
                            <Col xs="">
                              <h4 className="m-0">Net Members</h4>
                            </Col>
                            {/*<Col xs="auto">
                              {this.state.totalPercentFulfilledPercentChange !==
                                null &&
                              this.state.totalPercentFulfilledPercentChange !==
                                0 ? (
                                <small
                                  className={`pl-2 font-weight-bold ${
                                    this.state
                                      .totalPercentFulfilledPercentChange > 0
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                  style={{ fontSize: "14px" }}
                                >
                                  {this.state
                                    .totalPercentFulfilledPercentChange > 0 ? (
                                    <i className="text-success mdi mdi-chevron-up"></i>
                                  ) : (
                                    <i className="text-danger mdi mdi-chevron-down"></i>
                                  )}
                                  {Math.abs(
                                    this.state
                                      .totalPercentFulfilledPercentChange
                                  )}
                                  %
                                </small>
                              ) : null}
                                  </Col>*/}
                          </Row>
                        </CardHeader>
                        <CardBody className={`p-3`}>
                          {this.state.netLoading ? (
                            <>
                              <Spinner size="sm" color="dark"></Spinner>
                            </>
                          ) : (
                            <>
                              <h1
                                className={`m-0 ${
                                  this.state.netMembers <= 0
                                    ? "text-danger"
                                    : "text-success"
                                }`}
                              >
                                {this.state.netMembers > 0 ? "+" : ""}
                                {this.state.netMembers}
                              </h1>
                            </>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xs="12" sm="6" lg="4" className="mb-3">
                      <Card className="border shadow">
                        <CardHeader className="p-2">
                          <Row className="align-items-center">
                            <Col xs="">
                              <h4 className="m-0">LEAN Consultations</h4>
                            </Col>
                            {/*<Col xs="auto">
                              {this.state.totalOrdersPercentChange !== null &&
                              this.state.totalOrdersPercentChange !== 0 ? (
                                <small
                                  className={`pl-2 font-weight-bold ${
                                    this.state.totalOrdersPercentChange > 0
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                  style={{ fontSize: "14px" }}
                                >
                                  {this.state.totalOrdersPercentChange > 0 ? (
                                    <i className="text-success mdi mdi-chevron-up"></i>
                                  ) : (
                                    <i className="text-danger mdi mdi-chevron-down"></i>
                                  )}
                                  {Math.abs(
                                    this.state.totalOrdersPercentChange
                                  )}
                                  %
                                </small>
                              ) : null}
                                  </Col>*/}
                          </Row>
                        </CardHeader>
                        <CardBody className="p-3">
                          {this.state.consultationsLoading ? (
                            <>
                              <Spinner size="sm" color="dark"></Spinner>
                            </>
                          ) : (
                            <>
                              <h1 className="m-0">
                                {this.state.consultations}
                              </h1>
                            </>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xs="12" sm="6" lg="4" className="mb-3">
                      <Card className="border shadow">
                        <CardHeader className="p-2">
                          <Row className="align-items-center">
                            <Col xs="">
                              <h4 className="m-0">Member Check-Ins</h4>
                            </Col>
                            {/*<Col xs="auto">
                              {this.state.totalOrdersPercentChange !== null &&
                              this.state.totalOrdersPercentChange !== 0 ? (
                                <small
                                  className={`pl-2 font-weight-bold ${
                                    this.state.totalOrdersPercentChange > 0
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                  style={{ fontSize: "14px" }}
                                >
                                  {this.state.totalOrdersPercentChange > 0 ? (
                                    <i className="text-success mdi mdi-chevron-up"></i>
                                  ) : (
                                    <i className="text-danger mdi mdi-chevron-down"></i>
                                  )}
                                  {Math.abs(
                                    this.state.totalOrdersPercentChange
                                  )}
                                  %
                                </small>
                              ) : null}
                                  </Col>*/}
                          </Row>
                        </CardHeader>
                        <CardBody className="p-3">
                          {this.state.clientConsultationsLoading ? (
                            <>
                              <Spinner size="sm" color="dark"></Spinner>
                            </>
                          ) : (
                            <>
                              <h1 className="m-0">
                                {this.state.clientConsultations}
                              </h1>
                            </>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xs="12" sm="6" lg="4" className="mb-3">
                      <Card className="border shadow">
                        <CardHeader className="p-2">
                          <Row className="align-items-center">
                            <Col xs="">
                              <h4 className="m-0">Referrals</h4>
                            </Col>
                            {/*<Col xs="auto">
                              {this.state.totalOrdersPercentChange !== null &&
                              this.state.totalOrdersPercentChange !== 0 ? (
                                <small
                                  className={`pl-2 font-weight-bold ${
                                    this.state.totalOrdersPercentChange > 0
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                  style={{ fontSize: "14px" }}
                                >
                                  {this.state.totalOrdersPercentChange > 0 ? (
                                    <i className="text-success mdi mdi-chevron-up"></i>
                                  ) : (
                                    <i className="text-danger mdi mdi-chevron-down"></i>
                                  )}
                                  {Math.abs(
                                    this.state.totalOrdersPercentChange
                                  )}
                                  %
                                </small>
                              ) : null}
                                  </Col>*/}
                          </Row>
                        </CardHeader>
                        <CardBody className="p-3">
                          {this.state.referralsLoading ? (
                            <>
                              <Spinner size="sm" color="dark"></Spinner>
                            </>
                          ) : (
                            <>
                              <h1 className="m-0">{this.state.referrals}</h1>
                            </>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Collapse>
              </div>
            </>
          ) : null}
        </Container>
      </>
    );
  }
}

export default StoreScorecard;
