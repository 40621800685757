import React from "react";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";

const ConversationListFilter = ({ selected = null, onSelect = null }) => {
  return (
    <div className="mt-2 d-flex">
      <div
        className={`px-2 py-1 ${
          selected == "All" ? "active" : ""
        } conversation-list-filter-item`}
        onClick={() => {
          if (typeof onSelect == "function") {
            onSelect("All");
          }
        }}
      >
        All
      </div>
      <div
        className={`px-2 py-1 ${
          selected == "Unread" ? "active" : ""
        } conversation-list-filter-item`}
        onClick={() => {
          if (typeof onSelect == "function") {
            onSelect("Unread");
          }
        }}
      >
        Unread
      </div>
      <div
        className={`px-2 py-1 ${
          selected == "Members" ? "active" : ""
        } conversation-list-filter-item`}
        onClick={() => {
          if (typeof onSelect == "function") {
            onSelect("Members");
          }
        }}
      >
        Members
      </div>
      <div
        className={`px-2 py-1 ${
          selected == "Leads" ? "active" : ""
        } conversation-list-filter-item`}
        onClick={() => {
          if (typeof onSelect == "function") {
            onSelect("Leads");
          }
        }}
      >
        Leads
      </div>
    </div>
  );
};

export default withRouter(ConversationListFilter);
