import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  Checkbox,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import { isThisTypeNode } from "typescript";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";

class StorefrontModifyCouponDrawer extends React.Component {
  state = {
    position: "",
    name: "",
    email: "",
    phone: {
      value: "",
    },
    hireDate: null,
    discount: {
      value: "",
    },
  };

  constructor() {
    super();

    this.dropdownRef = React.createRef();
  }

  updateForm() {
    let discount = {
      value: null,
    };

    this.setState({
      couponUsage: this.props.coupon?.couponUsage,
      name: this.props.coupon?.name,
      code: this.props.coupon?.code,
      description: this.props.coupon?.description,
      startDate: this.props.coupon?.customerUseAfterDate
        ? moment(this.props.coupon?.customerUseAfterDate).toDate()
        : null,
      endDate: this.props.coupon?.customerUseBeforeDate
        ? moment(this.props.coupon?.customerUseBeforeDate).toDate()
        : null,
      customerUse: this.props.coupon?.customerUse,
      nationalCampaign: this.props.coupon?.nationalCampaign ? true : false,
    });

    if (this.props.coupon?.couponUsage == "tiered") {
      let couponTiers = [];

      for (let i = 0; i < this.props.coupon?.couponTiers?.length; i++) {
        const tier = this.props.coupon?.couponTiers[i];

        let payload = {
          _id: tier?._id,
          maxNumberUses: {
            value: tier?.maxNumberUses?.toString(),
          },
          discountType: tier?.discountType,
          discount: {
            value: "",
          },
        };

        let discount = {};

        if (tier?.discountType == "Percent") {
          discount.value =
            parseFloat(tier?.percentDiscount ? tier?.percentDiscount : 0) * 100;
        }

        if (tier?.discountType == "Flat") {
          discount.value =
            parseInt(tier?.flatDiscount ? tier?.flatDiscount : 0) / 100;
        }

        payload.discount.value = discount?.value?.toString();

        couponTiers.push(payload);
      }

      this.setState({
        couponTiers,
      });
    } else {
      if (this.props.coupon?.discountType == "Percent") {
        discount.value =
          parseFloat(
            this.props.coupon?.percentDiscount
              ? this.props.coupon?.percentDiscount
              : 0
          ) * 100;
      }

      if (this.props.coupon?.discountType == "Flat") {
        discount.value =
          parseInt(
            this.props.coupon?.flatDiscount
              ? this.props.coupon?.flatDiscount
              : 0
          ) / 100;
      }

      discount.value = discount?.value?.toString();

      let maxUses = this.props.coupon?.removeFromSubscriptionAfterUses;

      this.setState({
        discountType: this.props.coupon?.discountType,
        discount,
        maxNumberUses: maxUses?.toString(),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props?.coupon != prevProps?.coupon) {
      this.updateForm();
    }
  }

  componentDidMount() {
    if (this.props.coupon) {
      this.updateForm();
    }
  }

  handleCouponCodeChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value?.toUpperCase(),
    });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  handleFlatAmountChange(value) {
    this.setState({
      discount: value,
    });
  }

  handlePercentAmountChange(value) {
    this.setState({
      discount: value,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  toggleModal() {
    this.props.onClose();
  }

  getTotalValueForTiers(tiers) {
    if (!tiers?.length) {
      return "--";
    }

    let val = 0;

    for (let i = 0; i < tiers?.length; i++) {
      const tier = tiers[i];

      if (tier.discountType == "Percent") {
        return "(no calculation available)";
      }

      let discount = 0;

      if (tier.discountType == "Flat") {
        discount = tier.discount?.value
          ? parseFloat(tier.discount?.value) * 100
          : 0;
      }

      if (tier.discountType == "Percent") {
        discount = tier.discount?.value
          ? parseFloat(tier.discount?.value) / 100
          : 0;
      }

      let maxUses = parseInt(tier.maxNumberUses?.value);

      if (maxUses <= 0 || isNaN(maxUses)) {
        maxUses = 1;
      }

      val = val + discount * maxUses;
    }

    return StringUtils.centsToCurrency(val);
  }

  addTier() {
    let tiers = [];

    if (this.state.couponTiers?.length) {
      tiers = this.state.couponTiers;
    }

    tiers.push({
      _id: StringUtils.uuid(),
      maxNumberUses: {
        value: "",
      },
      discountType: "",
      discount: {
        value: "",
      },
    });

    this.setState({
      couponTiers: tiers,
    });
  }

  removeTier(id) {
    let tiers = this.state.couponTiers;

    tiers = _.filter(tiers, (t) => {
      return t._id != id;
    });

    this.setState({
      couponTiers: tiers,
    });
  }

  setTierValue(tier, key, value) {
    let tiers = this.state.couponTiers;

    const idx = _.findIndex(tiers, { _id: tier?._id });

    if (idx >= 0) {
      tiers[idx][key] = value;
    }

    this.setState({
      couponTiers: tiers,
    });
  }

  validTiers(tiers) {
    if (!tiers?.length) {
      return false;
    }

    for (let i = 0; i < tiers?.length; i++) {
      let tier = tiers[i];

      if (!tier?.discountType) {
        return false;
      }

      if (!tier?.discount?.value || parseFloat(tier?.discount?.value) <= 0) {
        return false;
      }

      if (
        !tier.maxNumberUses?.value ||
        parseInt(tier?.maxNumberUses?.value) <= 0
      ) {
        return false;
      }
    }

    return true;
  }

  _convertTierValues(tiers) {
    let out = [];

    if (!tiers?.length) {
      return null;
    }

    for (let i = 0; i < tiers?.length; i++) {
      let tier = tiers[i];

      let payload = {
        _id: tier?._id,
        discountType: tier?.discountType,
        maxNumberUses: parseInt(tier?.maxNumberUses?.value),
      };

      let discount = 0;

      if (tier.discountType == "Flat") {
        discount = tier.discount?.value
          ? parseFloat(tier.discount?.value) * 100
          : 0;

        payload.flatDiscount = discount;
      }

      if (tier.discountType == "Percent") {
        discount = tier.discount?.value
          ? parseFloat(tier.discount?.value) / 100
          : 0;

        payload.percentDiscount = discount;
      }

      out.push(payload);
    }

    return out;
  }

  submit() {
    let maxUses = 1;

    if (this.state.couponUsage == "recurring") {
      maxUses = -1;
    } else if (this.state.couponUsage == "onetime") {
      maxUses = 1;
    } else {
      maxUses = parseInt(this.state.maxNumberUses);
    }

    let discount = 0;

    console.log(this.state.discount);

    if (this.state.discountType == "Flat") {
      discount = this.state.discount?.value
        ? parseFloat(this.state.discount?.value) * 100
        : 0;
    }

    if (this.state.discountType == "Percent") {
      discount = this.state.discount?.value
        ? parseFloat(this.state.discount?.value) / 100
        : 0;
    }

    let couponTiers = null;

    if (this.state.couponUsage == "tiered") {
      couponTiers = this._convertTierValues(this.state.couponTiers);

      maxUses = 0;

      for (let i = 0; i < couponTiers?.length; i++) {
        maxUses += couponTiers[i].maxNumberUses;
      }
    }

    this.setState({ submitting: true });

    APIV2.modifyStoreCoupon(
      this.props?.storeID,
      this.props?.coupon?._id,
      this.state.name,
      this.state.code,
      this.state.description,
      this.state.startDate ? this.state.startDate.toISOString() : null,
      this.state.endDate ? this.state.endDate.toISOString() : null,
      this.state.customerUse,
      this.state.couponUsage,
      this.state.couponUsage != "tiered" && this.state.discountType,
      this.state.couponUsage != "tiered" && this.state.discountType == "Flat"
        ? discount
        : null,
      this.state.couponUsage != "tiered" && this.state.discountType == "Percent"
        ? discount
        : null,
      maxUses,
      couponTiers,
      this.state.nationalCampaign ? true : false
    )
      .then(
        (data) => {
          const coupon = data.data.coupon;

          PubSub.publish(Event.COUPON.MODIFIED, coupon);

          this.toggleModal();
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to modify coupon - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  getTotalValue() {
    if (!this.state.couponUsage || !this.state.discount?.value) {
      return "--";
    }

    if (
      this.state.discountType == "Percent" ||
      this.state.couponUsage == "recurring"
    ) {
      return "(no calculation available)";
    }

    let discount = 0;

    console.log(this.state.discount);

    if (this.state.discountType == "Flat") {
      discount = this.state.discount?.value
        ? parseFloat(this.state.discount?.value) * 100
        : 0;
    }

    if (this.state.discountType == "Percent") {
      discount = this.state.discount?.value
        ? parseFloat(this.state.discount?.value) / 100
        : 0;
    }

    if (this.state.couponUsage == "onetime") {
      return StringUtils.centsToCurrency(discount);
    }

    let maxUses = parseInt(this.state.maxNumberUses);

    if (maxUses <= 0 || isNaN(maxUses)) {
      maxUses = 1;
    }

    return StringUtils.centsToCurrency(discount * maxUses);
  }

  /**
   * Checks if the current user is a global user via permissions
   *
   * @returns
   */
  isGlobal() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Modify Coupon
            </h3>
            <Drawer.Actions>
              <Button
                onClick={this.submit.bind(this)}
                size="sm"
                color="primary"
                disabled={
                  !this.state.name ||
                  !this.state.code ||
                  !this.state.endDate ||
                  (this.state.couponUsage != "tiered" &&
                    !this.state.discount?.value) ||
                  this.state.submitting ||
                  !this.state.couponUsage ||
                  (this.state.couponUsage == "limited" &&
                    (!this.state.maxNumberUses ||
                      parseInt(this.state.maxNumberUses) <= 0 ||
                      isNaN(parseInt(this.state.maxNumberUses)))) ||
                  (this.state.couponUsage == "tiered" &&
                    !this.validTiers(this.state.couponTiers))
                }
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  "Save"
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error && (
              <>
                <Alert
                  color=""
                  className="m-0 mb-4 bg-primary-superlight text-dark font-weight-500"
                >
                  {this.state.error}
                </Alert>
              </>
            )}
            <FormGroup>
              <h5>Name</h5>
              <Input
                type="text"
                name="name"
                placeholder="Name"
                required
                value={this.state.name}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Coupon Code</h5>
              <Input
                type="text"
                name="code"
                placeholder="Coupon Code"
                required
                value={this.state.code}
                onChange={this.handleCouponCodeChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Description</h5>
              <Input
                type="textarea"
                name="description"
                placeholder="Description"
                required
                value={this.state.description}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Coupon Start Date</h5>
              <DatePicker
                block
                value={this.state.startDate}
                onChange={(v) => {
                  this.setState({ startDate: v });
                }}
                cleanable={true}
                oneTap={true}
                placement="autoVertical"
              ></DatePicker>
            </FormGroup>
            <FormGroup>
              <h5>
                Coupon End Date <span className="text-danger">*</span>
              </h5>
              <DatePicker
                block
                value={this.state.endDate}
                onChange={(v) => {
                  this.setState({ endDate: v });
                }}
                cleanable={true}
                oneTap={true}
                placement="autoVertical"
              ></DatePicker>
            </FormGroup>
            <FormGroup>
              <h5>Online Checkout Mode</h5>
              <p className="small mb-2 text-dark" style={{ lineHeight: 1.3 }}>
                Enabling this setting will allow customers to use this at online
                checkout. Disabling it will allow use only inside PLN
                Storefront.
              </p>
              <Checkbox
                checked={this.state.customerUse}
                onChange={(e, v) => {
                  this.setState({
                    customerUse: v,
                  });
                }}
              >
                {" "}
                Allow Use At Online Checkout
              </Checkbox>
            </FormGroup>
            {this.isGlobal() ? (
              <FormGroup>
                <h5>Systemwide Campaign</h5>
                <p className="small mb-2 text-dark" style={{ lineHeight: 1.3 }}>
                  Enabling this will prevent stores from modifying this coupon.
                </p>
                <Checkbox
                  checked={this.state.nationalCampaign}
                  onChange={(e, v) => {
                    this.setState({
                      nationalCampaign: v,
                    });
                  }}
                >
                  {" "}
                  Use For Systemwide Campaign
                </Checkbox>
              </FormGroup>
            ) : null}
            <FormGroup>
              <h5>Coupon Usage</h5>
              <SelectPicker
                onChange={(v) => {
                  this.setState({ couponUsage: v });

                  if (v == "tiered") {
                    this.setState({
                      discount: {
                        value: "",
                      },
                      maxNumberUses: null,
                      discountType: null,
                    });
                  } else {
                    this.setState({
                      couponTiers: [],
                    });
                  }
                }}
                searchable={false}
                placement="autoVertical"
                value={this.state.couponUsage}
                block
                size="lg"
                data={[
                  {
                    value: "onetime",
                    label: (
                      <>
                        <div>One-Time Usage</div>
                        <div className="small">
                          A single discount applied to only one order and then
                          removed from the subscription.
                        </div>
                      </>
                    ),
                  },
                  {
                    value: "limited",
                    label: (
                      <>
                        <div>Limited Usage</div>
                        <div className="small">
                          A single discount applied to a present number of
                          orders and then removed from the subscription.
                        </div>
                      </>
                    ),
                  },
                  {
                    value: "recurring",
                    label: (
                      <>
                        <div>Recurring Usage (No Limit)</div>
                        <div className="small">
                          A single discount applied to all orders for the
                          subscription.
                        </div>
                      </>
                    ),
                  },
                  {
                    value: "tiered",
                    label: (
                      <>
                        <div>Tiered Usage</div>
                        <div className="small">
                          Multiple discounts applied in a specific order to the
                          subscription.
                        </div>
                      </>
                    ),
                  },
                ]}
              ></SelectPicker>
            </FormGroup>
            {this.state.couponUsage && (
              <>
                {this.state.couponUsage != "tiered" ? (
                  <>
                    <FormGroup>
                      <h5>Discount Type</h5>
                      <Input
                        type="select"
                        name="discountType"
                        required
                        value={this.state.discountType}
                        onChange={this.handleInputChange.bind(this)}
                        placeholder="Discount Type"
                      >
                        <option value="" disabled>
                          Choose A Discount Type
                        </option>
                        <option value="Flat">Flat</option>
                        <option value="Percent">Percent</option>
                      </Input>
                    </FormGroup>
                    {this.state.discountType == "Flat" ? (
                      <FormGroup>
                        <h5>Flat Discount</h5>
                        <NumberFormat
                          className="form-control mb-3"
                          prefix={"$"}
                          thousandSeparator={true}
                          name="flatDiscount"
                          placeholder="Flat Discount"
                          onValueChange={this.handleFlatAmountChange.bind(this)}
                          value={this.state.discount?.value}
                          type="tel"
                          required
                        />
                      </FormGroup>
                    ) : null}
                    {this.state.discountType == "Percent" ? (
                      <FormGroup>
                        <h5>Perent Discount</h5>
                        <NumberFormat
                          className="form-control mb-3"
                          suffix={"%"}
                          thousandSeparator={false}
                          name="percentDiscount"
                          placeholder="Percent Discount"
                          onValueChange={this.handleFlatAmountChange.bind(this)}
                          value={this.state.discount?.value}
                          type="tel"
                          required
                        />
                      </FormGroup>
                    ) : null}

                    {this.state.couponUsage == "limited" && (
                      <>
                        <FormGroup>
                          <h5 className="mb-0">Max Number Of Uses</h5>
                          <p className="small">
                            Maximum number of uses a member can use this coupon
                            in a row.
                          </p>
                          <Input
                            type="number"
                            name="maxNumberUses"
                            placeholder="Max Uses"
                            value={this.state.maxNumberUses}
                            onChange={this.handleInputChange.bind(this)}
                          ></Input>
                        </FormGroup>
                      </>
                    )}
                    <FormGroup>
                      <p className="mb-0 text-dark">
                        <span className="font-weight-bold">Total Value: </span>
                        {this.getTotalValue()}
                      </p>
                    </FormGroup>
                  </>
                ) : (
                  <>
                    <>
                      <FormGroup>
                        <p className="mb-0 text-dark">
                          <span className="font-weight-bold">
                            Total Value:{" "}
                          </span>
                          {this.getTotalValueForTiers(this.state.couponTiers)}
                        </p>
                      </FormGroup>
                      {this.state.couponTiers?.map((tier, i) => (
                        <Card key={i} className="mb-3 border border-lighter">
                          <CardHeader className="p-3">
                            <Row className="align-items-center">
                              <Col xs="">
                                <h3 className="m-0">Tier {i + 1}</h3>
                              </Col>
                              <Col xs="auto">
                                <Button
                                  size="sm"
                                  outline
                                  color="danger"
                                  className="btn-icon-only"
                                  onClick={() => {
                                    this.removeTier(tier._id);
                                  }}
                                >
                                  <i className="mdi mdi-close"></i>
                                </Button>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody className="p-3">
                            <FormGroup>
                              <h5>Discount Type</h5>
                              <Input
                                type="select"
                                name="discountType"
                                required
                                value={tier.discountType}
                                onChange={(e) => {
                                  this.setTierValue(
                                    tier,
                                    "discountType",
                                    e.target.value
                                  );
                                }}
                                placeholder="Discount Type"
                              >
                                <option value="" disabled>
                                  Choose A Discount Type
                                </option>
                                <option value="Flat">Flat</option>
                                <option value="Percent">Percent</option>
                              </Input>
                            </FormGroup>
                            {tier.discountType == "Flat" ? (
                              <FormGroup>
                                <h5>Flat Discount</h5>
                                <NumberFormat
                                  className="form-control mb-3"
                                  prefix={"$"}
                                  thousandSeparator={true}
                                  name="flatDiscount"
                                  placeholder="Flat Discount"
                                  onValueChange={(v) => {
                                    this.setTierValue(tier, "discount", v);
                                  }}
                                  value={tier.discount?.value}
                                  type="tel"
                                  required
                                />
                              </FormGroup>
                            ) : null}
                            {tier.discountType == "Percent" ? (
                              <FormGroup>
                                <h5>Perent Discount</h5>
                                <NumberFormat
                                  className="form-control mb-3"
                                  suffix={"%"}
                                  thousandSeparator={false}
                                  name="percentDiscount"
                                  placeholder="Percent Discount"
                                  onValueChange={(v) => {
                                    this.setTierValue(tier, "discount", v);
                                  }}
                                  value={tier.discount?.value}
                                  type="tel"
                                  required
                                />
                              </FormGroup>
                            ) : null}
                            <FormGroup>
                              <h5 className="mb-0">Number Of Uses</h5>
                              <p className="small">
                                Number of times this tier will be applied to
                                orders before going to the next tier or removing
                                the coupon.
                              </p>
                              <NumberFormat
                                className="form-control mb-3"
                                thousandSeparator={false}
                                name="maxNumberUses"
                                placeholder="Number Of Uses"
                                onValueChange={(v) => {
                                  this.setTierValue(tier, "maxNumberUses", v);
                                }}
                                value={tier.maxNumberUses?.value}
                                type="tel"
                                required
                              />
                            </FormGroup>
                          </CardBody>
                        </Card>
                      ))}
                      <Button
                        color="primary"
                        outline
                        block
                        onClick={() => {
                          this.addTier();
                        }}
                      >
                        Add Tier
                      </Button>
                    </>
                  </>
                )}
              </>
            )}
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(StorefrontModifyCouponDrawer);
