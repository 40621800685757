import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  Checkbox,
  CheckPicker,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import Constant from "lib/Constant";
import FileUploadDrawer from "components/FileUploadDrawer";

class InAppContentDrawer extends React.Component {
  state = {
    message: "",
    name: "",
    conditionallyDisplay: "false",
    displayForAllStores: "true",
    interactiveEventType: "",
  };

  storePickerRef = React.createRef();

  getAllStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStores()
      .then((data) => {
        const now = new Date();

        let stores = _.filter(data.data.stores, (st) => {
          return (
            st.nsoStage &&
            st.nsoStage != "Lead" &&
            st.nsoStage != "Funding" &&
            st.nsoStage != "Cold" &&
            st.nsoStage != "Discovery"
          );
        });

        let storeOptions = stores?.map((st) => {
          return {
            label: st?.name,
            value: st?._id,
            groupBy: st?.nsoStage,
          };
        });

        this.setState({
          stores,
          storeOptions,
        });
      })
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.content != prevProps?.content && this.props.content) {
      let payload = {
        ...this.props.content,
      };

      if (payload.displayStartDate) {
        payload.displayStartDate = moment(payload.displayStartDate).toDate();
      }

      if (payload.displayEndDate) {
        payload.displayEndDate = moment(payload.displayEndDate).toDate();
      }

      if (payload.displayForAllStores) {
        payload.displayForAllStores = "true";
      } else {
        payload.displayForAllStores = "false";
      }

      if (payload.interactive) {
        payload.interactive = "true";
      } else {
        payload.interactive = "false";
      }

      if (payload.conditionalRendering) {
        payload.conditionallyDisplay = "true";
      } else {
        payload.conditionallyDisplay = "false";
      }

      if (payload.image) {
        payload.image = {
          url: payload.image,
        };
      }

      this.setState(payload);
    }
  }

  componentDidMount() {
    this.getAllStores();

    if (this.props.content) {
      this.setState({
        name: this.props.content?.name + (this.props.duplicate ? " Copy" : ""),
        message: this.props.content?.message,
      });
    }
  }

  toggleModal() {
    this.setState({
      name: "",
      description: "",
      displayStartDate: null,
      displayEndDate: null,
      displayForAllStores: "true",
      selectedStores: [],
      displayRanking: 1,
      dynamicRanking: false,
      dynamicDisplayRankingScript: "",
      conditionalRendering: false,
      dynamicConditionalRenderingScript: "",
      dynamicImage: false,
      dynamicImageScript: "",
      image: null,
      dynamicHeader: false,
      dynamicHeaderScript: "",
      header: "",
      dynamicSubheader: false,
      dynamicSubheaderScript: "",
      subheader: "",
      interactive: false,
      dynamicInteractiveButtonText: false,
      dynamicInteractiveButtonTextScript: "",
      interactiveButtonText: "",
      interactiveEventType: "",
      dynamicInteractiveEventData: false,
      dynamicInteractiveEventDataScript: "",
      interactiveEventData: "",
      dynamicInteractiveEventMetadata: false,
      dynamicInteractiveEventMetadataScript: "",
      interactiveEventMetadata: "",
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  submit() {
    if (this.props.content) {
      this.modify();
    } else {
      this.create();
    }
  }

  _stateToPayload() {
    let payload = {
      name: this.state.name,
      description: this.state.description,
      displayStartDate: moment(this.state.displayStartDate)
        .startOf("day")
        .toISOString(),
      displayEndDate: this.state.displayEndDate
        ? moment(this.state.displayEndDate).endOf("day").toISOString()
        : null,
      displayForAllStores: this.state.displayForAllStores == "true",
      selectedStores:
        this.state.displayForAllStores != "true"
          ? this.state.selectedStores
          : [],
      displayRanking: this.state.dynamicRanking
        ? null
        : parseInt(this.state.displayRanking),
      dynamicRanking: this.state.dynamicRanking,
      dynamicDisplayRankingScript: this.state.dynamicRanking
        ? this.state.dynamicDisplayRankingScript
        : null,
      conditionalRendering: this.state.conditionallyDisplay == "true",
      dynamicConditionalRenderingScript:
        this.state.conditionallyDisplay == "true"
          ? this.state.dynamicConditionalRenderingScript
          : null,
      dynamicImage: this.state.dynamicImage,
      dynamicImageScript: this.state.dynamicImage
        ? this.state.dynamicImageScript
        : null,
      image: this.state.dynamicImage ? null : this.state.image?.url,
      dynamicHeader: this.state.dynamicHeader,
      dynamicHeaderScript: this.state.dynamicHeader
        ? this.state.dynamicHeaderScript
        : null,
      header: this.state.dynamicHeader ? null : this.state.header,
      dynamicSubheader: this.state.dynamicSubheader,
      dynamicSubheaderScript: this.state.dynamicSubheader
        ? this.state.dynamicSubheaderScript
        : null,
      subheader: this.state.dynamicSubheader ? null : this.state.subheader,
      interactive: this.state.interactive ? true : false,
      dynamicInteractiveButtonText: this.state.dynamicInteractiveButtonText,
      dynamicInteractiveButtonTextScript: this.state
        .dynamicInteractiveButtonText
        ? this.state.dynamicInteractiveButtonTextScript
        : null,
      interactiveButtonText: this.state.dynamicInteractiveButtonText
        ? null
        : this.state.interactiveButtonText,
      interactiveEventType: this.state.interactiveEventType,
      dynamicInteractiveEventData: this.state.dynamicInteractiveEventData,
      dynamicInteractiveEventDataScript: this.state.dynamicInteractiveEventData
        ? this.state.dynamicInteractiveEventDataScript
        : null,
      interactiveEventData: this.state.dynamicInteractiveEventData
        ? null
        : this.state.interactiveEventData,
      dynamicInteractiveEventMetadata:
        this.state.dynamicInteractiveEventMetadata,
      dynamicInteractiveEventMetadataScript: this.state
        .dynamicInteractiveEventMetadata
        ? this.state.dynamicInteractiveEventMetadataScript
        : null,
      interactiveEventMetadata: this.state.dynamicInteractiveEventMetadata
        ? null
        : this.state.interactiveEventMetadata,
    };

    return payload;
  }

  create() {
    this.setState({ submitting: true });

    let payload = this._stateToPayload();

    APIV2.createAppContent(payload)
      .then(
        (data) => {
          const content = data.data.content;

          this.toggleModal();

          PubSub.publish(Event.IN_APP_CONTENT.CREATED, content);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create content - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  modify() {
    this.setState({ submitting: true });

    let payload = this._stateToPayload();

    APIV2.modifyAppContent(this.props.content?._id, payload)
      .then(
        (data) => {
          const content = data.data.content;

          this.toggleModal();

          PubSub.publish(Event.IN_APP_CONTENT.MODIFIED, content);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to modify content - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.content && !this.props.duplicate
                ? "Modify Content Card"
                : null}

              {!this.props.content ? "New Content Card" : null}
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={
                  this.state.submitting ||
                  !this.state.name ||
                  !this.state.displayStartDate ||
                  (!this.state.dynamicRanking &&
                    isNaN(this.state.displayRanking)) ||
                  (!this.state.dynamicRanking &&
                    this.state.displayRanking < 1) ||
                  (!this.state.dynamicRanking &&
                    this.state.displayRanking > 100) ||
                  (this.state.dynamicImage
                    ? !this.state.dynamicImageScript
                    : !this.state.image) ||
                  (this.state.dynamicHeader
                    ? !this.state.dynamicHeaderScript
                    : !this.state.header) ||
                  (this.state.dynamicSubheader
                    ? !this.state.dynamicSubheaderScript
                    : !this.state.subheader) ||
                  (this.state.interactive
                    ? !this.state.interactiveButtonText
                    : false)
                }
                color="primary"
                onClick={this.submit.bind(this)}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  <>{this.props.content ? "Save" : "Add"}</>
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            <h3 className="text-dark mt-0 mb-3 border-bottom pb-3">
              Card Information
            </h3>
            <FormGroup>
              <h5>
                Name{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <Input
                bsSize="sm"
                type="text"
                name="name"
                placeholder="Card Name"
                value={this.state.name}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Description</h5>
              <Input
                bsSize="sm"
                type="textarea"
                rows={2}
                name="description"
                placeholder="Card Description"
                value={this.state.description}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>
                Display In-App Starting On
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <DatePicker
                cleanable={false}
                size="sm"
                oneTap={true}
                preventOverflow={true}
                position="auto"
                block
                value={this.state.displayStartDate}
                onChange={(value) => {
                  this.setState({ displayStartDate: value });
                }}
              ></DatePicker>
            </FormGroup>
            <FormGroup>
              <h5>Stop Displaying In-App On</h5>
              <DatePicker
                size="sm"
                oneTap={true}
                preventOverflow={true}
                position="auto"
                block
                value={this.state.displayEndDate}
                onChange={(value) => {
                  this.setState({ displayEndDate: value });
                }}
              ></DatePicker>
            </FormGroup>
            <FormGroup>
              <h5>
                Display In-App For Users At All Current & Future Stores
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <Input
                bsSize="sm"
                type="select"
                name="displayForAllStores"
                placeholder="Display"
                value={this.state.displayForAllStores}
                onChange={this.handleInputChange.bind(this)}
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Input>
              {this.state.displayForAllStores == "false" ? (
                <>
                  <CheckPicker
                    className="mt-2"
                    loading={this.state.loadingStores}
                    groupBy="groupBy"
                    block
                    ref={this.storePickerRef}
                    cleanable={true}
                    label="Stores"
                    data={this.state.storeOptions}
                    placement="autoVertical"
                    preventOverflow={true}
                    placeholder="Select Stores"
                    value={this.state.selectedStores}
                    onChange={(v) => {
                      this.setState({
                        selectedStores: v,
                      });
                    }}
                    sort={(isGroup) => {
                      if (isGroup) {
                        return (a, b) => {
                          if (b.groupTitle == "Live") {
                            return 1;
                          }

                          if (b.groupTitle == "Prelaunch") {
                            if (a.groupTitle == "Live") {
                              return -1;
                            }

                            return 1;
                          }

                          if (b.groupTitle == "Buildout") {
                            if (
                              a.groupTitle == "Live" ||
                              a.groupTitle == "Prelaunch"
                            ) {
                              return -1;
                            }

                            return 1;
                          }

                          if (b.groupTitle == "Site Selection") {
                            if (
                              a.groupTitle == "Live" ||
                              a.groupTitle == "Prelaunch" ||
                              a.groupTitle == "Buildout"
                            ) {
                              return -1;
                            }

                            return 1;
                          }

                          return a.groupTitle < b.groupTitle ? -1 : 1;
                        };
                      }

                      return (a, b) => {
                        return a.label < b.label ? -1 : 1;
                      };
                    }}
                  ></CheckPicker>
                </>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Row className="align-items-center">
                <Col xs="">
                  <h5 className="mb-1">
                    Display Ranking{" "}
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  <p style={{ lineHeight: 1.2 }} className="small text-dark">
                    1-100, where 1 is displayed at the top of the feed.
                  </p>
                </Col>
                <Col xs="auto">
                  <Checkbox
                    checked={this.state.dynamicRanking}
                    onChange={(e, v) => {
                      this.setState({
                        dynamicRanking: v,
                      });
                    }}
                  >
                    {" "}
                    Dynamic
                  </Checkbox>
                </Col>
              </Row>
              {this.state.dynamicRanking ? (
                <Input
                  bsSize="sm"
                  type="textarea"
                  name="dynamicDisplayRankingScript"
                  value={this.state.dynamicDisplayRankingScript}
                  onChange={(e) => {
                    this.setState({
                      dynamicDisplayRankingScript: e.target.value,
                    });
                  }}
                  rows={4}
                  placeholder="Javascript here"
                ></Input>
              ) : (
                <Input
                  bsSize="sm"
                  type="number"
                  name="displayRanking"
                  placeholder="Display Ranking"
                  value={this.state.displayRanking}
                  onChange={this.handleInputChange.bind(this)}
                ></Input>
              )}
            </FormGroup>
            <FormGroup>
              <h5>
                Display Conditionally Based On User & Store Information
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <Input
                bsSize="sm"
                type="select"
                name="conditionallyDisplay"
                placeholder="Display"
                value={this.state.conditionallyDisplay}
                onChange={this.handleInputChange.bind(this)}
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Input>
              {this.state.conditionallyDisplay == "true" ? (
                <>
                  <Input
                    className="mt-2"
                    type="textarea"
                    name="dynamicConditionalRenderingScript"
                    value={this.state.dynamicConditionalRenderingScript}
                    onChange={(e) => {
                      this.setState({
                        dynamicConditionalRenderingScript: e.target.value,
                      });
                    }}
                    rows={4}
                    placeholder="Javascript here"
                  ></Input>
                </>
              ) : null}
            </FormGroup>

            <h3 className="text-dark mt-0 mb-3 border-bottom pb-3">
              Card Content
            </h3>
            <FormGroup>
              <Row className="align-items-center">
                <Col xs="">
                  <h5 className="mb-0">
                    Image{" "}
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                </Col>
                <Col xs="auto">
                  <Checkbox
                    checked={this.state.dynamicImage}
                    onChange={(e, v) => {
                      this.setState({
                        dynamicImage: v,
                      });
                    }}
                  >
                    {" "}
                    Dynamic
                  </Checkbox>
                </Col>
              </Row>
              {this.state.dynamicImage ? (
                <Input
                  bsSize="sm"
                  type="textarea"
                  name="dynamicImageScript"
                  value={this.state.dynamicImageScript}
                  onChange={(e) => {
                    this.setState({
                      dynamicImageScript: e.target.value,
                    });
                  }}
                  rows={4}
                  placeholder="Javascript here"
                ></Input>
              ) : (
                <>
                  {this.state.image ? (
                    <div
                      style={{
                        width: "100%",
                        height: 200,
                        position: "relative",
                      }}
                      className="border text-center border-lighter"
                    >
                      <div style={{ position: "absolute", top: 10, right: 10 }}>
                        <Button
                          size="sm"
                          outline
                          color="danger"
                          onClick={() => {
                            this.setState({
                              image: null,
                            });
                          }}
                        >
                          <i className="mdi mdi-close"></i>
                        </Button>
                      </div>
                      <img
                        src={this.state.image?.url}
                        alt="Image"
                        style={{ height: "200px", width: "auto" }}
                      />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        this.setState({
                          showImagePicker: true,
                        });
                      }}
                      style={{ height: "200px" }}
                      className="cursor-pointer border border-lighter text-center d-flex align-items-center text-center"
                    >
                      <div
                        style={{ width: "100%" }}
                        className="text-dark text-center"
                      >
                        Click here to add an image
                      </div>
                    </div>
                  )}
                </>
              )}
            </FormGroup>
            <FormGroup>
              <Row className="align-items-center">
                <Col xs="">
                  <h5 className="mb-0">
                    Header{" "}
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                </Col>
                <Col xs="auto">
                  <Checkbox
                    checked={this.state.dynamicHeader}
                    onChange={(e, v) => {
                      this.setState({
                        dynamicHeader: v,
                      });
                    }}
                  >
                    {" "}
                    Dynamic
                  </Checkbox>
                </Col>
              </Row>
              {this.state.dynamicHeader ? (
                <Input
                  bsSize="sm"
                  type="textarea"
                  name="dynamicHeaderScript"
                  value={this.state.dynamicHeaderScript}
                  onChange={(e) => {
                    this.setState({
                      dynamicHeaderScript: e.target.value,
                    });
                  }}
                  rows={4}
                  placeholder="Javascript here"
                ></Input>
              ) : (
                <Input
                  bsSize="sm"
                  type="text"
                  name="header"
                  placeholder="Card Header"
                  value={this.state.header}
                  onChange={this.handleInputChange.bind(this)}
                ></Input>
              )}
            </FormGroup>
            <FormGroup>
              <Row className="align-items-center">
                <Col xs="">
                  <h5 className="mb-0">
                    Subheader{" "}
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                </Col>
                <Col xs="auto">
                  <Checkbox
                    checked={this.state.dynamicSubheader}
                    onChange={(e, v) => {
                      this.setState({
                        dynamicSubheader: v,
                      });
                    }}
                  >
                    {" "}
                    Dynamic
                  </Checkbox>
                </Col>
              </Row>
              {this.state.dynamicSubheader ? (
                <Input
                  bsSize="sm"
                  type="textarea"
                  name="dynamicSubheaderScript"
                  value={this.state.dynamicSubheaderScript}
                  onChange={(e) => {
                    this.setState({
                      dynamicSubheaderScript: e.target.value,
                    });
                  }}
                  rows={4}
                  placeholder="Javascript here"
                ></Input>
              ) : (
                <Input
                  bsSize="sm"
                  type="textarea"
                  name="subheader"
                  placeholder="Card Subheader"
                  rows={2}
                  value={this.state.subheader}
                  onChange={this.handleInputChange.bind(this)}
                ></Input>
              )}
            </FormGroup>
            <FormGroup>
              <Checkbox
                checked={this.state.interactive}
                onChange={(e, v) => {
                  this.setState({
                    interactive: v,
                  });
                }}
              >
                {" "}
                Interactive Card
              </Checkbox>
            </FormGroup>
            {this.state.interactive && (
              <>
                <FormGroup>
                  <Row className="align-items-center">
                    <Col xs="">
                      <h5 className="mb-0">
                        Interactive Button Text{" "}
                        <i
                          className="mdi mdi-octagram text-danger pl-1"
                          style={{
                            fontSize: "80%",
                            position: "relative",
                            top: -1,
                          }}
                        ></i>
                      </h5>
                    </Col>
                    <Col xs="auto">
                      <Checkbox
                        checked={this.state.dynamicInteractiveButtonText}
                        onChange={(e, v) => {
                          this.setState({
                            dynamicInteractiveButtonText: v,
                          });
                        }}
                      >
                        {" "}
                        Dynamic
                      </Checkbox>
                    </Col>
                  </Row>
                  {this.state.dynamicInteractiveButtonText ? (
                    <Input
                      bsSize="sm"
                      type="textarea"
                      name="dynamicInteractiveButtonTextScript"
                      value={this.state.dynamicInteractiveButtonTextScript}
                      onChange={(e) => {
                        this.setState({
                          dynamicInteractiveButtonTextScript: e.target.value,
                        });
                      }}
                      rows={4}
                      placeholder="Javascript here"
                    ></Input>
                  ) : (
                    <Input
                      bsSize="sm"
                      type="text"
                      name="interactiveButtonText"
                      placeholder="Interactive Button Text"
                      value={this.state.interactiveButtonText}
                      onChange={this.handleInputChange.bind(this)}
                    ></Input>
                  )}
                </FormGroup>
                <FormGroup>
                  <Row className="align-items-center">
                    <Col xs="">
                      <h5 className="mb-0">
                        Interactive Event{" "}
                        <i
                          className="mdi mdi-octagram text-danger pl-1"
                          style={{
                            fontSize: "80%",
                            position: "relative",
                            top: -1,
                          }}
                        ></i>
                      </h5>
                    </Col>
                  </Row>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="interactiveEventType"
                    placeholder="InteractiveEventType"
                    value={this.state.interactiveEventType}
                    onChange={this.handleInputChange.bind(this)}
                  >
                    <option value="" disabled>
                      Select An Event
                    </option>
                    <option value="in-app-page">In-App Page Change</option>
                    <option value="in-app-event">In-App Event</option>
                    <option value="website-link">Website Link</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Row className="align-items-center">
                    <Col xs="">
                      <h5 className="mb-0">
                        {!this.state.interactiveEventType ? "Event Data" : ""}
                        {this.state.interactiveEventType == "in-app-page"
                          ? "In-App URL Path"
                          : ""}
                        {this.state.interactiveEventType == "in-app-event"
                          ? "In-App Event Name"
                          : ""}
                        {this.state.interactiveEventType == "website-link"
                          ? "Website URL"
                          : ""}{" "}
                        <i
                          className="mdi mdi-octagram text-danger pl-1"
                          style={{
                            fontSize: "80%",
                            position: "relative",
                            top: -1,
                          }}
                        ></i>
                      </h5>
                    </Col>
                    <Col xs="auto">
                      <Checkbox
                        checked={this.state.dynamicInteractiveEventData}
                        onChange={(e, v) => {
                          this.setState({
                            dynamicInteractiveEventData: v,
                          });
                        }}
                      >
                        {" "}
                        Dynamic
                      </Checkbox>
                    </Col>
                  </Row>
                  {this.state.dynamicInteractiveEventData ? (
                    <Input
                      bsSize="sm"
                      type="textarea"
                      name="dynamicInteractiveEventDataScript"
                      value={this.state.dynamicInteractiveEventDataScript}
                      onChange={(e) => {
                        this.setState({
                          dynamicInteractiveEventDataScript: e.target.value,
                        });
                      }}
                      rows={4}
                      placeholder="Javascript here"
                    ></Input>
                  ) : (
                    <Input
                      bsSize="sm"
                      type="text"
                      name="interactiveEventData"
                      placeholder={`${
                        !this.state.interactiveEventType ? "Event Data" : ""
                      }${
                        this.state.interactiveEventType == "in-app-page"
                          ? "In-App URL Path"
                          : ""
                      }${
                        this.state.interactiveEventType == "in-app-event"
                          ? "In-App Event Name"
                          : ""
                      }${
                        this.state.interactiveEventType == "website-link"
                          ? "Website URL"
                          : ""
                      }`}
                      value={this.state.interactiveEventData}
                      onChange={this.handleInputChange.bind(this)}
                    ></Input>
                  )}
                </FormGroup>
                {this.state.interactiveEventType == "in-app-event" && (
                  <FormGroup>
                    <Row className="align-items-center">
                      <Col xs="">
                        <h5 className="mb-0">
                          In-App Event Metadata JSON{" "}
                          <i
                            className="mdi mdi-octagram text-danger pl-1"
                            style={{
                              fontSize: "80%",
                              position: "relative",
                              top: -1,
                            }}
                          ></i>
                        </h5>
                      </Col>
                      <Col xs="auto">
                        <Checkbox
                          checked={this.state.dynamicInteractiveEventMetadata}
                          onChange={(e, v) => {
                            this.setState({
                              dynamicInteractiveEventMetadata: v,
                            });
                          }}
                        >
                          {" "}
                          Dynamic
                        </Checkbox>
                      </Col>
                    </Row>
                    {this.state.dynamicInteractiveEventMetadata ? (
                      <Input
                        bsSize="sm"
                        type="textarea"
                        name="dynamicInteractiveEventMetadataScript"
                        value={this.state.dynamicInteractiveEventMetadataScript}
                        onChange={(e) => {
                          this.setState({
                            dynamicInteractiveEventMetadataScript:
                              e.target.value,
                          });
                        }}
                        rows={4}
                        placeholder="Javascript here"
                      ></Input>
                    ) : (
                      <Input
                        bsSize="sm"
                        type="textarea"
                        name="interactiveEventMetadata"
                        placeholder="In-App Event Metaata JSON"
                        rows={4}
                        value={this.state.interactiveEventMetadata}
                        onChange={this.handleInputChange.bind(this)}
                      ></Input>
                    )}
                  </FormGroup>
                )}
              </>
            )}
          </Drawer.Body>
        </Drawer>
        <FileUploadDrawer
          open={this.state.showImagePicker}
          cdn="public"
          size="sm"
          singleFile={true}
          compressUploads={true}
          allowedFileTypes={[
            "image/jpeg",
            "image/png",
            "image/jpg",
            "image/gif",
          ]}
          onComplete={(files) => {
            console.log(files);

            this.setState({
              showImagePicker: false,
              image: files[0],
            });
          }}
        ></FileUploadDrawer>
      </>
    );
  }
}

export default withRouter(InAppContentDrawer);
