import ArrowText from "components/ArrowText";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  Badge,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import Card from "reactstrap/lib/Card";
import moment from "moment";
import CustomerOrderDrawer from "components/Drawers/CustomerOrderDrawer";
import CustomerCreateOrderDrawer from "components/Drawers/CustomerCreateOrderDrawer";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import CustomerAllOrdersDrawer from "components/Drawers/CustomerAllOrdersDrawer";
import _ from "underscore";
import { SelectPicker } from "rsuite";
import NoteBody from "components/NoteBody";
import swal from "sweetalert";
import LinkUtils from "lib/LinkUtils";
import Linkify from "components/Linkify";
import NoteBodyV2 from "../NoteBodyV2";
import Editor from "../NovelEditor/ui/editor";

class FranDevLeadNotesWidget extends React.Component {
  state = {
    expand: false,
    page: 1,
    recentFirst: "recentFirst",
    notes: [],
  };

  fetchNotes(forceLoad = false) {
    if (forceLoad) {
      this.setState({
        loading: true,
      });
    }

    let query = {
      frandevLeadID: this?.props?.lead?._id,
    };

    let sortBy = {
      createdAt: this.state.recentFirst == "recentFirst" ? -1 : 1,
    };

    APIV2.getNotesForStore("none", this.state.page, 20, query, sortBy, true)
      .then(
        (data) => {
          let notes = forceLoad ? [] : this.state.notes;

          notes = data?.data?.notes?.length
            ? notes.concat(data?.data?.notes)
            : notes;

          let notesOut = [];

          for (let i = 0; i < notes?.length; i++) {
            if (!_.findWhere(notesOut, { _id: notes[i]?._id })) {
              notesOut.push(notes[i]);
            }
          }

          this.setState({
            notes: notesOut,
            pagination: data?.data?.pagination,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  handleRecentFirstChange(v) {
    this.setState(
      {
        recentFirst: v,
        page: 1,
      },
      () => {
        this.fetchNotes(true);
      }
    );
  }

  componentDidMount() {
    this.fetchNotes(true);

    PubSub.subscribe(Event.NOTE.CREATED, (note) => {
      let notes = this.state.notes?.length ? this.state.notes : [];

      notes = [note].concat(notes);

      this.setState({
        notes,
      });
    });

    PubSub.subscribe(Event.NOTE.MODIFIED, (note) => {
      if (this.state?.notes?.length) {
        let idx = _.findIndex(this.state.notes, { _id: note?.id });

        if (idx >= 0) {
          let od = this.state.notes;
          od[idx] = note;

          this.setState({
            notes: od,
          });
        }
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.leadID != this.props?.leadID ||
      prevProps?.storeID != this.props?.storeID
    ) {
      this.fetchNotes(true);
    }
  }

  openOrderDrawer(note) {
    this.setState({
      openOrder: note,
      noteDrawer: true,
    });
  }

  render() {
    return (
      <>
        {this.props.loading ? (
          <>
            <Card
              style={{ marginLeft: -15, marginRight: -15 }}
              className="mb-4 shadow bnote border-0 mt--4"
            >
              <CardBody className="bg-transparent py-5 text-center">
                <Spinner size="lg" color="medium"></Spinner>
              </CardBody>
            </Card>
          </>
        ) : (
          <>
            <Card
              style={{ marginLeft: -15, marginRight: -15 }}
              className="mb-4 bnote border-0 mt--4"
            >
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <Col>
                    <h3 className="text-dark mb-0">
                      {this.props.title ? this.props.title : "Notes"}
                    </h3>
                  </Col>
                  <Col xs="auto">
                    <SelectPicker
                      searchable={false}
                      placement="auto"
                      data={[
                        { label: "Newest First", value: "recentFirst" },
                        { label: "Oldest First", value: "oldestFirst" },
                      ]}
                      value={this.state.recentFirst}
                      onChange={(v) => {
                        this.handleRecentFirstChange(v);
                      }}
                      cleanable={false}
                    ></SelectPicker>
                  </Col>
                </Row>
              </CardHeader>
              <CardHeader className="bg-transparent">
                <NoteBodyV2
                  templates={true}
                  templateTypes={["FranDev Lead"]}
                  id={`newstorenote-${this?.props?.lead?._id}`}
                  frandevLead={this?.props?.lead}
                  onSave={(note) => {
                    console.log(note);

                    this.setState(
                      {
                        page: 1,
                      },
                      () => {
                        this.fetchNotes(true);
                      }
                    );
                  }}
                ></NoteBodyV2>
              </CardHeader>
              <CardBody className="p-0">
                {this.state?.loading && (
                  <div className="px-3 py-5 text-center">
                    <Spinner size="sm" color="dark"></Spinner>
                  </div>
                )}
                {!this.state?.notes?.length && !this.state.loading && (
                  <div className="px-3 py-5">
                    <Row>
                      <Col xs="12" className="text-center">
                        <p className="m-0">No notes found.</p>
                      </Col>
                    </Row>
                  </div>
                )}

                {this.state?.notes?.map((note, i) => (
                  <div key={note?._id}>
                    <div className="px-4 cursor-pointer py-3">
                      <div
                        onClick={() => {
                          if (this.state[`noteEditable${note?._id}`]) {
                            return;
                          }

                          this.setState({
                            [`noteEditable${note?._id}`]: true,
                          });
                        }}
                        className={`${
                          !this.state[`noteEditable${note?._id}`]
                            ? "cursor-pointer"
                            : ""
                        }`}
                      >
                        <Editor
                          editable={
                            this.state[`noteEditable${note?._id}`]
                              ? true
                              : false
                          }
                          id={note?._id}
                          padding="p-0"
                          autoSave={true}
                          debounceRate={750}
                          value={note?.body}
                          onSave={(data) => {
                            console.log(data);

                            /**
                            *  storeID = "",
                            noteID = "",
                            customerID = "",
                            taskID = "",
                            leadID = "",
                            affiliateID = "",
                            frandevLeadID = "",
                            appointmentID = "",
                            productOrderID = "",
                            storeNote = false,
                            title = "",
                            body = "",
                            attachments = []
                            */

                            APIV2.modifyStoreNote(
                              "none",
                              note?._id,
                              null,
                              null,
                              null,
                              null,
                              this?.props?.lead._id,
                              null,
                              null,
                              false,
                              "",
                              data,
                              note.attachments?.length > 0
                                ? note.attachments
                                : []
                            )
                              .then(
                                () => {
                                  let notes = this.state.notes;

                                  let idx = _.findIndex(notes, {
                                    _id: note._id,
                                  });

                                  if (idx >= 0) {
                                    notes[idx] = note;
                                  }

                                  this.setState({
                                    notes,
                                  });
                                },
                                (e) => {}
                              )
                              .finally(() => {
                                this.setState({
                                  [`noteSaving${note?._id}`]: false,
                                });
                              });
                          }}
                          onChange={() => {
                            this.setState({
                              [`noteSaving${note?._id}`]: true,
                            });
                          }}
                          saving={this.state[`noteSaving${note?._id}`]}
                          allowTaskLists={true}
                        ></Editor>
                      </div>
                      {note?.attachments?.length > 0 && (
                        <div className="mt-4">
                          <h5>Attachments: </h5>
                          {note?.attachments?.map((at, i) => (
                            <div
                              className={`p-2 border rounded ${
                                i != this.state?.attachments?.length - 1 &&
                                "mb-2"
                              }`}
                              key={i}
                            >
                              <Row className="align-items-center">
                                <Col xs="">
                                  <p className="small m-0 text-dark">
                                    {at?.fileName}
                                  </p>
                                </Col>
                                <Col xs="auto">
                                  <Button
                                    className="btn-icon-only"
                                    size="sm"
                                    outline
                                    color="secondary"
                                    onClick={() => {
                                      LinkUtils.openPrivateURL(at?.url);
                                    }}
                                  >
                                    <i className="mdi mdi-eye"></i>
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          ))}
                        </div>
                      )}
                      <div className="mt-3">
                        <Row className="align-items-center">
                          <Col xs="12" sm="">
                            <small>
                              {moment(note?.createdAt).format("MM/DD/YY h:mmA")}{" "}
                              by {note?.createdBy?.name}
                              {note?.updatedAt != note?.createdAt ? (
                                <>
                                  , last updated{" "}
                                  {moment(note?.updatedAt).format(
                                    "MM/DD/YY h:mmA"
                                  )}
                                </>
                              ) : null}
                            </small>
                          </Col>
                          <Col
                            xs="12"
                            sm="auto"
                            className="text-right mt-2 mt-sm-0"
                          >
                            <Badge className="border mr-2" color="light">
                              {this.state[`noteSaving${note?._id}`]
                                ? "Saving..."
                                : "Up To Date"}
                            </Badge>

                            <Button
                              size="sm"
                              color="danger"
                              outline
                              className="p-1"
                              style={{ lineHeight: 1, height: 22 }}
                              disabled={this.state.deletingNote == note?._id}
                              onClick={() => {
                                swal({
                                  title: "Delete Note?",
                                  text: "Are you sure you want to delete this note?",
                                  icon: "warning",
                                  buttons: ["Nevermind", "Delete"],
                                  dangerMode: true,
                                }).then((conf) => {
                                  if (!conf) {
                                    return;
                                  }

                                  APIV2.deleteStoreNote("none", note?._id).then(
                                    () => {
                                      let notes = this.state.notes;

                                      let idx = _.findIndex(notes, {
                                        _id: note._id,
                                      });

                                      notes.splice(idx, 1);

                                      this.setState({
                                        editNote: "",
                                        notes,
                                      });

                                      this.setState({
                                        deletingNote: "",
                                      });
                                    }
                                  );
                                });
                              }}
                            >
                              Delete
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    {i != this.state?.notes?.length - 1 && (
                      <hr className="m-0"></hr>
                    )}
                  </div>
                ))}
              </CardBody>
              <CardFooter className="text-center">
                <Button
                  size="sm"
                  color="secondary"
                  outline
                  onClick={() => {
                    console.log(this.state.pagination);

                    this.setState(
                      {
                        page: this.state.pagination?.next,
                      },
                      () => {
                        this.fetchNotes();
                      }
                    );
                  }}
                  disabled={!this.state.pagination?.hasNext}
                >
                  {this.state.pagination?.hasNext
                    ? "View More Notes"
                    : "Viewing All Notes"}
                </Button>
              </CardFooter>
            </Card>
          </>
        )}
      </>
    );
  }
}

export default withRouter(FranDevLeadNotesWidget);
