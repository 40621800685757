import StringUtils from "lib/StringUtils";
import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import moment from "moment";
import APIV2 from "lib/APIV2";
import _ from "underscore";
import Badge from "reactstrap/lib/Badge";
import CustomerStatusWidget from "./CustomerStatusWidget";
import CustomerNextBillDateWidget from "./CustomerNextBillDateWidget";
import CustomerPlanWidget from "./CustomerPlanWidget";
import CustomerCouponWidget from "./CustomerCouponWidget";
import CustomerBoxWidget from "./CustomerBoxWidget";
import CustomerHoldDateWidget from "./CustomerHoldDateWidget";
import CustomerHoldDetailsWidget from "./CustomerHoldDetailsWidget";

class CustomerSubscriptionWidget extends React.Component {
  state = {
    expand: false,
  };

  componentDidUpdate(prevProps) {}

  componentDidMount() {}

  render() {
    return (
      <>
        {this.props.loading ? (
          <>
            <Card className="shadow border">
              <CardBody className="bg-transparent py-5 text-center">
                <Spinner size="lg" color="medium"></Spinner>
              </CardBody>
            </Card>
          </>
        ) : (
          <>
            <Row className="align-items-top row-eq-height">
              <Col xs="12" md={`6`} className="mb-4">
                <CustomerStatusWidget
                  customer={this.props.customer}
                  loading={this.props.loading}
                ></CustomerStatusWidget>
              </Col>
              {this.props.customer?.accountStatus == "ACTIVE" ? (
                <>
                  <Col xs="12" md="6" className="mb-4">
                    <CustomerNextBillDateWidget
                      customer={this.props.customer}
                      loading={this.props.loading}
                    ></CustomerNextBillDateWidget>
                  </Col>
                </>
              ) : (
                <>
                  <Col xs="12" md="6" className="mb-4">
                    <CustomerHoldDateWidget
                      customer={this.props.customer}
                      loading={this.props.loading}
                    ></CustomerHoldDateWidget>
                  </Col>
                </>
              )}
            </Row>
            {this.props.customer?.accountStatus == "ACTIVE" ? (
              <>
                <Row>
                  <Col xs="12" md="6" className="mb-4">
                    <CustomerPlanWidget
                      customer={this.props.customer}
                      loading={this.props.loading}
                    ></CustomerPlanWidget>
                  </Col>
                  <Col xs="12" md="6" className="mb-4">
                    <CustomerCouponWidget
                      customer={this.props.customer}
                      loading={this.props.loading}
                    ></CustomerCouponWidget>
                  </Col>
                </Row>

                <CustomerBoxWidget
                  customer={this.props.customer}
                  loading={this.props.loading}
                ></CustomerBoxWidget>
              </>
            ) : (
              <>
                <CustomerHoldDetailsWidget
                  customer={this.props.customer}
                  loading={this.props.loading}
                ></CustomerHoldDetailsWidget>
              </>
            )}
          </>
        )}
      </>
    );
  }
}

export default CustomerSubscriptionWidget;
