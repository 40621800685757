import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  BarChart,
  BarList,
  Card,
  CategoryBar,
  DonutChart,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import {
  Axis,
  Chart,
  Coordinate,
  Interaction,
  Interval,
  Legend,
  Tooltip,
} from "bizcharts";
import ChartMetricHeader from "./ChartMetricHeader";

class OutreachTypePieChartCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    tab: "overall",
  };

  /**
   * Fetches the order data by week for the given stores & date range
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchData(cubejsApi, stores, dateRange) {
    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["Appointments.count"],
          order: {
            "Appointments.startsat": "asc",
          },

          timeDimensions: [
            {
              dimension: "Appointments.startsat",
              dateRange: [dateRange[0], dateRange[1]],
            },
          ],
          filters: [
            {
              member: "Appointments.storeid",
              operator: "equals",
              values: stores,
            },
            {
              member: "Appointments.appointmenttype",
              operator: "equals",
              values: ["or-popup", "or-groupsweat", "or-dropin", "or-inreach"],
            },
            {
              member: "Appointments.status",
              operator: "equals",
              values: ["COMPLETED"],
            },
          ],
          dimensions: ["Appointments.appointmenttype", "Stores.name"],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          if (!data?.length) {
            return resolve(null);
          }

          let total = 0;
          let totalFirstTime = 0;

          let out = [];

          let outOverall = [];

          for (let i = 0; i < data?.length; i++) {
            const item = data[i];
            const storeName = item["Stores.name"]
              ?.replace("Project LeanNation", "PLN")
              ?.trim();

            total += item["Appointments.count"];

            const idx = _.findIndex(out, { storeName });

            let metricName = item["Appointments.appointmenttype"];

            if (metricName == "or-popup") {
              metricName = "Pop-Up Events";

              totalFirstTime += item["Appointments.count"];
            } else if (metricName == "or-groupsweat") {
              metricName = "Group Sweats";
            } else if (metricName == "or-dropin") {
              metricName = "Drop-Ins";
            } else if (metricName == "or-inreach") {
              metricName = "InReach Events";
            }

            if (idx >= 0) {
              out[idx][metricName] = item["Appointments.count"];
            } else {
              out.push({
                storeName,
                [metricName]: item["Appointments.count"],
              });
            }

            const idx2 = _.findIndex(outOverall, { metricName });

            if (idx2 >= 0) {
              outOverall[idx2].Events += item["Appointments.count"];
            } else {
              outOverall.push({
                Events: item["Appointments.count"],
                metricName,
              });
            }
          }

          out = out.map((item) => {
            const total =
              (item["Pop-Up Events"] ? item["Pop-Up Events"] : 0) +
              (item["Group Sweats"] ? item["Group Sweats"] : 0) +
              (item["Drop-Ins"] ? item["Drop-Ins"] : 0) +
              (item["InReach Events"] ? item["InReach Events"] : 0);

            let categoryValues = [];

            let categoryColors = [];

            let popupPercent = 0;
            let groupPercent = 0;
            let dropinPercent = 0;
            let inreachPercent = 0;

            let popupCount = item["Pop-Up Events"] ? item["Pop-Up Events"] : 0;
            let groupCount = item["Group Sweats"] ? item["Group Sweats"] : 0;
            let dropinCount = item["Drop-Ins"] ? item["Drop-Ins"] : 0;
            let inreachCount = item["InReach Events"]
              ? item["InReach Events"]
              : 0;

            if (!total) {
              categoryValues = [100];
              categoryColors = ["gray"];
            } else {
              popupPercent = Math.round(
                ((item["Pop-Up Events"] ? item["Pop-Up Events"] : 0) / total) *
                  100
              );
              groupPercent = Math.round(
                ((item["Group Sweats"] ? item["Group Sweats"] : 0) / total) *
                  100
              );

              dropinPercent = Math.round(
                ((item["Drop-Ins"] ? item["Drop-Ins"] : 0) / total) * 100
              );

              inreachPercent = Math.round(
                ((item["InReach Events"] ? item["InReach Events"] : 0) /
                  total) *
                  100
              );

              if (popupPercent > 0) {
                categoryValues.push(popupPercent);
                categoryColors.push("orange");
              }

              if (groupPercent > 0) {
                groupPercent += 100 - (popupPercent + groupPercent);

                categoryValues.push(groupPercent);
                categoryColors.push("green");
              }

              if (dropinPercent > 0) {
                dropinPercent +=
                  100 - (popupPercent + groupPercent + dropinPercent);

                categoryValues.push(dropinPercent);
                categoryColors.push("purple");
              }

              if (inreachPercent > 0) {
                inreachPercent +=
                  100 -
                  (popupPercent +
                    groupPercent +
                    dropinPercent +
                    inreachPercent);

                categoryValues.push(inreachPercent);
                categoryColors.push("blue");
              }

              categoryColors = ["orange", "green", "purple", "blue"];
            }

            console.log(categoryColors, categoryValues);

            return {
              ...item,
              total,
              popupPercent,
              groupPercent,
              dropinPercent,
              inreachPercent,
              popupCount,
              groupCount,
              dropinCount,
              inreachCount,
              categoryValues,
              categoryColors,
            };
          });

          outOverall = _.sortBy(outOverall, "metricName");

          out = _.sortBy(out, "total");

          out.reverse();

          return resolve({
            byStore: out,
            total: total,
            overall: outOverall,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async loadDetailedReport(stores, dateRange) {
    this.setState({
      loading: true,
      dataAvailable: false,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!stores?.length || !dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchData(cubejsApi, stores, dateRange);
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load total new members by store.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });

      return;
    }

    let colors = StringUtils.randomChartColorSet(currentCount.byStore?.length);

    console.log(currentCount?.byStore);

    this.setState({
      currentCount: currentCount,
      loading: false,
      byStore: currentCount?.byStore,
      overall: currentCount?.overall,
      byStoreColors: colors,
      total: currentCount?.total,
    });

    if (typeof this.props.onLoaded == "function") {
      this.props.onLoaded();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stores != prevProps?.stores ||
      this.props.dateRange != prevProps?.dateRange
    ) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload != prevProps.reload && this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  componentDidMount() {
    if (this.props.store && this.props.dateRange) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  render() {
    return (
      <>
        <Card marginTop="mt-0">
          <ChartMetricHeader
            title="Events By Type"
            actions={
              <>
                <Button
                  size="sm"
                  outline
                  color="dark"
                  className="border-0 btn-icon-only"
                  disabled={this.state.loading}
                  onClick={() => {
                    this.loadDetailedReport(
                      this.props.stores,
                      this.props.dateRange
                    );
                  }}
                >
                  {this.state.loading ? (
                    <Spinner size="sm"></Spinner>
                  ) : (
                    <i className="mdi mdi-refresh"></i>
                  )}
                </Button>
              </>
            }
            loading={this.state.loading}
            dataAvailable={this.state.dataAvailable}
            comparisonMetric={null}
            dateRange={this.props.dateRange}
            deltaType={null}
            percentChange={null}
            showPercentChange={false}
          ></ChartMetricHeader>
          <div className="mt-3 border-bottom"></div>
          {this.state.loading ? (
            <div
              className="skeleton mt-3"
              style={{ height: "calc(327px + 1rem)", width: "100%" }}
            >
              &nbsp;
            </div>
          ) : (
            <>
              {this.state.dataAvailable ? (
                <>
                  <div className="mt-3 mx--4 px-4">
                    <Row className="align-items-center">
                      <Col xs="12" sm="12" md="6">
                        <div className="pt-4">
                          <DonutChart
                            data={this.state.overall}
                            category={"Events"}
                            dataKey="metricName"
                            colors={["purple", "green", "blue", "orange"]}
                            valueFormatter={(number) => {
                              return (
                                StringUtils.numberFormat(number) +
                                " Events" +
                                ` (${Math.round(
                                  ((number ? number : 0) /
                                    this.state.currentCount?.total) *
                                    100
                                )}%)`
                              );
                            }}
                            height="h-80"
                          />
                        </div>
                      </Col>
                      <Col md="6" className="mt-3 mt-md-0">
                        <div
                          className="pt-4 mb--4 pb-4"
                          style={{
                            maxHeight: 368,
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          {this.state.byStore?.map((store, i) => (
                            <div key={i} className={`${i != 0 && "mt-3"}`}>
                              <Row className="align-items-center">
                                <Col xs="">
                                  <p
                                    className="m-0 text-dark font-weight-500"
                                    style={{ fontSize: 16, lineHeight: 1.3 }}
                                  >
                                    {store?.storeName}
                                  </p>
                                </Col>
                                <Col xs="auto">
                                  <p
                                    className="m-0 text-dark font-weight-500"
                                    style={{ fontSize: 16, lineHeight: 1.3 }}
                                  >
                                    {StringUtils.numberFormat(store?.total)}
                                    &nbsp;Event{store?.total != 1 && "s"}
                                  </p>
                                </Col>
                              </Row>
                              <Text>
                                {store?.popupCount} Pop-Ups, {store?.groupCount}{" "}
                                Group Sweats, {store?.dropinCount} Drop-Ins,{" "}
                                {store?.inreachCount} InReach
                              </Text>
                            </div>
                          ))}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "calc(327px + 1rem)", width: "100%" }}
                >
                  <p className="m-0">
                    No data available.
                    {this.state.error ? ` ${this.state.error}` : null}
                  </p>
                </div>
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default OutreachTypePieChartCard;
