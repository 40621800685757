import React from "react";
// react component that copies the given text inside your clipboard

import { DateRangePicker } from "react-date-range";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Spinner,
  Collapse,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import moment from "moment";
import {
  Checkbox,
  CheckPicker,
  DatePicker,
  Popover,
  SelectPicker,
  Tooltip,
  Whisper,
} from "rsuite";
import StringUtils from "lib/StringUtils";
import { Table, Cell, Column, HeaderCell } from "rsuite-table";
import _ from "underscore";
import MoneyCell from "components/Table/MoneyCell";
import NumericCell from "components/Table/NumericCell";
import StoreCell from "components/Table/StoreCell";
import BootstrapTable from "react-bootstrap-table-next";
import MealsOrderedWidget from "components/Widgets/Fulfillment/MealsOrderedWidget";
import PlansFulfilledWidget from "components/Widgets/Fulfillment/PlansFulfilledWidget";
import AverageBoxSizeWidget from "components/Widgets/Fulfillment/AverageBoxSizeWidget";
import PlanFrequencyChartWidget from "components/Widgets/Fulfillment/PlanFrequencyChartWidget";
import PlanProcurementChartWidget from "components/Widgets/Fulfillment/PlanProcurementChartWidget";
import PlanTypeChartWidget from "components/Widgets/Fulfillment/PlanTypeChartWidget";
import { Link } from "react-router-dom";
import PLNDateRangePicker from "../../components/PLNDateRangePicker";
import cubejs from "@cubejs-client/core";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";

import DataAPI from "../../lib/DataAPI";
import SalesSummaryTrendlineChart from "../../components/Charts/SalesSummaryTrendlineChart";
import SalesSummaryPieChart from "../../components/Charts/SalesSummaryPieChart";

class SalesSummary extends React.Component {
  state = {
    dateRange: [
      moment()
        .startOf("month")
        .hours(0)
        .minutes(0)
        .seconds(0)
        .millisecond(0)
        .toDate(),
      moment()
        .endOf("month")
        .hours(23)
        .minutes(59)
        .seconds(59)
        .millisecond(999)
        .toDate(),
    ],
    storeOptions: [],
    selectedStores: [],
    pendingSelectedStores: [],
    groupByOptions: [
      {
        label: "Summary",
        value: "summary",
      },
      {
        label: "Daily",
        value: "daily",
      },
      {
        label: "Weekly",
        value: "weekly",
      },
      {
        label: "Monthly",
        value: "monthly",
      },
      {
        label: "Quarterly",
        value: "quarterly",
      },
      {
        label: "Yearly",
        value: "yearly",
      },
    ],
    groupBy: "summary",
    revenueSourceOptions: [
      {
        label: "All Sales",
        value: "all",
      },
      {
        label: "Subscription Sales",
        value: "subscription",
      },
      {
        label: "Net Retail Sales",
        value: "retail",
      },
    ],
    revenueSource: "all",
  };

  constructor(props) {
    super(props);

    this.storePickerRef = React.createRef();
  }

  getUser() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user;
  }

  /**
   * Checks if the current user is a global user via permissions
   *
   * @returns
   */
  isGlobal() {
    let user = this.getUser();

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  getAllStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStores(
      null,
      { _id: 1, name: 1, nsoStage: 1, supportLevel: 1, closed: 1 },
      true
    )
      .then((data) => {
        const now = new Date();

        let stores = _.filter(data.data.stores, (st) => {
          return (
            now >= moment(st?.operations?.storeOpening?.firstBillDate).toDate()
          );
        });

        if (!this.isGlobal()) {
          let user = this.getUser();

          console.log(user);

          stores = _.filter(data.data.stores, (st) => {
            return _.findWhere(user?.storePermissions, { storeID: st._id });
          });
        }

        let storeOptions = stores?.map((st) => {
          return {
            label: st?.name,
            value: st?._id,
            category: st?.closed ? "Closed Stores" : "Open Stores",
          };
        });

        let selectedStores = [];

        if (this.state.selectedStores?.length) {
          selectedStores = _.filter(this.state.selectedStores, (v) => {
            return _.findWhere(stores, { _id: v });
          });
        } else {
          selectedStores = storeOptions?.map((st) => {
            return st.value;
          });
        }

        this.setState(
          {
            stores: stores,
            storeOptions,
            selectedStores,
            pendingSelectedStores: selectedStores,
          },
          () => {
            if (this.state.groupBy && this.state.revenueSource) {
              this.loadData();
            }
          }
        );
      })
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  componentDidMount() {
    this.readURLParameters();

    this.getAllStores();
  }

  onSelectAllStores() {
    let selected = this.state.storeOptions?.map((opt) => {
      return opt.value;
    });

    if (
      this.state.pendingSelectedStores?.length ==
      this.state.storeOptions?.length
    ) {
      return this.setState({
        pendingSelectedStores: [],
      });
    }

    this.setState({
      pendingSelectedStores: selected,
    });
  }

  onChangeStores(v) {
    this.setState({
      pendingSelectedStores: v,
    });
  }

  onApplyChangeStores() {
    this.setState(
      {
        selectedStores: this.state.pendingSelectedStores,
        storesSums: [],
      },
      () => {
        this.loadData();
      }
    );
  }

  onCancelChangeStores() {
    this.setState({
      pendingSelectedStores: this.state.selectedStores,
    });
  }

  onChangeGroupBy(v) {
    if (v == this.state.groupBy) {
      return;
    }

    this.setState(
      {
        groupBy: v,
      },
      () => {
        this.loadData();
      }
    );
  }

  onChangeRevenueSource(v) {
    if (v == this.state.revenueSource) {
      return;
    }

    this.setState(
      {
        revenueSource: v,
      },
      () => {
        this.loadData();
      }
    );
  }

  hasValidDateRange() {
    if (
      this.state?.dateRange?.length != 2 ||
      !this.state?.dateRange[0] ||
      !this.state?.dateRange[1]
    ) {
      return false;
    }

    return true;
  }

  countTier(name) {
    let tierStores = _.filter(this.state.stores, (st) => {
      return st.supportLevel == name;
    });

    return tierStores.length;
  }

  getStoreByIDFromData(id) {
    console.log(id);

    let st = _.findWhere(this.state.stores, { _id: id });

    if (st) {
      st = _.clone(st);

      st.name = st.name.replace("Project LeanNation", "");

      return st;
    }

    return null;
  }

  renderDateRange() {
    if (!this.hasValidDateRange()) {
      return "Select Date Range";
    }

    let startMoment = moment(this.state.dateRange[0]?.toISOString());
    let endMoment = moment(this.state.dateRange[1]?.toISOString());

    if (startMoment?.format("YYYY-MM-DD") == endMoment.format("YYYY-MM-DD")) {
      return `${startMoment?.format("MMM D, YYYY")}`;
    }

    return `${startMoment?.format("MMM D, YYYY")} - ${endMoment?.format(
      "MMM D, YYYY"
    )}`;
  }

  renderSummaryExplanation() {
    if (!this.state.revenueSource || !this.state.groupBy) {
      return "Sales Summary";
    }

    let source = "";

    switch (this.state.revenueSource) {
      case "retail":
        source = "Net Retail Sales";
        break;
      case "subscription":
        source = "Subscription Sales";
        break;
      default:
        source = "All Sales";
    }

    if (this.state.groupBy == "week") {
      return `${source} By Week`;
    }

    if (this.state.groupBy == "day") {
      return `${source} By Day`;
    }

    if (this.state.groupBy == "month") {
      return `${source} By Month`;
    }

    return `${source} Summary`;
  }

  getPeriodString(record) {
    if (!record || !record?.date) {
      return "--";
    }

    if (this.state.groupBy == "summary") {
      return "Summary";
    }

    let startMoment = moment(record?.date?.toISOString())
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0);

    if (record?.granularity == "day") {
      return startMoment.format("MM/DD/YYYY");
    }

    if (record?.granularity == "week") {
      return `${startMoment?.format("MM/DD/YYYY")} - ${moment(
        startMoment?.format("YYYY-MM-DD")
      )
        .endOf("week")
        .format("MM/DD/YYYY")}`;
    }

    if (record?.granularity == "month") {
      return `${startMoment?.format("MMMM YYYY")}`;
    }

    if (record?.granularity == "quarter") {
      return `Q${startMoment?.format("Q YYYY")}`;
    }

    if (record?.granularity == "year") {
      return `FY ${startMoment?.format("YYYY")}`;
    }
  }

  renderPeriodHeaderCells(i) {
    if (this.state.groupBy == "summary") {
      return (
        <div
          style={{
            height: "53px",
          }}
          className="text-dark border-bottom text-right"
        >
          <div
            className="px-3"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 53,
            }}
          >
            <p className="font-weight-bold m-0">Summary</p>
          </div>
        </div>
      );
    }

    let periods = [];

    let data = this.state?.intervals;

    if (!data?.length) {
      return (
        <div
          style={{
            height: "53px",
          }}
          className="text-dark border-bottom text-right"
        >
          <div
            className="px-3"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 53,
            }}
          >
            <p className="font-weight-bold m-0">--</p>
          </div>
        </div>
      );
    }

    let width = 150;

    if (data[0].granularity == "week") {
      width = 250;
    }

    if (data[0].granularity == "month") {
      width = 220;
    }

    return (
      <>
        {data?.map((record, i) => (
          <div
            style={{
              height: "53px",
              width: width,
              minWidth: "auto",
            }}
            className={`text-dark text-center border-bottom border-right d-inline-block`}
          >
            <div
              className="px-3"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 53,
              }}
            >
              <p className="font-weight-bold m-0">{record?.readable}</p>
            </div>
          </div>
        ))}
        <div
          style={{
            height: "53px",
            width: `calc(100% - ${data?.length * width}px)`,
            minWidth: width,
          }}
          className={`text-dark text-center border-bottom d-inline-block`}
        >
          <div
            className="px-3 text-center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 53,
            }}
          >
            <p className="font-weight-bold m-0">Summary</p>
          </div>
        </div>
      </>
    );
  }

  generateExportHeaders(i) {
    let exportHeaders = [`"Store"`];

    if (this.state.groupBy == "summary") {
      exportHeaders.push(`"Summary"`);

      return exportHeaders.join(",");
    }
    let data = this.state?.intervals;

    if (!data?.length) {
      return exportHeaders.join(",");
    }

    let periods = data?.map((record, i) => {
      return `"${record?.readable}"`;
    });

    exportHeaders = exportHeaders.concat(periods);

    return exportHeaders.join(",");
  }

  loadSubscriptionData(cubejsApi) {
    this.setState({
      loadingDataSubscription: true,
    });

    let intervals = [];

    let startDate = moment(this.state.dateRange[0]?.toISOString()).format(
      "YYYY-MM-DD"
    );
    let endDate = moment(this.state.dateRange[1]?.toISOString()).format(
      "YYYY-MM-DD"
    );

    let granularity = "day";

    if (this.state.groupBy == "weekly") {
      granularity = "week";
    }

    if (this.state.groupBy == "monthly") {
      granularity = "month";
    }

    if (this.state.groupBy == "quarterly") {
      granularity = "quarter";
    }

    if (this.state.groupBy == "yearly") {
      granularity = "year";
    }

    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["CustomerInvoices.totalpricewithouttax"],
          order: {
            "Customers.createdat": "asc",
          },
          dimensions: ["CustomerInvoices.storeid"],
          timeDimensions: [
            {
              dimension: "CustomerInvoices.paidOn",
              granularity: granularity,
              dateRange: [startDate, endDate],
            },
          ],
          filters: [
            {
              member: "CustomerInvoices.storeid",
              operator: "equals",
              values: this.state.selectedStores,
            },
            {
              member: "CustomerInvoices.status",
              operator: "equals",
              values: ["PAID"],
            },
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          let storeData = {};
          let chartData = [];
          let summedData = 0;

          let intervalKey = "day";

          if (this.state.groupBy == "weekly") {
            intervalKey = "week";
          }

          if (this.state.groupBy == "monthly") {
            intervalKey = "month";
          }

          if (this.state.groupBy == "quarterly") {
            intervalKey = "quarter";
          }

          if (this.state.groupBy == "yearly") {
            intervalKey = "year";
          }

          let storesSums = [];

          for (let i = 0; i < data?.length; i++) {
            const record = data[i];

            const dateString = record[`CustomerInvoices.paidOn`];
            const date = moment(record[`CustomerInvoices.paidOn`]).toDate();

            summedData += record[`CustomerInvoices.totalpricewithouttax`];

            if (
              !storeData?.hasOwnProperty(record[`CustomerInvoices.storeid`])
            ) {
              storeData[record[`CustomerInvoices.storeid`]] = [];
            }

            storeData[record[`CustomerInvoices.storeid`]].push({
              date: moment(record[`CustomerInvoices.paidOn`]).toDate(),
              revenue: record[`CustomerInvoices.totalpricewithouttax`],
              granularity,
            });

            const storeSumsIDX = _.findIndex(storesSums, {
              _id: record[`CustomerInvoices.storeid`],
            });

            if (storeSumsIDX >= 0) {
              storesSums[storeSumsIDX].revenue +=
                record[`CustomerInvoices.totalpricewithouttax`];
            } else {
              storesSums.push({
                _id: record[`CustomerInvoices.storeid`],
                revenue: record[`CustomerInvoices.totalpricewithouttax`],
              });
            }

            storeData[record[`CustomerInvoices.storeid`]] = _.sortBy(
              storeData[record[`CustomerInvoices.storeid`]],
              "date"
            );

            const idx = _.findIndex(chartData, {
              dateString: record[`CustomerInvoices.paidOn`],
            });

            if (idx < 0) {
              chartData.push({
                dateString: record[`CustomerInvoices.paidOn`],
                revenue: record[`CustomerInvoices.totalpricewithouttax`],
                granularity,
                date: moment(record[`CustomerInvoices.paidOn`]).toDate(),
              });
            } else {
              chartData[idx].revenue +=
                record[`CustomerInvoices.totalpricewithouttax`];
            }

            chartData = _.sortBy(chartData, "date");

            if (!_.findWhere(intervals, { dateString })) {
              intervals.push({
                dateString,
                date,
                readable: this.getPeriodString({
                  dateString,
                  granularity,
                  date,
                }),
                granularity,
              });

              intervals = _.sortBy(intervals, "date");
            }
          }

          if (this.state.groupBy == "summary") {
            let storeKeys = _.keys(storeData);

            let newStoreData = {};

            for (let i = 0; i < storeKeys?.length; i++) {
              const key = storeKeys[i];

              let total = 0;

              for (let j = 0; j < storeData[key]?.length; j++) {
                total += storeData[key][j].revenue;
              }

              newStoreData[key] = {
                date: "",
                revenue: total,
                granularity: "summary",
              };
            }

            storeData = {
              summary: newStoreData,
              interval: storeData,
            };
          }

          storesSums = _.sortBy(storesSums, "revenue");
          storesSums.reverse();

          resolve({
            storeData,
            chartData,
            summedData,
            rawData: data,
            intervals,
            storesSums,
          });
        })
        .catch((e) => {
          console.error(e);

          reject(e);
        })
        .finally(() => {
          this.setState({
            loadingDataSubscription: false,
          });
        });
    });
  }

  generateCombinedData(intervals, subscriptionData, retailData) {
    let storeData = {};
    let chartData = [];
    let summedData = 0;
    let storesSums = [];

    let intervalKey = "day";

    if (this.state.groupBy == "weekly") {
      intervalKey = "week";
    }

    if (this.state.groupBy == "monthly") {
      intervalKey = "month";
    }

    if (this.state.groupBy == "quarterly") {
      intervalKey = "quarter";
    }

    if (this.state.groupBy == "yearly") {
      intervalKey = "year";
    }

    let granularity = intervalKey;

    if (subscriptionData?.storesSums?.length) {
      storesSums = subscriptionData?.storesSums?.map((st) => {
        return _.clone(st);
      });
    }

    if (retailData?.storesSums?.length) {
      for (let i = 0; i < retailData?.storesSums?.length; i++) {
        const item = retailData?.storesSums[i];

        const idx = _.findIndex(storesSums, { _id: item?._id });

        if (idx >= 0) {
          storesSums[idx].revenue += item?.revenue;
        } else {
          storesSums.push(item);
        }
      }
    }

    storesSums = _.sortBy(storesSums, "revenue");
    storesSums.reverse();

    if (intervals?.length == 1 && intervals[0].readable == "Summary") {
      let newIntervals = [];

      for (let i = 0; i < subscriptionData?.intervals?.length; i++) {
        if (
          !_.findWhere(newIntervals, {
            dateString: subscriptionData?.intervals[i]?.dateString,
          })
        ) {
          newIntervals.push(
            JSON.parse(JSON.stringify(subscriptionData?.intervals[i]))
          );
        }
      }

      for (let i = 0; i < retailData?.intervals?.length; i++) {
        if (
          !_.findWhere(newIntervals, {
            dateString: retailData?.intervals[i]?.dateString,
          })
        ) {
          newIntervals.push(retailData?.intervals[i]);
        }
      }

      newIntervals = _.sortBy(newIntervals, "date");

      intervals = newIntervals;
    }

    for (let i = 0; i < intervals?.length; i++) {
      const interval = intervals[i];

      const retailRecords = _.filter(retailData?.rawData, {
        "SquareOrders.closedat": interval?.dateString,
      });

      const subscriptionRecords = _.filter(subscriptionData?.rawData, {
        "CustomerInvoices.paidOn": interval?.dateString,
      });

      let dateString = "";
      let date = null;
      let recordSum = 0;

      for (let j = 0; j < retailRecords?.length; j++) {
        const retailRecord = retailRecords[j];
        let storeID = "";

        if (retailRecord) {
          dateString = retailRecord[`SquareOrders.closedat`];
          date = moment(retailRecord[`SquareOrders.closedat`]).toDate();

          summedData += retailRecord[`SquareOrders.plnNetAmount`];

          if (retailRecord[`SquareOrders.storeid`]) {
            storeID = retailRecord[`SquareOrders.storeid`];
          }

          if (storeID) {
            if (!storeData?.hasOwnProperty(storeID)) {
              storeData[storeID] = [];
            }

            let idx2 = _.findIndex(storeData[storeID], (val) => {
              return val?.date?.toISOString() == date?.toISOString();
            });

            if (idx2 >= 0) {
              storeData[storeID][idx2].revenue +=
                retailRecord[`SquareOrders.plnNetAmount`];
            } else {
              storeData[storeID].push({
                date: date,
                revenue: retailRecord[`SquareOrders.plnNetAmount`],
                granularity,
                storeID,
              });
            }

            storeData[storeID] = _.sortBy(storeData[storeID], "date");
          }

          const idx = _.findIndex(chartData, {
            dateString: dateString,
          });

          if (idx < 0) {
            chartData.push({
              dateString: dateString,
              revenue: retailRecord[`SquareOrders.plnNetAmount`],
              granularity,
              date: date,
            });
          } else {
            chartData[idx].revenue += retailRecord[`SquareOrders.plnNetAmount`];
          }

          chartData = _.sortBy(chartData, "date");

          if (!_.findWhere(intervals, { dateString })) {
            intervals.push({
              dateString,
              date,
              readable: this.getPeriodString({
                dateString,
                granularity,
                date,
              }),
              granularity,
            });

            intervals = _.sortBy(intervals, "date");
          }
        }
      }

      for (let j = 0; j < subscriptionRecords?.length; j++) {
        const subscriptionRecord = subscriptionRecords[j];
        let storeID = "";

        if (subscriptionRecord) {
          if (!dateString && subscriptionRecord[`CustomerInvoices.paidOn`]) {
            dateString = subscriptionRecord[`CustomerInvoices.paidOn`];
          }

          if (!date && subscriptionRecord[`CustomerInvoices.paidOn`]) {
            date = moment(
              subscriptionRecord[`CustomerInvoices.paidOn`]
            ).toDate();
          }

          summedData +=
            subscriptionRecord[`CustomerInvoices.totalpricewithouttax`];
          recordSum +=
            subscriptionRecord[`CustomerInvoices.totalpricewithouttax`];

          if (subscriptionRecord[`CustomerInvoices.storeid`]) {
            storeID = subscriptionRecord[`CustomerInvoices.storeid`];
          }

          if (storeID) {
            if (!storeData?.hasOwnProperty(storeID)) {
              storeData[storeID] = [];
            }

            let idx2 = _.findIndex(storeData[storeID], (val) => {
              return val?.date?.toString() == date?.toString();
            });

            if (idx2 >= 0) {
              storeData[storeID][idx2].revenue +=
                subscriptionRecord[`CustomerInvoices.totalpricewithouttax`];
            } else {
              storeData[storeID].push({
                date: date,
                revenue:
                  subscriptionRecord[`CustomerInvoices.totalpricewithouttax`],
                granularity,
                storeID,
              });
            }

            storeData[storeID] = _.sortBy(storeData[storeID], "date");
          }

          const idx = _.findIndex(chartData, {
            dateString: dateString,
          });

          if (idx < 0) {
            chartData.push({
              dateString: dateString,
              revenue:
                subscriptionRecord[`CustomerInvoices.totalpricewithouttax`],
              granularity,
              date: date,
            });
          } else {
            chartData[idx].revenue +=
              subscriptionRecord[`CustomerInvoices.totalpricewithouttax`];
          }

          chartData = _.sortBy(chartData, "date");

          if (!_.findWhere(intervals, { dateString })) {
            intervals.push({
              dateString,
              date,
              readable: this.getPeriodString({
                dateString,
                granularity,
                date,
              }),
              granularity,
            });

            intervals = _.sortBy(intervals, "date");
          }
        }
      }
    }

    // TODO: fix the store data total revenue -> looks like its much higher

    if (this.state.groupBy == "summary") {
      let storeKeys = _.keys(storeData);

      let newStoreData = {};

      for (let i = 0; i < storeKeys?.length; i++) {
        const key = storeKeys[i];

        let total = 0;

        for (let j = 0; j < storeData[key]?.length; j++) {
          total += storeData[key][j].revenue;
        }

        newStoreData[key] = {
          date: "",
          revenue: total,
          granularity: "summary",
        };
      }

      storeData = {
        summary: newStoreData,
        interval: storeData,
      };
    }

    return {
      summedData,
      chartData,
      storeData,
      storesSums,
    };
  }

  loadRetailData(cubejsApi) {
    this.setState({
      loadingDataRetail: true,
    });

    let intervals = [];

    let startDate = moment(this.state.dateRange[0]?.toISOString()).format(
      "YYYY-MM-DD"
    );
    let endDate = moment(this.state.dateRange[1]?.toISOString()).format(
      "YYYY-MM-DD"
    );

    let granularity = "day";

    if (this.state.groupBy == "weekly") {
      granularity = "week";
    }

    if (this.state.groupBy == "monthly") {
      granularity = "month";
    }

    if (this.state.groupBy == "quarterly") {
      granularity = "quarter";
    }

    if (this.state.groupBy == "yearly") {
      granularity = "year";
    }

    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["SquareOrders.plnNetAmount"],
          order: {
            "Customers.closedat": "asc",
          },
          dimensions: ["SquareOrders.storeid"],
          timeDimensions: [
            {
              dimension: "SquareOrders.closedat",
              granularity: granularity,
              dateRange: [startDate, endDate],
            },
          ],
          filters: [
            {
              member: "SquareOrders.storeid",
              operator: "equals",
              values: this.state.selectedStores,
            },
            {
              member: "SquareOrders.state",
              operator: "equals",
              values: ["COMPLETED"],
            },
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          let storeData = {};
          let chartData = [];
          let summedData = 0;
          let storesSums = [];

          let intervalKey = "day";

          if (this.state.groupBy == "weekly") {
            intervalKey = "week";
          }

          if (this.state.groupBy == "monthly") {
            intervalKey = "month";
          }

          if (this.state.groupBy == "quarterly") {
            intervalKey = "quarter";
          }

          if (this.state.groupBy == "yearly") {
            intervalKey = "year";
          }

          for (let i = 0; i < data?.length; i++) {
            const record = data[i];

            const dateString = record[`SquareOrders.closedat`];
            const date = moment(record[`SquareOrders.closedat`]).toDate();

            summedData += record[`SquareOrders.plnNetAmount`];

            if (!storeData?.hasOwnProperty(record[`SquareOrders.storeid`])) {
              storeData[record[`SquareOrders.storeid`]] = [];
            }

            storeData[record[`SquareOrders.storeid`]].push({
              date: moment(record[`SquareOrders.closedat`]).toDate(),
              revenue: record[`SquareOrders.plnNetAmount`],
              granularity,
            });

            storeData[record[`SquareOrders.storeid`]] = _.sortBy(
              storeData[record[`SquareOrders.storeid`]],
              "date"
            );

            const storeSumsIDX = _.findIndex(storesSums, {
              _id: record[`SquareOrders.storeid`],
            });

            if (storeSumsIDX >= 0) {
              storesSums[storeSumsIDX].revenue +=
                record[`SquareOrders.plnNetAmount`];
            } else {
              storesSums.push({
                _id: record[`SquareOrders.storeid`],
                revenue: record[`SquareOrders.plnNetAmount`],
              });
            }

            const idx = _.findIndex(chartData, {
              dateString: record[`SquareOrders.closedat`],
            });

            if (idx < 0) {
              chartData.push({
                dateString: record[`SquareOrders.closedat`],
                revenue: record[`SquareOrders.plnNetAmount`],
                granularity,
                date: moment(record[`SquareOrders.closedat`]).toDate(),
              });
            } else {
              chartData[idx].revenue += record[`SquareOrders.plnNetAmount`];
            }

            chartData = _.sortBy(chartData, "date");

            if (!_.findWhere(intervals, { dateString })) {
              intervals.push({
                dateString,
                date,
                readable: this.getPeriodString({
                  dateString,
                  granularity,
                  date,
                }),
                granularity,
              });

              intervals = _.sortBy(intervals, "date");
            }
          }

          if (this.state.groupBy == "summary") {
            let storeKeys = _.keys(storeData);

            let newStoreData = {};

            for (let i = 0; i < storeKeys?.length; i++) {
              const key = storeKeys[i];

              let total = 0;

              for (let j = 0; j < storeData[key]?.length; j++) {
                total += storeData[key][j].revenue;
              }

              newStoreData[key] = {
                date: "",
                revenue: total,
                granularity: "summary",
              };
            }

            storeData = {
              summary: newStoreData,
              interval: storeData,
            };
          }

          storesSums = _.sortBy(storesSums, "revenue");
          storesSums.reverse();

          resolve({
            storeData,
            chartData,
            summedData,
            rawData: data,
            intervals,
            storesSums,
          });
        })
        .catch((e) => {
          console.error(e);

          reject(e);
        })
        .finally(() => {
          this.setState({
            loadingDataRetail: false,
          });
        });
    });
  }

  setURLParameters() {
    // Construct URLSearchParams object instance from current URL querystring.
    let queryParams = new URLSearchParams(window.location.search);

    if (this.state.dateRange?.length == 2) {
      let startDate = this.state.dateRange[0]?.toISOString();
      let endDate = this.state.dateRange[1]?.toISOString();

      // Set new or modify existing parameter value.
      queryParams.set("startDate", startDate);
      // Set new or modify existing parameter value.
      queryParams.set("endDate", endDate);
    }

    if (this.state.groupBy) {
      queryParams.set("groupBy", this.state.groupBy);
    }

    if (this.state.revenueSource) {
      queryParams.set("revenueSource", this.state.revenueSource);
    }

    if (this.state.selectedStores?.length) {
      queryParams.set("stores", this.state.selectedStores.join(","));
    }

    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );
  }

  readURLParameters() {
    let queryParams = new URLSearchParams(window.location.search);

    let state = {};

    if (queryParams.get("startDate") && queryParams.get("endDate")) {
      let startDate = moment(queryParams.get("startDate")).toDate();
      let endDate = moment(queryParams.get("endDate")).toDate();
      state.dateRange = [startDate, endDate];
    }

    if (
      queryParams.get("groupBy") &&
      _.findWhere(this.state.groupByOptions, {
        value: queryParams.get("groupBy"),
      })
    ) {
      state.groupBy = queryParams.get("groupBy");
    }

    if (
      queryParams.get("revenueSource") &&
      _.findWhere(this.state.revenueSourceOptions, {
        value: queryParams.get("revenueSource"),
      })
    ) {
      state.revenueSource = queryParams.get("revenueSource");
    }

    if (queryParams.get("stores")) {
      let storeIDs = queryParams.get("stores").split(",");

      if (storeIDs?.length) {
        state.selectedStores = storeIDs;
      }
    }

    this.setState(state);
  }

  async loadData() {
    this.setState({
      loadingData: true,
    });

    this.setURLParameters();

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    try {
      let subscriptionData = {};
      let retailData = {};
      let intervals = [];

      let storesSums = [];

      if (
        this.state.revenueSource == "all" ||
        this.state.revenueSource == "subscription"
      ) {
        subscriptionData = await this.loadSubscriptionData(cubejsApi);

        for (let i = 0; i < subscriptionData?.intervals?.length; i++) {
          if (
            !_.findWhere(intervals, {
              readable: subscriptionData?.intervals[i]?.readable,
            })
          ) {
            intervals.push(subscriptionData?.intervals[i]);
          }
        }

        intervals = _.sortBy(intervals, "date");

        storesSums = subscriptionData?.storesSums;

        this.setState({
          subscriptionData,
          intervals,
        });
      }

      if (
        this.state.revenueSource == "all" ||
        this.state.revenueSource == "retail"
      ) {
        retailData = await this.loadRetailData(cubejsApi);

        for (let i = 0; i < retailData?.intervals?.length; i++) {
          if (
            !_.findWhere(intervals, {
              readable: retailData?.intervals[i]?.readable,
            })
          ) {
            intervals.push(retailData?.intervals[i]);
          }
        }

        intervals = _.sortBy(intervals, "date");

        storesSums = retailData?.storesSums;

        if (this.state.revenueSource == "all") {
          let combinedData = this.generateCombinedData(
            intervals,
            subscriptionData,
            retailData
          );

          storesSums = combinedData?.storesSums;

          this.setState({
            combinedData,
          });
        }

        let reportedStores = _.filter(this.state.selectedStores, (st) => {
          return _.findWhere(storesSums, { _id: st });
        });

        this.setState({
          subscriptionData,
          retailData,
          intervals,
          reportedStores,
          storesSums,
        });
      }

      this.setState({
        loadingData: false,
      });
    } catch (e) {
      console.error(e);

      // TODO: set error state here
    }
  }

  getDataForSource() {
    if (!this.state.revenueSource) {
      return null;
    }

    return {
      combinedData: this.state.combinedData,
      retailData: this.state.retailData,
      subscriptionData: this.state.subscriptionData,
      revenueSource: this.state.revenueSource,
    };
  }

  renderPeriodCells(storeID, i, revenue) {
    let storeData = [];

    if (this.state.groupBy == "summary") {
      if (this.state.revenueSource == "all") {
        storeData =
          this.state?.combinedData?.storeData?.summary?.hasOwnProperty(storeID)
            ? this.state?.combinedData?.storeData.summary[storeID]
            : [];
      } else if (this.state.revenueSource == "subscription") {
        storeData =
          this.state?.subscriptionData?.storeData?.summary?.hasOwnProperty(
            storeID
          )
            ? this.state?.subscriptionData?.storeData.summary[storeID]
            : [];
      } else if (this.state.revenueSource == "retail") {
        storeData = this.state?.retailData?.storeData?.summary?.hasOwnProperty(
          storeID
        )
          ? this.state?.retailData?.storeData.summary[storeID]
          : [];
      }
    } else {
      if (this.state.revenueSource == "all") {
        storeData = this.state?.combinedData?.storeData?.hasOwnProperty(storeID)
          ? this.state?.combinedData?.storeData[storeID]
          : [];
      } else if (this.state.revenueSource == "subscription") {
        storeData = this.state?.subscriptionData?.storeData?.hasOwnProperty(
          storeID
        )
          ? this.state?.subscriptionData?.storeData[storeID]
          : [];
      } else if (this.state.revenueSource == "retail") {
        storeData = this.state?.retailData?.storeData?.hasOwnProperty(storeID)
          ? this.state?.retailData?.storeData[storeID]
          : [];
      }
    }

    // TODO: handle fetch data
    if (this.state.groupBy == "summary") {
      if (!storeData) {
        return (
          <div
            key={storeID}
            style={{ height: 53 }}
            className={`${
              i != this.state.selectedStores?.length - 1 && "border-bottom"
            } ${i % 2 == 0 && "bg-primary-table-striped"} text-right`}
          >
            <div
              className="px-3"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 53,
              }}
            >
              <p className="m-0 text-dark">--</p>
            </div>
          </div>
        );
      }

      return (
        <div
          key={storeID}
          style={{ height: 53, whiteSpace: "nowrap" }}
          className={`${
            i != this.state.selectedStores?.length - 1 && "border-bottom"
          } ${i % 2 == 0 && "bg-primary-table-striped"} text-right`}
        >
          <div
            className="px-3"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 53,
            }}
          >
            <p className="m-0 text-dark">
              {StringUtils.centsToCurrency(
                storeData?.revenue ? storeData?.revenue * 100 : 0
              )}
            </p>
          </div>
        </div>
      );
    }

    let intervals = this.state.intervals;

    let width = 150;

    if (intervals[0].granularity == "week") {
      width = 250;
    }

    if (intervals[0].granularity == "month") {
      width = 220;
    }

    const getDataForInterval = (interval) => {
      if (interval?.isTotal) {
        return {
          revenue: interval?.total,
        };
      } else {
        const val = _.find(storeData, (record) => {
          return record?.date?.toISOString() == interval?.date?.toISOString();
        });

        if (val) {
          return val;
        }

        return null;
      }
    };

    // TODO: fix this

    return (
      <>
        <div
          style={{ height: 53, whiteSpace: "nowrap" }}
          className={` d-flex ${
            i != this.state.selectedStores?.length - 1 && "border-bottom"
          }  text-right`}
        >
          {intervals?.map((int, k) => (
            <div
              style={{
                height: "53px",
                width: width,
                flexGrow: "unset",
                minWidth: width,
              }}
              className={`text-dark text-center border-bottom border-right ${
                i % 2 == 0 && "bg-primary-table-striped"
              }`}
            >
              <div
                className="px-3"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 53,
                }}
              >
                <p className="m-0 ">
                  {getDataForInterval(int)
                    ? StringUtils.centsToCurrency(
                        getDataForInterval(int)?.revenue
                          ? getDataForInterval(int)?.revenue * 100
                          : 0
                      )
                    : "--"}
                </p>
              </div>
            </div>
          ))}
          <div
            style={{
              height: "53px",
              width: `auto`,
              flexGrow: 1,
              minWidth: width,
            }}
            className={`text-dark text-center border-bottom ${
              i % 2 == 0 && "bg-primary-table-striped"
            }`}
          >
            <div
              className="px-3"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 53,
              }}
            >
              <p className="m-0 font-weight-bold">
                {StringUtils.centsToCurrency(revenue ? revenue * 100 : 0)}
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }

  exportStoreData(storeID, i) {
    let storeData = [];

    if (this.state.groupBy == "summary") {
      if (this.state.revenueSource == "all") {
        storeData =
          this.state?.combinedData?.storeData?.summary?.hasOwnProperty(storeID)
            ? this.state?.combinedData?.storeData.summary[storeID]
            : [];
      } else if (this.state.revenueSource == "subscription") {
        storeData =
          this.state?.subscriptionData?.storeData?.summary?.hasOwnProperty(
            storeID
          )
            ? this.state?.subscriptionData?.storeData.summary[storeID]
            : [];
      } else if (this.state.revenueSource == "retail") {
        storeData = this.state?.retailData?.storeData?.summary?.hasOwnProperty(
          storeID
        )
          ? this.state?.retailData?.storeData.summary[storeID]
          : [];
      }
    } else {
      if (this.state.revenueSource == "all") {
        storeData = this.state?.combinedData?.storeData?.hasOwnProperty(storeID)
          ? this.state?.combinedData?.storeData[storeID]
          : [];
      } else if (this.state.revenueSource == "subscription") {
        storeData = this.state?.subscriptionData?.storeData?.hasOwnProperty(
          storeID
        )
          ? this.state?.subscriptionData?.storeData[storeID]
          : [];
      } else if (this.state.revenueSource == "retail") {
        storeData = this.state?.retailData?.storeData?.hasOwnProperty(storeID)
          ? this.state?.retailData?.storeData[storeID]
          : [];
      }
    }

    let store = _.findWhere(this.state.stores, { _id: storeID });

    // TODO: handle fetch data
    if (this.state.groupBy == "summary") {
      if (!storeData) {
        return `"${store?.name}",""`;
      }

      return `"${store?.name}","${StringUtils.centsToCurrency(
        storeData?.revenue ? storeData?.revenue * 100 : 0
      )}"`;
    }

    let intervals = this.state.intervals;

    const getDataForInterval = (interval) => {
      const val = _.find(storeData, (record) => {
        return record?.date?.toISOString() == interval?.date?.toISOString();
      });

      if (val) {
        return val;
      }

      return null;
    };

    // TODO: fix this

    let out = `"${store?.name}",`;

    let intervalData = intervals?.map((int, k) => {
      return getDataForInterval(int)
        ? `"${StringUtils.centsToCurrency(
            getDataForInterval(int)?.revenue
              ? getDataForInterval(int)?.revenue * 100
              : 0
          )}"`
        : `""`;
    });

    return out + intervalData.join(",");
  }

  selectTier(name) {
    let tierStores = _.filter(this.state.stores, (st) => {
      return st.supportLevel == name;
    });

    console.log(tierStores);

    let tierStoreIDs = tierStores.map((st) => {
      return st._id;
    });

    this.setState({
      pendingSelectedStores: tierStoreIDs,
    });
  }

  isTier(name) {
    let selectedStores = this.state.pendingSelectedStores;

    if (!selectedStores?.length) {
      return false;
    }

    let tierStores = _.filter(this.state.stores, (st) => {
      return st.supportLevel == name;
    });

    let tierStoreIDs = tierStores.map((st) => {
      return st._id;
    });

    if (_.difference(selectedStores, tierStoreIDs).length) {
      return false;
    }

    return true;
  }

  getTotal(data) {
    if (
      this.state.loadingStores ||
      this.state.loadingDataSubscription ||
      this.state.loadingDataRetail
    ) {
      return <div style={{ height: 63 }} className="skeleton"></div>;
    }

    if (this.state.dateRange?.length != 2) {
      return (
        <>
          <div style={{ height: 63 }}>
            <h1 className="m-0 text-dark">--</h1>
            <p className="small text-muted mb-0 mt-2 font-weight-500">
              Select A Date Range
            </p>
          </div>
        </>
      );
    }

    let val = null;

    let source = data?.combinedData;

    if (this.state.revenueSource == "all") {
      source = data?.combinedData;
    }

    if (this.state.revenueSource == "subscription") {
      source = data?.subscriptionData;
    }

    if (this.state.revenueSource == "retail") {
      source = data?.retailData;
    }

    let start = moment(this.state.dateRange[0]?.toISOString()).format(
      "MM/DD/YYYY"
    );
    let end = moment(this.state.dateRange[1]?.toISOString()).format(
      "MM/DD/YYYY"
    );

    if (source?.summedData === null || source?.summedData === undefined) {
      return (
        <>
          <div style={{ height: 63 }}>
            <h1 className="m-0 text-dark">--</h1>
            <p className="small text-muted mb-0 mt-2 font-weight-500">
              From {start} - {end}
            </p>
          </div>
        </>
      );
    }

    return (
      <>
        <div style={{ height: 63 }}>
          <h1 className="m-0 text-dark">
            {StringUtils.centsToCurrency(source?.summedData * 100)}
          </h1>
          <p className="small text-muted mb-0 mt-2 font-weight-500">
            From {start} - {end}
          </p>
        </div>
      </>
    );
  }

  generateFileName() {
    let source = "";

    if (this.state?.revenueSource == "all") {
      source = "All Sales";
    } else if (this.state?.revenueSource == "subscription") {
      source = "Subscription Sales";
    } else if (this.state?.revenueSource == "retail") {
      source = "Net Retail Sales";
    }

    let mode = "By Store";

    let range = "";

    if (this.state.dateRange?.length == 2) {
      range = `${moment(this.state?.dateRange[0]?.toISOString()).format(
        "MM-DD-YYYY"
      )}-${moment(this.state?.dateRange[1]?.toISOString()).format(
        "MM-DD-YYYY"
      )}`;
    }

    return `${source} ${mode} ${range}.csv`;
  }

  exportDataToCSV() {
    let out = [this.generateExportHeaders()];

    for (let i = 0; i < this.state.selectedStores?.length; i++) {
      out.push(this.exportStoreData(this.state.selectedStores[i]));
    }

    let str = out.join("\r\n");

    let link = document.createElement("a");
    link.download = this.generateFileName();
    link.href = `data:text/csv;charset=utf-8,${str}`;
    link.click();
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeader title="Sales Summary" showBackButton={false} />
          {/* Table */}
          <div className="border-bottom pb-3 mt--2 mb-3">
            <Row className="align-items-center">
              <Col xs="12" sm="6" lg="auto" className="pr-sm-1">
                <div className="reports-date-picker">
                  <PLNDateRangePicker
                    value={this.state.dateRange}
                    onChange={(v) => {
                      this.setState({ dateRange: v }, () => {
                        this.loadData();
                      });

                      if (v?.length == 2 && v[0] && v[1]) {
                        localStorage.setItem(
                          "salesSummary_dateRange",
                          JSON.stringify({
                            startDate: v[0]?.toISOString(),
                            endDate: v[1]?.toISOString(),
                          })
                        );
                      }
                    }}
                  ></PLNDateRangePicker>
                </div>
              </Col>
              <Col
                xs="12"
                sm="6"
                lg="auto"
                className="mt-3 mt-sm-0 pl-sm-1 pr-lg-1"
              >
                <div className="reports-store-picker">
                  <CheckPicker
                    block
                    ref={this.storePickerRef}
                    cleanable={true}
                    label="Stores"
                    groupBy="category"
                    data={this.state.storeOptions}
                    placement="autoVertical"
                    preventOverflow={true}
                    placeholder="Select Stores"
                    sort={(isGroup) => {
                      if (isGroup) {
                        return (a, b) => {
                          if (b.groupTitle == "Live") {
                            return 1;
                          }

                          if (b.groupTitle == "Prelaunch") {
                            if (a.groupTitle == "Live") {
                              return -1;
                            }

                            return 1;
                          }

                          if (b.groupTitle == "Buildout") {
                            if (
                              a.groupTitle == "Live" ||
                              a.groupTitle == "Prelaunch"
                            ) {
                              return -1;
                            }

                            return 1;
                          }

                          if (b.groupTitle == "Site Selection") {
                            if (
                              a.groupTitle == "Live" ||
                              a.groupTitle == "Prelaunch" ||
                              a.groupTitle == "Buildout"
                            ) {
                              return -1;
                            }

                            return 1;
                          }

                          return a.groupTitle < b.groupTitle ? -1 : 1;
                        };
                      }

                      return (a, b) => {
                        return a.label < b.label ? -1 : 1;
                      };
                    }}
                    value={this.state.pendingSelectedStores}
                    onChange={this.onChangeStores.bind(this)}
                    onClose={() => {
                      if (
                        JSON.stringify(this.state.pendingSelectedStores) !=
                        JSON.stringify(this.state.selectedStores)
                      ) {
                        this.onApplyChangeStores();
                      }
                    }}
                    renderExtraFooter={() => (
                      <div className="pr-2 py-2 border-top">
                        <div className="pl-2" style={{ maxWidth: 291 }}>
                          <div className="small text-dark font-weight-bold text-center mb-2">
                            Quick Select
                          </div>
                          <Row className="align-items-center">
                            <Col xs="4" className="pr-1">
                              <Button
                                block
                                color={
                                  this.isTier("Impact Level")
                                    ? "primary"
                                    : "dark"
                                }
                                outline={
                                  this.isTier("Impact Level") ? false : true
                                }
                                size="sm"
                                onClick={() => {
                                  this.selectTier("Impact Level");
                                }}
                              >
                                Impact ({this.countTier("Impact Level")})
                              </Button>
                            </Col>
                            <Col xs="4" className="pl-1 pr-1">
                              <Button
                                block
                                color={
                                  this.isTier("Growth") ? "primary" : "dark"
                                }
                                outline={this.isTier("Growth") ? false : true}
                                size="sm"
                                onClick={() => {
                                  this.selectTier("Growth");
                                }}
                              >
                                Growth ({this.countTier("Growth")})
                              </Button>
                            </Col>
                            <Col xs="4" className="pl-1 mt-1">
                              <Button
                                block
                                color={
                                  this.isTier("Critical Level")
                                    ? "primary"
                                    : "dark"
                                }
                                outline={
                                  this.isTier("Critical Level") ? false : true
                                }
                                size="sm"
                                onClick={() => {
                                  this.selectTier("Critical Level");
                                }}
                              >
                                Critical ({this.countTier("Critical Level")})
                              </Button>
                            </Col>
                            <Col xs="12" className="mt-2">
                              <Button
                                block
                                color={
                                  this.isTier("Post-Open Level")
                                    ? "primary"
                                    : "dark"
                                }
                                outline={
                                  this.isTier("Post-Open Level") ? false : true
                                }
                                size="sm"
                                onClick={() => {
                                  this.selectTier("Post-Open Level");
                                }}
                              >
                                Post-Open ({this.countTier("Post-Open Level")})
                              </Button>
                            </Col>
                          </Row>
                        </div>
                        <hr className="my-2"></hr>
                        <Row className="align-items-center">
                          <Col xs="">
                            <Checkbox
                              className="ml-1"
                              inline
                              indeterminate={
                                this.state.pendingSelectedStores?.length > 0 &&
                                this.state.pendingSelectedStores?.length <
                                  this.state.storeOptions?.length
                              }
                              checked={
                                this.state.pendingSelectedStores?.length ===
                                this.state.storeOptions?.length
                              }
                              onChange={this.onSelectAllStores.bind(this)}
                            >
                              All Stores
                            </Checkbox>
                          </Col>
                          <Col xs="auto">
                            <Button
                              color="dark"
                              outline
                              size="sm"
                              onClick={() => {
                                this.onCancelChangeStores();

                                this.storePickerRef.current.close();
                              }}
                            >
                              Cancel
                            </Button>

                            <Button
                              color="primary"
                              size="sm"
                              onClick={() => {
                                this.storePickerRef.current.close();
                              }}
                            >
                              Apply
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    )}
                  ></CheckPicker>
                </div>
              </Col>
              <Col
                xs="6"
                sm="6"
                lg="auto"
                className="mt-3 mt-lg-0 pl-lg-1 pr-1"
              >
                <div className="reports-display-picker">
                  <SelectPicker
                    block
                    cleanable={false}
                    searchable={false}
                    data={this.state.groupByOptions}
                    placement="autoVertical"
                    preventOverflow={true}
                    placeholder="Display"
                    value={this.state.groupBy}
                    onChange={this.onChangeGroupBy.bind(this)}
                  ></SelectPicker>
                </div>
              </Col>
              <Col xs="6" sm="6" lg="auto" className="mt-3 mt-lg-0 pl-1">
                <div className="reports-display-picker">
                  <SelectPicker
                    block
                    cleanable={false}
                    searchable={false}
                    data={this.state.revenueSourceOptions}
                    placement="autoVertical"
                    preventOverflow={true}
                    placeholder="Revenue Source"
                    value={this.state.revenueSource}
                    onChange={this.onChangeRevenueSource.bind(this)}
                  ></SelectPicker>
                </div>
              </Col>
            </Row>
          </div>
          <div className="mb-3">
            {this.state.loadingStores ? (
              <>
                <h1 className="skeleton" style={{ width: 280 }}>
                  &nbsp;
                </h1>
              </>
            ) : (
              <>
                <h1>{this.renderDateRange()}</h1>
              </>
            )}
          </div>
          <div>
            {!this.hasValidDateRange() && (
              <>
                <Card className="border">
                  <CardBody className="text-center">
                    <h3>Select Date Range</h3>
                    <p className="m-0">
                      Select a date range to view the sales summary.
                    </p>
                  </CardBody>
                </Card>
              </>
            )}
            {this.hasValidDateRange() && (
              <>
                <div className="mb-3">
                  <Row>
                    <Col
                      xs="12"
                      sm={12}
                      md={this.state.pieExpanded ? 6 : 7}
                      lg={this.state.pieExpanded ? 6 : 8}
                      className="pr-md-2"
                    >
                      <div>
                        <h3>{this.renderSummaryExplanation()}</h3>
                        {this.state.selectedStores?.length ? (
                          <SalesSummaryTrendlineChart
                            data={this.getDataForSource()}
                            loading={
                              this.state.loadingStores ||
                              this.state.loadingDataSubscription ||
                              this.state.loadingDataRetail
                            }
                            groupBy={this.state.groupBy}
                            stores={this.state.stores}
                            dateRange={this.state.dateRange}
                          ></SalesSummaryTrendlineChart>
                        ) : (
                          <Card className="border shadow">
                            <CardHeader className="p-3">
                              <Button size="sm" color="dark" outline disabled>
                                By Revenue Source
                              </Button>
                              <Button size="sm" color="dark" outline disabled>
                                By Store
                              </Button>
                            </CardHeader>
                            <CardBody className="p-2">
                              <div
                                style={{ height: 260 }}
                                className="text-center"
                              >
                                <div
                                  style={{
                                    position: "relative",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                  }}
                                >
                                  <h4>Select Stores</h4>
                                  <p className="m-0">
                                    Select one or more stores to view sales
                                    summary
                                  </p>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        )}
                      </div>
                    </Col>
                    <Col
                      xs="12"
                      sm={12}
                      md={this.state.pieExpanded ? 6 : 5}
                      lg={this.state.pieExpanded ? 6 : 4}
                      className="pl-md-2 mt-3 mt-md-0"
                    >
                      <div style={{ height: "100%" }}>
                        {!this.state.pieExpanded && (
                          <>
                            <h3>Total Revenue</h3>
                            <Card className="border shadow border-lighter mb-3">
                              <CardBody className="text-center p-3">
                                {this.getTotal(this.getDataForSource())}
                              </CardBody>
                            </Card>
                          </>
                        )}

                        <h3>Revenue Distribution</h3>
                        <SalesSummaryPieChart
                          data={this.getDataForSource()}
                          loading={
                            this.state.loadingStores ||
                            this.state.loadingDataSubscription ||
                            this.state.loadingDataRetail
                          }
                          groupBy={this.state.groupBy}
                          stores={this.state.stores}
                          onExpandChange={(val) => {
                            this.setState({ pieExpanded: val });
                          }}
                          dateRange={this.state.dateRange}
                        ></SalesSummaryPieChart>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row className="align-items-center mb-2">
                    <Col xs="">
                      <h3 className="m-0">By Store</h3>
                    </Col>
                    <Col xs="auto">
                      <Button
                        size="sm"
                        outline
                        color="dark"
                        onClick={() => {
                          this.exportDataToCSV();
                        }}
                      >
                        Export
                      </Button>
                    </Col>
                  </Row>
                  {this.state.loadingStores ? (
                    <>
                      <Card className="border shadow">
                        <CardBody className="text-center">
                          <Spinner color="dark"></Spinner>
                        </CardBody>
                      </Card>
                    </>
                  ) : (
                    <>
                      {this.state.selectedStores?.length ? (
                        <>
                          <Card className="border shadow">
                            <CardBody
                              className="p-0"
                              style={{
                                height:
                                  (this.state.reportedStores?.length + 1) * 53,
                              }}
                            >
                              <div
                                className="d-inline-block border-right border-lighter"
                                style={{
                                  width: 220,
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  height:
                                    (this.state.reportedStores?.length + 1) *
                                    53,
                                }}
                              >
                                <div
                                  className="border-bottom"
                                  style={{
                                    height: "53px",
                                  }}
                                >
                                  <div
                                    className="px-3"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      height: 53,
                                    }}
                                  >
                                    <p className="m-0 text-truncate text-dark font-weight-bold">
                                      Store
                                    </p>
                                  </div>
                                </div>
                                {this.state.storesSums?.map((store, i) => (
                                  <div
                                    key={store?._id}
                                    style={{ height: 53 }}
                                    className={`${
                                      i != this.state.storesSums?.length - 1 &&
                                      "border-bottom"
                                    } ${
                                      i % 2 == 0 && "bg-primary-table-striped"
                                    }`}
                                  >
                                    <div
                                      className="d-inline-block"
                                      style={{
                                        width: 220,
                                        whiteSpace: "nowrap",
                                        overflowX: "scroll",
                                        overflowY: "hidden",
                                        height: "53px",
                                      }}
                                    >
                                      <div className="text-truncate px-3 py-2 border-right overflow-hidden">
                                        <div>
                                          <p
                                            style={{ lineHeight: 1.3 }}
                                            className="m-0 cursor-pointer text-truncate text-dark font-weight-bold"
                                          >
                                            {
                                              this.getStoreByIDFromData(
                                                store?._id
                                              )?.name
                                            }
                                            {this.getStoreByIDFromData(
                                              store?._id
                                            )?.level ? (
                                              <Badge
                                                color=""
                                                className="ml-2 border border-dark py-1 bg-transparent text-dark"
                                                style={{
                                                  position: "relative",
                                                  top: -2,
                                                }}
                                              >
                                                {this.getStoreByIDFromData(
                                                  store?._id
                                                )?.supportLevel?.replace(
                                                  "Level",
                                                  ""
                                                )}
                                              </Badge>
                                            ) : null}
                                          </p>
                                          <p
                                            style={{ lineHeight: 1.2 }}
                                            className="m-0 cursor-pointer text-truncate text-muted small font-weight-500"
                                          >
                                            {this.getStoreByIDFromData(
                                              store?._id
                                            )?.type == "retail" ? (
                                              <>
                                                {
                                                  this.getStoreByIDFromData(
                                                    store?._id
                                                  )?.location?.address?.city
                                                }
                                                ,&nbsp;
                                                {
                                                  this.getStoreByIDFromData(
                                                    store?._id
                                                  )?.location?.address?.state
                                                }
                                              </>
                                            ) : (
                                              "(online only)"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <div
                                style={{
                                  width: "calc(100% - 220px)",
                                  height:
                                    (this.state.storesSums?.length + 1) * 53,
                                  overflow: "scroll",
                                }}
                                className="d-inline-block text-dark"
                              >
                                {this.state.loadingData ? (
                                  <>
                                    <div
                                      className="text-center"
                                      style={{
                                        position: "relative",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                      }}
                                    >
                                      <Spinner color="dark"></Spinner>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {/** Use this component for period of time headers */}
                                    <div
                                      style={{
                                        whiteSpace: "nowrap",
                                        height: 53,
                                      }}
                                    >
                                      {this.renderPeriodHeaderCells()}
                                    </div>
                                    {this.state.storesSums?.map((store, i) => (
                                      <>
                                        {this.renderPeriodCells(
                                          store?._id,
                                          i,
                                          store?.revenue
                                        )}
                                      </>
                                    ))}
                                  </>
                                )}
                              </div>
                            </CardBody>
                          </Card>
                        </>
                      ) : (
                        <>
                          <Card className="border">
                            <CardBody className="text-center">
                              <h4>Select Stores</h4>
                              <p className="m-0">
                                Select one or more stores to view the sales
                                summary
                              </p>
                            </CardBody>
                          </Card>
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </Container>
      </>
    );
  }
}

export default SalesSummary;
