import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Spinner,
  Collapse,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import moment from "moment";
import { DatePicker, SelectPicker, Tooltip, Whisper } from "rsuite";
import StringUtils from "lib/StringUtils";
import { Table, Cell, Column, HeaderCell } from "rsuite-table";
import _ from "underscore";
import MoneyCell from "components/Table/MoneyCell";
import NumericCell from "components/Table/NumericCell";
import StoreCell from "components/Table/StoreCell";
import BootstrapTable from "react-bootstrap-table-next";
import MealsOrderedWidget from "components/Widgets/Fulfillment/MealsOrderedWidget";
import PlansFulfilledWidget from "components/Widgets/Fulfillment/PlansFulfilledWidget";
import AverageBoxSizeWidget from "components/Widgets/Fulfillment/AverageBoxSizeWidget";
import PlanFrequencyChartWidget from "components/Widgets/Fulfillment/PlanFrequencyChartWidget";
import PlanProcurementChartWidget from "components/Widgets/Fulfillment/PlanProcurementChartWidget";
import PlanTypeChartWidget from "components/Widgets/Fulfillment/PlanTypeChartWidget";
import { Link } from "react-router-dom";
import LinkUtils from "lib/LinkUtils";
import swal from "sweetalert";

class ProductionFulfillmentSummary extends React.Component {
  state = {
    stores: [],
    loading: true,
    fulfillmentDate: new Date(),
    availableDates: [],
    storeOpen: true,
    mealOpen: true,
    planOpen: true,
    storePerformance: [],
    dates: [],
    compareToOptions: [],
  };

  loadCompareToOptions(first) {
    let current = moment(this.state.fulfillmentMoment.format("YYYY-MM-DD"));

    let weekPrior = moment(current.format());
    let monthPrior = moment(current.format());
    let quarterPrior = moment(current.format());
    let yearPrior = moment(current.format());

    weekPrior = weekPrior.subtract(1, "week");
    monthPrior = monthPrior.subtract(4, "weeks");
    quarterPrior = quarterPrior.subtract(12, "weeks");
    yearPrior = yearPrior.subtract(1, "year").day(6);

    //let monthPrior = current.subtract(4 * 7, "days");
    //let quarterPrior = current.subtract(12 * 7, "days");
    //let yearPrior = current.subtract(1, "year");

    let compareTo = [];

    if (
      this.state.availableDates.indexOf(weekPrior.format("YYYY-MM-DD")) >= 0
    ) {
      compareTo.push({
        value: "lastweek",
        label: "vs. Previous Week",
      });
    }

    if (
      this.state.availableDates.indexOf(monthPrior.format("YYYY-MM-DD")) >= 0
    ) {
      compareTo.push({
        value: "lastmonth",
        label: "vs. Previous Month",
      });
    }

    if (
      this.state.availableDates.indexOf(quarterPrior.format("YYYY-MM-DD")) >= 0
    ) {
      compareTo.push({
        value: "lastquarter",
        label: "vs. Previous Quarter",
      });
    }

    if (
      this.state.availableDates.indexOf(yearPrior.format("YYYY-MM-DD")) >= 0
    ) {
      compareTo.push({
        value: "lastyear",
        label: "vs. Previous Year",
      });
    }

    this.setState({ compareToOptions: compareTo });
  }

  getAvailableDates() {
    APIV2.getSubscriptionFulfillmentDates().then(
      (data) => {
        let dates = data.data.dates;

        dates.push(this.state.fulfillmentMoment.format("YYYY-MM-DD"));

        this.setState({
          availableDates: dates,
        });

        this.loadCompareToOptions(true);
      },
      (e) => {
        this.setState({
          availableDates: [],
        });

        console.error(e);
      }
    );
  }

  getAllStores() {
    APIV2.getStores().then((data) => {
      let stores = _.filter(data.data.stores, (st) => {
        return st?.nsoStage == "Live" || st?.nsoStage == "Prelaunch";
      });

      stores = _.sortBy(stores, "name");

      this.setState({ stores });

      if (this.state.reports?.length) {
        this.renderSummary(this.state.reports);
      }
    });
  }

  getAllMeals() {}

  renderSummary(reports, comparison = []) {
    if (!reports || !reports.length) {
      this.setState({ emptyReporting: true });
    } else {
      this.setState({ emptyReporting: false });
    }

    for (let i = 0; i < reports.length; i++) {
      let st = _.findWhere(this.state.stores, {
        _id: reports[i].storeID,
      });

      reports[i].store = st;

      console.log(reports[i]);
    }

    reports = _.filter(reports, (r) => {
      return r?.store ? true : false;
    });

    this.setState({
      reports,
    });
  }

  loadSummary(dateString, compareTo = false) {
    if (!dateString) {
      return;
    }

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("date", dateString);
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.setState({
      loading: true,
    });

    let comp = "";

    if (compareTo) {
      let current = moment(this.state.fulfillmentMoment.format("YYYY-MM-DD"));
      let weekPrior = moment(current.format());
      let monthPrior = moment(current.format());
      let quarterPrior = moment(current.format());
      let yearPrior = moment(current.format());

      weekPrior = weekPrior.subtract(1, "week");
      monthPrior = monthPrior.subtract(4, "weeks");
      quarterPrior = quarterPrior.subtract(12, "weeks");
      yearPrior = yearPrior.subtract(1, "year").day(6);

      if (compareTo == "lastweek") {
        comp = weekPrior.format("YYYY-MM-DD");
      } else if (compareTo == "lastmonth") {
        comp = monthPrior.format("YYYY-MM-DD");
      } else if (compareTo == "lastquarter") {
        comp = quarterPrior.format("YYYY-MM-DD");
      } else if (compareTo == "lastyear") {
        comp = yearPrior.format("YYYY-MM-DD");
      }
    }

    this.setState({ comparisonDateString: comp });

    if (comp) {
      APIV2.getSubscriptionFulfillmentSummaryForDate(dateString).then(
        (data) => {
          let payload = {};

          payload.reports = data.data.reports;

          this.setState(payload, () => {
            APIV2.getSubscriptionFulfillmentSummaryForDate(comp)
              .then(
                (data) => {
                  this.setState(
                    {
                      comparisonReports: data.data.reports,
                    },
                    () => {
                      this.renderSummary(
                        this.state.reports,
                        this.state.comparisonReports
                      );

                      this.loadCompareToOptions();
                    }
                  );
                },
                (e) => {}
              )
              .finally(() => {
                this.setState({ loading: false });
              });
          });
        },
        (e) => {
          this.setState({ loading: false });
        }
      );
    } else {
      APIV2.getSubscriptionFulfillmentSummaryForDate(dateString)
        .then(
          (data) => {
            let payload = {};

            payload.reports = data.data.reports;

            this.setState(payload, () => {
              this.renderSummary(this.state.reports);

              this.loadCompareToOptions();
            });
          },
          (e) => {}
        )
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const date = urlParams.get("date");

    let fulfillmentDate = moment();

    if (date) {
      fulfillmentDate = moment(date);
    }

    fulfillmentDate
      .set("day", 6)
      .set("hours", 0)
      .set("minutes", 0)
      .set("seconds", 0);

    this.setState(
      {
        fulfillmentDate: fulfillmentDate.toDate(),
        fulfillmentMoment: fulfillmentDate,
        fulfillmentDateString: fulfillmentDate.format("YYYY-MM-DD"),
        comparisonValue: "lastweek",
      },
      () => {
        this.loadSummary(this.state.fulfillmentDateString, "lastweek");
        this.getAvailableDates();
      }
    );

    this.getAllStores();
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeader
            sticky={true}
            title="Fulfillment Reports"
            showBackButton={false}
            forceSecondaryDropdown={true}
            secondaryActions={[
              {
                name: <>Download Bulk Usage Report</>,
                onClick: () => {
                  LinkUtils.openInNewTab(
                    `/storefront-print/fulfillment/bulk-usage-report/csv?date=${this.state.fulfillmentDateString}`,
                    true
                  );
                },
              },
              {
                name: <>Re-Run All Reports</>,
                onClick: () => {
                  swal({
                    title: "Are you sure?",
                    text: "This will delete and re-generate all fulfillment reports for this date. This process may take 5-10 minutes.",
                    icon: "warning",
                    buttons: ["Nevermind", "Re-Run Reports"],
                    dangerMode: true,
                  }).then(
                    (conf) => {
                      if (!conf) {
                        return;
                      }

                      APIV2.rerunSubscriptionFulfillmentReportAllStores(
                        this.state.fulfillmentDateString
                      ).then((data) => {
                        swal({
                          title: "Success",
                          text: "All reports have been queued to re-run. This process may take 5-10 minutes before you start seeing reports appear.",
                          icon: "success",
                        });
                      });
                    },
                    (e) => {
                      alert("Failed to generate reports.");
                    }
                  );
                },
              },
            ]}
          />
          {/* Table */}
          <div
            className="border-bottom bg-white pb-3 mb-4 mt--4 pt-3"
            style={{
              paddingLeft: 15,
              paddingRight: 15,
              marginLeft: -15,
              marginRight: -15,
            }}
          >
            <Row className="align-items-center">
              <Col xs="12" sm="12" md="">
                <h3 className="m-0">
                  {this.state.fulfillmentMoment?.format("ddd, MMM Do YYYY")}
                </h3>
              </Col>
              <Col xs="12" sm="12" md="auto">
                <DatePicker
                  style={{
                    zIndex: 0,
                    minWidth: "200px",
                    width: "100%",
                    maxWidth: "800px",
                  }}
                  oneTap
                  value={this.state.fulfillmentDate}
                  onChange={(v) => {
                    let mom = moment(v.toISOString());

                    mom.set("hours", 0).set("minutes", 0).set("seconds", 0);

                    this.setState(
                      {
                        fulfillmentDate: mom.toDate(),
                        fulfillmentMoment: mom,
                        fulfillmentDateString: mom.format("YYYY-MM-DD"),
                      },
                      () => {
                        this.loadSummary(
                          this.state.fulfillmentDateString,
                          this.state.comparisonValue
                        );
                      }
                    );
                  }}
                  className="mt-3 mt-md-0"
                  placeholder="Select Date"
                  placement="autoVerticalEnd"
                  block
                  cleanable={false}
                  disabledDate={(date) => {
                    let mom = moment(date.toISOString());

                    if (!this.state.availableDates) {
                      return false;
                    }

                    return this.state.availableDates.indexOf(
                      mom.format("YYYY-MM-DD")
                    ) >= 0
                      ? false
                      : true;
                  }}
                />
              </Col>
            </Row>
          </div>

          {this.state.loading ? (
            <>
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">
                    <Spinner color="dark" size="sm"></Spinner>
                    <span className="pl-3">Loading Reports</span>
                  </h3>
                </CardHeader>
                <CardBody>
                  Hang tight while we fetch your labels, boxing lists, and usage
                  reports for{" "}
                  {this.state.fulfillmentMoment?.format("dddd, MMM Do YYYY")}.
                </CardBody>
              </Card>
            </>
          ) : null}
          {!this.state.loading && this.state.emptyReporting ? (
            <>
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">
                    {moment().isBefore(this.state.fulfillmentMoment)
                      ? "Summary Not Ready"
                      : "Summary Not Available"}
                  </h3>
                </CardHeader>
                <CardBody>
                  {moment().isBefore(this.state.fulfillmentMoment) ? (
                    <>
                      <p className="mb-0">
                        Check back on Thursday after 11am ET to see a labels
                        &amp; boxing lists of this week's fulfillment.
                      </p>
                    </>
                  ) : (
                    <>
                      We couldn't find any labels &amp; boxing lists for{" "}
                      {this.state.fulfillmentMoment?.format(
                        "dddd, MMM Do YYYY"
                      )}
                      . Try choosing another date.
                    </>
                  )}
                </CardBody>
              </Card>
            </>
          ) : null}
          {!this.state.loading && !this.state.emptyReporting ? (
            <>
              <div className="mb-3 mt--3">
                {this.state.reports?.length ? (
                  <p className="mb-0" style={{ lineHeight: 1.2 }}>
                    <small>
                      Labels, boxing lists, and usage reports from{" "}
                      {this.state.reports.length} store
                      {this.state.reports.length > 1 ? "s" : ""}.
                    </small>
                  </p>
                ) : null}
              </div>
              <>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col xs="12" sm="12" md="3">
                        <h4 className="m-0 text-dark">Store</h4>
                      </Col>
                      <Col
                        xs="12"
                        sm="6"
                        md="3"
                        className="d-none d-sm-block mt-2 mt-md-0"
                      >
                        <h4 className="m-0 text-dark">Labels</h4>
                      </Col>
                      <Col
                        xs="12"
                        sm="6"
                        md="3"
                        className="d-none d-sm-block mt-2 mt-md-0"
                      >
                        <h4 className="m-0 text-dark">Boxing List</h4>
                      </Col>
                      <Col
                        xs="12"
                        sm="12"
                        md="3"
                        className="d-none d-sm-block mt-2 mt-md-0"
                      >
                        <h4 className="m-0 text-dark">Usage Report</h4>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="p-0">
                    {this.state.reports?.map((report, i) => (
                      <>
                        <div className="p-4">
                          <Row className="align-items-center">
                            <Col xs="12" sm="12" md="3">
                              <h4 className="m-0 text-dark">
                                {report?.store?.name?.replace(
                                  "Project LeanNation",
                                  "PLN"
                                )}
                              </h4>
                            </Col>
                            <Col xs="12" sm="6" md="3" className="mt-2 mt-md-0">
                              <h4 className="m-0 font-weight-500">
                                {!report?.orders?.length ? (
                                  <>
                                    <Whisper
                                      placement="auto"
                                      trigger="hover"
                                      speaker={
                                        <Tooltip>
                                          This store does not have any orders to
                                          fulfill.
                                        </Tooltip>
                                      }
                                    >
                                      <a
                                        href="/"
                                        disabled
                                        className={`disabled text-light`}
                                        onClick={(e) => {
                                          e.preventDefault();

                                          if (!report?.orders?.length) {
                                            return;
                                          }

                                          LinkUtils.openInNewTab(
                                            `/storefront-print/${report?.store?._id}/fulfillment/orders/labels?date=${this.state.fulfillmentDateString}`,
                                            true
                                          );
                                        }}
                                      >
                                        Print Labels
                                      </a>
                                    </Whisper>
                                  </>
                                ) : (
                                  <a
                                    href="/"
                                    className={`${
                                      !report?.orders?.length ? "disabled" : ""
                                    }`}
                                    onClick={(e) => {
                                      e.preventDefault();

                                      if (!report?.orders?.length) {
                                        return;
                                      }

                                      LinkUtils.openInNewTab(
                                        `/storefront-print/${report?.store?._id}/fulfillment/orders/labels?date=${this.state.fulfillmentDateString}`,
                                        true
                                      );
                                    }}
                                  >
                                    Print Labels
                                  </a>
                                )}
                              </h4>
                            </Col>
                            <Col xs="12" sm="6" md="3" className="mt-2 mt-md-0">
                              <h4 className="m-0 font-weight-500">
                                {!report?.orders?.length ? (
                                  <>
                                    <Whisper
                                      placement="auto"
                                      trigger="hover"
                                      speaker={
                                        <Tooltip>
                                          This store does not have any orders to
                                          fulfill.
                                        </Tooltip>
                                      }
                                    >
                                      <a
                                        href="/"
                                        disabled
                                        className={`disabled text-light`}
                                        onClick={(e) => {
                                          e.preventDefault();

                                          if (!report?.orders?.length) {
                                            return;
                                          }

                                          LinkUtils.openInNewTab(
                                            `/storefront-print/${report?.store?._id}/fulfillment/orders/boxing-list/csv?date=${this.state.fulfillmentDateString}`,
                                            true
                                          );
                                        }}
                                      >
                                        Download List
                                      </a>
                                    </Whisper>
                                  </>
                                ) : (
                                  <a
                                    href="/"
                                    className={`${
                                      !report?.orders?.length ? "disabled" : ""
                                    }`}
                                    onClick={(e) => {
                                      e.preventDefault();

                                      if (!report?.orders?.length) {
                                        return;
                                      }

                                      LinkUtils.openInNewTab(
                                        `/storefront-print/${report?.store?._id}/fulfillment/orders/boxing-list/csv?date=${this.state.fulfillmentDateString}`,
                                        true
                                      );
                                    }}
                                  >
                                    Download List
                                  </a>
                                )}
                              </h4>
                            </Col>
                            <Col xs="12" sm="6" md="3" className="mt-2 mt-md-0">
                              <h4 className="m-0 font-weight-500">
                                {!report?.orders?.length ? (
                                  <>
                                    <Whisper
                                      placement="auto"
                                      trigger="hover"
                                      speaker={
                                        <Tooltip>
                                          This store did not have any orders to
                                          fulfill.
                                        </Tooltip>
                                      }
                                    >
                                      <a
                                        href="/"
                                        disabled
                                        className={`disabled text-light`}
                                        onClick={(e) => {
                                          e.preventDefault();

                                          if (!report?.orders?.length) {
                                            return;
                                          }

                                          LinkUtils.openInNewTab(
                                            `/storefront-print/${report?.store?._id}/fulfillment/orders/usage-report/csv?date=${this.state.fulfillmentDateString}`,
                                            true
                                          );
                                        }}
                                      >
                                        Usage Report
                                      </a>
                                    </Whisper>
                                  </>
                                ) : (
                                  <a
                                    href="/"
                                    className={`${
                                      !report?.orders?.length ? "disabled" : ""
                                    }`}
                                    onClick={(e) => {
                                      e.preventDefault();

                                      if (!report?.orders?.length) {
                                        return;
                                      }

                                      LinkUtils.openInNewTab(
                                        `/storefront-print/${report?.store?._id}/fulfillment/orders/usage-report/csv?date=${this.state.fulfillmentDateString}`,
                                        true
                                      );
                                    }}
                                  >
                                    Usage Report
                                  </a>
                                )}
                              </h4>
                            </Col>
                          </Row>
                        </div>
                        {i != this.state.reports?.length ? (
                          <>
                            <hr className="my-0"></hr>
                          </>
                        ) : null}
                      </>
                    ))}
                  </CardBody>
                </Card>
              </>
            </>
          ) : null}
        </Container>
      </>
    );
  }
}

export default ProductionFulfillmentSummary;
