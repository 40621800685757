import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
  Button,
  Modal,
} from "reactstrap";
import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";
import API from "lib/API";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import User from "lib/Model/User";
import APIV2 from "lib/APIV2";

class ProductGroupRow extends React.Component {
  state = {
    showSecret: false,
    name: "",
    description: "",
    key: "",
    secret: "",
    modify: false,
  };

  updateShowSecret(show) {
    this.setState({
      showSecret: show,
    });
  }

  delete() {
    let c = window.confirm(
      "Are you sure you want to delete this product group?"
    );

    if (c) {
      APIV2.deleteProductGroup(this.props.group._id).then(
        (data) => {
          PubSub.publish(Event.PRODUCT_GROUP.DELETED, {
            id: this.props.group._id,
          });
        },
        (e) => {
          console.error(e);
          window.alert(
            "We're having trouble removing that group. Please try again."
          );
        }
      );
    }
  }

  modify(e) {
    e.preventDefault();

    this.setState({
      submitting: true,
    });

    APIV2.modifyProductGroup(
      this.props.group._id,
      this.state.name,
      this.state.groupCode,
      this.state.description,
      parseInt(this.state.calorieMin),
      parseInt(this.state.calorieMax)
    )
      .then(
        (data) => {
          console.log("here");

          if (data && data.data && data.data.planType) {
            PubSub.publish(Event.PRODUCT_GROUP.MODIFIED, data.data.planType);

            this.closeModifyModal();
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "We were unable to modify that group. Please try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  closeModifyModal() {
    this.setState({
      name: "",
      description: "",
      key: "",
      secret: "",
      modify: false,
    });
  }

  handleNameChange(event) {
    const val = event.target.value;

    this.setState({
      name: val,
    });
  }

  handleDescriptionChange(event) {
    const val = event.target.value;

    this.setState({
      description: val,
    });
  }

  handleMinChange(event) {
    const val = event.target.value;

    this.setState({
      calorieMin: val,
    });
  }

  handleCodeChange(event) {
    const val = event.target.value;

    this.setState({
      groupCode: val,
    });
  }

  handleMaxChange(event) {
    const val = event.target.value;

    this.setState({
      calorieMax: val,
    });
  }

  openModifyModal() {
    this.setState({
      name: this.props.group.name,
      groupCode: this.props.group.groupCode,
      description: this.props.group.description,
      calorieMin: this.props.group.calorieMin,
      calorieMax: this.props.group.calorieMax,

      modify: true,
    });
  }

  render() {
    return (
      <>
        <tr>
          <td style={{ whiteSpace: "initial" }}>
            <h4 className="mb-1 d-block d-md-none">{this.props.group.name}</h4>
            <h3 className="mb-1 d-none d-md-block">{this.props.group.name}</h3>
            <p className="mb-0">
              {this.props.group.calorieMin} - {this.props.group.calorieMax} CAL
            </p>
            <p className="mb-0" style={{ lineHeight: 1.25 }}>
              <small>{this.props.group.description}</small>
            </p>
          </td>

          <td className="text-right">
            <Button
              size="sm"
              outline
              color="secondary"
              className="btn-icon-only"
              onClick={() => this.openModifyModal()}
            >
              <i className="mdi mdi-pencil"></i>
            </Button>
            <Button
              size="sm"
              outline
              color="danger"
              className="btn-icon-only"
              onClick={() => this.delete()}
            >
              <i className="mdi mdi-delete"></i>
            </Button>
          </td>
        </tr>
        <Modal
          isOpen={this.state.modify}
          toggle={() => this.closeModifyModal()}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="createBAModalLabel">
              Modify Product Group
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.closeModifyModal()}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Form onSubmit={this.modify.bind(this)}>
            <div className="modal-body">
              <Input
                id="createPGName"
                placeholder="Name"
                type="text"
                className="mb-3"
                required={true}
                value={this.state.name}
                onChange={this.handleNameChange.bind(this)}
              />
              <Input
                id="createPGCode"
                placeholder="Group Code"
                type="text"
                className="mb-3"
                required={true}
                value={this.state.groupCode}
                onChange={this.handleCodeChange.bind(this)}
              />
              <Input
                id="createPGDescription"
                placeholder="Description"
                rows="3"
                type="textarea"
                className="mb-3"
                required={true}
                value={this.state.description}
                onChange={this.handleDescriptionChange.bind(this)}
              />
              <Input
                id="createPGKey"
                placeholder="Calorie Min"
                type="text"
                className="mb-3"
                required={true}
                value={this.state.calorieMin}
                onChange={this.handleMinChange.bind(this)}
              />
              <Input
                id="createPGSecret"
                placeholder="Calorie Max"
                type="text"
                required={true}
                value={this.state.calorieMax}
                onChange={this.handleMaxChange.bind(this)}
              />
            </div>
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="reset"
                onClick={() => this.closeModifyModal()}
              >
                Close
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={this.state.submitting}
              >
                Save
              </Button>
            </div>
          </Form>
        </Modal>
      </>
    );
  }
}

export default ProductGroupRow;
