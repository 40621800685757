import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import LocalStorage from "lib/LocalStorage";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import { Badge, Button, Card, Col, Row } from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import swal from "sweetalert";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import TaskModifyDrawer from "./TaskModifyDrawer";
import CustomerInformationWidget from "components/Widgets/CustomerInformationWidget";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import NoteBody from "components/NoteBody";
import Spinner from "reactstrap/lib/Spinner";
import LeadContactInformationWidget from "components/Widgets/LeadContactInformationWidget";
import AffiliateAboutWidget from "components/Widgets/AffiliateAboutWidget";
import LinkUtils from "lib/LinkUtils";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import CustomerAllTasksWidget from "components/Widgets/CustomerAllTasksWidget";
import LeadAllTasksWidget from "components/Widgets/LeadAllTasksWidget";
import CustomerStatusWidget from "components/Widgets/CustomerStatusWidget";
import CustomerNextBillDateWidget from "components/Widgets/CustomerNextBillDateWidget";
import CustomerPlanWidget from "components/Widgets/CustomerPlanWidget";
import CustomerCouponWidget from "components/Widgets/CustomerCouponWidget";
import CustomerBoxWidget from "components/Widgets/CustomerBoxWidget";
import CustomerHoldDateWidget from "components/Widgets/CustomerHoldDateWidget";
import CustomerHoldDetailsWidget from "components/Widgets/CustomerHoldDetailsWidget";
import TechSupportTicketDrawer from "./TechSupportTicketDrawer";
import ConversationStoreAssignmentDrawer from "./ConversationStoreAssignmentDrawer";
import CustomerCreateDrawer from "./CustomerCreateDrawer";
import Constant from "lib/Constant";
import Linkify from "components/Linkify";

class ConversationDetailDrawer extends React.Component {
  state = {
    recentFirst: "recentFirst",
    tab: {
      id: "Notes",
      name: "Notes",
    },
  };

  dropdownRef = React.createRef();
  drawerRef = React.createRef();

  componentDidUpdate(prevProps) {
    if (
      prevProps?.conversation != this.props.conversation &&
      this.props.conversation
    ) {
      this.fetchNotes(true);

      //console.log(this.props.conversation?.customer);

      if (this.props.conversation?._id != prevProps?.conversation?._id) {
        this.setState({
          tab: {
            id: "Notes",
            name: "Notes",
          },
        });
      }
    }
  }

  componentDidMount() {
    if (this.props.conversation) {
      this.fetchNotes(true);
    }
  }

  toggleModal() {
    this.setState({
      name: "",
      email: "",
      phone: {
        value: "",
      },
      plan: null,
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  modifyLeadStatus(status) {
    this.setState({ statusSubmitting: true });

    APIV2.setLeadVariable(
      this.props.conversation?.lead?.storeID,
      this.props?.conversation?.lead?._id,
      "status",
      status
    )
      .then(
        (data) => {
          //console.log(data.data.lead);

          PubSub.publish(Event.LEAD.MODIFIED, data.data.lead);
        },
        (e) => {
          this.setError("error", "Could not update lead, please try again.");
        }
      )
      .finally(() => {
        this.setState({ statusSubmitting: false });
      });
  }

  fetchNotes(forceLoad = false) {
    if (!this.props.conversation) {
      return;
    }

    if (forceLoad) {
      this.setState({
        loading: true,
      });
    }

    let query = {};

    if (this.props.conversation?.customerID) {
      query = {
        customerID: this.props.conversation?.customerID,
      };
    } else if (this.props.conversation?.leadID) {
      query = {
        leadID: this.props.conversation?.leadID,
      };
    }

    let sortBy = {
      createdAt: this.state.recentFirst == "recentFirst" ? -1 : 1,
    };

    APIV2.getNotesForStore(
      this.props?.conversation?.storeID,
      this.state.page,
      10,
      query,
      sortBy
    )
      .then(
        (data) => {
          let notes = forceLoad ? [] : this.state.notes;

          notes = data?.data?.notes?.length
            ? notes.concat(data?.data?.notes)
            : notes;

          let notesOut = [];

          for (let i = 0; i < notes?.length; i++) {
            if (!_.findWhere(notesOut, { _id: notes[i]?._id })) {
              notesOut.push(notes[i]);
            }
          }

          this.setState({
            notes: notesOut,
            pagination: data?.data?.pagination,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  handleRecentFirstChange(v) {
    this.setState(
      {
        recentFirst: v,
        page: 1,
      },
      () => {
        this.fetchNotes(true);
      }
    );
  }

  renderLeadStatus(c) {
    if (c?.status == "Not Contacted") {
      return (
        <Badge
          color=""
          style={{ position: "relative", top: -2 }}
          className="bg-yellow text-dark ml-2"
        >
          {c?.status}
        </Badge>
      );
    }

    if (c?.status == "Attempted") {
      return (
        <Badge
          color="info"
          style={{ position: "relative", top: -2 }}
          className="ml-2"
        >
          {c?.status}
        </Badge>
      );
    }

    if (c?.status == "closedwon") {
      return (
        <Badge
          color="success"
          style={{ position: "relative", top: -2 }}
          className="ml-2"
        >
          Converted
        </Badge>
      );
    }

    if (c?.status == "Inactive") {
      return (
        <Badge
          color="light"
          style={{ position: "relative", top: -2 }}
          className="ml-2"
        >
          {c?.status}
        </Badge>
      );
    }

    return null;
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          ref={this.drawerRef}
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
          onOpen={() => {
            //console.log("open");

            if (typeof this.props.onOpen == "function") {
              this.props.onOpen(this.drawerRef);
            }
          }}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Conversation Details
            </h3>
            <Drawer.Actions>
              <Whisper
                ref={this.dropdownRef}
                trigger="click"
                placement={"autoVerticalEnd"}
                speaker={
                  <Popover full>
                    <Dropdown.Menu>
                      {this.props.conversation?.lead &&
                      !this.props.conversation?.customer ? (
                        <>
                          <Dropdown.Item
                            panel
                            className="py-2 font-weight-800 text-uppercase"
                            style={{ paddingLeft: 12, paddingRight: 12 }}
                          >
                            <h5 className="m-0">Lead Status</h5>
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled={
                              this.props.conversation?.lead?.status ==
                                "Not Contacted" || this.state.statusSubmitting
                            }
                            onClick={() => {
                              this.modifyLeadStatus("Not Contacted");

                              this.dropdownRef.current.close();
                            }}
                          >
                            Set as Not Contacted
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled={
                              this.props.conversation?.lead?.status ==
                                "Attempted" || this.state.statusSubmitting
                            }
                            onClick={() => {
                              this.modifyLeadStatus("Attempted");

                              this.dropdownRef.current.close();
                            }}
                          >
                            Set as Attempted
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled={
                              this.props.conversation?.lead?.status ==
                                "Inactive" || this.state.statusSubmitting
                            }
                            onClick={() => {
                              this.modifyLeadStatus("Inactive");

                              this.dropdownRef.current.close();
                            }}
                          >
                            Set as Inactive
                          </Dropdown.Item>
                          <Dropdown.Item divider></Dropdown.Item>
                        </>
                      ) : null}
                      <Dropdown.Item
                        panel
                        className="py-2 font-weight-800 text-uppercase"
                        style={{ paddingLeft: 12, paddingRight: 12 }}
                      >
                        <h5 className="m-0">Actions</h5>
                      </Dropdown.Item>
                      {this.props.conversation?.lead &&
                      !this.props.conversation?.customer ? (
                        <Dropdown.Item
                          onClick={() => {
                            this.setState({
                              openNewMemberDrawer: true,
                            });

                            this.dropdownRef.current.close();
                          }}
                        >
                          Convert To Member
                        </Dropdown.Item>
                      ) : null}
                      <Dropdown.Item
                        disabled={
                          this.props.conversation?.status == "In Progress" ||
                          this.state.submitting
                        }
                        onClick={() => {
                          this.setState({
                            openSupportTicketDrawer: true,
                          });

                          this.dropdownRef.current.close();
                        }}
                      >
                        Open Support Ticket
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Popover>
                }
              >
                <Button
                  size="sm"
                  outline
                  color="secondary"
                  className="btn-icon-only"
                >
                  <i className="mdi mdi-dots-vertical"></i>
                </Button>
              </Whisper>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-0">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}

            <div className="px-4 pt-4  border-bottom">
              {this.props.conversation?.customer ||
              this.props.conversation?.lead ? (
                <>
                  <Row className="mb-1 align-items-center">
                    <Col xs="">
                      <h2 className="mb-0">
                        {this.props.conversation?.customer ? "Member" : ""}
                        {this.props.conversation?.lead &&
                        !this.props.conversation?.customer
                          ? "Lead"
                          : ""}
                        {this.props.conversation?.lead &&
                        !this.props.conversation?.customer
                          ? this.renderLeadStatus(this.props.conversation?.lead)
                          : ""}
                      </h2>
                    </Col>
                    <Col xs="auto">
                      <Button
                        size="sm"
                        color="secondary"
                        outline
                        onClick={() => {
                          let url = "";

                          if (this.props.conversation?.customer?._id) {
                            url = `/storefront/${this.props?.conversation?.storeID}/members/details/${this.props.conversation?.customer?._id}/summary`;
                          } else if (this.props.conversation?.lead?._id) {
                            url = `/storefront/${this.props?.conversation?.storeID}/leads/details/${this.props.conversation?.leadID}/summary`; // TODO: fix this once lead page is built
                          } else if (this.props.conversation?.affiliate?._id) {
                            url = `/storefront/${this.props?.conversation?.storeID}/affiliates/details/${this.props.conversation?.affiliateID}/summary`; // TODO: fix this once lead page is built
                          }

                          LinkUtils.openInNewTab(url, true);
                        }}
                      >
                        View
                      </Button>
                    </Col>
                  </Row>
                  {this.props.conversation?.customer ? (
                    <>
                      <div className="py-3 border-bottom">
                        <Row className="align-items-center">
                          <Col xs="auto">Name:</Col>
                          <Col xs="" className="pl-0">
                            {this.props.conversation?.customer?.name}
                          </Col>
                        </Row>
                      </div>
                      <div className="py-3 border-bottom">
                        <Row className="align-items-center">
                          <Col xs="auto">Email:</Col>
                          <Col xs="" className="pl-0">
                            <a href={`mailto:${this.props.customer?.email}`}>
                              {this.props.conversation?.customer?.email}
                            </a>
                          </Col>
                        </Row>
                      </div>
                      <div className="py-3">
                        <Row className="align-items-center">
                          <Col xs="auto">Phone:</Col>
                          <Col xs="" className="pl-0">
                            <div>
                              <a
                                href={`tel:${this.props.conversation?.customer?.phone}`}
                              >
                                {StringUtils.formatPhoneNumber(
                                  this.props.conversation?.customer?.phone
                                )}
                              </a>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </>
                  ) : null}
                  {this.props.conversation?.lead &&
                  !this.props.conversation?.customer ? (
                    <>
                      <div className="py-3 border-bottom">
                        <Row className="align-items-center">
                          <Col xs="auto">Name:</Col>
                          <Col xs="" className="pl-0">
                            {this.props.conversation?.lead?.name
                              ? this.props.conversation?.lead?.name
                              : "--"}
                          </Col>
                        </Row>
                      </div>
                      <div className="py-3 border-bottom">
                        <Row className="align-items-center">
                          <Col xs="auto">Email:</Col>
                          <Col xs="" className="pl-0">
                            {this.props.conversation?.lead?.email ? (
                              <a href={`mailto:${this.props.lead?.email}`}>
                                {this.props.conversation?.lead?.email}
                              </a>
                            ) : (
                              "--"
                            )}
                          </Col>
                        </Row>
                      </div>
                      <div className="py-3">
                        <Row className="align-items-center">
                          <Col xs="auto">Phone:</Col>
                          <Col xs="" className="pl-0">
                            <div>
                              {this.props.conversation?.lead?.phone ? (
                                <a
                                  href={`tel:${this.props.conversation?.lead?.phone}`}
                                >
                                  {StringUtils.formatPhoneNumber(
                                    this.props.conversation?.lead?.phone
                                  )}
                                </a>
                              ) : (
                                "--"
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}
            </div>
            <div
              className="mb-0 border-bottom border-lighter"
              style={{ position: "sticky", top: 0, zIndex: 1 }}
            >
              <Card className="border-0 px-2 py-0 m-0">
                <CardSelectorHeader
                  value={this.state.tab}
                  options={
                    this.props.conversation?.lead &&
                    !this.props.conversation?.customer
                      ? [
                          {
                            id: "Notes",
                            name: "Notes",
                          },
                          { id: "Tasks", name: "Tasks" },
                        ]
                      : [
                          {
                            id: "Notes",
                            name: "Notes",
                          },
                          { id: "Tasks", name: "Tasks" },
                          { id: "Subscription", name: "Subscription" },
                        ]
                  }
                  onChange={(v) => {
                    this.setState({
                      tab: v,
                    });
                  }}
                ></CardSelectorHeader>
              </Card>
            </div>
            {this.state.tab?.id == "Notes" ? (
              <>
                <div className="px-4 pb-4 mt-4">
                  <Row className="align-items-center mb-3">
                    <Col>
                      <h3 className="text-dark mb-0">
                        {this.props.conversation?.customer ? "Member " : ""}
                        {this.props.conversation?.lead &&
                        !this.props.conversation?.customer
                          ? "Lead "
                          : null}
                        Notes
                      </h3>
                    </Col>
                    <Col xs="auto">
                      <SelectPicker
                        size="sm"
                        searchable={false}
                        data={[
                          { label: "Newest First", value: "recentFirst" },
                          { label: "Oldest First", value: "oldestFirst" },
                        ]}
                        value={this.state.recentFirst}
                        onChange={(v) => {
                          this.handleRecentFirstChange(v);
                        }}
                        cleanable={false}
                        placement="auto"
                      ></SelectPicker>
                    </Col>
                  </Row>

                  <div>
                    <NoteBody
                      customer={
                        this.props.conversation?.customer
                          ? this.props.conversation?.customer
                          : null
                      }
                      lead={
                        this.props.conversation?.lead &&
                        !this.props.conversation?.customer
                          ? this.props.conversation.lead
                          : null
                      }
                      onSave={(note) => {
                        //console.log(note);

                        this.setState(
                          {
                            page: 1,
                          },
                          () => {
                            this.fetchNotes(true);
                          }
                        );
                      }}
                    ></NoteBody>
                  </div>
                  <div>
                    {this.state?.loading && (
                      <div className="px-3 py-5 text-center">
                        <Spinner size="sm" color="dark"></Spinner>
                      </div>
                    )}
                    {!this.state?.notes?.length && !this.state.loading && (
                      <div className="px-3 py-5">
                        <Row>
                          <Col xs="12" className="text-center">
                            <p className="m-0">No notes found.</p>
                          </Col>
                        </Row>
                      </div>
                    )}

                    {this.state?.notes?.map((note, i) => (
                      <div key={note?._id}>
                        {this.state.editNote == note?._id ? (
                          <>
                            <div className="p-4">
                              <NoteBody
                                note={note}
                                conversation={this.props.conversation}
                                forceEdit={true}
                                onSave={(n) => {
                                  let notes = this.state.notes;

                                  let idx = _.findIndex(notes, { _id: n._id });

                                  if (idx >= 0) {
                                    notes[idx] = n;
                                  }

                                  this.setState({
                                    editNote: "",
                                    notes,
                                  });
                                }}
                                onCancel={() => {
                                  this.setState({ editNote: "" });
                                }}
                              ></NoteBody>
                            </div>
                          </>
                        ) : (
                          <div className="px-4 cursor-pointer py-3 bg-superlight--hover">
                            <Row>
                              <Col xs="">
                                {note?.title ? (
                                  <>
                                    <h3 className="text-dark mb-1">
                                      {note?.title}
                                    </h3>
                                    <Linkify
                                      body={note?.body}
                                      wrap={true}
                                    ></Linkify>
                                  </>
                                ) : (
                                  <>
                                    <Linkify
                                      body={note?.body}
                                      wrap={true}
                                    ></Linkify>
                                  </>
                                )}
                              </Col>
                              <Col xs="auto">
                                <Button
                                  size="sm"
                                  color="secondary"
                                  outline
                                  className="p-1 mr-0"
                                  style={{ width: 22, height: 22 }}
                                  onClick={() => {
                                    this.setState({
                                      editNote: note?._id,
                                    });
                                  }}
                                >
                                  <i
                                    className="mdi mdi-pencil"
                                    style={{
                                      fontSize: 16,
                                      lineHeight: 1,
                                      position: "relative",
                                      left: -2,
                                      top: -2,
                                    }}
                                  ></i>
                                </Button>
                                <Button
                                  size="sm"
                                  color="danger"
                                  outline
                                  className="p-1"
                                  style={{ width: 22, height: 22 }}
                                  disabled={
                                    this.state.deletingNote == note?._id
                                  }
                                  onClick={() => {
                                    swal({
                                      title: "Delete Note?",
                                      text: "Are you sure you want to delete this note?",
                                      icon: "warning",
                                      buttons: ["Nevermind", "Delete"],
                                      dangerMode: true,
                                    }).then((conf) => {
                                      if (!conf) {
                                        return;
                                      }

                                      APIV2.deleteStoreNote(
                                        note?.storeID,
                                        note?._id
                                      ).then(() => {
                                        let notes = this.state.notes;

                                        let idx = _.findIndex(notes, {
                                          _id: note._id,
                                        });

                                        notes.splice(idx, 1);

                                        this.setState({
                                          editNote: "",
                                          notes,
                                        });

                                        this.setState({
                                          deletingNote: "",
                                        });
                                      });
                                    });
                                  }}
                                >
                                  <i
                                    className="mdi mdi-close"
                                    style={{
                                      fontSize: 18,
                                      lineHeight: 1,
                                      position: "relative",
                                      left: -3,
                                      top: -3,
                                    }}
                                  ></i>
                                </Button>
                              </Col>
                              {note?.attachments?.length > 0 && (
                                <>
                                  <Col className="mt-2" xs="12">
                                    <h5>Attachments: </h5>
                                    {note?.attachments?.map((at, i) => (
                                      <div
                                        className={`p-2 border rounded ${
                                          i !=
                                            this.state?.attachments?.length -
                                              1 && "mb-2"
                                        }`}
                                        key={i}
                                      >
                                        <Row className="align-items-center">
                                          <Col xs="">
                                            <p className="small m-0 text-dark">
                                              {at?.fileName}
                                            </p>
                                          </Col>
                                          <Col xs="auto">
                                            <Button
                                              className="btn-icon-only"
                                              size="sm"
                                              outline
                                              color="secondary"
                                              onClick={() => {
                                                LinkUtils.openPrivateURL(
                                                  at?.url
                                                );
                                              }}
                                            >
                                              <i className="mdi mdi-eye"></i>
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    ))}
                                  </Col>
                                </>
                              )}
                            </Row>
                            <div className="mt-2">
                              <small>
                                {moment(note?.createdAt).format(
                                  "MM/DD/YY h:mmA"
                                )}{" "}
                                by {note?.createdBy?.name}
                              </small>
                            </div>
                          </div>
                        )}
                        {i != this.state?.notes?.length - 1 && (
                          <hr className="m-0 border-light"></hr>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="text-center p-2">
                    <Button
                      size="sm"
                      color="secondary"
                      outline
                      onClick={() => {
                        //console.log(this.state.pagination);

                        this.setState(
                          {
                            page: this.state.pagination?.next,
                          },
                          () => {
                            this.fetchNotes();
                          }
                        );
                      }}
                      disabled={!this.state.pagination?.hasNext}
                    >
                      {this.state.pagination?.hasNext
                        ? "View More Notes"
                        : "Viewing All Notes"}
                    </Button>
                  </div>
                </div>
              </>
            ) : null}
            {this.state.tab?.id == "Tasks" ? (
              <>
                {this.props.conversation?.customer ? (
                  <>
                    <CustomerAllTasksWidget
                      customer={this.props.conversation?.customer}
                      store={{ _id: this.props.conversation?.storeID }}
                      flat={true}
                      noPadding={true}
                    ></CustomerAllTasksWidget>
                  </>
                ) : null}
                {this.props.conversation?.lead &&
                !this.props.conversation?.customer ? (
                  <>
                    <LeadAllTasksWidget
                      lead={this.props.conversation?.lead}
                      store={{ _id: this.props.conversation?.storeID }}
                      flat={true}
                      noPadding={true}
                    ></LeadAllTasksWidget>
                  </>
                ) : null}
              </>
            ) : null}
            {this.state.tab?.id == "Subscription" ? (
              <>
                <div>
                  <CustomerStatusWidget
                    customer={this.props.conversation?.customer}
                    loading={this.props.loading}
                    flat={true}
                  ></CustomerStatusWidget>
                </div>
                <hr className="m-0 border-light"></hr>
                {this.props.conversation?.customer?.accountStatus ==
                "ACTIVE" ? (
                  <>
                    <div>
                      <CustomerNextBillDateWidget
                        customer={this.props.conversation?.customer}
                        loading={this.props.loading}
                        flat={true}
                      ></CustomerNextBillDateWidget>
                    </div>
                    <hr className="m-0 border-light"></hr>
                    <div>
                      <CustomerPlanWidget
                        customer={this.props.conversation?.customer}
                        loading={this.props.loading}
                        flat={true}
                      ></CustomerPlanWidget>
                    </div>
                    <hr className="m-0 border-light"></hr>
                    <div>
                      <CustomerCouponWidget
                        customer={this.props.conversation?.customer}
                        loading={this.props.loading}
                        flat={true}
                      ></CustomerCouponWidget>
                    </div>
                    <hr className="m-0 border-light"></hr>
                    <div>
                      <CustomerBoxWidget
                        customer={this.props.conversation?.customer}
                        loading={this.props.loading}
                        flat={true}
                        grid={false}
                      ></CustomerBoxWidget>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <CustomerHoldDateWidget
                        customer={this.props.conversation?.customer}
                        loading={this.props.loading}
                        flat={true}
                      ></CustomerHoldDateWidget>
                    </div>
                    <hr className="m-0 border-light"></hr>
                    <div>
                      <CustomerHoldDetailsWidget
                        customer={this.props.conversation?.customer}
                        loading={this.props.loading}
                        flat={true}
                      ></CustomerHoldDetailsWidget>
                    </div>
                  </>
                )}
              </>
            ) : null}
          </Drawer.Body>
        </Drawer>
        <TechSupportTicketDrawer
          store={{ _id: this.props.conversation?.storeID }}
          open={this.state.openSupportTicketDrawer}
          storeMode={true}
          customer={
            this.props.conversation?.customer
              ? this.props.conversation?.customer
              : null
          }
          lead={
            this.props.conversation?.lead && !this.props.conversation?.customer
              ? this.props.conversation?.lead
              : null
          }
          onClose={() => {
            this.setState({
              openSupportTicketDrawer: false,
            });
          }}
          onCreate={(c) => {
            swal({
              title: "Support Ticket Opened",
              text: "We've received your support ticket and will contact you shortly!",
              icon: "success",
            });
          }}
        ></TechSupportTicketDrawer>

        <CustomerCreateDrawer
          store={{ _id: this.props.conversation?.storeID }}
          open={this.state.openNewMemberDrawer}
          lead={this.props.conversation?.lead}
          onClose={() => {
            this.setState({
              openNewMemberDrawer: false,
            });
          }}
          onCreate={(c) => {
            PubSub.publish(Event.CUSTOMER.CREATED, c);
          }}
          redirect={false}
        ></CustomerCreateDrawer>
      </>
    );
  }
}

export default withRouter(ConversationDetailDrawer);
