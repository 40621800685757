import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import NumberFormat from "react-number-format";
import APIV2 from "lib/APIV2";
import _ from "underscore";
import { Drawer } from "rsuite";

class CreatePlanModal extends React.Component {
  state = {
    open: false,
    staticCode: "",
    code: "",
    name: "",
    price: {
      value: null,
    },
    deliveryFee: {
      value: null,
    },
    groupID: 0,
    frequency: 0,
    procurement: 0,
    quantity: null,
    productGroups: [],
    loadingBAs: true,
    submitting: false,
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });

    if (this.state[state] == true) {
      this.setState({
        code: "",
        name: "",
        price: {
          value: null,
        },
        groupID: 0,
        frequency: 0,
        procurement: 0,
        quantity: null,
        productGroups: [],
      });
    }
  };

  handleCodeChange(event) {
    const val = event.target.value;

    this.setState({
      code: val,
    });
  }

  handleNameChange(event) {
    const val = event.target.value;

    this.setState({
      name: val,
    });
  }

  handleQuantityChange(event) {
    const val = event.target.value;

    this.setState(
      {
        quantity: val,
      },
      () => {
        this.generatePlanCode();
      }
    );
  }

  handlePriceChange(val) {
    this.setState({
      price: val,
    });
  }

  handleDeliveryFeeChange(val) {
    this.setState({
      deliveryFee: val,
    });
  }

  handleFrequencyChange(event) {
    const val = event.target.value;

    this.setState(
      {
        frequency: val,
      },
      () => {
        this.generatePlanCode();
      }
    );
  }

  handleProcurementChange(event) {
    const val = event.target.value;

    this.setState(
      {
        procurement: val,
      },
      () => {
        this.generatePlanCode();
      }
    );
  }

  handleGroupChange(event) {
    const val = event.target.value;

    this.setState(
      {
        groupID: val,
      },
      () => {
        this.generatePlanCode();
      }
    );
  }

  submit(e) {
    e.preventDefault();

    if (!parseInt(this.state.quantity)) {
      window.alert("Please enter a quantity greater than zero.");

      return;
    }

    this.setState({
      submitting: true,
    });

    let frequency = this.state.frequency == "0" ? "weekly" : "biweekly";

    let procurement = "pickup";

    if (this.state.procurement == "1") {
      procurement = "delivery";
    }

    if (this.state.procurement == "2") {
      procurement = "shipping";
    }

    APIV2.createStorePlan(
      this.props.store._id,
      this.state.code,
      this.state.name,
      this.state.groupID,
      parseFloat(this.state.price.value) * 100,
      this.state.deliveryFee.value
        ? parseFloat(this.state.deliveryFee.value) * 100
        : 0,
      parseInt(this.state.quantity),
      procurement,
      frequency,
      true
    )
      .then(
        (data) => {
          if (data && data.data && data.data.store) {
            PubSub.publish(Event.STORE.MODIFIED, data.data.store);

            this.toggleModal("open");

            this.setState({
              code: "",
              name: "",
              price: {
                value: null,
              },
              groupID: 0,
              frequency: 0,
              procurement: 0,
              quantity: null,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert("We were unable to create that plan. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  getPlanTypes() {
    APIV2.getProductGroups().then(
      (data) => {
        if (data.data && data.data.planTypes) {
          this.setState({
            productGroups: data.data.planTypes,
            groupID: data.data.planTypes.length
              ? data.data.planTypes[0]._id
              : "0",
            loadingBAs: false,
          });
        }
      },
      () => {}
    );
  }

  generatePlanCode() {
    let productGroup = _.findWhere(this.state.productGroups, {
      _id: this.state.groupID,
    });

    let frequencyString = this.state.frequency == "0" ? "W" : "B";
    let frequencyStringLong =
      this.state.frequency == "0" ? "Weekly" : "Bi-Weekly";

    let procurementString = "P";
    let procurementStringLong = "Pickup";

    if (this.state.procurement == "1") {
      procurementString = "D";
      procurementStringLong = "Delivery";
    }

    if (this.state.procurement == "2") {
      procurementString = "S";
      procurementStringLong = "Shipping";
    }

    let code = `${this.props.store.productCode}${
      productGroup && productGroup.groupCode ? productGroup.groupCode : "X"
    }${
      this.state.quantity !== null ? this.state.quantity : ""
    }-${frequencyString}${procurementString}`;

    let name = `${productGroup && productGroup.name ? productGroup.name : ""} ${
      this.state.quantity !== null ? this.state.quantity : ""
    } - ${frequencyStringLong} ${procurementStringLong}`;

    this.setState({
      code,
      name,
    });
  }

  componentDidMount() {
    PubSub.subscribe(Event.PLAN.CREATE_OPEN, () => {
      this.setState({
        open: true,
      });

      this.getPlanTypes();

      this.generatePlanCode();
    });

    this.getPlanTypes();
  }

  render() {
    const { productGroups } = this.state;

    let pgList =
      productGroups.length > 0 &&
      productGroups.map((item, i) => {
        return (
          <option key={i} value={item._id}>
            {item.name}
          </option>
        );
      }, this);

    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.state.open}
          onClose={() => this.toggleModal("open")}
        >
          <Form onSubmit={this.submit.bind(this)}>
            <Drawer.Header className="pr-4">
              <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
                New Plan
              </h3>
              <Drawer.Actions>
                <Button
                  size="sm"
                  disabled={this.state.submitting}
                  color="primary"
                  type="submit"
                >
                  {this.state.submitting ? (
                    <Spinner size="sm" color="white"></Spinner>
                  ) : (
                    "Add"
                  )}
                </Button>
              </Drawer.Actions>
            </Drawer.Header>
            <Drawer.Body className="p-4">
              <div>
                <h5 className="mb-2">Product Group</h5>
                {this.state.loadingBAs ? (
                  <select
                    value="0"
                    disabled={true}
                    className="form-control mb-3"
                  >
                    <option value="0">Loading...</option>
                  </select>
                ) : null}
                {!this.state.loadingBAs ? (
                  <select
                    value={this.state.groupID}
                    required={true}
                    onChange={this.handleGroupChange.bind(this)}
                    id="createPlanGroup"
                    className="form-control mb-3"
                  >
                    <option value="0" disabled>
                      Select A Group
                    </option>
                    {pgList}
                  </select>
                ) : null}
                <h5 className="mb-2">Meal Quantity</h5>
                <Input
                  id="createPGQuantity"
                  placeholder="Quantity"
                  rows="3"
                  type="text"
                  className="mb-3"
                  required={true}
                  value={this.state.quantity}
                  onChange={this.handleQuantityChange.bind(this)}
                />
                <h5 className="mb-2">Unit Price</h5>
                <NumberFormat
                  className="form-control mb-3"
                  fixedDecimalScale={true}
                  decimalScale={2}
                  required={true}
                  placeholder="$0.00"
                  value={this.state.price.value}
                  onValueChange={this.handlePriceChange.bind(this)}
                  thousandSeparator={true}
                  prefix={"$"}
                />
                <h5 className="mb-2">Delivery/Shipping Fee</h5>
                <NumberFormat
                  className="form-control mb-3"
                  fixedDecimalScale={true}
                  decimalScale={2}
                  required={false}
                  placeholder="$0.00"
                  value={this.state.deliveryFee.value}
                  onValueChange={this.handleDeliveryFeeChange.bind(this)}
                  thousandSeparator={true}
                  prefix={"$"}
                />
                <h5 className="mb-2">Fulfillment Frequency</h5>
                <select
                  required={true}
                  value={this.state.frequency}
                  onChange={this.handleFrequencyChange.bind(this)}
                  id="createPlanFreq"
                  className="form-control mb-3"
                >
                  <option value="0">Weekly</option>
                  <option value="1">Biweekly</option>
                </select>
                <h5 className="mb-2">Procurement Method</h5>
                <select
                  value={this.state.procurement}
                  required={true}
                  onChange={this.handleProcurementChange.bind(this)}
                  id="createPlanProc"
                  className="form-control mb-3"
                >
                  <option value="0">Pickup</option>
                  <option value="1">Delivery</option>

                  <option value="2">Shipping</option>
                </select>
                <h5 className="mb-2">Name</h5>
                <Input
                  id="createPGName"
                  placeholder="Name"
                  type="text"
                  className="mb-3"
                  required={true}
                  value={this.state.name}
                  onChange={this.handleNameChange.bind(this)}
                />
                <h5 className="mb-2">Plan Code</h5>
                <Input
                  id="createPGCode"
                  placeholder="Code"
                  type="text"
                  className="mb-3"
                  required={true}
                  value={this.state.code}
                  onChange={this.handleCodeChange.bind(this)}
                />
              </div>
            </Drawer.Body>
          </Form>
        </Drawer>
      </>
    );
  }
}

export default CreatePlanModal;
