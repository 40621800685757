import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Spinner,
  Collapse,
  Table,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import moment, { isMoment } from "moment";
import { CheckboxGroup, DatePicker, SelectPicker, Checkbox } from "rsuite";
import StringUtils from "lib/StringUtils";
import { Cell, Column, HeaderCell } from "rsuite-table";
import _ from "underscore";
import MoneyCell from "components/Table/MoneyCell";
import NumericCell from "components/Table/NumericCell";
import StoreCell from "components/Table/StoreCell";
import BootstrapTable from "react-bootstrap-table-next";
import MealsOrderedWidget from "components/Widgets/Fulfillment/MealsOrderedWidget";
import PlansFulfilledWidget from "components/Widgets/Fulfillment/PlansFulfilledWidget";
import AverageBoxSizeWidget from "components/Widgets/Fulfillment/AverageBoxSizeWidget";
import PlanFrequencyChartWidget from "components/Widgets/Fulfillment/PlanFrequencyChartWidget";
import PlanProcurementChartWidget from "components/Widgets/Fulfillment/PlanProcurementChartWidget";
import PlanTypeChartWidget from "components/Widgets/Fulfillment/PlanTypeChartWidget";
import { Link, withRouter } from "react-router-dom";
import Input from "reactstrap/lib/Input";
import swal from "sweetalert";
import CustomerOrderRow from "components/CustomerOrderRow";
import { CSVLink } from "react-csv";

class ProductOrderCSVDownloadView extends React.Component {
  state = {};

  loadOrder() {
    this.setState({
      loadingOrder: true,
    });

    APIV2.getProductionOrderByID(this.props?.match?.params?.orderID)
      .then(
        (data) => {
          this.setState({
            order: data?.data?.order,
          });

          console.log(data.data.order);

          this._loadOrderTable(data.data.order);
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the order. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingOrder: false,
          loading: false,
        });
      });
  }

  _loadOrderTable(order) {
    if (!order) {
      return;
    }

    if (order?.fulfillmentReportID) {
      let planKeys = _.keys(order?.summary?.planAnalysis);
      let plans = [];

      let totalQuantity = 0;

      for (let i = 0; i < planKeys?.length; i++) {
        const plan = planKeys[i];
        const qty = order?.summary?.planAnalysis[plan];

        plans.push({
          name: plan,
          quantity: qty,
        });

        totalQuantity += qty;
      }

      plans = _.sortBy(plans, "name");

      this.setState({
        plans,
        totalQuantity,
      });

      return;
    }

    let vals = _.values(order?.menus?.meals);

    console.log(vals);

    let allMeals = [];

    // Parse all options off the meal menus
    for (let i = 0; i < vals?.length; i++) {
      if (vals[i].hasOwnProperty("active") && vals[i].active?.meals?.length) {
        const menu = vals[i].active;

        let activeMenuMeals = vals[i].active?.meals;

        // Check for an upcoming transition for new meals to be orderable
        if (menu?.menuTransition) {
          let transitionDate = moment(
            menu?.menuTransition?.transitionDate
          ).toDate();

          // Make new meals available or order one week out
          if (moment().add(1, "week").toDate() >= transitionDate) {
            let swaps = menu?.menuTransition?.newMenu?.meals?.map((item) => {
              item.upcoming = true;

              return item;
            });

            let retiring = menu?.menuTransition?.retiringMealSwaps.map(
              (item) => {
                return item?.fromMeal;
              }
            );

            for (let j = 0; j < retiring?.length; j++) {
              const idx = _.findIndex(activeMenuMeals, {
                _id: retiring[j]?._id,
              });

              if (idx >= 0) {
                activeMenuMeals[idx].retiring = true;
              }
            }

            swaps = _.filter(swaps, (m) => {
              return !_.findWhere(activeMenuMeals, { _id: m?._id });
            });

            activeMenuMeals = activeMenuMeals.concat(swaps);
          }
        }

        for (let j = 0; j < activeMenuMeals?.length; j++) {
          const meal = activeMenuMeals[j];

          meal.name = meal?.name?.trim();

          // Set as default until it can be calculated
          if (
            order?.meals?.hasOwnProperty(meal?.sku) &&
            order?.meals[meal?.sku].caseQuantity
          ) {
            meal.caseQuantity = order?.meals[meal?.sku]?.caseQuantity;
          } else {
            meal.caseQuantity = 0;
          }

          if (
            order?.meals?.hasOwnProperty(meal?.sku) &&
            order?.meals[meal?.sku]?.mealQuantity
          ) {
            meal.mealQuantity = order?.meals[meal?.sku]?.mealQuantity;
          } else {
            meal.mealQuantity = 0;
          }

          if (!_.findWhere(allMeals, { _id: meal?._id })) {
            allMeals.push(meal);
          }
        }
      }
    }

    console.log(allMeals);

    let lifestyle = [];
    let athlete = [];
    let leancheats = [];

    let totalMeals = 0;
    let totalCases = 0;
    let lifestyleTotalCases = 0;
    let lifestyleTotalMeals = 0;
    let athleteTotalCases = 0;
    let athleteTotalMeals = 0;
    let leanCheatTotalPacks = 0;
    let leanCheatTotalMeals = 0;

    let retiredSKUs = _.filter(allMeals, (m) => {
      return m?.retired;
    });

    // Group by type
    for (let i = 0; i < allMeals?.length; i++) {
      const meal = allMeals[i];

      totalMeals += meal?.mealQuantity;

      if (meal?.planType?.name?.includes("Lifestyle")) {
        // Lifestyle meal
        // Lean cheat or lifestyle check
        // TODO: convert to sku check
        if (
          _.findWhere(meal?.categories, { name: "Lean Cheats" }) &&
          !_.findWhere(leancheats, { name: meal?.name })
        ) {
          leanCheatTotalPacks += meal?.caseQuantity;
          leanCheatTotalMeals += meal?.mealQuantity;

          leancheats.push(meal);
        } else if (
          !_.findWhere(lifestyle, { _id: meal?._id }) &&
          !_.findWhere(meal?.categories, { name: "Lean Cheats" })
        ) {
          lifestyleTotalCases += meal?.caseQuantity;
          lifestyleTotalMeals += meal?.mealQuantity;
          totalCases += meal?.caseQuantity;

          lifestyle.push(meal);
        }
      } else {
        // Athlete meal
        // Lean cheat or athlete check
        // TODO: convert to sku check
        if (
          _.findWhere(meal?.categories, { name: "Lean Cheats" }) &&
          !_.findWhere(leancheats, { name: meal?.name })
        ) {
          leancheats.push(meal);

          leanCheatTotalPacks += meal?.caseQuantity;
          leanCheatTotalMeals += meal?.mealQuantity;
        } else if (
          !_.findWhere(athlete, { _id: meal?._id }) &&
          !_.findWhere(meal?.categories, { name: "Lean Cheats" })
        ) {
          athlete.push(meal);

          athleteTotalCases += meal?.caseQuantity;
          athleteTotalMeals += meal?.mealQuantity;
          totalCases += meal?.caseQuantity;
        }
      }
    }

    // Sort alphabetically

    lifestyle = _.filter(lifestyle, (m) => {
      return !_.findWhere(retiredSKUs, { _id: m?._id });
    });

    athlete = _.filter(athlete, (m) => {
      return !_.findWhere(retiredSKUs, { _id: m?._id });
    });

    leancheats = _.filter(leancheats, (m) => {
      return !_.findWhere(retiredSKUs, { _id: m?._id });
    });

    lifestyle = _.sortBy(lifestyle, (m) => m?.name?.toLowerCase());
    athlete = _.sortBy(athlete, (m) => m?.name?.toLowerCase());
    leancheats = _.sortBy(leancheats, (m) => m?.name?.toLowerCase());

    for (let i = 0; i < leancheats?.length; i++) {
      leancheats[i].freightCaseQuantity = (
        leancheats[i].mealQuantity / order?.pricing?.leanCheatCaseSize
      ).toFixed(2);
    }

    let leanCheatTotalCases =
      leanCheatTotalMeals / order?.pricing?.leanCheatCaseSize;

    totalCases = totalCases + leanCheatTotalCases;

    let totalBoxes = Math.ceil(totalCases);

    let casesPerPallet = order?.pricing?.logisticsPalletCaseQuantity;

    if (
      this.state.store?._id &&
      this.state.pricing?.logisticsCustomPalletCaseQuantity?.hasOwnProperty(
        this.state.store?._id
      )
    ) {
      casesPerPallet =
        this.state.pricing?.logisticsCustomPalletCaseQuantity[
          this.state.store?._id
        ];
    }

    let palletsShipped = Math.ceil(totalCases / casesPerPallet);

    let allMealCSV = lifestyle.concat(athlete).concat(leancheats);

    allMealCSV = allMealCSV.map((meal) => {
      return {
        SKU: meal?.sku,
        "Case Quantity": meal?.caseQuantity,
        "Meal Quantity": meal?.mealQuantity,
      };
    });

    this.setState({
      lifestyleMeals: lifestyle,
      athleteMeals: athlete,
      leanCheats: leancheats,
      lifestyleTotalCases,
      lifestyleTotalMeals,
      athleteTotalCases,
      athleteTotalMeals,
      leanCheatTotalPacks,
      leanCheatTotalMeals,
      leanCheatTotalCases,
      totalMeals,
      totalCases,
      palletsShipped,
      totalBoxes,
      allMealCSV,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match?.params?.orderID != prevProps?.match?.params?.orderID &&
      this.props.match?.params?.orderID
    ) {
      this.loadOrder();
    }
  }

  componentDidMount() {
    if (this.props.match?.params?.orderID) {
      this.loadOrder();
    }
  }

  render() {
    return (
      <>
        <div>
          {this.state.loading || this.state.loadingOrder ? (
            <div className="p-3">
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">
                    <Spinner color="dark" size="sm"></Spinner>
                    <span className="pl-3">Generating Order CSV</span>
                  </h3>
                </CardHeader>
                <CardBody>
                  Hang tight while we fetch your order information.
                </CardBody>
              </Card>
            </div>
          ) : null}

          {!this.state.order &&
          !this.state.loadingOrder &&
          !this.state.loading ? (
            <div className="p-3">
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">
                    <span>No CSV Available</span>
                  </h3>
                </CardHeader>
                <CardBody>
                  We were unable to generate a CSV download for this order.
                  Please reload and try again.
                </CardBody>
              </Card>
            </div>
          ) : null}
          {!this.state.loading &&
          !this.state.loadingOrder &&
          this.state.order ? (
            <div className="p-3">
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">
                    CSV Download for {this.state.order?.name}
                  </h3>
                </CardHeader>
                <CardBody>
                  <CSVLink
                    headers={["SKU", "Case Quantity", "Meal Quantity"]}
                    filename={`${this.state.order?.store?.name
                      ?.replace("Project LeanNation", "PLN")
                      .split(" ")
                      .join("_")}_${this.state.order?.name
                      ?.split(" ")
                      .join("_")
                      .split("-")
                      .join("")}.csv`}
                    data={this.state.allMealCSV}
                    className="btn btn-primary"
                  >
                    Download
                  </CSVLink>
                </CardBody>
              </Card>
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

export default withRouter(ProductOrderCSVDownloadView);
