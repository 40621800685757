import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { DatePicker, SelectPicker } from "rsuite";
import moment from "moment";

class CustomerPlanWidget extends React.Component {
  state = {
    nextBillDate: null,
  };

  generatePlanDescription(plan) {
    return StringUtils.generatePlanDescription(plan);
  }

  getAllPlans() {
    this.setState({
      loadingPlans: true,
    });

    if (!this.props.customer?.storeID) {
      return;
    }

    APIV2.getStoreByID(this.props.customer?.storeID)
      .then(
        (data) => {
          let plans = data.data?.store?.products?.mealPlans?.map((c) => {
            return {
              label: (
                <>
                  <p className="m-0 font-weight-bold">{c.name}</p>
                  <p className="m-0" style={{ lineHeight: 1.3 }}>
                    <small>{this.generatePlanDescription(c)}</small>
                  </p>
                </>
              ),
              value: c._id,
              type: c.planType.name,
            };
          });

          this.setState({ plans: plans });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          loadingPlans: false,
        });
      });
  }

  componentDidMount() {
    this.getAllPlans();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.customer != this.props.customer ||
      this.props.customer?.storeID != prevProps.customer?.storeID
    ) {
      this.getAllPlans();
    }
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 4000);
  }

  setPlan(planID) {
    this.setState({
      submitting: true,
    });

    APIV2.updateCustomerPlan(
      this.props.customer.storeID,
      this.props.customer._id,
      planID
    )
      .then(
        (data) => {
          PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);

          this.setState({
            modify: false,
            plan: null,
          });
        },
        (err) => {
          this.setError(
            "error",
            "Unable to update customer's plan. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  renderNextBillDate(d) {
    let mom = moment(d);

    return mom.format("MMM Do YYYY");
  }

  render() {
    return (
      <>
        <Card
          style={{ height: "100%" }}
          className={`${this.props.flat ? "border-0" : "border shadow"}`}
        >
          <CardHeader>
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Plan</h3>
              </div>
              <Col xs="auto">
                {this.state.modify ? (
                  <>
                    <Button
                      size="sm"
                      color="secondary"
                      outline
                      onClick={() => {
                        this.setState({
                          modify: false,
                          plan: null,
                        });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="sm"
                      color="primary"
                      disabled={!this.state.plan || this.state.submitting}
                      onClick={() => {
                        this.setPlan(this.state.plan);
                      }}
                    >
                      {this.state.submitting ? (
                        <Spinner color="white" size="sm" />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </>
                ) : (
                  <Button
                    size="sm"
                    color="secondary"
                    outline
                    onClick={() => {
                      this.setState({
                        modify: true,
                        plan: this.props.customer?.subscription?.planID,
                      });
                    }}
                  >
                    Edit
                  </Button>
                )}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row className="align-items-center">
              {!this.state.modify ? (
                <Col xs="" className="">
                  <h3 className="mb-0">
                    {this.props.customer?.subscription?.plan
                      ? this.props.customer?.subscription?.plan.name
                      : "None assigned"}
                  </h3>
                  <p className="mb-0">
                    <small>
                      {this.generatePlanDescription(
                        this.props.customer?.subscription?.plan
                      )}
                    </small>
                  </p>
                </Col>
              ) : (
                <Col xs="" className="">
                  <SelectPicker
                    placeholder="Select A Plan"
                    data={this.state.plans}
                    value={this.state.plan}
                    block
                    groupBy="type"
                    onChange={(value, item) => {
                      this.setState({ plan: value });
                    }}
                    placement="auto"
                  ></SelectPicker>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default withRouter(CustomerPlanWidget);
