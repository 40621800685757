import ArrowText from "components/ArrowText";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  Badge,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import Card from "reactstrap/lib/Card";
import moment from "moment";
import CustomerOrderDrawer from "components/Drawers/CustomerOrderDrawer";
import CustomerCreateOrderDrawer from "components/Drawers/CustomerCreateOrderDrawer";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import CustomerAllOrdersDrawer from "components/Drawers/CustomerAllOrdersDrawer";
import _ from "underscore";
import {
  Drawer,
  Dropdown,
  Popover,
  SelectPicker,
  Tooltip,
  Uploader,
  Whisper,
} from "rsuite";
import LinkUtils from "lib/LinkUtils";
import swal from "sweetalert";
import FileUploadDrawer from "./FileUploadDrawer";
import Editor from "./NovelEditor/ui/editor";
import LocalStorage from "lib/LocalStorage";

class NoteBodyV2 extends React.Component {
  state = {
    title: "",
    body: "",
    editMode: false,
    submitting: false,
    attachments: [],
    files: [],
  };

  onFileChange(e) {
    let files = [];

    files = e;

    console.log(files);

    this.setState({ files: files });
  }

  removeAttachment(attachment) {
    swal({
      title: "Remove Attachment",
      text: `Are you sure you want to remove the "${attachment?.fileName}" attachment?`,
      icon: "warning",
      buttons: ["Nevermind", "Remove"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      let attachments = this.state?.attachments?.length
        ? this.state?.attachments
        : [];

      attachments = _.filter(attachments, (at) => {
        return (
          at?.fileName != attachment?.fileName && at?.url != attachment?.url
        );
      });

      this.setState({
        attachments,
      });
    });
  }

  async uploadAttachments(files) {
    let attachments = this.state?.attachments?.length
      ? this.state.attachments
      : [];

    attachments = attachments.concat(files);

    this.setState({
      attachments,
    });

    return attachments;
  }

  loadTemplates(types) {
    this.setState({
      loadingTemplates: true,
    });

    let orList = types?.map((type) => {
      return { type: type };
    });

    let filters = {
      $or: orList,
    };

    APIV2.getGlobalNoteTemplates(1, 500, filters, { name: -1 })
      .then(
        (data) => {
          if (data && data.data) {
            let templates = [];

            if (data.data.templates.length) {
              templates = data.data.templates;
            }

            this.setState({
              templates,
            });
          }
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          loadingTemplates: false,
        });
      });
  }

  componentDidMount() {
    if (this.props.note) {
      this.setState({
        title: this.props.note?.title,
        body: this?.props?.note?.body,
        attachments: this?.props?.note?.attachments ?? [],
      });
    }

    if (this.props.defaultTitle) {
      this.setState({
        title: this.props.defaultTitle,
        showTitle: true,
      });
    }

    if (this.props.forceEdit) {
      this.setState({
        editMode: true,
        showTitle: true,
      });
    }

    if (this.props.templates && this.props.templateTypes?.length) {
      this.loadTemplates(this.props.templateTypes);
    }
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState(
      {
        [name]: value,
      },
      () => {
        if (typeof this.props.onChange == "function") {
          this.props.onChange({
            title: this.state.showTitle ? this.state.title : "",
            body: this.state.body,
            attachments: this.state.attachments,
          });
        }
      }
    );
  }

  async _saveNote() {
    let payload = {
      title: this.state.showTitle ? this.state.title : "",
      body: this.state.body,
    };

    console.log(payload);

    let promise = null;

    if (this.props.note) {
      promise = APIV2.modifyStoreNote(
        this.props?.note?.storeID ? this.props.note?.storeID : "none",
        this.props.note?._id,
        this.props.customer ? this.props?.customer?._id : null,
        this.props.task ? this.props?.task?._id : null,
        this.props.lead ? this.props?.lead?._id : null,
        this.props.affiliate ? this.props?.affiliate?._id : null,
        this.props.frandevLead ? this.props?.frandevLead?._id : null,
        this.props.appointment ? this.props.appointment?._id : null,
        this.props.productOrder ? this.props?.productOrder?._id : null,
        this.props.store ? true : false,
        payload.title,
        payload.body,
        this.state.attachments?.length > 0 ? this.state.attachments : []
      );
    } else {
      let storeID = "";

      if (this.props.task) {
        storeID = this.props.task?.storeID;
      }

      if (this.props.productOrder) {
        storeID = this.props.productOrder?.storeID;
      }

      if (this.props.customer) {
        storeID = this.props.customer?.storeID;
      }

      if (this.props.lead) {
        storeID = this.props?.lead?.storeID;
      }

      if (this.props.affiliate) {
        storeID = this.props?.affiliate?.storeID;
      }

      if (this.props.appointment) {
        storeID = this.props?.appointment?.storeID;
      }

      if (this.props.store) {
        storeID = this.props?.store?._id;
      }

      if (!storeID) {
        storeID = "none";
      }

      console.log(this.props.compliance);

      promise = APIV2.createStoreNote(
        storeID,
        this.props.customer ? this.props?.customer?._id : null,
        this.props.task ? this.props?.task?._id : null,
        this.props.lead ? this.props?.lead?._id : null,
        this.props.affiliate ? this.props?.affiliate?._id : null,
        this.props.frandevLead ? this.props?.frandevLead?._id : null,
        this.props.appointment ? this.props.appointment?._id : null,
        this.props.productOrder ? this.props?.productOrder?._id : null,
        this.props.store ? true : false,
        payload.title,
        payload.body,
        this.state.attachments?.length > 0 ? this.state.attachments : [],
        null,
        this.props.compliance ? true : false
      );
    }

    this.setState({
      submitting: true,
    });

    if (typeof this.props?.onSubmit == "function") {
      this.props.onSubmit();
    }

    promise
      .then(
        (data) => {
          this.setState({
            editMode: this.props.forceEdit ? true : false,
            title: "",
            body: { type: "doc", content: [{ type: "paragraph" }] },
            showTitle: this.props?.forceEdit ? true : false,
          });

          LocalStorage.set("note-content-" + this.props.id, {
            json: { type: "doc", content: [{ type: "paragraph" }] },
          });

          PubSub.publish("editor:contentUpdated", {
            event: "saveCompleted",
            id: this.props.id,
          });

          if (typeof this.props.onSave == "function") {
            this.props.onSave(data.data.note);
          }
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  async save() {
    this._saveNote();
  }

  cancel() {
    if (typeof this.props.onCancel == "function") {
      this.props.onCancel();
    }

    this.setState({
      editMode: this.props.forceEdit ? true : false,
      title: "",
      body: {},
      showTitle: this.props?.forceEdit ? true : false,
      attachments: [],
      files: [],
    });

    LocalStorage.set("note-content-" + this.props.id, {});
  }

  async openAttachment(attachment) {
    LinkUtils.openPrivateURL(attachment?.url);

    return;
  }

  componentDidUpdate(prevProps) {
    if (this.props.forceEdit != prevProps.forceEdit) {
      this.setState({
        editMode: this.props.forceEdit,
        showTitle: this.props.forceEdit,
      });
    }
  }

  render() {
    return (
      <>
        {this.state.editMode ? (
          <>
            <div
              style={{
                position: "relative",
                border: "1px solid #cad1d7",
                borderRadius: "0.375rem",
              }}
            >
              <Editor
                allowForceHydration={this.state.templateSelected}
                autofocus={true}
                editable={this.state.editable}
                id={this.props.id}
                debounceRate={750}
                saving={this.state.saving}
                allowTaskLists={true}
                autoSave={false}
                onChange={(data) => {
                  //console.log("ON CHANGE");

                  if (data?.clear) {
                  } else {
                    this.setState({
                      body: data,
                    });
                  }

                  //console.log(this.state.body);

                  if (this.state.templateSelected) {
                    this.setState({
                      templateSelected: false,
                    });
                  }

                  //LocalStorage.set("note-content-" + this.props.id, data);
                }}
                value={this.state.body}
              />
              {this.state?.attachments?.length > 0 ? (
                <>
                  <h5 className="px-2">Attachments: </h5>
                  {this.state.attachments?.map((at, i) => (
                    <div className={`p-2 border rounded mx-2 mb-2`} key={i}>
                      <Row className="align-items-center">
                        <Col xs="12" sm="">
                          <p className="small m-0 text-dark text-truncate">
                            {at?.fileName}
                          </p>
                        </Col>
                        <Col
                          xs="12"
                          sm="auto"
                          className="mt-3 mt-sm-0 text-right"
                        >
                          <Button
                            className="btn-icon-only"
                            size="sm"
                            outline
                            color="secondary"
                            onClick={() => {
                              this.openAttachment(at);
                            }}
                          >
                            <i className="mdi mdi-eye"></i>
                          </Button>
                          <Button
                            className="btn-icon-only"
                            size="sm"
                            outline
                            color="danger"
                            onClick={() => {
                              this.removeAttachment(at);
                            }}
                          >
                            <i className="mdi mdi-close"></i>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              ) : null}

              <hr className="my-0"></hr>
              <div className="py-1 pr-1">
                <Row className="align-items-center">
                  <Col xs="">
                    <Button
                      color=""
                      size="sm"
                      className="btn-link text-muted ml-0"
                      onClick={() => {
                        this.setState({
                          showAttachment: true,
                        });
                      }}
                    >
                      Add Attachments
                    </Button>
                    {this.state.templates ? (
                      <Button
                        color=""
                        size="sm"
                        className="btn-link text-muted ml-sm--3"
                        onClick={() => {
                          this.setState({
                            openTemplates: true,
                          });
                        }}
                      >
                        From Template
                      </Button>
                    ) : null}
                  </Col>
                  <Col xs="auto">
                    <Button
                      color="secondary"
                      size="sm"
                      outline
                      onClick={() => {
                        this.cancel();
                      }}
                      className="mr-0"
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => {
                        this.save();
                      }}
                      disabled={!this.state.body || this.state.submitting}
                    >
                      {this.state.submitting ? (
                        <Spinner size="sm" color="white"></Spinner>
                      ) : (
                        <>{this.props.note ? "Save" : "Add"}</>
                      )}
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <div
                onClick={() => {
                  this.setState(
                    {
                      editMode: true,
                    },
                    () => {}
                  );
                }}
                className="cursor-pointer form-control"
              >
                Add a note
              </div>
            </div>
          </>
        )}
        <FileUploadDrawer
          cdn="private"
          compressUploads={true}
          size="sm"
          onComplete={(files) => {
            console.log(files);

            this.setState({
              showAttachment: false,
            });

            this.uploadAttachments(files);
          }}
          open={this.state.showAttachment}
          onClose={() => {
            this.setState({
              showAttachment: false,
            });
          }}
        ></FileUploadDrawer>

        <Drawer
          style={{ maxWidth: "100%" }}
          open={this.state.openTemplates}
          onClose={() => {
            this.setState({
              openTemplates: false,
            });
          }}
          size="sm"
        >
          <Drawer.Header className="pr-4 border-bottom-0">
            <h3 className="mb-2" style={{ position: "relative", top: "7px" }}>
              Note Templates
            </h3>
          </Drawer.Header>
          <Drawer.Body className="p-0" style={{ height: "calc(100% - 70px)" }}>
            {this.state.loadingTemplates ? (
              <div className="py-3 text-center">
                <Spinner size="sm"></Spinner>
              </div>
            ) : (
              <div>
                {this.state.templates?.map((template, i) => (
                  <div
                    key={i}
                    onClick={() => {
                      this.setState({
                        openTemplates: false,
                        body: template?.template,
                        templateSelected: true,
                        selectedTemplate: template?.template,
                      });

                      //console.log("HERE");

                      PubSub.publish("editor:contentUpdated", {
                        event: "contentUpdated",
                        forceHydrate: true,
                        id: this.props.id,
                        data: template?.template,
                      });
                    }}
                    className={`p-3 cursor-pointer bg-superlight--hover border-bottom ${
                      i == 0 ? "border-top" : ""
                    }`}
                  >
                    <Row className="align-items-center">
                      <Col xs="">
                        <h3 className="m-0">{template?.name}</h3>
                        {template?.description ? (
                          <p className="m-0">{template?.description}</p>
                        ) : null}
                      </Col>
                      <Col xs="auto">
                        <Button
                          color="dark"
                          outline
                          className="btn-icon-only"
                          size="sm"
                        >
                          <i className="mdi mdi-chevron-right"></i>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>
            )}
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default NoteBodyV2;
