import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
  Badge,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import { Editor } from "react-draft-wysiwyg";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import _, { all, isNumber } from "underscore";
import APIV2 from "lib/APIV2";

class CreateMealModal extends React.Component {
  state = {
    open: false,
    name: "",
    submitting: false,
    step: 1,
    descState: EditorState.createEmpty(),
    selectedFile: null,
    allergens: [],
    categories: [],
    loadingAllergens: true,
    loadingCategories: true,
    calories: null,
    carbs: null,
    protein: null,
    fat: null,
    fiber: null,
    sugar: null,
    sodium: null,
    foodWeight: null,
    finishedWeight: null,
    draft: true,
    zohoApi: "",
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  handleNameChange(event) {
    const val = event.target.value;

    this.setState({
      name: val,
    });
  }

  handleCalorieChange(event) {
    const val = event.target.value;

    this.setState({
      calories: val,
    });
  }

  handleZohoChange(event) {
    const val = event.target.value;

    this.setState({
      zohoApi: val,
    });
  }

  handleCarbChange(event) {
    const val = event.target.value;

    this.setState({
      carbs: val,
    });
  }

  handleProteinChange(event) {
    const val = event.target.value;

    this.setState({
      protein: val,
    });
  }

  handleFatChange(event) {
    const val = event.target.value;

    this.setState({
      fat: val,
    });
  }

  handleFiberChange(event) {
    const val = event.target.value;

    this.setState({
      fiber: val,
    });
  }

  handleSugarChange(event) {
    const val = event.target.value;

    this.setState({
      sugar: val,
    });
  }

  handleSodiumChange(event) {
    const val = event.target.value;

    this.setState({
      sodium: val,
    });
  }

  handleFoodWeightChange(event) {
    const val = event.target.value;

    this.setState({
      foodWeight: val,
    });
  }

  handleFinishedWeightChange(event) {
    const val = event.target.value;

    this.setState({
      finishedWeight: val,
    });
  }

  submit(e) {
    e.preventDefault();

    let content = draftToHtml(
      convertToRaw(this.state.descState.getCurrentContent())
    );

    if (!this.state.name || !this.state.selectedFile || !content) {
      window.alert(
        "Please include a name, description, and image to continue."
      );

      return;
    }

    let calories = parseInt(this.state.calories);
    let carbs = parseInt(this.state.carbs);
    let fat = parseInt(this.state.fat);
    let protein = parseInt(this.state.protein);
    let sugar = parseInt(this.state.sugar);
    let fiber = parseInt(this.state.fiber);
    let sodium = parseInt(this.state.sodium);
    let foodWeight = parseFloat(this.state.foodWeight);
    let finishedWeight = parseFloat(this.state.finishedWeight);

    if (
      !isNumber(calories) ||
      !isNumber(carbs) ||
      !isNumber(fat) ||
      !isNumber(protein) ||
      !isNumber(sugar) ||
      !isNumber(fiber) ||
      !isNumber(sodium) ||
      !isNumber(foodWeight) ||
      !isNumber(finishedWeight) ||
      calories < 0 ||
      carbs < 0 ||
      fat < 0 ||
      protein < 0 ||
      sugar < 0 ||
      fiber < 0 ||
      foodWeight < 0 ||
      finishedWeight < 0
    ) {
      window.alert(
        "Please include valid macros and production information to continue."
      );

      return;
    }

    let hasCategory = false;

    for (let i = 0; i < this.state.categories.length; i++) {
      if (this.state.categories[i].checked) {
        hasCategory = true;
      }
    }

    if (!hasCategory) {
      window.alert(
        "Please select at least one category for this meal to continue."
      );

      return;
    }

    if (!this.state.zohoApi) {
      window.alert("Please add the Zoho API Name for the meal to continue.");

      return;
    }

    this.setState({
      submitting: true,
    });

    // Create an object of formData
    let formData = new FormData();

    formData.append("file", this.state.selectedFile);

    APIV2.uploadCDNImage(formData).then(
      (data) => {
        let url = data.data.data.url;

        let allergens = [];
        let categories = [];

        for (let i = 0; i < this.state.allergens.length; i++) {
          if (this.state.allergens[i].checked) {
            allergens.push(this.state.allergens[i]._id);
          }
        }

        for (let i = 0; i < this.state.categories.length; i++) {
          if (this.state.categories[i].checked) {
            categories.push(this.state.categories[i]._id);
          }
        }

        APIV2.createMeal(
          this.state.name,
          this.state.zohoApi,
          content,
          url,
          this.props.group._id,
          calories,
          carbs,
          protein,
          fat,
          sugar,
          fiber,
          sodium,
          foodWeight,
          finishedWeight,
          categories,
          allergens
        )
          .then(
            (data) => {
              let meal = data.data.meal;

              PubSub.publish(Event.MEAL.CREATED, meal);
              this.reset();
              this.toggleModal("open");
            },
            (e) => {
              window.alert("Failed to create meal. Please try again.");
            }
          )
          .finally(() => {
            this.setState({
              submitting: false,
            });
          });
      },
      (e) => {
        window.alert("Failed to upload meal image. Please try again.");

        this.setState({
          submitting: false,
        });
      }
    );
  }

  reset() {
    this.setState({
      calories: null,
      carbs: null,
      protein: null,
      fat: null,
      fiber: null,
      sugar: null,
      sodium: null,
      foodWeight: null,
      finishedWeight: null,
      name: "",
      descState: EditorState.createEmpty(),
      step: 1,
      selectedFile: null,
      draft: true,
      zohoApi: "",
    });

    document.getElementById("createBAFile").value = "";

    let allergens = this.state.allergens;
    let categories = this.state.categories;

    for (let i = 0; i < allergens.length; i++) {
      allergens[i].checked = false;
    }

    for (let i = 0; i < categories.length; i++) {
      categories[i].checked = false;
    }

    this.setState({
      allergens: allergens,
      categories: categories,
    });
  }

  componentDidMount() {
    PubSub.subscribe(Event.MEAL.CREATE_OPEN, () => {
      this.setState({
        open: true,
      });

      if (!this.state.allergens.length) {
        this.setState({
          loadingAllergens: true,
        });
      }

      if (!this.state.categories.length) {
        this.setState({
          loadingCategories: true,
        });
      }

      APIV2.getCategories().then(
        (data) => {
          for (let i = 0; i < data.data.categories.length; i++) {
            data.data.categories[i].checked = false;
          }

          this.setState({
            categories: data.data.categories,
            loadingCategories: false,
          });
        },
        (e) => {
          console.error(e);
          window.alert(
            "We were unable to load the meal categories. Please reload and try again."
          );
        }
      );

      APIV2.getAllergens().then(
        (data) => {
          for (let i = 0; i < data.data.allergens.length; i++) {
            data.data.allergens[i].checked = false;
          }

          this.setState({
            allergens: data.data.allergens,
            loadingAllergens: false,
          });
        },
        (e) => {
          console.error(e);
          window.alert(
            "We were unable to load the meal allergens. Please reload and try again."
          );
        }
      );
    });
  }

  toggleAllergen(allergen) {
    let allergens = this.state.allergens;

    let idx = _.findIndex(allergens, { _id: allergen._id });

    if (idx >= 0) {
      allergens[idx].checked = !allergens[idx].checked;
    }

    this.setState({
      allergens: allergens,
    });
  }

  toggleCategory(c) {
    let categories = this.state.categories;

    let idx = _.findIndex(categories, { _id: c._id });

    if (idx >= 0) {
      categories[idx].checked = !categories[idx].checked;
    }

    this.setState({
      categories: categories,
    });
  }

  toggleDraft() {
    this.setState({
      draft: !this.state.draft,
    });
  }

  onDescriptionChange(editorState) {
    this.setState({
      descState: editorState,
    });
  }

  onFileChange(e) {
    this.setState({ selectedFile: e.target.files[0] });
  }

  render() {
    return (
      <>
        <Modal
          size="lg"
          isOpen={this.state.open}
          toggle={() => this.toggleModal("open")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="createBAModalLabel">
              Add {this.props.group ? this.props.group.name : ""}&nbsp;Meal
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("open")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div>
            <Row className="mt-2">
              <Col className="text-center">
                <Badge color={this.state.step == 1 ? "primary" : "light"}>
                  1
                </Badge>
                <br></br>
                <small>Details</small>
              </Col>
              <Col className="text-center">
                <Badge color={this.state.step == 2 ? "primary" : "light"}>
                  2
                </Badge>
                <br></br>
                <small>Product</small>
              </Col>
              <Col className="text-center">
                <Badge color={this.state.step == 3 ? "primary" : "light"}>
                  3
                </Badge>
                <br></br>
                <small>Categories</small>
              </Col>
            </Row>
            <hr className="mt-2 mb-2"></hr>
          </div>
          <Form onSubmit={this.submit.bind(this)}>
            {this.state.step == 1 ? (
              <div className="modal-body">
                <h5>Meal Name</h5>
                <Input
                  id="createBAName"
                  placeholder="Name"
                  type="text"
                  className="mb-3"
                  required={true}
                  value={this.state.name}
                  onChange={this.handleNameChange.bind(this)}
                />
                <div className="mb-4">
                  <h5>Meal Description</h5>
                  <Editor
                    editorState={this.state.descState}
                    onEditorStateChange={this.onDescriptionChange.bind(this)}
                    placeholder="Description"
                  ></Editor>
                </div>
                <h5>Meal Image</h5>
                <Input
                  id="createBAFile"
                  className="mb-4"
                  type="file"
                  accept="image/*"
                  required={true}
                  onChange={this.onFileChange.bind(this)}
                />
                <hr />
                <h5 className="mb-0">Zoho API Name</h5>
                <small>
                  This is used to automatically sync the meal with Zoho
                  Subscriptions.
                </small>
                <Input
                  id="createBAName"
                  placeholder="Zoho API Name"
                  type="text"
                  className="mb-3 mt-3"
                  required={true}
                  value={this.state.zohoApi}
                  onChange={this.handleZohoChange.bind(this)}
                />
              </div>
            ) : null}
            {this.state.step == 2 ? (
              <div className="modal-body">
                <h3>Macros</h3>
                <Row>
                  <Col xs="12" sm="6">
                    <h5>Calories</h5>
                    <FormGroup>
                      <InputGroup>
                        <Input
                          placeholder="Calories"
                          type="tel"
                          required={true}
                          value={this.state.calories}
                          onChange={this.handleCalorieChange.bind(this)}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>CAL</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6">
                    <h5>Carbohydrates</h5>
                    <FormGroup>
                      <InputGroup>
                        <Input
                          placeholder="Carbs"
                          type="tel"
                          required={true}
                          value={this.state.carbs}
                          onChange={this.handleCarbChange.bind(this)}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>G</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6">
                    <h5>Protein</h5>
                    <FormGroup>
                      <InputGroup>
                        <Input
                          placeholder="Protein"
                          type="tel"
                          required={true}
                          value={this.state.protein}
                          onChange={this.handleProteinChange.bind(this)}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>G</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6">
                    <h5>Fats</h5>
                    <FormGroup>
                      <InputGroup>
                        <Input
                          placeholder="Fat"
                          type="tel"
                          required={true}
                          value={this.state.fat}
                          onChange={this.handleFatChange.bind(this)}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>G</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6">
                    <h5>Dietary Fiber</h5>
                    <FormGroup>
                      <InputGroup>
                        <Input
                          placeholder="Fiber"
                          type="tel"
                          required={true}
                          value={this.state.fiber}
                          onChange={this.handleFiberChange.bind(this)}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>G</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6">
                    <h5>Total Sugar</h5>
                    <FormGroup>
                      <InputGroup>
                        <Input
                          placeholder="Sugar"
                          type="tel"
                          required={true}
                          value={this.state.sugar}
                          onChange={this.handleSugarChange.bind(this)}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>G</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6">
                    <h5>Sodium</h5>
                    <FormGroup>
                      <InputGroup>
                        <Input
                          placeholder="Sodium"
                          type="tel"
                          required={true}
                          value={this.state.sodium}
                          onChange={this.handleSodiumChange.bind(this)}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>MG</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <hr></hr>
                <h3>
                  Allergens{" "}
                  {this.state.loadingAllergens ? (
                    <span className="badge badge-info">Loading...</span>
                  ) : null}
                </h3>
                <small>Select all that apply.</small>
                <Row className="mt-2">
                  {this.state.allergens.map((a, i) => (
                    <Col xs="12" sm="6">
                      <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                        <input
                          className="custom-control-input"
                          id={"allergenCheck" + i}
                          type="checkbox"
                          onChange={() => {
                            this.toggleAllergen(a);
                          }}
                          checked={a.checked}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={"allergenCheck" + i}
                        >
                          {a.name}
                        </label>
                      </div>
                    </Col>
                  ))}
                </Row>

                <hr></hr>
                <h3>End Product</h3>
                <Row>
                  <Col xs="12" sm="6">
                    <h5>Food Weight</h5>
                    <FormGroup>
                      <InputGroup>
                        <Input
                          placeholder="Food Weight"
                          type="tel"
                          required={true}
                          value={this.state.foodWeight}
                          onChange={this.handleFoodWeightChange.bind(this)}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>OZ</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6">
                    <h5>Finished Weight</h5>
                    <FormGroup>
                      <InputGroup>
                        <Input
                          placeholder="Finished Weight"
                          type="tel"
                          required={true}
                          value={this.state.finishedWeight}
                          onChange={this.handleFinishedWeightChange.bind(this)}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>OZ</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            ) : null}
            {this.state.step == 3 ? (
              <div className="modal-body">
                <h3 className="mb-2">
                  Categories
                  {this.state.loadingCategories ? (
                    <span className="badge badge-info">Loading...</span>
                  ) : null}
                </h3>
                <small>Select all that apply.</small>
                <Row className="mt-3">
                  {this.state.categories.map((c, i) => (
                    <Col xs="12" sm="6">
                      <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                        <input
                          className="custom-control-input"
                          id={"catCheck" + i}
                          type="checkbox"
                          onChange={() => {
                            this.toggleCategory(c);
                          }}
                          checked={c.checked}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={"catCheck" + i}
                        >
                          {c.name}
                        </label>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            ) : null}
            <div className="modal-footer">
              <Button
                color="danger"
                type="button"
                className="mr-auto"
                onClick={() => {
                  this.reset();
                }}
                disabled={this.state.submitting}
              >
                Reset
              </Button>
              {this.state.step == 1 ? (
                <Button
                  color="secondary"
                  data-dismiss="modal"
                  type="reset"
                  onClick={() => this.toggleModal("open")}
                >
                  Close
                </Button>
              ) : null}
              {this.state.step > 1 ? (
                <Button
                  color="secondary"
                  type="button"
                  onClick={() => {
                    this.setState({ step: this.state.step - 1 });
                  }}
                  disabled={this.state.submitting}
                >
                  Back
                </Button>
              ) : null}
              {this.state.step < 3 ? (
                <Button
                  color="primary"
                  type="button"
                  onClick={() => {
                    this.setState({ step: this.state.step + 1 });
                  }}
                  disabled={this.state.submitting}
                >
                  Next
                </Button>
              ) : null}
              {this.state.step == 3 ? (
                <Button
                  color="primary"
                  type="button"
                  onClick={this.submit.bind(this)}
                  disabled={this.state.submitting}
                >
                  Add
                </Button>
              ) : null}
            </div>
          </Form>
        </Modal>
      </>
    );
  }
}

export default CreateMealModal;
