import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Alert,
} from "reactstrap";
import API from "lib/API";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import User from "lib/Model/User";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import APIV2 from "lib/APIV2";
import NumberFormat from "react-number-format";
import Spinner from "reactstrap/lib/Spinner";

let logo = require("assets/img/brand/pln_primary_no_dot.svg");

class Register extends React.Component {
  state = {
    submitting: false,
    email: "",
    password: "",
    error: false,
    phone: {
      value: "",
    },
    name: "",
    token: "",
  };

  handleInputChange(event) {
    const val = event.target.value;

    this.setState({
      [event.target.name]: val,
    });
  }

  handlePhoneChange(val) {
    this.setState({
      phone: val,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const name = urlParams.get("name");
    const email = urlParams.get("email");
    const token = urlParams.get("token");

    if (!token) {
      this.setState({
        error:
          "Your invite has expired, please reach out to the Storefront administator to request a new one.",
      });

      return;
    } else {
      this.setState({ token });
    }

    if (name) {
      this.setState({ name });
    }

    if (email) {
      this.setState({ email });
    }
  }

  signIn(e) {
    let self = this;

    e.preventDefault();

    let email = this.state.email;
    let password = this.state.password;

    if (!email) {
      return;
    }

    if (password?.length < 8) {
      return;
    }

    this.setState({
      submitting: true,
      error: false,
    });

    APIV2.registerUser(
      this.state.name,
      email,
      this.state.phone?.value,
      password,
      this.state?.token
    )
      .then(
        (data) => {
          let url = "/storefront";

          if (data?.data?.authenticationToken) {
            API.setAuthToken(data.data.authenticationToken);
            APIV2.setAuthToken(data.data.authenticationToken);
            APIV2._wsConnect();
            LocalStorage.set(
              Constant.CACHE.TOKEN,
              data.data.authenticationToken
            );
            console.log(LocalStorage.get(Constant.CACHE.TOKEN));

            let user = null;

            if (data?.data?.user) {
              console.log(data.data.user);

              data.data.user.id = data.data.user._id;

              user = data.data.user;

              user.id = user?._id;

              window[Constant.CACHE.USER] = user;

              console.log(window[Constant.CACHE.USER]);

              LocalStorage.set(Constant.CACHE.USER, user);

              PubSub.publish(Event.USER_LOADED, user);

              if (user?.globalPermissions?.length) {
                url = "/frandev/stores";
              } else if (user?.storePermissions?.length) {
                url += `/${user?.storePermissions[0]?.storeID}`;
              }
            }

            console.log(url, user);

            self.props.history.push(url);
          }
        },
        (error) => {
          console.error(error?.response);

          let message = error?.response?.body?.message;

          this.setError(
            "error",
            message
              ? message
              : "Unabled to create account. Please try again or contact PLN Support."
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  render() {
    // Redirect if already authenticated
    if (LocalStorage.get(Constant.CACHE.TOKEN)) {
      this.props.history.push("/admin");
    }

    return (
      <>
        <Col lg="6" md="7">
          <Card className="bg-white shadow border-0">
            <CardHeader className="bg-transparent py-4 px-5">
              <img
                alt="logo"
                src={logo}
                style={{
                  width: "90%",
                  maxWidth: "220px",
                  position: "relative",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              ></img>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <h2 className="text-center">Welcome to Storefront</h2>
              <div className="text-center text-muted mb-5">
                Enter your information below to create an account and access
                your Storefront.
              </div>
              <Form role="form" onSubmit={this.signIn.bind(this)}>
                <FormGroup className="mb-3">
                  <h5>Name</h5>
                  <Input
                    required={true}
                    onChange={this.handleInputChange.bind(this)}
                    value={this.state.name}
                    name="name"
                    placeholder="Name"
                    type="text"
                    autoComplete="name"
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <h5>Email</h5>
                  <Input
                    required={true}
                    onChange={this.handleInputChange.bind(this)}
                    value={this.state.email}
                    placeholder="Email"
                    name="email"
                    type="email"
                    autoComplete="new-email"
                  />
                </FormGroup>
                <FormGroup>
                  <h5>Phone</h5>
                  <NumberFormat
                    className="form-control mb-3"
                    format="(###) ###-####"
                    mask=""
                    name="phoneNumberInput"
                    placeholder="Phone Number"
                    onValueChange={this.handlePhoneChange.bind(this)}
                    value={this.state.phone?.value}
                    type="tel"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <h5 className="mb-0">Password</h5>
                  <div className="mb-2 mt-0" sytle={{ lineHeight: 1.2 }}>
                    <small>Must be 8+ characters long.</small>
                  </div>
                  <Input
                    required={true}
                    onChange={this.handleInputChange.bind(this)}
                    value={this.state.password}
                    name="password"
                    placeholder="New Password"
                    type="password"
                    autoComplete="new-password"
                  />
                </FormGroup>

                <div className="text-center">
                  <Button
                    disabled={
                      this.state.submitting ||
                      !this.state.name ||
                      !this.state.email ||
                      this.state.password?.length < 8 ||
                      !this.state.token ||
                      !this.state.phone?.value
                    }
                    className="my-4 px-5"
                    color="primary"
                    type="submit"
                  >
                    {this.state.submitting ? (
                      <Spinner size="sm" color="white"></Spinner>
                    ) : (
                      "Create Account"
                    )}
                  </Button>
                </div>
                {this.state.error ? (
                  <div className="text-center">
                    <Alert color="danger">{this.state.error}</Alert>
                  </div>
                ) : null}
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            {/*<Col xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a>
            </Col>*/}
          </Row>
        </Col>
      </>
    );
  }
}

export default Register;
