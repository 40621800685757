import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  BarChart,
  BarList,
  Card,
  DonutChart,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import {
  Axis,
  Chart,
  Coordinate,
  Interaction,
  Interval,
  Legend,
  Tooltip,
} from "bizcharts";
import ChartMetricHeader from "./ChartMetricHeader";

class OutreachByStorePieChartCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    tab: "overall",
    metricNames: ["or-popup", "or-groupsweat", "or-dropin", "or-inreach"],
  };

  /**
   * Fetches the order data by week for the given stores & date range
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchData(cubejsApi, stores, dateRange, metricNames = []) {
    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["Appointments.count"],
          order: {
            "Appointments.startsat": "desc",
          },
          timeDimensions: [
            {
              dimension: "Appointments.startsat",
              dateRange: [dateRange[0], dateRange[1]],
            },
          ],
          filters: [
            {
              member: "Appointments.storeid",
              operator: "equals",
              values: stores,
            },
            {
              member: "Appointments.appointmenttype",
              operator: "equals",
              values: metricNames,
            },
            {
              member: "Appointments.status",
              operator: "equals",
              values: ["COMPLETED"],
            },
          ],
          dimensions: ["Stores.name"],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          if (!data?.length) {
            return resolve(null);
          }

          let total = 0;

          data = data.map((item) => {
            total += item["Appointments.count"];

            return {
              storeName: item["Stores.name"]
                ?.replace("Project LeanNation", "PLN")
                ?.trim(),

              count: item["Appointments.count"],
            };
          });

          let outByStore2 = data.map((item) => {
            return {
              storeName: item?.storeName,
              Events: item?.count,
            };
          });

          return resolve({
            byStore: outByStore2,
            total,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async loadDetailedReport(stores, dateRange) {
    this.setState({
      loading: true,
      dataAvailable: false,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!stores?.length || !dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchData(
        cubejsApi,
        stores,
        dateRange,
        this.state.metricNames
      );
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load total new members by store.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
        loading: false,
      });

      return;
    }

    let colors = StringUtils.randomChartColorSet(currentCount.byStore?.length);

    console.log(currentCount?.byStore);

    this.setState({
      currentCount: currentCount,
      loading: false,
      byStore: currentCount?.byStore,
      byStoreColors: colors,
      total: currentCount?.total,
    });

    if (typeof this.props.onLoaded == "function") {
      this.props.onLoaded();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stores != prevProps?.stores ||
      this.props.dateRange != prevProps?.dateRange
    ) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload != prevProps.reload && this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  componentDidMount() {
    if (this.props.store && this.props.dateRange) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  render() {
    return (
      <>
        <Card marginTop="mt-0">
          <Row className="align-items-center">
            <Col xs="">
              <h3 className="m-0 text-dark">Event Distribution</h3>
            </Col>
            <Col xs="auto">
              <Button
                size="sm"
                outline
                color="dark"
                className="border-0 btn-icon-only"
                disabled={this.state.loading}
                onClick={() => {
                  this.loadDetailedReport(
                    this.props.stores,
                    this.props.dateRange
                  );
                }}
              >
                {this.state.loading ? (
                  <Spinner size="sm"></Spinner>
                ) : (
                  <i className="mdi mdi-refresh"></i>
                )}
              </Button>
            </Col>
          </Row>
          <TabList
            color="orange"
            defaultValue="overall"
            handleSelect={(value) => {
              this.setState({
                tab: value,
                loading: true,
              });

              let metricNames = [];

              if (value == "overall") {
                metricNames = [
                  "or-popup",
                  "or-groupsweat",
                  "or-dropin",
                  "or-inreach",
                ];
              } else if (value == "Pop-Up Events") {
                metricNames = ["or-popup"];
              } else if (value == "Group Sweats") {
                metricNames = ["or-groupsweat"];
              } else if (value == "Drop-Ins") {
                metricNames = ["or-dropin"];
              } else if (value == "InReach") {
                metricNames = ["or-inreach"];
              }

              this.setState(
                {
                  metricNames,
                },
                () => {
                  this.loadDetailedReport(
                    this.props.stores,
                    this.props.dateRange
                  );
                }
              );
            }}
            marginTop="mt-3"
            disabled={this.state.loading || !this.state.dataAvailable}
          >
            <Tab value="overall" text="Overall" />
            <Tab value="Pop-Up Events" text="Pop-Up Events" />
            <Tab value="Group Sweats" text="Group Sweats" />
            <Tab value="Drop-Ins" text="Drop-Ins" />
            <Tab value="InReach" text="InReach Events" />
          </TabList>

          {this.state.loading ? (
            <div
              className="skeleton mt-3"
              style={{ height: "calc(327px + 1rem)", width: "100%" }}
            >
              &nbsp;
            </div>
          ) : (
            <>
              {this.state.dataAvailable ? (
                <>
                  <div className="mt-3 mx--4 px-4 ">
                    <Row className="align-items-center">
                      <Col xs="12" sm="12" md="6">
                        <div className="pt-4">
                          <DonutChart
                            data={this.state.byStore}
                            category={"Events"}
                            dataKey="storeName"
                            colors={this.state.byStoreColors}
                            valueFormatter={(number) => {
                              return (
                                StringUtils.numberFormat(number) + " Events"
                              );
                            }}
                            height="h-80"
                          />
                        </div>
                      </Col>
                      <Col md="6" className="mt-3 mt-md-0">
                        <div
                          className="pt-4 mb--4 pb-4"
                          style={{
                            maxHeight: 368,
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          <BarList
                            data={this.state.byStore?.map((item) => {
                              return {
                                name: item?.storeName,
                                value: item?.Events,
                              };
                            })}
                            valueFormatter={(number) => {
                              return (
                                StringUtils.numberFormat(number) +
                                " Events " +
                                `[${
                                  this.state.total
                                    ? (
                                        (number / this.state.total) *
                                        100
                                      ).toFixed(1)
                                    : 0
                                }%]`
                              );
                            }}
                            color="orange"
                            showAnimation={true}
                            marginTop="mt-0"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "calc(327px + 1rem)", width: "100%" }}
                >
                  <p className="m-0">
                    No data available.
                    {this.state.error ? ` ${this.state.error}` : null}
                  </p>
                </div>
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default OutreachByStorePieChartCard;
