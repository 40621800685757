import cubejs from "@cubejs-client/core";
import { AreaChart, Card, Tab, TabList } from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import ChartMetricHeader from "./ChartMetricHeader";
import StoreDailyInventoryCardDetailDrawer from "./StoreDailyInventoryCardDetailDrawer";

class StoreDailyInventoryChartCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: true,
    activeTab: "A",
    dataSet: [],
    tab: "summary",
    categories: ["Meals", "Previous"],
    dataKey: "date",
    colors: ["orange", "gray"],
  };

  /**
   * Fetches a summary of all the lead data
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchLeadData(cubejsApi, stores, dateRange) {
    let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

    secondDifference = secondDifference / 1000;

    let granularity = "day";

    return new Promise((resolve, reject) => {
      let endDate6 = moment(dateRange[1]);

      let now = moment();

      if (endDate6.isAfter(now)) {
        endDate6 = now;
      }

      // Load
      cubejsApi
        .load({
          measures: [
            "StoreClosingInventoryReports.totalquantity",
            "StoreClosingInventoryReports.athletequantity",
            "StoreClosingInventoryReports.lifestylequantity",
            "StoreClosingInventoryReports.leancheatquantity",
            "StoreClosingInventoryReports.totalunitcost",
            "StoreClosingInventoryReports.athletemealtotalunitcost",
            "StoreClosingInventoryReports.lifestylemealtotalunitcost",
            "StoreClosingInventoryReports.leancheattotalunitcost",
            "StoreClosingInventoryReports.totalmealvalue",
            "StoreClosingInventoryReports.athletemealvalue",
            "StoreClosingInventoryReports.lifestylemealvalue",
            "StoreClosingInventoryReports.leancheatvalue",
          ],
          order: {
            "StoreClosingInventoryReports.date": "asc",
          },

          timeDimensions: [
            {
              dimension: "StoreClosingInventoryReports.date",
              dateRange: [moment(dateRange[0]).toDate(), endDate6?.toDate()],
              granularity: "day",
            },
          ],
          filters: [
            {
              member: "StoreClosingInventoryReports.storeid",
              operator: "equals",
              values: stores,
            },
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          let total = 0;
          let lifestyleTotal = 0;
          let athleteTotal = 0;
          let leanCheatTotal = 0;
          let count = 0;

          if (!data?.length) {
            return resolve(null);
          }

          data = data.map((item) => {
            total += item["StoreClosingInventoryReports.totalquantity"];
            lifestyleTotal +=
              item["StoreClosingInventoryReports.athletequantity"];
            athleteTotal +=
              item["StoreClosingInventoryReports.lifestylequantity"];
            leanCheatTotal +=
              item["StoreClosingInventoryReports.leancheatquantity"];
            count++;

            if (
              !stores.includes(item["StoreClosingInventoryReports.storeid"])
            ) {
              stores.push(item["StoreClosingInventoryReports.storeid"]);
            }

            let mom = moment(
              item[`StoreClosingInventoryReports.date.${granularity}`]
            );

            let granularityString = mom.format("MM/DD/YYYY");

            return {
              count: item["StoreClosingInventoryReports.totalquantity"],
              lifestyleCount:
                item["StoreClosingInventoryReports.lifestylequantity"],
              athleteCount:
                item["StoreClosingInventoryReports.athletequantity"],
              leancheatCount:
                item["StoreClosingInventoryReports.leancheatquantity"],
              lifestyleUnitCost:
                item["StoreClosingInventoryReports.lifestylemealtotalunitcost"],
              athleteUnitCost:
                item["StoreClosingInventoryReports.athletemealtotalunitcost"],
              leancheatUnitCost:
                item["StoreClosingInventoryReports.leancheattotalunitcost"],
              totalUnitCost: item["StoreClosingInventoryReports.totalunitcost"],
              lifestyleValue:
                item["StoreClosingInventoryReports.lifestylemealvalue"],
              athleteValue:
                item["StoreClosingInventoryReports.athletemealvalue"],
              leancheatValue:
                item["StoreClosingInventoryReports.leancheatvalue"],
              totalValue: item["StoreClosingInventoryReports.totalmealvalue"],
              dateObj: mom.toDate(),
              date: granularityString,
              dateKey: granularityString,
            };
          });

          return resolve({
            total,
            average: Math.round(total / count),
            history: data,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async _fetchLeadReport(stores, dateRange) {
    this.setState({
      loading: true,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    if (!stores?.length) {
      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchLeadData(cubejsApi, stores, dateRange);
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load order quantity.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });
    }

    if (this.props.comparePrevious) {
      let previousCount = null;

      let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

      secondDifference = secondDifference / 1000;

      let granularity = "day";

      let startMoment = moment(dateRange[0].toISOString());
      let endMoment = moment(dateRange[1].toISOString());

      startMoment.subtract(secondDifference, "seconds");
      endMoment.subtract(secondDifference + 1, "seconds");

      try {
        previousCount = await this._fetchLeadData(cubejsApi, stores, [
          startMoment.toDate(),
          endMoment.toDate(),
        ]);
      } catch (e) {}

      if (previousCount !== null) {
        let percentChange =
          previousCount?.total > 0
            ? (currentCount?.total - previousCount?.total) /
              previousCount?.total
            : null;

        let declineMode = "";
        let isNegative = false;

        if (percentChange > 0) {
          if (Math.abs(percentChange) < 0.015) {
            declineMode = "unchanged";
          } else if (Math.abs(percentChange) < 0.1) {
            declineMode = "moderateIncrease";
          } else if (Math.abs(percentChange) >= 0.1) {
            declineMode = "increase";
          }
        } else {
          isNegative = true;

          if (Math.abs(percentChange) < 0.015) {
            declineMode = "moderateDecrease";
          } else if (Math.abs(percentChange) < 0.1) {
            declineMode = "moderateDecrease";
          } else if (Math.abs(percentChange) >= 0.1) {
            declineMode = "decrease";
          }
        }

        let dataSet = [];

        for (let i = 0; i < currentCount?.history?.length; i++) {
          const cur = currentCount.history[i];

          const offsetDate = moment(cur?.dateObj?.toISOString()).subtract(
            secondDifference,
            "seconds"
          );

          offsetDate.startOf("day");

          let granularityString = offsetDate.format("MM/DD/YYYY");

          console.log(granularity);

          let prev = _.find(previousCount?.history, (item) => {
            return item?.date?.includes(
              granularityString?.replace("Week ", "")
            );
          });

          if (!prev) {
            prev = _.find(currentCount?.history, (item) => {
              return item?.dateKey?.includes(
                granularityString?.replace("Week ", "")
              );
            });
          }

          let out = {
            dateObj: cur?.dateObj,
            dateKey: granularityString,
            date: cur?.date + ` vs. ${granularityString?.replace("Week ", "")}`,
            curDate: cur?.date,
            Meals: cur?.count,
            "Lifestyle Meals": cur?.lifestyleCount,
            "Athlete Meals": cur?.athleteCount,
            "Lean Cheats": cur?.leancheatCount,
            "Lifestyle Unit Cost": cur?.lifestyleUnitCost,
            "Athlete Unit Cost": cur?.athleteUnitCost,
            "Lean Cheats Unit Cost": cur?.leancheatUnitCost,
            "Total Unit Cost": cur?.totalUnitCost,
            "Lifestyle Value": cur?.lifestyleValue,
            "Athlete Value": cur?.athleteValue,
            "Lean Cheats Value": cur?.leancheatValue,
            "Total Value": cur?.totalValue,
            "Previous Unit Cost": prev?.totalUnitCost !== null && prev?.totalUnitCost !== undefined ? prev.totalUnitCost : 0,
            "Previous Value": prev?.totalValue !== null && prev?.totalValue !== undefined ? prev.totalValue : 0,
            Previous:
              prev?.count !== null && prev?.count !== undefined
                ? prev.count
                : 0,
          };

          dataSet.push(out);
        }

        for (let i = 0; i < previousCount?.history?.length; i++) {
          const cur = previousCount.history[i];

          const offsetDate = moment(cur?.dateObj?.toISOString()).add(
            secondDifference,
            "seconds"
          );

          offsetDate.startOf("day").day(6);

          let granularityString = offsetDate.format("MM/DD/YYYY");

          if (granularity == "week") {
            offsetDate.day(6).startOf("day");
            granularityString = "Week " + offsetDate.format("MM/DD/YYYY");
          }

          if (granularity == "month") {
            offsetDate.startOf("month");

            granularityString = offsetDate.format("MMMM YY");
          }

          console.log(dataSet, granularityString);

          const prev = _.find(dataSet, (item) => {
            return item?.date?.includes(
              granularityString?.replace("Fullfillment ", "")
            );
          });

          console.log(prev);

          let prevString = moment(cur?.dateObj?.toISOString())
            .day(6)
            .startOf("day")
            .format("MM/DD/YYYY");

          if (granularity == "month") {
            prevString = moment(cur?.dateObj?.toISOString()).format("MMMM YY");
          }

          if (
            !prev &&
            offsetDate.toDate() >= dateRange[0] &&
            offsetDate?.toDate() <= dateRange[1]
          ) {
            let out = {
              dateObj: offsetDate.toDate(),
              date: granularityString + ` vs. ${prevString}`,
              curDate: granularityString,
              dateKey: granularityString,
              Meals: 0,
              Previous:
                cur?.count !== null && cur?.count !== undefined ? cur.count : 0,
            };

            dataSet.push(out);
          }
        }

        dataSet = _.sortBy(dataSet, "dateObj");

        this.setState({
          previousCount,
          changeIsNegative: isNegative,
          deltaType: declineMode,
          percentChange,
          percentChangeString: Math.abs(percentChange * 100).toFixed(1) + "%",
          dataSet,
        });
      }
    }

    this.setState({
      currentCount,
      loading: false,
    });
  }

  /**
   * Load all report information
   *
   * @param {*} stores
   * @param {*} dateRange
   * @param {*} compare
   */
  async loadReport(stores, dateRange, compare) {
    this.setState({
      loading: true,
    });

    this._fetchLeadReport(stores, dateRange);
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.stores != prevProps?.stores && this.props.stores?.length) ||
      this.props.dateRange != prevProps?.dateRange ||
      this.props.comparePrevious != prevProps.comparePrevious
    ) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  componentDidMount() {
    if (this.props.stores && this.props.dateRange) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  render() {
    return (
      <>
        <Card hFull={true}>
          <ChartMetricHeader
            title="Daily Inventory"
            description="Total daily inventory report for selected stores."
            forceWrapComparison={true}
            actions={
              <>
                <Button
                  size="sm"
                  outline
                  color="dark"
                  className="border-0 btn-icon-only"
                  disabled={
                    this.state.loading || !this.state.dataAvailable
                  }
                  onClick={() => {
                    this.setState({
                      open: true,
                    });
                  }}
                >
                  <i className="mdi mdi-fullscreen"></i>
                </Button>
              </>
            }
            loading={this.state.loading}
            dataAvailable={this.state.dataAvailable}
            metric={
              <>
                ~{StringUtils.numberFormat(this.state.currentCount?.average)}{" "}
                Meals In-Stock Daily
              </>
            }
            comparisonMetric={
              this.state.previousCount !== null &&
              !isNaN(this.state.previousCount?.average) && (
                <>
                  {StringUtils.numberFormat(this.state.previousCount?.average)}
                </>
              )
            }
            dateRange={this.props.dateRange}
            deltaType={this.state.deltaType}
            percentChange={this.state.percentChangeString}
            showPercentChange={true}
          ></ChartMetricHeader>

          <TabList
            color="orange"
            defaultValue="summary"
            handleSelect={(value) => {
              this.setState({
                tab: value,
              });

              if (value == "summary") {
                this.setState({
                  categories: ["Meals", "Previous"],
                  dataKey: "date",
                  colors: ["orange", "gray"],
                });
              } else if (value == "type") {
                this.setState({
                  categories: [
                    "Lifestyle Meals",
                    "Athlete Meals",
                    "Lean Cheats",
                  ],
                  dataKey: "curDate",
                  colors: ["emerald", "orange", "yellow"],
                });
              } else if (value == "valueSummary") {
                this.setState({
                  categories: ["Total Value", "Previous Value"],
                  dataKey: "date",
                  colors: ["orange", "gray"],
                });
              } else if (value == "valueType") {
                this.setState({
                  categories: [
                    "Lifestyle Value",
                    "Athlete Value",
                    "Lean Cheats Value",
                  ],
                  dataKey: "curDate",
                  colors: ["emerald", "orange", "yellow"],
                });
              } else if (value == "costSummary") {
                this.setState({
                  categories: ["Total Unit Cost", "Previous Unit Cost"],
                  dataKey: "date",
                  colors: ["orange", "gray"],
                });
              } else if (value == 'costType') {
                this.setState({
                  categories: [
                    "Lifestyle Unit Cost",
                    "Athlete Unit Cost",
                    "Lean Cheats Unit Cost",
                  ],
                  dataKey: "curDate",
                  colors: ["emerald", "orange", "yellow"],
                });
              } else if (value == "valueComp") {
                this.setState({
                  categories: ["Total Value", "Total Unit Cost"],
                  dataKey: "curDate",
                  colors: ["emerald", "orange"],
                });
              }
            }}
            marginTop="mt-3"
            disabled={this.state.loading || !this.state.dataAvailable}
          >
            <Tab value="summary" text="Unit Summary" />
            <Tab value="valueSummary" text="Sale Value" />
            <Tab value="costSummary" text="Unit Cost" />
            <Tab value="type" text="Units By Type" />
            <Tab value="valueType" text="Sale Value By Type" />
            <Tab value="costType" text="Unit Cost By Type" />
            <Tab value="valueComp" text="Sale Value vs. Unit Cost" />
          </TabList>
          {this.state.loading ? (
            <div
              className="mt-4 skeleton"
              style={{ height: 320, width: "100%" }}
            ></div>
          ) : (
            <>
              {this.state.dataAvailable ? (
                <AreaChart
                  marginTop="mt-4"
                  data={this.state.dataSet}
                  categories={this.state.categories}
                  dataKey={this.state.dataKey}
                  colors={this.state.colors}
                  valueFormatter={(number) => {
                    return this.state.tab?.includes('value') || this.state.tab?.includes('cost') ? StringUtils.centsToCurrency(number) : StringUtils.numberFormat(number);
                  }}
                  height="h-80"
                />
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "calc(320px + 1rem)", width: "100%" }}
                >
                  <p className="m-0">
                    No data available.
                    {this.state.error ? ` ${this.state.error}` : null}
                  </p>
                </div>
              )}
            </>
          )}
        </Card>

        <StoreDailyInventoryCardDetailDrawer
          open={this.state.open}
          onClose={() => {
            this.setState({
              open: false,
            });
          }}
          stores={this.props.stores}
          dateRange={this.props.dateRange}
        />
      </>
    );
  }
}

export default StoreDailyInventoryChartCard;
