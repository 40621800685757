/**
 * User class
 * 
 * Author: Brandon Hudson
 * Created: 12-16-2019
 */

import Settings from "./Settings";
import Role from "./Role";

class User {
    static STATUS_ACTIVE = 1;
    static STATUS_INACTIVE = 0;
    static STATUS_DELETED = -1;
    static STATUS_PENDING = 3;
    static STATUS_PASSWORD_RESET = 2;

    constructor(id = null, email = '', name = '', status = 0, profileID = 0, created = '', updated = '') {
        this.id = id;
        this.email = email;
        this.name = name;
        this.status = status;
        this.created = created;
        this.updated = updated;

        this.settings = new Settings();
        this.role = new Role();
    }

    getID() {
        return this.id;
    }

    setID(val) {
        this.id = val;
    }

    getEmail() {
        return this.email;
    }

    setEmail(val) {
        this.email = val;
    }

    getName() {
        return this.name;
    }

    setName(val) {
        this.name = val;
    }

    getFirstName() {
        if (!this.getName()) {
            return '';
        }

        if (this.getName().indexOf(' ') == -1) {
            return this.getName();
        }

        let nameArr = this.getName().split(' ');

        return nameArr[0].charAt(0).toUpperCase() + nameArr[0].slice(1);
    }

    getStatus() {
        return this.status;
    }

    setStatus(val) {
        this.status = val;
    }

    isActive() {
        return this.getStatus() == User.STATUS_ACTIVE;
    }

    isInactive() {
        return this.getStatus() == User.STATUS_INACTIVE;
    }

    isPending() {
        return this.getStatus() == User.STATUS_PENDING;
    }

    isDeleted() {
        return this.getStatus() == User.STATUS_DELETED;
    }

    isPasswordReset() {
        return this.getStatus() == User.STATUS_PASSWORD_RESET;
    }

    getSettings() {
        return this.settings;
    }

    setSettings(val) {
        this.settings = new Settings(val);
    }

    getRole() {
        return this.role;
    }

    setRole(val) {
        this.role = val;
    }

    getCreated() {
        return this.created;
    }

    setCreated(val) {
        this.created = val;
    }

    getUpdated() {
        return this.updated;
    }

    setUpdated(val) {
        this.updated = val;
    }

    /**
     * Checks whether the user is an internal user.
     */
    isInternal() {
        if (!this.getSettings()) {
            return false;
        }

        return this.getSettings().get('INTERNAL_internal') == true;
    }

    _toData() {
        let out = {
            id: this.getID(),
            name: this.getName(),
            email: this.getEmail(),
            status: this.getStatus(),
            created: this.getCreated(),
            updated: this.getUpdated(),
            settings: this.getSettings()._toData(),
            role: this.getRole()._toData(),
        };

        return out;
    }

    static _fromData(data) {
        let user = new User();

        if (!data) {
            return user;
        }

        if (data.id) {
            user.setID(data.id);
        }

        if (data.name) {
            user.setName(data.name);
        }

        if (data.email) {
            user.setEmail(data.email);
        }

        if (data.status !== null) {
            user.setStatus(data.status);
        }

        if (data.created) {
            user.setCreated(data.created);
        }

        if (data.updated) {
            user.setUpdated(data.updated);
        }

        if (data.settings !== null) {
            user.setSettings(data.settings);
        }

        if (data.role !== null) {
            user.setRole(Role._fromData(data.role));
        }

        return user;
    }
}

export default User;