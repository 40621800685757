import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  BarChart,
  Card,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Badge, Button, Col, Row, Spinner } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _, { times } from "underscore";
import {
  Axis,
  Chart,
  Coordinate,
  Interaction,
  Interval,
  Tooltip,
} from "bizcharts";

const ChartMetricHeader = ({
  loading = true,
  description = "",
  dataAvailable = false,
  metric,
  comparisonMetric = null,
  dateRange,
  error = "",
  actions,
  title = "",
  showPercentChange = true,
  deltaType = "",
  percentChange = null,
  forceWrapComparison = false,
  isIncreasePositive = true,
  badge = null,
}) => {
  return (
    <>
      <Row className="align-items-start">
        <Col xs="">
          <h3 className="m-0 text-dark">{title}</h3>
          {description ? (
            <>
              <p className="small text-muted mb-1" style={{ lineHeight: 1.2 }}>
                {description}
              </p>
            </>
          ) : null}
          {loading ? (
            <div className="skeleton" style={{ width: 100, height: 36 }}></div>
          ) : (
            <>
              {dataAvailable ? (
                <>
                  <Row className="align-items-baseline">
                    <Col xs="12" lg="auto" className="pr-2">
                      <Metric>{metric}</Metric>
                    </Col>
                    {comparisonMetric && dateRange?.length == 2 && (
                      <Col
                        xs="12"
                        lg={`${forceWrapComparison ? "12" : "auto"}`}
                        className={`${
                          forceWrapComparison ? "mt-1" : "pl-lg-0 mt-1 mt-lg-0"
                        }`}
                        style={{ paddingBottom: 2 }}
                      >
                        <Text>
                          vs. {comparisonMetric} previous{" "}
                          {StringUtils.dateRangeDescription(
                            dateRange[0],
                            dateRange[1]
                          )}
                        </Text>
                      </Col>
                    )}
                  </Row>
                </>
              ) : (
                <Metric>--</Metric>
              )}
            </>
          )}
        </Col>
        {actions || badge ? (
          <>
            <Col xs="auto">
              {badge ? (
                <Badge
                  color="light"
                  className={`${actions && "mr-2"} px-1 border border-lighter`}
                  style={{ position: "relative", top: -1 }}
                >
                  {badge}
                </Badge>
              ) : null}
              {actions}
            </Col>
          </>
        ) : null}
      </Row>
      {showPercentChange && (
        <>
          {loading ? (
            <div
              className="tr-mt-2 skeleton"
              style={{ height: 27, width: 200 }}
            ></div>
          ) : (
            <>
              <Flex
                marginTop="mt-2"
                justifyContent="justify-start"
                spaceX="space-x-2"
              >
                {dataAvailable ? (
                  <>
                    <BadgeDelta
                      isIncreasePositive={isIncreasePositive}
                      deltaType={deltaType}
                      text={percentChange}
                    />{" "}
                    <Text>
                      vs. previous{" "}
                      {StringUtils.dateRangeDescription(
                        dateRange[0],
                        dateRange[1]
                      )}
                    </Text>
                  </>
                ) : (
                  <>
                    <div style={{ height: 27 }}>
                      No data available.&nbsp;{error ? error : ""}
                    </div>
                  </>
                )}
              </Flex>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ChartMetricHeader;
