import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";

class CustomerCreateConsultationDrawer extends React.Component {
  state = {
    consultationDate: moment()
      .hours(0)
      .minutes(0)
      .seconds(0)
      .millisecond(0)
      .toDate(),
    coachID: null,
    notes: "",
    type: "LEAN Consultation",
    types: [
      {
        label: "LEAN Consultation",
        value: "LEAN Consultation",
      },
      {
        label: "Member Check-In",
        value: "Client Follow-Up Consultation",
      },
    ],
  };

  componentDidUpdate(prevProps) {
    if (this.props.customer != prevProps.customer) {
      this.getStoreStaff();
    }

    if (this.props.lead != prevProps.lead) {
      this.getStoreStaff();
    }
  }

  componentDidMount() {
    if (this.props.customer) {
      this.getStoreStaff();
    }

    if (this.props.lead) {
      this.getStoreStaff();
    }
  }

  toggleModal() {
    this.setState({
      notes: "",
      consultationDate: moment()
        .hours(0)
        .minutes(0)
        .seconds(0)
        .millisecond(0)
        .toDate(),
      coachID: null,
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  createForLead() {
    let date = moment(this.state.consultationDate?.toString())
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0);

    APIV2.createLeadConsultation(
      this.props?.lead?.storeID,
      this.props?.lead?._id,
      date,
      this.state.coachID,
      this.state.notes
    )
      .then(
        (data) => {
          const lead = data.data.lead;

          this.toggleModal();

          PubSub.publish(Event.LEAD.MODIFIED, lead);

          if (typeof this.props.onClose == "function") {
            this.props.onClose();
          }
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              `Unable to create ${
                this.props.customer ? "member" : "lead"
              } consultation - unknown error occurred. Try again.`
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  create() {
    this.setState({ submitting: true });

    //console.log(this.props.lead);

    if (this.props.lead) {
      return this.createForLead();
    }

    let consultDate = moment(this.state.consultationDate?.toString()).format(
      "YYYY-MM-DD"
    );

    APIV2.createCustomerConsultation(
      this.props?.customer?.storeID,
      this.props?.customer?._id,
      consultDate,
      this.state.coachID,
      this.state.notes,
      this.state.type
    )
      .then(
        (data) => {
          const customer = data.data.customer;

          this.toggleModal();

          PubSub.publish(Event.CUSTOMER.MODIFIED, customer);

          if (typeof this.props.onClose == "function") {
            this.props.onClose();
          }
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              `Unable to create ${
                this.props.customer ? "member" : "lead"
              } consultation - unknown error occurred. Try again.`
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  getStoreStaff() {
    this.setState({
      loadingCoaches: true,
    });

    APIV2.getStaffForStore(
      this?.props?.customer
        ? this?.props?.customer?.storeID
        : this.props?.lead?.storeID,
      1,
      10000,
      {
        position: { $nin: ["Terminated", "Applicant"] },
      }
    )
      .then(
        (data) => {
          let coachOptions = data.data.staff?.map((s) => {
            return {
              value: s._id,
              label: s.name,
            };
          });

          this.setState({
            coaches: data.data.staff,
            coachOptions: coachOptions,
          });
        },
        (e) => {
          this.setError("error", "Could not fetch coaches.");
        }
      )
      .finally(() => {
        this.setState({
          loadingCoaches: false,
        });
      });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              New Consultation
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={this.state.submitting || !this.state.consultationDate}
                color="primary"
                onClick={this.create.bind(this)}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  "Add"
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            {this.props.showType && (
              <>
                <FormGroup>
                  <h5>Consultation Type</h5>
                  <SelectPicker
                    oneTap
                    data={this.state.types}
                    value={this.state.type}
                    block
                    placeholder="Select Type"
                    onChange={(v) => {
                      this.setState({ type: v });
                    }}
                    placement="autoVerticalStart"
                  ></SelectPicker>
                </FormGroup>
              </>
            )}
            <FormGroup>
              <h5>Consultation Date</h5>
              <DatePicker
                oneTap
                required
                block
                placeholder="Select A Date"
                defaultValue={moment()
                  .hours(0)
                  .minutes(0)
                  .seconds(0)
                  .millisecond(0)
                  .toDate()}
                value={this.state.consultationDate}
                onChange={(e) => {
                  this.setState({ consultationDate: e });
                }}
                ranges={[
                  {
                    label: "Today",
                    value: moment()
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .millisecond(0)
                      .toDate(),
                    closeOverlay: true,
                  },
                ]}
              ></DatePicker>
            </FormGroup>
            <FormGroup>
              <h5>Staff Member</h5>
              <SelectPicker
                loading={this.state.loadingCoaches}
                cleanable
                size="md"
                placeholder="Select A Staff Member"
                data={this.state.coachOptions}
                style={{}}
                value={this.state.coachID}
                block
                onChange={(val) => {
                  this.setState({ coachID: val });
                }}
                placement="auto"
              />
            </FormGroup>
            <FormGroup>
              <h5>Notes</h5>
              <Input
                bsSize="sm"
                type="textarea"
                name="notes"
                placeholder="Notes"
                value={this.state.notes}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(CustomerCreateConsultationDrawer);
