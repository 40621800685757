import React from "react";
// react component that copies the given text inside your clipboard

import { DateRangePicker } from "react-date-range";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Spinner,
  Collapse,
} from "reactstrap";

import {
  Card as TCard,
  Title,
  Text,
  ColGrid,
  Col as TCol,
  Block,
  Flex,
  MarkerBar,
  CategoryBar,
  ProgressBar,
  Metric,
  BadgeDelta,
  ButtonInline,
  Footer,
  Tab,
  Divider,
  TabList,
  DeltaBar,
  AreaChart,
  BarChart,
  DonutChart,
  Bold,
  List,
  ListItem,
} from "@tremor/react";

// core components
import Header from "components/Headers/Header.js";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import moment from "moment";
import {
  Checkbox,
  CheckPicker,
  CheckTreePicker,
  DatePicker,
  Popover,
  SelectPicker,
  Tooltip,
  Whisper,
} from "rsuite";
import StringUtils from "lib/StringUtils";
import { Table, Cell, Column, HeaderCell } from "rsuite-table";
import _ from "underscore";
import MoneyCell from "components/Table/MoneyCell";
import NumericCell from "components/Table/NumericCell";
import StoreCell from "components/Table/StoreCell";
import BootstrapTable from "react-bootstrap-table-next";
import MealsOrderedWidget from "components/Widgets/Fulfillment/MealsOrderedWidget";
import PlansFulfilledWidget from "components/Widgets/Fulfillment/PlansFulfilledWidget";
import AverageBoxSizeWidget from "components/Widgets/Fulfillment/AverageBoxSizeWidget";
import PlanFrequencyChartWidget from "components/Widgets/Fulfillment/PlanFrequencyChartWidget";
import PlanProcurementChartWidget from "components/Widgets/Fulfillment/PlanProcurementChartWidget";
import PlanTypeChartWidget from "components/Widgets/Fulfillment/PlanTypeChartWidget";
import { Link } from "react-router-dom";
import PLNDateRangePicker from "../../components/PLNDateRangePicker";
import cubejs from "@cubejs-client/core";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";

import DataAPI from "../../lib/DataAPI";
import SalesSummaryTrendlineChart from "../../components/Charts/SalesSummaryTrendlineChart";
import SalesSummaryPieChart from "../../components/Charts/SalesSummaryPieChart";
import DashboardSectionHeader from "../../components/DashboardSectionHeader";
import ClientOrderKPICard from "../../components/Charts/Scorecard/ClientOrderKPICard";
import RetailSalesKPICard from "../../components/Charts/Scorecard/RetailSalesKPICard";
import StoreLeaderboardKPICard from "../../components/Charts/Scorecard/StoreLeaderboardKPICard";
import ScorecardNewLeadChartCard from "../../components/Charts/Scorecard/ScorecardNewLeadChartCard";
import ScorecardNewHFNChartCard from "../../components/Charts/Scorecard/ScorecardNewHFNChartCard";
import ScorecardNewContactsChartCard from "../../components/Charts/Scorecard/ScorecardNewContactsChartCard";
import LeadConversionRateKPICard from "../../components/Charts/Scorecard/LeadConversionRateKPICard";
import FulfillmentRateKPICard from "../../components/Charts/Scorecard/FulfillmentRateKPICard";
import MemberExperienceKPICard from "../../components/Charts/Scorecard/MemberExperienceKPICard";
import CommunityEngagementKPICard from "../../components/Charts/Scorecard/CommunityEngagementKPICard";
import OrderCountKPICard from "../../components/Charts/Scorecard/OrderCountKPICard";
import CancelledOrderCountKPICard from "../../components/Charts/Scorecard/CancelledOrderCountKPICard";
import LeadConversionTimeKPICard from "../../components/Charts/Scorecard/LeadConversionTimeKPICard";
import LeadSourcesCard from "../../components/Charts/Scorecard/LeadSourcesCard";
import NewMemberKPICard from "../../components/Charts/Scorecard/NewMemberKPICard";
import ConsultationKPICard from "../../components/Charts/Scorecard/ConsultationKPICard";
import OutreachKPICard from "../../components/Charts/Scorecard/OutreachKPICard";
import NetMemberKPICard from "../../components/Charts/Scorecard/NetMemberKPICard";
import NewMemberSourcesCard from "../../components/Charts/Scorecard/NewMemberSourcesCard";
import ActiveMemberSummaryChartCard from "../../components/Charts/Scorecard/ActiveMemberSummaryChartCard";
import SubscriptionLTVKPICard from "../../components/Charts/Scorecard/SubscriptionLTVKPICard";

class ScorecardDashboard extends React.Component {
  state = {
    dateRange: [
      moment()
        .day(0)
        .subtract(1, "week")
        .hours(0)
        .minutes(0)
        .seconds(0)
        .millisecond(0)
        .toDate(),
      moment()
        .day(6)
        .subtract(1, "week")
        .hours(23)
        .minutes(59)
        .seconds(59)
        .millisecond(999)
        .toDate(),
    ],
    storeOptions: [],
    selectedStores: [],
    pendingSelectedStores: [],
    groupByOptions: [
      {
        label: "Summary",
        value: "summary",
      },
      {
        label: "Daily",
        value: "daily",
      },
      {
        label: "Weekly",
        value: "weekly",
      },
      {
        label: "Monthly",
        value: "monthly",
      },
      {
        label: "Quarterly",
        value: "quarterly",
      },
      {
        label: "Yearly",
        value: "yearly",
      },
    ],
    groupBy: "summary",
    revenueSourceOptions: [
      {
        label: "All Sales",
        value: "all",
      },
      {
        label: "Subscription Sales",
        value: "subscription",
      },
      {
        label: "Net Retail Sales",
        value: "retail",
      },
    ],
    revenueSource: "all",
  };

  constructor(props) {
    super(props);

    this.storePickerRef = React.createRef();
  }

  getUser() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user;
  }

  explainToMe() {
    setTimeout(() => {
      let html = document?.getElementById("scorecard-content")?.innerText;

      this.setState({
        explainSubmitting: true,
        explainURL: null,
      });

      let prompt = `You are an expert in data analytics for small business and Project LeanNation Franchisees. You have been asked to analyze the data on the Scorecard Dashboard. The data is presented in a series of charts and graphs. Your goal is to analyze the data and provide insights to help the business grow. You are tasked with providing a summary of the data and to provide detailed, impactful recommendations based on your analysis that are non-apparent. If the recommendation is obvious don't state it. Your tone should be helpful and supportive. Write an audio narration script of your analysis based on the following data. Keep it concise and impactful, drawing correlations between the datasets available. Don't include any directors notes. Convert all symbols into their english language equivalent. "HFN" stands for "Hold For Now", which signifies a membership cancellation. Data: ${html}`;

      APIV2.generateAudioFromPrompt(prompt).then(
        (data) => {
          let url = data?.data?.url;

          if (url) {
            APIV2.getPrivateFileURL(url)
              .then((data) => {
                this.setState({
                  explainSubmitting: false,
                  explainURL: data?.data?.url,
                });

                console.log(data?.data?.url);
              })
              .finally(() => {
                this.setState({
                  explainSubmitting: false,
                });
              });
          }
        },
        (e) => {
          // TODO: error

          this.setState({
            explainSubmitting: false,
          });
        }
      );
    }, 0);
  }

  /**
   * Checks if the current user is a global user via permissions
   *
   * @returns
   */
  isGlobal() {
    let user = this.getUser();

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  getAllStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStores(null, { _id: 1, name: 1, nsoStage: 1, supportLevel: 1 })
      .then((data) => {
        const now = new Date();

        let stores = _.filter(data.data.stores, (st) => {
          return (
            st.nsoStage &&
            st.nsoStage != "Lead" &&
            st.nsoStage != "Funding" &&
            st.nsoStage != "Cold" &&
            st.nsoStage != "Discovery"
          );
        });

        let allUserStores = [];
        let userSelectedStores = [];
        let allUserLiveStores = [];
        let allUserLiveAndPrelaunchStores = [];
        let userSelectedLiveStores = [];
        let userSelectedLiveAndPrelaunchStores = [];
        let selectedLiveAndPrelaunchStores = [];
        let selectedLiveStores = [];
        // test

        let allStores = stores?.map((st) => {
          return _.clone(st);
        });

        let allLiveStores = _.filter(allStores, (st) => {
          return st.nsoStage == "Live";
        });

        let allLiveStoreIDs = allLiveStores.map((st) => {
          return st._id;
        });

        let allLiveAndPrelaunchStores = _.filter(allStores, (st) => {
          return st.nsoStage == "Live" || st.nsoStage == "Prelaunch";
        });

        let allLiveAndPrelaunchStoreIDs = allLiveAndPrelaunchStores.map(
          (st) => {
            return st._id;
          }
        );

        let allStoreIDs = allStores.map((st) => {
          return st._id;
        });

        if (!this.isGlobal()) {
          let user = this.getUser();

          allUserStores = _.filter(data.data.stores, (st) => {
            return _.findWhere(user?.storePermissions, { storeID: st._id });
          });
          allUserLiveStores = _.filter(allLiveStores, (st) => {
            return _.findWhere(user?.storePermissions, { storeID: st._id });
          });

          allUserLiveAndPrelaunchStores = _.filter(
            allLiveAndPrelaunchStores,
            (st) => {
              return _.findWhere(user?.storePermissions, { storeID: st._id });
            }
          );
        } else {
          allUserStores = data.data.stores;
          allUserLiveStores = allLiveStores;
          allUserLiveAndPrelaunchStores = allLiveAndPrelaunchStores;
        }

        let storeOptions = stores?.map((st) => {
          return {
            label: st?.name,
            value: st?._id,
            groupBy: st?.nsoStage,
          };
        });

        let selectedStores = [];

        if (this.state.selectedStores?.length) {
          selectedStores = _.filter(this.state.selectedStores, (v) => {
            return _.findWhere(stores, { _id: v });
          });

          userSelectedStores = _.filter(this.state.selectedStores, (st) => {
            return _.findWhere(allUserStores, { _id: st });
          });
          userSelectedLiveStores = _.filter(this.state.selectedStores, (st) => {
            return _.findWhere(allUserLiveStores, { _id: st });
          });
          userSelectedLiveAndPrelaunchStores = _.filter(
            this.state.selectedStores,
            (st) => {
              return _.findWhere(allUserLiveAndPrelaunchStores, { _id: st });
            }
          );
          selectedLiveAndPrelaunchStores = _.filter(
            this.state.selectedStores,
            (st) => {
              return _.findWhere(allLiveAndPrelaunchStores, { _id: st });
            }
          );

          selectedLiveStores = _.filter(this.state.selectedStores, (st) => {
            return _.findWhere(allLiveStores, { _id: st });
          });
        } else {
          selectedStores = storeOptions?.map((st) => {
            return st.value;
          });

          userSelectedStores = _.filter(
            storeOptions?.map((st) => {
              return st.value;
            }),
            (st) => {
              return _.findWhere(allUserStores, { _id: st });
            }
          );
          userSelectedLiveStores = _.filter(
            storeOptions?.map((st) => {
              return st.value;
            }),
            (st) => {
              return _.findWhere(allUserLiveStores, { _id: st });
            }
          );

          userSelectedLiveAndPrelaunchStores = _.filter(
            storeOptions?.map((st) => {
              return st.value;
            }),
            (st) => {
              return _.findWhere(allUserLiveAndPrelaunchStores, { _id: st });
            }
          );

          selectedLiveAndPrelaunchStores = _.filter(
            storeOptions?.map((st) => {
              return st.value;
            }),
            (st) => {
              return _.findWhere(allLiveAndPrelaunchStores, { _id: st });
            }
          );

          selectedLiveStores = _.filter(
            storeOptions?.map((st) => {
              return st.value;
            }),
            (st) => {
              return _.findWhere(allLiveStores, { _id: st });
            }
          );
        }

        this.setState(
          {
            stores: stores,
            allStores,
            allUserStores,
            allStoreIDs,
            allLiveStoreIDs,
            allLiveStores,
            allLiveAndPrelaunchStoreIDs,
            allLiveAndPrelaunchStores,
            storeOptions,
            selectedStores,
            pendingSelectedStores: selectedStores,
            userSelectedStores,
            allUserLiveStores,
            userSelectedLiveStores,
            selectedLiveStores,
            userSelectedLiveAndPrelaunchStores,
            allUserLiveAndPrelaunchStores,
            selectedLiveAndPrelaunchStores,
          },
          () => {
            if (this.state.groupBy && this.state.revenueSource) {
              this.loadData();
            }
          }
        );
      })
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  componentDidMount() {
    this.readURLParameters();

    this.getAllStores();
  }

  onSelectAllStores() {
    let selected = this.state.storeOptions?.map((opt) => {
      return opt.value;
    });

    if (
      this.state.pendingSelectedStores?.length ==
      this.state.storeOptions?.length
    ) {
      return this.setState({
        pendingSelectedStores: [],
      });
    }

    this.setState({
      pendingSelectedStores: selected,
    });
  }

  onChangeStores(v) {
    this.setState({
      pendingSelectedStores: v,
    });
  }

  onApplyChangeStores() {
    let userSelectedStores = this.state.pendingSelectedStores;
    let userSelectedLiveStores = this.state.pendingSelectedStores;
    let userSelectedLiveAndPrelaunchStores = this.state.pendingSelectedStores;
    let selectedLiveAndPrelaunchStores = this.state.pendingSelectedStores;

    if (!this.isGlobal()) {
      userSelectedStores = _.filter(this.state.pendingSelectedStores, (st) => {
        return _.findWhere(this.state.allUserStores, { _id: st });
      });
      userSelectedLiveStores = _.filter(
        this.state.pendingSelectedStores,
        (st) => {
          return _.findWhere(this.state.allUserLiveStores, { _id: st });
        }
      );
      userSelectedLiveAndPrelaunchStores = _.filter(
        this.state.pendingSelectedStores,
        (st) => {
          return _.findWhere(this.state.allUserLiveAndPrelaunchStores, {
            _id: st,
          });
        }
      );
    } else {
      userSelectedLiveStores = _.filter(
        this.state.pendingSelectedStores,
        (st) => {
          return _.findWhere(this.state.allLiveStores, { _id: st });
        }
      );
      userSelectedLiveAndPrelaunchStores = _.filter(
        this.state.pendingSelectedStores,
        (st) => {
          return _.findWhere(this.state.allLiveAndPrelaunchStores, {
            _id: st,
          });
        }
      );
    }

    selectedLiveAndPrelaunchStores = _.filter(
      this.state.pendingSelectedStores,
      (st) => {
        return _.findWhere(this.state.allLiveAndPrelaunchStores, {
          _id: st,
        });
      }
    );

    let selectedLiveStores = _.filter(
      this.state.pendingSelectedStores,
      (st) => {
        return _.findWhere(this.state.allLiveStores, {
          _id: st,
        });
      }
    );

    this.setState(
      {
        selectedStores: this.state.pendingSelectedStores,
        userSelectedStores,
        userSelectedLiveStores,
        userSelectedLiveAndPrelaunchStores,
        selectedLiveAndPrelaunchStores,
        selectedLiveStores,
      },
      () => {
        this.loadData();
      }
    );
  }

  onCancelChangeStores() {
    this.setState({
      pendingSelectedStores: this.state.selectedStores,
    });
  }

  hasValidDateRange() {
    if (
      this.state?.dateRange?.length != 2 ||
      !this.state?.dateRange[0] ||
      !this.state?.dateRange[1]
    ) {
      return false;
    }

    return true;
  }

  getStoreByIDFromData(id) {
    let st = _.findWhere(this.state.stores, { _id: id });

    if (st) {
      st = _.clone(st);

      st.name = st.name.replace("Project LeanNation", "");

      return st;
    }

    return null;
  }

  renderDateRange() {
    if (!this.hasValidDateRange()) {
      return "Select Date Range";
    }

    let startMoment = moment(this.state.dateRange[0]?.toISOString());
    let endMoment = moment(this.state.dateRange[1]?.toISOString());

    if (startMoment?.format("YYYY-MM-DD") == endMoment.format("YYYY-MM-DD")) {
      return `${startMoment?.format("MMM D, YYYY")}`;
    }

    return `${startMoment?.format("MMM D, YYYY")} - ${endMoment?.format(
      "MMM D, YYYY"
    )}`;
  }

  renderPreviousDateRange() {
    if (!this.hasValidDateRange()) {
      return "--";
    }

    let secondDifference =
      this.state.dateRange[1].getTime() - this.state.dateRange[0].getTime();

    secondDifference = secondDifference / 1000;

    let startMoment = moment(this.state.dateRange[0].toISOString());
    let endMoment = moment(this.state.dateRange[1].toISOString());

    startMoment.subtract(secondDifference, "seconds");
    endMoment.subtract(secondDifference + 1, "seconds");

    if (startMoment?.format("YYYY-MM-DD") == endMoment.format("YYYY-MM-DD")) {
      return `${startMoment?.format("MMM D, YYYY")}`;
    }

    return `${startMoment?.format("MMM D, YYYY")} - ${endMoment?.format(
      "MMM D, YYYY"
    )}`;
  }

  setURLParameters() {
    // Construct URLSearchParams object instance from current URL querystring.
    let queryParams = new URLSearchParams(window.location.search);

    if (this.state.dateRange?.length == 2) {
      let startDate = this.state.dateRange[0]?.toISOString();
      let endDate = this.state.dateRange[1]?.toISOString();

      // Set new or modify existing parameter value.
      queryParams.set("startDate", startDate);
      // Set new or modify existing parameter value.
      queryParams.set("endDate", endDate);
    }

    if (this.state.groupBy) {
      queryParams.set("groupBy", this.state.groupBy);
    }

    if (this.state.revenueSource) {
      queryParams.set("revenueSource", this.state.revenueSource);
    }

    if (this.state.selectedStores?.length) {
      queryParams.set("stores", this.state.selectedStores.join(","));
    }

    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );
  }

  readURLParameters() {
    let queryParams = new URLSearchParams(window.location.search);

    let state = {};

    if (queryParams.get("startDate") && queryParams.get("endDate")) {
      let startDate = moment(queryParams.get("startDate")).toDate();
      let endDate = moment(queryParams.get("endDate")).toDate();
      state.dateRange = [startDate, endDate];
    }

    if (
      queryParams.get("groupBy") &&
      _.findWhere(this.state.groupByOptions, {
        value: queryParams.get("groupBy"),
      })
    ) {
      state.groupBy = queryParams.get("groupBy");
    }

    if (
      queryParams.get("revenueSource") &&
      _.findWhere(this.state.revenueSourceOptions, {
        value: queryParams.get("revenueSource"),
      })
    ) {
      state.revenueSource = queryParams.get("revenueSource");
    }

    if (queryParams.get("stores")) {
      let storeIDs = queryParams.get("stores").split(",");

      if (storeIDs?.length) {
        state.selectedStores = storeIDs;
      }
    }

    this.setState(state);
  }

  async loadData() {
    this.setState({
      loadingData: true,
    });

    this.setURLParameters();
  }

  selectTier(name) {
    let tierStores = _.filter(this.state.stores, (st) => {
      return st.supportLevel == name;
    });

    console.log(tierStores);

    let tierStoreIDs = tierStores.map((st) => {
      return st._id;
    });

    this.setState({
      pendingSelectedStores: tierStoreIDs,
    });
  }

  countTier(name) {
    let tierStores = _.filter(this.state.stores, (st) => {
      return st.supportLevel == name;
    });

    return tierStores.length;
  }

  isTier(name) {
    let selectedStores = this.state.pendingSelectedStores;

    if (!selectedStores?.length) {
      return false;
    }

    let tierStores = _.filter(this.state.stores, (st) => {
      return st.supportLevel == name;
    });

    let tierStoreIDs = tierStores.map((st) => {
      return st._id;
    });

    if (_.difference(selectedStores, tierStoreIDs).length) {
      return false;
    }

    return true;
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container
          className="main-content-padding"
          id="scorecard-content"
          fluid
        >
          <PageHeader title="Scorecard" showBackButton={false} />
          {/* Table */}
          <div className="border-bottom pb-3 mt--2 mb-3">
            <Row className="align-items-center">
              <Col xs="12" sm="6" lg="auto" className="pr-sm-1">
                <div className="reports-date-picker">
                  <PLNDateRangePicker
                    value={this.state.dateRange}
                    onChange={(v) => {
                      console.log(v);

                      this.setState({ dateRange: v }, () => {
                        this.loadData();
                      });

                      if (v?.length == 2 && v[0] && v[1]) {
                        localStorage.setItem(
                          "salesSummary_dateRange",
                          JSON.stringify({
                            startDate: v[0]?.toISOString(),
                            endDate: v[1]?.toISOString(),
                          })
                        );
                      }
                    }}
                  ></PLNDateRangePicker>
                </div>
              </Col>
              <Col
                xs="12"
                sm="6"
                lg="auto"
                className="mt-3 mt-sm-0 pl-sm-1 pr-lg-1"
              >
                <div className="reports-store-picker">
                  <CheckPicker
                    groupBy="groupBy"
                    block
                    ref={this.storePickerRef}
                    cleanable={true}
                    label="Stores"
                    data={this.state.storeOptions}
                    placement="autoVertical"
                    preventOverflow={true}
                    placeholder="Select Stores"
                    value={this.state.pendingSelectedStores}
                    onChange={this.onChangeStores.bind(this)}
                    onClose={() => {
                      if (
                        JSON.stringify(this.state.pendingSelectedStores) !=
                        JSON.stringify(this.state.selectedStores)
                      ) {
                        this.onApplyChangeStores();
                      }
                    }}
                    sort={(isGroup) => {
                      if (isGroup) {
                        return (a, b) => {
                          if (b.groupTitle == "Live") {
                            return 1;
                          }

                          if (b.groupTitle == "Prelaunch") {
                            if (a.groupTitle == "Live") {
                              return -1;
                            }

                            return 1;
                          }

                          if (b.groupTitle == "Buildout") {
                            if (
                              a.groupTitle == "Live" ||
                              a.groupTitle == "Prelaunch"
                            ) {
                              return -1;
                            }

                            return 1;
                          }

                          if (b.groupTitle == "Site Selection") {
                            if (
                              a.groupTitle == "Live" ||
                              a.groupTitle == "Prelaunch" ||
                              a.groupTitle == "Buildout"
                            ) {
                              return -1;
                            }

                            return 1;
                          }

                          return a.groupTitle < b.groupTitle ? -1 : 1;
                        };
                      }

                      return (a, b) => {
                        return a.label < b.label ? -1 : 1;
                      };
                    }}
                    renderExtraFooter={() => (
                      <div className="pr-2 py-2 border-top">
                        <div className="pl-2" style={{ maxWidth: 291 }}>
                          <div className="small text-dark font-weight-bold text-center mb-2">
                            Quick Select
                          </div>
                          <Row className="align-items-center">
                            <Col xs="4" className="pr-1">
                              <Button
                                block
                                color={
                                  this.isTier("Impact Level")
                                    ? "primary"
                                    : "dark"
                                }
                                outline={
                                  this.isTier("Impact Level") ? false : true
                                }
                                size="sm"
                                onClick={() => {
                                  this.selectTier("Impact Level");
                                }}
                              >
                                Impact ({this.countTier("Impact Level")})
                              </Button>
                            </Col>
                            <Col xs="4" className="pl-1 pr-1">
                              <Button
                                block
                                color={
                                  this.isTier("Growth") ? "primary" : "dark"
                                }
                                outline={this.isTier("Growth") ? false : true}
                                size="sm"
                                onClick={() => {
                                  this.selectTier("Growth");
                                }}
                              >
                                Growth ({this.countTier("Growth")})
                              </Button>
                            </Col>
                            <Col xs="4" className="pl-1">
                              <Button
                                block
                                color={
                                  this.isTier("Critical Level")
                                    ? "primary"
                                    : "dark"
                                }
                                outline={
                                  this.isTier("Critical Level") ? false : true
                                }
                                size="sm"
                                onClick={() => {
                                  this.selectTier("Critical Level");
                                }}
                              >
                                Critical ({this.countTier("Critical Level")})
                              </Button>
                            </Col>
                            <Col xs="6" className="pr-1 mt-2">
                              <Button
                                block
                                color={
                                  this.isTier("Post-Open Level")
                                    ? "primary"
                                    : "dark"
                                }
                                outline={
                                  this.isTier("Post-Open Level") ? false : true
                                }
                                size="sm"
                                onClick={() => {
                                  this.selectTier("Post-Open Level");
                                }}
                              >
                                Post-Open ({this.countTier("Post-Open Level")})
                              </Button>
                            </Col>
                            <Col xs="6" className="pl-1 mt-2">
                              <Button
                                block
                                color={
                                  this.isTier("Pre-Open Level")
                                    ? "primary"
                                    : "dark"
                                }
                                outline={
                                  this.isTier("Pre-Open Level") ? false : true
                                }
                                size="sm"
                                onClick={() => {
                                  this.selectTier("Pre-Open Level");
                                }}
                              >
                                Pre-Open ({this.countTier("Pre-Open Level")})
                              </Button>
                            </Col>
                          </Row>
                        </div>
                        <hr className="my-2"></hr>
                        <Row className="align-items-center">
                          <Col xs="">
                            <Checkbox
                              className="ml-1"
                              inline
                              indeterminate={
                                this.state.pendingSelectedStores?.length > 0 &&
                                this.state.pendingSelectedStores?.length <
                                  this.state.storeOptions?.length
                              }
                              checked={
                                this.state.pendingSelectedStores?.length ===
                                this.state.storeOptions?.length
                              }
                              onChange={this.onSelectAllStores.bind(this)}
                            >
                              All Stores
                            </Checkbox>
                          </Col>
                          <Col xs="auto">
                            <Button
                              color="dark"
                              outline
                              size="sm"
                              onClick={() => {
                                this.onCancelChangeStores();

                                this.storePickerRef.current.close();
                              }}
                            >
                              Cancel
                            </Button>

                            <Button
                              color="primary"
                              size="sm"
                              onClick={() => {
                                this.storePickerRef.current.close();
                              }}
                            >
                              Apply
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    )}
                  ></CheckPicker>
                </div>
              </Col>
            </Row>
          </div>
          <div className="mb-3">
            {this.state.loadingStores ? (
              <>
                <h1 className="m-0 skeleton" style={{ width: 280 }}>
                  &nbsp;
                </h1>
                <p
                  className="mt-2 skeleton"
                  style={{ width: 260, height: 16, lineHeight: 1.2 }}
                >
                  &nbsp;
                </p>
              </>
            ) : (
              <>
                <h1 className="m-0">{this.renderDateRange()}</h1>
                <p className="m-0 text-muted">
                  Compared to {this.renderPreviousDateRange()}
                </p>
              </>
            )}
          </div>
          <div>
            {!this.hasValidDateRange() && (
              <>
                <Card className="border">
                  <CardBody className="text-center">
                    <h3>Select Date Range</h3>
                    <p className="m-0">
                      Select a date range to view the sales summary.
                    </p>
                  </CardBody>
                </Card>
              </>
            )}
            {this.hasValidDateRange() && (
              <>
                {/**
                 * <div className="mb-4">
                  <Card>
                    <CardBody className="p-3">
                      <Row className="align-items-center">
                        <Col xs="">
                          <h3 className="text-dark m-0 font-weight-bold">
                            Explain It
                          </h3>
                        </Col>
                        <Col xs="auto">
                          <Button
                            size="sm"
                            onClick={() => {
                              this.explainToMe();
                            }}
                            outline
                            color="primary"
                          >
                            Generate
                          </Button>
                        </Col>
                      </Row>
                      {this.state.explainURL ? (
                        <audio
                          className="mt-3"
                          controls
                          src={this.state.explainURL}
                          style={{ width: "100%" }}
                        />
                      ) : null}
                    </CardBody>
                  </Card>
                </div>
                 */}
                <div className="mb-5">
                  <DashboardSectionHeader
                    title="Store Goals"
                    description="Measure your store's progress over time and compare it to other Project
        LeanNation locations."
                  ></DashboardSectionHeader>

                  <ColGrid
                    numColsLg={6}
                    numColsMd={6}
                    numColsSm={6}
                    gapX="gap-x-6"
                    gapY="gap-y-6"
                    marginTop="mt-6"
                  >
                    {/* Main section */}
                    <TCol numColSpanSm={3} numColSpanMd={3} numColSpanLg={3}>
                      <Block spaceY="space-y-6">
                        <ClientOrderKPICard
                          dateRange={this.state.dateRange}
                          comparePrevious={true}
                          stores={this.state.userSelectedLiveStores}
                          isGlobal={this.isGlobal()}
                        ></ClientOrderKPICard>
                        <RetailSalesKPICard
                          dateRange={this.state.dateRange}
                          comparePrevious={true}
                          stores={this.state.userSelectedLiveStores}
                          isGlobal={this.isGlobal()}
                        ></RetailSalesKPICard>
                      </Block>
                    </TCol>

                    {/* KPI sidebar */}
                    <TCol numColSpanLg={3} numColSpanSm={3} numColSpanMd={3}>
                      <StoreLeaderboardKPICard
                        dateRange={this.state.dateRange}
                        comparePrevious={true}
                        stores={this.state.allLiveStoreIDs}
                      ></StoreLeaderboardKPICard>
                    </TCol>
                  </ColGrid>
                </div>
                <div className="mb-5">
                  <DashboardSectionHeader
                    title="Member Retention"
                    description="Keep track of your store's active member retention."
                  ></DashboardSectionHeader>
                  <ColGrid
                    numColsLg={6}
                    numColsMd={6}
                    numColsSm={6}
                    gapX="gap-x-6"
                    gapY="gap-y-6"
                    marginTop="mt-6"
                  >
                    {/* Main section */}
                    <TCol numColSpanSm={6} numColSpanMd={3} numColSpanLg={3}>
                      <ActiveMemberSummaryChartCard
                        dateRange={this.state.dateRange}
                        comparePrevious={true}
                        stores={this.state.selectedLiveAndPrelaunchStores}
                      ></ActiveMemberSummaryChartCard>
                    </TCol>
                    {/* KPI sidebar */}
                    <TCol numColSpanLg={3} numColSpanSm={6} numColSpanMd={3}>
                      <ConsultationKPICard
                        dateRange={this.state.dateRange}
                        comparePrevious={true}
                        stores={this.state.selectedLiveStores}
                        allStores={this.state.allLiveStoreIDs}
                      ></ConsultationKPICard>
                    </TCol>
                  </ColGrid>
                  <ColGrid
                    numColsLg={6}
                    numColsMd={6}
                    numColsSm={6}
                    gapX="gap-x-6"
                    gapY="gap-y-6"
                    marginTop="mt-6"
                  >
                    <TCol numColSpanSm={3} numColSpanMd={3} numColSpanLg={3}>
                      <MemberExperienceKPICard
                        dateRange={this.state.dateRange}
                        comparePrevious={true}
                        stores={this.state.selectedLiveStores}
                        allStores={this.state.allLiveStoreIDs}
                      ></MemberExperienceKPICard>
                    </TCol>
                    <TCol numColSpanSm={3} numColSpanMd={3} numColSpanLg={3}>
                      <FulfillmentRateKPICard
                        dateRange={this.state.dateRange}
                        comparePrevious={true}
                        stores={this.state.selectedLiveStores}
                        allStores={this.state.allLiveStoreIDs}
                      ></FulfillmentRateKPICard>
                    </TCol>
                  </ColGrid>

                  <ColGrid
                    numColsLg={6}
                    numColsMd={6}
                    numColsSm={6}
                    gapX="gap-x-6"
                    gapY="gap-y-6"
                    marginTop="mt-6"
                  >
                    <TCol numColSpanSm={3} numColSpanMd={3} numColSpanLg={3}>
                      <OrderCountKPICard
                        dateRange={this.state.dateRange}
                        comparePrevious={true}
                        stores={this.state.selectedLiveStores}
                        allStores={this.state.allLiveStoreIDs}
                      ></OrderCountKPICard>
                    </TCol>
                    <TCol numColSpanSm={3} numColSpanMd={3} numColSpanLg={3}>
                      <SubscriptionLTVKPICard
                        dateRange={this.state.dateRange}
                        comparePrevious={true}
                        stores={this.state.selectedLiveStores}
                        allStores={this.state.allLiveStoreIDs}
                      ></SubscriptionLTVKPICard>
                    </TCol>
                  </ColGrid>
                </div>
                <div className="mb-5">
                  <DashboardSectionHeader
                    title="Lead Acquisition"
                    description="Keep track of your store's lead acquisition."
                  ></DashboardSectionHeader>

                  <ColGrid
                    numColsMd={12}
                    numColsLg={12}
                    gapX="gap-x-6"
                    gapY="gap-y-6"
                    marginTop="mt-6"
                  >
                    <TCol numColSpanMd={6} numColSpanLg={6}>
                      <ScorecardNewLeadChartCard
                        dateRange={this.state.dateRange}
                        comparePrevious={true}
                        stores={this.state.selectedStores}
                      ></ScorecardNewLeadChartCard>
                    </TCol>
                    <TCol numColSpanMd={6} numColSpanLg={6}>
                      <OutreachKPICard
                        dateRange={this.state.dateRange}
                        comparePrevious={true}
                        stores={this.state.selectedStores}
                        allStores={this.state.allStoreIDs}
                      ></OutreachKPICard>
                    </TCol>
                  </ColGrid>
                  <ColGrid
                    numColsLg={6}
                    numColsMd={6}
                    numColsSm={6}
                    gapX="gap-x-6"
                    gapY="gap-y-6"
                    marginTop="mt-6"
                  >
                    {/* Main section */}
                    <TCol numColSpanSm={6} numColSpanMd={3} numColSpanLg={3}>
                      <LeadConversionRateKPICard
                        dateRange={this.state.dateRange}
                        comparePrevious={true}
                        stores={this.state.selectedLiveAndPrelaunchStores}
                        allStores={this.state.allLiveAndPrelaunchStoreIDs}
                      ></LeadConversionRateKPICard>
                    </TCol>
                    <TCol numColSpanLg={3} numColSpanSm={6} numColSpanMd={3}>
                      <CommunityEngagementKPICard
                        dateRange={this.state.dateRange}
                        comparePrevious={true}
                        stores={this.state.selectedStores}
                        allStores={this.state.allStoreIDs}
                      ></CommunityEngagementKPICard>
                    </TCol>
                    {/* KPI sidebar */}
                    {/**
                     * <TCol numColSpanLg={3} numColSpanSm={6} numColSpanMd={3}>
                      <LeadConversionTimeKPICard
                        dateRange={this.state.dateRange}
                        comparePrevious={true}
                        stores={this.state.selectedLiveAndPrelaunchStores}
                        allStores={this.state.allLiveAndPrelaunchStoreIDs}
                      ></LeadConversionTimeKPICard>
                    </TCol>
                     */}
                  </ColGrid>
                </div>
                <div className="mb-5">
                  <DashboardSectionHeader
                    title="Member Growth"
                    description="Keep track of your store's member growth."
                  ></DashboardSectionHeader>
                  <ColGrid
                    numColsLg={6}
                    numColsMd={6}
                    numColsSm={6}
                    gapX="gap-x-6"
                    gapY="gap-y-6"
                    marginTop="mt-6"
                  >
                    {/* Main section */}
                    <TCol numColSpanSm={6} numColSpanMd={3} numColSpanLg={3}>
                      <NewMemberKPICard
                        dateRange={this.state.dateRange}
                        comparePrevious={true}
                        stores={this.state.selectedLiveAndPrelaunchStores}
                      ></NewMemberKPICard>
                    </TCol>

                    {/* KPI sidebar */}
                    <TCol numColSpanLg={3} numColSpanSm={6} numColSpanMd={3}>
                      <ScorecardNewHFNChartCard
                        dateRange={this.state.dateRange}
                        comparePrevious={true}
                        stores={this.state.selectedLiveAndPrelaunchStores}
                      ></ScorecardNewHFNChartCard>
                    </TCol>
                  </ColGrid>
                  <div className="mt-4">
                    <NetMemberKPICard
                      dateRange={this.state.dateRange}
                      comparePrevious={true}
                      stores={this.state.selectedLiveAndPrelaunchStores}
                    ></NetMemberKPICard>
                  </div>
                </div>
              </>
            )}
          </div>
        </Container>
      </>
    );
  }
}

export default ScorecardDashboard;
