import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
  Button,
  Modal,
  Row,
  Col,
  Badge,
} from "reactstrap";
import _ from "underscore";

import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import Compressor from "@uppy/compressor";
import XHR from "@uppy/xhr-upload";
import GoldenRetriever from "@uppy/golden-retriever";
import RemoteSources from "@uppy/remote-sources";
import GoogleDrive from "@uppy/google-drive";
import Instagram from "@uppy/instagram";
import Url from "@uppy/url";
import Facebook from "@uppy/facebook";

import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import APIV2 from "../lib/APIV2";
import { Drawer } from "rsuite";
import FileUploader from "./FileUploader";
import { Capacitor } from "@capacitor/core";

class FileUploadDrawer extends React.Component {
  state = {
    uppy: null,
    files: [],
  };

  render() {
    return (
      <>
        <Drawer
          size={this.props.size ? this.props.size : "md"}
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => {
            if (typeof this.props.onComplete == "function") {
              this.props.onComplete(
                this.state.files?.length ? this.state.files : []
              );
            }

            this.setState(
              {
                files: [],
              },
              () => {}
            );
          }}
          backdrop="static"
        >
          <Drawer.Header className="pr-4 border-bottom-0">
            <h3 className="mb-2" style={{ position: "relative", top: "7px" }}>
              Upload Files
            </h3>
          </Drawer.Header>
          <Drawer.Body className="p-0" style={{ height: "calc(100% - 70px)" }}>
            <FileUploader
              compressUploads={this.props.compressUploads}
              embedded={true}
              cdn={this.props.cdn ? this.props.cdn : 'private'}
              singleFile={this.props.singleFile}
              allowedFileTypes={this.props.allowedFileTypes}
              height={`${
                Capacitor.isNativePlatform()
                  ? "calc(100vh - 120px)"
                  : "calc(100vh - 70px)"
              }`}
              stateless={true}
              onChange={(files) => {
                this.setState({
                  files: files,
                });

                console.log("CHANGE", files);
              }}
              onUpload={() => {
                console.log("UPLOAD", this.state.files);

                if (typeof this.props.onComplete == "function") {
                  this.props.onComplete(
                    this.state.files?.length ? this.state.files : []
                  );
                }
              }}
            ></FileUploader>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default FileUploadDrawer;
