export default class Role {
    __construct(id, name, description, permissions = {}, created = '', updated = '') {
        this.id = id;
        this.name = name;
        this.description = description;
        this.permissions = permissions;
        this.created = created;
        this.updated = updated;
    }

    getID() {
        return this.id;
    }

    setID(val) {
        this.id = val;
    }

    getName() {
        return this.name;
    }

    setName(val) {
        this.name = val;
    }

    getDescription() {
        return this.description;
    }

    setDescription(val) {
        this.description = val;
    }

    getPermissions() {
        return this.permissions;
    }

    setPermissions(val) {
        if (!val || typeof val != 'object') {
            this.permissions = {};

            return;
        }

        this.permissions = val;
    }

    hasPermission(key) {
        return this.permissions && this.permissions.hasOwnProperty(key) && this.permissions[key];
    }

    setPermission(key, val) {
        this.permissions[key] = val ? true : false;
    }

    getCreated() {
        return this.created;
    }

    setCreated(val) {
        this.created = val;
    }

    getUpdated() {
        return this.updated;
    }

    setUpdated(val) {
        this.updated = val;
    }

    _toData() {
        let out = {
            id: this.getID(),
            name: this.getName(),
            description: this.getDescription(),
            permissions: this.getPermissions(),
            created: this.getCreated(),
            updated: this.getUpdated(),
        };

        return out;
    }

    static _fromData(data) {
        let role = new Role();

        if (!data) {
            return role;
        }

        if (data.id) {
            role.setID(data.id);
        }

        if (data.name) {
            role.setName(data.name);
        }

        if (data.description) {
            role.setDescription(data.description);
        }

        if (data.permissions) {
            role.setPermissions(data.permissions);
        }

        if (data.created) {
            role.setCreated(data.created);
        }

        if (data.updated) {
            role.setUpdated(data.updated);
        }

        return role;
    }
}