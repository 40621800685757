import { Capacitor } from "@capacitor/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import { Dropdown, Popover, Tooltip, Whisper } from "rsuite";
import swal from "sweetalert";
import APIV2 from "../../lib/APIV2";
import Event from "../../lib/Event";
import PubSub from "../../lib/PubSub";
import StringUtils from "../../lib/StringUtils";

const ConversationListItem = ({
  conversation = {},
  active = false,
  onClick,
  skeleton = false,
  selectMode = false,
  selected = false,
  onSelect = null,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [markingRead, setMarkingRead] = useState(false);
  const [modifyingStatus, setModifyingStatus] = useState(false);
  const [scrollPos, setScrollPos] = useState(null);
  const [subscribeFunctionNumber, setSubscribeFunctionNumber] = useState(null);
  const [conversationID, setConversationID] = useState(null);
  const dropdownRef = useRef();
  const timerRef = useRef();

  const [unsubscribing, setUnsubscribing] = useState(false);

  const [touchAction, setTouchAction] = useState("press");
  const isLongPress = useRef();

  const [blocking, setBlocking] = useState(false);

  const getConversationName = (conversation) => {
    if (!conversation) {
      return "(unknown sender)";
    }

    if (conversation?.customer?.name) {
      return conversation?.customer?.name;
    }

    if (conversation?.lead?.name) {
      return conversation?.lead?.name;
    }

    if (conversation?.recipientPhone) {
      return StringUtils.formatPhoneNumber(
        conversation?.recipientPhone?.replace("+1", "")
      );
    }

    if (conversation?.recipientName) {
      return conversation?.recipientName;
    }

    return "(unknown sender)";
  };

  useEffect(() => {
    if (!conversation?._id) {
      return;
    }

    if (subscribeFunctionNumber) {
      PubSub.removeSubscriber(
        `conversationDropdownOpen`,
        subscribeFunctionNumber
      );
    }

    let num = PubSub.subscribe("conversationDropdownOpen", (id) => {
      console.log(id);

      if (id != conversation?._id && dropdownRef.current) {
        dropdownRef.current.close();
      }
    });

    setSubscribeFunctionNumber(num);
    setConversationID(conversation?._id);
  }, [conversation]);

  /**
   * Unsubscribes the contact from messages
   */
  const unsubscribe = () => {
    swal({
      title: "Unsubscribe Contact",
      text: `Are you sure you want to unsubscribe this contact?`,
      icon: "warning",
      buttons: ["Nevermind", "Unsubscribe"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      setUnsubscribing(true);

      APIV2.bulkCreateContactSuppressions(
        conversation?.storeID,
        "UNSUBSCRIBED",
        [conversation?.recipientPhone]
      )
        .then(
          (data) => {},
          (e) => {
            window.alert(
              e?.response?.message ??
                "Unable to unsubscribe contact. Please try again."
            );
          }
        )
        .finally(() => {
          setUnsubscribing(false);
        });
    });
  };

  /**
   * Blocks the contact from sending messages to the store
   */
  const block = () => {
    swal({
      title: "Block Contact",
      text: `Are you sure you want to block this contact?`,
      icon: "warning",
      buttons: ["Nevermind", "Block"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      setBlocking(true);

      APIV2.bulkCreateContactSuppressions(conversation?.storeID, "BLOCKED", [
        conversation?.recipientPhone,
      ])
        .then(
          (data) => {},
          (e) => {
            window.alert(
              e?.response?.message ??
                "Unable to block contact. Please try again."
            );
          }
        )
        .finally(() => {
          setBlocking(false);
        });
    });
  };

  /**
   * Unblocks the contact
   */
  const unblock = () => {
    swal({
      title: "Unblock Contact",
      text: `Are you sure you want to unblock this contact?`,
      icon: "warning",
      buttons: ["Nevermind", "Unblock"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      setBlocking(true);

      APIV2.deleteContactSuppressionForStore(
        conversation?.storeID,
        conversation?.blockSuppressionID
      )
        .then(
          (data) => {},
          (e) => {
            window.alert(
              e?.response?.message ??
                "Unable to unblock contact. Please try again."
            );
          }
        )
        .finally(() => {
          setBlocking(false);
        });
    });
  };

  /**
   * Marks the existing conversation as read
   */
  const markAsRead = () => {
    setMarkingRead(true);

    APIV2.markConversationAsRead(conversation?._id)
      .then(
        (data) => {},
        (e) => {}
      )
      .finally(() => {
        setMarkingRead(false);
      });
  };

  const startPressTimer = () => {
    isLongPress.current = false;
    setTouchAction("press");

    timerRef.current = setTimeout(() => {
      setTouchAction("longpress");
      isLongPress.current = true;

      dropdownRef.current.open();

      PubSub.publish("conversationDropdownOpen", conversation?._id);

      setDropdownOpen(true);
    }, 500);
  };

  const onTouchStart = (e) => {
    console.log("dropdown open", dropdownOpen);

    if (dropdownOpen) {
      return;
    }

    if (selectMode) {
      if (selected && typeof onSelect == "function") {
        onSelect(conversation, false);
      } else if (!selected && typeof onSelect == "function") {
        onSelect(conversation, true);
      }

      return;
    }

    console.log("ON TOUCH START");

    let el = document.getElementsByClassName(
      "conversation-list-item-container"
    );

    if (el.length) {
      el = el[0];

      setScrollPos({ scrollTop: el.scrollTop, scrollLeft: el.scrollLeft });
    }

    startPressTimer();
  };

  const onTouchEnd = (e) => {
    console.log("ON TOUCH END", dropdownOpen);

    let el = document.getElementsByClassName(
      "conversation-list-item-container"
    );

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (dropdownOpen || selectMode) {
      return;
    }

    if (el.length) {
      el = el[0];

      if (scrollPos?.scrollTop != el.scrollTop) {
        setScrollPos(null);

        return;
      }
    }

    if (touchAction === "press") {
      // TODO: open;

      if (typeof onClick === "function") {
        PubSub.publish("conversationDropdownOpen", null);

        onClick(conversation);
      }

      setScrollPos(null);

      return;
    }

    setTouchAction("press");
    setScrollPos(null);
  };

  const onTouchMove = (e) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  /**
   * Marks the existing conversation as read
   */
  const modifyStatus = () => {
    setModifyingStatus(true);

    const status = conversation?.status == "OPEN" ? "CLOSED" : "OPEN";

    APIV2.modifyConversationStatus(conversation?._id, status)
      .then(
        (data) => {},
        (e) => {}
      )
      .finally(() => {
        setModifyingStatus(false);
      });
  };

  /**
   * Marks the existing conversation as unread
   */
  const markAsUnread = () => {
    setMarkingRead(true);

    APIV2.markConversationAsUnread(conversation?._id)
      .then(
        (data) => {},
        (e) => {}
      )
      .finally(() => {
        setMarkingRead(false);
      });
  };

  const getConversationDescription = (conversation) => {
    if (conversation?.customer) {
      return "Member";
    }

    if (conversation?.lead) {
      return "Lead";
    }
  };

  const getTimeAgo = (conversation) => {
    if (!conversation?.latestMessageTimestamp) {
      return "";
    }

    let mom = moment(conversation?.latestMessageTimestamp);

    let now = moment();

    if (
      now?.toDate().getTime() - mom?.toDate().getTime() >
      60 * 60 * 24 * 1000
    ) {
      return mom.format("MMM D");
    }

    let out = mom.fromNow();

    if (out == "a few seconds ago") {
      return "just now";
    }

    return out;
  };

  return (
    <>
      {skeleton ? (
        <>
          <div
            style={{ height: 101 }}
            className={`conversation-list-item border-bottom p-3`}
          >
            <Row className="align-items-top">
              <Col xs="12">
                <div
                  className="skeleton"
                  style={{ height: 30, width: "45%" }}
                ></div>
              </Col>
            </Row>
            <div
              className="skeleton mt-3"
              style={{ height: 22, width: "100%" }}
            ></div>
          </div>
        </>
      ) : (
        <>
          <Button
            onClick={() => {
              if (Capacitor.isNativePlatform() || dropdownOpen) {
                return;
              }

              if (selectMode) {
                if (selected && typeof onSelect == "function") {
                  onSelect(conversation, false);
                } else if (!selected && typeof onSelect == "function") {
                  onSelect(conversation, true);
                }

                return;
              }

              if (typeof onClick == "function") {
                onClick(conversation);
              }
            }}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            onTouchMove={onTouchMove}
            color=""
            className={`conversation-list-item d-block text-left ${
              active && !selectMode
                ? "bg-primary-conversation-active bg-primary-conversation-hover--hover"
                : "bg-superlight--hover"
            } ${
              selectMode && selected
                ? "bg-primary-conversation-active bg-primary-conversation-hover--hover"
                : "bg-superlight--hover"
            } p-3 border-bottom  cursor-pointer`}
            style={{
              position: "relative",
              borderRadius: 0,
              width: "100%",
              boxShadow: "none",
            }}
          >
            <div style={{ width: "100%" }}>
              <Row className="align-items-top">
                <Col xs="" style={{ maxWidth: "calc(100% - 85px)" }}>
                  <h4
                    className={`text-dark m-0 text-truncate ${
                      conversation?.unreadCount > 0
                        ? "font-weight-bold"
                        : "font-weight-normal"
                    }`}
                  >
                    {selectMode ? (
                      <>
                        <i
                          className={`mdi  mr-1 ${
                            selected
                              ? "mdi-checkbox-marked text-primary"
                              : "mdi-checkbox-blank-outline text-dark"
                          }`}
                          style={{ position: "relative", top: 1 }}
                        ></i>
                      </>
                    ) : null}
                    {getConversationName(conversation)}
                    {conversation?.unsubscribeSuppressionID && !selectMode ? (
                      <>
                        <Whisper
                          trigger={["click", "hover"]}
                          delayOpen={400}
                          placement="autoHorizontal"
                          speaker={<Tooltip>Unsubscribed</Tooltip>}
                        >
                          <i
                            className="mdi mdi-close-octagon-outline cursor-pointer text-dark pl-2"
                            style={{
                              lineHeight: 1,
                              position: "relative",
                            }}
                          ></i>
                        </Whisper>
                      </>
                    ) : null}
                    {conversation?.blockSuppressionID && !selectMode ? (
                      <>
                        <Whisper
                          trigger={["click", "hover"]}
                          delayOpen={400}
                          placement="autoHorizontal"
                          speaker={<Tooltip>Blocked</Tooltip>}
                        >
                          <i
                            className="mdi mdi-account-cancel cursor-pointer text-dark pl-2"
                            style={{
                              lineHeight: 1,
                              position: "relative",
                            }}
                          ></i>
                        </Whisper>
                      </>
                    ) : null}
                    {conversation?.status == "CLOSED" && !selectMode ? (
                      <>
                        <Whisper
                          trigger="hover"
                          placement="autoHorizontal"
                          speaker={<Tooltip>Conversation Closed</Tooltip>}
                          delayOpen={400}
                        >
                          <i className="mdi mdi-chat-remove-outline ml-2"></i>
                        </Whisper>
                      </>
                    ) : null}
                  </h4>
                  <p className="small m-0" style={{ lineHeight: 1.3 }}>
                    {getConversationDescription(conversation)}
                  </p>
                </Col>
                <Col
                  xs=""
                  style={{ maxWidth: 85 }}
                  className="pl-0 text-right text-truncate"
                >
                  <p
                    className="small m-0 text-truncate"
                    style={{ lineHeight: 1.2 }}
                  >
                    {getTimeAgo(conversation)}
                  </p>
                </Col>
              </Row>
              <Row className="align-items-center mt-2">
                <Col
                  xs=""
                  className={`list-item-body ${
                    dropdownOpen || conversation?.unreadCount > 0 ? "short" : ""
                  }`}
                >
                  <div
                    style={{ fontSize: 14 }}
                    className={`text-dark text-truncate ${
                      conversation?.unreadCount > 0 ? "font-weight-bold" : ""
                    }`}
                  >
                    {conversation?.latestMessage?.storeSent && (
                      <i
                        className="mdi mdi-share pr-1"
                        style={{ position: "relative", top: 1 }}
                      ></i>
                    )}
                    {conversation?.latestMessage?.body
                      ? conversation?.latestMessage?.body
                      : "(empty message)"}
                  </div>
                </Col>

                {conversation?.unreadCount > 0 ? (
                  <Col xs="auto" className="pl-0">
                    <i
                      className="mdi mdi-circle text-primary unread-icon"
                      style={{
                        fontSize: 12,
                        visibility: dropdownOpen ? "hidden" : "",
                      }}
                    ></i>
                  </Col>
                ) : null}
              </Row>
            </div>
            <div
              className={`action-dropdown-button ${dropdownOpen && "d-block"} ${
                conversation?._id == "new" && "d-none"
              } ${selectMode && "d-none"}`}
            >
              <Whisper
                onClose={() => {
                  setDropdownOpen(false);
                }}
                trigger="click"
                ref={dropdownRef}
                placement="autoVerticalEnd"
                speaker={
                  <Popover className="dropdown-popover" full>
                    <Dropdown.Menu
                      style={{
                        minWidth: 150,
                        paddingBottom: 0,
                      }}
                    >
                      <Dropdown.Item
                        panel
                        className="font-weight-800 text-uppercase"
                        style={{
                          padding: 12,
                        }}
                      >
                        <h5 className="m-0 text-dark">Actions</h5>
                      </Dropdown.Item>
                      <Dropdown.Item className="my-0" divider></Dropdown.Item>
                      <Dropdown.Item
                        disabled={markingRead}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          if (conversation?.unreadCount > 0) {
                            markAsRead();
                          } else {
                            markAsUnread();
                          }

                          console.log("INSIDE HERE");

                          dropdownRef.current.close();
                        }}
                        className="text-dark bg-superlight--hover"
                      >
                        <Row className="align-items-center">
                          <Col xs="auto" className="pr-0">
                            <i
                              className={`mdi mdi-${
                                conversation?.unreadCount > 0
                                  ? "checkbox-marked-outline"
                                  : "checkbox-blank-badge-outline"
                              }`}
                              style={{ fontSize: 16, lineHeight: 1.3 }}
                            ></i>
                          </Col>
                          <Col xs="" className="pl-2">
                            <p className="m-0" style={{ fontSize: 14 }}>
                              Mark As{" "}
                              {conversation?.unreadCount > 0
                                ? "Read"
                                : "Unread"}
                            </p>
                          </Col>
                        </Row>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          //this.settingsDropdownRef.current.close();
                          e.preventDefault();
                          e.stopPropagation();

                          modifyStatus();

                          dropdownRef.current.close();
                        }}
                        disabled={modifyingStatus}
                        className="text-dark bg-superlight--hover"
                      >
                        <Row className="align-items-center">
                          <Col xs="auto" className="pr-0">
                            <i
                              className={`mdi mdi-${
                                conversation?.status == "OPEN"
                                  ? "close-circle-outline"
                                  : "check-circle-outline"
                              }`}
                              style={{ fontSize: 16, lineHeight: 1.3 }}
                            ></i>
                          </Col>
                          <Col xs="" className="pl-2">
                            <p
                              className="m-0"
                              style={{ fontSize: 14, lineHeight: 1 }}
                            >
                              {conversation?.status == "OPEN"
                                ? "Close"
                                : "Reopen"}
                              &nbsp;Conversation
                            </p>
                          </Col>
                        </Row>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          PubSub.publish(
                            Event.CONVERSATION.VIEW_DETAILS,
                            conversation
                          );

                          dropdownRef.current.close();
                        }}
                        className="text-dark bg-superlight--hover"
                      >
                        <Row className="align-items-center">
                          <Col xs="auto" className="pr-0">
                            <i
                              className={`mdi mdi-information-outline`}
                              style={{ fontSize: 16, lineHeight: 1.3 }}
                            ></i>
                          </Col>
                          <Col xs="" className="pl-2">
                            <p className="m-0" style={{ fontSize: 14 }}>
                              View Details
                            </p>
                          </Col>
                        </Row>
                      </Dropdown.Item>
                      <Dropdown.Item className="my-0" divider></Dropdown.Item>

                      {conversation?.blockSuppressionID ? (
                        <Dropdown.Item
                          disabled={blocking}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            dropdownRef.current.close();

                            unblock();
                          }}
                          className="text-dark bg-superlight--hover"
                        >
                          <Row className="align-items-center">
                            <Col xs="auto" className="pr-0">
                              <i
                                className={`mdi mdi-check-circle-outline`}
                                style={{
                                  fontSize: 16,
                                  lineHeight: 1.3,
                                }}
                              ></i>
                            </Col>
                            <Col xs="" className="pl-2">
                              <p className="m-0" style={{ fontSize: 14 }}>
                                Unblock Sender
                              </p>
                            </Col>
                          </Row>
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item
                          disabled={blocking}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            dropdownRef.current.close();

                            block();
                          }}
                          className="text-danger bg-superlight--hover"
                        >
                          <Row className="align-items-center">
                            <Col xs="auto" className="pr-0">
                              <i
                                className={`mdi mdi-account-cancel`}
                                style={{
                                  fontSize: 16,
                                  lineHeight: 1.3,
                                }}
                              ></i>
                            </Col>
                            <Col xs="" className="pl-2">
                              <p className="m-0" style={{ fontSize: 14 }}>
                                Block Sender
                              </p>
                            </Col>
                          </Row>
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Popover>
                }
              >
                <Button
                  size="sm"
                  outline
                  color="dark"
                  className="btn-icon-only"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (dropdownOpen) {
                      setDropdownOpen(false);

                      return dropdownRef.current.close();
                    }

                    PubSub.publish(
                      "conversationDropdownOpen",
                      conversation?._id
                    );

                    setDropdownOpen(true);

                    dropdownRef.current.open();
                  }}
                >
                  <i className="mdi mdi-dots-vertical"></i>
                </Button>
              </Whisper>
            </div>
          </Button>
        </>
      )}
    </>
  );
};

export default withRouter(ConversationListItem);
