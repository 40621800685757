import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, Dropdown, Popover, SelectPicker, Whisper } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import CustomerCreateDrawer from "components/Drawers/CustomerCreateDrawer";
import TaskCreateDrawer from "components/Drawers/TaskCreateDrawer";
import TaskModifyDrawer from "components/Drawers/TaskModifyDrawer";
import TaskDetailDrawer from "components/Drawers/TaskDetailDrawer";
import InAppContentDrawer from "../../components/Drawers/InAppContentDrawer";
import swal from "sweetalert";

class GlobalAppContentView extends React.Component {
  state = {
    active: {
      id: "Active Cards",
      name: "Active Cards",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 25,
    filters: null,
    orderBy: {
      dueDate: -1,
    },
    sortByOption: "dueDate",
    sortByDirection: "ascending",
    truncate: true,
    truncateStyles: {
      maxHeight: "30px",
    },
  };

  deletingTemplate(template) {
    swal({
      title: "Delete Template",
      text: `Are you sure you want to delete the "${template?.name}" conversation template?`,
      icon: "warning",
      buttons: ["Nevermind", "Delete"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        deletingTemplate: template?._id,
      });

      APIV2.deleteGlobalConversationTemplate(template?._id)
        .then(
          () => {
            PubSub.publish(Event.IN_APP_CONTENT.DELETED, template);

            swal({
              title: "Templated Deleted",
              text: "Your conversation template has been successfully deleted",
              icon: "success",
            });
          },
          (e) => {
            window.alert(
              e?.replace?.message ??
                "Unable to delete template. Please try again."
            );
          }
        )
        .finally(() => {
          this.setState({
            deletingTemplate: null,
          });
        });
    });
  }

  componentDidUpdate(prevProps) {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab && tab != this.state.active?.id) {
      this.handleTabChange({
        id: decodeURIComponent(tab),
        name: decodeURIComponent(tab),
      });
    }

    if (prevProps?.match?.params.storeID != this.props.match?.params?.storeID) {
      this.handleTabChange(this.state.active, true);

      this.setState({
        store: {
          _id: this.props.match?.params?.storeID,
        },
      });
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab) {
      this.handleTabChange(
        {
          id: decodeURIComponent(tab),
          name: decodeURIComponent(tab),
        },
        true
      );
    } else {
      this.handleTabChange(this.state.active, true);
    }

    if (this.props.match?.params?.storeID) {
      this.setState({
        store: {
          _id: this.props.match?.params?.storeID,
        },
      });
    }

    PubSub.subscribe(Event.IN_APP_CONTENT.CREATED, (m) => {
      this.loadContent(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );
    });

    PubSub.subscribe(Event.IN_APP_CONTENT.MODIFIED, (a) => {
      this.loadContent(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );

      if (this.state.modifyTemplate?._id == a?._id) {
        this.setState({
          modifyTemplate: a,
        });
      }
    });

    PubSub.subscribe(Event.IN_APP_CONTENT.DELETED, (a) => {
      this.loadContent(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );
    });
  }

  loadContent(page = 1, count = 25, filters = null, orderBy) {
    this.setState({
      loadingTemplates: true,
    });

    APIV2.getAppContent(page, count, filters, orderBy)
      .then(
        (data) => {
          if (data && data.data) {
            let content = [];

            if (data?.data?.content?.length) {
              content = data.data.content;
            }

            this.setState({
              content,
              pagination: data.data.pagination,
              result: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the content. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingTemplates: false,
        });
      });
  }

  handleTabChange(option, force = false) {
    let filters = null;
    let orderBy = { displayRanking: 1 };

    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    if (option.id == "Active Cards") {
      filters = {
        platform: { $ne: "storefront" },
        displayStartDate: {
          $lte: { $date: moment().toDate() },
        },
        $or: [
          {
            displayEndDate: { $gte: { $date: moment().toDate() } },
          },
          {
            displayEndDate: null,
          },
        ],
      };
    } else if (option.id == "Inactive Cards") {
      filters = {
        platform: { $ne: "storefront" },
        $or: [
          {
            displayStartDate: {
              $gte: { $date: moment().toDate() },
            },
          },
          {
            displayEndDate: {
              $lte: { $date: moment().toDate() },
            },
          },
        ],
        $expr: {},
      };
    } else if (option.id == "All Cards") {
      filters = {
        platform: { $ne: "storefront" },
      };
    }

    this.setState({
      filters,
      orderBy,
      active: option,
    });

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.loadContent(1, this.state.count, filters, orderBy);
  }

  /*handleSearchChange(e) {
    const { name, value } = e.target;

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    if (value) {
      this.setState({
        searchLoading: true,
      });
    } else {
      this.setState({
        searchLoading: false,
        searchResults: false,
      });
    }

    if (value && value.length >= 3) {
      let timeout = setTimeout(() => {
        this.performSearch();
      }, 1200);

      this.setState({
        searchTimeout: timeout,
      });
    }

    this.setState({
      [name]: value,
    });
  }*/

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadContent(
      page,
      this.state.count,
      this.state.filters,
      this.state.orderBy
    );
  }

  templateRow(c) {
    return (
      <Row
        className="align-items-center cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();

          this.setState({ createOpen: true, modifyTemplate: c });
        }}
      >
        <Col xs="auto" style={{ width: 59 }}>
          {c?.dynamicRanking ? "DYN" : c?.displayRanking}
        </Col>
        <Col style={{ maxWidth: "calc(100% - 123px)" }} xs="" className="">
          <h4 className="mb-0">{c.name}</h4>
          {c?.description ? (
            <p className={`m-0 text-truncate small`}>{c?.description}</p>
          ) : null}
          <p className="mt-1 mb-0 small">
            Visible: {moment(c?.displayStartDate).format("MM/DD/YY")}
            {c?.displayEndDate
              ? ` - ${moment(c?.displayEndDate).format("MM/DD/YY")}`
              : " onwards"}
          </p>
        </Col>
        <Col xs="auto" className="text-right">
          <Button size="sm" outline color="dark" className="btn-icon-only">
            <i className="mdi mdi-chevron-right"></i>
          </Button>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader
            title="In-App Content"
            showBackButton={false}
            primaryAction={{
              name: <>Add</>,
              onClick: () => {
                this.setState({
                  createOpen: true,
                });
              },
            }}
          />

          <Row>
            <div className="col">
              <Card className=" shadow" style={{ minHeight: "500px" }}>
                <CardSelectorHeader
                  compact={true}
                  value={this.state.active}
                  options={[
                    {
                      name: "Active Cards",
                      id: "Active Cards",
                    },
                    {
                      name: "Inactive Cards",
                      id: "Inactive Cards",
                    },
                    {
                      name: "All Cards",
                      id: "All Cards",
                    },
                  ]}
                  onChange={this.handleTabChange.bind(this)}
                ></CardSelectorHeader>
                <CardHeader className="py-2 px-3">
                  {/*<Row className="align-items-center">
                    <Col xs="12" sm="">
                      <Input
                        type="text"
                        placeholder="Search content..."
                        size="sm"
                        name="search"
                        value={this.state.search}
                        onChange={this.handleSearchChange.bind(this)}
                      ></Input>
                    </Col>
                </Row>*/}
                  <Row className="align-items-center">
                    {/*<Col xs="auto" sm="auto" className="pr-0">
                      <Checkbox
                        indeterminate={true}
                        checked={true}
                        onChange={() => {}}
                      ></Checkbox>
                </Col>*/}
                    <Col xs="" sm="" className="">
                      <div>
                        {this.state.result?.start && this.state.result?.end ? (
                          <>
                            Showing{" "}
                            {StringUtils.numberFormat(this.state.result?.start)}
                            -{StringUtils.numberFormat(this.state.result?.end)}
                            {this.state.result?.total ? (
                              <>
                                &nbsp;of{" "}
                                {StringUtils.numberFormat(
                                  this.state.result?.total
                                )}{" "}
                                cards
                              </>
                            ) : null}
                          </>
                        ) : (
                          <>---</>
                        )}
                      </div>
                    </Col>
                    <Col
                      xs="auto"
                      sm="auto"
                      className="pl-2 pl-sm-3 text-right"
                    >
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        className="btn-icon-only"
                        outline
                        disabled={
                          this.state.page == 1 ||
                          !this.state.pagination?.hasPrevious ||
                          this.state.searchResults
                        }
                        onClick={() => {
                          this.incrementPage(-1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-left"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        disabled={
                          !this.state.pagination?.hasNext ||
                          this.state.searchResults
                        }
                        className="btn-icon-only"
                        outline
                        onClick={() => {
                          this.incrementPage(1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-right"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="py-2 px-3 bg-superlight border-bottom border-top text-dark">
                    <Row className="align-items-center">
                      <Col xs="auto" style={{ width: 59 }}>
                        <h4 className="mb-0">#</h4>
                      </Col>
                      <Col xs="12" sm="12" md="">
                        <h4 className="mb-0">Card</h4>
                      </Col>

                      <Col
                        xs="12"
                        sm="12"
                        md="auto"
                        className="text-left text-md-right d-none d-md-block"
                      ></Col>
                    </Row>
                  </div>

                  <div>
                    {this.state.loadingTemplates || this.state.searchLoading ? (
                      <>
                        <div
                          className="text-center p-3"
                          style={{
                            zIndex: 1,
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            background: "rgba(255, 255, 255, 0.6)",
                          }}
                        >
                          <Spinner
                            style={{ position: "relative", top: "15vh" }}
                            color="medium"
                            size=""
                          ></Spinner>
                        </div>
                      </>
                    ) : null}
                    <div>
                      {!this.state.content?.length &&
                      !this.state.loadingTemplates ? (
                        <div className="px-3 py-2">
                          <Row>
                            <Col>
                              Get started by adding your in-app content.
                            </Col>
                          </Row>
                        </div>
                      ) : null}

                      {this.state.content?.map((c, i) => (
                        <div
                          className={`px-3 py-2 bg-superlight--hover ${
                            i != this.state.content?.length - 1
                              ? "border-bottom"
                              : ""
                          }`}
                          key={i}
                        >
                          {this.templateRow(c)}
                        </div>
                      ))}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        <InAppContentDrawer
          open={this.state.createOpen}
          onClose={() => {
            this.setState({
              createOpen: false,
              duplicate: false,
              modifyTemplate: null,
            });
          }}
          content={this.state.modifyTemplate}
        ></InAppContentDrawer>
      </>
    );
  }
  f;
}

export default GlobalAppContentView;
