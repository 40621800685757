import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import {
  Checkbox,
  Dropdown,
  Popover,
  SelectPicker,
  Tooltip,
  Whisper,
} from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CampaignDrawer from "../../components/Drawers/CampaignDrawer";
import swal from "sweetalert";
import Constant from "../../lib/Constant";

class GlobalCampaignListView extends React.Component {
  state = {
    active: {
      id: "All",
      name: "All",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 25,
    filters: null,
    orderBy: {
      scheduledFor: -1,
    },
    sortByOption: "dueDate",
    sortByDirection: "ascending",
    truncate: true,
    truncateStyles: {
      maxHeight: "30px",
    },
    channels: [
      {
        value: "conversations",
        label: "Conversations Broadcast",
      },
      {
        value: "email",
        label: "Email",
      },
      {
        value: "push",
        label: "Push Notification",
      },
    ],
  };

  deletingBroadcast(broadcast) {
    swal({
      title: "Delete Broadcast",
      text: `Are you sure you want to delete the "${broadcast?.name}" conversation broadcast?`,
      icon: "warning",
      buttons: ["Nevermind", "Delete"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        deletingBroadcast: broadcast?._id,
      });

      APIV2.deleteConversationBroadcast(
        this.props.match?.params?.storeID,
        broadcast?._id
      )
        .then(
          () => {
            PubSub.publish(Event.CONVERSATION_BROADCAST.DELETED, broadcast);

            swal({
              title: "Broadcast Deleted",
              text: "Your conversation broadcast has been successfully deleted",
              icon: "success",
            });
          },
          (e) => {
            window.alert(
              e?.replace?.message ??
                "Unable to delete broadcast. Please try again."
            );
          }
        )
        .finally(() => {
          this.setState({
            deletingBroadcast: null,
          });
        });
    });
  }

  getStore() {
    APIV2.getStoreByID(this?.props?.match?.params?.storeID).then((data) => {
      this.setState({
        store: data?.data?.store,
      });
    });
  }

  componentDidUpdate(prevProps) {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab && tab != this.state.active?.id) {
      this.handleTabChange({
        id: decodeURIComponent(tab),
        name: decodeURIComponent(tab),
      });
    }

    if (prevProps?.match?.params.storeID != this.props.match?.params?.storeID) {
      this.handleTabChange(this.state.active, true);

      this.getStore();
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab) {
      this.handleTabChange(
        {
          id: decodeURIComponent(tab),
          name: decodeURIComponent(tab),
        },
        true
      );
    } else {
      this.handleTabChange(this.state.active, true);
    }

    if (this.props.match?.params?.storeID) {
      this.getStore();
    }

    PubSub.subscribe(Event.CONVERSATION_BROADCAST.CREATED, (m) => {
      this.loadBroadcasts(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );
    });

    PubSub.subscribe(Event.CONVERSATION_BROADCAST.MODIFIED, (a) => {
      this.loadBroadcasts(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );

      if (this.state.modifyBroadcast?._id == a?._id) {
        this.setState({
          modifyBroadcast: a,
        });
      }
    });

    PubSub.subscribe(Event.CONVERSATION_BROADCAST.DELETED, (a) => {
      this.loadBroadcasts(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );
    });
  }

  loadBroadcasts(page = 1, count = 25, filters = null, orderBy) {
    this.setState({
      loadingBroadcasts: true,
    });

    APIV2.getCampaigns(page, count, filters, orderBy)
      .then(
        (data) => {
          let campaigns = [];

          if (data?.data?.campaigns?.length) {
            campaigns = data?.data?.campaigns;
          }

          for (let i = 0; i < campaigns?.length; i++) {
            campaigns[i].dropdownRef = React.createRef();
          }

          this.setState({
            broadcasts: campaigns,
            pagination: data.data.pagination,
            result: data.data.result,
          });
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the campaigns. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingBroadcasts: false,
        });
      });
  }

  performSearch() {
    console.log(this.state.search);

    APIV2.simpleSearchConversationBroadcastsForStore(
      this.props?.match?.params?.storeID,
      this.state.search
    )
      .then(
        (data) => {
          let broadcasts = data.data.broadcasts;

          for (let i = 0; i < broadcasts?.length; i++) {
            broadcasts[i].dropdownRef = React.createRef();
          }

          this.setState({
            searchResultBroadcasts: broadcasts,
            searchResults: true,
          });
        },
        (e) => {
          console.error(e);

          this.setState({ searchResults: true, searchResultBroadcasts: [] });
        }
      )
      .finally(() => {
        this.setState({
          searchLoading: false,
        });
      });
  }

  clearSearch() {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);

      this.setState({
        searchTimeout: null,
      });
    }

    this.setState({
      search: "",
    });
  }

  handleTabChange(option, force = false) {
    let filters = {
      platform: { $ne: "storefront" },
    };
    let orderBy = {
      scheduledFor: -1,
    };

    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    if (option.id == "Broadcasts") {
      filters.channel = "conversations";
    } else if (option.id == "Push Notifications") {
      filters.channel = "push";
    } else if (option.id == "Email") {
      filters.channel = "email";
    }

    this.setState({
      filters,
      orderBy,
      active: option,
    });

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.loadBroadcasts(1, this.state.count, filters, orderBy);
  }

  generateListNames(lists) {
    if (lists?.length == 1 && lists[0].includes("custom:")) {
      let out = "Custom List ";

      const contactCount = lists[0].replace("custom:", "").split(",");

      return (
        out +
        `(${contactCount?.length} Contact${
          contactCount?.length > 1 ? "s" : ""
        })`
      );
    }

    let out = lists?.map((val) => {
      let value = _.findWhere(Constant.BROADCAST_LISTS, { value: val });

      if (value) {
        return value.label;
      }

      return "";
    });

    out = _.filter(out, (v) => {
      return v ? true : false;
    });

    return out?.join(", ");
  }

  handleSearchChange(e) {
    const { name, value } = e.target;

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    if (value) {
      this.setState({
        searchLoading: true,
      });
    } else {
      this.setState({
        searchLoading: false,
        searchResults: false,
      });
    }

    if (value && value.length >= 3) {
      let timeout = setTimeout(() => {
        this.performSearch();
      }, 1200);

      this.setState({
        searchTimeout: timeout,
      });
    }

    this.setState({
      [name]: value,
    });
  }

  getChannelName(channel) {
    let value = _.findWhere(this.state.channels, { value: channel });

    if (value) {
      return value.label;
    }

    return "";
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadBroadcasts(
      page,
      this.state.count,
      this.state.filters,
      this.state.orderBy
    );
  }

  toPercent(number) {
    if (!number) {
      return "0.00%";
    }

    return `${(number * 100)?.toFixed(2)}%`;
  }

  broadcastRow(c) {
    return (
      <Row className="align-items-center">
        <Col xs="12" sm="6" md="4">
          <div style={{ fontSize: 14 }} className="mb-1">
            <i
              className={`mdi mdi-circle pr-1 ${
                c?.status == "SCHEDULED" ? "text-information" : ""
              } ${c?.status == "SENT" ? "text-success" : ""} ${
                c?.status == "PROCESSING" ? "text-warning" : ""
              } ${c?.status == "ERROR" ? "text-danger" : ""}`}
            ></i>
            {c?.status == "SCHEDULED" ? "Scheduled" : ""}
            {c?.status == "SENT" ? "Sent" : ""}
            {c?.status == "PROCESSING" ? "Processing" : ""}
            {c?.status == "ERROR" ? "Failed To Send" : ""}&nbsp;
            {moment(c?.scheduledFor).format("MM/DD/YY hh:mm A")}
          </div>
          <h4 className="mb-0">{c.name}</h4>
          <p className="m-0" style={{ fontSize: 12 }}>
            <span className="text-capitalize">
              {this.getChannelName(c?.channel)}
            </span>
            &nbsp;To {this.generateListNames(c?.lists)}
          </p>
        </Col>
        <Col xs="6" sm="6" md="2">
          <p className="m-0">
            {c?.status == "SENT"
              ? c?.recipientCount
                ? StringUtils.numberFormat(c?.recipientCount)
                : "--"
              : "--"}
          </p>
        </Col>
        <Col xs="12" sm="6" md="2" className="mt-3 mt-md-0">
          {c.status == "SENT" ? (
            <>
              {c.recipientCount > 0 &&
              StringUtils.parseURLsFromText(c.message)?.length ? (
                <>
                  {this.toPercent(
                    (c.clickCount ? c.clickCount : 0) / c.recipientCount
                  )}
                </>
              ) : (
                "--"
              )}
            </>
          ) : (
            "--"
          )}
        </Col>
        <Col xs="12" sm="6" md="2" className="mt-3 mt-md-0">
          {c.status == "SENT" ? (
            <>
              {this.toPercent(
                (c.unsubscribeCount ? c.unsubscribeCount : 0) / c.recipientCount
              )}
            </>
          ) : (
            "--"
          )}
        </Col>
        <Col xs="6" sm="6" md="2" className="text-right order-1 order-md-2">
          <Whisper
            onClose={() => {
              this.setState({
                dropdownOpen: null,
              });
            }}
            trigger="click"
            ref={c.dropdownRef}
            placement="autoVerticalEnd"
            speaker={
              <Popover className="dropdown-popover" full>
                <Dropdown.Menu
                  style={{
                    minWidth: 150,
                    paddingBottom: 0,
                  }}
                >
                  <Dropdown.Item
                    panel
                    className="font-weight-800 text-uppercase"
                    style={{
                      padding: 12,
                    }}
                  >
                    <h5 className="m-0 text-dark">Actions</h5>
                  </Dropdown.Item>
                  <Dropdown.Item className="my-0" divider></Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      e.stopPropagation();

                      this.setState({
                        modifyBroadcast: c,
                        createOpen: true,
                      });

                      c.dropdownRef.current.close();
                    }}
                    className="text-dark bg-superlight--hover"
                  >
                    <Row className="align-items-center">
                      <Col xs="auto" className="pr-0">
                        <i
                          className={`mdi mdi-pencil-box-outline`}
                          style={{ fontSize: 16, lineHeight: 1.3 }}
                        ></i>
                      </Col>
                      <Col xs="" className="pl-2">
                        <p className="m-0" style={{ fontSize: 14 }}>
                          Modify Campaign
                        </p>
                      </Col>
                    </Row>
                  </Dropdown.Item>

                  <Dropdown.Item divider></Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      //this.settingsDropdownRef.current.close();

                      e.stopPropagation();

                      this.deletingBroadcast(c);

                      c.dropdownRef.current.close();
                    }}
                    disabled={this.state.deletingBroadcast == c?._id}
                    className="text-danger bg-superlight--hover"
                  >
                    <Row className="align-items-center">
                      <Col xs="auto" className="pr-0">
                        <i
                          className={`mdi mdi-trash-can-outline`}
                          style={{ fontSize: 16, lineHeight: 1.3 }}
                        ></i>
                      </Col>
                      <Col xs="" className="pl-2">
                        <p
                          className="m-0"
                          style={{ fontSize: 14, lineHeight: 1 }}
                        >
                          Delete Campaign
                        </p>
                      </Col>
                    </Row>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Popover>
            }
          >
            <Button
              size="sm"
              outline
              color="dark"
              className="btn-icon-only"
              onClick={(e) => {
                e.stopPropagation();

                if (this.state.dropdownOpen == c?._id) {
                  this.setState({
                    dropdownOpen: "",
                  });

                  return c.dropdownRef.current.close();
                }

                this.setState({ dropdownOpen: c?._id });
              }}
            >
              <i className="mdi mdi-dots-vertical"></i>
            </Button>
          </Whisper>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader
            title="Campaigns"
            showBackButton={false}
            primaryAction={{
              name: <>Add</>,
              onClick: () => {
                this.setState({
                  createOpen: true,
                });
              },
            }}
          />

          <Row>
            <div className="col">
              <Card className=" shadow" style={{ minHeight: "500px" }}>
                <CardSelectorHeader
                  compact={true}
                  value={this.state.active}
                  options={[
                    {
                      name: "All",
                      id: "All",
                    },
                    {
                      name: "Broadcasts",
                      id: "Broadcasts",
                    },
                    {
                      name: "Email",
                      id: "Email",
                    },
                    {
                      name: "Push Notifications",
                      id: "Push Notifications",
                    },
                  ]}
                  onChange={this.handleTabChange.bind(this)}
                ></CardSelectorHeader>
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    <Col xs="12" sm="">
                      <Input
                        type="text"
                        placeholder="Search campaigns..."
                        size="sm"
                        name="search"
                        value={this.state.search}
                        onChange={this.handleSearchChange.bind(this)}
                      ></Input>
                    </Col>
                  </Row>
                  <Row className="align-items-center mt-2">
                    {/*<Col xs="auto" sm="auto" className="pr-0">
                      <Checkbox
                        indeterminate={true}
                        checked={true}
                        onChange={() => {}}
                      ></Checkbox>
                </Col>*/}
                    <Col xs="" sm="" className="">
                      {this.state.searchResults ? (
                        <>
                          Found{" "}
                          {this.state.searchResultBroadcasts?.length
                            ? StringUtils.numberFormat(
                                this.state.searchResultBroadcasts?.length
                              )
                            : "0"}{" "}
                          campaign
                          {this.state.searchResultBroadcasts?.length == 1
                            ? ""
                            : "s"}{" "}
                          matching your search.
                        </>
                      ) : (
                        <div>
                          {this.state.result?.start &&
                          this.state.result?.end ? (
                            <>
                              Showing{" "}
                              {StringUtils.numberFormat(
                                this.state.result?.start
                              )}
                              -
                              {StringUtils.numberFormat(this.state.result?.end)}
                              {this.state.result?.total ? (
                                <>
                                  &nbsp;of{" "}
                                  {StringUtils.numberFormat(
                                    this.state.result?.total
                                  )}{" "}
                                  campaigns
                                </>
                              ) : null}
                            </>
                          ) : (
                            <>---</>
                          )}
                        </div>
                      )}
                    </Col>
                    <Col
                      xs="auto"
                      sm="auto"
                      className="pl-2 pl-sm-3 text-right"
                    >
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        className="btn-icon-only"
                        outline
                        disabled={
                          this.state.page == 1 ||
                          !this.state.pagination?.hasPrevious ||
                          this.state.searchResults
                        }
                        onClick={() => {
                          this.incrementPage(-1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-left"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        disabled={
                          !this.state.pagination?.hasNext ||
                          this.state.searchResults
                        }
                        className="btn-icon-only"
                        outline
                        onClick={() => {
                          this.incrementPage(1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-right"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="py-2 px-3 bg-superlight border-bottom border-top text-dark">
                    <Row className="align-items-center">
                      <Col xs="12" sm="12" md="4">
                        <h4 className="mb-0">Campaign</h4>
                      </Col>

                      <Col xs="12" sm="12" md="2">
                        <h4
                          className="mb-0 cursor-pointer"
                          onClick={() => {
                            this.setState({
                              truncate: !this.state.truncate,
                            });
                          }}
                        >
                          Sent
                        </h4>
                      </Col>
                      <Col xs="12" sm="12" md="2">
                        <Whisper
                          trigger={"hover"}
                          placement="auto"
                          speaker={
                            <Tooltip>
                              <p className="m-0">Click-Through Rate</p>
                              <span>
                                The percentage of receipients who clicked a link
                                inside the broadcast.
                              </span>
                            </Tooltip>
                          }
                        >
                          <h4
                            className="mb-0 cursor-pointer"
                            onClick={() => {
                              this.setState({
                                truncate: !this.state.truncate,
                              });
                            }}
                          >
                            CTR
                            <i
                              className="mdi mdi-information-outline ml-1"
                              style={{ position: "relative", top: 1 }}
                            ></i>
                          </h4>
                        </Whisper>
                      </Col>
                      <Col xs="12" sm="12" md="2">
                        <Whisper
                          trigger={"hover"}
                          placement="auto"
                          speaker={
                            <Tooltip>
                              <p className="m-0">Opt-Out Rate</p>
                              <span>
                                The percentage of receipients who unsubscribed
                                after receiving the broadcast.
                              </span>
                            </Tooltip>
                          }
                        >
                          <h4
                            className="mb-0 cursor-pointer"
                            onClick={() => {
                              this.setState({
                                truncate: !this.state.truncate,
                              });
                            }}
                          >
                            OOR
                            <i
                              className="mdi mdi-information-outline ml-1"
                              style={{ position: "relative", top: 1 }}
                            ></i>
                          </h4>
                        </Whisper>
                      </Col>

                      <Col
                        xs="12"
                        sm="12"
                        md="2"
                        className="text-left text-md-right d-none d-md-block"
                      >
                        <h4 className="mb-0">Actions</h4>
                      </Col>
                    </Row>
                  </div>

                  <div>
                    {this.state.loadingBroadcasts ||
                    this.state.searchLoading ? (
                      <>
                        <div
                          className="text-center p-3"
                          style={{
                            zIndex: 1,
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            background: "rgba(255, 255, 255, 0.6)",
                          }}
                        >
                          <Spinner
                            style={{ position: "relative", top: "15vh" }}
                            color="medium"
                            size=""
                          ></Spinner>
                        </div>
                      </>
                    ) : null}
                    {this.state.searchResults ? (
                      <div>
                        {!this.state.searchResultBroadcasts?.length &&
                        !this.state.searchLoading ? (
                          <div className="px-3 py-2">
                            <Row>
                              <Col>
                                No broadcasts found matching your search "
                                {this.state.search}"
                              </Col>
                            </Row>
                          </div>
                        ) : null}

                        {this.state.searchResultBroadcasts?.map((c, i) => (
                          <div
                            className={`px-3 py-2 ${
                              i != this.state.searchResultBroadcasts.length - 1
                                ? "border-bottom"
                                : ""
                            }`}
                            key={i}
                          >
                            {this.broadcastRow(c)}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>
                        {!this.state.broadcasts?.length &&
                        !this.state.loadingBroadcasts ? (
                          <div className="px-3 py-2">
                            <Row>
                              <Col>
                                Get started by adding your first campaign.
                              </Col>
                            </Row>
                          </div>
                        ) : null}

                        {this.state.broadcasts?.map((c, i) => (
                          <div
                            className={`px-3 py-2 ${
                              i != this.state.broadcasts.length - 1
                                ? "border-bottom"
                                : ""
                            }`}
                            key={i}
                          >
                            {this.broadcastRow(c)}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        <CampaignDrawer
          open={this.state.createOpen}
          onClose={() => {
            this.setState({
              createOpen: false,
              duplicate: false,
              modifyBroadcast: null,
            });
          }}
          campaign={this.state.modifyBroadcast}
        ></CampaignDrawer>
      </>
    );
  }
  f;
}

export default GlobalCampaignListView;
