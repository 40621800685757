import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
  Spinner,
  Alert,
  Collapse,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import NumberFormat from "react-number-format";
import APIV2 from "lib/APIV2";
import { Drawer, SelectPicker } from "rsuite";
import StringUtils from "lib/StringUtils";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import LinkUtils from "lib/LinkUtils";

class CreateFranDevLeadDrawer extends React.Component {
  state = {
    name: "",
    submitting: false,
    stage: "Not Contacted",
    stages: [
      { label: "Not Contacted" },
      { label: "Contacted" },
      { label: "Focus Call" },
      { label: "Discovery Call" },
      { label: "Discovery Day" },
      { label: "Funding" },
      { label: "Converted" },
      { label: "Inactive" },
    ],
  };

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
      name: "",
      stage: "Not Contacted",
      contacts: [],

      businesses: [],
      markets: [],
    });

    if (typeof this.props.onClose == "function") {
      this.props.onClose();
    }
  };

  submit() {
    this.setState({
      submitting: true,
    });

    let contacts = this.state.contacts?.length
      ? this.state.contacts?.map((contact) => {
          delete contact?._id;
          delete contact?.expanded;

          contact.phone = contact?.phone?.value
            ? contact?.phone?.value
            : contact?.phone;

          return contact;
        })
      : [];

    let businesses = this.state.businesses?.length
      ? this.state.businesses?.map((business) => {
          delete business?._id;
          delete business?.expanded;

          business.phone = business?.phone?.value
            ? business?.phone?.value
            : business?.phone;

          return business;
        })
      : [];

    let markets = this.state.markets?.length
      ? this.state.markets?.map((market) => {
          delete market?._id;
          delete market?.expanded;

          return market;
        })
      : [];

    APIV2.createFranDevLead(
      this.state.name,
      this.state.stage,
      contacts,
      markets,
      businesses
    )
      .then(
        (data) => {
          if (data?.data?.lead) {
            if (typeof this.props.onCreate == "function") {
              this.props.onCreate(data.data.lead);
            }

            if (typeof this.props.onClose == "function") {
              this.props.onClose();
            }

            this.setState({
              name: "",
              stage: "Not Contacted",
              contacts: [],
              markets: [],
              businesses: [],
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert("We were unable to create the store. Please try again.");
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  componentDidMount() {}

  isValidForm() {
    if (!this.state.name || !this.state.stage) {
      return false;
    }

    for (let i = 0; i < this.state.contacts?.length; i++) {
      if (!this.state.contacts[i]?.name) {
        return false;
      }
    }

    for (let i = 0; i < this.state.markets?.length; i++) {
      if (!this.state.markets[i]?.name) {
        return false;
      }
    }

    for (let i = 0; i < this.state.businesses?.length; i++) {
      if (!this.state.businesses[i]?.name) {
        return false;
      }
    }

    return true;
  }

  render() {
    return (
      <>
        <Drawer
          backdrop="static"
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              New Lead
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={this.state.submitting || !this.isValidForm()}
                color="primary"
                onClick={this.submit.bind(this)}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  "Add"
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}

            <FormGroup>
              <h5>
                Name<span className="text-danger">*</span>
              </h5>
              <Input
                bsSize="sm"
                type="text"
                name="name"
                placeholder="Name"
                value={this.state.name}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>
                Stage<span className="text-danger">*</span>
              </h5>
              <SelectPicker
                placeholder="Select A Stage"
                data={this.state.stages}
                value={this.state.stage}
                valueKey="label"
                onChange={(v) => {
                  this.setState({
                    stage: v,
                  });
                }}
                block
                placement="auto"
                preventOverflow
              ></SelectPicker>
            </FormGroup>
            <div className="border rounded p-3">
              <div className="pb-3 mb-3 border-bottom">
                <Row className="align-items-center">
                  <Col xs="">
                    <h2 className="m-0">Contacts</h2>
                  </Col>
                  <Col xs="auto">
                    <Button
                      size="sm"
                      outline
                      color="primary"
                      className="btn-icon-only"
                      onClick={() => {
                        let contacts = this.state.contacts
                          ? this.state.contacts
                          : [];

                        for (let j = 0; j < contacts.length; j++) {
                          contacts[j].expanded = false;
                        }

                        contacts.push({
                          name: "",
                          email: "",
                          phone: {
                            value: "",
                          },
                          linkedinURL: "",
                          facebookURL: "",
                          instagramURL: "",
                          _id: StringUtils.uuid(),
                          expanded: true,
                        });

                        this.setState({
                          contacts: contacts,
                        });
                      }}
                    >
                      <i className="mdi mdi-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
              {this.state.contacts?.length ? (
                <>
                  {this.state.contacts?.map((contact, i) => (
                    <div
                      key={i}
                      className={`${
                        i != this.state.contacts?.length && "mb-2"
                      } border rounded`}
                    >
                      <div
                        className={`p-2 ${
                          contact?.expanded && "border-bottom"
                        } bg-ultralight cursor-pointer`}
                        onClick={() => {
                          let contacts = this.state.contacts
                            ? this.state.contacts
                            : [];

                          contacts[i].expanded = !contacts[i].expanded;

                          this.setState({
                            contacts: contacts,
                          });
                        }}
                      >
                        <Row className="align-items-center">
                          <Col xs="">
                            <h3 className="m-0">
                              {contact.name ? contact.name : "(add a name)"}
                            </h3>
                          </Col>
                          <Col xs="auto">
                            <h2 className="m-0">
                              <i
                                className={`mdi mdi-${
                                  contact.expanded
                                    ? "chevron-down"
                                    : "chevron-right"
                                }`}
                              ></i>
                            </h2>
                          </Col>
                        </Row>
                      </div>
                      <Collapse isOpen={contact?.expanded}>
                        <div className="p-2">
                          <Row className="align-items-center mb-2">
                            <Col xs="">
                              <h3 className="m-0">Contact Information</h3>
                            </Col>
                            <Col xs="auto">
                              <Button
                                size="sm"
                                outline
                                color="danger"
                                className="btn-icon-only border-0"
                                onClick={() => {
                                  let contacts = this.state.contacts
                                    ? this.state.contacts
                                    : [];

                                  contacts.splice(i, 1);

                                  this.setState({
                                    contacts: contacts,
                                  });
                                }}
                              >
                                <i className="mdi mdi-delete"></i>
                              </Button>
                            </Col>
                          </Row>
                          <div>
                            <FormGroup className="mb-2">
                              <h5>
                                Name <span className="text-danger">*</span>
                              </h5>
                              <Input
                                bsSize="sm"
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={contact.name}
                                onChange={(e) => {
                                  let contacts = this.state.contacts
                                    ? this.state.contacts
                                    : [];

                                  contacts[i].name = e.target.value;

                                  this.setState({
                                    contacts: contacts,
                                  });
                                }}
                              ></Input>
                            </FormGroup>
                            <FormGroup className="mb-2">
                              <h5>Email</h5>
                              <Input
                                bsSize="sm"
                                type="text"
                                name="email"
                                placeholder="Email"
                                value={contact.email}
                                onChange={(e) => {
                                  let contacts = this.state.contacts
                                    ? this.state.contacts
                                    : [];

                                  contacts[i].email = e.target.value;

                                  this.setState({
                                    contacts: contacts,
                                  });
                                }}
                              ></Input>
                            </FormGroup>
                            <FormGroup className="mb-2">
                              <h5>Phone</h5>
                              <NumberFormat
                                className="form-control form-control-sm"
                                format="(###) ###-####"
                                mask="_"
                                type="text"
                                name="phone"
                                placeholder="Phone"
                                value={contact.phone?.value}
                                onChange={(e) => {
                                  let contacts = this.state.contacts;

                                  contacts[i].phone = e.target.value;

                                  this.setState({
                                    contacts: contacts,
                                  });
                                }}
                              ></NumberFormat>
                            </FormGroup>
                            <FormGroup className="mb-2">
                              <h5>Mailing Address</h5>
                              <ReactGoogleAutocomplete
                                options={{ types: ["address"] }}
                                required
                                placeholder="Address"
                                apiKey={
                                  "AIzaSyA5-fbnedb5uXDBwbjnz0J_2eDe_9Gi2Cs"
                                }
                                onPlaceSelected={(place) => {
                                  console.log(place);

                                  let contacts = this.state.contacts
                                    ? this.state.contacts
                                    : [];

                                  contacts[i].address = place.formatted_address;
                                  contacts[i].addressComponents =
                                    place.address_components;
                                  contacts[i].geometry = place.geometry;
                                  contacts[i].placeID = place.place_id;

                                  this.setState({
                                    contacts: contacts,
                                  });
                                }}
                                onChange={(e) => {
                                  let contacts = this.state.contacts
                                    ? this.state.contacts
                                    : [];

                                  contacts[i].address = e.target.value;

                                  this.setState({
                                    contacts: contacts,
                                  });
                                }}
                                name="marketName"
                                value={contact.address}
                                className="form-control form-control-sm"
                                //value={this.state.address1}
                              ></ReactGoogleAutocomplete>
                              {contact?.placeID ? (
                                <>
                                  <Button
                                    className="mt-2"
                                    onClick={() => {
                                      LinkUtils.openInNewTab(
                                        `https://www.google.com/maps/search/?api=1&query=${contact?.address}`
                                      );
                                    }}
                                    size="sm"
                                    color="dark"
                                    outline
                                  >
                                    Open In Google Maps
                                  </Button>
                                </>
                              ) : null}
                            </FormGroup>
                            <FormGroup className="mb-2">
                              <h5>LinkedIn URL</h5>
                              <Input
                                bsSize="sm"
                                type="text"
                                name="linkedinURL"
                                placeholder="LinkedIn URL"
                                value={contact.linkedinURL}
                                onChange={(e) => {
                                  let contacts = this.state.contacts
                                    ? this.state.contacts
                                    : [];

                                  contacts[i].linkedinURL = e.target.value;

                                  this.setState({
                                    contacts: contacts,
                                  });
                                }}
                              ></Input>
                            </FormGroup>
                            <FormGroup className="mb-2">
                              <h5>Instagram URL</h5>
                              <Input
                                bsSize="sm"
                                type="text"
                                name="instagramURL"
                                placeholder="Instagram URL"
                                value={contact.instagramURL}
                                onChange={(e) => {
                                  let contacts = this.state.contacts
                                    ? this.state.contacts
                                    : [];

                                  contacts[i].instagramURL = e.target.value;

                                  this.setState({
                                    contacts: contacts,
                                  });
                                }}
                              ></Input>
                            </FormGroup>
                            <FormGroup className="mb-2">
                              <h5>Facebook URL</h5>
                              <Input
                                bsSize="sm"
                                type="text"
                                name="facebookURL"
                                placeholder="Facebook URL"
                                value={contact.facebookURL}
                                onChange={(e) => {
                                  let contacts = this.state.contacts
                                    ? this.state.contacts
                                    : [];

                                  contacts[i].facebookURL = e.target.value;

                                  this.setState({
                                    contacts: contacts,
                                  });
                                }}
                              ></Input>
                            </FormGroup>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  ))}
                </>
              ) : (
                <div className="py-3 text-dark">Add your first contact</div>
              )}
            </div>
            <div className="border rounded p-3 mt-4">
              <div className="pb-3 mb-3 border-bottom">
                <Row className="align-items-center ">
                  <Col xs="">
                    <h2 className="m-0">Markets</h2>
                  </Col>
                  <Col xs="auto">
                    <Button
                      size="sm"
                      outline
                      color="primary"
                      className="btn-icon-only"
                      onClick={() => {
                        let markets = this.state.markets
                          ? this.state.markets
                          : [];

                        for (let j = 0; j < markets.length; j++) {
                          markets[j].expanded = false;
                        }

                        markets.push({
                          name: "",
                          _id: StringUtils.uuid(),
                          expanded: true,
                        });

                        this.setState({
                          markets,
                        });
                      }}
                    >
                      <i className="mdi mdi-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
              {this.state.markets?.length ? (
                <>
                  {this.state.markets?.map((market, i) => (
                    <div
                      key={i}
                      className={`${
                        i != this.state.markets?.length && "mb-2"
                      } border rounded`}
                    >
                      <div
                        className={`p-2 ${
                          market?.expanded && "border-bottom"
                        } bg-ultralight cursor-pointer`}
                        onClick={() => {
                          let markets = this.state.markets
                            ? this.state.markets
                            : [];

                          markets[i].expanded = !markets[i].expanded;

                          this.setState({
                            markets: markets,
                          });
                        }}
                      >
                        <Row className="align-items-center">
                          <Col xs="">
                            <h3 className="m-0">
                              {market?.priority ? `[${market?.priority}]` : ""}
                              &nbsp;{market.name ? market.name : "(add a name)"}
                            </h3>
                          </Col>
                          <Col xs="auto">
                            <h2 className="m-0">
                              <i
                                className={`mdi mdi-${
                                  market.expanded
                                    ? "chevron-down"
                                    : "chevron-right"
                                }`}
                              ></i>
                            </h2>
                          </Col>
                        </Row>
                      </div>
                      <Collapse isOpen={market?.expanded}>
                        <div className="p-2">
                          <Row className="align-items-center mb-2">
                            <Col xs="">
                              <h3 className="m-0">Market Information</h3>
                            </Col>
                            <Col xs="auto">
                              <Button
                                size="sm"
                                outline
                                color="danger"
                                className="btn-icon-only border-0"
                                onClick={() => {
                                  let markets = this.state.markets
                                    ? this.state.markets
                                    : [];

                                  markets.splice(i, 1);

                                  this.setState({
                                    markets: markets,
                                  });
                                }}
                              >
                                <i className="mdi mdi-delete"></i>
                              </Button>
                            </Col>
                          </Row>
                          <div>
                            <FormGroup className="mb-2">
                              <h5>
                                Name <span className="text-danger">*</span>
                              </h5>
                              <ReactGoogleAutocomplete
                                required
                                placeholder="Market Name"
                                apiKey={
                                  "AIzaSyA5-fbnedb5uXDBwbjnz0J_2eDe_9Gi2Cs"
                                }
                                onPlaceSelected={(place) => {
                                  console.log(place);

                                  let markets = this.state.markets
                                    ? this.state.markets
                                    : [];

                                  markets[i].name = place.formatted_address;
                                  markets[i].addressComponents =
                                    place.address_components;
                                  markets[i].geometry = place.geometry;
                                  markets[i].placeID = place.place_id;

                                  this.setState({
                                    markets: markets,
                                  });
                                }}
                                onChange={(e) => {
                                  let markets = this.state.markets
                                    ? this.state.markets
                                    : [];

                                  markets[i].name = e.target.value;

                                  this.setState({
                                    markets: markets,
                                  });
                                }}
                                name="marketName"
                                value={market.name}
                                className="form-control form-control-sm"
                                //value={this.state.address1}
                              ></ReactGoogleAutocomplete>
                              {market?.placeID ? (
                                <>
                                  <Button
                                    className="mt-2"
                                    onClick={() => {
                                      LinkUtils.openInNewTab(
                                        `https://www.google.com/maps/search/?api=1&query=${market?.name
                                          ?.split(" ")
                                          .join("+")}`
                                      );
                                    }}
                                    size="sm"
                                    color="dark"
                                    outline
                                  >
                                    Open In Google Maps
                                  </Button>
                                </>
                              ) : null}
                            </FormGroup>
                            <FormGroup className="mb-2">
                              <h5>Priority</h5>
                              <SelectPicker
                                placeholder="Select a Priority"
                                value={market?.priority}
                                data={[
                                  { label: "1" },
                                  {
                                    label: "2",
                                  },
                                  {
                                    label: "3",
                                  },
                                  {
                                    label: "4",
                                  },
                                  {
                                    label: "5",
                                  },
                                  {
                                    label: "6",
                                  },
                                  {
                                    label: "7",
                                  },
                                  {
                                    label: "8",
                                  },
                                  {
                                    label: "9",
                                  },
                                  {
                                    label: "10",
                                  },
                                ]}
                                block
                                size="sm"
                                placement="auto"
                                preventOverflow={true}
                                searchable={false}
                                valueKey="label"
                                onChange={(value) => {
                                  let markets = this.state.markets
                                    ? this.state.markets
                                    : [];

                                  markets[i].priority = value;

                                  this.setState({
                                    markets: markets,
                                  });
                                }}
                              ></SelectPicker>
                            </FormGroup>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  ))}
                </>
              ) : (
                <div className="py-3 text-dark">Add your first market</div>
              )}
            </div>
            <div className="border rounded p-3 mt-4">
              <div className="pb-3 mb-3 border-bottom">
                <Row className="align-items-center">
                  <Col xs="">
                    <h2 className="m-0">Businesses</h2>
                  </Col>
                  <Col xs="auto">
                    <Button
                      size="sm"
                      outline
                      color="primary"
                      className="btn-icon-only"
                      onClick={() => {
                        let businesses = this.state.businesses
                          ? this.state.businesses
                          : [];

                        for (let j = 0; j < businesses.length; j++) {
                          businesses[j].expanded = false;
                        }

                        businesses.push({
                          name: "",
                          email: "",
                          phone: {
                            value: "",
                          },
                          address: "",
                          type: null,
                          websiteURL: "",
                          linkedinURL: "",
                          facebookURL: "",
                          instagramURL: "",
                          _id: StringUtils.uuid(),
                          expanded: true,
                        });

                        this.setState({
                          businesses: businesses,
                        });
                      }}
                    >
                      <i className="mdi mdi-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
              {this.state.businesses?.length ? (
                <>
                  {this.state.businesses?.map((business, i) => (
                    <div
                      key={i}
                      className={`${
                        i != this.state.businesses?.length && "mb-2"
                      } border rounded`}
                    >
                      <div
                        className={`p-2 ${
                          business?.expanded && "border-bottom"
                        } bg-ultralight cursor-pointer`}
                        onClick={() => {
                          let businesses = this.state.businesses
                            ? this.state.businesses
                            : [];

                          businesses[i].expanded = !businesses[i].expanded;

                          this.setState({
                            businesses: businesses,
                          });
                        }}
                      >
                        <Row className="align-items-center">
                          <Col xs="">
                            <h3 className="m-0">
                              {business.name ? business.name : "(add a name)"}
                            </h3>
                          </Col>
                          <Col xs="auto">
                            <h2 className="m-0">
                              <i
                                className={`mdi mdi-${
                                  business.expanded
                                    ? "chevron-down"
                                    : "chevron-right"
                                }`}
                              ></i>
                            </h2>
                          </Col>
                        </Row>
                      </div>
                      <Collapse isOpen={business?.expanded}>
                        <div className="p-2">
                          <Row className="align-items-center mb-2">
                            <Col xs="">
                              <h3 className="m-0">Business Information</h3>
                            </Col>
                            <Col xs="auto">
                              <Button
                                size="sm"
                                outline
                                color="danger"
                                className="btn-icon-only border-0"
                                onClick={() => {
                                  let businesses = this.state.businesses
                                    ? this.state.businesses
                                    : [];

                                  businesses.splice(i, 1);

                                  this.setState({
                                    businesses: businesses,
                                  });
                                }}
                              >
                                <i className="mdi mdi-delete"></i>
                              </Button>
                            </Col>
                          </Row>
                          <div>
                            <FormGroup className="mb-2">
                              <h5>
                                Name <span className="text-danger">*</span>
                              </h5>
                              <Input
                                bsSize="sm"
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={business.name}
                                onChange={(e) => {
                                  let businesses = this.state.businesses
                                    ? this.state.businesses
                                    : [];

                                  businesses[i].name = e.target.value;

                                  this.setState({
                                    businesses: businesses,
                                  });
                                }}
                              ></Input>
                            </FormGroup>
                            <FormGroup className="mb-2">
                              <h5>Type</h5>
                              <SelectPicker
                                placeholder="Select a Type"
                                value={business?.type}
                                data={[
                                  { label: "Franchise Unit" },
                                  { label: "Franchising Company" },
                                  {
                                    label: "Private Company",
                                  },
                                  {
                                    label: "Public Company",
                                  },
                                  {
                                    label: "Other",
                                  },
                                ]}
                                block
                                size="sm"
                                placement="auto"
                                preventOverflow={true}
                                searchable={false}
                                valueKey="label"
                                onChange={(value) => {
                                  let businesses = this.state.businesses
                                    ? this.state.businesses
                                    : [];

                                  businesses[i].type = value;

                                  this.setState({
                                    businesses,
                                  });
                                }}
                              ></SelectPicker>
                            </FormGroup>
                            <FormGroup className="mb-2">
                              <h5>Address</h5>
                              <ReactGoogleAutocomplete
                                options={{ types: ["address"] }}
                                required
                                placeholder="Address"
                                apiKey={
                                  "AIzaSyA5-fbnedb5uXDBwbjnz0J_2eDe_9Gi2Cs"
                                }
                                onPlaceSelected={(place) => {
                                  console.log(place);

                                  let businesses = this.state.businesses
                                    ? this.state.businesses
                                    : [];

                                  businesses[i].address =
                                    place.formatted_address;
                                  businesses[i].addressComponents =
                                    place.address_components;
                                  businesses[i].geometry = place.geometry;
                                  businesses[i].placeID = place.place_id;

                                  this.setState({
                                    businesses: businesses,
                                  });
                                }}
                                onChange={(e) => {
                                  let businesses = this.state.businesses
                                    ? this.state.businesses
                                    : [];

                                  businesses[i].address = e.target.value;

                                  this.setState({
                                    businesses: businesses,
                                  });
                                }}
                                name="marketName"
                                value={business.address}
                                className="form-control form-control-sm"
                                //value={this.state.address1}
                              ></ReactGoogleAutocomplete>
                              {business?.placeID ? (
                                <>
                                  <Button
                                    className="mt-2"
                                    onClick={() => {
                                      LinkUtils.openInNewTab(
                                        `https://www.google.com/maps/search/?api=1&query=${business?.address}`
                                      );
                                    }}
                                    size="sm"
                                    color="dark"
                                    outline
                                  >
                                    Open In Google Maps
                                  </Button>
                                </>
                              ) : null}
                            </FormGroup>
                            <FormGroup className="mb-2">
                              <h5>Email</h5>
                              <Input
                                bsSize="sm"
                                type="text"
                                name="email"
                                placeholder="Email"
                                value={business.email}
                                onChange={(e) => {
                                  let businesses = this.state.businesses
                                    ? this.state.businesses
                                    : [];

                                  businesses[i].email = e.target.value;

                                  this.setState({
                                    businesses: businesses,
                                  });
                                }}
                              ></Input>
                            </FormGroup>
                            <FormGroup className="mb-2">
                              <h5>Phone</h5>
                              <NumberFormat
                                className="form-control form-control-sm"
                                format="(###) ###-####"
                                mask="_"
                                type="text"
                                name="phone"
                                placeholder="Phone"
                                value={business.phone?.value}
                                onValueChange={(e) => {
                                  let businesses = this.state.businesses;

                                  businesses[i].phone = e;

                                  this.setState({
                                    businesses: businesses,
                                  });
                                }}
                              ></NumberFormat>
                            </FormGroup>
                            <FormGroup className="mb-2">
                              <h5>Website URL</h5>
                              <Input
                                bsSize="sm"
                                type="text"
                                name="websiteURL"
                                placeholder="Website URL"
                                value={business.websiteURL}
                                onChange={(e) => {
                                  let businesses = this.state.businesses
                                    ? this.state.businesses
                                    : [];

                                  businesses[i].websiteURL = e.target.value;

                                  this.setState({
                                    businesses: businesses,
                                  });
                                }}
                              ></Input>
                            </FormGroup>
                            <FormGroup className="mb-2">
                              <h5>LinkedIn URL</h5>
                              <Input
                                bsSize="sm"
                                type="text"
                                name="linkedinURL"
                                placeholder="LinkedIn URL"
                                value={business.linkedinURL}
                                onChange={(e) => {
                                  let businesses = this.state.businesses
                                    ? this.state.businesses
                                    : [];

                                  businesses[i].linkedinURL = e.target.value;

                                  this.setState({
                                    businesses: businesses,
                                  });
                                }}
                              ></Input>
                            </FormGroup>
                            <FormGroup className="mb-2">
                              <h5>Instagram URL</h5>
                              <Input
                                bsSize="sm"
                                type="text"
                                name="instagramURL"
                                placeholder="Instagram URL"
                                value={business.instagramURL}
                                onChange={(e) => {
                                  let businesses = this.state.businesses
                                    ? this.state.businesses
                                    : [];

                                  businesses[i].instagramURL = e.target.value;

                                  this.setState({
                                    businesses: businesses,
                                  });
                                }}
                              ></Input>
                            </FormGroup>
                            <FormGroup className="mb-2">
                              <h5>Facebook URL</h5>
                              <Input
                                bsSize="sm"
                                type="text"
                                name="facebookURL"
                                placeholder="Facebook URL"
                                value={business.facebookURL}
                                onChange={(e) => {
                                  let businesses = this.state.businesses
                                    ? this.state.businesses
                                    : [];

                                  businesses[i].facebookURL = e.target.value;

                                  this.setState({
                                    businesses: businesses,
                                  });
                                }}
                              ></Input>
                            </FormGroup>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  ))}
                </>
              ) : (
                <div className="py-3 text-dark">Add your first business</div>
              )}
            </div>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default CreateFranDevLeadDrawer;
