import cubejs from "@cubejs-client/core";
import { AreaChart, Card } from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import ChartMetricHeader from "./ChartMetricHeader";
import FulfillmentRetailMealCardDetailDrawer from "./FulfillmentRetailMealCardDetailDrawer";

class FulfillmentRetailMealChartCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: true,
    activeTab: "A",
    dataSet: [],
  };

  /**
   * Fetches a summary of all the lead data
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchLeadData(cubejsApi, stores, dateRange) {
    let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

    secondDifference = secondDifference / 1000;

    let granularity =
      secondDifference > 60 * 60 * 24 * 30 * 6 ? "month" : "week";

    /**
     * {
  "filters": [
    {
      "member": "SubscriptionFulfillmentReports.orderCount",
      "operator": "notEquals",
      "values": [
        "0"
      ]
    }
  ],
  "measures": [
    "SquareOrderLineItems.quantity"
  ],
  "timeDimensions": [
    {
      "dimension": "SubscriptionFulfillmentReports.createdat",
      "dateRange": "Last 30 days",
      "granularity": "day"
    }
  ],
  "order": {
    "SubscriptionFulfillmentReports.createdat": "asc"
  }
}
     */

    return new Promise((resolve, reject) => {
      let endDate6 = moment(dateRange[1]);

      endDate6.startOf("day").day(6);

      let now = moment();

      if (endDate6.isAfter(now)) {
        endDate6.subtract(1, "week");
      }

      // Load
      cubejsApi
        .load({
          measures: ["SquareOrderLineItems.quantity"],
          order: {
            "SquareOrders.closedat": "asc",
          },

          timeDimensions: [
            {
              dimension: "SquareOrders.closedat",
              dateRange: [moment(dateRange[0]).toDate(), endDate6?.toDate()],
              granularity,
            },
          ],
          filters: [
            {
              member: "SquareOrders.storeid",
              operator: "equals",
              values: stores,
            },
            {
              member: "SquareOrderLineItems.mealSku",
              operator: "set",
            },
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          let total = 0;

          if (!data?.length) {
            return resolve(null);
          }

          data = data.map((item) => {
            total += item["SquareOrderLineItems.quantity"];

            let mom = moment(item[`SquareOrders.closedat.${granularity}`]);

            let granularityString = mom.format("MM/DD/YYYY");

            if (granularity == "week") {
              mom.day(6).startOf("day");
              granularityString = "Week Ending " + mom.format("MM/DD/YYYY");
            }

            if (granularity == "month") {
              mom.startOf("month");

              granularityString = mom.format("MMMM YY");
            }

            return {
              count: item["SquareOrderLineItems.quantity"],
              dateObj: mom.toDate(),
              date: granularityString,
              dateKey: granularityString,
            };
          });

          return resolve({
            total,
            history: data,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async _fetchLeadReport(stores, dateRange) {
    this.setState({
      loading: true,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    if (!stores?.length) {
      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchLeadData(cubejsApi, stores, dateRange);
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load meal quantity.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });
    }

    if (this.props.comparePrevious) {
      let previousCount = null;

      let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

      secondDifference = secondDifference / 1000;

      let granularity =
        secondDifference > 60 * 60 * 24 * 30 * 6 ? "month" : "week";

      let startMoment = moment(dateRange[0].toISOString());
      let endMoment = moment(dateRange[1].toISOString());

      startMoment.subtract(secondDifference, "seconds");
      endMoment.subtract(secondDifference + 1, "seconds");

      try {
        previousCount = await this._fetchLeadData(cubejsApi, stores, [
          startMoment.toDate(),
          endMoment.toDate(),
        ]);
      } catch (e) {}

      if (previousCount !== null) {
        let percentChange =
          previousCount?.total > 0
            ? (currentCount?.total - previousCount?.total) /
              previousCount?.total
            : null;

        let declineMode = "";
        let isNegative = false;

        if (percentChange > 0) {
          if (Math.abs(percentChange) < 0.015) {
            declineMode = "unchanged";
          } else if (Math.abs(percentChange) < 0.1) {
            declineMode = "moderateIncrease";
          } else if (Math.abs(percentChange) >= 0.1) {
            declineMode = "increase";
          }
        } else {
          isNegative = true;

          if (Math.abs(percentChange) < 0.015) {
            declineMode = "moderateDecrease";
          } else if (Math.abs(percentChange) < 0.1) {
            declineMode = "moderateDecrease";
          } else if (Math.abs(percentChange) >= 0.1) {
            declineMode = "decrease";
          }
        }

        let dataSet = [];

        for (let i = 0; i < currentCount?.history?.length; i++) {
          const cur = currentCount.history[i];

          const offsetDate = moment(cur?.dateObj?.toISOString()).subtract(
            secondDifference,
            "seconds"
          );

          offsetDate.startOf("day").day(6);

          let granularityString = offsetDate.format("MM/DD/YYYY");

          if (granularity == "week") {
            offsetDate.day(6).startOf("day");
            granularityString =
              "Week Ending " + offsetDate.format("MM/DD/YYYY");
          }

          if (granularity == "month") {
            offsetDate.startOf("month");

            granularityString = offsetDate.format("MMMM YY");
          }

          let prev = _.find(previousCount?.history, (item) => {
            return item?.date?.includes(
              granularityString?.replace("Week Ending ", "")
            );
          });

          if (!prev) {
            prev = _.find(currentCount?.history, (item) => {
              return item?.dateKey?.includes(
                granularityString?.replace("Week Ending ", "")
              );
            });
          }

          let out = {
            dateObj: cur?.dateObj,
            dateKey: granularityString,
            date:
              cur?.date +
              ` vs. ${granularityString?.replace("Week Ending ", "")}`,
            Meals: cur?.count,
            Previous:
              prev?.count !== null && prev?.count !== undefined
                ? prev.count
                : 0,
          };

          dataSet.push(out);
        }

        for (let i = 0; i < previousCount?.history?.length; i++) {
          const cur = previousCount.history[i];

          const offsetDate = moment(cur?.dateObj?.toISOString()).add(
            secondDifference,
            "seconds"
          );

          offsetDate.startOf("day").day(6);

          let granularityString = offsetDate.format("MM/DD/YYYY");

          if (granularity == "week") {
            offsetDate.day(6).startOf("day");
            granularityString =
              "Week Ending " + offsetDate.format("MM/DD/YYYY");
          }

          if (granularity == "month") {
            offsetDate.startOf("month");

            granularityString = offsetDate.format("MMMM YY");
          }

          const prev = _.find(dataSet, (item) => {
            return item?.date?.includes(
              granularityString?.replace("Fullfillment ", "")
            );
          });

          let prevString = moment(cur?.dateObj?.toISOString())
            .day(6)
            .startOf("day")
            .format("MM/DD/YYYY");

          if (granularity == "month") {
            prevString = moment(cur?.dateObj?.toISOString()).format("MMMM YY");
          }

          if (
            !prev &&
            offsetDate.toDate() >= dateRange[0] &&
            offsetDate?.toDate() <= dateRange[1]
          ) {
            let out = {
              dateObj: offsetDate.toDate(),
              date: granularityString + ` vs. ${prevString}`,
              dateKey: granularityString,
              Meals: 0,
              Previous:
                cur?.count !== null && cur?.count !== undefined ? cur.count : 0,
            };

            dataSet.push(out);
          }
        }

        dataSet = _.sortBy(dataSet, "dateObj");

        this.setState({
          previousCount,
          changeIsNegative: isNegative,
          deltaType: declineMode,
          percentChange,
          percentChangeString: Math.abs(percentChange * 100).toFixed(1) + "%",
          dataSet,
        });
      }
    }

    this.setState({
      currentCount,
      loading: false,
    });
  }

  /**
   * Load all report information
   *
   * @param {*} stores
   * @param {*} dateRange
   * @param {*} compare
   */
  async loadReport(stores, dateRange, compare) {
    this.setState({
      loading: true,
    });

    this._fetchLeadReport(stores, dateRange);
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.stores != prevProps?.stores && this.props.stores?.length) ||
      this.props.dateRange != prevProps?.dateRange ||
      this.props.comparePrevious != prevProps.comparePrevious
    ) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  componentDidMount() {
    if (this.props.stores && this.props.dateRange) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  render() {
    return (
      <>
        <Card hFull={true}>
          <ChartMetricHeader
            title="Retail Meal Sales"
            description="All meals sold via in-store retail."
            forceWrapComparison={true}
            actions={
              <>
                <Button
                  size="sm"
                  outline
                  color="dark"
                  className="border-0 btn-icon-only"
                  disabled={this.state.loading || !this.state.dataAvailable}
                  onClick={() => {
                    this.setState({
                      open: true,
                    });
                  }}
                >
                  <i className="mdi mdi-fullscreen"></i>
                </Button>
              </>
            }
            loading={this.state.loading}
            dataAvailable={this.state.dataAvailable}
            metric={
              <>
                {StringUtils.numberFormat(this.state.currentCount?.total)} Meals
              </>
            }
            comparisonMetric={
              this.state.previousCount !== null &&
              !isNaN(this.state.previousCount?.total) && (
                <>{StringUtils.numberFormat(this.state.previousCount?.total)}</>
              )
            }
            dateRange={this.props.dateRange}
            deltaType={this.state.deltaType}
            percentChange={this.state.percentChangeString}
            showPercentChange={true}
          ></ChartMetricHeader>

          {this.state.loading ? (
            <div
              className="mt-4 skeleton"
              style={{ height: 320, width: "100%" }}
            ></div>
          ) : (
            <>
              {this.state.dataAvailable ? (
                <AreaChart
                  marginTop="mt-4"
                  data={this.state.dataSet}
                  categories={["Meals", "Previous"]}
                  dataKey="date"
                  colors={["orange", "gray"]}
                  valueFormatter={(number) => {
                    return StringUtils.numberFormat(number);
                  }}
                  height="h-80"
                />
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "calc(320px + 1rem)", width: "100%" }}
                >
                  <p className="m-0">
                    No data available.
                    {this.state.error ? ` ${this.state.error}` : null}
                  </p>
                </div>
              )}
            </>
          )}
        </Card>

        <FulfillmentRetailMealCardDetailDrawer
          open={this.state.open}
          onClose={() => {
            this.setState({
              open: false,
            });
          }}
          stores={this.props.stores}
          dateRange={this.props.dateRange}
        />
      </>
    );
  }
}

export default FulfillmentRetailMealChartCard;
