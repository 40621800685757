import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import APIV2 from "lib/APIV2";
import { Drawer, Panel, PanelGroup } from "rsuite";

class CustomerGlobalFilterDrawer extends React.Component {
  state = {
    open: false,
    name: "",
    description: "",
    key: "",
    secret: "",
    submitting: false,
  };

  componentDidMount() {
    if (this.props.openEvent) {
      PubSub.subscribe(this.props.openEvent, () => {
        this.setState({
          open: true,
        });
      });
    }
  }

  apply() {
    this.setState({
      open: false,
    });
  }

  render() {
    return (
      <>
        <Drawer
          size={"xs"}
          placement={"right"}
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          style={{ maxWidth: "100%" }}
        >
          <Drawer.Header>
            <Drawer.Title className="font-weight-bold">Filter</Drawer.Title>
          </Drawer.Header>
          <Drawer.Body
            className="px-2 py-0"
            style={{
              paddingBottom: "80px",
              overflowY: "scroll",
              height: "calc(100% - 149px)",
            }}
          >
            <PanelGroup accordion>
              <Panel header="Status">Status</Panel>
              <Panel header="Next Bill Date">Next Bill Date</Panel>
              <Panel header="Store">Store</Panel>
              <Panel header="Procurement">Procuremement</Panel>
              <Panel header="Plan Type">Plan Type</Panel>
              <Panel header="Signup Date">Signup Date</Panel>
              <Panel header="Has Billing Error">Has Billing Error</Panel>
              <Panel header="Coach">Coach</Panel>
              <Panel header="Source">Source</Panel>
            </PanelGroup>
          </Drawer.Body>
          <Drawer.Actions
            style={{ position: "absolute", bottom: 0, left: 0, width: "100%" }}
            className="p-3 border-top bg-white"
          >
            <Row className="align-items-center">
              <Col xs="auto" className="text-left">
                <Button
                  onClick={() => {
                    this.setState({ open: false });
                  }}
                  color="secondary"
                  outline
                >
                  Clear All Filters
                </Button>
              </Col>
              <Col xs="" className="text-right">
                <Button
                  onClick={() => {
                    this.apply();
                  }}
                  color="primary"
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Drawer.Actions>
        </Drawer>
      </>
    );
  }
}

export default CustomerGlobalFilterDrawer;
