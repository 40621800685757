import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
  Button,
  Modal,
  Row,
  Col,
  Badge,
} from "reactstrap";
import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";
import API from "lib/API";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import User from "lib/Model/User";
import { isNumber } from "underscore";
import _ from "underscore";
import { Editor } from "react-draft-wysiwyg";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import APIV2 from "lib/APIV2";
import ArrowText from "./ArrowText";
import { withRouter } from "react-router";

class MealRow extends React.Component {
  state = {
    showSecret: false,
    name: "",
    description: "",
    key: "",
    secret: "",
    modify: false,
  };

  updateShowSecret(show) {
    this.setState({
      showSecret: show,
    });
  }

  delete() {
    let c = window.confirm("Are you sure you want to delete this meal?");

    if (c) {
      APIV2.deleteMeal(this.props.meal._id).then(
        (data) => {
          PubSub.publish(Event.MEAL.DELETED, { id: this.props.meal._id });
        },
        (e) => {
          console.error(e);
          window.alert(
            "We're having trouble removing that meal. Please try again."
          );
        }
      );
    }
  }

  closeModifyModal() {
    this.setState({
      name: "",
      description: "",
      key: "",
      secret: "",
      modify: false,
    });
  }

  closeDescriptionModal() {
    this.setState({
      showDescription: false,
    });
  }

  handleNameChange(event) {
    const val = event.target.value;

    this.setState({
      name: val,
    });
  }

  handleCalorieChange(event) {
    const val = event.target.value;

    this.setState({
      calories: val,
    });
  }

  handleCarbChange(event) {
    const val = event.target.value;

    this.setState({
      carbs: val,
    });
  }

  handleProteinChange(event) {
    const val = event.target.value;

    this.setState({
      protein: val,
    });
  }

  handleFatChange(event) {
    const val = event.target.value;

    this.setState({
      fat: val,
    });
  }

  handleFiberChange(event) {
    const val = event.target.value;

    this.setState({
      fiber: val,
    });
  }

  handleSugarChange(event) {
    const val = event.target.value;

    this.setState({
      sugar: val,
    });
  }

  handleZohoChange(event) {
    const val = event.target.value;

    this.setState({
      zohoApi: val,
    });
  }

  handleSodiumChange(event) {
    const val = event.target.value;

    this.setState({
      sodium: val,
    });
  }

  handleFoodWeightChange(event) {
    const val = event.target.value;

    this.setState({
      foodWeight: val,
    });
  }

  handleFinishedWeightChange(event) {
    const val = event.target.value;

    this.setState({
      finishedWeight: val,
    });
  }

  async submit(e) {
    e.preventDefault();

    let content = draftToHtml(
      convertToRaw(this.state.descState.getCurrentContent())
    );

    if (
      !this.state.name ||
      (!this.state.selectedFile && !this.state.useExistingImg) ||
      !content
    ) {
      window.alert(
        "Please include a name, description, and image to continue."
      );

      return;
    }

    let calories = parseInt(this.state.calories);
    let carbs = parseInt(this.state.carbs);
    let fat = parseInt(this.state.fat);
    let protein = parseInt(this.state.protein);
    let sugar = parseInt(this.state.sugar);
    let fiber = parseInt(this.state.fiber);
    let sodium = parseInt(this.state.sodium);
    let foodWeight = parseFloat(this.state.foodWeight);
    let finishedWeight = parseFloat(this.state.finishedWeight);

    if (
      !isNumber(calories) ||
      !isNumber(carbs) ||
      !isNumber(fat) ||
      !isNumber(protein) ||
      !isNumber(sugar) ||
      !isNumber(fiber) ||
      !isNumber(sodium) ||
      !isNumber(foodWeight) ||
      !isNumber(finishedWeight) ||
      calories < 0 ||
      carbs < 0 ||
      fat < 0 ||
      protein < 0 ||
      sugar < 0 ||
      fiber < 0 ||
      foodWeight < 0 ||
      finishedWeight < 0
    ) {
      window.alert(
        "Please include valid macros and production information to continue."
      );

      return;
    }

    let hasCategory = false;

    for (let i = 0; i < this.state.categories.length; i++) {
      if (this.state.categories[i].checked) {
        hasCategory = true;
      }
    }

    if (!hasCategory) {
      window.alert(
        "Please select at least one category for this meal to continue."
      );

      return;
    }

    this.setState({
      submitting: true,
    });

    let url = "";

    if (!this.state.useExistingImg) {
      // Create an object of formData
      let formData = new FormData();

      formData.append("file", this.state.selectedFile);

      let data = await APIV2.uploadCDNImage(formData);

      url = data.data.data.url;
    }

    let allergens = [];
    let categories = [];

    for (let i = 0; i < this.state.allergens.length; i++) {
      if (this.state.allergens[i].checked) {
        allergens.push(this.state.allergens[i]._id);
      }
    }

    for (let i = 0; i < this.state.categories.length; i++) {
      if (this.state.categories[i].checked) {
        categories.push(this.state.categories[i]._id);
      }
    }

    APIV2.modifyMeal(
      this.props.meal._id,
      this.state.name,
      this.state.zohoApi,
      content,
      this.state.useExistingImg ? this.props.meal.imageURL : url,
      this.props.meal.planTypeID,
      calories,
      carbs,
      protein,
      fat,
      sugar,
      fiber,
      sodium,
      foodWeight,
      finishedWeight,
      categories,
      allergens
    )
      .then(
        (data) => {
          let m = data.data.meal;

          PubSub.publish(Event.MEAL.MODIFIED, m);
          this.closeModifyModal();
        },
        (e) => {
          console.error(e);
          window.alert("We were unable to create the meal. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  reset() {
    this.setState({
      name: this.props.meal.name,
      descState: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(
            this.props.meal.description ? this.props.meal.description : ""
          )
        )
      ), // DEBUG
      selectedFile: null,
      useExistingImg: true,
      calories: this.props.meal.calories,
      carbs: this.props.meal.carbs,
      protein: this.props.meal.protein,
      fat: this.props.meal.fat,
      fiber: this.props.meal.fiber,
      sodium: this.props.meal.sodium,
      sugar: this.props.meal.sugar,
      loadingAllergens: true,
      loadingCategories: true,
      foodWeight: this.props.meal.food_weight,
      finishedWeight: this.props.meal.finished_weight,
      draft: !this.props.meal.visible,
      allergens: [],
      categories: [],
      modify: true,
      step: 1,
      zohoApi: this.props.meal.zoho_api_name,
    });

    APIV2.getAllergens().then(
      (data) => {
        let allergens = data.data.allergens;

        for (let i = 0; i < this.props.meal.allergens.length; i++) {
          for (let j = 0; j < allergens.length; j++) {
            if (allergens[j]._id == this.props.meal.allergens[i]._id) {
              allergens[j].checked = true;
            }
          }
        }

        this.setState({
          loadingAllergens: false,
          allergens: allergens,
        });
      },
      (e) => {
        console.error(e);
        window.alert("Could not load allergens. Please reload and try again.");
      }
    );

    APIV2.getCategories().then(
      (data) => {
        let categories = data.data.categories;

        console.log(this.props.meal);

        for (let i = 0; i < this.props.meal.categories.length; i++) {
          for (let j = 0; j < categories.length; j++) {
            if (categories[j]._id == this.props.meal.categories[i]._id) {
              categories[j].checked = true;
            }
          }
        }

        this.setState({
          loadingCategories: false,
          categories: categories,
        });
      },
      (e) => {
        console.error(e);
        window.alert("Could not load categories. Please reload and try again.");
      }
    );
  }

  toggleAllergen(allergen) {
    let allergens = this.state.allergens;

    let idx = _.findIndex(allergens, { _id: allergen._id });

    if (idx >= 0) {
      allergens[idx].checked = !allergens[idx].checked;
    }

    this.setState({
      allergens: allergens,
    });
  }

  toggleCategory(c) {
    let categories = this.state.categories;

    let idx = _.findIndex(categories, { _id: c._id });

    if (idx >= 0) {
      categories[idx].checked = !categories[idx].checked;
    }

    this.setState({
      categories: categories,
    });
  }

  toggleDraft() {
    this.setState({
      draft: !this.state.draft,
    });
  }

  onDescriptionChange(editorState) {
    this.setState({
      descState: editorState,
    });
  }

  onFileChange(e) {
    this.setState({ selectedFile: e.target.files[0] });
  }

  openModifyModal() {
    this.setState({
      name: this.props.meal.name,
      descState: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(
            this.props.meal.description ? this.props.meal.description : ""
          )
        )
      ), // DEBUG
      selectedFile: null,
      useExistingImg: true,
      calories: this.props.meal.nutrition.calories,
      carbs: this.props.meal.nutrition.carbs,
      protein: this.props.meal.nutrition.protein,
      fat: this.props.meal.nutrition.fat,
      fiber: this.props.meal.nutrition.fiber,
      sodium: this.props.meal.nutrition.sodium,
      sugar: this.props.meal.nutrition.sugar,
      loadingAllergens: true,
      loadingCategories: true,
      foodWeight: this.props.meal.foodWeight,
      finishedWeight: this.props.meal.finishedWeight,
      allergens: [],
      categories: [],
      modify: true,
      zohoApi: this.props.meal.zohoAPIName,
      step: 1,
    });

    APIV2.getAllergens().then(
      (data) => {
        let allergens = data.data.allergens;

        for (let i = 0; i < this.props.meal.allergens.length; i++) {
          for (let j = 0; j < allergens.length; j++) {
            if (allergens[j]._id == this.props.meal.allergens[i]._id) {
              allergens[j].checked = true;
            }
          }
        }

        this.setState({
          loadingAllergens: false,
          allergens: allergens,
        });
      },
      (e) => {
        console.error(e);
        window.alert("Could not load allergens. Please reload and try again.");
      }
    );

    APIV2.getCategories().then(
      (data) => {
        let categories = data.data.categories;

        console.log(this.props.meal);

        for (let i = 0; i < this.props.meal.categories.length; i++) {
          for (let j = 0; j < categories.length; j++) {
            if (categories[j]._id == this.props.meal.categories[i]._id) {
              categories[j].checked = true;
            }
          }
        }

        this.setState({
          loadingCategories: false,
          categories: categories,
        });
      },
      (e) => {
        console.error(e);
        window.alert("Could not load categories. Please reload and try again.");
      }
    );
  }

  toggleDraftMode() {
    let visible = !this.props.meal.visible;

    this.setState({
      submitting: true,
    });

    API.modifyMeal(
      this.props.meal.id,
      this.props.meal.name,
      this.props.meal.zoho_api_name,
      this.props.meal.description,
      this.props.meal.product_group_id,
      this.props.meal.calories,
      this.props.meal.carbs,
      this.props.meal.protein,
      this.props.meal.fat,
      this.props.meal.sugar,
      this.props.meal.fiber,
      this.props.meal.sodium,
      this.props.meal.food_weight,
      this.props.meal.finished_weight,
      visible
    )
      .then(
        (data) => {
          PubSub.publish(Event.MEAL.MODIFIED, data.data.meal);
        },
        (e) => {
          console.error(e);
          window.alert(
            "We were unable to toggle draft mode. Please try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  render() {
    return (
      <>
        <tr
          onClick={() => {
            this.props.history.push(
              `/production/meals/details/${this.props.meal?._id}`
            );
          }}
        >
          <td className="cursor-pointer align-middle">
            <div
              className="border p-2 bg-white"
              style={{ borderRadius: "6px", width: "60px", height: 60 }}
            >
              <img
                src={this.props.meal ? this.props.meal.imageURL : "##"}
                style={{ width: "100%" }}
              ></img>
            </div>
          </td>
          <td
            className="cursor-pointer align-middle"
            style={{ whiteSpace: "pre-wrap" }}
          >
            <h3 className="mb-0 d-none d-md-block">
              {this.props.meal ? this.props.meal.name : null}
              <div className="small">{this.props.meal?.sku}</div>
            </h3>
            <h4 className="mb-0 d-block d-md-none">
              {this.props.meal ? this.props.meal.name : null}
              <div className="small">{this.props.meal?.sku}</div>
            </h4>
          </td>
          <td
            className="cursor-pointer align-middle d-md-table-cell d-none"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {this.props.meal.categories
              ? this.props.meal.categories.map((c, i) => (
                  <>
                    {c.name}
                    {i != this.props.meal.categories.length - 1 ? ", " : ""}
                  </>
                ))
              : ""}
          </td>
          <td className="cursor-pointer align-middle d-lg-table-cell d-none">
            {this.props.meal
              ? this.props.meal.nutrition.calories + " CAL"
              : "--"}
          </td>
          <td className="cursor-pointer align-middle d-lg-table-cell d-none">
            {this.props.meal ? this.props.meal.nutrition.carbs + " G" : "--"}
          </td>
          <td className="cursor-pointer align-middle d-lg-table-cell d-none">
            {this.props.meal ? this.props.meal.nutrition.protein + " G" : "--"}
          </td>
          <td className="align-middle d-xl-table-cell d-none">
            {this.props.meal ? this.props.meal.nutrition.fat + " G" : "--"}
          </td>
          <td className="text-right align-middle">
            {/*<UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Button className="btn-link text-dark btn-icon-only" color="">
                  <i
                    className="mdi mdi-dots-vertical"
                    style={{ fontSize: "20px" }}
                  ></i>
                </Button>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Actions</h6>
                </DropdownItem>
                <DropdownItem
                  href={
                    this.props.meal && this.props.meal.imageURL
                      ? this.props.meal.imageURL
                      : "#"
                  }
                  target="_blank"
                >
                  <i className="mdi mdi-image" />
                  <span>Download Image</span>
                </DropdownItem>
                <DropdownItem
                  to="/admin/user-profile"
                  onClick={() => this.openModifyModal()}
                >
                  <i className="mdi mdi-pencil" />
                  <span>Modify</span>
                </DropdownItem>

                <DropdownItem divider />
                <DropdownItem
                  className="text-danger"
                  onClick={() => this.delete()}
                >
                  <i className="mdi mdi-trash-can" />
                  <span>Delete</span>
                </DropdownItem>
              </DropdownMenu>
                </UncontrolledDropdown>*/}
          </td>
        </tr>
      </>
    );
  }
}

export default withRouter(MealRow);
