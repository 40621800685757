import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";
import Constant from "./Constant";
import APIV2 from "./APIV2";

import { Haptics, ImpactStyle } from "@capacitor/haptics";

const hapticsImpactMedium = async () => {
  await Haptics.impact({ style: ImpactStyle.Medium });
};

const hapticsImpactLight = async () => {
  await Haptics.impact({ style: ImpactStyle.Light });
};

const hapticsVibrate = async () => {
  await Haptics.vibrate();
};

const hapticsSelectionStart = async () => {
  await Haptics.selectionStart();
};

const hapticsSelectionChanged = async () => {
  await Haptics.selectionChanged();
};

const hapticsSelectionEnd = async () => {
  await Haptics.selectionEnd();
};

export default class HapticUtils {
  static navigationEvent() {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    hapticsImpactLight();
  }
}
