import ArrowText from "components/ArrowText";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  Badge,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import Card from "reactstrap/lib/Card";
import moment from "moment";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import _ from "underscore";
import TaskDetailDrawer from "components/Drawers/TaskDetailDrawer";
import TaskCreateDrawer from "components/Drawers/TaskCreateDrawer";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import CalendarWeekView from "../CalendarWeekView";
import AppointmentCard from "../AppointmentCard";
import ConsultationDetailDrawer from "../Drawers/ConsultationDetailDrawer";
import ContentCalendarCard from "../ContentCalendarCard";
import ContentCalendarDetailDrawer from "../Drawers/ContentCalendarDetailDrawer";

class ContentCalendarWidget extends React.Component {
  state = {
    expand: false,
    taskDetail: null,
    appointments: [],
    loading: false,
  };

  componentDidMount() {
    //this.handleTabChange(this.state.active, true);

    PubSub.subscribe(Event.CONTENT.CREATED, () => {
      this.loadContent(this.state.dateRange);
    });

    PubSub.subscribe(Event.CONTENT.MODIFIED, (content) => {
      this.loadContent(this.state.dateRange);

      if (this.state?.detailContent?._id == content?._id) {
        this.setState({
          detailContent: content,
        });
      }
    });

    PubSub.subscribe(Event.CONTENT.DELETED, () => {
      this.loadContent(this.state.dateRange);
    });
  }

  componentWillUnmount() {
    if (this.state.backgroundRefresh) {
      clearInterval(this.state.backgroundRefresh);
    }
  }

  getContent(startDate, endDate, background = false) {
    if (!background) {
      this.setState({
        loading: true,
      });
    }

    let start = moment(startDate?.toISOString())
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .toDate();
    let end = moment(endDate?.toISOString())
      .hour(23)
      .minutes(59)
      .second(59)
      .millisecond(999)
      .toDate();

    let filters = {
      scheduledFor: {
        $gte: { $date: start },
        $lte: { $date: end },
      },
    };

    let orderBy = {
      scheduledFor: -1,
    };

    APIV2.getContentCalendar(1, 500, filters, orderBy)
      .then(
        (data) => {
          if (data?.data) {
            let content = [];

            if (data?.data?.content?.length) {
              content = data.data.content;
            }

            let existing = JSON.stringify(this.state.content);

            if (JSON.stringify(content) != existing) {
              this.setState({
                content,
                pagination: data.data.pagination,
                result: data.data.result,
              });
            }
          }
        },
        (e) => {
          console.error(e);

          if (!background) {
            window.alert(
              "There was an error loading the content calendar. Please reload and try again."
            );
          }
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.store != this.props?.store) {
      this.loadContent(this.state.dateRange);
    }
  }

  taskDetailDrawer(task) {
    this.setState({
      taskDetail: task,
      detailOpen: true,
    });
  }

  loadContent(dateRange) {
    if (!dateRange) {
      return;
    }

    this.getContent(dateRange?.startDate, dateRange?.endDate);
  }

  render() {
    return (
      <>
        {this.props.loading ? (
          <>
            <Card className="mb-4 shadow border">
              <CardBody className="bg-transparent py-5 text-center">
                <Spinner size="lg" color="medium"></Spinner>
              </CardBody>
            </Card>
          </>
        ) : (
          <>
            <Card className="mb-4 border shadow">
              <CardBody className="p-0">
                <CalendarWeekView
                  columnHeight={500}
                  loading={this.state.loading}
                  timeslotComponent={(data) => {
                    return (
                      <>
                        <ContentCalendarCard
                          onClick={() => {
                            this.setState({
                              detailContent: data,
                              detailOpen: true,
                            });
                          }}
                          content={data}
                        ></ContentCalendarCard>
                      </>
                    );
                  }}
                  data={this.state.content}
                  onChange={(v) => {
                    this.loadContent(v);
                    this.setState({
                      dateRange: v,
                    });
                  }}
                  onRefresh={(v) => {
                    this.getContent(v?.startDate, v?.endDate);
                  }}
                  compareKey="scheduledFor"
                  emptyString={"No content scheduled."}
                ></CalendarWeekView>
              </CardBody>
            </Card>
          </>
        )}
        <ContentCalendarDetailDrawer
          content={this.state.detailContent}
          open={this.state.detailOpen}
          onClose={() => {
            this.setState({
              detailContent: null,
              detailOpen: false,
            });
          }}
        ></ContentCalendarDetailDrawer>
      </>
    );
  }
}

export default withRouter(ContentCalendarWidget);
