import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  InputPicker,
  Input as RInput,
  Tooltip,
} from "rsuite";
import moment from "moment";
import _, { times } from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import Table from "reactstrap/lib/Table";
import TaskDetailDrawer from "./TaskDetailDrawer";
import Constant from "lib/Constant";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import LinkUtils from "../../lib/LinkUtils";
import NoteBody from "components/NoteBody";
import Linkify from "components/Linkify";

class ProductionOrderDrawer extends React.Component {
  state = {
    editMode: false,
    orderDate: moment(),
    recommendationAvailable: true,
    orderName: `Product Order - ${moment().format("MM/DD/YYYY")}`,
    store: null,
    stores: [],
    additionalLineItems: [],
    storeOptions: [],
    active: {
      id: "Summary",
      name: "Summary",
    },
    recentFirst: "recentFirst",
    lineItemOptions: [
      {
        label: "Lifestyle Meal Credit",
        value: "Lifestyle Meal Credit",
        group: "General Credit",
      },
      {
        label: "Athlete Meal Credit",
        value: "Athlete Meal Credit",
        group: "General Credit",
      },
      {
        label: "Lean Cheat Credit",
        value: "Lean Cheat Credit",
        group: "General Credit",
      },
      {
        label: "Corrugated Box Credit",
        value: "Corrugated Box Credit",
        group: "General Credit",
      },
      {
        label: "Order Credit",
        value: "Order Credit",
        group: "General Credit",
      },
      {
        label: "Lifestyle Meal: Loose Seal Credit",
        value: "Lifestyle Meal: Loose Seal Credit",
        group: "Loose Seal Credit",
      },
      {
        label: "Athlete Meal: Loose Seal Credit",
        value: "Athlete Meal: Loose Seal Credit",
        group: "Loose Seal Credit",
      },
      {
        label: "Local Delivery",
        value: "Local Delivery",
        group: "Additional Services",
      },
      {
        label: "Client Boxed Orders",
        value: "Member Boxed Orders",
        group: "Additional Services",
      },
    ],
    defaultLineItemOptions: [
      {
        label: "Lifestyle Meal Credit",
        value: "Lifestyle Meal Credit",
        group: "General Credit",
      },
      {
        label: "Athlete Meal Credit",
        value: "Athlete Meal Credit",
        group: "General Credit",
      },
      {
        label: "Lean Cheat Credit",
        value: "Lean Cheat Credit",
        group: "General Credit",
      },
      {
        label: "Corrugated Box Credit",
        value: "Corrugated Box Credit",
        group: "General Credit",
      },
      {
        label: "Order Credit",
        value: "Order Credit",
        group: "General Credit",
      },
      {
        label: "Lifestyle Meal: Loose Seal Credit",
        value: "Lifestyle Meal: Loose Seal Credit",
        group: "Loose Seal Credit",
      },
      {
        label: "Athlete Meal: Loose Seal Credit",
        value: "Athlete Meal: Loose Seal Credit",
        group: "Loose Seal Credit",
      },
      {
        label: "Local Delivery",
        value: "Local Delivery",
        group: "Additional Services",
      },
      {
        label: "Client Boxed Orders",
        value: "Member Boxed Orders",
        group: "Additional Services",
      },
    ],
  };

  dropdownRef = React.createRef();

  componentDidUpdate(prevProps) {
    if (
      this.props.store != prevProps?.store &&
      this.props.store &&
      this.props.open
    ) {
      this._selectStore(this.props.store);
    }

    if (this.props.open != prevProps.open && this.props.open) {
      if (this.props.store) {
        this._selectStore(this.props.store);
      }

      this.setState({
        active: {
          id: "Summary",
          name: "Summary",
        },
      });

      if (this.props.productionMode) {
        if (
          this.props.order?.storeID &&
          this.props.order?.storeID != this.state?.store?._id &&
          this.state?.stores?.length
        ) {
          const store = _.findWhere(this.state.stores, {
            _id: this.props.order?.storeID,
          });

          if (store) {
            this._selectStore(store);
          }
        }

        this.getAllStores();
      }
    }

    if (this.props.order != prevProps.order && this.props.order) {
      this._loadOrderOptions(this.state.store, this.props.order);
    }

    if (
      this.props.productionMode != prevProps.productionMode &&
      this.props.productionMode
    ) {
      this.getAllStores();
    }
  }

  /**
   * Gets the latest inventory for a specific store by storeID
   *
   * @param {*} storeID
   */
  getLatestInventory(storeID) {
    return new Promise((resolve) => {
      APIV2.getLatestInventoryForStore(storeID).then(
        (data) => {
          //console.log(data.data);

          //console.log(data.data.inventory);

          this.setState(
            {
              inventory: data.data.inventory,
            },
            () => {
              resolve(this.state.inventory);
            }
          );
        },
        (e) => {
          console.error(e);
          this.setState(
            {
              inventory: {},
            },
            () => {
              resolve(this.state.inventory);
            }
          );
        }
      );
    });
  }

  getIncomingInventory(storeID) {
    if (!storeID) {
      return;
    }

    this.setState({
      incomingLoading: true,
    });

    APIV2.getIncomingInventoryForStore(storeID)
      .then(
        (data) => {
          //console.log(data.data);

          this.setState({
            incomingInventory: data.data.inventory ?? {},
          });
        },
        (e) => {
          console.error(e);
          this.setState({
            incomingInventory: {},
          });
        }
      )
      .finally(() => {
        this.setState({
          incomingLoading: false,
        });
      });
  }

  loadLatestPricing() {
    return new Promise((resolve, reject) => {
      this.setState({
        loadingPricing: true,
      });

      APIV2.getLatestProductionPricing()
        .then(
          (data) => {
            resolve(data.data.pricing);
          },
          (e) => {
            reject("Unable to load pricing sheet.");
          }
        )
        .finally(() => {
          this.setState({
            loadingPricing: false,
          });
        });
    });
  }

  getPricingByID(id) {
    return new Promise((resolve, reject) => {
      this.setState({
        loadingPricing: true,
      });

      APIV2.getProductionPricingByID(id)
        .then(
          (data) => {
            resolve(data.data.pricing);
          },
          (e) => {
            reject("Unable to load pricing sheet.");
          }
        )
        .finally(() => {
          this.setState({
            loadingPricing: false,
          });
        });
    });
  }

  setQuantity(key, item, quantity) {
    if (
      key == "lifestyleMeals" ||
      key == "athleteMeals" ||
      key == "leanCheats"
    ) {
      let existing = this.state.hasOwnProperty(key) ? this.state[key] : [];

      if (!existing?.length) {
        existing = [];
      }

      const idx = _.findIndex(existing, { _id: item?._id });

      if (idx >= 0) {
        existing[idx].caseQuantity = quantity;

        this.setState({
          [key]: existing,
        });
      }
    } else if (key == "boxes") {
      let existing = this.state.hasOwnProperty(key) ? this.state[key] : [];

      if (!existing?.length) {
        existing = [];
      }

      const idx = _.findIndex(existing, { name: item?.name });

      if (idx >= 0) {
        existing[idx].caseQuantity = quantity;

        this.setState({
          [key]: existing,
        });
      }
    }
  }

  /**
   * Generates the order options for the store
   *
   * @param {*} store
   * @returns
   */
  async _loadOrderOptions(store, order) {
    if (!store) {
      // Check for a store prop on the drawer
      if (this.props.store) {
        store = this.props.store;

        return this._selectStore(store);
      } else if (order?.storeID) {
        // Check for an order

        // Check if the stores are already loaded
        if (this.state.stores?.length) {
          store = _.findWhere(this.state.stores, { _id: order?.storeID });

          if (!store) {
            return;
          }

          return this._selectStore(store);
        }

        // Attempt to load the store by id
        try {
          let data = await APIV2.getStoreByID(order?.storeID);

          if (!data?.data?.store) {
            return;
          }

          return this._selectStore(data.data.store);
        } catch (e) {
          return;
        }
      }

      return;
    }

    if (order?.deliveryDate) {
      this.setState({
        deliveryDate: moment(order?.deliveryDate).toDate(),
      });
    } else {
      this.setState({
        deliveryDate: null,
      });
    }

    this.fetchNotes(true);

    let orderHistory = [];

    if (order?.history?.length) {
      orderHistory = _.sortBy(order?.history, "eventTimestamp");

      orderHistory.reverse();
    }

    this.setState({
      orderHistory,
    });

    this.setState({
      loadingOptions: true,
    });

    if (order?.name) {
      this.setState({
        orderName: order?.name,
      });
    }

    if (order?.additionalLineItems?.length) {
      let additionalLineItems = order?.additionalLineItems?.map((item) => {
        return {
          _id: item?._id,
          name: item?.name,
          unitPrice: {
            value: item?.unitPrice ? item?.unitPrice / 100 : 0,
            floatValue: item?.unitPrice ? item?.unitPrice / 100 : 0,
          },
          quantity: item?.quantity ? item?.quantity : 0,
        };
      });

      console.log(additionalLineItems); //debug

      let lineItemOptions = this.state.defaultLineItemOptions;

      for (let i = 0; i < additionalLineItems?.length; i++) {
        const item = additionalLineItems[i];

        if (!_.findWhere(this.state.lineItemOptions, { value: item?.name })) {
          lineItemOptions.push({
            label: item?.name,
            value: item?.name,
            group: "Additional Line Items",
          });
        }
      }

      this.setState({
        additionalLineItems,
        lineItemOptions,
      });
    }

    let pricing = null;

    try {
      if (order?.pricing?._id) {
        pricing = await this.getPricingByID(order?.pricing?._id);
      } else {
        pricing = await this.loadLatestPricing();
      }

      this.setState({
        pricing,
      });
    } catch (e) {
      this.setError(
        "error",
        "Unable to load pricing. Please reload and try again."
      );

      return;
    }

    //console.log(pricing);

    let vals = [];

    if (this.props.createMode) {
      vals = _.values(store?.menus?.meals);
    } else {
      vals = _.values(order?.menus?.meals);
    }

    let allMeals = [];

    // Parse all options off the meal menus
    for (let i = 0; i < vals?.length; i++) {
      if (vals[i].hasOwnProperty("active") && vals[i].active?.meals?.length) {
        const menu = vals[i].active;

        let activeMenuMeals = vals[i].active?.meals;

        // Check for an upcoming transition for new meals to be orderable
        if (menu?.menuTransition) {
          let transitionDate = moment(
            menu?.menuTransition?.transitionDate
          ).toDate();

          if (moment().add(3, "week").toDate() >= transitionDate) {
            let retiring = menu?.menuTransition?.retiringMealSwaps.map(
              (item) => {
                return item?.fromMeal;
              }
            );

            for (let j = 0; j < retiring?.length; j++) {
              const idx = _.findIndex(activeMenuMeals, {
                _id: retiring[j]?._id,
              });

              if (idx >= 0) {
                activeMenuMeals[idx].retiring = true;
              }
            }
          }

          // Make new meals available or order one week out
          if (moment().add(1, "week").toDate() >= transitionDate) {
            //console.log("HERE");

            let swaps = menu?.menuTransition?.newMenu?.meals?.map((item) => {
              item.upcoming = true;

              return item;
            });

            swaps = _.filter(swaps, (m) => {
              return !_.findWhere(activeMenuMeals, { _id: m?._id });
            });

            //console.log("SWAPS", swaps?.length);

            // Clear upcoming meals for retail orders prior to the menu launch
            /*if (
              swaps?.length &&
              store?.fulfillmentStrategy ==
                Constant.FULFILLMENT_STRATEGIES.LLM_BOXED
            ) {
              swaps = _.filter(swaps, (m) => {
                return m?.upcoming ? false : true;
              });
            }*/

            activeMenuMeals = activeMenuMeals.concat(swaps);
          }
        }

        for (let j = 0; j < activeMenuMeals?.length; j++) {
          const meal = activeMenuMeals[j];

          meal.name = meal?.name?.trim();

          // Set as default until it can be calculated
          if (
            order?.meals?.hasOwnProperty(meal?.sku) &&
            order?.meals[meal?.sku].caseQuantity
          ) {
            meal.caseQuantity = order?.meals[meal?.sku]?.caseQuantity;
          } else {
            meal.caseQuantity = 0;
          }

          if (
            order?.fulfillmentReportID &&
            order?.meals?.hasOwnProperty(meal?.sku) &&
            order?.meals[meal?.sku]?.mealQuantity
          ) {
            meal.mealQuantity = order?.meals[meal?.sku]?.mealQuantity;
          } else {
            meal.mealQuantity = 0;
          }

          if (!_.findWhere(allMeals, { _id: meal?._id })) {
            allMeals.push(meal);
          }
        }
      }
    }

    let lifestyle = [];
    let athlete = [];
    let leancheats = [];

    // Group by type
    for (let i = 0; i < allMeals?.length; i++) {
      const meal = allMeals[i];

      if (meal?.planType?.name?.includes("Lifestyle")) {
        // Lifestyle meal
        // Lean cheat or lifestyle check
        // TODO: convert to sku check
        if (
          _.findWhere(meal?.categories, { name: "Lean Cheats" }) &&
          !_.findWhere(leancheats, { name: meal?.name })
        ) {
          leancheats.push(meal);
        } else if (
          !_.findWhere(lifestyle, { _id: meal?._id }) &&
          !_.findWhere(meal?.categories, { name: "Lean Cheats" })
        ) {
          lifestyle.push(meal);
        }
      } else {
        // Athlete meal
        // Lean cheat or athlete check
        // TODO: convert to sku check
        if (
          _.findWhere(meal?.categories, { name: "Lean Cheats" }) &&
          !_.findWhere(leancheats, { name: meal?.name })
        ) {
          leancheats.push(meal);
        } else if (
          !_.findWhere(athlete, { _id: meal?._id }) &&
          !_.findWhere(meal?.categories, { name: "Lean Cheats" })
        ) {
          athlete.push(meal);
        }
      }
    }

    // Sort alphabetically

    lifestyle = _.sortBy(lifestyle, "name");
    athlete = _.sortBy(athlete, "name");
    leancheats = _.sortBy(leancheats, "name");

    let boxes = [
      {
        name: "Small Box (12 Meals)",
        sku: "Small Box",
        caseQuantity:
          order?.boxes?.hasOwnProperty("Small Box") &&
          order?.boxes["Small Box"]?.caseQuantity
            ? order?.boxes["Small Box"]?.caseQuantity
            : 0,
        boxQuantity:
          order?.fulfillmentReportID && order?.boxes["Small Box"]?.boxQuantity
            ? order?.boxes["Small Box"]?.boxQuantity
            : 0,
        unitPrice: pricing?.boxSmallUnitPrice,
      },
      {
        name: "Medium Box (18 Meals)",
        sku: "Medium Box",
        caseQuantity:
          order?.boxes?.hasOwnProperty("Medium Box") &&
          order?.boxes["Medium Box"]?.caseQuantity
            ? order?.boxes["Medium Box"]?.caseQuantity
            : 0,
        boxQuantity:
          order?.fulfillmentReportID && order?.boxes["Medium Box"]?.boxQuantity
            ? order?.boxes["Medium Box"]?.boxQuantity
            : 0,
        unitPrice: pricing?.boxMediumUnitPrice,
      },
      {
        name: "Large Box (24 Meals)",
        sku: "Large Box",
        caseQuantity:
          order?.boxes?.hasOwnProperty("Large Box") &&
          order?.boxes["Large Box"]?.caseQuantity
            ? order?.boxes["Large Box"]?.caseQuantity
            : 0,
        boxQuantity:
          order?.fulfillmentReportID && order?.boxes["Large Box"]?.boxQuantity
            ? order?.boxes["Large Box"]?.boxQuantity
            : 0,
        unitPrice: pricing?.boxLargeUnitPrice,
      },
      {
        name: "XL Box (30 Meals)",
        sku: "XL Box",
        caseQuantity:
          order?.boxes?.hasOwnProperty("XL Box") &&
          order?.boxes["XL Box"]?.caseQuantity
            ? order?.boxes["XL Box"]?.caseQuantity
            : 0,
        boxQuantity:
          order?.fulfillmentReportID && order?.boxes["XL Box"]?.boxQuantity
            ? order?.boxes["XL Box"]?.boxQuantity
            : 0,
        unitPrice: pricing?.boxXLUnitPrice,
      },
      {
        name: "Lean Cheat Box",
        sku: "Lean Cheat Box",
        caseQuantity:
          order?.boxes?.hasOwnProperty("Lean Cheat Box") &&
          order?.boxes["Lean Cheat Box"]?.caseQuantity
            ? order?.boxes["Lean Cheat Box"]?.caseQuantity
            : 0,
        boxQuantity:
          order?.fulfillmentReportID &&
          order?.boxes["Lean Cheat Box"]?.boxQuantity
            ? order?.boxes["Lean Cheat Box"]?.boxQuantity
            : 0,
        unitPrice: pricing?.boxLeanCheatUnitPrice,
      },
      {
        name: "Box Inserts",
        sku: "Box Inserts",
        caseQuantity:
          order?.boxes?.hasOwnProperty("Box Inserts") &&
          order?.boxes["Box Inserts"]?.caseQuantity
            ? order?.boxes["Box Inserts"]?.caseQuantity
            : 0,
        boxQuantity:
          order?.fulfillmentReportID && order?.boxes["Box Inserts"]?.boxQuantity
            ? order?.boxes["Box Inserts"]?.boxQuantity
            : 0,
        unitPrice: pricing?.boxInsertUnitPrice,
      },
    ];

    let lifestyleMealCaseOptions = [
      {
        value: 0,
        label: "0 - None",
      },
    ];
    let athleteMealCaseOptions = [
      {
        value: 0,
        label: "0 - None",
      },
    ];
    let leanCheatCaseOptions = [
      {
        value: 0,
        label: "0 - None",
      },
    ];
    let boxBundleOptions = [
      {
        value: 0,
        label: "0 - None",
      },
    ];
    let boxInsertOptions = [
      {
        value: 0,
        label: "0 - None",
      },
    ];

    for (let i = 0; i < 100; i++) {
      lifestyleMealCaseOptions.push({
        value: i + 1,
        label: `${i + 1} ${i == 0 ? "Case" : "Cases"} - ${
          (i + 1) * pricing?.lifestyleMealCaseSize
        } Meals`,
      });

      athleteMealCaseOptions.push({
        value: i + 1,
        label: `${i + 1} ${i == 0 ? "Case" : "Cases"} - ${
          (i + 1) * pricing?.athleteMealCaseSize
        } Meals`,
      });

      leanCheatCaseOptions.push({
        value: i + 1,
        label: `${(i + 1) * pricing?.leanCheatOrderMultiple} Lean Cheat Packs`,
      });

      boxBundleOptions.push({
        value: i + 1,
        label: `${i + 1} ${i == 0 ? "Bundle" : "Bundles"} - ${
          (i + 1) * pricing?.boxBundleSize
        } Boxes`,
      });

      boxInsertOptions.push({
        value: i + 1,
        label: `${i + 1} ${i == 0 ? "Bundle" : "Bundles"} - ${
          (i + 1) * pricing?.boxBundleSize
        } Inserts`,
      });
    }

    if (order?.recommendation) {
      this.setState({
        recommendation: order?.recommendation,
      });
    }

    lifestyle = _.filter(lifestyle, (m) => {
      return !m?.retired;
    });

    athlete = _.filter(athlete, (m) => {
      return !m?.retired;
    });

    leancheats = _.filter(leancheats, (m) => {
      return !m?.retired;
    });

    this.setState({
      lifestyleMealCaseOptions,
      athleteMealCaseOptions,
      leanCheatCaseOptions,
      boxBundleOptions,
      boxInsertOptions,
      lifestyleMeals: lifestyle,
      athleteMeals: athlete,
      leanCheats: leancheats,
      boxes,
    });
  }

  /**
   * Selects a specific store that this order is assigned to
   *
   * @param {*} store
   */
  _selectStore(store) {
    if (this.state.listener) {
      PubSub.removeSubscriber(
        `ws_orderRecommendation:${this.props.store?._id}`,
        this.state.listener
      );
    }

    let id = PubSub.subscribe(
      `ws_orderRecommendation:${this.props.store?._id}`,
      (data) => {
        this.setState({
          recommendation: data,
        });

        this._updateValuesByRecommendation(data);
      }
    );

    console.log("ID", id); //debug

    this.setState({
      listener: id,
    });

    const orderName = `${
      store?.fulfillmentStrategy == Constant.FULFILLMENT_STRATEGIES.LLM_BOXED
        ? "Retail Order"
        : "Product Order"
    } - ${moment().format("MM/DD/YYYY")}`;

    this.setState({
      store,
      storeSelector: store ? store?._id : null,
      orderName,
    });

    if (store) {
      this.getLatestInventory(store?._id);
      this.getIncomingInventory(store?._id);

      console.log(store);

      this._loadOrderOptions(store, this.props.order);

      if (this.props.createMode && !this.props.order) {
        this._calculateRecommendedQuantity(store?._id);
      }
    }

    // TODO: clean out prior order information if store changes

    //console.log(store); //debug
  }

  _updateValuesByRecommendation(recommendation) {
    //console.log(recommendation);

    if (this.state.requestTimeout) {
      clearTimeout(this.state.requestTimeout);
    }

    this.setState({
      recommendationSubmitting: false,
    });

    if (!recommendation?.meals) {
      this.setState({
        recommendationError: "No recommendations available.",
      });

      return;
    }

    const mealVals = _.values(recommendation?.meals);

    if (!mealVals?.length) {
      this.setState({
        recommendationError: "No recommendations available.",
      });

      return;
    }

    let lifestyleMeals = this.state.lifestyleMeals;
    let athleteMeals = this.state.athleteMeals;
    let leanCheats = this.state.leanCheats;

    for (let i = 0; i < mealVals?.length; i++) {
      const meal = mealVals[i];
      const { sku, mealID, caseQuantity, mealQuantity } = mealVals[i];

      const lIndex = _.findIndex(lifestyleMeals, { sku });
      const aIndex = _.findIndex(athleteMeals, { sku });
      const cIndex = _.findIndex(leanCheats, { sku });

      if (lIndex >= 0) {
        //console.log(lifestyleMeals[lIndex], meal);

        lifestyleMeals[lIndex].caseQuantity = meal?.caseQuantity;
      } else if (aIndex >= 0) {
        athleteMeals[aIndex].caseQuantity = meal?.caseQuantity;
      } else if (cIndex >= 0) {
        leanCheats[cIndex].caseQuantity = meal?.caseQuantity;
      }
    }

    this.setState({
      recommendationSubmitting: false,
      lifestyleMeals,
      athleteMeals,
      leanCheats,
    });
  }

  /**
   * Fetch notes for the order
   */
  fetchNotes(forceLoad = false) {
    if (!this.props.order) {
      return;
    }

    if (forceLoad) {
      this.setState({
        loading: true,
      });
    }

    let query = {
      productOrderID: this?.props?.order?._id,
    };

    let sortBy = {
      createdAt: this.state.recentFirst == "recentFirst" ? -1 : 1,
    };

    APIV2.getNotesForStore(
      this.props?.order?.storeID,
      this.state.page,
      20,
      query,
      sortBy
    )
      .then(
        (data) => {
          let notes = forceLoad ? [] : this.state.notes;

          notes = data?.data?.notes?.length
            ? notes.concat(data?.data?.notes)
            : notes;

          let notesOut = [];

          for (let i = 0; i < notes?.length; i++) {
            if (!_.findWhere(notesOut, { _id: notes[i]?._id })) {
              notesOut.push(notes[i]);
            }
          }

          this.setState({
            notes: notesOut,
            pagination: data?.data?.pagination,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  async _calculateRecommendedQuantity(storeID) {
    if (!storeID) {
      // TODO: show error here

      return;
    }

    // Don't get recommended for fulfillment report orders
    if (this.props.order?.fulfillmentReportID) {
      return;
    }

    this.setState({
      recommendationSubmitting: true,
      recommendationError: "",
    });

    let timeout = setTimeout(() => {
      if (this.state.recommendationSubmitting) {
        this.setState({
          recommendationSubmitting: false,
        });

        this.setError(
          "error",
          "Unable to get recommendation. Please try again."
        );
      }
    }, 7 * 60 * 1000);

    this.setState({
      requestTimeout: timeout,
    });

    APIV2.getRecommendedProductOrderForStore(storeID).then(
      (data) => {
        /*this.setState({
          recommendation: data.data.recommendation,
        });*/
        //this._updateValuesByRecommendation(data.data.recommendation);
      },
      (e) => {
        console.error(e);

        this.setState({
          recommendationSubmitting: false,
        });

        // TODO: log error
      }
    );
  }

  /**
   * Fetches all the stores
   */
  getAllStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStores()
      .then((data) => {
        this.setState({
          stores: data.data.stores,
          storeOptions: data.data.stores?.map((st) => {
            return {
              value: st?._id,
              label: st?.name?.trim(),
            };
          }),
        });

        if (
          this.props.order &&
          this.props.order?.storeID != this.state.store?._id
        ) {
          const store = _.findWhere(data.data.stores, {
            _id: this.props.order?.storeID,
          });

          if (store) {
            this._selectStore(store);
          }
        }
      })
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  componentDidMount() {
    if (this.props.store && this.props.open) {
      this._selectStore(this.props.store);
    }

    if (this.props.productionMode) {
      this.getAllStores();
    }
  }

  /**
   * Handles closing the modal events
   */
  toggleModal() {
    this.props.onClose();

    const orderName = `${
      this.state?.store?.fulfillmentStrategy ==
      Constant.FULFILLMENT_STRATEGIES.LLM_BOXED
        ? "Retail Order"
        : "Product Order"
    } - ${moment().format("MM/DD/YYYY")}`;

    this.setState({
      editMode: false,
      orderDate: moment(),
      recommendationAvailable: true,
      orderName,
      lineItems: [],
      store: null,
      additionalLineItems: [],
      storeOptions: [],
      active: {
        id: "Summary",
        name: "Summary",
      },
      lineItemOptions: JSON.parse(
        JSON.stringify(this.state.defaultLineItemOptions)
      ),
      deliveryDate: null,
      modifyDeliveryDate: null,
      recentFirst: "recentFirst",
      lifestyleMeals: [],
      athleteMeals: [],
      leanCheats: [],
      boxes: [],
      promptReceipt: false,
      inaccuratePrompt: false,
    });

    // TODO: clear the order information here
  }

  /**
   * Handles rendering an error message in the drawer
   *
   * @param {*} id
   * @param {*} message
   * @returns
   */
  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  /**
   * Input event handler
   *
   * @param {*} e
   */
  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  /**
   * Sets the status for the product order
   *
   * @param {*} status
   */
  setOrderStatus(status) {
    this.setState({
      statusSubmitting: true,
    });

    APIV2.modifyProductOrderStatus(this.props.order?._id, status)
      .then(
        (data) => {
          const order = data.data.order;

          PubSub.publish(Event.PRODUCT_ORDER.MODIFIED, order);
        },
        (e) => {
          console.error(e); //debug

          this.setError(
            "error",
            "Unable to update order status: " + e?.response?.message ??
              "Unknown error occurred. Please try again"
          );
        }
      )
      .finally(() => {
        this.setState({ statusSubmitting: false });
      });
  }

  /**
   * Sets the invoice status for the order
   *
   * @param {*} status
   */
  setInvoiceStatus(status) {
    APIV2.modifyProductInvoiceStatus(this.props.order?._id, status)
      .then(
        (data) => {
          const order = data.data.order;

          PubSub.publish(Event.PRODUCT_ORDER.MODIFIED, order);
        },
        (e) => {
          console.error(e); //debug

          this.setError(
            "error",
            "Unable to update invoice status: " + e?.response?.message ??
              "Unknown error occurred. Please try again"
          );
        }
      )
      .finally(() => {
        this.setState({ statusSubmitting: false });
      });
  }

  _stateToPayload() {
    let pricing = this.state.pricing;
    let athleteQty = 0;
    let lifestyleQty = 0;
    let leanCheatQty = 0;
    let athleteSubtotal = 0;
    let lifestyleSubtotal = 0;
    let leanCheatSubtotal = 0;

    let lifestyle = this.state.lifestyleMeals?.map((meal) => {
      let qty = this.props?.order?.fulfillmentReportID
        ? meal?.mealQuantity
        : meal?.caseQuantity * this.state.pricing?.lifestyleMealCaseSize;

      let caseQty = this.props.order?.fulfillmentReportID
        ? null
        : meal?.caseQuantity;

      const subtotal =
        qty * Math.ceil(this.state.pricing?.lifestyleMealUnitPrice);

      lifestyleSubtotal += subtotal;

      if (caseQty) {
        lifestyleQty += meal?.caseQuantity;
      }

      if (this.props?.order?.fulfillmentReportID) {
        lifestyleQty += qty;
      }

      return {
        sku: meal?.sku,
        mealID: meal?._id,
        caseQuantity: caseQty,
        mealQuantity: qty,
        unitPrice: Math.ceil(this.state.pricing?.lifestyleMealUnitPrice),
        subtotal,
      };
    });

    let athlete = this.state.athleteMeals?.map((meal) => {
      //console.log(meal?.caseQuantity);
      let qty = this.props?.order?.fulfillmentReportID
        ? meal?.mealQuantity
        : meal?.caseQuantity * this.state.pricing?.athleteMealCaseSize;

      let caseQty = this.props.order?.fulfillmentReportID
        ? null
        : meal?.caseQuantity;

      const subtotal =
        qty * Math.ceil(this.state.pricing?.athleteMealUnitPrice);

      athleteSubtotal += subtotal;

      if (caseQty) {
        athleteQty += meal?.caseQuantity;
      }

      if (this.props?.order?.fulfillmentReportID) {
        athleteQty += qty;
      }

      return {
        sku: meal?.sku,
        mealID: meal?._id,
        caseQuantity: caseQty,
        mealQuantity:
          meal?.caseQuantity * this.state.pricing?.athleteMealCaseSize,
        unitPrice: Math.ceil(this.state.pricing?.athleteMealUnitPrice),
        subtotal,
      };
    });

    let leanCheats = this.state.leanCheats?.map((meal) => {
      let qty = this.props?.order?.fulfillmentReportID
        ? meal?.mealQuantity
        : meal?.caseQuantity * this.state.pricing?.leanCheatOrderMultiple;

      let caseQty = this.props.order?.fulfillmentReportID
        ? null
        : meal?.caseQuantity;

      const subtotal = qty * Math.ceil(this.state.pricing?.leanCheatUnitPrice);

      leanCheatSubtotal += subtotal;

      if (caseQty) {
        leanCheatQty += meal?.caseQuantity;
      }

      if (this.props?.order?.fulfillmentReportID) {
        leanCheatQty += qty;
      }

      return {
        sku: meal?.sku,
        mealID: meal?._id,
        caseQuantity: caseQty,
        mealQuantity:
          meal?.caseQuantity * this.state.pricing?.leanCheatOrderMultiple,
        unitPrice: Math.ceil(this.state.pricing?.leanCheatUnitPrice),
        subtotal,
      };
    });

    let lineItemsSubtotal = 0;
    let lineItems = this.state.additionalLineItems?.map((item) => {
      const quantity = !isNaN(parseInt(item?.quantity))
        ? parseInt(item?.quantity)
        : 0;
      const unitPrice = !isNaN(parseFloat(item?.unitPrice?.value))
        ? parseFloat(item?.unitPrice?.value) * 100
        : 0;

      lineItemsSubtotal += quantity * unitPrice;

      return {
        _id: item?._id,
        name: item?.name,
        quantity,
        unitPrice,
        subtotal: quantity * unitPrice,
      };
    });

    console.log(lineItems);

    let meals = {};

    for (let i = 0; i < lifestyle?.length; i++) {
      const m = lifestyle[i];

      meals[m?.sku] = m;
    }

    for (let i = 0; i < athlete?.length; i++) {
      const m = athlete[i];

      meals[m?.sku] = m;
    }

    for (let i = 0; i < leanCheats?.length; i++) {
      const m = leanCheats[i];

      meals[m?.sku] = m;
    }

    let boxes = {};

    let boxSubtotal = 0;

    for (let i = 0; i < this.state.boxes?.length; i++) {
      const box = this.state.boxes[i];

      const caseQty = this.props.order?.fulfillmentReportID
        ? null
        : box?.caseQuantity;
      let qty = this.props.order?.fulfillmentReportID
        ? box?.boxQuantity
        : box?.caseQuantity * pricing?.boxBundleSize;

      const subtotal = Math.round(qty * box?.unitPrice);

      boxSubtotal += subtotal;

      boxes[box?.sku] = {
        sku: box?.sku,
        caseQuantity: caseQty,
        boxQuantity: qty,
        unitPrice: box?.unitPrice,
        subtotal: subtotal,
      };
    }

    if (this.props.order?.fulfillmentReportID) {
      boxes["Boxes For Cases"] = {
        sku: "Boxes For Cases",
        boxQuantity: 0,
        unitPrice: Math.ceil(pricing?.boxMealCaseUnitPrice),
        subtotal: 0,
      };
    } else {
      boxes["Boxes For Cases"] = {
        sku: "Boxes For Cases",
        boxQuantity: Math.ceil(
          this.calculateTotalQuantity("lifestyleMeals") +
            this.calculateTotalQuantity("athleteMeals")
        ),
        unitPrice: Math.ceil(pricing?.boxMealCaseUnitPrice),
        subtotal:
          Math.ceil(
            this.calculateTotalQuantity("lifestyleMeals") +
              this.calculateTotalQuantity("athleteMeals")
          ) * Math.ceil(pricing?.boxMealCaseUnitPrice),
      };

      boxSubtotal +=
        (this.calculateTotalQuantity("lifestyleMeals") +
          this.calculateTotalQuantity("athleteMeals")) *
        Math.ceil(pricing?.boxMealCaseUnitPrice);
    }

    const leanCheatBoxQuantity = Math.ceil(
      (this.calculateTotalQuantity("leanCheats") *
        this.state.pricing?.leanCheatOrderMultiple) /
        this.state.pricing?.leanCheatCaseSize
    );
    const caseInsertBoxQuantity = Math.ceil(
      (this.calculateTotalQuantity("leanCheats") *
        this.state.pricing?.leanCheatOrderMultiple) /
        this.state.pricing?.leanCheatCaseSize
    );

    if (this.props.order?.fulfillmentReportID) {
      boxes["Boxes For Lean Cheats"] = {
        sku: "Boxes For Lean Cheats",
        boxQuantity: 0,
        unitPrice: Math.ceil(pricing?.boxLeanCheatUnitPrice),
        subtotal: 0,
      };

      const caseInserts = this.props.order?.boxes?.hasOwnProperty(
        "Case Inserts"
      )
        ? this.props.order.boxes["Case Inserts"]
        : {
            sku: "Case Inserts",
            boxQuantity: 0,
            unitPrice: Math.ceil(pricing?.boxInsertUnitPrice),
            subtotal: 0,
          };

      boxes["Case Inserts"] = caseInserts;
    } else {
      boxes["Boxes For Lean Cheats"] = {
        sku: "Boxes For Lean Cheats",
        boxQuantity: leanCheatBoxQuantity,
        unitPrice: Math.ceil(pricing?.boxLeanCheatUnitPrice),
        subtotal:
          leanCheatBoxQuantity * Math.ceil(pricing?.boxLeanCheatUnitPrice),
      };

      boxes["Case Inserts"] = {
        sku: "Case Inserts",
        boxQuantity: caseInsertBoxQuantity,
        unitPrice: Math.ceil(pricing?.boxInsertUnitPrice),
        subtotal:
          caseInsertBoxQuantity * Math.ceil(pricing?.boxInsertUnitPrice),
      };

      boxSubtotal +=
        ((this.calculateTotalQuantity("leanCheats") *
          this.state.pricing?.leanCheatOrderMultiple) /
          this.state.pricing?.leanCheatCaseSize) *
        Math.ceil(pricing?.boxLeanCheatUnitPrice);

      boxSubtotal +=
        ((this.calculateTotalQuantity("leanCheats") *
          this.state.pricing?.leanCheatOrderMultiple) /
          this.state.pricing?.leanCheatCaseSize) *
        Math.ceil(pricing?.boxInsertUnitPrice);
    }

    let logisticsSubtotal = 0;
    let totalCases = 0;
    let palletCount = 0;

    let averageUtilization = 0;

    let palletOptimization = this._calculatePallets().map((pallet) => {
      logisticsSubtotal += pallet?.unitPrice;
      totalCases += pallet?.casesPerPallet * pallet.percentUtilized;
      palletCount++;

      averageUtilization += pallet.percentUtilized;

      return {
        casesPerPallet: pallet?.casesPerPallet,
        percentUtilized: pallet?.percentUtilized,
        unitPrice: pallet?.unitPrice,
        subtotal: pallet?.unitPrice,
        _id: pallet?._id ? pallet?._id : null,
        _idString: pallet?._id ? pallet?._id.toString() : null,
      };
    });

    averageUtilization = palletCount > 0 ? averageUtilization / palletCount : 0;

    let summary = {
      lifestyleCaseQuantity: lifestyleQty,
      lifestyleMealQuantity: lifestyleQty * pricing?.lifestyleMealCaseSize,
      lifestyleSubtotal: lifestyleSubtotal,
      athleteCaseQuantity: athleteQty,
      athleteMealQuantity: athleteQty * pricing?.athleteMealCaseSize,
      athleteSubtotal: athleteSubtotal,
      leanCheatPackQuantity: leanCheatQty,
      leanCheatQuantity: leanCheatQty * pricing?.leanCheatOrderMultiple,
      leanCheatSubtotal: leanCheatSubtotal,
      boxSubtotal,
    };

    if (this.props?.order?.fulfillmentReportID) {
      summary = {
        lifestyleCaseQuantity: parseFloat(
          (lifestyleQty / pricing?.lifestyleMealCaseSize).toFixed(2)
        ),
        lifestyleMealQuantity: lifestyleQty,
        lifestyleSubtotal: lifestyleSubtotal,
        athleteCaseQuantity: parseFloat(
          (athleteQty / pricing?.athleteMealCaseSize).toFixed(2)
        ),
        athleteMealQuantity: athleteQty,
        athleteSubtotal: athleteSubtotal,
        leanCheatPackQuantity: parseFloat(
          (leanCheatQty / pricing?.leanCheatOrderMultiple).toFixed(2)
        ),
        leanCheatQuantity: leanCheatQty,
        leanCheatSubtotal: leanCheatSubtotal,
        boxSubtotal,
      };
    }

    let total =
      boxSubtotal +
      lifestyleSubtotal +
      athleteSubtotal +
      leanCheatSubtotal +
      lineItemsSubtotal +
      logisticsSubtotal;

    let payload = {
      name: this.state.orderName,
      pricing: {
        _id: pricing?._id,
      },
      productOrderStrategy: this.state.store?.productOrderStrategy
        ? this.state.store?.productOrderStrategy
        : "Weekly Ordering",
      recommendation: this.state.recommendation
        ? this.state.recommendation
        : {},
      meals,
      boxes,
      additionalLineItems: lineItems,
      logistics: {
        totalCases,
        subtotal: logisticsSubtotal,
        palletCount,
        averageUtilization,
        palletOptimization,
        averageCostPerMeal: Math.ceil(
          logisticsSubtotal / (lifestyleQty + athleteQty + leanCheatQty)
        ),
      },
      menus: this.props.order?.menus
        ? this.props.order?.menus
        : this.state.store?.menus,
      summary: summary,
      inventory: {
        _id: this.state.inventory?._id,
        name: this.state.inventory?.name,
      },
      total,
      storeID: this.state.store?._id,
    };

    return payload;
  }

  create() {
    this.setState({ submitting: true });

    let payload = this._stateToPayload();

    // TODO: finish this

    APIV2.createProductOrder(payload)
      .then(
        (data) => {
          const order = data.data.order;

          this.toggleModal();

          PubSub.publish(Event.PRODUCT_ORDER.CREATED, order);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create order - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  modify() {
    let payload = this._stateToPayload();

    // TODO: finish this

    this.setState({ submitting: true });

    APIV2.modifyProductOrder(this.props?.order?._id, payload)
      .then(
        (data) => {
          const order = data.data.order;

          this.setState({
            editMode: false,
          });

          //this.toggleModal();

          PubSub.publish(Event.PRODUCT_ORDER.MODIFIED, order);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to modify order - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  getDiscountValue(c) {
    let discountValue = "";

    if (c.discountType == "Flat") {
      discountValue = StringUtils.centsToCurrency(c.flatDiscount);
    }

    if (c.discountType == "Percent") {
      discountValue = c.percentDiscount * 100 + "%";
    }

    return discountValue;
  }

  calcItemPrice(type, item) {
    if (!type || !item || !this.state.pricing) {
      return "--";
    }

    if (type == "lifestyleMeals") {
      let cents =
        item?.caseQuantity *
        this.state.pricing?.lifestyleMealUnitPrice *
        this.state.pricing?.lifestyleMealCaseSize;

      if (this.props.order?.fulfillmentReportID) {
        //console.log(item);
        cents = item?.mealQuantity * this.state.pricing?.lifestyleMealUnitPrice;
      }

      return StringUtils.centsToCurrency(cents);
    } else if (type == "athleteMeals") {
      let cents =
        item?.caseQuantity *
        this.state.pricing?.athleteMealUnitPrice *
        this.state.pricing?.athleteMealCaseSize;

      if (this.props.order?.fulfillmentReportID) {
        cents = item?.mealQuantity * this.state.pricing?.athleteMealUnitPrice;
      }

      return StringUtils.centsToCurrency(cents);
    } else if (type == "leanCheats") {
      let cents =
        item?.caseQuantity *
        this.state.pricing?.leanCheatUnitPrice *
        this.state.pricing?.leanCheatOrderMultiple;

      if (this.props.order?.fulfillmentReportID) {
        cents = item?.mealQuantity * this.state.pricing?.leanCheatUnitPrice;
      }

      return StringUtils.centsToCurrency(cents);
    } else if (type == "boxes") {
      let cents =
        item?.caseQuantity *
        item?.unitPrice *
        this.state.pricing?.boxBundleSize;

      if (this.props.order?.fulfillmentReportID) {
        cents = item?.boxQuantity * item?.unitPrice;
      }

      return StringUtils.centsToCurrency(cents);
    } else {
      return "$0.00";
    }
  }

  handleUnitPriceChange(item, v) {
    let items = this.state.lineItems;

    let idx = _.findIndex(items, { id: item?.id });

    if (idx >= 0) {
      items[idx].unitPrice = v;
    }

    this.setState(
      {
        lineItems: items,
      },
      () => {
        if (this.state?.discountObject) {
          this.handleDiscountChange(this?.state?.discountObject?._id);
        }

        this.calculateTotals();
      }
    );
  }

  handleItemNameChange(item, e) {
    let items = this.state.lineItems;

    let idx = _.findIndex(items, { id: item?.id });

    if (idx >= 0) {
      items[idx].name = e?.target?.value;
    }

    this.setState({
      lineItems: items,
    });
  }

  handleItemQuantityChange(item, e) {
    let items = this.state.lineItems;

    let idx = _.findIndex(items, { id: item?.id });

    if (idx >= 0) {
      items[idx].quantity = e;
    }

    this.setState(
      {
        lineItems: items,
      },
      () => {
        if (this.state?.discountObject) {
          this.handleDiscountChange(this?.state?.discountObject?._id);
        }

        this.calculateTotals();
      }
    );
  }

  isValidInvoice() {
    if (
      !this.state?.orderName ||
      !this.state.store ||
      this._getProjectedTotal() <= 0
    ) {
      return false;
    }

    return true;
  }

  calculateTotalQuantity(type) {
    if (
      type == "lifestyleMeals" ||
      type == "athleteMeals" ||
      type == "leanCheats"
    ) {
      let existing =
        this.state.hasOwnProperty(type) && this.state[type]?.length
          ? this.state[type]
          : [];
      let caseQuantity = 0;

      for (let i = 0; i < existing?.length; i++) {
        let qty = existing[i]?.caseQuantity;

        if (this.props.order?.fulfillmentReportID) {
          qty = existing[i]?.mealQuantity;
        }

        if (qty) {
          caseQuantity += qty;
        }
      }

      return caseQuantity;
    }

    return 0;
  }

  _calculatePallets() {
    if (
      !this.state.pricing ||
      this.state.store?.fulfillmentStrategy !=
        Constant.FULFILLMENT_STRATEGIES.IN_STORE
    ) {
      return [];
    }

    let unitPrice = this.state.pricing?.logisticsFreightStandardPrice;
    let casesPerPallet = this.state.pricing?.logisticsPalletCaseQuantity;

    if (
      this.state.store?._id &&
      this.state.pricing?.logisticsFreightCustomPricing?.hasOwnProperty(
        this.state.store?._id
      )
    ) {
      unitPrice =
        this.state.pricing?.logisticsFreightCustomPricing[
          this.state.store?._id
        ];
    }

    if (
      this.state.store?._id &&
      this.state.pricing?.logisticsCustomPalletCaseQuantity?.hasOwnProperty(
        this.state.store?._id
      )
    ) {
      casesPerPallet =
        this.state.pricing?.logisticsCustomPalletCaseQuantity[
          this.state.store?._id
        ];
    }

    let totalCases = 0;

    totalCases += this.calculateTotalQuantity("lifestyleMeals");
    totalCases += this.calculateTotalQuantity("athleteMeals");
    totalCases += Math.ceil(
      (this.calculateTotalQuantity("leanCheats") *
        this.state.pricing?.leanCheatOrderMultiple) /
        this.state.pricing?.leanCheatCaseSize
    );

    for (let i = 0; i < this.state?.boxes?.length; i++) {
      const item = this.state.boxes[i];

      let cents = item?.caseQuantity;

      totalCases += cents * 5;
    }

    let pallets = [];

    if (totalCases > casesPerPallet) {
      while (true) {
        if (totalCases < casesPerPallet) {
          if (totalCases == 0) {
            break;
          }

          pallets.push({
            unitPrice,
            casesPerPallet,
            percentUtilized: totalCases / casesPerPallet,
          });

          break;
        } else {
          pallets.push({
            unitPrice,
            casesPerPallet,
            percentUtilized: 1,
          });

          totalCases = totalCases - casesPerPallet;
        }
      }
    } else if (totalCases > 0) {
      pallets.push({
        unitPrice,
        casesPerPallet,
        percentUtilized: totalCases / casesPerPallet,
      });
    }

    //console.log(totalCases);

    return pallets;
  }

  calculateBoxSubtotal() {
    if (!this.state.pricing) {
      return "--";
    }

    let totalCases = 0;
    let leanCheatCases = this.calculateTotalQuantity("leanCheats");

    totalCases += this.calculateTotalQuantity("lifestyleMeals");
    totalCases += this.calculateTotalQuantity("athleteMeals");

    let subtotal = 0;

    if (this.props.createMode || !this.props.order?.fulfillmentReportID) {
      leanCheatCases = Math.ceil(
        leanCheatCases / this.state.pricing?.leanCheatCaseSize
      );

      subtotal += totalCases * this.state.pricing?.boxSmallUnitPrice;
      subtotal += leanCheatCases * this.state?.pricing?.boxLeanCheatUnitPrice;
      subtotal += leanCheatCases * this.state.pricing?.boxInsertUnitPrice;
    } else {
      const count =
        this.props.order?.boxes?.hasOwnProperty("Case Inserts") &&
        this.props.order?.boxes["Case Inserts"]?.boxQuantity
          ? this.props.order?.boxes["Case Inserts"]?.boxQuantity
          : 0;

      subtotal += count * this.state.pricing?.boxInsertUnitPrice;
    }

    for (let i = 0; i < this.state?.boxes?.length; i++) {
      const item = this.state.boxes[i];

      const qty =
        this.props.createMode || !this.props.order?.fulfillmentReportID
          ? item?.caseQuantity * this.state.pricing?.boxBundleSize
          : item?.boxQuantity;

      let cents = qty * item?.unitPrice;

      subtotal += cents;
    }

    return subtotal;
  }

  _totalPalletPrice(pallets) {
    let totalCents = 0;

    if (!pallets?.length) {
      return 0;
    }

    for (let i = 0; i < pallets?.length; i++) {
      totalCents += pallets[i]?.unitPrice;
    }

    return totalCents;
  }

  _addAdditionalLineItem() {
    let items = this.state.additionalLineItems?.length
      ? this.state.additionalLineItems
      : [];

    items.push({
      _id: StringUtils.uuid(),
      name: "",
      quantity: 0,
      unitPrice: {
        value: "",
      },
    });

    this.setState({
      additionalLineItems: items,
    });
  }

  _removeAdditionalLineItem(id) {
    let items = this.state.additionalLineItems?.length
      ? this.state.additionalLineItems
      : [];

    items = _.filter(items, (it) => {
      return it._id != id;
    });

    this.setState({
      additionalLineItems: items,
    });
  }

  setItemValue(key, item, value) {
    let items = this.state?.additionalLineItems?.length
      ? this.state.additionalLineItems
      : [];

    const idx = _.findIndex(items, { _id: item?._id });

    if (idx >= 0) {
      items[idx][key] = value;
    }

    this.setState({
      additionalLineItems: items,
    });
  }

  getAdditionalItemSubtotal() {
    let items = this.state?.additionalLineItems?.length
      ? this.state.additionalLineItems
      : [];

    let total = 0;

    for (let i = 0; i < items?.length; i++) {
      if (items[i]?.unitPrice?.value && items[i]?.quantity) {
        const cents =
          parseFloat(items[i]?.unitPrice?.value) * 100 * items[i]?.quantity;

        total += cents;
      }
    }

    return total;
  }

  _getProjectedTotal() {
    let lifestyleMeals =
      this.calculateTotalQuantity("lifestyleMeals") *
      this.state.pricing?.lifestyleMealUnitPrice;

    let athleteMeals =
      this.calculateTotalQuantity("athleteMeals") *
      this.state.pricing?.athleteMealUnitPrice;

    let leanCheats =
      this.calculateTotalQuantity("leanCheats") *
      this.state.pricing?.leanCheatUnitPrice;

    if (this.props.createMode || !this.props.order?.fulfillmentReportID) {
      lifestyleMeals =
        lifestyleMeals * this.state.pricing?.lifestyleMealCaseSize;

      athleteMeals = athleteMeals * this.state.pricing?.athleteMealCaseSize;
      leanCheats = leanCheats * this.state.pricing?.leanCheatOrderMultiple;
    }

    return (
      lifestyleMeals +
      athleteMeals +
      leanCheats +
      this.calculateBoxSubtotal() +
      this._totalPalletPrice(this._calculatePallets()) +
      this.getAdditionalItemSubtotal()
    );
  }

  /**
   * Formats the order statuses into badges
   *
   * @param {*} type
   * @param {*} order
   * @returns
   */
  formatStatus(type, order) {
    let color = "light";
    let classNames = "";

    if (type == "orderStatus") {
      let icon = "mdi-circle-outline";

      if (order?.orderStatus == "SUBMITTED") {
        color = "medium";
      }

      if (order?.orderStatus == "ACCEPTED") {
        color = "info";
      }

      if (order?.orderStatus == "RECEIVED") {
        color = "success";
      }

      if (order?.orderStatus == "COMPLETED") {
        color = "success";

        icon = "mdi-circle";
      }

      if (
        order?.orderStatus == "PROCESSING" ||
        order?.orderStatus == "IN TRANSIT"
      ) {
        color = "primary";
      }

      return (
        <Badge color={color}>
          <i className={`mr-1 mdi ${icon}`}></i>
          {order?.orderStatus}
        </Badge>
      );
    } else if (type == "invoiceStatus") {
      let icon = "mdi-circle-outline";

      if (
        order?.invoiceStatus == "UNPAID" &&
        (order?.orderStatus == "PROCESSING" ||
          order?.orderStatus == "IN TRANSIT" ||
          order?.orderStatus == "RECEIVED" ||
          order?.orderStatus == "COMPLETED")
      ) {
        color = "";
        classNames = "bg-yellow text-dark";

        //console.log(classNames);
      }

      if (order?.invoiceStatus == "PAID") {
        color = "success";

        icon = "mdi-circle";
      }

      return (
        <Badge color={color} className={classNames}>
          <i className={`mr-1 mdi ${icon}`}></i>
          {order?.invoiceStatus == "PAID" ? "FUNDED" : "UNFUNDED"}
        </Badge>
      );
    }

    return <>--</>;
  }

  deleteOrder() {
    swal({
      title: "Delete Order",
      text: "Are you sure you want delete this order?",
      icon: "warning",
      buttons: ["Nevermind", "Delete"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({ deleting: true });

      APIV2.deleteProductOrder(this?.props?.order?._id)
        .then(
          (data) => {
            PubSub.publish(Event.PRODUCT_ORDER.DELETED);

            this.toggleModal();
          },
          (e) => {
            this.setError(
              "error",
              e?.response?.body?.message ??
                "Unable to delete order - unknown error occurred. Try again."
            );
          }
        )
        .finally(() => {
          this.setState({ deleting: false });
        });
    });
  }

  modifyOrderDeliveryDate() {
    this.setState({ submittingDelivery: true });

    APIV2.modifyProductOrderDeliveryDate(
      this?.props?.order?._id,
      this.state.modifyDeliveryDate
    )
      .then(
        (data) => {
          PubSub.publish(Event.PRODUCT_ORDER.MODIFIED, data.data.order);

          this.setState({
            modifyDeliveryDate: false,
            editDeliveryDate: false,
            deliveryDate: data.data.order?.deliveryDate
              ? moment(data.data.order?.deliveryDate).toDate()
              : null,
          });
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to modify order delivery date - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submittingDelivery: false });
      });
  }

  generateRecommendationExplanation(meal) {
    if (!this.state.recommendation || !meal) {
      return "--";
    }

    const recommended = this.state.recommendation?.meals?.hasOwnProperty(
      meal?.sku
    )
      ? this.state.recommendation.meals[meal?.sku]
      : null;

    if (!recommended) {
      return "--";
    }

    console.log(recommended, this.state.recommendation);

    return (
      <Table className={"table-responsive table-bordered m-0"}>
        <thead>
          <tr>
            <th className="p-2 bg-superlight text-dark align-middle text-center">
              Projected<br></br>Inventory
            </th>

            <th className="p-2 bg-superlight text-dark align-middle text-center"></th>
            <th
              className="p-2 bg-superlight text-dark align-middle text-center"
              colspan={2}
            >
              <div>Projected Usage</div>
              <div style={{ fontSize: 9 }} className="text-muted">
                Current Week
              </div>
            </th>
            <th className="p-2 bg-superlight text-dark align-middle text-center"></th>
            <th className="p-2 bg-superlight text-dark align-middle text-center">
              Projected<br></br>Leftovers
            </th>
            <th className="p-2 bg-superlight text-dark align-middle text-center"></th>
            <th
              className="p-2 bg-superlight text-dark align-middle text-center"
              colspan={2}
            >
              <div>Projected Usage</div>
              <div style={{ fontSize: 9 }} className="text-muted">
                {recommended?.projectedUsage?.subscriptionUsage
                  ?.thirdWeekQuantity
                  ? "For Two Weeks"
                  : "Next Week"}
              </div>
            </th>
            <th className="p-2 bg-superlight text-dark align-middle text-center"></th>
            <th className="p-2 bg-superlight text-dark align-middle text-center">
              Projected<br></br>Quantity{" "}
              {(recommended?.projectedUsage?.quantity ?? 0) -
                (recommended?.projectedLeftovers?.quantity ?? 0) >=
              0
                ? "Needed"
                : "Leftover"}
            </th>
            <th className="p-2 bg-superlight text-dark align-middle text-center"></th>
            <th className="p-2 bg-superlight text-dark align-middle text-center">
              Recommended<br></br>Order Quantity
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={`align-middle text-center p-2`}>
              <div className="text-dark">
                {StringUtils.numberFormat(
                  recommended?.projectedInventory?.quantity ?? 0
                )}
              </div>
              <div
                className="text-dark text-uppercase"
                style={{ fontSize: 11 }}
              >
                In Stock & Incoming
              </div>
              <div
                className="text-muted text-uppercase"
                style={{ fontSize: 9 }}
              >
                As of{" "}
                {moment(this.state.recommendation?.calculatedAt).format(
                  "MM/DD/YY"
                )}
              </div>
            </td>
            <td className="text-dark align-middle text-center p-2">
              <i
                className="mdi mdi-minus text-dark"
                style={{ fontSize: 20 }}
              ></i>
            </td>

            <td
              className={`align-middle text-center p-2 ${
                !recommended?.projectedUsage?.subscriptionUsage && "d-none"
              }`}
              colspan={recommended?.projectedUsage?.retailUsage ? 1 : 2}
            >
              <div className="text-dark">
                {meal?.upcoming
                  ? "0"
                  : StringUtils.numberFormat(
                      recommended?.projectedUsage?.subscriptionUsage
                        ?.firstWeekQuantity ?? 0
                    )}
              </div>
              <div
                className="text-dark text-uppercase"
                style={{ fontSize: 11 }}
              >
                Subscription
              </div>
              <div
                className="text-muted text-uppercase"
                style={{ fontSize: 9 }}
              >
                {recommended?.projectedUsage?.subscriptionUsage
                  ?.averagedSKUs ? (
                  <>
                    {meal?.upcoming ? (
                      "Not Yet Available"
                    ) : (
                      <>
                        Based on avg. of{" "}
                        {
                          recommended?.projectedUsage?.subscriptionUsage
                            ?.averagedSKUs
                        }{" "}
                        {
                          recommended?.projectedUsage?.subscriptionUsage
                            ?.category
                        }{" "}
                        SKUs
                      </>
                    )}
                  </>
                ) : (
                  <>
                    Est. for{" "}
                    {moment(
                      recommended?.projectedUsage?.subscriptionUsage
                        ?.fulfillmentDateString
                    )
                      .day(6)
                      .format("MM/DD/YY")}{" "}
                    Fulfillment
                  </>
                )}
              </div>
            </td>
            <td
              className={`align-middle text-center p-2 ${
                !recommended?.projectedUsage?.retailUsage && "d-none"
              }`}
              colspan={recommended?.projectedUsage?.subscriptionUsage ? 1 : 2}
            >
              <div className="text-dark">
                {meal?.upcoming
                  ? "0"
                  : StringUtils.numberFormat(
                      recommended?.projectedUsage?.retailUsage
                        ?.firstWeekQuantity ?? 0
                    )}
              </div>
              <div
                className="text-dark text-uppercase"
                style={{ fontSize: 11 }}
              >
                Retail
              </div>
              <div
                className="text-muted text-uppercase"
                style={{ fontSize: 9 }}
              >
                {recommended?.projectedUsage?.retailUsage?.averagedSKUs ? (
                  <>
                    {meal?.upcoming ? (
                      "Not Yet Available"
                    ) : (
                      <>
                        Based on avg. of{" "}
                        {recommended?.projectedUsage?.retailUsage?.averagedSKUs}{" "}
                        {recommended?.projectedUsage?.retailUsage?.category}{" "}
                        SKUs
                      </>
                    )}
                  </>
                ) : (
                  <>
                    Est. for week of{" "}
                    {moment(
                      recommended?.projectedUsage?.retailUsage
                        ?.retailSaleWeekStartDate
                    )
                      .day(0)
                      .format("MM/DD/YY")}
                  </>
                )}
              </div>
            </td>

            <td className="text-dark align-middle text-center p-2">
              <i
                className="mdi mdi-equal text-dark"
                style={{ fontSize: 20 }}
              ></i>
            </td>
            <td className={`align-middle text-center p-2`}>
              <div className="text-dark">
                {StringUtils.numberFormat(
                  recommended?.projectedLeftovers?.quantity ?? 0
                )}
              </div>
              <div
                className="text-dark text-uppercase"
                style={{ fontSize: 11 }}
              >
                Leftover Meals
              </div>
              <div
                className="text-muted text-uppercase"
                style={{ fontSize: 9 }}
              >
                Est. for{" "}
                {moment(this.state.recommendation?.calculatedAt)
                  .day(6)
                  .format("MM/DD/YY")}
              </div>
            </td>
            <td className="text-dark align-middle text-center p-2">
              <i
                className="mdi mdi-minus text-dark"
                style={{ fontSize: 20 }}
              ></i>
            </td>
            <td
              className={`align-middle text-center p-2 ${
                !recommended?.projectedUsage?.subscriptionUsage && "d-none"
              }`}
              colspan={recommended?.projectedUsage?.retailUsage ? 1 : 2}
            >
              <div className="text-dark">
                {StringUtils.numberFormat(
                  recommended?.projectedUsage?.subscriptionUsage?.quantity ?? 0
                )}
              </div>
              <div
                className="text-dark text-uppercase"
                style={{ fontSize: 11 }}
              >
                Subscription
              </div>
              <div
                className="text-muted text-uppercase"
                style={{ fontSize: 9 }}
              >
                {recommended?.projectedUsage?.subscriptionUsage
                  ?.averagedSKUs ? (
                  <>
                    Based on avg. of{" "}
                    {
                      recommended?.projectedUsage?.subscriptionUsage
                        ?.averagedSKUs
                    }{" "}
                    {recommended?.projectedUsage?.subscriptionUsage?.category}{" "}
                    SKUs
                  </>
                ) : (
                  <>
                    Est. for{" "}
                    {moment(
                      recommended?.projectedUsage?.subscriptionUsage
                        ?.fulfillmentDateString
                    )
                      .day(6)
                      .add(1, "week")
                      .format("MM/DD/YY")}{" "}
                    Fulfillment
                  </>
                )}
              </div>
            </td>
            <td
              className={`align-middle text-center p-2 ${
                !recommended?.projectedUsage?.retailUsage && "d-none"
              }`}
              colspan={recommended?.projectedUsage?.subscriptionUsage ? 1 : 2}
            >
              <div className="text-dark">
                {StringUtils.numberFormat(
                  recommended?.projectedUsage?.retailUsage?.quantity ?? 0
                )}
              </div>
              <div
                className="text-dark text-uppercase"
                style={{ fontSize: 11 }}
              >
                Retail
              </div>
              <div
                className="text-muted text-uppercase"
                style={{ fontSize: 9 }}
              >
                {recommended?.projectedUsage?.retailUsage?.averagedSKUs ? (
                  <>
                    Based on avg. of{" "}
                    {recommended?.projectedUsage?.retailUsage?.averagedSKUs}{" "}
                    {recommended?.projectedUsage?.retailUsage?.category} SKUs
                  </>
                ) : (
                  <>
                    Est. for week of{" "}
                    {moment(
                      recommended?.projectedUsage?.retailUsage
                        ?.retailSaleWeekStartDate
                    )
                      .day(0)
                      .add(1, "week")
                      .format("MM/DD/YY")}
                  </>
                )}
              </div>
            </td>
            <td className="text-dark align-middle text-center p-2">
              <i
                className="mdi mdi-equal text-dark"
                style={{ fontSize: 20 }}
              ></i>
            </td>
            <td className={`align-middle text-center p-2`}>
              <div className="text-dark">
                {StringUtils.numberFormat(
                  Math.abs(
                    (recommended?.projectedUsage?.quantity ?? 0) -
                      (recommended?.projectedLeftovers?.quantity ?? 0)
                  )
                )}
              </div>
              <div
                className="text-dark text-uppercase"
                style={{ fontSize: 11 }}
              >
                {`${
                  recommended?.projectedUsage?.subscriptionUsage
                    ? "Subscription"
                    : ""
                }${
                  recommended?.projectedUsage?.subscriptionUsage &&
                  recommended?.projectedUsage?.retailUsage
                    ? " & "
                    : ""
                }${recommended?.projectedUsage?.retailUsage ? "Retail" : ""}`}
              </div>
              <div
                className="text-muted text-uppercase"
                style={{ fontSize: 9 }}
              >
                Est. for{" "}
                {moment(this.state.recommendation?.calculatedAt)
                  .day(6)
                  .add(1, "week")
                  .format("MM/DD/YY")}{" "}
                Fulfillment
              </div>
            </td>
            <td className="text-dark align-middle text-center p-2">
              <i
                className="mdi mdi-approximately-equal text-dark"
                style={{ fontSize: 20 }}
              ></i>
            </td>
            <td className={`align-middle text-center p-2`}>
              <div className="text-dark">
                {StringUtils.numberFormat(recommended?.mealQuantity ?? 0)}
              </div>
              <div
                className="text-dark text-uppercase"
                style={{ fontSize: 11 }}
              >
                {StringUtils.numberFormat(recommended?.caseQuantity ?? 0)} Case
                {recommended?.caseQuantity != 1 ? "s" : ""}
              </div>
              <div
                className="text-muted text-uppercase"
                style={{ fontSize: 9 }}
              >
                rounded to full case
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    );

    console.log(this.state.recommendation, meal);
  }

  render() {
    return (
      <>
        <Drawer
          size="lg"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
          keyboard={this.props.createMode || this.state.editMode ? false : true}
          backdrop={
            this.props.createMode || this.state.editMode ? "static" : true
          }
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.createMode ? <>New Product Order</> : null}
              {this.props.order && !this.state.editMode ? (
                <>Order Details</>
              ) : null}
              {this.state?.editMode ? <>Modify Product Order</> : null}
            </h3>
            <Drawer.Actions>
              {this.props.createMode ? (
                <>
                  <Button
                    size="sm"
                    disabled={
                      this.state.submitting ||
                      this.state.recommendationSubmitting ||
                      !this.isValidInvoice()
                    }
                    color="primary"
                    onClick={this.create.bind(this)}
                  >
                    {this.state.submitting ? (
                      <Spinner size="sm" color="white"></Spinner>
                    ) : (
                      "Create"
                    )}
                  </Button>
                </>
              ) : null}
              {this.state.editMode ? (
                <>
                  <Button
                    size="sm"
                    disabled={this.state.submitting}
                    color="secondary"
                    outline
                    onClick={() => {
                      this.setState(
                        {
                          editMode: false,
                        },
                        () => {
                          this._selectStore(this.state.store);
                        }
                      );
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    disabled={
                      this.state.submitting ||
                      this.state.recommendationSubmitting ||
                      !this.isValidInvoice()
                    }
                    color="primary"
                    onClick={this.modify.bind(this)}
                  >
                    {this.state.submitting ? (
                      <Spinner size="sm" color="white"></Spinner>
                    ) : (
                      "Save"
                    )}
                  </Button>
                </>
              ) : null}
              {this.props.order && !this.state.editMode ? (
                <>
                  <Whisper
                    ref={this.dropdownRef}
                    trigger="click"
                    placement={"auto"}
                    preventOverflow={true}
                    speaker={
                      <Popover full>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            panel
                            className="py-2 font-weight-800 text-uppercase"
                            style={{ paddingLeft: 12, paddingRight: 12 }}
                          >
                            <h5 className="m-0">Order Status</h5>
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled={
                              this.props.order?.orderStatus == "DRAFT" ||
                              this.props.order?.invoiceStatus == "PAID" ||
                              (!this.props.productionMode &&
                                this.props.order?.orderStatus == "ACCEPTED") ||
                              (!this.props.productionMode &&
                                this.props.order?.orderStatus ==
                                  "PROCESSING") ||
                              (!this.props.productionMode &&
                                this.props.order?.orderStatus ==
                                  "IN TRANSIT") ||
                              (!this.props.productionMode &&
                                this.props.order?.orderStatus == "RECEIVED") ||
                              (!this.props.productionMode &&
                                this.props.order?.orderStatus == "COMPLETED") ||
                              this.state.statusSubmitting
                            }
                            onClick={() => {
                              this.setOrderStatus("DRAFT");

                              this.dropdownRef.current.close();
                            }}
                          >
                            Mark as Draft
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled={
                              (this.props.productionMode &&
                                this.props.order?.orderStatus == "SUBMITTED") ||
                              (!this.props.productionMode &&
                                this.props.order?.orderStatus != "DRAFT") ||
                              this.props.order?.invoiceStatus == "PAID" ||
                              this.state.statusSubmitting
                            }
                            onClick={() => {
                              this.setOrderStatus("SUBMITTED");

                              this.dropdownRef.current.close();
                            }}
                          >
                            {this.props.productionMode
                              ? "Mark as Submitted"
                              : "Submit Order"}
                          </Dropdown.Item>
                          {this.props.productionMode ? (
                            <>
                              <Dropdown.Item
                                disabled={
                                  this.props.order?.orderStatus == "ACCEPTED" ||
                                  this.props.order?.invoiceStatus == "PAID" ||
                                  this.state.statusSubmitting
                                }
                                onClick={() => {
                                  this.setOrderStatus("ACCEPTED");

                                  this.dropdownRef.current.close();
                                }}
                              >
                                Mark as Accepted
                              </Dropdown.Item>
                              <Dropdown.Item
                                disabled={
                                  this.props.order?.orderStatus ==
                                    "PROCESSING" ||
                                  this.props.order?.invoiceStatus == "PAID" ||
                                  this.state.statusSubmitting
                                }
                                onClick={() => {
                                  this.setOrderStatus("PROCESSING");

                                  this.dropdownRef.current.close();
                                }}
                              >
                                Mark as Processing
                              </Dropdown.Item>
                              <Dropdown.Item
                                disabled={
                                  this.props.order?.orderStatus ==
                                    "IN TRANSIT" ||
                                  this.props.order?.invoiceStatus == "PAID" ||
                                  this.state.statusSubmitting
                                }
                                onClick={() => {
                                  this.setOrderStatus("IN TRANSIT");

                                  this.dropdownRef.current.close();
                                }}
                              >
                                Mark as In Transit
                              </Dropdown.Item>
                              <Dropdown.Item
                                disabled={
                                  (this.props.productionMode &&
                                    this.props.order?.orderStatus ==
                                      "RECEIVED") ||
                                  (!this.props.productionMode &&
                                    this.props.order?.orderStatus !=
                                      "IN TRANSIT") ||
                                  this.props.order?.invoiceStatus == "PAID" ||
                                  this.state.statusSubmitting
                                }
                                onClick={() => {
                                  this.setOrderStatus("RECEIVED");

                                  this.dropdownRef.current.close();
                                }}
                              >
                                Mark as Received
                              </Dropdown.Item>
                            </>
                          ) : null}

                          <Dropdown.Item
                            disabled={
                              (this.props.productionMode &&
                                this.props.order?.orderStatus == "COMPLETED") ||
                              (!this.props.productionMode &&
                                this.props.order?.orderStatus !=
                                  "IN TRANSIT") ||
                              this.props.order?.invoiceStatus == "PAID" ||
                              this.state.statusSubmitting
                            }
                            onClick={() => {
                              swal({
                                title: "Confirm Order Completion",
                                text: `Please confirm that you would like to complete this order.${
                                  !this.props.order?.fulfillmentReportID
                                    ? " Completing this order will automatically update your inventory."
                                    : ""
                                }`,
                                icon: "info",
                                buttons: ["Nevermind", "Confirm"],
                              }).then((conf) => {
                                if (!conf) {
                                  return;
                                }

                                this.setOrderStatus("COMPLETED");
                              });

                              this.dropdownRef.current.close();
                            }}
                          >
                            Mark as Completed
                          </Dropdown.Item>
                          {this.props.productionMode ? (
                            <>
                              <Dropdown.Item divider></Dropdown.Item>
                              <Dropdown.Item
                                panel
                                className="py-2 font-weight-800 text-uppercase"
                                style={{ paddingLeft: 12, paddingRight: 12 }}
                              >
                                <h5 className="m-0">Transfer Status</h5>
                              </Dropdown.Item>
                              <Dropdown.Item
                                disabled={
                                  this.props.order?.invoiceStatus == "UNPAID" ||
                                  this.state.statusSubmitting
                                }
                                onClick={() => {
                                  this.setInvoiceStatus("UNPAID");

                                  this.dropdownRef.current.close();
                                }}
                              >
                                Mark as Unfunded
                              </Dropdown.Item>
                              <Dropdown.Item
                                disabled={
                                  this.props.order?.invoiceStatus == "PAID" ||
                                  this.state.statusSubmitting
                                }
                                onClick={() => {
                                  this.setInvoiceStatus("PAID");

                                  this.dropdownRef.current.close();
                                }}
                              >
                                Mark as Funded
                              </Dropdown.Item>
                            </>
                          ) : null}
                          <Dropdown.Item divider></Dropdown.Item>
                          <Dropdown.Item
                            panel
                            className="py-2 font-weight-800 text-uppercase"
                            style={{ paddingLeft: 12, paddingRight: 12 }}
                          >
                            <h5 className="m-0">Manage Order</h5>
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled={
                              (this.props.order?.orderStatus != "DRAFT" &&
                                !this.props.productionMode) ||
                              this.props.order?.invoiceStatus == "PAID" ||
                              this.state.statusSubmitting
                            }
                            onClick={() => {
                              this.setState({
                                editMode: true,
                              });

                              this.dropdownRef.current.close();
                            }}
                          >
                            Modify Order
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled={
                              this.props.order?.invoiceStatus == "PAID" ||
                              (this.props.order?.orderStatus != "DRAFT" &&
                                this.props.order?.orderStatus != "SUBMITTED" &&
                                !this.props.productionMode) ||
                              this.state.statusSubmitting ||
                              this.state.deleting
                            }
                            onClick={() => {
                              this.deleteOrder();

                              this.dropdownRef.current.close();
                            }}
                            className={`${
                              this.props.order?.invoiceStatus == "PAID" ||
                              (this.props.order?.orderStatus != "DRAFT" &&
                                !this.props.productionMode) ||
                              this.state.statusSubmitting ||
                              this.state.deleting
                                ? "text-light"
                                : "text-danger"
                            }`}
                          >
                            Delete Order
                          </Dropdown.Item>
                          {false ? (
                            <>
                              <Dropdown.Item
                                panel
                                className="py-2 font-weight-800 text-uppercase"
                                style={{ paddingLeft: 12, paddingRight: 12 }}
                              >
                                <h5 className="m-0">Developer</h5>
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  this.setState({ viewObjectDrawer: true });

                                  this.dropdownRef.current.close();
                                }}
                              >
                                View Order Object
                              </Dropdown.Item>
                            </>
                          ) : null}
                        </Dropdown.Menu>
                      </Popover>
                    }
                  >
                    <Button size="sm" outline color="secondary">
                      Actions <i className="mdi mdi-chevron-down pl-2"></i>
                    </Button>
                  </Whisper>
                </>
              ) : null}
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            {this.props.createMode && this.props.productionMode && (
              <>
                <FormGroup>
                  <h5>Store</h5>
                  <SelectPicker
                    loading={this.state.loadingStores}
                    block
                    value={this.state.storeSelector}
                    data={this.state.storeOptions}
                    onChange={(v) => {
                      const store = _.findWhere(this.state.stores, { _id: v });

                      this._selectStore(store);
                    }}
                    placeholder="Select A Store"
                    cleanable={false}
                  ></SelectPicker>
                </FormGroup>
              </>
            )}
            {this.state.store && (
              <>
                <FormGroup>
                  {this.state.editMode || this.props.createMode ? (
                    <>
                      <h4>Order Name</h4>
                      <Input
                        type="text"
                        value={this.state.orderName}
                        name="orderName"
                        placeholder="Name"
                        onChange={this.handleInputChange.bind(this)}
                      ></Input>
                    </>
                  ) : (
                    <>
                      {this.props.order ? (
                        <p className="m-0">#{this.props?.order?._id}</p>
                      ) : null}
                      <h2 className="m-0 text-dark">{this.state.orderName}</h2>
                    </>
                  )}
                </FormGroup>
                {!this.props.createMode &&
                  !this.state.editMode &&
                  this.props.productionMode &&
                  this.props.order?.orderStatus == "SUBMITTED" && (
                    <Card className="border mb-3">
                      <CardHeader className="p-3">
                        <h3 className="m-0 text-dark">Next Step</h3>
                      </CardHeader>
                      <CardBody className="p-3">
                        <p className="text-dark">
                          This order has been submitted by{" "}
                          {this.props.order?.submittedBy?.name
                            ? `${this.props.order?.submittedBy?.name} (${this.props.order?.submittedBy?.email})`
                            : "the store"}{" "}
                          on{" "}
                          {moment(this.props.order?.submittedOn).format(
                            "MM/DD/YYYY hh:mma"
                          )}
                          . Please review the order, make any required changes,
                          and mark the order as accepted.
                        </p>
                        <Button
                          disabled={this.state.statusSubmitting}
                          size=""
                          color="primary"
                          onClick={() => {
                            this.setOrderStatus("ACCEPTED");
                          }}
                        >
                          {this.state.statusSubmitting ? (
                            <Spinner size="sm"></Spinner>
                          ) : (
                            "Accept Order"
                          )}
                        </Button>
                      </CardBody>
                    </Card>
                  )}
                {!this.props.createMode &&
                  !this.state.editMode &&
                  !this.props.productionMode &&
                  this.props.order?.orderStatus == "DRAFT" && (
                    <Card className="border mb-3">
                      <CardHeader className="p-3">
                        <h3 className="m-0 text-dark">Next Step</h3>
                      </CardHeader>
                      <CardBody className="p-3">
                        <p className="text-dark">
                          This order has been automatically drafted. Please
                          review the order, make any necessary changes, and
                          submit the order.
                        </p>
                        <Button
                          disabled={this.state.statusSubmitting}
                          size=""
                          color="primary"
                          onClick={() => {
                            this.setOrderStatus("SUBMITTED");
                          }}
                        >
                          {this.state.statusSubmitting ? (
                            <Spinner size="sm"></Spinner>
                          ) : (
                            "Submit Order"
                          )}
                        </Button>
                      </CardBody>
                    </Card>
                  )}
                {!this.props.createMode &&
                !this.state.editMode &&
                !this.props.productionMode &&
                (this.props.order?.orderStatus == "SUBMITTED" ||
                  this.props.order?.orderStatus == "ACCEPTED" ||
                  this.props.order?.orderStatus == "IN TRANSIT" ||
                  this.props.order?.orderStatus == "PROCESSING" ||
                  this.props.order?.orderStatus == "RECEIVED" ||
                  this.props.order?.orderStatus == "COMPLETED") ? (
                  <>
                    <Alert
                      className={`mb-3 ${
                        this.props.order?.orderStatus == "COMPLETED"
                          ? "border-lighter bg-ultralight text-medium "
                          : "border-primary-lighter text-dark  bg-primary-ultralight"
                      } font-weight-bold`}
                      color=""
                    >
                      You order is currently: {this.props.order?.orderStatus}.
                      At this time, no modifications can be made. For additional
                      information or questions, please add a note or contact:
                      victoriapalmeroni@leanlifemfg.com
                    </Alert>
                  </>
                ) : null}
                {!this.props.createMode &&
                  !this.state.editMode &&
                  !this.props.productionMode &&
                  this.props.order?.orderStatus == "IN TRANSIT" && (
                    <Card className="border mb-3">
                      <CardHeader className="p-3">
                        <h3 className="m-0 text-dark">Next Step</h3>
                      </CardHeader>
                      <CardBody className="p-3">
                        <p className="text-dark">
                          This order is in transit. Upon arrival, click the
                          Review Order button below to complete your order.
                        </p>
                        {this.state.promptReceipt ? (
                          <>
                            <hr className="mb-3 mt-0"></hr>
                            <h3 className="text-dark">
                              Was the order you received accurate &amp;
                              undamaged?
                            </h3>
                            <p className="text-dark">
                              Select "Yes, Accurate" if you received the correct
                              quantities for each of the items in your order and
                              none of the item's cases were damaged.
                            </p>

                            <Button
                              disabled={
                                this.state.statusSubmitting ||
                                this.state.inaccurateSubmitting
                              }
                              size=""
                              color="success"
                              onClick={() => {
                                this.setState({
                                  inaccuratePrompt: false,
                                });

                                swal({
                                  title: "Confirm Order Completion",
                                  text: `Please confirm that you would like to complete this order.${
                                    !this.props.order?.fulfillmentReportID
                                      ? " Completing this order will automatically update your inventory."
                                      : ""
                                  }`,
                                  icon: "info",
                                  buttons: ["Nevermind", "Confirm"],
                                }).then((conf) => {
                                  if (!conf) {
                                    return;
                                  }

                                  this.setOrderStatus("COMPLETED");
                                });
                              }}
                            >
                              {this.state.statusSubmitting ? (
                                <Spinner size="sm"></Spinner>
                              ) : (
                                "Yes, Accurate"
                              )}
                            </Button>
                            <Button
                              disabled={this.state.inaccurateSubmitting}
                              size=""
                              color="danger"
                              onClick={() => {
                                this.setState({
                                  inaccuratePrompt: true,
                                });
                              }}
                            >
                              {this.state.inaccurateSubmitting ? (
                                <Spinner size="sm"></Spinner>
                              ) : (
                                "No, Inaccurate"
                              )}
                            </Button>
                          </>
                        ) : (
                          <Button
                            disabled={this.state.statusSubmitting}
                            size=""
                            color="primary"
                            onClick={() => {
                              this.setState({
                                promptReceipt: true,
                              });
                            }}
                          >
                            {this.state.statusSubmitting ? (
                              <Spinner size="sm"></Spinner>
                            ) : (
                              "Review Order"
                            )}
                          </Button>
                        )}
                        {this.state.inaccuratePrompt ? (
                          <>
                            <hr className="my-3"></hr>
                            <h3 className="text-dark">Share Details Below</h3>
                            <p className="text-dark">
                              Provide a note and any additional attachments
                              below describing what was inaccurage or damaged in
                              your order.
                            </p>
                            <NoteBody
                              productOrder={this.props.order}
                              defaultTitle="Order Inaccuracy/Damage Report"
                              onSubmit={() => {
                                this.setState({
                                  inaccurateSubmitting: true,
                                });
                              }}
                              onSave={(note) => {
                                this.fetchNotes(true);

                                this.setState({
                                  inaccuratePrompt: false,
                                  promptReceipt: false,
                                  inaccurateSubmitting: false,
                                });

                                this.setOrderStatus("RECEIVED");

                                swal({
                                  title: "Inaccuracy/Damage Report Submitted",
                                  text: "Your report has been submitted and you'll be contacted shortly with any additional information or steps!",
                                  icon: "success",
                                });
                              }}
                              forceEdit={true}
                            ></NoteBody>
                          </>
                        ) : null}
                      </CardBody>
                    </Card>
                  )}
                <Table
                  responsive
                  className="table-sm table-bordered border-0 mb-2"
                >
                  <tbody>
                    {this?.props?.order ? (
                      <>
                        <tr>
                          <th className="p-2 align-middle">
                            <h4 className="m-0">Order Status:</h4>
                          </th>
                          <td className="p-2 text-dark">
                            <h4 className="m-0">
                              {this.state.statusSubmitting ? (
                                <Spinner size="sm"></Spinner>
                              ) : (
                                <>
                                  {this.formatStatus(
                                    "orderStatus",
                                    this.props.order
                                  )}
                                </>
                              )}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <th className="p-2 align-middle">
                            <h4 className="m-0">Transfer Status:</h4>
                          </th>
                          <td className="p-2 text-dark">
                            <h4 className="m-0">
                              {this.formatStatus(
                                "invoiceStatus",
                                this.props.order
                              )}
                            </h4>
                          </td>
                        </tr>
                      </>
                    ) : null}
                    <tr>
                      <th className="p-2 align-middle">
                        <h4 className="m-0">Order Date:</h4>
                      </th>
                      <td className="p-2 text-dark">
                        {this.state.orderDate?.format("MM/DD/YYYY")}
                      </td>
                    </tr>
                    <tr>
                      <th className="p-2 align-middle">
                        <h4 className="m-0">Scheduled Delivery Date:</h4>
                      </th>
                      <td className="p-2 text-dark">
                        {this.state.editDeliveryDate ? (
                          <>
                            <Row className="align-items-center">
                              <Col xs="">
                                <DatePicker
                                  value={this.state.modifyDeliveryDate}
                                  onChange={(v) => {
                                    this.setState({
                                      modifyDeliveryDate: v,
                                    });
                                  }}
                                  format="MM/dd/yyyy"
                                  oneTap
                                  placement="auto"
                                  preventOverflow
                                  block
                                  cleanable={true}
                                ></DatePicker>
                              </Col>
                              {this.props.productionMode && (
                                <Col xs="auto" className="pl-0">
                                  <Button
                                    size="sm"
                                    outline
                                    className="btn-icon-only"
                                    disabled={this.state.submittingDelivery}
                                    color="dark"
                                    onClick={() => {
                                      this.setState({
                                        editDeliveryDate: false,
                                      });
                                    }}
                                  >
                                    <i className="mdi mdi-close"></i>
                                  </Button>
                                  <Button
                                    disabled={this.state.submittingDelivery}
                                    size="sm"
                                    className="btn-icon-only ml-0"
                                    color="primary"
                                    onClick={() => {
                                      this.modifyOrderDeliveryDate();
                                    }}
                                  >
                                    {this.state.submittingDelivery ? (
                                      <Spinner size="sm"></Spinner>
                                    ) : (
                                      <i className="mdi mdi-check"></i>
                                    )}
                                  </Button>
                                </Col>
                              )}
                            </Row>
                          </>
                        ) : (
                          <>
                            <Row className="align-items-center">
                              <Col xs="">
                                {this.state.deliveryDate ? (
                                  <>
                                    {moment(
                                      this.state.deliveryDate?.toISOString()
                                    ).format("MM/DD/YYYY")}
                                  </>
                                ) : (
                                  <>--</>
                                )}
                              </Col>
                              {this.props.productionMode && (
                                <Col xs="auto" className="pl-0">
                                  <Button
                                    size="sm"
                                    outline
                                    className="btn-icon-only"
                                    color="secondary"
                                    onClick={() => {
                                      this.setState({
                                        editDeliveryDate: true,
                                        modifyDeliveryDate: this.state
                                          .deliveryDate
                                          ? this.state.deliveryDate
                                          : new Date(),
                                      });
                                    }}
                                  >
                                    <i className="mdi mdi-pencil"></i>
                                  </Button>
                                </Col>
                              )}
                            </Row>
                          </>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th className="p-2">
                        <h4 className="m-0">Bill To:</h4>
                      </th>
                      <td className="p-2 text-dark">
                        <h4 className="m-0 font-weight-normal">
                          {this.state?.store?.name}
                        </h4>
                        {this.state?.store?.location?.address ? (
                          <>
                            <p className="m-0" style={{ lineHeight: 1.3 }}>
                              <small>
                                {
                                  this.state?.store?.location?.address
                                    ?.address_1
                                }
                                {this.state?.store?.location?.address?.address_2
                                  ? ` ${this.state?.store?.location?.address?.address_2}`
                                  : null}
                              </small>
                            </p>

                            <p className="m-0" style={{ lineHeight: 1.3 }}>
                              <small>
                                {this.state?.store?.location?.address?.city},{" "}
                                {this.state?.store?.location?.address?.state}{" "}
                                {this.state?.store?.location?.address?.zip}
                              </small>
                            </p>
                          </>
                        ) : null}

                        <p className="m-0" style={{ lineHeight: 1.3 }}>
                          <small>
                            {this.props?.customer?.phone
                              ? StringUtils.formatPhoneNumber(
                                  this.props?.customer?.phone
                                )
                              : null}
                          </small>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th className="p-2">
                        <h4 className="m-0">Ship To:</h4>
                      </th>
                      <td className="p-2 text-dark">
                        <h4 className="m-0 font-weight-normal">
                          {this.state?.store?.name}
                        </h4>
                        {this.state?.store?.location?.address ? (
                          <>
                            <p className="m-0" style={{ lineHeight: 1.3 }}>
                              <small>
                                {
                                  this.state?.store?.location?.address
                                    ?.address_1
                                }
                                {this.state?.store?.location?.address?.address_2
                                  ? ` ${this.state?.store?.location?.address?.address_2}`
                                  : null}
                              </small>
                            </p>

                            <p className="m-0" style={{ lineHeight: 1.3 }}>
                              <small>
                                {this.state?.store?.location?.address?.city},{" "}
                                {this.state?.store?.location?.address?.state}{" "}
                                {this.state?.store?.location?.address?.zip}
                              </small>
                            </p>
                          </>
                        ) : null}

                        <p className="m-0" style={{ lineHeight: 1.3 }}>
                          <small>
                            {this.props?.customer?.phone
                              ? StringUtils.formatPhoneNumber(
                                  this.props?.customer?.phone
                                )
                              : null}
                          </small>
                        </p>
                      </td>
                    </tr>
                    {this.props.order?.fulfillmentReportID &&
                    this.props.productionMode ? (
                      <>
                        <tr>
                          <th className="p-2 align-middle">
                            <h4 className="m-0">Labels:</h4>
                          </th>
                          <td className="p-2 text-dark">
                            <p className="m-0">
                              <a
                                href={`/storefront-print/${this.props.order?.storeID}/fulfillment/orders/labels?date=${this.props.order?.fulfillmentDateString}`}
                                onClick={(e) => {
                                  e.preventDefault();

                                  LinkUtils.openInNewTab(
                                    `/storefront-print/${this.props.order?.storeID}/fulfillment/orders/labels?date=${this.props.order?.fulfillmentDateString}`,
                                    true
                                  );
                                }}
                                target="_blank"
                              >
                                Print Labels
                              </a>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th className="p-2 align-middle">
                            <h4 className="m-0">Boxing List:</h4>
                          </th>
                          <td className="p-2 text-dark">
                            <p className="m-0">
                              <a
                                href={`/storefront-print/${this.props.order?.storeID}/fulfillment/orders/boxing-list/csv?date=${this.props.order?.fulfillmentDateString}`}
                                onClick={(e) => {
                                  e.preventDefault();

                                  LinkUtils.openInNewTab(
                                    `/storefront-print/${this.props.order?.storeID}/fulfillment/orders/boxing-list/csv?date=${this.props.order?.fulfillmentDateString}`,
                                    true
                                  );
                                }}
                              >
                                Download List
                              </a>
                            </p>
                          </td>
                        </tr>
                      </>
                    ) : null}
                    {this.props.order?.orderStatus != "DRAFT" &&
                      this.props.order?.orderStatus != "SUBMITTED" &&
                      !this.props.createMode && (
                        <>
                          <tr>
                            <th>
                              <h4 className="m-0">Reference #:</h4>
                            </th>
                            <td>
                              <p className="m-0">
                                {this.props?.order?.store?.code}
                                {moment(this.props?.order?.orderDate)
                                  .day(5)
                                  .format("MMDDYY")}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <th className="p-2 align-middle">
                              <h4 className="m-0">Packing Slip:</h4>
                            </th>
                            <td className="p-2 text-dark">
                              <p className="m-0">
                                <a
                                  href={`/storefront-print/product/orders/${this.props.order?._id}/packing-slip`}
                                  onClick={(e) => {
                                    e.preventDefault();

                                    LinkUtils.openInNewTab(
                                      `/storefront-print/product/orders/${this.props.order?._id}/packing-slip`,
                                      true
                                    );
                                  }}
                                  target="_blank"
                                >
                                  Print Packing Slip
                                </a>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <th className="p-2 align-middle">
                              <h4 className="m-0">Pallet Sheet:</h4>
                            </th>
                            <td className="p-2 text-dark">
                              <p className="m-0">
                                <a
                                  href={`/storefront-print/product/orders/${this.props.order?._id}/pallet-sheet`}
                                  onClick={(e) => {
                                    e.preventDefault();

                                    LinkUtils.openInNewTab(
                                      `/storefront-print/product/orders/${this.props.order?._id}/pallet-sheet`,
                                      true
                                    );
                                  }}
                                  target="_blank"
                                >
                                  Print Pallet Sheet
                                </a>
                              </p>
                            </td>
                          </tr>
                          {this.props.productionMode &&
                          !this.props.order?.fulfillmentReportID ? (
                            <tr>
                              <th className="p-2 align-middle">
                                <h4 className="m-0">Order CSV:</h4>
                              </th>
                              <td className="p-2 text-dark">
                                <p className="m-0">
                                  <a
                                    href={`/storefront-print/product/orders/${this.props.order?._id}/csv`}
                                    onClick={(e) => {
                                      e.preventDefault();

                                      LinkUtils.openInNewTab(
                                        `/storefront-print/product/orders/${this.props.order?._id}/csv`,
                                        true
                                      );
                                    }}
                                  >
                                    Download CSV
                                  </a>
                                </p>
                              </td>
                            </tr>
                          ) : null}
                        </>
                      )}
                  </tbody>
                </Table>

                <div>
                  <Button
                    className={`btn-link pl-0 pt-0 ${
                      !this.props.order && "d-none"
                    }`}
                    onClick={() => {
                      this.setState({
                        progress: !this.state.progress,
                      });
                    }}
                    color=""
                  >
                    View Progress <i className="mdi mdi-chevron-down"></i>
                  </Button>
                  <Collapse isOpen={this.state.progress}>
                    <Table
                      responsive
                      className="table-sm table-bordered mt-2 mb-3"
                    >
                      <tbody>
                        {this.props.order?.submittedOn && (
                          <>
                            <tr>
                              <th className="p-2 align-middle">
                                <h4 className="m-0">Submitted:</h4>
                              </th>
                              <td className="p-2 text-dark">
                                {moment(this.props.order?.submittedOn)?.format(
                                  "MM/DD/YYYY hh:mma"
                                )}{" "}
                                by {this.props.order?.submittedBy?.name} (
                                {this.props.order?.submittedBy?.email})
                              </td>
                            </tr>
                          </>
                        )}
                        {this.props.order?.acceptedOn && (
                          <>
                            <tr>
                              <th className="p-2 align-middle">
                                <h4 className="m-0">Accepted:</h4>
                              </th>
                              <td className="p-2 text-dark">
                                {moment(this.props.order?.acceptedOn)?.format(
                                  "MM/DD/YYYY hh:mma"
                                )}{" "}
                                by {this.props.order?.acceptedBy?.name} (
                                {this.props.order?.acceptedBy?.email})
                              </td>
                            </tr>
                          </>
                        )}
                        {this.props.order?.processingOn && (
                          <>
                            <tr>
                              <th className="p-2 align-middle">
                                <h4 className="m-0">Processing:</h4>
                              </th>
                              <td className="p-2 text-dark">
                                {moment(this.props.order?.processingOn)?.format(
                                  "MM/DD/YYYY hh:mma"
                                )}{" "}
                                updated by{" "}
                                {this.props.order?.processingBy?.name} (
                                {this.props.order?.processingBy?.email})
                              </td>
                            </tr>
                          </>
                        )}
                        {this.props.order?.inTransitOn && (
                          <>
                            <tr>
                              <th className="p-2 align-middle">
                                <h4 className="m-0">In Transit:</h4>
                              </th>
                              <td className="p-2 text-dark">
                                {moment(this.props.order?.inTransitOn)?.format(
                                  "MM/DD/YYYY hh:mma"
                                )}{" "}
                                updated by {this.props.order?.inTransitBy?.name}{" "}
                                ({this.props.order?.inTransitBy?.email})
                              </td>
                            </tr>
                          </>
                        )}
                        {this.props.order?.receivedOn && (
                          <>
                            <tr>
                              <th className="p-2 align-middle">
                                <h4 className="m-0">Received:</h4>
                              </th>
                              <td className="p-2 text-dark">
                                {moment(this.props.order?.receivedOn)?.format(
                                  "MM/DD/YYYY hh:mma"
                                )}{" "}
                                by {this.props.order?.receivedBy?.name} (
                                {this.props.order?.receivedBy?.email})
                              </td>
                            </tr>
                          </>
                        )}
                        {this.props.order?.completedOn && (
                          <>
                            <tr>
                              <th className="p-2 align-middle">
                                <h4 className="m-0">Completed:</h4>
                              </th>
                              <td className="p-2 text-dark">
                                {moment(this.props.order?.completedOn)?.format(
                                  "MM/DD/YYYY hh:mma"
                                )}{" "}
                                updated by {this.props.order?.completedBy?.name}{" "}
                                ({this.props.order?.completedBy?.email})
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </Table>
                  </Collapse>
                </div>

                {((this.state?.editMode &&
                  (this.props.productionMode ||
                    this.props.order?.orderStatus == "DRAFT" ||
                    this.props.order?.orderStatus == "SUBMITTED")) ||
                  this.props.createMode) &&
                this.props.order?.status != "IN TRANSIT" &&
                this.props.order?.status != "RECEIVED" &&
                !this.props.order?.fulfillmentReportID ? (
                  <Card className="border shadow-none mt-3">
                    <CardBody className="p-3">
                      <Row className="align-items-center">
                        <Col xs="12" sm="">
                          <h4 className="mb-2 text-dark">
                            Smart Ordering Recommendations
                          </h4>
                          <p
                            className="mb-2 text-dark p-2 rounded border bg-primary-ultralight border-primary-superlight"
                            style={{ lineHeight: 1.4, fontSize: 14 }}
                          >
                            <span className="font-weight-500">
                              Ordering Strategy:{" "}
                            </span>
                            {this.props.createMode ? (
                              <>
                                {this.state.store?.productOrderStrategy
                                  ? this.state.store?.productOrderStrategy
                                  : "Weekly Ordering"}
                              </>
                            ) : (
                              <>
                                {this.props.order?.productOrderStrategy
                                  ? this.props.order?.productOrderStrategy
                                  : "Weekly Ordering"}
                              </>
                            )}
                          </p>
                          <p
                            className="mb-2 small text-dark"
                            style={{ lineHeight: 1.4 }}
                          >
                            Smart ordering recommendations inspect your latest
                            subscription &amp; retail sales trends to project
                            your usage and recommend product order quantities.{" "}
                          </p>

                          <Alert
                            color=""
                            className="p-2 border border-lighter bg-superlight"
                          >
                            <span className="text-dark font-weight-bold">
                              It may take 1-3 minutes to generate your
                              recommendation.
                            </span>
                          </Alert>

                          {this.state.inventory?.createdAt ? (
                            <p
                              className="m-0 small text-dark"
                              style={{ lineHeight: 1.4 }}
                            >
                              Recommendations are based on inventory last
                              reported on:&nbsp;
                              <span className="font-weight-bold">
                                {moment(this.state.inventory.createdAt).format(
                                  "MM/DD/YYYY hh:mm A"
                                )}
                              </span>
                            </p>
                          ) : null}
                          {this.state.recommendation?.calculatedAt ? (
                            <p
                              className="mt-2 mb-0 small text-dark"
                              style={{ lineHeight: 1.4 }}
                            >
                              Recommendation last calculated:&nbsp;
                              <span className="font-weight-bold">
                                {moment(
                                  this.state.recommendation?.calculatedAt
                                ).format("MM/DD/YYYY hh:mm A")}
                              </span>
                            </p>
                          ) : null}
                          {this.state.recommendation?.newStoreEstimate ? (
                            <>
                              <div className="mt-3 p-2 border border-lighter rounded">
                                <h4>New Store Opening Recommendation</h4>
                                <p className="m-0 text-dark">
                                  Based on projection of{" "}
                                  {
                                    this.state.recommendation
                                      ?.estimatedActiveMembers
                                  }{" "}
                                  Active Members with{" "}
                                  {(
                                    this.state.recommendation
                                      ?.estimatedFulfillmentRate * 100
                                  )?.toFixed(1)}
                                  % Fulfillment.
                                </p>
                              </div>
                            </>
                          ) : null}
                          {this.state.recommendationError ? (
                            <p
                              className="mt-2 mb-0 small text-danger"
                              style={{ lineHeight: 1.4 }}
                            >
                              {this.state.recommendationError}
                            </p>
                          ) : null}
                        </Col>
                        <Col xs="12" sm="auto" className="mt-3 mt-sm-0">
                          <Button
                            disabled={
                              !this.state.recommendationAvailable ||
                              this.state.recommendationSubmitting ||
                              this.props.order?.orderStatus == "ACCEPTED" ||
                              this.props.order?.orderStatus == "PROCESSING" ||
                              this.props.order?.orderStatus == "IN TRANSIT" ||
                              this.props.order?.orderStatus == "RECEIVED"
                            }
                            size="sm"
                            outline
                            color={
                              this.props.order?.orderStatus == "ACCEPTED" ||
                              this.props.order?.orderStatus == "PROCESSING" ||
                              this.props.order?.orderStatus == "IN TRANSIT" ||
                              this.props.order?.orderStatus == "RECEIVED" ||
                              !this.state.recommendationAvailable
                                ? "medium"
                                : "primary"
                            }
                            onClick={() => {
                              this._calculateRecommendedQuantity(
                                this.state.store?._id
                              );
                            }}
                          >
                            {this.state.recommendationSubmitting ? (
                              <>
                                <Spinner size="sm"></Spinner>
                              </>
                            ) : (
                              <>
                                {this.props.order?.orderStatus == "ACCEPTED" ||
                                this.props.order?.orderStatus == "PROCESSING" ||
                                this.props.order?.orderStatus == "IN TRANSIT" ||
                                this.props.order?.orderStatus == "RECEIVED" ||
                                !this.state.recommendationAvailable
                                  ? "Not Available"
                                  : "Recalculate"}
                              </>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                ) : null}

                {!this.state.editMode && !this.props.createMode && (
                  <>
                    <div className="mx--2">
                      <CardSelectorHeader
                        compact={true}
                        value={this.state.active}
                        options={[
                          {
                            name: "Summary",
                            id: "Summary",
                          },
                          {
                            name: "Notes",
                            id: "Notes",
                          },
                          {
                            name: "History",
                            id: "History",
                          },
                        ]}
                        onChange={(v) => {
                          this.setState({ active: v });
                        }}
                      ></CardSelectorHeader>
                    </div>
                  </>
                )}

                {this.state.active?.id == "Summary" ||
                this.props.createMode ||
                this.props.editMode ? (
                  <>
                    {/** Lifestyle Meals Start Here */}
                    <div
                      className="bg-white py-3 border-bottom"
                      style={{
                        position: "sticky",
                        top: "calc(0px - 1.5rem)",
                        zIndex: 6,
                      }}
                    >
                      <h3 className="m-0">Lifestyle Meals:</h3>
                    </div>
                    <Table responsive className="table-sm table-bordered">
                      <thead>
                        <td
                          style={{ minWidth: 200 }}
                          className="p-2 bg-superlight text-dark"
                        >
                          Meal
                        </td>
                        <td
                          className="p-2 bg-superlight text-dark"
                          style={{ width: "240px" }}
                        >
                          {this.props.createMode ||
                          !this.props.order?.fulfillmentReportID
                            ? "Cases"
                            : "Quantity"}
                        </td>
                        <td
                          className="p-2 bg-superlight text-dark"
                          style={{ width: "120px" }}
                        >
                          Unit Price
                        </td>
                        <td
                          className="text-right p-2 bg-superlight text-dark"
                          style={{ width: "120px" }}
                        >
                          Subtotal
                        </td>
                      </thead>
                      <tbody>
                        {this.state.lifestyleMeals?.map((meal) => (
                          <tr key={meal?.id}>
                            <>
                              <td
                                className={`p-2 text-dark ${
                                  meal?.retiring && "bg-primary-ultralight"
                                }`}
                                style={{
                                  whiteSpace: "pre-wrap",
                                  verticalAlign: "middle",
                                }}
                              >
                                <Row className="align-items-center">
                                  <Col xs="">
                                    <div>
                                      {meal?.retiring ? (
                                        <>
                                          <Whisper
                                            speaker={
                                              <Tooltip>Retiring Meal</Tooltip>
                                            }
                                            placement="auto"
                                            trigger="hover"
                                            delayOpen={400}
                                          >
                                            <div className="d-inline-block">
                                              <i className="mdi mdi-alert-minus text-warning pr-2"></i>
                                            </div>
                                          </Whisper>
                                        </>
                                      ) : null}
                                      {meal?.upcoming ? (
                                        <>
                                          <Whisper
                                            speaker={
                                              <Tooltip>New Meal</Tooltip>
                                            }
                                            placement="auto"
                                            trigger="hover"
                                            delayOpen={400}
                                          >
                                            <div className="d-inline-block">
                                              <i className="mdi mdi-plus-circle text-success pr-2"></i>
                                            </div>
                                          </Whisper>
                                        </>
                                      ) : null}
                                      {meal?.name}
                                    </div>
                                    <div
                                      className={`text-muted ${
                                        !this.props.createMode &&
                                        !this.state.editMode &&
                                        "d-none"
                                      }`}
                                      style={{ fontSize: 12 }}
                                    >
                                      {this.state?.inventory?.items?.hasOwnProperty(
                                        meal?._id
                                      ) &&
                                      this.state?.inventory?.items[meal?._id]
                                        ? `${StringUtils.numberFormat(
                                            this.state?.inventory?.items[
                                              meal?._id
                                            ]
                                          )} In Stock`
                                        : ""}
                                      {this.state?.incomingInventory?.hasOwnProperty(
                                        meal?._id
                                      ) &&
                                      this.state?.incomingInventory[meal?._id]
                                        .quantity
                                        ? `${
                                            this.state?.inventory?.items?.hasOwnProperty(
                                              meal?._id
                                            ) &&
                                            this.state?.inventory?.items[
                                              meal?._id
                                            ]
                                              ? ", "
                                              : ""
                                          }${StringUtils.numberFormat(
                                            this.state?.incomingInventory[
                                              meal?._id
                                            ].quantity
                                          )} Incoming`
                                        : ""}
                                    </div>
                                  </Col>
                                  {this.props.createMode ||
                                  this.state.editMode ? (
                                    <Col xs="auto" className="pl-0">
                                      <Whisper
                                        trigger={["hover", "click"]}
                                        placement="auto"
                                        preventOverflow
                                        enterable
                                        speaker={
                                          <Popover
                                            className="p-0"
                                            style={{ maxWidth: "100%" }}
                                          >
                                            <div className="p-2 border-bottom">
                                              <h4 className="text-dark m-0">
                                                Order Recommendation
                                              </h4>
                                            </div>
                                            <div className="p-0">
                                              {this.generateRecommendationExplanation(
                                                meal
                                              )}
                                            </div>
                                          </Popover>
                                        }
                                        delayOpen={400}
                                      >
                                        <Button
                                          disabled={
                                            this.state.recommendationSubmitting
                                          }
                                          color="dark"
                                          size="sm"
                                          outline
                                          className="btn-icon-only border-0"
                                        >
                                          <i className="mdi mdi-information-outline"></i>
                                        </Button>
                                      </Whisper>
                                    </Col>
                                  ) : null}
                                </Row>
                              </td>

                              <td
                                className={`p-2 text-dark ${
                                  meal?.retiring && "bg-primary-ultralight"
                                }`}
                                style={{ verticalAlign: "middle" }}
                              >
                                {this.props.createMode ||
                                !this.props.order?.fulfillmentReportID ? (
                                  <InputPicker
                                    data={this.state.lifestyleMealCaseOptions}
                                    value={meal?.caseQuantity}
                                    block
                                    onChange={(v) => {
                                      this.setQuantity(
                                        "lifestyleMeals",
                                        meal,
                                        v
                                      );
                                    }}
                                    disabled={
                                      this.state.recommendationSubmitting
                                    }
                                    placement="auto"
                                    cleanable={false}
                                    plaintext={
                                      !this.state.editMode &&
                                      !this.props.createMode
                                    }
                                  />
                                ) : (
                                  <>
                                    {StringUtils.numberFormat(
                                      meal?.mealQuantity
                                    )}
                                  </>
                                )}
                                {this.state.recommendation?.meals?.hasOwnProperty(
                                  meal?.sku
                                ) &&
                                  meal?.caseQuantity !=
                                    this.state.recommendation.meals[meal?.sku]
                                      ?.caseQuantity &&
                                  (this.props.createMode ||
                                    this.state.editMode ||
                                    this.props.productionMode) &&
                                  !this.props.order?.fulfillmentReportID && (
                                    <>
                                      <Button
                                        size="sm"
                                        className="btn-link pl-0 pb-0"
                                        color=""
                                        onClick={() => {
                                          this.setQuantity(
                                            "lifestyleMeals",
                                            meal,
                                            this.state.recommendation.meals[
                                              meal?.sku
                                            ]?.caseQuantity
                                          );
                                        }}
                                        disabled={
                                          this.state.recommendationSubmitting ||
                                          (this.props.productionMode &&
                                            !this.props.createMode &&
                                            !this.state.editMode)
                                        }
                                      >
                                        Recommended:{" "}
                                        {StringUtils.numberFormat(
                                          this.state.recommendation.meals[
                                            meal?.sku
                                          ]?.caseQuantity
                                        )}{" "}
                                        Case
                                        {this.state.recommendation.meals[
                                          meal?.sku
                                        ]?.caseQuantity != 1
                                          ? "s"
                                          : ""}
                                      </Button>
                                    </>
                                  )}
                              </td>
                              <td
                                className={`p-2 text-dark ${
                                  meal?.retiring && "bg-primary-ultralight"
                                }`}
                                style={{ verticalAlign: "middle" }}
                              >
                                {this.state.pricing?.lifestyleMealUnitPrice
                                  ? StringUtils.centsToCurrency(
                                      this.state.pricing?.lifestyleMealUnitPrice
                                    )
                                  : "--"}
                              </td>
                              <td
                                className={`p-2 text-right text-dark ${
                                  meal?.retiring && "bg-primary-ultralight"
                                }`}
                                style={{ verticalAlign: "middle" }}
                              >
                                {this.calcItemPrice("lifestyleMeals", meal)}
                              </td>
                            </>
                          </tr>
                        ))}
                        <tr>
                          <td className="p-2 text-dark" colspan={4}></td>
                        </tr>
                        {this.props.createMode ||
                          (!this.props.order?.fulfillmentReportID && (
                            <tr>
                              <td
                                colSpan={2}
                                className="p-2 text-dark"
                                style={{
                                  whiteSpace: "pre-wrap",
                                  verticalAlign: "middle",
                                }}
                              ></td>

                              <th
                                className="p-2 font-weight-bold text-dark text-uppercase text-right"
                                style={{
                                  verticalAlign: "middle",
                                  fontSize: "0.65rem",
                                }}
                              >
                                Total Cases:
                              </th>
                              <td
                                className="p-2 text-right"
                                style={{ verticalAlign: "middle" }}
                              >
                                {StringUtils.numberFormat(
                                  this.calculateTotalQuantity("lifestyleMeals")
                                )}
                              </td>
                            </tr>
                          ))}
                        <tr>
                          <td
                            colSpan={2}
                            className="p-2 text-dark"
                            style={{
                              whiteSpace: "pre-wrap",
                              verticalAlign: "middle",
                            }}
                          ></td>

                          <th
                            className="p-2 font-weight-bold text-dark text-uppercase text-right"
                            style={{
                              verticalAlign: "middle",
                              fontSize: "0.65rem",
                            }}
                          >
                            Total Meals:
                          </th>
                          <td
                            className="p-2 text-right"
                            style={{ verticalAlign: "middle" }}
                          >
                            {this.state.pricing
                              ? StringUtils.numberFormat(
                                  this.props.createMode ||
                                    !this.props.order?.fulfillmentReportID
                                    ? this.calculateTotalQuantity(
                                        "lifestyleMeals"
                                      ) *
                                        this.state.pricing
                                          ?.lifestyleMealCaseSize
                                    : this.calculateTotalQuantity(
                                        "lifestyleMeals"
                                      )
                                )
                              : "--"}
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan={2}
                            className="p-2 text-dark"
                            style={{
                              whiteSpace: "pre-wrap",
                              verticalAlign: "middle",
                            }}
                          ></td>

                          <th
                            className="p-2 font-weight-bold text-dark text-uppercase text-right"
                            style={{
                              verticalAlign: "middle",
                              fontSize: "0.85rem",
                            }}
                          >
                            Lifestyle Meal Subtotal:
                          </th>
                          <td
                            className="p-2 text-right font-weight-bold text-dark"
                            style={{
                              verticalAlign: "middle",
                              fontSize: "0.85rem",
                            }}
                          >
                            {this.state.pricing
                              ? StringUtils.centsToCurrency(
                                  this.props.createMode ||
                                    !this.props.order?.fulfillmentReportID
                                    ? this.calculateTotalQuantity(
                                        "lifestyleMeals"
                                      ) *
                                        this.state.pricing
                                          ?.lifestyleMealCaseSize *
                                        this.state.pricing
                                          ?.lifestyleMealUnitPrice
                                    : this.calculateTotalQuantity(
                                        "lifestyleMeals"
                                      ) *
                                        this.state.pricing
                                          ?.lifestyleMealUnitPrice
                                )
                              : "--"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>

                    {/** Athlete Meals Start Here */}
                    <div
                      className="bg-white py-3 border-bottom"
                      style={{
                        position: "sticky",
                        top: "calc(0px - 1.5rem)",
                        zIndex: 6,
                      }}
                    >
                      <h3 className="m-0">Athlete Meals:</h3>
                    </div>
                    <Table responsive className="table-sm table-bordered">
                      <thead>
                        <td
                          style={{ minWidth: 200 }}
                          className="p-2 bg-superlight text-dark"
                        >
                          Meal
                        </td>
                        <td
                          className="p-2 bg-superlight text-dark"
                          style={{ width: "240px" }}
                        >
                          {this.props.createMode ||
                          !this.props.order?.fulfillmentReportID
                            ? "Cases"
                            : "Quantity"}
                        </td>
                        <td
                          className="p-2 bg-superlight text-dark"
                          style={{ width: "120px" }}
                        >
                          Unit Price
                        </td>
                        <td
                          className="text-right p-2 bg-superlight text-dark"
                          style={{ width: "120px" }}
                        >
                          Subtotal
                        </td>
                      </thead>
                      <tbody>
                        {this.state.athleteMeals?.map((meal) => (
                          <tr key={meal?.id}>
                            <>
                              <td
                                className={`p-2 text-dark ${
                                  meal?.retiring && "bg-primary-ultralight"
                                }`}
                                style={{
                                  whiteSpace: "pre-wrap",
                                  verticalAlign: "middle",
                                }}
                              >
                                <Row className="align-items-center">
                                  <Col xs="">
                                    <div>
                                      {meal?.retiring ? (
                                        <>
                                          <Whisper
                                            speaker={
                                              <Tooltip>Retiring Meal</Tooltip>
                                            }
                                            placement="auto"
                                            trigger="hover"
                                            delayOpen={400}
                                          >
                                            <div className="d-inline-block">
                                              <i className="mdi mdi-alert-minus text-warning pr-2"></i>
                                            </div>
                                          </Whisper>
                                        </>
                                      ) : null}
                                      {meal?.upcoming ? (
                                        <>
                                          <Whisper
                                            speaker={
                                              <Tooltip>New Meal</Tooltip>
                                            }
                                            placement="auto"
                                            trigger="hover"
                                            delayOpen={400}
                                          >
                                            <div className="d-inline-block">
                                              <i className="mdi mdi-plus-circle text-success pr-2"></i>
                                            </div>
                                          </Whisper>
                                        </>
                                      ) : null}
                                      {meal?.name}
                                    </div>
                                    <div
                                      className={`text-muted ${
                                        !this.props.createMode &&
                                        !this.state.editMode &&
                                        "d-none"
                                      }`}
                                      style={{ fontSize: 12 }}
                                    >
                                      {this.state?.inventory?.items?.hasOwnProperty(
                                        meal?._id
                                      ) &&
                                      this.state?.inventory?.items[meal?._id]
                                        ? `${StringUtils.numberFormat(
                                            this.state?.inventory?.items[
                                              meal?._id
                                            ]
                                          )} In Stock`
                                        : ""}
                                      {this.state?.incomingInventory?.hasOwnProperty(
                                        meal?._id
                                      ) &&
                                      this.state?.incomingInventory[meal?._id]
                                        .quantity
                                        ? `${
                                            this.state?.inventory?.items?.hasOwnProperty(
                                              meal?._id
                                            ) &&
                                            this.state?.inventory?.items[
                                              meal?._id
                                            ]
                                              ? ", "
                                              : ""
                                          }${StringUtils.numberFormat(
                                            this.state?.incomingInventory[
                                              meal?._id
                                            ].quantity
                                          )} Incoming`
                                        : ""}
                                    </div>
                                  </Col>
                                  {this.props.createMode ||
                                  this.state.editMode ? (
                                    <Col xs="auto" className="pl-0">
                                      <Whisper
                                        trigger={["hover", "click"]}
                                        placement="auto"
                                        preventOverflow
                                        enterable
                                        speaker={
                                          <Popover
                                            className="p-0"
                                            style={{ maxWidth: "100%" }}
                                          >
                                            <div className="p-2 border-bottom">
                                              <h4 className="text-dark m-0">
                                                Order Recommendation
                                              </h4>
                                            </div>
                                            <div className="p-0">
                                              {this.generateRecommendationExplanation(
                                                meal
                                              )}
                                            </div>
                                          </Popover>
                                        }
                                        delayOpen={400}
                                      >
                                        <Button
                                          disabled={
                                            this.state.recommendationSubmitting
                                          }
                                          color="dark"
                                          size="sm"
                                          outline
                                          className="btn-icon-only border-0"
                                        >
                                          <i className="mdi mdi-information-outline"></i>
                                        </Button>
                                      </Whisper>
                                    </Col>
                                  ) : null}
                                </Row>
                              </td>

                              <td
                                className={`p-2 text-dark ${
                                  meal?.retiring && "bg-primary-ultralight"
                                }`}
                                style={{ verticalAlign: "middle" }}
                              >
                                {this.props.createMode ||
                                !this.props.order?.fulfillmentReportID ? (
                                  <>
                                    <InputPicker
                                      data={this.state.athleteMealCaseOptions}
                                      value={meal?.caseQuantity}
                                      block
                                      onChange={(v) => {
                                        this.setQuantity(
                                          "athleteMeals",
                                          meal,
                                          v
                                        );
                                      }}
                                      disabled={
                                        this.state.recommendationSubmitting
                                      }
                                      placement="auto"
                                      cleanable={false}
                                      plaintext={
                                        !this.state.editMode &&
                                        !this.props.createMode
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    {StringUtils.numberFormat(
                                      meal?.mealQuantity
                                    )}
                                  </>
                                )}

                                {this.state.recommendation?.meals?.hasOwnProperty(
                                  meal?.sku
                                ) &&
                                  meal?.caseQuantity !=
                                    this.state.recommendation.meals[meal?.sku]
                                      ?.caseQuantity &&
                                  (this.props.createMode ||
                                    this.state.editMode ||
                                    this.props.productionMode) &&
                                  !this.props.order?.fulfillmentReportID && (
                                    <>
                                      <Button
                                        size="sm"
                                        className="btn-link pl-0 pb-0"
                                        color=""
                                        onClick={() => {
                                          this.setQuantity(
                                            "athleteMeals",
                                            meal,
                                            this.state.recommendation.meals[
                                              meal?.sku
                                            ]?.caseQuantity
                                          );
                                        }}
                                        disabled={
                                          this.state.recommendationSubmitting ||
                                          (this.props.productionMode &&
                                            !this.props.createMode &&
                                            !this.state.editMode)
                                        }
                                      >
                                        Recommended:{" "}
                                        {StringUtils.numberFormat(
                                          this.state.recommendation.meals[
                                            meal?.sku
                                          ]?.caseQuantity
                                        )}{" "}
                                        Case
                                        {this.state.recommendation.meals[
                                          meal?.sku
                                        ]?.caseQuantity != 1
                                          ? "s"
                                          : ""}
                                      </Button>
                                    </>
                                  )}
                              </td>
                              <td
                                className={`p-2 text-dark ${
                                  meal?.retiring && "bg-primary-ultralight"
                                }`}
                                style={{ verticalAlign: "middle" }}
                              >
                                {this.state.pricing?.athleteMealUnitPrice
                                  ? StringUtils.centsToCurrency(
                                      this.state.pricing?.athleteMealUnitPrice
                                    )
                                  : "--"}
                              </td>
                              <td
                                className={`p-2 text-right text-dark ${
                                  meal?.retiring && "bg-primary-ultralight"
                                }`}
                                style={{ verticalAlign: "middle" }}
                              >
                                {this.calcItemPrice("athleteMeals", meal)}
                              </td>
                            </>
                          </tr>
                        ))}
                        <tr>
                          <td className="p-2 text-dark" colspan={4}></td>
                        </tr>
                        {this.props.createMode ||
                        !this.props.order?.fulfillmentReportID ? (
                          <tr>
                            <td
                              colSpan={2}
                              className="p-2 text-dark"
                              style={{
                                whiteSpace: "pre-wrap",
                                verticalAlign: "middle",
                              }}
                            ></td>

                            <th
                              className="p-2 font-weight-bold text-dark text-uppercase text-right"
                              style={{
                                verticalAlign: "middle",
                                fontSize: "0.65rem",
                              }}
                            >
                              Total Cases:
                            </th>
                            <td
                              className="p-2 text-right"
                              style={{ verticalAlign: "middle" }}
                            >
                              {StringUtils.numberFormat(
                                this.calculateTotalQuantity("athleteMeals")
                              )}
                            </td>
                          </tr>
                        ) : null}
                        <tr>
                          <td
                            colSpan={2}
                            className="p-2 text-dark"
                            style={{
                              whiteSpace: "pre-wrap",
                              verticalAlign: "middle",
                            }}
                          ></td>

                          <th
                            className="p-2 font-weight-bold text-dark text-uppercase text-right"
                            style={{
                              verticalAlign: "middle",
                              fontSize: "0.65rem",
                            }}
                          >
                            Total Meals:
                          </th>
                          <td
                            className="p-2 text-right"
                            style={{ verticalAlign: "middle" }}
                          >
                            {this.state.pricing
                              ? StringUtils.numberFormat(
                                  this.props.createMode ||
                                    !this.props.order?.fulfillmentReportID
                                    ? this.calculateTotalQuantity(
                                        "athleteMeals"
                                      ) *
                                        this.state.pricing
                                          ?.lifestyleMealCaseSize
                                    : this.calculateTotalQuantity(
                                        "athleteMeals"
                                      )
                                )
                              : "--"}
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan={2}
                            className="p-2 text-dark"
                            style={{
                              whiteSpace: "pre-wrap",
                              verticalAlign: "middle",
                            }}
                          ></td>

                          <th
                            className="p-2 font-weight-bold text-dark text-uppercase text-right"
                            style={{
                              verticalAlign: "middle",
                              fontSize: "0.85rem",
                            }}
                          >
                            Athlete Meal Subtotal:
                          </th>
                          <td
                            className="p-2 text-right font-weight-bold text-dark"
                            style={{
                              verticalAlign: "middle",
                              fontSize: "0.85rem",
                            }}
                          >
                            {this.state.pricing
                              ? StringUtils.centsToCurrency(
                                  this.props.createMode ||
                                    !this.props.order?.fulfillmentReportID
                                    ? this.calculateTotalQuantity(
                                        "athleteMeals"
                                      ) *
                                        this.state.pricing
                                          ?.athleteMealCaseSize *
                                        this.state.pricing?.athleteMealUnitPrice
                                    : this.calculateTotalQuantity(
                                        "athleteMeals"
                                      ) *
                                        this.state.pricing?.athleteMealUnitPrice
                                )
                              : "--"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>

                    {/** Lean Cheats Start Here */}
                    <div
                      className="bg-white py-3 border-bottom"
                      style={{
                        position: "sticky",
                        top: "calc(0px - 1.5rem)",
                        zIndex: 6,
                      }}
                    >
                      <h3 className="m-0">Lean Cheats:</h3>
                    </div>
                    <Table responsive className="table-sm table-bordered">
                      <thead>
                        <td
                          style={{ minWidth: 200 }}
                          className="p-2 bg-superlight text-dark"
                        >
                          Meal
                        </td>
                        <td
                          className="p-2 bg-superlight text-dark"
                          style={{ width: "240px" }}
                        >
                          {this.props.createMode ||
                          !this.props.order?.fulfillmentReportID
                            ? "Packs"
                            : "Quantity"}
                        </td>
                        <td
                          className="p-2 bg-superlight text-dark"
                          style={{ width: "120px" }}
                        >
                          Unit Price
                        </td>
                        <td
                          className="text-right p-2 bg-superlight text-dark"
                          style={{ width: "120px" }}
                        >
                          Subtotal
                        </td>
                      </thead>
                      <tbody>
                        {this.state.leanCheats?.map((meal) => (
                          <tr key={meal?.id}>
                            <>
                              <td
                                className={`p-2 text-dark ${
                                  meal?.retiring && "bg-primary-ultralight"
                                }`}
                                style={{
                                  whiteSpace: "pre-wrap",
                                  verticalAlign: "middle",
                                }}
                              >
                                <Row className="align-items-center">
                                  <Col xs="">
                                    <div>
                                      {meal?.retiring ? (
                                        <>
                                          <Whisper
                                            speaker={
                                              <Tooltip>Retiring Meal</Tooltip>
                                            }
                                            placement="auto"
                                            trigger="hover"
                                            delayOpen={400}
                                          >
                                            <div className="d-inline-block">
                                              <i className="mdi mdi-alert-minus text-warning pr-2"></i>
                                            </div>
                                          </Whisper>
                                        </>
                                      ) : null}
                                      {meal?.upcoming ? (
                                        <>
                                          <Whisper
                                            speaker={
                                              <Tooltip>New Meal</Tooltip>
                                            }
                                            placement="auto"
                                            trigger="hover"
                                            delayOpen={400}
                                          >
                                            <div className="d-inline-block">
                                              <i className="mdi mdi-plus-circle text-success pr-2"></i>
                                            </div>
                                          </Whisper>
                                        </>
                                      ) : null}
                                      {meal?.name}
                                    </div>
                                    <div
                                      className={`text-muted ${
                                        !this.props.createMode &&
                                        !this.state.editMode &&
                                        "d-none"
                                      }`}
                                      style={{ fontSize: 12 }}
                                    >
                                      {this.state?.inventory?.items?.hasOwnProperty(
                                        meal?._id
                                      ) &&
                                      this.state?.inventory?.items[meal?._id]
                                        ? `${StringUtils.numberFormat(
                                            this.state?.inventory?.items[
                                              meal?._id
                                            ]
                                          )} In Stock`
                                        : ""}
                                      {this.state?.incomingInventory?.hasOwnProperty(
                                        meal?._id
                                      ) &&
                                      this.state?.incomingInventory[meal?._id]
                                        .quantity
                                        ? `${
                                            this.state?.inventory?.items?.hasOwnProperty(
                                              meal?._id
                                            ) &&
                                            this.state?.inventory?.items[
                                              meal?._id
                                            ]
                                              ? ", "
                                              : ""
                                          }${StringUtils.numberFormat(
                                            this.state?.incomingInventory[
                                              meal?._id
                                            ].quantity
                                          )} Incoming`
                                        : ""}
                                    </div>
                                  </Col>
                                  {this.props.createMode ||
                                  this.state.editMode ? (
                                    <Col xs="auto" className="pl-0">
                                      <Whisper
                                        trigger={["hover", "click"]}
                                        placement="auto"
                                        preventOverflow
                                        enterable
                                        speaker={
                                          <Popover
                                            className="p-0"
                                            style={{ maxWidth: "100%" }}
                                          >
                                            <div className="p-2 border-bottom">
                                              <h4 className="text-dark m-0">
                                                Order Recommendation
                                              </h4>
                                            </div>
                                            <div className="p-0">
                                              {this.generateRecommendationExplanation(
                                                meal
                                              )}
                                            </div>
                                          </Popover>
                                        }
                                        delayOpen={400}
                                      >
                                        <Button
                                          disabled={
                                            this.state.recommendationSubmitting
                                          }
                                          color="dark"
                                          size="sm"
                                          outline
                                          className="btn-icon-only border-0"
                                        >
                                          <i className="mdi mdi-information-outline"></i>
                                        </Button>
                                      </Whisper>
                                    </Col>
                                  ) : null}
                                </Row>
                              </td>

                              <td
                                className={`p-2 text-dark ${
                                  meal?.retiring && "bg-primary-ultralight"
                                }`}
                                style={{ verticalAlign: "middle" }}
                              >
                                {this.props.createMode ||
                                !this.props.order?.fulfillmentReportID ? (
                                  <InputPicker
                                    data={this.state.leanCheatCaseOptions}
                                    value={meal?.caseQuantity}
                                    block
                                    onChange={(v) => {
                                      this.setQuantity("leanCheats", meal, v);
                                    }}
                                    disabled={
                                      this.state.recommendationSubmitting
                                    }
                                    placement="auto"
                                    cleanable={false}
                                    plaintext={
                                      !this.state.editMode &&
                                      !this.props.createMode
                                    }
                                  />
                                ) : (
                                  <>
                                    {StringUtils.numberFormat(
                                      meal?.mealQuantity
                                    )}
                                  </>
                                )}
                                {this.state.recommendation?.meals?.hasOwnProperty(
                                  meal?.sku
                                ) &&
                                  meal?.caseQuantity !=
                                    this.state.recommendation.meals[meal?.sku]
                                      ?.caseQuantity &&
                                  (this.props.createMode ||
                                    this.state.editMode ||
                                    this.props.productionMode) &&
                                  !this.props.order?.fulfillmentReportID && (
                                    <>
                                      <Button
                                        size="sm"
                                        className="btn-link pl-0 pb-0"
                                        color=""
                                        onClick={() => {
                                          this.setQuantity(
                                            "leanCheats",
                                            meal,
                                            this.state.recommendation.meals[
                                              meal?.sku
                                            ]?.caseQuantity
                                          );
                                        }}
                                        disabled={
                                          this.state.recommendationSubmitting ||
                                          (this.props.productionMode &&
                                            !this.props.createMode &&
                                            !this.state.editMode)
                                        }
                                      >
                                        Recommended:{" "}
                                        {StringUtils.numberFormat(
                                          this.state.recommendation.meals[
                                            meal?.sku
                                          ]?.caseQuantity *
                                            this.state.pricing
                                              ?.leanCheatOrderMultiple
                                        )}{" "}
                                        Packs
                                      </Button>
                                    </>
                                  )}
                              </td>
                              <td
                                className={`p-2 text-dark ${
                                  meal?.retiring && "bg-primary-ultralight"
                                }`}
                                style={{ verticalAlign: "middle" }}
                              >
                                {this.state.pricing?.leanCheatUnitPrice
                                  ? StringUtils.centsToCurrency(
                                      this.state.pricing?.leanCheatUnitPrice
                                    )
                                  : "--"}
                              </td>
                              <td
                                className={`p-2 text-right text-dark ${
                                  meal?.retiring && "bg-primary-ultralight"
                                }`}
                                style={{ verticalAlign: "middle" }}
                              >
                                {this.calcItemPrice("leanCheats", meal)}
                              </td>
                            </>
                          </tr>
                        ))}
                        <tr>
                          <td className="p-2 text-dark" colspan={4}></td>
                        </tr>

                        <tr>
                          <td
                            colSpan={2}
                            className="p-2 text-dark"
                            style={{
                              whiteSpace: "pre-wrap",
                              verticalAlign: "middle",
                            }}
                          ></td>

                          <th
                            className="p-2 font-weight-bold text-dark text-uppercase text-right"
                            style={{
                              verticalAlign: "middle",
                              fontSize: "0.65rem",
                            }}
                          >
                            Total Packs:
                          </th>
                          <td
                            className="p-2 text-right"
                            style={{ verticalAlign: "middle" }}
                          >
                            {this.state.pricing
                              ? StringUtils.numberFormat(
                                  this.props.createMode ||
                                    !this.props.order?.fulfillmentReportID
                                    ? this.calculateTotalQuantity(
                                        "leanCheats"
                                      ) *
                                        this.state.pricing
                                          ?.leanCheatOrderMultiple
                                    : this.calculateTotalQuantity("leanCheats")
                                )
                              : "--"}
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan={2}
                            className="p-2 text-dark"
                            style={{
                              whiteSpace: "pre-wrap",
                              verticalAlign: "middle",
                            }}
                          ></td>

                          <th
                            className="p-2 font-weight-bold text-dark text-uppercase text-right"
                            style={{
                              verticalAlign: "middle",
                              fontSize: "0.85rem",
                            }}
                          >
                            Lean Cheat Subtotal:
                          </th>
                          <td
                            className="p-2 text-right font-weight-bold text-dark"
                            style={{
                              verticalAlign: "middle",
                              fontSize: "0.85rem",
                            }}
                          >
                            {this.state.pricing
                              ? StringUtils.centsToCurrency(
                                  this.props.createMode ||
                                    !this.props.order?.fulfillmentReportID
                                    ? this.calculateTotalQuantity(
                                        "leanCheats"
                                      ) *
                                        this.state.pricing
                                          ?.leanCheatOrderMultiple *
                                        this.state.pricing?.leanCheatUnitPrice
                                    : this.calculateTotalQuantity(
                                        "leanCheats"
                                      ) * this.state.pricing?.leanCheatUnitPrice
                                )
                              : "--"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>

                    {/** Start Corrugated Boxes */}
                    <div
                      className="bg-white py-3 border-bottom"
                      style={{
                        position: "sticky",
                        top: "calc(0px - 1.5rem)",
                        zIndex: 6,
                      }}
                    >
                      <h3 className="m-0">Corrugated Boxes:</h3>
                    </div>
                    <Table responsive className="table-sm table-bordered">
                      <thead>
                        <td
                          style={{ minWidth: 200 }}
                          className="p-2 bg-superlight text-dark"
                        >
                          Type
                        </td>
                        <td
                          className="p-2 bg-superlight text-dark"
                          style={{ width: "240px" }}
                        >
                          {this.props.createMode ||
                          !this.props.order?.fulfillmentReportID
                            ? "Bundles"
                            : "Quantity"}
                        </td>
                        <td
                          className="p-2 bg-superlight text-dark"
                          style={{ width: "120px" }}
                        >
                          Unit Price
                        </td>
                        <td
                          className="text-right p-2 bg-superlight text-dark"
                          style={{ width: "120px" }}
                        >
                          Subtotal
                        </td>
                      </thead>
                      <tbody>
                        {this.state.boxes?.map((meal) => (
                          <tr key={meal?.id}>
                            <>
                              <td
                                className="p-2 text-dark"
                                style={{
                                  whiteSpace: "pre-wrap",
                                  verticalAlign: "middle",
                                }}
                              >
                                {meal?.name}
                              </td>

                              <td
                                className="p-2 text-dark"
                                style={{ verticalAlign: "middle" }}
                              >
                                {this.props.createMode ||
                                !this.props.order?.fulfillmentReportID ? (
                                  <InputPicker
                                    data={
                                      meal?.name?.includes("Insert")
                                        ? this.state.boxInsertOptions
                                        : this.state.boxBundleOptions
                                    }
                                    value={meal?.caseQuantity}
                                    block
                                    onChange={(v) => {
                                      this.setQuantity("boxes", meal, v);
                                    }}
                                    disabled={
                                      this.state.recommendationSubmitting
                                    }
                                    placement="auto"
                                    cleanable={false}
                                    plaintext={
                                      !this.state.editMode &&
                                      !this.props.createMode
                                    }
                                  />
                                ) : (
                                  <>
                                    {StringUtils.numberFormat(
                                      meal?.boxQuantity
                                    )}
                                  </>
                                )}
                              </td>
                              <td
                                className="p-2 text-dark"
                                style={{ verticalAlign: "middle" }}
                              >
                                {meal?.unitPrice
                                  ? StringUtils.centsToCurrency(meal?.unitPrice)
                                  : "--"}
                              </td>
                              <td
                                className="p-2 text-right text-dark"
                                style={{ verticalAlign: "middle" }}
                              >
                                {this.calcItemPrice("boxes", meal)}
                              </td>
                            </>
                          </tr>
                        ))}
                        <tr>
                          <td className="p-2 text-dark" colspan={4}></td>
                        </tr>
                        <tr>
                          <>
                            <td
                              className="p-2 text-dark"
                              style={{
                                whiteSpace: "pre-wrap",
                                verticalAlign: "middle",
                              }}
                            >
                              Boxes For Cases
                            </td>

                            <td
                              className="p-2 text-dark"
                              style={{ verticalAlign: "middle" }}
                            >
                              {this.props.createMode ||
                              !this.props.order?.fulfillmentReportID
                                ? StringUtils.numberFormat(
                                    this.calculateTotalQuantity(
                                      "lifestyleMeals"
                                    ) +
                                      this.calculateTotalQuantity(
                                        "athleteMeals"
                                      )
                                  )
                                : "0"}
                            </td>
                            <td
                              className="p-2 text-dark"
                              style={{ verticalAlign: "middle" }}
                            >
                              {this.state?.pricing?.boxMealCaseUnitPrice
                                ? StringUtils.centsToCurrency(
                                    this.state?.pricing?.boxMealCaseUnitPrice
                                  )
                                : "--"}
                            </td>
                            <td
                              className="p-2 text-right"
                              style={{ verticalAlign: "middle" }}
                            >
                              {StringUtils.centsToCurrency(
                                this.props.createMode ||
                                  !this.props.order?.fulfillmentReportID
                                  ? (this.calculateTotalQuantity(
                                      "lifestyleMeals"
                                    ) +
                                      this.calculateTotalQuantity(
                                        "athleteMeals"
                                      )) *
                                      this.state.pricing?.boxMealCaseUnitPrice
                                  : 0
                              )}
                            </td>
                          </>
                        </tr>
                        <tr>
                          <>
                            <td
                              className="p-2 text-dark"
                              style={{
                                whiteSpace: "pre-wrap",
                                verticalAlign: "middle",
                              }}
                            >
                              Boxes For Lean Cheats
                            </td>

                            <td
                              className="p-2 text-dark"
                              style={{ verticalAlign: "middle" }}
                            >
                              {this.props.createMode ||
                              !this.props.order?.fulfillmentReportID
                                ? StringUtils.numberFormat(
                                    Math.ceil(
                                      (this.calculateTotalQuantity(
                                        "leanCheats"
                                      ) *
                                        this.state.pricing
                                          ?.leanCheatOrderMultiple) /
                                        this.state.pricing?.leanCheatCaseSize
                                    )
                                  )
                                : "0"}
                            </td>
                            <td
                              className="p-2 text-dark"
                              style={{ verticalAlign: "middle" }}
                            >
                              {this.state?.pricing?.boxLeanCheatUnitPrice
                                ? StringUtils.centsToCurrency(
                                    this.state?.pricing?.boxLeanCheatUnitPrice
                                  )
                                : "--"}
                            </td>
                            <td
                              className="p-2 text-right"
                              style={{ verticalAlign: "middle" }}
                            >
                              {StringUtils.centsToCurrency(
                                this.props.createMode ||
                                  !this.props.order?.fulfillmentReportID
                                  ? Math.ceil(
                                      (this.calculateTotalQuantity(
                                        "leanCheats"
                                      ) *
                                        this.state.pricing
                                          ?.leanCheatOrderMultiple) /
                                        this.state.pricing?.leanCheatCaseSize
                                    ) *
                                      this.state.pricing?.boxLeanCheatUnitPrice
                                  : 0
                              )}
                            </td>
                          </>
                        </tr>
                        <tr>
                          <>
                            <td
                              className="p-2 text-dark"
                              style={{
                                whiteSpace: "pre-wrap",
                                verticalAlign: "middle",
                              }}
                            >
                              Case Inserts
                            </td>

                            <td
                              className="p-2 text-dark"
                              style={{ verticalAlign: "middle" }}
                            >
                              {this.props.createMode ||
                              !this.props.order?.fulfillmentReportID
                                ? StringUtils.numberFormat(0)
                                : StringUtils.numberFormat(
                                    this.props.order?.boxes?.hasOwnProperty(
                                      "Case Inserts"
                                    ) &&
                                      this.props.order?.boxes["Case Inserts"]
                                        ?.boxQuantity
                                      ? this.props.order?.boxes["Case Inserts"]
                                          ?.boxQuantity
                                      : 0
                                  )}
                            </td>
                            <td
                              className="p-2 text-dark"
                              style={{ verticalAlign: "middle" }}
                            >
                              {this.state?.pricing?.boxInsertUnitPrice
                                ? StringUtils.centsToCurrency(
                                    this.state?.pricing?.boxInsertUnitPrice
                                  )
                                : "--"}
                            </td>
                            <td
                              className="p-2 text-right"
                              style={{ verticalAlign: "middle" }}
                            >
                              {StringUtils.centsToCurrency(
                                this.props.createMode ||
                                  !this.props.order?.fulfillmentReportID
                                  ? Math.ceil(
                                      (this.calculateTotalQuantity(
                                        "leanCheats"
                                      ) *
                                        this.state.pricing
                                          ?.leanCheatOrderMultiple) /
                                        this.state.pricing?.leanCheatCaseSize
                                    ) * this.state.pricing?.boxInsertUnitPrice
                                  : (this.props.order?.boxes?.hasOwnProperty(
                                      "Case Inserts"
                                    ) &&
                                    this.props.order?.boxes["Case Inserts"]
                                      ?.boxQuantity
                                      ? this.props.order?.boxes["Case Inserts"]
                                          ?.boxQuantity
                                      : 0) *
                                      this.state.pricing?.boxInsertUnitPrice
                              )}
                            </td>
                          </>
                        </tr>
                        <tr>
                          <td className="p-2 text-dark" colspan={4}></td>
                        </tr>
                        <tr>
                          <td
                            colSpan={2}
                            className="p-2 text-dark"
                            style={{
                              whiteSpace: "pre-wrap",
                              verticalAlign: "middle",
                            }}
                          ></td>

                          <th
                            className="p-2 font-weight-bold text-dark text-uppercase text-right"
                            style={{
                              verticalAlign: "middle",
                              fontSize: "0.85rem",
                            }}
                          >
                            Corrugated Boxes Subtotal:
                          </th>
                          <td
                            className="p-2 text-right font-weight-bold text-dark"
                            style={{
                              verticalAlign: "middle",
                              fontSize: "0.85rem",
                            }}
                          >
                            {/** TODO: Calculate box total here */}
                            {this.state.pricing
                              ? StringUtils.centsToCurrency(
                                  this.calculateBoxSubtotal()
                                )
                              : "--"}
                          </td>
                        </tr>
                      </tbody>

                      {/** Start Labor Section If Applicable */}

                      {/** Start Freight/Logistics Section */}

                      {/** Start Order Summary Section */}
                    </Table>

                    {this.state.store?.fulfillmentStrategy ==
                    Constant.FULFILLMENT_STRATEGIES.IN_STORE ? (
                      <>
                        <div
                          className="bg-white py-3 border-bottom"
                          style={{
                            position: "sticky",
                            top: "calc(0px - 1.5rem)",
                            zIndex: 6,
                          }}
                        >
                          <h3 className="m-0">Logistics:</h3>
                        </div>
                        <Table responsive className="table-sm table-bordered">
                          <thead>
                            <td
                              className="p-2 bg-superlight text-dark"
                              style={{ minWidth: 200 }}
                            >
                              Pallet
                            </td>
                            <td
                              className="p-2 bg-superlight text-dark"
                              style={{ width: "120px" }}
                            >
                              % Utilized
                            </td>
                            <td
                              className="p-2 bg-superlight text-dark"
                              style={{ width: "120px" }}
                            >
                              Unit Price
                            </td>
                            <td
                              className="p-2 bg-superlight text-dark"
                              style={{ width: "120px" }}
                            >
                              Est. Price / Meal
                            </td>
                            <td
                              className="text-right p-2 bg-superlight text-dark"
                              style={{ width: "120px" }}
                            >
                              Subtotal
                            </td>
                          </thead>
                          <tbody>
                            {this._calculatePallets().map((pallet, i) => (
                              <tr key={i}>
                                <td
                                  className="p-2 text-dark"
                                  style={{
                                    whiteSpace: "pre-wrap",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  Pallet {i + 1}
                                </td>

                                <td
                                  className={`font-weight-bold p-2 ${
                                    pallet?.percentUtilized * 100 >= 75
                                      ? "bg-success text-white"
                                      : ""
                                  } ${
                                    pallet?.percentUtilized * 100 < 60
                                      ? "bg-danger text-white"
                                      : ""
                                  } ${
                                    pallet?.percentUtilized * 100 >= 60 &&
                                    pallet?.percentUtilized * 100 < 75
                                      ? "bg-warning text-white"
                                      : ""
                                  }`}
                                  style={{ verticalAlign: "middle" }}
                                >
                                  {(pallet?.percentUtilized * 100).toFixed(2)}%
                                </td>
                                <td
                                  className="p-2 text-dark"
                                  style={{ verticalAlign: "middle" }}
                                >
                                  {StringUtils.centsToCurrency(
                                    pallet?.unitPrice
                                  )}
                                </td>
                                <td
                                  className="p-2 text-dark"
                                  style={{ verticalAlign: "middle" }}
                                >
                                  {StringUtils.centsToCurrency(
                                    pallet?.unitPrice /
                                      Math.round(1200 * pallet?.percentUtilized)
                                  )}{" "}
                                  / Meal
                                </td>
                                <td
                                  className="p-2 text-right"
                                  style={{ verticalAlign: "middle" }}
                                >
                                  {StringUtils.centsToCurrency(
                                    pallet?.unitPrice
                                  )}
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td className="p-2 text-dark" colspan={4}></td>
                            </tr>
                            <tr>
                              <td
                                colSpan={2}
                                className="p-2"
                                style={{
                                  whiteSpace: "pre-wrap",
                                  verticalAlign: "middle",
                                }}
                              ></td>

                              <th
                                className="p-2 font-weight-bold text-dark text-uppercase text-right"
                                style={{
                                  verticalAlign: "middle",
                                  fontSize: "0.85rem",
                                }}
                              >
                                Logistics Subtotal:
                              </th>
                              <td
                                className="p-2 font-weight-bold text-dark text-right"
                                style={{
                                  verticalAlign: "middle",
                                  fontSize: "0.85rem",
                                }}
                              >
                                {/** TODO: Calculate box total here */}
                                {this.state.pricing
                                  ? StringUtils.centsToCurrency(
                                      this._totalPalletPrice(
                                        this._calculatePallets()
                                      )
                                    )
                                  : "--"}
                              </td>
                            </tr>
                          </tbody>

                          {/** Start Labor Section If Applicable */}

                          {/** Start Freight/Logistics Section */}

                          {/** Start Order Summary Section */}
                        </Table>
                      </>
                    ) : null}
                    {this.props.productionMode ||
                    (this.props.order?.additionalLineItems?.length &&
                      !this.props.createMode &&
                      !this.state.editMode) ? (
                      <>
                        <div
                          className="bg-white py-3 border-bottom"
                          style={{
                            position: "sticky",
                            top: "calc(0px - 1.5rem)",
                            zIndex: 6,
                          }}
                        >
                          <h3 className="m-0">Additional Items:</h3>
                        </div>
                        <Table responsive className="table-sm table-bordered">
                          <thead>
                            <td
                              className="p-2 bg-superlight text-dark"
                              style={{ minWidth: 200 }}
                            >
                              Item
                            </td>
                            <td
                              className="p-2 bg-superlight text-dark"
                              style={{ width: "120px" }}
                            >
                              Unit Price
                            </td>
                            <td
                              className="p-2 bg-superlight text-dark"
                              style={{ width: "120px" }}
                            >
                              Quantity
                            </td>
                            <td
                              className="text-right p-2 bg-superlight text-dark"
                              style={{ width: "120px" }}
                            >
                              Subtotal
                            </td>
                            {this.props.productionMode &&
                              (this.state.editMode ||
                                this.props.createMode) && (
                                <td
                                  className="text-right p-2 bg-superlight text-dark"
                                  style={{ width: "100px" }}
                                ></td>
                              )}
                          </thead>
                          <tbody>
                            {this.state?.additionalLineItems.map((item, i) => (
                              <tr key={i}>
                                <td className="p-2">
                                  {!this.props.productionMode ||
                                  (!this.state.editMode &&
                                    !this.props.createMode) ? (
                                    <>{item?.name}</>
                                  ) : (
                                    <>
                                      <InputPicker
                                        block
                                        groupBy="group"
                                        creatable
                                        value={item?.name}
                                        data={this.state.lineItemOptions}
                                        type="text"
                                        onChange={(v) => {
                                          this.setItemValue("name", item, v);
                                        }}
                                        placeholder="Item Name"
                                      ></InputPicker>
                                    </>
                                  )}
                                </td>

                                <td
                                  className={`p-2`}
                                  style={{ verticalAlign: "middle" }}
                                >
                                  {!this.props.productionMode ||
                                  (!this.state.editMode &&
                                    !this.props.createMode) ? (
                                    <>
                                      {item?.unitPrice?.value && item?.quantity
                                        ? StringUtils.centsToCurrency(
                                            parseFloat(item?.unitPrice?.value) *
                                              100
                                          )
                                        : StringUtils.centsToCurrency(0)}
                                    </>
                                  ) : (
                                    <>
                                      <NumberFormat
                                        className="form-control form-control-sm"
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        required={false}
                                        placeholder="$0.00"
                                        value={item?.unitPrice?.value}
                                        onValueChange={(v) => {
                                          this.setItemValue(
                                            "unitPrice",
                                            item,
                                            v
                                          );
                                        }}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                      />
                                    </>
                                  )}
                                </td>
                                <td
                                  className="p-2 text-dark"
                                  style={{ verticalAlign: "middle" }}
                                >
                                  <RInput
                                    className="form-control form-control-sm"
                                    block
                                    plaintext={
                                      !this.props.productionMode ||
                                      (!this.state.editMode &&
                                        !this.props.createMode)
                                    }
                                    type="number"
                                    placeholder="Qty"
                                    value={item?.quantity}
                                    onChange={(v) => {
                                      this.setItemValue("quantity", item, v);
                                    }}
                                  ></RInput>
                                </td>
                                <td
                                  className="p-2 text-right"
                                  style={{ verticalAlign: "middle" }}
                                >
                                  {item?.unitPrice?.value && item?.quantity
                                    ? StringUtils.centsToCurrency(
                                        parseFloat(item?.unitPrice?.value) *
                                          100 *
                                          item?.quantity
                                      )
                                    : StringUtils.centsToCurrency(0)}
                                </td>

                                {this.props.productionMode &&
                                (this.state.editMode ||
                                  this.props.createMode) ? (
                                  <>
                                    <td className="text-right">
                                      <Button
                                        className="btn-icon-only"
                                        outline
                                        color="danger"
                                        size="sm"
                                        onClick={() => {
                                          this._removeAdditionalLineItem(
                                            item?._id
                                          );
                                        }}
                                      >
                                        <i className="mdi mdi-close"></i>
                                      </Button>
                                    </td>
                                  </>
                                ) : null}
                              </tr>
                            ))}
                            {this.props.productionMode &&
                            (this.state.editMode || this.props.createMode) ? (
                              <>
                                <tr>
                                  <td
                                    className="p-2 text-right"
                                    colspan={
                                      this.props.productionMode &&
                                      (this.state.editMode ||
                                        this.props.createMode)
                                        ? 5
                                        : 4
                                    }
                                  >
                                    <Button
                                      size="sm"
                                      outline
                                      color="primary"
                                      className="btn-icon-only"
                                      onClick={() => {
                                        this._addAdditionalLineItem();
                                      }}
                                    >
                                      <i className="mdi mdi-plus"></i>
                                    </Button>
                                  </td>
                                </tr>
                              </>
                            ) : null}

                            <tr>
                              <td className="p-2 text-dark" colspan={5}></td>
                            </tr>
                            <tr>
                              <td
                                colSpan={2}
                                className="p-2"
                                style={{
                                  whiteSpace: "pre-wrap",
                                  verticalAlign: "middle",
                                }}
                              ></td>

                              <th
                                className="p-2 font-weight-bold text-dark text-uppercase text-right"
                                style={{
                                  verticalAlign: "middle",
                                  fontSize: "0.85rem",
                                }}
                              >
                                Additional Items Subtotal:
                              </th>
                              <td
                                className="p-2 font-weight-bold text-dark text-right"
                                style={{
                                  verticalAlign: "middle",
                                  fontSize: "0.85rem",
                                }}
                              >
                                {/** TODO: Calculate box total here */}
                                {this.state.pricing
                                  ? StringUtils.centsToCurrency(
                                      this.getAdditionalItemSubtotal()
                                    )
                                  : "--"}
                              </td>
                              {this.props.productionMode &&
                                (this.props.createMode ||
                                  this.state.editMode) && <td></td>}
                            </tr>
                          </tbody>

                          {/** Start Labor Section If Applicable */}

                          {/** Start Freight/Logistics Section */}

                          {/** Start Order Summary Section */}
                        </Table>
                      </>
                    ) : null}
                    <div
                      className="bg-white py-3 border-bottom"
                      style={{
                        position: "sticky",
                        top: "calc(0px - 1.5rem)",
                        zIndex: 6,
                      }}
                    >
                      <h3 className="m-0">Order Summary:</h3>
                    </div>
                    <Table responsive className="table-sm table-bordered">
                      <thead>
                        <td className="p-2 bg-superlight text-dark">Item</td>

                        <td
                          className="text-right p-2 bg-superlight text-dark"
                          style={{ width: "120px" }}
                        >
                          Subtotal
                        </td>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="p-2 text-dark">
                            Lifestyle Meals (
                            {this.props.createMode ||
                            !this.props.order?.fulfillmentReportID ? (
                              <>
                                {StringUtils.numberFormat(
                                  this.calculateTotalQuantity("lifestyleMeals")
                                )}{" "}
                                Cases -{" "}
                              </>
                            ) : null}
                            {StringUtils.numberFormat(
                              this.props.createMode ||
                                !this.props.order?.fulfillmentReportID
                                ? this.calculateTotalQuantity(
                                    "lifestyleMeals"
                                  ) * this.state.pricing?.lifestyleMealCaseSize
                                : this.calculateTotalQuantity("lifestyleMeals")
                            )}{" "}
                            Meals)
                          </td>
                          <td
                            className="text-right p-2"
                            style={{ width: "120px" }}
                          >
                            {StringUtils.centsToCurrency(
                              this.props.createMode ||
                                !this.props.order?.fulfillmentReportID
                                ? this.calculateTotalQuantity(
                                    "lifestyleMeals"
                                  ) *
                                    this.state.pricing?.lifestyleMealCaseSize *
                                    this.state.pricing?.lifestyleMealUnitPrice
                                : this.calculateTotalQuantity(
                                    "lifestyleMeals"
                                  ) * this.state.pricing?.lifestyleMealUnitPrice
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="p-2 text-dark">
                            Athlete Meals (
                            {this.props.createMode ||
                            !this.props.order?.fulfillmentReportID ? (
                              <>
                                {StringUtils.numberFormat(
                                  this.calculateTotalQuantity("athleteMeals")
                                )}{" "}
                                Cases -{" "}
                              </>
                            ) : null}
                            {StringUtils.numberFormat(
                              this.props.createMode ||
                                !this.props.order?.fulfillmentReportID
                                ? this.calculateTotalQuantity("athleteMeals") *
                                    this.state.pricing?.athleteMealCaseSize
                                : this.calculateTotalQuantity("athleteMeals")
                            )}{" "}
                            Meals)
                          </td>
                          <td
                            className="text-right p-2"
                            style={{ width: "120px" }}
                          >
                            {StringUtils.centsToCurrency(
                              this.props.createMode ||
                                !this.props.order?.fulfillmentReportID
                                ? this.calculateTotalQuantity("athleteMeals") *
                                    this.state.pricing?.athleteMealCaseSize *
                                    this.state.pricing?.athleteMealUnitPrice
                                : this.calculateTotalQuantity("athleteMeals") *
                                    this.state.pricing?.athleteMealUnitPrice
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="p-2 text-dark">
                            Lean Cheats (
                            {this.props.createMode ||
                            !this.props.order?.fulfillmentReportID ? (
                              <>
                                {StringUtils.numberFormat(
                                  this.calculateTotalQuantity("leanCheats")
                                )}{" "}
                                Packs -{" "}
                              </>
                            ) : null}
                            {StringUtils.numberFormat(
                              this.props.createMode ||
                                !this.props.order?.fulfillmentReportID
                                ? this.calculateTotalQuantity("leanCheats") *
                                    this.state.pricing?.leanCheatOrderMultiple
                                : this.calculateTotalQuantity("leanCheats")
                            )}{" "}
                            Lean Cheats)
                          </td>
                          <td
                            className="text-right p-2"
                            style={{ width: "120px" }}
                          >
                            {StringUtils.centsToCurrency(
                              this.props.createMode ||
                                !this.props.order?.fulfillmentReportID
                                ? this.calculateTotalQuantity("leanCheats") *
                                    this.state.pricing?.leanCheatOrderMultiple *
                                    this.state.pricing?.leanCheatUnitPrice
                                : this.calculateTotalQuantity("leanCheats") *
                                    this.state.pricing?.leanCheatUnitPrice
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="p-2 text-dark">
                            Corrugated Boxes &amp; Inserts
                          </td>
                          <td
                            className="text-right p-2"
                            style={{ width: "120px" }}
                          >
                            {StringUtils.centsToCurrency(
                              this.calculateBoxSubtotal()
                            )}
                          </td>
                        </tr>
                        {this.state.store?.fulfillmentStrategy ==
                        Constant.FULFILLMENT_STRATEGIES.IN_STORE ? (
                          <tr>
                            <td className="p-2 text-dark">
                              Logistics ({this._calculatePallets()?.length}{" "}
                              Pallets)
                            </td>
                            <td
                              className="text-right p-2"
                              style={{ width: "120px" }}
                            >
                              {StringUtils.centsToCurrency(
                                this._totalPalletPrice(this._calculatePallets())
                              )}
                            </td>
                          </tr>
                        ) : null}
                        {this.state?.additionalLineItems?.length ? (
                          <tr>
                            <td className="p-2 text-dark">
                              Additional Items (
                              {StringUtils.numberFormat(
                                this.state?.additionalLineItems?.length
                              )}{" "}
                              Line Item
                              {this.state?.additionalLineItems?.length > 1 &&
                                "s"}
                              )
                            </td>
                            <td
                              className="text-right p-2"
                              style={{ width: "120px" }}
                            >
                              {StringUtils.centsToCurrency(
                                this.getAdditionalItemSubtotal()
                              )}
                            </td>
                          </tr>
                        ) : null}
                        <tr>
                          <th
                            className="p-2 font-weight-bold text-dark text-uppercase text-right"
                            style={{
                              verticalAlign: "middle",
                              fontSize: "1rem",
                            }}
                          >
                            Projected Order Total:
                          </th>
                          <td
                            className="p-2 text-right font-weight-bold text-dark"
                            style={{
                              verticalAlign: "middle",
                              fontSize: "1rem",
                            }}
                          >
                            {/** TODO: Calculate box total here */}
                            {this.state.pricing
                              ? StringUtils.centsToCurrency(
                                  this._getProjectedTotal()
                                )
                              : "--"}
                          </td>
                        </tr>
                      </tbody>

                      {/** Start Labor Section If Applicable */}

                      {/** Start Freight/Logistics Section */}

                      {/** Start Order Summary Section */}
                    </Table>
                  </>
                ) : null}
                {this.state.active?.id == "Notes" &&
                !this.props.createMode &&
                !this.state.editMode ? (
                  <>
                    <div className="my-3">
                      <div>
                        <NoteBody
                          productOrder={this.props.order}
                          onSave={(note) => {
                            this.setState(
                              {
                                page: 1,
                              },
                              () => {
                                this.fetchNotes(true);
                              }
                            );
                          }}
                        ></NoteBody>
                      </div>
                      <div>
                        {this.state?.loading && (
                          <div className="px-3 py-5 text-center">
                            <Spinner size="sm" color="dark"></Spinner>
                          </div>
                        )}
                        {!this.state?.notes?.length && !this.state.loading && (
                          <div className="px-3 py-5">
                            <Row>
                              <Col xs="12" className="text-center">
                                <p className="m-0">No notes found.</p>
                              </Col>
                            </Row>
                          </div>
                        )}

                        {this.state?.notes?.map((note, i) => (
                          <div key={note?._id}>
                            {this.state.editNote == note?._id ? (
                              <>
                                <div className="p-4">
                                  <NoteBody
                                    note={note}
                                    productOrder={this.props.order}
                                    forceEdit={true}
                                    onSave={(n) => {
                                      let notes = this.state.notes;

                                      let idx = _.findIndex(notes, {
                                        _id: n._id,
                                      });

                                      if (idx >= 0) {
                                        notes[idx] = n;
                                      }

                                      this.setState({
                                        editNote: "",
                                        notes,
                                      });
                                    }}
                                    onCancel={() => {
                                      this.setState({ editNote: "" });
                                    }}
                                  ></NoteBody>
                                </div>
                              </>
                            ) : (
                              <div className="px-4 cursor-pointer py-3 bg-superlight--hover">
                                <Row>
                                  <Col xs="">
                                    {note?.title ? (
                                      <>
                                        <h3 className="text-dark mb-1">
                                          {note?.title}
                                        </h3>
                                        <Linkify
                                          body={note?.body}
                                          wrap={true}
                                        ></Linkify>
                                      </>
                                    ) : (
                                      <>
                                        <Linkify
                                          body={note?.body}
                                          wrap={true}
                                        ></Linkify>
                                      </>
                                    )}
                                  </Col>
                                  <Col xs="auto">
                                    <Button
                                      size="sm"
                                      color="secondary"
                                      outline
                                      className="p-1 mr-0"
                                      style={{ width: 22, height: 22 }}
                                      onClick={() => {
                                        this.setState({
                                          editNote: note?._id,
                                        });
                                      }}
                                    >
                                      <i
                                        className="mdi mdi-pencil"
                                        style={{
                                          fontSize: 16,
                                          lineHeight: 1,
                                          position: "relative",
                                          left: -2,
                                          top: -2,
                                        }}
                                      ></i>
                                    </Button>
                                    <Button
                                      size="sm"
                                      color="danger"
                                      outline
                                      className="p-1"
                                      style={{ width: 22, height: 22 }}
                                      disabled={
                                        this.state.deletingNote == note?._id
                                      }
                                      onClick={() => {
                                        swal({
                                          title: "Delete Note?",
                                          text: "Are you sure you want to delete this note?",
                                          icon: "warning",
                                          buttons: ["Nevermind", "Delete"],
                                          dangerMode: true,
                                        }).then((conf) => {
                                          if (!conf) {
                                            return;
                                          }

                                          APIV2.deleteStoreNote(
                                            note?.storeID,
                                            note?._id
                                          ).then(() => {
                                            let notes = this.state.notes;

                                            let idx = _.findIndex(notes, {
                                              _id: note._id,
                                            });

                                            notes.splice(idx, 1);

                                            this.setState({
                                              editNote: "",
                                              notes,
                                            });

                                            this.setState({
                                              deletingNote: "",
                                            });
                                          });
                                        });
                                      }}
                                    >
                                      <i
                                        className="mdi mdi-close"
                                        style={{
                                          fontSize: 18,
                                          lineHeight: 1,
                                          position: "relative",
                                          left: -3,
                                          top: -3,
                                        }}
                                      ></i>
                                    </Button>
                                  </Col>
                                  {note?.attachments?.length > 0 && (
                                    <>
                                      <Col className="mt-2" xs="12">
                                        <h5>Attachments: </h5>
                                        {note?.attachments?.map((at, i) => (
                                          <div
                                            className={`p-2 border rounded ${
                                              i !=
                                                this.state?.attachments
                                                  ?.length -
                                                  1 && "mb-2"
                                            }`}
                                            key={i}
                                          >
                                            <Row className="align-items-center">
                                              <Col xs="">
                                                <p className="small m-0 text-dark">
                                                  {at?.fileName}
                                                </p>
                                              </Col>
                                              <Col xs="auto">
                                                <Button
                                                  className="btn-icon-only"
                                                  size="sm"
                                                  outline
                                                  color="secondary"
                                                  onClick={() => {
                                                    LinkUtils.openPrivateURL(
                                                      at?.url
                                                    );
                                                  }}
                                                >
                                                  <i className="mdi mdi-eye"></i>
                                                </Button>
                                              </Col>
                                            </Row>
                                          </div>
                                        ))}
                                      </Col>
                                    </>
                                  )}
                                </Row>
                                <div className="mt-2">
                                  <small>
                                    {moment(note?.createdAt).format(
                                      "MM/DD/YY h:mmA"
                                    )}{" "}
                                    by {note?.createdBy?.name}
                                  </small>
                                </div>
                              </div>
                            )}
                            {i != this.state?.notes?.length - 1 && (
                              <hr className="m-0"></hr>
                            )}
                          </div>
                        ))}
                      </div>
                      <div className="text-center p-2">
                        <Button
                          size="sm"
                          color="secondary"
                          outline
                          onClick={() => {
                            console.log(this.state.pagination);

                            this.setState(
                              {
                                page: this.state.pagination?.next,
                              },
                              () => {
                                this.fetchNotes();
                              }
                            );
                          }}
                          disabled={!this.state.pagination?.hasNext}
                        >
                          {this.state.pagination?.hasNext
                            ? "View More Notes"
                            : "Viewing All Notes"}
                        </Button>
                      </div>
                    </div>
                  </>
                ) : null}
                {this.state.active?.id == "History" &&
                !this.props.createMode &&
                !this.state.editMode ? (
                  <>
                    <div className="my-3">
                      <Row className="align-items-center mb-3">
                        <Col xs="">
                          <h3 className="mb-0">Product Order History</h3>
                        </Col>
                        <Col xs="auto">
                          <Badge color="medium">
                            {this.state.orderHistory?.length} Event
                            {this.state.orderHistory?.length != 1 ? "s" : ""}
                          </Badge>
                        </Col>
                      </Row>
                      {this.state.orderHistory?.map((h, i) => (
                        <div key={i}>
                          <div className="text-muted">
                            <small>
                              {moment(h.eventTimestamp).format(
                                "MM/DD/YY hh:mmA"
                              )}
                            </small>
                          </div>
                          <div>
                            <h4 className="mb-0">{h.message}</h4>
                          </div>
                          {i != this.state.orderHistory.length - 1 ? (
                            <hr className="my-2" />
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </>
                ) : null}
              </>
            )}
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(ProductionOrderDrawer);
