import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Spinner,
  Collapse,
  Table,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import moment, { isMoment } from "moment";
import { CheckboxGroup, DatePicker, SelectPicker, Checkbox } from "rsuite";
import StringUtils from "lib/StringUtils";
import { Cell, Column, HeaderCell } from "rsuite-table";
import _ from "underscore";
import MoneyCell from "components/Table/MoneyCell";
import NumericCell from "components/Table/NumericCell";
import StoreCell from "components/Table/StoreCell";
import BootstrapTable from "react-bootstrap-table-next";
import MealsOrderedWidget from "components/Widgets/Fulfillment/MealsOrderedWidget";
import PlansFulfilledWidget from "components/Widgets/Fulfillment/PlansFulfilledWidget";
import AverageBoxSizeWidget from "components/Widgets/Fulfillment/AverageBoxSizeWidget";
import PlanFrequencyChartWidget from "components/Widgets/Fulfillment/PlanFrequencyChartWidget";
import PlanProcurementChartWidget from "components/Widgets/Fulfillment/PlanProcurementChartWidget";
import PlanTypeChartWidget from "components/Widgets/Fulfillment/PlanTypeChartWidget";
import { Link, withRouter } from "react-router-dom";
import Input from "reactstrap/lib/Input";
import swal from "sweetalert";
import CustomerOrderRow from "components/CustomerOrderRow";
import { CSVLink } from "react-csv";

class ProductOrderSummaryCSVDownloadView extends React.Component {
  state = {};

  loadOrders() {
    this.setState({
      loadingOrder: true,
    });

    let orderIDs = this.props.match?.params?.orderIDs?.split(",");

    console.log(orderIDs);

    this.setState({
      orderIDs,
    });

    let promises = [];

    for (let i = 0; i < orderIDs?.length; i++) {
      const orderID = orderIDs[i];

      promises.push(
        new Promise((resolve, reject) => {
          APIV2.getProductionOrderByID(orderID).then(
            (data) => {
              resolve(data.data.order);
            },
            (e) => {
              reject(e);
            }
          );
        })
      );
    }

    Promise.all(promises)
      .then((res) => {
        this.setState({
          orders: res,
        });

        this._loadOrderTable(res);
      })
      .finally(() => {
        this.setState({
          loadingOrder: false,
          loading: false,
        });
      });
  }

  _loadOrderTable(orders) {
    if (!orders?.length) {
      return;
    }

    let lifestyle = [];
    let athlete = [];
    let leancheats = [];

    console.log(orders);

    for (let k = 0; k < orders?.length; k++) {
      const order = orders[k];

      let vals = _.values(order?.menus?.meals);

      let allMeals = [];

      // Parse all options off the meal menus
      for (let i = 0; i < vals?.length; i++) {
        if (vals[i].hasOwnProperty("active") && vals[i].active?.meals?.length) {
          for (let j = 0; j < vals[i].active?.meals?.length; j++) {
            const meal = vals[i].active?.meals[j];

            meal.name = meal?.name?.trim();

            // Set as default until it can be calculated
            if (
              order?.meals?.hasOwnProperty(meal?.sku) &&
              order?.meals[meal?.sku].caseQuantity
            ) {
              meal.caseQuantity = order?.meals[meal?.sku]?.caseQuantity;
            } else {
              meal.caseQuantity = 0;
            }

            if (
              order?.meals?.hasOwnProperty(meal?.sku) &&
              order?.meals[meal?.sku]?.mealQuantity
            ) {
              meal.mealQuantity = order?.meals[meal?.sku]?.mealQuantity;
            } else {
              meal.mealQuantity = 0;
            }

            if (!_.findWhere(allMeals, { _id: meal?._id })) {
              allMeals.push(meal);
            }
          }
        }
      }

      // Group by type
      for (let i = 0; i < allMeals?.length; i++) {
        const meal = allMeals[i];

        if (meal?.planType?.name?.includes("Lifestyle")) {
          // Lifestyle meal
          // Lean cheat or lifestyle check
          // TODO: convert to sku check
          if (_.findWhere(meal?.categories, { name: "Lean Cheats" })) {
            const idx = _.findIndex(leancheats, { sku: meal?.sku });

            if (idx >= 0) {
              leancheats[idx].caseQuantity += meal?.caseQuantity;
              leancheats[idx].mealQuantity += meal?.mealQuantity;
            } else {
              leancheats.push(meal);
            }
          } else if (!_.findWhere(meal?.categories, { name: "Lean Cheats" })) {
            const idx = _.findIndex(lifestyle, { sku: meal?.sku });

            if (idx >= 0) {
              lifestyle[idx].caseQuantity += meal?.caseQuantity;
              lifestyle[idx].mealQuantity += meal?.mealQuantity;
            } else {
              lifestyle.push(meal);
            }
          }
        } else {
          // Athlete meal
          // Lean cheat or athlete check
          // TODO: convert to sku check
          if (_.findWhere(meal?.categories, { name: "Lean Cheats" })) {
            const idx = _.findIndex(leancheats, { sku: meal?.sku });

            if (idx >= 0) {
              leancheats[idx].caseQuantity += meal?.caseQuantity;
              leancheats[idx].mealQuantity += meal?.mealQuantity;
            } else {
              leancheats.push(meal);
            }
          } else if (!_.findWhere(meal?.categories, { name: "Lean Cheats" })) {
            const idx = _.findIndex(athlete, { sku: meal?.sku });

            if (idx >= 0) {
              athlete[idx].caseQuantity += meal?.caseQuantity;
              athlete[idx].mealQuantity += meal?.mealQuantity;
            } else {
              athlete.push(meal);
            }
          }
        }
      }

      // Sort alphabetically
    }

    lifestyle = _.sortBy(lifestyle, "name");
    athlete = _.sortBy(athlete, "name");
    leancheats = _.sortBy(leancheats, "name");

    let allMealCSV = lifestyle.concat(athlete).concat(leancheats);

    allMealCSV = allMealCSV.map((meal) => {
      return {
        SKU: meal?.sku,
        //"Case Quantity": meal?.caseQuantity,
        "Meal Quantity": meal?.mealQuantity,
      };
    });

    this.setState({
      lifestyleMeals: lifestyle,
      athleteMeals: athlete,
      leanCheats: leancheats,
      allMealCSV,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match?.params?.orderIDs !=
        prevProps?.match?.params?.orderIDs &&
      this.props.match?.params?.orderIDs
    ) {
      this.loadOrders();
    }
  }

  componentDidMount() {
    if (this.props.match?.params?.orderIDs) {
      this.loadOrders();
    }
  }

  render() {
    return (
      <>
        <div>
          {this.state.loading || this.state.loadingOrder ? (
            <div className="p-3">
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">
                    <Spinner color="dark" size="sm"></Spinner>
                    <span className="pl-3">Generating Order Summary CSV</span>
                  </h3>
                </CardHeader>
                <CardBody>
                  Hang tight while we fetch your summary for{" "}
                  {this.state.orderIDs?.length} orders.
                </CardBody>
              </Card>
            </div>
          ) : null}

          {!this.state.orderIDs &&
          !this.state.loadingOrders &&
          !this.state.loading ? (
            <div className="p-3">
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">
                    <span>No CSV Available</span>
                  </h3>
                </CardHeader>
                <CardBody>
                  We were unable to generate a CSV download for this order.
                  Please reload and try again.
                </CardBody>
              </Card>
            </div>
          ) : null}
          {!this.state.loading &&
          !this.state.loadingOrder &&
          this.state.orders ? (
            <div className="p-3">
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">
                    CSV Download for {this.state?.orders?.length} Orders
                  </h3>
                </CardHeader>
                <CardBody>
                  <CSVLink
                    headers={["SKU", "Meal Quantity"]}
                    filename={`LLM_Product_Order_Summary_${moment().format(
                      "MM-DD-YYYY_HH:mm:ss"
                    )}.csv`}
                    data={this.state.allMealCSV}
                    className="btn btn-primary"
                  >
                    Download
                  </CSVLink>
                </CardBody>
              </Card>
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

export default withRouter(ProductOrderSummaryCSVDownloadView);
