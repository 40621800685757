import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Badge,
  Spinner,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";

import _, { times } from "underscore";
import APIV2 from "lib/APIV2";
import {
  BarcodeFormat,
  BarcodeScanner,
  LensFacing,
} from "@projectleannation/barcode-scanning";
import { Capacitor } from "@capacitor/core";
import { Modal, Dropdown, Popover, Whisper, SelectPicker } from "rsuite";
import NumberFormat from "react-number-format";
import { ScreenOrientation } from "@capacitor/screen-orientation";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { CameraPreview } from "@capacitor-community/camera-preview";
import { Ocr, TextDetections } from "@capacitor-community/image-to-text";

class LooseSealScanner extends React.Component {
  state = {
    open: false,
    pendingMeals: [],
    manualPendingCodes: {
      value: null,
    },
    imageCount: 0,
    mealOptions: [],
    pendingMeal: null,
  };

  dropdownRef = React.createRef();

  getAllMeals() {
    return new Promise((resolve, reject) => {
      APIV2.getMeals()
        .then(
          (data) => {
            let mealOptions = [];

            for (let i = 0; i < data.data.meals.length; i++) {
              let meal = data.data.meals[i];

              if (_.findWhere(mealOptions, { value: meal?.sku })) {
                continue;
              }

              let category = _.findWhere(meal?.categories, {
                name: "Lean Cheats",
              })
                ? "Lean Cheats"
                : meal?.planType?.name;

              if (
                meal?.planType?.name == "Athlete" &&
                _.findWhere(meal?.categories, { name: "Lean Cheats" })
              ) {
                continue;
              }

              mealOptions.push({
                value: meal?.sku,
                mealID: meal?._id,
                label: meal?.name,
                category,
                planType: meal?.planType?.name,
                planTypeID: meal?.planType?._id,
              });
            }

            this.setState({ meals: data?.data?.meals, mealOptions }, () => {
              resolve(data?.data?.meals);
            });
          },
          (e) => {
            reject(e);
          }
        )
        .finally(() => {
          this.setState({ loading: false });
        });
    });
  }

  async recordLooseSeal(meal, codes, image) {
    // TODO: check if code is already used
    // TODO: record loose seal info

    let pendingMeals = this.state.pendingMeals?.length
      ? this.state.pendingMeals
      : [];

    pendingMeals.push({
      meal: {
        name: meal?.name,
        planType: {
          _id: meal?.planType?._id,
          name: meal?.planType?.name,
        },
        categories: meal?.categories,
        _id: meal?._id,
        sku: meal?.sku,
      },
      code1: codes?.length == 2 ? codes[0] : null,
      code2: codes?.length == 2 ? codes[1] : null,
      image: image,
      originalFileURL: image,
    });

    this.setState({
      pendingMeals,
      pendingImage: null,
      identifiedMeal: null,
      identifyMealMode: false,
    });
  }

  async openScanner() {
    this.getAllMeals().then(async (meals) => {
      await ScreenOrientation.lock({ orientation: "portrait" });

      try {
        await CameraPreview.start({
          position: "rear",
          toBack: true,
          rotateWhenOrientationChanged: false,
          enableHighResolution: true,
          disableAudio: true,
          enableZoom: true,
          storeToFile: true,
        });
      } catch (e) {
        try {
          await CameraPreview.stop();

          await CameraPreview.start({
            position: "rear",
            toBack: true,
            rotateWhenOrientationChanged: false,
            enableHighResolution: true,
            disableAudio: true,
            enableZoom: true,
            storeToFile: true,
          });
        } catch (e) {}
      }

      this.setState({
        showGuideText: true,
        open: true,
      });

      document.querySelector("body").classList.add("barcode-scanner-active");

      setTimeout(() => {
        this.setState({
          showGuideText: false,
        });
      }, 3000);

      PubSub.publish(Event.LOOSE_SEAL_SCANNER.LOADED);
    });
  }

  async closeScanner(listener) {
    // TODO: publish scanner closed event
    PubSub.publish(Event.LOOSE_SEAL_SCANNER.COMPLETE, this.state.pendingMeals);

    try {
      ScreenOrientation.unlock();
    } catch (e) {
      console.error(e);
    }

    this.setState({
      open: false,
      pendingMeal: null,
      pendingImage: null,
      pendingCodes: [],
      pendingURL: null,
      pendingMeals: [],
      imageCount: 0,
    });

    try {
      //BarcodeScanner.stopScan();

      await CameraPreview.stop();
    } catch (e) {
      console.error(e);
    }

    //await BarcodeScanner.removeAllListeners();

    document.querySelector("body").classList.remove("barcode-scanner-active");

    console.log("SCANNER CLOSED");
  }

  componentDidMount() {
    PubSub.subscribe(Event.LOOSE_SEAL_SCANNER.OPEN, () => {
      console.log("OPEN SCANNER");

      this.openScanner();
    });

    PubSub.subscribe(Event.LOOSE_SEAL_SCANNER.CLOSE, () => {
      this.closeScanner();
    });
  }

  async captureImage() {
    this.setState({
      capturing: true,
      imageError: false,
      identifyMealMode: false,
    });

    const res = await CameraPreview.capture({
      quality: 60,
    });

    console.log(res?.value);

    let planType = "";
    let scannedMeal = null;
    let leanCheat = false;

    try {
      const data = await Ocr.detectText({ filename: res?.value });
      for (let detection of data.textDetections) {
        //console.log(detection.text);

        if (detection?.text?.toLowerCase()?.trim()?.includes("lifestyle")) {
          planType = "Lifestyle";
        }

        if (detection?.text?.toLowerCase()?.trim()?.includes("athlete")) {
          planType = "Athlete";
        }

        if (detection?.text?.toLowerCase()?.trim()?.includes("lean cheats")) {
          planType = "Lifestyle";
          leanCheat = true;
        }

        for (let meal of this.state.meals) {
          if (
            meal?.name?.toLowerCase()?.trim() ==
              detection?.text?.toLowerCase()?.trim() &&
            planType?.toLowerCase()?.trim() ==
              meal?.planType?.name?.toLowerCase()?.trim()
          ) {
            //console.log(planType, meal?._id);

            scannedMeal = meal;
          }

          if (
            leanCheat &&
            _.findWhere(meal?.categories, { name: "Lean Cheats" }) &&
            planType?.toLowerCase()?.trim() ==
              meal?.planType?.name?.toLowerCase()?.trim() &&
            meal?.name?.toLowerCase()?.replace("protein ball", "")?.trim() ==
              detection?.text?.toLowerCase()?.trim()
          ) {
            scannedMeal = meal;
          }
        }

        if (!isNaN(parseInt(detection?.text)) && detection?.text?.length == 5) {
          //console.log("NUMBER", detection?.text);
        }

        if (!isNaN(parseInt(detection?.text)) && detection?.text?.length == 4) {
          //console.log("NUMBER", detection?.text);
        }
      }

      if (scannedMeal) {
        console.log("RECORDING LOOSE SEAL", planType, scannedMeal);

        this.recordLooseSeal(scannedMeal, [], res?.value);

        this.setState({
          imageCount: this.state.imageCount + 1,
        });
      } else {
        this.setState(
          {
            identifyMealMode: true,
            pendingImage: res?.value,
          },
          () => {
            this.setState({
              capturing: false,
            });
          }
        );

        /*this.setState({
          imageError: true,
        });

        setTimeout(() => {
          this.setState({
            imageError: false,
          });
        }, 1500);*/
      }
    } catch (e) {
      console.error(e);

      this.setState({
        imageError: true,
        capturing: false,
      });

      setTimeout(() => {
        this.setState({
          imageError: false,
        });
      }, 1500);
    }
  }

  render() {
    return (
      <>
        {this.state.open ? (
          <>
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                zIndex: 999,
                width: "100%",
                height: "100%",
              }}
            >
              <div
                style={{
                  position: "fixed",
                  top: "2.75rem",
                  right: "0.75rem",
                  zIndex: 9999,
                }}
              >
                <Button
                  style={{ boxShadow: "none" }}
                  onClick={() => {
                    this.closeScanner();
                  }}
                  outline
                  className="text-white border-0 p-2"
                >
                  {this.state.pendingMeals?.length ? (
                    <i
                      className="mdi mdi-check"
                      style={{ fontSize: 40, lineHeight: 1 }}
                    ></i>
                  ) : (
                    <i
                      className="mdi mdi-close"
                      style={{ fontSize: 40, lineHeight: 1 }}
                    ></i>
                  )}
                </Button>
              </div>

              <div
                style={{
                  position: "fixed",
                  top: "2.75rem",
                  left: "0.75rem",
                  zIndex: 9999,
                }}
              >
                <Button
                  style={{ boxShadow: "none" }}
                  onClick={() => {
                    if (this.state.flash) {
                      CameraPreview.setFlashMode({
                        flashMode: "off",
                      });

                      this.setState({
                        flash: false,
                      });
                    } else {
                      CameraPreview.setFlashMode({
                        flashMode: "torch",
                      });

                      this.setState({
                        flash: true,
                      });
                    }
                  }}
                  outline
                  className="text-white border-0 p-2 border-0 bg-transparent"
                >
                  {this.state.flash ? (
                    <i
                      className="mdi mdi-flash"
                      style={{ fontSize: 40, lineHeight: 1 }}
                    ></i>
                  ) : (
                    <i
                      className="mdi mdi-flash-off"
                      style={{ fontSize: 40, lineHeight: 1 }}
                    ></i>
                  )}
                </Button>
              </div>

              <div className="scanner-window">
                <div
                  style={{
                    position: "relative",
                    top: "-50px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    textAlign: "center",

                    color: "#FFFFFF",
                    fontWeight: 800,
                    fontSize: 16,
                  }}
                  className="rounded"
                >
                  Tap Screen To Focus Camera
                </div>
              </div>
              {this.state.showGuideText && (
                <>
                  <div
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      textAlign: "center",
                      width: "60%",
                      maxWidth: 300,
                      color: "#000000",
                      fontWeight: 800,
                      fontSize: 18,
                      background: "rgba(255,255,255,0.9)",
                      padding: "0.5rem",
                    }}
                    className="rounded"
                  >
                    Center Meal In The Guides & Press Shutter To Capture Image
                  </div>
                </>
              )}
              {this.state.identifyMealMode ? (
                <div
                  style={{
                    position: "fixed",
                    bottom: 50,
                    left: "50%",
                    transform: "translateX(-50%)",
                    borderRadius: 10,
                    width: "90%",
                    maxWidth: 400,
                    background: "#FFFFFF",

                    border: "1px solid var(--medium)",
                    boxShadow: "0px 0px 2px rgba(0,0,0,0.2)",
                    padding: 15,
                    textAlign: "center",
                  }}
                >
                  <Row className="align-items-center">
                    <Col xs="12">
                      <h3 className="mt-0 mb-3 text-dark">Identify The Meal</h3>
                    </Col>
                    <Col xs="12">
                      <SelectPicker
                        block
                        placeholder="Select The Meal"
                        loading={this.state.mealsLoading}
                        data={this.state.mealOptions}
                        value={this.state?.identifiedMeal?.sku}
                        onChange={(v) => {
                          console.log(v);

                          let opt = _.findWhere(this.state.mealOptions, {
                            value: v,
                          });

                          let meal = _.find(this.state.meals, (m) => {
                            return (
                              m?.sku == v && m?.planType?.name == opt?.planType
                            );
                          });

                          this.setState({
                            identifiedMeal: meal,
                          });
                        }}
                        groupBy="category"
                        placement="autoVertical"
                        preventOverflow={true}
                        size="lg"
                      ></SelectPicker>
                    </Col>
                    <Col xs="6" className="mt-3">
                      <Button
                        style={{ width: "100%" }}
                        block
                        outline
                        color="dark"
                        className="btn-icon-only"
                        onClick={() => {
                          this.setState({
                            identifyMealMode: false,
                            identifiedMeal: null,
                          });
                        }}
                      >
                        <i className="mdi mdi-close"></i>
                      </Button>
                    </Col>
                    <Col className="pl-0 mt-3" xs="6">
                      <Button
                        style={{ width: "100%" }}
                        block
                        color="primary"
                        className="btn-icon-only"
                        disabled={!this.state.identifiedMeal}
                        onClick={() => {
                          //console.log("PENDING", this.state.pendingImage);

                          this.recordLooseSeal(
                            this.state.identifiedMeal,
                            [],
                            this.state.pendingImage
                          );

                          this.setState({
                            imageCount: this.state.imageCount + 1,
                          });
                        }}
                      >
                        <i className="mdi mdi-check"></i>
                      </Button>
                    </Col>
                  </Row>
                </div>
              ) : (
                <button
                  onClick={() => {
                    console.log("capture");

                    this.captureImage();
                  }}
                  style={{
                    position: "fixed",
                    bottom: 50,
                    left: "50%",
                    transform: "translateX(-50%)",
                    height: 80,
                    width: 80,
                    background: "#FFFFFF",
                    borderRadius: "100%",
                    border: "1px solid var(--medium)",
                    boxShadow: "0px 0px 2px rgba(0,0,0,0.2)",
                    padding: 10,
                    textAlign: "center",

                    fontSize: 24,
                    fontWeight: 800,
                    color: "var(--dark)",
                    lineHeight: 1,
                  }}
                  className="scanner-capture-button d-flex justify-content-center align-items-center"
                >
                  {this.state.capturing ? (
                    <Spinner color="light"></Spinner>
                  ) : (
                    <>
                      {this.state.imageError ? (
                        <>
                          <i
                            className="mdi mdi-alert-circle text-danger"
                            style={{ fontSize: 40 }}
                          ></i>
                        </>
                      ) : (
                        <>
                          {this.state.imageCount ? this.state.imageCount : null}
                        </>
                      )}
                    </>
                  )}
                </button>
              )}
            </div>
          </>
        ) : null}
        <div id="cameraPreview"></div>
        <Modal
          size="full"
          style={{ maxWidth: "100%" }}
          open={this.state.photoModal}
          onClose={() =>
            this.setState({
              photoModal: false,
            })
          }
        >
          <Modal.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Captured Photo
            </h3>
          </Modal.Header>

          <Modal.Body className="p-4">
            {this.state.pendingImage ? (
              <>
                <img
                  src={`data:image/jpg;base64,${this.state.pendingImage}`}
                  style={{ width: "100%" }}
                ></img>
              </>
            ) : (
              <div className="rounded border p-4 text-center text-dark">
                No photo captured
              </div>
            )}
          </Modal.Body>
        </Modal>
        <Modal
          size="full"
          style={{ maxWidth: "100%" }}
          open={this.state.dataModal}
          onClose={() =>
            this.setState({
              dataModal: false,
            })
          }
        >
          <Modal.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Enter Codes Manually
            </h3>
          </Modal.Header>

          <Modal.Body className="p-4">
            <FormGroup>
              <h5>Unique Code</h5>
              <NumberFormat
                className="form-control mb-3"
                fixedDecimalScale={true}
                decimalScale={2}
                required={true}
                placeholder="XXXXX-XXXX"
                format={"#####-####"}
                value={this.state.manualPendingCodes.value}
                onValueChange={(v) => {
                  console.log(v);

                  this.setState({ manualPendingCodes: v });
                }}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              color="primary"
              disabled={
                this.state.submitting ||
                this.state.manualPendingCodes?.value?.length != 9
              }
              onClick={() => {
                let codes =
                  this.state.manualPendingCodes?.formattedValue?.split("-");

                this.setState({
                  pendingCodes: codes,
                });

                this.setState({
                  dataModal: false,
                });
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default LooseSealScanner;
