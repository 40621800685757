import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  BarChart,
  Card,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _, { times } from "underscore";
import {
  Axis,
  Chart,
  Coordinate,
  Interaction,
  Interval,
  Legend,
  Tooltip,
} from "bizcharts";
import ChartMetricHeader from "./ChartMetricHeader";

class StoreLeaderboardRetailGoalTrendChartCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    tab: "performanceTrend",
  };

  /**
   * Fetches the order data by week for the given stores & date range
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchOrderDataByWeek(cubejsApi, stores, dateRange) {
    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: [
            "MetricsWeeklyRetailSales.metadataSalesTotalSum",
            "MetricsWeeklyRetailSales.metadataSalesTotalAverage",
            "MetricsWeeklyRetailSales.metadataSalesGoalSum",
            "MetricsWeeklyRetailSales.metadataSalesGoalAverage",
            "MetricsWeeklyRetailSales.metadataPercentGoal",
          ],
          order: {
            "MetricsWeeklyRetailSales.createdat": "asc",
          },

          timeDimensions: [
            {
              dimension: "MetricsWeeklyRetailSales.createdat",
              dateRange: [dateRange[0], dateRange[1]],
              granularity: "week",
            },
          ],
          filters: [
            {
              member: "MetricsWeeklyRetailSales.metadatastoreid",
              operator: "equals",
              values: stores,
            },
            {
              member: "MetricsWeeklyRetailSales.name",
              operator: "equals",
              values: ["weekly_retail_sales"],
            },
            {
              member: "MetricsWeeklyRetailSales.metadataSalesGoalAverage",
              operator: "notEquals",
              values: ["0"],
            },
          ],
          dimensions: ["Stores.name"],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          if (!data?.length) {
            return resolve(null);
          }

          data = data.map((item) => {
            return {
              storeName: item["Stores.name"]
                ?.replace("Project LeanNation", "PLN")
                ?.trim(),
              fulfillmentDate: moment(
                item["MetricsWeeklyRetailSales.createdat.week"]
              )
                .day(6)
                .toDate(),
              fulfillmentDateString: moment(
                item["MetricsWeeklyRetailSales.createdat.week"]
              )
                .day(6)
                .format("MM/DD/YYYY"),
              totalFulfilledCount:
                item["MetricsWeeklyRetailSales.metadataSalesTotalAverage"],
              goalOrderCount:
                item["MetricsWeeklyRetailSales.metadataSalesGoalAverage"],
              percentGoal: item["MetricsWeeklyRetailSales.metadataPercentGoal"],
            };
          });

          console.log(data);

          let outByStore = data.map((item) => {
            return {
              storeName: item?.storeName,
              fulfillmentDate: item?.fulfillmentDate,
              fulfillmentDateString: item?.fulfillmentDateString,
              goalOrderCount: item?.goalOrderCount,
              totalFulfilledCount: item?.totalFulfilledCount,
              percentGoal: item?.percentGoal
                ? parseFloat((item?.percentGoal * 100).toFixed(1))
                : 0,
            };
          });

          let outOverall = [];

          let totalFulfilled = [];
          let totalGoal = [];

          for (let i = 0; i < data?.length; i++) {
            const item = data[i];

            const overallIDX = _.findIndex(outOverall, {
              fulfillmentDateString: item.fulfillmentDateString,
            });

            if (overallIDX == -1) {
              outOverall.push({
                fulfillmentDateString: item?.fulfillmentDateString,
                percentGoal:
                  item.percentGoal !== null ? [item.percentGoal * 100] : [],
                fulfillmentDate: item?.fulfillmentDate,
              });
            } else if (item.percentGoal !== null) {
              outOverall[overallIDX].percentGoal.push(item.percentGoal * 100);
            }

            if (item?.totalFulfilledCount !== null) {
              totalFulfilled.push(item?.totalFulfilledCount);
            }

            if (item?.goalOrderCount !== null) {
              totalGoal.push(item?.goalOrderCount);
            }
          }

          for (let i = 0; i < outOverall?.length; i++) {
            let sum = 0;

            for (let j = 0; j < outOverall[i].percentGoal?.length; j++) {
              sum += outOverall[i].percentGoal[j];
            }

            sum =
              outOverall[i].percentGoal?.length == 0
                ? 0
                : sum / outOverall[i].percentGoal?.length;

            outOverall[i].percentGoal = sum.toFixed(1);
          }

          let avgFulfilled = 0;
          let avgGoal = 0;

          for (let i = 0; i < totalFulfilled?.length; i++) {
            avgFulfilled += totalFulfilled[i];
          }

          for (let i = 0; i < totalGoal?.length; i++) {
            avgGoal += totalGoal[i];
          }

          totalFulfilled = avgFulfilled / totalFulfilled?.length;
          totalGoal = avgGoal / totalGoal?.length;

          outByStore = _.sortBy(outByStore, "fulfillmentDate");
          outOverall = _.sortBy(outOverall, "fulfillmentDate");

          return resolve({
            byStore: outByStore,
            overall: outOverall,
            totalFulfilled,
            totalGoal,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async loadDetailedReport(stores, dateRange) {
    this.setState({
      loading: true,
      dataAvailable: false,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!stores?.length || !dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchOrderDataByWeek(
        cubejsApi,
        stores,
        dateRange
      );
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load client order quantities by week.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });

      return;
    }

    let storeNames = [];

    for (let i = 0; i < currentCount?.byStore?.length; i++) {
      let keys = _.keys(currentCount.byStore[i]);

      let names = _.filter(keys, (name) => {
        return name.includes("PLN");
      });

      storeNames = storeNames.concat(names);
    }

    storeNames = _.uniq(storeNames);

    let previousCount = null;

    let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

    secondDifference = secondDifference / 1000;

    let startMoment = moment(dateRange[0].toISOString());
    let endMoment = moment(dateRange[1].toISOString());

    startMoment.subtract(secondDifference, "seconds");
    endMoment.subtract(secondDifference + 1, "seconds");

    let currentGoalPercent =
      (currentCount?.totalFulfilled / currentCount?.totalGoal) * 100;

    currentGoalPercent = currentGoalPercent.toFixed(1);

    try {
      previousCount = await this._fetchOrderDataByWeek(cubejsApi, stores, [
        startMoment.toDate(),
        endMoment.toDate(),
      ]);
    } catch (e) {}

    if (previousCount !== null) {
      let prevGoalPercent =
        (previousCount?.totalFulfilled / previousCount?.totalGoal) * 100;

      prevGoalPercent = prevGoalPercent.toFixed(1);

      let percentChange =
        (currentGoalPercent - prevGoalPercent) / prevGoalPercent;

      let declineMode = "";
      let isNegative = false;

      if (percentChange > 0) {
        if (Math.abs(percentChange) < 0.015) {
          declineMode = "unchanged";
        } else if (Math.abs(percentChange) < 0.1) {
          declineMode = "moderateIncrease";
        } else if (Math.abs(percentChange) >= 0.1) {
          declineMode = "increase";
        }
      } else {
        isNegative = true;

        if (Math.abs(percentChange) < 0.015) {
          declineMode = "unchanged";
        } else if (Math.abs(percentChange) < 0.1) {
          declineMode = "moderateDecrease";
        } else if (Math.abs(percentChange) >= 0.1) {
          declineMode = "decrease";
        }
      }

      this.setState({
        previousGoalPercent: prevGoalPercent,
        currentGoalPercent,
        changeIsNegative: isNegative,
        deltaType: isNaN(percentChange) ? "neutral" : declineMode,
        percentChange: percentChange,
        percentChangeString: isNaN(percentChange)
          ? "--"
          : Math.abs(percentChange * 100).toFixed(1) + "%",
      });
    }

    let performanceTrend = [];

    for (let i = 0; i < currentCount?.overall?.length; i++) {
      const item = currentCount?.overall[i];

      performanceTrend.push({
        fulfillmentDate: item?.fulfillmentDate,
        fulfillmentDateString: `EOW ` + item?.fulfillmentDateString,
        "% Of Goal": item?.percentGoal,
        Previous: null,
      });
    }

    for (let i = 0; i < previousCount?.overall?.length; i++) {
      const item = previousCount?.overall[i];

      const fulfillmentDate = moment(item?.fulfillmentDate?.toISOString())
        .add(secondDifference, "seconds")
        .day(6)
        .hours(0)
        .minute(0)
        .second(0)
        .millisecond(0);
      const realDate = moment(item?.fulfillmentDate?.toISOString())
        .day(6)
        .hours(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      const idx = _.findIndex(performanceTrend, {
        fulfillmentDateString: "EOW " + fulfillmentDate?.format("MM/DD/YYYY"),
      });

      if (idx >= 0) {
        performanceTrend[idx].Previous = item?.percentGoal;
        performanceTrend[idx].fulfillmentDateString += ` vs. ${realDate?.format(
          "MM/DD/YY"
        )}`;
      } else if (
        fulfillmentDate?.toDate() >= dateRange[0] &&
        fulfillmentDate.toDate() <= dateRange[1]
      ) {
        performanceTrend.push({
          fulfillmentDate: item?.fulfillmentDate,
          fulfillmentDateString: `EOW ${fulfillmentDate?.format(
            "MM/DD/YY"
          )} vs. ${realDate?.format("MM/DD/YY")}`,
          "% Of Goal": null,
          Previous: item?.percentGoal,
        });
      }
    }

    storeNames.sort();

    this.setState({
      currentCount: currentCount,
      loading: false,
      performanceTrend,
      byStore: currentCount?.byStore,
      storeNames: storeNames,
    });

    if (typeof this.props.onLoaded == "function") {
      this.props.onLoaded();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stores != prevProps?.stores ||
      this.props.dateRange != prevProps?.dateRange
    ) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload != prevProps.reload && this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  componentDidMount() {
    if (this.props.store && this.props.dateRange) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  render() {
    return (
      <>
        <Card marginTop="mt-0">
          <ChartMetricHeader
            title="Average Goal Performance"
            actions={
              <>
                <Button
                  size="sm"
                  outline
                  color="dark"
                  className="border-0 btn-icon-only"
                  disabled={this.state.loading}
                  onClick={() => {
                    this.loadDetailedReport(
                      this.props.stores,
                      this.props.dateRange
                    );
                  }}
                >
                  {this.state.loading ? (
                    <Spinner size="sm"></Spinner>
                  ) : (
                    <i className="mdi mdi-refresh"></i>
                  )}
                </Button>
                {/*<Button
                  size="sm"
                  outline
                  color="dark"
                  className="border-0 btn-icon-only"
                  disabled={this.state.loading || !this.state.dataAvailable}
                >
                  <i className="mdi mdi-download"></i>
                  </Button>*/}
              </>
            }
            loading={this.state.loading}
            dataAvailable={this.state.dataAvailable}
            metric={
              <>{StringUtils.numberFormat(this.state.currentGoalPercent)}%</>
            }
            comparisonMetric={
              this.state.previousGoalPercent !== null &&
              !isNaN(this.state.previousGoalPercent) && (
                <>{StringUtils.numberFormat(this.state.previousGoalPercent)}%</>
              )
            }
            dateRange={this.props.dateRange}
            deltaType={this.state.deltaType}
            percentChange={this.state.percentChangeString}
            showPercentChange={true}
          ></ChartMetricHeader>
          {this.state.loading ? (
            <div
              className="skeleton mt-4"
              style={{ height: "calc(14rem)", width: "100%" }}
            >
              &nbsp;
            </div>
          ) : (
            <>
              {this.state.dataAvailable ? (
                <>
                  <div className="mt-4">
                    <AreaChart
                      data={this.state.performanceTrend}
                      categories={["% Of Goal", "Previous"]}
                      dataKey="fulfillmentDateString"
                      colors={["orange", "gray"]}
                      valueFormatter={(number) => {
                        return StringUtils.numberFormat(number) + "%";
                      }}
                      height="h-56"
                    />
                  </div>
                </>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "14rem", width: "100%" }}
                >
                  <p className="m-0">
                    No data available.
                    {this.state.error ? ` ${this.state.error}` : null}
                  </p>
                </div>
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default StoreLeaderboardRetailGoalTrendChartCard;
