import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import APIV2 from "lib/APIV2";
import _ from "underscore";

class CreateMenuModal extends React.Component {
  state = {
    open: false,
    name: "",
    description: "",
    key: "",
    secret: "",
    submitting: false,
    meals: [],
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });

    if (this.state[state] == true) {
      this.setState({
        name: "",
        description: "",
        key: "",
        secret: "",
      });
    }
  };

  toggleMeal(c) {
    let meals = this.state.meals;

    let idx = _.findIndex(meals, { _id: c._id });

    if (idx >= 0) {
      meals[idx].checked = !meals[idx].checked;
    }

    this.setState({
      meals,
    });
  }

  handleNameChange(event) {
    const val = event.target.value;

    this.setState({
      name: val,
    });
  }

  handleDescriptionChange(event) {
    const val = event.target.value;

    this.setState({
      description: val,
    });
  }

  handleKeyChange(event) {
    const val = event.target.value;

    this.setState({
      key: val,
    });
  }

  handlePublicChange(event) {
    const val = event.target.value;

    this.setState({
      publicKey: val,
    });
  }

  handleSecretChange(event) {
    const val = event.target.value;

    this.setState({
      secret: val,
    });
  }

  submit(e) {
    e.preventDefault();

    this.setState({
      submitting: true,
    });

    let meals = [];

    for (let i = 0; i < this.state.meals.length; i++) {
      if (this.state.meals[i].checked) {
        meals.push(this.state.meals[i]._id);
      }
    }

    APIV2.createMealMenu(this.props.planType._id, this.state.name, meals)
      .then(
        (data) => {
          console.log(data);

          if (data && data.data && data.data.menu) {
            PubSub.publish(Event.MENU.CREATED, data.data.menu);

            this.toggleModal("open");

            this.setState({
              name: "",
              description: "",
              key: "",
              secret: "",
              publicKey: "",
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "We were unable to create the account. Please try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  componentDidMount() {
    PubSub.subscribe(Event.MENU.CREATE_OPEN, () => {
      APIV2.getMealsByProductGroup(
        this.props.planType ? this.props.planType._id : ""
      ).then(
        (data) => {
          let meals = _.sortBy(data.data.meals, "sku");

          console.log(meals);

          this.setState({ meals });
        },
        (e) => {
          window.alert("Failed to load meals by product group.");
        }
      );

      this.setState({
        open: true,
      });
    });
  }

  render() {
    return (
      <>
        <Modal isOpen={this.state.open} toggle={() => this.toggleModal("open")}>
          <div className="modal-header">
            <h5 className="modal-title" id="createBAModalLabel">
              Create {this.props.planType ? this.props.planType.name + " " : ""}
              Menu
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("open")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Form onSubmit={this.submit.bind(this)}>
            <div className="modal-body">
              <h5>Menu Name</h5>
              <Input
                id="createBAName"
                placeholder="Name"
                type="text"
                className="mb-3"
                required={true}
                value={this.state.name}
                onChange={this.handleNameChange.bind(this)}
              />
              <h5 className="mb-2">
                Meals
                {this.state.loadingMeals ? (
                  <span className="badge badge-info">Loading...</span>
                ) : null}
              </h5>
              <small>Select all meals you'd like to add to this menu.</small>
              <Row className="mt-3">
                {this.state.meals.map((c, i) => (
                  <Col xs="12" sm="6">
                    <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                      <input
                        className="custom-control-input"
                        id={"catCheck" + i}
                        type="checkbox"
                        onChange={() => {
                          this.toggleMeal(c);
                        }}
                        checked={c.checked}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={"catCheck" + i}
                      >
                        {c.name}
                      </label>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="reset"
                onClick={() => this.toggleModal("open")}
              >
                Close
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={this.state.submitting}
              >
                Create
              </Button>
            </div>
          </Form>
        </Modal>
      </>
    );
  }
}

export default CreateMenuModal;
