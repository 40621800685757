import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

import Login from "views/examples/Login.js";
import ForgotPassword from "views/examples/ForgotPassword";
import ResetPassword from "views/examples/ResetPassword";
import Register from "views/examples/Register.js";

class Auth extends React.Component {
  componentDidMount() {
    document.body.classList.add("bg-superlight");
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-superlight");
  }
  render() {
    return (
      <>
        <div className="main-content bg-superlight">
          <div className="header bg-primary py-7 py-lg-8"></div>
          {/* Page content */}
          <div>
            <Container className="mt--8 pb-5">
              <Row className="justify-content-center">
                <Switch>
                  <Route path="/auth/login" component={Login} exact />
                  <Route
                    path="/auth/login/forgot"
                    component={ForgotPassword}
                    exact
                  />
                  <Route
                    path="/auth/login/reset"
                    component={ResetPassword}
                    exact
                  />
                  <Route path="/auth/register" component={Register} exact />
                  <Redirect from="*" to="/auth/login" />
                </Switch>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default Auth;
