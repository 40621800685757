import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import { Dropdown, Popover, Tooltip, Whisper } from "rsuite";
import APIV2 from "../../lib/APIV2";
import Event from "../../lib/Event";
import PubSub from "../../lib/PubSub";
import LinkUtils from "../../lib/LinkUtils";
import Constant from "lib/Constant";
import _ from "underscore";

const LinkPreview = ({ url }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [preview, setPreview] = useState(null);
  const [loadedURL, setLoadedURL] = useState("");

  useEffect(() => {
    if (url && url != loadedURL) {
      setLoading(true);
      setError(false);
      setLoadedURL(url);

      APIV2.getURLPreview(url)
        .then(
          (data) => {
            if (data.data.preview?.error) {
              setError(true);
              setPreview(null);

              return;
            }

            setPreview(data.data.preview);
          },
          (e) => {
            setError(true);
            setPreview(null);
          }
        )
        .finally(() => {
          setLoading(false);
        });
    }
  }, [url]);

  return (
    <>
      {loading ? (
        <>
          <div className="bg-white rounded border">
            <Row className="align-items-center">
              <Col xs="auto" style={{ maxWidth: 70 }}>
                <div
                  className="bg-lighter"
                  style={{ height: 70, width: 70 }}
                ></div>
              </Col>

              <Col xs="" style={{ maxWidth: "calc(100% - 70px)" }}>
                <div style={{ height: 70 }} className="p-2">
                  <div
                    className="skeleton"
                    style={{
                      width: "80%",
                      maxWidth: 300,
                      height: 15,
                      marginBottom: "0.5rem",
                    }}
                  ></div>
                  <div
                    className="skeleton"
                    style={{
                      width: "100%",
                      maxWidth: 450,
                      height: 12,
                      marginBottom: "0.5rem",
                    }}
                  ></div>
                  <div
                    className="skeleton"
                    style={{ width: "55%", maxWidth: 200, height: 12 }}
                  ></div>
                </div>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <>
          <div
            className="bg-white rounded border border-lighter cursor-pointer shadow--hover bg-ultralight--hover"
            onClick={() => {
              LinkUtils.openInNewTab(url);
            }}
          >
            {error ? (
              <>
                <Row className="align-items-center">
                  <Col xs="auto" style={{ maxWidth: 70 }}>
                    <div
                      className="bg-lighter"
                      style={{ height: 70, width: 70 }}
                    ></div>
                  </Col>

                  <Col xs="" style={{ maxWidth: "calc(100% - 70px)" }}>
                    <div style={{ height: 70 }} className="p-2">
                      <h4 className="text-truncate text-dark m-0">
                        No Preview Available
                      </h4>
                      <p
                        className="m-0 small text-dark text-truncate"
                        style={{ lineHeight: 1.4 }}
                      >
                        &nbsp;
                      </p>
                      <p className="text-muted text-truncate small m-0">
                        {preview?.url}
                      </p>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row className="align-items-center">
                  {preview?.images?.length ? (
                    <Col xs="auto" style={{ maxWidth: 70 }}>
                      <div
                        className="bg-white"
                        style={{ height: 70, width: 70 }}
                      >
                        <div
                          style={{
                            height: 70,
                            width: 70,
                            borderBottomLeftRadius: 4,
                            borderTopLeftRadius: 4,
                            background: `url(${preview?.images[0]}) center center / cover`,
                          }}
                          className="border-right"
                        ></div>
                      </div>
                    </Col>
                  ) : null}
                  {!preview?.images?.length && preview?.favicons?.length ? (
                    <Col xs="auto" style={{ maxWidth: 70 }}>
                      <div
                        className="bg-white"
                        style={{ height: 70, width: 70 }}
                      >
                        <div
                          style={{
                            height: 50,
                            width: 50,
                            borderBottomLeftRadius: 4,
                            borderTopLeftRadius: 4,
                            background: `url(${preview?.favicons[0]}) center center / cover`,
                            margin: 10,
                          }}
                          className="border-right"
                        ></div>
                      </div>
                    </Col>
                  ) : null}
                  <Col xs="" style={{ maxWidth: "calc(100% - 70px)" }}>
                    <div style={{ height: 70 }} className="p-2">
                      <h4 className="text-truncate text-dark m-0">
                        {preview?.title}
                      </h4>
                      <p
                        className="m-0 small text-dark text-truncate"
                        style={{ lineHeight: 1.4 }}
                      >
                        {preview?.description ? (
                          preview?.description
                        ) : (
                          <>&nbsp;</>
                        )}
                      </p>
                      <p className="text-muted text-truncate small m-0">
                        {preview?.url}
                      </p>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

const getAttachmentFileName = (url) => {
  var filename = url.substring(url.lastIndexOf("/") + 1);

  return filename ?? "1 Attachment";
};

const Linkify = ({ body }) => {
  const dropdownRef = useRef();
  const [copied, setCopied] = useState(false);

  const output = useMemo(() => {
    let inputText = body;

    let replacedText, replacePattern1, replacePattern2, replacePattern3;

    let bodyArr = [];

    if (body) {
      bodyArr = body?.split("\n");
    }

    let bodyComponents = [];
    let linkPreviews = [];

    for (let i = 0; i < bodyArr?.length; i++) {
      let html = false;

      let line = bodyArr[i];

      let bodyStringArr = line?.split(" ");

      for (let j = 0; j < bodyStringArr?.length; j++) {
        let bodyString = bodyStringArr[j];

        //URLs starting with http://, https://, or ftp://
        replacePattern1 =
          /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        const urlMatch1 = bodyString.match(replacePattern1);

        //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
        replacePattern2 =
          /^(((?!\-))(xn\-\-)?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*(xn\-\-)?([a-z0-9\-]{1,61}|[a-z0-9\-]{1,30})\.[a-z]{2,}$/gim;
        const urlMatch2 = bodyString.match(replacePattern2);

        replacePattern3 =
          /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
        const emailMatch = bodyString.match(replacePattern3);

        //console.log(bodyString, "Match", replacedText);

        if (urlMatch1?.length) {
          bodyComponents.push(
            <span>
              <a
                href={urlMatch1[0]}
                target="_blank"
                onClick={(e) => {
                  e.preventDefault();

                  LinkUtils.openInNewTab(urlMatch1[0]);
                }}
              >
                {urlMatch1[0]}
              </a>
            </span>
          );
          linkPreviews.push(<LinkPreview url={urlMatch1[0]}></LinkPreview>);
        } else if (urlMatch2?.length) {
          bodyComponents.push(
            <span>
              <a
                href={"https://" + urlMatch2[0]}
                target="_blank"
                onClick={(e) => {
                  e.preventDefault();

                  LinkUtils.openInNewTab("https://" + urlMatch2[0]);
                }}
              >
                {urlMatch2[0]}
              </a>
            </span>
          );
          linkPreviews.push(
            <LinkPreview url={"https://" + urlMatch2[0]}></LinkPreview>
          );
        } else if (emailMatch?.length) {
          bodyComponents.push(
            <span>
              <Whisper
                placement="auto"
                trigger={["click", "hover"]}
                enterable
                ref={dropdownRef}
                delayOpen={400}
                speaker={
                  <Popover full className="dropdown-popover">
                    <Dropdown.Menu
                      style={{
                        minWidth: 150,
                        paddingBottom: 0,
                      }}
                    >
                      <Dropdown.Item
                        panel
                        className="font-weight-800 text-uppercase"
                        style={{
                          padding: 12,
                        }}
                      >
                        <h5 className="m-0 text-dark">Actions</h5>
                      </Dropdown.Item>
                      <Dropdown.Item className="my-0" divider></Dropdown.Item>
                      <Dropdown.Item
                        disabled={copied}
                        onClick={() => {
                          //this.settingsDropdownRef.current.close();

                          if (
                            typeof navigator?.clipboard?.writeText == "function"
                          ) {
                            navigator.clipboard.writeText(emailMatch[0]);

                            setCopied(true);

                            setTimeout(() => {
                              setCopied(false);
                            }, 1000);
                          }

                          dropdownRef.current.close();
                        }}
                        className="text-dark bg-superlight--hover"
                      >
                        <Row className="align-items-center">
                          <Col xs="auto" className="pr-0">
                            <i
                              className={`mdi mdi-content-copy`}
                              style={{
                                fontSize: 16,
                                lineHeight: 1.3,
                              }}
                            ></i>
                          </Col>
                          <Col xs="" className="pl-2">
                            <p className="m-0" style={{ fontSize: 14 }}>
                              {copied ? "Copied!" : "Copy Email"}
                            </p>
                          </Col>
                        </Row>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          //this.settingsDropdownRef.current.close();

                          LinkUtils.openInNewTab("mailto:" + emailMatch[0]);

                          dropdownRef.current.close();
                        }}
                        className="text-dark bg-superlight--hover"
                      >
                        <Row className="align-items-center">
                          <Col xs="auto" className="pr-0">
                            <i
                              className={`mdi mdi-send-circle-outline`}
                              style={{
                                fontSize: 16,
                                lineHeight: 1.3,
                              }}
                            ></i>
                          </Col>
                          <Col xs="" className="pl-2">
                            <p className="m-0" style={{ fontSize: 14 }}>
                              Send Email
                            </p>
                          </Col>
                        </Row>
                      </Dropdown.Item>
                      {/*<Dropdown.Item
                        onClick={() => {
                          //this.settingsDropdownRef.current.close();

                          console.log("SET AS EMAIL");

                          dropdownRef.current.close();
                        }}
                        className="text-dark bg-superlight--hover"
                      >
                        <Row className="align-items-center">
                          <Col xs="auto" className="pr-0">
                            <i
                              className={`mdi mdi-account-edit`}
                              style={{
                                fontSize: 16,
                                lineHeight: 1.3,
                              }}
                            ></i>
                          </Col>
                          <Col xs="" className="pl-2">
                            <p className="m-0" style={{ fontSize: 14 }}>
                              Set As Contact's Email
                            </p>
                          </Col>
                        </Row>
                            </Dropdown.Item>*/}
                    </Dropdown.Menu>
                  </Popover>
                }
              >
                <a
                  href={"mailto:" + emailMatch[0]}
                  target="_blank"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  {emailMatch[0]}
                </a>
              </Whisper>
            </span>
          );
        } else {
          bodyComponents.push(<span>{bodyString}</span>);
        }

        if (j != bodyStringArr?.length - 1) {
          bodyComponents.push(<span>&nbsp;</span>);
        }
      }

      if (i != bodyArr?.length - 1) {
        bodyComponents.push(
          <>
            <br></br>
            {`\n`}
          </>
        );
      }
    }

    return (
      <div style={{ lineHeight: 1 }}>
        {bodyComponents?.map((comp) => comp)}
        {linkPreviews?.length ? (
          <>
            {linkPreviews?.map((preview, i) => (
              <>
                <div className={`mt-3`}>{preview}</div>
              </>
            ))}
          </>
        ) : null}
      </div>
    );
  }, [body]);

  return <>{output}</>;
};

const ConversationMessageItem = ({
  message = {},
  presentationMode = "",
  onClick = null,
  sent = false,
  skeleton = false,
  clickable = false,
}) => {
  const [resending, setResending] = useState(false);
  const [attachedImage, setAttachedImage] = useState(null);

  const getPrivateCDNURL = async (url) => {
    let result = await APIV2.getPrivateFileURL(url);

    if (result?.data?.url) {
      return result?.data?.url;
    }

    return null;
  };

  useEffect(() => {
    if (message?.mediaUrl && message?.mediaMimeType?.includes("image/")) {
      renderAttachedImage(
        message?.mediaUrl,
        message?.mediaMimeType,
        message?.mediaSource
      );
    } else {
      setAttachedImage(null);
    }
  }, [message?.mediaUrl, message?.mediaMimeType, message?.mediaSource]);

  const openAttachment = async (url, mimeType, source) => {
    if (source == "private-cdn") {
      let result = await getPrivateCDNURL(url);

      if (result) {
        LinkUtils.openInNewTab(result);
      }
    } else {
      LinkUtils.openInNewTab(url);
    }
  };

  const renderAttachedImage = async (url, mimeType, source) => {
    if (source == "private-cdn") {
      let result = await getPrivateCDNURL(url);

      if (result) {
        setAttachedImage(
          <>
            <img
              src={result}
              alt="Attachment"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                borderRadius: 4,
              }}
              className="cursor-pointer"
              onClick={() => {
                openAttachment(url, mimeType, source);
              }}
            ></img>
          </>
        );
      }
    } else {
      setAttachedImage(
        <>
          <img
            src={url}
            alt="Attachment"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              borderRadius: 4,
            }}
            className="cursor-pointer"
            onClick={() => {
              openAttachment(url, mimeType, source);
            }}
          ></img>
        </>
      );
    }
  };

  const formatSentAt = (date) => {
    if (!date) {
      return "";
    }

    let mom = moment(date);
    let compare = moment().subtract(2, "days");

    let now = new Date();

    if (mom?.toDate() < compare?.toDate()) {
      return mom.format("MM/DD/YY hh:mm A");
    }

    return mom.fromNow();
  };

  const getMessageError = (message) => {
    if (message?.birdMessageID) {
      let error = "";

      if (
        message?.sendError?.code &&
        Constant.BIRD_ERROR_CODES.hasOwnProperty(
          parseInt(message?.sendError?.code)
        )
      ) {
        error =
          Constant.BIRD_ERROR_CODES[parseInt(message?.sendError?.code)]
            ?.DESCRIPTION;
      } else {
        error = "Unknown error.";
      }

      return error;
    }

    if (message?.sendError?.moreInfo?.includes("twilio")) {
      return (
        <>
          Twilio Returned Error Code: {message?.sendError?.code}{" "}
          <a
            href={message?.sendError?.moreInfo}
            target="_blank"
            onClick={(e) => {
              e.preventDefault();

              LinkUtils.openInNewTab(message?.sendError?.moreInfo);
            }}
          >
            Click here for more info
          </a>
        </>
      );
    }

    return "Unknown error.";
  };

  const resendMessage = () => {
    setResending(true);

    APIV2.resendConversationMessage(message?.conversationID, message?._id)
      .then(
        (data) => {
          // TODO: handle this

          let message = data.data.message;

          PubSub.publish(Event.CONVERSATION.MESSAGE_MODIFIED, message);
        },
        (e) => {}
      )
      .finally(() => {
        setResending(false);
      });
  };

  const messageBody = useMemo(() => {
    return (
      <>
        {message?.body ? (
          <Linkify body={message?.body} />
        ) : (
          <>{message?.mediaUrl ? null : <p>(empty message)</p>}</>
        )}
      </>
    );
  }, [message?.body]);

  return (
    <>
      {skeleton ? (
        <>
          <div
            className={`conversation-message  ${sent ? "sent" : "received"}`}
          >
            <div className="message-body">
              <div className="skeleton" style={{ height: 40 }}></div>
            </div>
            <div className="message-status text-right mt-2">
              <div
                className="skeleton"
                style={{ height: 12, width: 100, marginLeft: "auto" }}
              ></div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            onClick={() => {
              if (typeof onClick == "function") {
                onClick(message);
              }
            }}
            className={`conversation-message ${
              clickable
                ? "bg-ultralight--hover shadow--hover cursor-pointer"
                : ""
            } ${sent ? "sent" : "received"} ${
              message?.presentAs == "note" ? "note" : ""
            }`}
          >
            {message?.type == "automation" && (
              <div className="message-info mb-2">
                <div className="p-1 border border-dark text-dark rounded d-inline-block">
                  <p
                    className="m-0 small font-weight-bold text-uppercase"
                    style={{ lineHeight: 1.2, fontSize: 11 }}
                  >
                    <i
                      className="mdi mdi-lightning-bolt-circle pr-1"
                      style={{ lineHeight: 1 }}
                    ></i>
                    Automation
                  </p>
                </div>
              </div>
            )}
            {message?.type == "broadcast" && (
              <div className="message-info mb-2">
                <div className="p-1 border border-dark text-dark rounded d-inline-block">
                  <p
                    className="m-0 small font-weight-bold text-uppercase"
                    style={{ lineHeight: 1.2, fontSize: 11 }}
                  >
                    <i
                      className="mdi mdi-bullhorn pr-1"
                      style={{ lineHeight: 1 }}
                    ></i>
                    Broadcast
                  </p>
                </div>
              </div>
            )}
            {message?.type == "note" && (
              <div className="message-info mb-2">
                <div className="p-1 border border-dark text-dark rounded d-inline-block">
                  <p
                    className="m-0 small font-weight-bold text-uppercase"
                    style={{ lineHeight: 1.2, fontSize: 11 }}
                  >
                    <i
                      className="mdi mdi-note pr-1"
                      style={{ lineHeight: 1 }}
                    ></i>
                    Note
                  </p>
                </div>
              </div>
            )}
            <div
              className="message-body text-dark"
              style={{ wordBreak: "break-all" }}
            >
              {messageBody}
            </div>
            {message?.mediaUrl ? (
              <>
                {message?.mediaMimeType &&
                message?.mediaMimeType?.includes("image/") ? (
                  <>
                    <div>
                      <div
                        style={{ height: 250 }}
                        className="rounded border p-1 bg-white d-inline-block"
                      >
                        {attachedImage}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mt-2 text-dark" style={{ fontSize: 14 }}>
                      Attached:{" "}
                      <a
                        href={message?.mediaUrl}
                        target="_blank"
                        onClick={(e) => {
                          e.preventDefault();

                          openAttachment(
                            message?.mediaUrl,
                            message?.mediaMimeType,
                            message?.mediaSource
                          );
                        }}
                      >
                        {getAttachmentFileName(message?.mediaUrl)}
                      </a>
                    </div>
                  </>
                )}
              </>
            ) : null}
            <div className="message-status mt-2">
              <Row className="align-items-center">
                <Col xs="auto" className="text-left">
                  {message?.status == "UNREAD" ? (
                    <p
                      className="small m-0 text-primary"
                      style={{ lineHeight: 1.2 }}
                    >
                      <i className="mdi mdi-circle "></i> New
                    </p>
                  ) : null}
                  {message?.status == "SEND_FAILED" ? (
                    <>
                      <Button
                        color="danger"
                        size="sm"
                        outline
                        className="border-0 m-0 p-1"
                        style={{ lineHeight: 1.3 }}
                        onClick={() => {
                          resendMessage();
                        }}
                        disabled={resending}
                      >
                        {resending ? (
                          <>Retrying...</>
                        ) : (
                          <>
                            <i
                              className="mdi mdi-message-alert"
                              style={{ position: "relative", top: 1 }}
                            ></i>{" "}
                            Message Failed. Retry
                          </>
                        )}
                      </Button>
                    </>
                  ) : null}
                  {message?.status == "SEND_QUEUED" ? (
                    <>
                      <Whisper
                        trigger="hover"
                        placement="autoHorizontal"
                        delayOpen={400}
                        speaker={<Tooltip>Message Queued</Tooltip>}
                      >
                        <p className="small m-0" style={{ lineHeight: 1.2 }}>
                          <i className="mdi mdi-circle-outline"></i>
                        </p>
                      </Whisper>
                    </>
                  ) : null}
                  {message?.status == "SEND_SUCCESS" ? (
                    <>
                      <Whisper
                        trigger="hover"
                        placement="autoHorizontal"
                        delayOpen={400}
                        speaker={<Tooltip>Message Sent</Tooltip>}
                      >
                        <p className="small m-0" style={{ lineHeight: 1.2 }}>
                          <i className="mdi mdi-arrow-right-thin-circle-outline"></i>
                        </p>
                      </Whisper>
                    </>
                  ) : null}
                  {message?.status == "DELIVERED" ? (
                    <>
                      <Whisper
                        trigger="hover"
                        placement="autoHorizontal"
                        delayOpen={400}
                        speaker={<Tooltip>Message Delivered</Tooltip>}
                      >
                        <p className="small m-0" style={{ lineHeight: 1.2 }}>
                          <i className="mdi mdi-check-circle-outline"></i>
                        </p>
                      </Whisper>
                    </>
                  ) : null}
                </Col>
                <Col xs="" className="text-right">
                  <div className="d-inline-block ml-auto">
                    <Whisper
                      placement="autoVertical"
                      trigger="hover"
                      delayOpen={300}
                      speaker={
                        <Tooltip>
                          {sent
                            ? message?.presentAs == "note" ||
                              message?.type == "note"
                              ? "Added at"
                              : "Sent at"
                            : "Received at"}
                          &nbsp;
                          {moment(message?.messageTimestamp).format(
                            "MM/DD/YY hh:mm A"
                          )}
                        </Tooltip>
                      }
                    >
                      <div className="cursor-pointer">
                        {sent ? (
                          <p
                            className="small m-0 text-right"
                            style={{ lineHeight: 1.2 }}
                          >
                            {formatSentAt(message?.messageTimestamp)} by{" "}
                            {message?.type == "automation" ||
                            message?.type == "broadcast"
                              ? `${message?.automationName ?? "Automation"}`
                              : message?.sentBy?.name}
                          </p>
                        ) : (
                          <p
                            className="small m-0 text-right"
                            style={{ lineHeight: 1.2 }}
                          >
                            {formatSentAt(message?.messageTimestamp)} via{" "}
                            {message.provider}
                          </p>
                        )}
                      </div>
                    </Whisper>
                  </div>
                </Col>
                {message?.status == "SEND_FAILED" ? (
                  <Col xs="12" className="mt-2">
                    <div className="border-danger border rounded p-1">
                      <Row className="align-items-center">
                        <Col xs="auto" className="pr-0">
                          <i className="mdi mdi-alert-circle-outline text-danger"></i>
                        </Col>
                        <Col xs="" className="pl-1">
                          <p className="small m-0">
                            <span className="font-weight-bold">
                              Sending Error:
                            </span>{" "}
                            {getMessageError(message)}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                ) : null}
              </Row>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default withRouter(ConversationMessageItem);
