import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
  Collapse,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import _, { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import { Dropdown, Popover, Tooltip, Whisper } from "rsuite";
import swal from "sweetalert";
import LibraryCategoryEditDrawer from "components/Drawers/LibraryCategoryEditDrawer";
import LibraryModuleEditDrawer from "components/Drawers/LibraryModuleEditDrawer";
import LibraryAllModulesDrawer from "components/Drawers/LibraryAllModulesDrawer";
import { convertTypeAcquisitionFromJson } from "typescript";
import LibraryAllTopicsDrawer from "components/Drawers/LibraryAllTopicsDrawer";
import { Capacitor } from "@capacitor/core";

class LearningCenterTrackView extends React.Component {
  state = {
    active: 0,
    loadingCategories: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    openCategory: false,
  };

  constructor() {
    super();

    this.dropdownRef = React.createRef();
  }

  /**
   * Fetches all categories.
   */
  getCategories() {
    this.setState({
      loadingCategories: true,
    });

    if (LocalStorage.get("libraryCategories")) {
      let categories = LocalStorage.get("libraryCategories");

      categories = categories?.map((cat) => {
        return { ...cat, open: false };
      });

      this.setState({
        categories,
        loadingCategories: false,
      });
    }

    let loadInternal = this.isGlobal() || this.isProduction() ? true : false;

    APIV2.getLibraryCategories(loadInternal)

      .then(
        (data) => {
          this.setState(
            {
              categories: _.sortBy(
                data?.data?.categories?.map((cat) => {
                  return {
                    ...cat,
                    open: this.state.categories?.length
                      ? _.findWhere(this.state.categories, { _id: cat?._id })
                          ?.open
                        ? true
                        : false
                      : false,
                  };
                }),
                "order"
              ),
            },
            () => {
              LocalStorage.set("libraryCategories", this.state.categories);
            }
          );
        },
        () => {
          window.alert("Failed to get categories. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          loadingCategories: false,
        });
      });
  }

  /**
   * Event handler for after a category is created
   * @param {} category
   */
  onCreateCategory(category) {
    let categories = this.state.categories;

    categories.push(category);

    this.setState({ categories, openCategory: false });
  }

  /**
   * Event handler for when a module is added to a category
   *
   * @param {*} category
   * @param {*} module
   */
  onCreateCategoryModule(category, module) {
    let categories = this.state.categories;

    const idx = _.findIndex(categories, { _id: category?._id });

    if (idx >= 0) {
      categories[idx] = category;
    }

    this.setState({
      categories,
      openModule: false,
      modifyModule: null,
      modifyCategory: null,
    });
  }

  /**
   * Event handler for after a category is modified.
   *
   * @param {*} category
   */
  onModifyCategory(category) {
    let categories = this.state.categories;

    const idx = _.findIndex(categories, { _id: category?._id });

    if (idx >= 0) {
      categories[idx] = category;
    }

    this.setState({ categories, openCategory: false });
  }

  /**
   * Event handler for when module is modified
   *
   * @param {*} category
   */
  onModifyModule(module) {
    let categories = this.state.categories;

    for (let i = 0; i < categories?.length; i++) {
      const idx = _.findIndex(categories[i]?.modules, {
        moduleID: module?._id,
      });

      if (idx < 0) {
        continue;
      }

      categories[i].modules[idx].module = module;
    }

    this.setState({ categories, openModule: false });
  }

  /**
   * Handles deleting a category
   *
   * @param {*} category
   */
  deleteCategory(category) {
    swal({
      title: "Delete Category",
      text: "Are you sure you want delete this category?",
      icon: "warning",
      buttons: ["Nevermind", "Delete"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        deletingCategory: category?._id,
      });

      APIV2.deleteLibraryCategory(category?._id)
        .then(
          () => {
            let categories = this.state.categories;

            categories = _.filter(categories, (cat) => {
              return cat?._id != category?._id;
            });

            this.setState({ categories: _.sortBy(categories, "order") });
          },
          (e) => {
            alert("Unable to delete category. Please try again.");
          }
        )
        .finally(() => {
          this.setState({ deletingCategory: "" });
        });
    });
  }

  componentDidMount() {
    this.getCategories();
  }

  /**
   * Checks if the current user is a global user via permissions
   *
   * @returns
   */
  isGlobal() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  /**
   * Checks if the current user is a global user via permissions
   *
   * @returns
   */
  isProduction() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.productionPermissions?.length ? true : false;
  }

  /**
   * Helper function to handle reorder events from react-beautiful-dnd library
   *
   * @param {*} list
   * @param {*} startIndex
   * @param {*} endIndex
   * @returns
   */
  reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  /**
   * Event handler for when a category drag has ended.
   *
   * @param {*} result
   * @returns
   */
  onCategoryDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.categories,
      result.source.index,
      result.destination.index
    );

    this.setState({
      categories: items,
    });
  }

  onModuleDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.moduleOrder,
      result.source.index,
      result.destination.index
    );

    this.setState({
      moduleOrder: items,
    });
  }

  saveCategoryOrder() {
    if (!this.state.categories?.length) {
      this.setState({
        reorderCategories: false,
      });

      return;
    }

    this.setState({
      reorderCategoriesSubmitting: true,
    });

    let order = this.state.categories.map((category, i) => {
      return { categoryID: category?._id, order: i + 1 };
    });

    APIV2.reorderLibraryCategories(order)
      .then(
        (data) => {
          this.setState({
            categories: data.data?.categories,
            reorderCategories: false,
          });
        },
        (e) => {
          alert("Unable to save order of categories. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          reorderCategoriesSubmitting: false,
        });
      });
  }

  saveModuleOrder(category, modules) {
    if (!modules?.length) {
      this.setState({
        reorderModules: false,
      });

      return;
    }

    this.setState({
      reorderModulesSubmitting: true,
    });

    let order = modules.map((module, i) => {
      return { moduleID: module?.moduleID, order: i + 1 };
    });

    APIV2.reorderLibraryCategoryModules(category?._id, order)
      .then(
        (data) => {
          let categories = this.state.categories;

          const idx = _.findIndex(categories, (cat) => {
            return cat?._id == data.data.category?._id;
          });

          console.log(data.data.categories);

          if (idx >= 0) {
            categories[idx] = data.data.category;
          }

          this.setState({
            categories: _.sortBy(categories, "order"),
            reorderModules: false,
            reorderModulesSubmitting: false,
          });
        },
        (e) => {
          alert("Unable to save order of modules. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          reorderCategoriesSubmitting: false,
        });
      });
  }

  removeModuleFromCategory(category, module) {
    swal({
      title: "Remove Module From Category",
      text: "Are you sure you want remove this module from the category?",
      icon: "warning",
      buttons: ["Nevermind", "Remove"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        removingModule: module?._id,
      });

      APIV2.removeLibraryModuleFromCategory(category?._id, module?._id)
        .then(
          (data) => {
            let categories = this.state.categories;

            const idx = _.findIndex(categories, (cat) => {
              return cat?._id == data.data.category?._id;
            });

            if (idx >= 0) {
              categories[idx] = data.data.category;
            }

            this.setState({ categories: _.sortBy(categories, "order") });
          },
          (e) => {
            alert("Unable to remove module from category. Please try again.");
          }
        )
        .finally(() => {
          this.setState({ removingModule: "" });
        });
    });
  }

  submitPrompt(prompt) {
    this.setState({
      searching: true,
    });

    APIV2.ragSearchLibraryTopics(this.state.prompt)
      .then(
        (data) => {
          let response = data?.data?.response;

          this.setState({
            searchResponse: response,
          });
        },
        (e) => {
          this.setState({
            searchResponse: null,
            searchError: true,
          });
        }
      )
      .finally(() => {
        this.setState({
          searching: false,
        });
      });
  }

  render() {
    return (
      <>
        <div
          className="bg-white border-bottom px-4 py-4 py-md-5 mb-4"
          style={{ marginTop: "5rem" }}
        >
          {/* Page content */}
          {Capacitor.isNativePlatform() && (
            <div className="pt-5 d-md-none"></div>
          )}
          <Row className="align-items-center">
            <Col xs="12">
              <h1 className="m-0 display-3 text-dark">Resource Center</h1>
              <p className="m-0 text-dark font-weight-500">
                A space to search and browse for answers, processes, guides, and
                documentation.
              </p>
            </Col>
            <Col xs="12" className="mt-4">
              <div
                className={`p-3 rounded border border-midlighter ${
                  this.state.searching ||
                  this.state.searchResponse ||
                  this.state.searchError
                    ? "shadow"
                    : ""
                }`}
              >
                <div>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();

                      this.submitPrompt(this.state.prompt);
                    }}
                  >
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h2 className="m-0 text-dark">
                          <Badge
                            color="success"
                            className="py-1 mr-2"
                            style={{ position: "relative", top: -1 }}
                          >
                            BETA
                          </Badge>
                          Instant Answers
                        </h2>
                        <p
                          className="text-dark mt-2 mb-3"
                          style={{ lineHeight: 1.3 }}
                        >
                          Receive instant answers to your questions directly
                          from the Resource Center using GPT-powered search.
                        </p>
                      </Col>
                      <Col xs="">
                        <Input
                          style={{
                            padding: 7,
                            paddingLeft: 10,
                            paddingRight: 10,
                            height: 38,
                          }}
                          size=""
                          value={this.state.prompt}
                          onChange={(e) => {
                            this.setState({ prompt: e.target.value });
                          }}
                          placeholder="Ask a question..."
                        />
                      </Col>
                      <Col xs="auto" className="pl-0">
                        <Button
                          className="btn-icon-only"
                          color="primary"
                          type="submit"
                          disabled={!this.state.prompt || this.state.searching}
                        >
                          {this.state.searching ? (
                            <>
                              <Spinner size="sm"></Spinner>
                            </>
                          ) : (
                            <>
                              <i className="mdi mdi-chevron-right"></i>
                            </>
                          )}
                        </Button>
                      </Col>
                      <Col xs="12">
                        <div className="mt-1 text-dark small">
                          Instant Answers is in BETA and can make mistakes. It
                          uses the Resource Center as it's knowledge base. Check
                          important info.
                        </div>
                      </Col>
                    </Row>
                  </form>
                </div>
                {this.state.searching ? (
                  <div
                    className="mt-3 rounded skeleton"
                    style={{ height: 400 }}
                  ></div>
                ) : null}
                {this.state.searchResponse && !this.state.searching ? (
                  <>
                    <div style={{ position: "relative" }}>
                      <div
                        className="p-3"
                        style={{ position: "absolute", top: 0, right: 0 }}
                      >
                        <Button
                          size="sm"
                          color="dark"
                          outline
                          className="btn-icon-only"
                          onClick={() => {
                            this.setState({
                              searchResponse: null,
                              searchError: false,
                              prompt: "",
                            });
                          }}
                        >
                          <i className="mdi mdi-close"></i>
                        </Button>
                      </div>
                      <div
                        className="rounded pb-3 pt-5 mt-3 px-3 border border-lighter university-gpt-response-container"
                        dangerouslySetInnerHTML={{
                          __html: this.state.searchResponse,
                        }}
                      ></div>
                      <div className="mt-1 text-dark small text-center">
                        Instant Answers is in BETA and can make mistakes. Check
                        important info.
                      </div>
                    </div>
                  </>
                ) : null}
                {this.state.searchError && !this.state.searching ? (
                  <div style={{ position: "relative" }}>
                    <div
                      className="p-3"
                      style={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <Button
                        size="sm"
                        color="dark"
                        outline
                        className="btn-icon-only"
                        onClick={() => {
                          this.setState({
                            searchResponse: null,
                            searchError: false,
                            prompt: "",
                          });
                        }}
                      >
                        <i className="mdi mdi-close"></i>
                      </Button>
                    </div>
                    <div className="text-center mt-3 px-3 pb-3 pt-5 mt-3 rounded border border-lighter">
                      <p className="m-0 text-danger">
                        Unable to fetch search results. An error occurred.
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
        </div>

        <Container fluid>
          {/* Table */}
          {this.isGlobal() && (
            <>
              <div className="text-right mb-4">
                <Button
                  onClick={() => {
                    this.setState({ editMode: !this.state.editMode });
                  }}
                  color="dark"
                  outline
                  className=""
                >
                  {this.state.editMode ? "Done Editing" : "Edit"}
                </Button>
              </div>
            </>
          )}
          {this.isGlobal() && this.state.editMode && (
            <>
              <Row className="align-items-center mb-4">
                <Col xs="">
                  <Button
                    color="secondary"
                    outline
                    onClick={() => {
                      this.setState({ openAllModules: true });
                    }}
                  >
                    All Modules
                  </Button>
                  <Button
                    color="secondary"
                    outline
                    onClick={() => {
                      this.setState({ openAllTopics: true });
                    }}
                  >
                    All Topics
                  </Button>
                </Col>
                <Col xs="auto">
                  {this.state.reorderCategories ? (
                    <>
                      <Button
                        color="secondary"
                        outline
                        onClick={() => {
                          this.setState({
                            reorderCategories: false,
                            categories: this.state.originalCategories,
                          });
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        disabled={this.state.reorderCategoriesSubmitting}
                        onClick={() => {
                          this.saveCategoryOrder();
                        }}
                      >
                        {this.state.reorderCategoriesSubmitting ? (
                          <>
                            <Spinner size="sm"></Spinner>
                          </>
                        ) : (
                          <>Save Order</>
                        )}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Whisper
                        trigger="hover"
                        placement="autoHorizontal"
                        delayOpen={400}
                        speaker={
                          <Tooltip>
                            <p className="m-0">Reorder categories</p>
                          </Tooltip>
                        }
                      >
                        <Button
                          color="secondary"
                          outline
                          className="btn-icon-only"
                          onClick={() => {
                            this.setState({
                              reorderCategories: true,
                              originalCategories: JSON.parse(
                                JSON.stringify(this.state.categories)
                              ),
                            });
                          }}
                        >
                          <i className="mdi mdi-swap-vertical-bold"></i>
                        </Button>
                      </Whisper>

                      <Whisper
                        trigger="hover"
                        placement="autoHorizontal"
                        delayOpen={400}
                        speaker={
                          <Tooltip>
                            <p className="m-0">Add category</p>
                          </Tooltip>
                        }
                      >
                        <Button
                          color="primary"
                          className="btn-icon-only"
                          onClick={() => {
                            this.setState({ openCategory: true });
                          }}
                        >
                          <i className="mdi mdi-plus"></i>
                        </Button>
                      </Whisper>
                    </>
                  )}
                </Col>
              </Row>
            </>
          )}
          {this.state.loadingCategories ? (
            <>
              <Card className="border">
                <CardBody>
                  <Row className="align-items-center">
                    <Col xs="auto">
                      <Spinner color="dark"></Spinner>
                    </Col>
                    <Col xs="">
                      <h2 className="m-0">Hang Tight...</h2>
                      <p className="m-0">Fetching your library now.</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </>
          ) : (
            <>
              {this.state.categories?.length ? (
                <>
                  {this.state.reorderCategories ? (
                    <>
                      <DragDropContext
                        onDragEnd={this.onCategoryDragEnd.bind(this)}
                      >
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                background: snapshot.isDraggingOver
                                  ? "var(--primary-superlight)"
                                  : "",
                                borderRadius: 6,
                              }}
                            >
                              {this.state.categories.map((category, index) => (
                                <Draggable
                                  key={category._id}
                                  draggableId={category._id}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className="my-2"
                                    >
                                      <Card
                                        className={`cursor-pointer ${
                                          snapshot.isDragging ? "shadow" : ""
                                        } border`}
                                      >
                                        <CardBody>
                                          <Row className="align-items-center">
                                            <Col
                                              xs="auto"
                                              className="pr-0 pl-2"
                                            >
                                              <i
                                                className="mdi mdi-drag text-dark"
                                                style={{ fontSize: 25 }}
                                              ></i>
                                            </Col>
                                            <Col xs="">
                                              <h3 className="m-0 text-dark">
                                                {category?.name}
                                              </h3>
                                              <p className="m-0 small text-muted">
                                                {category?.description}
                                              </p>
                                            </Col>
                                          </Row>
                                        </CardBody>
                                      </Card>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </>
                  ) : (
                    <>
                      {this.state.categories?.map((category, i) => (
                        <Card
                          className="border shadow bg-white mb-3"
                          key={category?._id}
                        >
                          <CardHeader className="cursor-pointer">
                            <Row className="align-items-center">
                              <Col
                                xs="auto"
                                className="pr-0 pl-2"
                                onClick={() => {
                                  let categories = this.state.categories;

                                  const idx = _.findIndex(categories, {
                                    _id: category?._id,
                                  });

                                  if (idx >= 0) {
                                    categories[idx].open =
                                      !categories[idx].open;
                                  }

                                  this.setState({
                                    categories,
                                  });
                                }}
                              >
                                <Button
                                  color="dark"
                                  outline
                                  className="border-0 btn-icon-only"
                                >
                                  <i
                                    className={`mdi mdi-${
                                      category?.open
                                        ? "chevron-down"
                                        : "chevron-right"
                                    }`}
                                    style={{ fontSize: 28 }}
                                  ></i>
                                </Button>
                              </Col>
                              <Col
                                xs=""
                                onClick={() => {
                                  let categories = this.state.categories;

                                  const idx = _.findIndex(categories, {
                                    _id: category?._id,
                                  });

                                  if (idx >= 0) {
                                    categories[idx].open =
                                      !categories[idx].open;
                                  }

                                  this.setState({
                                    categories,
                                  });
                                }}
                              >
                                <h2 className="m-0 text-dark">
                                  {category?.internal ? (
                                    <Badge
                                      color="light"
                                      className="mr-2 border"
                                      style={{ position: "relative", top: -1 }}
                                    >
                                      INTERNAL
                                    </Badge>
                                  ) : null}
                                  {category?.name}
                                </h2>
                                {category?.description ? (
                                  <>
                                    <p className="m-0 text-muted">
                                      {category?.description}
                                    </p>
                                  </>
                                ) : null}
                              </Col>
                              {this.isGlobal() &&
                                this.state.editMode &&
                                this.state.reorderModules != category?._id && (
                                  <Col xs="auto">
                                    <Whisper
                                      trigger="hover"
                                      placement="autoHorizontal"
                                      delayOpen={400}
                                      speaker={
                                        <Tooltip>
                                          <p className="m-0">
                                            Modify category name &amp;
                                            description
                                          </p>
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        size="sm"
                                        color="dark"
                                        outline
                                        className="btn-icon-only"
                                        onClick={() => {
                                          this.setState({
                                            modifyCategory: category,
                                            openCategory: true,
                                          });
                                        }}
                                      >
                                        <i className="mdi mdi-pencil"></i>
                                      </Button>
                                    </Whisper>

                                    <Whisper
                                      trigger="hover"
                                      placement="autoHorizontal"
                                      delayOpen={400}
                                      speaker={
                                        <Tooltip>
                                          <p className="m-0">Add module</p>
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        size="sm"
                                        color="primary"
                                        className="btn-icon-only"
                                        onClick={() => {
                                          this.setState({
                                            openModule: true,
                                            modifyCategory: category,
                                            modifyModule: null,
                                          });
                                        }}
                                      >
                                        <i className="mdi mdi-plus"></i>
                                      </Button>
                                    </Whisper>
                                  </Col>
                                )}
                            </Row>
                          </CardHeader>
                          <Collapse isOpen={category?.open}>
                            <CardBody
                              className={`${
                                this.state.reorderModules == category?._id
                                  ? "px-2 py-2"
                                  : "p-0"
                              }`}
                            >
                              {this.state.reorderModules == category?._id ? (
                                <>
                                  <DragDropContext
                                    onDragEnd={this.onModuleDragEnd.bind(this)}
                                  >
                                    <Droppable droppableId="droppable">
                                      {(provided, snapshot) => (
                                        <div
                                          {...provided.droppableProps}
                                          ref={provided.innerRef}
                                          style={{
                                            background: snapshot.isDraggingOver
                                              ? "var(--primary-superlight)"
                                              : "",
                                            borderRadius: 0,
                                          }}
                                        >
                                          {this.state.moduleOrder.map(
                                            (module, index) => (
                                              <Draggable
                                                key={module?.moduleID}
                                                draggableId={module?.moduleID}
                                                index={index}
                                              >
                                                {(provided, snapshot) => (
                                                  <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={`py-2 mx-2`}
                                                  >
                                                    <Card
                                                      className={`cursor-pointer ${
                                                        snapshot.isDragging
                                                          ? "shadow"
                                                          : ""
                                                      } border`}
                                                    >
                                                      <CardBody className="py-2 px-2">
                                                        <Row className="align-items-center">
                                                          <Col
                                                            xs="auto"
                                                            className="pr-0 pl-3"
                                                          >
                                                            <i
                                                              className="mdi mdi-drag text-dark"
                                                              style={{
                                                                fontSize: 25,
                                                              }}
                                                            ></i>
                                                          </Col>
                                                          <Col xs="">
                                                            <h3 className="m-0 text-dark">
                                                              {
                                                                module?.module
                                                                  ?.name
                                                              }
                                                            </h3>
                                                            <p className="m-0 small text-muted">
                                                              {
                                                                module?.module
                                                                  ?.description
                                                              }
                                                            </p>
                                                          </Col>
                                                        </Row>
                                                      </CardBody>
                                                    </Card>
                                                  </div>
                                                )}
                                              </Draggable>
                                            )
                                          )}
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Droppable>
                                  </DragDropContext>
                                </>
                              ) : (
                                <>
                                  {category?.modules?.length ? (
                                    <>
                                      {category?.modules?.map((module, k) => (
                                        <div
                                          className={`px-4 py-3 ${
                                            module?.internal &&
                                            !this.isGlobal() &&
                                            !this.isProduction() &&
                                            "d-none"
                                          } ${
                                            k !=
                                              category?.modules?.length - 1 &&
                                            "border-bottom"
                                          } bg-superlight--hover cursor-pointer`}
                                          key={module?.module?._id}
                                        >
                                          <Row className="align-items-center">
                                            <Col
                                              xs=""
                                              onClick={() => {
                                                this.props.history.push(
                                                  `/university/library/modules/${module?.module?._id}`
                                                );
                                              }}
                                            >
                                              <h3 className="m-0">
                                                {module?.internal ? (
                                                  <Badge
                                                    color="light"
                                                    className="mr-2 border"
                                                    style={{
                                                      position: "relative",
                                                      top: -1,
                                                    }}
                                                  >
                                                    INTERNAL
                                                  </Badge>
                                                ) : null}
                                                {module?.module?.name}
                                              </h3>
                                              {module?.module?.description ? (
                                                <>
                                                  <p className="small m-0">
                                                    {
                                                      module?.module
                                                        ?.description
                                                    }
                                                  </p>
                                                </>
                                              ) : null}
                                            </Col>
                                            <Col xs="auto">
                                              {this.isGlobal() &&
                                                this.state.editMode && (
                                                  <Whisper
                                                    ref={this.dropdownRef}
                                                    trigger="click"
                                                    placement={
                                                      "autoVerticalEnd"
                                                    }
                                                    speaker={
                                                      <Popover full>
                                                        <Dropdown.Menu>
                                                          <Dropdown.Item
                                                            onClick={() => {
                                                              this.setState({
                                                                modifyCategory:
                                                                  category,
                                                                modifyModule:
                                                                  module?.module,
                                                                openModule: true,
                                                              });

                                                              this.dropdownRef.current.close();
                                                            }}
                                                          >
                                                            Modify
                                                          </Dropdown.Item>
                                                          <Dropdown.Item
                                                            divider
                                                          ></Dropdown.Item>
                                                          <Dropdown.Item
                                                            className="text-danger"
                                                            onClick={() => {
                                                              this.dropdownRef.current.close();

                                                              this.removeModuleFromCategory(
                                                                category,
                                                                module?.module
                                                              );
                                                            }}
                                                          >
                                                            Remove From Category
                                                          </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                      </Popover>
                                                    }
                                                  >
                                                    <Button
                                                      size="sm"
                                                      color="dark"
                                                      outline
                                                      className="btn-icon-only"
                                                    >
                                                      <i className="mdi mdi-dots-horizontal"></i>
                                                    </Button>
                                                  </Whisper>
                                                )}
                                              <Button
                                                size="sm"
                                                color="dark"
                                                outline
                                                className="btn-icon-only border-0"
                                                onClick={() => {
                                                  this.props.history.push(
                                                    `/university/library/modules/${module?.module?._id}`
                                                  );
                                                }}
                                              >
                                                <i className="mdi mdi-chevron-right"></i>
                                              </Button>
                                            </Col>
                                          </Row>
                                        </div>
                                      ))}
                                    </>
                                  ) : (
                                    <>
                                      <p className="m-0 p-4">
                                        Category is empty.
                                        {this.isGlobal() &&
                                          this.state.editMode &&
                                          " Add a module to get started."}
                                      </p>
                                    </>
                                  )}
                                </>
                              )}
                            </CardBody>
                            {this.isGlobal() && this.state.editMode && (
                              <>
                                <CardFooter>
                                  <Row className="align-items-center">
                                    <Col xs="">
                                      {this.state.reorderModules !=
                                        category?._id && (
                                        <Whisper
                                          trigger="hover"
                                          placement="autoHorizontal"
                                          delayOpen={400}
                                          speaker={
                                            <Tooltip>
                                              <p className="m-0">
                                                Delete category
                                              </p>
                                            </Tooltip>
                                          }
                                        >
                                          <Button
                                            size="sm"
                                            color="danger"
                                            outline
                                            className="btn-icon-only"
                                            onClick={() =>
                                              this.deleteCategory(category)
                                            }
                                          >
                                            <i className="mdi mdi-trash-can"></i>
                                          </Button>
                                        </Whisper>
                                      )}
                                    </Col>
                                    <Col xs="auto">
                                      {this.state.reorderModules ==
                                      category?._id ? (
                                        <>
                                          <Button
                                            color="secondary"
                                            outline
                                            size="sm"
                                            onClick={() => {
                                              this.setState({
                                                reorderModules: false,
                                                moduleOrder: [],
                                              });
                                            }}
                                          >
                                            Cancel
                                          </Button>
                                          <Button
                                            color="primary"
                                            size="sm"
                                            disabled={
                                              this.state
                                                .reorderModulesSubmitting
                                            }
                                            onClick={() => {
                                              this.saveModuleOrder(
                                                category,
                                                this.state.moduleOrder
                                              );
                                            }}
                                          >
                                            {this.state
                                              .reorderModulesSubmitting ? (
                                              <>
                                                <Spinner size="sm"></Spinner>
                                              </>
                                            ) : (
                                              <>Save Order</>
                                            )}
                                          </Button>
                                        </>
                                      ) : (
                                        <Whisper
                                          trigger="hover"
                                          placement="autoHorizontal"
                                          delayOpen={400}
                                          speaker={
                                            <Tooltip>
                                              <p className="m-0">
                                                Reorder modules
                                              </p>
                                            </Tooltip>
                                          }
                                        >
                                          <Button
                                            className="btn-icon-only"
                                            size="sm"
                                            outline
                                            color="dark"
                                            onClick={() => {
                                              this.setState({
                                                reorderModules: category?._id,
                                                moduleOrder: JSON.parse(
                                                  JSON.stringify(
                                                    category?.modules?.length
                                                      ? category?.modules
                                                      : []
                                                  )
                                                ),
                                              });
                                            }}
                                          >
                                            <i className="mdi mdi-swap-vertical-bold"></i>
                                          </Button>
                                        </Whisper>
                                      )}
                                    </Col>
                                  </Row>
                                </CardFooter>
                              </>
                            )}
                          </Collapse>
                        </Card>
                      ))}
                    </>
                  )}
                </>
              ) : (
                <>
                  <Card className="border">
                    <CardBody>
                      <Row className="align-items-center">
                        <Col xs="">
                          <h2 className="m-0">
                            Nothing available in your library.
                          </h2>
                          <p className="m-0">
                            Check back later or reload to try again.
                          </p>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </>
              )}
            </>
          )}
        </Container>
        <LibraryCategoryEditDrawer
          open={this.state.openCategory}
          category={this.state.modifyCategory}
          onCreate={this.onCreateCategory.bind(this)}
          onModify={this.onModifyCategory.bind(this)}
          onClose={() => {
            this.setState({ openCategory: false });
          }}
        ></LibraryCategoryEditDrawer>
        <LibraryModuleEditDrawer
          open={this.state.openModule}
          category={this.state.modifyCategory}
          module={this.state.modifyModule}
          onCreate={(category, module) => {
            this.onCreateCategoryModule(category, module);
          }}
          onModify={(module) => {
            this.onModifyModule(module);
          }}
          onClose={() => {
            this.setState({ openModule: false });
          }}
        ></LibraryModuleEditDrawer>
        <LibraryAllModulesDrawer
          open={this.state.openAllModules}
          onClose={() => {
            this.setState({ openAllModules: false });
          }}
        ></LibraryAllModulesDrawer>
        <LibraryAllTopicsDrawer
          open={this.state.openAllTopics}
          onClose={() => {
            this.setState({ openAllTopics: false });
          }}
        ></LibraryAllTopicsDrawer>
      </>
    );
  }
}

export default LearningCenterTrackView;
