import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
  Button,
  Modal,
  Badge,
  Row,
  Col,
} from "reactstrap";
import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";
import API from "lib/API";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import User from "lib/Model/User";
import NumberFormat from "react-number-format";
import APIV2 from "lib/APIV2";
import _ from "underscore";
import { Checkbox } from "rsuite";
import StringUtils from "../lib/StringUtils";
import CustomerOrderDrawer from "./Drawers/CustomerOrderDrawer";

class CustomerPickupListRow extends React.Component {
  state = {};

  render() {
    return (
      <>
        <div
          className={`px-3 py-2 ${
            this.props.selected
              ? "bg-primary-ultralight"
              : "bg-superlight--hover"
          } cursor-pointer`}
        >
          <Row className="align-items-center">
            {/**
             * <Col xs="auto">
              <Checkbox
                checked={this.props.selected}
                onChange={(v, c) => {
                  this.props.onSelect(c);
                }}
              ></Checkbox>
            </Col>
             */}
            <Col
              xs=""
              onClick={() => {
                PubSub.publish(Event.ORDER.OPEN, this.props.order);
              }}
            >
              <Row className="align-items-center">
                <Col xs="6" sm="" md="" className="mb-1 mb-md-0">
                  <h4 className="mb-0 text-capitalize text-dark">
                    {this.props.order?.customer?.name}
                  </h4>
                  <p className="mb-0 text-dark" style={{ fontSize: "13px" }}>
                    {this.props.order?.subscription?.currentFulfillmentCount ? (
                      <>
                        {this.props.order?.subscription
                          ?.currentFulfillmentCount > 0
                          ? `Week ${
                              this.props.order?.subscription
                                ?.currentFulfillmentCount + 1
                            }`
                          : "First Fulfillment"}
                      </>
                    ) : (
                      <>--</>
                    )}
                  </p>
                </Col>
                <Col xs="6" sm="" md="" className="mb-1 mb-md-0">
                  <div style={{ fontSize: "15px" }}>
                    <p className="text-dark m-0" style={{ lineHeight: 1.2 }}>
                      {this.props.order?.subscription?.plan?.planType?.name
                        ? `${this.props.order?.subscription?.plan?.planType?.name} ${this.props.order?.subscription?.plan?.quantity}`
                        : "--"}
                    </p>
                    <p
                      className="m-0 text-dark text-capitalize"
                      style={{ lineHeight: 1 }}
                    >
                      <small>
                        {this.props.order?.subscription?.plan?.frequency?.replace(
                          "biweekly",
                          "Bi-Weekly"
                        )}{" "}
                        {this.props.order?.subscription?.plan?.procurement}
                      </small>
                    </p>
                  </div>
                </Col>
                <Col xs="6" sm="auto" md="2">
                  {this.props.order?.invoice?.status == "UNPAID" ? (
                    <>
                      <Badge color="" className="bg-yellow text-dark">
                        {this.props.order?.invoice?.status}
                        <span className="d-inline d-md-none">
                          &nbsp;Invoice
                        </span>
                      </Badge>
                    </>
                  ) : null}

                  {this.props.order?.invoice?.status == "PAID" ? (
                    <>
                      <Badge color="success">
                        {this.props.order?.invoice?.status}
                        <span className="d-inline d-md-none">
                          &nbsp;Invoice
                        </span>
                      </Badge>
                    </>
                  ) : null}
                  {this.props.order?.invoice?.status == "REFUNDED" ||
                  this.props.order?.invoice?.status == "CANCELLED" ? (
                    <>
                      <Badge color="medium">
                        {this.props.order?.invoice?.status}
                        <span className="d-inline d-md-none">
                          &nbsp;Invoice
                        </span>
                      </Badge>
                    </>
                  ) : null}
                </Col>
                <Col xs="6" sm="auto" md="2" className="mb-1 mb-sm-0">
                  <Badge
                    color={`${
                      this.props.order?.status == "UNCLAIMED" ? "danger" : ""
                    }${this.props.order?.status == "CLAIMED" ? "success" : ""}${
                      this.props.order?.status == "PENDING" ||
                      this.props.order?.status == "CANCELLED" ||
                      this.props.order?.status == "BREAKDOWN"
                        ? "medium"
                        : ""
                    }`}
                    className=""
                  >
                    {this.props.order?.status}
                    <span className="d-inline d-md-none">&nbsp;Order</span>
                  </Badge>
                </Col>
                <Col xs="auto" className="d-none d-sm-block">
                  <i
                    className="mdi mdi-chevron-right text-dark"
                    style={{ fontSize: 24, lineHeight: 1 }}
                  ></i>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withRouter(CustomerPickupListRow);
