/*eslint-disable*/
import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import { withRouter } from "react-router";
import { Browser } from "@capacitor/browser";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Badge,
} from "reactstrap";
import PubSub from "lib/PubSub";
import { Drawer, SelectPicker } from "rsuite";
import { Capacitor } from "@capacitor/core";
import APIV2 from "lib/APIV2";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import _ from "underscore";
import LinkUtils from "lib/LinkUtils";
import { App } from "@capacitor/app";

class StorefrontSidebarV2 extends React.Component {
  state = {
    collapseOpen: false,
    storeOptions: [],
    selectedStore: "",
    version: "",
    groupNSO: false,
  };
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props?.match?.params?.storeID != this.state.selectedStore) {
      localStorage.setItem(
        "selected_store",
        this.props?.match?.params?.storeID
      );

      this.setState({
        selectedStore: this.props?.match?.params?.storeID,
      });
    }

    if (
      this.props.match?.params?.storeID != prevProps?.match?.params?.storeID
    ) {
      let selectedStore = _.findWhere(this.state.stores, {
        _id: this.props.match?.params?.storeID,
      });

      if (selectedStore) {
        this.setState({
          store: selectedStore,
        });
      }
    }
  }

  componentDidMount() {
    if (Capacitor.isNativePlatform()) {
      App.getInfo().then((info) => {
        this.setState({
          appId: info.id,
          build: info?.build,
          version: info?.version,
        });
      });
    }

    this.getStoresForUser();

    if (this.props?.match?.params?.storeID) {
      this.setState({
        selectedStore: this.props?.match?.params?.storeID,
      });

      console.log(this.props?.match?.params?.storeID);

      localStorage.setItem(
        "selected_store",
        this.props?.match?.params?.storeID
      );
    } else if (localStorage.getItem("selected_store")) {
      this.setState({
        selectedStore: localStorage.getItem("selected_store"),
      });
    }

    PubSub.subscribe("SidenavDrawer", (val) => {
      //console.log(val);

      this.setState({
        collapseOpen: val,
      });
    });
  }

  loadStore(id) {
    this.setState({ selectedStore: id });

    localStorage.setItem("selected_store", id);

    this.props.history.push("/storefront/" + id + "/home");

    this.closeCollapse();
  }

  getStoresForUser() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStoresForUser({ _id: 1, name: 1, nsoStage: 1 })
      .then(
        (data) => {
          let pickerOptions = data.data.stores.map((s) => {
            return {
              value: s._id,
              label: s.name.replace("Project LeanNation", "PLN"),
              nsoStage: s?.nsoStage,
            };
          });

          pickerOptions = _.filter(pickerOptions, (opt) => {
            return opt?.nsoStage != "Cold";
          });

          let user = LocalStorage.get(Constant.CACHE.USER);

          if (!user?.globalPermissions?.length) {
            pickerOptions = _.filter(pickerOptions, (item) => {
              return _.findWhere(user?.storePermissions, {
                storeID: item.value,
              });
            });
          } else {
            this.setState({
              groupNSO: true,
            });
          }

          this.setState({
            stores: data.data.stores,
            storeOptions: pickerOptions,
          });

          let selectedStore = _.findWhere(data.data.stores, {
            _id: this.props.match?.params?.storeID,
          });

          if (selectedStore) {
            this.setState({
              store: selectedStore,
            });
          }

          if (
            !_.findWhere(pickerOptions, {
              value: this.props.match?.params?.storeID,
            }) &&
            this.props.match?.params?.storeID != "select" &&
            this.props.match?.params?.storeID != "undefined"
          ) {
            this.loadStore(user?.storePermissions[0]?.storeID);

            return;
          }

          if (
            (this.props.match?.params?.storeID == "select" ||
              this.props.match?.params?.storeID == "undefined") &&
            pickerOptions?.length
          ) {
            this.loadStore(pickerOptions[0]?.value);
          }
        },
        (e) => {}
      )
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName, exact) {
    if (exact) {
      //console.log(this.props.location.pathname);

      return this.props.location.pathname == routeName ? "active" : "";
    }

    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };
  // creates the links that appear in the left menu / Sidebar
  createLinks = (routes, mobile = false) => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          {prop.dropdown ? (
            <>
              <NavLink
                to={
                  prop?.external ? prop.path : this.props.basePath + prop.path
                }
                tag={NavLinkRRD}
                onClick={(e) => {
                  if (prop?.external) {
                    e.preventDefault();

                    return LinkUtils.openInNewTab(prop.path);
                  }

                  prop.dropdown ? null : this.closeCollapse();
                }}
                activeClassName="active"
                className={`font-weight-500 ${
                  prop.dropdown ? "sidenav-dropdown" : ""
                } ${
                  prop.featureFlag &&
                  (!this.state.store ||
                    !this.state.store?.hasOwnProperty(prop.featureFlag) ||
                    !this.state.store[prop.featureFlag])
                    ? "text-light"
                    : ""
                }`}
                disabled={
                  (prop.featureFlag &&
                    (!this.state.store ||
                      !this.state.store?.hasOwnProperty(prop.featureFlag) ||
                      !this.state.store[prop.featureFlag])) ||
                  (prop.dataRequirement &&
                    (!this.state.store ||
                      !this.state.store?.hasOwnProperty(prop.dataRequirement) ||
                      !this.state.store[prop.dataRequirement]))
                }
              >
                <i
                  className={`${prop.icon} ${
                    prop.featureFlag &&
                    (!this.state.store ||
                      !this.state.store?.hasOwnProperty(prop.featureFlag) ||
                      !this.state.store[prop.featureFlag])
                      ? "text-light"
                      : ""
                  } pr-3 pr-lg-0`}
                  style={{ fontSize: mobile ? "24px" : "20px" }}
                />
                {prop.name}
                {prop.external ? (
                  <i
                    className={`mdi mdi-open-in-new pr-3 pr-lg-0`}
                    style={{ fontSize: 20, position: "absolute", right: 0 }}
                  />
                ) : null}
                {prop.featureFlag &&
                (!this.state.store ||
                  !this.state.store?.hasOwnProperty(prop.featureFlag) ||
                  !this.state.store[prop.featureFlag]) ? (
                  <Badge color="" className="bg-transparent text-medium ml-1">
                    Coming Soon
                  </Badge>
                ) : null}
              </NavLink>
              {this.activeRoute(this.props.basePath + prop.path) ? (
                <>
                  {prop.dropdownItems?.map((item, j) => (
                    <NavItem key={j}>
                      <NavLink
                        to={
                          item?.external
                            ? item.path
                            : this.props.basePath + item.path
                        }
                        tag={NavLinkRRD}
                        onClick={(e) => {
                          if (item?.external) {
                            e.preventDefault();

                            return LinkUtils.openInNewTab(item.path);
                          }

                          this.closeCollapse();
                        }}
                        activeClassName=""
                        className={`font-weight-500 sidenav-dropdown-item ${this.activeRoute(
                          this.props.basePath + item.path,
                          true
                        )} ${
                          item.featureFlag &&
                          (!this.state.store ||
                            !this.state.store?.hasOwnProperty(
                              item.featureFlag
                            ) ||
                            !this.state.store[item.featureFlag])
                            ? "text-light"
                            : ""
                        } ${
                          item.conditionalRender &&
                          typeof item.conditionalRender == "function" &&
                          !item.conditionalRender(this.state.store)
                            ? "d-none"
                            : ""
                        }`}
                        disabled={
                          (item.featureFlag &&
                            (!this.state.store ||
                              !this.state.store?.hasOwnProperty(
                                item.featureFlag
                              ) ||
                              !this.state.store[item.featureFlag])) ||
                          (item.dataRequirement &&
                            (!this.state.store ||
                              !this.state.store?.hasOwnProperty(
                                item.dataRequirement
                              ) ||
                              !this.state.store[item.dataRequirement]))
                        }
                      >
                        {item.name}
                        {item.external ? (
                          <i
                            className={`mdi mdi-open-in-new pr-3 pr-lg-0`}
                            style={{
                              fontSize: 20,
                              position: "absolute",
                              right: 0,
                            }}
                          />
                        ) : null}
                        {item.featureFlag &&
                        (!this.state.store ||
                          !this.state.store?.hasOwnProperty(item.featureFlag) ||
                          !this.state.store[item.featureFlag]) ? (
                          <Badge
                            color=""
                            className="bg-transparent text-medium ml-1"
                          >
                            Coming Soon
                          </Badge>
                        ) : null}
                      </NavLink>
                    </NavItem>
                  ))}
                </>
              ) : null}
            </>
          ) : (
            <NavLink
              to={prop?.external ? prop.path : this.props.basePath + prop.path}
              tag={NavLinkRRD}
              onClick={(e) => {
                if (prop?.external) {
                  e.preventDefault();

                  return LinkUtils.openInNewTab(prop.path);
                }

                this.closeCollapse();
              }}
              activeClassName="active"
              className={`font-weight-500 ${
                prop.featureFlag &&
                (!this.state.store ||
                  !this.state.store?.hasOwnProperty(prop.featureFlag) ||
                  !this.state.store[prop.featureFlag])
                  ? "text-light"
                  : ""
              }`}
              disabled={
                (prop.featureFlag &&
                  (!this.state.store ||
                    !this.state.store?.hasOwnProperty(prop.featureFlag) ||
                    !this.state.store[prop.featureFlag])) ||
                (prop.dataRequirement &&
                  (!this.state.store ||
                    !this.state.store?.hasOwnProperty(prop.dataRequirement) ||
                    !this.state.store[prop.dataRequirement]))
              }
            >
              <i
                className={`${prop.icon} pr-3 pr-lg-0`}
                style={{ fontSize: mobile ? "24px" : "20px" }}
              />
              {prop.name}
              {prop.external ? (
                <i
                  className={`mdi mdi-open-in-new pr-3 pr-lg-0`}
                  style={{ fontSize: 20, position: "absolute", right: 0 }}
                />
              ) : null}
            </NavLink>
          )}
        </NavItem>
      );
    });
  };

  getFooterLinks(mobile = false) {
    return (
      <>
        {Capacitor.isNativePlatform() ? (
          <>
            <hr className="my-2"></hr>
            <NavItem>
              <NavLink
                to={"/versionstring"}
                href={"/versionstring"}
                tag={NavLinkRRD}
                className={`font-weight-500 sidenav-dropdown-item text-dark`}
                style={{ color: "var(--dark) !important" }}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                App Version: {this.state.version}
              </NavLink>
            </NavItem>
          </>
        ) : (
          <>
            <hr className="my-2"></hr>
            <NavItem>
              <NavLink
                to={"/versionstring"}
                href={"/versionstring"}
                tag={NavLinkRRD}
                className={`font-weight-500 sidenav-dropdown-item text-dark py-1`}
                style={{ color: "var(--dark) !important" }}
                onClick={(e) => {
                  e.preventDefault();

                  LinkUtils.openInNewTab(Constant.IOS_APP_URL);
                }}
              >
                <img
                  src={require("../../assets/img/icons/common/download_on_ios.svg")}
                  style={{ width: 120 }}
                ></img>
              </NavLink>
            </NavItem>
          </>
        )}
      </>
    );

    // return (
    //   <NavItem>
    //     <NavLink
    //       to={"/resources"}
    //       tag={NavLinkRRD}
    //       onClick={(e) => {
    //         e.preventDefault();

    //         this.setState({ resourceLink: !this.state.resourceLink });
    //       }}
    //       activeClassName=""
    //       className={`font-weight-500 sidenav-dropdown ${
    //         this.state.resourceLink ? "active" : ""
    //       }`}
    //     >
    //       <i
    //         className={`mdi mdi-lifebuoy pr-3 pr-lg-0`}
    //         style={{ fontSize: mobile ? "24px" : "20px" }}
    //       />
    //       Resources
    //     </NavLink>
    //     {this.state.resourceLink ? (
    //       <>
    //         {/**<NavItem>
    //           <NavLink
    //             to={`/storefront/${this?.props?.match?.params?.storeID}/support/franchise`}
    //             href={`/storefront/${this?.props?.match?.params?.storeID}/support/franchise`}
    //             tag={NavLinkRRD}
    //             activeClassName="active"
    //             className={`font-weight-500 sidenav-dropdown-item`}
    //             onClick={() => {
    //               this.closeCollapse();
    //             }}
    //           >
    //             Franchise Support
    //           </NavLink>
    //         </NavItem> */}

    //         {Capacitor.isNativePlatform() ? (
    //           <>
    //             <hr className="my-2"></hr>
    //             <NavItem>
    //               <NavLink
    //                 to={"/versionstring"}
    //                 href={"/versionstring"}
    //                 tag={NavLinkRRD}
    //                 className={`font-weight-500 sidenav-dropdown-item text-dark`}
    //                 style={{ color: "var(--dark) !important" }}
    //                 onClick={(e) => {
    //                   e.preventDefault();
    //                 }}
    //               >
    //                 App Version: {this.state.version}
    //               </NavLink>
    //             </NavItem>
    //           </>
    //         ) : (
    //           <>
    //             <hr className="my-2"></hr>
    //             <NavItem>
    //               <NavLink
    //                 to={"/versionstring"}
    //                 href={"/versionstring"}
    //                 tag={NavLinkRRD}
    //                 className={`font-weight-500 sidenav-dropdown-item text-dark py-1`}
    //                 style={{ color: "var(--dark) !important" }}
    //                 onClick={(e) => {
    //                   e.preventDefault();

    //                   LinkUtils.openInNewTab(Constant.IOS_APP_URL);
    //                 }}
    //               >
    //                 <img
    //                   src={require("../../assets/img/icons/common/download_on_ios.svg")}
    //                   style={{ width: 120 }}
    //                 ></img>
    //               </NavLink>
    //             </NavItem>
    //           </>
    //         )}
    //       </>
    //     ) : null}
    //   </NavItem>
    // );
  }

  render() {
    const { bgColor, routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank",
      };
    }
    return (
      <>
        <Navbar
          className="navbar-vertical fixed-left navbar-light bg-white border-right d-none d-lg-block sidenav-topmargin"
          expand="lg"
          id="sidenav-main"
          style={{ zIndex: 2 }}
        >
          <Container fluid>
            {/* Toggler */}

            {/* Brand */}

            {/* User */}

            {/* Collapse */}
            <Collapse className="px-0 " navbar isOpen={this.state.collapseOpen}>
              {/* Collapse header */}

              {/* Form */}
              <div style={{ maxWidth: "100%", overflowX: "hidden" }}>
                <div
                  style={{
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                  }}
                  className="mt-0 pb-3 mb-2 border-bottom"
                >
                  <div className="mb-2">
                    <small className="text-uppercase font-weight-bold text-muted">
                      Store
                    </small>
                  </div>
                  <SelectPicker
                    loading={this.state.loadingStores}
                    cleanable={false}
                    size="md"
                    placeholder="Select A Store"
                    placement="autoVertical"
                    preventOverflow={true}
                    groupBy={this.state.groupNSO ? "nsoStage" : ""}
                    data={this.state.storeOptions}
                    menuStyle={{
                      zIndex: 9999,
                    }}
                    sort={(isGroup) => {
                      if (isGroup) {
                        return (a, b) => {
                          if (b.groupTitle == "Live") {
                            return 1;
                          }

                          if (b.groupTitle == "Prelaunch") {
                            if (a.groupTitle == "Live") {
                              return -1;
                            }

                            return 1;
                          }

                          if (b.groupTitle == "Buildout") {
                            if (
                              a.groupTitle == "Live" ||
                              a.groupTitle == "Prelaunch"
                            ) {
                              return -1;
                            }

                            return 1;
                          }

                          if (b.groupTitle == "Site Selection") {
                            if (
                              a.groupTitle == "Live" ||
                              a.groupTitle == "Prelaunch" ||
                              a.groupTitle == "Buildout"
                            ) {
                              return -1;
                            }

                            return 1;
                          }

                          return a.groupTitle < b.groupTitle ? -1 : 1;
                        };
                      }

                      return (a, b) => {
                        return a.label < b.label ? -1 : 1;
                      };
                    }}
                    value={this.state.selectedStore}
                    block
                    onChange={(val) => {
                      this.loadStore(val);
                    }}
                  />
                </div>
              </div>
              {/* Navigation */}
              <Nav
                className="ml-0"
                style={{ maxWidth: "100%", overflowX: "hidden" }}
                navbar
              >
                {this.createLinks(routes)}
              </Nav>
              {/* Divider */}
              <hr className="my-0" style={{ width: "100%" }}></hr>
              <Nav
                className="ml-0"
                style={{ maxWidth: "100%", overflowX: "hidden" }}
                navbar
              >
                {this.getFooterLinks()}
              </Nav>

              {/* Heading */}

              {/* Navigation */}
              {/*
              <h6 className="navbar-heading text-muted">Manage</h6>
              <Nav navbar>
              <NavItem>
                <NavLink href="https://demos.creative-tim.com/argon-dashboard-react/#/documentation/overview?ref=adr-admin-sidebar">
                  <i className="mdi mdi-account-group" />
                  User Access
                </NavLink>
              </NavItem>
            </Nav>    
            */}
            </Collapse>
          </Container>
        </Navbar>
        <Drawer
          style={{ maxWidth: "100%" }}
          size={"xs"}
          placement={"left"}
          onOpen={(e) => {
            //console.log("is open");
          }}
          open={this.state.collapseOpen}
          onClose={() => this.closeCollapse()}
          className="nav-drawer"
        >
          <Drawer.Header>
            <Drawer.Title className="font-weight-bold">Menu</Drawer.Title>
          </Drawer.Header>
          <Drawer.Body
            style={{
              paddingTop: "0px",
              paddingTop: "0px",
              paddingLeft: "0px",
              paddingRight: "0px",
              overflowX: "hidden",
            }}
          >
            <Navbar
              className="navbar-vertical navbar-light"
              navbar
              style={{ padding: 0 }}
            >
              <Collapse
                className="collapse-override"
                navbar
                isOpen={true}
                style={{
                  padding: "0.6rem",
                  background: "transparent",
                  boxShadow: "none",
                  borderRadius: "none",
                  animation: "none",
                  opacity: 1,
                  width: "100%",
                  margin: 0,
                  overflowX: "hidden",
                }}
              >
                {/* Collapse header */}

                {/* Form */}
                <div
                  style={{
                    paddingLeft: "0.7rem",
                    paddingRight: "0.7rem",
                  }}
                  className="mt-0 pb-3 mb-2 border-bottom"
                >
                  <div className="mb-2">
                    <small className="text-uppercase font-weight-bold text-muted">
                      Store
                    </small>
                  </div>
                  <SelectPicker
                    loading={this.state.loadingStores}
                    cleanable={false}
                    size="md"
                    placeholder="Select A Store"
                    placement="auto"
                    groupBy="nsoStage"
                    data={this.state.storeOptions}
                    style={{}}
                    value={this.state.selectedStore}
                    block
                    onChange={(val) => {
                      this.loadStore(val);
                    }}
                  />
                </div>
                {/* Navigation */}
                <Nav navbar>{this.createLinks(routes)}</Nav>
                <hr className="my-0" style={{ width: "100%" }}></hr>
                <Nav navbar>{this.getFooterLinks()}</Nav>
                <div className="pb-4 pb-md-0"></div>
                {/* Divider */}

                {/* Heading */}

                {/* Navigation */}
                {/*
              <h6 className="navbar-heading text-muted">Manage</h6>
              <Nav navbar>
              <NavItem>
                <NavLink href="https://demos.creative-tim.com/argon-dashboard-react/#/documentation/overview?ref=adr-admin-sidebar">
                  <i className="mdi mdi-account-group" />
                  User Access
                </NavLink>
              </NavItem>
            </Nav>    
            */}
              </Collapse>
            </Navbar>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

StorefrontSidebarV2.defaultProps = {
  routes: [{}],
};

StorefrontSidebarV2.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  basePath: PropTypes.string.isRequired,
};

export default withRouter(StorefrontSidebarV2);
