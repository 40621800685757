import ArrowText from "components/ArrowText";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  Alert,
  Badge,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import Card from "reactstrap/lib/Card";
import moment from "moment";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import _, { flatten } from "underscore";
import TaskDetailDrawer from "components/Drawers/TaskDetailDrawer";
import TaskCreateDrawer from "components/Drawers/TaskCreateDrawer";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { P } from "@antv/g2plot";
import { Drawer } from "rsuite";
import ConversationMessagePane from "components/Conversation/ConversationMessagePane";

class ConversationAutomationWidget extends React.Component {
  state = {
    expand: false,
    taskDetail: null,
    count: 200,
  };

  componentDidMount() {}

  setError(message) {
    if (!message) {
      this.setState({
        error: "",
      });

      return;
    }

    this.setState({
      error: message,
    });

    setTimeout(() => {
      this.setError("");
    }, 4000);
  }

  componentDidUpdate(prevProps) {}

  updateAutomation(key, value) {
    this.setState({ submitting: true });

    APIV2.setCustomerVariable(
      this.props?.match?.params?.storeID,
      this.props?.customer?._id,
      key,
      value
    )
      .then(
        (data) => {
          PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);
        },
        (e) => {
          this.setError("error", "Failed to update automation. try again.");
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }
  render() {
    return (
      <>
        {this.props.loading ? (
          <>
            <Card className="mb-4 shadow border">
              <CardBody className="bg-transparent py-5 text-center">
                <Spinner size="lg" color="medium"></Spinner>
              </CardBody>
            </Card>
          </>
        ) : (
          <>
            <Card className="mb-4 border shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <Col xs="">
                    <h3 className="text-dark mb-0">Conversation Automations</h3>
                    <p className="m-0 small">
                      Enable or disable conversation automations for this
                      member.
                    </p>
                  </Col>
                </Row>
              </CardHeader>
              {this.state.error ? (
                <>
                  <CardBody>
                    <Alert className="m-0">{this.state.error}</Alert>
                  </CardBody>
                </>
              ) : null}
              <CardBody>
                <Row className="align-items-center">
                  <Col xs="12" sm="">
                    <h4 className="m-0 text-dark">
                      Order Pickup Confirmation{" "}
                      {this?.props?.customer
                        ?.conversationAutomationOrderPickup === false && (
                        <Badge
                          color="danger"
                          className="ml-1"
                          style={{ position: "relative", top: -2 }}
                        >
                          Disabled
                        </Badge>
                      )}
                    </h4>
                    <p className="m-0 small">
                      Pickup reminder sent to members with a pickup available at
                      9am local time after their invoice has been processed.
                    </p>
                  </Col>
                  <Col xs="12" sm="auto" className="mt-3 mt-sm-0">
                    <Button
                      size="sm"
                      onClick={() => {
                        let value = false;

                        if (
                          this.props.customer
                            ?.conversationAutomationOrderPickup === false
                        ) {
                          value = true;
                        }

                        this.updateAutomation(
                          "conversationAutomationOrderPickup",
                          value
                        );
                      }}
                      block
                      color={
                        this.props.customer?.hasOwnProperty(
                          "conversationAutomationOrderPickup"
                        ) &&
                        this.props.customer[
                          "conversationAutomationOrderPickup"
                        ] === false
                          ? "success"
                          : "danger"
                      }
                      outline
                      disabled={this.state.submitting}
                    >
                      {this.props.customer?.hasOwnProperty(
                        "conversationAutomationOrderPickup"
                      ) &&
                      this.props.customer[
                        "conversationAutomationOrderPickup"
                      ] === false
                        ? "Enable"
                        : "Disable"}
                    </Button>

                    {/**conversationAutomationOrderPickup */}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </>
        )}
      </>
    );
  }
}

export default withRouter(ConversationAutomationWidget);
