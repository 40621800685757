import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import Alert from "reactstrap/lib/Alert";
import AffiliateAboutWidget from "./AffiliateAboutWidget";
import AffiliateContactInformationWidget from "./AffiliateContactInformationWidget";
import AffiliateDeliverablesWidget from "./AffiliateDeliverablesWidget";

class AffiliateInformationWidget extends React.Component {
  state = {
    expand: false,
    phone: {
      value: "",
    },
    name: "",
    email: "",
  };

  updateInformation() {
    this.setState({ submitting: true });

    let phone = "";

    if (this.state.phone?.value.length == 10) {
      phone = `+1${this.state.phone?.value}`;
    }

    APIV2.updateCustomerInformation(
      this.props?.match?.params?.storeID,
      this.props?.customer?._id,
      this.state.name,
      this.state.email,
      phone
    )
      .then(
        (data) => {
          PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);

          this.setState({ openModify: false });
        },
        (e) => {
          this.setError(
            "error",
            "Could not update customer, please try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 4000);
  }

  render() {
    return (
      <>
        {this.props.loading ? (
          <>
            <Card className="border shadow mb-4">
              <CardBody className="bg-transparent py-5 text-center">
                <Spinner size="lg" color="medium"></Spinner>
              </CardBody>
            </Card>
          </>
        ) : (
          <>
            <AffiliateAboutWidget
              affiliate={this.props.affiliate}
              modifiable={true}
              loading={this.props.loading}
            />
            <AffiliateContactInformationWidget
              affiliate={this.props.affiliate}
              modifiable={true}
              loading={this.props.loading}
            />
            <AffiliateDeliverablesWidget
              affiliate={this.props.affiliate}
              modifiable={true}
              loading={this.props.loading}
            />
          </>
        )}
      </>
    );
  }
}

export default withRouter(AffiliateInformationWidget);
