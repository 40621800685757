import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import { isThisTypeNode } from "typescript";

class StorefrontCreateUserDrawer extends React.Component {
  state = {
    position: "",
    name: "",
    email: "",
    phone: {
      value: "",
    },
    hireDate: null,
  };

  constructor() {
    super();

    this.dropdownRef = React.createRef();
  }

  componentDidUpdate(prevProps) {}

  componentDidMount() {}

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  handlePhoneChange(values) {
    this.setState({
      phone: values,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  toggleModal() {
    this.props.onClose();
  }

  submit() {
    this.setState({ submitting: true });

    APIV2.createStoreUser(
      this.props?.storeID,
      this.state.name,
      this.state.email
    )
      .then(
        (data) => {
          const user = data.data.user;

          PubSub.publish(Event.USER.CREATED, user);

          this.setState({
            name: "",
            email: "",
          });

          this.toggleModal();
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create user - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Add User
            </h3>
            <Drawer.Actions>
              <Button
                onClick={this.submit.bind(this)}
                size="sm"
                color="primary"
                disabled={
                  !this.state.name || !this.state.email || this.state.submitting
                }
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  "Add"
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error && (
              <>
                <Alert
                  color=""
                  className="m-0 mb-4 bg-primary-superlight text-dark font-weight-500"
                >
                  {this.state.error}
                </Alert>
              </>
            )}
            <FormGroup>
              <h5>Name</h5>
              <Input
                type="text"
                name="name"
                placeholder="Name"
                required
                value={this.state.name}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Email</h5>
              <Input
                type="email"
                name="email"
                placeholder="Email"
                required
                value={this.state.email}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(StorefrontCreateUserDrawer);
