export default class DataAPI {
  static ENV_STAGING = "https://cubeapi.projectleannation.com/cubejs-api/v1";
  static ENV_PRODUCTION = "https://cubeapi.projectleannation.com/cubejs-api/v1";
  //static ENV_DEV = "http://localhost:4000/cubejs-api/v1";

  static ENV_DEV = "https://cubeapi.projectleannation.com/cubejs-api/v1";

  static TOKEN_STAGING =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2Mzg5MzAyOTh9.PtFpnnmQyWZeR2L-5cCLakyVKRL0RGfCzVSMzUoyTvg";
  static TOKEN_PRODUCTION =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2Mzg5MzAyOTh9.PtFpnnmQyWZeR2L-5cCLakyVKRL0RGfCzVSMzUoyTvg";
  //static TOKEN_DEV =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2Mzg5NzkxMjIsImV4cCI6MTYzOTA2NTUyMn0.4hvjw0mmPyTEVsYhgsymMdTvQGA8m_ZH9_YWBRat7qY";

  static TOKEN_DEV =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2Mzg5MzAyOTh9.PtFpnnmQyWZeR2L-5cCLakyVKRL0RGfCzVSMzUoyTvg";

  static ENV_CURRENT = this.ENV_PRODUCTION;
  static ENV_USER_TOKEN = this.TOKEN_PRODUCTION;

  /**
   * Sets the environment
   *
   * @param {*} env
   */
  static setEnvironment(env) {
    this.ENV_CURRENT = env;
  }

  static getEnvironment() {
    return this.ENV_CURRENT;
  }

  /**
   * Gets the current auth token.
   */
  static getAuthToken() {
    return this.ENV_USER_TOKEN;
  }

  /**
   * Sets the user's auth token.
   *
   * @param {*} val
   */
  static setAuthToken(val) {
    this.ENV_USER_TOKEN = val;
  }
}
