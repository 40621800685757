import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import APIV2 from "lib/APIV2";
import { DatePicker } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";

class ModifyStoreBlackoutDatesModal extends React.Component {
  state = {
    open: false,
    id: null,
    name: "",
    submitting: false,
    type: 0,
    address_1: "",
    address_2: "",
    city: "",
    state: "AL",
    zip_code: "",
    billingAccounts: [],
    loadingBAs: true,
    billingAccountID: null,
    dates: {},
    dateComponents: [],
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  handleDateChange(uuid, val) {
    this.setState({ [uuid]: val }, () => {});

    //this.forceUpdate();
  }

  loadDates() {
    let dateComponents = {};

    let st = this.state;

    if (this.props.store?.operations?.blackoutDates?.length) {
      let filteredBlackoutDates = _.filter(
        this.props.store.operations.blackoutDates,
        (date) => {
          return date.indexOf(moment().format("YYYY")) >= 0;
        }
      );

      for (let i = 0; i < filteredBlackoutDates.length; i++) {
        let uuid = StringUtils.uuid();

        let mo = moment(filteredBlackoutDates[i]);

        this.setState({ [uuid]: mo.toDate() }, () => {
          let component = (
            <>
              <Row className="mb-3">
                <Col xs="">
                  <DatePicker
                    required
                    block
                    placeholder="Select A Date"
                    defaultValue={mo.toDate()}
                    onChange={(e) => {
                      this.handleDateChange(uuid, e);
                    }}
                    disabledDate={(date) =>
                      date < new Date() || date.getDay() != 6
                    }
                  ></DatePicker>
                </Col>
                <Col xs="auto" className="pl-0">
                  <Button
                    style={{ height: "38px", width: "38px" }}
                    size="sm"
                    color="danger"
                    outline
                    onClick={(e) => {
                      e.preventDefault();
                      this.removeDate(uuid);
                    }}
                  >
                    <i className="mdi mdi-close"></i>
                  </Button>
                </Col>
              </Row>
            </>
          );

          dateComponents[uuid] = component;

          this.setState({ dateComponents });
        });
      }
    }
  }

  removeDate(uuid) {
    let st = this.state;

    let dateComponents = st.dateComponents;

    delete st[uuid];
    delete dateComponents[uuid];

    st.dateComponents = dateComponents;

    this.setState(st);
  }

  addNew() {
    let uuid = StringUtils.uuid();

    let dateComponents = this.state.dateComponents;

    let st = {};

    st[uuid] = null;

    let component = (
      <>
        <Row className="mb-3">
          <Col xs="">
            <DatePicker
              required
              value={this.state[uuid]}
              block
              placeholder="Select A Date"
              onChange={(e) => {
                this.handleDateChange(uuid, e);
              }}
              disabledDate={(date) => date < new Date() || date.getDay() != 6}
            ></DatePicker>
          </Col>
          <Col xs="auto" className="pl-0">
            <Button
              style={{ height: "38px", width: "38px" }}
              size="sm"
              color="danger"
              outline
              onClick={(e) => {
                e.preventDefault();
                this.removeDate(uuid);
              }}
            >
              <i className="mdi mdi-close"></i>
            </Button>
          </Col>
        </Row>
      </>
    );

    dateComponents[uuid] = component;

    st.dateComponents = dateComponents;

    this.setState(st);
  }

  submit(e) {
    e.preventDefault();

    let uuids = _.keys(this.state.dateComponents);

    let dates = [];

    for (let i = 0; i < uuids.length; i++) {
      if (this.state[uuids[i]]) {
        let date = this.state[uuids[i]];

        dates.push(moment(date.toISOString()).format("YYYY-MM-DD"));
      }
    }

    this.setState({
      submitting: true,
    });

    APIV2.setStoreBlackoutDates(this.props.store._id, dates)
      .then(
        (data) => {
          if (data && data.data && data.data.store) {
            PubSub.publish(Event.STORE.MODIFIED, data.data.store);

            this.setState({
              open: false,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "We were unable to set the blackout dates for the store. Please try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  componentDidMount() {
    PubSub.subscribe(Event.STORE.MODIFY_BLACKOUT_OPEN, (s) => {
      this.loadDates();

      this.setState({
        open: true,
      });
    });
  }

  render() {
    return (
      <>
        <Modal
          id="modifyBlackoutDates"
          isOpen={this.state.open}
          toggle={() => this.toggleModal("open")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="createBAModalLabel">
              Modify Blackout Dates
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("open")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Form onSubmit={this.submit.bind(this)}>
            <div className="modal-body">
              {_.values(this.state.dateComponents).map((comp, i) => (
                <div key={i}>{comp}</div>
              ))}
              {!_.values(this.state.dateComponents).length ? (
                <>No dates selected.</>
              ) : null}
              <hr className="my-2"></hr>
              <div className="text-right">
                <Button
                  size="sm"
                  color="primary"
                  outline
                  type="button"
                  onClick={() => this.addNew()}
                >
                  Add Date
                </Button>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="reset"
                onClick={() => this.toggleModal("open")}
              >
                Close
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={this.state.submitting}
              >
                Save
              </Button>
            </div>
          </Form>
        </Modal>
      </>
    );
  }
}

export default ModifyStoreBlackoutDatesModal;
