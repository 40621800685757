import React from "react";
// react component that copies the given text inside your clipboard

import { DateRangePicker } from "react-date-range";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Spinner,
  Collapse,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.js";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import moment from "moment";
import _ from "underscore";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";

class MarketingCampaignDashboard extends React.Component {
  state = {};

  getUser() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user;
  }

  /**
   * Checks if the current user is a global user via permissions
   *
   * @returns
   */
  isGlobal() {
    let user = this.getUser();

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  componentDidMount() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    console.log(user);

    if (!user?.globalPermissions?.length) {
      this.props.history.push("/reports");

      return;
    }
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <iframe
            src="https://pln.metabaseapp.com/public/dashboard/8ee1f931-ee59-4f9e-8001-5593fbfc3104"
            frameborder="0"
            width="100%"
            height="auto"
            style={{ height: "calc(100vh - 150px)" }}
            allowtransparency
          ></iframe>
        </Container>
      </>
    );
  }
}

export default MarketingCampaignDashboard;
