import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import { Badge, Button, Col, Row } from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import swal from "sweetalert";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import TaskModifyDrawer from "./TaskModifyDrawer";
import CustomerInformationWidget from "components/Widgets/CustomerInformationWidget";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import NoteBody from "components/NoteBody";
import Spinner from "reactstrap/lib/Spinner";
import LeadContactInformationWidget from "components/Widgets/LeadContactInformationWidget";
import AffiliateAboutWidget from "components/Widgets/AffiliateAboutWidget";
import { Link } from "react-router-dom";

class ReferralDetailDrawer extends React.Component {
  state = {
    dueDate: moment().day(6).toDate(),
    subject: "",
    description: "",
    relatedToType: "",
    type: "",
    priority: "Normal",
    status: "Not Started",
    assignedTo: null,
    timeToContact: "",
    customers: [],
    recentFirst: "recentFirst",
  };

  dropdownRef = React.createRef();

  componentDidUpdate(prevProps) {
    if (prevProps?.task != this.props.task) {
      this.fetchNotes(true);
    }
  }

  componentDidMount() {
    if (this.props.task) {
      this.fetchNotes(true);
    }
  }

  toggleModal() {
    this.setState({
      name: "",
      email: "",
      phone: {
        value: "",
      },
      plan: null,
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  modifyStatus(status) {
    this.setState({ submitting: true });

    APIV2.modifyTask(
      this.props?.task?.storeID,
      this?.props?.task?._id,
      this.props.task?.subject,
      this.props.task?.type,
      this.props.task?.description,
      this.props.task?.priority,
      this.props.task?.customerID ?? null,
      this.props.task?.leadID ?? null,
      this.props.task?.affiliateID ?? null,
      status,
      this.props.task?.dueDate,
      this.props.task?.assignedTo ? this.props.task?.assignedTo?._id : null,
      this.props.task?.timeToContact
    )
      .then(
        (data) => {
          const task = data.data.task;

          //this.toggleModal();

          PubSub.publish(Event.TASK.MODIFIED, task);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to update task status - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  delete() {
    swal({
      title: "Delete Referral",
      text: "Are you sure you want delete this referral?",
      icon: "warning",
      buttons: ["Nevermind", "Delete"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({ deleting: true });

      let endpoint =
        this.props.mode == "given"
          ? APIV2.deleteCustomerGivenReferral(
              this.props?.customer?.storeID,
              this?.props?.customer?._id,
              this.props?.referral?.id
            )
          : APIV2.deleteCustomerReceivedReferral(
              this.props?.customer?.storeID,
              this?.props?.customer?._id,
              this.props?.referral?.id
            );

      endpoint
        .then(
          (data) => {
            this.toggleModal();

            PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);

            this.toggleModal();
          },
          (e) => {
            this.setError(
              "error",
              e?.response?.body?.message ??
                "Unable to delete referral - unknown error occurred. Try again."
            );
          }
        )
        .finally(() => {
          this.setState({ deleting: false });
        });
    });
  }

  formatDueDate(dateString, status) {
    if (!dateString) {
      return "--";
    }

    let due = moment(dateString);

    return due.format("MMM DD, YYYY");
  }

  formatStatus(referral) {
    if (!referral) {
      return "--";
    }

    if (referral?.applied) {
      return "Used";
    }

    if (!referral?.applied && referral?.availableForUse) {
      return "Unused";
    }

    if (!referral?.availableForUse) {
      return "Pending";
    }
  }

  fetchNotes(forceLoad = false) {
    if (forceLoad) {
      this.setState({
        loading: true,
      });
    }

    let query = {
      taskID: this?.props?.task?._id,
    };

    let sortBy = {
      createdAt: this.state.recentFirst == "recentFirst" ? -1 : 1,
    };

    APIV2.getNotesForStore(
      this.props?.task?.storeID,
      this.state.page,
      20,
      query,
      sortBy
    )
      .then(
        (data) => {
          let notes = forceLoad ? [] : this.state.notes;

          notes = data?.data?.notes?.length
            ? notes.concat(data?.data?.notes)
            : notes;

          let notesOut = [];

          for (let i = 0; i < notes?.length; i++) {
            if (!_.findWhere(notesOut, { _id: notes[i]?._id })) {
              notesOut.push(notes[i]);
            }
          }

          this.setState({
            notes: notesOut,
            pagination: data?.data?.pagination,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  handleRecentFirstChange(v) {
    this.setState(
      {
        recentFirst: v,
        page: 1,
      },
      () => {
        this.fetchNotes(true);
      }
    );
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Referral Details
            </h3>
            <Drawer.Actions>
              <Whisper
                ref={this.dropdownRef}
                trigger="click"
                placement={"autoVerticalEnd"}
                speaker={
                  <Popover full>
                    <Dropdown.Menu style={{ minWidth: 150 }}>
                      <Dropdown.Item
                        panel
                        className="py-2 font-weight-800 text-uppercase"
                        style={{ paddingLeft: 12, paddingRight: 12 }}
                      >
                        <h5 className="m-0">Actions</h5>
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="text-danger"
                        disabled={this.state.deleting}
                        onClick={() => {
                          this.delete();

                          this.dropdownRef.current.close();
                        }}
                      >
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Popover>
                }
              >
                <Button size="sm" outline color="secondary">
                  Actions <i className="mdi mdi-chevron-down pl-2"></i>
                </Button>
              </Whisper>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}

            <FormGroup>
              <Row className="align-items-center">
                <Col xs="">
                  <h2 className="m-0">
                    {StringUtils.centsToCurrency(this?.props?.referral?.value)}{" "}
                    Referral
                  </h2>
                </Col>
                <Col xs="auto">
                  <h2 className="m-0">
                    <Badge color="medium">
                      {this.formatStatus(this.props.referral)}
                    </Badge>
                  </h2>
                </Col>
              </Row>
            </FormGroup>

            <Row className="align-items-top">
              <Col xs="12" md="6">
                <FormGroup>
                  <h5>Referred On</h5>
                  {this.formatDueDate(this.props.referral?.referredOn)}
                </FormGroup>
              </Col>
              <Col xs="12" md="6">
                <FormGroup>
                  <h5>Source</h5>
                  <p className="m-0">{this.props.referral?.referralSource}</p>
                </FormGroup>
              </Col>
              <Col xs="12" md="6">
                <FormGroup>
                  <h5>Value</h5>
                  <p className="m-0">
                    {StringUtils.centsToCurrency(this?.props?.referral?.value)}
                  </p>
                </FormGroup>
              </Col>
            </Row>
            <div>
              {this.props.referral?.customer ? (
                <>
                  <hr className="my-3"></hr>
                  <Row className="mb-3 align-items-center">
                    <Col xs="">
                      <h4 className="mb-0">
                        {this.props?.mode == "given"
                          ? "Member Referred"
                          : "Referred By"}
                      </h4>
                    </Col>
                    <Col xs="auto">
                      <Link
                        to={`/storefront/${this.props.referral?.customer?.storeID}/members/details/${this.props.referral?.customer?._id}/summary`}
                      >
                        <Button
                          size="sm"
                          color="secondary"
                          outline
                          onClick={() => {
                            let url = "";

                            console.log(this.props.referral?.customer);

                            if (this.props.referral?.customer?._id) {
                              url = `/storefront/${this.props.referral?.customer?.storeID}/members/details/${this.props.referral?.customer?._id}/summary`;
                            }

                            this.props.history.push(url);
                          }}
                        >
                          View
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                  {this.props.referral?.customer ? (
                    <>
                      <CustomerContactInformationWidget
                        customer={this.props.referral?.customer}
                        flat={true}
                      />
                    </>
                  ) : null}
                </>
              ) : null}
            </div>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(ReferralDetailDrawer);
