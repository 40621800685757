import Login from "views/examples/Login.js";
import MealList from "views/examples/MealList";
import BillingAccountList from "views/examples/BillingAccountList";
import ProductGroupList from "views/examples/ProductGroupList";
import StoreList from "views/examples/StoreList";
import StoreView from "views/examples/StoreView";
import SignupLinkCreatorView from "views/examples/SignupLinkCreatorView";
import MenuList from "views/examples/MenuList";

var routes = [
  {
    path: "/stores",
    name: "Stores",
    icon: "mdi mdi-store",
    component: StoreList,
    layout: "/global",
    sidebar: true,
    exact: true,
  },
  {
    path: "/meals",
    name: "Meals",
    icon: "mdi mdi-food",
    component: MealList,
    layout: "/global",
  },
  {
    path: "/menus",
    name: "Menus",
    icon: "mdi mdi-format-list-text",
    component: MenuList,
    layout: "/global",
  },
  {
    path: "/billing-accounts",
    name: "Billing Accounts",
    icon: "mdi mdi-cash-usd",
    component: BillingAccountList,
    layout: "/global",
    sidebar: true,
    exact: true,
  },
  {
    path: "/discounts",
    name: "Discounts",
    icon: "mdi mdi-ticket-percent",
    component: BillingAccountList,
    layout: "/global",
    sidebar: true,
    exact: true,
  },
  {
    path: "/stores/:storeID",
    name: "Store",
    icon: "mdi mdi-store",
    component: StoreView,
    layout: "/global",
    sidebar: false,
    exact: true,
  },
  {
    path: "/product-categories",
    name: "Product Categories",
    icon: "mdi mdi-select-group",
    component: ProductGroupList,
    layout: "/global",
    sidebar: true,
    exact: true,
  },
  {
    path: "/url-creator",
    name: "Signup Link Creator",
    icon: "mdi mdi-link",
    component: SignupLinkCreatorView,
    layout: "/global",
    sidebar: true,
    exact: true,
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    sidebar: false,
    exact: true,
  },
];
export default routes;
