import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import {
  CheckPicker,
  Checkbox,
  Dropdown,
  Popover,
  SelectPicker,
  Tooltip,
  Whisper,
} from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import CustomerCreateDrawer from "components/Drawers/CustomerCreateDrawer";
import TaskCreateDrawer from "components/Drawers/TaskCreateDrawer";
import TaskModifyDrawer from "components/Drawers/TaskModifyDrawer";
import TaskDetailDrawer from "components/Drawers/TaskDetailDrawer";
import { Sparklines, SparklinesBars } from "react-sparklines";
import cubejs from "@cubejs-client/core";
import DataAPI from "../../lib/DataAPI";
import StoreUpcomingAppointmentsWidget from "../../components/Widgets/StoreUpcomingAppointmentsWidget";
import StoreUpcomingMonthWidget from "../../components/Widgets/StoreUpcomingMonthWidget";
import NewConsultationDrawer from "../../components/Drawers/NewConsultationDrawer";
import NewOutreachDrawer from "../../components/Drawers/NewOutreachDrawer";
import StoreOpeningWidget from "../../components/Widgets/StoreOpeningWidget";
import LinkUtils from "lib/LinkUtils";
import LocalStorage from "lib/LocalStorage";

class StorefrontHomeView extends React.Component {
  state = {
    active: {
      id: "All Upcoming & Overdue Tasks",
      name: "All Upcoming & Overdue Tasks",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 15,
    filters: null,
    orderBy: null,
    memberActivity: "This Week (Sun-Sat)",
    leadActivity: "This Week (Sun-Sat)",
    activeView: "member",
    calendarViewMode: LocalStorage.get("storeCalendarViewMode")
      ? LocalStorage.get("storeCalendarViewMode")
      : "week",
    filterCalendarOptions: [
      {
        value: "Appointments",
        label: "Appointments",
      },
      {
        value: "Marketing & Social",
        label: "Marketing & Social",
      },
      {
        value: "Community Events",
        label: "Community Events",
      },
    ],
    filterCalendar: ["Appointments", "Marketing & Social", "Community Events"],
  };

  calendarDropdownRef = React.createRef();
  calendarViewDropdownRef = React.createRef();

  makePossessive(name) {
    if (name.endsWith("s")) {
      return `${name}'`;
    }

    return `${name}'s`;
  }

  async fetchBanners() {
    this.setState({
      banners: [],
    });

    APIV2.getSystemBanners(this.props?.match?.params?.storeID)
      .then(
        (data) => {
          console.log("BANNERS", data?.data?.banners);

          this.setState(
            {
              banners: data?.data?.banners,
            },
            () => {
              console.log("FETCHING");

              APIV2.getTechSupportTickets(
                1,
                25,
                {
                  storeID: this.props?.match?.params?.storeID,
                  requiresResponse: true,
                  status: { $ne: "Resolved" },
                },
                {
                  createdAt: 1,
                }
              ).then((data) => {
                const tickets = data?.data?.tickets;

                console.log("TICKETS", tickets);

                if (tickets?.length) {
                  let banners = this.state.banners;

                  banners.push({
                    data: {
                      header: `${tickets.length} Support ${
                        tickets?.length == 1 ? "Ticket" : "Tickets"
                      } ${tickets?.length == 1 ? "Needs" : "Need"} A Response`,
                      subheader: `Please respond to ${
                        tickets?.length == 1 ? "this ticket" : "these tickets"
                      } as soon as possible.`,
                      interactive: true,
                      interactiveEventType: "in-app-page",
                      interactiveEventData: `/storefront/${this.props?.match?.params?.storeID}/support/franchise?tab=Response%2520Requested`,
                    },
                  });

                  this.setState({
                    banners,
                  });
                }
              });
            }
          );
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {});
  }

  async loadCharts() {
    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    this.setState({
      newMemberResultsLoading: true,
      hfnResultsLoading: true,
      totalActiveResultsLoading: true,
      newLeadResultsLoading: true,
      convertedLeadResultsLoading: true,
      conversionRateResultsLoading: true,
      referralsLoading: true,
      consultationsLoading: true,
      reactivationsLoading: true,
      clientConsultations: true,
    });

    // Load New Member Results
    cubejsApi
      .load({
        measures: ["Customers.count"],
        timeDimensions: [
          {
            dimension: "Customers.signupdate",
            granularity: "day",
            dateRange: [
              moment()
                .day(0)
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .format("YYYY-MM-DD"),
              moment()
                .day(6)
                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(999)
                .format("YYYY-MM-DD"),
            ],
          },
        ],
        order: {
          "Customers.createdat": "asc",
        },
        dimensions: [],
        filters: [
          {
            member: "Customers.storeid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
          {
            member: "CustomerSubscriptions.status",
            operator: "equals",
            values: ["ACTIVE"],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        let total = 0;
        let byDay = [];

        for (let i = 0; i < data?.length; i++) {
          total += data[i]["Customers.count"];

          byDay.push(data[i]["Customers.count"]);
        }

        while (byDay?.length < 7) {
          byDay.push(null);
        }

        this.setState({
          newMemberResults: StringUtils.numberFormat(total),
          newMemberResultsByDay: byDay,
        });
      })
      .catch(() => {
        this.setState({ newMemberResults: "--", newMemberResultsByDay: [] });
      })
      .finally(() => {
        this.setState({
          newMemberResultsLoading: false,
        });
      });

    // Load Active this week Results
    cubejsApi
      .load({
        measures: ["Customers.count"],
        timeDimensions: [],
        order: {},
        dimensions: [],
        filters: [
          {
            member: "Customers.storeid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
          {
            member: "CustomerSubscriptions.status",
            operator: "equals",
            values: ["ACTIVE"],
          },
          {
            member: "CustomerSubscriptions.nextbilldate",
            operator: "equals",
            values: [moment().day(6).format("YYYY-MM-DD")],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        let total = 0;
        let byDay = [];

        console.log(data);

        for (let i = 0; i < data?.length; i++) {
          total += data[i]["Customers.count"];
        }

        this.setState({
          totalActiveResults: StringUtils.numberFormat(total),
        });
      })
      .catch(() => {
        this.setState({ totalActiveResults: "--" });
      })
      .finally(() => {
        this.setState({
          totalActiveResultsLoading: false,
        });
      });

    // Load HFNs
    cubejsApi
      .load({
        measures: ["Customers.count"],
        order: {
          "Customers.createdat": "asc",
        },
        dimensions: [],
        timeDimensions: [
          {
            dimension: "CustomerSubscriptions.holdDate",
            granularity: "day",
            dateRange: [
              moment()
                .day(0)
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .format("YYYY-MM-DD"),
              moment()
                .day(6)
                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(999)
                .format("YYYY-MM-DD"),
            ],
          },
        ],
        filters: [
          {
            member: "Customers.storeid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
          {
            member: "CustomerSubscriptions.status",
            operator: "equals",
            values: ["HOLD"],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        let total = 0;
        let byDay = [];

        console.log(data);

        for (let i = 0; i < data?.length; i++) {
          total += data[i]["Customers.count"];

          byDay.push(data[i]["Customers.count"]);
        }

        while (byDay?.length < 7) {
          byDay.push(null);
        }

        this.setState({
          hfnResults: StringUtils.numberFormat(total),
          hfnResultsByDay: byDay,
        });
      })
      .catch(() => {
        this.setState({ hfnResults: "--", hfnResultsByDay: [] });
      })
      .finally(() => {
        this.setState({
          hfnResultsLoading: false,
        });
      });

    // Load New Leads
    cubejsApi
      .load({
        measures: ["CustomerLeads.totalWithInformation"],
        timeDimensions: [
          {
            dimension: "CustomerLeads.createdAt",
            granularity: "day",
            dateRange: [
              moment()
                .day(0)
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .format("YYYY-MM-DD"),
              moment()
                .day(6)
                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(999)
                .format("YYYY-MM-DD"),
            ],
          },
        ],
        order: {
          "CustomerLeads.createdat": "asc",
        },
        dimensions: [],
        timeDimensions: [
          {
            dimension: "CustomerLeads.createdat",
            granularity: "day",
            dateRange: [
              moment()
                .day(0)
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .format("YYYY-MM-DD"),
              moment()
                .day(6)
                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(999)
                .format("YYYY-MM-DD"),
            ],
          },
        ],
        filters: [
          {
            member: "CustomerLeads.storeid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        let total = 0;
        let byDay = [];

        console.log(data);

        for (let i = 0; i < data?.length; i++) {
          total += data[i]["CustomerLeads.totalWithInformation"];

          byDay.push(data[i]["CustomerLeads.totalWithInformation"]);
        }

        while (byDay?.length < 7) {
          byDay.push(null);
        }

        this.setState({
          newLeadResults: StringUtils.numberFormat(total),
          newLeadResultsByDay: byDay,
        });
      })
      .catch(() => {
        this.setState({ newLeadResults: "--", newLeadResultsByDay: [] });
      })
      .finally(() => {
        this.setState({
          newLeadResultsLoading: false,
        });
      });

    // Load Converted Leads
    cubejsApi
      .load({
        measures: ["CustomerLeads.converted"],
        timeDimensions: [
          {
            dimension: "CustomerLeads.convertedat",
            granularity: "day",
            dateRange: [
              moment()
                .day(0)
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .format("YYYY-MM-DD"),
              moment()
                .day(6)
                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(999)
                .format("YYYY-MM-DD"),
            ],
          },
        ],
        order: {
          "CustomerLeads.createdat": "asc",
        },
        dimensions: [],
        filters: [
          {
            member: "CustomerLeads.storeid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        let total = 0;
        let byDay = [];

        console.log(data);

        for (let i = 0; i < data?.length; i++) {
          total += data[i]["CustomerLeads.converted"];

          byDay.push(data[i]["CustomerLeads.converted"]);
        }

        while (byDay?.length < 7) {
          byDay.push(null);
        }

        this.setState({
          convertedLeadResults: StringUtils.numberFormat(total),
          convertedLeadResultsByDay: byDay,
        });
      })
      .catch(() => {
        this.setState({
          convertedLeadResults: "--",
          convertedLeadResultsByDay: [],
        });
      })
      .finally(() => {
        this.setState({
          convertedLeadResultsLoading: false,
        });
      });

    // Load conversion rate
    cubejsApi
      .load({
        measures: ["CustomerLeads.closeRate"],
        timeDimensions: [
          {
            dimension: "CustomerLeads.createdat",
            dateRange: "Last 30 days",
          },
        ],
        order: {
          "CustomerLeads.createdat": "asc",
        },
        dimensions: [],
        filters: [
          {
            member: "CustomerLeads.storeid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        console.log(data);

        let total = data?.length ? data[0]["CustomerLeads.closeRate"] : "--";

        if (total != "--") {
          total = total.toFixed(1) + "%";
        }

        this.setState({
          conversionRateResults: total,
        });
      })
      .catch(() => {
        this.setState({
          conversionRateResults: "--",
          conversionRateResultsByDay: [],
        });
      })
      .finally(() => {
        this.setState({
          conversionRateResultsLoading: false,
        });
      });

    // Consultations
    cubejsApi
      .load({
        measures: ["Appointments.count"],
        timeDimensions: [
          {
            dimension: "Appointments.startsat",
            granularity: "day",
            dateRange: [
              moment()
                .day(0)
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .format("YYYY-MM-DD"),
              moment()
                .day(6)
                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(999)
                .format("YYYY-MM-DD"),
            ],
          },
        ],
        order: {
          "Appointments.startsat": "asc",
        },
        filters: [
          {
            member: "Appointments.storeid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
          {
            member: "Appointments.appointmenttype",
            operator: "equals",
            values: ["nc-consult"],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        let total = 0;
        let byDay = [];

        for (let i = 0; i < data?.length; i++) {
          total += data[i]["Appointments.count"];
          byDay.push(data[i]["Appointments.count"]);
        }

        while (byDay?.length < 7) {
          byDay.push(null);
        }

        this.setState({
          consultations: StringUtils.numberFormat(total),
          consultationsByDay: byDay,
        });
      })
      .catch(() => {
        this.setState({ consultations: "--" });
      })
      .finally(() => {
        this.setState({
          consultationsLoading: false,
        });
      });

    // Client Consultations
    cubejsApi
      .load({
        measures: ["Appointments.count"],
        timeDimensions: [
          {
            dimension: "Appointments.startsat",
            granularity: "day",
            dateRange: [
              moment()
                ?.day(0)
                .hour(0)
                .minute(0)
                .second(0)
                .milliseconds(0)
                ?.format("YYYY-MM-DD"),
              moment()
                ?.day(6)
                ?.hour(23)
                .minute(59)
                .second(59)
                .milliseconds(999)
                .format("YYYY-MM-DD"),
            ],
          },
        ],
        order: {
          "Appointments.startsat": "asc",
        },
        filters: [
          {
            member: "Appointments.storeid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
          {
            member: "Appointments.appointmenttype",
            operator: "equals",
            values: ["ec-consult"],
          },
          {
            member: "Appointments.status",
            operator: "equals",
            values: ["COMPLETED"],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        let total = 0;
        let byDay = [];

        for (let i = 0; i < data?.length; i++) {
          total += data[i]["Appointments.count"];
          byDay.push(data[i]["Appointments.count"]);
        }

        while (byDay?.length < 7) {
          byDay.push(null);
        }

        this.setState({
          clientConsultations: StringUtils.numberFormat(total),
          clientConsultationsByDay: byDay,
        });
      })
      .catch(() => {
        this.setState({ clientConsultations: "--" });
      })
      .finally(() => {
        this.setState({
          clientConsultationsLoading: false,
        });
      });

    // Referrals
    cubejsApi
      .load({
        measures: ["Metrics.totalEvents"],
        timeDimensions: [
          {
            dimension: "Metrics.createdat",
            granularity: "day",
            dateRange: [
              moment()
                ?.day(0)
                .hour(0)
                .minute(0)
                .second(0)
                .milliseconds(0)
                ?.format("YYYY-MM-DD"),
              moment()
                ?.hour(23)
                .minute(59)
                .second(59)
                .milliseconds(999)
                .format("YYYY-MM-DD"),
            ],
          },
        ],
        order: {
          "Metrics.createdat": "asc",
        },
        filters: [
          {
            member: "Metrics.metadatastoreid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
          {
            member: "Metrics.name",
            operator: "equals",
            values: ["member_referral"],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        let total = 0;
        let byDay = [];

        for (let i = 0; i < data?.length; i++) {
          total += data[i]["Metrics.totalEvents"];
          byDay.push(data[i]["Metrics.totalEvents"]);
        }

        while (byDay?.length < 7) {
          byDay.push(null);
        }

        this.setState({
          referrals: StringUtils.numberFormat(total),
          referralsByDay: byDay,
        });
      })
      .catch(() => {
        this.setState({ referrals: "--" });
      })
      .finally(() => {
        this.setState({
          referralsLoading: false,
        });
      });

    // Reactivations
    cubejsApi
      .load({
        measures: ["Customers.count"],
        order: {
          "Customers.createdat": "asc",
        },
        dimensions: [],
        timeDimensions: [
          {
            dimension: "CustomerSubscriptions.reactivateDate",
            granularity: "day",
            dateRange: [
              moment()
                .day(0)
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .format("YYYY-MM-DD"),
              moment()
                .day(6)
                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(999)
                .format("YYYY-MM-DD"),
            ],
          },
        ],
        filters: [
          {
            member: "CustomerSubscriptions.storeid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
          {
            member: "CustomerSubscriptions.status",
            operator: "equals",
            values: ["ACTIVE"],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        let total = 0;
        let byDay = [];

        for (let i = 0; i < data?.length; i++) {
          total += data[i]["Customers.count"];
          byDay.push(data[i]["Customers.count"]);
        }

        while (byDay?.length < 7) {
          byDay.push(null);
        }

        this.setState({
          reactivations: StringUtils.numberFormat(total),
          reactivationsByDay: byDay,
        });
      })
      .catch(() => {
        this.setState({ reactivations: "--" });
      })
      .finally(() => {
        this.setState({
          reactivationsLoading: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match?.params?.storeID != prevProps?.match?.params?.storeID &&
      this.props?.match?.params?.storeID != "select"
    ) {
      this.loadStores();

      this.loadCharts();
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    this.loadStores();
    this.loadCharts();

    PubSub.subscribe(Event.STORE.MODIFIED, (store) => {
      console.log(store);

      this.setState({
        store,
      });
    });
  }

  onFileChange(e) {
    this.setState({ selectedFile: e.target.files[0] });
  }

  testImageUpload(e) {
    e.preventDefault();

    // Create an object of formData
    let formData = new FormData();

    formData.append("file", this.state.selectedFile);

    console.log(formData);

    API.setMealImage(1, formData).then(
      (data) => {
        console.log(data);
      },
      (e) => {
        console.error(e);
      }
    );
  }

  clearSearch() {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);

      this.setState({
        searchTimeout: null,
      });
    }

    this.setState({
      search: "",
    });
  }

  handleTabChange(option, force = false) {
    let filters = {
      storeTask: true,
      type: "Franchise Partner",
      storeID: this.props?.match?.params?.storeID,
    };
    let orderBy = {
      dueDate: 1,
    };

    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    if (option.id == "All Upcoming & Overdue Tasks") {
      let mom = moment();

      mom.add(14, "days");

      filters = {
        status: { $in: ["Not Started", "In Progress"] },
        dueDate: {
          $lte: { $date: mom.toDate().toISOString() },
        },
        storeTask: true,
        type: "Franchise Partner",
        storeID: this.props?.match?.params?.storeID,
      };
    } else if (option.id == "All Incomplete Tasks") {
      filters = {
        status: { $in: ["Not Started", "In Progress"] },
        storeTask: true,
        type: "Franchise Partner",
        storeID: this.props?.match?.params?.storeID,
      };
    } else if (option.id == "All Completed Tasks") {
      filters = {
        status: { $in: ["Completed"] },
        storeTask: true,
        type: "Franchise Partner",
        storeID: this.props?.match?.params?.storeID,
      };
    }

    console.log(filters);

    this.setState({
      filters,
      orderBy,
      active: option,
    });

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.loadTasks(1, this.state.count, filters);
  }

  loadStores() {
    if (this.props.match?.params?.storeID != "select") {
      let selectedStoreObject = localStorage.getItem("selected_store_object");

      if (selectedStoreObject) {
        try {
          selectedStoreObject = JSON.parse(selectedStoreObject);

          if (selectedStoreObject?._id == this.props.match?.params?.storeID) {
            this.setState({ store: selectedStoreObject }, () => {
              this.fetchBanners();
            });

            return;
          } else {
            this.setState({
              loadingStores: true,
            });
          }
        } catch (e) {}
      }

      APIV2.getStoreByID(this.props.match?.params?.storeID)
        .then(
          (data) => {
            if (data && data.data) {
              let store = data?.data?.store;

              //console.log(localStorage.getItem("cache_user"));

              this.setState({ store }, () => {
                localStorage.setItem(
                  "selected_store_object",
                  JSON.stringify(store)
                );

                //this.handleTabChange(this.state.active, true);
                this.fetchBanners();
              });
            }
          },
          (e) => {
            console.error(e);
            window.alert(
              "There was an error loading the stores. Please reload and try again."
            );
          }
        )
        .finally(() => {
          this.setState({
            loadingStores: false,
          });
        });
    }
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadTasks(page, this.state.count, this.state.filters);
  }

  formatDueDate(dateString, status) {
    if (!dateString) {
      return "--";
    }

    let now = moment();
    let due = moment(dateString);

    if (now.toDate() > due.toDate() && status != "Completed") {
      return (
        <Badge color="" className="bg-yellow text-dark">
          {due.format("MMM DD, YYYY")}
        </Badge>
      );
    }

    return due.format("MMM DD, YYYY");
  }

  formatStatus(status) {
    if (!status) {
      return "--";
    }

    if (status == "Not Started") {
      return (
        <Badge color="" className="bg-yellow text-dark">
          {status}
        </Badge>
      );
    }

    if (status == "In Progress") {
      return <Badge color="info">{status}</Badge>;
    }

    if (status == "Completed") {
      return <Badge color="light">{status}</Badge>;
    }
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}

          {this.state.store &&
          this.state.banners?.length &&
          !this.state.loadingStores ? (
            <>
              {this.state.banners?.map((banner) => (
                <>
                  <div
                    key={banner?.data?._id}
                    className={`p-2 mb-3 border border-primary-superlight rounded shadow-sm bg-primary-ultralight ${
                      banner?.data?.interactiveEventType ? "cursor-pointer" : ""
                    }`}
                    onClick={() => {
                      if (!banner?.data?.interactiveEventType) {
                        return;
                      }

                      const action = banner?.data;

                      if (action?.interactiveEventType == "website-link") {
                        LinkUtils.openInNewTab(action?.interactiveEventData);
                      } else if (
                        action?.interactiveEventType == "in-app-page"
                      ) {
                        this.props.history.push(action?.interactiveEventData);
                      } else if (
                        action?.interactiveEventType == "in-app-event"
                      ) {
                        PubSub.publish(
                          action?.interactiveEventData,
                          action?.interactiveEventMetadata
                            ? action?.interactiveEventMetadata
                            : {}
                        );
                      }
                    }}
                  >
                    <Row className="align-items-center">
                      <Col xs="" sm="" className="pr-1">
                        {banner?.data?.header ? (
                          <h4 className="m-0 text-dark">
                            {banner?.data?.header}
                          </h4>
                        ) : null}
                        <p
                          className="text-dark m-0"
                          style={{ lineHeight: 1.3, fontSize: 14 }}
                        >
                          {banner?.data?.subheader}
                        </p>
                      </Col>
                      {banner?.data?.interactiveEventType ? (
                        <Col xs="auto" sm="auto" className="pl-0">
                          <Button
                            size="sm"
                            outline
                            color="dark"
                            className="p-0 border-0"
                            style={{
                              height: 30,
                              width: 30,
                              lineHeight: 1,
                              fontSize: 22,
                              textAlign: "center",
                            }}
                          >
                            <i className="mdi mdi-chevron-right"></i>
                          </Button>
                        </Col>
                      ) : null}
                    </Row>
                  </div>
                </>
              ))}
            </>
          ) : null}

          {!this.state.loadingStores &&
          this.state.store &&
          (!this.state.store?.operations?.storeOpening?.firstBillDate ||
            moment().isBefore(
              moment(
                this.state.store?.operations?.storeOpening?.firstBillDate
              ).add(120, "days")
            )) ? (
            <>
              <StoreOpeningWidget store={this.state.store}></StoreOpeningWidget>
            </>
          ) : null}
          {this.state.loadingStores ? (
            <>
              <Row>
                <div className="col">
                  <Row className="align-items-center">
                    <Col xs="12" md="" className="mb-3 mb-md-0">
                      <div
                        className="skeleton rounded"
                        style={{ height: 36, width: "100%" }}
                      ></div>
                    </Col>
                  </Row>
                  <hr className="mt-2 mb-3"></hr>
                  <div
                    className="skeleton rounded"
                    style={{ height: 540 }}
                  ></div>
                </div>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <div className="col">
                  <Row className="align-items-center">
                    <Col xs="12" md="" className="mb-3 mb-md-0">
                      <h2 className="mb-0 text-dark">
                        {this.state.store?.name
                          ? `${this.makePossessive(
                              this.state.store?.name
                                ?.replace("Project LeanNation", "")
                                ?.trim()
                            )} `
                          : ""}
                        Calendar
                      </h2>
                    </Col>
                    <Col xs="auto" className="pr-2">
                      <Whisper
                        placement="auto"
                        trigger={"click"}
                        preventOverflow={true}
                        enterable
                        ref={this.calendarViewDropdownRef}
                        speaker={
                          <Popover full className="dropdown-popover">
                            <Dropdown.Menu
                              style={{
                                minWidth: 200,
                                paddingBottom: 0,
                              }}
                            >
                              <Dropdown.Item
                                panel
                                className="font-weight-800 text-uppercase"
                                style={{
                                  padding: 12,
                                }}
                              >
                                <h5 className="m-0 text-dark">Calendar View</h5>
                              </Dropdown.Item>
                              <hr className="my-0"></hr>
                              <Dropdown.Item
                                onClick={() => {
                                  this.setState({
                                    calendarViewMode: "week",
                                  });

                                  LocalStorage.set(
                                    "storeCalendarViewMode",
                                    "week"
                                  );

                                  this.calendarViewDropdownRef.current.close();
                                }}
                                className="text-dark bg-superlight--hover"
                              >
                                <Row className="align-items-center">
                                  <Col xs="auto" className="pr-0">
                                    <i
                                      className={`mdi mdi-view-week ${
                                        this.state.calendarViewMode == "week" &&
                                        "text-primary"
                                      }`}
                                      style={{
                                        fontSize: 16,
                                        lineHeight: 1,
                                        position: "relative",
                                        top: 2,
                                      }}
                                    ></i>
                                  </Col>
                                  <Col xs="" className="pl-2">
                                    <p className="m-0" style={{ fontSize: 16 }}>
                                      Week
                                    </p>
                                  </Col>
                                </Row>
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  //this.settingsDropdownRef.current.close();

                                  LocalStorage.set(
                                    "storeCalendarViewMode",
                                    "month"
                                  );

                                  this.setState({
                                    calendarViewMode: "month",
                                  });

                                  this.calendarViewDropdownRef.current.close();
                                }}
                                className="text-dark bg-superlight--hover"
                              >
                                <Row className="align-items-center">
                                  <Col xs="auto" className="pr-0">
                                    <i
                                      className={`mdi mdi-view-module ${
                                        this.state.calendarViewMode ==
                                          "month" && "text-primary"
                                      }`}
                                      style={{
                                        fontSize: 16,
                                        lineHeight: 1,
                                        position: "relative",
                                        top: 2,
                                      }}
                                    ></i>
                                  </Col>
                                  <Col xs="" className="pl-2">
                                    <p className="m-0" style={{ fontSize: 16 }}>
                                      Month
                                    </p>
                                  </Col>
                                </Row>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Popover>
                        }
                      >
                        <Button
                          className="m-0 btn-icon-only"
                          size="sm"
                          color="dark"
                          outline
                        >
                          <i className="mdi mdi-table-headers-eye"></i>
                        </Button>
                      </Whisper>
                    </Col>
                    <Col
                      xs="auto"
                      sm=""
                      md="auto"
                      className="pl-0 pr-2 upcoming-appt-filter"
                    >
                      <div>
                        <CheckPicker
                          block
                          searchable={false}
                          placement="autoVertical"
                          label="Viewing"
                          placeholder="Select Event Types"
                          preventOverflow={true}
                          cleanable={false}
                          data={this.state.filterCalendarOptions}
                          value={this.state.filterCalendar}
                          onChange={(v) => {
                            console.log(v);

                            this.setState({
                              filterCalendar: v,
                            });
                          }}
                        ></CheckPicker>
                      </div>
                    </Col>
                    <Col xs="" sm="auto" className="pl-0 text-right">
                      <Whisper
                        placement="auto"
                        trigger={"click"}
                        preventOverflow={true}
                        enterable
                        ref={this.calendarDropdownRef}
                        speaker={
                          <Popover full className="dropdown-popover">
                            <Dropdown.Menu
                              style={{
                                minWidth: 200,
                                paddingBottom: 0,
                              }}
                            >
                              <Dropdown.Item
                                panel
                                className="font-weight-800 text-uppercase"
                                style={{
                                  padding: 12,
                                }}
                              >
                                <h5 className="m-0 text-dark">New</h5>
                              </Dropdown.Item>
                              <hr className="my-0"></hr>
                              <Dropdown.Item
                                onClick={() => {
                                  //this.settingsDropdownRef.current.close();

                                  this.setState({
                                    newConsult: true,
                                  });

                                  this.calendarDropdownRef.current.close();
                                }}
                                className="text-dark bg-superlight--hover"
                              >
                                <Row className="align-items-center">
                                  <Col xs="auto" className="pr-0">
                                    <i
                                      className={`mdi mdi-account-circle`}
                                      style={{
                                        fontSize: 16,
                                        lineHeight: 1,
                                        position: "relative",
                                        top: 2,
                                      }}
                                    ></i>
                                  </Col>
                                  <Col xs="" className="pl-2">
                                    <p className="m-0" style={{ fontSize: 16 }}>
                                      Appointment
                                    </p>
                                  </Col>
                                </Row>
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  //this.settingsDropdownRef.current.close();

                                  this.setState({
                                    openOutreach: true,
                                  });

                                  this.calendarDropdownRef.current.close();
                                }}
                                className="text-dark bg-superlight--hover"
                              >
                                <Row className="align-items-center">
                                  <Col xs="auto" className="pr-0">
                                    <i
                                      className={`mdi mdi-calendar-heart`}
                                      style={{
                                        fontSize: 16,
                                        lineHeight: 1,
                                        position: "relative",
                                        top: 2,
                                      }}
                                    ></i>
                                  </Col>
                                  <Col xs="" className="pl-2">
                                    <p className="m-0" style={{ fontSize: 16 }}>
                                      Community Event
                                    </p>
                                  </Col>
                                </Row>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Popover>
                        }
                      >
                        <Button
                          className="m-0 btn-icon-only"
                          size="sm"
                          color="primary"
                        >
                          <i className="mdi mdi-plus"></i>
                        </Button>
                      </Whisper>
                    </Col>
                  </Row>
                  <hr className="mt-2 mb-3"></hr>
                  {this.state.calendarViewMode == "week" ? (
                    <StoreUpcomingAppointmentsWidget
                      store={this.state.store}
                      filter={this.state.filterCalendar}
                    ></StoreUpcomingAppointmentsWidget>
                  ) : (
                    <StoreUpcomingMonthWidget
                      store={this.state.store}
                      filter={this.state.filterCalendar}
                    ></StoreUpcomingMonthWidget>
                  )}
                  <NewConsultationDrawer
                    open={this.state.newConsult}
                    store={this.state.store}
                    onClose={() => {
                      this.setState({ newConsult: false });
                    }}
                  ></NewConsultationDrawer>
                  <NewOutreachDrawer
                    open={this.state.openOutreach}
                    store={this.state.store}
                    onClose={() => {
                      this.setState({ openOutreach: false });
                    }}
                  ></NewOutreachDrawer>
                </div>
              </Row>
              <div
                className={`mb-5 ${
                  this.state.activeView != "member" && "d-none"
                }`}
              >
                <div>
                  <Row className="align-items-center">
                    <Col xs="12" sm="">
                      <h2 className="mb-0">Member Activity</h2>
                    </Col>
                    <Col xs="" sm="auto">
                      <p className="m-0">
                        This Week ({moment().day(0)?.format("MM/DD")}-
                        {moment().day(6).format("MM/DD")})
                      </p>
                    </Col>
                    <Col xs="auto" className="pl-0">
                      <Button
                        size="sm"
                        outline
                        color="dark"
                        onClick={() => {
                          this.props.history.push(`/reports/scorecard`);
                        }}
                      >
                        View Scorecard
                      </Button>
                    </Col>
                  </Row>
                </div>
                <hr className="mt-2 mb-3"></hr>
                <Row>
                  <Col xs="12" sm="12" md="4" className="mb-3">
                    <Card
                      style={{ height: "100%" }}
                      className="border shadow-sm"
                    >
                      <CardHeader className="border-0">
                        <Row className="align-items-center">
                          <Col xs="">
                            <h4 className="m-0">New Members</h4>
                          </Col>
                          <Col xs="auto">
                            <Whisper
                              placement="autoHorizontal"
                              trigger="hover"
                              speaker={
                                <Tooltip>
                                  Total number of members who signed up during
                                  the selected time range.
                                </Tooltip>
                              }
                            >
                              <div
                                className="cursor-pointer"
                                style={{ lineHeight: 1, fontSize: 18 }}
                              >
                                <i className="mdi mdi-information-outline"></i>
                              </div>
                            </Whisper>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody className="pt-0">
                        <h1 className="mb-4 display-4">
                          {this.state.newMemberResultsLoading ? (
                            <>
                              <Spinner size="sm" color="medium"></Spinner>
                            </>
                          ) : (
                            this.state.newMemberResults
                          )}
                        </h1>
                        <Sparklines
                          style={{ maxHeight: 20, width: "100%" }}
                          data={this.state?.newMemberResultsByDay}
                        >
                          <SparklinesBars
                            style={{
                              fill: "var(--primary)",
                            }}
                          />
                        </Sparklines>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="12" sm="12" md="4" className="mb-3">
                    <Card
                      style={{ height: "100%" }}
                      className="border shadow-sm"
                    >
                      <CardHeader className="border-0">
                        <Row className="align-items-center">
                          <Col xs="">
                            <h4 className="m-0">Active This Week</h4>
                          </Col>
                          <Col xs="auto">
                            <Whisper
                              placement="autoHorizontal"
                              trigger="hover"
                              speaker={
                                <Tooltip>
                                  Total number of active members with a next
                                  bill date on:{" "}
                                  {moment().day(6).format("dddd MMM DD, YYYY")}
                                </Tooltip>
                              }
                            >
                              <div
                                className="cursor-pointer"
                                style={{ lineHeight: 1, fontSize: 18 }}
                              >
                                <i className="mdi mdi-information-outline"></i>
                              </div>
                            </Whisper>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody className="pt-0">
                        <h1 className="mb-3 display-4">
                          {this.state.totalActiveResultsLoading ? (
                            <>
                              <Spinner size="sm" color="medium"></Spinner>
                            </>
                          ) : (
                            this.state.totalActiveResults
                          )}
                        </h1>
                        <p className="mb-0">
                          For {moment().day(6).format("MMMM DD, YYYY")}
                        </p>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col xs="12" sm="12" md="4" className="mb-3">
                    <Card
                      style={{ height: "100%" }}
                      className="border shadow-sm"
                    >
                      <CardHeader className="border-0">
                        <Row className="align-items-center">
                          <Col xs="">
                            <h4 className="m-0">HFNs</h4>
                          </Col>
                          <Col xs="auto">
                            <Whisper
                              placement="autoHorizontal"
                              trigger="hover"
                              speaker={
                                <Tooltip>
                                  Total number of members who were placed on
                                  HOLD during the selected time range.
                                </Tooltip>
                              }
                            >
                              <div
                                className="cursor-pointer"
                                style={{ lineHeight: 1, fontSize: 18 }}
                              >
                                <i className="mdi mdi-information-outline"></i>
                              </div>
                            </Whisper>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody className="pt-0">
                        <h1 className="mb-4 display-4">
                          {this.state.hfnResultsLoading ? (
                            <>
                              <Spinner size="sm" color="medium"></Spinner>
                            </>
                          ) : (
                            this.state.hfnResults
                          )}
                        </h1>
                        <Sparklines
                          style={{ maxHeight: 20, width: "100%" }}
                          data={this.state?.hfnResultsByDay}
                        >
                          <SparklinesBars
                            style={{
                              fill: "var(--primary)",
                            }}
                          />
                        </Sparklines>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="12" sm="12" md="4" className="mb-3">
                    <Card
                      style={{ height: "100%" }}
                      className="border shadow-sm"
                    >
                      <CardHeader className="border-0">
                        <Row className="align-items-center">
                          <Col xs="">
                            <h4 className="m-0">Reactivations</h4>
                          </Col>
                          <Col xs="auto">
                            <Whisper
                              placement="autoHorizontal"
                              trigger="hover"
                              speaker={
                                <Tooltip>
                                  Total number of members who came off HOLD were
                                  during the selected time range.
                                </Tooltip>
                              }
                            >
                              <div
                                className="cursor-pointer"
                                style={{ lineHeight: 1, fontSize: 18 }}
                              >
                                <i className="mdi mdi-information-outline"></i>
                              </div>
                            </Whisper>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody className="pt-0">
                        <h1 className="mb-4 display-4">
                          {this.state.reactivationsLoading ? (
                            <>
                              <Spinner size="sm" color="medium"></Spinner>
                            </>
                          ) : (
                            this.state.reactivations
                          )}
                        </h1>
                        <Sparklines
                          style={{ maxHeight: 20, width: "100%" }}
                          data={this.state?.reactivationsByDay}
                        >
                          <SparklinesBars
                            style={{
                              fill: "var(--primary)",
                            }}
                          />
                        </Sparklines>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="12" sm="12" md="4" className="mb-3">
                    <Card
                      style={{ height: "100%" }}
                      className="border shadow-sm"
                    >
                      <CardHeader className="border-0">
                        <Row className="align-items-center">
                          <Col xs="">
                            <h4 className="m-0">Member Check-Ins</h4>
                          </Col>
                          <Col xs="auto">
                            <Whisper
                              placement="autoHorizontal"
                              trigger="hover"
                              speaker={
                                <Tooltip>
                                  Total number of Member Check-Ins performed
                                  during the selected time range.
                                </Tooltip>
                              }
                            >
                              <div
                                className="cursor-pointer"
                                style={{ lineHeight: 1, fontSize: 18 }}
                              >
                                <i className="mdi mdi-information-outline"></i>
                              </div>
                            </Whisper>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody className="pt-0">
                        <h1 className="mb-4 display-4">
                          {this.state.clientConsultationsLoading ? (
                            <>
                              <Spinner size="sm" color="medium"></Spinner>
                            </>
                          ) : (
                            this.state.clientConsultations
                          )}
                        </h1>

                        <Sparklines
                          style={{ maxHeight: 20, width: "100%" }}
                          data={this.state.clientConsultationsByDay}
                        >
                          <SparklinesBars
                            style={{
                              fill: "var(--primary)",
                            }}
                          />
                        </Sparklines>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="12" sm="12" md="4" className="mb-3">
                    <Card
                      style={{ height: "100%" }}
                      className="border shadow-sm"
                    >
                      <CardHeader className="border-0">
                        <Row className="align-items-center">
                          <Col xs="">
                            <h4 className="m-0">Referrals</h4>
                          </Col>
                          <Col xs="auto">
                            <Whisper
                              placement="autoHorizontal"
                              trigger="hover"
                              speaker={
                                <Tooltip>
                                  Total number of new &amp; reactivated members
                                  who were referred by another PLN member during
                                  the selected time range.
                                </Tooltip>
                              }
                            >
                              <div
                                className="cursor-pointer"
                                style={{ lineHeight: 1, fontSize: 18 }}
                              >
                                <i className="mdi mdi-information-outline"></i>
                              </div>
                            </Whisper>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody className="pt-0">
                        <h1 className="mb-4 display-4">
                          {this.state.referralsLoading ? (
                            <>
                              <Spinner size="sm" color="medium"></Spinner>
                            </>
                          ) : (
                            this.state.referrals
                          )}
                        </h1>
                        <Sparklines
                          style={{ maxHeight: 20, width: "100%" }}
                          data={this.state?.referralsByDay}
                        >
                          <SparklinesBars
                            style={{
                              fill: "var(--primary)",
                            }}
                          />
                        </Sparklines>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
              <div
                className={`mb-5 ${
                  this.state.activeView != "lead" && "d-none"
                }`}
              >
                <div>
                  <Row className="align-items-center">
                    <Col xs="">
                      <h2 className="mb-0">Lead Activity</h2>
                    </Col>
                    <Col xs="auto">
                      <SelectPicker
                        searchable={false}
                        cleanable={false}
                        size="sm"
                        value={this.state.leadActivity}
                        placement="auto"
                        data={[
                          {
                            label: "This Week (Sun-Sat)",
                            value: "This Week (Sun-Sat)",
                          },
                        ]}
                      ></SelectPicker>
                    </Col>
                  </Row>
                </div>
                <hr className="mt-2 mb-3"></hr>
                <Row>
                  <Col xs="12" sm="12" md="4" className="mb-3">
                    <Card
                      style={{ height: "100%" }}
                      className="border shadow-sm"
                    >
                      <CardHeader className="border-0">
                        <Row className="align-items-center">
                          <Col xs="">
                            <h4 className="m-0">New Leads</h4>
                          </Col>
                          <Col xs="auto">
                            <Whisper
                              placement="autoHorizontal"
                              trigger="hover"
                              speaker={
                                <Tooltip>
                                  Total number of leads created with a name,
                                  email, and phone during the selected time
                                  range.
                                </Tooltip>
                              }
                            >
                              <div
                                className="cursor-pointer"
                                style={{ lineHeight: 1, fontSize: 18 }}
                              >
                                <i className="mdi mdi-information-outline"></i>
                              </div>
                            </Whisper>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody className="pt-0">
                        <h1 className="mb-4 display-4">
                          {this.state.newLeadResultsLoading ? (
                            <>
                              <Spinner size="sm" color="medium"></Spinner>
                            </>
                          ) : (
                            this.state.newLeadResults
                          )}
                        </h1>

                        <Sparklines
                          style={{ maxHeight: 20, width: "100%" }}
                          data={this.state.newLeadResultsByDay}
                        >
                          <SparklinesBars
                            style={{
                              fill: "var(--primary)",
                            }}
                          />
                        </Sparklines>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="12" sm="12" md="4" className="mb-3">
                    <Card
                      style={{ height: "100%" }}
                      className="border shadow-sm"
                    >
                      <CardHeader className="border-0">
                        <Row className="align-items-center">
                          <Col xs="">
                            <h4 className="m-0">Converted Leads</h4>
                          </Col>
                          <Col xs="auto">
                            <Whisper
                              placement="autoHorizontal"
                              trigger="hover"
                              speaker={
                                <Tooltip>
                                  Total number of leads converted within the
                                  selected time range.
                                </Tooltip>
                              }
                            >
                              <div
                                className="cursor-pointer"
                                style={{ lineHeight: 1, fontSize: 18 }}
                              >
                                <i className="mdi mdi-information-outline"></i>
                              </div>
                            </Whisper>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody className="pt-0">
                        <h1 className="mb-4 display-4">
                          {this.state.convertedLeadResultsLoading ? (
                            <>
                              <Spinner size="sm" color="medium"></Spinner>
                            </>
                          ) : (
                            this.state.convertedLeadResults
                          )}
                        </h1>

                        <Sparklines
                          style={{ maxHeight: 20, width: "100%" }}
                          data={this.state.convertedLeadResultsByDay}
                        >
                          <SparklinesBars
                            style={{
                              fill: "var(--primary)",
                            }}
                          />
                        </Sparklines>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="12" sm="12" md="4" className="mb-3">
                    <Card
                      style={{ height: "100%" }}
                      className="border shadow-sm"
                    >
                      <CardHeader className="border-0">
                        <Row className="align-items-center">
                          <Col xs="">
                            <h4 className="m-0">Avg. Conversion Rate</h4>
                          </Col>
                          <Col xs="auto">
                            <Whisper
                              placement="autoHorizontal"
                              trigger="hover"
                              speaker={
                                <Tooltip>
                                  Average percentage of leads (with name, email,
                                  phone) that were created and converted within
                                  the last 30 days.
                                </Tooltip>
                              }
                            >
                              <div
                                className="cursor-pointer"
                                style={{ lineHeight: 1, fontSize: 18 }}
                              >
                                <i className="mdi mdi-information-outline"></i>
                              </div>
                            </Whisper>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody className="pt-0">
                        <h1 className="mb-4 display-4">
                          {this.state.conversionRateResultsLoading ? (
                            <>
                              <Spinner size="sm" color="medium"></Spinner>
                            </>
                          ) : (
                            this.state.conversionRateResults
                          )}
                        </h1>
                        <p className="mb-0">Last 30 Days</p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="12" sm="12" md="4" className="mt-3 mb-3 mb-md-0">
                    <Card
                      style={{ height: "100%" }}
                      className="border shadow-sm"
                    >
                      <CardHeader className="border-0">
                        <Row className="align-items-center">
                          <Col xs="">
                            <h4 className="m-0">LEAN Consultations</h4>
                          </Col>
                          <Col xs="auto">
                            <Whisper
                              placement="autoHorizontal"
                              trigger="hover"
                              speaker={
                                <Tooltip>
                                  Total number of LEAN consultations performed
                                  during the selected time range.
                                </Tooltip>
                              }
                            >
                              <div
                                className="cursor-pointer"
                                style={{ lineHeight: 1, fontSize: 18 }}
                              >
                                <i className="mdi mdi-information-outline"></i>
                              </div>
                            </Whisper>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody className="pt-0">
                        <h1 className="mb-4 display-4">
                          {this.state.consultationsLoading ? (
                            <>
                              <Spinner size="sm" color="medium"></Spinner>
                            </>
                          ) : (
                            this.state.consultations
                          )}
                        </h1>

                        <Sparklines
                          style={{ maxHeight: 20, width: "100%" }}
                          data={this.state.consultationsByDay}
                        >
                          <SparklinesBars
                            style={{
                              fill: "var(--primary)",
                            }}
                          />
                        </Sparklines>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
              <div className="mt--4 mb-5">
                <Row className="justify-content-center">
                  <Col xs="12" sm="5" md="4" lg="4">
                    <Row className="align-items-center">
                      <Col xs="auto">
                        <Button
                          size="sm"
                          outline
                          color="dark"
                          className="btn-icon-only"
                          onClick={() => {
                            if (this.state.activeView == "member") {
                              return this.setState({
                                activeView: "lead",
                              });
                            }

                            return this.setState({
                              activeView: "member",
                            });
                          }}
                        >
                          <i className="mdi mdi-chevron-left"></i>
                        </Button>
                      </Col>
                      <Col xs="">
                        <div className="text-center">
                          <div
                            className={`rounded d-inline-block mr-2 ${
                              this.state.activeView == "member"
                                ? "bg-primary"
                                : "bg-light"
                            }`}
                            style={{ width: 10, height: 10 }}
                          ></div>
                          <div
                            className={`rounded d-inline-block ${
                              this.state.activeView == "lead"
                                ? "bg-primary"
                                : "bg-light"
                            }`}
                            style={{ width: 10, height: 10 }}
                          ></div>
                        </div>
                      </Col>
                      <Col xs="auto">
                        <Button
                          size="sm"
                          outline
                          color="dark"
                          className="btn-icon-only"
                          onClick={() => {
                            if (this.state.activeView == "member") {
                              return this.setState({
                                activeView: "lead",
                              });
                            }

                            return this.setState({
                              activeView: "member",
                            });
                          }}
                        >
                          <i className="mdi mdi-chevron-right"></i>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </Container>

        <TaskDetailDrawer
          preventModify={true}
          preventDelete={true}
          store={this.state.store}
          open={this.state.detailOpen}
          task={this.state.detailTask}
          onClose={() => {
            this.setState({
              detailOpen: false,
              detailTask: null,
            });
          }}
        ></TaskDetailDrawer>
      </>
    );
  }
  f;
}

export default StorefrontHomeView;
