import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  BarChart,
  BarList,
  Card,
  CategoryBar,
  DeltaBar,
  DonutChart,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import {
  Axis,
  Chart,
  Coordinate,
  Interaction,
  Interval,
  Legend,
  Tooltip,
} from "bizcharts";
import ChartMetricHeader from "./ChartMetricHeader";

class FulfillmentRateByStoreCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    tab: "absolute",
  };

  /**
   * Fetches a summary of all the member data
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchData(cubejsApi, stores, dateRange, metricNames = []) {
    let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

    secondDifference = secondDifference / 1000;

    let granularity = secondDifference > 60 * 60 * 24 * 90 ? "week" : "day";

    if (secondDifference >= 60 * 60 * 24 * 364) {
      granularity = "month";
    }

    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["SubscriptionFulfillmentReports.percentFulfilled"],
          order: {
            "SubscriptionFulfillmentReports.createdat": "asc",
          },
          timeDimensions: [
            {
              dimension: "SubscriptionFulfillmentReports.fulfillmentdate",
              dateRange: [dateRange[0], dateRange[1]],
              granularity: "week",
            },
          ],
          filters: [
            {
              member: "SubscriptionFulfillmentReports.storeid",
              operator: "equals",
              values: stores,
            },
            {
              member: "SubscriptionFulfillmentReports.fulfillmentdate",
              operator: "beforeDate",
              values: [moment().toISOString()],
            },
            {
              member: "SubscriptionFulfillmentReports.orderCount",
              operator: "notEquals",
              values: ["0"],
            },
          ],
          dimensions: ["Stores.name"],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          if (!data?.length) {
            return resolve(null);
          }

          let stores = [];

          let out = [];

          for (let i = 0; i < data.length; i++) {
            const item = data[i];

            if (!stores?.includes(item["Stores.name"])) {
              stores.push(item["Stores.name"]);
            }

            const idx = _.findIndex(out, {
              storeName: item["Stores.name"]
                ?.replace("Project LeanNation", "PLN")
                ?.trim(),
            });

            if (idx >= 0) {
              out[idx].count +=
                item["SubscriptionFulfillmentReports.percentFulfilled"];
              out[idx].order++;
            } else {
              out.push({
                count: item["SubscriptionFulfillmentReports.percentFulfilled"],
                order: 1,
                storeName: item["Stores.name"]
                  ?.replace("Project LeanNation", "PLN")
                  ?.trim(),
              });
            }
          }

          let total = 0;
          let orders = 0;

          for (let i = 0; i < out.length; i++) {
            total += out[i].count;
            orders += out[i].order;

            out[i].count = out[i].count / out[i].order;
          }

          return resolve({
            total: total / orders,
            history: out,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async _fetchReport(stores, dateRange) {
    this.setState({
      loading: true,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    if (!stores?.length) {
      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchData(cubejsApi, stores, dateRange);

      currentCount.history = _.sortBy(currentCount.history, "count");
      currentCount.history.reverse();
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load new member quantity.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });
    }

    let total = 0;
    let count = 0;

    for (let i = 0; i < currentCount?.history?.length; i++) {
      count++;

      total += currentCount?.history[i]?.count;
    }

    console.log("STATS", total, count);

    this.setState({
      currentCount,
      average: currentCount?.total,
      loading: false,
    });

    if (typeof this.props.onLoaded == "function") {
      this.props.onLoaded();
    }
  }

  /**
   * Load all report information
   *
   * @param {*} stores
   * @param {*} dateRange
   * @param {*} compare
   */
  async loadReport(stores, dateRange, compare) {
    this.setState({
      loading: true,
    });

    this._fetchReport(stores, dateRange);
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.stores != prevProps?.stores && this.props.stores?.length) ||
      this.props.dateRange != prevProps?.dateRange ||
      this.props.comparePrevious != prevProps.comparePrevious
    ) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }

    if (this.props.reload != prevProps.reload && this.props.reload) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  componentDidMount() {
    if (this.props.stores && this.props.dateRange) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }

    if (this.props.reload) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  render() {
    return (
      <>
        <Card marginTop="mt-0">
          <ChartMetricHeader
            title="Fulfillment Rate By Store"
            actions={
              <>
                <Button
                  size="sm"
                  outline
                  color="dark"
                  className="border-0 btn-icon-only"
                  disabled={this.state.loading}
                  onClick={() => {
                    this.loadReport(
                      this.props.stores,
                      this.props.dateRange,
                      this.props.comparePrevious
                    );
                  }}
                >
                  {this.state.loading ? (
                    <Spinner size="sm"></Spinner>
                  ) : (
                    <i className="mdi mdi-refresh"></i>
                  )}
                </Button>
              </>
            }
            loading={this.state.loading}
            dataAvailable={this.state.dataAvailable}
            metric={`~${StringUtils.numberFormat(
              parseFloat(this.state.average?.toFixed(1))
            )}% / Store`}
            comparisonMetric={null}
            dateRange={this.props.dateRange}
            deltaType={null}
            percentChange={null}
            showPercentChange={false}
          ></ChartMetricHeader>
          <TabList
            color="orange"
            defaultValue="absolute"
            handleSelect={(value) => {
              this.setState({
                tab: value,
              });
            }}
            marginTop="mt-3"
            disabled={this.state.loading || !this.state.dataAvailable}
          >
            <Tab value="absolute" text="Absolute" />
            <Tab value="relative" text="Relative" />
          </TabList>

          {this.state.loading ? (
            <div
              className="skeleton mt-3"
              style={{ height: "calc(327px + 1rem)", width: "100%" }}
            >
              &nbsp;
            </div>
          ) : (
            <>
              {this.state.dataAvailable ? (
                <>
                  {this.state.tab == "absolute" ? (
                    <div className="mt-3 mx--4 px-4">
                      <div className="mb--4 pb-4">
                        {this.state.currentCount?.history?.map((store, i) => (
                          <div
                            key={i}
                            className={`${i != 0 && "mt-4 border-top"} pt-3`}
                          >
                            <Row className="align-items-center mb-2">
                              <Col xs="12" sm="">
                                <p
                                  className="mt-0 mb-0 text-dark font-weight-500"
                                  style={{ fontSize: 16, lineHeight: 1.3 }}
                                >
                                  {store?.storeName}
                                  <span className="font-weight-normal small">
                                    &nbsp;({store?.order} Fulfillment
                                    {store?.order != 1 ? "s" : ""})
                                  </span>
                                </p>
                              </Col>
                              <Col xs="12" sm="auto" className="mt-1 mt-sm-0">
                                <Text>
                                  {StringUtils.numberFormat(
                                    store?.count.toFixed(1)
                                  )}
                                  %&nbsp;Fulfillment
                                </Text>
                              </Col>
                            </Row>

                            <CategoryBar
                              marginTop="mt-1"
                              percentageValue={store?.count}
                              colors={[
                                "red",
                                "yellow",
                                "lime",
                                "green",
                                "emerald",
                              ]}
                              categoryPercentageValues={[30, 10, 10, 50]}
                              showLabels={true}
                              showAnimation={true}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="mt-3 mx--4 px-4">
                      <div className="mb--4 pb-4">
                        {this.state.currentCount?.history?.map((store, i) => (
                          <div
                            key={i}
                            className={`${i != 0 && "mt-4 border-top"} pt-3`}
                          >
                            <p
                              className="mt-0 mb-1 text-dark font-weight-500"
                              style={{ fontSize: 16, lineHeight: 1.3 }}
                            >
                              {store?.storeName}
                              <span className="font-weight-normal small">
                                &nbsp;({store?.order} Fulfillment
                                {store?.order != 1 ? "s" : ""})
                              </span>
                            </p>

                            <Row className="align-items-center mb-2">
                              <Col xs="12" sm="">
                                <Text>
                                  {StringUtils.numberFormat(
                                    store?.count.toFixed(1)
                                  )}
                                  %&nbsp;Fulfillment
                                </Text>
                              </Col>
                              <Col xs="12" sm="auto" className="mt-1 mt-sm-0">
                                <Text>
                                  {store?.count - this.state.average > 0
                                    ? "+"
                                    : ""}
                                  {StringUtils.numberFormat(
                                    (store?.count - this.state.average).toFixed(
                                      1
                                    )
                                  )}
                                  %&nbsp;vs. all-store avg.
                                </Text>
                              </Col>
                            </Row>

                            <DeltaBar
                              marginTop="mt-1"
                              percentageValue={
                                store?.count - this.state.average
                              }
                              isIncreasePositive={true}
                              showAnimation={true}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "calc(327px + 1rem)", width: "100%" }}
                >
                  <p className="m-0">
                    No data available.
                    {this.state.error ? ` ${this.state.error}` : null}
                  </p>
                </div>
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default FulfillmentRateByStoreCard;
