import { Check, Trash } from "lucide-react";
import React, { useEffect, useRef } from "react";

/**
interface LinkSelectorProps {
  editor: Editor;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}
 */

export const LinkSelector = ({ editor, isOpen, setIsOpen }) => {
  const inputRef = useRef(null);

  // Autofocus on input by default
  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  });

  return (
    <div className="relative" style={{ height: 40 }}>
      <button
        style={{ marginTop: 4 }}
        className="border-left d-flex align-items-center px-2 py-2 font-medium text-dark "
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <p style={{ lineHeight: 1 }} className="m-0 text-base">
          ↗
        </p>
        <p
          style={{ lineHeight: 1 }}
          className={`m-0 pl-2 text-underline ${
            editor.isActive("link") ? "text-primary" : ""
          }`}
        >
          Link
        </p>
      </button>
      {isOpen && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const input = e.target[0];

            console.log(editor.chain().focus());

            editor.chain().focus().setLink({ href: input.value }).run();
            setIsOpen(false);
          }}
          style={{ position: "fixed", overflow: "hidden" }}
          className="mt-1 d-flex  rounded border border-stone-200 bg-white p-1 shadow-xl animate-in fade-in slide-in-from-top-1"
        >
          <input
            ref={inputRef}
            type="url"
            placeholder="Paste a link"
            className="form-control form-control-sm"
            defaultValue={editor?.getAttributes("link")?.href || ""}
          />
          {editor?.getAttributes("link")?.href ? (
            <button
              className="ml-2 btn btn-danger btn-sm btn-icon-only"
              onClick={() => {
                editor.chain().focus().unsetLink().run();
                setIsOpen(false);
              }}
            >
              <i className="mdi mdi-trash-can"></i>
            </button>
          ) : (
            <button className="ml-2 btn btn-primary btn-sm btn-icon-only">
              <i className="mdi mdi-check"></i>
            </button>
          )}
        </form>
      )}
    </div>
  );
};
