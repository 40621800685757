import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  Tooltip,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import Toolbar from "rsuite/esm/DatePicker/Toolbar";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import { TooltipController } from "bizcharts";
import LinkUtils from "lib/LinkUtils";

class LearningCourseSelectorDrawer extends React.Component {
  state = {
    name: "",
    description: "",
    courses: [],
    selectedCourses: [],
  };

  getCourses() {
    this.setState({
      loadingCourses: true,
    });

    APIV2.getLearningCourses(1, 1000, {}, { name: 1 })
      .then(
        (data) => {
          this.setState({
            courses: data?.data?.courses,
          });
        },
        () => {
          window.alert("Failed to get courses. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          loadingCourses: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.open != prevProps.open && this.props.open) {
      this.getCourses();

      if (this.props.selectedCourses) {
        this.setState({
          selectedCourses: this.props.selectedCourses?.length
            ? this.props.selectedCourses
            : null,
        });
      }
    }

    if (this.props.selectedCourses != prevProps.selectedCourses) {
      this.setState({
        selectedCourses: this.props.selectedCourses?.length
          ? this.props.selectedCourses
          : null,
      });
    }
  }

  selectedCourse(course, selected) {
    if (typeof this.props.onSelect == "function") {
      this.props.onSelect(course, selected);
    }
  }

  componentDidMount() {
    this.getCourses();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => {
            if (typeof this.props.onClose == "function") {
              this.props.onClose();
            }
          }}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              All Courses
            </h3>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            {this.state.courses?.map((course, i) => (
              <>
                <div
                  className={`pb-3 ${i != 0 && "pt-3"} ${
                    i != this.state.courses?.length - 1 && "border-bottom"
                  }`}
                  key={course?._id}
                >
                  <Row className="align-items-center">
                    <Col xs="">
                      <h4 className="m-0">
                        {course?.draft ? (
                          <Badge color="light" className="border mr-2">
                            DRAFT
                          </Badge>
                        ) : null}
                        {course?.name}
                      </h4>
                      {course.description ? (
                        <>
                          <p className="small m-0" style={{ lineHeight: 1.2 }}>
                            {course?.description}
                          </p>
                        </>
                      ) : null}
                    </Col>
                    <Col xs="auto">
                      <Button
                        size="sm"
                        disabled={this.state.selectedCourses?.includes(
                          course?._id
                        )}
                        color="primary"
                        outline={
                          !this.state.selectedCourses?.includes(course?._id)
                        }
                        onClick={() => {
                          if (
                            this.state.selectedCourses?.includes(course?._id)
                          ) {
                            return;
                          }

                          this.selectedCourse(course, true);
                        }}
                      >
                        {this.state.selectedCourses?.includes(course?._id)
                          ? "Added"
                          : "Add"}
                      </Button>
                      <Button
                        size="sm"
                        color="dark"
                        outline
                        disabled={this.state.deletingCourse == course?._id}
                        onClick={() => {
                          LinkUtils.openInNewTab(
                            `/university/lmsmanager/courses/${course?._id}/overview`,
                            true
                          );
                        }}
                      >
                        View
                      </Button>
                    </Col>
                  </Row>
                </div>
              </>
            ))}
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(LearningCourseSelectorDrawer);
