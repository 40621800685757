import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeaderV2 from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker, Tooltip, Whisper } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";
import ContentCalendarDetailDrawer from "../../components/Drawers/ContentCalendarDetailDrawer";

class SocialContentListView extends React.Component {
  state = {
    active: {
      id: "All Content",
      name: "All Content",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 25,
    filters: null,
    orderBy: {
      scheduledFor: -1,
    },
    sortByOption: "dueDate",
    sortByDirection: "ascending",
    viewMode: "table",
  };

  componentDidUpdate(prevProps) {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab && tab != this.state.active?.id) {
      this.handleTabChange({
        id: decodeURIComponent(tab),
        name: decodeURIComponent(tab),
      });
    }

    if (prevProps?.match?.params.storeID != this.props.match?.params?.storeID) {
      this.handleTabChange(this.state.active, true);
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab) {
      this.handleTabChange(
        {
          id: decodeURIComponent(tab),
          name: decodeURIComponent(tab),
        },
        true
      );
    } else {
      this.handleTabChange(this.state.active, true);
    }

    PubSub.subscribe(Event.CONTENT.CREATED, (m) => {
      this.loadContent(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );
    });

    PubSub.subscribe(Event.CONTENT.MODIFIED, (a) => {
      this.loadContent(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );

      if (this.state.detailContent?._id == a?._id) {
        this.setState({
          detailContent: a,
        });
      }
    });

    PubSub.subscribe(Event.CONTENT.DELETED, (a) => {
      this.loadContent(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );
    });
  }

  onFileChange(e) {
    this.setState({ selectedFile: e.target.files[0] });
  }

  testImageUpload(e) {
    e.preventDefault();

    // Create an object of formData
    let formData = new FormData();

    formData.append("file", this.state.selectedFile);

    console.log(formData);

    API.setMealImage(1, formData).then(
      (data) => {
        console.log(data);
      },
      (e) => {
        console.error(e);
      }
    );
  }

  loadContent(page = 1, count = 25, filters = null, orderBy) {
    this.setState({
      loadingContent: true,
    });

    APIV2.getContentCalendar(page, count, filters, orderBy)
      .then(
        (data) => {
          if (data?.data) {
            let content = [];

            if (data?.data?.content?.length) {
              content = data.data.content;
            }

            let existing = JSON.stringify(this.state.content);

            if (JSON.stringify(content) != existing) {
              this.setState({
                content,
                pagination: data.data.pagination,
                result: data.data.result,
              });
            }
          }
        },
        (e) => {
          console.error(e);

          window.alert(
            "There was an error loading the social media content. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingContent: false,
        });
      });
  }

  performSearch() {
    console.log(this.state.search);

    APIV2.simpleSearchContentForStore(
      this.props?.match?.params?.storeID,
      this.state.search
    )
      .then(
        (data) => {
          this.setState({
            searchResultContent: data.data.content,
            searchResults: true,
          });
        },
        (e) => {
          console.error(e);

          this.setState({ searchResults: true, searchResultContent: [] });
        }
      )
      .finally(() => {
        this.setState({
          searchLoading: false,
        });
      });
  }

  clearSearch() {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);

      this.setState({
        searchTimeout: null,
      });
    }

    this.setState({
      search: "",
    });
  }

  handleTabChange(option, force = false) {
    let filters = null;
    let orderBy = {
      scheduledFor: -1,
    };

    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    let user = LocalStorage.get(Constant.CACHE.USER);

    if (option.id == "All Content") {
      filters = {
        type: "Social Media",
      };
    } else if (option.id == "Stories & Reels") {
      filters = {
        type: "Social Media",
        postTypes: ["Story"],
      };
    } else if (option.id == "Posts") {
      filters = {
        type: "Social Media",
        postTypes: ["Post"],
      };
    }

    this.setState({
      filters,
      orderBy,
      active: option,
    });

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.loadContent(1, this.state.count, filters, orderBy);
  }

  handleSearchChange(e) {
    const { name, value } = e.target;

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    if (value) {
      this.setState({
        searchLoading: true,
      });
    } else {
      this.setState({
        searchLoading: false,
        searchResults: false,
      });
    }

    if (value && value.length >= 3) {
      let timeout = setTimeout(() => {
        this.performSearch();
      }, 1200);

      this.setState({
        searchTimeout: timeout,
      });
    }

    this.setState({
      [name]: value,
    });
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadContent(
      page,
      this.state.count,
      this.state.filters,
      this.state.orderBy
    );
  }

  renderPlatforms(content) {
    let out = [];

    if (content?.type == "Email") {
      return (
        <>
          <div className="d-flex">
            <svg
              style={{
                height: 18,
                width: 18,
                marginRight: "0.1rem",
                display: "inline-block",
              }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>email</title>
              <path
                fill="var(--dark)"
                d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"
              />
            </svg>
          </div>
        </>
      );
    }

    if (content.type == "Broadcast") {
      return (
        <>
          <div className="d-flex">
            <svg
              style={{
                height: 18,
                width: 18,
                marginRight: "0.1rem",
                display: "inline-block",
              }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>message-fast</title>
              <path
                fill="var(--dark)"
                d="M3 7C2.4 7 2 7.4 2 8S2.4 9 3 9H5V7H3M2 11C1.4 11 1 11.4 1 12S1.4 13 2 13H5V11H2M1 15C.4 15 0 15.4 0 16C0 16.6 .4 17 1 17H5V15H1M20 5H9C7.9 5 7 5.9 7 7V21L11 17H20C21.1 17 22 16.1 22 15V7C22 5.9 21.1 5 20 5Z"
              />
            </svg>
          </div>
        </>
      );
    }

    if (content?.platforms.includes("Instagram")) {
      out.push(
        <div className="d-inline-flex">
          <svg
            style={{
              height: 18,
              width: 18,
              marginRight: "0.1rem",
              display: "inline-block",
            }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <title>instagram</title>
            <path
              fill="var(--dark)"
              d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"
            />
          </svg>
        </div>
      );
    }

    if (content?.platforms.includes("Facebook")) {
      out.push(
        <div className="d-inline-flex">
          <svg
            style={{
              height: 18,
              width: 18,
              marginRight: "0.1rem",
              display: "inline-block",
            }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <title>facebook</title>
            <path
              fill="var(--dark)"
              d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z"
            />
          </svg>
        </div>
      );
    }

    return (
      <>
        {out.map((item, i) => (
          <>{item}</>
        ))}
      </>
    );
  }

  row(c) {
    return (
      <Row className="align-items-center">
        <Col xs="12" sm="9">
          <h3 className="mb-0">{c.name}</h3>
          <p className="mb-0 text-dark" style={{ fontSize: "13px" }}>
            <div
              className="d-inline pr-1"
              style={{ position: "relative", top: 5, lineHeight: 1 }}
            >
              {this.renderPlatforms(c)}
            </div>
            {c.postTypes?.join(", ")}
          </p>
          <p className="m-0 text-dark" style={{ fontSize: "13px" }}>
            {c?.tags?.map((tag, i) => (
              <Badge
                color="medium"
                className="border border-light mr-1 mt-2 p-1"
                style={{ fontSize: 10 }}
                key={i}
              >
                {tag}
              </Badge>
            ))}
          </p>
        </Col>
        <Col xs="12" sm="3">
          <p className="mt-2 mt-sm-0 mb-0 text-dark" style={{ fontSize: 14 }}>
            <span className="d-inline d-sm-none">Published: </span>
            {moment(c?.scheduledFor).format("MM/DD/YYYY")}
          </p>
        </Col>
      </Row>
    );
  }

  switchViewMode(viewMode) {
    this.setState({
      viewMode,
    });
  }

  formatStatus(status) {
    if (!status) {
      return "--";
    }

    if (status == "Not Started") {
      return (
        <Badge
          color=""
          style={{ fontSize: "0.75rem" }}
          className="bg-yellow text-dark"
        >
          {status}
        </Badge>
      );
    }

    if (status == "In Progress") {
      return (
        <Badge style={{ fontSize: "0.75rem" }} color="info">
          {status}
        </Badge>
      );
    }

    if (status == "Completed") {
      return (
        <Badge style={{ fontSize: "0.75rem" }} color="light">
          {status}
        </Badge>
      );
    }
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeaderV2
            sticky={true}
            title={`Social Media Content`}
            showBackButton={false}
            forceSecondaryDropdown={true}
            secondaryActions={
              [
                /*{
                name: <>View As Table</>,
                onClick: () => {
                  this.setState({
                    viewMode: "table",
                  });
                },
                disabled: this.state.viewMode == "table",
              },
              {
                name: <>View As Board</>,
                onClick: () => {
                  this.setState({
                    viewMode: "board",
                  });
                },
                disabled: this.state.viewMode == "board",
              },*/
              ]
            }
            tabs={
              <CardSelectorHeader
                compact={true}
                value={this.state.active}
                options={[
                  {
                    name: "All Content",
                    id: "All Content",
                  },
                  {
                    name: "Stories & Reels",
                    id: "Stories & Reels",
                  },
                  {
                    name: "Posts",
                    id: "Posts",
                  },
                ]}
                onChange={this.handleTabChange.bind(this)}
              ></CardSelectorHeader>
            }
          />
          <div
            className="pb-3 bg-white border-bottom"
            style={{
              marginLeft: -15,
              marginRight: -15,
              marginTop: "-1.5rem",
              paddingLeft: 15,
              paddingRight: 15,
              paddingTop: "1rem",
            }}
          >
            {/**<Row className="align-items-center">
                  <Col xs="" sm="" className="">
                    <Input
                      type="text"
                      placeholder="Search tasks..."
                      size="sm"
                      name="search"
                      value={this.state.search}
                      onChange={this.handleSearchChange.bind(this)}
                    ></Input>
                  </Col>
                </Row> */}
            <Row className="align-items-center mt-3">
              {/*<Col xs="auto" sm="auto" className="pr-0">
                      <Checkbox
                        indeterminate={true}
                        checked={true}
                        onChange={() => {}}
                      ></Checkbox>
                </Col>*/}
              <Col xs="" sm="" className="">
                {this.state.searchResults ? (
                  <>
                    Found{" "}
                    {this.state.searchResultContent?.length
                      ? StringUtils.numberFormat(
                          this.state.searchResultContent?.length
                        )
                      : "0"}{" "}
                    task
                    {this.state.searchResultContent?.length == 1
                      ? ""
                      : "s"}{" "}
                    matching your search.
                  </>
                ) : (
                  <div>
                    {this.state.result?.start && this.state.result?.end ? (
                      <>
                        Showing{" "}
                        {StringUtils.numberFormat(this.state.result?.start)}-
                        {StringUtils.numberFormat(this.state.result?.end)}
                        {this.state.result?.total ? (
                          <>
                            &nbsp;of{" "}
                            {StringUtils.numberFormat(this.state.result?.total)}{" "}
                            content items.
                          </>
                        ) : null}
                      </>
                    ) : (
                      <>---</>
                    )}
                  </div>
                )}
              </Col>
              <Col xs="auto" sm="auto" className="pl-2 pl-sm-3 text-right">
                <Button
                  style={{ height: "28px" }}
                  size="sm"
                  color="secondary"
                  className="btn-icon-only"
                  outline
                  disabled={
                    this.state.page == 1 ||
                    !this.state.pagination?.hasPrevious ||
                    this.state.searchResults
                  }
                  onClick={() => {
                    this.incrementPage(-1);
                  }}
                >
                  <i
                    className="mdi mdi-chevron-left"
                    style={{ fontSize: "18px" }}
                  ></i>
                </Button>
                <Button
                  style={{ height: "28px" }}
                  size="sm"
                  color="secondary"
                  disabled={
                    !this.state.pagination?.hasNext || this.state.searchResults
                  }
                  className="btn-icon-only"
                  outline
                  onClick={() => {
                    this.incrementPage(1);
                  }}
                >
                  <i
                    className="mdi mdi-chevron-right"
                    style={{ fontSize: "18px" }}
                  ></i>
                </Button>
              </Col>
            </Row>
          </div>
          <div
            className="bg-white"
            style={{
              marginLeft: -15,
              marginRight: -15,
            }}
          >
            <div className="py-2 px-3 bg-superlight border-bottom text-dark">
              <Row className="align-items-center">
                <Col xs="6" sm="9">
                  <h4 className="mb-0">Content</h4>
                </Col>

                <Col xs="12" sm="3" className="d-none d-sm-block">
                  <h4 className="mb-0">Published</h4>
                </Col>
              </Row>
            </div>

            <div>
              {this.state.loadingContent || this.state.searchLoading ? (
                <>
                  <div
                    className="text-center p-3"
                    style={{
                      zIndex: 1,
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                      background: "rgba(255, 255, 255, 0.6)",
                    }}
                  >
                    <Spinner
                      style={{ position: "relative", top: "15vh" }}
                      color="medium"
                      size=""
                    ></Spinner>
                  </div>
                </>
              ) : null}
              {this.state.searchResults ? (
                <div>
                  {!this.state.searchResultContent?.length &&
                  !this.state.searchLoading ? (
                    <div className="px-3 py-6 border-bottom">
                      <Row>
                        <Col>
                          No tasks found matching your search "
                          {this.state.search}"
                        </Col>
                      </Row>
                    </div>
                  ) : null}

                  {this.state.searchResultContent?.map((c, i) => (
                    <div
                      className={`px-3 py-2 bg-superlight--hover cursor-pointer border-bottom`}
                      key={i}
                      onClick={() => {
                        this.setState({
                          detailContent: c,
                          detailOpen: true,
                        });
                      }}
                    >
                      {this.row(c)}
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  {!this.state.content?.length && !this.state.loadingContent ? (
                    <div className="px-3 py-6 border-bottom">
                      <Row>
                        <Col>No content available.</Col>
                      </Row>
                    </div>
                  ) : null}

                  {this.state.content?.map((c, i) => (
                    <div
                      className={`px-3 py-2 bg-superlight--hover cursor-pointer border-bottom`}
                      key={i}
                      onClick={() => {
                        this.setState({
                          detailContent: c,
                          detailOpen: true,
                        });
                      }}
                    >
                      {this.row(c)}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </Container>
        <ContentCalendarDetailDrawer
          content={this.state.detailContent}
          open={this.state.detailOpen}
          onClose={() => {
            this.setState({
              detailContent: null,
              detailOpen: false,
            });
          }}
        ></ContentCalendarDetailDrawer>
      </>
    );
  }
  f;
}

export default SocialContentListView;
