import ArrowText from "components/ArrowText";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  Badge,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import Card from "reactstrap/lib/Card";
import moment from "moment";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import _ from "underscore";
import TaskDetailDrawer from "components/Drawers/TaskDetailDrawer";
import TaskCreateDrawer from "components/Drawers/TaskCreateDrawer";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";

class AutomatedEmailWidget extends React.Component {
  state = {
    expand: false,
    taskDetail: null,
    count: 200,
  };

  componentDidMount() {
    this.handleTabChange();
  }

  loadAutomatedEmails(page = 1, count = 2000, filters = null, orderBy) {
    this.setState({
      loadingAutomatedEmails: true,
    });

    console.log(filters);

    APIV2.getAutomatedEmails(
      this.props.match?.params?.storeID,
      page,
      count,
      filters,
      orderBy
    )
      .then(
        (data) => {
          if (data && data.data) {
            let emails = [];

            if (data.data.emails.length) {
              emails = data.data.emails;
            }

            this.setState({
              emails,
              pagination: data.data.pagination,
              result: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the automated emails. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingAutomatedEmails: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.lead != this.props?.lead ||
      prevProps?.customer != this.props?.customer ||
      prevProps?.storeID != this.props?.storeID
    ) {
      this.handleTabChange();
    }
  }

  handleTabChange() {
    let filters = {};

    if (this.props.lead) {
      filters = {
        leadID: this.props.lead?._id,
      };
    } else if (this.props.customer) {
      filters = {
        customerID: this.props.customer?._id,
      };
    }

    let orderBy = {
      createdAt: -1,
    };

    this.setState({
      filters,
      orderBy,
    });

    this.loadAutomatedEmails(1, this.state.count, filters, orderBy);
  }

  formatDate(dateString) {
    if (!dateString) {
      return "--";
    }

    let now = moment();
    let due = moment(dateString);

    return due.format("MMM DD, YYYY");
  }

  translateStatus(c) {
    if (c == "open") {
      return "Opened";
    }

    if (c == "click") {
      return "Opened & Engaged";
    }

    return c;
  }

  formatStatus(c) {
    if (!c) {
      return "unknown";
    }

    if (c?.allEvents?.length) {
      if (c?.allEvents[c?.allEvents?.length - 1]?.event) {
        return this.translateStatus(
          c?.allEvents[c?.allEvents?.length - 1]?.event
        );
      }
    }

    if (c?.lastStatus) {
      return this.translateStatus(c?.lastStatus);
    }

    if (c?.sendgridMessage?.status) {
      return this.translateStatus(c?.sendgridMessage?.status);
    }

    return "unknown";
  }

  formatCategories(c) {
    if (c?.type) {
      return c?.type;
    }

    if (!c?.categories?.length) {
      return "Transactional";
    }

    return c?.categories?.join("& ");
  }

  emailRow(c) {
    return (
      <Row className="align-items-center">
        <Col xs="12" sm="6" md="6">
          <h4 className="mb-0">{c?.subject ?? "(Unknown Subject)"}</h4>
          <p className="mb-0 small text-capitalize">
            Sent to <span className="text-lowercase">{c.email}</span>
          </p>
        </Col>
        <Col xs="6" sm="6" md="3" className="text-capitalize">
          <p className="m-0">{this.formatStatus(c)}</p>
        </Col>
        <Col xs="6" sm="6" md="2">
          {this.formatDate(c.createdAt)}
        </Col>
        <Col xs="6" sm="6" md="3">
          {c.priority == "High" ? (
            <i className="mdi mdi-exclamation-thick text-danger pr-2"></i>
          ) : null}
          {c.priority}
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <>
        {this.props.loading ? (
          <>
            <Card className="mb-4 shadow border">
              <CardBody className="bg-transparent py-5 text-center">
                <Spinner size="lg" color="medium"></Spinner>
              </CardBody>
            </Card>
          </>
        ) : (
          <>
            <Card className="mb-4 border shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <Col>
                    <h3 className="text-dark mb-0">Automated Emails</h3>
                    <p className="m-0 small">
                      View automated marketing &amp; transactional emails this
                      {this.props.lead ? " lead" : " member"} has received.
                    </p>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                {this.state?.loading && (
                  <div className="px-3 py-5 text-center">
                    <Spinner size="sm" color="dark"></Spinner>
                  </div>
                )}
                {!this.state?.emails?.length && !this.state.loading && (
                  <div className="px-3 py-5">
                    <Row>
                      <Col xs="12">
                        <h3 className="m-0">No email history available.</h3>
                        <p>
                          {this.props.lead ? "This Lead" : "This Member"} has
                          not received any automated emails yet.
                        </p>
                      </Col>
                    </Row>
                  </div>
                )}

                {this.state?.emails?.length > 0 && (
                  <div className="px-4 py-2 bg-superlight border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12" sm="6" md="6">
                        <h4 className="mb-0">Email</h4>
                      </Col>

                      <Col xs="6" sm="6" md="3">
                        <h4 className="mb-0">Status</h4>
                      </Col>
                      <Col xs="6" sm="6" md="3">
                        <h4 className="mb-0">Processed On</h4>
                      </Col>
                    </Row>
                  </div>
                )}
                {this.state?.emails?.map((email, i) => (
                  <div key={email?._id}>
                    <div className="px-4 py-3 cursor-pointer bg-superlight--hover">
                      {this.emailRow(email)}
                    </div>
                    {i != this.state?.emails?.length - 1 && (
                      <hr className="m-0"></hr>
                    )}
                  </div>
                ))}
              </CardBody>
            </Card>
          </>
        )}
      </>
    );
  }
}

export default withRouter(AutomatedEmailWidget);
