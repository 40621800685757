import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
  Spinner,
  Alert,
  Collapse,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import NumberFormat from "react-number-format";
import APIV2 from "lib/APIV2";
import { Drawer, SelectPicker } from "rsuite";
import StringUtils from "lib/StringUtils";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import LinkUtils from "lib/LinkUtils";
import Constant from "../../lib/Constant";

class FranDevLeadStageDrawer extends React.Component {
  state = {
    name: "",
    submitting: false,
    stage: "Not Contacted",
    stages: Constant.SELECT_PICKER.FRANDEV_LEAD_STAGES,
  };

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
      name: "",
      stage: "Not Contacted",
      contacts: [],

      businesses: [],
      markets: [],
    });

    if (typeof this.props.onClose == "function") {
      this.props.onClose();
    }
  };

  submit() {
    this.setState({
      submitting: true,
    });

    let contacts = this.state.contacts?.length
      ? this.state.contacts?.map((contact) => {
          delete contact?._id;
          delete contact?.expanded;

          contact.phone = contact?.phone?.value
            ? contact?.phone?.value
            : contact?.phone;

          return contact;
        })
      : [];

    let businesses = this.state.businesses?.length
      ? this.state.businesses?.map((business) => {
          delete business?._id;
          delete business?.expanded;

          business.phone = business?.phone?.value
            ? business?.phone?.value
            : business?.phone;

          return business;
        })
      : [];

    let markets = this.state.markets?.length
      ? this.state.markets?.map((market) => {
          delete market?._id;
          delete market?.expanded;

          return market;
        })
      : [];

    APIV2.bulkModifyFranDevLeads(this.props.leads, {
      stage: this.state.stage,
    })
      .then(
        (data) => {
          if (data?.data?.leads) {
            if (typeof this.props.onSave == "function") {
              this.props.onSave(data.data.leads);
            }

            if (typeof this.props.onClose == "function") {
              this.props.onClose();
            }

            this.setState({
              name: "",
              stage: "Not Contacted",
              contacts: [],
              markets: [],
              businesses: [],
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "We were unable to modify the lead stages. Please try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  componentDidMount() {}

  isValidForm() {
    if (!this.state.stage) {
      return false;
    }

    return true;
  }

  render() {
    return (
      <>
        <Drawer
          backdrop="static"
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Modify Stage For
              {this.props.leads?.length} Leads
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={this.state.submitting || !this.isValidForm()}
                color="primary"
                onClick={this.submit.bind(this)}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  "Save"
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}

            <FormGroup>
              <h5>
                Stage<span className="text-danger">*</span>
              </h5>
              <SelectPicker
                placeholder="Select A Stage"
                data={this.state.stages}
                value={this.state.stage}
                valueKey="label"
                onChange={(v) => {
                  this.setState({
                    stage: v,
                  });
                }}
                block
                placement="auto"
                preventOverflow
              ></SelectPicker>
            </FormGroup>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default FranDevLeadStageDrawer;
