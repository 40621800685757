import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker, Tooltip, Whisper } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import StorefrontCreateStaffMemberDrawer from "components/Drawers/StorefrontCreateStaffMemberDrawer";
import StorefrontModifyStaffMemberDrawer from "components/Drawers/StorefrontModifyStaffMemberDrawer";
import swal from "sweetalert";
import NumberFormat from "react-number-format";

class ProductionPricingView extends React.Component {
  state = {
    active: {
      id: "All",
      name: "All",
    },
    phone: {
      value: "",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 25,
    filters: null,
    pricing: {
      lifestyleMealUnitPrice: {
        value: "",
      },
      athleteMealUnitPrice: {
        value: "",
      },
      leanCheatUnitPrice: {
        value: "",
      },
      lifestyleMealCaseSize: {
        value: "",
      },
      athleteMealCaseSize: {
        value: "",
      },
      leanCheatCaseSize: {
        value: "",
      },
      boxSmallUnitPrice: {
        value: "",
      },
      boxMediumUnitPrice: {
        value: "",
      },
      boxLargeUnitPrice: {
        value: "",
      },
      boxXLUnitPrice: {
        value: "",
      },
      boxMealCaseUnitPrice: {
        value: "",
      },
      boxLeanCheatUnitPrice: {
        value: "",
      },
      boxInsertUnitPrice: {
        value: "",
      },
      boxBundleSize: {
        value: "",
      },
      laborUnitPrice: {
        value: "",
      },
      laborUnitPrice: {
        value: "",
      },
      logisticsPalletCaseQuantity: {
        value: "",
      },
      logisticsFreightStandardPrice: {
        value: "",
      },
      logisticsFreightCustomPricing: {},
      logisticsCustomPalletCaseQuantity: {},

      leanCheatOrderMultiple: {
        value: "",
      },
    },
    pricingBase: {
      lifestyleMealUnitPrice: {
        value: "",
      },
      athleteMealUnitPrice: {
        value: "",
      },
      leanCheatUnitPrice: {
        value: "",
      },
      lifestyleMealCaseSize: {
        value: "",
      },
      athleteMealCaseSize: {
        value: "",
      },
      leanCheatCaseSize: {
        value: "",
      },
      boxSmallUnitPrice: {
        value: "",
      },
      boxMediumUnitPrice: {
        value: "",
      },
      boxLargeUnitPrice: {
        value: "",
      },
      boxMealCaseUnitPrice: {
        value: "",
      },
      boxLeanCheatUnitPrice: {
        value: "",
      },
      boxInsertUnitPrice: {
        value: "",
      },
      boxBundleSize: {
        value: "",
      },
      laborUnitPrice: {
        value: "",
      },
      laborUnitPrice: {
        value: "",
      },
      logisticsPalletCaseQuantity: {
        value: "",
      },
      logisticsFreightStandardPrice: {
        value: "",
      },
      logisticsFreightCustomPricing: {},
      logisticsCustomPalletCaseQuantity: {},
      leanCheatOrderMultiple: {
        value: "",
      },
    },
  };

  loadStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStores()
      .then(
        (data) => {
          this.setState({
            stores: data.data.stores,
            storeOptions: data.data.stores?.map((st) => {
              return {
                value: st?._id,
                label: st.name,
              };
            }),
          });
        },
        (e) => {
          alert("Unable to fetch stores. Reload and try again.");
        }
      )
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  loadLatestPricing() {
    this.setState({
      loadingPricing: true,
    });

    APIV2.getLatestProductionPricing()
      .then(
        (data) => {
          this._loadPricing(data.data.pricing);
        },
        (e) => {}
      )
      .finally(() => {
        this.setState({
          loadingPricing: false,
        });
      });
  }

  savePricing() {
    this.setState({
      submitting: true,
    });

    let payload = this._pricingToPayload(this.state.pricing);

    APIV2.createProductionPricing(payload)
      .then(
        (data) => {
          this.setState({
            modified: false,
          });

          this._loadPricing(data.data.pricing);
        },
        (e) => {
          alert("Unable to update pricing. Reload and try again.");
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  _loadPricing(pricing) {
    let keys = _.keys(pricing);

    let out = {};

    for (let i = 0; i < keys?.length; i++) {
      const key = keys[i];

      if (key == "logisticsFreightCustomPricing") {
        if (pricing[key]) {
          const customKeys = _.keys(pricing[key]);

          for (let j = 0; j < customKeys?.length; j++) {
            const storeID = customKeys[j];

            if (!out[key]) {
              out[key] = {};
            }

            out[key][storeID] = {
              value: (pricing[key][storeID] / 100).toString(),
            };
          }
        }
      } else if (key == "logisticsCustomPalletCaseQuantity") {
        if (pricing[key]) {
          const customKeys = _.keys(pricing[key]);

          for (let j = 0; j < customKeys?.length; j++) {
            const storeID = customKeys[j];

            if (!out[key]) {
              out[key] = {};
            }

            out[key][storeID] = {
              value: (pricing[key][storeID] / 100).toString(),
            };
          }
        }
      } else if (key.includes("Price")) {
        out[key] = {
          value: (pricing[key] / 100).toString(),
        };
      } else {
        out[key] = {
          value: pricing[key].toString(),
        };
      }
    }

    this.setState(
      {
        pricing: out,
        originalPricing: JSON.parse(JSON.stringify(out)),
      },
      () => {
        this.setState({
          modified: false,
        });
      }
    );
  }

  _pricingToPayload(pricing) {
    let keys = _.keys(pricing);

    let out = {};

    for (let i = 0; i < keys?.length; i++) {
      const key = keys[i];

      if (
        key == "logisticsFreightCustomPricing" ||
        key == "logisticsCustomPalletCaseQuantity"
      ) {
        if (pricing[key]) {
          const customKeys = _.keys(pricing[key]);

          for (let j = 0; j < customKeys?.length; j++) {
            const storeID = customKeys[j];

            if (!out[key]) {
              out[key] = {};
            }

            out[key][storeID] = parseFloat(pricing[key][storeID].value) * 100;
          }
        }
      } else if (key.includes("Price")) {
        out[key] = parseFloat(pricing[key].value) * 100;
      } else {
        out[key] = parseInt(pricing[key].value);
      }
    }

    return out;
  }

  _isValidPricing(pricing) {
    let keys = _.keys(pricing);

    let out = {};

    for (let i = 0; i < keys?.length; i++) {
      const key = keys[i];

      if (
        key == "logisticsFreightCustomPricing" ||
        key == "logisticsCustomPalletCaseQuantity"
      ) {
        console.log(pricing[key]);

        if (pricing[key]) {
          const customKeys = _.keys(pricing[key]);

          for (let j = 0; j < customKeys?.length; j++) {
            const storeID = customKeys[j];

            if (
              pricing[key][storeID].value === "" ||
              isNaN(parseFloat(pricing[key][storeID].value)) ||
              parseFloat(pricing[key][storeID].value) < 0
            ) {
              return false;
            }
          }
        }
      } else if (key.includes("Price")) {
        if (
          pricing[key].value === "" ||
          pricing[key].value === null ||
          isNaN(parseFloat(pricing[key].value)) ||
          parseFloat(pricing[key].value) < 0
        ) {
          return false;
        }
      } else {
        if (
          pricing[key].value === "" ||
          pricing[key].value === null ||
          isNaN(parseFloat(pricing[key].value)) ||
          parseFloat(pricing[key].value) < 0
        ) {
          return false;
        }
      }
    }

    return true;
  }

  updateValue(key, value) {
    let pricing = this.state.pricing;

    let originalValue = pricing[key];

    if (typeof value == "object") {
      pricing[key] = value;
    } else {
      pricing[key] = {
        value,
      };
    }

    this.setState({
      pricing,
    });

    if (originalValue != value) {
      this.setState({
        modified: true,
      });
    }
  }

  updateStoreValue(key, storeID, value) {
    let pricing = this.state.pricing;

    if (typeof value == "object") {
      pricing[key][storeID] = value;
    } else {
      pricing[key][storeID] = {
        value,
      };
    }

    this.setState({
      pricing,
      modified: true,
    });
  }

  removeStoreValue(key, storeID) {
    let pricing = this.state.pricing;

    if (
      typeof pricing == "object" &&
      pricing.hasOwnProperty(key) &&
      typeof pricing[key] == "object" &&
      pricing[key].hasOwnProperty(storeID)
    ) {
      delete pricing[key][storeID];
    }

    this.setState({
      pricing,
      modified: true,
    });
  }

  addCustomRateForStore(id) {
    let pricing = this.state.pricing;

    if (pricing.logisticsFreightCustomPricing.hasOwnProperty(id)) {
      this.setState({
        selectedStore: null,
      });

      return;
    }

    pricing.logisticsFreightCustomPricing[id] = {
      value: "",
    };

    this.setState({
      pricing,
      selectedStore: null,
      modified: true,
    });
  }

  addCustomPalletQuantityForStore(id) {
    let pricing = this.state.pricing;

    console.log("HERE");

    if (pricing?.logisticsCustomPalletCaseQuantity?.hasOwnProperty(id)) {
      this.setState({
        selectedStorePallet: null,
      });

      return;
    }

    if (!pricing.logisticsCustomPalletCaseQuantity) {
      pricing.logisticsCustomPalletCaseQuantity = {};
    }

    pricing.logisticsCustomPalletCaseQuantity[id] = {
      value: "",
    };

    this.setState({
      pricing,
      selectedStorePallet: null,
      modified: true,
    });
  }

  componentDidUpdate(prevProps) {}

  componentDidMount() {
    this.loadStores();
    this.loadLatestPricing();
  }

  getStoreByID(id) {
    if (!this.state.stores?.length) {
      return null;
    }

    let st = _.findWhere(this.state.stores, { _id: id });

    return st ? st : null;
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader
            title="Pricing & Quantities"
            showBackButton={false}
            secondaryAction={
              this.state.modified
                ? {
                    name: <>Cancel</>,
                    onClick: () => {
                      this.setState({
                        pricing: JSON.parse(
                          JSON.stringify(
                            this.state.originalPricing
                              ? this.state.originalPricing
                              : this.state.pricingBase
                          )
                        ),
                        modified: false,
                      });
                    },
                  }
                : null
            }
            primaryAction={
              this.state.modified
                ? {
                    disabled:
                      !this._isValidPricing(this.state.pricing) ||
                      this.state.submitting,
                    name: (
                      <>
                        {this.state.submitting ? (
                          <>
                            <Spinner size="sm" />
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </>
                    ),
                    onClick: () => {
                      this.savePricing();
                    },
                  }
                : null
            }
          />

          <Row>
            <Col xs="12" className="mb-4">
              <Card className="border shadow" style={{ height: "100%" }}>
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    <Col xs="">
                      <h3 className="mb-0">Meals</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="p-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h4 className="mb-0 font-weight-bold text-dark">
                          Lifestyle Meals
                        </h4>
                      </Col>
                      <Col xs="12" sm="6" className="mt-3">
                        <h4 className="mb-1 small font-weight-bold text-dark">
                          Unit Price (Per Meal)
                        </h4>
                        <NumberFormat
                          className="form-control form-control-sm"
                          fixedDecimalScale={true}
                          decimalScale={2}
                          required={true}
                          placeholder="$0.00"
                          value={
                            this.state.pricing?.lifestyleMealUnitPrice?.value
                          }
                          onValueChange={(v) => {
                            this.updateValue("lifestyleMealUnitPrice", v);
                          }}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </Col>
                      <Col xs="12" sm="6" className="mt-3">
                        <h4 className="mb-1 small font-weight-bold text-dark">
                          Logistics Case Size
                        </h4>
                        <Input
                          type="number"
                          bsSize="sm"
                          placeholder="Logistics Case Size"
                          value={
                            this.state.pricing?.lifestyleMealCaseSize?.value
                          }
                          onChange={(e) => {
                            this.updateValue(
                              "lifestyleMealCaseSize",
                              e?.target?.value
                            );
                          }}
                        ></Input>
                      </Col>
                    </Row>
                  </div>
                  <div className="p-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h4 className="mb-0 font-weight-bold text-dark">
                          Athlete Meals
                        </h4>
                      </Col>
                      <Col xs="12" sm="6" className="mt-3">
                        <h4 className="mb-1 small font-weight-bold text-dark">
                          Unit Price (Per Meal)
                        </h4>
                        <NumberFormat
                          className="form-control form-control-sm"
                          fixedDecimalScale={true}
                          decimalScale={2}
                          required={true}
                          placeholder="$0.00"
                          value={
                            this.state.pricing?.athleteMealUnitPrice?.value
                          }
                          onValueChange={(v) => {
                            this.updateValue("athleteMealUnitPrice", v);
                          }}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </Col>
                      <Col xs="12" sm="6" className="mt-3">
                        <h4 className="mb-1 small font-weight-bold text-dark">
                          Logistics Case Size
                        </h4>
                        <Input
                          type="number"
                          bsSize="sm"
                          placeholder="Logistics Case Size"
                          value={this.state.pricing?.athleteMealCaseSize?.value}
                          onChange={(e) => {
                            this.updateValue(
                              "athleteMealCaseSize",
                              e?.target?.value
                            );
                          }}
                        ></Input>
                      </Col>
                    </Row>
                  </div>
                  <div className="p-3">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h4 className="mb-0 font-weight-bold text-dark">
                          Lean Cheats
                        </h4>
                      </Col>
                      <Col xs="12" sm="6" className="mt-3">
                        <h4 className="mb-1 small font-weight-bold text-dark">
                          Unit Price (Per Meal)
                        </h4>
                        <NumberFormat
                          className="form-control form-control-sm"
                          fixedDecimalScale={true}
                          decimalScale={2}
                          required={true}
                          placeholder="$0.00"
                          value={this.state.pricing?.leanCheatUnitPrice?.value}
                          onValueChange={(v) => {
                            this.updateValue("leanCheatUnitPrice", v);
                          }}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </Col>
                      <Col xs="12" sm="6" className="mt-3">
                        <h4 className="mb-1 small font-weight-bold text-dark">
                          Logistics Case Size
                        </h4>
                        <Input
                          type="number"
                          bsSize="sm"
                          placeholder="Logistics Case Size"
                          value={this.state.pricing?.leanCheatCaseSize?.value}
                          onChange={(e) => {
                            this.updateValue(
                              "leanCheatCaseSize",
                              e?.target?.value
                            );
                          }}
                        ></Input>
                      </Col>
                      <Col xs="12" sm="6" className="mt-3">
                        <h4 className="mb-1 small font-weight-bold text-dark">
                          Order Multiples Of
                        </h4>
                        <Input
                          type="number"
                          bsSize="sm"
                          placeholder="Multiples Of"
                          value={
                            this.state.pricing?.leanCheatOrderMultiple?.value
                          }
                          onChange={(e) => {
                            this.updateValue(
                              "leanCheatOrderMultiple",
                              e?.target?.value
                            );
                          }}
                        ></Input>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" md="12" className="mb-4">
              <Card className="border shadow" style={{ height: "100%" }}>
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    <Col xs="">
                      <h3 className="mb-0">Corrugated Boxes</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="p-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h4 className="mb-0 font-weight-bold text-dark">
                          Bundle Size
                        </h4>
                      </Col>
                      <Col xs="12" sm="6" className="mt-3">
                        <h4 className="mb-1 small font-weight-bold text-dark">
                          Quantity (Per Bundle)
                        </h4>
                        <Input
                          type="number"
                          bsSize="sm"
                          placeholder="Bundle Size"
                          value={this.state.pricing?.boxBundleSize?.value}
                          onChange={(e) => {
                            this.updateValue("boxBundleSize", e?.target?.value);
                          }}
                        ></Input>
                      </Col>
                    </Row>
                  </div>
                  <div className="p-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h4 className="mb-0 font-weight-bold text-dark">
                          Small Boxes
                        </h4>
                      </Col>
                      <Col xs="12" sm="6" className="mt-3">
                        <h4 className="mb-1 small font-weight-bold text-dark">
                          Unit Price (Per Box)
                        </h4>
                        <NumberFormat
                          className="form-control form-control-sm"
                          fixedDecimalScale={true}
                          decimalScale={2}
                          required={true}
                          placeholder="$0.00"
                          value={this.state.pricing?.boxSmallUnitPrice?.value}
                          onValueChange={(v) => {
                            this.updateValue("boxSmallUnitPrice", v);
                          }}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="p-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h4 className="mb-0 font-weight-bold text-dark">
                          Medium Boxes
                        </h4>
                      </Col>
                      <Col xs="12" sm="6" className="mt-3">
                        <h4 className="mb-1 small font-weight-bold text-dark">
                          Unit Price (Per Box)
                        </h4>
                        <NumberFormat
                          className="form-control form-control-sm"
                          fixedDecimalScale={true}
                          decimalScale={2}
                          required={true}
                          placeholder="$0.00"
                          value={this.state.pricing?.boxMediumUnitPrice?.value}
                          onValueChange={(v) => {
                            this.updateValue("boxMediumUnitPrice", v);
                          }}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="p-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h4 className="mb-0 font-weight-bold text-dark">
                          Large Boxes (24 Meals)
                        </h4>
                      </Col>
                      <Col xs="12" sm="6" className="mt-3">
                        <h4 className="mb-1 small font-weight-bold text-dark">
                          Unit Price (Per Box)
                        </h4>
                        <NumberFormat
                          className="form-control form-control-sm"
                          fixedDecimalScale={true}
                          decimalScale={2}
                          required={true}
                          placeholder="$0.00"
                          value={this.state.pricing?.boxLargeUnitPrice?.value}
                          onValueChange={(v) => {
                            this.updateValue("boxLargeUnitPrice", v);
                          }}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="p-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h4 className="mb-0 font-weight-bold text-dark">
                          XL Boxes (30 Meals)
                        </h4>
                      </Col>
                      <Col xs="12" sm="6" className="mt-3">
                        <h4 className="mb-1 small font-weight-bold text-dark">
                          Unit Price (Per Box)
                        </h4>
                        <NumberFormat
                          className="form-control form-control-sm"
                          fixedDecimalScale={true}
                          decimalScale={2}
                          required={true}
                          placeholder="$0.00"
                          value={this.state.pricing?.boxXLUnitPrice?.value}
                          onValueChange={(v) => {
                            this.updateValue("boxXLUnitPrice", v);
                          }}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="p-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h4 className="mb-0 font-weight-bold text-dark">
                          Meal Case Boxes
                        </h4>
                      </Col>
                      <Col xs="12" sm="6" className="mt-3">
                        <h4 className="mb-1 small font-weight-bold text-dark">
                          Unit Price (Per Box)
                        </h4>
                        <NumberFormat
                          className="form-control form-control-sm"
                          fixedDecimalScale={true}
                          decimalScale={2}
                          required={true}
                          placeholder="$0.00"
                          value={
                            this.state.pricing?.boxMealCaseUnitPrice?.value
                          }
                          onValueChange={(v) => {
                            this.updateValue("boxMealCaseUnitPrice", v);
                          }}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="p-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h4 className="mb-0 font-weight-bold text-dark">
                          Lean Cheat Boxes
                        </h4>
                      </Col>
                      <Col xs="12" sm="6" className="mt-3">
                        <h4 className="mb-1 small font-weight-bold text-dark">
                          Unit Price (Per Box)
                        </h4>
                        <NumberFormat
                          className="form-control form-control-sm"
                          fixedDecimalScale={true}
                          decimalScale={2}
                          required={true}
                          placeholder="$0.00"
                          value={
                            this.state.pricing?.boxLeanCheatUnitPrice?.value
                          }
                          onValueChange={(v) => {
                            this.updateValue("boxLeanCheatUnitPrice", v);
                          }}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="p-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h4 className="mb-0 font-weight-bold text-dark">
                          Box Inserts
                        </h4>
                      </Col>
                      <Col xs="12" sm="6" className="mt-3">
                        <h4 className="mb-1 small font-weight-bold text-dark">
                          Unit Price (Per Insert)
                        </h4>
                        <NumberFormat
                          className="form-control form-control-sm"
                          fixedDecimalScale={true}
                          decimalScale={2}
                          required={true}
                          placeholder="$0.00"
                          value={this.state.pricing?.boxInsertUnitPrice?.value}
                          onValueChange={(v) => {
                            this.updateValue("boxInsertUnitPrice", v);
                          }}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" md="12" className="mb-4">
              <Card className="border shadow" style={{ height: "100%" }}>
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    <Col xs="">
                      <h3 className="mb-0">Labor</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="p-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h4 className="mb-0 font-weight-bold text-dark">
                          Pick &amp; Pack Fee
                        </h4>
                      </Col>
                      <Col xs="12" sm="6" className="mt-3">
                        <h4 className="mb-1 small font-weight-bold text-dark">
                          Fee (Per Member Box)
                        </h4>
                        <NumberFormat
                          className="form-control form-control-sm"
                          fixedDecimalScale={true}
                          decimalScale={2}
                          required={true}
                          placeholder="$0.00"
                          value={this.state.pricing?.laborUnitPrice?.value}
                          onValueChange={(v) => {
                            this.updateValue("laborUnitPrice", v);
                          }}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" md="12" className="mb-4">
              <Card className="border shadow mb-4">
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    <Col xs="">
                      <h3 className="mb-0">Logistics</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="p-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h4 className="mb-0 font-weight-bold text-dark">
                          Standard Pallet Case Quantity
                        </h4>
                      </Col>
                      <Col xs="12" sm="6" className="mt-3">
                        <h4 className="mb-1 small font-weight-bold text-dark">
                          Cases (Per Pallet)
                        </h4>
                        <Input
                          type="number"
                          bsSize="sm"
                          placeholder="Case Quantity"
                          value={
                            this.state.pricing?.logisticsPalletCaseQuantity
                              ?.value
                          }
                          onChange={(e) => {
                            this.updateValue(
                              "logisticsPalletCaseQuantity",
                              e?.target?.value
                            );
                          }}
                        ></Input>
                      </Col>
                    </Row>
                  </div>
                  <div className="px-3 pt-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="" className="mb-3">
                        <h4 className="mb-0 font-weight-bold text-dark">
                          Custom Pallet Case Quantities
                        </h4>
                      </Col>
                      <Col xs="auto" className="mb-3">
                        <SelectPicker
                          loading={this.state.loadingStores}
                          onChange={(v) => {
                            this.setState({
                              selectedStorePallet: v,
                            });
                          }}
                          style={{ width: 150 }}
                          value={this.state.selectedStorePallet}
                          data={this.state.storeOptions}
                          placement="auto"
                          oneTap
                        ></SelectPicker>

                        <Button
                          size="sm"
                          color="primary"
                          className="ml-2"
                          disabled={!this.state.selectedStorePallet}
                          onClick={() => {
                            this.addCustomPalletQuantityForStore(
                              this.state.selectedStorePallet
                            );
                          }}
                        >
                          Add Store
                        </Button>
                      </Col>
                      {this.state.loadingStores ? (
                        <>
                          <Col xs="12">
                            <div className="py-3 text-center">
                              <Spinner size="sm"></Spinner>
                            </div>
                          </Col>
                        </>
                      ) : (
                        <>
                          {_.keys(
                            this.state.pricing
                              ?.logisticsCustomPalletCaseQuantity || {}
                          )?.length ? (
                            <>
                              <Col xs="12" className="border-top bg-superlight">
                                <div className="py-2">
                                  <Row className="align-items-center">
                                    <Col xs="12" sm="6">
                                      <h4 className="mb-0 small font-weight-bold text-dark">
                                        Store
                                      </h4>
                                    </Col>
                                    <Col xs="" sm="" className="mt-3 mt-sm-0">
                                      <h4 className="mb-0 small font-weight-bold text-dark">
                                        Cases (Per Pallet)
                                      </h4>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </>
                          ) : null}
                          {_.keys(
                            this.state.pricing
                              ?.logisticsCustomPalletCaseQuantity || {}
                          ).map((storeID, i) => (
                            <Col xs="12" className="border-top">
                              <div className="py-3">
                                <Row className="align-items-center">
                                  <Col xs="12" sm="6">
                                    <h4 className="text-dark m-0">
                                      {this.getStoreByID(storeID)?.name}
                                    </h4>
                                  </Col>
                                  <Col xs="" sm="" className="mt-3 mt-sm-0">
                                    <Input
                                      type="number"
                                      bsSize="sm"
                                      placeholder="Case Quantity"
                                      value={
                                        this.state.pricing
                                          ?.logisticsCustomPalletCaseQuantity[
                                          storeID
                                        ]?.value
                                      }
                                      className="d-inline-block"
                                      style={{ maxWidth: "calc(100% - 40px)" }}
                                      onChange={(e) => {
                                        this.updateStoreValue(
                                          "logisticsCustomPalletCaseQuantity",
                                          storeID,
                                          {
                                            value: e?.target?.value,
                                          }
                                        );
                                      }}
                                    ></Input>

                                    <Button
                                      size="sm"
                                      outline
                                      color="danger"
                                      className="btn-icon-only ml-2"
                                      onClick={() => {
                                        this.removeStoreValue(
                                          "logisticsCustomPalletCaseQuantity",
                                          storeID
                                        );
                                      }}
                                    >
                                      <i className="mdi mdi-close"></i>
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          ))}
                        </>
                      )}
                    </Row>
                  </div>
                </CardBody>
              </Card>
              <Card className="border shadow">
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    <Col xs="">
                      <h3 className="mb-0">Freight</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="p-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h4 className="mb-0 font-weight-bold text-dark">
                          Freight: Standard Rate
                        </h4>
                      </Col>
                      <Col xs="12" sm="6" className="mt-3">
                        <h4 className="mb-1 small font-weight-bold text-dark">
                          Rate (Per Pallet)
                        </h4>
                        <NumberFormat
                          className="form-control form-control-sm"
                          fixedDecimalScale={true}
                          decimalScale={2}
                          required={true}
                          placeholder="$0.00"
                          value={
                            this.state.pricing?.logisticsFreightStandardPrice
                              ?.value
                          }
                          onValueChange={(v) => {
                            this.updateValue(
                              "logisticsFreightStandardPrice",
                              v
                            );
                          }}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="px-3 pt-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="" className="mb-3">
                        <h4 className="mb-0 font-weight-bold text-dark">
                          Freight: Custom Rates
                        </h4>
                      </Col>
                      <Col xs="auto" className="mb-3">
                        <SelectPicker
                          loading={this.state.loadingStores}
                          onChange={(v) => {
                            this.setState({
                              selectedStore: v,
                            });
                          }}
                          style={{ width: 150 }}
                          value={this.state.selectedStore}
                          data={this.state.storeOptions}
                          placement="auto"
                          oneTap
                        ></SelectPicker>

                        <Button
                          size="sm"
                          color="primary"
                          className="ml-2"
                          disabled={!this.state.selectedStore}
                          onClick={() => {
                            this.addCustomRateForStore(
                              this.state.selectedStore
                            );
                          }}
                        >
                          Add Store
                        </Button>
                      </Col>
                      {this.state.loadingStores ? (
                        <>
                          <Col xs="12">
                            <div className="py-3 text-center">
                              <Spinner size="sm"></Spinner>
                            </div>
                          </Col>
                        </>
                      ) : (
                        <>
                          {_.keys(
                            this.state.pricing?.logisticsFreightCustomPricing ||
                              {}
                          )?.length ? (
                            <>
                              <Col xs="12" className="border-top bg-superlight">
                                <div className="py-2">
                                  <Row className="align-items-center">
                                    <Col xs="12" sm="6">
                                      <h4 className="mb-0 small font-weight-bold text-dark">
                                        Store
                                      </h4>
                                    </Col>
                                    <Col xs="" sm="" className="mt-3 mt-sm-0">
                                      <h4 className="mb-0 small font-weight-bold text-dark">
                                        Rate (Per Pallet)
                                      </h4>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </>
                          ) : null}
                          {_.keys(
                            this.state.pricing?.logisticsFreightCustomPricing ||
                              {}
                          ).map((storeID, i) => (
                            <Col xs="12" className="border-top">
                              <div className="py-3">
                                <Row className="align-items-center">
                                  <Col xs="12" sm="6">
                                    <h4 className="text-dark m-0">
                                      {this.getStoreByID(storeID)?.name}
                                    </h4>
                                  </Col>
                                  <Col xs="" sm="" className="mt-3 mt-sm-0">
                                    <NumberFormat
                                      className="form-control form-control-sm d-inline-block"
                                      fixedDecimalScale={true}
                                      decimalScale={2}
                                      required={true}
                                      placeholder="$0.00"
                                      style={{ maxWidth: "calc(100% - 40px)" }}
                                      value={
                                        this.state.pricing
                                          ?.logisticsFreightCustomPricing[
                                          storeID
                                        ]?.value
                                      }
                                      onValueChange={(v) => {
                                        this.updateStoreValue(
                                          "logisticsFreightCustomPricing",
                                          storeID,
                                          v
                                        );
                                      }}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                    />
                                    <Button
                                      size="sm"
                                      outline
                                      color="danger"
                                      className="btn-icon-only ml-2"
                                      onClick={() => {
                                        this.removeStoreValue(
                                          "logisticsFreightCustomPricing",
                                          storeID
                                        );
                                      }}
                                    >
                                      <i className="mdi mdi-close"></i>
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          ))}
                        </>
                      )}
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default ProductionPricingView;
