import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import Alert from "reactstrap/lib/Alert";
import { SelectPicker } from "rsuite";
import CustomerOpenActivitiesWidget from "./CustomerOpenActivitiesWidget";
import CustomerUnclaimedBoxWidget from "./CustomerUnclaimedBoxWidget";
import _ from "underscore";
import Constant from "lib/Constant";

class AffiliateContactInformationWidget extends React.Component {
  state = {
    expand: false,
    phone: {
      value: "",
    },
    zip: {
      value: "",
    },
    name: "",
    email: "",
  };

  updateInformation() {
    this.setState({ submitting: true });

    let payload = {
      pointOfContact: this.state.pointOfContact,
      email: this.state.email,
    };

    APIV2.modifyAffiliate(
      this.props?.affiliate?.storeID,
      this.props?.affiliate?._id,
      payload
    )
      .then(
        (data) => {
          PubSub.publish(Event.AFFILIATE.MODIFIED, data.data.affiliate);

          this.setState({ openModify: false });
        },
        (e) => {
          this.setError(
            "error",
            "Could not update affiliate, please try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 4000);
  }

  formatAddress(address) {
    if (!address?.address1) {
      return "--";
    }

    return `${address?.address1}${
      address?.address2 ? ` ${address.address2}` : ""
    }, ${address?.city}, ${address?.state} ${address?.zip}`;
  }

  render() {
    return (
      <>
        <Card
          className={`mb-4 ${
            this.props.flat ? "shadow-none" : "shadow"
          } border`}
        >
          <CardHeader>
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Point Of Contact</h3>
              </div>
              {this.props.modifiable ? (
                <Col xs="auto">
                  {!this.state.openModify ? (
                    <Button
                      size="sm"
                      color="secondary"
                      outline
                      onClick={() => {
                        this.setState({
                          openModify: true,
                          pointOfContact: this.props.affiliate?.pointOfContact,
                          pointOfContactEmail:
                            this.props?.affiliate?.pointOfContactEmail,
                        });
                      }}
                    >
                      Edit
                    </Button>
                  ) : (
                    <>
                      <Button
                        size="sm"
                        color="secondary"
                        onClick={() => {
                          this.setState({
                            openModify: false,
                          });
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="sm"
                        color="primary"
                        disabled={this.state.submitting}
                        onClick={() => {
                          this.updateInformation();
                        }}
                      >
                        {this.state.submitting ? (
                          <Spinner color="white" size="sm" />
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  )}
                </Col>
              ) : null}
            </Row>
          </CardHeader>
          {this.props.loading ? (
            <CardBody>
              <Spinner size="sm" color="dark" className="mr-2" /> Loading...
            </CardBody>
          ) : (
            <CardBody className="p-0">
              {this.state.openModify ? (
                <>
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h5 className="text-uppercase">Name</h5>
                        <Input
                          type="text"
                          value={this.state.pointOfContact}
                          name="pointOfContact"
                          placeholder="Name"
                          onChange={this.handleInputChange.bind(this)}
                        ></Input>
                      </Col>
                    </Row>
                  </div>

                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h5 className="text-uppercase">Email</h5>
                        <Input
                          type="email"
                          value={this.state.email}
                          name="email"
                          placeholder="Email"
                          onChange={this.handleInputChange.bind(this)}
                        ></Input>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                <>
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="auto">Name:</Col>
                      <Col xs="" className="pl-0">
                        {this.props.affiliate?.pointOfContact
                          ? this.props.affiliate?.pointOfContact
                          : "--"}
                      </Col>
                    </Row>
                  </div>
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="auto">Email:</Col>
                      <Col xs="" className="pl-0">
                        <a href={`mailto:${this.props.affiliate?.email}`}>
                          {this.props.affiliate?.email
                            ? this.props.affiliate?.email
                            : "--"}
                        </a>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
              {this.state.error ? (
                <Alert className="mt-3 mb-0" color="danger">
                  {this.state.error}
                </Alert>
              ) : null}
            </CardBody>
          )}
        </Card>
      </>
    );
  }
}

export default withRouter(AffiliateContactInformationWidget);
